var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "card card-list" }, [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between"
        },
        [
          _c("span", [
            _c(
              "a",
              {
                staticClass: "mr-2 btn btn-primary btn-sm m-0",
                attrs: { href: "/marketing/campaigns/" + _vm.type }
              },
              [
                _c("i", {
                  staticClass: "nav-icon fa fa-chevron-left",
                  attrs: { "aria-hidden": "false" }
                })
              ]
            ),
            _vm._v(" "),
            _c("span", [
              _c("i", {
                staticClass: "nav-icon fa fa-users",
                attrs: { "aria-hidden": "false" }
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.ucFirst(_vm.campaignTitle)) +
                  "\n                "
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("campaign-customers-filter-list-component", {
            attrs: {
              "campaign-id": _vm.id,
              "campaign-type": _vm.type,
              "is-loading": _vm.filterListLoader,
              "filter-list": _vm.filtersList
            },
            on: { change: _vm.onFiltersChangeEvent }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(0),
              _vm._v(" "),
              !_vm.isLoading
                ? _vm._l(_vm.leadsList, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "row leads-section-list-row" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg-4 align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Name")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "font-weight-bold m-0" }, [
                              _vm._v(
                                _vm._s(item.name) + " " + _vm._s(item.surname)
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "#", target: "_blank" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.copyToClipboard(item.id)
                                  }
                                }
                              },
                              [
                                _c("small", [
                                  _vm._v("#" + _vm._s(item.id) + " "),
                                  _c("i", { staticClass: "fa fa-copy" })
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Email")
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.email) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Country")
                            ]),
                            _vm._v(" "),
                            item.phone
                              ? _c("div", [_vm._v(_vm._s(item.phone))])
                              : _vm._e(),
                            _vm._v(" "),
                            item.mobile
                              ? _c("div", [_vm._v(_vm._s(item.mobile))])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Country")
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.emptyField(item.country)) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Live Spent")
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.emptyField(item.live_spent)) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Last Disposition")
                            ]),
                            _vm._v(" "),
                            item.status_name
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-primary" },
                                    [_vm._v(_vm._s(item.status_name))]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(_vm.utcToLocal(item.status_date))
                                    )
                                  ])
                                ])
                              : _c("span", [_vm._v("/")])
                          ]
                        ),
                        _vm._v(" "),
                        item.customer_campaigns &&
                        item.customer_campaigns.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 align-self-center mb-2 mt-2 mb-lg-0 bg-light p-1"
                              },
                              _vm._l(item.customer_campaigns, function(
                                campaign,
                                index
                              ) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "badge badge-primary ml-1"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(campaign.title) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: {
                      items: _vm.leadsList,
                      msg: "No leads added to campaign."
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.leadsResponse.last_page > 1
                ? _c(
                    "nav",
                    { staticClass: "wrap py-3" },
                    [
                      _c("atbl-pagination", {
                        staticClass: "m-0",
                        attrs: { small: true, pagination: _vm.leadsResponse },
                        on: { paginate: _vm.fetchCampaignLeadsList }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-none d-lg-flex" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-4 align-self-center mb-2 mb-lg-0" },
        [_vm._v("Name")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Email")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Phone")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Country")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Live Spent")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Last Disposition")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }