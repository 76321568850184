<template>
    <div class="container-fluid">
        <div v-if="! isEveryActionCompatibleWithTrigger" class="row">
            <div class="col">
                <div class="alert alert-warning">
                  There are <strong>actions</strong> incompatible with the selected <strong>trigger</strong>. Bonus saved with this state should be assigned with overridden settings
                </div>
            </div>
        </div>

        <div v-if="bonusContainsActionsAllowingDataOverriding" class="row">
            <div class="col">
              <div class="alert warning">
                Bonus contains actions that are allowed to override data
              </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-7">
                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Bonus details
                            <span v-if="this.id" class="cursor-pointer" style="color: #3097D1" v-tooltip="{content: 'Copy to clipboard'}" @click="copyToClipboard(uuid)">| <i class="fa fa-copy" aria-hidden="false"></i> {{ this.uuid }}</span>
                        </div>

                        <div v-if="this.id">
                            <atbl-switch :value="isActive"
                                         label="Active state"
                                         name="isActive"
                                         :on="true"
                                         :off="false"
                                         @input="changeActiveState"
                            />
                        </div>
                    </div>

                    <div class="card-body p-4">
                        <div class="row">
                            <div class="col-8">
                                <atbl-input label="Bonus Title" placeholder="Enter bonus title" v-model="title" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <atbl-form-group label="Trigger event" class="font-weight-bold" :horizontal="false">
                                    <select class="form-control" v-model="trigger">
                                        <option :value="null">Select trigger event</option>
                                        <option v-for="triggerItem in triggerList" :value="triggerItem.key">{{ triggerItem.name }}</option>
                                    </select>
                                </atbl-form-group>
                            </div>

                            <div class="col-4">
                                <atbl-form-group label="Active until" :label-cols="3" class="font-weight-bold" :horizontal="false">
                                    <date-range-picker v-model="activeUntil"
                                                       :singleDatePicker="true"
                                                       :autoApply="true"
                                                       :ranges="false"
                                                       :minDate="minDate"
                                                       :showDropdowns="true">
                                        <div slot="input" slot-scope="picker">
                                            <span v-if="activeUntilFormatted">{{ activeUntilFormatted }} <i title="Clear date" @click="clearActiveUntil" style="margin-left: 8px" class="fa fa-times"></i></span>
                                            <span v-else>Select date</span>
                                        </div>
                                    </date-range-picker>
                                </atbl-form-group>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <atbl-input label="Duration (in hours)" placeholder="Enter bonus duration" v-model="hoursDuration" :disabled="!! expiresAt.startDate" />
                            </div>

                            <div class="col-4">
                                <atbl-form-group label="Expires at" :label-cols="3" class="font-weight-bold" :horizontal="false">
                                    <date-range-picker v-model="expiresAt"
                                                       :singleDatePicker="true"
                                                       :autoApply="true"
                                                       :ranges="false"
                                                       :minDate="minDate"
                                                       :showDropdowns="true">
                                        <div slot="input" slot-scope="picker">
                                            <span v-if="expiresAtFormatted">{{ expiresAtFormatted }} <i title="Clear date" @click="clearExpiresAt" style="margin-left: 8px" class="fa fa-times"></i></span>
                                            <span v-else>Select date</span>
                                        </div>
                                    </date-range-picker>
                                </atbl-form-group>
                            </div>

                            <div class="col-12">
                                <atbl-switch label="Recurring"
                                             tooltip="Reassign bonus after it is accomplished"
                                             :on="true"
                                             :off="false"
                                             class="mb-3"
                                             name="isRecurring"
                                             v-model="isRecurring"
                                />
                            </div>

                            <div class="col-12">
                                <atbl-switch label="Is claimable"
                                             :on="true"
                                             :off="false"
                                             class="mb-3"
                                             name="isClaimable"
                                             v-model="isClaimable"
                                />
                            </div>

                            <div class="col-12">
                                <atbl-switch label="Limit occurrences"
                                             :on="true"
                                             :off="false"
                                             class="mb-3"
                                             name="limitOccurrences"
                                             v-model="limitOccurrences"
                                />
                            </div>

                            <template v-if="limitOccurrences">
                                <div class="col-5">
                                    <atbl-input label="Num. of occurrences"
                                                placeholder="Enter bonus duration"
                                                v-model="numberOfOccurrences"
                                    />
                                </div>

                                <div class="col-2 d-flex align-items-center justify-content-center">
                                    <p class="mb-0"><strong>per</strong></p>
                                </div>

                                <div class="col-5">
                                    <atbl-form-group label="Occurrence type" class="font-weight-bold" :horizontal="false">
                                        <select class="form-control" v-model="occurrenceType">
                                            <option v-for="occurrenceTypeItem in occurrencesTypeList" :value="occurrenceTypeItem">{{ occurrenceTypeItem }}</option>
                                        </select>
                                    </atbl-form-group>
                                </div>
                            </template>
                        </div>

                        <div class="row">
                            <div class="col-6 d-flex justify-content-end border-top pt-3">
                                <atbl-textarea label="Note" placeholder="Place a note to remind you of the purpose of this bonus" :rows="3" v-model="notes" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex justify-content-end border-top pt-3">
                                <button class="btn btn-sm btn-primary" @click="save">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Actions
                        </div>

                        <button class="btn btn-sm btn-primary" @click="editingActionIndex = null; showActionModal = true;">
                            Add action
                        </button>
                    </div>

                    <div class="card-body">
                        <div class="general-list">
                            <div class="row">
                                <div class="col-1 text-muted">#</div>
                                <div class="col">Action</div>
                                <div class="col">Configuration</div>
                                <div class="col-2"></div>
                            </div>

                            <div v-for="(action, index) in actions" :key="`bonus_action_${index}_index`" :class="{'is-incompatible': !isCompatible(action)}" class="row">
                                <div class="col-1 text-muted">
                                    {{ index + 1 }}
                                </div>
                                <div class="col">
                                    {{ action.name }}
                                </div>
                                <div class="col">
                                    <span v-for="actionField in action.configuration.filter(x => !!x.value)" class="badge badge__property-tag large badge-primary">
                                        {{ actionField.key }}: <strong>{{ actionField.value === null ? '/' : actionField.value }}</strong>
                                    </span>
                                </div>
                                <div class="col-2">
                                    <a href="#" class="circle circle__minus" @click="removeAction(index)"></a>
                                    <a href="#" class="circle circle__pencil" @click="editAction(index)"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <action-create-edit-modal v-if="showActionModal"
                                  :bonus-action="editingActionIndex !== null ? actions[editingActionIndex] : null"
                                  :bonus-trigger="triggerConfiguration"
                                  v-on:cancel="showActionModal = false"
                                  v-on:confirm="addOrEditAction" />

        <bonus-disable-modal v-if="showDisableBonusModal"
                             v-on:cancel="showDisableBonusModal = false; isActive = true; enableBonus"
                             v-on:confirm="disableBonus" />
    </div>
</template>

<script>
import ActionCreateEditModal from './Modals/Actions/ActionCreateEditModal';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import Date from '../../helpers/Date';
import BonusService from '../../services/BonusService';
import BonusDisableModal from './Modals/DisableModal';

export default {
    components: { BonusDisableModal, ActionCreateEditModal, DateRangePicker },

    props: {
        id: {
            type: Number,
            required: false,
            default() {
                return null;
            }
        }
    },

    data() {
        return {
            minDate: moment().toDate(),
            occurrencesTypeList: ['day', 'week', 'month', 'year', 'forever'],

            datePickersOptions: {
                format: 'YYYY-MM-DD',
                firstDay: 1
            },

            editingActionIndex: null,
            showActionModal: false,
            showDisableBonusModal: false,

            triggerList: [],
            actions: [],

            title: null,
            trigger: null,
            activeUntil: {
                startDate: null,
            },
            isActive: true,
            expiresAt: {
                startDate: null,
            },
            hoursDuration: null,
            isRecurring: false,
            isClaimable: false,

            limitOccurrences: false,
            numberOfOccurrences: 1,
            occurrenceType: 'forever',
            notes: null,
            uuid: null,
        };
    },

    computed: {
        expiresAtFormatted() {
            return this.expiresAt.startDate ? Date.format(this.expiresAt.startDate, 'YYYY-MM-DD') : null;
        },

        activeUntilFormatted() {
            return this.activeUntil.startDate ? Date.format(this.activeUntil.startDate, 'YYYY-MM-DD') : null;
        },

        triggerConfiguration() {
            return !!this.trigger ? this.triggerList.find(t => t.key === this.trigger) : null;
        },

        isEveryActionCompatibleWithTrigger() {
            return this.actions.every(a => this.isCompatible(a));
        },

        bonusContainsActionsAllowingDataOverriding() {
            return !!this.actions.find(a => a.allowDataOverride);
        },
    },

    watch: {
        hoursDuration(val) {
            if (val) {
                this.clearExpiresAt();
            }
        },

        expiresAt: {
            deep: true,
            handler(val) {
                if (val.startDate) {
                    this.hoursDuration = null;
                }
            }
        },
    },

    mounted() {
        this.fetchTriggers();

        if (this.id) {
            this.fetchBonus();
        }
    },

    methods: {
        fetchTriggers() {
            BonusService
                .fetchTriggers()
                .then((response) => {
                    this.triggerList = response.data;
                });
        },

        fetchBonus() {
            BonusService
                .show(this.id)
                .then((response) => {
                    this.uuid = response.data.uuid;
                    this.title = response.data.title;
                    this.trigger = response.data.trigger;
                    this.activeUntil.startDate = response.data.active_until;
                    this.isActive = response.data.is_active;
                    this.expiresAt.startDate = response.data.expires_at;
                    this.hoursDuration = response.data.duration;
                    this.isRecurring = response.data.is_recurring;
                    this.isClaimable = response.data.is_claimable;
                    this.limitOccurrences = response.data.has_limited_occurrences;
                    this.numberOfOccurrences = response.data.number_of_occurrences;
                    this.occurrenceType = response.data.occurrence_type;
                    this.notes = response.data.notes;
                    this.actions = response.data.actions.map(a => {
                        return {
                            id: a.id,
                            key: a.action,
                            name: a.action,
                            compatibility: a.compatibility,
                            allowDataOverride: a.allow_settings_override,
                            configuration: Object.entries(a.settings).map(i => {
                                return {
                                    key: i[0],
                                    value: i[1],
                                }
                            }),
                        };
                    });
                });
        },

        changeActiveState(value) {
			this.isActive = value;
			
            if (!value) {
                this.showDisableBonusModal = true;
                return;
            }

            this.enableBonus();
        },

        enableBonus() {
            if (!this.id && this.isActive) {
                return;
            }

            BonusService
                .enable(this.id)
                .then((response) => {
                    window.showMessage('Bonus successfully enabled');
                });
        },

        disableBonus() {
            this.showDisableBonusModal = false;

            if (!this.id) {
                return;
            }

            BonusService
                .disable(this.id)
                .then((response) => {
                    window.showMessage('Bonus successfully disabled');
                });
        },

        clearExpiresAt() {
            this.expiresAt.startDate = null;
            this.expiresAt.endDate = null;
        },

        clearActiveUntil() {
            this.activeUntil.startDate = null;
            this.activeUntil.endDate = null;
        },

        addOrEditAction(action) {
            if (this.editingActionIndex !== null) {
                this.actions.splice(this.editingActionIndex, 1, action);
            }

            if (this.editingActionIndex === null) {
                this.actions.push(action);
            }

            this.editingActionIndex = null;
            this.showActionModal = false
        },

        editAction(index) {
            this.editingActionIndex = index;
            this.showActionModal = true;
        },

        isCompatible(action) {
            if (!this.triggerConfiguration) {
                return action.compatibility.length === 0;
            }

            return action.compatibility.every(c => this.triggerConfiguration.compatibility_contracts.includes(c));
        },

        removeAction(index) {
            this.actions.splice(index, 1);
        },

        save() {
            if (!this.actions.length) {
                window.flashError({ message: 'You must provide at least 1 action' });
                return;
            }

            const actions = this.actions.map(a => {
                return {
                    key: a.key,
                    fields: a.configuration.reduce((map, c) => {
                        map[c.key] = c.value;

                        return map;
                    }, {}),
                    allowDataOverride: a.allowDataOverride,
                };
            });

            const params = {
                title: this.title,
                trigger: this.trigger,
                ...(this.activeUntil.startDate && { activeUntil: moment(this.activeUntil.startDate).format('YYYY-MM-DD') }),
                ...(this.expiresAt.startDate && { expiresAt: moment(this.expiresAt.startDate).format('YYYY-MM-DD') }),
                ...(this.hoursDuration && { duration: this.hoursDuration }),
                isRecurring: this.isRecurring,
                isClaimable: this.isClaimable,
                limitOccurrences: this.limitOccurrences,
                notes: this.notes,
                ...(this.limitOccurrences && { numberOfOccurrences: this.numberOfOccurrences }),
                ...(this.limitOccurrences && { occurrenceType: this.occurrenceType }),
                actions: actions,
            };

            const savePromise = this.id ? BonusService.update(this.id, params) : BonusService.store(params);

            savePromise
                .then(response => {
                    if (!this.id) {
                        window.location.href = `/bonuses/${response.data.id}/edit`;
                    } else {
                        window.showMessage('Bonus successfully updated');
                    }
                })
                .catch(err => {
                    const errResponse = err.response;

                    if (errResponse.status === 422) {
                        const errors = [errResponse.data.message];

                        for (const [key, error] of Object.entries(errResponse.data.errors)) {
                            errors.push(error[0]);
                        }

                        window.flash(errors.join('<br />'), 'alert-danger');
                    }
                });
        },
    },
}
</script>

<style lang="scss">
.is-incompatible {
    background : rgba(255, 0, 0, 0.22) !important;
}

.vue-daterange-picker {
    width : 100%;

    &.is-invalid {
        .reportrange-text {
            border-color      : #e55353;
            background-repeat : no-repeat;
            background-size   : calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
    }
}

.badge {
    &__property-tag {
        font-size   : 14px;
        font-weight : normal;
        text-shadow : 0 2px rgba(0, 0, 0, 0.3);
        margin      : 0 4px 4px 0;
    }
}

.circle {
    border         : 1px solid #aaaaaa;
    box-shadow     : inset 1px 1px 3px #ffffff;
    width          : 18px;
    height         : 18px;
    border-radius  : 100%;
    position       : relative;
    margin         : 4px;
    display        : inline-block;
    vertical-align : middle;
    background     : #aaaaaa4f;

    &:before,
    &:after {
        content  : '';
        position : absolute;
        top      : 0;
        left     : 0;
        right    : 0;
        bottom   : 0;
    }

    &__minus:before {
        background : #cc0000;
        margin     : auto 3px;
        height     : 2px;
        box-shadow : 0 1px 1px #ffffff9e;
    }

    &__pencil:before {
        font-family : 'CoreUI-Icons-Free' !important;
        content     : "\EB8A";
        font-size   : 11px;
        text-align  : center;
    }
}
</style>
