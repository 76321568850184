<script>
    export default {
        settingsSectionsRoute: function(id, $path){
            return `/settings/${id}/${$path}`;
        },
        getSettingsSections: function(id){
            return window.axios.get(`${this.settingsSectionsRoute(id, "sections")}/get`);
        },
        saveNewSettingsSection: function(id, model){
            return window.axios.post(`${this.settingsSectionsRoute(id, "sections")}/save`, model);
        },
        getSettingSectionById: function(id){
            return window.axios.get(`${this.settingsSectionsRoute(id, "sections")}/single`);
        },
        removeSettingSection: function(id){
            return window.axios.post(`${this.settingsSectionsRoute(id, "sections")}/remove`);
        },
        saveNewSettings: function(id, model){
            return window.axios.post(`${this.settingsSectionsRoute(id, "settings")}/save`, model);
        },
        saveImmediateSettings: function(id, model){
            return window.axios.post(`${this.settingsSectionsRoute(id, "settings")}/immediate`, model);
        },
        getSettingById: function(id){
            return window.axios.get(`${this.settingsSectionsRoute(id, "settings")}/single`);
        },
        removeSettingById: function(id){
            return window.axios.post(`${this.settingsSectionsRoute(id, "settings")}/remove`);
        },
    }
</script>