var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.$can("Phone Numbers - View")
        ? _c("phone-numbers-filter-form-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !!_vm.phoneNumbersFilterFormVisibility,
                expression: "!!phoneNumbersFilterFormVisibility"
              }
            ],
            attrs: { model: _vm.phoneNumbersFilterModel }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-between"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm mr-2",
                    class: {
                      "btn-primary": !_vm.phoneNumbersFilterFormVisibility,
                      "btn-outline-primary": !!_vm.phoneNumbersFilterFormVisibility
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.phoneNumbersFilterFormVisibility = !_vm.phoneNumbersFilterFormVisibility
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-filter" })]
                ),
                _vm._v(" "),
                _vm.$can("Phone Numbers - Pull")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-success mr-2",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onPullPhoneNumbers.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-download" }),
                        _vm._v(" Pull\n                ")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content:
                            "Pull all phone numbers and store to database.",
                          placement: "top-end"
                        },
                        expression:
                          "{\n                       content: 'Pull all phone numbers and store to database.',\n                       placement: 'top-end'\n                   }"
                      }
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-info-circle" })]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg-1" }, [_vm._v("Id")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [
                  _vm._v("Phone number")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg-1" }, [
                  _vm._v("Status")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [
                  _vm._v("Allowed for")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg-1" }, [
                  _vm._v("Is Active")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg-1" }, [
                  _vm._v("Provider")
                ]),
                _vm._v(" "),
                _vm.$canAny(_vm.operationColumnPermissions)
                  ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                      _vm._v(
                        "\n                        Operations\n                    "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              !_vm.isLoading
                ? _vm._l(_vm.phoneNumbers.data, function(item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-1 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Id")
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.id) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Phone number")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.phone_number) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-1 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Status")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class: [
                                "badge",
                                "badge-" + _vm.resolveStatusColor(item.status)
                              ]
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.status) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Allowed for")
                          ]),
                          _vm._v(" "),
                          !!item.allowed_for && !!item.allowed_for.length
                            ? _vm._l(item.allowed_for, function(type, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    class: [
                                      "badge",
                                      "mr-2",
                                      "badge-" + _vm.resolveTypeColor(type)
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(type) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              })
                            : _c("span", [_vm._v("/")])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-1 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Is Active")
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fa",
                            class: {
                              "fa-check text-success": !!item.is_active,
                              "fa-times text-danger": !item.is_active
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-1 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Provider")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class: [
                                "badge",
                                "badge-" +
                                  _vm.resolveProviderColor(item.provider)
                              ]
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.provider) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$canAny(_vm.operationColumnPermissions)
                        ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-start"
                              },
                              [
                                _vm.$can("Phone Numbers - Update")
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onSelectPhoneNumber(item)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-pen" })]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$can("Phone Numbers - Delete")
                                  ? _c("remove-list-item-button", {
                                      attrs: { value: item.id },
                                      on: {
                                        confirmed: _vm.onDeletePhoneNumberEvent
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("items-not-found", {
                    attrs: {
                      items: _vm.phoneNumbers.data,
                      msg: "No phone numbers found"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ]),
        _vm._v(" "),
        !_vm.isLoading && _vm.phoneNumbers.last_page > 1
          ? _c("div", { staticClass: "card-footer" }, [
              _c(
                "nav",
                { staticClass: "wrap py-3" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.phoneNumbers },
                    on: { paginate: _vm.fetchPhoneNumbersList }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("pull-phone-numbers-component", {
        attrs: {
          show: _vm.showPullWindow,
          "is-loading": _vm.isPullLoading,
          "phone-numbers": _vm.pulledPhoneNumbers
        }
      }),
      _vm._v(" "),
      _c("update-phone-number-component", {
        attrs: {
          show: _vm.showUpdateWindow,
          "is-loading": _vm.isUpdateLoading,
          "phone-number": _vm.selectedPhoneNumber
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-phone" }),
      _vm._v(" Phone Numbers")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }