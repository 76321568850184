<template>
    <a href="#"
       class="btn btn-sm mr-2 filter-link"
       :class="{
           'btn-primary': !filter.isActive,
           'btn-success': !!filter.isActive
       }"
       @click.prevent="$emit('click', filter)"
    >
        <div class="d-flex align-items-center"
        >
            {{ filter.title }}
            <span v-if="!!filter.count"
                  class="badge badge-light position-static ml-2"
            >
                <span v-if="filter.count">
                    {{ filter.count }}
                </span>
            </span>
        </div>
    </a>
</template>

<script>
export default {
    name: 'filter-list-link',
    emits: [
        'click',
    ],
    props: {
        filter: {
            type: Object,
            default: () => ({
                title: null,
                count: null,
                isActive: false
            })
        },
    },
}
</script>