var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-flex align-items-center justify-content-center justify-content-lg-start"
    },
    [
      _vm.hasImage && !_vm.isBundle
        ? _c("img", {
            staticClass: "mr-2",
            staticStyle: { width: "60px", height: "auto" },
            attrs: {
              src: _vm.$imageService.gameImage(_vm.item.image),
              alt: "" + _vm.item.image
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isBundle
        ? _c("img", {
            staticClass: "mr-2",
            staticStyle: { width: "60px", height: "auto" },
            attrs: { src: _vm.bundleLogoUrl, alt: "" + _vm.item.name }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasImage && !_vm.isBundle
        ? _c("span", { staticClass: "no-image-found" }, [
            _c("i", {
              staticClass: "fa fa-image",
              attrs: { "aria-hidden": "false" }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "text-left" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.getName(_vm.item)) + "\n        "
          )
        ]),
        _vm._v(" "),
        _vm.isLoteriaNacional
          ? _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("span", { staticClass: "mr-2" }, [
                _c("b", [_vm._v("Lines:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isset(_vm.item, "lines") ? _vm.item.lines.length : 1
                    ) +
                    "\n            "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isSyndicate
          ? _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("span", { staticClass: "mr-2" }, [
                _c("b", [_vm._v("Shares:")]),
                _vm._v(" " + _vm._s(_vm.item.shares) + "\n            ")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "d-block mr-2" }, [
                _c("b", [_vm._v("Draws:")]),
                _vm._v(" " + _vm._s(_vm.item.draws) + "\n            ")
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isBundle
          ? _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("span", { staticClass: "mr-2" }, [
                _c("b", [_vm._v("Weeks:")]),
                _vm._v(" " + _vm._s(_vm.item.weeks) + "\n            ")
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isLotteryGame
          ? _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("span", { staticClass: "mr-2" }, [
                _c("b", [_vm._v("Lines:")]),
                _vm._v(" " + _vm._s(_vm.item.lines.length) + "\n            ")
              ]),
              _vm._v(" "),
              _vm.isset(_vm.item, "weeks")
                ? _c("span", [
                    _c("b", [_vm._v("Weeks:")]),
                    _vm._v(" " + _vm._s(_vm.item.weeks) + "\n            ")
                  ])
                : _c("span", [
                    _c("b", [_vm._v("Draws:")]),
                    _vm._v(" " + _vm._s(_vm.item.draws) + "\n            ")
                  ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isset(_vm.item, "weeks")
          ? _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              _vm._l(_vm.item.weekdays, function(weekday, index) {
                return _c(
                  "span",
                  { key: index, staticClass: "badge badge-primary mr-1" },
                  [
                    _vm._v(
                      "\n                " + _vm._s(weekday) + "\n            "
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }