/**
 * Make a nested object flat
 *
 * Example: { a: {value: 1}, b: 1, c: {extra: 1} }
 * Return: { value:1, b: 1, extra: 1 }
 *
 * @param object
 * @param parents
 * @return {any}
 */
import { isObject } from './Object';

function flatObject (object, parents = []) {
    if (!object || !isObject(object)) {
        return {};
    }

    return Object.assign({}, ...Object
        .entries(object)
        .map(([k, v]) => v && typeof v === 'object'
            ? flatObject(v, [...parents, k])
            : { [k]: v }
        ));
}

export default flatObject;