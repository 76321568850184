<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span>
                    <a :href="`/marketing/campaigns/${type}`"
                       class="mr-2 btn btn-primary btn-sm m-0"
                    >
                        <i class="nav-icon fa fa-chevron-left" aria-hidden="false"></i>
                    </a>
                    <span>
                        <i class="nav-icon fa fa-users" aria-hidden="false"></i> {{ ucFirst(campaignTitle) }}
                    </span>
                </span>
            </div>

            <div class="card-body">
                <campaign-customers-filter-list-component :campaign-id="id"
                                                          :campaign-type="type"
                                                          :is-loading="filterListLoader"
                                                          :filter-list="filtersList"
                                                          @change="onFiltersChangeEvent"
                />

                <div class="general-list">
                    <div class="row d-none d-lg-flex">
                        <div class="col-12 col-lg-4 align-self-center mb-2 mb-lg-0">Name</div>
                        <div class="col">Email</div>
                        <div class="col">Phone</div>
                        <div class="col">Country</div>
                        <div class="col">Live Spent</div>
                        <div class="col">Last Disposition</div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(item, index) in leadsList"
                             class="row leads-section-list-row"
                             :key="index"
                        >
                            <div class="col-12 col-lg-4 align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Name</span>
                                <p class="font-weight-bold m-0">{{ item.name }} {{ item.surname }}</p>
                                <a href="#"
                                   target="_blank"
                                   @click.prevent="copyToClipboard(item.id)"
                                >
                                    <small>#{{ item.id }} <i class="fa fa-copy"></i></small>
                                </a>
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Email</span>
                                {{ item.email }}
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Country</span>
                                <div v-if="item.phone">{{ item.phone }}</div>
                                <div v-if="item.mobile">{{ item.mobile }}</div>
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Country</span>
                                {{ emptyField(item.country) }}
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Live Spent</span>
                                {{ emptyField(item.live_spent) }}
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Last Disposition</span>
                                <div v-if="item.status_name">
                                    <span class="badge badge-primary">{{ item.status_name }}</span>
                                    <br />
                                    <small>{{ utcToLocal(item.status_date) }}</small>
                                </div>
                                <span v-else>/</span>
                            </div>
                            <div v-if="item.customer_campaigns && item.customer_campaigns.length" class="col-12 align-self-center mb-2 mt-2 mb-lg-0 bg-light p-1">
                                <span v-for="(campaign, index) in item.customer_campaigns"
                                      :key="index"
                                      class="badge badge-primary ml-1"
                                >
                                    {{ campaign.title }}
                                </span>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />

                    <not-found v-if="!isLoading"
                               :items="leadsList"
                               msg="No leads added to campaign."
                    />

                    <nav class="wrap py-3" v-if="leadsResponse.last_page > 1">
                        <atbl-pagination class="m-0"
                                         :small="true"
                                         :pagination="leadsResponse"
                                         @paginate="fetchCampaignLeadsList"
                        />
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { clone } from '../../../../utils/Json/Json';
import CampaignsViewSearchModel from './CampaignsViewSearchModel';
import MarketingService from '../../../../services/MarketingService';
import CampaignCustomersFilterListComponent
    from '../_Components/CampaignCustomersFilterList/CampaignCustomersFilterListComponent';
import CampaignCustomersFilterList from '../_Components/CampaignCustomersFilterList/CampaignCustomersFilterList';
import Campaign from '../Campaign';
import ucFirst from '../../../../utils/String/UcFirst';
import emptyField from '../../../../filters/EmptyField';

export default {
    name: 'campaigns-leads-view',
    mixins: [
        Campaign,
        CampaignCustomersFilterList,
    ],
    components: {
        CampaignCustomersFilterListComponent
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            leadsResponse: {},
            leadsList: [],
            searchModel: clone(CampaignsViewSearchModel)
        };
    },
    async created() {
        await this.fetchCampaignLeadsList();
    },
    methods: {
        ucFirst,
        emptyField,
        async onFiltersChangeEvent(filtersList, filtersResolvedList) {
            this.filtersList = filtersList;
            this.searchModel.filter = filtersResolvedList;

            await this.fetchCampaignLeadsList();
        },
        async fetchCampaignLeadsList(page = 1) {
            this.isLoading = true;

            try {
                const response = await MarketingService.getCampaignLeads(
                    this.type,
                    this.id,
                    {
                        ...this.searchModel,
                        page
                    });

                this.leadsResponse = response.data;
                this.leadsList = response.data.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>