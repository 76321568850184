var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: { id: _vm.id, title: "Notes", icon: "fa fa-note" },
      scopedSlots: _vm._u([
        {
          key: "rightSide",
          fn: function() {
            return [
              _vm.$can("Player - Create/Update Notes")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      on: {
                        click: function($event) {
                          _vm.myModal = true
                        }
                      }
                    },
                    [_vm._v("\n                Create Note\n            ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("NotesList", {
        attrs: { notes: _vm.pnotes, isLoading: _vm.isLoading }
      }),
      _vm._v(" "),
      _vm.$can("Player - Create/Update Notes")
        ? _c(
            "atbl-modal",
            {
              attrs: {
                title: "Create Note",
                show: _vm.myModal,
                closeOnBackdrop: false
              },
              on: {
                close: function($event) {
                  _vm.myModal = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger",
                            on: { click: _vm.handleCancel }
                          },
                          [
                            _vm._v(
                              "\n                    Close\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            on: { click: _vm.handleOK }
                          },
                          [
                            _vm._v(
                              "\n                    Save\n                "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3455657594
              )
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("atbl-select", {
                      attrs: {
                        label: "Note Type",
                        name: "note_id",
                        options: _vm.notes,
                        errors: _vm.errors,
                        firstOptionLabel: "Select note type"
                      },
                      model: {
                        value: _vm.pnote.id,
                        callback: function($$v) {
                          _vm.$set(_vm.pnote, "id", $$v)
                        },
                        expression: "pnote.id"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("atbl-textarea", {
                      staticClass: "font-weight-bold",
                      attrs: {
                        id: "type",
                        label: "Message",
                        errors: _vm.errors,
                        placeholder: "Enter message",
                        rows: 3,
                        "max-rows": 6
                      },
                      model: {
                        value: _vm.pnote.message,
                        callback: function($$v) {
                          _vm.$set(_vm.pnote, "message", $$v)
                        },
                        expression: "pnote.message"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("DateTimePicker", {
                      attrs: {
                        defaultValue: _vm.pnote.followUp,
                        label: "Follow-Up Date"
                      },
                      model: {
                        value: _vm.pnote.followUp,
                        callback: function($$v) {
                          _vm.$set(_vm.pnote, "followUp", $$v)
                        },
                        expression: "pnote.followUp"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }