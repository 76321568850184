<template>
    <c-sidebar-nav-dropdown v-if="$canAny(permissions)"
                            :class="[
                                isRouteActive(routes, 'c-show'),
                                { 'c-show' : !!isMarketingDropdownActive }
                            ]"
                            name="Marketing"
                            href="#"
                            fontIcon="fa fa-rocket"
    >
        <c-sidebar-nav-item v-if="$can('Campaigns - View Campaigns')"
                            :addLinkClasses="`${isLeadsCampaignsActive ? 'c-active': null}`"
                            name="Campaigns Leads"
                            fontIcon="fa fa-bullhorn"
                            href="/marketing/campaigns/lead"
        />
        <c-sidebar-nav-item v-if="$can('Campaigns - View Campaigns')"
                            :addLinkClasses="`${isPlayersCampaignsActive ? 'c-active': null}`"
                            name="Campaigns Players"
                            fontIcon="fa fa-bullhorn"
                            href="/marketing/campaigns/player"
        />
        <c-sidebar-nav-item v-if="$can('Leads - View')"
                            :addLinkClasses="isRouteActive([
                                    'campaigns.leads',
                                    'campaigns.leads.show.single.view'
                            ])"
                            name="Leads"
                            fontIcon="fa fa-users"
                            href="/marketing/leads"
        />
        <c-sidebar-nav-item v-if="$can('Leads Statuses - View')"
                            :addLinkClasses="isRouteActive('campaigns.statuses.view')"
                            name="Leads Statuses"
                            fontIcon="fa fa-clock"
                            href="/marketing/statuses"
        />
    </c-sidebar-nav-dropdown>
</template>

<script>
export default {
    name: 'marketing',
    data() {
        return {
            routes: [
                'campaigns.manager.view',
                'campaigns.leads',
                'campaigns.statuses.view',
                'campaigns.manager.call.view',
                'campaigns.manager.list.view',
                'campaigns.manager.edit',
                'campaigns.manager.view',
                'campaigns.leads.show.single.view'
            ],
            permissions: [
                'Campaigns - View Campaigns',
                'Leads - View',
                'Leads Statuses - View',
            ],
            isMarketingDropdownActive: false,
            isLeadsCampaignsActive: false,
            isPlayersCampaignsActive: false,
        };
    },
    created() {
        const url = window.location.href;

        if (url.includes('/marketing/campaigns')) {
            this.isMarketingDropdownActive = true;
        }

        if (url.includes('/marketing/campaigns/lead')) {
            this.isLeadsCampaignsActive = true;
        }

        if (url.includes('/marketing/campaigns/player')) {
            this.isPlayersCampaignsActive = true;
        }
    },
}
</script>