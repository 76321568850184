export default {
    notificationsList: [],
    notificationsWidgetData: {
        notifications: [],
        unread_notifications: null
    },
    notificationsListResponse: {},
    notificationsListLoader: false,
    notificationsWidgetDataLoader: false,
    notificationReadBar: false,
    notificationReadBarLoader: false,
    notificationModel: {
        created_at: null,
        data: {
            amount: null,
            currency: null,
            message: null,
            payment_type: null,
            player_id: 0,
            ticket_line_id: 0
        },
        id: null,
        notifiable_id: null,
        notifiable_type: null,
        read_at: null,
        type: null,
        updated_at: null
    }
};