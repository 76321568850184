<template>
    <atbl-modal :show="show"
                :close-on-backdrop="false"
                title="Confirm add"
                @input="toggleConfirmAddAllLeadsModal"
    >
        <h4 v-if="!isLoading"
            class="text-center"
        >
            Do you really want to {{ inCampaign ? 'remove' : 'add' }} <b>{{ leadsCount }}</b> leads at once?
        </h4>

        <h4 v-if="isLoading"
            class="text-center text-secondary text-uppercase"
        >
            PROCESSING
        </h4>

        <loader :show="isLoading"
                type="small"
        />

        <template #footer>
            <div class="d-flex justify-content-end align-items-center">
                <button :disabled="isLoading"
                        class="btn btn-sm btn-danger mr-2"
                        @click="toggleConfirmAddAllLeadsModal"
                >
                    No
                </button>
                <button :disabled="isLoading"
                        class="btn btn-sm btn-success"
                        @click="onAddAllLeadsToCampaign"
                >
                    Yes
                </button>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'confirm-add-all-customers-in-campaign-component',
    inject: [
        'campaignId',
        'campaignType',
        'addLeadToCampaign',
        'toggleConfirmAddAllLeadsModal',
    ],
    components: {
		AtblModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        inCampaign: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        leadsCount: {
            type: Number,
            default: null
        },
    },
    methods: {
        async onAddAllLeadsToCampaign() {
            const status = await this.addLeadToCampaign([], this.campaignId, this.campaignType, true);

            if (!status) {
                return;
            }

            await this.toggleConfirmAddAllLeadsModal();
        },
    },
}
</script>