var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-list" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.logins, function(item, index) {
        return !_vm.isLoading
          ? _c("div", { key: index, staticClass: "row" }, [
              _c("div", { staticClass: "col-6 col-sm-3 mb-2 mb-lg-0" }, [
                _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Date")]),
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.$options.moment(item.created_at).format("YYYY-MM-DD")
                    ) +
                    "\n\t\t"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-sm-3 mb-2 mb-lg-0" }, [
                _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Time")]),
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.$options.moment(item.created_at).format("HH:mm:ss")
                    ) +
                    "\n\t\t"
                )
              ])
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("not-found", {
            attrs: { items: _vm.logins, msg: "No login history found." }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
      _vm._v(" "),
      !_vm.isLoading && _vm.loginsData.last_page > 1
        ? _c(
            "nav",
            { staticClass: "wrap py-3" },
            [
              _c("atbl-pagination", {
                attrs: { pagination: _vm.loginsData },
                on: { paginate: _vm.getPlayerLoginHistory }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 col-sm-3 text-muted" }, [_vm._v("Date")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-sm-3 text-muted" }, [_vm._v("Time")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }