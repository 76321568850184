<template>
    <atbl-modal :title="modalTitle"
                :close-on-backdrop="false"
                :show="true"
                @close="emitCloseEvent"
    >
        <div class="row border-bottom">
            <div class="col col-4">
                <atbl-form-group label="Select action" class="font-weight-bold" :horizontal="false" :error="null">
                    <select class="form-control" v-model="action" @change="onActionChange">
                        <option :value="null">Select action</option>
                        <option v-for="action in actionList" :key="`action_${action.key}`" :value="action">
                            {{ action.name }}
                        </option>
                    </select>
                </atbl-form-group>
            </div>
        </div>

        <bonus-action-field-wrapper v-if="action" :action-fields-config="action.config.fields" v-model="fields" :disable-fields="allowDataOverride" />

        <div v-if="action" class="row">
            <div class="col col-8">
                <div class="form-group-atbl">
                    <atbl-check-box :value="allowDataOverride"
                                    label="Allow data override"
                                    class="mr-1"
                                    @input="onAllowDataOverrideChange"
                    />
                </div>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-primary" :disabled="! action" @click="submitBonusActionData">
                {{ saveButtonLabel }}
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import BonusActionFieldWrapper from './ActionFieldsWrapper';
import BonusService from '../../../../services/BonusService';
import AtblCheckBox from '../../../../components/Forms/Inputs/AtblCheckbox.vue';
import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'action-create-edit-modal',

    components: {AtblCheckBox, BonusActionFieldWrapper,AtblModal},

    props: {
        bonusAction: {
            required: false,
            default() {
                return null;
            }
        },
        bonusTrigger: {
            required: false,
            type: Object,
            default() {
                return null;
            }
        },
    },

    data() {
        return {
            actionList: [],
            action: null,
            fields: [],
            allowDataOverride: false,
        };
    },

    computed: {
        modalTitle() {
            return !this.bonusAction ? 'Create Action' : 'Edit Action';
        },

        saveButtonLabel() {
            return !this.bonusAction ? 'Create' : 'Update';
        },
    },

    mounted() {
        this.fetchActions()
            .then(() => {
                this.setBonusAction();
                this.setActionFields();
            });
    },

    methods: {
        fetchActions() {
            const promise = BonusService.fetchActions();

            promise.then((response) => {
                this.actionList = response.data;
            });

            return promise;
        },

        setBonusAction() {
            if (!this.bonusAction) {
                return;
            }

            this.action = this.actionList.find(a => a.key === this.bonusAction.key);
            this.allowDataOverride = this.bonusAction.allowDataOverride;
        },

        setActionFields() {
            if (!this.action) {
                return;
            }

            this.fields = Object.entries(this.action.config.fields).map(([i, configField]) => {
                let v = configField.type === 'checkbox' ? false : null;

                if (this.bonusAction) {
                    const valueField = this.bonusAction.configuration.find(c => c.key === configField.key);
                    v = valueField ? valueField.value : v;
                }

                return {
                    key: configField.key,
                    value: v,
                }
            });
        },

        onActionChange() {
            this.fields = [];
            this.allowDataOverride = false;
            this.setActionFields();
        },

        onAllowDataOverrideChange(state) {
            this.allowDataOverride = state;

            if (!state) {
                return;
            }

            this.fields.forEach(f => {
                f.value = null;
            });
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },

        submitBonusActionData() {
            const params = {
                id: this.bonusAction ? this.bonusAction.id : null,
                name: this.action.name,
                key: this.action.key,
                configuration: this.fields,
                compatibility: this.action.config.compatibility,
                allowDataOverride: this.allowDataOverride,
            };

            this.$emit('confirm', params);
        },
    },
}
</script>

<style lang="scss" scoped>
.form-group-atbl {
    margin-top : 1rem;
}
</style>