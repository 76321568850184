var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid players" },
    [
      _c("search-players", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSearchOpen,
            expression: "isSearchOpen"
          }
        ],
        on: { search: _vm.onSearchEvent, clear: _vm.clearSearchModel }
      }),
      _vm._v(" "),
      !!_vm.showTable
        ? _c("div", { staticClass: "card card-list atbl-table m-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex align-items-center justify-content-between"
              },
              [
                _c("div", [
                  _c("i", { staticClass: "fa fa-users" }),
                  _vm._v(" Players\n                "),
                  _vm.players.length
                    ? _c("span", [
                        _vm._v(
                          "\n                    (" +
                            _vm._s(_vm.players.length) +
                            ")\n                "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                !!_vm.hasMore
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                Search result contains more than " +
                          _vm._s(_vm.limit) +
                          " rows, please narrow your search.\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-header-operations" },
                  [
                    !_vm.isLoading && !!_vm.playersBulk.length
                      ? _c(
                          "a",
                          {
                            staticClass: "mr-2 font-weight-bold",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onAddAllToBulkEvent.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _vm.playersBulk.length !== _vm.players.length
                              ? _c("span", [
                                  _vm._v(
                                    "\n                        Select all " +
                                      _vm._s(_vm.players.length) +
                                      " players\n                    "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n                        Clear selection\n                    "
                                  )
                                ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.players.length
                      ? _c("atbl-select", {
                          staticClass: "mr-2 mb-0",
                          attrs: {
                            options: _vm.paginationModel.data,
                            value: _vm.paginationModel.default,
                            "first-option-label": null,
                            "option-label": "text"
                          },
                          on: { input: _vm.onPaginationLimitChangeEvent }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm mr-1",
                        class: {
                          "btn-primary": !_vm.isSearchOpen,
                          "btn-outline-primary": _vm.isSearchOpen
                        },
                        on: {
                          click: function($event) {
                            _vm.isSearchOpen = !_vm.isSearchOpen
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-filter m-0" })]
                    ),
                    _vm._v(" "),
                    _vm.$can("Player - Assign Agent")
                      ? _c(
                          "c-dropdown",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              id: "bulkAction",
                              "toggler-text": "Bulk",
                              color: "danger",
                              size: "sm",
                              right: "",
                              disabled: !_vm.hasPlayerInBulk
                            }
                          },
                          [
                            _c(
                              "c-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.toggleBulkAction()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Assign Agent\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "c-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.toggleBulkAction("UnAssignAgent")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Unassign Agent\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("PlayersList", {
                  attrs: {
                    isLoading: _vm.isLoading,
                    players: _vm.players,
                    playersData: _vm.playersData,
                    "players-bulk": _vm.playersBulk,
                    "bulk-selected-all": _vm.bulkSelectedAll,
                    sort: _vm.sort,
                    page: _vm.page,
                    "partial-checked": _vm.partialChecked,
                    "current-page-selected": _vm.bulkSelectedOnCurrentPage
                  },
                  on: {
                    paginateUsersList: _vm.onPageChangeEvent,
                    agent: _vm.onAssignAgentEvent,
                    callPlayer: _vm.callPlayer,
                    sorted: _vm.onUpdateSortEvent
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !!_vm.showCreatePlayerForm
        ? _c("create-player", {
            on: {
              success: _vm.fetchPlayers,
              close: function($event) {
                _vm.showCreatePlayerForm = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("bulk-players", {
        attrs: {
          show: _vm.showBulkModal,
          "bulk-action": _vm.activeBulkAction,
          players: _vm.players,
          "selected-players": _vm.playersBulk
        }
      }),
      _vm._v(" "),
      _c("assign-agent", {
        attrs: {
          player: _vm.selectedPlayerToAssign,
          show: _vm.showAssignAgentForm
        },
        on: {
          success: _vm.fetchPlayers,
          close: function($event) {
            _vm.showAssignAgentForm = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }