var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      staticClass: "mb-3",
      attrs: { id: _vm.id, title: "Withdrawals", icon: "fa fa-credit-card" },
      scopedSlots: _vm._u([
        {
          key: "first",
          fn: function() {
            return [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-body d-flex align-items-center justify-content-between flex-wrap"
                  },
                  [
                    !_vm.isWalletLoading
                      ? [
                          _c("div", { staticClass: "font-weight-bold" }, [
                            _vm._v("\n            Wallet\n          ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between flex-wrap"
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _c("i", { staticClass: "fa fa-wallet mr-1" }),
                                _vm._v("\n              Real funds: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(_vm.wallet.real_funds)
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-wallet mr-1 mr-1"
                                }),
                                _vm._v("\n              Bonus funds: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(_vm.wallet.bonus_funds)
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("loader", {
                      attrs: { show: _vm.isWalletLoading, type: "small" }
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.$can("Withdrawals - Create")
                ? _c("player-create-withdrawal-component", {
                    attrs: { "player-id": _vm.id }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("player-withdrawals-list", {
        attrs: {
          withdrawals: _vm.withdrawals,
          withdrawalsData: _vm.withdrawalsData,
          isLoading: _vm.isLoading,
          isAdmin: _vm.isAdmin
        }
      }),
      _vm._v(" "),
      _vm.withdrawalDetailsModal
        ? _c("review-withdrawal-component", {
            attrs: {
              "is-loading": _vm.isWithdrawalDetailsLoading,
              "withdrawal-details": _vm.withdrawalDetails,
              "error-message": _vm.errorMessage
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }