<template>
  <div class="container-fluid">
    <withdrawal-search-component v-show="isFilterOpen"
                                 :model="searchModel"
    />

    <div class="card card-list">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span><i class="fa fa-closed-captioning"></i> Withdrawal</span>
        <div>
          <button v-if="$can('Withdrawals - View')"
                  class="btn btn-sm btn-primary m-0"
                  @click="isFilterOpen = !isFilterOpen"

          >
            <i class="fa fa-filter" aria-hidden="false"></i>
          </button>
        </div>
      </div>

      <div class="card-body">
        <section class="general-list">
          <div class="row">
            <div class="col">Player Name</div>
            <div class="col">Created By</div>
            <div class="col">Reference</div>
            <div class="col">Amount</div>
            <div class="col">Status</div>
            <div class="col">Created At</div>
            <div v-if="$can('Withdrawals - Review')" class="col">Actions</div>
          </div>

          <template v-if="!withdrawalsLoader">
            <div v-for="(item, index) in withdrawals.data"
                 :key="index"
                 class="row"
            >
              <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                <span class="small-mb-badge">Player Name</span>
                <a :href="`/players/${item.player_id}/view`"
                   target="_blank"
                   v-if="$can('Player - View Details')"
                >
                  {{ item.player_name }} {{ item.player_surname }}
                </a>
                <span v-else>
                    {{ item.player_name }} {{ item.player_surname }}
                </span>
              </div>
              <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                <span class="small-mb-badge">Created By</span>
                <created-by-badge :created="item"/>
              </div>
              <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                <span class="small-mb-badge">Reference</span>
                <span v-if="item.holder && item.holder !== null">{{ item.holder }}/{{ item.payment_brand }}</span>
                <span v-else class="text-danger">This card is removed.</span>
              </div>
              <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                <span class="small-mb-badge">Amount</span>
                {{ formatCurrency(item.amount) }}
              </div>
              <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                <span class="small-mb-badge">Status</span>
                <withdrawals-status-badge :status="item.status"/>
              </div>
              <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                <span class="small-mb-badge">Created At</span>
                <small>{{ utcToLocal(item.created_at) }}</small>
              </div>
              <div v-if="$can('Withdrawals - Review')"
                   class="col-6 col-sm-6 col-md"
              >
                <span class="small-mb-badge">Action</span>
                <a href="#"
                   class="d-block"
                   @click.prevent="reviewWithdrawal(item.id, item.player_id)"
                >
                  <i class="fa fa-flag"></i> Review
                </a>
              </div>
            </div>

            <items-not-found v-if="!withdrawalsLoader"
                             :items="withdrawals.data"
                             msg="No withdrawals found."
            />
          </template>

          <loader :show="withdrawalsLoader"
                  type="small"
          />

          <nav class="wrap py-3"
               v-if="!withdrawalsLoader && withdrawals.last_page > 1"
          >
            <atbl-pagination :pagination="withdrawals"
                             @paginate="fetchWithdrawals"
            />
          </nav>
        </section>
      </div>
    </div>

    <review-withdrawal-component v-if="$can('Withdrawals - Review') && withdrawalDetailsModal"
                                 :is-loading="isWithdrawalDetailsLoading"
                                 :withdrawal-details="withdrawalDetails"
                                 :error-message="errorMessage"
    />
  </div>
</template>

<script>
import ReviewWithdrawalComponent from './ReviewWithdrawal/ReviewWithdrawalComponent.vue';
import WithdrawalSearchComponent from './WithdrawalSearch/WithdrawalSearchComponent.vue';
import PlayerWithdrawalsList from '../Players/Player/Withdrawals/Sections/PlayerWithdrawalsList.vue';
import ReviewWithdrawal from './ReviewWithdrawal/ReviewWithdrawal';
import {clone} from '../../utils/Json/Json';
import PaginationModel from '../../modules/pagination/PaginationModel';
import WithdrawalsService from '../../services/WithdrawalsService.vue';
import WithdrawalSearch from './WithdrawalSearch/WithdrawalSearch';
import utcToLocal from '../../filters/UtcToLocal';
import formatCurrency from '../../filters/FormatCurrency';
import AtblPagination from '../../components/AtblPagination.vue';
import Loader from '../../components/Widgets/Loader.vue';
import ItemsNotFound from '../../components/Lists/ItemsNotFound.vue';
import WithdrawalsStatusBadge from '../../components/Transactions/WithdrawalsStatusBadge.vue';
import CreatedByBadge from '../../components/Transactions/CreatedByBadge.vue';

export default {
    name: 'withdrawals',
    mixins: [
        ReviewWithdrawal,
        WithdrawalSearch,
    ],
    components: {
        PlayerWithdrawalsList,
        ReviewWithdrawalComponent,
        WithdrawalSearchComponent,
        WithdrawalsStatusBadge,
        CreatedByBadge,
        AtblPagination,
        Loader,
        ItemsNotFound,
    },
    provide() {
        return {
            fetchWithdrawals: this.fetchWithdrawals,
        };
    },
    data() {
        return {
            withdrawalsLoader: false,
            withdrawals: clone(PaginationModel),

            isFilterOpen: false,
        };
    },
    async created() {
        await this.fetchWithdrawals();
    },
    methods: {
        formatCurrency,
        utcToLocal,
        // this method is called after withdrawal close button is pressed
        // call is made in ReviewWithdrawal.js -> closeWithdrawalDetails()
        async afterWithdrawalClose() {
            await this.fetchWithdrawals();
        },
        async fetchWithdrawals(page = 1) {
            this.withdrawalsLoader = true;

            try {
                const response = await WithdrawalsService.getAllWithdrawals({
                    ...this.searchModel,
                    page
                });

                this.withdrawals = response.data;

                this.withdrawalsLoader = false;
            } catch (error) {
                this.withdrawalsLoader = false;

                window.flashError(error);
            }
        },
    },
};
</script>