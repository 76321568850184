export default {
    provide() {
        return {
            approveDrawAction: this.approveDrawAction,
            toggleApproveModal: this.toggleApproveModal,
        };
    },
    data() {
        return {
            isApproveModalLoading: false,
            isApproveModalOpen: false,
        };
    },
    methods: {
        async approveDrawAction(drawId, lines) {
            this.isApproveModalLoading = true;

            try {
                const response = await window.axios.post(`/games/draws/review/${drawId}/approve`, {
                    lines
                });
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.isApproveModalLoading = false;

                    return;
                }

                this.selectedLines = [];

                this.toggleApproveModal();

                await this.getGameDrawReviewDetails(drawId)

                this.isApproveModalLoading = false;
            } catch (error) {
                this.isApproveModalLoading = false;

                window.flashError(error);
            }
        },
        toggleApproveModal() {
            this.isApproveModalOpen = !this.isApproveModalOpen;
        },
    },
}