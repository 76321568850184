<template>
    <atbl-select v-model="selectedValue"
                 :options="options"
                 :label="label"
                 :name="name"
    />
</template>

<script>
export default {
    name: 'CreatedSelect',
    props: {
        value: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: 'Created'
        },
        name: {
            type: String,
            default: 'created'
        },
        options: {
            type: Array,
            default: () => ([
                {
                    label: 'Last 24 Hours',
                    value: 'last_24_hours'
                },
                {
                    label: 'Last 7 Days',
                    value: 'last_7_days'
                },
                {
                    label: 'Last Month',
                    value: 'last_month'
                }
            ])
        },
    },
    computed: {
        selectedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
}
</script>