<template>
  <div class="container-fluid">
    <div class="card card-list">
      <div class="card-header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <i class="fa fa-bell mr-1"></i> Notifications
        </div>
        <div v-if="getNotificationsList.length > 0"
             class="d-flex align-items-center"
        >
          <a class="btn btn-link mr-2"
            @click.prevent="markAllAsRead"
            v-if="$can('Users - Update Notifications')"
          >
            Mark all as read
          </a>
          <confirmable-button @confirmed="clearAll"
                              label="Clear all"
                              v-if="$can('Users - Remove Notifications')"
          />
        </div>
      </div>

      <div class="card-body">
        <div class="general-list">
          <div class="row">
            <div class="col-12 col-lg">Message</div>
            <div class="col-12 col-lg-2">Created At</div>
            <div class="col-12 col-lg-2">Operations</div>
          </div>

          <div v-if="!getNotificationsListLoader"
                 v-for="(item, index) in getNotificationsList"
                 :key="index"
                 :class="{
                  'bg-light': !item.read_at
                 }"
                 class="row"
          >
            <div class="col-12 col-lg mb-1 mb-lg-0">
              <span class="small-mb-badge">Message</span>
              {{ item.data.message }}
            </div>
            <div class="col-12 col-lg-2 mb-1 mb-lg-0">
              <span class="small-mb-badge">Created At</span>
              {{ item.created_at | utcToLocal }}
            </div>
            <div class="col-12 col-lg-2 mb-1 mb-lg-0">
              <span class="small-mb-badge">Operations</span>
              <div class="d-flex align-items-center">
                <button class="btn btn-sm btn-link"
                          @click="readNotification(item)"
                          v-if="$can('Users - Get Notifications')"
                >
                  <i class="fa fa-eye" aria-hidden="false"></i>
                </button>
                <confirmable-remove-button :value="item.id"
                                           @confirmed="removeNotification"
                                           v-if="$can('Users - Remove Notifications')"
                />
              </div>
            </div>
          </div>

          <not-found v-if="!getNotificationsListLoader"
                     :items="getNotificationsList"
                     msg="No notifications found"
          />

          <loader :show="getNotificationsListLoader"
                  type="small"
          />
	
	        <nav class="wrap p-3" v-if="!getNotificationsListLoader && getNotificationsListResponse.last_page > 1">
		        <atbl-pagination :pagination="getNotificationsListResponse"
		                         @paginate="getNotificationsListAction"
		        />
	        </nav>
        </div>
      </div>
    </div>

    <ReadNotificationsSidebar v-if="$can('Users - Get Notifications')"/>
  </div>
</template>

<script>
  import TypesConst from "../../store/TypesConst";
  import NamespaceConst from "../../store/NamespaceConst";
  import {createNamespacedHelpers} from "vuex";

  const {
    mapGetters: mapNotificationsGetters,
    mapActions: mapNotificationsActions
  } = createNamespacedHelpers(NamespaceConst.notifications);

  import ReadNotificationsSidebar from "./Components/ReadNotificationsSidebar";

  export default {
    name: "notifications",
    components: {
      ReadNotificationsSidebar
    },
    computed: {
      ...mapNotificationsGetters({
        getNotificationsList: TypesConst.notifications.getters.GET_NOTIFICATIONS_LIST,
        getNotificationsListResponse: TypesConst.notifications.getters.GET_NOTIFICATIONS_LIST_RESPONSE,
        getNotificationsListLoader: TypesConst.notifications.getters.GET_NOTIFICATIONS_LIST_LOADER
      })
    },
    methods: {
      ...mapNotificationsActions({
        getNotificationsListAction: TypesConst.notifications.actions.NOTIFICATIONS_GET_LIST_ACTION,
        readNotification: TypesConst.notifications.actions.NOTIFICATIONS_READ_ACTION,
        removeNotification: TypesConst.notifications.actions.NOTIFICATIONS_REMOVE_ACTION,
        markAllAsRead: TypesConst.notifications.actions.NOTIFICATIONS_MARK_ALL_AS_READ_ACTION,
        clearAll: TypesConst.notifications.actions.NOTIFICATIONS_CLEAR_ALL_ACTION
      }),
      openNotification: function () {
        let id = this.getParam("id");

        if (id) {
          this.readNotification({
            id
          });
        }
      }
    },
    created: function () {
      this.getNotificationsListAction();

      this.openNotification();
    }
  }
</script>
