<template>
	<div v-if="getProcessingOverlay" class="modal fade show d-block">
		<div tabindex="-1" class="d-flex flex-column justify-content-center align-items-center"
		     style="height: 100%">
			<span class="spiner" style="z-index: 1051"><img src="/img/spiner.svg" alt="spiner"></span>
		</div>
		<div class="modal-backdrop fade show"></div>
	</div>
</template>

<script>
	import TypesConst from "../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../store/NamespaceConst";
	import { createNamespacedHelpers } from "vuex";

	const {
		mapGetters: mapCartGetters
	} = createNamespacedHelpers(NamespaceConst.cart);

	export default {
		name: "processing-overlay",
		computed: {
			...mapCartGetters({
				getProcessingOverlay: TypesConst.cart.getters.GET_PROCESSING_OVERLAY
			})
		}
	}
</script>