export default {
    name: null,
    surname: null,
    email: null,
    country: null,
    phone: null,
    status: [],
    statusDate: null,
    batch: null,
    visible: 1,
    inCampaign: false,
    balance: null,
    balanceSelector: null
}