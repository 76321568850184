<template>
    <atbl-modal title="Disable Bonus" :close-on-backdrop="true" color="danger" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <p>Are you sure you want to disable this bonus ?</p>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-danger" @click="confirm">
                Yes
            </button>

            <button class="btn btn-sm btn-primary" @click="emitCloseEvent">
                No
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'bonus-disable-modal',
    components: {
		AtblModal,
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },
    },
}
</script>