var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      attrs: {
        label: _vm.label,
        "label-for": _vm.getLabelFor,
        error: _vm.getError(_vm.errors, _vm.name)
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          _vm.$slots.formGroup ? _vm._t("formGroup") : _vm._e(),
          _vm._v(" "),
          _c(
            "select",
            {
              staticClass: "form-control",
              class: {
                "is-invalid": _vm.hasError(_vm.errors, _vm.name)
              },
              attrs: { id: _vm.getId, disabled: _vm.disabled },
              on: { change: _vm.onUpdate }
            },
            [
              _vm.firstOptionLabel
                ? _c(
                    "option",
                    { domProps: { value: null, selected: !_vm.value } },
                    [_vm._v(_vm._s(_vm.firstOptionLabel))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.options, function(item, index) {
                return _c(
                  "option",
                  {
                    key: index,
                    domProps: {
                      value: _vm.getOptionData(item, "value"),
                      selected: _vm.getOptionData(item, "value") === _vm.value
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.getOptionData(item, _vm.optionLabel)) +
                        "\n\t\t\t\t"
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _vm._t("end")
            ],
            2
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }