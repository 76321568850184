var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    {
      attrs: { title: "Leads", icon: "nav-icon fa fa-users" },
      scopedSlots: _vm._u(
        [
          _vm.showFilter
            ? {
                key: "beforeCard",
                fn: function() {
                  return [
                    _c("leads-filter-form-component", {
                      attrs: { model: _vm.modelSearch },
                      on: { search: _vm.fetchLeads }
                    })
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "header",
            fn: function() {
              return [
                _vm.$can("Leads - View")
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Search"
                            },
                            expression:
                              "{\n                        content: 'Search'\n                    }"
                          }
                        ],
                        staticClass: "btn btn-sm",
                        class: {
                          "btn-outline-primary": _vm.showFilter,
                          "btn-primary": !_vm.showFilter
                        },
                        on: {
                          click: function($event) {
                            _vm.showFilter = !_vm.showFilter
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-filter",
                          attrs: { "aria-hidden": "false" }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("Leads - Import")
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Import"
                            },
                            expression:
                              "{\n                        content: 'Import'\n                    }"
                          }
                        ],
                        staticClass: "btn btn-sm btn-warning",
                        on: {
                          click: function($event) {
                            _vm.showImportLeadsModal = true
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-upload",
                          attrs: { "aria-hidden": "false" }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("Leads - Export")
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Export"
                            },
                            expression:
                              "{\n                        content: `Export`\n                    }"
                          }
                        ],
                        staticClass: "btn btn-sm btn-success",
                        attrs: { disabled: !!_vm.exportLoader },
                        on: { click: _vm.onExportEvent }
                      },
                      [
                        _c("loader", {
                          attrs: { show: _vm.exportLoader, type: "smaller" }
                        }),
                        _vm._v(" "),
                        !_vm.exportLoader
                          ? _c("i", {
                              staticClass: "fa fa-download",
                              attrs: { "aria-hidden": "false" }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("Leads - Create/Edit")
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Create Lead"
                            },
                            expression:
                              "{\n                        content: 'Create Lead'\n                    }"
                          }
                        ],
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.toggleAddLeadModal }
                      },
                      [_vm._v("\n                Create\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: !_vm.hasSearchModelValues
                            ? "Search form is empty"
                            : "Create campaign from leads list"
                        },
                        expression:
                          "{\n                     content: !hasSearchModelValues\n                     ? 'Search form is empty'\n                     : 'Create campaign from leads list'\n                  }"
                      }
                    ]
                  },
                  [
                    _vm.$can("Campaigns - Create/Update")
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            attrs: { disabled: !_vm.hasSearchModelValues },
                            on: { click: _vm.toggleAddToCampaign }
                          },
                          [
                            _vm._v(
                              "\n                    Add to campaign\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "modals",
            fn: function() {
              return [
                _vm.isLeadModalOpen
                  ? _c("add-leads-modal-component", {
                      attrs: {
                        model: _vm.leadModel,
                        errors: _vm.leadModelErrors,
                        "is-loading": _vm.addLeadLoader
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showImportLeadsModal
                  ? _c("import-leads-modal", {
                      attrs: {
                        file: _vm.fileToImport,
                        "is-loading": _vm.importLoader,
                        errors: _vm.importErrors
                      },
                      on: {
                        close: function($event) {
                          _vm.showImportLeadsModal = false
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showImportLeadsErrorsModal
                  ? _c("import-errors-log-modal", {
                      attrs: { errors: _vm.importErrors }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("add-to-campaign-component", {
                  attrs: {
                    show: _vm.isAddToCampaignOpened,
                    "search-model": _vm.modelSearch
                  }
                })
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Email")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Phone")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Country")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Live Spent")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg" }, [
              _vm._v("Last Status")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Operations")])
          ]),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.leads, function(item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Name")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-2 font-weight-bold" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.name) +
                            " " +
                            _vm._s(item.surname) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$can("Leads - Details")
                        ? _c(
                            "a",
                            {
                              attrs: { href: "#", target: "_blank" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.copyToClipboard(item.id)
                                }
                              }
                            },
                            [
                              _c("small", [
                                _vm._v("#" + _vm._s(item.id) + " "),
                                _c("i", { staticClass: "fa fa-copy" })
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !!item.converted
                        ? _c("span", { staticClass: "badge badge-success" }, [
                            _vm._v(
                              "\n                            Converted\n                        "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Email")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.emptyField(item.email)) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Phone")
                      ]),
                      _vm._v(" "),
                      _c("small", [_vm._v(_vm._s(item.phone))]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [_vm._v(_vm._s(item.mobile))])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Country")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.emptyField(item.country)) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Live Spent")
                      ]),
                      _vm._v(" "),
                      item.live_spent !== null && item.live_spent === 0
                        ? _c("span", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.live_spent) +
                                "\n                        "
                            )
                          ])
                        : item.live_spent > 0
                        ? _c("span", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.formatCurrency(item.live_spent)) +
                                "\n                        "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                            /\n\t\t\t\t\t    "
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Last Status")
                      ]),
                      _vm._v(" "),
                      !!item.status_name && !!item.status_date
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "badge",
                                class: {
                                  "badge-primary":
                                    item.status_name !==
                                    _vm.$options.Campaigns.SaleStatusName,
                                  "badge-success":
                                    item.status_name ===
                                    _vm.$options.Campaigns.SaleStatusName
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.status_name) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.utcToLocal(item.status_date)) +
                                  "\n                            "
                              )
                            ])
                          ])
                        : _c("span", [_vm._v("/")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _vm.$can("Leads - Details")
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    href:
                                      "/marketing/leads/" + item.id + "/view",
                                    target: "_blank"
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-eye" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$can("Leads - Remove")
                            ? _c("confirmable-remove-button", {
                                attrs: { value: item.id },
                                on: { confirmed: _vm.onDeleteLead }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !!item.campaigns && item.campaigns.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-12 align-self-center mb-2 mt-2 mb-lg-0 bg-light p-1"
                        },
                        _vm._l(item.campaigns, function(campaign, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "badge badge-primary ml-1"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(campaign.title) +
                                  "\n                        "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.leads, msg: "No leads found." }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && _vm.leadsResponse.last_page > 1
            ? _c(
                "nav",
                { staticClass: "wrap py-3 overflow-x-auto" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.leadsResponse },
                    on: { paginate: _vm.fetchLeads }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }