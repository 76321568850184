<script>
    export default {
        getWithdrawalsRoute: function(){
            return `/transaction/get/withdrawal/all`;
        },
        getWithdrawalDetailsRoute: function(id){
            return `/transaction/get/withdrawal/${id}/get`;
        },
        getAllWithdrawals: function(model){
            return window.axios.post(
                this.getWithdrawalsRoute(),
                model
            );
        },
        getWithdrawalById: function(id, playerId){
            return window.axios.get(this.getWithdrawalDetailsRoute(id), {
                params: {
                    playerId: playerId
                }
            });
        },
        getWithdrawalsStatuses: function(){
            return window.axios.get(`/transaction/get/withdrawals/statuses`);
        }
    }
</script>