var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$canAny(_vm.permissions)
    ? _c(
        "c-sidebar-nav-dropdown",
        {
          class: _vm.isRouteActive(_vm.routes, "c-show"),
          attrs: { name: "Reports", href: "#", fontIcon: "fa fa-chart-line" }
        },
        [
          _vm.$can("Reports Agents - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Agents",
                  fontIcon: "fa fa-calendar-alt",
                  href:
                    "" +
                    (_vm.getIsOnlyAgent
                      ? "/reports/agents/agent/" + _vm.user + "/edit"
                      : "/reports/agents"),
                  addLinkClasses: _vm.isRouteActive([
                    "reports-agents-view",
                    "reports-agents-get-agent"
                  ])
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Reports Agent Deposits - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Agent Deposits",
                  fontIcon: "fa fa-list-alt",
                  href: "/reports/agents/deposits",
                  addLinkClasses: _vm.isRouteActive(
                    "reports-agents-deposit-view"
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Reports Sales - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Sales",
                  fontIcon: "fa fa-pound-sign",
                  href: "/reports/sales",
                  addLinkClasses: _vm.isRouteActive("reports-sales-view")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Reports Games - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Games",
                  fontIcon: "fa fa-play",
                  href: "/reports/games",
                  addLinkClasses: _vm.isRouteActive("reports-games-view")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Reports Players - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Players",
                  fontIcon: "fa fa-users",
                  href: "/reports/players",
                  addLinkClasses: _vm.isRouteActive("reports-players-view")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Reports New Players - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "New Players",
                  fontIcon: "fa fa-users",
                  href: "/reports/new-players",
                  addLinkClasses: _vm.isRouteActive([
                    "reports-new-players-view",
                    "reports-new-players-monthly-details"
                  ])
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }