<template>
    <b-card class="multi-card">
        <div class="multi-card-header d-flex justify-content-between">
            <span>{{ title }}</span> <i :class="icon"></i>
        </div>
        <div class="multi-card-items d-flex justify-content-between">
            <div class="multi-card-item" v-for="item in data" :key="item.index">
                <a :href="item.link" target="_blank" v-if="item.link">
                    <div class="h4 mb-0">{{item.number}}</div>
                    <small class="text-muted text-uppercase font-weight-bold">{{item.title}}</small>
                </a>
                <div v-if="!item.link" class="h4 mb-0">{{item.number}}</div>
                <small v-if="!item.link" class="text-muted text-uppercase font-weight-bold">{{item.title}}</small>
                <b-progress height={} class="progress-xs mt-3 mb-0" variant="info" :value="Number(item.value)"/>
            </div>
        </div>
    </b-card>
</template>

<script>
    export default {
        name: "statistics-card",
        props: {
            icon: {
                required: true
            },
            title: {
                required: true
            },
            data: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped lang="scss">
    .multi-card {
        padding: 0;

        .card-body {
            padding: 0;
        }

        &-header {
            padding: 5px 15px;
            background-color: #20a8d8;
            color: #FFF;
            font-size: 14px;
            align-items: center;
        }

        &-items {
            padding: 15px;
            flex-wrap: wrap;
        }

        &-item {
            flex-basis: 0;
            flex-grow: 1;
            margin-right: 0px;
            text-align: center;
            align-items: inherit;
            justify-content: space-between;
            flex-direction: column;
            display: flex;

            &:last-of-type {
                margin-right: 0;
            }

            @media(min-width: 768px){
                margin-right: 5px;
            }
        }
    }
</style>