var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "card player-play mb-3" },
            [
              _vm.gameConfiguration
                ? _c("syndicate-game-header", {
                    attrs: {
                      config: _vm.gameConfiguration,
                      syndicate: _vm.syndicate
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "player-play-picker" },
                  [
                    _c("validation-errors", { attrs: { errors: _vm.errors } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "player-play-picker-wrapper" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("p", { staticClass: "text-uppercase" }, [
                                _vm._v(
                                  "\n                                            # Lines - "
                                ),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.syndicate.lines_count))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-8 col-lg-6" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "syndicate-group" },
                                  [
                                    _c("div", [
                                      _c("span", [_vm._v("Shares")]),
                                      _vm._v(" "),
                                      !_vm.hasSelectedFreeGame
                                        ? _c("span", [
                                            _vm._v("#Max: "),
                                            _c("strong", [
                                              _vm._v(_vm._s(_vm.maxShares))
                                            ])
                                          ])
                                        : _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v("Free "),
                                              _c("strong", [
                                                _vm._v(_vm._s(_vm.shares))
                                              ]),
                                              _vm._v(" shares!")
                                            ]
                                          )
                                    ]),
                                    _vm._v(" "),
                                    _c("atbl-number", {
                                      staticClass: "m-0",
                                      attrs: {
                                        value: _vm.shares,
                                        min: 1,
                                        max: _vm.maxShares,
                                        "show-errors": false,
                                        disabled: _vm.hasSelectedFreeGame,
                                        label: null,
                                        name: "shares",
                                        placeholder: "Enter number of shares"
                                      },
                                      on: { update: _vm.setShares }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.maxFutureDraws > 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 col-md-8 col-lg-6" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "syndicate-group" },
                                      [
                                        _c("div", [
                                          _c("span", [_vm._v("Draws")]),
                                          _vm._v(" "),
                                          !_vm.hasSelectedFreeGame
                                            ? _c("span", [
                                                _vm._v("#Max: "),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(_vm.maxFutureDraws)
                                                  )
                                                ])
                                              ])
                                            : _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v("Free "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(_vm.futureDraws)
                                                    )
                                                  ]),
                                                  _vm._v(" draws!")
                                                ]
                                              )
                                        ]),
                                        _vm._v(" "),
                                        _c("atbl-number", {
                                          staticClass: "m-0",
                                          attrs: {
                                            value: _vm.futureDraws,
                                            min: 1,
                                            max: _vm.maxFutureDraws,
                                            "show-errors": false,
                                            disabled: _vm.hasSelectedFreeGame,
                                            label: null,
                                            name: "draws",
                                            placeholder: "Enter number of draw"
                                          },
                                          on: { update: _vm.setDraws }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "buy" }, [
                          _c("div", { staticClass: "buy-total" }, [
                            _c("table", [
                              _c("tr", { staticClass: "border-bottom" }, [
                                _vm._m(0),
                                _vm._v(" "),
                                _c("td", { attrs: { align: "right" } }, [
                                  !_vm.hasSelectedFreeGame
                                    ? _c(
                                        "span",
                                        { staticClass: "buy-total-price ml-1" },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(_vm.shares) +
                                              " x " +
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  _vm.syndicate.share_price
                                                )
                                              ) +
                                              "\n                                                "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "buy-total-price ml-1" },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(_vm.shares) +
                                              " "
                                          ),
                                          _c("s", [
                                            _vm._v(
                                              "x " +
                                                _vm._s(
                                                  _vm._f("formatCurrency")(
                                                    _vm.syndicate.share_price
                                                  )
                                                )
                                            )
                                          ])
                                        ]
                                      )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticClass: "border-bottom" }, [
                                _vm._m(1),
                                _vm._v(" "),
                                _c("td", { attrs: { align: "right" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "buy-total-price ml-1" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.futureDraws) +
                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _vm._m(2),
                                _vm._v(" "),
                                _c("td", { attrs: { align: "right" } }, [
                                  !_vm.hasSelectedFreeGame
                                    ? _c(
                                        "strong",
                                        { staticClass: "buy-total-price ml-1" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.totalPrice
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _c(
                                        "strong",
                                        { staticClass: "buy-total-price ml-1" },
                                        [
                                          _c("s", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  _vm.totalPrice
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatCurrency")(0)
                                              )
                                          )
                                        ]
                                      )
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "buy-operations" }, [
                            !_vm.hasSelectedFreeGame
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn buy-button btn-sm mb-2 btn-success",
                                    attrs: { name: "buyBtn", type: "submit" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addToCart(false)
                                      }
                                    }
                                  },
                                  [_vm._m(3)]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.hasSelectedFreeGame
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-danger buy-button text-uppercase",
                                    on: {
                                      click: function($event) {
                                        return _vm.addToCart(true)
                                      }
                                    }
                                  },
                                  [
                                    !_vm.isPlacingOrder
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-credit-card",
                                            attrs: { "aria-hidden": "false" }
                                          }),
                                          _vm._v(
                                            " Add & Place Order\n                                        "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("loader", {
                                      attrs: {
                                        show: _vm.isPlacingOrder,
                                        type: "smaller"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasSelectedFreeGame
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-warning buy-button text-uppercase",
                                    attrs: { disabled: _vm.isUseFreeLoading },
                                    on: {
                                      click: function($event) {
                                        _vm.useFreeTicket(_vm.ticket())
                                      }
                                    }
                                  },
                                  [
                                    !_vm.isUseFreeLoading
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-ticket-alt",
                                            attrs: { "aria-hidden": "false" }
                                          }),
                                          _vm._v(
                                            " Use Free Ticket\n                                    "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("loader", {
                                      attrs: {
                                        show: _vm.isUseFreeLoading,
                                        type: "smaller"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Shares: ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Draws: ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Total:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", {
        staticClass: "fa fa-shopping-cart",
        attrs: { "aria-hidden": "false" }
      }),
      _vm._v(" Add to Cart\n                                        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }