<template>
    <div class="container-fluid callbacks">
        <CallbackSearchForm v-if="filter" />
        <CallbackList @filterToggled="filterShowHide" />
    </div>
</template>

<script>
    import CallbackSearchForm from "./Components/CallbackSearchForm";
    import CallbackList from "./Sections/CallbackList";

    export default {
        name: "Callback",
        data: function(){
            return {
                filter: true
            };
        },
        components: {
            CallbackList,
            CallbackSearchForm
        },
        methods: {
            filterShowHide: function(){
                this.filter = !this.filter;
            }
        }
    }
</script>