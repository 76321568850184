import TypesConst from "../../TypesConst";
import moment from "moment";
import Date from "../../../helpers/Date";

export default {
    [TypesConst.callback.mutations.SET_CALLBACK_LIST](state, payload) {
        state.callBackList = payload;
    },
    [TypesConst.callback.mutations.SET_CALLBACK_LIST_RESPONSE](state, payload) {
        state.callBackListResponse = payload;
    },
    [TypesConst.callback.mutations.SET_CALLBACK_LIST_LOADER](state, payload) {
        state.callBackListLoader = payload;
    },
    [TypesConst.callback.mutations.SET_CALLBACK_LIST_MODEL](state, payload) {
        Object.assign(state.callBackListModel, payload);
    },
    [TypesConst.callback.mutations.SET_CALLBACK_LIST_MODEL_CLEAR](state, payload) {
        state.callBackListModel = {
            agents: [],
            dateFrom: moment().subtract(7, "days").format(Date.defaultDate),
            dateTo: moment().format(Date.defaultDate),
            country: null,
            sort: {
                sort: "none",
                value: "DESC"
            }
        };
    },
};