var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "card card-search" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col mb-2 m-lg-0" },
            [
              _c("p", { staticClass: "d-inline" }, [_vm._v("Filter by tags")]),
              _vm._v(" "),
              _vm._l(_vm.tags, function(tag) {
                return _c(
                  "span",
                  {
                    key: "filterTag_" + tag,
                    staticClass: "badge mr-1 cursor-pointer",
                    class: [
                      _vm.selectedTags.includes(tag)
                        ? "badge-primary"
                        : "badge-secondary"
                    ],
                    on: {
                      click: function($event) {
                        return _vm.addToSelectedTags(tag)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(tag) +
                        "\n                    "
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "atbl-form-group",
                {
                  staticClass: "font-weight-bold",
                  attrs: {
                    label: "Date range",
                    "label-cols": 3,
                    horizontal: false
                  }
                },
                [
                  _c("date-range-picker", {
                    attrs: {
                      singleDatePicker: "range",
                      autoApply: true,
                      ranges: false,
                      opens: "right",
                      showDropdowns: true
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "input",
                        fn: function(picker) {
                          return _c("div", {}, [
                            _vm.dateRangeFormatted
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.dateRangeFormatted) + " "),
                                  _c("i", {
                                    staticClass: "fa fa-times",
                                    staticStyle: { "margin-left": "8px" },
                                    attrs: { title: "Clear date" },
                                    on: { click: _vm.clearDayRange }
                                  })
                                ])
                              : _c("span", [_vm._v("Select date")])
                          ])
                        }
                      }
                    ]),
                    model: {
                      value: _vm.dateRange,
                      callback: function($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function($event) {
                _vm.page = 1
                _vm.fetchLogs()
              }
            }
          },
          [
            _c("i", { staticClass: "fa fa-search" }),
            _vm._v(" Search\n            ")
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card card-list" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _vm._m(2),
            _vm._v(" "),
            _vm._l(_vm.logs, function(item, index) {
              return _c(
                "div",
                {
                  key: "log_row_" + index,
                  staticClass: "row",
                  style: { background: item.color }
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Message")
                      ]),
                      _vm._v(" "),
                      _c("strong", [_vm._v(_vm._s(item.message))]),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-block text-muted" }, [
                        _vm._v("\n                            Level: "),
                        _c("strong", [_vm._v(_vm._s(item.level))])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-block text-muted" }, [
                        _vm._v("\n                            Code: "),
                        _c("strong", [_vm._v(_vm._s(item.code))])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("File")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.file) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("File line")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.file_line) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("File")
                      ]),
                      _vm._v(" "),
                      _vm._l(item.tags, function(tag) {
                        return _c(
                          "span",
                          {
                            key: "log_tag_" + tag,
                            staticClass: "badge badge-primary mr-1"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(tag) +
                                "\n                        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("File line")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.date_time) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Operations")
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-warning",
                        on: {
                          click: function($event) {
                            return _vm.showLog(item.group_id)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                            View\n                        "
                        )
                      ]
                    )
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("div", {
              ref: "infinite-scroll-trigger",
              staticStyle: { visibility: "hidden", padding: "0" }
            }),
            _vm._v(" "),
            !_vm.logs.length
              ? _c("not-found", { attrs: { items: [], msg: "No logs found" } })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", {
        staticClass: "fa fa-search",
        attrs: { "aria-hidden": "false" }
      }),
      _vm._v(" Search\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center justify-content-between"
      },
      [
        _c("span", [
          _c("i", { staticClass: "fa fa-align-justify" }),
          _vm._v("\n                Logs\n            ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Error")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("File")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("File line")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Tags")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Date time")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Operations")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }