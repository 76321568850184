<template>
    <atbl-modal title="Remove Credit Card"
             :show="show"
             class="hide-close"
             @close="show = false"
    >
        <div class="row">
            <div class="col-12">
                Are you sure you want to delete this credit card?
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-danger"
                      @click="close"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                      :disabled="isLoading"
                      @click="deleteCard"
            >
                <span v-if="!isLoading">Delete</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
    import PlayerCreditCardsService from '../../../../../services/PlayerCreditCardsService';
    import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

    export default {
        name: "remove-note-modal",
	    components: {
		    AtblModal,
	    },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            cardId: {
                type: Number,
                default: null
            }
        },
        emits: [
            'close',
            'removed',
        ],
        data () {
            return {
                isLoading: false
            };
        },
        methods: {
            deleteCard () {
                if (!this.cardId) {
                    flash('Card id missing.');
                    return;
                }

                this.isLoading = true;

                PlayerCreditCardsService.deletePlayerCard(this.playerId, {
                    tId: this.cardId
                })
                    .then(() => {
                        this.$emit('removed');
                        this.close();

                        flash('Success');
                    })
                    .catch(error => {
                        let { message } = error.response.data ? error.response.data : error;
                        flash(message, "alert-danger");
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            close () {
                this.$emit('close', null, false);
            }
        }
    }
</script>