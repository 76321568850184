import TypesConst from "../../TypesConst";

export default {
    [TypesConst.payment.mutations.SET_HAS_SAVED_CARD](state, payload){
        state.hasSavedCard = payload;
    },
    [TypesConst.payment.mutations.SET_HAS_SAVED_CARD_LOADER](state, payload){
        state.hasSavedCardLoader = payload;
    },
    [TypesConst.payment.mutations.SET_PAYMENT_LOADER](state, payload){
        state.paymentLoader = payload;
    },
};