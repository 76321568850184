<template>
    <form @submit.prevent="search">
        <div class="card card-search">
            <div class="card-header">
                <i class="fa fa-search" aria-hidden="false"></i> Search
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6 col-xl">
                        <atbl-input id="firstName"
                                 label="Name"
                                 type="text"
                                 class="font-weight-bold"
                                 placeholder="First Name"
                                 v-model="filters.name"
                        />
                    </div>
                    <div class="col-12 col-sm-6 col-xl">
                        <atbl-input id="surname"
                                 label="Surname"
                                 class="font-weight-bold"
                                 type="text"
                                 placeholder="Surname"
                                 v-model="filters.surname"
                        />
                    </div>
                    <div class="col-12 col-sm-6 col-xl">
                        <atbl-input id="ticket"
                                 label="Ticket Num."
                                 class="font-weight-bold"
                                 type="text"
                                 placeholder="Ticket Number"
                                 v-model="filters.ticket"
                        />
                    </div>
                    <div class="col-12 col-sm-6 col-xl">
                        <atbl-form-group label="Draw date"
                                         label-for="Draw date"
                                         :label-cols="3"
                                         class="font-weight-bold"
                                         :horizontal="false"
                        >
                            <multi-date-picker v-model="selectedDates"
                                               :format="datePickersOptions.format"
                                               :show-ranges="true"
                                               :auto-apply="true"
                                               direction="left"
                            />
                        </atbl-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-xl">
                        <multi-games-picker v-model="filters.games"
                                            :horizontal="false"
                                            filter="lottery"
                        />
                    </div>
                    <div v-if="!getIsOnlyAgent && $can('Users - Get List')" class="col-12 col-sm-6 col-xl">
                        <multi-agents-picker v-model="filters.agents"/>
                    </div>
                    <div class="col-lg-3">
                        <atbl-form-group label="Created At"
                                         label-for="CreatedAt"
                                         :label-cols="3"
                                         class="font-weight-bold"
                                         :horizontal="false"
                        >
                            <multi-date-picker v-model="selectedCreatedAt"
                                               :format="datePickersOptions.format"
                                               :show-ranges="true"
                                               :auto-apply="true"
                                               direction="left"
                            />
                        </atbl-form-group>
                    </div>
                </div>
            </div>
            
            <div class="card-footer d-flex justify-content-between align-items-center">
                <div>
                    <button type="submit"
                              class="btn btn-sm btn-primary"
                    >
                        <i class="fa fa-search"></i> Search
                    </button>
                    <button type="reset"
                              class="btn btn-sm btn-danger"
                              @click="clearFilters"
                    >
                        <i class="fa fa-ban"></i> Clear
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import moment from 'moment';
import DateOptions from '../../../../helpers/Date';
import QueryToObject from '../../../../utils/Url/QueryToObject';
import {pick} from 'lodash';
import {clone} from '../../../../utils/Json/Json';
import TicketSearchModel from './TicketSearchModel';

export default {
    name: 'ticket-search',
    emits: [
        'search',
        'clear',
    ],
    data() {
        return {
            datePickersOptions: {
                format: 'YYYY-MM-DD',
                firstDay: 1,
            },
            filters: clone(TicketSearchModel),
        };
    },
    computed: {
        balanceSelect: {
            get() {
                return {
                    winnings: this.filters.winnings,
                    winningsSelector: this.filters.winningsSelector
                };
            },
            set(value) {
                Object.assign(this.filters, value);
            }
        },
        selectedDates: {
            get() {
                if (!this.filters.dateFrom && !this.filters.dateTo) {
                    return null;
                }
                return {
                    startDate: this.filters.dateFrom,
                    endDate: this.filters.dateTo
                };
            },
            set(value) {
                const {startDate, endDate} = value;
                
                Object.assign(this.filters, {
                    dateFrom: !!startDate
                      ? moment(startDate).format(DateOptions.defaultDate)
                      : null,
                    dateTo: !!endDate
                      ? moment(endDate).format(DateOptions.defaultDate)
                      : null
                });
            }
        },
        selectedCreatedAt: {
            get() {
                if (!this.filters.createdAtFrom && !this.filters.createdAtTo) {
                    return null;
                }
                return {
                    startDate: this.filters.createdAtFrom,
                    endDate: this.filters.createdAtTo
                };
            },
            set(value) {
                const {startDate, endDate} = value;
                
                Object.assign(this.filters, {
                    createdAtFrom: !!startDate
                      ? moment(startDate).format(DateOptions.defaultDate)
                      : null,
                    createdAtTo: !!endDate
                      ? moment(endDate).format(DateOptions.defaultDate)
                      : null
                });
            }
        }
    },
    created() {
        this.initializeSearchForm();
    },
    methods: {
        initializeSearchForm() {
            const searchParams = QueryToObject();
            const filters = pick(searchParams, Object.keys(this.filters));
            const otherParams = pick(searchParams, ['sort', 'paginate', 'page']);
            
            if (this.isObjectEmpty(filters) && this.isObjectEmpty(otherParams)) {
                return;
            }
            
            Object.assign(this.filters, filters);
            
            this.search({
                filters,
                otherParams
            });
        },
        search(data) {
            const model = data instanceof Event
              ? this.filters
              : data;
            
            this.$emit('search', model);
        },
        clearFilters() {
            this.filters = clone(TicketSearchModel);
            this.$emit('clear');
        },
    },
};
</script>
