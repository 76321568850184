var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "card card-list" }, [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex justify-content-between align-items-center"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.$can("Bonuses - Create/Update")
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  on: { click: _vm.navigateToCreateBonus }
                },
                [_vm._v("\n                Create\n            ")]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col text-muted" }, [
                _vm._v("Reference")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-muted" }, [_vm._v("Title")]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-muted" }, [_vm._v("Trigger")]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-muted" }, [
                _vm._v("Number of actions")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-muted" }, [
                _vm._v("Expires at")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-muted" }, [
                _vm._v("Duration "),
                _c("i", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Duration after bonus is assigned to player"
                      },
                      expression:
                        "{content: 'Duration after bonus is assigned to player'}"
                    }
                  ],
                  staticClass: "fa fa-info-circle"
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-muted text-center" }, [
                _vm._v("Action")
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.bonuses, function(bonus) {
              return [
                _c("div", { key: "bonus_" + bonus.id, staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(bonus.uuid) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(bonus.title) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(bonus.trigger) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(bonus.actions.length) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(bonus.expires_at || "-") +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(bonus.duration || "-") +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-center" }, [
                    _vm.$can("Bonuses - Create/Update")
                      ? _c(
                          "a",
                          {
                            staticClass: "d-block",
                            attrs: { href: "/bonuses/" + bonus.id + "/edit" }
                          },
                          [_vm._m(1, true)]
                        )
                      : _vm._e()
                  ])
                ])
              ]
            }),
            _vm._v(" "),
            !_vm.isLoading && !_vm.bonuses.length
              ? _c("not-found", {
                  attrs: { msg: "No bonuses found", items: _vm.bonuses }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-exchange-alt" }),
      _vm._v(" Bonuses\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" Edit bonus")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }