export default {
    methods: {
        gameHasNumbers: function(){
            return this.isset(this.game, "numbers");
        },
        getNumberConfiguration: function(){
            if(this.gameHasNumbers()){
                let gameNumbersConfiguration = this.game.numbers,
                    numbers = {};

                for(let item in gameNumbersConfiguration){
                    let currentItem = gameNumbersConfiguration[item],
                        key = null;

                    if(Array.isArray(currentItem)){
                        for(let numberItem in currentItem){
                            let currentNumberItem = currentItem[numberItem];

                            Object.assign(numbers, {
                                [currentNumberItem.key]: currentNumberItem
                            });
                        }
                    }else {
                        if(this.isset(currentItem, "key")){
                            key = currentItem.key;
                        }else {
                            key = item;
                        }

                        Object.assign(numbers, {
                            [key]: currentItem
                        });
                    }
                }

                return numbers;
            }
            return null;
        },
        getNumberTypeConfiguration: function(type){
            let numbersConfiguration = this.getNumberConfiguration();
            return numbersConfiguration && this.isset(numbersConfiguration, type) ? numbersConfiguration[type] : null;
        },
        createLine: function(random = true, numbers = null){
            if(this.gameHasNumbers()){
                let numbersKeys = Object.keys(this.game.numbers),
                    numbersObj = {};

                if(numbers) {
                    return {
                        random,
                        numbers
                    };
                }else {
                    if(numbersKeys.length > 0){
                        for(let item in numbersKeys){
                            let currentKey = numbersKeys[item],
                                getNumbersConfiguration = this.game.numbers[currentKey],
                                resolveNumbersConfigurations = Array.isArray(getNumbersConfiguration) ?
                                    getNumbersConfiguration.filter(item => item.active && !item.generated) :
                                    getNumbersConfiguration;
                                numbers = null;

                            if(Array.isArray(resolveNumbersConfigurations)){
                                for(let numberItem in resolveNumbersConfigurations){
                                    let currentNumberItem = resolveNumbersConfigurations[numberItem];

                                    Object.assign(numbersObj, {
                                        [currentNumberItem.key]: currentNumberItem.numbers > 1 ? [] : ""
                                    });
                                }
                            }else {
                                numbers = resolveNumbersConfigurations.numbers > 1 ? [] : ""

                                Object.assign(numbersObj, {
                                    [currentKey]: numbers
                                });
                            }
                        }

                        return {
                            random,
                            numbers: numbersObj,
                            error: {
                                invalid: false,
                                messages: []
                            }
                        };
                    }
                }
            }
            return null;
        },
        linesTranslator: function(lines){
            let translatorLines = JSON.parse(JSON.stringify(lines)),
                translatedLines = [];

            for(let line in translatorLines){
                let currentLine = translatorLines[line],
                    lineNumbers = currentLine.numbers;

                if(currentLine.random){
                    delete currentLine.numbers;
                }else {
                    for(let type in lineNumbers){
                        let currentLineNumbers = lineNumbers[type],
                            getNumberTypeConfiguration = this.getNumberTypeConfiguration(type);

                        if(type !== "main" && this.isset(getNumberTypeConfiguration, "key")){
                            lineNumbers["extra"] = currentLineNumbers;
                            delete lineNumbers[type];
                        }
                    }
                }

                delete currentLine.error;

                translatedLines = [
                    ...translatedLines,
                    currentLine
                ];
            }

            return translatedLines;
        },
        createMassLines: function(linesNumber){
            let lines = [];

            for (let i = 0; i < linesNumber; i++){
                lines = [
                    ...lines,
                    this.createLine()
                ];
            }

            return lines;
        }
    }
};