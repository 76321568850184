var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["atbl-dropdown", { disabled: !!_vm.disabled, sm: !!_vm.small }] },
    [
      !!_vm.isPositionedTop
        ? _c(
            "div",
            {
              class: [
                "atbl-dropdown-content",
                { show: _vm.isComponentOpen },
                _vm.position
              ]
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.$slots.header
        ? [
            _c(
              "button",
              {
                class: ["atbl-dropdown-header", _vm.inputStyle],
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.toggleDropdown.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.headerText))]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa",
                  class: {
                    "fa-caret-down": !_vm.isComponentOpen,
                    "fa-caret-up": !!_vm.isComponentOpen
                  }
                })
              ]
            )
          ]
        : _c(
            "div",
            {
              class: ["atbl-dropdown-header", _vm.inputStyle],
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.toggleDropdown.apply(null, arguments)
                }
              }
            },
            [_vm._t("header")],
            2
          ),
      _vm._v(" "),
      !_vm.isPositionedTop
        ? _c(
            "div",
            {
              class: [
                "atbl-dropdown-content",
                { show: _vm.isComponentOpen },
                _vm.position
              ]
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }