var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: { horizontal: _vm.horizontal, label: "Tags", "label-for": "tags" }
    },
    [
      _c("tree-select", {
        attrs: {
          multiple: true,
          searchable: true,
          "clear-on-select": true,
          options: _vm.tags,
          normalizer: function(node) {
            return { id: node.id, label: node.name }
          },
          disabled: !!_vm.disabled || !!_vm.isLoading,
          placeholder: "" + (!!_vm.isLoading ? "Loading..." : "Select options"),
          id: "tags"
        },
        model: {
          value: _vm.selectedTags,
          callback: function($$v) {
            _vm.selectedTags = $$v
          },
          expression: "selectedTags"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }