<template>
    <atbl-modal title="Remap Preview"
                :value="isOpen"
                :loader="!!isLoading"
                @close="toggleRemapPlayers"
    >
        <template v-if="!!errors.length">
            <div v-for="(error, index) in errors"
                 :key="index"
                 class="alert alert-danger"
            >
                {{ error }}
            </div>
        </template>

        <h4 class="text-center">
            Remap players from <u>{{ model.dateFrom }}</u> to <u>{{ model.dateTo }}</u>.
        </h4>

        <template #footer>
            <button :disabled="!!isLoading"
                    class="btn btn-sm btn-danger"
                    @click="toggleRemapPlayers"
            >
                Close
            </button>
            <button class="btn btn-sm btn-success"
                    @click="remapPlayers"
            >
                <span v-if="!isLoading">
                    Remap
                </span>
                <loader :show="!!isLoading"
                        type="small"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../components/AtblModal/AtblModal.vue';
import Loader from '../../../components/Widgets/Loader.vue';
import RemapModel from './RemapModel';

export default {
    name: 'confirm-remap-component',
    inject: [
        'remapPlayers',
        'toggleRemapPlayers',
    ],
    components: {
        AtblModal,
        Loader,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        model: {
            type: Object,
            default: () => (RemapModel),
        },
        errors: {
            type: Array,
            default: () => ([]),
        },
    },
}
</script>