var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { class: ["badge", "badge-" + _vm.variant] }, [
    _c("b", [
      _vm._v(
        _vm._s(_vm.status) +
          _vm._s(_vm.declineReason ? " - " + _vm.declineReason : "")
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }