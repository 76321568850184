var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: { title: "Cancel Edit", show: _vm.showModal },
      on: { close: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  on: { click: _vm.handleCancel }
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  on: { click: _vm.handleOK }
                },
                [_vm._v("\n            Save\n        ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("h3", [
            _vm._v("Are you sure you want to cancel this configuration edit?")
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }