var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: { id: _vm.id },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("i", { staticClass: "fa fa-tag mr-2" }),
                  _vm._v(" Free Games\n            "),
                  !_vm.userFreeGamesSettingsLoader &&
                  !!_vm.userFreeGamesSettings.can_create_free_game &&
                  !!_vm.userFreeGamesSettings.tickets.total_can_be_created
                    ? _c("span", { staticClass: "ml-2" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.userFreeGamesSettings.tickets.created) +
                            " / " +
                            _vm._s(
                              _vm.userFreeGamesSettings.tickets
                                .total_can_be_created
                            ) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    staticClass: "ml-2",
                    attrs: {
                      show: _vm.userFreeGamesSettingsLoader,
                      type: "smaller"
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "rightSide",
          fn: function() {
            return [
              !_vm.userFreeGamesSettingsLoader
                ? _c("div", [
                    !!_vm.userFreeGamesSettings.can_create_free_game
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "d-flex align-items-center btn btn-primary btn-sm",
                            attrs: {
                              disabled: _vm.userFreeGamesSettingsLoader
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.toggleCreateUpdateForm.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-plus m-0 mr-2",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(" Add\n            ")
                          ]
                        )
                      : _c("span", [
                          _c("i", { staticClass: "fa fa-ban text-danger" }),
                          _vm._v(" Cannot create free games.\n            ")
                        ])
                  ])
                : _c(
                    "div",
                    [
                      _c("loader", {
                        staticClass: "ml-2",
                        attrs: { show: true, type: "smaller" }
                      })
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-1 text-center text-lg-left" },
              [_vm._v("ID")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
              _vm._v("Created By")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg text-center" }, [
              _vm._v("Game")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
              _vm._v("Tickets")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
              _vm._v("Expires At")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg text-center" }, [
              _vm._v("Is Used")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
              _vm._v("Operations")
            ])
          ]),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.freeGames, function(item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-1 text-center text-lg-left mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge m-0" }, [
                        _vm._v("ID")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.id) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-2 text-center mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge m-0" }, [
                        _vm._v("Created By")
                      ]),
                      _vm._v(" "),
                      _vm.isset(item, "user") && item.user
                        ? _c("span", [_vm._v(_vm._s(item.user.name))])
                        : _c("span", { staticClass: "badge badge-danger" }, [
                            _vm._v(
                              "\n                        System\n                    "
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg text-center mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge m-0" }, [
                        _vm._v("Game")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            item.gameable.name
                              ? item.gameable.name
                              : item.gameable.title
                          ) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-2 text-center mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Tickets")
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Number of used tickets"
                              },
                              expression:
                                "{\n                            content: 'Number of used tickets'\n                          }"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.number_of_tickets_used) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v("\n                    /\n                    "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Number of total"
                              },
                              expression:
                                "{\n                            content: 'Number of total'\n                          }"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.number_of_tickets) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-2 text-center mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge m-0" }, [
                        _vm._v("Expires At")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(!!item.expires_at ? item.expires_at : "/") +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg text-center mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge m-0" }, [
                        _vm._v("Is Used")
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa",
                        class: {
                          "fa-check text-success": !!item.is_used,
                          "fa-times text-danger": !item.is_used
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
                    _c("span", { staticClass: "small-mb-badge m-0" }, [
                      _vm._v("Operations")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link btn-delete btn-sm mr-2",
                            on: {
                              click: function($event) {
                                return _vm.previewFreeGame(item)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-eye" })]
                        ),
                        _vm._v(" "),
                        _vm.$can("Player - Free Games Delete") &&
                        !item.number_of_tickets_used
                          ? _c("confirmable-remove-button", {
                              attrs: { value: item.id },
                              on: { confirmed: _vm.removeFreeGame }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.freeGames, msg: "No free games found" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        2
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        !_vm.isLoading && _vm.freeGamesData.last_page > 1
          ? _c(
              "nav",
              { staticClass: "wrap" },
              [
                _c("atbl-pagination", {
                  attrs: { pagination: _vm.freeGamesData },
                  on: { paginate: _vm.fetchFreeGames }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("create-update-free-game-form", {
        attrs: {
          "is-loading": _vm.isCreateUpdateLoading,
          "is-game-loading": _vm.isGameLoading,
          show: _vm.isCreateUpdateFormShown,
          configuration: _vm.createUpdateFormConfiguration,
          "is-save-allowed": _vm.isSaveAllowed,
          errors: _vm.freeGameModelErrors,
          "user-free-game-settings": _vm.userFreeGamesSettings
        }
      }),
      _vm._v(" "),
      _c("preview-free-game-sidebar", {
        attrs: {
          data: _vm.freeGamePreviewData,
          "is-delete-pressed": _vm.isDeletePressed,
          "is-loading": _vm.isLoading,
          "can-create-free-game": _vm.userFreeGamesSettings.can_create_free_game
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }