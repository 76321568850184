var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body ticket-lines-card-body" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "row leads-section-list-row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0"
          },
          [
            _c("span", { staticClass: "small-mb-badge" }, [_vm._v("#Line")]),
            _vm._v(
              "\n                #L-" + _vm._s(_vm.ticket.id) + "\n            "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0"
          },
          [
            _c("span", { staticClass: "small-mb-badge" }, [
              _vm._v("Reference")
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "d-block",
                attrs: { href: "/raffle-tickets/" + _vm.ticket.id }
              },
              [_vm._v("Ticket #" + _vm._s(_vm.ticket.id))]
            ),
            _vm._v(" "),
            _c("a", { attrs: { href: "/orders/" + _vm.ticket.order_id } }, [
              _vm._v("Order #" + _vm._s(_vm.ticket.order_id))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0"
          },
          [
            _c("span", { staticClass: "small-mb-badge" }, [
              _vm._v("Draw Date")
            ]),
            _vm._v(
              "\n                " +
                _vm._s(_vm.ticket.draw_date || "") +
                "\n            "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-4 text-center align-self-center text-center text-lg-left mb-2 mb-lg-0"
          },
          [
            _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Numbers")]),
            _vm._v(" "),
            _c("div", { staticClass: "numbers-group" }, [
              _c("div", { staticClass: "numbers-group-wrapper" }, [
                _c("span", { staticClass: "mr-1 raffle bg-warning" }, [
                  _vm._v(_vm._s(_vm.ticketNumbers))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: { content: "Fractions" },
                      expression: "{content: 'Fractions'}"
                    }
                  ],
                  staticClass: "d-flex"
                },
                [
                  _c("span", { staticClass: "mr-1 fraction" }, [
                    _vm._v(_vm._s(_vm.fractions))
                  ])
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-lg-2 align-self-center text-center text-lg-left"
          },
          [
            _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Status")]),
            _vm._v(
              "\n                " +
                _vm._s(_vm.ticketWinStatus) +
                "\n            "
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center justify-content-between"
      },
      [
        _c("span", [
          _c("i", { staticClass: "fa fa-align-justify" }),
          _vm._v(" Ticket Lines")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row d-none d-lg-flex leads-section-list-row" },
      [
        _c("div", { staticClass: "col-sm-2 text-muted" }, [_vm._v("#Line")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2 text-muted" }, [
          _vm._v("Reference")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2 text-muted" }, [
          _vm._v("Draw Date")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-4 text-muted" }, [_vm._v("Numbers")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2 text-muted" }, [_vm._v("Status")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }