var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      attrs: {
        title: "Preview Free Game",
        value: _vm.show,
        "body-class": "" + (_vm.show ? "pt-0" : "")
      },
      on: {
        input: function($event) {
          return _vm.previewFreeGame({})
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-outline-danger mr-2",
                  on: {
                    click: function($event) {
                      return _vm.previewFreeGame({})
                    }
                  }
                },
                [_vm._v("\n            Cancel\n        ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.show
        ? _c("div", [
            _c("div", { staticClass: "general-list" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("ID")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [_vm._v(_vm._s(_vm.data.id))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Created By")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm.isset(_vm.data, "user") && _vm.data.user
                    ? _c("span", [_vm._v(_vm._s(_vm.data.user.name))])
                    : _c("span", { staticClass: "badge badge-danger" }, [
                        _vm._v(
                          "\n                        System\n                    "
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Player")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/players/" + _vm.data.player.id + "/view",
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.data.player.name) +
                          " " +
                          _vm._s(_vm.data.player.surname) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Type")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$options.ResolveFreeGameType(
                        _vm.data.gameable_type,
                        _vm.data.gameable.key
                          ? _vm.data.gameable.key
                          : _vm.data.gameable.slug
                      )
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Game")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    _vm._s(
                      _vm.data.gameable.name
                        ? _vm.data.gameable.name
                        : _vm.data.gameable.title
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Tickets")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6 font-weight-bold" }, [
                      _vm._v(
                        "\n                            Total\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.data.number_of_tickets) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 font-weight-bold" }, [
                      _vm._v(
                        "\n                            Used\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.data.number_of_tickets_used) +
                          "\n                        "
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Is Used")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("i", {
                    staticClass: "fa",
                    class: {
                      "fa-check text-success": _vm.data.is_used,
                      "fa-times text-danger": !_vm.data.is_used
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Meta")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  _vm._l(_vm.data.meta, function(item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-6 font-weight-bold" }, [
                        _vm._v(_vm._s(index))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _vm._v(_vm._s(item))
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Expires at")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    _vm._s(!!_vm.data.expires_at ? _vm.data.expires_at : "/")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col font-weight-bold" }, [
                  _vm._v("Created at")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(_vm._s(_vm.data.created_at))
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.$can("Player - Free Games Delete") &&
            !_vm.data.number_of_tickets_used
              ? _c(
                  "div",
                  { staticClass: "row mt-3" },
                  [
                    !_vm.isLoading
                      ? _c("div", { staticClass: "col" }, [
                          !_vm.isDeletePressed
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger btn-sm",
                                  on: { click: _vm.togglePreviewDelete }
                                },
                                [
                                  _vm._v(
                                    "\n                    Delete\n                "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isDeletePressed
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger btn-sm mr-2",
                                  on: { click: _vm.togglePreviewDelete }
                                },
                                [
                                  _vm._v(
                                    "\n                    Cancel\n                "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isDeletePressed
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-success btn-sm",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeFreeGame(_vm.data.id)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    Confirm\n                "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("loader", {
                      attrs: { show: _vm.isLoading, type: "small" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _c(
            "div",
            [
              _c("not-found", {
                attrs: { items: [], msg: "Preview Free Game" }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }