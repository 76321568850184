<template>
    <div class="container-fluid report-sales-days">
        <div class="card card-search">
            <div class="card-header">
                <i class="fa fa-search mr-1"></i> Search
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-8 col-lg-4">
                        <atbl-form-group label="Date range"
                                         :label-cols="3"
                                         :horizontal="false"
                                         class="font-weight-bold mb-0"
                        >
                            <multi-date-picker v-model="selectedCreatedDates"
                                               :show-ranges="true"
                                               :auto-apply="true"
                                               :clearable="isChanged"
                                               format="YYYY-MM-DD"
                            />
                        </atbl-form-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <a href="/reports/sales" class="mr-1">
                    <i class="fa fa-chevron-left"></i>
                </a>
                <i class="fa fa-pound-sign mr-1"></i> Sales ( {{ dateRange.startDate + ' - ' + dateRange.endDate }} )
            </div>
            
            <div class="card-body">
                <section class="sales general-list">
                    <div class="row sticky-element">
                        <div class="col">Day</div>
                        <div class="col">Items</div>
                        <div class="col">Deposits</div>
                        <div class="col">Dep. CRM</div>
                        <div class="col">Dep. Web</div>
                        <div class="col">Bonus</div>
                        <div class="col">Sales</div>
                        <div class="col">Winners</div>
                        <div class="col">Winnings</div>
                        <div class="col">Withdrawals</div>
                        <div class="col">Refunds</div>
                    </div>
                    
                    <template v-if="!isLoading && !!salesData.length">
                        <div v-for="(sale, index) in salesData"
                             :key="index"
                             class="row py-3"
                        >
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Day</span>
                                {{ sale.report_date }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Tickets</span>
                                <div>Tickets: {{ sale.tickets }}</div>
                                <div>Lines: {{ sale.lines }}</div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Deposits</span>
                                {{ sale.deposits | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Dep. CRM</span>
                                {{ sale.deposits_crm | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Dep. Web</span>
                                {{ sale.deposits_web | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Bonus</span>
                                {{ sale.bonus | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Total</span>
                                {{ sale.sales | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Winners</span>
                                {{ sale.winners }}
                                <div>Unique: {{ sale.unique_winners }}</div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Winnings</span>
                                {{ sale.winnings | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Withdrawals</span>
                                {{ sale.withdrawals | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Refunds</span>
                                {{ sale.refunds | formatCurrency }}
                            </div>
                        </div>
                    </template>
                    
                    <div class="row last" v-if="!isLoading && salesData.length">
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0 d-none d-lg-flex justify-content-end">
                            Total
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Items</span>
                            <div>Tickets: {{ total.tickets }}</div>
                            <div>Lines: {{ total.lines }}</div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Deposits</span>
                            {{ total.deposits | formatCurrency }}
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Dep. CRM</span>
                            {{ total.deposits_crm | formatCurrency }}
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Dep. Web</span>
                            {{ total.deposits_web | formatCurrency }}
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Bonus</span>
                            {{ total.bonus | formatCurrency }}
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Sales</span>
                            {{ total.sales | formatCurrency }}
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Winners</span>
                            {{ total.winners }}
                            <div>Unique: {{ total.unique_winners }}</div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Winnings</span>
                            {{ total.winnings | formatCurrency }}
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Withdrawals</span>
                            {{ total.withdrawals | formatCurrency }}
                        </div>
                        <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Total Refunds</span>
                            {{ total.refunds | formatCurrency }}
                        </div>
                    </div>
                    
                    <not-found v-if="!isLoading"
                               :items="salesData"
                               msg="No sales found."
                    />
                    <loader :show="isLoading"
                            type="small"
                    />
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import ReportsSalesService from '../../../services/ReportsSalesService';
    import moment from 'moment';
    import Date from '../../../helpers/Date';
    import MultiDatePicker from '../../../components/Forms/Select/MultiDatePicker.vue';
    
    export default {
        name: 'sales-days',
        components: {MultiDatePicker},
        props: {
            month: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                salesData: [],
                isLoading: true,
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            };
        },
        async created() {
            this.initializeDateRange();
            
            await this.getSalesDaysReports();
        },
        computed: {
            selectedCreatedDates: {
                get() {
                    return this.dateRange;
                },
                set({startDate, endDate}) {
                    if (!startDate && !endDate) {
                        this.initializeDateRange();
                    } else {
                        Object.assign(this.dateRange, {
                            startDate: moment(startDate).format(Date.defaultDate),
                            endDate: moment(endDate).format(Date.defaultDate),
                        });
                    }
                    
                    this.getSalesDaysReports();
                }
            },
            total() {
                const sales = this.salesData;
                
                const sumOf = (field, isFloat = false) => {
                    const arr = sales.map(s => isFloat ? parseFloat(s[field]) : parseInt(s[field]));
                    
                    return arr.reduce((a, b) => a + b, 0);
                };
                
                return {
                    tickets: sumOf('tickets'),
                    lines: sumOf('lines'),
                    deposits: parseFloat(sumOf('deposits', true)).toFixed(2),
                    deposits_crm: parseFloat(sumOf('deposits_crm', true)).toFixed(2),
                    deposits_web: parseFloat(sumOf('deposits_web', true)).toFixed(2),
                    bonus: parseFloat(sumOf('bonus', true)).toFixed(2),
                    sales: parseFloat(sumOf('sales', true)).toFixed(2),
                    winners: sumOf('winners'),
                    unique_winners: sumOf('unique_winners'),
                    winnings: parseFloat(sumOf('winnings', true)).toFixed(2),
                    withdrawals: parseFloat(sumOf('withdrawals', true)).toFixed(2),
                    refunds: parseFloat(sumOf('refunds', true)).toFixed(2),
                };
            },
            isChanged() {
                const initial = moment(this.month);
                const initialStartDate = initial.startOf('month').format(Date.defaultDate);
                const initialEndDate = initial.endOf('month').format(Date.defaultDate);

                return initialStartDate !== this.dateRange.startDate || initialEndDate !== this.dateRange.endDate;
            },
        },
        methods: {
            initializeDateRange() {
                const date = moment(this.month);
                
                this.dateRange.startDate = date.startOf('month').format(Date.defaultDate);
                this.dateRange.endDate = date.endOf('month').format(Date.defaultDate);
            },
            async getSalesDaysReports() {
                this.isLoading = true;
                
                try {
                    const {data} = await ReportsSalesService.getSalesDaysReports(
                      {
                          type: 'days',
                          startDate: this.dateRange.startDate,
                          endDate: this.dateRange.endDate,
                      }
                    );
                    
                    this.salesData = data;
                } catch (error) {
                    window.flashError(error);
                } finally {
                    this.isLoading = false;
                }
            },
        },
    };
</script>