<template>
    <div class="dashboard-deposits">
        <div class="dashboard-deposits-heading">
            <p class="dashboard-deposits-title"> Deposits </p>
        </div>
        <div class="row">
            <div class="col-12 col-xl-6">
                <statistic-card crmLabel="CRM"
                                :crmTotal="getStatisticsValue('crm', 'deposits')"
                                webLabel="WEB"
                                :webTotal="getStatisticsValue('web', 'deposits')"
                                class="mb-4 mb-xl-0"
                >
                    <i class="fa fa-money-bill"></i>
                    <p class="statistic-icon-label"> Daily </p>
                </statistic-card>
            </div>
            <div class="col-12 col-xl-6">
                <statistic-card bgColor="bg-twitter"
                                crmLabel="CRM"
                                :crmTotal="getStatisticsValue('crm', 'deposits', 'monthly')"
                                webLabel="WEB"
                                :webTotal="getStatisticsValue('web', 'deposits', 'monthly')"
                                class="mb-0"
                >
                    <i class="fa fa-chart-pie"></i>
                    <p class="statistic-icon-label"> Monthly </p>
                </statistic-card>
            </div>
        </div>
    </div>
</template>

<script>
import StatisticCard from '../Components/StatisticCard';

export default {
    name: 'sales',
    inject: [
        'getStatisticsValue',
    ],
    components: {
        StatisticCard
    },
};
</script>
