<template>
    <sidebar-sub title="Create/Update Email Template"
                 :value="show"
                 :loader="isLoading"
                 @input="toggleCreateUpdateForm"
                 class="desktop"
    >
        <loader :show="isLoading"
                type="small"
        />

        <div v-if="!isLoading">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <atbl-form-group label="Key"
                                     :error="getError(emailErrors, 'key')"
                    >
                        <atbl-input name="key"
                                    :value="emailModel.key"
                                    :disabled="isLoading"
                                    @update="updateModel"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-4">
                    <atbl-form-group label="Name"
                                     :error="getError(emailErrors, 'name')"
                    >
                        <atbl-input name="name"
                                    :value="emailModel.name"
                                    :disabled="isLoading"
                                    @update="updateModel"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-4">
                    <atbl-form-group label="Description"
                                     :error="getError(emailErrors, 'description')"
                    >
                        <atbl-input name="description"
                                    :value="emailModel.description"
                                    :disabled="isLoading"
                                    @update="updateModel"
                        />
                    </atbl-form-group>
                </div>
            </div>

            <fieldset>
                <legend>
                    Send email
                    <a href="#"
                       @click.prevent
                    >

                    </a>
                    <i class="fa fa-info-circle text-dark"
                       v-tooltip="{
                        content: 'These options enable/disable visibility for every email template for players in Send Mail modal in the player details view and Send Email in the campaigns call view page.'
                       }"
                    ></i>
                </legend>
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <atbl-form-group label="Visibile for players"
                                         :error="getError(emailErrors, 'players_enabled')"
                                         class="mb-0"
                        >
                            <atbl-switch name="players_enabled"
                                         :value="!!emailModel.players_enabled"
                                         :disabled="isLoading"
                                         :off="false"
                                         :on="true"
                                         @update="updateModel"
                            />
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-lg-4">
                        <atbl-form-group label="Visibile for leads"
                                         :error="getError(emailErrors, 'leads_enabled')"
                                         class="mb-0"
                        >
                            <atbl-switch name="leads_enabled"
                                         :value="!!emailModel.leads_enabled"
                                         :disabled="isLoading"
                                         :off="false"
                                         :on="true"
                                         @update="updateModel"
                            />
                        </atbl-form-group>
                    </div>
                </div>
            </fieldset>

            <user-type v-for="userType in userTypes"
                       :key="userType"
                       :type="userType"
            >
                <div class="row">
                    <div class="col-12 col-lg-4"
                         :class="{
                            'email-templates-internal-links' : emailModel.meta[userType].type === 'Internal'
                         }"
                    >
                        <atbl-form-group label="Type"
                                         :error="getError(emailErrors, 'type')"
                        >
                            <atbl-select :options="emailTypes"
                                         :value="emailModel.meta[userType].type"
                                         :disabled="isLoading"
                                         name="type"
                                         @update="updateType(userType, ...arguments)"
                            />
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-lg-4">
                        <atbl-form-group label="Source"
                                         :error="getError(emailErrors, 'type_source')"
                        >
                            <atbl-select :options="Object.keys(emailModel.meta[userType].externalTypes)"
                                         :value="emailModel.meta[userType].type_source"
                                         :disabled="isLoading || !emailModel.meta[userType].type || emailModel.meta[userType].type === 'Internal'"
                                         name="type_source"
                                         @update="updateSource(userType, ...arguments)"
                            />
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-lg-4">
                        <atbl-form-group label="Source Id"
                                         :error="getError(emailErrors, 'source_id')"
                        >

                            <atbl-input name="source_id"
                                        :value="emailModel.meta[userType].source_id"
                                        :disabled="isLoading"
                                        @update="updateUserTypeModel(userType, ...arguments)"
                            />
                        </atbl-form-group>
                    </div>
                    <div v-if="emailModel.meta[userType].type === 'Internal'"
                         class="email-templates-internal-links col-12 mb-3"
                    >
                        <div class="row">
                            <div v-for="(language, index) in languages"
                                 :key="index"
                                 class="col-12 col-lg-4"
                            >
                                <atbl-form-group :label="`Internal ${language.label.toLowerCase()} link`"
                                                 :error="getError(emailErrors, 'link')"
                                >

                                    <atbl-input :name="`link_${language.value}`"
                                                :value="emailModel.meta[userType].links[language.value]"
                                                :disabled="isLoading"
                                                :placeholder="`Enter ${language.label} link`"
                                                @update="updateUserTypeInternalLinkModel(userType, ...arguments)"
                                    />
                                </atbl-form-group>
                            </div>
                        </div>
                    </div>

                </div>

                <atbl-tabs variant="tabs"
                        :active-tab="activeSourceType"
                         header-classes="p-0 border-top-0 border-right-0 border-left-0 bg-transparent"
                        @change-tab="updateActiveSourceType"
                >
                    <atbl-tab v-for="(item, index) in emailModel.meta[userType].externalTypes"
                           :key="index"
                           :title="index"
                              class="bg-lighter"
                    >
                        <meta-fields :metas="item.meta"
                                     :source-type="index"
                                     :user-type="userType"
                                     :is-loading="isLoading"
                        />
                    </atbl-tab>
                </atbl-tabs>
            </user-type>
        </div>

        <template #footer>
            <button v-if="!isLoading"
                      class="btn btn-sm btn-outline-danger mr-2"
                      :disabled="isLoading"
                      @click="toggleCreateUpdateForm"
            >
                Cancel
            </button>
            <button class="btn btn-sm btn-primary"
                      :disabled="isLoading || !canSaveEmail"
                      v-if="$canAny(['Email Templates - Create', 'Email Templates - Update'])"
                      @click="onSaveEmailTemplate"
            >
                <span v-if="!isLoading">Save</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </sidebar-sub>
</template>

<script>
import MetaFields from './Components/MetaFields';
import UserType from './Components/UserType';
import AtblTabs from '../../../components/AtblTabs/AtblTabs.vue';
import AtblTab from '../../../components/AtblTabs/AtblTab.vue';

export default {
    name: 'CreateUpdateEmailTemplatesComponent',
    inject: [
        'toggleCreateUpdateForm',
        'onSaveEmailTemplate',
        'updateModel',
        'updateUserTypeModel',
        'updateUserTypeInternalLinkModel',
        'updateType',
        'updateSource',
        'updateActiveSourceType',
    ],
    components: {
        UserType,
        MetaFields,
        AtblTabs,
        AtblTab,
    },
    props: {
        emailTypes: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: false
        },
        emailModel: {
            type: Object,
            default: () => ({})
        },
        emailErrors: {
            type: Object,
            default: () => ({})
        },
        activeSourceType: {
            type: Number,
            default: 0
        },
        userTypes: {
            type: Array,
            default: () => ([])
        },
        languages: {
            type: Array,
            default: () => ([])
        },
    },
    computed: {
        canSaveEmail() {
            return !!this.emailModel.name && !!this.emailModel.key;
        },
    },
}
</script>