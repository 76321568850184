var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "players-list" },
    [
      _c("div", { staticClass: "players-list-mobile" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg col d-flex align-items-center" },
                [_vm._v("\n                     \n                ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-1" }, [
                _vm._v("\n                    Id\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-2" }, [
                _vm._v("\n                    Info\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _vm._v("\n                    Country\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _vm._v("\n                    Balance\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _vm._v("\n                    Live spent\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _vm._v("\n                    Total win\n                ")
              ]),
              _vm._v(" "),
              _vm.$can("Player - View Agent")
                ? _c("div", { staticClass: "col" }, [
                    _vm._v("\n                    Agent\n                ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$canAny([
                "Player - View Latest Call",
                "Player - View Latest Dispositions"
              ])
                ? _c("div", { staticClass: "col-2" }, [
                    _vm._v(
                      "\n                    Disposition\n                "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [_vm._v(" Operations")])
            ]),
            _vm._v(" "),
            !_vm.isLoading
              ? _vm._l(_vm.playersData.data, function(player, index) {
                  return _c("div", { key: index, staticClass: "row" }, [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-6 col-lg-3 align-self-start mc-2 players-list-row-id"
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "small-mc-badge-extended black" },
                          [_vm._v("Id")]
                        ),
                        _vm._v(
                          "\n                        " +
                            _vm._s(player.id) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3 align-self-start mc-2" },
                      [
                        _c(
                          "span",
                          { staticClass: "small-mc-badge-extended black" },
                          [_vm._v("Info")]
                        ),
                        _vm._v(
                          "\n                        " +
                            _vm._s(player.name) +
                            " " +
                            _vm._s(player.surname)
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("created-through", {
                          attrs: { "created-through": player.created_through }
                        }),
                        _vm._v(" "),
                        _c("player-kyc-status-badge", {
                          attrs: { "kyc-status": player.kyc_status }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                        " + _vm._s(player.email)
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("latest-transaction", { attrs: { player: player } }),
                        _vm._v(" "),
                        player.last_seen_at
                          ? _c(
                              "span",
                              { staticClass: "badge badge-warning p-1" },
                              [
                                _vm._v(
                                  "\n                            Last seen at: " +
                                    _vm._s(
                                      _vm._f("utcToLocal")(player.last_seen_at)
                                    ) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        player.tags.length > 0
                          ? _c(
                              "div",
                              { staticClass: "player-tags-container mt-3" },
                              _vm._l(player.tags, function(tag, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "player-tag primary"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(tag) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3 align-self-start mc-2" },
                      [
                        _c(
                          "span",
                          { staticClass: "small-mc-badge-extended black" },
                          [_vm._v("Country")]
                        ),
                        _vm._v(
                          "\n                        " +
                            _vm._s(player.country) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3 align-self-start mc-2" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "small-mc-badge-extended inline black"
                          },
                          [_vm._v("Balance")]
                        ),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                (player.amount ? player.amount : 0) +
                                  (player.bonus_amount
                                    ? player.bonus_amount
                                    : 0)
                              )
                            ) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3 align-self-start mc-2" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "small-mc-badge-extended inline black"
                          },
                          [_vm._v("Live spent")]
                        ),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("formatCurrency")(player.live_spent)
                            ) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3 align-self-start mc-2" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "small-mc-badge-extended inline black"
                          },
                          [_vm._v("Total win")]
                        ),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm._f("formatCurrency")(player.total_win)) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.$can("Player - View Agent")
                      ? _c(
                          "div",
                          {
                            staticClass: "col-6 col-lg-3 align-self-start mc-2"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "small-mc-badge-extended inline black"
                              },
                              [_vm._v("Agent")]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(player.agent_name) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$canAny([
                      "Player - View Latest Call",
                      "Player - View Latest Dispositions"
                    ])
                      ? _c(
                          "div",
                          {
                            staticClass: "col-6 col-lg-3 align-self-start mc-2"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "small-mc-badge-extended black" },
                              [_vm._v("Last contact")]
                            ),
                            _vm._v(" "),
                            _vm.$can("Player - View Latest Call")
                              ? _c("latest-call", { attrs: { player: player } })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$can("Player - View Latest Dispositions")
                              ? _c("latest-disposition", {
                                  attrs: { player: player }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3 align-self-start mc-2" },
                      [
                        _c(
                          "span",
                          { staticClass: "small-mc-badge-extended black" },
                          [_vm._v("Operations")]
                        ),
                        _vm._v(" "),
                        _vm.$can("Player - View Details")
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: player,
                                    expression: "player"
                                  }
                                ],
                                attrs: {
                                  href: "/players/" + player.id + "/view",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            View Player\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm._v(" "),
                        _vm.$can("Player - Assign Agent")
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: player,
                                    expression: "player"
                                  }
                                ],
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onAssignAgentEvent(player)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            Agent\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm._v(" "),
                        _vm.$can("Player - Call") && _vm.playerHasPhone(player)
                          ? _c(
                              "div",
                              { staticClass: "d-inline-block" },
                              [
                                player.mobile && player.phone
                                  ? _c(
                                      "c-dropdown",
                                      {
                                        attrs: {
                                          id: "callNowDesktop",
                                          "toggler-text": "Call",
                                          size: "sm",
                                          right: "",
                                          color: "link"
                                        }
                                      },
                                      [
                                        _c(
                                          "c-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.callPlayer(
                                                  player.mobile,
                                                  player.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(player.mobile) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "c-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.callPlayer(
                                                  player.phone,
                                                  player.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(player.phone) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: player,
                                            expression: "player"
                                          }
                                        ],
                                        attrs: {
                                          href: "#",
                                          size: "sm",
                                          color: "btn btn-outline-primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.callPlayer(
                                              player.mobile
                                                ? player.mobile
                                                : player.phone,
                                              player.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Call\n                            "
                                        )
                                      ]
                                    )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "players-list-desktop" }, [
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-borderless m-0" }, [
            _c("thead", [
              _c(
                "tr",
                {
                  staticClass: "v-sortable",
                  on: { sort: _vm.onUpdateSortEvent }
                },
                [
                  _c(
                    "th",
                    { staticClass: "check-box-input", attrs: { scope: "col" } },
                    [
                      _c("atbl-checkbox", {
                        attrs: {
                          "partial-check": _vm.partialChecked,
                          value: _vm.currentPageSelected
                        },
                        on: { input: _vm.onAddAllFromCurrentPageToBulkEvent }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "sort",
                          rawName: "v-sort:id",
                          value: _vm.sort,
                          expression: "sort",
                          arg: "id"
                        }
                      ],
                      attrs: { scope: "col" }
                    },
                    [_vm._v("Id")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "sort",
                          rawName: "v-sort:transaction_created_at",
                          value: _vm.sort,
                          expression: "sort",
                          arg: "transaction_created_at"
                        }
                      ],
                      attrs: { scope: "col" }
                    },
                    [_vm._v("Info")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "sort",
                          rawName: "v-sort:country",
                          value: _vm.sort,
                          expression: "sort",
                          arg: "country"
                        }
                      ],
                      attrs: { scope: "col" }
                    },
                    [_vm._v("Country")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "sort",
                          rawName: "v-sort:wallet_total_balance",
                          value: _vm.sort,
                          expression: "sort",
                          arg: "wallet_total_balance"
                        }
                      ],
                      attrs: { scope: "col" }
                    },
                    [_vm._v("Balance")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "sort",
                          rawName: "v-sort:live_spent",
                          value: _vm.sort,
                          expression: "sort",
                          arg: "live_spent"
                        }
                      ],
                      attrs: { scope: "col" }
                    },
                    [_vm._v("Live spent")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "sort",
                          rawName: "v-sort:total_win",
                          value: _vm.sort,
                          expression: "sort",
                          arg: "total_win"
                        }
                      ],
                      attrs: { scope: "col" }
                    },
                    [_vm._v("Total win")]
                  ),
                  _vm._v(" "),
                  _vm.$can("Player - View Agent")
                    ? _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:agent_name",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "agent_name"
                            }
                          ],
                          attrs: { scope: "col" }
                        },
                        [
                          _vm._v(
                            "\n                            Agent\n                        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$canAny([
                    "Player - View Latest Call",
                    "Player - View Latest Dispositions"
                  ])
                    ? _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:call_created_at",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "call_created_at"
                            }
                          ],
                          attrs: { scope: "col" }
                        },
                        [
                          _vm._v(
                            "\n                            Disposition\n                        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Operations")])
                ]
              )
            ]),
            _vm._v(" "),
            !_vm.isLoading
              ? _c(
                  "tbody",
                  _vm._l(_vm.playersData.data, function(player, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "th",
                        {
                          staticClass: "check-box-input",
                          attrs: { scope: "row" }
                        },
                        [
                          _c("atbl-checkbox", {
                            attrs: {
                              value: _vm.playersBulk.includes(player.id)
                            },
                            on: {
                              input: function($event) {
                                return _vm.onAddBulkEvent(player.id)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(player.id) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(player.name) +
                              " " +
                              _vm._s(player.surname) +
                              "\n                            "
                          ),
                          _c("created-through", {
                            attrs: { "created-through": player.created_through }
                          }),
                          _vm._v(" "),
                          _c("player-kyc-status-badge", {
                            attrs: { "kyc-status": player.kyc_status }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                            " +
                              _vm._s(player.email)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("latest-transaction", {
                            attrs: { player: player }
                          }),
                          _vm._v(" "),
                          player.last_seen_at
                            ? _c(
                                "span",
                                { staticClass: "badge badge-warning p-1" },
                                [
                                  _vm._v(
                                    "\n                                Last seen at: " +
                                      _vm._s(
                                        _vm._f("utcToLocal")(
                                          player.last_seen_at
                                        )
                                      ) +
                                      "\n                            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          player.tags.length > 0
                            ? _c(
                                "div",
                                { staticClass: "player-tags-container mt-3" },
                                _vm._l(player.tags, function(tag, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "player-tag primary"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(tag) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(player.country) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "span",
                          { staticClass: "text-big font-weight-bold d-block" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    player.wallet_total_balance
                                  )
                                ) +
                                "\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("small", { staticClass: "text-danger d-block" }, [
                          _vm._v(
                            "\n                                Real: " +
                              _vm._s(
                                _vm._f("formatCurrency")(player.wallet_amount)
                              ) +
                              "\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("small", { staticClass: "text-warning d-block" }, [
                          _vm._v(
                            "\n                                Bonus: " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  player.wallet_bonus_amount
                                )
                              ) +
                              "\n                            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("formatCurrency")(player.live_spent)
                            ) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm._f("formatCurrency")(player.total_win)) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$can("Player - View Agent")
                        ? _c("td", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.emptyField(player.agent_name)) +
                                "\n                        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$canAny([
                        "Player - View Latest Call",
                        "Player - View Latest Dispositions"
                      ])
                        ? _c(
                            "td",
                            [
                              _vm.$can("Player - View Latest Call")
                                ? _c("latest-call", {
                                    attrs: { player: player }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$can("Player - View Latest Dispositions")
                                ? _c("latest-disposition", {
                                    attrs: { player: player }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("td", [
                        _vm.$can("Player - View Details")
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: player,
                                    expression: "player"
                                  }
                                ],
                                attrs: {
                                  href: "/players/" + player.id + "/view",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                View Player\n                            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm._v(" "),
                        _vm.$can("Player - Assign Agent")
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: player,
                                    expression: "player"
                                  }
                                ],
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onAssignAgentEvent(player)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                Agent\n                            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm._v(" "),
                        _vm.$can("Player - Call") && _vm.playerHasPhone(player)
                          ? _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                player.mobile && player.phone
                                  ? _c(
                                      "c-dropdown",
                                      {
                                        staticClass: "call-dropdown",
                                        attrs: {
                                          id: "callNow",
                                          "toggler-text": "Call",
                                          right: "",
                                          color: "link"
                                        }
                                      },
                                      [
                                        _c(
                                          "c-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.callPlayer(
                                                  player.mobile,
                                                  player.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(player.mobile) +
                                                "\n                                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "c-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.callPlayer(
                                                  player.phone,
                                                  player.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(player.phone) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: player,
                                            expression: "player"
                                          }
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.callPlayer(
                                              player.mobile
                                                ? player.mobile
                                                : player.phone,
                                              player.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Call\n                                "
                                        )
                                      ]
                                    )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("not-found", {
            staticClass: "py-2",
            attrs: { items: _vm.playersData.data, msg: "No players found." }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.playersData.last_page > 1
        ? _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-between"
              },
              [
                _c(
                  "nav",
                  { staticClass: "wrap" },
                  [
                    _c("atbl-pagination", {
                      attrs: { pagination: _vm.playersData, scroll: false },
                      on: { paginate: _vm.onPageChangeEvent }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$scrollToTop()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-caret-up mr-2" }),
                    _vm._v(" Back to top\n            ")
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 col-lg-3 align-self-start mc-2" }, [
      _c("span", { staticClass: "small-mc-badge-extended black" }, [
        _vm._v("Action")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }