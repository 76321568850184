/**
 * Check if value is object
 * @param v
 * @return {boolean}
 */
export function isObject(v) {
    return typeof v === 'object' && !Array.isArray(v) && v !== null;
}

/**
 * Check if object is empty
 * @param obj
 * @return {boolean}
 */
export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Check if property contains key
 * @param object
 * @param key
 * @return {boolean}
 */
export function isset(object, key){
    return object.hasOwnProperty(key);
}