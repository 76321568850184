<template>
    <div class="card statistic-card">
        <div class="card-body" :class="bgColor">
            <div class="statistic-icon">
                <slot></slot>
            </div>
            <div class="statistic-inner">
                <div class="statistic-crm">
                    <div class="statistic-crm-label">
                        {{ crmLabel }}
                    </div>
                    <div class="statistic-crm-amount">
                        {{ crmTotal }}
                    </div>
                </div>
                <div class="statistic-web">
                    <div class="statistic-web-label">
                        {{ webLabel }}
                    </div>
                    <div class="statistic-web-amount">
                        {{ webTotal }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bgColor: {
            default: 'bg-facebook'
        },
        crmLabel: {
            required: true
        },
        webLabel: {
            required: true
        },
        crmTotal: {
            required: true
        },
        webTotal: {
            required: true
        }
    },
    name: "statistic-card"
}
</script>