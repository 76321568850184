var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$canAny(_vm.permissions)
    ? _c(
        "c-sidebar-nav-dropdown",
        {
          class: [
            _vm.isRouteActive(_vm.routes, "c-show"),
            { "c-show": !!_vm.isMarketingDropdownActive }
          ],
          attrs: { name: "Marketing", href: "#", fontIcon: "fa fa-rocket" }
        },
        [
          _vm.$can("Campaigns - View Campaigns")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  addLinkClasses:
                    "" + (_vm.isLeadsCampaignsActive ? "c-active" : null),
                  name: "Campaigns Leads",
                  fontIcon: "fa fa-bullhorn",
                  href: "/marketing/campaigns/lead"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Campaigns - View Campaigns")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  addLinkClasses:
                    "" + (_vm.isPlayersCampaignsActive ? "c-active" : null),
                  name: "Campaigns Players",
                  fontIcon: "fa fa-bullhorn",
                  href: "/marketing/campaigns/player"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Leads - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  addLinkClasses: _vm.isRouteActive([
                    "campaigns.leads",
                    "campaigns.leads.show.single.view"
                  ]),
                  name: "Leads",
                  fontIcon: "fa fa-users",
                  href: "/marketing/leads"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Leads Statuses - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  addLinkClasses: _vm.isRouteActive("campaigns.statuses.view"),
                  name: "Leads Statuses",
                  fontIcon: "fa fa-clock",
                  href: "/marketing/statuses"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }