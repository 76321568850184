<template>
    <player-layout :id="id"
                   title="Messages"
                   icon="fa fa-envelope"
    >
        <template slot="rightSide">
            <button class="btn btn-sm btn-primary"
                      @click="onSendEmailComponentOpenEvent"
            >
                Send Email
            </button>
        </template>

        <div class="general-list">
            <div class="row d-none d-xl-flex">
                <div class="col-12 col-lg status-column text-center">Status</div>
                <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0">Agent</div>
                <div class="col">Name</div>
                <div class="col">Description</div>
                <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0">Date</div>
            </div>

            <template v-if="!isLoading">
                <div v-for="(item, index) in messages.data"
                       :key="index"
                       class="row"
                >
                    <div class="status-column col-12 col-lg-6 col-xl mb-2 mb-lg-0 text-center">
                        <i class="fa fa-lg"
                           :class="{
                           'fa-envelope-open': item.is_read,
                           'fa-envelope': !item.is_read
                       }"
                           v-tooltip="{
                           content: `${item.is_read ? 'Read' : 'Unread'}`
                       }"
                        ></i>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0">
                        <span class="small-mb-badge-extended">Agent</span>
                        {{ item.user.name }}
                    </div>
                    <div class="col-12 col-lg-6 col-xl mb-2 mb-lg-0">
                        <span class="small-mb-badge-extended">Template Name</span>
                        {{ item.template.name }}
                    </div>
                    <div class="description-column col-12 col-lg-6 col-xl mb-2 mb-lg-0">
                        <span class="small-mb-badge-extended">Template Description</span>
                        {{ item.template.description ? item.template.description.substr(0, 40) : '/' }}
                    </div>
                    <div class="col-12 col-lg-6 col-xl-2">
                        <span class="small-mb-badge-extended">Date</span>
                        {{ utcToLocal(item.created_at) }}
                    </div>
                </div>
            </template>

            <loader :show="isLoading"
                    type="small"
            />

            <not-found v-if="!isLoading"
                       msg="No messages found."
                       :items="messages.data"
            />
        </div>

        <template slot="footer">
            <nav class="wrap" v-if="!isLoading && messages.last_page > 1">
                <atbl-pagination :pagination="messages"
                                 @paginate="fetchPlayerMessages"
                />
            </nav>
        </template>
    </player-layout>
</template>

<script>
import SendEmailConstants from '../../../../components/SendEmail/SendEmailConstants';

export default {
    props: {
        id: {}
    },
    data: function () {
        return {
            isLoading: false,
            messages: {
                last_page: null,
                data: []
            },
        }
    },
    methods: {
        async fetchPlayerMessages(page = 1) {
            try {
                const response = await window.axios.get(`/player/${this.id}/message/get`, {
                    params: {
                        page
                    }
                });

                this.messages = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        onSendEmailComponentOpenEvent() {
            window.Atbl.$emit(SendEmailConstants.key, true)
        },
    },
    async created() {
        await this.fetchPlayerMessages();
    }
}
</script>

<style lang="scss">
.status-column {
    @media (min-width : 1200px) {
        flex : 0 0 80px;
    }
}

.description-column {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}
</style>