var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    _vm._l(_vm.logs, function(log) {
      return _c("div", { key: "log_id_" + log.id, staticClass: "card" }, [
        _vm._m(0, true),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-fake-input", { attrs: { label: "ID" } }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(log.id) +
                      "\n                    "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-fake-input", { attrs: { label: "Message" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(log.message) +
                      "\n                  "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-fake-input", { attrs: { label: "Level" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(log.level) +
                      "\n                  "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-fake-input", { attrs: { label: "File" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(log.file) +
                      "\n                  "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-fake-input", { attrs: { label: "File line" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(log.file_line) +
                      "\n                  "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-fake-input", { attrs: { label: "Date time" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(log.date_time) +
                      "\n                  "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "atbl-form-group",
                  { attrs: { "label-size": "sm", label: "Trace" } },
                  [_c("pre", { domProps: { innerHTML: _vm._s(log.trace) } })]
                )
              ],
              1
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center justify-content-between"
      },
      [
        _c("span", [
          _c("i", { staticClass: "fa fa-align-justify" }),
          _vm._v("\n                Logs\n            ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }