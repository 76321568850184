import playersTypes from "./types/playersTypes";
import playersFundsTypes from "./types/playersFundsTypes";
import playersCallHistoryTypes from "./types/playersCallHistoryTypes";
import playersPlayTypes from "./types/playersPlayTypes";
import playersWalletManagerTypes from "./types/playersWalletManagerTypes";

export default {
    mutations: {
        ...playersTypes.mutations,
        ...playersFundsTypes.mutations,
        ...playersCallHistoryTypes.mutations,
        ...playersPlayTypes.mutations,
        ...playersWalletManagerTypes.mutations
    },
    getters: {
        ...playersTypes.getters,
        ...playersFundsTypes.getters,
        ...playersCallHistoryTypes.getters,
        ...playersPlayTypes.getters,
        ...playersWalletManagerTypes.getters
    },
    actions: {
        ...playersTypes.actions,
        ...playersFundsTypes.actions,
        ...playersCallHistoryTypes.actions,
        ...playersPlayTypes.actions,
    }
};
