var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "atbl-file-upload",
      attrs: {
        label: _vm.label,
        "label-for": _vm.getLabelFor,
        error: _vm.getError(_vm.allErrors, _vm.name),
        "label-class": _vm.labelClass
      }
    },
    [
      _c("input", {
        ref: "file",
        attrs: {
          id: _vm.getLabelFor,
          accept: _vm.accept
            .map(function(extension) {
              return "." + extension
            })
            .join(","),
          type: "file"
        },
        on: {
          change: function($event) {
            return _vm.onFileUpdateEvent(_vm.$refs.file.files[0])
          }
        }
      }),
      _vm._v(" "),
      !!_vm.value
        ? _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.update(null)
                }
              }
            },
            [_vm._v("\n        Clear\n    ")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }