import { Device } from '@twilio/voice-sdk';
import Campaigns from '../../../../../../constants/Campaigns';
import isString from '../../../../../../utils/String/IsString';

export default {
    data() {
        return {
            selectedNumber: null,
            callStarted: false,
            deviceConnection: null,
            isMuted: false,
            callLoader: false,
            originalPhoneNumber: null,
            numberToCall: null,
            isKeypadOpen: false,
            CallSid: null,
            callObject: null,
            isCallStarted: false,
            isCallAccepted: false,
            device: null,
            customerNumberToCall: null,
        };
    },
    methods: {
        async getToken() {
            const response = await window.axios.post('/twilio/token', {forPage: window.location.pathname});
            const { token } = response.data;

            return token;
        },
        async call(fromPhoneNumber, toPhoneNumber) {
            this.callLoader = true;

            const { id } = this.customer;

            try {
                this.log("Get token.");

                const token = await this.getToken();

                this.log("Token has been get successfully.", "alert-warning");

                this.device = new Device(token, {
                    debug: true,
                    codecPreferences: ['opus', 'pcmu']
                });

                this.log("Device has been initialized.");

                this.callLoader = false;

                this.isCallStarted = true;

                this.log(`Call connection start.`, 'alert-success');
                this.log(`Caller: ${fromPhoneNumber}; To: ${toPhoneNumber};`, 'alert-warning');

                this.callObject = await this.device.connect({
                    params: {
                        fromPhoneNumber,
                        toPhoneNumber,
                        caller_id: id,
                        caller_type: this.campaignType,
                        agent_id: this.agentId
                    }
                });

                this.callObject.on('accept', async call => {
                    const { parameters: { CallSid } } = call;

                    this.log(`Call has been accepted with CallSid: ${CallSid}.`, 'alert-success');

                    this.CallSid = CallSid;

                    this.isCallAccepted = true;
                });

                this.callObject.on('disconnect', async () => {
                    this.log(`Call has been disconnected.`, 'alert-success');

                    this.disconnectCall();
                });
            } catch (error) {
                this.callLoader = false;

                this.log(error, 'alert-danger');
            }
        },
        async onHandleCallEvent() {
            if (!this.selectedNumber) {
                return 'Select number';
            }

            if (this.isCallStarted) {
                this.hangCall();

                return;
            }

            (this.campaign.view_type === Campaigns.ViewTypes.listView)
                ? await this.callFromListView()
                : await this.callFromRegularCampaignView();
        },
        async callFromListView() {
            if (!this.selectedNumber) {
                this.log("Select call number.", "alert-danger");

                return;
            }

            const customerNumberToCall = this.getCustomerNumberToCall();

            this.setAddStatusFormState(false);
            await this.call(this.selectedNumber.value, `+${customerNumberToCall}`);
        },
        async callFromRegularCampaignView() {
            try {
                if(!this.selectedNumber) {
                    this.log("Select call number.", "alert-danger");

                    return;
                }

                const customerNumberToCall = this.getCustomerNumberToCall();

                this.setAddStatusFormState(false);
                await this.call(this.selectedNumber.value, `+${customerNumberToCall}`);
            } catch (error) {
                this.log(error, 'alert-danger');
            }
        },
        hangCall() {
            if (!this.device) {
                return;
            }

            this.log(
                'Hang up call in progress...',
                'alert-warning'
            );

            this.device.disconnectAll();
        },
        disconnectCall() {
            this.callObject = null;
            this.isMuted = false;
            this.isCallAccepted = false;
            this.isCallStarted = false;

            const createdModelAsPlayer = localStorage.getItem('created-lead');

            if (!createdModelAsPlayer) {
                this.setAddStatusFormState(true);

                return;
            }

            localStorage.removeItem('created-lead');
        },
        onPressedDigitEvent(value) {
            if (!this.callObject) {
                return;
            }

            const tempValue = value.toString();

            this.callObject.sendDigits(tempValue[tempValue.length - 1]);
        },
        onDialPadNumberUpdate(value) {
            const phone = this.customerPhone;
            const mobile = this.customerMobile;

            this.customerNumberToCall = (!!phone && value === phone) || (!!mobile && value === mobile)
                ? null
                : `+${value.replace('+', '')}` || "+";
        },
        muteHandler() {
            if (!this.callObject) {
                return;
            }

            this.isMuted = !this.isMuted;

            this.callObject.mute(this.isMuted);

            this.log(
                `Microphone is ${this.isMuted ? "muted" : "unmuted"}`,
                `${this.isMuted ? "alert-danger" : "alert-success"}`
            );
        },
        setAddStatusFormState(value = false) {
            this.$emit('add-status-state', value);
        },
        getCustomerNumberToCall() {
            const customerNumberToCall = !!this.customerNumberToCall
                ? this.customerNumberToCall
                : this.numberToCall;

            return !!isString(customerNumberToCall)
                ? Number(customerNumberToCall.replace('+', ''))
                : customerNumberToCall;
        },
    },
}