<template>
    <atbl-modal v-if="$can('Shifts - Create/Update')"
             :show="show"
             :close-on-backdrop="false"
             :no-close-on-esc="false"
             title="Create/Update Shift"
             @close="toggleCreateUpdateModal"
    >
        <div class="row">
            <div class="col-12">
                <atbl-input :value="model.name"
                            :errors="modelErrors"
                            :disabled="isLoading"
                            label="Enter your name"
                            name="name"
                            @update="updateModelField"
                />
            </div>

            <div class="col-12">
                <atbl-textarea :value="model.description"
                               :disabled="isLoading"
                               label="Enter your description"
                               name="description"
                               @update="updateModelField"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <atbl-input :value="model.start"
                            :errors="modelErrors"
                            :disabled="isLoading"
                            label="Enter start"
                            name="start"
                            type="time"
                            @update="updateModelField"
                />
            </div>

            <div class="col-6">
                <atbl-input :value="model.finish"
                            :errors="modelErrors"
                            :disabled="isLoading"
                            label="Enter finish"
                            name="finish"
                            type="time"
                            @update="updateModelField"
                />
            </div>
        </div>

        <template #footer>
            <button v-if="!isLoading"
                      class="btn btn-sm btn-danger"
                      @click="toggleCreateUpdateModal"
            >
                Cancel
            </button>
            <button class="btn btn-sm btn-success"
                      :disabled="isLoading"
                      @click="createUpdateShift"
            >
                <span v-if="!isLoading">Save</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import ShiftModel from './ShiftModel';
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'CreateUpdateShiftComponent',
    inject: [
        'createUpdateShift',
        'updateModelField',
        'toggleCreateUpdateModal',
    ],
    components: {
		AtblModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        model: {
            type: Object,
            default: ShiftModel,
        },
        modelErrors: {
            type: Object,
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>