import NamespaceConst from "../../NamespaceConst";

export default {
    mutations: {
        SET_PARENT_ID: `${NamespaceConst.settings}setParentId`,
        SET_SECTION_ID: `${NamespaceConst.settings}setSectionId`,
        SET_SETTING_ID: `${NamespaceConst.settings}setSettingId`,
        SET_BREADCRUMBS: `${NamespaceConst.settings}setBreadcrumbs`,

        // sections
        SET_SETTINGS_SECTIONS: `${NamespaceConst.settings}setSettingsSections`,
        SET_OPEN_SETTINGS_SECTIONS_MODAL: `${NamespaceConst.settings}setOpenSettingsSectionsModal`,
        SET_SECTIONS_TYPES: `${NamespaceConst.settings}setSectionsTypes`,
        SET_SECTIONS_LOADER: `${NamespaceConst.settings}setSectionsLoader`,
        SET_SELECTED_SECTION: `${NamespaceConst.settings}setSelectedSection`,
        SET_SECTION_FORM_MODEL: `${NamespaceConst.settings}setSectionFormModel`,
        SET_SECTION_MODAL_LOADER: `${NamespaceConst.settings}setSectionModalLoader`,
        SET_SECTION_FORM_ERRORS: `${NamespaceConst.settings}setSectionsFormErrors`,
        SET_SETTING_SECTION_REMOVE: `${NamespaceConst.settings}setSettingsSectionRemove`,
        SET_SETTING_SECTION_REMOVE_MODAL: `${NamespaceConst.settings}setSettingsSectionRemoveModal`,
        CLEAR_SECTION_MODEL: `${NamespaceConst.settings}clearSectionModel`,
        UPDATE_FORM_MODEL: `${NamespaceConst.settings}updateFormModel`,
        UPDATE_SETTINGS_FORM_MODEL: `${NamespaceConst.settings}updateSettingsFormModel`,

        // settings
        SET_OPEN_SETTINGS_MODAL: `${NamespaceConst.settings}setOpenSettingsModal`,
        SET_SETTINGS_TYPES: `${NamespaceConst.settings}setSettingsTypes`,
        SET_SETTINGS_FORM_MODEL: `${NamespaceConst.settings}setSettingsFormModel`,
        SET_SETTINGS_MODAL_LOADER: `${NamespaceConst.settings}setSettingsModalLoader`,
        SET_SETTINGS_FORM_ERRORS: `${NamespaceConst.settings}setSettingsFormErrors`,
        SET_SETTING_REMOVE: `${NamespaceConst.settings}setSettingsRemove`,
        SET_SETTING_REMOVE_MODAL: `${NamespaceConst.settings}setSettingsRemoveModal`,
        SET_SETTING_REMOVE_MODAL_LOADER: `${NamespaceConst.settings}setSettingsRemoveModalLoader`,
        CLEAR_SETTINGS_FORM_MODEL: `${NamespaceConst.settings}clearSettingsModel`,
        SET_SETTINGS: `${NamespaceConst.settings}setSettings`,
        SET_SETTINGS_SAVE_LOADER: `${NamespaceConst.settings}setSettingsSaveLoader`,
    },
    getters: {
        GET_PARENT_ID: `${NamespaceConst.settings}getParentId`,
        GET_SECTION_ID: `${NamespaceConst.settings}getSectionId`,
        GET_SETTING_ID: `${NamespaceConst.settings}getSettingId`,
        GET_BREADCRUMBS: `${NamespaceConst.settings}getBreadcrumbs`,

        // sections
        GET_SETTINGS_SECTIONS: `${NamespaceConst.settings}getSettingsSections`,
        GET_SECTIONS_TYPES: `${NamespaceConst.settings}getSettingsSectionsTypes`,
        GET_ADD_SETTINGS_SECTION_MODAL: `${NamespaceConst.settings}getAddSettingsSectionsModal`,
        GET_SELECTED_SECTION: `${NamespaceConst.settings}getSelectedSection`,
        GET_SECTION_MODAL_LOADER: `${NamespaceConst.settings}getSectionModalLoader`,
        GET_SECTION_FORM_ERRORS: `${NamespaceConst.settings}getSectionsFormErrors`,
        GET_SETTING_SECTION_REMOVE: `${NamespaceConst.settings}setSettingsSectionRemove`,
        GET_SETTING_SECTION_REMOVE_MODAL: `${NamespaceConst.settings}getSettingsSectionRemoveModal`,
        GET_SETTING_SECTION_REMOVE_MODAL_LOADER: `${NamespaceConst.settings}getSettingsSectionRemoveModalLoader`,
        SECTIONS_LOADER: `${NamespaceConst.settings}sectionsLoader`,

        // settings
        GET_SETTINGS_TYPES: `${NamespaceConst.settings}getSettingsTypes`,
        GET_ADD_SETTINGS_MODAL: `${NamespaceConst.settings}getAddSettingsModal`,
        GET_SETTINGS: `${NamespaceConst.settings}getSettings`,
        GET_SECTION_MODEL: `${NamespaceConst.settings}getSectionModel`,
        GET_SETTINGS_FORM_MODEL: `${NamespaceConst.settings}getSettingsFormModel`,
        GET_SETTINGS_MODAL_LOADER: `${NamespaceConst.settings}getSettingsModalLoader`,
        GET_SETTINGS_FORM_ERRORS: `${NamespaceConst.settings}getSettingsFormErrors`,
        GET_SETTING_REMOVE: `${NamespaceConst.settings}getSettingsRemove`,
        GET_SETTING_REMOVE_MODAL: `${NamespaceConst.settings}getSettingsRemoveModal`,
        GET_SETTING_REMOVE_MODAL_LOADER: `${NamespaceConst.settings}getSettingsRemoveModalLoader`,
        GET_SETTINGS_SAVE_LOADER: `${NamespaceConst.settings}getSettingsSaveLoader`,
    },
    actions: {
        // section
        FETCH_SETTINGS_SECTIONS_ACTION: `${NamespaceConst.settings}fetchSettingsSections`,
        FETCH_SETTINGS_SECTIONS_TYPES_ACTION: `${NamespaceConst.settings}fetchSettingsSectionsTypes`,
        OPEN_SETTINGS_SECTIONS_MODAL_ACTION: `${NamespaceConst.settings}openSettingsSectionsModal`,
        OPEN_SECTION_ACTION: `${NamespaceConst.settings}openSection`,
        EDIT_SETTING_SECTION_ACTION: `${NamespaceConst.settings}editSettingSection`,
        SAVE_SETTINGS_SECTION_ACTION: `${NamespaceConst.settings}saveSettingsSection`,
        CHANGE_SECTION_ACTION: `${NamespaceConst.settings}changeSection`,
        CLEAR_SECTION_MODEL_ACTION: `${NamespaceConst.settings}clearSectionModel`,
        REMOVE_SETTING_SECTION_CONFIRM_ACTION: `${NamespaceConst.settings}removeSettingSectionConfirm`,
        REMOVE_SETTINGS_SECTION_ACTION: `${NamespaceConst.settings}removeSettingSection`,
        OPEN_CLOSE_REMOVE_SECTION_SETTINGS_ACTION: `${NamespaceConst.settings}closeRemoveSectionModal`,

        // settings
        FETCH_SETTINGS_TYPES_ACTION: `${NamespaceConst.settings}fetchSettingsTypes`,
        OPEN_SETTINGS_MODAL_ACTION: `${NamespaceConst.settings}openSettingsModal`,
        EDIT_SETTING_ACTION: `${NamespaceConst.settings}editSetting`,
        SAVE_SETTINGS_ACTION: `${NamespaceConst.settings}saveSettings`,
        SAVE_SETTINGS_FAST_ACTION: `${NamespaceConst.settings}saveSettingsFast`,
        REMOVE_SETTINGS_ACTION: `${NamespaceConst.settings}removeSetting`,
        OPEN_CLOSE_REMOVE_SETTINGS_ACTION: `${NamespaceConst.settings}closeRemoveModal`,
        REMOVE_SETTING_CONFIRM_ACTION: `${NamespaceConst.settings}removeSettingConfirm`,
    }
};