import dynamicSort from '../utils/Array/DynamicSort';

export default {
    methods: {
        dynamicSort,
        alphabeticSort: function(array, key){
            // this is only ASC alphabetic sort
            // TODO: desc alphabetic sort
            return array.sort(function(a, b) {
                const textA = a[key].toUpperCase(),
                    textB = b[key].toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        },
        convertToTree: function(options, nestedKey){
            let _parent = this;

            return options.map(item => {
                let newItem = {
                    id: item.id,
                    label: item.name,
                };

                if(this.isset(item, nestedKey) && Array.isArray(item[nestedKey]) && item[nestedKey].length > 0){
                    newItem = {
                        ...newItem,
                        children: _parent.convertToTree(item[nestedKey])
                    };
                }

                return newItem;
            });
        },
        findValueByKey: function(options, key, value, nestedKey){
            const findById = options.find(item => item[key] === value);

            if (findById) {
                return findById;
            }

            if (options.length > 0) {
                for (let option in options) {
                    let optionValue = options[option];

                    if (this.isset(optionValue, nestedKey) &&
                        Array.isArray(optionValue[nestedKey]) &&
                        optionValue[nestedKey].length > 0
                    ) {
                        return this.findValueByKey(optionValue[nestedKey], key, value, nestedKey);
                    }
                }
            }

            return false;
        },
        nestedArrayToFlat(values) {
            return Object.keys(values)
                .map(item => {
                    const current = values[item];

                    return Array.isArray(current)
                        ? current
                        : this.nestedArrayToFlat(current)
                })
                .flat();
        },
    }
};