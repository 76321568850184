var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("orders-search", {
        staticClass: "mb-3",
        on: { search: _vm.onSearchEvent, clear: _vm.clearSearchModel }
      }),
      _vm._v(" "),
      !!_vm.showTable
        ? _c("div", { staticClass: "card card-list" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex align-items-center justify-content-between"
              },
              [
                _vm._m(0),
                _vm._v(" "),
                !!_vm.hasMore
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                Search result contains more than 1000 rows, please narrow your search.\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    !!_vm.orders.length
                      ? _c("atbl-select", {
                          staticClass: "mr-2 mb-0",
                          attrs: {
                            options: _vm.paginationModel.data,
                            value: _vm.paginationModel.default,
                            "first-option-label": null,
                            "option-label": "text"
                          },
                          on: { input: _vm.onPaginationLimitChangeEvent }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                !_vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "general-list v-sortable",
                        on: { sort: _vm.onUpdateSortEvent }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row sticky-element d-none d-xl-flex"
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sort",
                                    rawName: "v-sort:id",
                                    value: _vm.sort,
                                    expression: "sort",
                                    arg: "id"
                                  }
                                ],
                                staticClass: "col-12 col-lg-4 col-xl text-muted"
                              },
                              [_vm._v("ID")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sort",
                                    rawName: "v-sort:created_by_string",
                                    value: _vm.sort,
                                    expression: "sort",
                                    arg: "created_by_string"
                                  }
                                ],
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-muted text-center"
                              },
                              [_vm._v("Created By")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sort",
                                    rawName: "v-sort:created_at",
                                    value: _vm.sort,
                                    expression: "sort",
                                    arg: "created_at"
                                  }
                                ],
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-muted text-center"
                              },
                              [_vm._v("Date")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sort",
                                    rawName: "v-sort:amount",
                                    value: _vm.sort,
                                    expression: "sort",
                                    arg: "amount"
                                  }
                                ],
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-muted text-center"
                              },
                              [_vm._v("Total")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sort",
                                    rawName: "v-sort:order_items_count",
                                    value: _vm.sort,
                                    expression: "sort",
                                    arg: "order_items_count"
                                  }
                                ],
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-muted text-center"
                              },
                              [_vm._v("Items")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sort",
                                    rawName: "v-sort:status",
                                    value: _vm.sort,
                                    expression: "sort",
                                    arg: "status"
                                  }
                                ],
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-muted text-center"
                              },
                              [
                                _vm._v(
                                  "\n                        Status\n                    "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.ordersData.data, function(order, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-center text-xl-left"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-inline-block" },
                                  [_vm._v("Order:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "small-mb-badge-extended" },
                                  [_vm._v("Order")]
                                ),
                                _vm._v(" "),
                                _vm.$can("Orders - View Order Details")
                                  ? _c(
                                      "a",
                                      {
                                        attrs: { href: "/orders/" + order.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            #O-" +
                                            _vm._s(order.id) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "\n                            #O-" +
                                          _vm._s(order.id) +
                                          "\n                        "
                                      )
                                    ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-inline-block" },
                                  [_vm._v("Player:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "small-mb-badge-extended" },
                                  [_vm._v("Player")]
                                ),
                                _vm._v(" "),
                                _vm.$can("Player - View Details")
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "/players/" +
                                            order.player_id +
                                            "/view"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(order.player_name) +
                                            " " +
                                            _vm._s(order.player_surname) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(order.player_name) +
                                          " " +
                                          _vm._s(order.player_surname) +
                                          "\n                        "
                                      )
                                    ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                !!order.latest_completed_transaction_id
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-none d-xl-inline-block"
                                        },
                                        [_vm._v("Transaction:")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "small-mb-badge-extended"
                                        },
                                        [_vm._v("Transaction")]
                                      ),
                                      _vm._v(" "),
                                      _vm.$can(
                                        "Orders - View Transaction Details"
                                      )
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "/transaction/" +
                                                  order.latest_completed_transaction_id
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                #T-" +
                                                  _vm._s(
                                                    order.latest_completed_transaction_id
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              "\n                                #T-" +
                                                _vm._s(
                                                  order.latest_completed_transaction_id
                                                ) +
                                                "\n                            "
                                            )
                                          ])
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-center align-self-center"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "small-mb-badge-extended" },
                                  [_vm._v("Created by")]
                                ),
                                _vm._v(" "),
                                _c("created-by-badge", {
                                  attrs: { created: order }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-center align-self-center"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "small-mb-badge-extended" },
                                  [_vm._v("Created at")]
                                ),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.utcToLocal(order.created_at)) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-center align-self-center"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "small-mb-badge-extended" },
                                  [_vm._v("Amount")]
                                ),
                                _vm._v(" "),
                                !!order.is_free
                                  ? _c(
                                      "span",
                                      { staticClass: "badge badge-danger" },
                                      [
                                        _vm._v(
                                          "\n                            Free\n                        "
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              order.amount
                                            )
                                          ) +
                                          "\n                        "
                                      )
                                    ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-center align-self-center"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "small-mb-badge-extended" },
                                  [_vm._v("Items")]
                                ),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(order.order_items_count) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-lg-4 col-xl text-center align-self-center mt-3 mt-xl-0"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: "Status"
                                        },
                                        expression:
                                          "{\n                                    content: 'Status'\n                                 }"
                                      }
                                    ],
                                    staticClass: "font-weight-bold",
                                    class: [
                                      "badge",
                                      "badge-" +
                                        _vm.$options.OrdersConstants.statusColor(
                                          order.status
                                        )
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          order.status.split("_").join(" ")
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        }),
                        _vm._v(" "),
                        !_vm.isLoading
                          ? _c("not-found", {
                              attrs: {
                                msg: "No orders found.",
                                items: _vm.orders
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isLoading && _vm.ordersData.last_page > 1
              ? _c("div", { staticClass: "card-footer overflow-x-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c(
                        "nav",
                        { staticClass: "wrap" },
                        [
                          _c("atbl-pagination", {
                            attrs: {
                              pagination: _vm.ordersData,
                              scroll: false
                            },
                            on: { paginate: _vm.onPageChangeEvent }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$scrollToTop()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-caret-up mr-2" }),
                          _vm._v(" Back to top\n                ")
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-play mr-1" }),
      _vm._v(" Orders\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }