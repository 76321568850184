var render, staticRenderFns
import script from "./SettingsService.vue?vue&type=script&lang=js&"
export * from "./SettingsService.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/app-stage.thelottoexperts.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7932f33f')) {
      api.createRecord('7932f33f', component.options)
    } else {
      api.reload('7932f33f', component.options)
    }
    
  }
}
component.options.__file = "resources/js/services/SettingsService.vue"
export default component.exports