import TypesConst from "../../../TypesConst";
import ConfigurationsService from "../../../../services/ConfigurationsService";
import SettingsService from "../../../../services/SettingsService";

export default {
    // settings sections
    [TypesConst.settings.actions.OPEN_SETTINGS_SECTIONS_MODAL_ACTION]({commit}, isOpen){
        commit(TypesConst.settings.mutations.SET_OPEN_SETTINGS_SECTIONS_MODAL, isOpen);
    },
    [TypesConst.settings.actions.CLEAR_SECTION_MODEL_ACTION]({commit}){
        commit(TypesConst.settings.mutations.CLEAR_SECTION_MODEL);
    },
    [TypesConst.settings.actions.REMOVE_SETTINGS_SECTION_ACTION]({commit}, section){
        commit(TypesConst.settings.mutations.SET_SETTING_SECTION_REMOVE, section);
        commit(TypesConst.settings.mutations.SET_SETTING_SECTION_REMOVE_MODAL, true);
    },
    [TypesConst.settings.actions.OPEN_CLOSE_REMOVE_SECTION_SETTINGS_ACTION]({commit}, isOpen){
        commit(TypesConst.settings.mutations.SET_SETTING_SECTION_REMOVE_MODAL, isOpen);
    },
    [TypesConst.settings.actions.OPEN_SECTION_ACTION]({commit, state, dispatch}, data){
        let id = data.sectionId;
        commit(TypesConst.settings.mutations.SET_SECTIONS_LOADER, true);
        commit(TypesConst.settings.mutations.SET_PARENT_ID, id);

        let breadcrumbs = [];
        if(id){
            let crumbIndex = state.breadcrumbs.findIndex(item => item.id === id);
            breadcrumbs = state.breadcrumbs;
            if (crumbIndex !== -1) {
                breadcrumbs = state.breadcrumbs.filter((item, index) => index < crumbIndex);
            }
        }
        commit(TypesConst.settings.mutations.SET_BREADCRUMBS, breadcrumbs);

        if(id){
            dispatch(TypesConst.settings.actions.CHANGE_SECTION_ACTION, {
                id: id
            });
        }else {
            dispatch(TypesConst.settings.actions.FETCH_SETTINGS_SECTIONS_ACTION, {
                id: id
            });
        }

        commit(TypesConst.settings.mutations.SET_SECTIONS_LOADER, false);
    },
    async [TypesConst.settings.actions.FETCH_SETTINGS_SECTIONS_TYPES_ACTION]({commit}){
        let sectionsTypes = await ConfigurationsService.getSettingsSectionsTypes();
        commit(TypesConst.settings.mutations.SET_SECTIONS_TYPES, sectionsTypes.data);
    },
    [TypesConst.settings.actions.FETCH_SETTINGS_SECTIONS_ACTION]({commit}, id){
        commit(TypesConst.settings.mutations.SET_SECTIONS_LOADER, true);

        SettingsService.getSettingsSections(id)
            .then(response => {
                commit(TypesConst.settings.mutations.SET_SELECTED_SECTION, null);
                commit(TypesConst.settings.mutations.SET_SETTINGS_SECTIONS, response.data);
            })
            .finally(() => {
                commit(TypesConst.settings.mutations.SET_SECTIONS_LOADER, false);
            });
    },
    async [TypesConst.settings.actions.EDIT_SETTING_SECTION_ACTION]({commit}, sectionId){
        commit(TypesConst.settings.mutations.SET_OPEN_SETTINGS_SECTIONS_MODAL, true);
        commit(TypesConst.settings.mutations.SET_SECTION_MODAL_LOADER, true);

        if(sectionId){
            commit(TypesConst.settings.mutations.SET_SECTION_ID, sectionId);
        }
        let section = await SettingsService.getSettingSectionById(sectionId);
        commit(TypesConst.settings.mutations.SET_SECTION_FORM_MODEL, section.data);

        commit(TypesConst.settings.mutations.SET_SECTION_MODAL_LOADER, false);
    },
    async [TypesConst.settings.actions.CHANGE_SECTION_ACTION]({commit}, data){
        let section = await SettingsService.getSettingSectionById(data.id);
        commit(TypesConst.settings.mutations.SET_SELECTED_SECTION, section.data);
    },
    async [TypesConst.settings.actions.SAVE_SETTINGS_SECTION_ACTION]({commit, state, dispatch}, model){
        commit(TypesConst.settings.mutations.SET_SECTION_MODAL_LOADER, true);

        await SettingsService.saveNewSettingsSection(model.parentId, model)
            .then(response => {
                const { status, message } = response.data;
                commit(TypesConst.settings.mutations.SET_SECTION_MODAL_LOADER, false);

                if(status){
                    commit(TypesConst.settings.mutations.SET_OPEN_SETTINGS_SECTIONS_MODAL, false);
                    commit(TypesConst.settings.mutations.CLEAR_SECTION_MODEL);
                    dispatch(TypesConst.settings.actions.OPEN_SECTION_ACTION, {
                        sectionId: state.parent_id
                    });
                }

                flash(message, status ? "alert-success" : "alert-danger");
            })
            .catch(error => {
                commit(TypesConst.settings.mutations.SET_SECTION_FORM_ERRORS, error.response.data.errors);
                commit(TypesConst.settings.mutations.SET_SECTION_MODAL_LOADER, false);
            });
    },
    async [TypesConst.settings.actions.REMOVE_SETTING_SECTION_CONFIRM_ACTION]({commit, state, dispatch}){
        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL_LOADER, true);

        let section = await SettingsService.removeSettingSection(state.removeSettingSection.id),
            message = section.data.message;

        if(section.data.status){
            dispatch(TypesConst.settings.actions.OPEN_SECTION_ACTION, {
                sectionId: state.parent_id
            });
        }

        commit(TypesConst.settings.mutations.SET_SETTING_SECTION_REMOVE_MODAL, false);
        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL_LOADER, false);
        flash(message, section.data.status ? "alert-success" : "alert-danger");
    },
};