<template>
    <div v-if="isset(game, 'key')"
         class="card player-play mb-3"
    >
        <div class="card-header card-header-atbl">
            <LotteryGameHeader :game="game"
                               :configuration="getNumberConfiguration()"
            />
        </div>

        <div class="card-body">
            <div class="player-play-picker">
                <validation-errors :errors="errors" />
                <div class="player-play-picker-wrapper">
                    <div>
                        <LinesPicker :lines="lines"
                                     :game="game"
                                     :selected-free-game="selectedFreeGame"
                                     @updateLines="updateLines"
                        />

                        <p v-if="!hasSelectedFreeGame && closeToNextLineDiscount" class="alert alert-warning p-2 w-100">
                            <strong>Note: You are one line away to get a {{ closeToNextLineDiscount.discount_crm }}% discount !</strong>
                        </p>

                        <div class="ticket-options">
                            <div class="ticket-options-numbers"
                                 v-if="isset(game, 'ticketNumbers') && !Array.isArray(game.ticketNumbers)">
                                <TicketNumber :minRange="game.ticketNumbers.numbers.ticket.minRange"
                                              :maxRange="game.ticketNumbers.numbers.ticket.maxRange + 1"
                                              :maxNumbers="game.ticketNumbers.numbers.ticket.numbers"
                                              v-model="changeTicketNumber"
                                />
                            </div>
                            <div class="ticket-options-draw">
                                <atbl-select name="weeks"
                                          label="# of Weeks"
                                          :options="getWeeks"
                                          :value="draws"
                                          :disabled="hasSelectedFreeGame"
                                          @input="updateDraws"
                                />
                                <atbl-form-group label="Weekdays" class="weekdays font-weight-normal">
                                    <div class="weekdays-holder">
                                        <label v-for="(weekday, index) in game.weekdays"
                                               :for="weekday"
                                               :key="index"
                                               class="mb-1"
                                        >
                                            <input v-if="!hasSelectedFreeGame"
                                                   v-model="weekdays"
                                                   :value="weekday"
                                                   :disabled="(weekdays.length < 2 && weekdays.indexOf(weekday) !== -1)"
                                                   type="checkbox"
                                                   :id="weekday"
                                                   class="mr-1"
                                            />
                                            <input v-else
                                                   :value="weekday"
                                                   :id="weekday"
                                                   :checked="weekdays.indexOf(weekday) !== -1"
                                                   type="radio"
                                                   name="weekdays[]"
                                                   class="mr-1"
                                                   @input="selectWeekday"
                                            />

                                            <span>{{ weekday }}</span>
                                        </label>
                                    </div>
                                </atbl-form-group>
                            </div>
                        </div>
                        <div v-if="!hasSelectedFreeGame && closeToNextDrawDiscount" class="alert alert-warning mt-1 p-2 w-100">
                            <strong>Note: You are one draw away to get a {{ closeToNextDrawDiscount.discount_crm }}% discount !</strong>
                        </div>
                    </div>
                    <div>
                        <div class="buy">
                            <div class="buy-total">
                                <table width="100%">
                                    <tr class="border-bottom">
                                        <td align="left">
                                            <span v-if="game.drawsPerTicket">Draws:</span>
                                            <span v-if="game.weeksPerTicket">Weeks:</span>
                                        </td>
                                        <td align="right">
                                            <span class="buy-total-price ml-1">{{ draws }}</span>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom" v-if="game.weeksPerTicket">
                                        <td align="left">
                                            <span>Draws:</span>
                                        </td>
                                        <td align="right">
											<span class="buy-total-price ml-1">
												{{ weekdays.length * draws }}
											</span>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td align="left">
                                            <span>Lines: </span>
                                        </td>
                                        <td align="right">
											<span v-if="!hasSelectedFreeGame"
                                                  class="buy-total-price ml-1"
                                            >
												{{ lines.length }} x {{ game.price | formatCurrency }}
											</span>
                                            <span v-else
                                                  class="buy-total-price ml-1"
                                            >

                                                {{ lines.length }} <s>x {{ game.price | formatCurrency }}</s>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="!hasSelectedFreeGame"
                                        class="border-bottom"
                                    >
                                        <td align="left">
                                            <span>Discount: </span>
                                        </td>
                                        <td align="right">
											<span class="buy-total-price ml-1">
												{{ totalDiscount }} %
											</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left">
                                            <span>Total:</span>
                                        </td>
                                        <td v-if="!hasSelectedFreeGame"
                                            align="right"
                                        >
                                            <strong v-if="totalDiscount === 0" class="buy-total-price ml-1">{{ total | formatCurrency }}</strong>
                                            <strong v-if="totalDiscount !== 0" class="buy-total-price ml-1"><span style="text-decoration: line-through;">{{ total | formatCurrency }}</span> {{ totalWithDiscount | formatCurrency }}</strong>
                                        </td>
                                        <td v-else
                                            align="right"
                                        >
                                            <strong class="buy-total-price ml-1"><s>{{ total | formatCurrency }}</s> {{ 0 | formatCurrency }}</strong>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="buy-operations">
                                <button v-if="!hasSelectedFreeGame"
                                          name="buyBtn"
                                          type="submit"
                                          class="btn buy-button btn-sm mb-2"
                                          :class="{
                                              'btn-success': !addToCardLoading,
                                              'btn-outline-success': addToCardLoading
                                          }"
                                          :disabled="getInvalidLines.length > 0 || addToCardLoading"
                                          @click.prevent="addToCart(false)"
                                >
                                    <span v-if="!addToCardLoading">
                                        <i class="fa fa-shopping-cart" aria-hidden="false"></i> Add to Cart
                                    </span>
                                    <loader :show="addToCardLoading"
                                            type="smaller"
                                    />
                                </button>
                                <button v-if="!hasSelectedFreeGame"
                                          class="btn btn-sm btn-danger buy-button text-uppercase"
                                          @click="addToCart(true)"
                                          :disabled="getInvalidLines.length > 0 || addToCardLoading || isPlacingOrder"
                                >
                                    <span v-if="!addToCardLoading && !isPlacingOrder">
                                        <i class="fa fa-credit-card" aria-hidden="false"></i> Add & Place Order
                                    </span>
                                    <loader :show="addToCardLoading || isPlacingOrder"
                                            type="smaller"
                                    />
                                </button>
                                <button v-if="hasSelectedFreeGame"
                                          class="btn btn-sm btn-warning buy-button text-uppercase"
                                          @click="useFreeTicket(ticket())"
                                          :disabled="getInvalidLines.length > 0 || isUseFreeLoading"
                                >
                                    <span v-if="!isUseFreeLoading">
                                        <i class="fa fa-ticket-alt" aria-hidden="false"></i> Use Free Ticket
                                    </span>
                                    <loader :show="isUseFreeLoading"
                                            type="smaller"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TypesConst from '../../../../../../store/TypesConst';
import NamespaceConst from '../../../../../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';
import LotteryGameHeader from './Components/LotteryGameHeader';
import LinesPicker from './Components/LinesPicker';
import TicketNumber from './Components/TicketNumber';
import ValidationErrors from '../../Componenets/ValidationErrors/ValidationErrors';

import LinesPickerMixin from './Mixins/LinesPicker';
import CheckoutAction from '../../Mixins/CheckoutAction';
import PlaceOrder from '../../Mixins/PlaceOrder';
import DiscountService from '../../../../../../services/DiscountService';
import UseFreeTicket from '../../Mixins/UseFreeTicket';
import round from '../../../../../../utils/Number/Round';

const {
		mapGetters: mapCartGetters,
		mapMutations: mapCartMutations,
		mapActions: mapCartAction
	} = createNamespacedHelpers(NamespaceConst.cart);

	const {
		mapGetters: mapPlayersGetters
	} = createNamespacedHelpers(NamespaceConst.players);

	const {
		mapGetters: mapGamesGetters
	} = createNamespacedHelpers(NamespaceConst.games);

	const {
		mapGetters: mapOrderGetters,
        mapMutations: mapOrderMutations
	} = createNamespacedHelpers(NamespaceConst.order);

import AtblSelect from '../../../../../../components/Forms/Inputs/ATBLSelect.vue';

export default {
		name: 'LotteryGame',
        inject: [
            'selectFreeGame',
            'getFreeGames',
        ],
		mixins: [
			LinesPickerMixin,
			CheckoutAction,
            PlaceOrder,
            UseFreeTicket
		],
		components: {
			LotteryGameHeader,
			LinesPicker,
			TicketNumber,
            ValidationErrors,
			AtblSelect,
		},
        props: {
		    selectedFreeGame: {
		        type: Object,
                default: () => ({})
            },
        },
		data() {
			return {
				addToCardLoading: false,
				lines: [],
				draws: '',
				weekdays: [],
				numbers: {
					ticket: []
				},
				errors: {},
                lineDiscounts: [],
                drawDiscounts: [],
			}
		},
		computed: {
			...mapCartGetters({
				cartLoader: TypesConst.cart.getters.GET_ADD_TO_CART_LOADER,
				total: TypesConst.cart.getters.GET_CART_TOTAL,
				getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
                isPlacingOrder: TypesConst.cart.getters.GET_PLACE_ORDER,
			}),
			...mapPlayersGetters({
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
			}),
			...mapGamesGetters({
				game: TypesConst.games.getters.GET_GAME_CONFIGURATION_DATA
			}),
            ...mapOrderGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            }),
			isTicketPlayerLoading: function () {
				return this.cartLoader;
			},
            isWeeklyPurchasable() {
                return !this.game.drawsPerTicket;
            },
            totalDiscount() {
                const lineDiscount = DiscountService.findLineDiscount(this.lineDiscounts, this.lines.length);
                const drawDiscount = DiscountService.findDrawDiscount(this.drawDiscounts, this.totalDraws);

                return (lineDiscount.discount_crm || 0) + (drawDiscount.discount_crm || 0);
            },
            totalDraws() {
			    if(!this.isWeeklyPurchasable) {
			        return this.draws;
                }
			    
			    return this.draws * this.weekdays.length;
            },
            totalWithDiscount() {
			    return round(this.total - (this.total * this.totalDiscount / 100), 2);
            },
            closeToNextLineDiscount() {
                const currentLineDiscount = DiscountService.findLineDiscount(this.lineDiscounts, this.lines.length);
                const linePlusDiscount = DiscountService.findLineDiscount(this.lineDiscounts, this.lines.length + 1);

                if(currentLineDiscount.id !== linePlusDiscount.id && linePlusDiscount.discount_crm > currentLineDiscount.discount_crm) {
                    return linePlusDiscount;
                }

                return null;
            },
            closeToNextDrawDiscount() {
                const currentDrawDiscount = DiscountService.findDrawDiscount(this.drawDiscounts, this.totalDraws);
                const drawPlusDiscount = DiscountService.findDrawDiscount(this.drawDiscounts, this.totalDraws + 1);

                if(currentDrawDiscount.id !== drawPlusDiscount.id && drawPlusDiscount.discount_crm > currentDrawDiscount.discount_crm) {
                    return drawPlusDiscount;
                }

                return null;
            },
			getInvalidLines: function () {
				return this.lines.filter(item => item.error.invalid);
			},
			getWeeks: function () {
				if (this.game.drawsPerTicket) {
					return this.game.drawsPerTicket.map(draw => {
						return {
							value: draw,
							label: draw > 1 ? `${draw} Draws` : `${draw} Draw`
						};
					});
				} else if (this.game.weeksPerTicket) {
					return this.game.weeksPerTicket.map(week => {
						return {
							value: week,
							label: week > 1 ? `${week} Weeks` : `${week} Week`
						};
					});
				} else {
					return [];
				}
			},
			linePrice: function () {
				if (this.game.price) {
					return this.game.price * 100
				}
			},
			changeTicketNumber: {
				get: function () {
					return this.numbers.ticket;
				},
				set: function (value) {
					this.numbers.ticket = value;
				}
			},
            hasSelectedFreeGame() {
                return !this.isObjectEmpty(this.selectedFreeGame);
            },
		},
		methods: {
			...mapCartMutations({
				setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
				setProcessingOverlay: TypesConst.cart.mutations.SET_PROCESSING_OVERLAY,
				setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
                clearCartData: TypesConst.cart.mutations.SET_CLEAR_CART_DATA,
                setIsPlacingOrder: TypesConst.cart.mutations.SET_PLACE_ORDER,
			}),
            ...mapOrderMutations({
                setOrderId: TypesConst.order.mutations.SET_ORDER_ID
            }),
			...mapCartAction({
				addToCartAction: TypesConst.cart.actions.ADD_TO_CART_ACTION
			}),
			updateDraws: function (value) {
				this.draws = value;
			},
			changeWeekdays: function (value) {
				this.weekdays = value;
			},
			updateLines(lines) {
				this.lines = lines;
			},
			ticket() {
				let ticket = {
					type: this.game.key ? this.game.key : null,
					lines: this.linesTranslator(this.lines),
					playerId: this.getPlayerId
				}

                if (this.game.drawsPerTicket) {
					ticket.draws = this.draws;
				} else {
					ticket.weeks = this.draws
					ticket.weekdays = this.weekdays;
				}

				if (this.isset(this.game, "ticketNumbers") && !Array.isArray(this.game.ticketNumbers)) {
					ticket.numbers = {
						ticket: this.numbers.ticket.join(""),
					};
				}

                ticket.image = this.game.image;
                ticket.name =  this.game.name;

				return ticket;
			},
			calcTotal(checkout = false) {
				this.setCheckout({
					checkout: checkout,
					cartTotal: ((this.lines.length * this.linePrice) * this.selectedDraws()) / 100
				});
			},
			selectedDraws() {
				return this.game.drawsPerTicket ? this.draws : this.draws * this.selectedWeekdays();
			},
			selectedWeekdays() {
				return this.weekdays.length;
			},
			initializeLines: function () {
				let linesPerTicket = this.game.linesPerTicket,
					minLines = linesPerTicket[0];

				this.lines = [
					...this.lines,
					...this.createMassLines(minLines)
				];
			},
            initializeGame(){
                this.draws = this.game.drawsPerTicket ? this.game.drawsPerTicket[0] : this.game.weeksPerTicket[0];

			    if (this.hasSelectedFreeGame) {
			        this.weekdays = [this.game.weekdays[0]];

                    this.lines = [
                        ...this.lines,
                        ...this.createMassLines(this.selectedFreeGame.meta.lines)
                    ];
                } else {
                    this.initializeLines();
                }
            },
			addToCart: function (checkout = false) {
				let _vm = this;

                if (this.orderId) {
                    this.clearCartData();
                    this.setOrderId(null);
                }

				_vm.calcTotal();

				_vm.errors = {};
				_vm.addToCardLoading = true;
				_vm.isCheckout = checkout;

                flash("Adding ticket to cart...");
                _vm.addToCartAction({
                    price: this.hasSelectedFreeGame ? 0 : _vm.totalWithDiscount,
                    item: _vm.ticket(),
                    playerId: _vm.getPlayerId
                })
                    .then(() => {
                        if(_vm.isCheckout) {
                            this.placeOrder(this.getCartItemsStatistics.price);
                        }
                        _vm.setShowGame(false);

                        _vm.$scrollToTop();
                        flash("Successfully added ticket to cart.");
                    })
                    .catch(error => {
                        this.errors = this.isset(error.response.data, "errors") ? error.response.data.errors: {};
                        window.showMessage("Error while adding a ticket to the cart.", false);
                    })
                    .finally(() => {
                        _vm.addToCardLoading = false;
                    });
			},
            fetchGameDiscounts(game, version = null) {
                DiscountService.fetchGameDiscounts(this.game.id, 'line', version).then((response) => {
                    this.lineDiscounts = response.data;
                });

                DiscountService.fetchGameDiscounts(this.game.id, 'draw', version).then((response) => {
                    this.drawDiscounts = response.data;
                });
            },
            selectWeekday(event) {
                this.weekdays = [event.target.value];
            }
		},
		created: function () {
		    this.initializeGame();
		},
		mounted: function () {
            this.fetchGameDiscounts(this.game.key);
			this.$el.scrollIntoView({behavior: "smooth"});
		},
		watch: {
			lines: function () {
				this.calcTotal()
			},
			draws: function () {
				this.calcTotal()
			},
			weekdays: function () {
				this.calcTotal()
			},
            game: {
			    handler (value) {
                    if (value.weekdays) {
                        this.weekdays = value.weekdays;
                    }
                },
                deep: true,
                immediate: true
            }
		}
	}
</script>
