<template>
    <player-layout :id="id">
        <template #header>
            <div class="d-flex align-items-center">
                <i class="fa fa-tag mr-2"></i> Free Games
                <span v-if="!userFreeGamesSettingsLoader && !!userFreeGamesSettings.can_create_free_game && !!userFreeGamesSettings.tickets.total_can_be_created"
                      class="ml-2"
                >
                    {{ userFreeGamesSettings.tickets.created }} / {{ userFreeGamesSettings.tickets.total_can_be_created }}
                </span>
                <loader :show="userFreeGamesSettingsLoader"
                        type="smaller"
                        class="ml-2"
                />
            </div>
        </template>

        <template #rightSide>
            <div v-if="!userFreeGamesSettingsLoader">
                <button v-if="!!userFreeGamesSettings.can_create_free_game"
                        :disabled="userFreeGamesSettingsLoader"
                        class="d-flex align-items-center btn btn-primary btn-sm"
                        @click.prevent="toggleCreateUpdateForm"
                >
                    <i class="fa fa-plus m-0 mr-2" aria-hidden="true"></i> Add
                </button>
                <span v-else>
                    <i class="fa fa-ban text-danger"></i> Cannot create free games.
                </span>
            </div>
            <div v-else>
                <loader :show="true"
                        type="smaller"
                        class="ml-2"
                />
            </div>
        </template>

        <div class="general-list">
            <div class="row">
                <div class="col-12 col-lg-1 text-center text-lg-left">ID</div>
                <div class="col-12 col-lg-2 text-center">Created By</div>
                <div class="col-12 col-lg text-center">Game</div>
                <div class="col-12 col-lg-2 text-center">Tickets</div>
                <div class="col-12 col-lg-2 text-center">Expires At</div>
                <div class="col-12 col-lg text-center">Is Used</div>
                <div class="col-12 col-lg-2 text-center">Operations</div>
            </div>

            <template v-if="!isLoading">
                <div v-for="(item, index) in freeGames"
                     :key="index"
                     class="row"
                >
                    <div class="col-12 col-lg-1 text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge m-0">ID</span>
                        {{ item.id }}
                    </div>
                    <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                        <span class="small-mb-badge m-0">Created By</span>
                        <span v-if="isset(item, 'user') && item.user">{{ item.user.name }}</span>
                        <span v-else
                                 class="badge badge-danger"
                        >
                            System
                        </span>
                    </div>
                    <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                        <span class="small-mb-badge m-0">Game</span>
                        {{ item.gameable.name ? item.gameable.name : item.gameable.title }}
                    </div>
                    <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Tickets</span>
                        <span v-tooltip="{
                                content: 'Number of used tickets'
                              }"
                        >
                            {{ item.number_of_tickets_used }}
                        </span>
                        /
                        <span v-tooltip="{
                                content: 'Number of total'
                              }"
                        >
                            {{ item.number_of_tickets }}
                        </span>
                    </div>
                    <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                        <span class="small-mb-badge m-0">Expires At</span>
                        {{ !!item.expires_at ? item.expires_at : '/' }}
                    </div>
                    <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                        <span class="small-mb-badge m-0">Is Used</span>
                        <i class="fa" :class="{
                            'fa-check text-success': !!item.is_used,
                            'fa-times text-danger': !item.is_used
                        }"></i>
                    </div>
                    <div class="col-12 col-lg-2 text-center">
                        <span class="small-mb-badge m-0">Operations</span>
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="btn btn-link btn-delete btn-sm mr-2"
                                    @click="previewFreeGame(item)"
                            >
                                <i class="fa fa-eye"></i>
                            </button>
                            <confirmable-remove-button v-if="$can('Player - Free Games Delete') && !item.number_of_tickets_used"
                                                       :value="item.id"
                                                       @confirmed="removeFreeGame"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <not-found v-if="!isLoading"
                       :items="freeGames"
                       msg="No free games found"
            />

            <loader :show="isLoading"
                    type="small"
            />
        </div>

        <template slot="footer">
            <nav class="wrap" v-if="!isLoading && freeGamesData.last_page > 1">
                <atbl-pagination :pagination="freeGamesData"
                                 @paginate="fetchFreeGames"
                />
            </nav>
        </template>

        <create-update-free-game-form :is-loading="isCreateUpdateLoading"
                                      :is-game-loading="isGameLoading"
                                      :show="isCreateUpdateFormShown"
                                      :configuration="createUpdateFormConfiguration"
                                      :is-save-allowed="isSaveAllowed"
                                      :errors="freeGameModelErrors"
                                      :user-free-game-settings="userFreeGamesSettings"
        />

        <preview-free-game-sidebar :data="freeGamePreviewData"
                                   :is-delete-pressed="isDeletePressed"
                                   :is-loading="isLoading"
                                   :can-create-free-game="userFreeGamesSettings.can_create_free_game"
        />
    </player-layout>
</template>

<script>
import CreateUpdateFreeGames from './CreateUpdateFreeGameForm/CreateUpdateFreeGames';
import CreateUpdateFreeGameForm from './CreateUpdateFreeGameForm/CreateUpdateFreeGameForm';
import PreviewFreeGame from './PreviewFreeGame/PreviewFreeGame';
import PreviewFreeGameSidebar from './PreviewFreeGame/PreviewFreeGameSidebar';

export default {
    name: 'FreeGames',
    mixins: [
        CreateUpdateFreeGames,
        PreviewFreeGame,
    ],
    components: {
        CreateUpdateFreeGameForm,
        PreviewFreeGameSidebar,
    },
    props: {
        id: {},
    },
    provide() {
        return {
            // CreateUpdateFreeGames
            freeGameModel: this.freeGameModel,
            toggleCreateUpdateForm: this.toggleCreateUpdateForm,
            updateGameType: this.updateGameType,
            updateFreeGameModel: this.updateFreeGameModel,
            changeMetaInput: this.changeMetaInput,
            createFreeGame: this.createFreeGame,
            removeFreeGame: this.removeFreeGame,
            changeFieldsInput: this.changeFieldsInput,
            updateGame: this.updateGame,
            // PreviewFreeGame
            previewFreeGame: this.previewFreeGame,
            togglePreviewDelete: this.togglePreviewDelete,
        };
    },
    data() {
        return {
            userFreeGamesSettingsLoader: false,
            userFreeGamesSettings: {
                can_create_free_game: false,
                range_type: null,
                tickets: {
                    created: null,
                    total_can_be_created: null,
                },
                lines: null,
                shares: null,
                fractions: null,
            },
            freeGames: [],
            freeGamesData: {},
            isLoading: false,
        };
    },
    methods: {
        async fetchFreeGames(page = 1) {
            this.isLoading = true;

            try {
                const response = await window.axios.get(`/player/${this.id}/free-games`, {
                    params: {
                        page
                    }
                });

                this.freeGamesData = response.data;
                this.freeGames = this.freeGamesData.data;

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);
                this.isLoading = false;
            }
        },
        async getUserFreeGamesSettings() {
            this.userFreeGamesSettingsLoader = true;

            try {
                const response = await window.axios.get(`/users/free-games-settings/${this.id}`);

                this.userFreeGamesSettings = response.data;

                this.createUpdateFormConfiguration = this.syncConfigurationWithSettings(this.userFreeGamesSettings);

                this.userFreeGamesSettingsLoader = false;
            } catch (error) {
                window.flashError(error);
                this.userFreeGamesSettingsLoader = false;
            }
        },
    },
    created() {
        this.getUserFreeGamesSettings();
        this.fetchFreeGames();
        this.getAllGames();
    },
}
</script>