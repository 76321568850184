import TypesConst from "../../TypesConst";

export default {
	[TypesConst.cart.mutations.SET_ADD_TO_CART_DATA](state, payload) {
		state.cartData = payload;
	},
	[TypesConst.cart.mutations.SET_CLEAR_CART_DATA](state, payload) {
		state.cartData = {
			id: null,
			items: []
		};
	},
	[TypesConst.cart.mutations.SET_CART_FIELD_DATA](state, payload) {
		Object.assign(state.cartData, payload);
	},
	[TypesConst.cart.mutations.SET_ADD_TO_CART_LOADER](state, payload) {
		state.cartLoader = payload;
	},
	[TypesConst.cart.mutations.SET_CHECKOUT](state, payload) {
		if (payload.hasOwnProperty("checkout")) {
			state.checkout = payload.checkout;
		}
		if (payload.hasOwnProperty("cartTotal")) {
			state.cartTotal = payload.cartTotal;
		}
	},
	[TypesConst.cart.mutations.SET_CART_TOTAL](state, payload) {
		state.cartTotal = payload;
	},
	[TypesConst.cart.mutations.SET_PROCESSING_OVERLAY](state, payload) {
		state.showProcessingOverlay = payload;
	},
	[TypesConst.cart.mutations.SET_SHOW_GAME](state, payload) {
		state.showGame = payload;
	},
	[TypesConst.cart.mutations.SET_PLAYER_CAN_PLAY_GAME](state, payload) {
		state.playerCanPlayGame = payload;
	},
	[TypesConst.cart.mutations.SET_WALLET_CONFIRM_MODAL](state, payload) {
		state.walletConfirmModal = payload;
	},
	[TypesConst.cart.mutations.SET_PAYMENT_LINK](state, payload) {
		state.paymentLink = payload;
	},
	[TypesConst.cart.mutations.SET_ADD_BALANCE](state, payload) {
		state.addBalance = payload;
	},
	[TypesConst.cart.mutations.SET_PLACE_ORDER](state, payload) {
		state.isPlacingOrder = payload;
	},
};