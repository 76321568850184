var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("AgentsFilterForm", {
        attrs: { users: _vm.users, filterModel: _vm.modelStore, show: false },
        on: { modelChange: _vm.modelChange }
      }),
      _vm._v(" "),
      _c("AgentsDetailsCards", {
        attrs: {
          agent: _vm.agent,
          bonus: true,
          isLoading: _vm.isLoading,
          "search-model": _vm.modelStore,
          "commission-report-type": "day-groups"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list mt-3" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("i", { staticClass: "fa fa-align-justify" }),
          _vm._v(" Details: "),
          _c("b", [_vm._v(_vm._s(_vm.agentName))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("AgentsGroupsDetailsList", {
              attrs: {
                user: _vm.user,
                days: _vm.days,
                id: _vm.id,
                isLoading: _vm.isLoading
              },
              on: { openAgent: _vm.openAgent }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }