<template>
    <div class='loader-line' v-if="show"></div>
</template>

<script>
    export default {
        name: "loader-line",
        props: {
            show: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style scoped lang="scss">
    .loader-line {
        width: 100%;
        height: 2px;
        background-color: #f6f6f6;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            display: block;
            height: 100%;
            background-color: #20a8d8;
            left: 0%;
            right: 100%;
            animation: loadingLine 2s ease infinite;
        }
    }

    @keyframes loadingLine {
        0% {
            left: 0;
            right: 100%;
        }
        50% {
            left: 0;
            right: 0;
        }
        100% {
            left: 100%;
            right: 0;
        }
    }
</style>