<template>
    <div class="card-header card-header-atbl">
        <div class="player-play-header bundle">
            <div v-for="(gameConfig, index) in config" class="d-flex align-items-center mb-1">
                <img :src="$imageService.gameImage(gameConfig.image)" class="game-thumb mx-2" :alt="gameConfig.name">

                <div>
                    <h5 class="text-muted m-0">{{ gameConfig.name }}</h5>

                    <p class="text-muted m-0">
                        <strong>Weekdays:</strong> {{ gameConfig.weekdays.join(', ') }}
                        <strong>Est. Jackpot:</strong> <span>{{ gameConfig.jackpot || 'N/A' }}</span>
                    </p>
                </div>
            </div>

            <button class="btn btn-sm btn-link" @click="closeGame">
                <i class="fa fa-times" aria-hidden="false"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bundle-game-header',

    props: ['config', 'bundle'],

    methods: {
        closeGame() {
            this.$store.commit('cart/setShowGame', false);
            this.$store.commit('cart/setCheckout', {
                checkout: false,
                total: 0,
            });
        },

        getKeyIndexByType(type){
            return Object.keys(this.configuration).indexOf(type);
        },
    }
}
</script>