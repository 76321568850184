var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "sort-link",
      class: { active: _vm.sort.sort === _vm.name },
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.onSortUpdateEvent.apply(null, arguments)
        }
      }
    },
    [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
      _vm.sort.sort === _vm.name
        ? _c("span", [
            _c("i", {
              staticClass: "fa",
              class: {
                "fa-caret-up": _vm.sort.value === "ASC",
                "fa-caret-down": _vm.sort.value === "DESC"
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }