var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: { id: _vm.id, icon: "fa fa-ticket-alt", title: "Raffle tickets" }
    },
    [
      _c(
        "template",
        { slot: "first" },
        [
          _c("player-raffle-tickets-search-form", {
            on: { search: _vm.search }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "general-list agents" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col text-muted" }, [_vm._v("Ticket")]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Created By")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Lottery")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Order")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Draw Date")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Lines")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Winnings")
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.tickets, function(ticket, index) {
            return !_vm.isLoading
              ? _c("div", { key: index, staticClass: "row" }, [
                  _c("div", { staticClass: "col align-self-center" }, [
                    _c(
                      "a",
                      { attrs: { href: "/raffle-tickets/" + ticket.id } },
                      [_vm._v("T# " + _vm._s(ticket.id))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: { content: "Copy to clipboard" },
                            expression: "{content: 'Copy to clipboard'}"
                          }
                        ],
                        staticClass: "btn btn-sm btn-link p-0",
                        on: {
                          click: function($event) {
                            return _vm.copyToClipboard(ticket.id)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-copy",
                          attrs: { "aria-hidden": "false" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.utcToLocal(ticket.created_at)))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col align-self-center text-center" },
                    [
                      _c("created-by-badge", {
                        attrs: { created: ticket.user_name }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _c("img", {
                        staticClass: "game-thumb",
                        attrs: {
                          src: _vm.$imageService.gameImage(ticket.game_image),
                          alt: "" + ticket.game_image
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm.$can("Orders - View Order Details")
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/orders/" +
                                  ticket.order_id +
                                  "?playerId=" +
                                  ticket.player_id +
                                  "&from=raffles"
                              }
                            },
                            [
                              _vm._v(
                                "\n                    O# " +
                                  _vm._s(ticket.order_id) +
                                  "\n                "
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              "\n                    O# " +
                                _vm._s(ticket.order_id) +
                                "\n                "
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(ticket.draw_date) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(ticket.fractions) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "col text-center align-self-center",
                    domProps: {
                      innerHTML: _vm._s(
                        ticket.is_parsed
                          ? _vm.formatCurrency(ticket.prize)
                          : "Not drawn yet"
                      )
                    }
                  })
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { msg: "No tickets found.", items: _vm.tickets }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && _vm.ticketResponse.last_page > 1
            ? _c(
                "nav",
                { staticClass: "wrap py-3" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.ticketResponse },
                    on: { paginate: _vm.getRaffleTickets }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }