var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permissions-holder-item" }, [
    _c(
      "div",
      { staticClass: "permissions-holder-item-header" },
      [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onChangeOpenStateEvent.apply(null, arguments)
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa mr-2",
              class: {
                "fa-caret-down": !!_vm.isShown,
                "fa-caret-right": !_vm.isShown
              }
            }),
            _vm._v(" " + _vm._s(_vm.name) + " "),
            _c("small", { staticClass: "font-weight-bold ml-1" }, [
              _vm._v(
                "(" +
                  _vm._s(_vm.selectedPermissionsGroup.length) +
                  " / " +
                  _vm._s(_vm.permissionsList.length) +
                  ")"
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("atbl-check-box", {
          attrs: {
            value: _vm.isAllSelected,
            "partial-check": _vm.isPartialSelected,
            name: "group-permission",
            label: "Select All"
          },
          on: { update: _vm.onSelectGroupPermissionsEvent }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !!_vm.isShown,
            expression: "!!isShown"
          }
        ],
        staticClass: "permissions-holder-item-list"
      },
      _vm._l(_vm.permissionsList, function(item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("atbl-check-box", {
              attrs: {
                value: item.id,
                label: item.text,
                checked: !!_vm.selectedPermissions.find(function(permission) {
                  return item.value === permission.value
                }),
                name: "users"
              },
              on: {
                update: function($event) {
                  return _vm.onSelectPermissionEvent(item)
                }
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }