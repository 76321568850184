<template>
	<div class="game" :title="item.name">
		<img class="game-thumb" :src="$imageService.gameImage(item.image)" />
		<div class="game-play">
			<p>{{ item.name }}</p>
			<button @click="playGame"
			          :class="{
						'btn-primary': !isLoading,
						'btn-outline-primary': isLoading
			          }"
					class="btn"
			>
				<span v-if="!isLoading">Play</span>
				<loader :show="isLoading"
				        type="smaller"
				/>
			</button>
		</div>
	</div>
</template>

<script>
	import TypesConst from "../../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../../store/NamespaceConst";
	import { createNamespacedHelpers } from "vuex";

	const {
		mapActions: mapGamesActions
	} = createNamespacedHelpers(NamespaceConst.games);

	const {
		mapMutations: mapCartMutations
	} = createNamespacedHelpers(NamespaceConst.cart);

	export default {
		name: "Game",
		props: {
			item: {
				type: Object,
				required: true
			},
            orderId: {
			    type: String,
                default: null
            }
		},
		data: function() {
			return {
				isLoading: false
			};
		},
		methods: {
			...mapCartMutations({
				setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
				setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
				setProcessingOverlay: TypesConst.cart.mutations.SET_PROCESSING_OVERLAY,
                setClearCartData: TypesConst.cart.mutations.SET_CLEAR_CART_DATA
			}),
			...mapGamesActions({
				getGameConfiguration: TypesConst.games.actions.GET_GAME_CONFIGURATION_ACTION
			}),
			playGame: function () {
                this.setShowGame(true);
				this.setProcessingOverlay(true);
				this.setCheckout({
					checkout: false
				});

				if (this.orderId) {
				    this.setClearCartData();
                }

				this.isLoading = true;
				this.getGameConfiguration(this.item)
					.finally(() => {
                        this.isLoading = false
						this.setProcessingOverlay(false);
					});
			}
		}
	}
</script>