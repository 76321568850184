var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atbl-select", {
    attrs: {
      label: "Language",
      name: "language_id",
      options: _vm.languages,
      disabled: _vm.isLoading,
      value: _vm.getValue,
      firstOptionLabel: null
    },
    on: { update: _vm.changed },
    scopedSlots: _vm._u(
      [
        !!_vm.getValue
          ? {
              key: "formGroup",
              fn: function() {
                return [
                  _c("img", {
                    staticClass: "flag",
                    attrs: {
                      src: "/img/flags/" + _vm.getValue.toLowerCase() + ".svg",
                      alt: "flag"
                    }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }