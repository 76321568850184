export default {
    playerId: 0,
    playerLoader: false,
    playerModel: {
        id: '',
        name: '',
        surname: '',
        password: '',
        password_confirmation: '',
        birthdate: null,
        email: '',
        address1: '',
        address2: '',
        city: '',
        postcode: '',
        state: '',
        country: null,
        phone: '',
        mobile: '',
        note: null,
        active: false,
        blocked: null,
        newsletter: 0,
        tags: [],
    },
    playerModelErrors: {},
    createPlayerModal: false,
    createPlayerModalLoader: false,
    shouldAddStatus: true,
};