<template>
    <date-range-picker v-model="dateRange"
                       :opens="opens"
                       :singleDatePicker="singleDatePicker"
                       :ranges="showRanges"
                       :locale-data="locale"
                       :showDropdowns="showDropdowns"
                       :min-date="minDate"
                       :max-date="maxDate"
                       :disabled="disabled"
                       :auto-apply="autoApply"
                       :class="{
                           'disabled-state': disabled
                       }"
                       @update="afterUpdate"
    >
        <div slot="input" slot-scope="picker">
            <div v-if="!!value"
                 class="d-flex align-items-center justify-content-between"
            >
                <span>{{ value }}</span>
                <a href="#"
                   @click.stop="afterUpdate(null)"
                >
                    <i class="fa fa-times"></i>
                </a>
            </div>
            <span v-else>Select date</span>
        </div>
    </date-range-picker>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import Date from "../../../helpers/Date";

export default {
    components: {
        DateRangePicker
    },
    name: 'date-picker',
    props: {
        format: {
            type: String
        },
        input: {},
        value: {},
        name: {
            type: String,
            default: 'date'
        },
        minDate: {
            type: String,
            default: null
        },
        maxDate: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoApply: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            opens: 'center',
            dateRange: {
                startDate: this.value ? this.value : moment().toDate()
            },
            locale: {
                direction: 'center',
                format: this.format,
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: moment.weekdaysMin(),
                monthNames: moment.monthsShort(),
                firstDay: 1
            },
            singleDatePicker: true,
            showWeekNumbers: true,
            showRanges: false,
            showDropdowns: true,
        }
    },
    methods: {
        afterUpdate(value){
            const tempValue = !!value
                ? value.startDate
                : null;

            this.dateRange.startDate = tempValue;
            this.setValue(tempValue);
        },
        formatDate(value){
            return Date.format(value.startDate, this.format);
        },
        setValue(value){
            const date = !!value
                ? Date.format(value, this.format)
                : null;

            this.$emit("input", date);
            this.$emit("update", this.name, date);
        }
    },
    watch: {
        value: {
            handler(value){
                if(!value){
                    this.selected = "";
                    this.dateRange = {};
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="scss">
.vue-daterange-picker {
    width: 100%;

    .reportrange-text {
        width: 100%;

        > div {
            width: 100%;
        }
    }
}
</style>