<template>
    <div class="card card-list">
        <div class="card-header d-flex align-items-center">
            <i class="nav-icon fa fa-clock mr-1"></i> Statuses
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="row">
                    <div class="col-lg-2">Id</div>
                    <div class="col">Agent</div>
                    <div class="col">Name</div>
                    <div class="col">Date</div>
                </div>

                <template v-if="!isLoading" v-for="(item, index) in dispositions">
                    <div :key="index" class="row">
                        <div class="col-12 col-lg-2 align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Id</span>
                            {{ item.id }}
                        </div>
                        <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Agent</span>
                            <span v-if="item.user && item.user.name.length > 0"
                                  class="badge badge-success"
                            >
                                {{ item.user.name }}
                            </span>
                            <span v-else>/</span>
                        </div>
                        <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Name</span>
                            {{ item.status.name }}
                        </div>
                        <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Date</span>
                            {{ utcToLocal(item.created_at) }}
                        </div>
                    </div>
                </template>

                <not-found :items="dispositions"
                           v-if="!isLoading"
                           msg="No dispositions found."
                />

                <loader :show="isLoading"
                        type="small"
                />
            </div>
        </div>

        <div v-if="!isLoading && dispositionsResponse.last_page > 1"
             class="card-footer overflow-x-auto"
        >
            <nav class="wrap">
                <atbl-pagination :pagination="dispositionsResponse"
                                 @paginate="fetchDispositions"
                />
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: 'leads-dispositions-list',
    props: {
        leadId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            dispositions: [],
            dispositionsResponse: {},
            isLoading: false
        };
    },
    async created() {
        await this.fetchDispositions();
    },
    methods: {
        async fetchDispositions(page = 1) {
            if (!this.$can('Leads - Add Status To Leads')) {
                window.showMessage('You does not have permissions for this action.', false);

                return;
            }

            this.isLoading = true;

            try {
                const response = await window.axios.get(`/marketing/leads/${this.leadId}/all-disposition`, {
                    params: {
                        page
                    }
                });

                this.dispositionsResponse = response.data;
                this.dispositions = response.data.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        }
    },
}
</script>