/**
 * Remove undefined properties from object
 * @param obj
 * @return {{}}
 */
function removeUndefinedProperties(obj) {
    return Object.keys(obj || {})
        .reduce((acc, key) => {
            const value = obj[key];

            if (value === null || value === '') {
                return { ...acc };
            }

            if (Array.isArray(value)) {
                return !!value.length
                    ? { ...acc, [key]: value }
                    : {...acc };
            }

            switch (typeof value) {
                case 'object': {
                    const cleanValue = removeUndefinedProperties(value);

                    if (!Object.keys(cleanValue).length) {
                        return { ...acc };
                    }

                    return { ...acc, [key]: cleanValue };
                }
                case 'undefined':
                    return { ...acc };
                default:
                    return { ...acc, [key]: value };
            }
        }, {});
}

export default removeUndefinedProperties;