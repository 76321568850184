import playersState from "./state/playersState";
import playersFundsState from "./state/playersFundsState";
import playersCallHistoryState from "./state/playersCallHistoryState";
import playersPlayState from "./state/playersPlayState";
import playersWalletManagerState from "./state/playersWalletManagerState";

export default {
    ...playersState,
    ...playersFundsState,
    ...playersCallHistoryState,
    ...playersPlayState,
    ...playersWalletManagerState,
};
