<template>
    <div class="row payment-gateways-settings-item">
        <div class="col-12 col-lg">
            <div class="d-none d-lg-flex">
                {{ paymentGateway.gateway.name }}
            </div>
            <h4 class="d-flex d-lg-none mb-3">
                {{ paymentGateway.gateway.name }}
            </h4>
        </div>
        <div class="col-6 col-md text-center text-lg-left">
            <span class="small-mb-badge">Is CRM Active</span>
            <atbl-switch :value="paymentGateway.is_active"
                         :on="true"
                         :off="false"
                         :is-loading="isLoading && editingProperty === 'is_active'"
                         class="mb-0"
                         name="is_active"
                         @update="toggleActiveState(...arguments, paymentGateway)"
            />
        </div>
        <div class="col-6 col-md text-center text-lg-left">
            <span class="small-mb-badge">Is API Active</span>
            <atbl-switch :value="paymentGateway.is_api_active"
                         :on="true"
                         :off="false"
                         :is-loading="isLoading && editingProperty === 'is_api_active'"
                         class="mb-0"
                         name="is_api_active"
                         @update="toggleActiveState(...arguments, paymentGateway)"
            />
        </div>
        <div class="col-6 col-md text-center text-lg-left">
            <span class="small-mb-badge">Is CRM Withdrawal Active</span>
            <atbl-switch :value="paymentGateway.withdrawal_enabled"
                         :on="true"
                         :off="false"
                         :is-loading="isLoading && editingProperty === 'withdrawal_enabled'"
                         class="mb-0"
                         name="withdrawal_enabled"
                         @update="toggleActiveState(...arguments, paymentGateway)"
            />
        </div>
        <div class="col-6 col-md text-center text-lg-left">
            <span class="small-mb-badge">Is API Withdrawal Active</span>
            <atbl-switch :value="paymentGateway.withdrawal_api_enabled"
                         :on="true"
                         :off="false"
                         :is-loading="isLoading && editingProperty === 'withdrawal_api_enabled'"
                         class="mb-0"
                         name="withdrawal_api_enabled"
                         @update="toggleActiveState(...arguments, paymentGateway)"
            />
        </div>
        <div class="col-12 col-md">
            <span class="small-mb-badge">Order</span>
            <div v-if="editingProperty !== `order_${paymentGateway.gateway.key}` || !isLoading" class="payment-gateways-settings-item-order">
                <atbl-input v-model="currentOrder"
                            :disabled="!$can('Payment - Manage settings')"
                            placeholder="Order"
                            name="order"
                            class="mb-0"
                />
                <template v-if="isOrderChanged">
                    <button type="button"
                            class="btn btn-sm btn-danger"
                            @click="currentOrder = paymentGateway.order"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-success"
                            @click="updatePaymentGateway"
                    >
                        <i class="fa fa-check"></i>
                    </button>
                </template>
            </div>
            <loader :show="isLoading && editingProperty === `order_${paymentGateway.gateway.key}`"
                    type="smaller"
            />
        </div>
    </div>
</template>

<script>
    import AtblInput from '../../../components/Forms/Inputs/ATBLInput.vue';
    import AtblSwitch from '../../../components/Forms/Inputs/ATBLSwitch.vue';
    import PaymentService from '../../../services/PaymentService';
    import Loader from '../../../components/Widgets/Loader.vue';
    
    export default {
        name: 'PaymentGatewayItem',
        inject: [
          'onOrderChangeEvent',
        ],
        components: {
            AtblInput,
            AtblSwitch,
            Loader,
        },
        props: {
            paymentGateway: {
                type: Object,
                default: () => ({
                    gateway: {
                        name: null,
                        key: null,
                        is_saving_credit_cards_enabled: null
                    },
                    available_countries: [],
                    excluded_countries: [],
                    is_active: null,
                    is_api_active: null,
                    withdrawal_enabled: null,
                    withdrawal_api_enabled: null,
                    order: null
                }),
            },
        },
        data() {
            return {
                isLoading: false,
                editingProperty: null,
                currentOrder: this.paymentGateway.order,
            };
        },
        computed: {
            isOrderChanged() {
                return this.currentOrder !== this.paymentGateway.order;
            },
        },
        methods: {
            async updatePaymentGateway() {
                this.editingProperty = `order_${this.paymentGateway.gateway.key}`;
                this.isLoading = true;
                
                try {
                    const response = await window.axios.patch(`/payments/${this.paymentGateway.gateway.key}`, {
                        order: this.currentOrder,
                    });
                    const { message, status } = response.data;
                    
                    window.showMessage(message, status);
                    
                    if(!status) {
                        this.isLoading = false;
                        
                        return;
                    }
                    
                    this.onOrderChangeEvent(this.paymentGateway, 'order', this.currentOrder);
                } catch (error) {
                    flashError(error);
                } finally {
                    this.editingProperty = null;
                    this.isLoading = false;
                }
            },
            async toggleActiveState(name, value, gateway) {
                this.editingProperty = name;
                this.isLoading = true;
                
                try {
                    !!value
                        ? await PaymentService.activate(gateway.gateway.key, name, value)
                        : await PaymentService.deactivate(gateway.gateway.key, name, value);
                    
                    this.onOrderChangeEvent(gateway, name, value);
                } catch (e) {
                    console.log(e);
                } finally {
                    this.editingProperty = null;
                    this.isLoading = false;
                }
            },
        },
    }
</script>