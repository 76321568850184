import isString from '../utils/String/IsString';

export default {
    CAVD: "CAVD",
    CAFD: "CAFD",
    CABD: "CABD",
    CMWD: "CMWD",
    CMRF: "CMRF",
    CAWP: "CAWP",
    CACP: "CACP",
    CAHP: "CAHP",
    CPCP: "CPCP",
    CPWP: "CPWP",
    CPHP: "CPHP",
    CPFD: "CPFD",
    CSWN: "CSWN",
    CSVD: "CSVD",
    CSVC: "CSVC",
    amountLimit: 100000,
    getTicketPaymentTypes() {
        return [
            this.CAWP,
            this.CACP,
            this.CAHP,
            this.CPCP,
            this.CPWP,
            this.CPHP,
        ];
    },
    creditCardPaymentTypes() {
        return [
            this.CACP,
            this.CPCP,
        ];
    },
    depositTransactionTypes() {
        return [
            this.CAFD,
            this.CACP,
            this.CPFD,
            this.CPCP,
            this.CAVD
        ];
    },
    bonusTransactionTypes() {
        return [
            this.CABD,
        ];
    },
    isDeposit(type) {
        if (!type || !isString(type)) {
            return false;
        }

        return this.depositTransactionTypes().includes(type);
    },
    isSale(type) {
        if (!type || !isString(type)) {
            return false;
        }

        return this.getTicketPaymentTypes().includes(type);
    },
    isBonus(type) {
        if (!type || !isString(type)) {
            return false;
        }

        return this.bonusTransactionTypes().includes(type);
    },
};