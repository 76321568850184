var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "atbl-checkbox",
      class: {
        disabled: !!_vm.disabled,
        "partial-check": !!_vm.partialCheck && !_vm.isChecked
      }
    },
    [
      _c("input", {
        attrs: { name: _vm.name, type: "checkbox" },
        domProps: { checked: _vm.isChecked },
        on: { change: _vm.changeValue }
      }),
      _vm._v(" "),
      !!_vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }