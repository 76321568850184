var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "general-list" },
    [
      _c("div", { staticClass: "row sticky-element p-0" }, [
        _c("div", { staticClass: "col-6 col-lg" }, [_vm._v("No.")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-lg" }, [
          _c(
            "a",
            {
              staticClass: "sort-link",
              class: { active: _vm.sort.sort === "name" },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.sortChange("name")
                }
              }
            },
            [
              _vm._v("\n        Name "),
              _vm.sort.sort === "name"
                ? _c("span", [
                    _c("i", {
                      staticClass: "fa",
                      class: {
                        "fa-caret-up": _vm.sort.value === "ASC",
                        "fa-caret-down": _vm.sort.value === "DESC"
                      }
                    })
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-lg" }, [_vm._v("Number of Sales")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-lg" }, [
          _c(
            "a",
            {
              staticClass: "sort-link",
              class: { active: _vm.sort.sort === "total" },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.sortChange("total")
                }
              }
            },
            [
              _vm._v("\n        Sales "),
              _vm.sort.sort === "total"
                ? _c("span", [
                    _c("i", {
                      staticClass: "fa",
                      class: {
                        "fa-caret-up": _vm.sort.value === "ASC",
                        "fa-caret-down": _vm.sort.value === "DESC"
                      }
                    })
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-lg" }, [
          _c(
            "a",
            {
              staticClass: "sort-link",
              class: { active: _vm.sort.sort === "deposits" },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.sortChange("deposits")
                }
              }
            },
            [
              _vm._v("\n        Deposits "),
              _vm.sort.sort === "deposits"
                ? _c("span", [
                    _c("i", {
                      staticClass: "fa",
                      class: {
                        "fa-caret-up": _vm.sort.value === "ASC",
                        "fa-caret-down": _vm.sort.value === "DESC"
                      }
                    })
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-lg" }, [
          _c(
            "a",
            {
              staticClass: "sort-link",
              class: { active: _vm.sort.sort === "bonus" },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.sortChange("bonus")
                }
              }
            },
            [
              _vm._v("\n        Bonus "),
              _vm.sort.sort === "bonus"
                ? _c("span", [
                    _c("i", {
                      staticClass: "fa",
                      class: {
                        "fa-caret-up": _vm.sort.value === "ASC",
                        "fa-caret-down": _vm.sort.value === "DESC"
                      }
                    })
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-lg" }, [_vm._v("Percentage")]),
        _vm._v(" "),
        _vm.$can("Reports Agents - View")
          ? _c("div", { staticClass: "col-6 col-lg" }, [
              _vm._v("\n      Actions\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._l(_vm.agents, function(agent, index) {
        return !_vm.isSearching
          ? _c("div", { key: agent.id, staticClass: "row" }, [
              _c("div", { staticClass: "col-6 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [_vm._v("No.")]),
                _vm._v("\n        " + _vm._s(index + 1) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Name")]),
                _vm._v("\n        " + _vm._s(agent.name) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Num. of Sales")
                ]),
                _vm._v("\n        " + _vm._s(agent.count) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Sales")
                ]),
                _vm._v(
                  "\n        " + _vm._s(_vm.total(agent.total)) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Deposits")
                ]),
                _vm._v(
                  "\n        " + _vm._s(_vm.total(agent.deposits)) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Bonus")
                ]),
                _vm._v(
                  "\n        " + _vm._s(_vm.total(agent.bonus)) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Percents")
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.percents(agent.total)) +
                    " %\n      "
                )
              ]),
              _vm._v(" "),
              _vm.$can("Reports Agents - View")
                ? _c("div", { staticClass: "col-12 col-lg" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Actions")
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.openAgent($event, agent)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-eye" }),
                        _vm._v(" View Agent\n        ")
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      !_vm.isSearching && _vm.agents.length
        ? _c(
            "div",
            { staticClass: "row general-list-row last font-weight-bold" },
            [
              _c("div", { staticClass: "col-12 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Total")
                ]),
                _vm._v("\n      Total: " + _vm._s(_vm.agents.length) + "\n    ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-none d-lg-flex col-lg" }, [
                _vm._v(" ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Total Sales Count")
                ]),
                _vm._v("\n      " + _vm._s(_vm.sales.countAll) + "\n    ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Total Sales")
                ]),
                _vm._v(
                  "\n      " + _vm._s(_vm.total(_vm.sales.totalAll)) + "\n    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Total Deposits")
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.total(_vm.sales.totalDeposits)) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Total Bonus")
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.total(_vm.sales.totalBonus)) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Total Percents")
                ]),
                _vm._v(
                  "\n      " + _vm._s(_vm.sales.totalSalesPercent) + " %\n    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-none d-lg-flex col-lg" }, [
                _vm._v(" ")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isSearching
        ? _c("not-found", {
            attrs: { items: _vm.agents, msg: "No agents found." }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isSearching, type: "small" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }