<template>
    <div class="container-fluid free-games-settings">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                    <i class="fa fa-gamepad mr-1"></i> Free Games Settings
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg text-center text-lg-left">ID</div>
                        <div class="col-12 col-lg text-center">Name</div>
                        <div class="col-12 col-lg text-center">Range Type</div>
                        <div class="col-12 col-lg text-center">Used Tickets</div>
                        <div class="col-12 col-lg text-center">Tickets</div>
                        <div class="col-12 col-lg text-center">Lines</div>
                        <div class="col-12 col-lg text-center">Fractions</div>
                        <div class="col-12 col-lg text-center">Shares</div>
                        <div class="col-12 col-lg text-center">Operations</div>
                    </div>

                    <template v-if="!isLoading && !!users.length">
                        <div v-for="(item, index) in users"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-lg text-center text-lg-left mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">ID</span>
                                {{ item.id }}
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">User</span>
                                {{ item.name }}
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Range Type</span>
                                <span :class="['badge', `badge-${rangeType(item)}`]">
                                    {{ !!item.free_games_settings && item.free_games_settings.range_type ? ucFirst(item.free_games_settings.range_type) : '/' }}
                                </span>
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Used Tickets</span>
                                <span :class="`badge badge-${hasUsedAllTickets(item) ? 'warning' : rangeType(item)}`">
                                    {{ item.used_tickets }}
                                </span>
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Tickets</span>
                                <span v-if="!!item.free_games_settings">
                                    <span v-if="!!has(item, 'tickets')">
                                        <span :class="`badge badge-${hasUsedAllTickets(item) ? 'warning' : rangeType(item)}`">
                                            {{ item.free_games_settings.tickets }}
                                        </span>
                                    </span>
                                    <span v-else>
                                        &infin;
                                    </span>
                                </span>
                                <span v-else
                                      class="text-danger"
                                      v-tooltip="{
                                        content: 'Cannot create free games for any player.'
                                      }"
                                >
                                    <i class="fa fa-ban"></i>
                                </span>
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Lines</span>
                                <span v-if="!!item.free_games_settings">
                                    <span v-if="!!has(item, 'lines')">
                                        {{ item.free_games_settings.lines }}
                                    </span>
                                    <span v-else>
                                        &infin;
                                    </span>
                                </span>
                                <span v-else
                                      class="text-danger"
                                      v-tooltip="{
                                        content: 'Cannot create free games for any player.'
                                      }"
                                >
                                    <i class="fa fa-ban"></i>
                                </span>
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Fractions</span>
                                <span v-if="!!item.free_games_settings">
                                    <span v-if="!!has(item, 'fractions')">
                                        {{ item.free_games_settings.fractions }}
                                    </span>
                                    <span v-else>
                                        &infin;
                                    </span>
                                </span>
                                <span v-else
                                      class="text-danger"
                                      v-tooltip="{
                                        content: 'Cannot create free games for any player.'
                                      }"
                                >
                                    <i class="fa fa-ban"></i>
                                </span>
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Shares</span>
                                <span v-if="!!item.free_games_settings">
                                    <span v-if="!!has(item, 'shares')">
                                        {{ item.free_games_settings.shares }}
                                    </span>
                                    <span v-else>
                                        &infin;
                                    </span>
                                </span>
                                <span v-else
                                      class="text-danger"
                                      v-tooltip="{
                                        content: 'Cannot create free games for any player.'
                                      }"
                                >
                                    <i class="fa fa-ban"></i>
                                </span>
                            </div>
                            <div class="col-12 col-lg text-center">
                                <span class="small-mb-badge m-0">Operations</span>
                                <div class="d-flex align-items-center justify-content-center">
                                    <button v-if="$can('Users - Free Games Settings Create/Edit')"
                                            class="btn btn-link btn-delete btn-sm mr-2"
                                            @click="onEditFreeGameSetting(item)"
                                    >
                                        <i class="fa"
                                           :class="{
                                               'fa-pencil-alt': item.free_games_settings,
                                               'fa-plus': !item.free_games_settings
                                           }">
                                        </i>
                                    </button>
                                    <confirmable-remove-button v-if="$can('Users - Free Games Settings Delete') && !!item.free_games_settings"
                                                               :value="item.id"
                                                               @confirmed="removeFreeGameSetting(item.free_games_settings.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />
                    <not-found v-if="!isLoading"
                               msg="No users found."
                               :items="users"
                    />
                </div>
            </div>
        </div>

        <create-update-free-game-setting-form :show="createUpdateFreeGameSettingsForm"
                                              :model="createUpdateFreeGameSettingsModel"
                                              :errors="createUpdateFreeGameSettingsModelErrors"
                                              :loader="createUpdateFreeGameSettingsLoader"
                                              :can-save="canSave"
        />
    </div>
</template>

<script>
import CreateUpdateFreeGameSetting from './CreateUpdateFreeGameSetting/CreateUpdateFreeGameSetting';
import CreateUpdateFreeGameSettingForm from './CreateUpdateFreeGameSetting/CreateUpdateFreeGameSettingForm';

export default {
    name: 'UserFreeGamesSettings',
    mixins: [
        CreateUpdateFreeGameSetting,
    ],
    provide() {
        return {
            // CreateUpdateFreeGameSetting
            toggleCreateUpdateFreeGamesForm: this.toggleCreateUpdateFreeGamesForm,
            clearCreateUpdateFreeGamesModel: this.clearCreateUpdateFreeGamesModel,
            updateRangeType: this.updateRangeType,
            updateFreeGameSettingModel: this.updateFreeGameSettingModel,
            saveFreeGameSetting: this.saveFreeGameSetting,
            updateFreeGameLines: this.updateFreeGameLines,
        };
    },
    components: {
        CreateUpdateFreeGameSettingForm,
    },
    data(){
        return {
            isLoading: false,
            users: []
        };
    },
    methods: {
        async fetchUsers(){
            this.isLoading = true;

            try {
                const response = await window.axios.get('/users/free-games-settings');

                this.users = response.data;

                this.isLoading = false;
            } catch (error) {
                window.flashError(error)
                this.isLoading = false;
            }
        },
        async removeFreeGameSetting(id) {
            this.isLoading = true;

            if(!id) {
                return window.flash('Id is not defined.');
            }

            try {
                const response = await window.axios.delete(`/users/free-games-settings/${id}`);
                const { status, message } = response.data;

                if (status) {
                    await this.fetchUsers();
                }

                window.showMessage(message, status);
            } catch (error) {
                window.flashError(error)
                this.isLoading = false;
            }
        },
        has(item, key = 'tickets'){
            if(!item.free_games_settings) {
                return false;
            }

            return !!item.free_games_settings[key];
        },
        hasUsedAllTickets(item) {
            return this.has(item) && item.free_games_settings.tickets === item.used_tickets;
        },
        rangeType(item){
            if(!item.free_games_settings) {
                return 'danger';
            }

            switch(item.free_games_settings.range_type) {
                case 'daily':
                    return 'primary';
                case 'weekly':
                    return 'warning';
                case 'monthly':
                    return 'success';
                default:
                    return 'danger';
            }
        }
    },
    created() {
        if(this.$can('Users - Free Games Settings View')) {
            this.fetchUsers();
        }
    },
}
</script>