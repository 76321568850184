var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.player.transaction_id &&
    !!_vm.player.transaction_payment_type &&
    !!_vm.player.transaction_created_at
    ? _c("div", [
        _c("span", { staticClass: "badge badge-success p-1" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.isPurchase ? "Purchase" : "Deposit") +
              "\n        "
          ),
          _vm.isPurchase
            ? _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.isCardPayment ? "CC" : "W") +
                    "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(
            "\n        " +
              _vm._s(_vm.utcToLocal(_vm.player.transaction_created_at)) +
              "\n    "
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }