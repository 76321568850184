<template>
    <div class="card card-list reports reports-agents m-0 border-0">
        <div :class="{
                 'p-0': !!hasAgents
             }"
             class="card-body"
        >
            <atbl-tabs v-if="!isLoading && !!hasAgents"
                       :vertical="true"
                       @change-tab="updateTab"
            >
                <atbl-tab v-for="(agent, index) in agents"
                          :key="`user_list_${agent}`"
                          :title="agent"
                >
                    <div class="general-list">
                        <div class="row no-gutters">
                            <div></div>
                            <div class="col">
                                <a href="#" @click.prevent="sortChange('name')" class="sort-link" :class="{'active': sort.column === 'name'}">
                                    Game <span v-if="sort.column === 'name'"><i class="fa" :class="{'fa-caret-up': sort.direction === 'ASC', 'fa-caret-down': sort.direction === 'DESC'}"></i> </span>
                                </a>
                            </div>
                            <div class="col">
                                <a href="#" @click.prevent="sortChange('tickets_count')" class="sort-link" :class="{'active': sort.column === 'tickets_count'}">
                                    Tickets <span v-if="sort.column === 'tickets_count'"><i class="fa" :class="{'fa-caret-up': sort.direction === 'ASC', 'fa-caret-down': sort.direction === 'DESC'}"></i> </span>
                                </a>
                            </div>
                            <div class="col">
                                <a href="#" @click.prevent="sortChange('lines_count')" class="sort-link" :class="{'active': sort.column === 'lines_count'}">
                                    Lines <span v-if="sort.column === 'lines_count'"><i class="fa" :class="{'fa-caret-up': sort.direction === 'ASC', 'fa-caret-down': sort.direction === 'DESC'}"></i> </span>
                                </a>
                            </div>
                            <div class="col">
                                <a href="#" @click.prevent="sortChange('free_games_count')" class="sort-link" :class="{'active': sort.column === 'free_games_count'}">
                                    Free Games <span v-if="sort.column === 'free_games_count'"><i class="fa" :class="{'fa-caret-up': sort.direction === 'ASC', 'fa-caret-down': sort.direction === 'DESC'}"></i> </span>
                                </a>
                            </div>
                        </div>

                        <div v-for="game in agentsReports[index]"
                             :key="`user_game_${agent}_${game.id}`"
                             class="row p-1 no-gutters"
                        >
                            <div>
                                <img :src="$imageService.gameImage(game.image)"
                                     :alt="game.image"
                                     class="game-thumb"
                                />
                            </div>
                            <div class="col-12 col-lg">
                                <span class="small-mb-badge">Game</span>
                                {{ game.name }}
                            </div>
                            <div class="col-12 col-lg">
                                <span class="small-mb-badge">Tickets</span>
                                {{ game.tickets_count }}
                            </div>
                            <div class="col-12 col-lg">
                                <span class="small-mb-badge">Lines</span>
                                {{ game.lines_count }}
                            </div>
                            <div class="col-12 col-lg">
                                <span class="small-mb-badge">Free Games</span>
                                {{ game.free_games_count }}
                            </div>
                        </div>
                    </div>
                </atbl-tab>
            </atbl-tabs>

            <loader :show="isLoading"
                    type="small"
            />

            <not-found v-if="!isLoading && !hasAgents"
                       msg="No results found"
                       :items="[]"
                       class="w-100"
            />
        </div>
    </div>
</template>

<script>
    import ReportsGamesService from "../../../../../services/ReportsGamesService";
    import AtblTab from '../../../../../components/AtblTabs/AtblTab.vue';
    import AtblTabs from '../../../../../components/AtblTabs/AtblTabs.vue';

    export default {
        name: "ReportsAgents",
        components: {
			AtblTabs,
            AtblTab,
        },
        data() {
            return {
				agents: [],
                agentsReports: [],
                isLoading: false,
                sort: {
                    column: "lines_count",
                    direction: "DESC",
                },
                activeTab: 0,
            };
        },
        computed: {
            hasAgents() {
                return !!this.agents.length;
            },
        },
	    async created() {
		    await this.getGamesAgentsReports();
			
		    Atbl.$on("gameFilter", async (month) => {
			    await this.getGamesAgentsReports({
				    month
			    });
		    });
	    },
        methods: {
            async getGamesAgentsReports(_params = {}) {
                this.isLoading = true;
                
				try {
					const response = await ReportsGamesService.getAgentsReports(_params);
					const { data } = response;

					this.agents = Object.keys(data);
					this.agentsReports = this.updateSortedData(Object.values(data), this.activeTab, this.sort);
				} catch (error) {
					window.flashError(error);
				} finally {
					this.isLoading = false;
				}
            },
            sortChange(column, direction = null){
                let orderDirection = direction
                    ? direction
                    : (this.sort.direction === "ASC" ? "DESC" : "ASC");

                this.sort = {
	                column,
	                direction: orderDirection
                };

                this.agentsReports = this.updateSortedData(this.agentsReports, this.activeTab, this.sort);
            },
            updateTab(value){
                this.activeTab = value;
                this.agentsReports = this.updateSortedData(this.agentsReports, value, this.sort);
            },
            updateSortedData(reports, index, {column: sortColumn, direction: sortDirection}) {
				return reports.map((games, gameIndex) => {
					return gameIndex === index
                        ? this.dynamicSort(games, sortColumn, sortDirection)
                        : games;
                })
            }
        }
    }
</script>