<template>
    <div>
        <div class="row" :class="{'border-bottom': shouldShow(field.key)}" v-for="(field, index) in fields" :key="`action_field_${field.key}_${index}`">
            <div class="col-12" v-if="shouldShow(field.key)">
                <bonus-action-field :config="getFieldConfiguration(field.key)" v-model="fields[index]" @input="sanitizeValues" :disabled="disableFields" />
            </div>
        </div>
    </div>
</template>

<script>
import BonusActionField from './ActionField';

export default {
    name: 'bonus-action-field-wrapper',

    components: { BonusActionField },

    props: ['value', 'actionFieldsConfig', 'disableFields'],

    data() {
        return {
            fields: this.value,
        };
    },

    watch: {
        actionFieldsConfig(v) {
            this.fields = [];
        },

        value: {
            deep: true,
            handler(v) {
                this.fields = v;
            }
        },
    },

    methods: {
        getFieldConfiguration(key) {
            return this.actionFieldsConfig.find(f => f.key === key);
        },

        sanitizeValues() {
            this.fields = this.fields.map(f => {
                const field = this.getFieldConfiguration(f.key);

                if (!this.shouldShow(f.key)) {
                    f.value = field.type === 'checkbox' ? false : null;
                }

                return f;
            });
        },

        shouldShow(key) {
            const field = this.getFieldConfiguration(key);

            // Show if field does not have any rules
            if (!field.rules.length) {
                return true;
            }

            const showRules = this.getRules(field, 'show');
            const total = showRules.reduce((a, r) => {
                const [rule, ruleDefinition] = r.split(':');

                const isNegation = ruleDefinition.startsWith('!');
                const [originalFieldKey, compareValue] = ruleDefinition.split(',');
                const fieldKey = isNegation ? originalFieldKey.substring(1) : originalFieldKey;
                const fieldValue = this.getFieldValue(fieldKey);

                if (!this.isFieldCheckbox(fieldKey)) {
                    if (!compareValue) {
                        if (fieldValue && !isNegation) {
                            return a + 1;
                        }

                        if (!fieldValue && isNegation) {
                            return a + 1;
                        }

                        return a;
                    }

                    if (fieldValue === compareValue && !isNegation) {
                        return a + 1;
                    }

                    if (fieldValue !== compareValue && isNegation) {
                        return a + 1;
                    }

                    return a;
                }

                if (fieldValue && !isNegation) {
                    return a + 1;
                }

                if (!fieldValue && isNegation) {
                    return a + 1;
                }

                return a;
            }, 0);

            return total === showRules.length;
        },

        getRules(field, rule) {
            return field.rules.filter(f => {
                return f.startsWith(`${rule}:`);
            });
        },

        isFieldCheckbox(key) {
            const field = this.actionFieldsConfig.find(f => f.key === key);
            if (field) {
                return field.type === 'checkbox';
            }

            return false;
        },

        getFieldValue(key) {
            const field = this.fields.find(f => f.key === key);
            if (field) {
                return field.value;
            }

            return null;
        },
    },
}
</script>