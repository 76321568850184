<template>
    <div v-if="!!player.disposition_created_at && !!player.disposition_name">
        <span class="font-weight-bold mr-1">Disposition</span>
        <a :href="`/players/${player.id}/dispositions`" target="_blank" class="badge badge-primary">
            {{ player.disposition_name }}
        </a>
        <br />
        <small class="follow-up">
            <strong>Created At:</strong> {{ player.disposition_created_at }}
        </small>
        <br />
        <small v-if="!!player.disposition_follow_up"
               class="follow-up"
        >
            <strong>Follow Up:</strong> {{ player.disposition_follow_up }}
        </small>
    </div>
</template>

<script>
export default {
    name: 'LatestDisposition',
    props: {
        player: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>