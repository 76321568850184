<template>
    <atbl-modal title="Add new attachment" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-6">
                <atbl-form-group label="Kyc attachment type" class="font-weight-bold" :horizontal="false">
                    <select class="form-control" v-model="kycAttachmentType" @change="resetFiles">
                        <option :value="null">Select attachment type</option>
                        <option v-for="availableKycAttachmentType in availableKycAttachmentTypes" :value="availableKycAttachmentType">{{ availableKycAttachmentType.type }}</option>
                    </select>
                </atbl-form-group>
            </div>

            <div class="col-12">
                <div class="form-group-atbl m-0 py-3">
                    <label>Attachment (front)</label>
                    <div class="form-group position-relative m-0" v-if="! selectedFileFront">
                        <input type="file" class="form-control-file" accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref="attachmentFileFrontInput" @change="handleAttachmentUpload('front')" />
                    </div>

                    <span v-if="selectedFileFront" class="d-block">
                        <i class="fa fa-upload" /> <strong>{{ selectedFileFront.name }}</strong>
                    </span>
                </div>
            </div>

            <div class="col-12" v-if="kycAttachmentType && kycAttachmentType.is_double_sided">
                <div class="form-group-atbl m-0 py-3">
                    <label>Attachment (back)</label>
                    <div class="form-group position-relative m-0" v-if="! selectedFileBack">
                        <input type="file" class="form-control-file" accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref="attachmentFileBackInput" @change="handleAttachmentUpload('back')" />
                    </div>

                    <span v-if="selectedFileBack" class="d-block">
                        <i class="fa fa-upload" /> <strong>{{ selectedFileBack.name }}</strong>
                    </span>
                </div>
            </div>

            <div class="col-6">
                <atbl-form-group label="Expires at" :label-cols="3" class="font-weight-bold" :horizontal="false">
                    <date-range-picker v-model="expiresAt"
                                       :singleDatePicker="true"
                                       :autoApply="true"
                                       :ranges="false"
                                       :minDate="minDate"
                                       :showDropdowns="true">
                        <div slot="input" slot-scope="picker">
                            <span v-if="expiresAtFormatted">{{ expiresAtFormatted }} <i title="Clear date" @click="clearExpiresAt" style="margin-left: 8px" class="fa fa-times"></i></span>
                            <span v-else>Select date</span>
                        </div>
                    </date-range-picker>
                </atbl-form-group>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-primary" @click="confirm" :disabled="! canSubmit">
                Confirm
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import moment from 'moment';
import Date from '../../../../../helpers/Date';
import DateRangePicker from 'vue2-daterange-picker';
import KycService from '../../../../../services/KycService';
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'add-new-kyc-attachment-modal',

    components: { DateRangePicker, AtblModal, },

    data() {
        return {
            minDate: moment().toDate(),
            availableKycAttachmentTypes: [],

            selectedFileFront: null,
            selectedFileBack: null,
            kycAttachmentType: null,
            expiresAt: {
                startDate: null,
            },
        };
    },

    computed: {
        expiresAtFormatted() {
            return this.expiresAt.startDate ? Date.format(this.expiresAt.startDate, 'YYYY-MM-DD') : null;
        },

        canSubmit() {
            if (!this.kycAttachmentType) {
                return false;
            }

            const hasSelectedAllFiles = !this.kycAttachmentType.is_double_sided ? !!this.selectedFileFront : !!this.selectedFileBack && !!this.selectedFileBack;
            return this.expiresAtFormatted && hasSelectedAllFiles;
        },
    },

    mounted() {
        this.fetchPlayerKycAttachmentTypes();
    },

    methods: {
        fetchPlayerKycAttachmentTypes() {
            KycService.fetchKycAttachmentTypes().then((response) => {
                this.availableKycAttachmentTypes = response.data;
            });
        },

        handleAttachmentUpload(side) {
            if (side === 'back') {
                this.selectedFileBack = this.$refs.attachmentFileBackInput.files[0];
                return;
            }

            this.selectedFileFront = this.$refs.attachmentFileFrontInput.files[0];
        },

        clearExpiresAt() {
            this.expiresAt.startDate = null;
            this.expiresAt.endDate = null;
        },

        resetFiles() {
            this.selectedFileFront = null;
            this.selectedFileBack = null;
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },

        confirm() {
            const sides = ['front', ...(this.kycAttachmentType.is_double_sided ? ['back'] : [])]

            const formData = new FormData();
            sides.forEach((side, index) => {
                const file = side === 'front' ? this.selectedFileFront : this.selectedFileBack;
                if (!file) {
                    return;
                }

                formData.append(`kycAttachments[${index}][attachment]`, file);
                formData.append(`kycAttachments[${index}][kycAttachmentType]`, this.kycAttachmentType.id);
                formData.append(`kycAttachments[${index}][expiresAt]`, this.expiresAt.startDate ? moment(this.expiresAt.startDate).format('YYYY-MM-DD') : null);
                formData.append(`kycAttachments[${index}][isBackSide]`, side === 'back');
            });

            this.$emit('confirm', formData);
        },
    }
}
</script>