<template>
    <div class="container-fluid">
        <search-email-templates-component v-if="showSearchForm"
                                          :external-types="externalTypes"
                                          :search-model="emailTemplatesSearchModel"
                                          :email-types="emailTypes"
        />

        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span>
                    <a v-if="!!category_id && !isLoading"
                       href="#"
                       @click.prevent="onCategoryChangeEvent()"
                    >
                        <i class='fa fa-chevron-left mr-2'></i>
                    </a>
                    <i class='fa fa-envelope'></i> Email Templates
                </span>
                <div class="d-flex align-items-center">
                    <button :class="`${!!showSearchForm ? 'btn-outline-primary' : 'btn-primary'}`"
                              class="btn btn-sm mr-2"
                              @click="showSearchForm = !showSearchForm"
                    >
                        <i class="fa fa-filter m-0"></i>
                    </button>
                    <button v-if="$can('Email Templates - Create')"
                              :disabled="!emailTemplateUserTypes.length"
                              class="btn btn-sm btn-primary"
                              @click="createFormShown = true"
                    >
                        Create
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-1 text-center text-lg-left">ID</div>
                        <div class="col-12 col-lg text-center">Name</div>
                        <div class="col-12 col-lg-2 text-center">Visible for</div>
                        <div class="col-12 col-lg-2 text-center">Channel</div>
                        <div class="col-12 col-lg-2 text-center">Operations</div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(item, index) in emailTemplates"
                             :key="index"
                             class="row"
                             :class="{
                                 'bg-light': !!item.children_count
                             }"
                        >
                            <div class="col-12 col-lg-1 text-center text-lg-left mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">ID</span>
                                {{ item.id }}
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Name</span>
                                <span v-if="!!item.children_count"
                                      class="badge badge-dark"
                                         v-tooltip="{
                                            content: 'Category'
                                         }"
                                >
                                    C
                                </span>
                                <a href="#"
                                   @click.prevent="onCategoryChangeEvent(item.id)"
                                >
                                    {{ item.name }}
                                </a>
                                <br />
                                <small class="text-muted">
                                    {{ $options.Elipsis(item.description, 50) }}
                                </small>
                            </div>
                            <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                                <span v-if="!item.players_enabled && !item.leads_enabled"
                                      class="badge badge-danger"
                                >
                                    /
                                </span>
                                <span v-if="!!item.players_enabled"
                                      class="badge badge-primary mr-1"
                                >
                                    Players
                                </span>
                                <span v-if="!!item.leads_enabled" class="badge badge-warning mr-1"
                                >
                                    Leads
                                </span>
                            </div>
                            <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Channel</span>
                                <div v-for="(item, index) in resolveChannels(item)"
                                     :key="index"
                                     class="d-flex justify-content-center align-items-center mb-1 border p-1"
                                >
                                    <span :class="['badge', `badge-${item.nameColor}`]"
                                          class="mr-1"
                                    >
                                        {{ item.name }}
                                    </span>
                                    <span :class="['badge', `badge-${item.typeColor}`]"
                                    >
                                        {{ item.type }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2 text-center">
                                <span class="small-mb-badge m-0">Operations</span>
                                <div class="d-flex align-items-center justify-content-center">
                                    <button class="btn btn-link btn-delete btn-sm mr-2"
                                            v-if="$can('Email Templates - Details')"
                                            @click="fetchEmailTemplateDetails(item)"
                                    >
                                        <i class="fa fa-pencil-alt"></i>
                                    </button>
                                    <confirmable-remove-button :value="item.id"
                                                               v-if="!item.children_count && $can('Email Templates - Remove')"
                                                               @confirmed="removeEmailTemplate"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>

                    <not-found v-if="!isLoading"
                               :items="emailTemplates"
                               msg="No email templates found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>

            <div v-if="!isLoading && emailTemplatesResponse.last_page > 1"
                 class="card-footer overflow-x-auto"
            >
                <nav class="wrap">
                    <atbl-pagination :pagination="emailTemplatesResponse"
                                     @paginate="fetchEmailTemplates"
                    />
                </nav>
            </div>
        </div>

        <create-update-email-templates-component v-if="!!createFormReady"
                                                 :email-types="emailTypes"
                                                 :show="createFormShown"
                                                 :is-loading="createFormLoading"
                                                 :email-model="emailModel"
                                                 :email-errors="emailError"
                                                 :active-source-type="activeSourceType"
                                                 :user-types="emailTemplateUserTypes"
                                                 :languages="languages"
        />
    </div>
</template>

<script>
import CreateUpdateEmailTemplatesComponent from './CreateUpdateEmailTemplates/CreateUpdateEmailTemplatesComponent';
import CreateUpdateEmailTemplates from './CreateUpdateEmailTemplates/CreateUpdateEmailTemplates';
import SearchEmailTemplatesComponent from './SearchEmailTemplates/SearchEmailTemplatesComponent';
import SearchEmailTemplates from './SearchEmailTemplates/SearchEmailTemplates';
import { clone } from '../../utils/Json/Json';
import EmailTemplatesUserTypes from './EmailTemplatesUserTypes';
import EmailTemplatesLanguages from './EmailTemplatesLanguages';
import EmailTemplateExternalTypesConfiguration from './EmailTemplateExternalTypesConfiguration';
import Elipsis from '../../utils/Text/Elipsis';
import ResolveDetailsMetaToLocalMeta from './CreateUpdateEmailTemplates/ResolveDetailsMetaToLocalMeta';

export default {
    name: 'EmailTemplates',
    Elipsis,
    mixins: [
        CreateUpdateEmailTemplates,
        SearchEmailTemplates,
        EmailTemplatesUserTypes,
        EmailTemplatesLanguages,
    ],
    provide() {
        return {
            // main
            fetchEmailTemplates: this.fetchEmailTemplates,
            // CreateUpdateEmailTemplates
            toggleCreateUpdateForm: this.toggleCreateUpdateForm,
            onSaveEmailTemplate: this.onSaveEmailTemplate,
            updateModel: this.updateModel,
            updateUserTypeModel: this.updateUserTypeModel,
            updateUserTypeInternalLinkModel: this.updateUserTypeInternalLinkModel,
            updateType: this.updateType,
            updateSource: this.updateSource,
            updateUserTypeModelMetaField: this.updateUserTypeModelMetaField,
            updateUserTypeModelMetaAttributeField: this.updateUserTypeModelMetaAttributeField,
            updateActiveSourceType: this.updateActiveSourceType,
            // SearchEmailTemplates
            updateSearchModel: this.updateSearchModel,
            clearSearchModel: this.clearSearchModel,
        };
    },
    components: {
        CreateUpdateEmailTemplatesComponent,
        SearchEmailTemplatesComponent,
    },
    data() {
        return {
            emailTypes: [
                'Internal',
                'External',
            ],
            externalTypes: clone(EmailTemplateExternalTypesConfiguration),
            emailTemplates: [],
            emailTemplatesResponse: {},
            languages: [],
            isLoading: true,
            showSearchForm: false,
            category_id: null,
            visitedCategories: [],
        };
    },
    async created() {
        this.category_id = this.getParam('category_id');
        this.addVisitedCategory(this.category_id);

        await this.fetchLanguages();
        await this.fetchEmailTemplateUserTypes();
        await this.initializeConfiguration();
        await this.fetchEmailTemplates();
    },
    methods: {
        addVisitedCategory(categoryId) {
            if (!categoryId) {
                return;
            }

            this.visitedCategories = [
                ...this.visitedCategories,
                categoryId
            ];
        },
        async onCategoryChangeEvent(category_id = null) {
            this.addVisitedCategory(category_id);

            let categoryToGo = category_id;

            let query = null;
            if (!!category_id) {
                query = `?category_id=${category_id}`;
            } else {
                const lastVisitedCategory = this.visitedCategories.slice(-1)[0];
                this.visitedCategories = this.visitedCategories.filter(item => item !== lastVisitedCategory);

                categoryToGo = this.visitedCategories.slice(-1)[0];

                query = !this.visitedCategories.length
                    ? ''
                    : `?category_id=${categoryToGo}`;
            }

            this.category_id = categoryToGo;

            window.history.pushState({}, '', `/email-templates/view${query}`);

            await this.fetchEmailTemplates();
        },
        async fetchEmailTemplates(page = 1) {
            this.isLoading = true;

            try {
                const response = await window.axios.get('/email-templates/list', {
                    params: {
                        ...this.emailTemplatesSearchModel,
                        page,
                        category_id: this.category_id
                    }
                });

                this.emailTemplates = response.data.data;
                this.emailTemplatesResponse = response.data;

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);
                this.isLoading = false;
            }
        },
        async fetchEmailTemplateDetails(item) {
            this.createFormShown = true;
            this.createFormLoading = true;

            try {
                const response = await window.axios.get(`/email-templates/${item.id}`);
                const { data, data: { meta } } = response.data;

                this.emailModel = {
                    ...data,
                    meta: ResolveDetailsMetaToLocalMeta(
                        this.resolvedEmailModel,
                        meta,
                        this.emailTemplateUserTypes
                    )
                };

                this.createFormLoading = false;
            } catch (error) {
                window.flashError(error);
                this.createFormLoading = false;
            }
        },
        async removeEmailTemplate(id) {
            this.isLoading = true;

            try {
                const response = await window.axios.delete(`/email-templates/${id}`);
                const { status, message } = response.data;

                await this.fetchEmailTemplates();

                window.showMessage(message, status);

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);
                this.isLoading = false;
            }
        },
        async initializeConfiguration() {
            this.emailModel.meta = await this.resolveMetaUserTypes(this.emailTemplateUserTypes);

            this.externalTypes = await this.resolveExternalTypesLanguages(this.languages, this.externalTypes);

            this.emailModel = await this.resolveExternalTypeByUserTypeMeta(this.emailModel, this.externalTypes);

            this.resolvedEmailModel = await clone(this.emailModel);

            this.createFormReady = true;
        },
        resolveChannels(emailTemplate) {
            if (!emailTemplate) {
                return [];
            }

            if (!emailTemplate.meta) {
                return [];
            }

            const meta = emailTemplate.meta;

            return Object.keys(meta)
                .map(item => {
                    const { type, type_source } = meta[item];

                    const channelName = item.replace('App\\', '');

                    const typeTemp = !type ? 'Internal' : type;
                    const channelType = typeTemp !== 'Internal'
                        ? type_source
                        : typeTemp;

                    return {
                        name: channelName,
                        type: channelType,
                        nameColor: channelName === 'Leads' ? 'warning' : 'primary',
                        typeColor: channelType === 'Internal' ? 'dark' : 'success',
                    };
                });
        },
    },
}
</script>