<template>
  <div class="atbl-switch"
       :class="{
           'disabled': !!disabled || !!isLoading,
           small: !!small,
       }"
  >
    <input :id="name"
           :name="name"
           class="atbl-switch-toggle"
           type="checkbox"
    />
    <label :for="name"
           :class="[color, {checked}]"
           @click.prevent="change"
    >
      <loader :show="isLoading"
              type="smaller"
      />
    </label>
    <small v-if="label">
      <b>{{ label }}</b>
      <i v-if="tooltip"
         class="fa fa-info-circle"
         v-tooltip="{content: tooltip}"
      ></i>
    </small>
  </div>
</template>

<script>
	export default {
		name: 'ATBLSwitch',
		emits: [
			'input',
			'update',
		],
		props: {
			value: {
				type: [String, Number, Boolean],
				default: 0,
			},
			off: {
				type: [String, Number, Boolean],
				default: 0,
			},
			on: {
				type: [String, Number, Boolean],
				default: 1,
			},
			color: {
				type: String,
				default: 'default',
				validator: function (value) {
					return [
						'default',
						'primary',
						'secondary',
						'success',
						'danger',
						'warning',
						'info',
						'light',
						'dark',
					].indexOf(value) !== -1;
				}
			},
			label: {
				type: String,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			},
			tooltip: {
				type: String,
				default: null,
			},
			name: {
				type: String,
				required: true
			},
			isLoading: {
				type: Boolean,
				required: false
			},
			small: {
				type: Boolean,
				required: false
			},
		},
		computed: {
            checked() {
              return this.value === this.on;
            },
		},
		methods: {
			change() {
              const value = this.value === this.on
					? this.off
					: this.on;
              
              this.$emit('input', value);
              this.$emit('update', this.name, value);
            }
		}
	};
</script>