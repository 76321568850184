var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "free-games" },
    [
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "free-games-content" },
            _vm._l(_vm.games, function(item, index) {
              return _c(
                _vm.$options
                  .ResolveFreeGameType(
                    item.gameable_type,
                    item.gameable.key ? item.gameable.key : item.gameable.slug
                  )
                  .toLowerCase(),
                {
                  key: index,
                  tag: "component",
                  attrs: { item: item, "order-id": _vm.orderId }
                }
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.loading, type: "small" } }),
      _vm._v(" "),
      _c("not-found", {
        attrs: { items: _vm.games, msg: "There are no free games created." }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }