var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      attrs: {
        title: "Create/Update Free Game Settings",
        value: _vm.show,
        "body-class": "" + (_vm.show ? "pt-0" : "")
      },
      on: { input: _vm.closeForm },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-outline-danger mr-2",
                  attrs: { disabled: _vm.loader },
                  on: { click: _vm.closeForm }
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { disabled: _vm.loader || !_vm.canSave },
                  on: { click: _vm.saveFreeGameSetting }
                },
                [
                  !_vm.loader
                    ? _c("span", [
                        _vm._v("\n                Save\n            ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", { attrs: { show: _vm.loader, type: "smaller" } })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h5", { staticClass: "title" }, [_vm._v("General")]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
          _vm._v("ID")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg-6" }, [
          _vm._v(_vm._s(_vm.model.id))
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
          _vm._v("Name")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg-6" }, [
          _vm._v(_vm._s(_vm.model.name))
        ])
      ]),
      _vm._v(" "),
      _c("h5", { staticClass: "title" }, [_vm._v("Free games settings")]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("atbl-select", {
              attrs: {
                label: "Range type",
                name: "range_type",
                options: _vm.rangeTypes,
                value: _vm.model.free_games_settings.range_type,
                errors: _vm.errors
              },
              on: { update: _vm.updateRangeType }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      !!_vm.model.free_games_settings.range_type
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Tickets",
                    name: "tickets",
                    value: _vm.model.free_games_settings.tickets,
                    errors: _vm.errors,
                    min: 1
                  },
                  on: { update: _vm.updateFreeGameSettingModel }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !!_vm.model.free_games_settings.range_type
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Lines (Up To)",
                    name: "lines",
                    value: _vm.model.free_games_settings.lines,
                    errors: _vm.errors,
                    min: 0
                  },
                  on: { update: _vm.updateFreeGameLines }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !!_vm.model.free_games_settings.range_type
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Fractions",
                    name: "fractions",
                    value: _vm.model.free_games_settings.fractions,
                    errors: _vm.errors,
                    min: 0,
                    max: 10
                  },
                  on: { update: _vm.updateFreeGameLines }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !!_vm.model.free_games_settings.range_type
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Shares",
                    name: "shares",
                    value: _vm.model.free_games_settings.shares,
                    errors: _vm.errors,
                    min: 0
                  },
                  on: { update: _vm.updateFreeGameLines }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !!_vm.model.free_games_settings &&
      !!_vm.model.free_games_settings.created_at
        ? _c("hr")
        : _vm._e(),
      _vm._v(" "),
      !!_vm.model.free_games_settings &&
      !!_vm.model.free_games_settings.created_at
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
              _vm._v("Created At")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _vm._v(_vm._s(_vm.model.free_games_settings.created_at))
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }