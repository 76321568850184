export default {
	getWithdrawalsRoute(id) {
		return `/player/${id}/withdrawals`;
	},
	createWithdrawalRoute() {
		return `/transactions/request/withdrawal`;
	},
	getPlayerWithdrawals(id, page) {
		return window.axios.get(
			this.getWithdrawalsRoute(id),
			{
				params: {
					playerId: id,
					page: page
				}
			}
		);
	},
	createWithdrawalRequest(model) {
		return window.axios.post(
			this.createWithdrawalRoute(),
			model
		);
	}
}