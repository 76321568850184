export default {
    mutations: {
        SET_PAY_WITH_SAVED_CARD_MODAL: "setPayWithSavedCardModal",
        SET_PAY_WITH_SAVED_CARD_MODAL_LOADER: "setPayWithSavedCardModalLoader",
        SET_PLAYER_CREDIT_CARDS: "setPlayerCreditCards",
        SET_PAY_WITH_MODEL_FIELD: "setPayWithModelField",
        SET_CONFIRM_PAY_WITH_CARD: "setConfirmPayWithCard",
    },
    getters: {
        GET_PAY_WITH_SAVED_CARD_MODAL: "getPayWithSavedCardModal",
        GET_PAY_WITH_SAVED_CARD_MODAL_LOADER: "getPayWithSavedCardModalLoader",
        GET_PLAYER_CREDIT_CARDS: "getPlayerCreditCards",
        GET_PAY_WITH_MODEL: "getPayWithModelField",
        GET_CONFIRM_PAY_WITH_CARD: "getConfirmPayWithCard",
    },
    actions: {
        GET_PLAYERS_SAVED_CARDS_ACTIONS: "getPlayersSavedCardActions",
    }
};