import { clone } from '../../../utils/Json/Json';
import { isObjectEmpty } from '../../../utils/Object/Object';

/**
 * Resolve all local attributes with detail attributes
 *
 * @param originalExternalTypeMetaInnerValue
 * @param databaseExternalTypeMetaInnerValue
 * @return {(*&{value: *})[]|null}
 */
function resolveAttributes(originalExternalTypeMetaInnerValue, databaseExternalTypeMetaInnerValue) {
    return originalExternalTypeMetaInnerValue.hasOwnProperty('attributes') && !!originalExternalTypeMetaInnerValue.attributes && Array.isArray(originalExternalTypeMetaInnerValue.attributes)
        ? originalExternalTypeMetaInnerValue.attributes.map(item => {
            const value = !!databaseExternalTypeMetaInnerValue.attributes.hasOwnProperty(item.name)
                ? databaseExternalTypeMetaInnerValue.attributes[item.name]
                : null;

            return {
                ...item,
                value
            };
        })
        : null;
}

/**
 * Resolve all keys for every external type values
 * Example: en, de, fr, etc...
 *
 * @param originalExternalTypeMetaValue
 * @param databaseExternalTypeMetaValue
 * @return {[string,{[p: string]: *}][]|*}
 */
function resolveExternalTypeMetaValue(originalExternalTypeMetaValue, databaseExternalTypeMetaValue) {
    if (!databaseExternalTypeMetaValue) {
        return originalExternalTypeMetaValue;
    }

    return Object.keys(originalExternalTypeMetaValue)
        .map(originalExternalTypeMetaInnerKey => {
            const originalExternalTypeMetaInnerValue = originalExternalTypeMetaValue[originalExternalTypeMetaInnerKey];

            const databaseExternalTypeMetaInnerValue = !!databaseExternalTypeMetaValue.hasOwnProperty(originalExternalTypeMetaInnerKey)
                ? databaseExternalTypeMetaValue[originalExternalTypeMetaInnerKey]
                : {
                    value: null,
                    attributes: Object.fromEntries(
                        originalExternalTypeMetaInnerValue.attributes.map(item => [item.name, item.value])
                    )
                };

            const attributes = resolveAttributes(
                originalExternalTypeMetaInnerValue,
                databaseExternalTypeMetaInnerValue
            );

            const value = databaseExternalTypeMetaInnerValue.value;

            return [
                originalExternalTypeMetaInnerKey,
                {
                    ...originalExternalTypeMetaInnerValue,
                    attributes,
                    value,
                },
            ];
        });
}

/**
 * Resolve every key in every external type
 * Example: options, languages, etc...
 *
 * @param originalMetaTemplateUserType
 * @param databaseMetaTemplateUserType
 * @return [][]
 */
function resolveExternalTypeMeta(originalMetaTemplateUserType, databaseMetaTemplateUserType) {
    return Object.keys(originalMetaTemplateUserType.meta)
        .map(externalTypeMetaKey => {
            const originalExternalTypeMetaValue = originalMetaTemplateUserType.meta.hasOwnProperty(externalTypeMetaKey)
                ? originalMetaTemplateUserType.meta[externalTypeMetaKey]
                : null;
            const databaseExternalTypeMetaValue = !!databaseMetaTemplateUserType && databaseMetaTemplateUserType.hasOwnProperty(externalTypeMetaKey) && databaseMetaTemplateUserType[externalTypeMetaKey]
                ? databaseMetaTemplateUserType[externalTypeMetaKey]
                : null;

            if (!databaseExternalTypeMetaValue) {
                return [
                    externalTypeMetaKey,
                    originalExternalTypeMetaValue
                ];
            }

            return [
                externalTypeMetaKey,
                Object.fromEntries(
                    resolveExternalTypeMetaValue(originalExternalTypeMetaValue, databaseExternalTypeMetaValue)
                )
            ];
        });
}

/**
 * Resolve external type meta
 * Example: customerio, mailgun, etc...
 *
 * @param originalMetaTemplateUserType
 * @param databaseMetaTemplateUserType
 * @returns {unknown[]}
 */
function resolveExternalTypes(originalMetaTemplateUserType, databaseMetaTemplateUserType) {
    return Object.keys(originalMetaTemplateUserType.externalTypes)
        .map(externalTypeKey => {
            const originalMetaTemplateExternalType = !!originalMetaTemplateUserType.externalTypes && originalMetaTemplateUserType.externalTypes.hasOwnProperty(externalTypeKey)
                ? originalMetaTemplateUserType.externalTypes[externalTypeKey]
                : null;
            const databaseMetaTemplateExternalType = !!databaseMetaTemplateUserType.sourceTypes && databaseMetaTemplateUserType.sourceTypes.hasOwnProperty(externalTypeKey)
                ? databaseMetaTemplateUserType.sourceTypes[externalTypeKey]
                : null;

            return [
                externalTypeKey,
                {
                    ...originalMetaTemplateExternalType,
                    meta: Object.fromEntries(
                        resolveExternalTypeMeta(originalMetaTemplateExternalType, databaseMetaTemplateExternalType)
                    )
                },
            ];
        });
}

/**
 * @param originalMetaObject
 * @param databaseMetaObject
 * @param userTypes
 * @return {*}
 */
export default function (originalMetaObject, databaseMetaObject, userTypes) {
    if (!databaseMetaObject || isObjectEmpty(databaseMetaObject)) {
        return originalMetaObject.meta;
    }

    const originalMetaTemplate = clone(originalMetaObject);
    const originalMetaTemplateMetaObject = originalMetaTemplate.meta;

    const databaseMetaTemplate = clone(databaseMetaObject);

    // App\User, App\Player, App\Lead, etc...
    for (const userType of userTypes) {
        if (!databaseMetaTemplate.hasOwnProperty(userType)) {
            continue;
        }

        const originalMetaTemplateUserType = originalMetaTemplateMetaObject[userType];

        const databaseMetaTemplateUserType = databaseMetaTemplate[userType];
        const { type, type_source, source_id, links } = databaseMetaTemplateUserType;

        originalMetaTemplateUserType.type = type;
        originalMetaTemplateUserType.type_source = type_source;
        originalMetaTemplateUserType.source_id = source_id;
        originalMetaTemplateUserType.links = links;
        originalMetaTemplateUserType.externalTypes = Object.fromEntries(
            resolveExternalTypes(originalMetaTemplateUserType, databaseMetaTemplateUserType)
        );
    }

    return originalMetaTemplateMetaObject;
}