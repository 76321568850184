var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        {
          staticClass:
            "card-header card-header-atbl d-flex align-items-center justify-content-between"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              !!_vm.isLeadCampaign()
                ? [
                    _vm.$can("Leads - Create/Edit")
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary m-0 mr-2",
                            on: { click: _vm.toggleAddLeadModal }
                          },
                          [
                            _vm._v(
                              "\n                            Edit\n                        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                : _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-primary mr-2",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onViewPlayerEvent(_vm.customer.id)
                        }
                      }
                    },
                    [
                      !_vm.customerInstantAccessLoader
                        ? _c("span", [_vm._v("View Player")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: {
                          show: _vm.customerInstantAccessLoader,
                          type: "smaller"
                        }
                      })
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.$can("Player - Create/Update") && !!_vm.showCreatePlayer
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-success d-flex align-items-center m-0",
                      on: { click: _vm.toggleCreatePlayerModal }
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: "/img/icons/transfer.svg", width: "20" }
                      }),
                      _vm._v(
                        "\n                        Create Player\n                    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _vm.customer
          ? _c("div", { staticClass: "row leads-section-profile" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Name")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.name)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Surname")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.surname)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Email")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.email)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Birthdate")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.birthdate)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Address1")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.address1)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Address2")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.address2)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Country")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.country)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("State")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.state)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("City")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.city)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Postcode")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(_vm._s(_vm._f("emptyField")(_vm.customer.postcode)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Phone")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm._f("emptyField")(_vm.customer.phone)) +
                      "\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Mobile")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm._f("emptyField")(_vm.customer.mobile)) +
                      "\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6 m-0" }, [
                _c("span", { staticClass: "leads-section-profile-label" }, [
                  _vm._v("Live Spent")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leads-section-profile-text" }, [
                  _vm.customer.live_spent && _vm.customer.live_spent > 0
                    ? _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(
                              _vm._f("formatCurrency")(_vm.customer.live_spent)
                            ) +
                            "\n\t\t\t\t\t\t\t"
                        )
                      ])
                    : _c("span", [
                        _vm._v("\n\t\t\t\t\t\t\t\t/\n\t\t\t\t\t\t\t")
                      ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row leads-section-profile" }, [
          _c("div", { staticClass: "col-12 m-0" }, [
            _c("span", { staticClass: "leads-section-profile-label" }, [
              _vm._v("Other")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "leads-section-profile-text" }, [
              _vm._v(
                "\n                            " +
                  _vm._s(_vm._f("emptyField")(_vm.customer.other)) +
                  "\n                        "
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.isWindowClosing
      ? _c("h3", { staticClass: "text-center font-weight-bold" }, [
          _vm._v("Window will be closed automatically.")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-user", attrs: { "aria-hidden": "false" } }),
      _vm._v(" Details")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }