<template>
  <atbl-modal title="Withdrawal Details"
              :show="true"
              :close-on-backdrop="false"
              @close="closeWithdrawalDetails"
              class="withdrawals-details-modal"
  >
    <template v-if="!isLoading">
      <div class="withdrawals-details-modal-details">
        <div class="row">
          <div class="col col-3">
            <span>Player</span>
          </div>
          <div class="col">
            {{ withdrawalDetails.player_name }} {{ withdrawalDetails.player_surname }}
          </div>
        </div>

        <div v-if="withdrawalDetails.name"
             class="row"
        >
          <div class="col col-3">
            <span>Agent</span>
          </div>
          <div class="col">
            {{ withdrawalDetails.name }}
          </div>
        </div>

        <div class="row">
          <div class="col col-3">
            <span>Reference</span>
          </div>
          <div class="col">
            <div v-if="withdrawalDetails.holder && withdrawalDetails.holder !== null">
              Holder: {{ withdrawalDetails.holder }}
              <br>
              {{ withdrawalDetails.payment_brand }} / *{{ withdrawalDetails.last_4_digits }}
            </div>
            <p v-else class="text-danger">
              This card is removed.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col col-3">
            <span>Amount</span>
          </div>
          <div class="col">
            {{ withdrawalDetails.amount | formatCurrency }}
          </div>
        </div>

        <div class="row">
          <div class="col col-3">
            <span>Status</span>
          </div>
          <div class="col">
            <withdrawals-status-badge :status="withdrawalDetails.status"
                                      :decline-reason="withdrawalDetails.decline_reason"
            />
          </div>
        </div>

        <div class="row">
          <div class="col col-3">
            <span>Created At</span>
          </div>
          <div class="col">
            {{ utcToLocal(withdrawalDetails.created_at) }}
          </div>
        </div>

        <div v-if="$can('Withdrawals - Update') && isStatusPending && !isCheckedStatus"
             class="row"
        >
          <div v-if="withdrawalDetails.holder && withdrawalDetails.holder !== null"
               class="col"
          >
            <button class="btn btn-success btn-block"
                    type="button"
                    @click="setStatusType('APPROVED')"
            >
              APPROVE
            </button>
          </div>
          <div class="col">
            <atbl-dropdown input-style="danger">
              <atbl-dropdown-item @click="setStatusType('DECLINED', 'Invalid email')">
                Invalid email
              </atbl-dropdown-item>
              <atbl-dropdown-item @click="setStatusType('DECLINED', 'Not enough funds')">
                Not enough funds
              </atbl-dropdown-item>
              <atbl-dropdown-item @click="setStatusType('DECLINED', null)">
                Without reason
              </atbl-dropdown-item>
            </atbl-dropdown>
          </div>
        </div>

        <div v-if="$can('Withdrawals - Update') && !isLoading && isStatusPending && isCheckedStatus"
             class="row"
        >
          <div class="col-12">
            <h5 class="text-center">
              Are your sure you want to set status <b>{{ status }}</b> to this withdrawal request?
            </h5>
          </div>
          <div class="col">
            <button class="btn btn-sm btn-success btn-block"
                    type="button"
                    @click="onStatusChangeEvent"
            >
              YES
            </button>
          </div>
          <div class="col">
            <button class="btn btn-sm btn-danger btn-block"
                    type="button"
                    @click="cancelProcess()"
            >
              NO
            </button>
          </div>
        </div>
      </div>

      <items-not-found v-if="!withdrawalDetails.id"
                       :items="[]"
                       msg="No withdrawal request found."
      />
    </template>

    <loader :show="isLoading"
            type="small"
    />

    <div v-if="errorMessage" class="alert alert-danger mt-2 mb-0">
      {{ errorMessage }}
    </div>
  </atbl-modal>
</template>

<script>
	import AtblModal from '../../../components/AtblModal/AtblModal.vue';
	import WithdrawalDetailsModel from './WithdrawalDetailsModel';
	import ItemsNotFound from '../../../components/Lists/ItemsNotFound.vue';
	import Loader from '../../../components/Widgets/Loader.vue';
	import WithdrawalStatuses from '../../../constants/WithdrawalStatuses';
	import utcToLocal from '../../../filters/UtcToLocal';
    import AtblDropdown from '../../../components/AtblDropdown/AtblDropdown.vue';
    import AtblDropdownItem from '../../../components/AtblDropdown/AtblDropdownItem.vue';

	export default {
		name: 'review-withdrawal-modal',
		inject: [
			'changeRequest',
			'closeWithdrawalDetails',
		],
		components: {
          AtblDropdownItem,
			AtblModal,
			ItemsNotFound,
			Loader,
          AtblDropdown,
		},
		props: {
			isLoading: {
				type: Boolean,
				default: false,
			},
			playerId: {
				type: Number,
				default: null,
			},
			withdrawalDetails: {
				type: Object,
				default: () => (WithdrawalDetailsModel),
			},
			errorMessage: {
				type: String,
				default: null,
			},
		},
		data() {
			return {
				isCheckedStatus: false,
				model: {
					withdrawal_request_id: null,
					status: null,
					decline_reason: null
				}
			};
		},
		computed: {
			status() {
				return this.model.status;
			},
			isStatusPending() {
				return this.withdrawalDetails.status === WithdrawalStatuses.PENDING;
			}
		},
		methods: {
			utcToLocal,
			async onStatusChangeEvent() {
				const model = {
					...this.model,
					withdrawal_request_id: this.withdrawalDetails.id
				};

				await this.changeRequest(model);
			},
			setStatusType(type, declineReason) {
				this.model.status = type;

				if (declineReason) {
					this.model.decline_reason = declineReason;
				}

				this.isCheckedStatus = !this.isCheckedStatus;
			},
			cancelProcess() {
				this.model.status = null;
				this.model.decline_reason = null;
				this.isCheckedStatus = false;
			}
		},
	};
</script>