var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "card card-list" }, [
      _c("div", { staticClass: "card-header" }, [
        _c(
          "a",
          {
            staticClass: "mr-2",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goBack.apply(null, arguments)
              }
            }
          },
          [_c("i", { staticClass: "fa fa-chevron-left" })]
        ),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-users" }),
        _vm._v(" New Players (" + _vm._s(_vm.month) + ")\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _vm._m(0),
            _vm._v(" "),
            !_vm.isLoading
              ? _vm._l(_vm.players.data, function(item, index) {
                  return _c("div", { key: index, staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Player Name")
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          { attrs: { href: "/players/" + item.id + "/view" } },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.name) +
                                " " +
                                _vm._s(item.surname) +
                                "\n                            "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Created At")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.utcToLocal(item.created_at)) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Deposit")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class:
                              "badge badge-" +
                              (!!item.deposit ? "success" : "danger")
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(!!item.deposit ? "Yes" : "No") +
                                "\n                            "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Lead Converted")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class:
                              "badge badge-" +
                              (!!item.lead_id ? "success" : "danger")
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(!!item.lead_id ? "Yes" : "No") +
                                "\n                            "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("not-found", {
                  attrs: {
                    msg: "No reports data found",
                    items: _vm.players.data
                  }
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      !_vm.isLoading && _vm.players.last_page > 1
        ? _c("div", { staticClass: "card-footer" }, [
            _c(
              "nav",
              { staticClass: "wrap" },
              [
                _c("atbl-pagination", {
                  attrs: { pagination: _vm.players },
                  on: { paginate: _vm.fetchMonthlyDetails }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm._v("\n                        Player Name\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _vm._v("\n                        Created At\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _vm._v("\n                        Deposit\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _vm._v("\n                        Lead Converted\n                    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }