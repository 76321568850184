import { clone } from '../../../utils/Json/Json';
import PhoneNumberModel from '../UpdatePhoneNumber/PhoneNumberModel';

export default {
    provide() {
        return {
            setFilterModelProperty: this.setFilterModelProperty,
            clearFilterModel: this.clearFilterModel,
        };
    },
    data() {
        return {
            phoneNumbersFilterModel: clone(PhoneNumberModel),
            phoneNumbersFilterFormVisibility: true,
        };
    },
    methods: {
        setFilterModelProperty(name, value) {
            Object.assign(this.phoneNumbersFilterModel, {
                [name]: value,
            });
        },
        clearFilterModel() {
            this.phoneNumbersFilterModel = clone(PhoneNumberModel);

            this.fetchPhoneNumbersList();
        },
    },
}