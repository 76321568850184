<template>
    <div class="configuration-form">
        <a href="#"
           @click.prevent="onRemoveConfigurationEvent(numberTypeIndex)"
        >
            <i class="fa fa-times"></i>
        </a>
        <p>How many numbers?</p>
        <div>
            <input :value="model.numbers"
                   class="configuration-text-input"
                   placeholder="Numbers amount (Ex: 7)"
                   name="numbers"
                   type="number"
                   @input="onUpdateModelEvent('numbers', $event.target.value)"
            />
            <input :value="model.length"
                   class="configuration-text-input"
                   placeholder="Number length (Ex: 4)"
                   name="length"
                   type="number"
                   @input="onUpdateModelEvent('length', $event.target.value)"
            />
            <button @click.prevent="onSaveEvent"
                    :disabled="!isValid"
            >
                <i class="fa fa-check"></i> Save
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RaffleConfigurationFormMainItem',
    emits: [
        'remove-configuration',
        'update-configuration',
    ],
    props: {
        numberTypeIndex: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            model: {
                numbers: null,
                length: null,
            },
        };
    },
    computed: {
        isValid() {
            return !!this.model.numbers && !!this.model.length;
        },
    },
    methods: {
        onUpdateModelEvent(name, value) {
            Object.assign(this.model, {
                [name]: Number(value)
            });
        },
        onRemoveConfigurationEvent(type) {
            this.$emit('remove-configuration', type);
        },
        onSaveEvent() {
            this.$emit('update-configuration', this.numberTypeIndex, this.model);
        },
    },
}
</script>