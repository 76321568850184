<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header">
                <i class='fa fa-users'></i> New Players
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-lg">
                            Month
                        </div>
                        <div class="col-lg">
                            Total Players
                        </div>
                        <div class="col-lg">
                            Players
                        </div>
                        <div class="col-lg">
                            Leads Conversion
                        </div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(item, index) in reports.data"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0">
                                <span class="small-mb-badge">Month</span>
                                <a v-if="$can('Reports New Players - Details View')"
                                   href="#"
                                   @click.prevent="showDetails(item.month)"
                                >
                                    {{ item.month }}
                                </a>
                                <span v-else>{{ item.month }}</span>
                            </div>
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0">
                                <span class="small-mb-badge">Total Players</span>
                                {{ item.total_players }}
                            </div>
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Players</span>
                                {{ item.players }}
                            </div>
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Leads Conversion</span>
                                {{ item.leads }}
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />
                    <not-found v-if="!isLoading"
                               msg="No reports data found"
                               :items="reports.data"
                    />
                </div>
            </div>

            <div v-if="!isLoading && reports.last_page > 1" class="card-footer">
                <nav class="wrap">
                    <atbl-pagination :pagination="reports"
                                     @paginate="getPlayersReports"
                    />
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReportNewPlayers',
    data() {
        return {
            isLoading: false,
            reports: []
        };
    },
    methods: {
        async getPlayersReports(page = 1) {
            this.isLoading = true;

            try {
                window.history.pushState({}, '', `/reports/new-players?page=${page}`);

                const response = await window.axios.get('/reports/new-players/list', {
                    params: {
                        page
                    }
                });

                this.reports = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        showDetails(month) {
            const url = window.location.href;

            window.location.href = `/reports/new-players/${month}/details?redirect=${url}`;
        },
    },
    async created() {
        const page = this.getParam('page');

        await this.getPlayersReports(!!page ? page : 1);
    }
}
</script>