export default {
    mutations: {
        SET_ORDER_ID: "setOrderId",
    },
    getters: {
        GET_ORDER_ID: "getOrderId",
    },
    actions: {
        //
    }
};