<template>
    <player-layout :id="id"
                   icon="fa fa-exchange-alt"
                   title="Transactions"
    >
        <template slot="first">
            <SearchTransactions v-show="showSearch"
                                @search="onSearchFormUpdate"
            />
        </template>

        <template slot="rightSide">
            <button class="btn btn-sm"
                    :class="{
                        'btn-primary': !showSearch,
                        'btn-outline-primary': showSearch
                    }"
                    @click="showSearch = !showSearch"
            >
                <i class="fa fa-filter"></i>
            </button>
        </template>

        <div class="general-list">
            <div class="row d-none d-xl-flex">
                <div class="col-12 col-xl text-muted">Reference</div>
                <div class="col-12 col-xl text-muted text-center">Created By</div>
                <div class="col-12 col-xl text-muted text-center">Date</div>
                <div class="col-12 col-xl text-muted" v-if="$can('Transactions - View Gateway')">Gateway</div>
                <div class="col-12 col-xl text-muted">Type</div>
                <div class="col-12 col-xl text-muted">Status</div>
                <div class="col-12 col-xl-1 text-muted">Last 4</div>
                <div class="col-12 col-xl text-muted">Amount</div>
                <div class="col-12 col-xl-2 col-xl text-muted text-center">Action</div>
            </div>

            <template v-if="!isLoading">
                <div v-for="(transaction, index) in transactions"
                       :key="index"
                       class="row"
                >
                    <div class="col-12 col-xl align-self-center text-center text-xl-left">
                        <span class="small-mb-badge">Reference</span>
                        <a class="d-block" :href="`/transaction/${transaction.id}`">
                            <i class="fa fa-link"></i> {{ transaction.id }}
                        </a>
                        <a v-if="!!transaction.order"
                           :href="`/orders/${transaction.order.id}`"
                        >
                            <small>
                                <b>Order</b> #{{ transaction.order.id }}
                            </small>
                        </a>
                        <small class="d-block text-muted"><b>Player:</b> {{transaction.player.name +' '+
                            transaction.player.surname}}
                        </small>
                        <small class="text-muted"><b>Ref:</b> {{ transaction.reference_id }}</small>
                        <br />
                        <small class="text-muted"><b>Ref tid:</b> {{ transaction.reference_transaction_id }}</small>
                        <br />
                        <a v-if="!!transaction.game_winner" :href="getGameWinnerTicket(transaction).url">
                            <small>
                                <b>{{ getGameWinnerTicket(transaction).label }}</b> #{{ getGameWinnerTicket(transaction).id }}
                            </small>
                        </a>
                    </div>
                    <div class="col-12 col-xl align-self-center text-center">
                        <span class="small-mb-badge">Created By</span>
                        <created-by-badge :created="transaction"
                        />
                    </div>
                    <div class="col-12 col-xl text-center align-self-center">
                        <span class="small-mb-badge">Created At</span>
                        <small>{{ utcToLocal(transaction.updated_at) }}</small>
                    </div>
                    <div class="col-12 col-xl align-self-center text-center text-xl-left" v-if="$can('Transactions - View Gateway')">
                        <small><strong>{{ transaction.gateway }}</strong></small>
                    </div>
                    <div class="col-12 col-xl align-self-center text-center text-xl-left">
                        <span class="small-mb-badge">Type</span>
                        <transaction-badge :transaction="transaction"
                        />
                    </div>
                    <div class="col-12 col-xl align-self-center text-center text-xl-left">
                        <div class="transaction-type font-weight-bold"
                                :class="{
                                'text-success': transaction.transaction_type === 1,
                                'text-danger': transaction.transaction_type !== 1
                                }"
                        >
                            {{ getReadableTransactionType(transaction.transaction_type) }}
                            <span v-if="transaction.transaction_type === 0 && transaction.response_message">
                                <a v-tooltip="{
                                        html: true,
                                        content: transaction.response_message,
                                        placement: 'top'
                                    }"
                                    href="#"
                                >
                                    <i class="fa fa-info-circle text-danger ml-1"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-xl-1 align-self-center text-center text-xl-left">
                        <span class="small-mb-badge">Last 4</span>
                        <span>{{ transaction.last_4_digits }}</span>
                    </div>
                    <div class="col-12 col-xl align-self-center text-center text-xl-left">
                        <span class="small-mb-badge">Amount</span>
                        <span class="d-block">{{ transaction.amount | formatCurrency }}</span>
                        <small class="d-block text-muted">
                            {{ logABSum('before', transaction) }}
                        </small>
                        <small class="d-block text-muted">
                            {{ logABSum('after', transaction) }}
                        </small>
                    </div>
                    <div class="col-12 col-xl-2 col-xl text-center align-self-center">
                        <span class="small-mb-badge">Action</span>
                        <a class="d-block" :href="`/players/${id}/view`">
                            <small><i class="fa fa-user"></i> View Player</small>
                        </a>
                    </div>
                </div>
            </template>

            <loader :show="isLoading"
                    type="small"
            />

            <not-found v-if="!isLoading"
                       msg="No transactions found"
                       :items="transactions"
            />
        </div>

        <template slot="footer">
            <nav class="wrap" v-if="!isLoading && transactionsData.last_page > 1">
                <atbl-pagination :pagination="transactionsData"
                                 @paginate="getTransactions"
                />
            </nav>
        </template>
    </player-layout>
</template>

<script>
    import SearchTransactions from "./Components/SearchTransactions";

    import Wallet from "../../../../mixins/Wallet";
    import PlayerTransactionsService from '../../../../services/PlayerTransactionsService';

    export default {
        name: "PlayerTransactions",
        components: {
            SearchTransactions
        },
        mixins: [
            Wallet
        ],
        props: [
            'id'
        ],
        data: function () {
            return {
                isLoading: false,
                transactions: [],
                transactionsData: {},
                showSearch: true,
                searchModel: {}
            }
        },
        methods: {
            onSearchFormUpdate (value) {
                this.searchModel = value;
                this.getTransactions();
            },
            getTransactions (page = 1){
                this.isLoading = true;

                PlayerTransactionsService.getPlayerTransactions(this.id, {
                    ...this.searchModel,
                    page
                })
                    .then(response => {
                        this.transactions = response.data.data;
                        this.transactionsData = response.data;
                    })
                    .catch(error => {
                        let message = error.response.data.message ? error.response.data.message : error.message;
                        flash(message, "alert-danger");
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            getReadableTransactionType(transactionType) {
                switch (transactionType) {
                    case 0:
                        return 'Failed';

                    case 1:
                        return 'Success';

                    case 2:
                        return 'Rejected';
                }
            },
            getGameWinnerTicket(transaction) {
                switch (transaction.game_winner.gameable_type) {
                    case "App\\Models\\Syndicates\\SyndicateShare":
                        return {
                            url: `/syndicate-shares/${transaction.game_winner.gameable_id}`,
                            label: 'Syndicate share',
                            id: transaction.game_winner.gameable_id,
                        };

                    case "App\\RaffleTicket":
                        return {
                            url: `/raffle-tickets/${transaction.game_winner.gameable_id}`,
                            label: 'Raffle',
                            id: transaction.game_winner.gameable_id,
                        };

                    case "App\\TicketLine":
                        return {
                            url: `/ticket/${transaction.game_winner.gameable.draw.ticket_id}`,
                            label: 'Ticket',
                            id: transaction.game_winner.gameable.draw.ticket_id,
                        };
                }

                return {
                    url: '#',
                    label: 'Ticket #',
                    id: 1,
                };
            },
        },
        created: function () {
            this.getTransactions();
        }
    }
</script>