var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: { id: _vm.id, icon: "fa fa-play", title: "Orders" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.isLoading && _vm.ordersData.last_page > 1
                ? _c(
                    "nav",
                    { staticClass: "wrap" },
                    [
                      _c("atbl-pagination", {
                        attrs: { pagination: _vm.ordersData },
                        on: { paginate: _vm.fetchOrders }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row d-none d-xl-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-lg-4 col-xl text-muted text-center text-xl-left"
              },
              [_vm._v("Order#")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4 col-xl text-muted text-center" },
              [_vm._v("Created By")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4 col-xl text-muted text-center" },
              [_vm._v("Items")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4 col-xl text-muted text-center" },
              [_vm._v("Amount")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4 col-xl text-muted text-center" },
              [_vm._v("Transaction")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4 col-xl text-muted text-center" },
              [_vm._v("Status")]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.orders, function(order, index) {
            return !_vm.isLoading && _vm.orders.length
              ? _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg text-muted text-center text-xl-left"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Order")
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/orders/" +
                              order.id +
                              "?playerId=" +
                              order.player_id
                          }
                        },
                        [_vm._v("#O-" + _vm._s(order.id))]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Created at"
                              },
                              expression:
                                "{\n                          content: 'Created at'\n                      }"
                            }
                          ],
                          staticClass: "badge badge-warning font-weight-bold"
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.utcToLocal(order.created_at)) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-4 col-xl text-muted text-center"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Created By")
                      ]),
                      _vm._v(" "),
                      _c("created-by-badge", { attrs: { created: order } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-4 col-xl text-muted text-center"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Items")
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(order.order_items_count) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-4 col-xl text-muted text-center"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Amount")
                      ]),
                      _vm._v(" "),
                      !!order.is_free
                        ? _c("span", { staticClass: "badge badge-danger" }, [
                            _vm._v(
                              "\n                    Free\n                "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.formatCurrency(order.amount)) +
                                "\n                "
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-4 col-xl text-muted text-center"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Transaction")
                      ]),
                      _vm._v(" "),
                      !!order.latest_completed_transaction_id
                        ? _c(
                            "a",
                            {
                              staticClass: "d-block",
                              attrs: {
                                href:
                                  "/transaction/" +
                                  order.latest_completed_transaction_id
                              }
                            },
                            [
                              _vm._v(
                                "\n                    #T-" +
                                  _vm._s(
                                    order.latest_completed_transaction_id
                                  ) +
                                  "\n                "
                              )
                            ]
                          )
                        : _c("span", [_vm._v("/")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-4 col-xl text-muted text-center"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Status")
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Status"
                              },
                              expression:
                                "{\n                            content: 'Status'\n                         }"
                            }
                          ],
                          staticClass: "font-weight-bold",
                          class: [
                            "badge",
                            "badge-" +
                              _vm.$options.OrdersConstants.statusColor(
                                order.status
                              )
                          ]
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(order.status.split("_").join(" ")) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { msg: "No orders found", items: _vm.orders }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }