<template>
    <div class="card card-search">
        <div class="card-header">
            <i class='fa fa-search'></i> Search agents
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-9">
                    <atbl-form-group label="For Date"
                                     label-for="For Date"
                                     class="font-weight-bold"
                                     :horizontal="false"
                    >
                        <multi-date-picker v-model="selectedDates"
                                           :format="datePickersOptions.format"
                                           :clearable="false"
                                           :show-ranges="true"
                                           :ranges="datePickersOptions.ranges"
                                           :auto-apply="true"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-3">
                    <show-per-page v-model="paginate"
                                   :horizontal="false"
                    />
                </div>
            </div>
            <div v-if="show && $can('Users - Get List')" class="row">
                <div class="col">
                    <multi-agents-picker v-model="model.agents"
                    />
                </div>
            </div>
        </div>

        <div class="card-footer">
            <footer class="d-flex align-items-center justify-content-between">
                <div class="search-controls">
                    <button type="submit"
                            class="btn btn-sm btn-primary mr-1"
                            @click="doSearch"
                    >
                        <i class="fa fa-search"></i> Search
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-danger"
                            @click="clear"
                    >
                        <i class="fa fa-ban"></i> Clear
                    </button>
                </div>
                <div class="export-controls"
                     v-if="show && $can('Reports Agents - Export File')"
                >
                    <b title="Export As"
                       class="mr-1"
                    >
                        <i class="fa fa-download"></i> Export:
                    </b>
                    <button type="button"
                            class="btn btn-sm btn-danger"
                            @click="exportFile('pdf')"
                            title="PDF Document"
                            :disabled="isExportDisabled"
                            v-if="$can('Reports Agents - Export File')"
                    >
                        <i class="fa fa-download"></i> PDF
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-success"
                            @click="exportFile('csv')"
                            title="CSV Document"
                            :disabled="isExportDisabled"
                            v-if="$can('Reports Agents - Export File')"
                    >
                        <i class="fa fa-file-excel"></i> CSV
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
    import PaginateOptions from "../../../../data/PaginateOptions";
    import moment from "moment";
    import Date from "../../../../helpers/Date";

    export default {
        name: "agents-filter-form",
        props: {
            show: {
                type: Boolean,
                default: true
            },
            filterModel: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                selectedAgent: [],
                paginate: null,
                selectedOption: this.filterModel ? this.filterModel.filterDate : "today",
                model: {
                    filterDate: this.filterModel.hasOwnProperty('filterDate')
                        ? this.filterModel.filterDate
                        : "today",
                    dateFrom: this.filterModel.hasOwnProperty('dateFrom')
                        ? this.filterModel.dateFrom
                        : moment().format(Date.defaultDate),
                    dateTo: this.filterModel.hasOwnProperty('dateTo')
                        ? this.filterModel.dateTo
                        : moment().format(Date.defaultDate),
                    paginate: PaginateOptions.default,
                    agents: null,
                    sort: {
                        sort: "deposits",
                        value: "DESC"
                    }
                },
                datePickersOptions: {
                    format: "YYYY-MM-DD",
                    firstDay: 1,
                    ranges: {
                        'This Year': [
                            moment().startOf('year').toDate(),
                            moment().endOf('year').toDate(),
                        ]
                    }
                },
                canSearch: true,
                isExportDisabled: false,
                hasPermissions: false
            };
        },
        computed: {
	        selectedDates: {
		        get(){
                    if (!this.model.dateFrom && !this.model.dateTo) {
                        return null;
                    }

                    return {
                        startDate: this.model.dateFrom,
                        endDate: this.model.dateTo
                    };
		        },
		        set(value){
		            if(this.model.dateFrom === value.startDate || this.model.dateTo === value.endDate) {
		                return;
                    }

			        this.model.filterDate = "daterange";
			        this.model.dateFrom = !!value.startDate
                        ? moment(value.startDate).format(Date.defaultDate)
                        : null;
			        this.model.dateTo = !!value.endDate
                        ? moment(value.endDate).format(Date.defaultDate)
                        : null;
		        }
	        }
        },

        methods: {
            selectedOptionValue(value) {
                this.model.filterDate = value;
                this.selectedOption = value;
                [ this.model.dateFrom, this.model.dateTo ] = this.getRangeFor(value, 'YYYY-MM-DD');
            },
            clear() {
                this.model = {
                    filterDate: "today",
                    paginate: PaginateOptions.default,
                    agents: null,
                    sort: {
                        sort: "deposits",
                        value: "DESC"
                    }
                };
                [this.model.dateFrom, this.model.dateTo] = this.getRangeFor('today', 'YYYY-MM-DD');
                this.paginate = PaginateOptions.default;
                this.selectedOption = "today";
                this.selectedAgent = [];
                this.doSearch();
            },
            datePickerHide() {
                if (this.isDateRange()) {
                    this.isExportDisabled = false;
                    this.doSearch();
                } else {
                    this.selectedOption = "daterange";
                    this.isExportDisabled = true;
                }
            },
            isDateRange() {
                return this.selectedOption === "daterange";
            },
            doSearch() {
                this.$emit("modelChange", this.model);
                this.canSearch = true;
            },
            exportFile(type){
                const query = this.buildQuery({
                    ...this.model,
                    type
                });

                window.open(`/reports/agents/export/file?${query}`);
            },
        },
        watch: {
            paginate(val) {
                this.model.paginate = val;
                if (this.canSearch) {
                    this.canSearch = false;
                }
            },
        }
    }
</script>