var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Add to campaign",
        value: _vm.show,
        loader: _vm.isLoading
      },
      on: { input: _vm.toggleAddToCampaign },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.isModalLoading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-outline-danger mr-2",
                      on: { click: _vm.toggleAddToCampaign }
                    },
                    [_vm._v("\n            Cancel\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { disabled: _vm.isModalLoading || !_vm.isSaveEnabled },
                  on: { click: _vm.onAddToCampaign }
                },
                [
                  !_vm.isModalLoading ? _c("span", [_vm._v("Save")]) : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: _vm.isModalLoading, type: "smaller" }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "atbl-tabs",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isModalLoading,
              expression: "!isModalLoading"
            }
          ],
          on: { "change-tab": _vm.onTabChange }
        },
        [
          _c(
            "atbl-tab",
            {
              attrs: {
                title: "Add to campaign",
                active: _vm.addToCampaignMode === 0
              }
            },
            [
              _c("campaigns-picker", {
                staticClass: "mt-2 mb-0",
                attrs: { multiple: false, disabled: _vm.isModalLoading },
                model: {
                  value: _vm.selectedCampaignId,
                  callback: function($$v) {
                    _vm.selectedCampaignId = $$v
                  },
                  expression: "selectedCampaignId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "atbl-tab",
            {
              attrs: {
                title: "Create campaign",
                active: _vm.addToCampaignMode === 1
              }
            },
            [
              _c("atbl-input", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.campaignModel.title,
                  disabled: _vm.isModalLoading,
                  errors: _vm.campaignModelErrors,
                  label: "Title *",
                  name: "title"
                },
                on: { update: _vm.updateCampaignModelField }
              }),
              _vm._v(" "),
              _c("strong", [_vm._v("* Finish this form after save")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isModalLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }