<template>
    <atbl-select :label="label"
                 name="agent"
                 :options="users"
                 :disabled="isFetchUsersLoading"
                 :value="getSelected"
                 @update="changed"
                 v-if="$canAny(permissions)"
    />
</template>

<script>
    import UsersService from "../../../services/UsersService";

    export default {
        name: "agents-single-picker",
        props: {
            value: {
                type: Number,
                default: null
            },
            label: {
                type: String,
                default: 'Agent'
            },
        },
        data: function(){
            return {
                selectedUser: null,
                users: [],
                isFetchUsersLoading: false,
                permissions: [
                    'Player - Assign Agent',
                    'Users - Get List'
                ]
            };
        },
        computed: {
            getSelected: {
                get: function(){
                    return this.selectedUser !== null ? this.selectedUser : this.value;
                },
                set: function(value){
                    this.selectedUser = value;
                }
            }
        },
        methods: {
            getUsers: function () {
                let vm = this;

                vm.isFetchUsersLoading = true;
                UsersService.getUsersList()
                    .then(function (response) {
                        vm.users = response.data.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    })
                    .finally(() => {
                        vm.isFetchUsersLoading = false;
                    });
            },
            changed: function(name, value){
                const id = Number(value);

                this.$emit("input", id);
                this.$emit("update", name, id);
            }
        },
        created: function(){
            this.getUsers();
        }
    }
</script>