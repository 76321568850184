<template>
    <form @submit.prevent="search">
        <div class="card card-search">
            <div class="card-header">
                <i class='fa fa-search' aria-hidden='false'></i> Search
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-4">
                      <atbl-input id="ticket"
                                  label="Ticket Num."
                                  class="font-weight-bold"
                                  placeholder="Ticket Number"
                                  v-model="filters.ticketNumber"
                      />
                    </div>
                    <div class="col-12 col-lg-4">
                        <bundle-game-picker v-model="filters.bundles" />
                    </div>
                    <div class="col-12 col-lg-4">
                        <atbl-form-group label="Date created"
                                         :label-cols="3" class="font-weight-bold"
                                         :horizontal="false"
                        >
                            <multi-date-picker v-model="selectedCreatedDates"
                                               :format="datePickersOptions.format"
                                               direction="left"
                                               :show-ranges="true"
                                               :auto-apply="true"
                            />
                        </atbl-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-3">
                      <atbl-input id="firstName"
                                  label="Name"
                                  class="font-weight-bold"
                                  placeholder="First Name"
                                  v-model="filters.name"
                      />
                    </div>
                    <div class="col-12 col-lg-3">
                      <atbl-input id="surname"
                                  label="Surname"
                                  class="font-weight-bold"
                                  placeholder="Surname"
                                  v-model="filters.surname"
                      />
                    </div>
                    <div v-if="!getIsOnlyAgent && $can('Users - Get List')"
                           class="col-12 col-lg-6"
                    >
                        <multi-agents-picker v-model="filters.agents" />
                    </div>
                </div>
            </div>

            <div class="card-footer d-flex justify-content-between align-items-center">
                <div>
                    <button type="submit"
                              class="btn btn-sm btn-primary"
                    >
                        <i class="fa fa-search"></i> Search
                    </button>
                    <button type="reset"
                              class="btn btn-sm btn-danger"
                              @click.prevent="clearFilters"
                    >
                        <i class="fa fa-ban"></i> Clear
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import moment from "moment";
import Date from "../../../../helpers/Date";
import BundleTicketSearchModel from './BundleTicketSearchModel';
import QueryToObject from '../../../../utils/Url/QueryToObject';
import { clone } from '../../../../utils/Json/Json';
import BundleGamePicker from '../../../../components/Forms/BundleGamePicker';
import { pick } from 'lodash';
import RemoveUndefinedProperties from '../../../../utils/Object/RemoveUndefinedProperties';

export default {
    name: 'bundle-tickets-search',
    emits: [
        'search',
        'clear',
    ],
    components: {
        BundleGamePicker,
    },
    data() {
        return {
            datePickersOptions: {
                format: "YYYY-MM-DD",
                firstDay: 1
            },
            filters: clone(BundleTicketSearchModel),
        };
    },
    computed: {
        selectedCreatedDates: {
            get() {
                const { createdAtFrom: startDate, createdAtTo: endDate } = this.filters;

                if (!startDate && !endDate) {
                    return null;
                }

                return {
                    startDate,
                    endDate
                };
            },
            set(value) {
                Object.assign(this.filters, {
                    createdAtFrom: !!value.startDate
                        ? moment(value.startDate).format(Date.defaultDate)
                        : null,
                    createdAtTo: !!value.endDate
                        ? moment(value.endDate).format(Date.defaultDate)
                        : null
                });
            }
        },
    },
    created() {
        this.initializeSearchForm();
    },
    methods: {
        initializeSearchForm() {
            const searchParams = QueryToObject();
            const searchModel = pick(searchParams, Object.keys(this.filters));
            const otherParams = pick(searchParams, ['sort', 'paginate', 'page']);

            if (this.isObjectEmpty(searchModel) && this.isObjectEmpty(otherParams)) {
                return;
            }

            Object.assign(this.filters, searchModel);

            this.search({
                searchModel,
                otherParams
            });
        },
        search(data) {
            const model = data instanceof Event
                ? this.filters
                : data;

            this.$emit('search', RemoveUndefinedProperties(model));
        },
        clearFilters() {
            this.filters = clone(BundleTicketSearchModel);
            this.$emit('clear');
        },
    },
}
</script>
