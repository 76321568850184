<template>
    <atbl-modal title="Remove Setting Section"
             :show="openModal"
             :close-on-backdrop="false"
             @close="openModal=false"
    >
        <div class="row">
            <div class="col-12">
                Are you sure you want to remove this setting section.
            </div>
        </div>

        <template #footer>
            <div class="d-flex w-100 justify-content-end">
                <button class="btn btn-sm btn-danger mr-2"
                          @click="handleCancel"
                >
                    Close
                </button>
                <button @click="handleOK"
                        class="btn"
                        :class="{
                           'btn-primary': !isLoading,
                           'btn-outline-primary': isLoading,
                           'disabled': isLoading
                       }"
                        :disabled="isLoading"
                        v-if="$can('Settings - Remove')"
                >
                        <span v-if="!isLoading">
                            Save
                        </span>
                    <loader type="small"
                            :show="isLoading"
                    />
                </button>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    import AtblModal from '../../../../components/AtblModal/AtblModal.vue';
    const {
        mapActions,
        mapGetters
    } = createNamespacedHelpers(NamespaceConst.settings);

    export default {
        name: "remove-settings-section-modal",
	    components: {
		    AtblModal,
	    },
        computed: {
            ...mapGetters({
                showModal: TypesConst.settings.getters.GET_SETTING_SECTION_REMOVE_MODAL,
                isLoading: TypesConst.settings.getters.GET_SETTING_SECTION_REMOVE_MODAL_LOADER
            }),
            openModal: {
                get: function(){
                    return this.showModal;
                },
                set: function(body){
                    this.closeRemoveModal(body);
                }
            }
        },
        methods: {
            ...mapActions({
                closeRemoveModal: TypesConst.settings.actions.OPEN_CLOSE_REMOVE_SECTION_SETTINGS_ACTION,
                removeSetting: TypesConst.settings.actions.REMOVE_SETTING_SECTION_CONFIRM_ACTION,
            }),
            handleOK: function(evt){
                evt.preventDefault();

                this.removeSetting();
            },
            handleCancel: function(){
                this.openModal = false;
            }
        }
    }
</script>