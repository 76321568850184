export default {
    getAllGames: function(){
        return window.axios.get(`/games/all`);
    },
    getSyndicateGames: function(){
        return window.axios.get(`/games/syndicates`);
    },
    getBundleGames: function(){
        return window.axios.get(`/games/bundles`);
    },
    getGameConfiguration: function(game){
        return window.axios.get(`/game/configuration/${game}`)
    }
};