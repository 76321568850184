import { render, staticRenderFns } from "./CurrentDrawHeaderComponent.vue?vue&type=template&id=0d13ef22&scoped=true&"
import script from "./CurrentDrawHeaderComponent.vue?vue&type=script&lang=js&"
export * from "./CurrentDrawHeaderComponent.vue?vue&type=script&lang=js&"
import style0 from "./CurrentDrawHeaderComponent.vue?vue&type=style&index=0&id=0d13ef22&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d13ef22",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/app-stage.thelottoexperts.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d13ef22')) {
      api.createRecord('0d13ef22', component.options)
    } else {
      api.reload('0d13ef22', component.options)
    }
    module.hot.accept("./CurrentDrawHeaderComponent.vue?vue&type=template&id=0d13ef22&scoped=true&", function () {
      api.rerender('0d13ef22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/pages/Games/Draws/Sections/CurrentDraws/CurrentDrawHeaderComponent.vue"
export default component.exports