<template>
    <atbl-number label="Amount"
                 name="amount"
                 @update="setAmount"
                 :value="getSelected"
                 :errors="errors"
                 :min="0.00"
                 :step=".01"
    />
</template>

<script>
    export default {
        name: "amount-input",
        props: {
            value: {},
            input: {},
            checkLimit: {
                type: Boolean,
                default: false
            }
        },
        data: function(){
            return {
                amountData: 0,
                errors: {}
            };
        },
        computed: {
            getSelected: {
                get: function(){
                    return this.amountData !== 0 ? this.amountData : this.value;
                },
                set: function(value){
                    this.amountData = value;
                }
            }
        },
        methods: {
            setAmount: function(name, value){
                this.errors = {};

                this.$emit("input", value);
                this.$emit("update", name, value);

                if(this.checkLimit){
                    if(value > this.amountLimit) {
                        this.errors = {
                            amount: [
                                "Amount cannot be bigger then 100000."
                            ]
                        };
                    }
                }
            }
        }
    }
</script>