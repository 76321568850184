import GameableModels from '../../../../constants/GameableModels';

/**
 * Resolve draw type
 * example: App\TicketLine -> { type: 'Lottery', color: 'primary' }
 * @param type
 * @return {{color: string, type: string}}
 */
export default function resolveDrawType(type) {
    switch(type) {
        case GameableModels.TicketLine:
            return {
                type: 'Lottery',
                color: 'primary',
            };
        case GameableModels.RaffleTicket:
            return {
                type: 'Raffle',
                color: 'warning',
            };
        case GameableModels.SyndicateShare:
            return {
                type: 'Syndicate',
                color: 'success',
            };
    }
}