var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      class: {
        "hide-footer": _vm.redirecting
      },
      attrs: {
        show: _vm.getWalletConfirmModal,
        "close-on-backdrop": false,
        fade: true
      },
      on: {
        close: function($event) {
          _vm.getWalletConfirmModal = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("h5", { staticClass: "m-0" }, [
                _vm._v("Wallet Transaction Confirmation")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.isPaymentProcessing && !_vm.redirecting
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light",
                      on: { click: _vm.closeWalletPayment }
                    },
                    [_vm._v("\n\t\t\tClose\n\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.redirecting
                ? _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: {
                        "btn-success": !_vm.isPaymentProcessing,
                        "btn-outline-success": _vm.isPaymentProcessing
                      },
                      on: { click: _vm.proceedPayment }
                    },
                    [
                      !_vm.isPaymentProcessing
                        ? _c("span", [_vm._v("Confirm")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: {
                          show: _vm.isPaymentProcessing,
                          type: "smaller"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      !_vm.redirecting
        ? _c("div", [
            !_vm.isPaymentProcessing
              ? _c("p", [
                  _vm._v(
                    "\n\t\t\tPlayer account will be charged with " +
                      _vm._s(_vm._f("formatCurrency")(_vm.getAmountToPay)) +
                      ". Do you want to proceed?\n\t\t"
                  )
                ])
              : _c(
                  "div",
                  {
                    staticClass: "alert text-center m-0",
                    class: "alert-" + _vm.status
                  },
                  [_vm._v("\n\t\t\t" + _vm._s(_vm.message) + "\n\t\t")]
                )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.redirecting
        ? _c(
            "div",
            { staticClass: "alert text-center m-0", class: "alert-success" },
            [_vm._v("\n\t\tRedirecting...\n\t")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }