var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "checkout-summary",
      class: { sticky: _vm.isSticky },
      style: { top: _vm.top + "px" }
    },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            !_vm.orderId &&
            _vm.getCartData.reference_id &&
            !_vm.getCartLoader && _vm.cartItems.length > 0
              ? _c("div", { staticClass: "card-header-operations" }, [
                  !_vm.isClearConfirm
                    ? _c(
                        "a",
                        {
                          staticClass: "text-link",
                          attrs: { href: "#", disabled: _vm.getCartLoader },
                          on: { click: _vm.clearCart }
                        },
                        [
                          _vm._v(
                            "\n                    Clear cart\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isClearConfirm
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-success",
                          on: { click: _vm.clearCartConfirm }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-check",
                            attrs: { "aria-hidden": "false" }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isClearConfirm
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-danger",
                          on: { click: _vm.clearCart }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-times",
                            attrs: { "aria-hidden": "false" }
                          })
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card-body",
            class: {
              "no-gutters": !_vm.cartItems.length,
              "p-0": !!_vm.cartItems.length
            }
          },
          [
            !!_vm.cartItems.length
              ? _c("div", { staticClass: "checkout-summary-place-order" }, [
                  _c("div", { staticClass: "checkout-summary-total" }, [
                    _c("span", [_vm._v("TOTAL:")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.getCartItemsStatistics.price
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "checkout-summary-action" }, [
                    !_vm.orderId
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger",
                            attrs: {
                              disabled: _vm.isPlacingOrder || _vm.getCartLoader
                            },
                            on: { click: _vm.placeOrder }
                          },
                          [
                            !_vm.isPlacingOrder
                              ? _c("span", [_vm._v("Place Order")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("loader", {
                              attrs: {
                                show: _vm.isPlacingOrder,
                                type: "smaller"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.orderId
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger",
                            attrs: {
                              disabled: _vm.isPlacingOrder || _vm.getCartLoader
                            },
                            on: {
                              click: function($event) {
                                return _vm.checkoutAction(
                                  _vm.getCartItemsStatistics.price
                                )
                              }
                            }
                          },
                          [_c("span", [_vm._v("Payment")])]
                        )
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkout-summary-body" },
              [
                !_vm.getCartLoader && !!_vm.cartItems.length
                  ? _vm._l(_vm.cartItems, function(cartItem, index) {
                      return _c(_vm.getGameType(cartItem), {
                        key: index,
                        tag: "component",
                        attrs: {
                          item: cartItem,
                          "can-delete-item": !_vm.orderId
                        },
                        on: { removeCartItem: _vm.removeCartItem }
                      })
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.getCartLoader
                  ? _c("not-found", {
                      staticClass: "bg-white",
                      attrs: { msg: "No items found", items: _vm.cartItems }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("loader", {
                  attrs: { show: _vm.getCartLoader, type: "small" }
                })
              ],
              2
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-title" }, [
      _c("i", {
        staticClass: "fa fa-shopping-cart",
        attrs: { "aria-hidden": "false" }
      }),
      _vm._v(" Cart\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }