import moment from 'moment';
import { clone } from '../../../utils/Json/Json';
import ShiftModel from './ShiftModel';
import GetValidationErrors from '../../../utils/Error/GetValidationErrors';
import { omit } from 'lodash';

export default {
    provide() {
        return {
            createUpdateShift: this.createUpdateShift,
            updateModelField: this.updateModelField,
            toggleCreateUpdateModal: this.toggleCreateUpdateModal,
        };
    },
    data() {
        return {
            isCreateShiftLoading: false,
            createUpdateShiftModalOpen: false,
            shiftModel: clone(ShiftModel),
            shiftModelErrors: {},
        };
    },
    methods: {
        async createUpdateShift() {
            if (!this.$can('Shifts - Create/Update')) {
                window.showMessage('No permissions for this action.', false);

                return;
            }

            this.isCreateShiftLoading = true;

            const { id } = this.shiftModel;

            try {
                const response = !!id
                    ? await window.axios.patch(`/shift/${id}`, omit(this.shiftModel, ['id']))
                    : await window.axios.post('/shift', this.shiftModel);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.toggleCreateUpdateModal();

                    await this.fetchShifts();
                }

                this.isCreateShiftLoading = false;
            } catch (error) {
                this.isCreateShiftLoading = false;

                this.shiftModelErrors = GetValidationErrors(error);

                window.flashBadResponse(error);
            }
        },
        async onUpdateShiftEvent(shift) {
            this.shiftModel = {
                ...shift,
                start: moment(shift.start, 'HH:mm:ss').format('HH:mm'),
                finish: moment(shift.finish, 'HH:mm:ss').format('HH:mm'),
            };

            this.toggleCreateUpdateModal();
        },
        updateModelField(name, value) {
            Object.assign(this.shiftModel, {
                [name]: value
            });
        },
        toggleCreateUpdateModal() {
            this.createUpdateShiftModalOpen = !this.createUpdateShiftModalOpen;

            if (!this.createUpdateShiftModalOpen) {
                this.clearShiftModel();
            }
        },
        clearShiftModel() {
            this.shiftModel = clone(ShiftModel);
            this.shiftModelErrors = {};
        },
    },
}