<template>
	<div class="card lottery-payment m-0 mb-4">
		<div class="card-header card-header-atbl d-flex align-items-center justify-content-between">
			<div>
				<i class='fa fa-credit-card'></i> How do you want to pay ?
			</div>
			<div>
				<button class="btn btn-sm"
				          @click="closeCheckout"
				>
					<i class='fa fa-times'></i>
				</button>
			</div>
		</div>

		<div class="card-body">
            <div class="row p-2" v-if="$canAny(paymentOperations)">
                <div class="col-12">
                    <payment-gateways :player-id="playerId"
                                      :enable-wallet-payment="true"
                                      :charge-amount="getCartItemsStatistics.price"
                                      url="/gateway-purchase"
                                      wallet-url="/internal-purchase"
                                      :params="{order_id: orderId}"
                                      v-on:payment-successful="paySuccess"
                                      v-on:payment-failed="payFailed"
                                      v-on:payment-error="payError" />
                </div>
            </div>

			<div v-else class="row">
				<div class="col">
					<h5>No payment access allowed.</h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TypesConst from "../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";
    import PaymentGateways from '../../../../../../components/PaymentGateways';
	import CheckoutAction from "../../Mixins/CheckoutAction";

	const {
		mapGetters: mapPaymentGetters,
		mapActions: mapPaymentActions
	} = createNamespacedHelpers(NamespaceConst.payment);

	const {
		mapGetters: mapCartGetters,
		mapMutations: mapCartMutations,
		mapActions: mapCartActions
	} = createNamespacedHelpers(NamespaceConst.cart);

	const {
		mapGetters: mapPlayersGetters,
		mapMutations: mapPlayersMutations
	} = createNamespacedHelpers(NamespaceConst.players);

    const {
        mapGetters: mapOrderGetters,
        mapMutations: mapOrderMutations
    } = createNamespacedHelpers(NamespaceConst.order);

	export default {
		name: "lottery-payment",
        components: { PaymentGateways },
		mixins: [
			CheckoutAction
		],
		data() {
			return {
				balance: 0,
				balanceLoading: false,
				paymentOperations: [
					"Payon - Wallet Payment Agent",
					"Payon - Card Payment Agent"
				],
				disableButtons: false,
				cardPaymentLoading: false
			}
		},
		computed: {
			...mapPaymentGetters({
				hasSavedCard: TypesConst.payment.getters.GET_HAS_SAVED_CARD,
				hasSavedCardLoader: TypesConst.payment.getters.GET_HAS_SAVED_CARD_LOADER
			}),
			...mapCartGetters({
				getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
				getCartLoader: TypesConst.cart.getters.GET_ADD_TO_CART_LOADER,
				getCartTotal: TypesConst.cart.getters.GET_CART_TOTAL
			}),
			...mapPlayersGetters({
				playerId: TypesConst.players.getters.GET_PLAYER_ID,
				card: TypesConst.players.getters.GET_PAY_WITH_MODEL
			}),
            ...mapOrderGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            }),
		},
		methods: {
            paySuccess(data) {
                window.showMessage(data.message);
                window.location.href = this.buildUrl(`/players/${this.playerId}/orders`);
            },

            payFailed(data) {
                window.showMessage(`Payment error: ${data.error}`, false);
            },

            payError(err) {
                window.showMessage('Payment failed. Please try again.', false);
            },

			...mapPaymentActions({
				getHasSavedCards: TypesConst.payment.actions.GET_HAS_SAVED_CARDS_ACTION
			}),
			...mapCartMutations({
				setWalletConfirmModal: TypesConst.cart.mutations.SET_WALLET_CONFIRM_MODAL,
				setPaymentLink: TypesConst.cart.mutations.SET_PAYMENT_LINK,
				setAddBalance: TypesConst.cart.mutations.SET_ADD_BALANCE,
				setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
				clearCart: TypesConst.cart.mutations.SET_CLEAR_CART_DATA
			}),
            ...mapOrderMutations({
                setOrderId: TypesConst.order.mutations.SET_ORDER_ID
            }),
			...mapPlayersMutations({
				setPayWithSavedCardModal: TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL
			}),
			...mapCartActions({
                resetCartAction: TypesConst.cart.actions.RESET_CART_ACTION,
				clearCartAction: TypesConst.cart.actions.CLEAR_CART_ACTION,
			}),
			getBalance: function (id) {
				const vm = this;

				vm.balanceLoading = true;
				window.axios.get(`/player/${id}/wallet`)
					.then(res => {
						this.balance = res.data.data.amount;
					})
					.catch(window.flashError)
					.finally(() => {
						vm.balanceLoading = false;
					});
			},
		},
		created: function () {
			const vm = this;

			vm.getBalance(this.playerId);
			vm.getHasSavedCards(vm.playerId);

			Atbl.$on("lotteryPaymentCanceled", function() {
				vm.disableButtons = false;
			});
		},
		mounted: function () {
			this.$el.scrollIntoView({behavior: "smooth"});
		}
	}
</script>
