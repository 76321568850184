var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-calls" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
        _c("div", { staticClass: "callout callout-primary" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("Players Talk Time")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("strong", { staticClass: "h4" }, [
            _vm._v(_vm._s(_vm.data.durationPlayersToday))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
        _c("div", { staticClass: "callout callout-primary" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("Leads Talk Time")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("strong", { staticClass: "h4" }, [
            _vm._v(_vm._s(_vm.data.durationLeadsToday))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
        _c("div", { staticClass: "callout callout-danger" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("Players Calls Per Day")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("strong", { staticClass: "h4" }, [
            _vm._v(_vm._s(_vm.data.callsPlayersToday))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
        _c("div", { staticClass: "callout callout-danger" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("Leads Calls Per Day")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("strong", { staticClass: "h4" }, [
            _vm._v(_vm._s(_vm.data.callsLeadsToday))
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-calls-heading" }, [
      _c("p", { staticClass: "dashboard-calls-title" }, [_vm._v(" Calls ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }