<template>
    <atbl-select v-if="$can('Dispositions - Get List')"
                 v-model="selectedDisposition"
                 :name="name"
                 :options="dispositionOptions"
                 :label="label"
                 @update="changed"
    />
</template>

<script>
import DispositionService from '../../../services/DispositionService';
import ATBLSelect from '../Inputs/ATBLSelect';

export default {
    name: 'dispositions-select',
    components: {
        ATBLSelect,
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        name: {
            type: String,
            default: 'disposition'
        },
        label: {
            type: String,
            default: 'Disposition'
        },
    },
    data() {
        return {
            selectedDisposition: null,
            isLoading: false,
            dispositionOptions: []
        };
    },
    async created() {
        if (!this.$can('Dispositions - Get List')) {
            return;
        }

        await this.getDispositionsOptions();
    },
    methods: {
        async getDispositionsOptions() {
            this.isLoading = true;

            try {
                const response = await DispositionService.getDispositionsDropDown();

                this.dispositionOptions = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        changed(name, value) {
            this.$emit('input', value);
            this.$emit('update', name, value);
        }
    },
    watch: {
        value: {
            handler(value) {
                this.selectedDisposition = value;
            },
            immediate: true
        }
    },
}
</script>