var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Choose payment type",
        "close-on-backdrop": true,
        show: true
      },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger",
                  on: { click: _vm.emitCloseEvent }
                },
                [_vm._v("\n            Cancel\n        ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("list-payments", {
              attrs: {
                "player-id": _vm.order.player_id,
                "charge-amount": parseFloat(_vm.order.amount),
                params: { order_id: _vm.order.reference_id }
              },
              on: {
                "payment-successful": _vm.paySuccess,
                "payment-failed": _vm.payFailed,
                "payment-error": _vm.payError
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }