<template>
    <atbl-modal title="Wallet payment confirmation"
             :close-on-backdrop="true"
             :show="true"
             @close="onCancelEvent"
    >
        <h4 class="text-center mb-3">Confirm wallet payment</h4>

        <p class="text-center mb-4">
            Player account will be charged with <b class="h6">{{ amount | formatCurrency }}</b>. Do you want to proceed?
        </p>

        <template #footer>
            <div class="d-flex align-items-center justify-content-end w-100">
                <button class="btn btn-sm btn-light mr-2"
                          @click="onCancelEvent"
                >
                    Close
                </button>
                <button class="btn btn-sm btn-success"
                          @click="$emit('confirm')"
                >
                    Confirm
                </button>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../AtblModal/AtblModal.vue';

export default {
    name: 'ConfirmWalletPaymentModal',
    components: {
		AtblModal,
    },
    props: {
        amount: {
            type: [Number, String],
            required: true
        }
    },
    methods: {
        onCancelEvent () {
            this.$emit('cancel');
        }
    },
}
</script>