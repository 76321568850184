<template>
    <section>
        <div class="general-list">
            <div class="row">
                <div class="col-6 col-sm-6 col-md text-muted">Note</div>
                <div class="col-6 col-sm-6 col-md text-muted">Added By</div>
                <div class="col-6 col-sm-6 col-md text-muted">Created</div>
                <div class="col-6 col-sm-6 col-md text-muted">Follow-up</div>
                <div class="col-6 col-sm-6 col-md text-muted" v-if="$can('Player - Remove Notes')">Actions</div>
            </div>

            <div v-if="!isLoading" v-for="(note, index) in notes" :key="index" class="row">
                <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                    <span class="small-mb-badge">Note</span>
                    <h6 v-if="note.note && isset(note.note, 'type')">
                        <span class="badge badge-primary">{{note.note.type}}</span>
                    </h6>
	                <span v-else class="text-danger">Note not found.</span>
                    {{note.message}}
                </div>
                <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                    <span class="small-mb-badge">Added by</span>
                    <span class="badge badge-primary">{{note.user.name}}</span>
                </div>
                <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                    <span class="small-mb-badge">Created At</span>
                    <small>{{ utcToLocal(note.created_at) }}</small>
                </div>
                <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                    <span class="small-mb-badge">Follow Up</span>
                    {{note.follow_up}}
                </div>
                <div class="col-12 col-sm-12 col-md mb-2 mb-lg-0" v-if="$can('Player - Remove Notes')">
                    <span class="small-mb-badge">Action</span>
                    <button class="btn btn-sm btn-danger btn-block"
                            @click="deleteNote(note)"
                    >
                        Delete
                    </button>
                </div>
            </div>

            <not-found v-if="!isLoading"
                       :items="notes"
                       msg="No notes found."
            />

            <loader :show="isLoading"
                    type="small"
            />
        </div>

        <RemoveNoteModal v-if="$can('Player - Remove Notes')"
        />
    </section>
</template>

<script>
    import RemoveNoteModal from "../Components/RemoveNoteModal";
    import PlayerNoteService from "../../../../../services/PlayerNotesService";

    export default {
        name: "notes-list",
        components: {
            RemoveNoteModal
        },
        props: {
            notes: {
                type: Array,
                required: true
            },
            isLoading: {
                type: Boolean,
                required: true
            }
        },
        data: function () {
            return {
                deleteNoteModal: false
            };
        },
        methods: {
            deleteNote: function (note) {
                Atbl.$emit("openDeleteNoteModal", note);
            }
        },
        mounted: function () {
            let vm = this;

            Atbl.$on("deleteNoteOk", function (note) {
                PlayerNoteService.removePlayerNote(note.id)
                    .then(response => {
                        if (response.data.status) {
                            Atbl.$emit("reloadPlayerNotes");
                            Atbl.$emit("removedPlayerNote");
                            Atbl.$emit("closeDeleteNoteModal");
                        }
                        flash(response.data.message);
                    })
            });
        }
    }
</script>