<template>
    <player-layout :id="id">
        <template #header>
            <div>
                <i class='fa fa-ban'></i> Player blocks
            </div>
            <div>
                <block-player-profile :player="player"
                                      @update="onPlayerBlockUpdate"
                />
            </div>
        </template>
        <div class="general-list">
            <div class="row show-first-row">
                <div class="col col-lg-2 text-muted">Blocked By</div>
                <div class="col text-muted">Reason</div>
                <div class="col text-muted text-center">Status</div>
                <div class="col text-muted text-center">Blocked at</div>
                <div class="col text-muted text-center">Unblocked at</div>
            </div>
            
            <div v-if="!isLoading"
                 class="row py-3"
                 v-for="(block, index) in blocks.data"
                 :key="index"
            >
                <div class="col col-lg-2 text-muted">{{ block.blocked_by_name }}</div>
                <div class="col text-muted">
                    <small>{{ block.reason }}</small>
                </div>
                <div class="col text-muted text-center">
                    <span v-if="!block.deleted_at"
                          class="badge badge-danger"
                    >
                        Blocked
                    </span>
                    <span v-else
                          class="badge badge-success"
                    >
                        Unblocked
                    </span>
                </div>
                <div class="col text-muted text-center">
                    <small>{{ utcToLocal(block.created_at) }}</small>
                </div>
                <div class="col text-muted text-center">
                    <small>{{ block.deleted_at ? utcToLocal(block.deleted_at) : '/' }}</small>
                </div>
            </div>
            
            <not-found v-if="!isLoading"
                       :items="blocks.data"
                       msg="No dispositions found."
            />
            
            <loader :show="isLoading"
                    type="small"
            />
            
            <nav v-if="!isLoading && blocks.last_page > 1"
                 class="wrap py-3"
            >
                <atbl-pagination :pagination="blocks"
                                 @paginate="getPlayerBlocks"
                />
            </nav>
        </div>
    </player-layout>
</template>

<script>
    import {clone} from '../../../../utils/Json/Json';
    import PaginationModel from '../../../../modules/pagination/PaginationModel';
    import utcToLocal from '../../../../filters/UtcToLocal';
    import BlockPlayerProfile from '../View/Components/BlockPlayerProfile.vue';
    
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import {createNamespacedHelpers} from "vuex";
    
    const {
        mapGetters,
        mapMutations,
        mapActions
    } = createNamespacedHelpers(NamespaceConst.players);
    
    export default {
        name: 'player-blocks',
        components: {BlockPlayerProfile},
        props: {
            id: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                blocks: clone(PaginationModel),
                isLoading: false
            };
        },
        async created() {
            await this.getPlayerBlocks();
        },
        computed: {
            ...mapGetters({
                player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL,
            }),
        },
        methods: {
            utcToLocal,
            ...mapMutations({
                setPlayerModel: TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL_FIELD,
            }),
            async getPlayerBlocks(page = 1) {
                this.isLoading = true;
                
                try {
                    const response = await window.axios.get(`/players/${this.id}/blocks`, {
                        params: {
                            page
                        }
                    });
                    
                    this.blocks = response.data;
                } catch (error) {
                    window.flashError(error);
                } finally {
                    this.isLoading = false;
                }
            },
            async onPlayerBlockUpdate(block) {
                this.setPlayerModel({
                    block
                });
                
                await this.getPlayerBlocks();
            },
        },
    };
</script>