var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "payment-gateways",
      class: {
        "payment-gateways--disabled": _vm.isLoading || _vm.chargeAmount === 0
      }
    },
    [
      _vm.enableWalletPayment && _vm.$can("Payment - Gateway wallet")
        ? _c(
            "div",
            {
              staticClass:
                "payment-gateway-button payment-gateway-button--wallet",
              class: {
                "payment-gateway-button--disabled": !_vm.hasEnoughWalletFunds
              },
              on: {
                click: function($event) {
                  _vm.walletPaymentConfirmModal = true
                }
              }
            },
            [
              _c("loader", {
                staticClass: "lottery-payment-balance",
                attrs: { show: _vm.isLoading, type: "small" }
              }),
              _vm._v(
                "\n        Wallet - " + _vm._s(_vm.formattedCurrency) + "\n    "
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.gateways, function(gateway) {
        return [
          _vm.$can("Payment - Gateway " + gateway.key)
            ? _c(
                "div",
                {
                  key: "gateway_" + gateway.key,
                  staticClass: "payment-gateway-button",
                  class: [
                    "payment-gateway-button--" + gateway.key,
                    {
                      "payment-gateway-button--toggled":
                        _vm.selectedGatewayDropDown === gateway.key
                    }
                  ],
                  on: {
                    "!click": function($event) {
                      return _vm.onButtonClick(gateway)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(gateway.name) + "\n            "
                  ),
                  _c("loader", {
                    attrs: { show: _vm.isLoading, type: "small" }
                  }),
                  _vm._v(" "),
                  gateway.is_saving_credit_cards_enabled
                    ? [
                        _c("span"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "payment-gateway-button__dropdown-content"
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.selectGateway(gateway.key)
                                  }
                                }
                              },
                              [_vm._v("Pay with credit card")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.showListOfSavedCardsForGateway =
                                      gateway.key
                                  }
                                }
                              },
                              [_vm._v("Pay with saved card")]
                            )
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      }),
      _vm._v(" "),
      _vm.showListOfSavedCardsForGateway
        ? _c("payment-gateway-saved-credit-cards-modal", {
            attrs: {
              "player-id": _vm.playerId,
              "gateway-key": _vm.showListOfSavedCardsForGateway
            },
            on: {
              confirm: _vm.confirmSelectedSavedCreditCard,
              cancel: function($event) {
                _vm.showListOfSavedCardsForGateway = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedGateway === "oppwa"
        ? _c("payment-gateway-oppwa", {
            attrs: { "api-payment-response": _vm.apiPaymentResponse },
            on: {
              cancel: function($event) {
                _vm.selectedGateway = null
                _vm.apiPaymentResponse = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedGateway === "bambora"
        ? _c("payment-gateway-bambora", {
            attrs: {
              "player-id": _vm.playerId,
              "api-payment-response": _vm.apiPaymentResponse
            },
            on: {
              cancel: function($event) {
                _vm.selectedGateway = null
                _vm.apiPaymentResponse = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.walletPaymentConfirmModal
        ? _c("confirm-wallet-payment-modal", {
            attrs: { amount: _vm.chargeAmount },
            on: {
              confirm: function($event) {
                _vm.walletPaymentConfirmModal = false
                _vm.selectGateway("wallet")
              },
              cancel: function($event) {
                _vm.walletPaymentConfirmModal = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }