<template>
    <div class="configuration">
        <h5 class="configuration-header"
            :class="{
                'success': isThisConfigurationValid
            }"
            @click="toggleBody"
        >
            <span>{{ title }}</span>
            <i class="fa" :class="{'fa-caret-up': showBody, 'fa-caret-down': !showBody}" aria-hidden="false"></i>
        </h5>
        <div v-show="showBody"
        >
            <div :class="{
                    'configuration-holder' : getConfiguration.numbers,
                    'configuration-holder-multiple' : !getConfiguration.numbers
                }"
            >
                <div v-for="(item, index) in getMainNumbers"
                     :key="index"
                     :class="{
                        'configuration-input-holder' : getConfiguration.numbers,
                        'configuration-holder-single' : !getConfiguration.numbers,
                        'full': getMainNumbers.length === 1
                    }"
                >
                    <input v-if="getConfiguration.numbers" :id="genId(item, index)" @keyup="changeNumber($event, item)" />
                    <div v-else>
                        <label :for="genId(item, index)" class="font-weight-bold">
                            {{ item.title ? item.title : ucFirst(index) }}
                        </label>
                        <div v-if="item.numbers">
                            <input v-for="(i, index) in item.numbers"
                                   :key="index"
                                   :id="genId(item, index)"
                                   @keyup="changeNumber($event, item)"
                                   :class="{
                                       'configuration-text-input': item.type === 'text'
                                   }"
                            />
                        </div>
                        <input v-else :id="genId(item, index)" @keyup="changePrize" class="configuration-text" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ObjectMixin from "../../../../../../mixins/Object";
    import DrawsEditorMixins from "../../../Mixins/DrawsEditor";

    export default {
        name: "configuration",
        mixins: [
            ObjectMixin,
            DrawsEditorMixins
        ],
        props: {
            configuration: {
                type: Object,
                required: true
            },
            configurationTmp: {
                type: Object,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            input: {}
        },
        data: function(){
            return {
                numbers: [],
                numbersOld: [],
                showBody: true,
                isThisConfigurationValid: false
            };
        },
        methods: {
            genId: function(item, index){
                return this.generateId(item, index, this.type);
            },
            changeNumber: _.debounce(function($event, item){
                let vm = this,
                    value = $event.target.value,
                    minRange = this.isObject(item) ? item.minRange : this.configuration[this.type].minRange,
                    maxRange = this.isObject(item) ? item.maxRange : this.configuration[this.type].maxRange,
                    isTypeText = this.isObject(item) ? item.type : this.configuration[this.type].type;

                // remove current number
                vm.removeInputNumber($event);

                if(isTypeText){
                    if(this.isInRange(value, minRange, maxRange, true)){
                        $event.target.classList.remove("input-has-error");
                        vm.changeInputNumbers($event, item);
                    }else {
                        $event.target.classList.add("input-has-error");
                    }
                }else {
                    if(this.isInRange(value, minRange, maxRange)){
                        $event.target.classList.remove("input-has-error");
                        vm.changeInputNumbers($event, item);
                    }else {
                        $event.target.classList.add("input-has-error");
                    }
                }
            }, 250),
            changePrize: function($event){
                if($event.target.value.length){
                    $event.target.classList.remove("input-has-error");
                    this.changeInputNumbers($event, false, false);
                }else {
                    $event.target.classList.add("input-has-error");
                    this.removeInputNumber($event);
                }
            },
            changeInputNumbers: function($event, item, validate = true){
                let id = this.idResolver($event.target.id),
                    value = $event.target.value,
                    number = {
                        key: id,
                        value:  value
                    },
                    hasDuplicate = validate ? this.checkDuplicate(id, value, item) : false;

                value = value.replace(',', '.');

                if(value.length && !hasDuplicate){
                    $event.target.classList.remove("input-has-error");

                    if(this.numbers.length){
                        let checkIfExists = this.numbers.filter(item => item.key === id);
                        if(checkIfExists.length){
                            let foundIndex = this.numbers.findIndex(item => item.key === id);
                            this.numbers[foundIndex] = number;
                        }else {
                            this.numbers = [
                                ...this.numbers,
                                number
                            ];
                        }
                    }else {
                        this.numbers = [number];
                    }

                    $event.target.value = value;
                }else {
                    $event.target.classList.add("input-has-error");
                }
            },
            removeInputNumber: function($event){
                let targetKey = this.idResolver($event.target.id);

                this.numbers = this.numbers.filter(item => {
                    return item.key !== targetKey;
                });
            },
            validate: function(){
                let vm = this,
                    isValid = false,
                    numOfItems = [];

                if(this.getConfiguration.numbers){
                    isValid = this.numbers.length === this.getConfiguration.numbers;
                }else {
                    let numbersExists = this.getConfiguration.findIndex(
                        obj => Object.keys(obj).includes("numbers")
                    ) !== -1;

                    if(numbersExists){
                        for(let item in this.getConfiguration){
                            let configItem = this.getConfiguration[item],
                                name = this.innerTrim(configItem.key.toLowerCase()),
                                numberOfNumbers = configItem.numbers,
                                interChecked = [];

                            for(let i = 0; i < numberOfNumbers; i++){
                                let indexToFind = `${name}_${i}`,
                                    checkItemExists = vm.numbers.some(item => item.key === indexToFind);
                                if(checkItemExists){
                                    interChecked.push(true);
                                }
                            }

                            if(interChecked.length === numberOfNumbers){
                                numOfItems.push(true);
                            }
                        }
                    }else {
                        numOfItems = this.numbers;
                    }

                    isValid = this.getConfiguration.length === numOfItems.length;
                }

                this.isThisConfigurationValid = isValid;
                this.$emit("input", isValid ? this.numbers : []);
            },
            checkDuplicate: function(key, value, item = true){
                let getKey = key.split("_")[0],
                    isBonusNumber = this.configuration.bonus ? this.configuration.bonus.some(item => item.key === getKey) : false,
                    numbers = this.numbersOld.filter(item => item.key.includes(getKey)),
                    hasDuplicates = numbers.some(item => item.value.toString() === value.toString()),
                    foundIndex = numbers.findIndex(item => item.key === key),
                    foundItem = numbers[foundIndex],
                    configuration = this.getConfiguration.numbers ? this.getConfiguration : this.getConfiguration[0],
                    isSamePool = configuration.samePool ? configuration.samePool : false,
                    mainNumbers = this.configurationTmp["main"],
                    samePoolExists = isSamePool ? mainNumbers.some(item => item.value.toString() === value.toString()) : false;

                if(this.shouldCheckDuplicates(item)){
                    if(isBonusNumber){
                        return hasDuplicates || samePoolExists;
                    }else {
                        if(foundItem){
                            if(hasDuplicates && foundItem.value === value){
                                return true;
                            }
                            return false;
                        }

                        return hasDuplicates;
                    }
                }
                return false;
            },
            isInRange: function(value, min, max, isTypeText = false){
                let valueToCheck = isTypeText ? value.length : value;
                return valueToCheck >= min && valueToCheck <= max;
            },
            shouldCheckDuplicates: function(item){
                if(this.isBoolean(item)){
                    return item;
                }else {
                    if(this.isObject(item)){
                        return this.isset(item, "duplicates") ? item.duplicates : true;
                    }else {
                        return this.isset(this.configuration[this.type], "duplicates") ? this.configuration[this.type].duplicates : true;
                    }
                }
            }
        },
        watch: {
            numbers: {
                handler: function(value){
                    this.numbersOld = value;
                    this.validate();
                }
            }
        },
    }
</script>