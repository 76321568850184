<template>
    <form @submit.prevent="onCreateUpdateEvent">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                    <i class="fa fa-key"></i> Permission Manager
                </div>
                <div v-if="!createUpdateLoader && isEditMode && $can('Permissions - Remove')">
                    <remove-list-item-button :value="permissionModel.id"
                                             @confirmed="onDeletePermission($event, '/permissions')"
                    />
                </div>
            </div>

            <div v-if="!createUpdateLoader"
                 class="card-body"
            >
                <div class="row">
                    <div class="col-12">
                        <atbl-input v-model.trim="changePermissionName"
                                    :errors="permissionErrors"
                                    label="Enter name"
                                    class="mt-2"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <atbl-form-group label="Roles"
                                         class="mt-2 mb-0"
                        >
                            <div class="roles-holder">
                                <div v-for="(item, index) in roles"
                                     :key="index"
                                >
                                    <atbl-check-box :value="item.value"
                                                    :label="item.text"
                                                    :checked="permissionModel.roles.includes(item.value)"
                                                    name="roles"
                                                    @update="onSelectRoleEvent(item)"
                                    />
                                </div>
                            </div>
                        </atbl-form-group>
                    </div>
                </div>
            </div>

            <div v-if="!createUpdateLoader"
                 class="card-footer"
            >
                <button class="btn btn-sm btn-primary mr-2"
                        :disabled="!isModelDirty"
                >
                    Save
                </button>
                <a href="/permissions"
                   class="btn btn-sm btn-danger"
                >
                    Cancel
                </a>
            </div>

            <loader :show="createUpdateLoader"
                    type="small"
            />
        </div>
    </form>
</template>

<script>
import RemoveListItemButton from '../../../../components/Lists/RemoveListItemButton';
import AtblCheckBox from '../../../../components/Forms/Inputs/AtblCheckbox';
import AtblInput from '../../../../components/Forms/Inputs/ATBLInput';
import PermissionModel from '../PermissionModel';
import { clone, stringify } from '../../../../utils/Json/Json';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';
import Loader from '../../../../components/Widgets/Loader';

export default {
    name: 'permission-editor-component',
    inject: [
        'permissionId',
        'isEditMode',
        'onDeletePermission',
        'setPermissionModel',
    ],
    components: {
        AtblCheckBox,
        AtblInput,
        Loader,
        RemoveListItemButton,
    },
    props: {
        model: {
            type: Object,
            default: () => (PermissionModel)
        },
        roles: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            permissionModel: clone(PermissionModel),
            permissionErrors: {},
            createUpdateLoader: false,
        };
    },
    computed: {
        originalModel() {
            return clone(this.model);
        },
        isModelDirty() {
            return stringify(this.originalModel) !== stringify(this.permissionModel);
        },
        changePermissionName: {
            get() {
                return this.permissionModel.name;
            },
            set(value) {
                this.permissionModel.name = !value || value === ''
                    ? null
                    : value;
            }
        },
    },
    created() {
        this.permissionModel = clone(this.model);
    },
    methods: {
        async onCreateUpdateEvent() {
            this.createUpdateLoader = true;

            try {
                const response = !this.isEditMode
                    ? await window.axios.post('/permissions/create', this.permissionModel)
                    : await window.axios.patch(`/permissions/${this.permissionId}/update`, this.permissionModel);
                const { status, message, data } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.permissionLoader = false;

                    return;
                }

                this.setPermissionModel(this.permissionModel);

                if (!this.isEditMode) {
                    window.location.href = `/permissions/${data.id}/update`;

                    return;
                }

                this.createUpdateLoader = false;
            } catch (error) {
                this.createUpdateLoader = false;

                this.permissionErrors = getValidationErrors(error);

                window.flashError(error);
            }
        },
        onSelectRoleEvent(value) {
            if (this.permissionModel.roles.includes(value.value)) {
                this.permissionModel.roles = this.permissionModel.roles.filter(item => item !== value.value);
            } else {
                this.permissionModel.roles = [
                    ...this.permissionModel.roles,
                    value.value
                ];
            }
        },
    },
}
</script>