var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      staticClass: "hide-close",
      attrs: {
        show: _vm.show,
        "close-on-backdrop": false,
        title: "Add Call Note"
      },
      on: {
        close: function($event) {
          return _vm.onCallNoteModalClose(false)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex w-100 align-items-center" }, [
                _c("div", { staticClass: "w-50 font-weight-bold" }, [
                  _vm._v("\n                * Required\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      on: {
                        click: function($event) {
                          return _vm.onCallNoteModalClose(false)
                        }
                      }
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm",
                      class: {
                        "btn-primary": !_vm.isLoading,
                        "btn-outline-primary": _vm.isLoading,
                        disabled: _vm.isLoading
                      },
                      attrs: {
                        disabled: _vm.isLoading || _vm.isSaveButtonDisabled
                      },
                      on: { click: _vm.onCreateNoteEvent }
                    },
                    [
                      !_vm.isLoading
                        ? _c("span", [
                            _vm._v(
                              "\n                        Save\n                    "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: { type: "small", show: _vm.isLoading }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("atbl-textarea", {
        attrs: { errors: _vm.errors, label: "Note *", name: "note" },
        model: {
          value: _vm.model.note,
          callback: function($$v) {
            _vm.$set(_vm.model, "note", $$v)
          },
          expression: "model.note"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }