import { clone } from '../../../utils/Json/Json';
import EmailTemplateSearchModel from './EmailTemplateSearchModel';

export default {
    data() {
        return {
            emailTemplatesSearchModel: clone(EmailTemplateSearchModel)
        };
    },
    methods: {
        updateSearchModel(name, value) {
            Object.assign(this.emailTemplatesSearchModel, {
                [name]: value.toString().length > 0 ? value : null
            });
        },
        async clearSearchModel(){
            this.emailTemplatesSearchModel = {
                ...EmailTemplateSearchModel,
                players_enabled: null,
                leads_enabled: null,
            };

            await this.fetchEmailTemplates();
        }
    }
}