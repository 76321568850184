<template>
    <div class="container-fluid">
        <div class="card card-list" v-for="(birthdayGroup, index) in birthdayGroups" :key="`birthday_group_${index}`">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span><i class='fa fa-align-justify'></i> {{ birthdayGroup.label }}</span>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-3">Player</div>
                        <div class="col-12 col-lg">Birthday</div>
                        <div class="col-12 col-lg">Agent</div>
                        <div class="col-12 col-lg">Last call</div>
                        <div class="col-12 col-lg">Last disposition</div>
                        <div class="col-12 col-lg"></div>
                    </div>

                    <div v-for="player in birthdayGroup.players" :key="`player_row_before_${player.id}`" class="row">
                        <div class="col-12 col-lg-3 mb-1 mb-lg-0">
                            <span class="small-mb-badge">Name</span>
                            {{ player.name }} {{ player.surname }}<br />
                            {{ player.email }}
                        </div>

                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Birthday</span>
                            {{ player.birthdate.format('MMMM Do, YYYY') }}
                        </div>

                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Agent</span>
                            {{ player.user ? player.user.name : '' }}
                        </div>

                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Last call</span>
                            <div v-if="player.call">
                                <call-status-badge :title="player.call.status"></call-status-badge>
                                <br />
                                <small>{{ utcToLocal(player.call.created_at) }}</small>
                            </div>
                        </div>

                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Last disposition</span>
                            <div v-if="player.disposition">
                                <a :href="`/players/${player.id}/dispositions`" target="_blank" class="d-block">
                                    {{ player.disposition.disposition.name }}
                                </a>
                                <small v-if="player.disposition.follow_up" class="follow-up">
                                    Follow Up:
                                    <br />
                                    {{ player.disposition.follow_up || '' }}
                                </small>
                            </div>
                        </div>

                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <a href="#" v-if="$can('Player - View Details')" @click="viewPlayer(player.id)">
                                View Player
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import CallStatusBadge from "../../../CallHistory/_Components/CallStatusBadge";

export default {
    components: { CallStatusBadge },
    props: ['players'],

    data() {
        return {
            daysOffset: 3,
        }
    },

    computed: {
        birthdayGroups() {
            const today = moment().startOf('day');
            const arrayOffset = Array(this.daysOffset).fill(null);

            const players = this.players.map(player => {
                return {
                    id: player.id,
                    name: player.name,
                    surname: player.surname,
                    email: player.email,
                    birthdate: moment(player.birthdate),
                    call: player.calls.length ? player.calls[0] : null,
                    disposition: player.last_disposition,
                    user: player.user,
                };
            });

            const daysBefore = arrayOffset.map((v, i) => moment().subtract(this.daysOffset - i, 'day').format('MM-DD'));
            const daysAfter = arrayOffset.map((v, i) => moment().add(i + 1, 'day').format('MM-DD'));

            return [
                {
                    label: 'Last 3 days',
                    players: players.filter(p => daysBefore.includes(p.birthdate.format('MM-DD'))),
                },
                {
                    label: 'Today',
                    players: players.filter(p => today.format('MM-DD') === p.birthdate.format('MM-DD')),
                },
                {
                    label: 'Next 3 days',
                    players: players.filter(p => daysAfter.includes(p.birthdate.format('MM-DD'))),
                },
            ];
        },
    },

    methods: {
        viewPlayer(id) {
            window.open(`/players/${id}/view`);
        },
    },
}
</script>
