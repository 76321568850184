<template>
    <div class="card">
        <div class="card-header">
            <not-found :items="[]"
                       :msg="message"
            />
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'no-leads-have-been-found-component',
    props: {
        message: {
            type: String,
            default: 'No leads to call at the moment.'
        },
    },
}
</script>