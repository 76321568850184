import playersGetters from "./getters/playersGetters";
import playersFundsGetters from "./getters/playersFundsGetters";
import playersCallHistoryGetters from "./getters/playersCallHistoryGetters";
import playersPlayGetters from "./getters/playersPlayGetters";
import playersWalletManagerGetters from "./getters/playersWalletManagerGetters";

export default {
    ...playersGetters,
    ...playersFundsGetters,
    ...playersCallHistoryGetters,
    ...playersPlayGetters,
    ...playersWalletManagerGetters
};
