export default {
    provide() {
        return {
            showCallRecordings: this.showCallRecordings,
            toggleCallRecordingsSidebar: this.toggleCallRecordingsSidebar,
        };
    },
    data() {
        return {
            callHistoryRecordingsSidebarOpen: false,
            callHistoryRecordingsLoader: false,
            callHistoryRecordingsList: [],
        };
    },
    methods: {
        async showCallRecordings(id = null) {
            if (!id) {
                return window.showMessage('Cannot get call recordings without ID.', false);
            }

            this.callHistoryRecordingsSidebarOpen = true;
            this.callHistoryRecordingsLoader = true;

            try {
                const response = await window.axios.get(`/call/history/${id}/recordings`);
                const { data } = response.data;

                this.callHistoryRecordingsList = data;
                this.callHistoryRecordingsLoader = false;
            } catch (error) {
                this.callHistoryRecordingsLoader = false;
                this.callHistoryRecordingsSidebarOpen = false;

                window.flashError(error);
            }
        },
        toggleCallRecordingsSidebar() {
            this.callHistoryRecordingsSidebarOpen = !this.callHistoryRecordingsSidebarOpen;

            if (!this.callHistoryRecordingsSidebarOpen) {
                this.callHistoryRecordingsList = [];
            }
        },
    },
}