import { clone } from '../../../utils/Json/Json';
import EmailTemplateModel from './EmailTemplateModel';

export default {
    data() {
        return {
            createFormReady: false,
            createFormShown: false,
            createFormLoading: false,
            emailModel: clone(EmailTemplateModel),
            resolvedEmailModel: {},
            emailError: {},
            activeSourceType: 0,
        };
    },
    methods: {
        async onSaveEmailTemplate() {
            this.createFormLoading = true;
            this.emailError = {};

            try {
                const meta = this.resolveModelMetaToRequestData(this.emailModel.meta);

                const url = this.isset(this.emailModel, 'id') && !!this.emailModel.id
                    ? `/email-templates/${this.emailModel.id}/update`
                    : `/email-templates/create`;

                const response = await window.axios.post(url, {
                    ...this.emailModel,
                    parent_id: this.category_id,
                    meta
                });

                const { status, message } = response.data;

                if (status) {
                    this.toggleCreateUpdateForm();
                    await this.fetchEmailTemplates();
                }

                window.showMessage(message, status);

                this.createFormLoading = false;
            } catch (error) {
                const { response: { status, data } } = error;

                if (status === 422) {
                    this.emailError = data.errors;
                }

                window.flashError(error);
                this.createFormLoading = false;
            }
        },
        updateType(userType, name, value) {
            this.updateUserTypeModel(userType, name, value);

            if (value === 'Internal') {
                this.updateUserTypeModel(userType, 'type_source', null);
            }
        },
        updateSource(userType, name, value) {
            if (!value) {
                this.updateUserTypeModel(userType, name, null);

                return;
            }

            const source = this.externalTypes[value];

            if (!source) {
                throw new Error('Source type is not found.');
            }

            this.updateUserTypeModel(userType, name, value);
        },
        updateModel(name, value) {
            Object.assign(this.emailModel, {
                [name]: value
            });
        },
        updateUserTypeModel(userType, name, value) {
            Object.assign(this.emailModel.meta[userType], {
                [name]: value
            });
        },
        updateUserTypeInternalLinkModel(userType, name, value) {
            const language = name.split('_')[1];

            this.emailModel.meta[userType].links[language] = value;
        },
        updateUserTypeModelMetaField(userType, sourceType, sourceOption, name, value) {
            this.emailModel.meta[userType].externalTypes[sourceType].meta[sourceOption][name].value = value;
        },
        updateUserTypeModelMetaAttributeField(userType, sourceType, sourceOption, name, attributeName, value) {
            const attributes = this.emailModel.meta[userType].externalTypes[sourceType].meta[sourceOption][name].attributes;
            const attribute = attributes.find(item => item.name === attributeName);

            if (!attribute) {
                return;
            }

            attribute.value = value;
        },
        updateActiveSourceType(value) {
            this.activeSourceType = value;
        },
        toggleCreateUpdateForm() {
            this.createFormShown = !this.createFormShown;

            if (!this.createFormShown) {
                this.clearModel();
            }
        },
        clearModel() {
            Object.assign(this.emailModel, clone(this.resolvedEmailModel));
            this.emailError = {};
            this.activeSourceType = 0;
        },
        resolveModelMetaToRequestData(meta) {
            if (this.isObjectEmpty(meta)) {
                return {};
            }

            let tmpObject = {};

            for (const userType of this.emailTemplateUserTypes) {
                const { source_id, type, type_source, externalTypes, links } = meta[userType];

                // eg. customerio, etc...
                const sourceTypes = Object.keys(externalTypes)
                    .map(sourceTypeItem => {
                        const meta = externalTypes[sourceTypeItem].meta;

                        // eg. languages, options, etc...
                        const sourceOptions = Object.keys(meta)
                            .map(sourceOptionItem => {
                                const sourceOptionValue = meta[sourceOptionItem];

                                // eg. en, de, etc...
                                const sourceOptionValues = Object.keys(sourceOptionValue)
                                    .map(sourceOptionValueItem => {
                                        const attributes = sourceOptionValue[sourceOptionValueItem].hasOwnProperty('attributes') && !!sourceOptionValue[sourceOptionValueItem].attributes
                                            ? sourceOptionValue[sourceOptionValueItem].attributes.map(item => {
                                                return [item.name, item.value];
                                            })
                                            : null;

                                        return [
                                            sourceOptionValueItem,
                                            {
                                                value: sourceOptionValue[sourceOptionValueItem].value,
                                                attributes: attributes ? Object.fromEntries(attributes) : null
                                            }
                                        ];
                                    });

                                return [
                                    sourceOptionItem,
                                    Object.fromEntries(sourceOptionValues)
                                ];
                            });

                        return [
                            sourceTypeItem,
                            Object.fromEntries(sourceOptions)
                        ];
                    });

                tmpObject[userType] = {
                    source_id,
                    type,
                    type_source,
                    links,
                    sourceTypes: Object.fromEntries(sourceTypes)
                };
            }

            return tmpObject;
        },
    },
}