<template>
    <atbl-modal title="Import errors log"
             :show="true"
             :close-on-backdrop="false"
             size="lg"
             @close="toggleErrorsModal"
    >
        <template #body-wrapper>
            <div class="modal-body p-0">
                <div v-if="!isObjectEmpty(errors)"
                     class="general-list import-leads-errors-log-list"
                >
                    <div class="row no-gutters">
                        <div class="col-12">
                            Message
                        </div>
                    </div>

                    <div v-for="(item, index) in errors"
                         :key="index"
                         class="row no-gutters"
                         @click="onMarkRowEvent"
                    >
                        <div v-for="(errorItem, errorIndex) in item"
                               :key="errorIndex"
                               class="col-12"
                        >
                            {{ errorItem }}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <not-found msg="No errors found"
                               :items="[]"
                    />
                </div>
            </div>
        </template>

        <template #footer>
            <button class="btn btn-sm btn-danger"
                      @click="toggleErrorsModal"
            >
                Close
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'import-errors-log-modal',
    inject: [
        'toggleErrorsModal',
    ],
	components: {
		AtblModal,
	},
    props: {
        errors: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        onMarkRowEvent(event) {
            event.preventDefault();

            if (!event.target.classList.contains('row')) {
                return;
            }

            if (event.target.classList.contains('bg-success')) {
                event.target.classList.remove('bg-success');
                event.target.classList.remove('text-white');
            } else {
                event.target.classList.add('bg-success');
                event.target.classList.add('text-white');
            }
        }
    }
}
</script>