<template>
    <atbl-form-group label="Roles"
                     class="font-weight-bold"
    >
        <tree-select v-model="selectedRole"
                     :multiple="true"
                     :value="value"
                     :options="roles"
                     :disabled="disabled || isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
    </atbl-form-group>
</template>

<script>
export default {
    name: 'roles-picker',
    props: {
        value: {
            type: [Number, Array],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'roles',
        },
    },
    data() {
        return {
            roles: [],
            isLoading: false,
        };
    },
    computed: {
        selectedRole: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
    async created() {
        await this.fetchRoles();
    },
    methods: {
        async fetchRoles() {
            this.isLoading = true;

            try {
                const response = await window.axios.get('/list-roles');

                this.roles = response.data
                    .filter(item => item.text !== 'Admin')
                    .map(({ text: label, value: id }) => {
                        return {
                            label,
                            id
                        };
                    });

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>