<template>
    <span class="badge text-uppercase"
          :class="`badge-${variant}`"
    >
        {{ title }}
    </span>
</template>

<script>
export default {
    name: 'call-status-badge',
    props: {
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        variant() {
            switch (this.title) {
                case 'failed':
                    return 'danger';
                case 'queued':
                    return 'secondary';
                case 'complete':
                case 'completed':
                    return 'success';
                case 'ringing':
                    return 'warning';
                case 'in-progress':
                    return 'info';
                case 'canceled':
                    return 'dark';
                case 'no-answer':
                    return 'primary';
                case 'busy':
                    return 'Light';
            }
        }
    }
}
</script>