var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canPlayGame
    ? _c(
        "div",
        [
          !_vm.isLoading && _vm.getAllGames.length
            ? _c(
                "div",
                { staticClass: "player-play-games-holder" },
                _vm._l(_vm.getAllGames, function(item, index) {
                  return _c("Game", {
                    key: index,
                    attrs: { item: item, "order-id": _vm.orderId }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.getAllGames, msg: "No game draws found." }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }