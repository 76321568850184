<template>
    <div class="game-header d-flex align-items-center w-100">
        <img :src="$imageService.gameImage(draw.game_image)" class="logo-game"/>
        <div class="d-flex justify-content-between w-100 align-items-center">
            <div>
                <h4 class="m-0">
                    <div>{{ draw.game_name }}</div>
                </h4>
                <span>
                    #{{ draw.id }}
                </span>
            </div>
            <h4 class="m-0">
                <p class="text-center m-0 draw-date-title">Draw Date</p>
                <span>{{ draw.date }}</span>
            </h4>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CurrentDrawHeaderComponent',
    props: {
        draw: {
            type: Object,
            default: () => ({})
        },
    },
}
</script>

<style scoped lang="scss">
.game-header {
    .draw-date-title {
        font-size: 14px;
        color: #9a9a9a;
    }

    img {
        width: 70px;
        margin-right: 20px
    }
}
</style>