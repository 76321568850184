<template>
	<div v-if="canPlayGame">
		<div v-if="!isLoading && getAllGames.length" class="player-play-games-holder">
            <Game  v-for="(item, index) in getAllGames"
                   :key="index"
                   :item="item"
                  :order-id="orderId"
            />
		</div>

		<not-found v-if="!isLoading"
                   :items="getAllGames"
		           msg="No game draws found."
		/>

        <loader :show="isLoading"
                type="small"
        />
	</div>
</template>

<script>
	import TypesConst from "../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";

	const {
		mapGetters: mapGamesGetters,
		mapActions: mapGamesActions
	} = createNamespacedHelpers(NamespaceConst.games);

	const {
		mapGetters: mapCartGetters
	} = createNamespacedHelpers(NamespaceConst.cart);

    const {
        mapGetters: mapOrderGetters
    } = createNamespacedHelpers(NamespaceConst.order);

	import Game from "./Components/Game";

	export default {
		name: "Games",
		components: {
			Game
		},
		props: {
			isLoading: {
				type: Boolean,
				required: true
			}
		},
		computed: {
			...mapGamesGetters({
				getAllGames: TypesConst.games.getters.GET_ALL_GAMES_DATA
			}),
			...mapCartGetters({
				canPlayGame: TypesConst.cart.getters.GET_PLAYER_CAN_PLAY_GAME
			}),
            ...mapOrderGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            })
		},
		methods: {
			...mapGamesActions({
				getAllGamesAction: TypesConst.games.actions.GET_ALL_GAMES_ACTION
			})
		},
		created: function () {
			this.getAllGamesAction();
		}
	}
</script>