export default {
    methods: {
        getError: function (errors, field) {
            if (errors && errors[field]) {
                return errors[field][0];
            }
        },
        getState: function (errors, field) {
            return errors[field] ? false : null;
        },
        hasError: function (errors, field) {
            return errors && !!errors[field];
        },
        isValidInput: function (errors, field) {
            return errors ? (this.getError(errors, field) ? this.hasError(errors, field) : null) : null;
        },
        hasPermissionError: function (error) {
            return error.response.status === 403;
        }
    }
}