<template>
    <div class="card leads-section card-list">
        <div class="card-header">
            <i class="fa fa-user"></i> Last Dispositions
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="row">
                    <div class="col-6 text-muted">Status</div>
                    <div class="col-6 text-muted text-center">Date</div>
                </div>

                <template v-if="!isLoading">
                    <div v-for="(item, index) in statuses"
                         :key="index"
                         class="row"
                    >
                        <div class="col-6 text-muted">
                            {{ item.status_name }}
                        </div>
                        <div class="col-6 text-muted text-center">
                            <small>{{ utcToLocal(item.created_at) }}</small>
                        </div>
                        <div class="col-12 c-flex align-items-center c-light">
                            <span v-tooltip="{
                                     content: 'Agent'
                                  }"
                                  class="badge badge-success"
                            >
                                {{ item.user_name }}
                            </span>
                            <span class="mx-2">
                                 /
                            </span>
                            <span v-tooltip="{
                                      content: 'Campaign'
                                  }"
                                  class="badge badge-primary"
                            >
                                {{ item.campaign_name }}
                            </span>
                        </div>
                    </div>
                </template>

                <items-not-found v-if="!isLoading"
                                 :items="statuses"
                                 msg="No status found."
                />

                <loader class="mt-3"
                        :show="isLoading"
                        type="small"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ItemsNotFound from '../../../../../../components/Lists/ItemsNotFound';
import Loader from '../../../../../../components/Widgets/Loader';

export default {
    name: 'customer-dispositions-component',
    components: {
        ItemsNotFound,
        Loader,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        statuses: {
            type: Array,
            default: () => ([])
        },
    },
}
</script>