import GameConfigurationService from '../../services/GameConfigurationService';

export default {
    data() {
        return {
            gameConfigurations: [],
            isLoading: false
        };
    },

    mounted() {
        this.isLoading = true;
        GameConfigurationService.index()
            .then(response => {
                this.gameConfigurations = response.data;
                this.onConfigurationLoaded?.();
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    methods: {
        getGameConfiguration(gameName) {
            return this.gameConfigurations.find(c => c.name === gameName);
        },

        getBundleGamePrice(gameName) {
            const gameConfiguration = this.getGameConfiguration(gameName);

            if(!gameConfiguration) {
                return 0;
            }

            return parseFloat(gameConfiguration.price) * gameConfiguration.weekdays.length;
        }
    }
};