<template>
    <atbl-modal title="Enable Syndicate" :close-on-backdrop="true" :show="true" @close="emitCloseEvent">
        <div v-if="! isRecurring || (syndicateItem.is_active && !syndicate.is_active)" class="row">
            <div class="col-12">
                <p>Are you sure you want to enable this syndicate ?</p>
            </div>
        </div>

        <div v-if="isRecurring && syndicate.is_active && ! syndicateItem.is_active" class="row">
            <div class="col-12">
                <p>Are you sure you want to enable this syndicate draw ?</p>
            </div>
        </div>

        <template #footer>
            <template v-if="! isRecurring || (syndicateItem.is_active && !syndicate.is_active)">
                <button class="btn btn-sm btn-danger" @click="emitEnableSyndicate">
                    Yes
                </button>

                <button class="btn btn-sm btn-primary" @click="emitCloseEvent">
                    No
                </button>
            </template>

            <template v-if="isRecurring && syndicate.is_active && ! syndicateItem.is_active">
                <button class="btn btn-sm btn-danger" @click="emitEnableSyndicateItem">
                    Yes
                </button>

                <button class="btn btn-sm btn-primary" @click="emitCloseEvent">
                    No
                </button>
            </template>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'syndicates-enable-modal',
	components: {
		AtblModal,
	},
    props: ['syndicateItem'],

    data() {
        return {
            //
        };
    },

    computed: {
        syndicate() {
            return this.syndicateItem.syndicate;
        },

        isRecurring() {
            return !!this.syndicate.is_recurring;
        },
    },

    methods: {
        emitEnableSyndicate() {
            this.$emit('enableSyndicate', 'syndicate');
        },

        emitEnableSyndicateItem() {
            this.$emit('enableSyndicate', 'syndicateItem');
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },
    },
}
</script>