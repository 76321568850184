var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$canAny(_vm.permissions)
    ? _c("CContainer", { attrs: { fluid: "" } }, [
        _c("div", { staticClass: "row" }, [
          _vm.$can("Dashboard - View Player Section")
            ? _c(
                "div",
                { staticClass: "col-12 mb-3" },
                [
                  _c("PlayersSection", {
                    staticClass: "pb-0",
                    attrs: { data: _vm.playersCreated }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Dashboard - View Sales Section")
            ? _c(
                "div",
                { staticClass: "col-12 col-xl-8 mb-3" },
                [_c("SalesSection")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$canAny([
            "Dashboard - View Deposits Section",
            "Dashboard - View Bonus Section"
          ])
            ? _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "row" }, [
                  _vm.$can("Dashboard - View Deposits Section")
                    ? _c(
                        "div",
                        {
                          staticClass: "col-12 col-md-6 col-xl-8 mb-3 mb-md-0"
                        },
                        [_c("DepositsSection")],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("Dashboard - View Bonus Section")
                    ? _c(
                        "div",
                        { staticClass: "col-12 col-md-6 col-xl-4" },
                        [_c("BonusSection")],
                        1
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Dashboard - View Calls Section")
            ? _c(
                "div",
                { staticClass: "col-12 mb-3" },
                [
                  _c("CallStatistics", {
                    staticClass: "pb-0",
                    attrs: { data: _vm.calls }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Dashboard - View Disposition Breakdown Section")
            ? _c(
                "div",
                { staticClass: "col-12 mb-3" },
                [
                  _c("DispositionBreakdownSection", {
                    attrs: { data: _vm.dispositionBreakdown }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }