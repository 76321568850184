<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span>
                    <i class='fa fa-calendar-alt'></i> Shifts
                </span>
                <button v-if="$can('Shifts - Create/Update')"
                          class="btn btn-sm btn-primary"
                          @click="createUpdateShiftModalOpen = true"
                >
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg">ID</div>
                        <div class="col-12 col-lg">Name</div>
                        <div class="col-12 col-lg">Description</div>
                        <div class="col-12 col-lg">Start</div>
                        <div class="col-12 col-lg">Finish</div>
                        <div class="col-12 col-lg"
                               v-if="$canAny(shiftOperationPermissions)"
                        >
                            Operations
                        </div>
                    </div>

                    <div v-if="!isLoading"
                           v-for="(item, index) in shifts"
                           :key="index"
                           class="row"
                    >
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">ID</span>
                            {{ item.id }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Name</span>
                            {{ item.name }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Description</span>
                            {{ item.description | elipsis }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Start</span>
                            {{ item.start }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Finish</span>
                            {{ item.finish }}
                        </div>
                        <div class="col-12 col-lg"
                               v-if="$canAny(shiftOperationPermissions)"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <div class="d-flex justify-content-start align-items-center">
                                <a v-if="$can('Shifts - Create/Update')"
                                   href="#"
                                   class="mr-2"
                                   @click.prevent="onUpdateShiftEvent(item)"
                                >
                                    <i class="fa fa-pen"></i>
                                </a>
                                <a v-if="$can('Shifts - Remove')"
                                   href="#"
                                   @click.prevent="getShiftDetailsForDelete(item.id)"
                                >
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <not-found v-if="!isLoading"
                           :items="shifts"
                           msg="No shifts found"
                />

                <loader :show="isLoading"
                        type="small"
                />
            </div>
        </div>

        <create-update-shift-component :show="createUpdateShiftModalOpen"
                                       :model="shiftModel"
                                       :model-errors="shiftModelErrors"
                                       :is-loading="isCreateShiftLoading"
        />

        <delete-shift-component :show="deleteShiftModalOpen"
                                :is-loading="deleteShiftLoader"
                                :selected-shift="selectedShift"
        />
    </div>
</template>

<script>
import ConfirmableButton from '../../components/Buttons/ConfirmableButton';
import CreateUpdateShift from './CreateUpdateShift/CreateUpdateShift';
import CreateUpdateShiftComponent from './CreateUpdateShift/CreateUpdateShiftComponent';
import ShiftsService from '../../services/ShiftsService';
import DeleteShiftComponent from './DeleteShift/DeleteShiftComponent';
import DeleteShift from './DeleteShift/DeleteShift';

export default {
    mixins: [
        CreateUpdateShift,
        DeleteShift,
    ],
    components: {
        DeleteShiftComponent,
        ConfirmableButton,
        CreateUpdateShiftComponent,
    },
    data: function () {
        return {
            shifts: [],
            isLoading: false,
            shiftOperationPermissions: [
                'Shifts - Create/Update',
                'Shifts - Remove'
            ],
        }
    },
    async created() {
        await this.fetchShifts();
    },
    methods: {
        async fetchShifts() {
            this.isLoading = true;

            const response = await ShiftsService.fetchShifts();

            this.shifts = response.data;

            this.isLoading = false;
        },
    }
}
</script>
