import playersMutations from "./mutations/playersMutations";
import playersFundsMutations from "./mutations/playersFundsMutations";
import playersCallHistoryMutations from "./mutations/playersCallHistoryMutations";
import playersPlayMutations from "./mutations/playersPlayMutations";
import playersWalletManagerMutations from "./mutations/playersWalletManagerMutations";

export default {
    ...playersMutations,
    ...playersFundsMutations,
    ...playersCallHistoryMutations,
    ...playersPlayMutations,
    ...playersWalletManagerMutations
};
