var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "configuration-winning-prizes-item",
      class: {
        editable: !_vm.disabled && !!_vm.numberType.hasOwnProperty("editable")
      }
    },
    [
      !_vm.disabled && _vm.isEditable
        ? _c(
            "a",
            {
              staticClass: "add-field",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.showForm = true
                }
              }
            },
            [_c("i", { staticClass: "fa fa-plus" })]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled &&
      !_vm.isEditable &&
      _vm.isChild &&
      !_vm.numberType.is_refund
        ? _c(
            "a",
            {
              staticClass: "remove-field",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onDeleteFieldEvent.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "fa fa-times" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "configuration-winning-prizes-title",
          class: {
            editable:
              !_vm.disabled && !!_vm.numberType.hasOwnProperty("editable")
          }
        },
        [
          _c("h6", { staticClass: "my-2" }, [
            _vm._v(_vm._s(_vm.numberType.title))
          ]),
          _vm._v(" "),
          _vm.isEditable && !_vm.disabled
            ? _c("span", [_vm._v(_vm._s(_vm.numberType.key))])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "configuration-text-input",
        class: {
          "input-has-error": !!_vm.numberType.error
        },
        attrs: { id: _vm.numberType.id, disabled: _vm.disabled },
        domProps: { value: _vm.numberType.value },
        on: {
          input: function($event) {
            return _vm.changeNumber(
              $event,
              _vm.index,
              _vm.numberTypeIndex,
              _vm.numberType
            )
          }
        }
      }),
      _vm._v(" "),
      !!_vm.numberType.editable && _vm.showForm
        ? _c("raffle-configuration-form-prize-winner-item", {
            attrs: {
              "number-type": _vm.numberType,
              "number-index": _vm.numberTypeIndex
            },
            on: {
              update: _vm.onUpdateConfigurationEvent,
              close: function($event) {
                _vm.showForm = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }