<template>
    <atbl-modal title="Create Currency Rate"
             :show="modalOpened"
             :close-on-backdrop="false"
             @close="modalOpened = false"
    >
        <div class="row">
            <div class="col-12">
                <atbl-input label="Enter currency code"
                            name="currency_code"
                            v-model.trim="editModel.currency_code"
                            :errors="errors"
                />
                <atbl-input label="Enter currency name (ISO 4217 Currency Name)"
                            name="currency_name"
                            v-model.trim="editModel.currency_name"
                            :errors="errors"
                />
                <atbl-input label="Enter rate"
                            name="rate"
                            v-model.trim="editModel.rate"
                            :errors="errors"
                />
            </div>
        </div>

        <template #footer v-if="$can('Conversions Rate - Create/Update')">
            <button class="btn btn-sm btn-light"
                      @click="cancelModal"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                      @click="submitModal"
            >
                Save
            </button>
        </template>
    </atbl-modal>
</template>

<script>
    import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

    export default {
        name: "create-currency-rate",
        components: {
			AtblModal,
        },
        props: {
            editModal: {
                type: Boolean,
                required: true
            },
            model: {
                type: Object,
                required: true
            },
            errors: {
                type: Object
            }
        },
        data(){
            return {
                modalOpened: false,
                editModel: {
                    id: 0,
                    currency_code: null,
                    currency_name: null,
                    rate: null
                }
            };
        },
        methods: {
            submitModal: function(e){
                e.preventDefault();
                this.$emit('on-edit', this.editModel);
            },
            cancelModal: function(e){
                e.preventDefault();
                this.editModel = {
                    id: 0,
                    currency_code: null,
                    currency_name: null,
                    rate: null
                };
                this.$emit('cancel', this.editModel);
            }
        },
        watch: {
            editModal: function(value){
                this.modalOpened = value;
            },
            model: {
                handler(value){
                    this.editModel.id = value.id;
                    this.editModel.currency_code = value.currency_code;
                    this.editModel.currency_name = value.currency_name;
                    this.editModel.rate = value.rate;
                },
                deep: true
            },
            modalOpened: function(value){
                if(!value){
                    this.$emit('cancel');
                }
            }
        }
    }
</script>