<template>
    <atbl-modal title="Assign bonus to player" :close-on-backdrop="true" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <atbl-form-group label="Game" label-for="game" class="font-weight-bold" :horizontal="false" :error="null">
                    <select class="form-control" id="game" v-model="selectedBonusUuid">
                        <option :value="null">Select Bonus</option>
                        <option v-for="bonus in bonusList" :key="bonus.id" :value="bonus.uuid">
                            {{ bonus.title }}
                        </option>
                    </select>
                </atbl-form-group>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-primary" @click="assignBonus()">
                Assign
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'assign-bonus-modal',
    components: {
		AtblModal,
    },
    data() {
        return {
            bonusList: [],

            selectedBonusUuid: null,
        };
    },

    mounted() {
        this.fetchBonuses();
    },

    methods: {
        emitCloseEvent() {
            this.$emit('cancel');
        },

        fetchBonuses() {
            window.axios.get('/bonuses?exclude-expired').then(response => {
                this.bonusList = response.data;
            });
        },

        assignBonus() {
            this.$emit('assign', this.selectedBonusUuid);
        },
    },
}
</script>