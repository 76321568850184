var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid permissions-manager" },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("permission-editor-component", {
                  attrs: { model: _vm.permissionModel, roles: _vm.roles }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.isEditMode
              ? _c(
                  "div",
                  { staticClass: "col-12 col-lg-6" },
                  [
                    _c("users-editor-component", {
                      attrs: {
                        model: _vm.permissionModel,
                        "users-data": _vm.usersData
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }