export default {
    getCallHistoryRoute: function(){
        return "/call/history";
    },
    getCallHistoryStatisticsRoute: function(){
        return `${this.getCallHistoryRoute()}/statistics`;
    },
    getDailyStatistics: function(agent = null){
        return window.axios.get(`${this.getCallHistoryStatisticsRoute()}/daily${agent ? `/${agent}` : ''}`);
    },
    getRangeStatistics: function(params = {}, agent = null){
        return window.axios.get(`${this.getCallHistoryStatisticsRoute()}/range${agent ? `/${agent}` : ''}`, {
            params
        });
    },
    getAgentsStatistics: function(params = {}){
        return window.axios.get(`${this.getCallHistoryStatisticsRoute()}/agents`, {
            params
        });
    },
    getDailyTotalStatistics: function(agent, params = {}){
        return window.axios.get(`${this.getCallHistoryStatisticsRoute()}/daily/${agent}/total`, {
            params
        });
    }
}