import moment from "moment";

export default {
    methods: {
        utcToLocal(date) {
            return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss");
        },

        getRangeFor(range, format = 'YYYY-MM-DD') {
            let dateFrom, dateTo;

            switch (range) {
                case 'yesterday':
                    dateFrom = moment().subtract(1, 'day').startOf('day');
                    dateTo = moment().subtract(1, 'day').startOf('day');
                    break;
                case 'week':
                    dateFrom = moment().startOf('isoWeek');
                    dateTo = moment().endOf('isoWeek');
                    break;
                case 'month':
                    dateFrom = moment().startOf('month');
                    dateTo = moment().endOf('month');
                    break;
                case 'year':
                    dateFrom = moment().startOf('year');
                    dateTo = moment().endOf('year');
                    break;
                case 'today':
                default:
                    dateFrom = moment().startOf('day')
                    dateTo = moment().endOf('day')
                    break;
            };

            dateFrom = dateFrom.format(format);
            dateTo = dateTo.format(format);

            return [dateFrom, dateTo];
        },
        validateDateBetweenTwoDates(fromDate, toDate, givenDate){
            return this.createValidDate(givenDate) <= this.createValidDate(toDate) && this.createValidDate(givenDate) >= this.createValidDate(fromDate);
        },
        createValidDate (d) {
            return new Date(d);
        }
    }
}