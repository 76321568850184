export default {
    status: {
        PENDING: "PENDING",
        PAYMENT_PROCESSING: "PAYMENT_PROCESSING",
        PAYMENT_FAILED: "PAYMENT_FAILED",
        COMPLETED: "COMPLETED",
        PARTLY_COMPLETED: "PARTLY_COMPLETED"
    },
    statusColor: function (status) {
        switch (status) {
            case this.status.PAYMENT_FAILED:
                return "danger";
            case this.status.COMPLETED:
                return "success";
            case this.status.PARTLY_COMPLETED:
                return "info";
            case this.status.PENDING:
            case this.status.PAYMENT_PROCESSING:
            default:
                return "warning";
        }
    }
};