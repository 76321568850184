<template>
    <div class="container-fluid">
        <div class="card card-search">
            <div class="card-header">
                <i class='fa fa-search' aria-hidden='false'></i> Bundle filter
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-3 mb-2 m-lg-0">
                        <atbl-switch v-model="disabledOnly"
                                     :off="false"
                                     :on="true"
                                     name="disabledOnly"
                                     label="Show disabled only bundle"
                        />
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button type="submit" class="btn btn-sm btn-primary" @click="fetchBundles">
                    <i class="fa fa-search"></i> Search
                </button>
            </div>
        </div>

        <div class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i class="fa fa-exchange-alt"></i> Bundles
                </div>

                <button class="btn btn-sm btn-primary" @click="openCreateModal" v-if="$can('Bundles - Create/Update')">
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col text-muted">Reference</div>
                        <div class="col text-muted">Title</div>
                        <div class="col text-muted">Games</div>
                        <div class="col text-muted">Description</div>
                        <div class="col text-muted">Ribbon text</div>
                        <div class="col text-muted">Price</div>
                        <div class="col text-muted">Lines per game</div>
                        <div class="col text-muted">Jackpot</div>
                        <div class="col text-muted">Is Active</div>
                        <div class="col text-muted">Is CRM enabled</div>
                        <div class="col text-muted">Is API enabled</div>
                        <div class="col text-muted text-center">Action</div>
                    </div>

                    <template v-for="bundle in bundles">
                        <div :key="bundle.id" class="row">
                            <div class="col">
                                <a class="d-block" :href="`/bundles/${bundle.id}/edit`" v-if="$can('Bundles - View List')">
                                    <i class="fa fa-link"></i> {{ bundle.id }}
                                </a>
                                <span v-else>
                                    Id: {{ bundle.id }}
                                </span>
                            </div>
                            <div class="col">
                                {{ bundle.title }}
                            </div>
                            <div class="col">
                                <img v-for="bundleGame in bundle.bundle_games" :src="$imageService.gameImage(bundleGame.game.image)" style="width: 40px; height: auto;">
                            </div>
                            <div class="col">
                                {{ bundle.description }}
                            </div>
                            <div class="col">
                                {{ bundle.ribbon_text }}
                            </div>
                            <div class="col">
                                {{ getBundlePrice(bundle).toFixed(2) | formatCurrency }}
                            </div>
                            <div class="col">
                                {{ bundle.bundle_games[0].number_of_lines }}
                            </div>
                            <div class="col">
                                <span v-html="getBundleCurrencySymbol(bundle)" /> {{ bundle.jackpot }}
                            </div>
                            <div class="col">
                                {{ bundle.is_active ? 'Yes' : 'No' }}
                            </div>
                            <div class="col">
                                {{ bundle.is_crm_enabled ? 'Yes' : 'No' }}
                            </div>
                            <div class="col">
                                {{ bundle.is_api_enabled ? 'Yes' : 'No' }}
                            </div>
                            <div class="col text-center">
                                <a v-if="$can('Bundles - Create/Update')" class="d-block" :href="`/bundles/${bundle.id}/edit`">
                                    <small><i class="fa fa-user"></i> Edit bundle</small>
                                </a>
                            </div>
                        </div>
                    </template>

                    <not-found v-if="! isLoading && ! bundles.length" msg="No bundles found" :items="bundles" />

                    <loader :show="isLoading" type="small" />
                </div>
            </div>
        </div>

        <bundle-create-modal v-if="isCreateModalVisible && $can('Bundles - Create/Update')"
                                 v-on:cancel="isCreateModalVisible = false"
                                 v-on:confirm="storeBundle" />
    </div>
</template>

<script>
import BundleCreateModal from './Modals/CreateEditModal';
import BundleService from '../../services/BundleService';
import CurrenciesService from '../../services/CurrenciesService';
import BundleMixin from '../../mixins/Bundles/Bundle';

export default {
    components: { BundleCreateModal },

    mixins: [ BundleMixin ],

    data() {
        return {
            isLoading: false,
            isCreateModalVisible: false,
            bundles: [],
            disabledOnly: false,
        };
    },

    watch: {
        disabledOnly() {
            this.fetchBundles();
        },
    },

    mounted() {
        this.fetchBundles();
    },

    methods: {
        fetchBundles() {
            this.isLoading = true;
            this.bundles = [];

            const params = {
                ...(this.disabledOnly && { disabledOnly: this.disabledOnly }),
            };

            BundleService
                .index(params)
                .then(response => {
                    this.bundles = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        storeBundle(formData) {
            BundleService
                .store(formData)
                .then(response => {
                    this.isCreateModalVisible = false;
                    this.fetchBundles();
                })
                .catch(err => {
                    const errResponse = err.response;

                    if (errResponse.status === 422) {
                        const errors = [errResponse.data.message];

                        for (const [key, error] of Object.entries(errResponse.data.errors)) {
                            errors.push(error[0]);
                        }

                        window.flash(errors.join('<br />'), 'alert-danger');
                    }
                });
        },

        getBundlePrice(bundle) {
            const totalBundleGamesPrice = bundle.bundle_games.reduce((a, g) => a + this.getBundleGamePrice(g.game.name), 0);
            const firstWeek = bundle.bundle_weeks[0];

            const total = firstWeek.price ? firstWeek.price : (totalBundleGamesPrice - (totalBundleGamesPrice * (firstWeek.discount / 100)));

            return firstWeek.price ? total : total * bundle.bundle_games[0].number_of_lines;
        },

        getBundleCurrencySymbol(bundle) {
            return CurrenciesService.getCurrencySymbol(bundle.jackpot_currency);
        },

        openCreateModal() {
            this.isCreateModalVisible = true;
        },
    },
}
</script>