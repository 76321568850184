<template>
    <c-sidebar-nav-dropdown v-if="$canAny(permissions)"
                            name="Reports"
                            href="#"
                            fontIcon="fa fa-chart-line"
                            :class="isRouteActive(routes, 'c-show')"
    >
        <c-sidebar-nav-item name="Agents"
                            fontIcon="fa fa-calendar-alt"
                            :href="`${getIsOnlyAgent ? `/reports/agents/agent/${user}/edit` : '/reports/agents'}`"
                            v-if="$can('Reports Agents - View')"
                            :addLinkClasses="isRouteActive([
                                'reports-agents-view',
                                'reports-agents-get-agent',
                            ])"
        />
        <c-sidebar-nav-item name="Agent Deposits"
                            fontIcon="fa fa-list-alt"
                            href="/reports/agents/deposits"
                            v-if="$can('Reports Agent Deposits - View')"
                            :addLinkClasses="isRouteActive('reports-agents-deposit-view')"
        />
        <c-sidebar-nav-item name="Sales"
                            fontIcon="fa fa-pound-sign"
                            href="/reports/sales"
                            v-if="$can('Reports Sales - View')"
                            :addLinkClasses="isRouteActive('reports-sales-view')"
        />
        <c-sidebar-nav-item name="Games"
                            fontIcon="fa fa-play"
                            href="/reports/games"
                            v-if="$can('Reports Games - View')"
                            :addLinkClasses="isRouteActive('reports-games-view')"
        />
        <c-sidebar-nav-item name="Players"
                            fontIcon="fa fa-users"
                            href="/reports/players"
                            v-if="$can('Reports Players - View')"
                            :addLinkClasses="isRouteActive('reports-players-view')"
        />
        <c-sidebar-nav-item name="New Players"
                            fontIcon="fa fa-users"
                            href="/reports/new-players"
                            v-if="$can('Reports New Players - View')"
                            :addLinkClasses="isRouteActive(['reports-new-players-view', 'reports-new-players-monthly-details'])"
        />
    </c-sidebar-nav-dropdown>
</template>

<script>
    export default {
        name: "CreateNew",
        props: {
            user: {
                type: Number
            }
        },
        data: function(){
            return {
                routes: [
                    "reports-agents-view",
                    "reports-sales-view",
                    "reports-games-view",
                    'reports-agents-get-agent',
                    'reports-agent-deposits-view',
                    'reports-players-view',
                    'reports-new-players-view',
                    'reports-agents-deposit-view',
                    'reports-new-players-monthly-details',
                ],
                permissions: [
                    'Reports Agents - View',
                    'Reports Agent Deposits - View',
                    'Reports Sales - View',
                    'Reports Games - View',
                    'Reports Players - View',
                    'Reports New Players - View',
                ],
            };
        }
    }
</script>