<template>
    <div class="card call-history-statistic-card">
        <div :class="{
                 'p-0': !loader
             }"
             class="card-body"
        >
            <div v-if="!loader"
                 class="call-history-statistic-card-holder"
            >
                <div v-if="!loader">
                    <h3>{{ title }}</h3>
                    <p>{{ description }}</p>
                </div>
                <div v-if="!loader">
                    {{ value }}
                </div>
            </div>
            <loader :show="loader"
                    type="smaller"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'call-history-statistic-card',
    props: {
        title: {
            required: true
        },
        description: {
            required: false
        },
        value: {
            required: true
        },
        loader: {
            type: Boolean,
            default: false
        }
    }
}
</script>