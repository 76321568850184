<template>
	<div class="card">
		<div class="card-body">
			<div class="h1 text-muted text-right mb-4">
				<i :class="icon"></i>
			</div>
			<div class="h4 mb-0">
				<span v-if="!isLoading && !$slots.value" v-html="number"/>
				<slot v-if="!isLoading && !!$slots.value"
				      name="value"
				></slot>
				<loader :show="isLoading"
				        type="smaller"
				        class="left"
				/>
			</div>
			<small class="text-muted text-uppercase font-weight-bold">{{ title }}</small>
		</div>
	</div>
</template>

<script>
import Loader from "../../../components/Widgets/Loader.vue";

export default {
	name: "statistics-card",
	components: {
		Loader,
	},
	props: {
		icon: {
			required: true
		},
		number: {
			type: String,
			required: false
		},
		title: {
			required: true
		},
		value: {
			type: Number,
			required: true
		},
		isLoading: {
			type: Boolean,
			required: true
		}
	}
}
</script>