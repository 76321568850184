import getValidationErrors from '../../../../../utils/Error/GetValidationErrors';
import LeadService from '../../../../../services/Marketing/LeadService';

export default {
    provide() {
        return {
            onImportLeads: this.onImportLeads,
            setImportFile: this.setImportFile,
            toggleImportModal: this.toggleImportModal,
            toggleErrorsModal: this.toggleErrorsModal,
        };
    },
    data() {
        return {
            showImportLeadsModal: false,
            showImportLeadsErrorsModal: false,
            importErrors: {},
            fileToImport: null,
            importLoader: false,
        };
    },
    methods: {
        async onImportLeads() {
            if (!this.$can('Leads - Import')) {
                window.showMessage('You does not have permissions for this action.', false);

                return;
            }

            this.importLoader = true;
            this.importErrors = {};

            try {
                const response = await LeadService.importLeads(this.fileToImport);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.toggleImportModal();

                    await this.fetchLeads();
                }

                this.importLoader = false;
            } catch (error) {
                this.importLoader = false;

                this.importErrors = getValidationErrors(error);
                this.fileToImport = null;

                window.flashSingleValidationError(error);
            }
        },
        setImportFile(file) {
            this.fileToImport = file;
        },
        toggleImportModal() {
            this.showImportLeadsModal = !this.showImportLeadsModal;

            if (!this.showImportLeadsModal) {
                this.importErrors = {};
                this.fileToImport = null;
            }
        },
        toggleErrorsModal() {
            this.showImportLeadsErrorsModal = !this.showImportLeadsErrorsModal;
        },
    },
}