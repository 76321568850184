import TypesConst from "../../TypesConst";
import PaymentService from "../../../services/PaymentService";

export default {
    [TypesConst.payment.actions.GET_HAS_SAVED_CARDS_ACTION]({commit, rootState}){
        commit(TypesConst.payment.mutations.SET_HAS_SAVED_CARD_LOADER, true);

        PaymentService.getHasSavedCards(rootState.players.playerId)
            .then(response => {
                const { status, message, data } = response.data;

                if(status){
                    commit(TypesConst.payment.mutations.SET_HAS_SAVED_CARD, data.hasCard);
                }

                flash(message, status ? "alert-success" : "alert-danger");
            })
            .catch(error => {
                let message = error.response.data.message ? error.response.data.message : error.message;
                flash(message, "alert-danger");
            })
            .finally(() => {
                commit(TypesConst.payment.mutations.SET_HAS_SAVED_CARD_LOADER, false);
            });
    },
};