var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "atbl-dropdown",
        {
          attrs: {
            small: false,
            "input-style": "outline-primary",
            placeholder: "Extra actions",
            position: "right"
          }
        },
        [
          _c(
            "atbl-dropdown-item",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toggleCopyMagicLink.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n\t\t\tCopy Magic Link\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "atbl-dropdown-item",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.unlockPlayerLogin.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n\t\t\tUnlock Player Login\n\t\t")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("copy-magic-link-component", {
        attrs: { show: _vm.copyMagicLinkOpened, player: _vm.player }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }