<template>
    <div class="card card-search">
        <div class="card-header">
            <i class='fa fa-search'></i> Get agents deposits and sales per year
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <atbl-select id="basicSelect"
                              label="Filter"
                              class="font-weight-bold"
                              name="year"
                              :value="model.year"
                              :plain="true"
                              :options="yearList"
                              @input="model.year = Number($event)"
                    />
                </div>
            </div>
        </div>

        <div class="card-footer">
            <footer class="d-flex align-items-center justify-content-between">
                <div class="search-controls">
                    <button type="submit"
                            class="btn btn-sm btn-primary mr-1"
                            @click="doSearch"
                    >
                        <i class="fa fa-search"></i> Search
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import AtblSelect from '../../../../components/Forms/Inputs/ATBLSelect.vue';

export default {
    name: "agent-deposit-filter-form",
    components: {
		AtblSelect,
    },
    data() {
        const startYear = 2019;
        const yearRange = ((new Date()).getFullYear() - startYear) + 1;
        const yearSelectList = Array(yearRange).fill(startYear).map((y, i) => {
            return {
                value: y + i,
                label: y + i,
            }
        });

        return {
            yearList: yearSelectList.reverse(),
            model: {
                year: yearSelectList[0].value,
            },
        };
    },

    methods: {
        doSearch: function () {
            this.$emit("modelChange", this.model);
        },
    },
}
</script>