var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blocked" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("h2", { staticClass: "message" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.message) + "\n\t")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "blocked-sign" }, [
      _c("i", { staticClass: "fa fa-hand-paper" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }