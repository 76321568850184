var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: { label: _vm.label, "label-for": "Status" }
    },
    [
      _c("tree-select", {
        attrs: {
          multiple: true,
          value: _vm.value,
          options: _vm.statuses,
          disabled: _vm.disabled || _vm.isLoading,
          placeholder: "" + (!!_vm.isLoading ? "Loading..." : "Select options")
        },
        model: {
          value: _vm.selectedStatus,
          callback: function($$v) {
            _vm.selectedStatus = $$v
          },
          expression: "selectedStatus"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }