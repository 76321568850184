/**
 * Convert vue $slot nodes to plain javascript object
 * Example:
 * Usage: const slot = Slot.make(this.$slots.default)
 * Return html string -> slot.toHtml();
 * Return HtmlDivElement -> slot.convert();
 *
 * @return {HTMLDivElement}
 */
function convert(nodes) {
	let parentElement= document.createElement('div');// document.createElement('div');

	for(const VNode of removeEmptyNodes(nodes)) {
		const element = createNodeElement(VNode);

		parentElement.appendChild(element);
	}

	return parentElement;
}

/**
 * Create javascript element from VNode
 *
 * @param node
 * @return {*}
 */
function createNodeElement(node) {
	const data = node.data;

	const staticClass = !!data && data.staticClass
		? data.staticClass.split(' ')
		: [];
	const classes = !!data && data.class
		? data.class
		: [];
	const allClasses = [...staticClass, ...classes];

	const nodeAttrs = !!data && data.attrs
		? data.attrs
		: {};

	if (allClasses.length) {
		nodeAttrs.class = allClasses.join(' ')
	}

	const element = document.createElement(node.tag);

	for (const attr in nodeAttrs) {
		element.setAttribute(attr, nodeAttrs[attr])
	}

	if (!node.children || !node.children.length) {
		return element;
	}

	if (!!nodeHasOnlyContent(node)) {
		element.innerHTML = node.children[0].text;

		return element;
	}

	for(const child of node.children) {
		if(!child.tag) {
			element.innerHTML = child.text;
		} else {
			element.appendChild(this.createNodeElement(child));
		}
	}

	return element;
}

/**
 * Check if node has only text inside
 *
 * @param node
 * @return {boolean}
 */
function nodeHasOnlyContent(node) {
	return node.children &&
		node.children.length === 1 &&
		! node.children[0].tag;
}

/**
 * Filter and get only nodes with tag or text
 *
 * @param nodes
 * @return {*}
 */
function removeEmptyNodes(nodes) {
	return nodes.filter(item => !!item.tag || !!item.text.trim());
}

/**
 * Class Slot
 */
export default {
	_nodes: [],
	_element: null,
	make(nodes) {
		this._nodes = nodes;
		this._element = convert(nodes);

		return this;
	},
	toHtml() {
		return this._element.innerHTML;
	},
	elements() {
		return this._element.children;
	},
};