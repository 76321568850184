export default {
    Internal: 'internal',
    Fixed: 'fixed',
    External: 'external',

    all() {
        return [
            this.Internal,
            this.Fixed,
            this.External,
        ]
    }
}