<template>
    <atbl-modal title="Preview Attachment" :show="true" size="lg" centered @close="emitCloseEvent">
        <a :href="previewUrl" target="_blank">
            <img v-if="isImage" :src="previewUrl" class="img-fluid w-100" alt="image" />
        </a>

        <p v-if="!isImage">File can not be previewed, click on the link bellow to open in new tab</p>
        <a v-if="!isImage" :href="previewUrl" target="_blank">{{ previewUrl }}</a>

        <template #footer>
            <button class="btn btn-sm btn-primary float-right" @click="emitCloseEvent()">
                Close
            </button>

            <button :href="downloadUrl" target="_blank" class="btn btn-sm btn-primary float-right mr-2">
                Download
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'preview-kyc-attachment-modal',
	components: {
		AtblModal,
	},
    props: ['playerKycAttachment'],

    data() {
        return {
            isImage: true,
        };
    },

    computed: {
        previewUrl() {
            if (!this.playerKycAttachment) {
                return '';
            }

            return this.makeBaseUrl() + '/preview';
        },

        downloadUrl() {
            if (!this.playerKycAttachment) {
                return '';
            }

            return this.makeBaseUrl() + '/download';
        }
    },

    mounted() {
        this.testImage(this.playerKycAttachment, (isImage) => {
            this.isImage = isImage;
        });
    },

    methods: {
        emitCloseEvent() {
            this.$emit('cancel');
        },

        makeBaseUrl() {
            return `/players/${this.playerKycAttachment.player_id}/kyc/attachments/${this.playerKycAttachment.id}`;
        },

        testImage(attachment, callback) {
            const img = new Image();
            img.onload = () => callback(true);
            img.onerror = () => callback(false);
            img.src = this.makeBaseUrl(attachment) + '/preview';
        },
    }
}
</script>