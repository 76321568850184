<template>
    <div class="card card-list campaign-added-leads-section">
        <div class="card-header d-flex align-items-center justify-content-between">
            <span>
                <i class="nav-icon fa fa-users" aria-hidden="false"></i> {{ ucFirst(campaignTitle) }}
            </span>
            <div>
                <button v-tooltip="`Reset and reload all campaign data.`"
                          class="btn btn-sm btn-warning m-0"
                          @click="reFetchData(campaignId, true)"
                >
                    <i class="fa fa-sync" aria-hidden="false"></i>
                </button>
                <button v-if="$can('Campaigns - Add/Remove Leads')"
                          class="btn btn-sm btn-primary m-0"
                          @click="toggleAddLeadsSidebar"
                >
                    Add / Remove
                </button>
            </div>
        </div>

        <div class="card-body">
            <campaign-customers-filter-list-component v-if="!!leadsList.length"
                                                      :campaign-id="campaignId"
                                                      :campaign-type="campaignType"
                                                      :is-loading="filterListModel.filterListLoader"
                                                      :filter-list="filterListModel.filtersList"
                                                      @change="changeModelFilters"
            />

            <hr v-if="!!leadsList.length"
                :class="{
                    'opacity-none': inCampaignLeadsLoader
                }"
            />

            <div class="campaign-added-leads-section-list">
                <template v-if="!inCampaignLeadsLoader">
                    <div v-for="(item, index) in leadsList"
                         :key="index"
                         class="campaign-added-leads-section-list-item"
                    >
                        <div class="campaign-added-leads-section-list-item-header">
                            <span>
                                <a v-tooltip="`Copy to clipboard`"
                                   href="#"
                                   class="mr-1"
                                   @click.prevent="copyToClipboard(item.id)"
                                >
                                    # {{ item.id }}
                                </a>
                                <span class="text-separator">/</span>
                                {{ item.name }} {{ item.surname }}
                            </span>
                            <div>
                                <a v-tooltip="`Copy to clipboard`"
                                   href="#"
                                   @click.prevent="copyToClipboard(item.email)"
                                >
                                    {{ item.email }}
                                </a>
                                <span>
                                <a v-if="$can('Leads - Details')"
                                   :href="getCustomerUrl(item)"
                                   target="_blank"
                                >
                                    <i class="fa fa-eye"></i>
                                </a>
                                <confirmable-remove-button :value="item"
                                                           @confirmed="removeLeadOutOfCampaign($event, campaignId, campaignType)"
                                />
                            </span>
                            </div>
                        </div>
                        <div class="campaign-added-leads-section-list-item-body">
                            <div>
                                <span class="small-mb-badge show mb-0">Last Disposition</span>
                                <div v-if="!!item.status_name">
                                    <span class="badge badge-primary">
                                        {{ item.status_name }}
                                    </span>
                                    <br />
                                    <small>{{ utcToLocal(item.status_date) }}</small>
                                </div>
                                <span v-else class="font-weight-normal">/</span>
                            </div>
                            <div>
                                <span class="small-mb-badge show mb-0">Live Spent</span>
                                <span class="font-weight-normal">
                                    <span v-if="!!item.live_spent">
                                        {{ item.live_spent | formatCurrency }}
                                    </span>
                                    <span v-else>/</span>
                                </span>
                            </div>
                            <div>
                                <span class="small-mb-badge show mb-0">Phone</span>
                                <div v-if="item.phone" class="font-weight-normal">
                                    {{ item.phone }}
                                </div>
                                <div v-if="item.mobile" class="font-weight-normal">
                                    {{ item.mobile }}
                                </div>
                            </div>
                            <div>
                                <span class="small-mb-badge show mb-0">Country</span>
                                <span class="font-weight-normal">
                                    {{ emptyField(item.country) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </template>

                <loader :show="inCampaignLeadsLoader"
                        type="small"
                />

                <not-found v-if="!inCampaignLeadsLoader"
                           :items="leadsList"
                           :msg="`No ${campaignTitle} added to campaign.`"
                />

                <nav v-if="!inCampaignLeadsLoader && inCampaignLeadsResponse.last_page > 1"
                     class="wrap pt-3"
                >
                    <atbl-pagination :small="true"
                                     :pagination="inCampaignLeadsResponse"
                                     class="m-0"
                                     @paginate="fetchCampaignLeads"
                    />
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import CampaignCustomersFilterListComponent
    from '../../_Components/CampaignCustomersFilterList/CampaignCustomersFilterListComponent';
import PaginationModel from '../../../../../modules/pagination/PaginationModel';
import ucFirst from '../../../../../utils/String/UcFirst';
import emptyField from '../../../../../filters/EmptyField';

const operationPermissions = [
    'Leads - Details',
    'Campaigns - Add/Remove Leads'
];

export default {
    name: 'campaigns-leads-list-edit-component',
    operationPermissions,
    inject: [
        'campaignId',
        'campaignType',
        'isLeadCampaign',
        'campaignTitle',
        'toggleAddLeadsSidebar',
        'fetchCampaignLeads',
        'changeModelFilters',
        'removeLeadOutOfCampaign',
        'reFetchData',
    ],
    components: {
        CampaignCustomersFilterListComponent,
    },
    props: {
        filterListModel: {
            type: Object,
            default: () => ({
                filterListLoader: false,
                filtersList: []
            })
        },
        inCampaignLeadsResponse: {
            type: Object,
            default: () => (PaginationModel)
        },
        inCampaignLeadsLoader: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        leadsList() {
            return this.inCampaignLeadsResponse.data;
        },
    },
    methods: {
        ucFirst,
        emptyField,
        getCustomerUrl(customer) {
            if(this.isLeadCampaign()) {
                return `/marketing/leads/${customer.id}/view`;
            }

            return `/players/${customer.id}/view`;
        },
    },
}
</script>