import { isObject } from '../Object/Object';

/**
 * Parse value
 * @param value
 * @return {any}
 */
export function parse (value) {
    return JSON.parse(value);
}

/**
 * Stringify value
 * @param value
 * @return {string}
 */
export function stringify (value) {
    return JSON.stringify(value);
}

/**
 * Clone value
 * @param value
 * @return {*}
 */
export function clone (value) {
    return parse(stringify(value));
}

/**
 * Check if string is json object
 * @return {boolean|*}
 * @param inputValue
 */
export function isValueJson(inputValue) {
    if (!inputValue) {
        return false;
    }

    try {
        return isObject(parse(inputValue));
    } catch (e) {
        return false;
    }
}