var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-inner-component",
      class: {
        active: _vm.value
      }
    },
    [
      _c("loader-line", { attrs: { show: _vm.loader } }),
      _vm._v(" "),
      _c("div", { staticClass: "sidebar-inner-component-header" }, [
        _c("span", [
          _vm._v("\n            " + _vm._s(_vm.title) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            { staticClass: "close-sidebar", on: { click: _vm.closeSidebar } },
            [
              _c("i", {
                staticClass: "fa fa-times",
                attrs: { "aria-hidden": "false" }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar-inner-component-body", class: _vm.bodyClass },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "sidebar-inner-component-footer" },
            [_vm._t("footer")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }