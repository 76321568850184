var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$canAny(_vm.permissions)
    ? _c(
        "c-sidebar-nav-dropdown",
        {
          class: _vm.isRouteActive(_vm.routes, "c-show"),
          attrs: { name: "Games", href: "#", fontIcon: "fa fa-play" }
        },
        [
          _vm.$can("Game Draws - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Games Draws",
                  fontIcon: "fa fa-play",
                  href: "/games/draws",
                  addLinkClasses: _vm.isRouteActive("games.draws.view")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Game Draws - Review")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Draws Reviews",
                  fontIcon: "fa fa-star",
                  href: "/games/draws/review",
                  addLinkClasses: _vm.isRouteActive([
                    "games.draws.review",
                    "games.draws.review.details"
                  ])
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Games - Price View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Games Prices",
                  fontIcon: "fa fa-gamepad",
                  href: "/games/view",
                  addLinkClasses: _vm.isRouteActive("games.view")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Free Games - View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Free Games",
                  fontIcon: "fa fa-gamepad",
                  href: "/games/free",
                  addLinkClasses: _vm.isRouteActive("games.free.view")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Users - Free Games Settings View")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "User Free Games Settings",
                  fontIcon: "fa fa-gamepad",
                  href: "/users/free-games-settings/view",
                  addLinkClasses: _vm.isRouteActive(
                    "users.free-games-settings.view"
                  )
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }