var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card presenter-agents" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header bg-light box-shadow d-flex align-items-center justify-content-between section-header"
      },
      [
        _c("b", [_vm._v("Agents Sales")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "header-button-holder" },
          [_vm._t("default")],
          2
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "presenter-agents-content" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm._l(_vm.agents, function(agent, index) {
                return !_vm.isLoading
                  ? [
                      _c("div", { staticClass: "col-12 col-md-6 col-lg-4" }, [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-header d-flex align-items-center justify-content-between winner",
                              class: _vm.winnerClass(agent, index)
                            },
                            [
                              _c("span", [_vm._v(_vm._s(index + 1))]),
                              _vm._v(" "),
                              _c("b", { staticClass: "name" }, [
                                _vm._v(_vm._s(agent.name))
                              ]),
                              _vm._v(" "),
                              index < 3 && agent.total > 0
                                ? _c("div", { staticClass: "medal" }, [
                                    _c("i", {
                                      staticClass: "fa fa-trophy",
                                      attrs: { "aria-hidden": "false" }
                                    })
                                  ])
                                : _c("span", [_vm._v(" ")])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body p-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-content d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("div", { staticClass: "currency" }, [
                                  _vm._m(0, true),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.total(agent.total)) +
                                      "\n                                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "percents" }, [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.percents(agent.total)) +
                                      "\n                                        "
                                  ),
                                  _vm._m(1, true)
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  : _vm._e()
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("loader", {
            staticClass: "mt-4",
            attrs: { show: _vm.isLoading, type: "normal" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer bg-light box-shadow" }, [
      _vm.agents.length
        ? _c("div", { staticClass: "presenter-agents-content-row last-row" }, [
            _c("div", { staticClass: "total-title" }, [
              _c("span", { staticClass: "small-mb-badge show" }, [
                _vm._v("Agents")
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.agents.length) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", { staticClass: "small-mb-badge show" }, [
                _vm._v("Number of Sales")
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.sales.countAll) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", { staticClass: "small-mb-badge show" }, [
                _vm._v("Total Sales")
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.total(_vm.sales.totalSales)) +
                  "\n            "
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "currency-symbol" }, [
      _c("i", {
        staticClass: "fa fa-pound-sign",
        attrs: { "aria-hidden": "false" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "percent-symbol" }, [
      _c("i", {
        staticClass: "fa fa-percent",
        attrs: { "aria-hidden": "false" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }