<script>
    export default {
        getPlayerCallHistoryRoute(id){
            return `/player/${id}/call-history`;
        },
        getPlayerCallHistory(id, params = {}){
            return window.axios.get(`${this.getPlayerCallHistoryRoute(id)}`, {
                params
            });
        }
    }
</script>