var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-header d-flex align-items-center w-100" },
    [
      _c("img", {
        staticClass: "logo-game",
        attrs: { src: _vm.$imageService.gameImage(_vm.draw.game_image) }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between w-100 align-items-center"
        },
        [
          _c("div", [
            _c("h4", { staticClass: "m-0" }, [
              _c("div", [_vm._v(_vm._s(_vm.draw.game_name))])
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n                #" + _vm._s(_vm.draw.id) + "\n            "
              )
            ])
          ]),
          _vm._v(" "),
          _c("h4", { staticClass: "m-0" }, [
            _c("p", { staticClass: "text-center m-0 draw-date-title" }, [
              _vm._v("Draw Date")
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.draw.date))])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }