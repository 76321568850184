import MarketingService from '../../services/MarketingService';
import CallHistoryService from '../../services/CallHistoryService';
import { clone } from '../../utils/Json/Json';
import PaginationModel from '../../modules/pagination/PaginationModel';
import { omit } from 'lodash';

/**
 * Check if response is successfully finished
 *
 * @param status
 * @return {boolean}
 */
function isResponseOk(status) {
    return status === 'fulfilled';
}

export default {
    provide() {
        return {
            fetchGeneralCallStatistics: this.fetchGeneralCallStatistics,
        };
    },
    data() {
        return {
            callHistoryLoader: false,
            callHistoryGeneralLoader: false,
            callHistoryResponse: {
                dailyStatistics: {
                    dailyTalkTime: null,
                    leads: null,
                    players: null,
                    completedCalls: null,
                    description: null,
                    averageTalkTime: null,
                },
                rangeStatistics: {
                    talkTime: null,
                    leads: null,
                    players: null,
                    completedCalls: null,
                    averageTalkTime: null,
                },
                agentsStatistics: [],
                callHistoryStatistics: clone(PaginationModel),
                dailyTotalStatistics: [],
            },
        };
    },
    computed: {
        hasSelectedAgent() {
            return !!this.callHistoryFilterModel.agents.length;
        },
        agent() {
            return this.callHistoryFilterModel.agents[0];
        }
    },
    methods: {
        async fetchCallHistoryStatistics(searchModel = {}, agentId = null) {
            this.callHistoryLoader = true;

            const agent = !agentId
                ? (this.hasSelectedAgent ? this.agent : null)
                : agentId;

            try {
                const [
                    dailyStatistics,
                    rangeStatistics,
                    agentsStatistics,
                    dailyTotalStatistics
                ] = await Promise.allSettled([
                    CallHistoryService.getDailyStatistics(agentId),
                    CallHistoryService.getRangeStatistics(searchModel, agentId),
                    CallHistoryService.getAgentsStatistics(searchModel),
                    CallHistoryService.getDailyTotalStatistics(agent, searchModel)
                ]);
                
                await this.fetchGeneralCallStatistics(searchModel, agentId);

                this.setDailyStatistics(dailyStatistics);
                this.setRangeStatistics(rangeStatistics);
                this.setAgentsStatistics(agentsStatistics);
                this.setDailyTotalStatistic(dailyTotalStatistics);

                this.callHistoryLoader = false;
            } catch (error) {
                this.callHistoryLoader = false;

                window.flashError(error);
            }
        },
        async fetchGeneralCallStatistics(searchModel = {}, agentId = null, page = 1) {
            this.callHistoryGeneralLoader = true;

            try {
                const response = await MarketingService.getCallsHistory({
                    ...searchModel,
                    page
                }, agentId);

                this.callHistoryResponse.callHistoryStatistics = response.data;

                this.callHistoryGeneralLoader = false;
            } catch (error) {
                this.callHistoryGeneralLoader = false;

                window.flashError(error);
            }
        },
        setDailyStatistics(dailyStatisticsResponse) {
            const { status, value: { data: dailyStatistic } } = dailyStatisticsResponse;

            if (!isResponseOk(status)) {
                return window.showMessage('Daily statistics response is not finished successfully.', false);
            }

            this.callHistoryResponse.dailyStatistics = {
                dailyTalkTime: dailyStatistic.daily_talk_time,
                leads: dailyStatistic.daily_dials_leads,
                players: dailyStatistic.daily_dials_players,
                completedCalls: dailyStatistic.completed_calls,
                description: dailyStatistic.average_talk_time.description,
                averageTalkTime: dailyStatistic.average_talk_time.average_talk_time,
            };
        },
        setRangeStatistics(rangeStatisticsResponse) {
            const { status, value: { data: rangeStatistics } } = rangeStatisticsResponse;

            if (!isResponseOk(status)) {
                return window.showMessage('Range statistics response is not finished successfully.', false);
            }

            this.callHistoryResponse.rangeStatistics = {
                talkTime: rangeStatistics.talk_time,
                leads: rangeStatistics.total_dials_leads,
                players: rangeStatistics.total_dials_players,
                completedCalls: rangeStatistics.completed_calls,
                averageTalkTime: rangeStatistics.average_talk_time,
            };
        },
        setAgentsStatistics(agentsStatisticsResponse) {
            const { status, value: { data: agentsStatistics } } = agentsStatisticsResponse;

            if (!isResponseOk(status)) {
                return window.showMessage('Agents statistics response is not finished successfully.', false);
            }

            this.callHistoryResponse.agentsStatistics = agentsStatistics;
        },
        setDailyTotalStatistic(dailyTotalStatisticsResponse) {
            const { status, value: { data: dailyTotalStatistics } } = dailyTotalStatisticsResponse;

            if (!isResponseOk(status)) {
                return window.showMessage('Daily total statistics response is not finished successfully.', false);
            }

            this.callHistoryResponse.dailyTotalStatistics = dailyTotalStatistics;
        },
    },
}