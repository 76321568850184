<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header card-header d-flex justify-content-between align-items-center">
                <span><i class='fa fa-clock'></i> Dispositions</span>
                <button v-if="$can('Dispositions - Create/Update')"
                        class="btn btn-sm btn-primary float-right py-1 px-2 m-0"
                        size="sm"
                        @click="toggleCreateUpdateForm"
                >
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg">Id</div>
                        <div class="col-12 col-lg">Name</div>
                        <div v-if="$canAny(dispositionsOperationPermissions)"
                             class="col-12 col-lg"
                        >
                            Operations
                        </div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(item, index) in dispositions"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Id</span>
                                {{ item.id }}
                            </div>
                            <div class="col-12 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Name</span>
                                {{ item.name }}
                            </div>
                            <div v-if="$canAny(dispositionsOperationPermissions)"
                                 class="col-12 col-lg"
                            >
                                <span class="small-mb-badge">Operations</span>
                                <a v-if="$can('Dispositions - Create/Update')"
                                   href="#"
                                   @click.prevent="onEditDispositionEvent(item.id)"
                                >
                                    <i class="fa fa-pen"></i>
                                </a>
                            </div>
                        </div>
                    </template>

                    <items-not-found v-if="!isLoading"
                                     :items="dispositions"
                                     msg="No dispositions found."
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>
        </div>

        <create-or-update-disposition-form-component :isModalOpened="isCreateUpdateOpened"
                                                     :model="dispositionModel"
                                                     :errors="dispositionModelErrors"
                                                     :is-loading="isCreateUpdateLoading"
        />
    </div>
</template>

<script>
import CreateOrUpdateDispositionFormComponent
    from './CreateOrUpdateDispositionForm/CreateOrUpdateDispositionFormComponent';
import DispositionService from '../../services/DispositionService';
import CreateUpdateDisposition from './CreateOrUpdateDispositionForm/CreateUpdateDisposition';
import ItemsNotFound from '../../components/Lists/ItemsNotFound';
import Loader from '../../components/Widgets/Loader';

export default {
    name: 'dispositions',
    mixins: [
        CreateUpdateDisposition,
    ],
    components: {
        CreateOrUpdateDispositionFormComponent,
        ItemsNotFound,
        Loader,
    },
    data() {
        return {
            isLoading: false,
            dispositions: [],
            dispositionsOperationPermissions: [
                'Dispositions - Create/Update'
            ],
        };
    },
    async created() {
        await this.getDispositions();
    },
    methods: {
        async getDispositions() {
            if (!this.$can('Dispositions - View Dispositions List')) {
                return window.showMessage('No permissions for this action', false);
            }

            this.isLoading = true;

            try {
                const response = await DispositionService.getDispositionsData();

                this.dispositions = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>