var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "badge text-uppercase", class: "badge-" + _vm.variant },
    [_vm._v("\n    " + _vm._s(_vm.title) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }