var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    { attrs: { id: _vm.id, title: "Dispositions", icon: "fa fa-clock" } },
    [
      _c("PlayerDispositionsList", {
        attrs: { dispositions: _vm.dispositions, isLoading: _vm.isLoading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }