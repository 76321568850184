var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-6" },
        [_c("SettingsCategories")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-6" }, [_c("Settings")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }