var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.isLoading
        ? [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header card-header-atbl d-flex justify-content-between"
                    },
                    [
                      _c("div", [
                        _c("span", [_vm._v("Transaction")]),
                        _vm._v(" "),
                        _c("strong", [_vm._v("#" + _vm._s(_vm.id))])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "badge",
                            class: {
                              "badge-success": !_vm.isFailed,
                              "badge-danger": _vm.isFailed
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.isFailed ? "Failed" : "Success") +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("atbl-fake-input", { attrs: { label: "Type" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.transaction.payment_type +
                                " - " +
                                _vm.transaction.type
                            ) +
                            "\n\t\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "atbl-fake-input",
                        { attrs: { label: "Date created" } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.utcToLocal(_vm.transaction.created_at)
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "atbl-fake-input",
                        { attrs: { label: "Date updated" } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.utcToLocal(_vm.transaction.updated_at)
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("atbl-fake-input", { attrs: { label: "Amount" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.formattedAmount) +
                            "\n\t\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c("atbl-fake-input", { attrs: { label: "Reference" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.transaction.reference_id) +
                            "\n\t\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "atbl-fake-input",
                        { attrs: { label: "Reference Transaction" } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.transaction.reference_transaction_id) +
                              "\n\t\t\t\t\t\t\t"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.transaction.payment_brand
                        ? _c(
                            "atbl-fake-input",
                            { attrs: { label: "Card Brand" } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.transaction.payment_brand) +
                                  "\n\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("atbl-fake-input", { attrs: { label: "Message" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.transaction.response_message) +
                            "\n\t\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _vm.transaction.created_by
                        ? _c(
                            "atbl-fake-input",
                            { attrs: { label: "Created By" } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.transaction.created_by.created) +
                                  "\n\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "atbl-fake-input",
                        { attrs: { label: "Credit card last 4 digits" } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.transaction.last_4_digits
                                  ? "**** **** **** " +
                                      _vm.transaction.last_4_digits
                                  : null
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header card-header-atbl" }, [
                    _c("span", [_vm._v("Player")]),
                    _vm._v(" "),
                    _vm.$can("Player - View Details")
                      ? _c(
                          "a",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              href:
                                "/players/" +
                                _vm.transaction.player.id +
                                "/view",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t#" +
                                _vm._s(_vm.transaction.player.id) +
                                "\n\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      : _c("strong", [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t#" +
                              _vm._s(_vm.transaction.player.id) +
                              "\n\t\t\t\t\t\t\t"
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("atbl-fake-input", { attrs: { label: "Name" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(this.transaction.player.name) +
                            " " +
                            _vm._s(this.transaction.player.surname) +
                            "\n\t\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c("atbl-fake-input", { attrs: { label: "Email" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.transaction.player.email) +
                            "\n\t\t\t\t\t\t\t"
                        )
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.transaction.meta
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-header card-header-atbl" },
                        [_vm._v("\n\t\t\t\t\t\t\tMeta\n\t\t\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        _vm._l(Object.entries(_vm.transaction.meta), function(
                          ref
                        ) {
                          var key = ref[0]
                          var value = ref[1]
                          return _c(
                            "atbl-fake-input",
                            { key: key, attrs: { label: key } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(value) +
                                  "\n\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }