var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: { label: "Type", "label-for": "Type" }
    },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.changeViewType,
              expression: "changeViewType"
            }
          ],
          staticClass: "form-control",
          attrs: { id: "Type", disabled: _vm.isDisabled },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.changeViewType = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.types, function(item, index) {
          return _c(
            "option",
            {
              key: index,
              domProps: { value: item, selected: item === _vm.value }
            },
            [_vm._v("\n            " + _vm._s(item) + "\n        ")]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }