export default {
	Verified: 'verified',
	NotVerified: 'not-verified',
	ForceVerified: 'force-verified',
	InProgress: 'in-process',
	Expired: 'expired',

	/**
	 * @return {(string)[]}
	 */
	statuses() {
		return [
			this.Verified,
			this.NotVerified,
			this.ForceVerified,
			this.InProgress,
			this.Expired,
		];
	},
	/**
	 * @param status
	 * @return {*|string}
	 * @throws Error
	 */
	resolveStatus(status) {
		if (!this.statuses().includes(status)) {
			throw new Error('Kyc status does not exists.');
		}

		switch (status) {
		case this.Verified:
		case this.ForceVerified:
			return this.Verified;
		default:
			return status;
		}
	},
};