<template>
    <div class="row mt-3">
        <div class="col-12">
            <div v-for="(meta, metaIndex) in metas"
                 :key="metaIndex"
                 class="email-templates-meta-group"
            >
                <h5 class="font-weight-bold">{{ ucFirst(metaIndex) }}</h5>
                <div v-for="(item, itemIndex) in meta"
                     :key="itemIndex"
                     class="email-templates-meta-fields"
                >
                    <div class="row d-flex align-items-center">
                        <div class="col-12 col-lg-6 d-flex align-items-center">
                            <label class="m-0">{{ item.title ? item.title : itemIndex }}</label>
                            <i v-if="!!item.description"
                               class="fa fa-info-circle"
                               v-tooltip="{
                            content: item.description,
                            placement: 'right'
                           }"
                            ></i>
                        </div>
                        <div class="col-12 col-lg-6">
                            <input v-if="item.inputType === 'number'"
                                   type="number"
                                   :value="item.value"
                                   class="form-control"
                                   :name="itemIndex"
                                   :disabled="isLoading"
                                   :placeholder="`Enter ${item.title.toLowerCase()}`"
                                   min="1"
                                   @input="updateUserTypeModelMetaField(userType, sourceType, metaIndex, itemIndex, $event.target.value)"
                            />
                            <atbl-switch v-if="item.inputType === 'toggle'"
                                         :value="!!item.value"
                                         :name="itemIndex"
                                         :disabled="isLoading"
                                         :off="false"
                                         :on="true"
                                         @input="updateUserTypeModelMetaField(userType, sourceType, metaIndex, itemIndex, $event)"
                            />
                        </div>
                    </div>
                    <template v-if="!!item.hasOwnProperty('attributes')">
                        <div v-for="(attribute, index) in item.attributes"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-lg-6 d-flex align-items-center">
                                <label class="m-0">{{ attribute.title }}</label>
                            </div>
                            <div class="col-12 col-lg-6">
                                <input v-if="attribute.inputType === 'text'"
                                       type="text"
                                       :value="attribute.value"
                                       class="form-control"
                                       :name="attribute.name"
                                       :placeholder="`Enter ${attribute.title}`"
                                       :disabled="isLoading"
                                       @input="updateUserTypeModelMetaAttributeField(userType, sourceType, metaIndex, itemIndex, attribute.name, $event.target.value)"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MetaFields',
    inject: [
        'updateUserTypeModelMetaField',
        'updateUserTypeModelMetaAttributeField',
    ],
    props: {
        sourceType: {
            type: String,
            required: true,
        },
        userType: {
            type: String,
            required: true,
        },
        metas: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
}
</script>