<template>
    <div class="col-12">
        <div class="card" :class="cardClass">
            <div class="card-body">
                <i class="p-3 font-2xl mr-2 float-left" style="min-width: 56px; text-align: center;" :class="[icon,`bg-${color}`]"></i>
	            <div class="row no-gutters d-flex justify-content-center align-items-center">
		            <div class="col-12 col-xl-6">
			            <div class="h5 mb-0 mt-2" :class="`text-${color}`">
				            {{number}}
			            </div>
			            <div class="text-muted text-uppercase font-xs">{{title}}</div>
		            </div>
		            <div class="col-12 col-xl-6">
			            <div class="h5 mb-0 mt-2" :class="`text-${color}`">
				            {{number2}}
			            </div>
			            <div class="text-muted text-uppercase font-xs">{{title2}}</div>
		            </div>
	            </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "multi-value-statistics-card",
        props: {
            icon: {
                required: true
            },
            number: {
                type: String,
                required: true
            },
            number2: {
                type: String,
                required: true
            },
            title: {
                required: true
            },
            title2: {
                required: true
            },
            color: {
                default: 'primary'
            },
            cardClass: {
                type: String,
                default: ""
            }
        }
    }
</script>
