<template>
    <atbl-file-upload :value="value"
                      :errors="errors"
                      :accept="['csv']"
                      label="Upload Csv"
                      name="csv"
                      @update="onUpdateFileEvent"
    />
</template>

<script>
import AtblFileUpload from '../../../../../components/Forms/Inputs/ATBLFileUpload';

export default {
    name: 'UploadCsv',
    emits: [
        'input',
        'update',
    ],
    components: {
        AtblFileUpload,
    },
    props: {
        value: {
            type: File,
            default: null
        },
        errors: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        onUpdateFileEvent(name, file) {
            this.$emit('input', file);
            this.$emit('update', name, file);
        },
    },
}
</script>