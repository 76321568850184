<template>
    <span class="created-by-badge" :class="getCreatedByColor">
        {{ getCreatedByString }}
    </span>
</template>

<script>
export default {
    name: "created-by-badge",
    props: {
        created: {
            types: [Object, String],
            required: true
        }
    },
    computed: {
        getCreatedByString() {
            return this.isObject(this.created)
                ? this.created.created_by.created
                : this.created;
        },
        getCreatedByColor() {
            return this.isObject(this.created)
                ? this.created.created_by.color
                : (
                    this.created === 'WEB'
                        ? 'f-purple'
                        : 'f-green'
                );
        },
    },
}
</script>

<style>
.created-by-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.f-purple{
    background-color: #c61197;
    color: #fff;
}

.f-green{
    background-color: #31a846;
    color: #fff;
}

.b-red{
    background-color: #ce2033;
    color: #FFF;
}
</style>