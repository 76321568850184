export default {
    provide() {
        return {
            onViewPlayerEvent: this.onViewPlayerEvent,
        };
    },
    data() {
        return {
            customerInstantAccessLoader: false,
        };
    },
    methods: {
        async onViewPlayerEvent(customerId) {
            if (!customerId) {
                return window.showMessage('Player id is undefined.', false);
            }

            this.customerInstantAccessLoader = true;

            try {
                const response = await window.axios.post(`/player/${customerId}/agent-instant-access`);
                const { status, message } = response.data;

                window.showMessage(message, status);

                this.customerInstantAccessLoader = false;

                if (!status) {
                    return;
                }

                window.open(`/players/${customerId}/view`, '_blank');
            } catch (error) {
                this.customerInstantAccessLoader = true;

                window.flashError(error);
            }
        }
    },
}