var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "atbl-badge-padding mb-1",
      class:
        "badge " + (_vm.wallet.amount > 0 ? "badge-success" : "badge-danger")
    },
    [
      _c("loader", {
        staticClass: "white",
        attrs: { show: _vm.walletLoader, type: "smaller" }
      }),
      _vm._v(" "),
      !_vm.walletLoader
        ? _c("span", { staticClass: "h6" }, [
            _c("i", { staticClass: "fa fa-wallet mr-1" }),
            _vm._v(" "),
            _c("strong", [
              _vm._v(_vm._s(_vm._f("formatCurrency")(_vm.wallet.amount)))
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }