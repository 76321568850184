<template>
	<div>
		<atbl-dropdown :small="false"
					   input-style="outline-primary"
					   placeholder="Extra actions"
					   position="right"
		>
			<atbl-dropdown-item @click.prevent="toggleCopyMagicLink">
				Copy Magic Link
			</atbl-dropdown-item>
			<atbl-dropdown-item @click.prevent="unlockPlayerLogin">
				Unlock Player Login
			</atbl-dropdown-item>
		</atbl-dropdown>
		
		<copy-magic-link-component :show="copyMagicLinkOpened"
								   :player="player"
		/>
	</div>
</template>

<script>
	import AtblDropdown from '../../../../../components/AtblDropdown/AtblDropdown.vue';
	import AtblDropdownItem from '../../../../../components/AtblDropdown/AtblDropdownItem.vue';
	import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';
	import CopyMagicLink from './MagicLink/CopyMagicLink';
	import CopyMagicLinkComponent from './MagicLink/CopyMagicLinkComponent.vue';
	
    export default {
        name: "ExtraActions",
		mixins: [
			CopyMagicLink,
		],
		components: {
			CopyMagicLinkComponent,
	        AtblDropdown,
	        AtblDropdownItem,
			AtblModal,
		},
	    props: {
		    player: {
			    type: Object,
			    required: true
		    },
	    },
	    methods: {
		    unlockPlayerLogin() {
			    window.axios.post(`/player/${this.player.id}/unlock-login`).then(() => {
				    flash('Player login unlocked');
			    });
		    }
	    }
    }
</script>