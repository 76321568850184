export default {
	_cartStorageKey: "_csk",
	storage: function (value, playerId = null) {
		if (value) {
			let cart = [],
				getCarts = this.storage(null, null);

			if (getCarts && Array.isArray(getCarts) && getCarts.length > 0) {
			    // get carts without cart for this player id
				let getOtherCarts = getCarts.filter(item => item.player_id !== playerId);

				cart = [
                    ...getOtherCarts,
                    value
                ];
			} else {
				cart = [
					value
				];
			}

			// set cart storage
			localStorage.setItem(this._cartStorageKey, JSON.stringify(cart));

			return this.storage(null, playerId);
		} else {
			let getCartStorage = localStorage.getItem(this._cartStorageKey);

			if (getCartStorage) {
				getCartStorage = JSON.parse(getCartStorage);

				if (!playerId) {
					return getCartStorage;
				} else {
					let cart = getCartStorage.filter(item => item.player_id === playerId)[0];

					if (cart) {
						return cart;
					}
					return null;
				}
			}
			return null;
		}
	},
	clear: function (playerId = null) {
		if(!playerId) {
			// remove cart key
			localStorage.removeItem(this._cartStorageKey);
		} else {
			let getShoppingCarts = this.storage(null, null),
				carts = getShoppingCarts ? getShoppingCarts.filter(item => item.player_id !== playerId) : null;

			if(carts && Array.isArray(carts) && carts.length > 0) {
				// set cart storage
				localStorage.setItem(this._cartStorageKey, JSON.stringify(carts));
			} else {
				this.clear();
			}
		}
	}
};