<template>
    <div class="card card-search">
        <div class="card-header">
            <i class="fa fa-plus"></i> Add Status
        </div>

        <div class="card-body">
            <div class="row add-dispositions-holder d-flex align-items-end">
                <div class="col add-dispositions-holder-dropdown">
                    <atbl-select :value="model.status_id"
                                 :errors="modelErrors"
                                 :options="statuses"
                                 :disabled="!!isLoading"
                                 label="Status"
                                 name="status_id"
                                 class="m-0"
                                 @update="updateStatusModelField"
                    />
                </div>
                <div class="col-auto add-dispositions-holder-button">
                    <button :disabled="!model.status_id || !!isLoading"
                            type="submit"
                            class="btn btn-primary"
                            @click="checkAndSaveStatus"
                    >
                        <span v-if="!isLoading">
                            Save
                        </span>
                        <loader :show="isLoading"
                                type="smaller"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'add-status-section-component',
    inject: [
        'campaignId',
        'updateStatusModelField',
        'checkAndSaveStatus',
    ],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        statuses: {
            type: Array,
            default: () => ([])
        },
        model: {
            type: Object,
            default: () => ({})
        },
        modelErrors: {
            type: Object,
            default: () => ({})
        },
    },
}
</script>