var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Payment - Manual Wallet Refunds")
    ? _c("div", { staticClass: "card m-0" }, [
        _c("div", { staticClass: "card-body py-3" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("agent-picker", {
                  model: {
                    value: _vm.agent,
                    callback: function($$v) {
                      _vm.agent = $$v
                    },
                    expression: "agent"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("AmountInput", {
                  attrs: { "check-limit": true },
                  model: {
                    value: _vm.amount,
                    callback: function($$v) {
                      _vm.amount = $$v
                    },
                    expression: "amount"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c(
                  "atbl-form-group",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { label: "Note", "label-for": "note" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.note,
                          expression: "note"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "note",
                        type: "text",
                        placeholder: "Leave a note"
                      },
                      domProps: { value: _vm.note },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.note = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Transaction Id",
                    name: "transactionId",
                    placeholder: "Enter transaction ID",
                    max: false
                  },
                  model: {
                    value: _vm.transactionId,
                    callback: function($$v) {
                      _vm.transactionId = $$v
                    },
                    expression: "transactionId"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  class: {
                    "btn-primary": !_vm.getPaymentLoader,
                    "btn-outline-primary": _vm.getPaymentLoader
                  },
                  attrs: { disabled: _vm.isSaveEnabled },
                  on: {
                    click: function($event) {
                      return _vm.setRefundsConfirmModal(true)
                    }
                  }
                },
                [
                  !_vm.getPaymentLoader
                    ? _c("span", [_vm._v("Add")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { type: "smaller", show: _vm.getPaymentLoader }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }