import GamesService from '../../../../../services/GamesService';
import { clone } from '../../../../../utils/Json/Json';

export default {
    data() {
        return {
            isCreateUpdateFormShown: false,
            isCreateUpdateLoading: false,
            isGameLoading: false,
            freeGameModel: {
                type: null,
                game: null,
                tickets: 1,
                expires_at: null,
                meta: {},
                fields: {}
            },
            freeGameModelErrors: {},
            createUpdateFormConfiguration: {
                lottery: {
                    fields: {
                        tickets: {
                            min: 1,
                            max: 10,
                            maxAllowed: false,
                        }
                    },
                    meta: {
                        lines: {
                            value: 1,
                            min: 1,
                            max: 100,
                            active: true,
                            maxAllowed: 100,
                        }
                    },
                    games: [],
                },
                raffle: {
                    fields: {
                        tickets: {
                            min: 1,
                            max: 10,
                            maxAllowed: false,
                        }
                    },
                    meta: {
                        fractions: {
                            value: 1,
                            min: 1,
                            max: 10,
                            maxAllowed: 10,
                            active: true,
                        },
                        lines: {
                            value: 1,
                            min: 1,
                            max: 1,
                            active: false,
                            maxAllowed: 1
                        }
                    },
                    games: [],
                },
                syndicate: {
                    fields: {
                        tickets: {
                            min: 1,
                            max: 10,
                            maxAllowed: false,
                        }
                    },
                    meta: {
                        shares: {
                            value: 1,
                            min: 1,
                            max: 10,
                            active: true,
                        }
                    },
                    games: [],
                },
                bundle: {
                    fields: {
                        tickets: {
                            min: 1,
                            max: 10,
                            maxAllowed: false,
                        }
                    },
                    meta: {},
                    games: [],
                },
            },
        };
    },
    computed: {
        isSaveAllowed() {
            const freeGameType = this.freeGameModel.type;

            if (!freeGameType) {
                return false;
            }

            if (!this.freeGameModel.game) {
                return false;
            }

            const fields = this.freeGameModel.fields;

            for (const fieldKey in fields) {
                const value = this.freeGameModel[fieldKey];
                const { min, max } = fields[fieldKey];

                if (value < min) {
                    return false;
                }

                if (!!max && value > max) {
                    return false;
                }
            }

            const meta = this.freeGameModel.meta;

            for (const metaKey in meta) {
                const { min, max, value, maxAllowed } = this.freeGameModel.meta[metaKey];

                if (value < min) {
                    return false;
                }

                if (!!max && value > max) {
                    return false;
                }

                if ((!max && !!maxAllowed) && value > maxAllowed) {
                    return false;
                }
            }

            return true;
        }
    },
    methods: {
        async getAllGames() {
            this.isGameLoading = true;

            try {
                const games = await GamesService.getAllGames();
                const syndicates = await GamesService.getSyndicateGames();
                const bundles = await GamesService.getBundleGames();

                this.createUpdateFormConfiguration.lottery.games = games.data.data.filter(item => item.is_lottery);
                this.createUpdateFormConfiguration.raffle.games = games.data.data.filter(item => item.is_raffle);
                this.createUpdateFormConfiguration.syndicate.games = syndicates.data;
                this.createUpdateFormConfiguration.bundle.games = bundles.data;

                this.isGameLoading = false;
            } catch (error) {
                window.flashError(error);
                this.isGameLoading = false;
            }
        },
        async createFreeGame() {
            const { type, game } = this.freeGameModel;

            if (!type || !game) {
                return window.showMessage('Type or Game is undefined.', false);
            }

            this.freeGameModelErrors = {};
            this.isCreateUpdateLoading = true;

            const meta = Object.keys(this.freeGameModel.meta)
                .filter(item => item !== 'fields')
                .map(key => {
                    const { value } = this.freeGameModel.meta[key];

                    return [
                        key,
                        value
                    ];
                });

            try {
                const response = await window.axios.post(`/player/${this.id}/free-games`, {
                    ...this.freeGameModel,
                    meta: Object.fromEntries(meta)
                });

                const { status, message } = response.data;

                if (status) {
                    this.clearFreeGameModel();
                    this.toggleCreateUpdateForm();

                    await this.getUserFreeGamesSettings();
                    await this.fetchFreeGames();
                }

                window.showMessage(message, status);

                this.isCreateUpdateLoading = false;
            } catch (error) {
                const { response: { status, data } } = error;

                if (status === 422) {
                    this.freeGameModelErrors = data.errors;
                } else {
                    this.clearFreeGameModel();
                    this.toggleCreateUpdateForm();

                    await this.getUserFreeGamesSettings();
                    await this.fetchFreeGames();
                }

                window.flashBadResponse(error);
                this.isCreateUpdateLoading = false;
            }
        },
        async removeFreeGame(freeGameId) {
            if (!freeGameId) {
                return window.showMessage('Free game id is undefined.', false);
            }

            this.isLoading = true;

            try {
                const response = await window.axios.delete(`/player/${this.id}/free-games`, {
                    params: {
                        freeGameId
                    }
                });

                const { status, message } = response.data;

                if (status) {
                    this.freeGamePreviewData = {};
                    this.isDeletePressed = false;

                    await this.fetchFreeGames();
                    await this.getUserFreeGamesSettings();
                }

                window.showMessage(message, status);

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);
                this.isLoading = false;
            }
        },
        updateGameType(name, value) {
            this.freeGameModel.game = null;
            this.freeGameModel.type = value;
            this.freeGameModel.tickets = 1;

            if (!this.createUpdateFormConfiguration.hasOwnProperty(value)) {
                return window.showMessage('Type does not exists in the configuration.', false);
            }

            this.freeGameModelErrors = {};

            this.isGameLoading = true;

            const configuration = this.createUpdateFormConfiguration[value];

            if (!configuration.games.length) {
                this.isGameLoading = false;
                return;
            }

            this.freeGameModel.meta = clone(configuration.meta);
            this.freeGameModel.fields = configuration.fields;

            this.isGameLoading = false;
        },
        updateGame(name, value) {
            const gameType = this.freeGameModel.type;

            if (!this.createUpdateFormConfiguration.hasOwnProperty(gameType)) {
                return window.showMessage('Type does not exists in the configuration.', false);
            }
            const config = this.createUpdateFormConfiguration[gameType];

            switch(gameType) {
                case 'syndicate':
                    if (!this.userFreeGamesSettings.shares) {
                        const game = config.games.find(item => item.id === Number(value));
                        const allowedShares = !!game
                            ? game.shares
                            : null;

                        this.createUpdateFormConfiguration[gameType].meta.shares.max = allowedShares;
                        this.freeGameModel.meta.shares.max = allowedShares;
                    }
                    break;
            }

            this.updateFreeGameModel(name, value);
        },
        changeMetaInput(name, value) {
            if (!this.freeGameModel.meta.hasOwnProperty(name)) {
                return window.showMessage('Meta key does not exists.', false);
            }

            Object.assign(this.freeGameModel.meta[name], {
                value
            });
        },
        changeFieldsInput(name, value) {
            if (!this.freeGameModel.fields.hasOwnProperty(name)) {
                return window.showMessage('Field key does not exists.', false);
            }

            Object.assign(this.freeGameModel, {
                [name]: value
            });
        },
        updateFreeGameModel(name, value) {
            Object.assign(this.freeGameModel, {
                [name]: value
            });
        },
        clearFreeGameModel() {
            Object.assign(this.freeGameModel, {
                type: null,
                game: null,
                tickets: 1,
                expires_at: null,
                meta: {},
                fields: {}
            });
        },
        toggleCreateUpdateForm() {
            this.isCreateUpdateFormShown = !this.isCreateUpdateFormShown;

            if (!this.isCreateUpdateFormShown) {
                this.clearFreeGameModel();
            }
        },
        syncConfigurationWithSettings(settings) {
            if (!settings || !this.isObject(settings) || this.isObjectEmpty(settings)) {
                throw new Error('Object cannot be empty or undefined.');
            }

            let configurationTemp = clone(this.createUpdateFormConfiguration);
            const { tickets } = settings;

            if (!tickets || !this.isset(tickets, "total_can_be_created")) {
                return configurationTemp;
            }

            for (const configurationKey in configurationTemp) {
                const currentConfigurationValue = configurationTemp[configurationKey];

                currentConfigurationValue.fields.tickets.max = tickets.total_can_be_created === 'unlimited'
                    ? null
                    : tickets.total_can_be_created;

                for (const metaKey in currentConfigurationValue.meta) {
                    const currentMetaValue = currentConfigurationValue.meta[metaKey];

                    currentMetaValue.max = configurationKey === 'raffle' && metaKey === 'lines'
                        ? 1
                        : settings[metaKey];
                }
            }

            return configurationTemp;
        },
    },
}