import { clone } from '../../../../utils/Json/Json';
import LeadStatusModel from './LeadStatusModel';
import LeadStatusService from '../../../../services/Marketing/LeadStatusService';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';

const maxDaysInMinutes = 365 * 24 * 60;

export default {
    provide() {
        return {
            maxDaysInMinutes,
            onSaveLeadStatusEvent: this.onSaveLeadStatusEvent,
            updateModelField: this.updateModelField,
            toggleCreateUpdateForm: this.toggleCreateUpdateForm,
        };
    },
    data() {
        return {
            isCreateUpdateOpened: false,
            isCreateUpdateLoading: false,
            leadStatusModel: clone(LeadStatusModel),
            leadStatusModelErrors: {},
        };
    },
    methods: {
        async onSaveLeadStatusEvent() {
            if (!this.$can('Leads Statuses - Create/Edit')) {
                return window.showMessage('No permission for this action.', false);
            }

            this.isCreateUpdateLoading = true;

            try {
                const { id } = this.leadStatusModel;

                const response = !!id
                    ? await LeadStatusService.updateStatus(id, this.leadStatusModel)
                    : await LeadStatusService.createStatus(this.leadStatusModel);

                const { status, message } = response.data;

                window.showMessage(message, status);

                this.isCreateUpdateLoading = false;

                if (!status) {
                    return;
                }

                this.toggleCreateUpdateForm();

                await this.fetchLeadStatuses();
            } catch (error) {
                this.isCreateUpdateLoading = false;

                this.leadStatusModelErrors = getValidationErrors(error);

                window.flashBadResponse(error);
            }
        },
        async onEditStatusEvent(id) {
            if (!this.$can('Leads Statuses - Create/Edit')) {
                return window.showMessage('No permission for this action.', false);
            }

            if (!id) {
                return window.showMessage('Id cannot be undefined.', false);
            }

            this.isCreateUpdateOpened = true;
            this.isCreateUpdateLoading = true;

            try {
                const response = await LeadStatusService.getLeadsStatusById(id);

                Object.assign(this.leadStatusModel, response.data);

                this.isCreateUpdateLoading = false;
            } catch (error) {
                this.isCreateUpdateOpened = false;
                this.isCreateUpdateLoading = false;

                window.flashError(error);
            }
        },
        updateModelField(name, value) {
            Object.assign(this.leadStatusModel, {
                [name]: value || null
            });
        },
        toggleCreateUpdateForm() {
            this.isCreateUpdateOpened = !this.isCreateUpdateOpened;

            if (!this.isCreateUpdateOpened) {
                this.leadStatusModelErrors = {};
                this.leadStatusModel = clone(LeadStatusModel);
            }
        },
    },
}