<template>
    <div class="dashboard-players">
        <div class="dashboard-players-heading">
            <p class="dashboard-players-title"> Players </p>
        </div>
        <div class="row">
            <div class="col-sm-4 col-xl" v-for="item in data" :key="item.index" v-if="item.condition">
                <div class="callout" :class="[`callout-${item.color}`]">
                    <a :href="item.link" target="_blank" v-if="item.link && !isset(item, 'with_deposits') && !isset(item, 'without_deposits')">
                        <Small class="text-muted"> {{item.title}} </Small>
                    </a>
                    <small v-else class="text-muted">{{item.title}}</small>
                    <br>
	                <strong v-if="!isset(item, 'with_deposits') && !isset(item, 'without_deposits')"
	                        class="h4"
	                >
		                {{item.number}}
	                </strong>
	                <div v-else>
		                <a :href="`${item.link}&deposits=true`"
		                   target="_blank"
		                   class="mr-1 text-dark h4"
		                   v-tooltip="{
	                          content: 'With Deposits'
	                       }"
		                >
			                <i class="fa fa-wallet text-success"></i> {{ item.with_deposits}}
		                </a>
		
		                <a :href="`${item.link}&deposits=false`"
		                   target="_blank"
		                   class="text-dark h4"
		                   v-tooltip="{
	                          content: 'Without Deposits'
	                       }"
		                >
			                <i class="fa fa-wallet text-danger"></i> {{ item.without_deposits}}
		                </a>
	                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sales",
        props: {
            data: {
                type: Array,
                required: true
            }
        }
    }
</script>
