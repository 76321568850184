var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.items.length
    ? _c(
        "div",
        {
          staticClass: "no-items-found",
          class: {
            row: _vm.rowable
          },
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "div",
            {
              staticClass: "no-items-found-message",
              class: [
                "text-" + _vm.textAlign,
                "text-" + _vm.textColor,
                {
                  col: _vm.rowable
                }
              ]
            },
            [_vm._v("\n        " + _vm._s(_vm.msg) + "\n    ")]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }