<template>
    <div class="container-fluid">
        <div v-if="!playerLoader && canViewPlayer" class="row">
            <div class="col-12 col-sm-4 col-lg-3">
                <player-nav :id="id" />
            </div>

            <div class="col-12 col-sm-8 col-lg-9 pl-lg-0">
                <div v-if="isPlayerBlocked"
                     class="card bg-danger text-white mb-3"
                >
                    <div class="card-header p-2">
                        Player is blocked
                    </div>
                    <div class="card-body p-2">
                        <p class="mb-2">
                            <strong>Blocked by:</strong> {{ player.block.blocked_by_name }}
                            - {{ utcToLocal(player.block.created_at) }}
                        </p>
                        <strong>Reason:</strong> {{ player.block.reason }}
                    </div>
                </div>
	            
	            <player-info :id="id" />
                <slot name="first" v-if="$slots.first"></slot>
                <div class="card card-list"
                     :class="cardClass"
                >
                    <div v-if="header"
                         class="card-header d-flex justify-content-between align-items-center"
                    >
                        <template v-if="!!$slots.header">
                            <slot name="header"></slot>
                        </template>
                        <span v-else
                              class="d-flex align-items-center"
                        >
                            <i :class="icon" class="mr-1"></i> {{ title }}
                        </span>
                        <slot name="rightSide"></slot>
                    </div>

                    <div :class="bodyClass" class="card-body">
                        <slot></slot>
                    </div>

                    <div v-if="$slots.footer" class="card-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
                <slot name="last" v-if="$slots.last"></slot>
            </div>
        </div>
	    <blocked-sign v-if="!playerLoader && !canViewPlayer"
	                  message="This player is blocked."
	    />
	    <loader :show="playerLoader"
	            type="small"
	    />
    </div>
</template>

<script>
	import TypesConst from "../../../../store/TypesConst";
	import NamespaceConst from "../../../../store/NamespaceConst";
	import { createNamespacedHelpers } from "vuex";
	
	const {
		mapGetters: mapPlayersGetters,
		mapMutations: mapPlayersMutations,
		mapActions: mapPlayersActions
		
	} = createNamespacedHelpers(NamespaceConst.players);
	
    import playerNav from './PlayerNav';
    import playerInfo from './PlayerInfo';
    import utcToLocal from '../../../../filters/UtcToLocal';

    export default {
        name: "player-layout",
        components: {
            playerNav,
            playerInfo
        },
        props: {
            id: {
                type: Number
            },
            icon: {
                type: String,
                default: "fa fa-align-justify"
            },
            title: {
                type: String
            },
            header: {
                type: Boolean,
                default: true
            },
            cardClass: {
                type: String,
                default: ""
            },
            bodyClass: {
                type: [String, Object, Array],
                default: ""
            }
        },
	    computed: {
		    ...mapPlayersGetters({
			    player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL,
			    playerLoader: TypesConst.players.getters.GET_PLAYER_LOADER
		    }),
		    isPlayerBlocked: function () {
			    return !!this.player.block;
		    },
        	canViewPlayer: function () {
		    	if (this.isPlayerBlocked) {
		    		return this.$can("Player - View Blocked Profile");
			    }
        		return !this.isPlayerBlocked;
	        }
	    },
	    methods: {
            utcToLocal,
		    ...mapPlayersMutations({
			    setPlayerId: TypesConst.players.mutations.SET_PLAYER_ID
		    }),
		    ...mapPlayersActions({
			    fetchPlayer: TypesConst.players.actions.FETCH_PLAYER_ACTION
		    })
	    },
	    created: function () {
		    let vm = this;
		
		    this.setPlayerId(vm.id);
		    this.fetchPlayer();
		    Atbl.$on("fetchPlayer", () => {
			    vm.fetchPlayer(vm.id);
		    });
	    }
    }
</script>