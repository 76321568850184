<template>
    <div class="general-list">
        <div class="row d-none d-lg-flex">
            <div class="col-12 col-lg-2 text-muted text-center text-lg-left">Id</div>
            <div class="col-12 col-lg-2 text-muted text-center">Agent</div>
            <div class="col-12 col-lg-2 text-muted text-center">Player</div>
            <div class="col-12 col-lg-2 text-muted text-center">Amount</div>
            <div class="col-12 col-lg-1 text-muted text-center">Gateway</div>
            <div class="col-12 col-lg-2 text-muted text-center">Payment Type</div>
            <div class="col-12 col-lg-1 text-muted text-center">Status</div>
        </div>

        <div v-for="(transaction, index) in transactions"
               :key="index"
               class="row"
        >
            <div class="col-12 col-lg-2 text-muted text-center text-lg-left">
                <span class="small-mb-badge">Id</span>
                <a :href="`/transaction/${transaction.id}`"
                   v-if="$can('Transactions - View Transaction Details')"
                >
                    #T-{{ transaction.id }}
                </a>
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <span class="small-mb-badge">Agent</span>
                <span v-if="transaction.user">
                    <b>#{{ transaction.user.id }}</b> / {{ transaction.user.name }}
                </span>
                <span v-else>
                    /
                </span>
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <span class="small-mb-badge">Player</span>
                <span v-if="transaction.player">
                    <a :href="`/players/${transaction.player.id}/view`"
                       target="_blank"
                    >
                        {{ transaction.player.name }} {{ transaction.player.surname }}
                    </a>
                </span>
                <span v-else>
                    /
                </span>
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <span class="small-mb-badge">Amount</span>
                {{ transaction.amount | formatCurrency }}
            </div>
            <div class="col-12 col-lg-1 text-center align-self-center">
                <span class="small-mb-badge">Gateway</span>
                <span :color="['badge', `badge-${$options.Gateways.gatewayColor(transaction.gateway)}`]"
                >
                    {{ transaction.gateway ? ucFirst(transaction.gateway) : 'Atbl' }}
                </span>
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <span class="small-mb-badge">Payment Type</span>
                <transaction-badge :transaction="transaction" />
            </div>
            <div class="col-12 col-lg-1 text-center align-self-center">
                <span class="small-mb-badge">Status</span>
                <span :class="`badge ${transaction.transaction_type ? 'badge-success' : 'badge-danger'}`"
                >
                    {{ transaction.transaction_type ? 'Success' : 'Failed' }}
                </span>
            </div>
        </div>

        <not-found msg="No transactions created."
                   :items="transactions"
        />
    </div>
</template>

<script>
    import Gateways from "../../../../constants/Gateways";

    export default {
        name: 'transactions',
        Gateways,
        props: {
            transactions: {
                type: Array,
                required: true
            },
            order: {
                type: Object,
                required: true
            }
        }
    }
</script>