<template>
    <div class="atbl-balance-selector">
        <atbl-form-group :label="label"
                         :error="errorMessage"
                         class="font-weight-bold mb-0"
        >
            <div class="d-flex">
                <atbl-select v-model="selectSelector"
                             :options="options"
                             first-option-label="Selector"
                             class="mr-1 mb-1"
                             :name="selectorName"
                />
                <atbl-number v-model="selectValue"
                             :min="0"
                             :max="false"
                             :name="selectorValue"
                             placeholder="e.g. 100"
                             autocomplete="off"
                             class="mb-1"
                />
            </div>
        </atbl-form-group>
        <a v-if='!!clearable && !!hasValue'
           class="atbl-balance-selector-close"
           href="#"
           @click.prevent="onClearValueEvent"
        >
            Clear
        </a>
    </div>
</template>

<script>
export default {
    name: 'CompareNumberSelect',
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        label: {
            type: String,
            default: 'Number'
        },
        name: {
            type: String,
            default: 'number'
        },
        options: {
            type: Array,
            default: () => ([
                {
                    value: '>',
                    label: 'Greater then'
                },
                {
                    value: '<',
                    label: 'Less than'
                },
                {
                    value: '=',
                    label: 'Equal to'
                }
            ])
        },
        clearable: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            errorMessageHolder: null,
        }
    },
    computed: {
        selectorName() {
            return `${this.name}Select`;
        },
        selectorValue() {
            return `${this.name}Value`;
        },
        selectSelector: {
            get() {
                return this.value[1] || null;
            },
            set(value) {
                let getOption = this.options.find(item => item.value === value);

                const selector = !!getOption && getOption.value === value
                    ? value
                    : null;

                this.afterUpdateValue(this.selectValue, selector);
            }
        },
        selectValue: {
            get() {
                return this.value[0] || null;
            },
            set(value) {
                const inputValue = !!value ? value : null;

                this.afterUpdateValue(inputValue, this.selectSelector);
            },
        },
        errorMessage() {
            return this.selectValue && this.selectSelector && this.errorMessageHolder
                ? this.errorMessageHolder
                : null;
        },
        hasValue() {
            const [ value, selector ] = this.value;

            return !!value || !!selector;
        },
    },
    methods: {
        afterUpdateValue(value, selector) {
            const validation = this.validate(value, selector);
            
            this.errorMessageHolder = validation.showError
                ? validation.message
                : null;
            
            this.$emit('update', 
                this.name,
                value,
                selector,
                validation,
            );
        },
        onClearValueEvent() {
            this.errorMessageHolder = null;

            this.$emit('update',this.name, null, null, null);
        },
        validate(value, selector) {
            if (!value && !selector) {
                return {
                    isValid: true,
                    message: null,
                    showError: false
                };
            }
            
            if (!value || !selector) {
                return {
                    isValid: false,
                    message: null,
                    showError: true
                };
            }
            
            if (selector === '<' && Number(value) <= 0) {
                return {
                    isValid: false,
                    message: 'Selected option cannot be less than or equal to 0.',
                    showError: true
                };
            }
            
            return {
                isValid: true,
                message: null,
                showError: false
            };
        },
    },
}
</script>