var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-3" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      _vm._l(_vm.metas, function(meta, metaIndex) {
        return _c(
          "div",
          { key: metaIndex, staticClass: "email-templates-meta-group" },
          [
            _c("h5", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.ucFirst(metaIndex)))
            ]),
            _vm._v(" "),
            _vm._l(meta, function(item, itemIndex) {
              return _c(
                "div",
                { key: itemIndex, staticClass: "email-templates-meta-fields" },
                [
                  _c("div", { staticClass: "row d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-lg-6 d-flex align-items-center"
                      },
                      [
                        _c("label", { staticClass: "m-0" }, [
                          _vm._v(_vm._s(item.title ? item.title : itemIndex))
                        ]),
                        _vm._v(" "),
                        !!item.description
                          ? _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: item.description,
                                    placement: "right"
                                  },
                                  expression:
                                    "{\n                        content: item.description,\n                        placement: 'right'\n                       }"
                                }
                              ],
                              staticClass: "fa fa-info-circle"
                            })
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-6" },
                      [
                        item.inputType === "number"
                          ? _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                name: itemIndex,
                                disabled: _vm.isLoading,
                                placeholder:
                                  "Enter " + item.title.toLowerCase(),
                                min: "1"
                              },
                              domProps: { value: item.value },
                              on: {
                                input: function($event) {
                                  return _vm.updateUserTypeModelMetaField(
                                    _vm.userType,
                                    _vm.sourceType,
                                    metaIndex,
                                    itemIndex,
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.inputType === "toggle"
                          ? _c("atbl-switch", {
                              attrs: {
                                value: !!item.value,
                                name: itemIndex,
                                disabled: _vm.isLoading,
                                off: false,
                                on: true
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updateUserTypeModelMetaField(
                                    _vm.userType,
                                    _vm.sourceType,
                                    metaIndex,
                                    itemIndex,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  !!item.hasOwnProperty("attributes")
                    ? _vm._l(item.attributes, function(attribute, index) {
                        return _c("div", { key: index, staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-lg-6 d-flex align-items-center"
                            },
                            [
                              _c("label", { staticClass: "m-0" }, [
                                _vm._v(_vm._s(attribute.title))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-lg-6" }, [
                            attribute.inputType === "text"
                              ? _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: attribute.name,
                                    placeholder: "Enter " + attribute.title,
                                    disabled: _vm.isLoading
                                  },
                                  domProps: { value: attribute.value },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateUserTypeModelMetaAttributeField(
                                        _vm.userType,
                                        _vm.sourceType,
                                        metaIndex,
                                        itemIndex,
                                        attribute.name,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ])
                      })
                    : _vm._e()
                ],
                2
              )
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }