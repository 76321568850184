var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h5",
      {
        staticClass:
          "text-center text-lg-left mt-2 mb-4 pb-2 border-bottom text-uppercase text-secondary pb-2"
      },
      [_vm._v("Order Info")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "order-info-wrapper mb-3" }, [
      _c(
        "div",
        {
          staticClass:
            "order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light"
        },
        [_vm._v("Id")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light"
        },
        [_vm._v("Amount")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light"
        },
        [_vm._v("Created At")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "order-info-wrapper-holder text-muted" }, [
        _c("span", { staticClass: "small-mb-badge-extended" }, [_vm._v("Id")]),
        _vm._v("\n            " + _vm._s(_vm.order.id) + "\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-info-wrapper-holder text-muted" }, [
        _c("span", { staticClass: "small-mb-badge-extended" }, [
          _vm._v("Amount")
        ]),
        _vm._v(" "),
        _vm.isOrderFree
          ? _c("span", [_vm._v("\n                /\n            ")])
          : _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("formatCurrency")(_vm.order.amount)) +
                  "\n            "
              )
            ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-info-wrapper-holder text-muted" }, [
        _c("span", { staticClass: "small-mb-badge-extended" }, [
          _vm._v("Created At")
        ]),
        _vm._v(
          "\n            " +
            _vm._s(_vm.utcToLocal(_vm.order.created_at)) +
            "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "order-info-wrapper mb-3" }, [
      _c(
        "div",
        {
          staticClass:
            "order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light"
        },
        [_vm._v("Agent")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light"
        },
        [_vm._v("Player")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light",
          class: {
            half: _vm.canBePaidAgain
          }
        },
        [_vm._v("\n            Status\n        ")]
      ),
      _vm._v(" "),
      _vm.canBePaidAgain
        ? _c(
            "div",
            {
              staticClass:
                "order-info-wrapper-holder half hide-mobile h6 m-0 mb-2 p-2 bg-light"
            },
            [_vm._v("\n            Action\n        ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "order-info-wrapper-holder text-muted" }, [
        _c("span", { staticClass: "small-mb-badge-extended" }, [
          _vm._v("Agent")
        ]),
        _vm._v(" "),
        _vm.order.user
          ? _c("span", [
              _c("b", [_vm._v("#" + _vm._s(_vm.order.user.id))]),
              _vm._v(" / " + _vm._s(_vm.order.user.name) + "\n            ")
            ])
          : _c("span", [_vm._v("\n                /\n            ")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-info-wrapper-holder text-muted" }, [
        _c("span", { staticClass: "small-mb-badge-extended" }, [
          _vm._v("Player")
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "/players/" + _vm.order.player.id + "/view",
              target: "_blank"
            }
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.order.player.name) +
                " " +
                _vm._s(_vm.order.player.surname) +
                "\n            "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "order-info-wrapper-holder text-muted",
          class: {
            half: _vm.canBePaidAgain
          }
        },
        [
          _c("span", { staticClass: "small-mb-badge-extended" }, [
            _vm._v("Status")
          ]),
          _vm._v(" "),
          _vm.order.status
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Status"
                      },
                      expression:
                        "{\n                        content: 'Status'\n                     }"
                    }
                  ],
                  staticClass: "badge font-weight-bold",
                  class: [
                    "badge-" +
                      _vm.$options.OrdersConstants.statusColor(_vm.order.status)
                  ]
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.order.status.split("_").join(" ")) +
                      "\n            "
                  )
                ]
              )
            : _c("span", [_vm._v("/")])
        ]
      ),
      _vm._v(" "),
      _vm.canBePaidAgain
        ? _c(
            "div",
            { staticClass: "order-info-wrapper-holder half text-muted" },
            [
              _c("span", { staticClass: "small-mb-badge-extended" }, [
                _vm._v("Action")
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: {
                    disabled: _vm.isOrderPaymentLocked,
                    title: _vm.isOrderPaymentLocked
                      ? "Payment for this order is locked during transaction processing"
                      : ""
                  },
                  on: { click: _vm.checkoutAction }
                },
                [_vm._v("\n                Buy\n            ")]
              )
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "h5",
      {
        staticClass:
          "text-center text-lg-left mt-4 mt-lg-2 mb-0 pb-2 text-uppercase text-secondary pb-2 border-bottom border-lg-none"
      },
      [_vm._v("Order Items")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "general-list" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.orderItems, function(orderItem, orderItemIndex) {
          return _c("div", { key: orderItemIndex, staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg text-center text-lg-left" },
              [
                _c("span", { staticClass: "small-mb-badge-extended" }, [
                  _vm._v("Item")
                ]),
                _vm._v(" "),
                _c("item", { attrs: { item: orderItem.item } })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-2 text-center align-self-center" },
              [
                _c("span", { staticClass: "small-mb-badge-extended" }, [
                  _vm._v("Price")
                ]),
                _vm._v(" "),
                !_vm.isOrderFree
                  ? _c("div", [
                      !!orderItem.discount
                        ? _c("s", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("formatCurrency")(orderItem.price)
                                ) +
                                "\n                    "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    orderItem.total_price
                                  )
                                ) +
                                "\n                    "
                            )
                          ]),
                      _vm._v(" "),
                      !!orderItem.discount
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    orderItem.total_price
                                  )
                                ) +
                                "\n                    "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _c("span", [
                      _vm._v("\n                    /\n                ")
                    ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-2 text-center align-self-center" },
              [
                _c("span", { staticClass: "small-mb-badge-extended" }, [
                  _vm._v("Discount (%)")
                ]),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      !!orderItem.discount ? orderItem.discount + " %" : ""
                    ) +
                    "\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-3 text-center align-self-center" },
              [
                _c("span", { staticClass: "small-mb-badge-extended" }, [
                  _vm._v("Created At")
                ]),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.utcToLocal(orderItem.created_at)) +
                    "\n            "
                )
              ]
            )
          ])
        }),
        _vm._v(" "),
        _c("not-found", {
          attrs: { msg: "No order items found.", items: _vm.orderItems }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg text-muted" }, [_vm._v("Item")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Price")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Discount (%)")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-3 text-muted text-center" }, [
        _vm._v("Created At")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }