var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.showSearchForm
        ? _c("search-form-component", {
            attrs: { model: _vm.searchFormModel, loading: _vm.searchFormLoader }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex flex-wrap align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center flex-wrap mt-2 mt-sm-0"
              },
              [
                _c("sort-by", {
                  staticClass: "mr-2",
                  attrs: { options: _vm.sortByOptions },
                  model: {
                    value: _vm.changeSortBy,
                    callback: function($$v) {
                      _vm.changeSortBy = $$v
                    },
                    expression: "changeSortBy"
                  }
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-sm mr-2",
                    class: {
                      "btn-primary": !_vm.showSearchForm,
                      "btn-outline-primary": !!_vm.showSearchForm
                    },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.showSearchForm = !_vm.showSearchForm
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-filter m-0" })]
                ),
                _vm._v(" "),
                _vm.$can("Permissions - Create/Update")
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { href: "/permissions/create" }
                      },
                      [_vm._v("\n                    Create\n                ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg-1" }, [_vm._v("Id")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg-2" }, [
                  _vm._v("Roles")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg-2" }, [
                  _vm._v("Users")
                ]),
                _vm._v(" "),
                _vm.$canAny(_vm.permissionsOperationPermissions)
                  ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                      _vm._v(
                        "\n                        Operations\n                    "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._l(_vm.permissions, function(item, index) {
                return !_vm.isLoading
                  ? _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-1 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Id")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.id) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Name")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.name) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-2 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Roles")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.roles_count) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-2 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Users")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.users_count) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$canAny(_vm.permissionsOperationPermissions)
                        ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _vm.$can("Permissions - Create/Update")
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-link btn-delete btn-sm mr-2",
                                        attrs: {
                                          href:
                                            "/permissions/" +
                                            item.id +
                                            "/update"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-pencil-alt"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$can("Permissions - Remove")
                                  ? _c("remove-list-item-button", {
                                      attrs: { value: item.id },
                                      on: { confirmed: _vm.onDeletePermission }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: {
                      items: _vm.permissions,
                      msg: "No permissions found"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ]),
        _vm._v(" "),
        !_vm.isLoading && _vm.permissionsData.last_page > 1
          ? _c("div", { staticClass: "card-footer overflow-x-auto" }, [
              _c(
                "nav",
                { staticClass: "wrap" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.permissionsData },
                    on: { paginate: _vm.fetchPermissions }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-key" }),
      _vm._v(" Permissions")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }