<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6">
                <SettingsCategories/>
            </div>
            <div class="col-12 col-lg-6">
                <Settings/>
            </div>
        </div>
    </div>
</template>

<script>
    import SettingsCategories from "./Categories/SettingsSections";
    import Settings from "./Settings/Settings";

    export default {
        name: "settings",
        components: {
            SettingsCategories,
            Settings
        }
    }
</script>