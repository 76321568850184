<template>
    <div class="card lottery-payment m-0 mb-4">
        <header class="card-header card-header-atbl d-flex align-items-center justify-content-between">
            <div>
                <i class='fa fa-credit-card'></i> How do you want to pay ?
            </div>
            <div>
                <button type="button" class="btn btn-sm"
                            @click="closeCheckout"
                >
                    <i class='fa fa-times'></i>
                </button>
            </div>
        </header>

        <div class="card-body">
            <div class="row p-2" v-if="$canAny(paymentOperations)">
                <div class="col-12">
                    <list-payments :player-id="playerId"
                                   :charge-amount="parseFloat(getCartItemsStatistics.price)"
                                   :params="{order_id: orderId}"
                                   v-on:payment-successful="paySuccess"
                                   v-on:payment-failed="payFailed"
                                   v-on:payment-error="payError" />
                </div>
            </div>

            <div class="row" v-else>
                <div class="col">
                    <h5>No payment access allowed.</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TypesConst from '../../../../../../store/TypesConst'
import NamespaceConst from "../../../../../../store/NamespaceConst";
import {createNamespacedHelpers} from "vuex";
import CheckoutAction from "../../Mixins/CheckoutAction";
import ListPayments from '../../../../../../components/PaymentGateways/ListPayments.vue'

const {
    mapGetters: mapCartGetters,
    mapMutations: mapCartMutations,
} = createNamespacedHelpers(NamespaceConst.cart);

const {
    mapGetters: mapPlayersGetters,
} = createNamespacedHelpers(NamespaceConst.players);

const {
    mapGetters: mapOrderGetters,
    mapMutations: mapOrderMutations
} = createNamespacedHelpers(NamespaceConst.order);

export default {
    name: "lottery-payments",
    components: { 
        ListPayments
    },
    mixins: [
        CheckoutAction
    ],
    data() {
        return {
            balance: 0,
            balanceLoading: false,
            paymentOperations: [
                "Payon - Wallet Payment Agent",
                "Payon - Card Payment Agent"
            ],
            disableButtons: false,
        }
    },
    computed: {
        ...mapCartGetters({
            getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
            getCartLoader: TypesConst.cart.getters.GET_ADD_TO_CART_LOADER,
            getCartTotal: TypesConst.cart.getters.GET_CART_TOTAL
        }),
        ...mapPlayersGetters({
            playerId: TypesConst.players.getters.GET_PLAYER_ID,
        }),
        ...mapOrderGetters({
            orderId: TypesConst.order.getters.GET_ORDER_ID
        }),
    },
    methods: {
        paySuccess(data) {
            window.showMessage(data.message);
            window.location.href = this.buildUrl(`/players/${this.playerId}/orders`);
        },

        payFailed(data) {
            window.showMessage(`Payment error: ${data.error}`, false);
        },

        payError(err) {
            window.showMessage('Payment failed. Please try again.', false);
        },
        ...mapCartMutations({
            setWalletConfirmModal: TypesConst.cart.mutations.SET_WALLET_CONFIRM_MODAL,
            setPaymentLink: TypesConst.cart.mutations.SET_PAYMENT_LINK,
            setAddBalance: TypesConst.cart.mutations.SET_ADD_BALANCE,
            setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
            clearCart: TypesConst.cart.mutations.SET_CLEAR_CART_DATA
        }),
    },
    created: function () {
        const vm = this;

        Atbl.$on("lotteryPaymentCanceled", function() {
            vm.disableButtons = false;
        });
    },
    mounted: function () {
        this.$el.scrollIntoView({behavior: "smooth"});
    }

}
</script>
