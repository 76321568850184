<template>
    <div class="container-fluid">
        <div v-if="!isLoading"
             class="discounts"
        >
            <div class="discounts-list">
                <DiscountsList :games="games"
                               :editing-game="editingGame"
                />
            </div>
            
            <div v-if="hasGameForEditing"
                 class="discounts-editor"
            >
                <DiscountEditorComponent :editing-game="editingGame"
                                         :line-data="linesData"
                                         :draw-data="drawData"
                                         :fraction-data="fractionsData"
                />
            </div>
        </div>
        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import Loader from '../../components/Widgets/Loader.vue';
import DiscountsList from './List/DiscountsList.vue';
import DiscountEditorComponent from './Edit/DiscountEditorComponent.vue';
import DiscountEditor from './Edit/DiscountEditor';
import LinesEditor from './Edit/LinesEditor/LinesEditor';
import DrawsEditor from './Edit/DrawsEditor/DrawsEditor';
import FractionsEditor from './Edit/FractionsEditor/FractionsEditor';

export default {
    name: 'Discounts',
    mixins: [
        DiscountEditor,
        LinesEditor,
        DrawsEditor,
        FractionsEditor,
    ],
    components: {
        DiscountEditorComponent,
        DiscountsList,
        Loader,
    },
    provide() {
        return {
            fetchGamesWithDiscounts: this.fetchGamesWithDiscounts,
        };
    },
    data() {
        return {
            isLoading: false,
            games: [],
        };
    },
    async created() {
        await this.fetchGamesWithDiscounts();
    },
    methods: {
        async fetchGamesWithDiscounts() {
            this.isLoading = true;
            
            try {
                const response = await window.axios.get('/games/list/discounts');
                
                this.games = response.data;
                
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                
                window.flashError(error);
            }
        },
    },
};
</script>