var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    {
      staticClass: "campaigns-list-section",
      attrs: { title: "Campaigns", icon: "nav-icon fa fa-bullhorn" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("i", { staticClass: "nav-icon fa fa-bullhorn mr-2" }),
              _vm._v(" Campaigns\n        "),
              _c(
                "span",
                {
                  staticClass: "badge text-uppercase ml-2",
                  class: {
                    "badge-primary": _vm.type === "lead",
                    "badge-danger": _vm.type === "player"
                  }
                },
                [_vm._v("\n            " + _vm._s(_vm.type) + "s\n        ")]
              )
            ]
          },
          proxy: true
        },
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm mr-2",
                  class: {
                    "btn-primary": !_vm.showFilter,
                    "btn-outline-primary": !!_vm.showFilter
                  },
                  on: {
                    click: function($event) {
                      _vm.showFilter = !_vm.showFilter
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-filter",
                    attrs: { "aria-hidden": "false" }
                  })
                ]
              ),
              _vm._v(" "),
              _vm.$can("Campaigns - Create/Update")
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btn-sm m-0 text-white",
                      attrs: {
                        href: "/marketing/campaigns/" + _vm.type + "/create"
                      }
                    },
                    [_vm._v("\n            Create\n        ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "beforeCard",
          fn: function() {
            return [
              _c("campaign-search", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFilter,
                    expression: "showFilter"
                  }
                ],
                on: { search: _vm.onSearchCampaignsEvent }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col col-lg-1" }, [_vm._v("Id")]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [_vm._v("Title")]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [_vm._v("Type")]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [_vm._v("Status")]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-center" }, [
              _vm._v("Customers")
            ]),
            _vm._v(" "),
            _vm.$canAny(_vm.$options.campaignPermissions)
              ? _c("div", { staticClass: "col" }, [_vm._v("Operations")])
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.campaignListLoader
            ? _vm._l(_vm.campaignsList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "row",
                    class: {
                      "pb-0": !!item.tags
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg-1 align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Id")
                        ]),
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.id) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Title")
                        ]),
                        _vm._v(" "),
                        _vm.$can("Campaigns - Call")
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/marketing/campaigns/" +
                                    _vm.type +
                                    "/" +
                                    item.id +
                                    "/" +
                                    (item.view_type ===
                                    _vm.$options.Campaigns.ViewTypes.callView
                                      ? "call-view"
                                      : "list-view")
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.title) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.title) +
                                  "\n                "
                              )
                            ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Type")
                        ]),
                        _vm._v("\n                    " + _vm._s(item.type)),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class:
                              "badge badge-" +
                              (item.view_type ===
                              _vm.$options.Campaigns.ViewTypes.callView
                                ? "primary"
                                : "success")
                          },
                          [_vm._v(_vm._s(item.view_type))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Status")
                        ]),
                        _vm._v(" "),
                        _c("campaign-status-badge", {
                          attrs: { status: item.status }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0 text-left text-lg-center"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Customers")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: { content: "Can be called" },
                                expression: "{content: 'Can be called'}"
                              }
                            ],
                            staticClass: "badge badge-danger"
                          },
                          [_vm._v(_vm._s(item.customers_that_can_be_called))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: { content: "In campaign" },
                                expression: "{content: 'In campaign'}"
                              }
                            ],
                            staticClass: "badge badge-warning"
                          },
                          [_vm._v(_vm._s(item.customers_count))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.$canAny(_vm.$options.campaignPermissions)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _vm.$can("Campaigns - View")
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      href:
                                        "/marketing/campaigns/" +
                                        _vm.type +
                                        "/" +
                                        item.id +
                                        "/view"
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-eye" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$can("Campaigns - Create/Update")
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      href:
                                        "/marketing/campaigns/" +
                                        _vm.type +
                                        "/" +
                                        item.id
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-pen" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$can("Campaigns - Remove")
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleRemoveCampaignModal(
                                          _vm.type,
                                          item.id
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-trash" })]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.tags
                      ? _c(
                          "div",
                          { staticClass: "col-12 tags-column" },
                          _vm._l(item.tags.split(","), function(tag, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticClass: "badge badge-primary"
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(tag) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            attrs: { show: _vm.campaignListLoader, type: "small" }
          }),
          _vm._v(" "),
          !_vm.campaignListLoader
            ? _c("not-found", {
                attrs: { items: _vm.campaignsList, msg: "No campaigns found." }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.campaignListLoader && _vm.campaignsListResponse.last_page > 1
            ? _c("nav", { staticClass: "wrap py-3" }, [
                _c(
                  "div",
                  [
                    _c("atbl-pagination", {
                      staticClass: "m-0",
                      attrs: { pagination: _vm.campaignsListResponse },
                      on: { paginate: _vm.fetchCampaigns }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.$can("Campaigns - Remove")
        ? _c("remove-campaign-modal", {
            attrs: {
              show: _vm.confirmRemoveCampaignModalOpen,
              "is-loading": _vm.isRemoveCampaignLoading
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }