var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: {
        id: "fsCountry",
        label: _vm.label,
        "label-for": "country",
        horizontal: _vm.isHorizontal,
        error: _vm.getError(_vm.error, "country")
      }
    },
    [
      _c("tree-select", {
        attrs: {
          multiple: _vm.multiple,
          searchable: true,
          "clear-on-select": true,
          options: _vm.countries,
          normalizer: function(node) {
            return { id: node.value, label: node.text }
          },
          disabled: _vm.disabled || _vm.isLoading,
          placeholder: "" + (!!_vm.isLoading ? "Loading..." : "Select options")
        },
        model: {
          value: _vm.selectedCountry,
          callback: function($$v) {
            _vm.selectedCountry = $$v
          },
          expression: "selectedCountry"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }