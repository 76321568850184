import lottery from './Lottery';
import bundle from './Bundle';
import syndicate from './Syndicate';
import raffle from './Raffle';

export default {
    lottery,
    bundle,
    syndicate,
    raffle,
};