var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.isEveryActionCompatibleWithTrigger
        ? _c("div", { staticClass: "row" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.bonusContainsActionsAllowingDataOverriding
        ? _c("div", { staticClass: "row" }, [_vm._m(1)])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6 col-lg-7" }, [
          _c("div", { staticClass: "card card-list" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex justify-content-between align-items-center"
              },
              [
                _c("div", [
                  _c("i", { staticClass: "fa fa-exchange-alt" }),
                  _vm._v(" Bonus details\n                        "),
                  this.id
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: { content: "Copy to clipboard" },
                              expression: "{content: 'Copy to clipboard'}"
                            }
                          ],
                          staticClass: "cursor-pointer",
                          staticStyle: { color: "#3097D1" },
                          on: {
                            click: function($event) {
                              return _vm.copyToClipboard(_vm.uuid)
                            }
                          }
                        },
                        [
                          _vm._v("| "),
                          _c("i", {
                            staticClass: "fa fa-copy",
                            attrs: { "aria-hidden": "false" }
                          }),
                          _vm._v(" " + _vm._s(this.uuid))
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                this.id
                  ? _c(
                      "div",
                      [
                        _c("atbl-switch", {
                          attrs: {
                            value: _vm.isActive,
                            label: "Active state",
                            name: "isActive",
                            on: true,
                            off: false
                          },
                          on: { input: _vm.changeActiveState }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body p-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("atbl-input", {
                      attrs: {
                        label: "Bonus Title",
                        placeholder: "Enter bonus title"
                      },
                      model: {
                        value: _vm.title,
                        callback: function($$v) {
                          _vm.title = $$v
                        },
                        expression: "title"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "atbl-form-group",
                      {
                        staticClass: "font-weight-bold",
                        attrs: { label: "Trigger event", horizontal: false }
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.trigger,
                                expression: "trigger"
                              }
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.trigger = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c("option", { domProps: { value: null } }, [
                              _vm._v("Select trigger event")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.triggerList, function(triggerItem) {
                              return _c(
                                "option",
                                { domProps: { value: triggerItem.key } },
                                [_vm._v(_vm._s(triggerItem.name))]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "atbl-form-group",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          label: "Active until",
                          "label-cols": 3,
                          horizontal: false
                        }
                      },
                      [
                        _c("date-range-picker", {
                          attrs: {
                            singleDatePicker: true,
                            autoApply: true,
                            ranges: false,
                            minDate: _vm.minDate,
                            showDropdowns: true
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "input",
                              fn: function(picker) {
                                return _c("div", {}, [
                                  _vm.activeUntilFormatted
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.activeUntilFormatted) + " "
                                        ),
                                        _c("i", {
                                          staticClass: "fa fa-times",
                                          staticStyle: { "margin-left": "8px" },
                                          attrs: { title: "Clear date" },
                                          on: { click: _vm.clearActiveUntil }
                                        })
                                      ])
                                    : _c("span", [_vm._v("Select date")])
                                ])
                              }
                            }
                          ]),
                          model: {
                            value: _vm.activeUntil,
                            callback: function($$v) {
                              _vm.activeUntil = $$v
                            },
                            expression: "activeUntil"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("atbl-input", {
                        attrs: {
                          label: "Duration (in hours)",
                          placeholder: "Enter bonus duration",
                          disabled: !!_vm.expiresAt.startDate
                        },
                        model: {
                          value: _vm.hoursDuration,
                          callback: function($$v) {
                            _vm.hoursDuration = $$v
                          },
                          expression: "hoursDuration"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c(
                        "atbl-form-group",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            label: "Expires at",
                            "label-cols": 3,
                            horizontal: false
                          }
                        },
                        [
                          _c("date-range-picker", {
                            attrs: {
                              singleDatePicker: true,
                              autoApply: true,
                              ranges: false,
                              minDate: _vm.minDate,
                              showDropdowns: true
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "input",
                                fn: function(picker) {
                                  return _c("div", {}, [
                                    _vm.expiresAtFormatted
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.expiresAtFormatted) + " "
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-times",
                                            staticStyle: {
                                              "margin-left": "8px"
                                            },
                                            attrs: { title: "Clear date" },
                                            on: { click: _vm.clearExpiresAt }
                                          })
                                        ])
                                      : _c("span", [_vm._v("Select date")])
                                  ])
                                }
                              }
                            ]),
                            model: {
                              value: _vm.expiresAt,
                              callback: function($$v) {
                                _vm.expiresAt = $$v
                              },
                              expression: "expiresAt"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("atbl-switch", {
                        staticClass: "mb-3",
                        attrs: {
                          label: "Recurring",
                          tooltip: "Reassign bonus after it is accomplished",
                          on: true,
                          off: false,
                          name: "isRecurring"
                        },
                        model: {
                          value: _vm.isRecurring,
                          callback: function($$v) {
                            _vm.isRecurring = $$v
                          },
                          expression: "isRecurring"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("atbl-switch", {
                        staticClass: "mb-3",
                        attrs: {
                          label: "Is claimable",
                          on: true,
                          off: false,
                          name: "isClaimable"
                        },
                        model: {
                          value: _vm.isClaimable,
                          callback: function($$v) {
                            _vm.isClaimable = $$v
                          },
                          expression: "isClaimable"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("atbl-switch", {
                        staticClass: "mb-3",
                        attrs: {
                          label: "Limit occurrences",
                          on: true,
                          off: false,
                          name: "limitOccurrences"
                        },
                        model: {
                          value: _vm.limitOccurrences,
                          callback: function($$v) {
                            _vm.limitOccurrences = $$v
                          },
                          expression: "limitOccurrences"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.limitOccurrences
                    ? [
                        _c(
                          "div",
                          { staticClass: "col-5" },
                          [
                            _c("atbl-input", {
                              attrs: {
                                label: "Num. of occurrences",
                                placeholder: "Enter bonus duration"
                              },
                              model: {
                                value: _vm.numberOfOccurrences,
                                callback: function($$v) {
                                  _vm.numberOfOccurrences = $$v
                                },
                                expression: "numberOfOccurrences"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-5" },
                          [
                            _c(
                              "atbl-form-group",
                              {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  label: "Occurrence type",
                                  horizontal: false
                                }
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.occurrenceType,
                                        expression: "occurrenceType"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.occurrenceType = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  _vm._l(_vm.occurrencesTypeList, function(
                                    occurrenceTypeItem
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: { value: occurrenceTypeItem }
                                      },
                                      [_vm._v(_vm._s(occurrenceTypeItem))]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-6 d-flex justify-content-end border-top pt-3"
                  },
                  [
                    _c("atbl-textarea", {
                      attrs: {
                        label: "Note",
                        placeholder:
                          "Place a note to remind you of the purpose of this bonus",
                        rows: 3
                      },
                      model: {
                        value: _vm.notes,
                        callback: function($$v) {
                          _vm.notes = $$v
                        },
                        expression: "notes"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 d-flex justify-content-end border-top pt-3"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.save }
                      },
                      [
                        _vm._v(
                          "\n                                Save\n                            "
                        )
                      ]
                    )
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card card-list" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex justify-content-between align-items-center"
              },
              [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    on: {
                      click: function($event) {
                        _vm.editingActionIndex = null
                        _vm.showActionModal = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        Add action\n                    "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "general-list" },
                [
                  _vm._m(4),
                  _vm._v(" "),
                  _vm._l(_vm.actions, function(action, index) {
                    return _c(
                      "div",
                      {
                        key: "bonus_action_" + index + "_index",
                        staticClass: "row",
                        class: { "is-incompatible": !_vm.isCompatible(action) }
                      },
                      [
                        _c("div", { staticClass: "col-1 text-muted" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(index + 1) +
                              "\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(action.name) +
                              "\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col" },
                          _vm._l(
                            action.configuration.filter(function(x) {
                              return !!x.value
                            }),
                            function(actionField) {
                              return _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge__property-tag large badge-primary"
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(actionField.key) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        actionField.value === null
                                          ? "/"
                                          : actionField.value
                                      )
                                    )
                                  ])
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _c("a", {
                            staticClass: "circle circle__minus",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.removeAction(index)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("a", {
                            staticClass: "circle circle__pencil",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.editAction(index)
                              }
                            }
                          })
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.showActionModal
        ? _c("action-create-edit-modal", {
            attrs: {
              "bonus-action":
                _vm.editingActionIndex !== null
                  ? _vm.actions[_vm.editingActionIndex]
                  : null,
              "bonus-trigger": _vm.triggerConfiguration
            },
            on: {
              cancel: function($event) {
                _vm.showActionModal = false
              },
              confirm: _vm.addOrEditAction
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDisableBonusModal
        ? _c("bonus-disable-modal", {
            on: {
              cancel: function($event) {
                _vm.showDisableBonusModal = false
                _vm.isActive = true
                _vm.enableBonus
              },
              confirm: _vm.disableBonus
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "alert alert-warning" }, [
        _vm._v("\n              There are "),
        _c("strong", [_vm._v("actions")]),
        _vm._v(" incompatible with the selected "),
        _c("strong", [_vm._v("trigger")]),
        _vm._v(
          ". Bonus saved with this state should be assigned with overridden settings\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "alert warning" }, [
        _vm._v(
          "\n            Bonus contains actions that are allowed to override data\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-2 d-flex align-items-center justify-content-center" },
      [_c("p", { staticClass: "mb-0" }, [_c("strong", [_vm._v("per")])])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-exchange-alt" }),
      _vm._v(" Actions\n                    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-1 text-muted" }, [_vm._v("#")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Action")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Configuration")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }