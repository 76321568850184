<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span><i class='fa fa-align-justify'></i> Users</span>
                <button class="btn btn-sm btn-primary m-0"
                          @click="showModal"
                          v-if="$can('Users - Create/Update')"
                >
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg">ID</div>
                        <div class="col-12 col-lg">Info</div>
                        <div class="col-12 col-lg">Email</div>
                        <div class="col-12 col-lg">Added</div>
                        <div class="col-12 col-lg">Roles</div>
                        <div class="col-12 col-lg"
                               v-if="$canAny(usersOperationPermissions)"
                        >
                            Operations
                        </div>
                        <div v-if="$can('Users - Create/Update')"
                               class="col-12 col-lg"
                        >
                            Is active
                        </div>
                    </div>

                    <div v-if="!isLoading"
                           v-for="(item, index) in users"
                           :key="index"
                           class="row"
                    >
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">ID</span>
                            {{ item.id }}
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Info</span>
                            <p class="mb-2">{{ item.name }}</p>
                            <span class="badge"
                                  :class="{
                                    'badge-info': !!item.shift_name,
                                    'badge-danger': !item.shift_name
                                  }"
                                  v-tooltip="{ content: 'Shift' }"
                            >
                                {{ item.shift_name || '/' }}
                            </span>
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Email</span>
                            {{ item.email }}
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Added</span>
                            {{ utcToLocal(item.added) }}
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Roles</span>
                            {{ item.roles }}
                        </div>
                        <div class="col-12 col-lg"
                               v-if="$canAny(usersOperationPermissions)"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <button class="btn btn-sm btn-warning"
                                      @click="onEditEvent(item.id)"
                                      v-if="$can('Users - Create/Update')"
                            >
                                Edit
                            </button>
                            <button class="btn btn-sm btn-danger"
                                      @click="onVisibilityChange(item.id, true)"
                                      v-if="$can('Users - Remove')"
                            >
                                Delete
                            </button>
                        </div>
                        <div v-if="$can('Users - Create/Update')"
                               class="col-12 col-lg"
                        >
                            <span v-if="item.id !== authUserId" class="small-mb-badge">Is active</span>
                            <atbl-switch v-if="$can('Users - Create/Update') && item.id !== authUserId"
                                         color="success"
                                         :value="!!item.is_active"
                                         :name="item.name"
                                         :off="false"
                                         :on="true"
                                         @input="onUserActiveChange($event, item)"
                            />
                        </div>
                    </div>

                    <not-found v-if="!isLoading"
                               :items="users"
                               msg="No users found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>
        </div>

        <create-update-user-form v-if="$can('Users - Create/Update')"
                                 :show="isCreateModalVisible"
                                 :current-user-id="currentUserId"
                                 @cancel="onCancelEvent"
                                 @reload="getUsers"
        />

        <delete-user-form v-if="$can('Users - Remove')"
                          :show="isConfirmDeleteOpen"
                          :current-user-id="currentUserId"
                          @on-visibility-change="onVisibilityChange"
                          @reload="getUsers"
        />
    </div>
</template>

<script>
    import UsersService from "../../services/UsersService";
    import MultiPermissionsPicker from '../../components/Forms/Select/MultiPermissionsPicker';
    import DeleteUserForm from './Forms/DeleteUserForm';
    import CreateUpdateUserForm from './Forms/CreateUpdateUserForm';

    export default {
        name: 'users',
        components: {
            MultiPermissionsPicker,
            CreateUpdateUserForm,
            DeleteUserForm
        },
        data () {
            return {
                currentUserId: null,
                isConfirmDeleteOpen: false,
                users: [],
                isCreateModalVisible: false,
                usersOperationPermissions: [
                    'Users - Create/Update',
                    'Users - Remove',
                ],
                isLoading: false,
            }
        },
        computed: {
            authUserId() {
                return window.layer.ud;
            }
        },
        methods: {
            getUsers () {
                this.isLoading = true;
                window.axios.get('/user/')
                    .then(response => {
                        this.users = response.data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            onUserActiveChange(e, item) {
				const user = this.users.find(user => user.id === item.id);
    
				user.is_active = e;
				
                UsersService.updateUserActiveState(item.id, e);
            },
            onEditEvent (userId) {
                this.currentUserId = userId;
                this.showModal();
            },
            showModal () {
                this.isCreateModalVisible = true;
            },
            onCancelEvent () {
                this.isCreateModalVisible = false;
                this.isEdit = false;
                this.currentUserId = null;
            },
            onVisibilityChange (userId, confirm) {
                this.currentUserId = userId;
                this.isConfirmDeleteOpen = confirm;
            }
        },
        created () {
            this.getUsers()
        },
    }
</script>