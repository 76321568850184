var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-disposition" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card m-0 border-0" }, [
      _c(
        "div",
        { staticClass: "card-body p-0" },
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "a",
            {
              key: index,
              staticClass:
                "badge badge-primary button-badge mt-2 mr-2 mb-2 cursor-pointer p-1",
              attrs: {
                href: _vm.baseUrl + "/players?disposition_id=" + item.id,
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(item.name) +
                  "\n                \n                "
              ),
              _c(
                "span",
                {
                  staticClass: "badge badge-light button-badge-number ml-1 p-1"
                },
                [_vm._v("\n        " + _vm._s(item.players_count) + "\n      ")]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-disposition-heading" }, [
      _c("p", { staticClass: "dashboard-disposition-title" }, [
        _vm._v("Disposition breakdown")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }