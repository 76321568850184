export default {
    getAllPermissions(config = {}) {
        return window.axios.get('/permissions/list', config);
    },
    getPermissionsList() {
        return window.axios.get('/list-permissions');
    },
    getPermissionsById(id) {
        return window.axios.get(`/permissions/${id}`);
    },
    getPermissionsByRole(roles) {
        return window.axios.get(`/permissions-by-role`, {
            params: {
                roles
            }
        });
    }
};