var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("AgentDepositFilterForm", { on: { modelChange: _vm.modelChange } }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("i", { staticClass: "fa fa-users" }),
          _vm._v(" Agents (" + _vm._s(_vm.model.year) + ")\n        ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("AgentDepositList", {
              attrs: {
                isSearching: _vm.isSearching,
                year: _vm.model.year,
                agents: _vm.agents
              },
              on: { "open-agent": _vm.openAgent }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }