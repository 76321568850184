import { clone } from '../../../utils/Json/Json';
import DispositionModel from './DispositionModel';
import DispositionService from '../../../services/DispositionService';
import getValidationErrors from '../../../utils/Error/GetValidationErrors';

const maxDaysInMinutes = 365 * 24 * 60;

export default {
    provide() {
        return {
            maxDaysInMinutes,
            saveDisposition: this.saveDisposition,
            updateModelField: this.updateModelField,
            toggleCreateUpdateForm: this.toggleCreateUpdateForm,
        };
    },
    data() {
        return {
            isCreateUpdateOpened: false,
            isCreateUpdateLoading: false,
            dispositionModel: clone(DispositionModel),
            dispositionModelErrors: {},
        };
    },
    methods: {
        async saveDisposition() {
            if (!this.$can('Dispositions - Create/Update')) {
                return window.showMessage('No permissions for this action.', false);
            }
            
            this.isCreateUpdateLoading = true;

            try {
                const { id } = this.dispositionModel;

                const response = !!id
                    ? await DispositionService.updateDisposition(id, this.dispositionModel)
                    : await DispositionService.createDisposition(this.dispositionModel);

                const { status, message } = response.data;

                window.showMessage(message, status);

                this.isCreateUpdateLoading = false;

                if (!status) {
                    return;
                }

                this.toggleCreateUpdateForm();

                await this.getDispositions();
            } catch (error) {
                this.isCreateUpdateLoading = false;

                this.dispositionModelErrors = getValidationErrors(error);

                window.flashBadResponse(error);
            }
        },
        async onEditDispositionEvent(id) {
            if (!this.$can('Dispositions - Create/Update')) {
                return window.showMessage('No permissions for this action.', false);
            }

            if (!id) {
                return window.showMessage('Id cannot be undefined.', false);
            }

            this.isCreateUpdateOpened = true;
            this.isCreateUpdateLoading = true;

            try {
                const response = await DispositionService.getSingleDisposition(id);

                this.dispositionModel = response.data;

                this.isCreateUpdateLoading = false;
            } catch (error) {
                this.isCreateUpdateOpened = false;
                this.isCreateUpdateLoading = false;

                window.flashError(error);
            }
        },
        updateModelField(name, value) {
            Object.assign(this.dispositionModel, {
                [name]: value || null
            });
        },
        toggleCreateUpdateForm() {
            this.isCreateUpdateOpened = !this.isCreateUpdateOpened;

            if (!this.isCreateUpdateOpened) {
                this.dispositionModelErrors = {};
                this.dispositionModel = clone(DispositionModel);
            }
        },
    },
}