var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isConfirmed
      ? _c(
          "div",
          { staticClass: "bulk-assign-agent" },
          [
            _c("h3", { staticClass: "mb-3" }, [
              _vm._v("Assign multiple players to agent.")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v("Players")
                ]),
                _c("br"),
                _vm._v(" "),
                _vm._l(_vm.getPlayersForAssign, function(item, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "badge badge-primary mr-1" },
                    [
                      _vm._v(
                        "\n                #" +
                          _vm._s(item.id) +
                          " / " +
                          _vm._s(item.name) +
                          " " +
                          _vm._s(item.surname) +
                          "\n            "
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("agent-picker", {
              model: {
                value: _vm.selectedAgent,
                callback: function($$v) {
                  _vm.selectedAgent = $$v
                },
                expression: "selectedAgent"
              }
            })
          ],
          1
        )
      : _c("div", { staticClass: "p-3" }, [
          !_vm.isLoading
            ? _c("h5", { staticClass: "text-center mb-0" }, [
                _vm._v(
                  "\n            Are you sure you want to continue?\n        "
                )
              ])
            : _c("h5", { staticClass: "text-center text-secondary mb-0" }, [
                _vm._v("PROCESSING...")
              ])
        ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-footer d-block d-flex justify-content-end" },
      [
        !_vm.isConfirmed
          ? _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closeBulkForm.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: {
                    disabled:
                      !_vm.selectedAgent || !_vm.getPlayersForAssign.length
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isConfirmed = true
                    }
                  }
                },
                [_vm._v("\n                Continue\n            ")]
              )
            ])
          : _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isConfirmed = false
                    }
                  }
                },
                [_vm._v("\n                Cancel\n            ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success",
                  attrs: {
                    disabled:
                      !_vm.selectedAgent || !_vm.getPlayersForAssign.length
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onAssignAgentsEvent.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("\n                Save\n            ")]
              )
            ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }