<template>
	<span class="payment-type-badge" :class="getBadge">
		{{ paymentTypeBeauty }}
	</span>
</template>

<script>
import Transactions from "../../constants/Transactions";

export default {
	name: 'PaymentTypeBadge',
	props: {
		paymentType: {
			type: String,
			required: true
		},
		paymentTypeBeauty: {
			type: String,
			required: true
		},
	},
	computed: {
		getBadge() {
			switch (this.paymentType) {
				case Transactions.CPCP:
				case Transactions.CACP:
					return 'b-red';
				case Transactions.CPFD:
				case Transactions.CAFD:
					return 'b-blue';
				case Transactions.CPWP:
				case Transactions.CAWP:
					return 'wallet';
				case Transactions.CAHP:
				case Transactions.CPHP:
					return 'mixed';
				case Transactions.CSWN:
					return 'f-green';
				case Transactions.CSVD:
				case Transactions.CAVD:
					return 'f-purple';
				case Transactions.CABD:
					return 'bg-dark text-white';
				case Transactions.CMWD:
					return 'f-pink';
				case Transactions.CSVC:
					return 'f-orange';
				case Transactions.CMRF:
					return 'f-brown';
				default:
					return 'b-blue';
			}
		},
	}
}
</script>