export default {
    playerIdFrom: null,
    playerIdTo: null,
    created_through: null,
    kyc_status: null,
    disposition_id: null,
    name: null,
    surname: null,
    ageFrom: null,
    ageTo: null,
    phone: null,
    postcode: null,
    email: null,
    country: [],
    balanceType: null,
    balanceSelector: null,
    balance: null,
    created_at: null,
    agents: [],
    dateTypes: [],
    tags: [],
    blocked: null,
    deposits: null,
    unAssigned: null,
    dateFrom: null,
    dateTo: null,
    affiliates: [],
    liveSpent: null,
    liveSpentSelector: null,
    liveDeposit: null,
    liveDepositSelector: null,
    liveRefund: null,
    liveRefundSelector: null,
    totalWin: null,
    totalWinSelector: null,
    totalWithdrawal: null,
    totalWithdrawalSelector: null,
}