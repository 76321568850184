var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    { attrs: { id: _vm.id, title: "KYC attachments" } },
    [
      _c(
        "div",
        { staticClass: "general-list agents" },
        [
          _c("div", { staticClass: "row border-bottom d-none d-lg-flex" }, [
            _c("div", { staticClass: "col-2 text-muted" }, [
              _vm._v("Attachment")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2 text-muted" }, [
              _vm._v("Added By")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3 text-muted" }, [_vm._v("Type")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3 text-muted" }, [
              _vm._v("Expires at")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2 text-muted" }, [_vm._v("Actions")])
          ]),
          _vm._v(" "),
          _vm._l(_vm.attachments, function(playerKycSubmission, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "row py-3 border-top",
                class: {
                  "bg-danger-lighter": playerKycSubmission.status === "declined"
                }
              },
              [
                _c("div", { staticClass: "col-2" }, [
                  playerKycSubmission.status === "declined"
                    ? _c("strong", [_vm._v("[DECLINED]")])
                    : _vm._e(),
                  _vm._v(" Attachment " + _vm._s(index + 1) + "\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-2" }, [
                  _c("span", { staticClass: "badge badge-primary" }, [
                    _vm._v(
                      _vm._s(playerKycSubmission.attachment.created_by.name)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  !_vm.editingAttachment ||
                  _vm.editingAttachment.id !== playerKycSubmission.id
                    ? _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              playerKycSubmission.kyc_attachment_type
                                ? playerKycSubmission.kyc_attachment_type.type
                                : ""
                            ) +
                            " " +
                            _vm._s(
                              playerKycSubmission.is_back_side
                                ? "(Backside)"
                                : ""
                            ) +
                            "\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editingAttachment &&
                  _vm.editingAttachment.id === playerKycSubmission.id
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editingKycAttachmentType,
                              expression: "editingKycAttachmentType"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.editingKycAttachmentType = $event.target
                                .multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("Select attachment type")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.availableKycAttachmentTypes, function(
                            availableKycAttachmentType
                          ) {
                            return _c(
                              "option",
                              {
                                domProps: {
                                  value: availableKycAttachmentType.id
                                }
                              },
                              [_vm._v(_vm._s(availableKycAttachmentType.type))]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    !_vm.editingAttachment ||
                    _vm.editingAttachment.id !== playerKycSubmission.id
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                playerKycSubmission.expires_at
                                  ? playerKycSubmission.expires_at
                                  : ""
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editingAttachment &&
                    _vm.editingAttachment.id === playerKycSubmission.id
                      ? _c(
                          "atbl-form-group",
                          {
                            staticClass: "mb-0",
                            attrs: { "label-cols": 3, horizontal: false }
                          },
                          [
                            _c("date-range-picker", {
                              attrs: {
                                singleDatePicker: true,
                                autoApply: true,
                                ranges: false,
                                minDate: _vm.minDate,
                                showDropdowns: true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "input",
                                    fn: function(picker) {
                                      return _c("div", {}, [
                                        _vm.editingExpiresAtFormatted
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.editingExpiresAtFormatted
                                                ) + " "
                                              ),
                                              _c("i", {
                                                staticClass: "fa fa-times",
                                                staticStyle: {
                                                  "margin-left": "8px"
                                                },
                                                attrs: { title: "Clear date" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.editingExpiresAt = {
                                                      startDate: null,
                                                      endDate: null
                                                    }
                                                  }
                                                }
                                              })
                                            ])
                                          : _c("span", [_vm._v("Select date")])
                                      ])
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingExpiresAt,
                                callback: function($$v) {
                                  _vm.editingExpiresAt = $$v
                                },
                                expression: "editingExpiresAt"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    !_vm.editingAttachment ||
                    _vm.editingAttachment.id !== playerKycSubmission.id
                      ? [
                          _vm.$can("KYC - Manage KYC")
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr-2",
                                  attrs: { href: "#", title: "Edit" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.editingAttachment = playerKycSubmission
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-pencil-alt" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "mr-2",
                              attrs: { href: "#", title: "Preview" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.previewingAttachment = playerKycSubmission
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-eye" })]
                          ),
                          _vm._v(" "),
                          _vm.$can("KYC - Manage KYC")
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr-4",
                                  attrs: { href: "#", title: "Delete" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.attachmentToBeDeleted = playerKycSubmission
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash-alt" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canBeApproved(playerKycSubmission) &&
                          _vm.$can("KYC - Manage KYC")
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: "#",
                                    title: "Approve attachment"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.approveAttachment(
                                        playerKycSubmission
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-check-circle" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          playerKycSubmission.status === "pending" &&
                          _vm.$can("KYC - Manage KYC")
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: "#",
                                    title: "Decline attachment"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.decliningAttachment = playerKycSubmission
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-minus-circle text-danger"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editingAttachment &&
                    _vm.editingAttachment.id === playerKycSubmission.id
                      ? [
                          _c(
                            "a",
                            {
                              staticClass: "mr-2",
                              attrs: { href: "#", title: "Save" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.updateAttachment.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-save" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "text-danger",
                              attrs: { href: "#", title: "Cancel" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.editingAttachment = null
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-times" })]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          }),
          _vm._v(" "),
          _c("not-found", {
            attrs: { items: _vm.attachments, msg: "No attachments found." }
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.$can("KYC - Manage KYC")
        ? _c("div", { staticClass: "row border-bottom" }, [
            _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary my-2",
                  on: {
                    click: function($event) {
                      _vm.showAddAttachmentModal = true
                    }
                  }
                },
                [_vm._v("Add new kyc attachment")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("KYC - Manage KYC")
        ? _c(
            "div",
            { staticClass: "row border-bottom justify-content-between py-3" },
            [
              _c(
                "div",
                { staticClass: "col d-flex justify-content-end" },
                [
                  _c(
                    "c-dropdown",
                    {
                      attrs: {
                        "toggler-text": "Change KYC status",
                        color: "primary",
                        size: "sm"
                      }
                    },
                    _vm._l(_vm.availablePlayerKycStatuses, function(
                      playerKycStatus
                    ) {
                      return _c(
                        "c-dropdown-item",
                        {
                          key: "kyc_status_" + playerKycStatus.key,
                          on: {
                            click: function($event) {
                              return _vm.changePlayerKycStatus(
                                playerKycStatus.key
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(playerKycStatus.label) +
                              "\n                "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("KYC - Manage KYC")
        ? _c(
            "div",
            { staticClass: "row border-bottom justify-content-between py-3" },
            [
              _c("div", { staticClass: "col-12 mb-2" }, [
                _c("h4", { staticClass: "border-bottom" }, [
                  _vm._v("Communication")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    ref: "messagesWrapper",
                    staticClass: "row",
                    staticStyle: { "max-height": "400px", "overflow-y": "auto" }
                  },
                  _vm._l(_vm.discussionMessages, function(message) {
                    return _c(
                      "div",
                      {
                        key: "message_" + message.id,
                        staticClass: "col-12 mb-2 d-flex",
                        class: { "flex-row-reverse": message.is_owner }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-circle bg-primary text-white d-flex justify-content-center align-items-center",
                            staticStyle: { width: "40px", height: "40px" }
                          },
                          [_vm._v(_vm._s(message.author.initials))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-3 pt-3 pb-4 bg-light d-inline-flex rounded-bottom rounded-right w-75 mx-2 position-relative",
                            class: {
                              "justify-content-end text-right": message.is_owner
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(message.body) +
                                "\n                        "
                            ),
                            _vm.canMessageBeEdited(message)
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "position-absolute font-xs cursor-pointer",
                                    staticStyle: {
                                      opacity: "0.4",
                                      top: "15px",
                                      right: "15px"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editMessage(message)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-pencil-alt" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "position-absolute",
                                staticStyle: {
                                  opacity: "0.4",
                                  bottom: "5px",
                                  right: "15px"
                                }
                              },
                              [_vm._v(_vm._s(message.commented_at))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 border-top pt-2" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message"
                    }
                  ],
                  staticClass: "rounded border-light p-2 w-100 mb-2",
                  attrs: {
                    disabled: _vm.sendingMessage,
                    placeholder: "Your message"
                  },
                  domProps: { value: _vm.message },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.message = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex justify-content-end" }, [
                  _vm.editingMessage
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-danger mr-2",
                          on: {
                            click: function($event) {
                              _vm.editingMessage = null
                              _vm.message = null
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { disabled: _vm.sendingMessage || !_vm.message },
                      on: { click: _vm.sendMessage }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          this.editingMessage ? "Edit message" : "Send message"
                        )
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddAttachmentModal
        ? _c("add-new-kyc-attachment-modal", {
            on: {
              cancel: function($event) {
                _vm.showAddAttachmentModal = null
              },
              confirm: _vm.storeAttachment
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.attachmentToBeDeleted
        ? _c("remove-kyc-attachment-modal", {
            on: {
              cancel: function($event) {
                _vm.attachmentToBeDeleted = null
              },
              confirm: _vm.deleteAttachment
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.decliningAttachment
        ? _c("decline-kyc-attachment-modal", {
            on: {
              cancel: function($event) {
                _vm.decliningAttachment = null
              },
              confirm: _vm.declineAttachment
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.previewingAttachment
        ? _c("preview-kyc-attachment-modal", {
            attrs: { "player-kyc-attachment": _vm.previewingAttachment },
            on: {
              cancel: function($event) {
                _vm.previewingAttachment = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showNotifyKycVerifiedPlayerModal
        ? _c("notify-kyc-verified-player-modal", {
            attrs: {
              label: "Do you want to notify player for his kyc verification ?"
            },
            on: {
              cancel: function($event) {
                _vm.showNotifyKycVerifiedPlayerModal = false
              },
              confirm: function($event) {
                _vm.updatePlayerKycStatus("force-verified", true)
                _vm.showNotifyKycVerifiedPlayerModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }