<template>
    <div class="card card-list games-draws-list">
        <div class="card-header">
            <i class="fa fa-play" aria-hidden="false"></i> Game Draws
        </div>

        <div class="card-body p-0">
            <div class="general-list">
                <div class="row no-gutters">
                    <div class="col-md-2 text-muted text-center" v-if="gameDraws.length > 0">&nbsp;</div>
                    <div class="col text-muted text-center">Draw Date</div>
                    <div class="col col-md-4 text-muted text-center">Game</div>
                    <div class="col text-muted text-center" v-if="$can('Game Draws - Save Configuration')">Actions</div>
                </div>

                <div v-if="!isLoading"
                     v-for="(draw, index) in gameDraws"
                     :key="index"
                     :class="{
                           'bg-primary': draw.id === activeId
                       }"
                     class="row no-gutters"
                >
                    <div class="col-6 col-sm-6 col-md-2 mb-2 mb-lg-0 text-center">
                        <span class="small-mb-badge">&nbsp;</span>
                        <img :src="$imageService.gameImage(draw.game_image)" class="logo-game" />
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0 text-center">
                        <span class="small-mb-badge">Draw Date</span>
                        {{ draw.date }}
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 mb-2 mb-lg-0 text-center">
                        <span class="small-mb-badge">Game</span>
                        {{ draw.game_name }}
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0 text-center">
                        <span class="small-mb-badge">Actions</span>
                        <a v-if="$can('Game Draws - Save Configuration')"
                           href="#"
                           :class="{
                               'text-white': draw.id === activeId
                           }"
                           @click.prevent="onEditDrawEvent(draw)"
                        >
                            Edit
                        </a>
                    </div>
                </div>

                <not-found v-if="!isLoading"
                           :items="gameDraws"
                           msg="Game draws not found."
                />

                <loader :show="isLoading"
                        type="small"
                />
            </div>
        </div>
    </div>
</template>

<script>
import GameDrawsService from '../../../../../services/GamesDrawsService';

export default {
    name: 'CurrentDrawsComponent',
    data() {
        return {
            gameDraws: [],
            isLoading: false,
            activeId: 0,
        };
    },
    async created() {
        await this.getCurrentDraws();

        Atbl.$on('nextDrawSaved', async () => await this.getCurrentDraws());
    },
    methods: {
        async getCurrentDraws() {
            this.isLoading = true;

            try {
                const response = await GameDrawsService.getCurrentGamesDraws();

                this.gameDraws = response.data;

                this.isLoading = false;
            } catch(error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        onEditDrawEvent(draw) {
            this.activeId = draw.id;

            Atbl.$emit('editNextDraw', draw);
        }
    },
}
</script>