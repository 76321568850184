<template>
	<atbl-modal title="Create Withdrawal"
	         :show="showModal"
	         @close="handleCancel"
	         :close-on-backdrop="false"
	>
		<h5 class="text-center m-0">
			Are you sure you want to make withdrawal of <b>{{ getFundsAmount | formatCurrency}}</b> to this player?
		</h5>
		
		<template #footer>
			<div class="d-flex w-100 align-items-center">
				<div class="w-50 font-weight-bold">
					* Required
				</div>
				<div class="w-50 text-right">
					<button class="btn btn-sm btn-danger"
					          @click="handleCancel"
					>
						Cancel
					</button>
					<button class="btn btn-sm"
					        :class="{
                                'btn-primary': !getPaymentLoader,
                                'btn-outline-primary': getPaymentLoader
                            }"
					        :disabled="getPaymentLoader"
					        @click="handlePayment"
					>
                        <span v-if="!getPaymentLoader"
                        >
                            Yes
                        </span>
						<loader type="small"
						        :show="getPaymentLoader"
						/>
					</button>
				</div>
			</div>
		</template>
	</atbl-modal>
</template>

<script>
	import TypesConst from "../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";
	
	const {
		mapGetters: mapPlayersGetters,
		mapMutations: mapPlayersMutations
	} = createNamespacedHelpers(NamespaceConst.players);
	
	const {
		mapGetters: mapPaymentGetters,
		mapMutations: mapPaymentMutations
	} = createNamespacedHelpers(NamespaceConst.payment);
	
	import Payment from "../../../../../modules/payment/Payment";
    import { isObjectEmpty } from '../../../../../utils/Object/Object';
	import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';
	
	export default {
		name: "confirm-withdrawals-modal",
		components: {
			AtblModal,
		},
		computed: {
			...mapPlayersGetters({
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID,
				isModalOpened: TypesConst.players.getters.GET_WITHDRAWAL_CONFIRM_MODAL,
        getFundsMeta: TypesConst.players.getters.GET_FUNDS_META,
				getFundsAmount: TypesConst.players.getters.GET_FUNDS_AMOUNT,
				getFundsAgent: TypesConst.players.getters.GET_FUNDS_AGENT,
			}),
			...mapPaymentGetters({
				getPaymentLoader: TypesConst.payment.getters.GET_PAYMENT_LOADER
			}),
			showModal: {
				get: function () {
					return this.isModalOpened;
				},
				set: function (value) {
					this.setShowModal(value);
				}
			}
		},
		methods: {
			...mapPlayersMutations({
				setShowModal: TypesConst.players.mutations.SET_WITHDRAWAL_CONFIRM_MODAL,
				setAmount: TypesConst.players.mutations.SET_FUNDS_AMOUNT,
				setAgent: TypesConst.players.mutations.SET_FUNDS_AGENT,
				setFundsMeta: TypesConst.players.mutations.SET_FUNDS_META
			}),
			...mapPaymentMutations({
				setPaymentLoader: TypesConst.payment.mutations.SET_PAYMENT_LOADER
			}),
			handleCancel: function () {
				this.showModal = false;
			},
			handlePayment() {
                this.setPaymentLoader(true);
                const fundsMeta = this.getFundsMeta;

                const params = {
                    amount: this.getFundsAmount,
										agent: this.getFundsAgent,
                    ...(!isObjectEmpty(fundsMeta) && { meta: fundsMeta })
                };

                window.axios.post(`/players/${this.getPlayerId}/manual-withdrawal`, params).then((response) => {
                    this.setAmount(0.00);
										this.setAgent(null);
										this.setFundsMeta({key: 'note', value: null});
                    this.fetchPlayerWallet();
                })
                .catch(window.flashError)
                .finally(() => {
                    this.setPaymentLoader(false);
                    this.showModal = false;
                });
            },
		}
	}
</script>