var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getProcessingOverlay
    ? _c("div", { staticClass: "modal fade show d-block" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "modal-backdrop fade show" })
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-column justify-content-center align-items-center",
        staticStyle: { height: "100%" },
        attrs: { tabindex: "-1" }
      },
      [
        _c(
          "span",
          { staticClass: "spiner", staticStyle: { "z-index": "1051" } },
          [_c("img", { attrs: { src: "/img/spiner.svg", alt: "spiner" } })]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }