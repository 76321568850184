<template>
    <div>
        <div v-if="!isLoading" class="card player-play mb-3">
            <syndicate-game-header v-if="gameConfiguration" :config="gameConfiguration" :syndicate="syndicate" />

            <div class="card-body">
                <div class="player-play-picker">
                    <validation-errors :errors="errors" />
                    <div class="player-play-picker-wrapper">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="text-uppercase">
                                            # Lines - <strong>{{ syndicate.lines_count }}</strong>
                                        </p>
                                    </div>

                                    <div class="col-12 col-md-8 col-lg-6">
                                        <div class="syndicate-group">
                                            <div>
                                                <span>Shares</span>
                                                <span v-if="!hasSelectedFreeGame">#Max: <strong>{{ maxShares }}</strong></span>
                                                <span v-else class="text-danger">Free <strong>{{ shares }}</strong> shares!</span>
                                            </div>
                                            <atbl-number :value="shares"
                                                         :min="1"
                                                         :max="maxShares"
                                                         :show-errors="false"
                                                         :disabled="hasSelectedFreeGame"
                                                         :label="null"
                                                         name="shares"
                                                         class="m-0"
                                                         placeholder="Enter number of shares"
                                                         @update="setShares"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-8 col-lg-6" v-if="maxFutureDraws > 1">
                                        <div class="syndicate-group">
                                            <div>
                                                <span>Draws</span>
                                                <span v-if="!hasSelectedFreeGame">#Max: <strong>{{ maxFutureDraws }}</strong></span>
                                                <span v-else class="text-danger">Free <strong>{{ futureDraws }}</strong> draws!</span>
                                            </div>
                                            <atbl-number :value="futureDraws"
                                                         :min="1"
                                                         :max="maxFutureDraws"
                                                         :show-errors="false"
                                                         :disabled="hasSelectedFreeGame"
                                                         :label="null"
                                                         name="draws"
                                                         class="m-0"
                                                         placeholder="Enter number of draw"
                                                         @update="setDraws"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="buy">
                                <div class="buy-total">
                                    <table>
                                        <tr class="border-bottom">
                                            <td align="left">
                                                <span>Shares: </span>
                                            </td>
                                            <td align="right">
                                                <span v-if="!hasSelectedFreeGame"
                                                      class="buy-total-price ml-1"
                                                >
                                                    {{ shares }} x {{ syndicate.share_price | formatCurrency }}
                                                </span>
                                                <span v-else
                                                      class="buy-total-price ml-1"
                                                >
                                                    {{ shares }} <s>x {{ syndicate.share_price | formatCurrency }}</s>
                                                </span>
                                            </td>
                                        </tr>

                                        <tr class="border-bottom">
                                            <td align="left">
                                                <span>Draws: </span>
                                            </td>
                                            <td align="right">
											<span class="buy-total-price ml-1">
												{{ futureDraws }}
											</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td align="left">
                                                <span>Total:</span>
                                            </td>
                                            <td align="right">
                                                <strong v-if="!hasSelectedFreeGame" class="buy-total-price ml-1">{{ totalPrice | formatCurrency }}</strong>
                                                <strong v-else class="buy-total-price ml-1"><s>{{ totalPrice | formatCurrency }}</s> {{ 0 | formatCurrency }}</strong>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="buy-operations">
                                    <button v-if="!hasSelectedFreeGame"
                                              name="buyBtn" type="submit"
                                              class="btn buy-button btn-sm mb-2 btn-success"
                                              @click="addToCart(false)"
                                    >
                                        <span>
                                            <i class="fa fa-shopping-cart" aria-hidden="false"></i> Add to Cart
                                        </span>
                                    </button>
                                    <button v-if="!hasSelectedFreeGame"
                                              class="btn btn-sm btn-danger buy-button text-uppercase"
                                              @click="addToCart(true)"
                                    >
                                        <span v-if="!isPlacingOrder">
                                            <i class="fa fa-credit-card" aria-hidden="false"></i> Add & Place Order
                                        </span>
                                        <loader :show="isPlacingOrder"
                                                type="smaller"
                                        />
                                    </button>
                                    <button v-if="hasSelectedFreeGame"
                                              class="btn btn-sm btn-warning buy-button text-uppercase"
                                              @click="useFreeTicket(ticket())"
                                              :disabled="isUseFreeLoading"
                                    >
                                    <span v-if="!isUseFreeLoading">
                                        <i class="fa fa-ticket-alt" aria-hidden="false"></i> Use Free Ticket
                                    </span>
                                        <loader :show="isUseFreeLoading"
                                                type="smaller"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import TypesConst from '../../../../../../store/TypesConst';
import NamespaceConst from '../../../../../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';

const {
    mapGetters: mapCartGetters,
    mapMutations: mapCartMutations
} = createNamespacedHelpers(NamespaceConst.cart);

const {
    mapGetters: mapPlayerGetters
} = createNamespacedHelpers(NamespaceConst.players);

const {
    mapGetters: mapOrderGetters,
    mapMutations: mapOrderMutations
} = createNamespacedHelpers(NamespaceConst.order);

import SyndicateGameHeader from './Components/SyndicateGameHeader';
import ValidationErrors from '../../Componenets/ValidationErrors/ValidationErrors';
import PlaceOrder from '../../Mixins/PlaceOrder';
import CheckoutAction from '../../Mixins/CheckoutAction';
import UseFreeTicket from '../../Mixins/UseFreeTicket';

export default {
    name: 'syndicate-game',
    inject: [
        'selectFreeGame',
        'getFreeGames',
    ],
    mixins: [
        PlaceOrder,
        CheckoutAction,
        UseFreeTicket
    ],
    components: { SyndicateGameHeader, ValidationErrors },
    props: {
        selectedFreeGame: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            shares: 0,
            futureDraws: 1,
            gameConfiguration: null,
            errors: {},
            isLoading: false
        };
    },
    computed: {
        ...mapPlayerGetters({
            getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
        }),
        ...mapCartGetters({
            isPlacingOrder: TypesConst.cart.getters.GET_PLACE_ORDER,
        }),
        ...mapOrderGetters({
            orderId: TypesConst.order.getters.GET_ORDER_ID
        }),
        rootSyndicate() {
            return this.$store.getters['games/getGameConfigurationData'];
        },
        syndicate() {
            return this.$store.getters['games/getGameConfigurationData'];
        },
        maxShares() {
            return this.syndicate.active_syndicate_item.active_syndicate_item_draw.available_shares;
        },
        maxFutureDraws() {
            return this.syndicate.max_future_draws;
        },
        totalPrice() {
            return this.shares * this.syndicate.share_price * this.futureDraws;
        },
        getCartData() {
            return this.$store.state.cart.cartData || [];
        },
        hasSelectedFreeGame() {
            return !this.isObjectEmpty(this.selectedFreeGame);
        },
    },
    watch: {
        rootSyndicate(newVal, oldVal) {
            this.fetchGameConfiguration();
            this.futureDraws = 1;
            this.shares = 0;
        },
        isLoading () {
            this.errors = {};
        }
    },
    mounted() {
        this.fetchGameConfiguration();
        this.initializeGame();

        this.$el.scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        ...mapCartMutations({
            setIsPlacingOrder: TypesConst.cart.mutations.SET_PLACE_ORDER,
            clearCartData: TypesConst.cart.mutations.SET_CLEAR_CART_DATA,
            setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
            setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
        }),
        ...mapOrderMutations({
            setOrderId: TypesConst.order.mutations.SET_ORDER_ID
        }),
        fetchGameConfiguration() {
            const gameKey = this.syndicate.game.key;

            this.isLoading = true;
            window.axios.get(`/game/configuration/${gameKey}`)
                .then(response => {
                    this.gameConfiguration = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        initializeGame() {
            if(this.hasSelectedFreeGame) {
                this.setShares(null, this.selectedFreeGame.meta.shares);
            }
        },
        ticket() {
            const playerId = this.$store.state.players.playerId;

            return {
                type: 'syndicate',
                image: this.syndicate.game.image,
                name: this.syndicate.game.name,
                playerId,
                shares: this.shares,
                syndicate: this.rootSyndicate.uuid,
                draws: parseInt(this.futureDraws),
            };
        },
        addToCart(directCheckout = false) {
            const playerId = this.$store.state.players.playerId;

            if (this.orderId) {
                this.clearCartData();
                this.setOrderId(null);
            }

            this.errors = {};

            this.shares = parseInt(this.shares);
            const futureDraws = parseInt(this.futureDraws);

            if (this.shares <= 0 || this.shares > this.syndicate.shares) {
                window.flashError({ message: 'Invalid shares number' });
                return;
            }

            if (futureDraws < 1 || futureDraws > this.maxFutureDraws) {
                window.flashError({ message: 'Invalid future draws' });
                return;
            }

            return this.$store.dispatch('cart/addToCartAction', {
                price: this.hasSelectedFreeGame ? 0 : this.totalPrice,
                free_game_id: this.hasSelectedFreeGame ? this.selectedFreeGame.id : null,
                item: this.ticket(),
                playerId: playerId,
            })
                .then(() => {
                    this.selectFreeGame({});
                    this.getFreeGames();

                    this.$store.commit('cart/setShowGame', false);
                    this.$scrollToTop();
                    flash('Successfully added syndicate to cart.');

                    if (directCheckout) {
                        this.placeOrder(this.totalPrice);
                    }
                })
                .catch(error => {
                    this.errors = this.isset(error.response.data, "errors") ? error.response.data.errors: {};
                    window.showMessage("Error while adding a ticket to the cart.", false);
                });
        },
        getCartTotal() {
            const baseAccumulator = {
                lines: 0,
                price: 0,
            };

            const result = this.getCartData.items.reduce((acc, item) => {
                acc.price += item.price;

                const orderItem = Array.isArray(item.item) ? item.item : JSON.parse(item.item);
                acc.lines += orderItem.lines ? orderItem.lines.length : 0;

                return acc;
            }, baseAccumulator);

            result.price = result.price.toFixed(2);

            return result;
        },
        setShares (_, value) {
            const maxSharesLength = this.maxShares.toString().length;

            if (value.toString().length > maxSharesLength) {
                value = this.maxShares;
            }

            this.shares = value;
        },
        setDraws (_, value) {
            const maxSharesLength = this.maxFutureDraws.toString().length;

            if (value.toString().length > maxSharesLength) {
                value = this.maxFutureDraws;
            }

            this.futureDraws = value;
        }
    },
}
</script>

<style lang="scss" scoped>
.player-play-picker-lines-line {
    justify-content : flex-start;
}
</style>