import { camelCase, toLower, startCase } from 'lodash';

export default {
	emits: [
		'input',
		'update',
	],
	props: {
		value: {},
		label: {
			type: String,
			default: null
		},
		labelClass: {
			type: String,
			default: null
		},
		name: {
			type: String,
			default: 'name'
		},
		errors: {
			type: Object,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: null
		},
		autocomplete: {
			type: String,
			default: 'off'
		},
		clearable: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		getId() {
			return camelCase(`${this.label}Input${this.name}`) + `#${this.generateRandomNumbers(9, 5).join("")}`;
		},
		getLabelFor() {
			return camelCase(`${this.label} Input ${this.name}`) + `#${this.generateRandomNumbers(9, 5).join("")}`;
		},
		getPlaceholder() {
			let capitalizeWords = startCase(toLower(this.name.toLowerCase().split("_").join(" ")));
			return this.placeholder ? this.placeholder : `Enter ${capitalizeWords}`;
		}
	},
	methods: {
		onUpdate(event) {
			this.update(event.target.value.trim());
		},
		update(value) {
			const newValue = value === '' ? null : value;

			this.$emit("input", newValue);
			this.$emit("update", this.name, newValue);
		}
	}
};