export default {
    methods: {
        currencyNumber: function(object, key){
            const amount = this.isset(object, key) ? Number(object[key]) : 0;

            return this.$options.filters.formatCurrency(amount);
        },
        percent: function(number, decimals = 2){
            return Number(parseFloat(number).toFixed(decimals));
        },
        convertDecimals: function(number, decimals = 2){
            return Number(parseFloat(number).toFixed(decimals));
        },
        generateRandomNumbers: function(maxRange, numbersLength = 1, zeroStarting = false) {
            let randomNumbers = [
                ...Array(maxRange).keys()
            ]
                .sort(() => Math.random() - .5)
                .slice(0, numbersLength);

            if(!zeroStarting){
                randomNumbers = randomNumbers.map(num => num + 1)
                    .sort((a, b) => a - b);
            }

            return randomNumbers;
        },
        isNumber: function(value){
            return /^\d+$/.test(value);
        }
    }
}