var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6 col-lg-8" }, [
          _c("div", { staticClass: "card card-list" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.bundle
              ? _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "general-list" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Title")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.bundle.title))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Slug")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.bundle.slug))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Reference")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.bundle.uuid))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Description")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.bundle.description))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Logo")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("img", {
                          staticStyle: { width: "60px" },
                          attrs: { src: _vm.logo }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Ribbon text")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.bundle.ribbon_text))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Price")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatCurrency")(_vm.bundlePrice.toFixed(2))
                          ) + " - "
                        ),
                        _vm._m(1)
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Jackpot")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getBundleCurrencySymbol())
                          }
                        }),
                        _vm._v(" " + _vm._s(_vm.bundle.jackpot))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Is CRM enabled")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.bundle.is_crm_enabled ? "Yes" : "No"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Is API enabled")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(_vm.bundle.is_api_enabled ? "Yes" : "No"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-end align-items-center"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-primary mr-2",
                                attrs: { type: "reset" },
                                on: {
                                  click: function($event) {
                                    _vm.showBundleEditModal = true
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-pencil-alt" }),
                                _vm._v(
                                  " Edit bundle\n                                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.bundle.is_active
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger mr-2",
                                    attrs: { type: "reset" },
                                    on: {
                                      click: function($event) {
                                        _vm.showDisableBundleModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-ban" }),
                                    _vm._v(
                                      " Disable bundle\n                                    "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.bundle.is_active
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-primary mr-2",
                                    attrs: { type: "reset" },
                                    on: { click: _vm.enableBundle }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(
                                      " Enable bundle\n                                    "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.bundle.is_active && !_vm.bundle.is_api_enabled
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-primary",
                                    attrs: { type: "reset" },
                                    on: { click: _vm.enableBundleForApi }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(
                                      " Enable bundle for API\n                                    "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.bundle.is_active && _vm.bundle.is_api_enabled
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger",
                                    attrs: { type: "reset" },
                                    on: { click: _vm.disableBundleForApi }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-ban" }),
                                    _vm._v(
                                      " Disable bundle for API\n                                    "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card card-list" }, [
            _vm._m(2),
            _vm._v(" "),
            _vm.bundle
              ? _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "general-list" },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _vm._l(_vm.bundle.bundle_games, function(bundleGame) {
                        return _c(
                          "div",
                          {
                            key: "bundle_game_" + bundleGame.id,
                            staticClass: "row"
                          },
                          [
                            _c("div", { staticClass: "col text-muted" }, [
                              _c("img", {
                                staticStyle: { width: "60px", height: "auto" },
                                attrs: {
                                  src: _vm.$imageService.gameImage(
                                    bundleGame.game.image
                                  )
                                }
                              }),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(bundleGame.game.name) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    bundleGame.game.current_price.line_price
                                  ) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(bundleGame.number_of_lines) +
                                  "\n                            "
                              )
                            ])
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm._m(4),
                        _vm._v(" "),
                        _c("div", { staticClass: "col" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.totalBundleGamePrice.toFixed(2)))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.linesPerGame))])
                        ])
                      ])
                    ],
                    2
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6 col-lg-4" }, [
          _c("div", { staticClass: "card card-list" }, [
            _vm._m(5),
            _vm._v(" "),
            _vm.bundle
              ? _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "general-list" },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _vm._l(_vm.bundle.bundle_weeks, function(bundleWeek) {
                        return _c(
                          "div",
                          {
                            key: "bundle_week_" + bundleWeek.id,
                            staticClass: "row"
                          },
                          [
                            _c("div", { staticClass: "col" }, [
                              _vm._v(_vm._s(bundleWeek.week))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c("strong", [
                                _vm._v(_vm._s(bundleWeek.discount || "/"))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .getBundleWeekPrice(bundleWeek)
                                      .toFixed(2)
                                  )
                                )
                              ])
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card card-list" }, [
            _vm._m(7),
            _vm._v(" "),
            _vm.bundle && _vm.bundle.background_image_attachment_id
              ? _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "general-list" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("img", {
                          staticStyle: { width: "100%", height: "auto" },
                          attrs: { src: _vm.backgroundImage }
                        })
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.showDisableBundleModal
        ? _c("bundle-disable-modal", {
            on: {
              cancel: function($event) {
                _vm.showDisableBundleModal = false
              },
              confirm: _vm.disableBundle
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showBundleEditModal && _vm.$can("Bundles - Create/Update")
        ? _c("bundle-edit-modal", {
            attrs: { bundle: _vm.bundle },
            on: {
              cancel: function($event) {
                _vm.showBundleEditModal = false
              },
              confirm: _vm.updateBundle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Bundle details\n                    ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [_c("strong", [_vm._v("Price taken from first week")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Games\n                    ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Game")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Price")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Number of lines")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col text-muted" }, [
      _c("strong", [_vm._v("Total")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Weeks\n                    ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Week")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Discount %")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Price")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-image" }),
          _vm._v(" Background image\n                    ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }