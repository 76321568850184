<template>
    <div class="card card-list">
        <div class="card-header d-flex align-items-center justify-content-between">
            <span><i class='fa fa-align-justify'></i> Ticket Lines</span>
        </div>

        <div class="card-body ticket-lines-card-body">
            <div class="row d-none d-lg-flex leads-section-list-row">
                <div class="col-sm-2 text-muted">#Line</div>
                <div class="col-sm-2 text-muted">Reference</div>
                <div class="col-sm-2 text-muted">Draw Date</div>
                <div class="col-sm-4 text-muted">Numbers</div>
                <div class="col-sm-2 text-muted">Status</div>
            </div>

            <div class="row leads-section-list-row">
                <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">#Line</span>
                    #L-{{ ticket.id }}
                </div>

                <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">Reference</span>
                    <a :href="`/raffle-tickets/${ticket.id}`" class="d-block">Ticket #{{ ticket.id }}</a>
                    <a :href="`/orders/${ticket.order_id}`">Order #{{ ticket.order_id }}</a>
                </div>

                <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">Draw Date</span>
                    {{ ticket.draw_date || '' }}
                </div>

                <div class="col-12 col-lg-4 text-center align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">Numbers</span>
                    <div class="numbers-group">
                        <div class="numbers-group-wrapper">
                            <span class="mr-1 raffle bg-warning">{{ ticketNumbers }}</span>
                        </div>
                        <div class="d-flex" v-tooltip="{content: 'Fractions'}">
                            <span class="mr-1 fraction">{{ fractions }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-2 align-self-center text-center text-lg-left">
                    <span class="small-mb-badge">Status</span>
                    {{ ticketWinStatus }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'raffle-ticket-lines',

    props: ['ticket'],

    computed: {
        ticketNumbers() {
            const ticket = JSON.parse(this.ticket.data);

            return ticket.numbers.join('');
        },

        fractions() {
            const ticket = JSON.parse(this.ticket.data);

            return ticket.fractions;
        },

        ticketWinStatus() {
            if (!this.ticket.is_parsed || this.ticket.prize === null) {
                return 'No status';
            }

            if (this.ticket.prize === 0) {
                return 'Not a winning line';
            }

            return `Won: ${this.$options.filters.formatCurrency(this.ticket.prize)}`;
        },
    },
}
</script>

<style lang="scss" scoped>
.ticket-results {
    .raffle {
        font-family    : monospace;
        font-size      : 16px;
        font-weight    : bold;
        letter-spacing : 4px;
    }

    .fraction {
        color       : #000000;
        font-weight : bold;
        font-size   : 16px;
        font-family : monospace;
    }
}
</style>