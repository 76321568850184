<template>
    <div v-if="hasPlayerFilledProfileData">
        <div class="player-play-games-holder">
            <div class="game syndicate" v-for="syndicate in syndicates" :key="`syndicate_${syndicate.id}`">
                <img class="game-thumb" :src="$imageService.gameImage(syndicate.game.image)" />

                <div class="game-play">
                    <p :title="syndicate.title">{{ syndicate.title }}</p>

                    <button @click="selectSyndicate(syndicate)" class="btn btn-sm btn-primary">
                        <span>Play</span>
                    </button>
                </div>
            </div>
        </div>

        <not-found v-if="!isLoading"
                   :items="syndicates"
                   msg="No syndicates found."
        />

        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import SyndicateService from '../../../../../../services/SyndicateService';

export default {
    name: 'syndicates',
    data() {
        return {
            syndicates: [],
            isLoading: false
        };
    },
    computed: {
        hasPlayerFilledProfileData() {
            return this.$store.getters['cart/getPlayerCanPlayGame'];
        },
        orderId() {
            return this.$store.getters['order/getOrderId'];
        },
    },
    mounted() {
        this.fetchSyndicates();
    },
    methods: {
        fetchSyndicates() {
            this.isLoading = true;
            SyndicateService
                .availableSyndicateList()
                .then(response => {
                    this.syndicates = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        selectSyndicate(syndicate) {
            if (this.orderId) {
                this.$store.commit('cart/setClearCartData');
            }

            this.$store.commit('cart/setCheckout', { checkout: false });
            this.$store.commit('cart/setShowGame', true);

            this.$store.commit('games/setGameConfigurationData', syndicate);
        },
    },
}
</script>