var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Add Setting",
        show: _vm.openModal,
        "close-on-backdrop": false
      },
      on: {
        close: function($event) {
          return _vm.openAddSectionModal(false)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex w-100" }, [
                _c("div", { staticClass: "w-50 font-weight-bold" }, [
                  _vm._v("\n                * Required\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                  _vm._v(" "),
                  _vm.$can("Settings - Create/Update")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: {
                            "btn-primary": !_vm.isLoading,
                            "btn-outline-primary": _vm.isLoading
                          },
                          on: { click: _vm.handleOK }
                        },
                        [
                          !_vm.isLoading
                            ? _c("span", [
                                _vm._v(
                                  "\n                        Save\n                    "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loader", {
                            attrs: { type: "small", show: _vm.isLoading }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Name *",
                    value: _vm.model.name,
                    errors: _vm.errors
                  },
                  on: { update: _vm.updateForm }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Key *",
                    name: "key",
                    value: _vm.model.key,
                    errors: _vm.errors
                  },
                  on: { update: _vm.updateForm }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Value *",
                    name: "value",
                    value: _vm.model.value,
                    errors: _vm.errors
                  },
                  on: { update: _vm.updateForm }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-select", {
                  attrs: {
                    label: "Type *",
                    name: "type",
                    value: _vm.model.type,
                    errors: _vm.errors,
                    options: _vm.settingsSectionsTypes
                  },
                  on: { update: _vm.updateForm }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.isToggle
              ? _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("atbl-input", {
                      attrs: {
                        label: "Off Value",
                        name: "off_value",
                        value: _vm.model.off_value,
                        errors: _vm.errors
                      },
                      on: { update: _vm.updateForm }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isToggle
              ? _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("atbl-input", {
                      attrs: {
                        label: "On Value",
                        name: "on_value",
                        value: _vm.model.on_value,
                        errors: _vm.errors
                      },
                      on: { update: _vm.updateForm }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _c("h5", { staticClass: "text-center text-uppercase" }, [
            _vm._v("\n        Loading...\n    ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }