import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_ID]: state => state.ticketId,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_DATA]: state => state.ticket,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_DATA_LOADER]: state => state.ticketLoader,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_GAME]: state => state.ticket.game ? state.ticket.game : null,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_PLAYER]: state => state.ticket.order && state.ticket.order.player ? state.ticket.order.player : null,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_CURRENCY]: state => state.ticket.currency ? state.ticket.currency : null,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_LINES]: state => state.ticket.draw && state.ticket.draw.lines ? state.ticket.draw.lines : null,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_DRAW]: state => state.ticket.draw ? state.ticket.draw : null,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_CONFIGURATION_NUMBERS]: state => state.ticket.numbers ? state.ticket.numbers : null,
    [TypesConst.tickets.getters.GET_TICKETS_TICKET_CONFIGURATION]: state => state.ticketConfiguration,
};