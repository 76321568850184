var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.player.disposition_created_at && !!_vm.player.disposition_name
    ? _c("div", [
        _c("span", { staticClass: "font-weight-bold mr-1" }, [
          _vm._v("Disposition")
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "badge badge-primary",
            attrs: {
              href: "/players/" + _vm.player.id + "/dispositions",
              target: "_blank"
            }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.player.disposition_name) + "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("small", { staticClass: "follow-up" }, [
          _c("strong", [_vm._v("Created At:")]),
          _vm._v(" " + _vm._s(_vm.player.disposition_created_at) + "\n    ")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        !!_vm.player.disposition_follow_up
          ? _c("small", { staticClass: "follow-up" }, [
              _c("strong", [_vm._v("Follow Up:")]),
              _vm._v(" " + _vm._s(_vm.player.disposition_follow_up) + "\n    ")
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }