<template>
    <div class="card card-list">
        <div class="card-header">
            <div>
                <img :src="$imageService.gameImage(editingGame.image)" width="60"/>
                {{ editingGame.name }}
            </div>
            <a :class="[{disabled: !!isLoading}]"
               href="#"
               @click.prevent="clearGameForEdit"
            >
                <i class="fa fa-times"></i>
            </a>
        </div>
        <div class="card-body">
            <template v-if="isLottery">
                <LinesEditorComponent :editing-game="editingGame"
                                      :data="lineData"
                />
                <DrawsEditorComponent :editing-game="editingGame"
                                      :data="drawData"
                />
            </template>
            <template v-else>
                <FractionsEditorComponent :editing-game="editingGame"
                                          :data="fractionData"
                />
            </template>
        </div>
        <div class="card-footer">
            <button :disabled="isLoading"
                    @click="clearGameForEdit"
            >
                Close
            </button>
        </div>
    </div>
</template>

<script>
import GameDiscountModel from '../GameDiscountModel';
import LinesEditorComponent from './LinesEditor/LinesEditorComponent.vue';
import GameType from '../../../constants/GameType';
import DrawsEditorComponent from './DrawsEditor/DrawsEditorComponent.vue';
import FractionsEditorComponent from './FractionsEditor/FractionsEditorComponent.vue';

export default {
    name: 'DiscountEditorComponent',
    components: {
        FractionsEditorComponent,
        DrawsEditorComponent,
        LinesEditorComponent
    },
    inject: [
        'clearGameForEdit',
    ],
    props: {
        editingGame: {
            type: Object,
            default: () => (GameDiscountModel)
        },
        lineData: {
            type: Object,
            default: () => ({
                discountsInitial: [],
                discounts: [],
                activeVersion: null,
                loader: false,
                errors: {},
            })
        },
        drawData: {
            type: Object,
            default: () => ({
                discountsInitial: [],
                discounts: [],
                activeVersion: null,
                loader: false,
                errors: {},
            })
        },
        fractionData: {
            type: Object,
            default: () => ({
                discountsInitial: [],
                discounts: [],
                activeVersion: null,
                loader: false,
                errors: {},
            })
        },
    },
    computed: {
        isLottery() {
            return this.editingGame.type === GameType.Lottery;
        },
        isLoading() {
            return !!this.lineData.loader ||
              !!this.drawData.loader ||
              !!this.fractionData.loader;
        },
    },
};
</script>