<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="card card-list">
                    <div class="card-header d-flex align-items-center">
                        <i class="fa fa-gamepad mr-1"></i> Games
                    </div>

                    <div class="card-body">
                        <div class="general-list">
                            <div class="row">
                                <div class="col-12 col-lg-2 text-center text-lg-left">&nbsp;</div>
                                <div class="col text-center text-lg-left">Game</div>
                                <div class="col-12 col-lg-3 text-center">Current Price</div>
                                <div class="col-12 col-lg-3 text-center">Operation</div>
                            </div>

                            <template v-if="!isLoading">
                                <div v-for="(item, index) in games" :key="index" class="row">
                                    <div class="col-12 col-lg-2 text-center">
                                        <img :src="$imageService.gameImage(item.image)" class="img-fluid" width="40" alt="" />
                                    </div>
                                    <div class="col text-center text-lg-left">
                                        <span class="small-mb-badge">Game</span>
                                        {{ item.name }}
                                    </div>
                                    <div class="col-12 col-lg-3 text-center" >
                                        <span class="small-mb-badge">Current Price</span>
                                        <span v-if="item.latest_price">
                                            {{ item.latest_price }}
                                        </span>
                                        <span v-else>
                                            <span class="badge badge-danger">
                                                Undefined
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-12 col-lg-3 text-center">
                                        <span class="small-mb-badge">Operation</span>
                                        <a href="#" @click.prevent="selectGame(item)">
                                            Open/Update
                                        </a>
                                    </div>
                                </div>
                            </template>

                            <not-found v-if="!isLoading"
                                       :items="games"
                                       msg="No games found."
                            />

                            <loader :show="isLoading"
                                    type="small"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card card-list">
                    <div class="card-header">
                        <div v-if="!isLoading && activeGame.id"
                             class="d-flex align-items-center"
                        >
                            <img :src="$imageService.gameImage(activeGame.image)" width="40" class="img-fluid mr-2" alt="" />
                            <h4 class="m-0">
                                {{ activeGame.name }}
                            </h4>
                        </div>
                        <span v-else
                              class="d-flex align-items-center"
                        >
                            <i class="fa fa-pound-sign mr-1"></i> Prices
                        </span>
                    </div>

                    <div class="card-body">
                        <div v-if="!isLoading && activeGame.id && $can('Games - Update Price')"
                             class="row no-gutters my-2"
                        >
                            <div class="col mr-2">
                                <input type="number"
                                       step="0.1"
                                       class="form-control"
                                       v-model="changePrice"
                                       :disabled="isUpdating"
                                />
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-primary"
                                        :disabled="isPriceSame || isUpdating"
                                        @click="updatePrice"
                                >
                                    <span v-if="!isUpdating">Update Price</span>
                                    <loader :show="isUpdating"
                                            type="smaller"
                                    />
                                </button>
                            </div>
                        </div>
                        <div v-if="!isLoading" class="general-list">
                            <div class="row">
                                <div class="col-12 col-lg">Price</div>
                                <div class="col-12 col-lg">From</div>
                                <div class="col-12 col-lg">To</div>
                            </div>

                            <div v-for="(item, index) in activeGame.prices" :key="index" class="row">
                                <div class="col-12 col-lg">
                                    <span class="small-mb-badge">Price</span>
                                    {{ item.line_price }}
                                </div>
                                <div class="col-12 col-lg">
                                    <span class="small-mb-badge">From</span>
                                    {{ utcToLocal(item.from) }}
                                </div>
                                <div class="col-12 col-lg">
                                    <span class="small-mb-badge">To</span>
                                    <span v-if="!item.to"
                                          class="badge badge-success"
                                    >
                                        Current Price
                                    </span>
                                    <span v-else>
                                        {{ utcToLocal(item.to) }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <not-found v-if="!isLoading"
                                   :items="activeGame.prices"
                                   msg="No game prices found."
                        />

                        <loader :show="isLoading"
                                type="small"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Games',
    data () {
        return {
            isLoading: false,
            isUpdating: false,
            games: [],
            price: null,
            activeGame: {
                api_enabled: false,
                country: null,
                country_code: null,
                created_at: null,
                enabled: false,
                favourited: false,
                id: null,
                image: null,
                is_auto_draw_processing_enabled: false,
                key: null,
                name: null,
                order: null,
                prices: [],
                support_draws: false,
                support_syndicate: false,
                updated_at: null
            }
        };
    },
    computed: {
        changePrice: {
            get () {
                return this.price;
            },
            set (value) {
                this.price = Number(value);
            }
        },
        isPriceSame () {
            const gamePrice = this.games.filter(item => item.id === this.activeGame.id)[0];

            if (!gamePrice) {
                return false;
            }

            return gamePrice.latest_price === this.price;
        },
    },
    methods: {
        async getGamesList (gameKey = null) {
            this.isLoading = true;

            try {
                const response = await window.axios.get('/games/list');

                this.games = response.data.map(item => {
                    item.prices = item.prices.map((item, index, all) => {
                        const nextPrice = all[index + 1];

                        return {
                            line_price: item.line_price,
                            from: item.created_at,
                            to: nextPrice ? nextPrice.created_at : null,
                        };
                    });

                    return {
                        ...item,
                        latest_price: !!item.prices.length ? item.prices[item.prices.length - 1].line_price : null
                    };
                });
                this.activeGame = !!gameKey
                    ? this.games.filter(item => item.key === gameKey)[0]
                    : this.games[0];
                this.price = this.activeGame.latest_price;

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);
                this.isLoading = false;
            }
        },
        async updatePrice () {
            this.isUpdating = true;

            try {
                const response = await window.axios.post('/games/update-price', {
                    game_id: this.activeGame.id,
                    price: this.price
                });

                const { status, message } = response.data;

                if (status) {
                    await this.getGamesList(this.activeGame.key);
                }

                window.showMessage(message, status)

                this.isUpdating = false;
            } catch (error) {
                window.flashError(error);
                this.isUpdating = false;
            }
        },
        selectGame (game) {
            this.activeGame = game;
            this.price = game.latest_price;
            this.$scrollToTop();
        },
    },
    created () {
        this.getGamesList();
    }
}
</script>