var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card m-0" }, [
    _vm.$can("Payon - Bonus Deposit Agent") ||
    _vm.$can("Payon - Bonus Deposit User")
      ? _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row mt-2" }, [
            _vm.$can("Payon - Bonus Deposit Agent")
              ? _c(
                  "div",
                  { staticClass: "col-12 col-md-3" },
                  [
                    _c("agent-picker", {
                      model: {
                        value: _vm.agent,
                        callback: function($$v) {
                          _vm.agent = $$v
                        },
                        expression: "agent"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-md-3" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Amount",
                    name: "amount",
                    errors: _vm.errors,
                    min: 0.0,
                    step: 0.01,
                    value: _vm.amount
                  },
                  on: { update: _vm.updateAmount }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-md-3" },
              [
                _c(
                  "atbl-form-group",
                  { attrs: { label: "#", "label-for": "buyBtn" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block",
                        class: {
                          "btn-primary": !_vm.getPaymentLoader,
                          "btn-outline-primary": _vm.getPaymentLoader
                        },
                        attrs: { disabled: _vm.isSaveEnabled },
                        on: {
                          click: function($event) {
                            return _vm.setBonusConfirmModal(true)
                          }
                        }
                      },
                      [
                        !_vm.getPaymentLoader
                          ? _c("span", [_vm._v("Add")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { type: "smaller", show: _vm.getPaymentLoader }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }