var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Wallet payment confirmation",
        "close-on-backdrop": true,
        show: true
      },
      on: { close: _vm.onCancelEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-end w-100"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light mr-2",
                      on: { click: _vm.onCancelEvent }
                    },
                    [_vm._v("\n                Close\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success",
                      on: {
                        click: function($event) {
                          return _vm.$emit("confirm")
                        }
                      }
                    },
                    [_vm._v("\n                Confirm\n            ")]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h4", { staticClass: "text-center mb-3" }, [
        _vm._v("Confirm wallet payment")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-center mb-4" }, [
        _vm._v("\n        Player account will be charged with "),
        _c("b", { staticClass: "h6" }, [
          _vm._v(_vm._s(_vm._f("formatCurrency")(_vm.amount)))
        ]),
        _vm._v(". Do you want to proceed?\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }