<template>
    <atbl-modal title="Remove Conversion Rate"
             :show="modalOpened"
             :close-on-backdrop="false"
             @close="modalOpened = false"
    >
        <div class="row">
            <div class="col-12">
                <h4 class="text-center">Are you sure you want to delete {{ typeOfCurrency }} currency rate?</h4>
            </div>
        </div>

        <template #footer v-if="$can('Conversions Rate - Remove')">
            <button class="btn btn-sm btn-light"
                      @click="cancelModal"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                      @click="submitModal"
            >
                Ok
            </button>
        </template>
    </atbl-modal>
</template>

<script>
    import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

    export default {
        name: "remove-currency-rate-modal",
	    components: {
		    AtblModal,
	    },
        props: {
            removeModal: {
                type: Boolean,
                required: true
            },
            currencyType: {
                required: true
            }
        },
        data(){
            return {
                modalOpened: false,
                typeOfCurrency: null
            };
        },
        methods: {
            submitModal: function(){
                this.$emit('on-edit');
            },
            cancelModal: function(){
                this.$emit('cancel');
            }
        },
        watch: {
            removeModal: function(value){
                this.modalOpened = value;
            },
            currencyType: function(value){
                this.typeOfCurrency = value;
            },
            modalOpened: function(value){
                if(!value){
                    this.$emit('cancel');
                }
            }
        }
    }
</script>