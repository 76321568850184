var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card m-0" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header card-header-atbl d-flex justify-content-between align-items-center"
      },
      [
        _c("span", [
          _c("i", { staticClass: "fa fa-align-justify" }),
          _vm._v(" " + _vm._s(_vm.title) + "\n        ")
        ]),
        _vm._v(" "),
        _vm._t("rightSide")
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }