var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      staticClass: "bulk-players-modal hide-footer",
      class: {
        "hide-close": !!_vm.isLoading
      },
      attrs: { "close-on-backdrop": false, title: "Bulk Action" },
      model: {
        value: _vm.modalVisibility,
        callback: function($$v) {
          _vm.modalVisibility = $$v
        },
        expression: "modalVisibility"
      }
    },
    [
      _c(_vm.bulkAction, {
        tag: "component",
        attrs: {
          players: _vm.players,
          "selected-players": _vm.selectedPlayers
        },
        on: {
          loader: function($event) {
            _vm.isLoading = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }