import PaymentService from '../../../services/PaymentService';

const state = {
    paymentGateways: [],
};

const getters = {
    paymentGateways: state => state.paymentGateways,
};

const actions = {
    fetchPaymentGateways({ commit }) {
        const promise = PaymentService.fetchPaymentGateways();

        promise.then((response) => {
            commit('setPaymentGateways', response.data);
        });

        return promise;
    },

    fetchPlayerPaymentGateways({ commit }, playerId) {
        const promise = PaymentService.fetchPlayerPaymentGateways(playerId);

        promise.then((response) => {
            commit('setPaymentGateways', response.data);
        });

        return promise;
    },
};

const mutations = {
    setPaymentGateways(state, paymentGateways) {
        state.paymentGateways = paymentGateways;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}