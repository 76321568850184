var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: {
        id: _vm.id,
        icon: "fa fa-user",
        title: "Player",
        header: "",
        "body-class": "py-3"
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("div", [
                _c("i", { staticClass: "fa fa-align-justify" }),
                _vm._v(" Player\n\t        ")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "rightSide",
          fn: function() {
            return [
              _vm.$canAny(["Player - View Agent", "Player - Assign Agent"])
                ? _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("span", { staticClass: "mr-1 text-uppercase" }, [
                        _c("small", { staticClass: "font-weight-bold" }, [
                          _vm._v("Agent")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.$can("Player - View Agent") &&
                      !_vm.$can("Player - Assign Agent")
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-danger atbl-badge-padding rounded-0"
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.player && _vm.player.user
                                      ? _vm.player.user.name
                                      : "/"
                                  ) +
                                  "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$can("Player - Assign Agent") && !_vm.agentLoader
                        ? _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.showAssignAgentForm = true
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-danger atbl-badge-padding rounded-0"
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.player && _vm.player.user
                                          ? _vm.player.user.name
                                          : "/"
                                      ) +
                                      " "
                                  ),
                                  _c("i", {
                                    staticClass: "fa fa-pencil-alt ml-1"
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: { show: _vm.agentLoader, type: "smaller" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "last",
          fn: function() {
            return [
              _c("PlayerStatistics", {
                attrs: { player: _vm.player, "is-loading": _vm.isLoadingNow }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoadingNow,
              expression: "!isLoadingNow"
            }
          ]
        },
        [
          !_vm.isEdit
            ? _c("PreviewPlayer", { attrs: { player: _vm.player } })
            : _c("EditPlayerForm", {
                attrs: { player: _vm.modelTemp, errors: _vm.errors },
                on: { "update:player-edit-form": _vm.updateModel }
              }),
          _vm._v(" "),
          !_vm.isEdit
            ? _c("div", { staticClass: "row justify-content-between" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [_c("player-tags", { attrs: { player: _vm.player } })],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("loader", { attrs: { type: "small", show: _vm.isLoadingNow } }),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center flex-wrap"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "player-profile-actions-holder text-center text-lg-left"
              },
              [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("\n                        Created: "),
                  _c("b", [
                    _vm._v(_vm._s(_vm.utcToLocal(_vm.player.created_at)))
                  ])
                ]),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("\n                        Updated: "),
                  _c("b", [
                    _vm._v(_vm._s(_vm.utcToLocal(_vm.player.updated_at)))
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            !_vm.isLoadingNow
              ? _c(
                  "div",
                  {
                    staticClass:
                      "player-profile-actions-holder text-center text-lg-right d-flex"
                  },
                  [
                    !_vm.isEdit
                      ? _c("extra-actions-component", {
                          staticClass: "mr-2",
                          attrs: { player: _vm.player }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("Player - Create/Update") && !_vm.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-primary mr-2",
                            on: { click: _vm.onEditPlayerToggle }
                          },
                          [_vm._v("Edit Profile\n                    ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("Player - Reset Password") && !_vm.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            on: {
                              click: function($event) {
                                _vm.showResetModal = true
                              }
                            }
                          },
                          [_vm._v("Reset Password\n                    ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isEdit
                      ? _c("activate-player-profile", {
                          staticClass: "ml-2",
                          attrs: { player: _vm.player }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isEdit
                      ? _c("BlockPlayerProfile", {
                          staticClass: "ml-2",
                          attrs: { player: _vm.player },
                          on: {
                            update: function($event) {
                              return _vm.setPlayerModel({ block: $event })
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("Player - Create/Update") && _vm.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary mr-2",
                            on: {
                              click: function($event) {
                                return _vm.update(_vm.player.id)
                              }
                            }
                          },
                          [_vm._v("Save\n                    ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-secondary",
                            on: { click: _vm.editPlayerToggleExit }
                          },
                          [_vm._v("Exit\n                    ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _vm._v(" "),
      _c("ResetPassword", {
        attrs: { "player-id": _vm.player.id },
        model: {
          value: _vm.showResetModal,
          callback: function($$v) {
            _vm.showResetModal = $$v
          },
          expression: "showResetModal"
        }
      }),
      _vm._v(" "),
      _c("AssignAgent", {
        attrs: { show: _vm.showAssignAgentForm, player: _vm.player },
        on: {
          success: function($event) {
            return _vm.fetchPlayer(true)
          },
          close: function($event) {
            _vm.showAssignAgentForm = false
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }