import Transactions from "../constants/Transactions";

export default {
    data: function(){
        return {
            get userId(){
                return window.layer ? window.layer.ud : null;
            },
            get getIsAdmin(){
                return window.layer ? window.layer.iad : null;
            },
            get getIsManager(){
                return window.layer ? window.layer.imn : null;
            },
            get getIsAdminManager(){
                return window.layer ? window.layer.iadmn : null;
            },
            get getIsOnlyAgent(){
                return window.layer ? window.layer.ioad : null;
            },
            get getIsAgent(){
                return window.layer ? window.layer.iag : null;
            },
            get routeName(){
                return window.layer ? window.layer.rn : null;
            },
            get amountLimit(){
                return Transactions.amountLimit;
            }
        };
    },
    methods: {
        isRoute: function(name){
            return this.routeName === name;
        },
        isRouteActive: function(routes, activeClass = "c-active") {
            if(Array.isArray(routes)){
                return routes.includes(this.routeName) ? activeClass : [];
            }
            return this.isRoute(routes) ? activeClass : [];
        }
    }
};
