/**
 * Convert full object to FormData object
 *
 * @param object
 * @return {FormData}
 */
export default function (object) {
    const formData = new FormData();

    for (const key in object) {
        const value = object[key];

        if (value === null) {
            continue;
        }

        if (Array.isArray(value)) {
            for (const arrayItem in value) {
                const arrayValue = value[arrayItem];

                formData.append(`${key}[]`, !!arrayValue ? arrayValue : null);
            }
        } else {
            formData.append(key, value);
        }
    }

    return formData;
}
