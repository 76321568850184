var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$canAny(_vm.permissions)
    ? _c("atbl-select", {
        attrs: {
          label: _vm.label,
          name: "agent",
          options: _vm.users,
          disabled: _vm.isFetchUsersLoading,
          value: _vm.getSelected
        },
        on: { update: _vm.changed }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }