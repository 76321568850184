var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-list" },
    _vm._l(_vm.settings, function(setting, index) {
      return _c("div", { key: index, staticClass: "settings-list-item" }, [
        _c("div", { staticClass: "settings-list-item-left" }, [
          _c("span", { staticClass: "name", attrs: { title: "Name" } }, [
            _vm._v(_vm._s(setting.name))
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "key", attrs: { title: "Key" } }, [
            _vm._v(_vm._s(setting.key))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "settings-list-item-middle" },
          [
            setting.type === "input"
              ? _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    disabled: !_vm.$can("Settings - Create/Update")
                  },
                  domProps: { value: setting.value },
                  on: {
                    keyup: function($event) {
                      return _vm.changeValue($event, setting)
                    }
                  }
                })
              : _c("atbl-switch", {
                  attrs: {
                    value: setting.value,
                    on: setting.on_value,
                    off: setting.off_value,
                    name: setting.key,
                    "is-loading": _vm.settingSaveLoading
                  },
                  on: {
                    input: function($event) {
                      return _vm.changeValue($event, setting)
                    }
                  }
                })
          ],
          1
        ),
        _vm._v(" "),
        _vm.$canAny(_vm.permissionsOperations)
          ? _c(
              "div",
              { staticClass: "settings-list-item-right" },
              [
                _c(
                  "c-dropdown",
                  {
                    attrs: {
                      id: "dropdown-right",
                      right: "",
                      color: "link",
                      caret: false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "toggler-content",
                          fn: function() {
                            return [
                              _c("i", {
                                staticClass: "fa fa-ellipsis-v",
                                attrs: { "aria-hidden": "false" }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _vm.$can("Settings - Create/Update")
                      ? _c(
                          "c-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.editSetting($event, setting)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Edit\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("Settings - Remove")
                      ? _c(
                          "c-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.removeSetting(setting)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Remove\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }