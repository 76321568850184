var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasPlayerFilledProfileData
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "player-play-games-holder" },
            _vm._l(_vm.syndicates, function(syndicate) {
              return _c(
                "div",
                {
                  key: "syndicate_" + syndicate.id,
                  staticClass: "game syndicate"
                },
                [
                  _c("img", {
                    staticClass: "game-thumb",
                    attrs: {
                      src: _vm.$imageService.gameImage(syndicate.game.image)
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "game-play" }, [
                    _c("p", { attrs: { title: syndicate.title } }, [
                      _vm._v(_vm._s(syndicate.title))
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.selectSyndicate(syndicate)
                          }
                        }
                      },
                      [_c("span", [_vm._v("Play")])]
                    )
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.syndicates, msg: "No syndicates found." }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }