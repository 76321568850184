var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Users - Create/Update")
    ? _c(
        "atbl-modal",
        {
          class: {
            "hide-footer": _vm.isGetPlayerLoading
          },
          attrs: {
            title: (!_vm.isEdit ? "Update" : "Create") + " User",
            show: _vm.show,
            "close-on-backdrop": false
          },
          on: { close: _vm.handleCancel },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: { click: _vm.handleCancel }
                      },
                      [_vm._v("\n            Close\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.createUpdateUser }
                      },
                      [
                        !_vm.isLoading
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.isEdit ? "Save" : "Create"))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { show: _vm.isLoading, type: "smaller" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            1466024042
          )
        },
        [
          !_vm.isGetPlayerLoading
            ? [
                _vm.$can("Users - Create/Update")
                  ? _c("div", { staticClass: "mb-4" }, [
                      _c("h5", { staticClass: "base-heading" }, [
                        _vm._v("General")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("atbl-input", {
                              attrs: {
                                label: "Name",
                                name: "name",
                                errors: _vm.errors
                              },
                              model: {
                                value: _vm.user.name,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "user.name"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("atbl-input", {
                              attrs: {
                                label: "Email",
                                name: "email",
                                errors: _vm.errors
                              },
                              model: {
                                value: _vm.user.email,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "email",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "user.email"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("atbl-input", {
                              attrs: {
                                label: "Password",
                                type: "password",
                                name: "password",
                                errors: _vm.errors
                              },
                              model: {
                                value: _vm.user.password,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "password",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "user.password"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("atbl-input", {
                              attrs: {
                                label: "Repeat Password",
                                type: "password",
                                name: "password_confirmation",
                                errors: _vm.errors
                              },
                              model: {
                                value: _vm.user.password_confirmation,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "password_confirmation",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "user.password_confirmation"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("Users - Assign Shift")
                  ? _c("div", { staticClass: "mb-4" }, [
                      _c("h5", { staticClass: "base-heading" }, [
                        _vm._v("Shift")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("shifts-picker", {
                              model: {
                                value: _vm.user.shift_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "shift_id", $$v)
                                },
                                expression: "user.shift_id"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("User - Can Assign Commission Plan")
                  ? _c("div", { staticClass: "mb-4" }, [
                      _c("h5", { staticClass: "base-heading" }, [
                        _vm._v("Commission plans")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._l(_vm.commissionPlans, function(plan, index) {
                            return _c(
                              "label",
                              {
                                key: index,
                                staticClass: "mr-1 font-weight-bold",
                                attrs: { for: plan.label }
                              },
                              [
                                _c("input", {
                                  attrs: { id: plan.label, type: "radio" },
                                  domProps: {
                                    checked:
                                      _vm.selectedCommissionPlan === plan.value,
                                    value: plan.value
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.selectPlan(plan.value)
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(plan.label) +
                                    "\n          "
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("loader", {
                            attrs: {
                              show: _vm.isLoadingCommissionsPlanList,
                              type: "smaller"
                            }
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$canAny([
                  "User - Can Assign Roles",
                  "User - Can Assign Permissions"
                ])
                  ? _c("div", [
                      _c("h5", { staticClass: "base-heading" }, [
                        _vm._v(
                          "\n                Roles and Permissions\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$can("User - Can Assign Roles")
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "atbl-form-group",
                                  { attrs: { label: "Roles" } },
                                  [
                                    !_vm.isLoadingRolesList
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center flex-wrap"
                                          },
                                          _vm._l(_vm.roles, function(
                                            item,
                                            index
                                          ) {
                                            return _c("atbl-check-box", {
                                              key: index,
                                              staticClass: "mr-1",
                                              attrs: {
                                                checked: _vm.selected.includes(
                                                  item.value
                                                ),
                                                label: item.text
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.selectRole(item)
                                                }
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("loader", {
                                      attrs: {
                                        show: _vm.isLoadingRolesList,
                                        type: "smaller"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$can("User - Can Assign Permissions")
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("multi-permissions-picker", {
                                  attrs: {
                                    "role-permissions": _vm.rolePermissions,
                                    disabled: _vm.isPermissionsLoading,
                                    label: "Direct permissions"
                                  },
                                  model: {
                                    value: _vm.user.permissions,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "permissions", $$v)
                                    },
                                    expression: "user.permissions"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            attrs: { show: _vm.isGetPlayerLoading, type: "small" }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }