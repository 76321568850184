var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    { staticClass: "atbl-fake-input-group", attrs: { label: _vm.label } },
    [
      _c(
        "div",
        { staticClass: "atbl-fake-input" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.copy
            ? _c(
                "a",
                {
                  staticClass: "atbl-fake-input-copy",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.copyToClipboard(_vm.value)
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-copy" })]
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }