var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      staticClass: "modal__payment-oppwa",
      attrs: { title: _vm.title, "close-on-backdrop": true, show: true },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "oppwa-spinner" }, [
        _c("img", { attrs: { src: "/img/spiner.svg", alt: "spiner" } })
      ]),
      _vm._v(" "),
      _c("form", {
        staticClass: "paymentWidgets",
        attrs: {
          action: _vm.apiPaymentResponse.checkout.redirect_url,
          "data-brands": "VISA MASTER"
        }
      }),
      _vm._v(" "),
      _c("script", {
        attrs: {
          type: "application/javascript",
          src: _vm.apiPaymentResponse.checkout.url
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }