var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _c(
      "div",
      { staticClass: "card-header card-header-atbl d-flex align-items-center" },
      [
        _c(
          "a",
          {
            staticClass: "mr-2 btn btn-primary btn-sm m-0",
            attrs: { href: "/marketing/campaigns/" + _vm.campaignType }
          },
          [
            _c("i", {
              staticClass: "nav-icon fa fa-chevron-left",
              attrs: { "aria-hidden": "false" }
            })
          ]
        ),
        _vm._v(" "),
        _c("span", [
          _c("i", {
            staticClass: "nav-icon fa fa-bullhorn",
            attrs: { "aria-hidden": "false" }
          }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(!!_vm.campaignId ? "Edit" : "Create")
            }
          }),
          _vm._v(" Campaign\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body pt-2" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("multi-agents-picker", {
              attrs: { disabled: _vm.isLoading },
              model: {
                value: _vm.selectedAgent,
                callback: function($$v) {
                  _vm.selectedAgent = $$v
                },
                expression: "selectedAgent"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("atbl-input", {
              attrs: {
                value: _vm.campaignModel.title,
                disabled: _vm.isLoading,
                errors: _vm.campaignModelErrors,
                label: "Title *",
                name: "title"
              },
              on: { update: _vm.updateCampaignModelField }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "atbl-form-group",
              {
                staticClass: "font-weight-bold",
                attrs: {
                  error: _vm.getError(_vm.campaignModelErrors, "description"),
                  state: _vm.getState(_vm.campaignModelErrors, "description"),
                  id: "campaign-description",
                  label: "Description",
                  "label-for": "campaignDescription"
                }
              },
              [
                _c("textarea", {
                  staticClass: "form-control",
                  attrs: {
                    disabled: _vm.isLoading,
                    id: "campaignDescription",
                    rows: "5",
                    required: "",
                    placeholder: "Enter description"
                  },
                  domProps: { value: _vm.campaignModel.description },
                  on: {
                    input: function($event) {
                      return _vm.updateCampaignModelField(
                        "description",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.$can("Leads - View")
          ? _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm.isLeadCampaign()
                  ? _c("batch-files-picker", {
                      attrs: {
                        value: _vm.campaignModel.batch,
                        disabled: _vm.isLoading
                      },
                      on: { update: _vm.updateCampaignModelField }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("upload-csv", {
                  attrs: {
                    value: _vm.campaignModel.csv,
                    disabled: _vm.isLoading
                  },
                  on: { update: _vm.updateCampaignModelField }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("campaign-types-picker", {
              attrs: { value: _vm.campaignModel.type, disabled: _vm.isLoading },
              on: { update: _vm.updateCampaignModelField }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("campaign-view-types-picker", {
              attrs: {
                value: _vm.campaignModel.view_type,
                disabled: _vm.isLoading
              },
              on: { update: _vm.updateCampaignModelField }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("campaigns-status-picker", {
              attrs: {
                value: _vm.campaignModel.status,
                errors: _vm.campaignModelErrors,
                disabled: _vm.isLoading
              },
              on: {
                input: function($event) {
                  return _vm.updateCampaignModelField("status", $event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("campaign-tags-picker", {
              attrs: {
                value: _vm.campaignModel.tags,
                errors: _vm.campaignModelErrors,
                disabled: _vm.isLoading
              },
              on: {
                input: function($event) {
                  return _vm.updateCampaignModelField("tags", $event)
                },
                interface: _vm.setChildInterface
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card-footer d-flex align-items-center justify-content-end"
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-sm",
            class: {
              "btn-primary": !_vm.isLoading,
              "btn-outline-primary": _vm.isLoading
            },
            attrs: { disabled: !_vm.isSaveEnabled },
            on: { click: _vm.saveCampaign }
          },
          [
            !_vm.isLoading
              ? _c("span", [_vm._v("\n                Save\n            ")])
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }