export default {
	en: 'en',
	resolve: function(locale){
		switch(locale) {
			case "gb":
					locale = "en";
				break;
		}
		return locale;
	}
};