import ticketsPreviewTypes from "./types/ticketsPreviewTypes";

export default {
    mutations: {
        ...ticketsPreviewTypes.mutations,
    },
    getters: {
        ...ticketsPreviewTypes.getters,
    },
    actions: {
        ...ticketsPreviewTypes.actions,
    }
};
