<template>
    <div class="basket-price">
        <span class="mr-2"
              v-tooltip="{
                content: 'Price'
              }"
        >
            <b>Price:</b>
            <s v-if="!!item.discount" class="text-danger ml-1">
                {{ item.price | formatCurrency }}
            </s>
            <span v-else>
                {{ item.total_price | formatCurrency }}
            </span>
        </span>

        <span v-if="!!item.discount"
              class="mr-2"
              v-tooltip="{
                content: 'Discount'
              }"
        >
            <i class="fa fa-percent"></i> {{ formatNumber(item.discount) }}
        </span>

        <span v-if="!!item.discount"
              v-tooltip="{
                content: 'Total'
              }"
        >
            {{ item.total_price | formatCurrency }}
        </span>
    </div>
</template>

<script>
import utils from '../../../../../../../../utils';

export default {
    name: 'Price',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatNumber(amount) {
            return utils.formatNumber(amount);
        },
    },
}
</script>