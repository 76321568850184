var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atbl-date-time-picker" },
    [
      _c(
        "atbl-form-group",
        {
          attrs: { label: _vm.label, error: _vm.getError(_vm.errors, _vm.name) }
        },
        [
          _c(
            "div",
            { staticClass: "atbl-date-time-picker-input-holder" },
            [
              _c("date-range-picker", {
                staticClass: "w-100",
                attrs: {
                  opens: _vm.direction,
                  singleDatePicker: _vm.singleDatePicker,
                  timePicker: _vm.timePicker,
                  showWeekNumbers: _vm.showWeekNumbers,
                  ranges: _vm.showRanges,
                  timePicker24Hour: _vm.timePicker24Hour,
                  "locale-data": _vm.locale,
                  "date-format": _vm.dateFormat,
                  "auto-apply": _vm.autoApply
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "input",
                      fn: function(scope) {
                        return !!_vm.componentValue.startDate
                          ? _c("div", {}, [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.componentValue.startDate) +
                                  "\n\t\t\t\t"
                              )
                            ])
                          : _c("span", [_vm._v(_vm._s(_vm.placeholder))])
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.componentValue,
                  callback: function($$v) {
                    _vm.componentValue = $$v
                  },
                  expression: "componentValue"
                }
              }),
              _vm._v(" "),
              !!_vm.clearable && !!_vm.shouldClearBeShown
                ? _c(
                    "a",
                    {
                      staticClass: "atbl-date-time-picker-close",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onClearValueEvent.apply(null, arguments)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-times-circle" })]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }