<template>
    <span :class="`badge badge-${variant}`">{{ status }}</span>
</template>

<script>
    export default {
        name: "campaign-status-badge",
        props: {
            status: {
                type: String,
                required: true
            }
        },
        computed: {
            variant: function(){
                switch(this.status){
                    case "Waiting":
                        return "warning";
                    case "On Going":
                        return "primary";
                    case "Done":
                        return "success";
                    case "Canceled":
                        return "danger";
                    default:
                        return "secondary";
                }
            }
        }
    }
</script>