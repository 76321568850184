<template>
    <div class="configuration">
        <h5
            class="configuration-header"
            @click="toggleBody"
        >
            <span>{{ title }}</span>
            <i class="fa" :class="{'fa-caret-up': showBody, 'fa-caret-down': !showBody}" aria-hidden="false"></i>
        </h5>
        <div v-show="showBody"
        >
            <div :class="{
                    'configuration-holder' : getConfiguration.numbers,
                    'configuration-holder-multiple' : !getConfiguration.numbers
                }"
            >
                <div v-for="(item, index) in getMainNumbers"
                     :key="index"
                     :class="{
                        'configuration-input-holder' : getConfiguration.numbers,
                        'configuration-holder-single' : !getConfiguration.numbers
                    }"
                >
                    <input v-if="getConfiguration.numbers"
                           :id="`preview_${title}_${genId(item, index)}`"
                           :value="getValue(genId(item, index))"
                           disabled
                    />
                    <div v-else>
                        <label :for="`preview_${title}_${genId(item, index)}`" class="font-weight-bold">
                            {{ item.title ? item.title : ucFirst(index) }}
                        </label>
                        <div v-if="item.numbers">
                            <input v-for="(i, index) in item.numbers"
                                   :key="index"
                                   :id="`preview_${title}_${genId(item, index)}`"
                                   :value="getValue(genId(item, index))"
                                   disabled
                                   :class="{
                                       'configuration-text-input': item.type === 'text'
                                   }"
                            />
                        </div>
                        <input v-else
                               :id="`preview_${title}_${genId(item, index)}`"
                               class="configuration-text"
                               :value="getValue(genId(item, index))"
                               disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ObjectMixin from "../../../../../../mixins/Object";
    import DrawsEditorMixins from "../../../Mixins/DrawsEditor";

    export default {
        name: "confirm-configuration",
        mixins: [
            ObjectMixin,
            DrawsEditorMixins
        ],
        props: {
            configuration: {
                type: Object,
                required: true
            },
            configurationTmp: {
                type: Object,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data: function(){
            return {
                numbers: [],
                showBody: true
            };
        },
        methods: {
            genId: function(item, index){
                return this.generateId(item, index);
            },
            getValue: function(id){
                let temp = this.getConfigurationTemp.filter(item => item.key === id);
                return temp[0].value;
            }
        }
    }
</script>