var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between align-items-center"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.$can("Settings - Create/Update")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary m-0",
                    on: {
                      click: function($event) {
                        return _vm.openAddSectionModal(true)
                      }
                    }
                  },
                  [_vm._v("\n                Add Section\n            ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body py-0 px-x" },
          [_c("SettingsCategoriesList")],
          1
        )
      ]),
      _vm._v(" "),
      _vm.$can("Settings - Create/Update")
        ? _c("AddSettingsSectionModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Settings - Remove")
        ? _c("RemoveSettingsSectionModal")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-cog" }),
      _vm._v(" Settings")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }