<template>
    <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1" style="height: 100%">
		<span v-if="isLoading" class="spiner">
            <img src="/img/spiner.svg" alt="spiner" />
        </span>

        <div v-if="status">
            <p v-if="!isLoading" class="success"><i class="fa fa-check"></i></p>
            <div :class="['alert', `alert-${alertState}`]">{{ message }}</div>
        </div>

        <div v-else>
            <p class="error"><i class="fa fa-times"></i></p>
            <div class="alert alert-danger">{{ message }}</div>
        </div>

        <h6 v-if="countDown >= 0">Redirect in {{ countDown }} seconds</h6>
    </div>
</template>

<script>
export default {
    props: ['id'],

    data() {
        return {
            isLoading: true,
            countDown: -1,
            message: 'Transaction processing...',
            alertState: 'warning',
            status: true,
        }
    },

    created() {
        this.captureAuthorization();
    },

    methods: {
        captureAuthorization() {
            const url = window.location.href;

            window.axios.post(url).then(response => {
                const responseData = response.data;

                this.status = responseData.status;
                this.alertState = responseData.status === 'success' ? 'success' : 'danger';
                this.message = !!responseData.paid ? responseData.message : (responseData.error || 'There was a problem processing your payment');
                this.message = responseData.status === 'success' ? responseData.message : 'There was a problem processing your credit card authorization';

                let redirectUrl = this.makeRedirectUrl(responseData.meta.player_id);

                if (responseData.status === 'failed') {
                    this.redirect(redirectUrl);
                    return;
                }

                this.redirect(redirectUrl);
            }).catch(err => {
                window.flashError(err);
            }).finally(() => {
                this.isLoading = false;
            });
        },

        makeRedirectUrl(playerId) {
            return this.buildUrl(`/players/${playerId}/cards`);
        },

        redirect(url) {
            this.countDown = 3;

            setTimeout(() => {
                window.location.href = url;
            }, this.countDown * 1000);

            this.countdown();
        },

        countdown() {
            if (this.countDown <= 0) {
                return;
            }

            setTimeout(() => {
                this.countDown -= 1;
                this.countdown();
            }, 1000);
        },
    },
};
</script>

<style scoped>
.spiner {
    z-index : 5;
    width   : 100px;
    height  : 100px;
}

.spiner img {
    width : 100%;
}

.success {
    text-align : center;
}

.success i {
    font-size   : 84px;
    line-height : 84px;
    color       : #20a8d8;
}

.error {
    text-align : center;
}

.error i {
    font-size   : 84px;
    line-height : 84px;
    color       : #f86c6b;
}
</style>
