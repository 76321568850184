<template>
    <div class="container-fluid">
        <orders-search class="mb-3"
                       @search="onSearchEvent"
                       @clear="clearSearchModel"
        />

        <div v-if="!!showTable"
             class="card card-list"
        >
            <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                    <i class='fa fa-play mr-1'></i> Orders
                </div>
                <span v-if="!!hasMore"
                      class="text-danger"
                >
                    Search result contains more than 1000 rows, please narrow your search.
                </span>
                <div class="d-flex align-items-center">
                    <atbl-select v-if="!!orders.length"
                                 :options="paginationModel.data"
                                 :value="paginationModel.default"
                                 :first-option-label="null"
                                 option-label="text"
                                 class="mr-2 mb-0"
                                 @input="onPaginationLimitChangeEvent"
                    />
                </div>
            </div>

            <div class="card-body">
                <div v-if="!isLoading"
                     class="general-list v-sortable" @sort="onUpdateSortEvent"
                >
                    <div class="row sticky-element d-none d-xl-flex">
                        <div v-sort:id="sort" class="col-12 col-lg-4 col-xl text-muted">ID</div>
                        <div v-sort:created_by_string="sort" class="col-12 col-lg-4 col-xl text-muted text-center">Created By</div>
                        <div v-sort:created_at="sort" class="col-12 col-lg-4 col-xl text-muted text-center">Date</div>
                        <div v-sort:amount="sort" class="col-12 col-lg-4 col-xl text-muted text-center">Total</div>
                        <div v-sort:order_items_count="sort" class="col-12 col-lg-4 col-xl text-muted text-center">Items</div>
                        <div v-sort:status="sort" class="col-12 col-lg-4 col-xl text-muted text-center">
                            Status
                        </div>
                    </div>

                    <div v-for="(order, index) in ordersData.data"
                           :key="index"
                           class="row"
                    >
                        <div class="col-12 col-lg-4 col-xl text-center text-xl-left">
                            <span class="d-none d-xl-inline-block">Order:</span>
                            <span class="small-mb-badge-extended">Order</span>
                            <a :href="`/orders/${order.id}`"
                               v-if="$can('Orders - View Order Details')"
                            >
                                #O-{{ order.id }}
                            </a>
                            <span v-else>
                                #O-{{ order.id }}
                            </span>
                            <br>
                            <span class="d-none d-xl-inline-block">Player:</span>
                            <span class="small-mb-badge-extended">Player</span>
                            <a :href="`/players/${order.player_id}/view`"
                               v-if="$can('Player - View Details')"
                            >
                                {{ order.player_name }} {{ order.player_surname }}
                            </a>
                            <span v-else>
                                {{ order.player_name }} {{ order.player_surname }}
                            </span>
                            <br>
                            <div v-if="!!order.latest_completed_transaction_id">
                                <span class="d-none d-xl-inline-block">Transaction:</span>
                                <span class="small-mb-badge-extended">Transaction</span>
                                <a :href="`/transaction/${order.latest_completed_transaction_id}`"
                                   v-if="$can('Orders - View Transaction Details')"
                                >
                                    #T-{{ order.latest_completed_transaction_id }}
                                </a>
                                <span v-else>
                                    #T-{{ order.latest_completed_transaction_id }}
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 col-xl text-center align-self-center">
                            <span class="small-mb-badge-extended">Created by</span>
                            <created-by-badge :created="order" />
                        </div>
                        <div class="col-12 col-lg-4 col-xl text-center align-self-center">
                            <span class="small-mb-badge-extended">Created at</span>
                            {{ utcToLocal(order.created_at) }}
                        </div>
                        <div class="col-12 col-lg-4 col-xl text-center align-self-center">
                            <span class="small-mb-badge-extended">Amount</span>
                            <span v-if="!!order.is_free"
                                  class="badge badge-danger"
                            >
                                Free
                            </span>
                            <span v-else>
                                {{ order.amount | formatCurrency }}
                            </span>
                        </div>
                        <div class="col-12 col-lg-4 col-xl text-center align-self-center">
                            <span class="small-mb-badge-extended">Items</span>
                            {{ order.order_items_count }}
                        </div>
                        <div class="col-12 col-lg-4 col-xl text-center align-self-center mt-3 mt-xl-0"
                        >
                            <span :class="['badge', `badge-${$options.OrdersConstants.statusColor(order.status)}`]"
                                  class="font-weight-bold"
                                     v-tooltip="{
                                        content: 'Status'
                                     }"
                            >
                                {{ order.status.split('_').join(' ') }}
                            </span>
                        </div>
                    </div>

                    <not-found v-if="!isLoading"
                               msg="No orders found."
                               :items="orders"
                    />
                </div>

                <loader :show="isLoading"
                        type="small"
                />
            </div>

            <div v-if="!isLoading && ordersData.last_page > 1"
                 class="card-footer overflow-x-auto"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <nav class="wrap">
                        <atbl-pagination :pagination="ordersData"
                                         :scroll="false"
                                         @paginate="onPageChangeEvent"
                        />
                    </nav>
                    <a href="#"
                       @click.prevent="$scrollToTop()"
                    >
                        <i class="fa fa-caret-up mr-2"></i> Back to top
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrdersSearch from './OrderSearch/OrdersSearch';
import PaginateOptions from '../../../data/PaginateOptions';

import Transaction from '../Mixins/Transaction';
import OrdersConstants from '../../../constants/Orders';
import SaveObjectToUrl from '../../../utils/Url/SaveObjectToUrl';
import DynamicSort from '../../../utils/Array/DynamicSort';
import Paginate from '../../../modules/pagination/Paginate';
import PaginationModel from '../../../modules/pagination/PaginationModel';
import { filterNonEmpty } from '../../../utils';
import { clone } from '../../../utils/Json/Json';

const sortObject = {
    sortBy: 'id',
    direction: 'DESC',
    sortOnInit: false,
};

export default {
    name: 'orders',
    OrdersConstants,
    mixins: [
        Transaction
    ],
    components: {
        OrdersSearch,
    },
    data() {
        return {
            hasMore: false,
            orders: [],
            ordersData: clone(PaginationModel),
            isLoading: false,
            model: {},
            paginationModel: clone(PaginateOptions),
            sort: clone(sortObject),
            showTable: false,
            page: 1,
        };
    },
    computed: {
        hasModelValues() {
            return !!filterNonEmpty(this.model).length;
        },
    },
    methods: {
        onSearchEvent(data) {
            if (data.hasOwnProperty('otherParams')) {
                const { page, sort, paginate } = data.otherParams;

                this.page = page;
                this.sort = sort;
                this.paginationModel.default = paginate;
            }

            this.model = data.hasOwnProperty('searchModel')
                ? data.searchModel
                : data;

            this.fetchOrders();
        },
        async fetchOrders() {
            if (!this.hasModelValues) {
                window.showMessage('Search form cannot be empty.', false);

                return;
            }

            this.showTable = true;
            this.isLoading = true;

            const response = await window.axios.get(`/orders/list`, {
                params: this.model
            });

            const { orders, hasMore } = response.data;

            this.hasMore = hasMore;
            this.orders = orders.map(item => {
                return {
                    ...item,
                    created_by_string: item.created_by.created,
                };
            });

            this.setOrdersData(this.page);

            this.isLoading = false;
        },
        onPageChangeEvent(page = 1) {
            this.setOrdersData(page);
        },
        onUpdateSortEvent(e) {
            this.sort = {
                sortBy: e.detail.key,
                direction: e.detail.direction,
                sortOnInit: sortObject.sortOnInit,
            };

            this.setOrdersData();
        },
        setOrdersData(page = 1) {
            SaveObjectToUrl({
                ...this.model,
                sort: this.sort,
                paginate: this.paginationModel.default,
                page: page,
            });

            this.ordersData = this.sortAndPaginateData(page);
        },
        sortAndPaginateData(page = 1) {
            const { sortBy, direction } = this.sort;

            const sortOrders = DynamicSort(
                clone(this.orders),
                sortBy,
                direction
            );

            return Paginate(
                sortOrders,
                page,
                this.paginationModel.default
            );
        },
        onPaginationLimitChangeEvent(value) {
            this.paginationModel.default = value;

            this.setOrdersData();
        },
        clearSearchModel() {
            this.model = {}

            const cleanUrl = window.location.href.split('?')[0];
            if (!!cleanUrl) {
                window.history.pushState({}, '', cleanUrl);
            }
        }
    },
}

</script>
