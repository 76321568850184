<script>
    export default {
        transactionsRoute: function(){
            return `/transaction`;
        },
        getTransactions: function(){
            return window.axios.get(`${this.transactionsRoute()}/get/payment-types`);
        },
        getTransactionById: function(id){
            return window.axios.get(`${this.transactionsRoute()}/${id}/show`);
        },
        getTransactionsExistingDates: function(){
            return window.axios.get(`/transactions/available/months`);
        }
    }
</script>