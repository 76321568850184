var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "row pb-3 mb-3 border-bottom justify-content-between" },
      [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("atbl-input", {
              staticClass: "m-0",
              attrs: {
                label: "Name",
                name: "name",
                "label-class": "font-weight-bold transform-none",
                errors: _vm.errors
              },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.model,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "model.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("atbl-input", {
              staticClass: "m-0",
              attrs: {
                label: "Surname",
                name: "surname",
                "label-class": "font-weight-bold transform-none",
                errors: _vm.errors
              },
              model: {
                value: _vm.model.surname,
                callback: function($$v) {
                  _vm.$set(
                    _vm.model,
                    "surname",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "model.surname"
              }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row pb-3 mb-3 border-bottom justify-content-between" },
      [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("birthday-picker", {
              staticClass: "font-weight-bold transform-none",
              attrs: { format: "YYYY-MM-DD" },
              model: {
                value: _vm.model.birthdate,
                callback: function($$v) {
                  _vm.$set(_vm.model, "birthdate", $$v)
                },
                expression: "model.birthdate"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Email",
                name: "email",
                "label-class": "font-weight-bold transform-none",
                errors: _vm.errors
              },
              model: {
                value: _vm.model.email,
                callback: function($$v) {
                  _vm.$set(
                    _vm.model,
                    "email",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "model.email"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Address 1",
                name: "address1",
                "label-class": "font-weight-bold transform-none",
                errors: _vm.errors
              },
              model: {
                value: _vm.model.address1,
                callback: function($$v) {
                  _vm.$set(
                    _vm.model,
                    "address1",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "model.address1"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Address 2",
                name: "address2",
                "label-class": "font-weight-bold transform-none",
                errors: _vm.errors
              },
              model: {
                value: _vm.model.address2,
                callback: function($$v) {
                  _vm.$set(
                    _vm.model,
                    "address2",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "model.address2"
              }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "row pb-3 mb-3 border-bottom transform-none justify-content-between"
      },
      [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("tel-number", {
              staticClass: "font-weight-bold transform-none",
              attrs: {
                label: "Mobile",
                id: "Mobile",
                placeholder: "Enter Mobile",
                name: "mobile",
                error: _vm.errors
              },
              model: {
                value: _vm.model.mobile,
                callback: function($$v) {
                  _vm.$set(_vm.model, "mobile", $$v)
                },
                expression: "model.mobile"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("tel-number", {
              staticClass: "font-weight-bold transform-none",
              attrs: {
                label: "Phone",
                id: "Phone",
                placeholder: "Enter Phone",
                name: "phone",
                error: _vm.errors
              },
              model: {
                value: _vm.model.phone,
                callback: function($$v) {
                  _vm.$set(_vm.model, "phone", $$v)
                },
                expression: "model.phone"
              }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-between" }, [
      _c(
        "div",
        { staticClass: "col-3" },
        [
          _c("atbl-input", {
            staticClass: "mb-1",
            attrs: {
              label: "City",
              name: "city",
              "label-class": "font-weight-bold transform-none",
              errors: _vm.errors
            },
            model: {
              value: _vm.model.city,
              callback: function($$v) {
                _vm.$set(
                  _vm.model,
                  "city",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model.city"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-3" },
        [
          _c("atbl-input", {
            staticClass: "mb-1",
            attrs: {
              label: "Postcode",
              name: "postcode",
              "label-class": "font-weight-bold transform-none",
              errors: _vm.errors
            },
            model: {
              value: _vm.model.postcode,
              callback: function($$v) {
                _vm.$set(
                  _vm.model,
                  "postcode",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model.postcode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-3" },
        [
          _c("atbl-input", {
            staticClass: "mb-1",
            attrs: {
              label: "State",
              "label-class": "font-weight-bold transform-none",
              name: "state",
              errors: _vm.errors
            },
            model: {
              value: _vm.model.state,
              callback: function($$v) {
                _vm.$set(
                  _vm.model,
                  "state",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model.state"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-3" },
        [
          _c("countries-options", {
            staticClass: "transform-none mb-1",
            attrs: { error: _vm.errors },
            model: {
              value: _vm.model.country,
              callback: function($$v) {
                _vm.$set(
                  _vm.model,
                  "country",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model.country"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }