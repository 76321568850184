var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-search" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "row add-dispositions-holder d-flex align-items-end" },
        [
          _c(
            "div",
            { staticClass: "col add-dispositions-holder-dropdown" },
            [
              _c("atbl-select", {
                staticClass: "m-0",
                attrs: {
                  value: _vm.model.status_id,
                  errors: _vm.modelErrors,
                  options: _vm.statuses,
                  disabled: !!_vm.isLoading,
                  label: "Status",
                  name: "status_id"
                },
                on: { update: _vm.updateStatusModelField }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-auto add-dispositions-holder-button" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    disabled: !_vm.model.status_id || !!_vm.isLoading,
                    type: "submit"
                  },
                  on: { click: _vm.checkAndSaveStatus }
                },
                [
                  !_vm.isLoading
                    ? _c("span", [
                        _vm._v(
                          "\n                        Save\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: _vm.isLoading, type: "smaller" }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-plus" }),
      _vm._v(" Add Status\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }