import MarketingService from '../../../../../services/MarketingService';
import removeUndefinedProperties from '../../../../../utils/Object/RemoveUndefinedProperties';

export default {
    provide() {
        return {
            addLeadToCampaign: this.addLeadToCampaign,
            removeLeadOutOfCampaign: this.removeLeadOutOfCampaign,
            changeInCampaignProperty: this.changeInCampaignProperty,
            toggleAddLeadsSidebar: this.toggleAddLeadsSidebar,
            reFetchData: this.reFetchData,
        };
    },
    data() {
        return {
            inCampaign: false,
            addLeadsSidebarShow: false,
            addLeadsSidebarLoading: false,
        };
    },
    methods: {
        async addLeadToCampaign(customer, campaignId, campaignType, allSelected = false) {
            this.addLeadsSidebarLoading = true;

            try {
                const model = {
                    customers_ids: Array.isArray(customer)
                        ? customer
                        : [customer.id],
                    campaign_id: campaignId,
                    inCampaign: this.inCampaign,
                    allSelected,
                    ...removeUndefinedProperties(this.leadsOutOfCampaignSearchModel),
                };

                const response = await MarketingService.addLeadById(campaignType, campaignId, model);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.addLeadsSidebarLoading = false;

                    return;
                }

                await this.reFetchData(campaignId);

                this.addLeadsSidebarLoading = false;

                return true;
            } catch (error) {
                this.addLeadsSidebarLoading = false;

                window.flashError(error);

                return false;
            }
        },
        async removeLeadOutOfCampaign(customer, campaignId, campaignType) {
            this.addLeadsSidebarLoading = true;

            try {
                const model = {
                    customersIds: Array.isArray(customer)
                        ? customer
                        : [customer.id],
                };

                const response = await MarketingService.removeCampaignLeads(campaignType, campaignId, model)
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.addLeadsSidebarLoading = false;

                    return;
                }

                await this.reFetchData(campaignId);

                this.addLeadsSidebarLoading = false;
            } catch (error) {
                this.addLeadsSidebarLoading = false;

                window.flashError(error);
            }
        },
        changeInCampaignProperty(value) {
            this.inCampaign = value;
        },
        toggleAddLeadsSidebar() {
            this.addLeadsSidebarShow = !this.addLeadsSidebarShow;
        },
    },
}