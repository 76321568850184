<template>
    <div class="card call-history-statistic-card multiple">
        <div :class="{
                 'p-0': !loader
             }"
             class="card-body"
        >
            <div v-if="!loader"
                 class="call-history-statistic-card-holder"
            >
                <div>
                    <h3>{{ title }}</h3>
                    <p>{{ description }}</p>
                </div>
                <div>
                    <div>
                        <h4>{{ leftTitle }}</h4>
                        <span>{{ leftValue }}</span>
                    </div>
                    <div>
                        <h4>{{ rightTitle }}</h4>
                        <span>{{ rightValue }}</span>
                    </div>
                </div>
            </div>
            <loader :show="loader"
                    type="smaller"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'call-history-statistic-multiple-card',
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: false
        },
        leftTitle: {
            type: String,
            required: true
        },
        leftValue: {
            required: true
        },
        rightTitle: {
            type: String,
            required: true
        },
        rightValue: {
            required: true
        },
        loader: {
            type: Boolean,
            default: false
        }
    }
}
</script>