import NamespaceConst from "../../NamespaceConst";

export default {
    mutations: {
        SET_SHOW_SIDEBAR: `setShowSidebar`,
        SET_SHOW_SIDEBAR_DESKTOP: `setShowSidebarDesktop`,
        SET_SHOW_SIDEBAR_MOBILE: `setShowSidebarMobile`,
        SET_MINIMIZE_SIDEBAR: `setMinimizeSidebar`,
        SET_LOCALE: `setLocale`,
        SET_LOCALE_CLEAR: `setLocaleClear`,
    },
    getters: {
        GET_SHOW_SIDEBAR: `getShowSidebar`,
        GET_MINIMIZE_SIDEBAR: `getMinimizeSidebar`,
        GET_LOCALE: `getLocale`,
    },
    actions: {
        // add actions
    }
};