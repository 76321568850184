<template>
    <div class="configuration-form mt-2">
        <span class="font-weight-bold text-secondary">{{ getResolvedKey }}</span>
        <div>
            <atbl-select :options="matchTypes"
                         v-model="modelKey"
                         class="mb-1"
                         name="key"
            />
            <input v-if="!!getKeyObject && getKeyObject.hasOwnProperty('min') && getKeyObject.hasOwnProperty('max')"
                   v-model="modelValue"
                   class="configuration-text-input"
                   :placeholder="`Number between ${getKeyObject.min} and ${getKeyObject.max}`"
                   :min="getKeyObject.min"
                   :max="getKeyObject.max"
                   type="number"
            />
            <button @click.prevent="onSaveEvent"
                    :disabled="!isValid"
            >
                <i class="fa fa-check"></i> Save
            </button>
            <button @click.prevent="onCloseEvent">
                <i class="fa fa-times"></i> Close
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RaffleConfigurationFormPrizeWinnerItem',
    emits: [
        'update',
    ],
    props: {
        numberType: {
            type: Object,
            default: () => ({
                key: null,
                title: null,
                editable: true,
            })
        },
        numberIndex: {
            required: true
        },
    },
    data() {
        return {
            matchTypes: [
                {
                    label: 'Aprox.',
                    value: 'a'
                },
                {
                    label: 'First',
                    value: 'f',
                    min: 1,
                    max: 4
                },
                {
                    label: 'Last',
                    value: 'l',
                    min: 1,
                    max: 4
                }
            ],
            model: {
                key: null,
                value: null,
            },
        };
    },
    computed: {
        modelKey: {
            get() {
                return this.model.key;
            },
            set(value) {
                this.model.key = value;

                if (value === 'a') {
                    this.model.value = null;
                }
            }
        },
        modelValue: {
            get() {
                return this.model.value;
            },
            set(value) {
                this.model.value = Number(value);
            }
        },
        getKeyObject() {
            return !!this.model.key
                ? this.matchTypes.find(item => item.value === this.model.key)
                : null;
        },
        getResolvedKey() {
            let key = this.model.key;
            let first = this.numberType.key;
            if (key === 'l' && this.model.value === 1) {
                key = 'r-';
                first = 'match';
            }

            let latest = !!this.model.value ? `${this.model.value}` : '';

            return `${first}${!!key ? `-${key}` : ''}${latest}`;
        },
        isValid() {
            const key = this.modelKey;
            const value = this.modelValue;

            if (!key) {
                return false;
            }

            if (!!key && key === 'a' && !value) {
                return true;
            }

            if (!!key && key !== 'a' && !value) {
                return false;
            }

            const { min, max } = this.getKeyObject;

            return value >= min && value <= max;
        },
    },
    methods: {
        onCloseEvent() {
            this.$emit('close');
        },
        onSaveEvent() {
            this.$emit('update', {
                key: this.getResolvedKey,
                title: `${this.numberType.title} - ${this.getResolvedKey}`,
                parent: this.numberType
            });
        },
    },
    unmounted() {
        this.model = {
            key: null,
            value: null,
        };
    }
}
</script>