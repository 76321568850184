<template>
    <sidebar-sub title="Create/Update Free Game Settings"
                 :value="show"
                 @input="closeForm"
                 :body-class="`${show ? 'pt-0': ''}`"
    >
        <h5 class="title">General</h5>
        <div class="row mt-3">
            <div class="col-12 col-lg-6 font-weight-bold">ID</div>
            <div class="col-12 col-lg-6">{{ model.id }}</div>
        </div>
        <hr />
        <div class="row mb-3">
            <div class="col-12 col-lg-6 font-weight-bold">Name</div>
            <div class="col-12 col-lg-6">{{ model.name }}</div>
        </div>
        <h5 class="title">Free games settings</h5>
        <div class="row mt-3">
            <div class="col-12">
                <atbl-select label="Range type"
                             name="range_type"
                             :options="rangeTypes"
                             :value="model.free_games_settings.range_type"
                             :errors="errors"
                             @update="updateRangeType"
                />
            </div>
        </div>
        <div v-if="!!model.free_games_settings.range_type"
             class="row"
        >
            <div class="col-12">
                <atbl-number label="Tickets"
                             name="tickets"
                             :value="model.free_games_settings.tickets"
                             :errors="errors"
                             :min="1"
                             @update="updateFreeGameSettingModel"
                />
            </div>
        </div>
        <div v-if="!!model.free_games_settings.range_type"
             class="row"
        >
            <div class="col-12">
                <atbl-number label="Lines (Up To)"
                             name="lines"
                             :value="model.free_games_settings.lines"
                             :errors="errors"
                             :min="0"
                             @update="updateFreeGameLines"
                />
            </div>
        </div>
        <div v-if="!!model.free_games_settings.range_type"
             class="row"
        >
            <div class="col-12">
                <atbl-number label="Fractions"
                             name="fractions"
                             :value="model.free_games_settings.fractions"
                             :errors="errors"
                             :min="0"
                             :max="10"
                             @update="updateFreeGameLines"
                />
            </div>
        </div>
        <div v-if="!!model.free_games_settings.range_type"
             class="row"
        >
            <div class="col-12">
                <atbl-number label="Shares"
                             name="shares"
                             :value="model.free_games_settings.shares"
                             :errors="errors"
                             :min="0"
                             @update="updateFreeGameLines"
                />
            </div>
        </div>
        <hr v-if="!!model.free_games_settings && !!model.free_games_settings.created_at" />
        <div v-if="!!model.free_games_settings && !!model.free_games_settings.created_at"
             class="row"
        >
            <div class="col-12 col-lg-6 font-weight-bold">Created At</div>
            <div class="col-12 col-lg-6">{{ model.free_games_settings.created_at }}</div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-outline-danger mr-2"
                      :disabled="loader"
                      @click="closeForm"
            >
                Cancel
            </button>

            <button class="btn btn-sm btn-primary"
                      :disabled="loader || !canSave"
                      @click="saveFreeGameSetting"
            >
                <span v-if="!loader">
                    Save
                </span>
                <loader :show="loader"
                        type="smaller"
                />
            </button>
        </template>
    </sidebar-sub>
</template>

<script>
export default {
    name: 'CreateUpdateFreeGameSettingForm',
    inject: [
        'toggleCreateUpdateFreeGamesForm',
        'clearCreateUpdateFreeGamesModel',
        'updateRangeType',
        'updateFreeGameSettingModel',
        'saveFreeGameSetting',
        'updateFreeGameLines',
    ],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loader: {
            type: Boolean,
            default: false
        },
        model: {
            type: Object,
            default: () => ({})
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        canSave: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            rangeTypes: [
                {
                    label: 'Daily',
                    value: 'daily'
                },
                {
                    label: 'Weekly',
                    value: 'weekly'
                },
                {
                    label: 'Monthly',
                    value: 'monthly'
                },
            ],
        };
    },
    methods: {
        closeForm() {
            this.clearCreateUpdateFreeGamesModel();
            this.toggleCreateUpdateFreeGamesForm();
        }
    }
}
</script>