var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.dispositions, function(item, index) {
            return !_vm.isLoading
              ? [
                  _c("div", { key: index, staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg-2 align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Id")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.id) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Agent")
                        ]),
                        _vm._v(" "),
                        item.user && item.user.name.length > 0
                          ? _c("span", { staticClass: "badge badge-success" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.user.name) +
                                  "\n                        "
                              )
                            ])
                          : _c("span", [_vm._v("/")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Name")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.status.name) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Date")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.utcToLocal(item.created_at)) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                ]
              : _vm._e()
          }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: {
                  items: _vm.dispositions,
                  msg: "No dispositions found."
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        2
      )
    ]),
    _vm._v(" "),
    !_vm.isLoading && _vm.dispositionsResponse.last_page > 1
      ? _c("div", { staticClass: "card-footer overflow-x-auto" }, [
          _c(
            "nav",
            { staticClass: "wrap" },
            [
              _c("atbl-pagination", {
                attrs: { pagination: _vm.dispositionsResponse },
                on: { paginate: _vm.fetchDispositions }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header d-flex align-items-center" }, [
      _c("i", { staticClass: "nav-icon fa fa-clock mr-1" }),
      _vm._v(" Statuses\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-2" }, [_vm._v("Id")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Agent")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Date")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }