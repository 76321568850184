var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card m-0 py-3" }, [
    _vm.$can("Payon - Virtual Deposit Agent")
      ? _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("agent-picker", {
                  model: {
                    value: _vm.agent,
                    callback: function($$v) {
                      _vm.agent = $$v
                    },
                    expression: "agent"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Amount",
                    name: "amount",
                    errors: _vm.errors,
                    min: 0.0,
                    step: 0.01,
                    value: _vm.amount
                  },
                  on: { update: _vm.updateAmount }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("atbl-select", {
                  attrs: {
                    options: _vm.reasonList,
                    label: "Reason",
                    name: "reason"
                  },
                  on: { update: _vm.onReasonChange }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c(
                  "atbl-form-group",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { label: "Reference", "label-for": "reference" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reference,
                          expression: "reference"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "reference",
                        type: "text",
                        placeholder: "Enter reference"
                      },
                      domProps: { value: _vm.reference },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.reference = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c(
                  "atbl-form-group",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { label: "Last 4", "label-for": "last4" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.last4,
                          expression: "last4"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "last4",
                        type: "text",
                        placeholder: "Enter last 4 digits"
                      },
                      domProps: { value: _vm.last4 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.last4 = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("atbl-check-box", {
                  staticClass: "atbl-checkbox pb-2",
                  attrs: {
                    value: _vm.isFailedTransaction,
                    label: "Create failed transaction"
                  },
                  on: {
                    input: function($event) {
                      _vm.isFailedTransaction = !_vm.isFailedTransaction
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  class: {
                    "btn-primary": !_vm.getPaymentLoader,
                    "btn-outline-primary": _vm.getPaymentLoader
                  },
                  attrs: { disabled: _vm.isSaveEnabled },
                  on: {
                    click: function($event) {
                      return _vm.setCreditConfirmModal(true)
                    }
                  }
                },
                [
                  !_vm.getPaymentLoader
                    ? _c("span", [_vm._v("Add")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { type: "smaller", show: _vm.getPaymentLoader }
                  })
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }