<template>
    <div class="card card-list">
        <div class="card-header">
            <div>
                <i class="fa fa fa-align-justify"></i> Lines ({{ items.length }})
            </div>
            <div>
                <sort-by v-model="sortByModel"
                         :options="sortByOptions"
                         class="mr-2"
                />
            </div>
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="row">
                    <div class="col-12 col-lg">
                        <atbl-check-box :value="isAllSelected"
                                        :partial-check="isPartialSelected"
                                        @input="selectLines(allLinesIds, $event)"
                        />
                    </div>
                    <div class="col-12 col-lg">
                        Ticket
                    </div>
                    <div class="col-12 col-lg">
                        Winnings
                    </div>
                    <div class="col-12 col-lg">
                        Matches
                    </div>
                    <div class="col-12 col-lg">
                        Is processing
                    </div>
                </div>

                <div v-for="(item, index) in winningLines"
                     :key="index"
                     class="row"
                >
                    <div class="col-12 col-lg mb-2 mb-lg-0">
                        <atbl-check-box :checked="selectedItems.includes(item.id)"
                                        :value="item.id"
                                        :disabled="!!item.is_processing"
                                        @input="selectLines(item.id, $event)"
                        />
                    </div>
                    <div class="col-12 col-lg">
                        <span class="small-mb-badge">Ticket</span>
                        <a :href="getTicketUrl(item)"
                           target="_blank"
                        >
                            #{{item.ticket_id}}
                        </a>
                    </div>
                    <div class="col-12 col-lg">
                        <span class="small-mb-badge">Winnings</span>
                        {{ item.winnings.toFixed(2) }}
                    </div>
                    <div class="col-12 col-lg">
                        <span class="small-mb-badge">Matches</span>
                        <span v-if="!item.matches || !item.matches.length">
                            /
                        </span>
                        <template v-else>
                            <span v-for="(match, index) in item.matches"
                                  :key="index"
                                  class="badge badge-primary mr-2"
                            >
                                {{ match }}
                            </span>
                        </template>
                    </div>
                    <div class="col-12 col-lg">
                        <span class="small-mb-badge">Is processing</span>
                        <i class="fa"
                           :class="{
                                'fa-check text-success': !!item.is_processing,
                                'fa-times text-danger': !item.is_processing
                           }"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AtblCheckBox from '../../../../../components/Forms/Inputs/AtblCheckbox.vue';
import SortBy from '../../../../../components/SortBy/SortBy.vue';
import dynamicSort from '../../../../../utils/Array/DynamicSort';
import GameableModels from '../../../../../constants/GameableModels';

export default {
    name: 'DrawReviewGroupItem',
    inject: [
        'selectLines',
    ],
    components: {
        AtblCheckBox,
        SortBy,
    },
    props: {
        items: {
            type: Array,
            default: () => ({})
        },
        selectedItems: {
            type: Array,
            default: () => ({})
        },
    },
    data() {
        return {
            sortByOptions: [
                {
                    value: 'winnings',
                    label: 'Winnings',
                },
                {
                    value: 'ticket_id',
                    label: 'Ticket',
                },
            ],
            sortByModel: {
                direction: 'desc',
                column: 'winnings',
            },
        };
    },
    computed: {
        winningLines() {
            return dynamicSort(this.items, this.sortByModel.column, this.sortByModel.direction);
        },
        notProcessedLinesOnly() {
            return this.items.filter(item => !item.is_processing);
        },
        allLinesIds() {
            return this.notProcessedLinesOnly.map(item => item.id);
        },
        isAllSelected() {
            return this.selectedItems.length === this.allLinesIds.length;
        },
        isPartialSelected() {
            return !!this.selectedItems.length && !this.isAllSelected;
        },
    },
    methods: {
        getTicketUrl(line) {
            const gameableType = line.gameable_type;
            const ticketId = line.ticket_id;

            switch (gameableType) {
                case GameableModels.SyndicateShare:
                    return `/syndicate-shares/${ticketId}`;
                case GameableModels.RaffleTicket:
                    return `/raffle-tickets/${ticketId}`;
                case GameableModels.TicketLine:
                    return `/ticket/${ticketId}`;
            }
        },
    },
}
</script>