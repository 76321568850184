var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card player-nav" }, [
    _c("div", { staticClass: "card-body" }, [
      _vm.$canAny(_vm.otherTop) || _vm.$canAny(_vm.manualWallet)
        ? _c(
            "div",
            { staticClass: "player-nav-group" },
            [
              _vm.$can("Player - Place A Bet")
                ? _c(
                    "a",
                    {
                      staticClass: "player-nav-group-button btn btn-primary",
                      attrs: { href: "/players/" + _vm.id + "/play" }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-play",
                        attrs: { "aria-hidden": "false" }
                      }),
                      _vm._v(" PLAY\n\t\t\t")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Payon - Funds Deposit Agent")
                ? _c(
                    "a",
                    {
                      staticClass: "player-nav-group-button btn btn-success",
                      attrs: { href: "/players/" + _vm.id + "/funds" }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-wallet",
                        attrs: { "aria-hidden": "false" }
                      }),
                      _vm._v(" ADD FUNDS\n\t\t\t")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$canAny(_vm.manualWallet)
                ? _c(
                    "a",
                    {
                      staticClass: "player-nav-group-button btn btn-warning",
                      attrs: { href: "/players/" + _vm.id + "/manage/wallet" }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-wallet",
                        attrs: { "aria-hidden": "false" }
                      }),
                      _vm._v(" MANAGE WALLET\n\t\t\t")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - Send Email")
                ? _c("send-email-component", { attrs: { client: _vm.player } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$canAny(_vm.crm)
        ? _c("div", { staticClass: "player-nav-group" }, [
            _c(
              "span",
              { staticClass: "navbar-text border-bottom font-weight-bold" },
              [_vm._v("CRM")]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "nav flex-column" }, [
              _vm.$can("Player - View Details")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.view") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/view" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tProfile\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Notes")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.notes") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/note" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tNotes\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Messages")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.messages") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/message" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tMessages\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Dispositions")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isRoute("agent.players.dispositions")
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "/players/" + _vm.id + "/dispositions"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tDispositions\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Credit Cards")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.cards") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/cards" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tCredit Cards\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Call History")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isRoute("agent.players.call-history")
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "/players/" + _vm.id + "/call-history"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tCall History\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("KYC - Manage KYC")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("players.kyc-list") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/kyc/list" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tKYC\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Login History")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isRoute("agent.players.login-history")
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "/players/" + _vm.id + "/login-history"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tLogin History\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - Agents History View")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isRoute("agent.players.agents-history")
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "/players/" + _vm.id + "/agents-history"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tAgents History\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - Free Games View")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.free-games") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/free-games" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tFree Games\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - Blocks View")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("players.blocks.view") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/player/" + _vm.id + "/blocks" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tBlocks\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$canAny(_vm.play)
        ? _c("div", { staticClass: "player-nav-group" }, [
            _c(
              "span",
              { staticClass: "navbar-text border-bottom font-weight-bold" },
              [_vm._v("REPORTS")]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "nav flex-column" }, [
              _vm.$can("Player - View Tickets")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.tickets") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/tickets" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tTickets\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Tickets")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.raffles") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/raffles" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tRaffles\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Tickets")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isRoute("agent.players.syndicate-shares")
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "/players/" + _vm.id + "/syndicate-shares"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tSyndicate Shares\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Tickets")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isRoute("agent.players.bundle-tickets")
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "/players/" + _vm.id + "/bundle-tickets"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tBundle Tickets\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Orders")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.orders") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/orders" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tOrders\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - View Transactions")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isRoute("agent.players.transactions")
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "/players/" + _vm.id + "/transactions"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tTransactions\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player - Withdrawals")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("player.with.requests") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/withdrawals" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tWithdrawals\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Player Player Bonuses - Overview")
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { active: _vm.isRoute("agent.players.") }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/players/" + _vm.id + "/bonuses" }
                        },
                        [_vm._v("\n\t\t\t\t\t\tBonuses\n\t\t\t\t\t")]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$canAny(_vm.marketing)
        ? _c("div", { staticClass: "player-nav-group" }, [
            _c(
              "span",
              { staticClass: "navbar-text border-bottom font-weight-bold" },
              [_vm._v("MARKETING")]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "nav flex-column" }, [
              _vm.$can("Player - View Campaigns")
                ? _c("li", { staticClass: "nav-item" }, [
                    _c("a", { staticClass: "nav-link", attrs: { href: "#" } }, [
                      _vm._v("\n\t\t\t\t\t\tCampaign\n\t\t\t\t\t")
                    ])
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }