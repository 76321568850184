var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "keypad",
      attrs: { tabindex: "0" },
      on: { keydown: _vm.dialNumber }
    },
    [
      _c("div", { staticClass: "keypad-number" }, [
        _c("div", { staticClass: "keypad-input-holder" }, [
          !_vm.callStarted
            ? _c("input", {
                staticClass: "keypad-input",
                attrs: { type: "tel", disabled: "" },
                domProps: { value: _vm.number }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.numberDigits,
                    expression: "numberDigits"
                  }
                ],
                staticClass: "keypad-input-digits",
                attrs: {
                  type: "tel",
                  placeholder: "Dial digits",
                  disabled: ""
                },
                domProps: { value: _vm.numberDigits },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.numberDigits = $event.target.value
                  }
                }
              })
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: "Reset phone number."
                },
                expression:
                  "{\n               content: 'Reset phone number.'\n           }"
              }
            ],
            staticClass: "clear-number",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.resetNumber.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n            RESET\n        ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: "Press ESC to clear"
                },
                expression:
                  "{\n               content: 'Press ESC to clear'\n           }"
              }
            ],
            staticClass: "clear-number",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.clearNumber.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n            CLEAR\n        ")]
        ),
        _vm._v(" "),
        !_vm.callStarted
          ? _c(
              "button",
              {
                staticClass: "backspace",
                attrs: { type: "button" },
                on: { mousedown: _vm.backspace }
              },
              [
                _c("img", {
                  attrs: {
                    src: "/img/icons/backspace.svg",
                    alt: "backspace-icon"
                  }
                })
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "keypad-wrapper", class: _vm.wrapperSize },
        _vm._l(_vm.dialPadPattern, function(numbers, index) {
          return _c(
            "div",
            { key: index, staticClass: "keypad-wrapper-numbers" },
            _vm._l(numbers, function(number, numberIndex) {
              return _c(
                "button",
                {
                  key: numberIndex,
                  staticClass: "keypad-button",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.dialClick(number)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(number) +
                        "\n                "
                    )
                  ])
                ]
              )
            }),
            0
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }