<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header">
                <i class='fa fa-users'></i> Players
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-2">
                            Agent
                        </div>
                        <div class="col-6 col-lg col-6 col-lg">
                            Today
                        </div>
                        <div class="col-6 col-lg">
                            Current Week
                        </div>
                        <div class="col-6 col-lg">
                            Current Month
                        </div>
                        <div class="col-6 col-lg">
                            Last 3 Months
                        </div>
                        <div class="col-6 col-lg">
                            Last 6 Months
                        </div>
                        <div class="col-6 col-lg">
                            Last Year
                        </div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(item, index) in reports"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-lg-2 text-center text-lg-left">
                                <span class="text-primary">#{{ item.id }}</span> {{ item.name }}
                            </div>
                            <div class="col-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Today</span>
                                <div class="d-flex justify-content-center justify-content-lg-left">
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-success"></i> {{ item.today_deposits }}
                                    </div>
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-danger"></i> {{ item.today - item.today_deposits }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Current Week</span>
                                <div class="d-flex justify-content-center justify-content-lg-left">
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-success"></i> {{ item.current_week_deposits }}
                                    </div>
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-danger"></i> {{ item.current_week - item.current_week_deposits }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Current Month</span>
                                <div class="d-flex justify-content-center justify-content-lg-left">
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-success"></i> {{ item.current_month_deposits }}
                                    </div>
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-danger"></i> {{ item.current_month - item.current_month_deposits }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Last 3 Months</span>
                                <div class="d-flex justify-content-center justify-content-lg-left">
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-success"></i> {{ item.last_three_months_deposits }}
                                    </div>
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-danger"></i> {{ item.last_three_months - item.last_three_months_deposits }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Last 6 Months</span>
                                <div class="d-flex justify-content-center justify-content-lg-left">
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-success"></i> {{ item.last_six_months_deposits }}
                                    </div>
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-danger"></i> {{ item.last_six_months - item.last_six_months_deposits }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Last Year</span>
                                <div class="d-flex justify-content-center justify-content-lg-left">
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-success"></i> {{ item.last_year_deposits }}
                                    </div>
                                    <div class="flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0">
                                        <i class="fa fa-wallet text-danger"></i> {{ item.last_year - item.last_year_deposits }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />
                    <not-found v-if="!isLoading"
                               msg="No reports data found"
                               :items="reports"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ReportPlayers',
        data () {
            return {
                isLoading: false,
                reports: []
            };
        },
        methods: {
            getPlayersReports() {
                this.isLoading = true;
                window.axios.get("/reports/players/list")
                    .then(response => {
                        this.reports = response.data;
                    })
                    .catch(window.flashError)
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        created () {
            this.getPlayersReports();
        }
    }
</script>