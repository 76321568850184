import { isNumber, sortBy } from 'lodash';

/**
 * Sort array by dynamic sort values
 *
 * Example: items: [....], sortObject: { sort: 'id', value: 'ASC' }
 *
 * @param items
 * @param key
 * @param direction
 * @return {*}
 */
export default (items, key, direction = 'ASC') => {
    const isDescending = direction.toUpperCase() === "DESC";

    const withProp = items.filter((o) => o[key] !== null);
    const withoutProp = items.filter((o) => o[key] === null);

    const results = sortBy(withProp, item => {
        const value = item[key];

        if(isNumber(value)) {
            return value;
        }

        const aValueDate = Date.parse(value);

        if (!aValueDate) {
            return value;
        }

        return new Date(value).getTime();
    });

    const sorted = isDescending
        ? results.reverse()
        : results;

    return [
        ...sorted,
        ...withoutProp,
    ];
}