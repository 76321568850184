<template>
    <div class="container-fluid">
        <AgentDepositFilterForm @modelChange="modelChange" />

        <div class="card card-list">
            <div class="card-header">
                <i class='fa fa-users'></i> Agents ({{ model.year }})
            </div>

            <div class="card-body">
                <AgentDepositList :isSearching="isSearching"
                                  :year="model.year"
                                  :agents="agents"
                                  @open-agent="openAgent"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import AgentsFilterForm from "./Forms/AgentsFilterForm";
import AgentsList from "./Sections/AgentsList";
import AgentDepositFilterForm from "./Forms/AgentDepositFilterForm";
import AgentDepositList from "./Sections/AgentDepositList";

export default {
    name: "agent-deposits",

    components: {
        AgentDepositList,
        AgentDepositFilterForm,
        AgentsFilterForm,
        AgentsList
    },

    data() {
        return {
            agentDepositsUrl: "/reports/agents/deposits/list",
            model: {
                year: moment().year(),
            },
            agents: [],
            isSearching: false
        };
    },

    mounted() {
        this.getAgentDepositAndSalesReports();
    },

    methods: {
        getAgentDepositAndSalesReports() {
            if (this.isSearching) {
                return;
            }

            this.isSearching = true;

            window.axios.get(this.agentDepositsUrl, { params: this.model }).then((response) => {
                this.agents = response.data;
                this.isSearching = false;
            });
        },

        modelChange: function (value) {
            this.model = value;
            this.getAgentDepositAndSalesReports();
        },

        openAgent: function (agent) {
            window.open(`/reports/agents/agent/${agent.id}/edit?${this.buildQuery({ filterDate: 'year' })}`);
        }
    },
}
</script>