<template>
    <atbl-select :label="null"
                 :options="shifts"
                 :disabled="isLoading"
                 :value="value"
                 firstOptionLabel="Select shift"
                 name="shift_id"
                 @update="changed"
    />
</template>

<script>
import ShiftsService from '../../../services/ShiftsService';

export default {
    name: 'shifts-picker',
    props: {
        value: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            shifts: [],
            isLoading: false,
        };
    },
    async created() {
        await this.fetchShifts();
    },
    methods: {
        async fetchShifts() {
            this.isLoading = true;

            try {
                const response = await ShiftsService.fetchShiftsDropDownList();

                this.shifts = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        changed(name, value) {
            const shiftId = !value ? null : Number(value);

            this.$emit('input', shiftId);
            this.$emit('update', name, shiftId);
        }
    },
}
</script>