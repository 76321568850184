<template>
	<div class="container-fluid">
		<AgentsDetailsCards :agent="agentSales"
		                    :deposits="true"
		                    :bonus="true"
		                    :isLoading="isLoading"
		                    :search-model="model"
		/>
		<div class="card card-list mt-3">
			<div class="card-header card-header-atbl">
				<i class='fa fa-align-justify'></i> Details: <b>{{ agent.name }}</b>
			</div>
			
			<div class="card-body">
				<section v-if="!isLoading && !!transactions.length"
				         class="general-list"
				>
					<div class="row sticky-element general-list-row d-none d-lg-flex">
						<div class="col">Player Name</div>
						<div class="col">Transaction</div>
						<div class="col">Type</div>
						<div class="col">Order</div>
						<div class="col">Sales</div>
						<div class="col">Deposits</div>
						<div class="col">Bonus</div>
					</div>
					
					<template v-for="(transaction, index) in transactions">
						<div class="row general-list-row p-0" :key="index">
							<div class="col-6 col-lg p-2">
								<span class="small-mb-badge">Player Name</span>
								<a :href="`/players/${transaction.player_id}/view`" target="_blank">
									{{ transaction.player_name }} {{ transaction.player_surname }}
								</a>
							</div>
							<div class="col-6 col-lg p-2">
								<span class="small-mb-badge">Transaction</span>
								<a :href="`/transaction/${transaction.id}`" target="_blank">
									#T-{{ transaction.id }}
								</a>
							</div>
							<div class="col-6 col-lg p-2">
								<span class="small-mb-badge">Type</span>
								<payment-type-badge :payment-type="transaction.payment_type"
								                    :payment-type-beauty="transaction.payment_type_beauty"
								/>
							</div>
							<div class="col-6 col-lg p-2">
								<span class="small-mb-badge">Order</span>
								<template v-if="!!transaction.order_id">
									<a :href="`/orders/${transaction.order_id}`" target="_blank">
										#O-{{ transaction.order_id }}
									</a>
								</template>
								<span v-else>/</span>
							</div>
							<div class="col-6 col-lg p-2">
								<span class="small-mb-badge">Sales</span>
								<template v-if="$options.Transactions.isSale(transaction.payment_type)">
									<span v-html="currencyNumber(transaction, 'amount')"/>
								</template>
								<span v-else>/</span>
							</div>
							<div class="col-6 col-lg bg-primary-20 p-2 border-right border-color-white">
								<span class="small-mb-badge">Deposits</span>
								<template v-if="$options.Transactions.isDeposit(transaction.payment_type)">
									<span v-html="currencyNumber(transaction, 'amount')"/>
								</template>
								<span v-else>/</span>
							</div>
							<div class="col-6 col-lg p-2 border-right">
								<span class="small-mb-badge">Bonus</span>
								<template v-if="$options.Transactions.isBonus(transaction.payment_type)">
									<span v-html="currencyNumber(transaction, 'amount')"/>
								</template>
								<span v-else>/</span>
							</div>
						</div>
					</template>
				</section>
				
				<items-not-found v-if="!isLoading"
				                 :items="transactions"
				                 msg="No transactions found."
				/>
				
				<loader :show="isLoading"
				        type="small"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import AgentsDetailsCards from './Sections/AgentsDetailsCards';
	import AgentsFilterForm from './Forms/AgentsFilterForm';
	import Transactions from '../../../constants/Transactions';
	import Loader from "../../../components/Widgets/Loader.vue";
	import ItemsNotFound from "../../../components/Lists/ItemsNotFound.vue";
	import PaymentTypeBadge from "../../../components/Transactions/PaymentTypeBadge.vue";
	
	export default {
		name: 'agents-details',
		Transactions,
		components: {
			AgentsDetailsCards,
			AgentsFilterForm,
			Loader,
			ItemsNotFound,
			PaymentTypeBadge,
		},
		props: {
			id: {
				type: Number
			},
			model: {
				type: Object
			},
		},
		data() {
			return {
				agentSales: {},
				agent: {},
				transactions: [],
				isLoading: true,
			};
		},
		async created() {
			await this.getAgentDetails();
		},
		methods: {
			async getAgentDetails() {
				const response = await window.axios.get(`/reports/agents/${this.id}/get`, {params: this.model});
				
				const agent = response.data;
				const {transactions} = agent;
				
				this.agent = agent;
				this.transactions = transactions;
				
				this.agentSales = {
					id: agent.id,
					total: this.getTransactionsAmountTotal(transactions, Transactions.getTicketPaymentTypes()),
					deposits: this.getTransactionsAmountTotal(transactions, Transactions.depositTransactionTypes()),
					commission: Number(agent.commission.commission_payout),
					bonus: this.getTransactionsAmountTotal(transactions, Transactions.bonusTransactionTypes())
				};
				
				this.isLoading = false;
			},
			getTransactionsAmountTotal(transactions, paymentTypes) {
				if (!transactions) {
					return 0;
				}
				
				const transactionsTmp = !paymentTypes
				  ? transactions
				  : transactions.filter(item => paymentTypes.includes(item.payment_type));
				
				return transactionsTmp.reduce((sum, next) => sum + Number(next.amount), 0);
			},
		},
	}
</script>