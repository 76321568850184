var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Remove Setting",
        show: _vm.openModal,
        "close-on-backdrop": false
      },
      on: {
        close: function($event) {
          _vm.openModal = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex w-100 justify-content-end" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-danger mr-2",
                    on: { click: _vm.handleCancel }
                  },
                  [_vm._v("\n                Close\n            ")]
                ),
                _vm._v(" "),
                _vm.$can("Settings - Remove")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn",
                        class: {
                          "btn-primary": !_vm.isLoading,
                          "btn-outline-primary": _vm.isLoading,
                          disabled: _vm.isLoading
                        },
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.handleOK }
                      },
                      [
                        !_vm.isLoading
                          ? _c("span", [
                              _vm._v(
                                "\n                        Save\n                    "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { type: "small", show: _vm.isLoading }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm._v(
            "\n            Are you sure you want to remove this setting.\n        "
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }