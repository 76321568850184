export default {
	computed: {
		cartItems: function () {
			return this.getCartData.items.length > 0 ?
				this.getCartData.items.map(item => {
					return {
						...item,
						item: Array.isArray(item.item) ? item.item : JSON.parse(item.item)
					};
				}) :
				[]
		},
		getCartItemsStatistics: function () {
			const price = this.getCartData.items.length > 0 ?
				this.getCartData.items.reduce((sum, item) => {
					return sum + item.total_price;
				}, 0) :
				0;

			const lines = this.getCartData.items.length > 0 ?
				this.getCartData
					.items
					.filter(i => {
						const item = JSON.parse(i.item);
						return item.type !== 'syndicate' && item.type !== 'bundle';
					})
					.reduce((sum, item) => {
						const orderItem = Array.isArray(item.item) ? item.item : JSON.parse(item.item);
						return sum + orderItem.lines.length;
				}, 0) :
				0;

			return {
				lines: lines,
				price: price > 0 ? price.toFixed(2) : 0
			};
		},
		getAmountToPay: function() {
			return this.getCartData.items.length > 0 ? Number(this.getCartItemsStatistics.price) : this.getCartTotal;
		}
	},
	methods: {
		checkoutAction: function (price) {
			this.setShowGame(false);
			this.setCheckout({
				checkout: true,
				cartTotal: price
			});

			if(!this.isRoute("player.play.view")){
				window.location.href = `/players/${this.getPlayerId}/play?checkout=true`;
			}

			this.closeCartWidget();
		},
		closeCartWidget: function(){
			let cartWidget = document.getElementById("cartWidget"),
				cartWidgetMenu = cartWidget.querySelector(".dropdown-menu");

			if(cartWidget.classList.contains("show") && cartWidgetMenu.classList.contains("show")) {
				cartWidget.classList.remove("show");
				cartWidgetMenu.classList.remove("show");
			}
		},
		removeCartItem: function(orderItemId){
			let _vm = this;

			_vm.removeCartItemAction(orderItemId)
				.then(() => {
					_vm.setCheckout({
						checkout: _vm.getCheckout && (_vm.getCartData.items.length > 0),
						cartTotal: _vm.getCartItemsStatistics.price
					});
				});
		},
		closeCheckout: function () {
			this.setCheckout({
				checkout: false,
				cartTotal: 0
			});
		},
		getGameType (item) {
			if (item.item.type !== 'syndicate' && item.item.type !== 'bundle') {
				return 'lottery';
			}

			if(item.item.type === 'bundle') {
				return 'bundle';
			}

			if(item.item.type === 'syndicate') {
				return 'syndicate';
			}

			throw new Error('Unsupported game type');
		}
	}
};