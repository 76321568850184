var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "card card-list" }, [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("sort-by", {
                staticClass: "mr-2",
                attrs: { options: _vm.sortByOptions },
                model: {
                  value: _vm.sortBy,
                  callback: function($$v) {
                    _vm.sortBy = $$v
                  },
                  expression: "sortBy"
                }
              }),
              _vm._v(" "),
              _vm.$can("Roles - Create/Update")
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { href: "/roles/create" }
                    },
                    [_vm._v("\n                    Create\n                ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-1" }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [
                _vm._v("Permissions")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg-1" }, [_vm._v("Users")]),
              _vm._v(" "),
              _vm.$canAny(_vm.rolesOperationPermissions)
                ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                    _vm._v(
                      "\n                        Operations\n                    "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm._l(_vm.rolesList, function(item, index) {
              return !_vm.isLoading
                ? _c("div", { key: index, staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-lg-1 mb-1 mb-lg-0" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Name")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.name) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Permissions")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        _vm._l(item.permissions, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "badge badge-primary mr-1"
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.name) +
                                  "\n                                "
                              ),
                              _c(
                                "span",
                                { staticClass: "badge badge-light ml-1" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item.count) +
                                      "\n                                "
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg-1 mb-1 mb-lg-0" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Permissions")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.users_count) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.$canAny(_vm.rolesOperationPermissions)
                      ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Operations")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm.$can("Roles - Create/Update")
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-link btn-delete btn-sm mr-2",
                                      attrs: {
                                        href: "/roles/" + item.id + "/update"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-pencil-alt"
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$can("Roles - Remove")
                                ? _c("remove-list-item-button", {
                                    attrs: { value: item.id },
                                    on: { confirmed: _vm.onDeleteRoleEvent }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("items-not-found", {
                  attrs: { items: _vm.roles, msg: "No roles found" }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-user-shield" }),
      _vm._v(" Roles")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }