var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-search" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-4 mb-2 m-lg-0" },
              [
                _c("atbl-switch", {
                  attrs: {
                    off: false,
                    on: true,
                    name: "disabledOnly",
                    label: "Show disabled only syndicates"
                  },
                  model: {
                    value: _vm.disabledOnly,
                    callback: function($$v) {
                      _vm.disabledOnly = $$v
                    },
                    expression: "disabledOnly"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary",
              attrs: { type: "submit" },
              on: { click: _vm.fetchSyndicates }
            },
            [
              _c("i", { staticClass: "fa fa-search" }),
              _vm._v(" Search\n            ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between align-items-center"
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm.$can("Syndicates - Create/Update")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    on: { click: _vm.openCreateModal }
                  },
                  [_vm._v("\n                Create\n            ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(2),
              _vm._v(" "),
              _vm._l(_vm.syndicates, function(syndicate) {
                return [
                  _c("div", { key: syndicate.id, staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm.$can("Syndicates - View List")
                        ? _c(
                            "a",
                            {
                              staticClass: "d-block",
                              attrs: {
                                href:
                                  "/syndicates/items/" +
                                  syndicate.active_syndicate_item_id +
                                  "/edit"
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-link" }),
                              _vm._v(
                                " " +
                                  _vm._s(syndicate.active_syndicate_item_id) +
                                  "\n                            "
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              "\n                                Id: " +
                                _vm._s(syndicate.active_syndicate_item_id) +
                                "\n                            "
                            )
                          ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(syndicate.title) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("img", {
                        staticStyle: { width: "60px", height: "auto" },
                        attrs: {
                          src: _vm.$imageService.gameImage(syndicate.game.image)
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(syndicate.lines_count) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            syndicate.active_syndicate_item
                              .active_syndicate_item_draw.available_shares
                          ) +
                          " / " +
                          _vm._s(syndicate.active_syndicate_item.total_shares) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm._f("formatCurrency")(syndicate.share_price)
                          ) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            syndicate.active_syndicate_item
                              .active_syndicate_item_draw.cut_off_at
                          ) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(syndicate.is_recurring ? "Yes" : "No") +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col text-center" }, [
                      _vm.$can("Syndicates - Create/Update")
                        ? _c(
                            "a",
                            {
                              staticClass: "d-block",
                              attrs: {
                                href:
                                  "/syndicates/items/" +
                                  syndicate.active_syndicate_item_id +
                                  "/edit"
                              }
                            },
                            [_vm._m(3, true)]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              }),
              _vm._v(" "),
              !_vm.isLoading && !_vm.syndicates.length
                ? _c("not-found", {
                    attrs: { msg: "No syndicates found", items: _vm.syndicates }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isCreateModalVisible && _vm.$can("Syndicates - Create/Update")
        ? _c("syndicates-create-modal", {
            on: {
              cancel: function($event) {
                _vm.isCreateModalVisible = false
              },
              confirm: _vm.storeSyndicate
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", {
        staticClass: "fa fa-search",
        attrs: { "aria-hidden": "false" }
      }),
      _vm._v(" Syndicate filter\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-exchange-alt" }),
      _vm._v(" Syndicates\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Reference")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Title")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Game")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Lines")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Shares")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Share price")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Cut off at")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Recurring")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted text-center" }, [
        _vm._v("Action")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" Edit syndicate")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }