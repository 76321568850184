export default {
    provide() {
        return {
            onDeletePermission: this.onDeletePermission,
        };
    },
    data() {
        return {
            deletePermissionLoader: false,
        };
    },
    methods: {
        async onDeletePermission(id, redirect = null) {
            if (!this.$can('Permissions - Remove')) {
                return window.showMessage('No permissions for this action', false);
            }

            this.deletePermissionLoader = true;

            try {
                const response = await window.axios.delete(`/permissions/${id}`);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.deletePermissionLoader = false;

                    return;
                }

                if (!!redirect) {
                    window.location.href = redirect;

                    return;
                }

                this.deletePermissionLoader = false;

                await this.fetchPermissions(this.page);
            } catch (error) {
                this.deletePermissionLoader = false;

                window.flashError(error);
            }
        },
    },
}