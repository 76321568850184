<template>
    <atbl-modal title="Remove Attachment" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <p>You are about to decline this attachment.</p>
                <p>Please leave a message if there is need to.</p>
            </div>

            <div class="col-12">
                <atbl-textarea label="Message" placeholder="Place a message" :rows="3" v-model="message" />
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-danger" @click="confirm()">
                Decline
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'decline-kyc-attachment-modal',
    components: {
		AtblModal,
    },
    data() {
        return {
            message: null,
        };
    },

    methods: {
        emitCloseEvent() {
            this.$emit('cancel');
        },

        confirm() {
            this.$emit('confirm', this.message);
        },
    }
}
</script>