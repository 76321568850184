<template>
    <form @submit.prevent="search">
        <div class="card card-search">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i class="fa fa-search" aria-hidden="false"></i> Search
                </div>
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <atbl-form-group id="date"
                                         label="Date"
                                         label-for="Date"
                                         class="font-weight-bold"
                        >
                            <multi-date-picker v-model="createdAt"
                                               format="YYYY-MM-DD"
                                               :show-ranges="true"
                                               :auto-apply="true"
                            />
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <payment-types-select v-model="paymentType"/>
                    </div>
                    <div class="col-12 col-md-4">
                        <atbl-input label="Transaction ID"
                                    name="transaction"
                                    placeholder="Enter transaction id"
                                    v-model="filters.transaction"
                        />
                    </div>
                    <div class="col-12 col-lg-4">
                        <atbl-select :options="transactionStatuses"
                                     v-model="filters.transactionStatus"
                                     name="transactionStatus"
                                     label="Status"
                        />
                    </div>
                    <div class="col-12 col-md-4">
                        <atbl-input label="Reference transaction id"
                                    name="referenceTransactionId"
                                    placeholder="Enter reference transaction id"
                                    v-model="filters.referenceTransactionId"
                        />
                    </div>
                    <div v-if="$hasAccessToAllUsersData() && $can('Users - Get List')" class="col-12 col-lg-4">
                        <atbl-form-group label="Gateway" label-for="gateway" class="font-weight-bold"
                                         :horizontal="false"
                        >
                            <select v-model="filters.gateway" :value="filters.gateway" class="form-control"
                                    id="gateway"
                            >
                                <option :value="null">Select option</option>
                                <option v-for="availableGateway in availableGateways"
                                        :value="availableGateway.gateway.key"
                                >
                                    {{ availableGateway.gateway.name }}
                                </option>
                            </select>
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-lg-4">
                        <compare-number-select :value="[filters.amount, filters.amountSelector]"
                                               label="Amount"
                                               name="amount"
                                               @update="onUpdateCompareNumber"
                        />
                    </div>
                </div>
            </div>
            
            <div class="card-footer">
                <button :disabled="!isSearchFormEnabled"
                        type="submit"
                        class="btn btn-sm btn-primary"
                >
                    <i class="fa fa-search"></i> Search
                </button>
                <button type="reset"
                        class="btn btn-sm btn-danger"
                        @click="clear"
                >
                    <i class="fa fa-ban"></i> Clear
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import moment from 'moment';
    import Date from '../../../../../helpers/Date';
    import PaymentService from '../../../../../services/PaymentService';
    import CompareNumberSelect from '../../../../../components/Forms/Select/CompareNumberSelect.vue';
    import TransactionsSearchValidation
        from '../../../../Transactions/List/TransactionsSearch/TransactionsSearchValidation';
    import {filterNonEmpty} from '../../../../../utils';
    import {clone} from '../../../../../utils/Json/Json';
    
    const searchModel = {
        paymentType: [],
        dateFrom: null,
        dateTo: null,
        transaction: null,
        transactionStatus: null,
        referenceTransactionId: null,
        gateway: null,
        amount: null,
        amountSelector: null,
    };
    
    export default {
        name: 'SearchTransactions',
        emits: [
            'search'
        ],
        mixins: [
            TransactionsSearchValidation,
        ],
        components: {
            CompareNumberSelect
        },
        data() {
            return {
                transactionStatuses: [
                    {
                        label: 'Success',
                        value: 'completed',
                    },
                    {
                        label: 'Failed',
                        value: 'failed',
                    },
                    {
                        label: 'Pending',
                        value: 'pending',
                    }
                ],
                availableGateways: [],
                filters: clone(searchModel),
            };
        },
        computed: {
            createdAt: {
                get() {
                    if (!this.filters.dateFrom && !this.filters.dateTo) {
                        return null;
                    }
                    
                    return {
                        startDate: this.filters.dateFrom,
                        endDate: this.filters.dateTo
                    };
                },
                set(value) {
                    if (this.filters.dateFrom === value.startDate || this.filters.dateTo === value.endDate) {
                        return;
                    }
                    
                    this.filters.dateFrom = !!value.startDate
                      ? moment(value.startDate).format(Date.defaultDate)
                      : null;
                    this.filters.dateTo = !!value.endDate
                      ? moment(value.endDate).format(Date.defaultDate)
                      : null;
                }
            },
            paymentType: {
                get() {
                    return this.filters.paymentType;
                },
                set(value) {
                    this.filters.paymentType = value;
                }
            },
            isSearchModelEmpty() {
                return !filterNonEmpty(this.filters).length;
            },
            isSearchFormEnabled() {
                return !this.isSearchModelEmpty && !!this.isSearchFormValid;
            },
        },
        created() {
            this.fetchPaymentGateways();
        },
        methods: {
            fetchPaymentGateways() {
                PaymentService.fetchPaymentGateways().then((response) => {
                    this.availableGateways = response.data;
                });
            },
            search() {
                this.$emit('search', this.filters);
            },
            clear() {
                this.filters = clone(searchModel);
                this.search();
            },
            onUpdateCompareNumber(name, value, selector, error) {
                Object.assign(this.filters, {
                    [name]: value,
                    [`${name}Selector`]: selector
                });
                
                this.updateSearchModelError(name, error);
            },
        },
        watch: {
            filters: {
                handler() {
                    this.validateSearchForm();
                },
                deep: true,
            },
        },
    };
</script>