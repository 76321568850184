var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: {
        id: _vm.id,
        icon: "fa fa-ticket-alt",
        title: "Syndicate shares"
      },
      scopedSlots: _vm._u([
        {
          key: "first",
          fn: function() {
            return [
              _c("player-syndicate-shares-search-form", {
                on: { search: _vm.search }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "general-list agents" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col text-muted" }, [_vm._v("Ticket")]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Created By")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Lottery")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Syndicate")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Order")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Draw Date")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Lines")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Shares")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Winnings")
            ])
          ]),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.syndicateShares, function(syndicateShare, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c("div", { staticClass: "col align-self-center" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/syndicate-shares/" + syndicateShare.id
                        }
                      },
                      [_vm._v("ST# " + _vm._s(syndicateShare.id))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: { content: "Copy to clipboard" },
                            expression: "{content: 'Copy to clipboard'}"
                          }
                        ],
                        staticClass: "btn btn-sm btn-link p-0",
                        on: {
                          click: function($event) {
                            return _vm.copyToClipboard(syndicateShare.id)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-copy",
                          attrs: { "aria-hidden": "false" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(_vm.utcToLocal(syndicateShare.created_at))
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col align-self-center text-center" },
                    [
                      _c("created-by-badge", {
                        attrs: { created: syndicateShare.created_by }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _c("img", {
                        staticStyle: { width: "60px", height: "auto" },
                        attrs: {
                          src: _vm.$imageService.gameImage(
                            syndicateShare.game_image
                          )
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm.$can("Syndicates - Create/Update")
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/syndicates/items/" +
                                  syndicateShare.syndicate_item_id +
                                  "/edit"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        S#" +
                                  _vm._s(syndicateShare.syndicate_item_id) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(syndicateShare.title) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              "\n                        S#" +
                                _vm._s(syndicateShare.syndicate_item_id) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(syndicateShare.title) +
                                "\n                    "
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm.$can("Orders - View Order Details")
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/orders/" +
                                  syndicateShare.order_id +
                                  "?playerId=" +
                                  syndicateShare.player_id +
                                  "&from=syndicate-shares"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        O# " +
                                  _vm._s(syndicateShare.order_id) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              "\n                        O# " +
                                _vm._s(syndicateShare.order_id) +
                                "\n                    "
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(syndicateShare.cut_off_at) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(syndicateShare.lines_count) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(syndicateShare.shares) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col text-center align-self-center" },
                    [
                      syndicateShare.prize === null
                        ? _c("span", [
                            _vm._v(
                              "\n                        Not drawn yet\n                    "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.formatCurrency(syndicateShare.prize)
                                ) +
                                "\n                    "
                            )
                          ])
                    ]
                  )
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("items-not-found", {
                attrs: { items: _vm.syndicateShares, msg: "No tickets found." }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && _vm.syndicateSharesResponse.last_page > 1
            ? _c(
                "nav",
                { staticClass: "wrap py-3" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.syndicateSharesResponse },
                    on: { paginate: _vm.getSyndicateShares }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }