var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.showSearchForm
        ? _c("search-email-templates-component", {
            attrs: {
              "external-types": _vm.externalTypes,
              "search-model": _vm.emailTemplatesSearchModel,
              "email-types": _vm.emailTypes
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _c("span", [
              !!_vm.category_id && !_vm.isLoading
                ? _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onCategoryChangeEvent()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-chevron-left mr-2" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-envelope" }),
              _vm._v(" Email Templates\n            ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm mr-2",
                  class:
                    "" +
                    (!!_vm.showSearchForm
                      ? "btn-outline-primary"
                      : "btn-primary"),
                  on: {
                    click: function($event) {
                      _vm.showSearchForm = !_vm.showSearchForm
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-filter m-0" })]
              ),
              _vm._v(" "),
              _vm.$can("Email Templates - Create")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { disabled: !_vm.emailTemplateUserTypes.length },
                      on: {
                        click: function($event) {
                          _vm.createFormShown = true
                        }
                      }
                    },
                    [_vm._v("\n                    Create\n                ")]
                  )
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(0),
              _vm._v(" "),
              !_vm.isLoading
                ? _vm._l(_vm.emailTemplates, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "row",
                        class: {
                          "bg-light": !!item.children_count
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg-1 text-center text-lg-left mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge m-0" }, [
                              _vm._v("ID")
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.id) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg text-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge m-0" }, [
                              _vm._v("Name")
                            ]),
                            _vm._v(" "),
                            !!item.children_count
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: "Category"
                                        },
                                        expression:
                                          "{\n                                        content: 'Category'\n                                     }"
                                      }
                                    ],
                                    staticClass: "badge badge-dark"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                C\n                            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onCategoryChangeEvent(item.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.name) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$options.Elipsis(item.description, 50)
                                  ) +
                                  "\n                            "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg-2 text-center mb-2 mb-lg-0"
                          },
                          [
                            !item.players_enabled && !item.leads_enabled
                              ? _c(
                                  "span",
                                  { staticClass: "badge badge-danger" },
                                  [
                                    _vm._v(
                                      "\n                                /\n                            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !!item.players_enabled
                              ? _c(
                                  "span",
                                  { staticClass: "badge badge-primary mr-1" },
                                  [
                                    _vm._v(
                                      "\n                                Players\n                            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !!item.leads_enabled
                              ? _c(
                                  "span",
                                  { staticClass: "badge badge-warning mr-1" },
                                  [
                                    _vm._v(
                                      "\n                                Leads\n                            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg-2 text-center mb-2 mb-lg-0"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Channel")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.resolveChannels(item), function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "d-flex justify-content-center align-items-center mb-1 border p-1"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-1",
                                      class: [
                                        "badge",
                                        "badge-" + item.nameColor
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.name) +
                                          "\n                                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        "badge",
                                        "badge-" + item.typeColor
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.type) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg-2 text-center" },
                          [
                            _c("span", { staticClass: "small-mb-badge m-0" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-center"
                              },
                              [
                                _vm.$can("Email Templates - Details")
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-link btn-delete btn-sm mr-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.fetchEmailTemplateDetails(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-pencil-alt"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !item.children_count &&
                                _vm.$can("Email Templates - Remove")
                                  ? _c("confirmable-remove-button", {
                                      attrs: { value: item.id },
                                      on: { confirmed: _vm.removeEmailTemplate }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: {
                      items: _vm.emailTemplates,
                      msg: "No email templates found"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ]),
        _vm._v(" "),
        !_vm.isLoading && _vm.emailTemplatesResponse.last_page > 1
          ? _c("div", { staticClass: "card-footer overflow-x-auto" }, [
              _c(
                "nav",
                { staticClass: "wrap" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.emailTemplatesResponse },
                    on: { paginate: _vm.fetchEmailTemplates }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      !!_vm.createFormReady
        ? _c("create-update-email-templates-component", {
            attrs: {
              "email-types": _vm.emailTypes,
              show: _vm.createFormShown,
              "is-loading": _vm.createFormLoading,
              "email-model": _vm.emailModel,
              "email-errors": _vm.emailError,
              "active-source-type": _vm.activeSourceType,
              "user-types": _vm.emailTemplateUserTypes,
              languages: _vm.languages
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-1 text-center text-lg-left" }, [
        _vm._v("ID")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
        _vm._v("Visible for")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
        _vm._v("Channel")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
        _vm._v("Operations")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }