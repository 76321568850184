export default {
    api_token: null,
    commission_plan_id: null,
    created_at: null,
    email: null,
    free_games_settings: {
        created_at: null,
        id: null,
        range_type: null,
        tickets: null,
        lines: null,
        fractions: null,
        shares: null,
        user_id: null,
    },
    id: null,
    is_active: null,
    name: null,
    updated_at: null,
}