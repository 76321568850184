<template>
    <div class="payment-gateways"
         :class="{
            'payment-gateways--disabled': isLoading || chargeAmount === 0,
            'mb-3': !!isLoading
         }"
    >
        <div v-if="!isLoading" class="payment-gateways--container">
            <div v-for="payment in availablePayments"
                :key="`gateway_${payment.providerType}`"
                class="payment-gateway-button"
                :class="[
                    `payment-gateway-button--${payment.gateway}`,
                    {'payment-gateway-button--toggled': selectedGatewayDropDown === payment.gateway},
                    {'payment-gateway-button--disabled': payment.providerType === 'wallet' && !hasEnoughWalletFunds }
                ]"
                @click.capture="onPaymentClick(payment)">
                    <template v-if="payment.providerType === 'oppwa'">
                        {{ payment.name }}
                        <span></span>
                        <div class="payment-gateway-button__dropdown-content">
                            <a href="#" @click.prevent="selectPayment(payment)">Pay with credit card</a>
                            <a href="#" @click.prevent="showListOfSavedCardsForGateway = payment.gateway">Pay with saved card</a>
                        </div>
                    </template>
                    <template v-else>
                        {{ payment.name }}
                        <template v-if="payment.providerType === 'wallet'">
                             - {{ formattedCurrency }}
                        </template>
                    </template>
            </div>
        </div>
        <loader v-else :show="isLoading" type="small" />

        <h4 v-if="!isLoading && !availablePayments.length"
            class="text-danger mb-0"
        >
            No payments available in the moment.
        </h4>
        
        <payment-gateway-saved-credit-cards-modal v-if="showListOfSavedCardsForGateway"
                                                  :player-id="playerId"
                                                  :gateway-key="showListOfSavedCardsForGateway"
                                                  @confirm="confirmSelectedSavedCreditCard"
                                                  @cancel="showListOfSavedCardsForGateway = null" />

        <payment-gateway-oppwa v-if="selectedProvider === 'oppwa' && !!apiPaymentResponse"
                               :api-payment-response="apiPaymentResponse"
                               v-on:cancel="selectedProvider = null; apiPaymentResponse = null"
        />

        <cashier v-if="selectedType === 'external' && !!apiPaymentResponse"
                :player-id="playerId"
                :providerType="selectedProvider"
                :api-payment-response="apiPaymentResponse"
                v-on:cancel="selectedType = null; apiPaymentResponse = null"
        />

        <confirm-wallet-payment-modal v-if="walletPaymentConfirmModal"
                                      :amount="chargeAmount"
                                      @confirm="walletPaymentConfirmModal = false; selectPayment(walletPayment)"
                                      @cancel="walletPaymentConfirmModal = false"
        />
    </div>
</template>
<script>
import ConfirmWalletPaymentModal from './ConfirmWalletPaymentModal.vue';
import PaymentGatewaySavedCreditCardsModal from './SavedCardModal.vue';
import PaymentService from '../../services/PaymentService';
import Cashier from './Cashier.vue';
import Loader from '../Widgets/Loader.vue';
import PaymentGatewayOppwa from './Oppwa.vue';
import PaymentTypes from '../../constants/PaymentTypes.js';


export default {
    name: 'list-payments',
    props: {
        playerId: {
            type: Number
        },
        chargeAmount: {
            type: Number
        },
        params: {
            type: Object
        },
        requestUrl: {
            type: String,
        },
        paymentTypes: {
            type: Array,
            validator: value => value.every(type => PaymentTypes.all().includes(type))
        }
    },

    components: {
        ConfirmWalletPaymentModal,
        PaymentGatewaySavedCreditCardsModal,
        Cashier,
        PaymentGatewayOppwa,
        Loader,
    },
    data() {
        return {
            availablePayments: [],
            selectedType: null,
            selectedProvider: null,
            walletBalance: null,
            apiPaymentResponse: null,
            isLoading: false,
            walletPaymentConfirmModal: false,
            showListOfSavedCardsForGateway: null,
            selectedGatewayDropDown: null,
        }
    },
    computed: {
        hasEnoughWalletFunds() {
            const toCharge = this.chargeAmount || 0;

            return this.walletBalance >= toCharge && !!toCharge;
        },
        formattedCurrency() {
            return this.$options.filters.formatCurrency(this.walletBalance);
        },
        walletPayment() {
            return this.availablePayments.find(item => item.gateway === 'wallet')
        }
    },
    async created() {
        await this.fetchAllPaymentProviders();
    },
    mounted() {
        window.addEventListener('click', (e) => {
            let targetElement = e.target;

            do {
                if (!targetElement.classList) {
                    this.selectedGatewayDropDown = null;
                    return;
                }

                if (targetElement.classList.contains('payment-gateway-button')) {
                    return;
                }

                targetElement = targetElement.parentNode;
            } while (targetElement);

            this.selectedGatewayDropDown = null;
        });
    },
    methods: {
        async fetchAllPaymentProviders() {
            this.isLoading = true;

            try {
                const response = await PaymentService.fetchAllPlayerPayments(this.playerId, this.paymentTypes);
                this.availablePayments = response.data;
                const wallet = response.data.find(item => item.gateway === 'wallet')
                this.walletBalance = wallet?.amount || 0;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashBadResponse(error);
            }
        },
        onPaymentClick(payment) {
            this.selectedProvider = payment.providerType || null;

            if (payment.gateway === 'wallet') {
                this.walletPaymentConfirmModal = true;
                return;
            }
            if (this.selectedGatewayDropDown === payment.gateway) {
                this.selectedGatewayDropDown = null;
                return;
            }

            if (payment.gateway === 'oppwa') {
                this.selectedGatewayDropDown = payment.gateway;
            } else {
                this.selectPayment(payment);
            }
        },
        selectPayment(payment) {
            if (payment.wallet === 'wallet' && !this.hasEnoughWalletFunds) {
                return;
            }
            this.isLoading = true;
            this.handlePayment(payment, null).finally(() => {
                this.isLoading = false;
            });

            this.selectedGatewayDropDown = null;
        },
        confirmSelectedSavedCreditCard(creditCard) {
            this.isLoading = true;
            const oppwaPayment = this.availablePayments.find(item => item.gateway === 'oppwa')
            this.handlePayment(oppwaPayment, creditCard.id).finally(() => {
                this.apiPaymentResponse = null;
                this.isLoading = false;
            });
            this.showListOfSavedCardsForGateway = null;

        },
        handlePayment(payment, creditCardId = null) {
            const sendParams = {
                    ...(payment?.gateway) && { gateway: payment.gateway},
                    ...(!!creditCardId) && {card_id: creditCardId},
                    ...this.params,
            };
            const paymentPromise = window.axios.post(this.requestUrl || payment.postUrl, sendParams);

            paymentPromise.then(response => {
                const responseData = response.data;
                const paymentStatus = responseData.status;
                this.apiPaymentResponse = responseData;

                const emitData = {
                    action: responseData.action,
                    message: responseData.message,
                };

                if (paymentStatus === 'success' && responseData.paid) {
                    emitData.order = responseData.order;
                    emitData.transaction = responseData.transaction;

                    this.$emit('payment-successful', emitData)

                    return;
                }

                if (paymentStatus === 'failed') {
                    emitData.error = responseData.error;

                    this.$emit('payment-failed', emitData)

                    return;
                }

                if (paymentStatus === 'pending' && responseData.is_checkout) {
                    this.selectedType = payment.type;
                }

                if (paymentStatus === 'pending' && responseData.is_redirect) {
                    window.location.href = responseData.redirect.url;
                }
            });

            paymentPromise.catch(error => {
                this.$emit('payment-error', error);
            })

            return paymentPromise;
        },
    }
}
</script>
