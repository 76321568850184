<template>
    <div v-click-outside="onUpdateValue"
         class="atbl-balance-selector"
    >
        <atbl-form-group :label="label"
                         :error="errorMessage"
                         class="font-weight-bold mb-0"
        >
            <div class="d-flex">
                <atbl-select v-if="!!balanceTypes"
                             v-model="selectType"
                             :id="labelId"
                             :options="balanceTypes"
                             name="Type"
                             first-option-label="Type"
                             class="mr-1 mb-1"
                />
                <atbl-select v-model="selectSelector"
                             :options="balanceOptions"
                             first-option-label="Selector"
                             class="mr-1 mb-1"
                             name="Selector"
                />
                <atbl-number v-model="selectValue"
                             :min="0"
                             :max="false"
                             name="balance"
                             placeholder="e.g. 100"
                             autocomplete="off"
                             class="mb-1"
                />
            </div>
        </atbl-form-group>
        <a v-if='!!clearable && !!hasValue'
           class="atbl-balance-selector-close"
           href="#"
           @click.prevent="onClearValueEvent"
        >
            Clear
        </a>
    </div>
</template>

<script>
export default {
    name: 'BalanceSelect',
    props: {
        value: {
            type: Object,
            default: () => ({
                balance: null,
                balanceSelector: null,
                balanceType: null,
            })
        },
        label: {
            type: String,
            default: 'Balance'
        },
        name: {
            type: String,
            default: 'balance'
        },
        errorMessage: {
            type: String,
            default: null
        },
        balanceTypes: {
            type: [Array, Boolean],
            default: () => ([
                {
                    value: 'wallet_amount',
                    label: 'Real'
                },
                {
                    value: 'wallet_bonus_amount',
                    label: 'Bonus'
                },
                {
                    value: 'wallet_total_balance',
                    label: 'Total'
                },
            ])
        },
        balanceOptions: {
            type: Array,
            default: () => ([
                {
                    value: '>',
                    label: 'Greater then'
                },
                {
                    value: '<',
                    label: 'Less than'
                },
                {
                    value: '=',
                    label: 'Equal to'
                }
            ])
        },
        clearable: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            type: null,
            selector: null,
            inputValue: null,
        };
    },
    computed: {
        selectType: {
            get() {
                return this.type;
            },
            set(value) {
                let getTypeOption = this.balanceTypes.find(item => item.value === value);

                this.type = !!getTypeOption && getTypeOption.value === value
                    ? value
                    : null;

                this.afterUpdateValue();
            },
        },
        selectSelector: {
            get() {
                return this.selector;
            },
            set(value) {
                let getBalanceOption = this.balanceOptions.find(item => item.value === value);

                this.selector = !!getBalanceOption && getBalanceOption.value === value
                    ? value
                    : null;

                this.afterUpdateValue();
            }
        },
        selectValue: {
            get() {
                return this.inputValue;
            },
            set(value) {
                this.inputValue = !!value ? value : null;

                this.afterUpdateValue();
            },
        },
        labelId() {
            return `${this.label}Select`;
        },
        hasValue() {
            const balance = this.value[this.getOptionName()];
            const balanceSelector = this.value[this.getOptionName('Selector')];
            const balanceType = this.value[this.getOptionName('Type')];

            return !!balance ||
                !!balanceSelector ||
                !!balanceType;
        },
    },
    methods: {
        afterUpdateValue() {
            if (!this.selector || !this.inputValue || (!!this.balanceTypes && !this.type)) {
                return;
            }

            this.onUpdateValue();
        },
        onUpdateValue() {
            this.$emit('input', {
                [this.getOptionName('Selector')]: this.selector,
                [this.getOptionName()]: this.inputValue,
                [this.getOptionName('Type')]: this.type,
                errorMessage: this.getValidationMessage(),
            });
        },
        onClearValueEvent() {
            this.$emit('input', {
                [this.getOptionName()]: null,
                [this.getOptionName('Selector')]: null,
                [this.getOptionName('Type')]: null,
                errorMessage: null,
            });
        },
        getOptionValue(type, value = null) {
            const tempValue = !value
                ? this.value
                : value;

            return tempValue[this.getOptionName(type)];
        },
        getOptionName(type) {
            return `${this.name}${!!type ? type : ''}`;
        },
        getValidationMessage() {
            if (!this.selector) {
                return null;
            }

            if (!this.inputValue) {
                return null;
            }

            return this.selector === '<' && Number(this.inputValue) <= 0
                ? 'Selected option cannot be less than or equal to 0.'
                : null;
        },
    },
    watch: {
        value: {
            handler(value) {
                const type = this.getOptionValue('Type', value);
                const selector = this.getOptionValue('Selector', value);
                const inputValue = this.getOptionValue(null, value);

                if (this.type === type &&
                    this.selector === selector &&
                    this.inputValue === inputValue
                ) {
                    return;
                }

                this.type = type;
                this.selector = selector;
                this.inputValue = inputValue;
            },
            deep: true,
            immediate: true
        }
    },
}
</script>