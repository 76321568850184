import moment from 'moment';

export default {
    defaultFormat: "YYYY-MM-DD HH:mm",
    defaultDate: "YYYY-MM-DD",
    defaultBirthDate: "DD/MM/YYYY",
    getTimeDate: function(value){
        return Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: "2-digit",
            minute: "2-digit"
        }).format(value);
    },
    getDate: function(value){
        return this.format(value, this.defaultDate);
    },
    format: function(date, format = "YYYY-MM-DD HH:mm"){
        let formatIng = format === "birthdate" ? this.defaultBirthDate : format;
        return moment(date).format(formatIng);
    }
}