<template>
    <div>
        <div class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span><i class='fa fa-cog'></i> Settings</span>
                <button class="btn btn-sm btn-primary m-0"
                          @click="openAddSectionModal(true)"
                          v-if="$can('Settings - Create/Update')"
                >
                    Add Section
                </button>
            </div>

            <div class="card-body py-0 px-x">
                <SettingsCategoriesList />
            </div>
        </div>

        <AddSettingsSectionModal v-if="$can('Settings - Create/Update')" />
        <RemoveSettingsSectionModal v-if="$can('Settings - Remove')" />
    </div>
</template>

<script>
    import SettingsCategoriesList from "./Sections/SettingsCategoriesList";
    import AddSettingsSectionModal from "./Components/AddSettingsSectionModal";
    import RemoveSettingsSectionModal from "./Components/RemoveSettingsSectionModal";
    import TypesConst from "../../../store/TypesConst";
    import NamespaceConst from "../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const { mapActions } = createNamespacedHelpers(NamespaceConst.settings);

    export default {
        name: "settings-categories",
        components: {
            SettingsCategoriesList,
            AddSettingsSectionModal,
            RemoveSettingsSectionModal
        },
        methods: {
            ...mapActions({
                openAddSectionModal: TypesConst.settings.actions.OPEN_SETTINGS_SECTIONS_MODAL_ACTION
            })
        }
    }
</script>