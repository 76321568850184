var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.fields, function(field, index) {
      return _c(
        "div",
        {
          key: "action_field_" + field.key + "_" + index,
          staticClass: "row",
          class: { "border-bottom": _vm.shouldShow(field.key) }
        },
        [
          _vm.shouldShow(field.key)
            ? _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("bonus-action-field", {
                    attrs: {
                      config: _vm.getFieldConfiguration(field.key),
                      disabled: _vm.disableFields
                    },
                    on: { input: _vm.sanitizeValues },
                    model: {
                      value: _vm.fields[index],
                      callback: function($$v) {
                        _vm.$set(_vm.fields, index, $$v)
                      },
                      expression: "fields[index]"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }