<template>
    <atbl-form-group label="Game"
                     :error="getError(errors, 'game')"
    >
        <select :disabled="disabled"
                class="form-control"
                @input="changeGame"
        >
            <option :value="null" :selected="!value">Select Game</option>
            <option v-for="(item, index) in options"
                    :key="index"
                    :value="item.id"
                    :selected="value === item.id"
            >
                {{ item.hasOwnProperty('title') ? item.title : item.name }}
            </option>
        </select>
    </atbl-form-group>
</template>

<script>
export default {
    name: 'GameSelect',
    emits: [
        'input',
    ],
    props: {
        value: {},
        input: {},
        options: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        changeGame (event) {
            const value = event.target.value;

            this.$emit('input', value);
            this.$emit('update', 'game', value);
        },
    },
}
</script>