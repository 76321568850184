import { isValueJson, parse } from '../Json/Json';
import pick from 'lodash/pick';

/**
 * @param value
 * @return {*|number}
 */
function getObjectValue(value) {
    return isNaN(+value)
        ? value
        : Number(value);
}

/**
 * Convert query params string to object
 *
 * Example:
 * name=Name&surname=Surname
 *
 * Result:
 * {
 *     name: "Name",
 *     surname: "Surname"
 * }
 *
 * @param string
 * @return {{}}
 */
export function queryParamsToObject(string) {
    const result = {};

    if (!string) {
        return result;
    }

    const queryString = string.substr(0, 1) === '?'
        ? string.substr(1)
        : string;

    const params = new URLSearchParams(queryString);

    for (const [key, value] of params.entries()) {
        const isArray = /[[\]]/.test(key);

        const objectKey = isArray
            ? key.split('[')[0]
            : key;

        let objectValue = getObjectValue(value);

        if (isValueJson(value)) {
            objectValue = parse(value);
        }

        if (isArray) {
            objectValue = result.hasOwnProperty(objectKey)
                ? [
                    ...result[objectKey],
                    getObjectValue(value)
                ]
                : [getObjectValue(value)];
        }

        result[objectKey] = objectValue;
    }

    return result;
}

/**
 * Gets all query params from current url and converts into object
 *
 * @return {{}}
 */
export default (pickOnly = []) => {
    const url = new URL(window.location.href);
    const object = queryParamsToObject(url.search);

    if (!pickOnly.length) {
        return object;
    }

    return pick(object, pickOnly);
}