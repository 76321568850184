import MarketingService from '../../../../../services/MarketingService';

const defaultFilterName = 'Not called';

export default {
    provide() {
        return {
            defaultFilterName,
            fetchCampaignFilters: this.fetchCampaignFilters,
        };
    },
    data() {
        return {
            filtersList: [],
            filterListLoader: false,
        };
    },
    methods: {
        async fetchCampaignFilters(campaignId, campaignType) {
            this.filterListLoader = true;

            try {
                const response = await MarketingService.getCampaignsLeadsFilter(campaignType, campaignId);

                this.filtersList = this.resolveResponse(response.data);

                this.filterListLoader = false;
            } catch (error) {
                this.filterListLoader = false;

                window.flashError(error);
            }
        },
        resolveResponse(responseData) {
            const keys = Object.keys(responseData);

            if (!keys.length) {
                return [];
            }

            const tmpList = keys.map(item => {
                const isDefaultFilter = !item.length;

                const title = isDefaultFilter
                    ? defaultFilterName
                    : item;

                const count = responseData[item];

                return {
                    title,
                    count,
                    isActive: !!isDefaultFilter,
                };
            });

            const defaultFilterItem = tmpList.find(item => item.title === defaultFilterName);
            const withoutDefaultFilterItem = tmpList.filter(item => item.title !== defaultFilterName);

            return [
                ...(
                    !!defaultFilterItem
                        ? [defaultFilterItem]
                        : []
                ),
                ...withoutDefaultFilterItem
            ];
        },
    },
}