export default {
    methods: {
        logABSum: function(text, transaction){
            let showSum = 0;
            if(transaction.wallet_log !== null){
                showSum = transaction.wallet_log[text] ? transaction.wallet_log[text] : 0;
            }

            const showSumFormatted = this.$options.filters.formatCurrency(showSum);

            return `${this.ucFirst(text)}: ${showSumFormatted}`;
        }
    }
}