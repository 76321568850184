/**
 * ImageService class
 */
class ImageService {
    /**
     * Get public resources url
     *
     * @return {*}
     */
    getPublicResourcesUrl() {
        return process.env.MIX_S3_PUBLIC_RESOURCES;
    }

    /**
     * Create custom resource path
     *
     * @param path
     * @return {*}
     */
    path (path) {
        return (new URL(path, this.getPublicResourcesUrl())).toString();
    }

    /**
     * Get game image resource path
     *
     * @param imageName
     * @param path
     * @return {*}
     */
    gameImage (imageName, path = 'games') {
        return this.path(`/${path}/${imageName}/logo.png`);
    }
}

export default ImageService;