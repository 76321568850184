<template>
    <span class="d-flex align-items-center justify-content-center">
        <button class="btn btn-sm btn-link btn-confirm"
                  v-if="!isChecked"
                  :disabled="disabled"
                  @click="showOperations"
        >
            <i v-if="icon" :class="icon"></i>
            <span v-if="label">{{ label }}</span>
        </button>
        <button class="btn btn-sm btn-success btn-confirm-action"
                  v-if="isChecked"
                  :disabled="disabled"
                  @click="confirmed"
        >
            <i class="fa fa-check"></i>
        </button>
        <button class="btn btn-sm btn-danger btn-confirm-action"
                  :disabled="disabled"
                  v-if="isChecked"
                  @click="showOperations"
        >
            <i class="fa fa-times"></i>
        </button>
    </span>
</template>

<script>
  export default {
    name: "confirmable-button",
    props: {
      icon: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: "Button"
      },
      value: {
        default: null
      },
      show: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        isChecked: false,
        disabled: false
      };
    },
    methods: {
      showOperations: function () {
        this.isChecked = !this.isChecked;
      },
      confirmed: function () {
        this.disabled = true;
        this.$emit("confirmed", this.value);
      }
    },
    created: function () {
      let _vm = this;

      _vm.isCheck = _vm.show;
      Atbl.$on("confirmed-button-clear", function(){
        _vm.disabled = true;
        _vm.showOperations();
      });
    }
  }
</script>