<template>
    <div class="configuration-wrapper">
        <div v-for="(numberTypes, index) in configurationTemp"
             :key="index"
             class="configuration"
        >
            <raffle-editor-item :index="index"
                                :number-types="numberTypes"
                                :configuration-temp="configurationTemp"
                                @update="update"
                                @remove-configuration="onRemoveConfigurationEvent"
                                @update-configuration="onUpdateConfigurationEvent"
                                @update-winner-prize-configuration="onPrizeWinnerUpdateEvent"
                                @remove-field-configuration="onDeleteFieldEvent"
            />
        </div>
    </div>
</template>

<script>
import RaffleEditorItem from './Components/RaffleEditorItem';
import { clone } from '../../../../../utils/Json/Json';

export default {
    name: 'RaffleEditor',
    emits: [
        'update-raffle',
        'update-configuration',
        'remove-configuration',
        'update-winner-prize-configuration',
        'remove-field-configuration',
    ],
    components: {
        RaffleEditorItem
    },
    props: {
        configuration: {
            type: Object,
            default: () => ({})
        },
        oldGameDraw: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            configurationTemp: {}
        };
    },
    methods: {
        resolveConfiguration(configuration) {
            let configTemp = {};

            for (const item in configuration) {
                const value = configuration[item];

                let temp = [];
                for (const obj in value) {
                    const currentObj = value[obj];

                    if (currentObj.hasOwnProperty('type')) {
                        const generateFullNumbersList = [...new Array(currentObj.numbers)].map(() => {
                            return {
                                ...currentObj,
                                ...{
                                    id: this.uuidv4(),
                                    value: null,
                                    error: null,
                                    isValid: false
                                }
                            };
                        });

                        temp = {
                            ...temp,
                            ...{
                                [currentObj.title]: generateFullNumbersList
                            }
                        };
                    } else {
                        const oldDrawValues = !!this.oldGameDraw && this.oldGameDraw.hasOwnProperty(item)
                            ? this.oldGameDraw[item]
                            : null;

                        const currentObjKey = currentObj.key;

                        const oldDrawValue = !!oldDrawValues && oldDrawValues.hasOwnProperty(currentObjKey)
                            ? oldDrawValues[currentObjKey]
                            : null;

                        temp.push({
                            ...currentObj,
                            ...{
                                id: this.uuidv4(),
                                value: oldDrawValue,
                                error: null,
                                isValid: !!oldDrawValue
                            }
                        });
                    }
                }


                configTemp[item] = temp;
            }

            return configTemp;
        },
        update(value){
            this.configurationTemp = value;

            this.$emit('update-raffle', value)
        },
        onRemoveConfigurationEvent(type) {
            this.$emit('remove-configuration', type)
        },
        onUpdateConfigurationEvent(type, model) {
            this.$emit('update-configuration', type, model);
        },
        configurationResolver(configuration) {
            return this.resolveConfiguration(clone(configuration));
        },
        onPrizeWinnerUpdateEvent(value) {
            this.$emit('update-winner-prize-configuration', value);
        },
        onDeleteFieldEvent(value) {
            this.$emit('remove-field-configuration', value);
        },
    },
    watch: {
        configuration: {
            handler(value) {
                this.configurationTemp = this.configurationResolver(value);
            },
            deep: true
        },
    },
    created() {
        this.configurationTemp = this.configurationResolver(this.configuration);
    }
}
</script>