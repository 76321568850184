var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        !_vm.isLoading && _vm.$can("Discounts - Create/Update")
          ? _c(
              "atbl-dropdown",
              {
                attrs: {
                  disabled: !_vm.gamesWithoutDiscount.length,
                  small: true,
                  placeholder: "Add game discount",
                  "input-style": "primary"
                }
              },
              _vm._l(_vm.gamesWithoutDiscount, function(game) {
                return _c(
                  "atbl-dropdown-item",
                  {
                    key: "game_without_discount_" + game.id,
                    attrs: { "is-anchor-tag": true },
                    on: {
                      click: function($event) {
                        return _vm.createGameDiscount(game.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(game.name) +
                        "\n            "
                    )
                  ]
                )
              }),
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "d-none" }),
          _vm._v(" "),
          _vm._l(_vm.gamesWithDiscount, function(discount) {
            return _c("div", { key: "game_discount_" + discount.id }, [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: _vm.$imageService.gameImage(discount.image),
                    width: "40"
                  }
                }),
                _vm._v(
                  "\n                    " +
                    _vm._s(discount.name) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onSelectGameForEdit(discount)
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-pen" })]
              )
            ])
          }),
          _vm._v(" "),
          _c("items-not-found", {
            attrs: {
              items: _vm.gamesWithDiscount,
              msg: "No game discounts found."
            }
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-percent" }),
      _vm._v(" Game discounts\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }