<template>
    <div class="configuration">
        <h5 class="configuration-header"
            @click="toggleBody"
        >
            <span>{{ ucFirst(index) }}</span>
            <i class="fa" :class="{'fa-caret-up': showBody, 'fa-caret-down': !showBody}" aria-hidden="false"></i>
        </h5>

        <div v-show="showBody"
             class="align-items-center justify-content-between flex-wrap"
             :class="{ 'd-flex': showBody }"
        >
            <template v-if="isObject(numberTypes)">
                <div v-for="(numberType, numberTypeIndex) in numberTypes"
                     :key="numberTypeIndex"
                     class="w-50 text-center"
                >
                    <h6 class="my-2">{{ numberTypeIndex }}</h6>
                    <template v-for="(number, numIndex) in numberType">
                        <div :key="numIndex">
                            <template v-if="number.hasOwnProperty('numbers')">
                                <input :id="number.id"
                                       class="configuration-text-input"
                                       :value="number.value"
                                       :disabled="disabled"
                                       :class="{
                                        'input-has-error': !!number.error
                                   }"
                                       @input="changeNumber($event, index, numberTypeIndex, number)"
                                />
                            </template>
                            <raffle-configuration-form-main-item v-else
                                                                 :number-type-index="numberTypeIndex"
                                                                 @remove-configuration="onRemoveConfigurationEvent"
                                                                 @update-configuration="onUpdateConfigurationEvent"
                            />
                        </div>
                    </template>
                </div>
            </template>
            <div v-else
                 class="configuration-winning-prizes"
            >
                <raffle-editor-prize-winner-item  v-for="(numberType, numberTypeIndex) in numberTypes"
                                                  :key="numberTypeIndex"
                                                  :number-type="numberType"
                                                  :index="index"
                                                  :disabled="disabled"
                                                  :number-type-index="numberTypeIndex"
                                                  @change="changeNumber"
                                                  @update-configuration="onPrizeWinnerUpdateEvent"
                                                  @remove-field-configuration="onDeleteFieldEvent"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DrawsEditorMixins from '../../../Mixins/DrawsEditor';
import RaffleConfigurationFormMainItem from './RaffleConfigurationFormMainItem';
import RaffleConfigurationFormPrizeWinnerItem from './RaffleConfigurationFormPrizeWinnerItem';
import RaffleEditorPrizeWinnerItem from './RaffleEditorPrizeWinnerItem';
import { clone } from '../../../../../../utils/Json/Json';

export default {
    name: 'RaffleEditorItem',
    emits: [
        'remove-configuration',
        'update-winner-prize-configuration',
        'update',
        'update',
    ],
    components: {
        RaffleEditorPrizeWinnerItem,
        RaffleConfigurationFormMainItem,
        RaffleConfigurationFormPrizeWinnerItem,
    },
    mixins: [
        DrawsEditorMixins
    ],
    props: {
        index: {
            required: true
        },
        numberTypes: {
            required: true
        },
        configurationTemp: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showBody: true,
        };
    },
    methods: {
        changeNumber(event, workingKey, numberTypeIndex, item) {
            const configTTemp = clone(this.configurationTemp);

            const id = event.target.getAttribute('id');
            const value = event.target.value;
            const hasErrors = this.hasErrors(value, item);

            const currentItemParent = typeof numberTypeIndex === 'string'
                ? configTTemp[workingKey][numberTypeIndex]
                : configTTemp[workingKey]
            const currentItem = currentItemParent.filter(item => item.id === id)[0];

            if (!currentItem) {
                return window.showMessage('Item is not found.', false);
            }

            currentItem.value = value;
            currentItem.error = hasErrors ? hasErrors : null;
            currentItem.isValid = !hasErrors;

            this.$emit('update', configTTemp);
        },
        onRemoveConfigurationEvent(type) {
            this.$emit('remove-configuration', type);
        },
        onUpdateConfigurationEvent(type, model) {
            this.$emit('update-configuration', type, model);
        },
        onPrizeWinnerUpdateEvent(value) {
            this.$emit('update-winner-prize-configuration', value);
        },
        onDeleteFieldEvent(value) {
            this.$emit('remove-field-configuration', value);
        },
        hasErrors(value, item) {
            // check for length
            if (!value.length || value.toString().length > item.length || value.toString().length < item.length) {
                return `Length cannot be less or more than ${item.length}`;
            }

            // check for only digits
            if (!/^[0-9,.]*$/.test(value)) {
                return 'Only digits are allowed in this input.';
            }

            return false;
        }
    }
}
</script>