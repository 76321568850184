<template>
    <AgentsSalesCardsList :agents="agents"
                          :sales="sales"
                          :isLoading="isLoading"
    >
        <slot name="logout"></slot>
    </AgentsSalesCardsList>
</template>

<script>
import AgentsSalesCardsList from './Sections/AgentsSalesCardsList';
import ReportsAgentsService from '../../../services/ReportsAgentsService';
import Date from '../../../helpers/Date';
import moment from 'moment';

export default {
    name: 'agent-sales',
    components: {
        AgentsSalesCardsList
    },
    data() {
        return {
            isLoading: false,
            agents: [],
            sales: {},
            model: {
                filterDate: 'today',
                paginate: 25,
                dateFrom: moment().format(Date.defaultDate),
                dateTo: moment().format(Date.defaultDate),
                sort: {
                    sort: 'total',
                    value: 'DESC'
                }
            }
        };
    },
    methods: {
        async getAgents(canLoad = false) {
            if (canLoad) {
                this.isLoading = true;
            }

            const response = await window.axios.get('/presenter/list', {
                params: this.model
            });

            this.agents = response.data.agents;
            this.sales = {
                countAll: response.data.countAll,
                totalSales: response.data.totalSales,
                totalSalesPercent: response.data.totalSalesPercent
            };

            if (canLoad) {
                this.isLoading = false;
            }
        }
    },
    created() {
        this.getAgents(true);

        setInterval(() => {
            this.getAgents();
        }, 60000);
    }
}
</script>