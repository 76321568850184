var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: { title: "Remove Attachment", show: true },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  on: { click: _vm.emitCloseEvent }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger",
                  on: {
                    click: function($event) {
                      return _vm.confirm()
                    }
                  }
                },
                [_vm._v("\n            Decline\n        ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", [_vm._v("You are about to decline this attachment.")]),
          _vm._v(" "),
          _c("p", [_vm._v("Please leave a message if there is need to.")])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("atbl-textarea", {
              attrs: {
                label: "Message",
                placeholder: "Place a message",
                rows: 3
              },
              model: {
                value: _vm.message,
                callback: function($$v) {
                  _vm.message = $$v
                },
                expression: "message"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }