var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      staticClass: "large",
      attrs: {
        title: "Preview Affiliate",
        value: _vm.isOpen,
        loader: _vm.isLoading
      },
      on: { input: _vm.onCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-outline-danger mr-2",
                  on: { click: _vm.onCloseEvent }
                },
                [_vm._v("\n            Close\n        ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row border-bottom mb-2 pb-2" }, [
        _c("div", { staticClass: "col" }, [
          _c("h5", { staticClass: "text-uppercase m-0" }, [_vm._v("General")])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom" }, [
        _c("div", { staticClass: "col font-weight-bold py-1" }, [
          _vm._v("\n            # ID\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col py-1" }, [
          _vm._v(
            "\n            # " + _vm._s(_vm.selectedAffiliate.id) + "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom" }, [
        _c("div", { staticClass: "col font-weight-bold py-1" }, [
          _vm._v("\n            Username\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col py-1" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.selectedAffiliate.username) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom" }, [
        _c("div", { staticClass: "col font-weight-bold py-1" }, [
          _vm._v("\n            Status\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col py-1" }, [
          _c(
            "span",
            {
              class:
                "badge badge-" +
                _vm.resolveStatusColor(_vm.selectedAffiliate.status)
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.selectedAffiliate.status) +
                  "\n            "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom" }, [
        _c("div", { staticClass: "col font-weight-bold py-1" }, [
          _vm._v("\n            Is Active\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col py-1" }, [
          _c("i", {
            staticClass: "fa",
            class: {
              "text-success fa-check": !!_vm.selectedAffiliate.is_active,
              "text-danger fa-times": !_vm.selectedAffiliate.is_active
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom" }, [
        _c("div", { staticClass: "col font-weight-bold py-1" }, [
          _vm._v("\n            Created At\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col py-1" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("utcToLocal")(_vm.selectedAffiliate.created_at)) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom" }, [
        _c("div", { staticClass: "col font-weight-bold py-1" }, [
          _vm._v("\n            Updated At\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col py-1" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("utcToLocal")(_vm.selectedAffiliate.updated_at)) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col" }, [
            _c("h5", { staticClass: "text-uppercase m-0" }, [_vm._v("Players")])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [_vm._v("ID")]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [_vm._v("Surname")]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [_vm._v("Email Confirmed")]),
              _vm._v(" "),
              _vm.$can("Player - View Details")
                ? _c("div", { staticClass: "col" }, [
                    _vm._v("\n                    Operations\n                ")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            !!_vm.players.length
              ? _vm._l(_vm.players, function(player, index) {
                  return _c("div", { key: index, staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("ID")
                      ]),
                      _vm._v(
                        "\n                        # " +
                          _vm._s(player.user.id) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Name")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(player.user.name) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Surname")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(player.user.surname) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Email Confirmed")
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa",
                        class: {
                          "fa-check text-success": !!player.user.activated_at,
                          "fa-times text-danger": !player.user.activated_at
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.$can("Player - View Details")
                      ? _c("div", { staticClass: "col-12 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Operations")
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/players/" + player.user.id + "/view",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            View Player\n                        "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            _c("not-found", {
              attrs: { items: _vm.players, msg: "No users found" }
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }