import { clone } from '../../utils/Json/Json';
import PaginationModel from './PaginationModel';

/**
 * Paginate data
 * @param items
 * @param current_page
 * @param per_page
 * @return {{per_page: number, data: *, last_page: number, from: number, to: number, current_page: number}}
 */
export default (items, current_page = 1, per_page = 25) => {
    if (!Array.isArray(items)) {
        throw new Error('Items attribute must be array.');
    }

    const totalItems = items.length;

    let last_page = Math.ceil(totalItems / per_page);
    last_page = !last_page ? 1 : last_page

    if (current_page > last_page) {
        current_page = last_page;
    }

    let from = current_page * per_page - per_page;
    let to = current_page * per_page;

    return Object.assign(
        clone(PaginationModel),
        {
            current_page: (!current_page ? 1 : current_page),
            per_page,
            from,
            to,
            last_page,
            data: items.slice(from, to)
        }
    );
}