<template>
    <div class="container-fluid">
        <template v-if="!isLoading && !!callId && !isCustomerToCallModelEmpty">
            <no-leads-have-been-found-component v-if='!isCustomerToCallModelEmpty && !!canCallThisLead'
                                                :message="`This lead has status Not Interested and cannot be called again until ${customer.unlock_at}.`"
            />
            <template v-else>
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <a :href="`/marketing/campaigns/${type}`"
                           class="btn btn-sm btn-block btn-primary font-weight-bold"
                        >
                            <i class="fa fa-chevron-left"></i> Back to campaigns
                        </a>
                        <campaign-customer-call-component :customer="customer"
                                                          :campaign="campaignModel"
                                                          :customer-campaign-id="customerToCallModel.customer_call_id"
                                                          @add-status-state="onAddStatusStateChanged"
                        />

                        <customer-dispositions-component v-if="$can('Leads - Add Status To Leads')"

                                                         :is-loading="customerDispositionStatusesLoader"
                                                         :statuses="customerDispositionStatusesList"
                        />

                        <last-calls-component v-if="$can('Leads - View Calls')"
                                              :is-loading="leadLastCallsLoading"
                                              :latest-calls-response="leadLastCallsResponse"
                        />
                    </div>
                    <div class="col-12 col-lg-6">
                        <add-status-component v-if="$can('Leads - Add Status To Leads') && !isLastStatusSale && !!isAddStatusAllowed"
                                              :campaignId="id"
                                              :statuses="customerDispositionList"
                                              :is-loading="customerDispositionLoader || addStatusLoader"
                                              :model="statusModel"
                                              :model-errors="statusModelErrors"
                        />

                        <customer-details-component :customer="previewCustomerModel"
                                                    :show-create-player="!!isLeadCampaign() && !isLastStatusSale && !shouldGoNext"
                                                    :customer-instant-access-loader="customerInstantAccessLoader"
                        />

                        <div class="d-flex align-items-center justify-content-end"
                             v-if="shouldGoNext"
                        >
                            <button v-if="isCampaignCallViewType"
                                    class="btn btn-primary"
                                    @click="fetchCustomerToCall(type, id)"
                            >
                                Next Lead
                            </button>
                            <button v-else
                                    class="btn btn-primary"
                                    @click="closeWindow"
                            >
                                Next Lead
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <call-notes :callable-id="callId"
                                    :callable-type="type"
                        />
                    </div>
                </div>
            </template>
        </template>

        <no-leads-have-been-found-component v-if='!isLoading && !callId && !!isCustomerToCallModelEmpty'
        >
            <div class="text-center mt-3">
                <a :href="`/marketing/campaigns/${type}`">
                    <i class="fa fa-chevron-left"></i> Back to campaigns
                </a>
            </div>
        </no-leads-have-been-found-component>

        <loader :show="!!isLoading"
                type="small"
        />

        <add-leads-modal-component v-if="!!isLeadCampaign() && $can('Leads - Create/Edit') && isLeadModalOpen"
                                   :model="leadModel"
                                   :errors="leadModelErrors"
                                   :is-loading="addLeadLoader"
                                   :is-from-call="true"
        />

        <create-player-component v-if="!!isLeadCampaign() && $can('Player - Create/Update') && !!createPlayerModalOpen"
                                 :client-model="customer"
                                 :additional-model-data="{
                                     lead_id: customer.id,
                                     campaign_id: id,
                                 }"
                                 :isLead="true"
                                 @created-lead="onCreatedLead"
                                 @close="toggleCreatePlayerModal"
        />
    </div>
</template>

<script>
import Campaigns from '../../../../../constants/Campaigns';
import AddLeadsModalComponent from '../../../_Components/AddLeadsModal/AddLeadsModalComponent';
import NoLeadsHaveBeenFoundComponent from './NoLeadsHaveBeenFound/NoLeadsHaveBeenFoundComponent';
import CampaignCustomerCallComponent from './Call/CampaignCustomerCallComponent';
import CustomerDispositionsComponent from './CustomerDispositions/CustomerDispositionsComponent';
import CallNotes from '../../../../../components/CallNote/CallNotes';
import AddStatusComponent from './AddStatus/AddStatusComponent';
import CustomerDetailsComponent from './CustomerDetails/CustomerDetailsComponent';
import MarketingService from '../../../../../services/MarketingService';
import { clone } from '../../../../../utils/Json/Json';
import CampaignModel from '../../Editor/CampaignFormEdit/CampaignModel';
import LastCallsComponent from './LastCalls/LastCallsComponent';
import AddLeads from '../../../_Components/AddLeadsModal/AddLeads';
import CustomerToCall from './CustomerToCall/CustomerToCall';
import CustomerDispositions from './CustomerDispositions/CustomerDispositions';
import AddStatus from './AddStatus/AddStatus';
import CreatePlayer from './CreatePlayer';
import CreatePlayerComponent from '../../../../Players/Components/Forms/CreatePlayer/CreatePlayer';
import LastCalls from './LastCalls/LastCalls';
import Campaign from '../../Campaign';
import Loader from '../../../../../components/Widgets/Loader';
import CustomerDetails from './CustomerDetails/CustomerDetails';

export default {
    name: 'campaigns-call-view-page',
    mixins: [
        Campaign,
        AddLeads,
        CustomerToCall,
        CustomerDispositions,
        AddStatus,
        CreatePlayer,
        LastCalls,
        CustomerDetails,
    ],
    components: {
        AddLeadsModalComponent,
        NoLeadsHaveBeenFoundComponent,
        CampaignCustomerCallComponent,
        LastCallsComponent,
        CustomerDispositionsComponent,
        CallNotes,
        AddStatusComponent,
        CustomerDetailsComponent,
        CreatePlayerComponent,
        Loader,
    },
    props: {
        type: {
            type: String,
            default: null
        },
        id: {
            type: Number,
            default: null
        },
        customerid: {
            type: Number,
            default: null
        },
        agentid: {
            type: Number,
            default: null
        }
    },
    provide() {
        return {
            campaignType: this.type,
            campaignId: this.id,
            customerId: this.customerid,
            agentId: this.agentid,
        };
    },
    data() {
        return {
            campaignLoader: false,
            campaignModel: clone(CampaignModel),
        };
    },
    computed: {
        isLoading() {
            return this.campaignLoader || this.customerToCallLoader;
        },
        canCallThisLead() {
            if (!this.customerid) {
                return false;
            }

            if (!!this.isCustomerToCallModelEmpty) {
                return false;
            }

            const { status_name, unlock_at } = this.customer;

            if (status_name !== 'Not Interested') {
                return false;
            }

            const unlockAt = new Date(this.utcToLocal(unlock_at));

            return (new Date().getTime() - unlockAt.getTime()) < 0;
        },
        isLastStatusSale() {
            const lastStatus = this.customerDispositionStatusesList[0];

            if (!lastStatus) {
                return false;
            }

            return lastStatus.status_name === Campaigns.SaleStatusName &&
                (!!lastStatus.campaign_id && lastStatus.campaign_id === this.id);
        },
        isCampaignCallViewType() {
            return this.campaignModel.view_type === Campaigns.ViewTypes.callView;
        },
    },
    async created() {
        if (!this.id) {
            window.showMessage('Campaign id is undefined.', false);

            return;
        }

        if (!this.$can('Campaigns - Get By Id')) {
            window.showMessage('No permissions for this action.', false);
            return;
        }

        await this.fetchCampaignById();

        await this.fetchCustomerToCall(this.type, this.id, this.customerid);

        await this.fetchCustomerDispositions();

        this.campaignLoader = false;
    },
    methods: {
        async fetchCampaignById() {
            if (!this.$can('Campaigns - Get By Id')) {
                window.showMessage('ou does not have permissions for this action.', false);
                return;
            }

            this.campaignLoader = true;

            try {
                const response = await MarketingService.getCampaignById(this.type, this.id);
                const data = response.data;

                if (!!data.hasOwnProperty('status') && !data.status) {
                    window.location.href = '/marketing/campaigns';

                    return;
                }

                this.campaignModel = data;
            } catch (error) {
                this.campaignLoader = false;

                window.flashError(error);
            }
        },
        onSuccessAddLead() {
            this.toggleAddLeadModal();
        },
        closeWindow() {
            window.close();
        },
    },
}
</script>