var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AgentsSalesCardsList",
    {
      attrs: { agents: _vm.agents, sales: _vm.sales, isLoading: _vm.isLoading }
    },
    [_vm._t("logout")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }