<template>
    <div class="container-fluid">
        <bundle-tickets-search class="mb-3"
                               v-if="showFilterForm"
                               @search="onSearchEvent"
                               @clear="clearSearchModel"
        />

        <div v-if="!!showTable"
             class="card card-list"
        >
            <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                    <i class="fa fa-ticket-alt"></i> Bundle tickets
                    <span v-if="tickets.length">
                        ({{ tickets.length }})
                    </span>
                </div>
                <span v-if="!!hasMore"
                      class="text-danger"
                >
                    Search result contains more than {{ limitResults }} rows, please narrow your search.
                </span>
                <div class="d-flex align-items-center">
                    <atbl-select v-if="!!tickets.length"
                                 :options="paginationModel.data"
                                 :value="paginationModel.default"
                                 :first-option-label="null"
                                 option-label="text"
                                 class="mr-2 mb-0"
                                 @input="onPaginationLimitChangeEvent"
                    />
                    <button class="btn btn-sm btn-outline-primary m-0"
                              @click="showFilterForm = ! showFilterForm"
                    >
                        <i class="fa fa-filter" aria-hidden="false"></i>
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list v-sortable" @sort="onUpdateSortEvent">
                    <div class="row sticky-element">
                        <div v-sort:id="sort" class="col text-muted">Ticket</div>
                        <div v-sort:user_name="sort" class="col text-muted text-center">Created By</div>
                        <div v-sort:bundle_id="sort" class="col text-muted text-center">Bundle</div>
                        <div v-sort:title="sort" class="col text-muted text-center">Logo</div>
                        <div v-sort:order_id="sort" class="col text-muted text-center">Order</div>
                        <div v-sort:weeks="sort" class="col text-muted text-center">Weeks</div>
                        <div v-sort:total_prize="sort" class="col text-muted text-center">Winnings</div>
                    </div>

                    <template v-if="!isLoading">
                        <div class="row py-3"
                               v-for="bundleTicket in ticketsData.data"
                               :key="bundleTicket.id"
                        >
                            <div class="col align-self-center">
                                <a :href="`/bundle-tickets/${bundleTicket.id}`" v-if="$can('Player - View Tickets')">
                                    BT# {{ bundleTicket.id }}
                                </a>
                                <div>
                                    <small class="text-muted">{{ bundleTicket.created_at | dateFormat }}</small>
                                </div>
                                <div>
                                    <span>Player: </span>
                                    <a :href="`/players/${bundleTicket.player_id}/view`" v-if="$can('Player - View Details')">
                                        {{ bundleTicket.name }} {{ bundleTicket.surname }}
                                    </a>
                                    <span v-else>
                                        {{ bundleTicket.name }} {{ bundleTicket.surname }}
                                    </span>
                                </div>
                            </div>

                            <div class="col align-self-center text-center">
                                <created-by-badge :created="bundleTicket.user_name" />
                            </div>

                            <div class="col text-center align-self-center">
                                <a :href="`/bundles/${bundleTicket.bundle_id}/edit`" v-if="$can('Bundles - Create/Update')">
                                    B#{{ bundleTicket.id }} <br/> {{ bundleTicket.title }}
                                </a>
                                <span v-else>
                                    B#{{ bundleTicket.id }} <br/> {{ bundleTicket.title }}
                                </span>
                            </div>

                            <div class="col text-center align-self-center">
                                <img :src="getBundleLogoUrl(bundleTicket.bundle_id)" :alt="`${bundleTicket.title}`" style="width: 60px; height: auto;">
                            </div>

                            <div class="col text-center align-self-center">
                                <a :href="`/orders/${bundleTicket.order_id}`" v-if="$can('Bets - View Bet Details')">
                                    O# {{ bundleTicket.order_id }}
                                </a>
                                <span v-else>
                                    O# {{ bundleTicket.order_id }}
                                </span>
                            </div>

                            <div class="col text-center align-self-center">
                                {{ bundleTicket.weeks }}
                            </div>

                            <div class="col align-self-center d-flex flex-column text-center text-nowrap">
                                <span>
                                    {{ formatPrize(bundleTicket.total_prize) }}
                                </span>
                                <span class="text-black-50 text-center text-value-sm">
                                    {{ bundleTicket.parsed }}
                                </span>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />

                    <not-found v-if="!isLoading"
                               msg="No bundle tickets found."
                               :items="tickets"
                    />
                </div>
            </div>

            <div v-if="!isLoading && ticketsData.last_page > 1"
                 class="card-footer overflow-x-auto"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <nav class="wrap">
                        <atbl-pagination :pagination="ticketsData"
                                         :scroll="false"
                                         @paginate="onPageChangeEvent"
                        />
                    </nav>
                    <a href="#"
                       @click.prevent="$scrollToTop()"
                    >
                        <i class="fa fa-caret-up mr-2"></i> Back to top
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BundleTicketsSearch from './Components/BundleTicketsSearch';
import BundleService from '../../../services/BundleService';
import BundleTicketsService from '../../../services/BundleTicketsService';
import { filterNonEmpty } from '../../../utils';
import SaveObjectToUrl from '../../../utils/Url/SaveObjectToUrl';
import DynamicSort from '../../../utils/Array/DynamicSort';
import { clone } from '../../../utils/Json/Json';
import Paginate from '../../../modules/pagination/Paginate';
import PaginationModel from '../../../modules/pagination/PaginationModel';
import PaginateOptions from '../../../data/PaginateOptions';

const sortObject = {
    sortBy: 'id',
    direction: 'DESC',
    sortOnInit: false,
};

export default {
    name: 'bundle-tickets-index',
    components: {
        BundleTicketsSearch
    },
    data() {
        return {
            showFilterForm: true,
            hasMore: false,
            limitResults : 1000,
            tickets: [],
            ticketsData: clone(PaginationModel),
            isLoading: false,
            model: {},
            paginationModel: clone(PaginateOptions),
            sort: clone(sortObject),
            showTable: false,
            page: 1,
        };
    },
    computed: {
        hasModelValues() {
            return !!filterNonEmpty(this.model).length;
        },
    },
    methods: {
        onSearchEvent(data) {
            if (data.hasOwnProperty('otherParams')) {
                const { page, sort, paginate } = data.otherParams;

                this.page = page;
                this.sort = sort;
                this.paginationModel.default = paginate;
            }

            this.model = data.hasOwnProperty('searchModel')
                ? data.searchModel
                : data;

            this.getBundleTickets();
        },
        async getBundleTickets() {
            if (!this.hasModelValues) {
                window.showMessage('Search form cannot be empty.', false);

                return;
            }

            this.showTable = true;
            this.isLoading = true;

            const response = await BundleTicketsService.index(this.model);

            const { tickets, hasMore, limitResults  } = response.data;

            this.hasMore = hasMore;
            this.limitResults = limitResults;
            this.tickets = tickets;

            this.setOrdersData(this.page);

            this.isLoading = false;
        },
        onPageChangeEvent(page = 1) {
            this.setOrdersData(page);
        },
        onUpdateSortEvent(e) {
            this.sort = {
                sortBy: e.detail.key,
                direction: e.detail.direction,
                sortOnInit: sortObject.sortOnInit,
            };

            this.setOrdersData();
        },
        setOrdersData(page = 1) {
            SaveObjectToUrl({
                ...this.model,
                sort: this.sort,
                paginate: this.paginationModel.default,
                page: page,
            });

            this.ticketsData = this.sortAndPaginateData(page);
        },
        sortAndPaginateData(page = 1) {
            const { sortBy, direction } = this.sort;

            const sortOrders = DynamicSort(
                clone(this.tickets),
                sortBy,
                direction
            );

            return Paginate(
                sortOrders,
                page,
                this.paginationModel.default
            );
        },
        onPaginationLimitChangeEvent(value) {
            this.paginationModel.default = value;

            this.setOrdersData();
        },
        getBundleLogoUrl(id) {
            return BundleService.makeLogoPreviewUrl(id);
        },

        formatPrize(prize) {
            return this.$options.filters.formatCurrency(prize || 0)
        },

        clearSearchModel() {
            this.model = {}

            const cleanUrl = window.location.href.split('?')[0];
            if (!!cleanUrl) {
                window.history.pushState({}, '', cleanUrl);
            }
        }
    },
}
</script>