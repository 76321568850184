<template>
    <div>
        <atbl-form-group v-for="(item, key) in fields"
                         :key="key"
                         :label="getLabel(item, key)"
                         :error="getError(errors, key)"
        >
            <atbl-number :value="freeGameModel[key]"
                         :name="key"
                         :min="item.min"
                         :max="!item.max ? item.maxAllowed : getUpTo(key, item.max)"
                         :step="1"
                         :disabled="disabled"
                         @update="changeFieldsInput"
            />
        </atbl-form-group>
    </div>
</template>

<script>
export default {
    name: 'FieldsInputs',
    inject: [
        'changeFieldsInput'
    ],
    props: {
        fields: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        freeGameModel: {
            type: Object,
            default: () => ({})
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        userFreeGameSettings: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        getLabel(item, key) {
            const label = !!item.label
                ? item.label
                : this.ucFirst(key);

            const upTo = this.getUpTo(key, item.max);
            const upToString = !upTo
                ? `(Unlimited)`
                : (!upTo ? '(Max tickets reached.)' : `(Up to ${upTo})`);

            return `${label} ${upToString}`;
        },
        getUpTo(key, max) {
            const freeGameSettingByKey = this.userFreeGameSettings[key];

            if (!freeGameSettingByKey) {
                throw new Error('Settings is not found');
            }

            switch(key) {
                case 'tickets':
                case 'raffle':
                case 'syndicate':
                case 'bundle':
                        return !max
                            ? false
                            : max - freeGameSettingByKey.created;
                default:
                    throw new Error('Key was not found');
            }
        },
    },
}
</script>