var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-list campaign-added-leads-section" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between"
        },
        [
          _c("span", [
            _c("i", {
              staticClass: "nav-icon fa fa-users",
              attrs: { "aria-hidden": "false" }
            }),
            _vm._v(" " + _vm._s(_vm.ucFirst(_vm.campaignTitle)) + "\n        ")
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Reset and reload all campaign data.",
                    expression: "`Reset and reload all campaign data.`"
                  }
                ],
                staticClass: "btn btn-sm btn-warning m-0",
                on: {
                  click: function($event) {
                    return _vm.reFetchData(_vm.campaignId, true)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa fa-sync",
                  attrs: { "aria-hidden": "false" }
                })
              ]
            ),
            _vm._v(" "),
            _vm.$can("Campaigns - Add/Remove Leads")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary m-0",
                    on: { click: _vm.toggleAddLeadsSidebar }
                  },
                  [_vm._v("\n                Add / Remove\n            ")]
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          !!_vm.leadsList.length
            ? _c("campaign-customers-filter-list-component", {
                attrs: {
                  "campaign-id": _vm.campaignId,
                  "campaign-type": _vm.campaignType,
                  "is-loading": _vm.filterListModel.filterListLoader,
                  "filter-list": _vm.filterListModel.filtersList
                },
                on: { change: _vm.changeModelFilters }
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.leadsList.length
            ? _c("hr", {
                class: {
                  "opacity-none": _vm.inCampaignLeadsLoader
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "campaign-added-leads-section-list" },
            [
              !_vm.inCampaignLeadsLoader
                ? _vm._l(_vm.leadsList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "campaign-added-leads-section-list-item"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "campaign-added-leads-section-list-item-header"
                          },
                          [
                            _c("span", [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Copy to clipboard",
                                      expression: "`Copy to clipboard`"
                                    }
                                  ],
                                  staticClass: "mr-1",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.copyToClipboard(item.id)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                # " +
                                      _vm._s(item.id) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-separator" }, [
                                _vm._v("/")
                              ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.name) +
                                  " " +
                                  _vm._s(item.surname) +
                                  "\n                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Copy to clipboard",
                                      expression: "`Copy to clipboard`"
                                    }
                                  ],
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.copyToClipboard(item.email)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.email) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _vm.$can("Leads - Details")
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.getCustomerUrl(item),
                                            target: "_blank"
                                          }
                                        },
                                        [_c("i", { staticClass: "fa fa-eye" })]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("confirmable-remove-button", {
                                    attrs: { value: item },
                                    on: {
                                      confirmed: function($event) {
                                        return _vm.removeLeadOutOfCampaign(
                                          $event,
                                          _vm.campaignId,
                                          _vm.campaignType
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "campaign-added-leads-section-list-item-body"
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "small-mb-badge show mb-0" },
                                [_vm._v("Last Disposition")]
                              ),
                              _vm._v(" "),
                              !!item.status_name
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "badge badge-primary" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.status_name) +
                                            "\n                                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        _vm._s(_vm.utcToLocal(item.status_date))
                                      )
                                    ])
                                  ])
                                : _c(
                                    "span",
                                    { staticClass: "font-weight-normal" },
                                    [_vm._v("/")]
                                  )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "small-mb-badge show mb-0" },
                                [_vm._v("Live Spent")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "font-weight-normal" },
                                [
                                  !!item.live_spent
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                item.live_spent
                                              )
                                            ) +
                                            "\n                                "
                                        )
                                      ])
                                    : _c("span", [_vm._v("/")])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "small-mb-badge show mb-0" },
                                [_vm._v("Phone")]
                              ),
                              _vm._v(" "),
                              item.phone
                                ? _c(
                                    "div",
                                    { staticClass: "font-weight-normal" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.phone) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.mobile
                                ? _c(
                                    "div",
                                    { staticClass: "font-weight-normal" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.mobile) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "small-mb-badge show mb-0" },
                                [_vm._v("Country")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "font-weight-normal" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.emptyField(item.country)) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", {
                attrs: { show: _vm.inCampaignLeadsLoader, type: "small" }
              }),
              _vm._v(" "),
              !_vm.inCampaignLeadsLoader
                ? _c("not-found", {
                    attrs: {
                      items: _vm.leadsList,
                      msg: "No " + _vm.campaignTitle + " added to campaign."
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.inCampaignLeadsLoader &&
              _vm.inCampaignLeadsResponse.last_page > 1
                ? _c(
                    "nav",
                    { staticClass: "wrap pt-3" },
                    [
                      _c("atbl-pagination", {
                        staticClass: "m-0",
                        attrs: {
                          small: true,
                          pagination: _vm.inCampaignLeadsResponse
                        },
                        on: { paginate: _vm.fetchCampaignLeads }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }