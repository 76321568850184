var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid leads-section" },
    [
      _vm._t("beforeCard"),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between align-items-center"
          },
          [
            !_vm.$slots.title
              ? _c("span", [
                  _c("i", { class: _vm.icon }),
                  _vm._v(" " + _vm._s(_vm.title) + "\n            ")
                ])
              : _c("div", [_vm._t("title")], 2),
            _vm._v(" "),
            _c("div", [_vm._t("header")], 2)
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [_vm._t("default")], 2)
      ]),
      _vm._v(" "),
      _vm._t("modals")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }