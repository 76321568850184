export default {
    data() {
        return {
            sendEmailModalOpened: false,
        };
    },
    methods: {
        onToggleEmailModalEvent(value = true) {
            this.sendEmailModalOpened = value;
        },
    },
}