import TypesConst from "../../TypesConst";

export default {
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST](state, payload) {
        state.notificationsList = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_LOADER](state, payload) {
        state.notificationsListLoader = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_WIDGET_DATA](state, payload) {
        state.notificationsWidgetData = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_WIDGET_DATA_LOADER](state, payload) {
        state.notificationsWidgetDataLoader = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_RESPONSE](state, payload) {
        state.notificationsListResponse = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_READ_BAR](state, payload) {
        state.notificationReadBar = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_READ_BAR_LOADER](state, payload) {
        state.notificationReadBarLoader = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_MODEL](state, payload) {
        state.notificationModel = payload;
    },
    [TypesConst.notifications.mutations.SET_NOTIFICATIONS_MODEL_FIELD](state, payload) {
        Object.assign(state.notificationModel, payload);
    },
};