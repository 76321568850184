<template>
	<div class="card card-list">
		<div class="card-header d-flex align-items-center justify-content-between">
            <span>
                <i class="fa fa-phone"></i> Callbacks <span class="badge badge-primary">{{ selectedPeriod }}</span>
            </span>
			<button class="btn btn-sm btn-outline-primary"
			          @click="showHideFilter"
			>
				<i class="fa fa-filter"></i>
			</button>
		</div>
		
		<div class="card-body">
			<div class="general-list">
				<div class="row">
					<div class="col-12 col-lg-3">
						Player
					</div>
					<div class="col-12 col-lg-2">
						Country
					</div>
					<div v-if="!getIsOnlyAgent" class="col-12 col-lg-2">
						Agent
					</div>
					<div class="col-12 col-lg-2">
						Disposition
					</div>
					<div class="col-12 col-lg-3">
						<a href="#" sort="follow_up" @click="sortChange" class="sort-link"
						   :class="{'active': getSearchModel.sort.sort === 'follow_up'}">
							Follow Up <span v-if="getSearchModel.sort.sort === 'follow_up'"><i class="fa"
							                                                                   :class="{'fa-caret-up': getSearchModel.sort.value === 'ASC', 'fa-caret-down': getSearchModel.sort.value === 'DESC'}"></i> </span>
						</a>
					</div>
				</div>
				
				<div v-if="!getCallbackListLoader"
				       v-for="(item, index) in getCallbackList"
				       :key="index"
					   class="row"
				>
					<div class="col-12 col-lg-3">
						<span class="small-mb-badge">Player</span>
						<a :href="`/players/${item.player_id}/view`" target="_blank"
						        class="text-dark"
						>
							{{ item.player_name }} {{ item.player_surname }}
						</a>
						<br/>
						<a href="#"
							v-if="item.player_phone"
							class="callbacks-phone"
							@click.prevent="openCallWindow(item.player_phone, item.player_id)"
						>
							{{ item.player_phone }}
						</a>
						<br/>
						<a href="#"
							v-if="item.player_mobile"
							class="callbacks-phone"
							@click.prevent="openCallWindow(item.player_mobile, item.player_id)"
						>
							{{ item.player_mobile }}
						</a>
					</div>
					<div class="col-12 col-lg-2">
						<span class="small-mb-badge">Country</span>
						{{ item.player_country }}
					</div>
					<div v-if="!getIsOnlyAgent" class="col-12 col-lg-2">
						<span class="small-mb-badge">Agent</span>
						{{ item.agent_name }}
					</div>
					<div class="col-12 col-lg-2">
						<span class="small-mb-badge">Disposition</span>
						<span class="badge badge-success">
							{{ item.disposition_name }}
						</span>
					</div>
					<div class="col-12 col-lg-3">
						<span class="small-mb-badge">Follow Up</span>
						{{ item.follow_up }}
					</div>
				</div>
				
				<loader :show="getCallbackListLoader"
				        type="small"
				/>
				<not-found v-if="!getCallbackListLoader"
				           msg="No callbacks found."
				           :items="getCallbackList"
				/>
			</div>
		</div>
		
		<div v-if="!getCallbackListLoader && getCallbackListResponse.last_page > 1" class="card-footer">
			<nav class="wrap">
				<atbl-pagination class="m-0"
				                 :pagination="getCallbackListResponse"
				                 @paginate="getCallbacksAction"
				/>
			</nav>
		</div>
	</div>
</template>

<script>
	import TypesConst from "../../../store/TypesConst";
	import NamespaceConst from "../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";
	
	const {
		mapGetters: mapCallbackGetters,
		mapMutations: mapCallbackMutations,
		mapActions: mapCallbackActions
	} = createNamespacedHelpers(NamespaceConst.callback);
	
	import moment from "moment";
	import PlayerCallMixin from "../../Players/Mixins/PlayerCallMixin";
	
	export default {
		name: "CallbackList",
		mixins: [
			PlayerCallMixin
		],
		computed: {
			...mapCallbackGetters({
				getCallbackList: TypesConst.callback.getters.GET_CALLBACK_LIST,
				getCallbackListResponse: TypesConst.callback.getters.GET_CALLBACK_LIST_RESPONSE,
				getCallbackListLoader: TypesConst.callback.getters.GET_CALLBACK_LIST_LOADER,
				getSearchModel: TypesConst.callback.getters.GET_CALLBACK_LIST_MODEL
			}),
			selectedPeriod: function () {
				if (this.getSearchModel.dateFrom && this.getSearchModel.dateTo) {
					if (this.getSearchModel.dateFrom === this.getSearchModel.dateTo) {
						return this.getSearchModel.dateFrom;
					}
					return `${this.getSearchModel.dateFrom} - ${this.getSearchModel.dateTo}`;
				}
				return moment().format("YYYY.MM.DD");
			}
		},
		methods: {
			...mapCallbackMutations({
				setModelField: TypesConst.callback.mutations.SET_CALLBACK_LIST_MODEL
			}),
			...mapCallbackActions({
				getCallbacksAction: TypesConst.callback.actions.CALLBACK_GET_LIST_ACTION
			}),
			showHideFilter: function () {
				this.$emit("filterToggled");
			},
			sortChange: function (e) {
				e.preventDefault();
				this.setModelField({
					sort: {
						sort: e.target.getAttribute("sort"),
						value: this.getSearchModel.sort.value === "ASC" ? "DESC" : "ASC"
					}
				});
			},
		}
	}
</script>