<template>
	<span :class="`badge ${wallet.amount > 0 ? 'badge-success' : 'badge-danger'}`"
          class="atbl-badge-padding mb-1"
    >
		<loader :show="walletLoader"
                type="smaller"
                class="white"
        />
		<span v-if="!walletLoader"
              class="h6"
        >
            <i class="fa fa-wallet mr-1"></i> <strong>{{ wallet.amount | formatCurrency }}</strong>
        </span>
	</span>
</template>

<script>
    import PlayerService from "../../../../services/PlayerService";

    export default {
        name: 'wallet-widget',

	    props: {
        	player: {
        		type: Object,
		        required: true
	        }
	    },

	    data() {
        	return {
        		wallet: this.player.wallet,
		        walletLoader: false
	        };
	    },

        created () {
            Atbl.$on("fetchPlayerWallet", () => {
                this.getPlayerWalletAction();
            });
        },

	    methods: {
        	getPlayerWalletAction() {
        		this.walletLoader = true;

		        PlayerService.fetchPlayerWallet(this.player.id).then((response) => {
				        const { message, status, data } = response.data;
				        this.wallet = data;
				        window.showMessage(message, status);
			        })
			        .finally(() => {
				        this.walletLoader = false;
			        });
	        }
	    },
    }
</script>