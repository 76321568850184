var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-search" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-3 mb-2 m-lg-0" },
              [
                _c("atbl-switch", {
                  attrs: {
                    off: false,
                    on: true,
                    name: "disabledOnly",
                    label: "Show disabled only bundle"
                  },
                  model: {
                    value: _vm.disabledOnly,
                    callback: function($$v) {
                      _vm.disabledOnly = $$v
                    },
                    expression: "disabledOnly"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary",
              attrs: { type: "submit" },
              on: { click: _vm.fetchBundles }
            },
            [
              _c("i", { staticClass: "fa fa-search" }),
              _vm._v(" Search\n            ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between align-items-center"
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm.$can("Bundles - Create/Update")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    on: { click: _vm.openCreateModal }
                  },
                  [_vm._v("\n                Create\n            ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(2),
              _vm._v(" "),
              _vm._l(_vm.bundles, function(bundle) {
                return [
                  _c("div", { key: bundle.id, staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm.$can("Bundles - View List")
                        ? _c(
                            "a",
                            {
                              staticClass: "d-block",
                              attrs: { href: "/bundles/" + bundle.id + "/edit" }
                            },
                            [
                              _c("i", { staticClass: "fa fa-link" }),
                              _vm._v(
                                " " +
                                  _vm._s(bundle.id) +
                                  "\n                            "
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              "\n                                Id: " +
                                _vm._s(bundle.id) +
                                "\n                            "
                            )
                          ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(bundle.title) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(bundle.bundle_games, function(bundleGame) {
                        return _c("img", {
                          staticStyle: { width: "40px", height: "auto" },
                          attrs: {
                            src: _vm.$imageService.gameImage(
                              bundleGame.game.image
                            )
                          }
                        })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(bundle.description) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(bundle.ribbon_text) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm._f("formatCurrency")(
                              _vm.getBundlePrice(bundle).toFixed(2)
                            )
                          ) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(bundle.bundle_games[0].number_of_lines) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getBundleCurrencySymbol(bundle))
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(bundle.jackpot) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(bundle.is_active ? "Yes" : "No") +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(bundle.is_crm_enabled ? "Yes" : "No") +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(bundle.is_api_enabled ? "Yes" : "No") +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col text-center" }, [
                      _vm.$can("Bundles - Create/Update")
                        ? _c(
                            "a",
                            {
                              staticClass: "d-block",
                              attrs: { href: "/bundles/" + bundle.id + "/edit" }
                            },
                            [_vm._m(3, true)]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              }),
              _vm._v(" "),
              !_vm.isLoading && !_vm.bundles.length
                ? _c("not-found", {
                    attrs: { msg: "No bundles found", items: _vm.bundles }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isCreateModalVisible && _vm.$can("Bundles - Create/Update")
        ? _c("bundle-create-modal", {
            on: {
              cancel: function($event) {
                _vm.isCreateModalVisible = false
              },
              confirm: _vm.storeBundle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", {
        staticClass: "fa fa-search",
        attrs: { "aria-hidden": "false" }
      }),
      _vm._v(" Bundle filter\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-exchange-alt" }),
      _vm._v(" Bundles\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Reference")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Title")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Games")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Description")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Ribbon text")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Price")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Lines per game")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Jackpot")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Is Active")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Is CRM enabled")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Is API enabled")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted text-center" }, [
        _vm._v("Action")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" Edit bundle")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }