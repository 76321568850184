export default {
    data() {
        return {
            showAssignAgentForm: false,
            selectedPlayerToAssign: {},
        };
    },
    methods: {
        onAssignAgentEvent(player) {
            if(this.hasPlayerInBulk) {
                this.toggleBulkAction();

                return;
            }

            this.selectedPlayerToAssign = player;
            this.showAssignAgentForm = true;
        },
    },
}