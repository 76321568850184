<template>
    <section>
        <div class="card card-list m-0 border-0 bg-transparent">
            <div class="card-header d-flex bg-primary text-white align-items-center justify-content-between border">
                <div>
                    <i class="fa fa-sticky-note"></i> Notes
                </div>
                <div>
                    <button class="btn btn-sm btn-light"
                            @click="callNoteModal = true"
                    >
                        Add Note
                    </button>
                </div>
            </div>

            <div class="card-body p-0"
                 :class="{
                    'bg-white': callNotesList.length === 0,
                    'bg-transparent': callNotesList.length > 0
                 }"
            >
                <div v-for="(item, index) in callNotesList"
                     :key="index"
                     class="card"
                >
                    <div class="card-header p-2">
                        <div>
                            Agent:
                            <span class="badge badge-primary">
                                {{ item.user.name }}
                            </span>
                        </div>
                        <div>
                            Created:
                            <small class="text-dark">
                                {{ utcToLocal(item.created_at) }}
                            </small>
                        </div>
                    </div>
                    <div class="card-body p-2">
                        <p class="m-0">
                            {{ item.note }}
                        </p>
                    </div>
                </div>

                <loader :show="isLoading"
                        type="small"
                />
                <not-found v-if="!isLoading"
                           msg="No call notes found."
                           :items="callNotesList"
                />
            </div>
        </div>

        <add-call-note-modal-component :show="callNoteModal" />
    </section>
</template>

<script>
import AddCallNoteModalComponent from './AddCallNoteModal/AddCallNoteModalComponent';
import CallNotesService from './Services/CallNotesService';

export default {
    name: 'CallNotes',
    props: {
        callableId: {
            type: [Number, String],
            required: true
        },
        callableType: {
            type: String,
            default: 'player'
        }
    },
    provide() {
        return {
            callableId: this.callableId,
            callableType: this.callableType,
            onCallNoteModalClose: this.onCallNoteModalClose,
        };
    },
    components: {
        AddCallNoteModalComponent,
    },
    data() {
        return {
            callNoteModal: false,
            callNotesList: [],
            isLoading: false
        };
    },
    async created() {
        await this.getCallNotes();
    },
    methods: {
        async getCallNotes(page = 1) {
            this.isLoading = true;

            try {
                const response = await CallNotesService.getCallNotes({
                    callableId: this.callableId,
                    callableType: this.callableType,
                    page
                });

                this.callNotesList = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        async onCallNoteModalClose(value, reload) {
            this.callNoteModal = value;

            if (!reload) {
                return;
            }

            await this.getCallNotes();
        }
    },
}
</script>