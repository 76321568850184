var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isset(_vm.game, "key")
    ? _c("div", { staticClass: "card player-play mb-3" }, [
        _c(
          "div",
          { staticClass: "card-header card-header-atbl" },
          [
            _c("LotteryGameHeader", {
              attrs: {
                game: _vm.game,
                configuration: _vm.getNumberConfiguration()
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "player-play-picker" },
            [
              _c("validation-errors", { attrs: { errors: _vm.errors } }),
              _vm._v(" "),
              _c("div", { staticClass: "player-play-picker-wrapper" }, [
                _c(
                  "div",
                  [
                    _c("LinesPicker", {
                      attrs: {
                        lines: _vm.lines,
                        game: _vm.game,
                        "selected-free-game": _vm.selectedFreeGame
                      },
                      on: { updateLines: _vm.updateLines }
                    }),
                    _vm._v(" "),
                    !_vm.hasSelectedFreeGame && _vm.closeToNextLineDiscount
                      ? _c(
                          "p",
                          { staticClass: "alert alert-warning p-2 w-100" },
                          [
                            _c("strong", [
                              _vm._v(
                                "Note: You are one line away to get a " +
                                  _vm._s(
                                    _vm.closeToNextLineDiscount.discount_crm
                                  ) +
                                  "% discount !"
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "ticket-options" }, [
                      _vm.isset(_vm.game, "ticketNumbers") &&
                      !Array.isArray(_vm.game.ticketNumbers)
                        ? _c(
                            "div",
                            { staticClass: "ticket-options-numbers" },
                            [
                              _c("TicketNumber", {
                                attrs: {
                                  minRange:
                                    _vm.game.ticketNumbers.numbers.ticket
                                      .minRange,
                                  maxRange:
                                    _vm.game.ticketNumbers.numbers.ticket
                                      .maxRange + 1,
                                  maxNumbers:
                                    _vm.game.ticketNumbers.numbers.ticket
                                      .numbers
                                },
                                model: {
                                  value: _vm.changeTicketNumber,
                                  callback: function($$v) {
                                    _vm.changeTicketNumber = $$v
                                  },
                                  expression: "changeTicketNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ticket-options-draw" },
                        [
                          _c("atbl-select", {
                            attrs: {
                              name: "weeks",
                              label: "# of Weeks",
                              options: _vm.getWeeks,
                              value: _vm.draws,
                              disabled: _vm.hasSelectedFreeGame
                            },
                            on: { input: _vm.updateDraws }
                          }),
                          _vm._v(" "),
                          _c(
                            "atbl-form-group",
                            {
                              staticClass: "weekdays font-weight-normal",
                              attrs: { label: "Weekdays" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "weekdays-holder" },
                                _vm._l(_vm.game.weekdays, function(
                                  weekday,
                                  index
                                ) {
                                  return _c(
                                    "label",
                                    {
                                      key: index,
                                      staticClass: "mb-1",
                                      attrs: { for: weekday }
                                    },
                                    [
                                      !_vm.hasSelectedFreeGame
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.weekdays,
                                                expression: "weekdays"
                                              }
                                            ],
                                            staticClass: "mr-1",
                                            attrs: {
                                              disabled:
                                                _vm.weekdays.length < 2 &&
                                                _vm.weekdays.indexOf(
                                                  weekday
                                                ) !== -1,
                                              type: "checkbox",
                                              id: weekday
                                            },
                                            domProps: {
                                              value: weekday,
                                              checked: Array.isArray(
                                                _vm.weekdays
                                              )
                                                ? _vm._i(
                                                    _vm.weekdays,
                                                    weekday
                                                  ) > -1
                                                : _vm.weekdays
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a = _vm.weekdays,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = weekday,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.weekdays = $$a.concat(
                                                        [$$v]
                                                      ))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.weekdays = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.weekdays = $$c
                                                }
                                              }
                                            }
                                          })
                                        : _c("input", {
                                            staticClass: "mr-1",
                                            attrs: {
                                              id: weekday,
                                              type: "radio",
                                              name: "weekdays[]"
                                            },
                                            domProps: {
                                              value: weekday,
                                              checked:
                                                _vm.weekdays.indexOf(
                                                  weekday
                                                ) !== -1
                                            },
                                            on: { input: _vm.selectWeekday }
                                          }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v(_vm._s(weekday))])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    !_vm.hasSelectedFreeGame && _vm.closeToNextDrawDiscount
                      ? _c(
                          "div",
                          { staticClass: "alert alert-warning mt-1 p-2 w-100" },
                          [
                            _c("strong", [
                              _vm._v(
                                "Note: You are one draw away to get a " +
                                  _vm._s(
                                    _vm.closeToNextDrawDiscount.discount_crm
                                  ) +
                                  "% discount !"
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "buy" }, [
                    _c("div", { staticClass: "buy-total" }, [
                      _c("table", { attrs: { width: "100%" } }, [
                        _c("tr", { staticClass: "border-bottom" }, [
                          _c("td", { attrs: { align: "left" } }, [
                            _vm.game.drawsPerTicket
                              ? _c("span", [_vm._v("Draws:")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.game.weeksPerTicket
                              ? _c("span", [_vm._v("Weeks:")])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("td", { attrs: { align: "right" } }, [
                            _c(
                              "span",
                              { staticClass: "buy-total-price ml-1" },
                              [_vm._v(_vm._s(_vm.draws))]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.game.weeksPerTicket
                          ? _c("tr", { staticClass: "border-bottom" }, [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("td", { attrs: { align: "right" } }, [
                                _c(
                                  "span",
                                  { staticClass: "buy-total-price ml-1" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.weekdays.length * _vm.draws
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tr", { staticClass: "border-bottom" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("td", { attrs: { align: "right" } }, [
                            !_vm.hasSelectedFreeGame
                              ? _c(
                                  "span",
                                  { staticClass: "buy-total-price ml-1" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.lines.length) +
                                        " x " +
                                        _vm._s(
                                          _vm._f("formatCurrency")(
                                            _vm.game.price
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "buy-total-price ml-1" },
                                  [
                                    _vm._v(
                                      "\n\n                                                " +
                                        _vm._s(_vm.lines.length) +
                                        " "
                                    ),
                                    _c("s", [
                                      _vm._v(
                                        "x " +
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              _vm.game.price
                                            )
                                          )
                                      )
                                    ])
                                  ]
                                )
                          ])
                        ]),
                        _vm._v(" "),
                        !_vm.hasSelectedFreeGame
                          ? _c("tr", { staticClass: "border-bottom" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("td", { attrs: { align: "right" } }, [
                                _c(
                                  "span",
                                  { staticClass: "buy-total-price ml-1" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.totalDiscount) +
                                        " %\n\t\t\t\t\t\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tr", [
                          _vm._m(3),
                          _vm._v(" "),
                          !_vm.hasSelectedFreeGame
                            ? _c("td", { attrs: { align: "right" } }, [
                                _vm.totalDiscount === 0
                                  ? _c(
                                      "strong",
                                      { staticClass: "buy-total-price ml-1" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency")(_vm.total)
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.totalDiscount !== 0
                                  ? _c(
                                      "strong",
                                      { staticClass: "buy-total-price ml-1" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "text-decoration": "line-through"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  _vm.total
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.totalWithDiscount
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _c("td", { attrs: { align: "right" } }, [
                                _c(
                                  "strong",
                                  { staticClass: "buy-total-price ml-1" },
                                  [
                                    _c("s", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatCurrency")(_vm.total)
                                        )
                                      )
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(_vm._f("formatCurrency")(0))
                                    )
                                  ]
                                )
                              ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buy-operations" }, [
                      !_vm.hasSelectedFreeGame
                        ? _c(
                            "button",
                            {
                              staticClass: "btn buy-button btn-sm mb-2",
                              class: {
                                "btn-success": !_vm.addToCardLoading,
                                "btn-outline-success": _vm.addToCardLoading
                              },
                              attrs: {
                                name: "buyBtn",
                                type: "submit",
                                disabled:
                                  _vm.getInvalidLines.length > 0 ||
                                  _vm.addToCardLoading
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addToCart(false)
                                }
                              }
                            },
                            [
                              !_vm.addToCardLoading
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-shopping-cart",
                                      attrs: { "aria-hidden": "false" }
                                    }),
                                    _vm._v(
                                      " Add to Cart\n                                    "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("loader", {
                                attrs: {
                                  show: _vm.addToCardLoading,
                                  type: "smaller"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hasSelectedFreeGame
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-danger buy-button text-uppercase",
                              attrs: {
                                disabled:
                                  _vm.getInvalidLines.length > 0 ||
                                  _vm.addToCardLoading ||
                                  _vm.isPlacingOrder
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addToCart(true)
                                }
                              }
                            },
                            [
                              !_vm.addToCardLoading && !_vm.isPlacingOrder
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-credit-card",
                                      attrs: { "aria-hidden": "false" }
                                    }),
                                    _vm._v(
                                      " Add & Place Order\n                                    "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("loader", {
                                attrs: {
                                  show:
                                    _vm.addToCardLoading || _vm.isPlacingOrder,
                                  type: "smaller"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasSelectedFreeGame
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-warning buy-button text-uppercase",
                              attrs: {
                                disabled:
                                  _vm.getInvalidLines.length > 0 ||
                                  _vm.isUseFreeLoading
                              },
                              on: {
                                click: function($event) {
                                  _vm.useFreeTicket(_vm.ticket())
                                }
                              }
                            },
                            [
                              !_vm.isUseFreeLoading
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-ticket-alt",
                                      attrs: { "aria-hidden": "false" }
                                    }),
                                    _vm._v(
                                      " Use Free Ticket\n                                    "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("loader", {
                                attrs: {
                                  show: _vm.isUseFreeLoading,
                                  type: "smaller"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Draws:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Lines: ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Discount: ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Total:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }