var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Users - Remove")
    ? _c(
        "atbl-modal",
        {
          staticClass: "hide-close",
          attrs: {
            show: _vm.show,
            title: "Remove User",
            "close-on-backdrop": false
          },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "on-visibility-change",
                              null,
                              false
                            )
                          }
                        }
                      },
                      [_vm._v("\n            Close\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-danger",
                        on: { click: _vm.deleteUserEvent }
                      },
                      [
                        !_vm.isDeleting
                          ? _c("span", [_vm._v("Delete")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { show: _vm.isDeleting, type: "smaller" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2191320563
          )
        },
        [_c("h5", [_vm._v("Are you sure you want to delete this user.")])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }