<template>
    <div class="container-fluid roles-manager">
        <div v-if="!isLoading"
             class="row"
        >
            <div class="col-12 col-lg-6">
                <role-manager-component :model="roleModel"
                                        :permissions="permissionsOriginal"
                                        :grouped-permissions="permissionsGrouped"
                />
            </div>
            <div v-if="isEditMode"
                 class="col-12 col-lg-6"
            >
                <role-user-manager-component :model="roleModel"
                                             :users-data="usersData" />
            </div>
        </div>
        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import AtblInput from '../../../components/Forms/Inputs/ATBLInput';
import RoleUserManagerComponent from './RoleUserManager/RoleUserManagerComponent';
import RoleManagerComponent from './RoleManager/RoleManagerComponent';
import { groupBy } from 'lodash';
import { clone } from '../../../utils/Json/Json';
import RoleModel from './RoleModel';

export default {
    name: 'RoleManager',
    components: {
        RoleManagerComponent,
        RoleUserManagerComponent,
        AtblInput,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
    },
    provide() {
        return {
            isEditMode: this.isEditMode,
            roleId: this.id,
            setRoleModel: this.setRoleModel,
        };
    },
    data() {
        return {
            dataLoader: false,

            permissionsOriginal: [],
            permissionsGrouped: {},
            usersData: [],

            roleModel: clone(RoleModel),
        };
    },
    computed: {
        isEditMode() {
            return !!this.id;
        },
        isLoading() {
            return this.dataLoader || this.deletePermissionLoader;
        },
    },
    async created() {
        await this.initializeData();
    },
    methods: {
        async initializeData() {
            this.dataLoader = true;

            try {
                const actions = [
                    window.axios.get('/list-permissions'),
                    window.axios.get('/list-users-all', {
                        params: {
                            sortBy: {
                                direction: 'asc',
                                column: 'name',
                            },
                        },
                    }),
                ];

                if (!!this.isEditMode) {
                    actions.push(window.axios.get(`/roles/${this.id}`));
                }

                const [
                    permissionsResponse,
                    usersResponse,
                    roleResponse
                ] = await Promise.all(actions);

                this.setPermissions(permissionsResponse);
                this.setUsers(usersResponse);
                this.setRoleModel(!!roleResponse ? roleResponse.data : null);

                this.dataLoader = false;
            } catch (error) {
                this.dataLoader = false;

                window.flashError(error);
            }
        },
        setPermissions(response) {
            if (!response) {
                return;
            }

            this.permissionsOriginal = response.data;
            this.permissionsGrouped = groupBy(response.data, permission => permission.text.split(' - ')[0]);
        },
        setUsers(response) {
            if (!response) {
                return;
            }

            this.usersData = response.data;
        },
        setRoleModel(response) {
            if (!response) {
                return;
            }

            Object.assign(this.roleModel, response);
        },

    },
}
</script>