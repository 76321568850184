var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atbl-select", {
    attrs: {
      label: null,
      options: _vm.shifts,
      disabled: _vm.isLoading,
      value: _vm.value,
      firstOptionLabel: "Select shift",
      name: "shift_id"
    },
    on: { update: _vm.changed }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }