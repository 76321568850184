<template>
    <div class="card m-0">
        <div class="card-body">
            <div class="row mt-2"
                   v-if="$can('Payment - Manual Wallet Withdrawals')"
            >
                <div class="col-12 col-md-3">
                    <agent-picker v-model="agent"/>
                </div>
                <div class="col-12 col-md-3">
                    <AmountInput v-model="amount"
                                 :check-limit="true"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <atbl-form-group label="Note" label-for="note" class="font-weight-bold">
                        <input name="note" type="text" placeholder="Leave a note" class="form-control" :value="note" @input="onNoteChange" />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-md-3">
                    <atbl-form-group label="#"
                                  label-for="buyBtn">
                        <button class="btn btn-block"
                                :class="{
                                'btn-primary': !getPaymentLoader,
                                'btn-outline-primary': getPaymentLoader
                            }"
                                :disabled="isSaveEnabled"
                                @click="setWithdrawalConfirmModal(true)"
                        >
                            <span v-if="!getPaymentLoader">Add</span>
                            <loader type="smaller"
                                    :show="getPaymentLoader"
                            />
                        </button>
                    </atbl-form-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../store/NamespaceConst";
    import {createNamespacedHelpers} from "vuex";

    const {
        mapGetters: mapPlayerGetters,
        mapMutations: mapPlayerMutations
    } = createNamespacedHelpers(NamespaceConst.players);

    const {
        mapGetters: mapPaymentGetters
    } = createNamespacedHelpers(NamespaceConst.payment);
    import AmountInput from "../../Funds/Components/AmountInput";

    export default {
        name: "AddManualWithdrawal",
        components: {
            AmountInput
        },
        computed: {
            ...mapPlayerGetters({
                getFundsAmount: TypesConst.players.getters.GET_FUNDS_AMOUNT,
                getFundsAgent: TypesConst.players.getters.GET_FUNDS_AGENT,
                getFundsMeta: TypesConst.players.getters.GET_FUNDS_META,
                getFundsCredit: TypesConst.players.getters.GET_FUNDS_CREDIT_TYPE
            }),
	        ...mapPaymentGetters({
		        getPaymentLoader: TypesConst.payment.getters.GET_PAYMENT_LOADER
	        }),
            amount: {
                get: function () {
                    return this.getFundsAmount;
                },
                set: function (value) {
                    this.setFundsAmount(value);
                }
            },
            agent: {
                get: function () {
                    return this.getFundsAgent;
                },
                set: function (value) {
                    this.setFundsAgent(value);
                }
            },
            note: {
                get: function () {
                    return this.getFundsMeta.note
                },
                set: function (value) {
                    this.setFundsMeta({ key: 'note', value: value})
                }
            },
            isSaveEnabled: function () {
                if (this.getPaymentLoader) {
                    return true;
                } else {
                    if (this.amount === 0 || this.amount > this.amountLimit) {
                        return true;
                    } else {
                        return !this.getFundsAgent
                            ? true
                            : false
                    }
                }
            }
        },
        methods: {
            ...mapPlayerMutations({
                setFundsAmount: TypesConst.players.mutations.SET_FUNDS_AMOUNT,
                setFundsAgent: TypesConst.players.mutations.SET_FUNDS_AGENT,
                setFundsMeta: TypesConst.players.mutations.SET_FUNDS_META,
                setFundsCredit: TypesConst.players.mutations.SET_FUNDS_TRANSACTION_TYPE,
                setWithdrawalConfirmModal: TypesConst.players.mutations.SET_WITHDRAWAL_CONFIRM_MODAL
            }),

            onNoteChange(e) {
                this.setFundsMeta({key: 'note', value: e.target.value});
            },
        }
    }
</script>