<template>
    <sidebar-sub title="Preview Free Game"
                 :value="show"
                 @input="previewFreeGame({})"
                 :body-class="`${show ? 'pt-0': ''}`"
    >
        <div v-if="show">
            <div class="general-list">
                <div class="row">
                    <div class="col font-weight-bold">ID</div>
                    <div class="col">{{ data.id }}</div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Created By</div>
                    <div class="col">
                        <span v-if="isset(data, 'user') && data.user">{{ data.user.name }}</span>
                        <span v-else
                              class="badge badge-danger"
                        >
                            System
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Type</div>
                    <div class="col">{{ $options.ResolveFreeGameType(data.gameable_type, data.gameable.key ? data.gameable.key : data.gameable.slug) }}</div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Game</div>
                    <div class="col">{{ data.gameable.name ? data.gameable.name : data.gameable.title }}</div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Tickets</div>
                    <div class="col">
                        <div class="row">
                            <div class="col-6 font-weight-bold">
                                Total
                            </div>
                            <div class="col-6">
                                {{ data.number_of_tickets }}
                            </div>
                            <div class="col-6 font-weight-bold">
                                Used
                            </div>
                            <div class="col-6">
                                {{ data.number_of_tickets_used }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Is Used</div>
                    <div class="col">
                        <i class="fa"
                           :class="{
                        'fa-check text-success': data.is_used,
                        'fa-times text-danger': !data.is_used
                    }"
                        ></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Meta</div>
                    <div class="col">
                        <div v-for="(item, index) in data.meta"
                             :key="index"
                             class="row"
                        >
                            <div class="col-6 font-weight-bold">{{ index }}</div>
                            <div class="col-6">{{ item }}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Expires at</div>
                    <div class="col">{{ !!data.expires_at ? data.expires_at : '/' }}</div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">Created at</div>
                    <div class="col">{{ utcToLocal(data.created_at) }}</div>
                </div>
            </div>
            <div v-if="$can('Player - Free Games Delete') && !data.number_of_tickets_used && !!canCreateFreeGame"
                 class="row mt-3"
            >
                <div v-if="!isLoading"
                     class="col"
                >
                    <button v-if="!isDeletePressed"
                            class="btn btn-danger btn-sm"
                            @click="togglePreviewDelete"
                    >
                        Delete
                    </button>
                    <button v-if="isDeletePressed"
                            class="btn btn-danger btn-sm mr-2"
                            @click="togglePreviewDelete"
                    >
                        Cancel
                    </button>
                    <button v-if="isDeletePressed"
                            class="btn btn-success btn-sm"
                            @click="removeFreeGame(data.id)"
                    >
                        Confirm
                    </button>
                </div>
                <loader :show="isLoading"
                        type="small"
                />
            </div>
        </div>
        <div v-else>
            <not-found :items="[]"
                       msg="Preview Free Game"
            />
        </div>

        <template #footer>
            <button class="btn btn-sm btn-outline-danger mr-2"
                      @click="previewFreeGame({})"
            >
                Cancel
            </button>
        </template>
    </sidebar-sub>
</template>

<script>
import ResolveFreeGameType from '../Utils/ResolveFreeGameType';
import utcToLocal from '../../../../../filters/UtcToLocal';

export default {
    name: 'PreviewFreeGameSidebar',
    ResolveFreeGameType,
    inject: [
        'previewFreeGame',
        'removeFreeGame',
        'togglePreviewDelete',
    ],
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        isDeletePressed: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        canCreateFreeGame: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        show () {
            return !this.isObjectEmpty(this.data);
        }
    },
    methods: {
	    utcToLocal,
    },
}
</script>