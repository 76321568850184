<template>
    <div class="row">
        <div class="col-12 col-lg-6 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Draw
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-lg-3 text-center">
                            <img :src="$imageService.gameImage(syndicateShare.game_image)" width="80" />
                        </div>
                        <div class="col-12 col-lg-9">
                            <not-found v-if="!gameDrawNumbers"
                                       msg="Lottery numbers not drawn yet."
                                       :items="[]"
                                       textAlign="left"
                            />

                            <div class="numbers-group">
                                <template v-if="!!gameDrawNumbers">
                                    <div v-for="(group, key) in getGameNumTypes(syndicateShare.game_key)">
                                        <div v-if="isTypeArray(gameDrawNumbers, key)" :class="`numbers-group-wrapper ${key}-numbers`" v-tooltip="{content: key}">
                                        <span v-for="number in gameDrawNumbers[key]" :class="[key, typeResolver(key)]">
                                            {{ number }}
                                        </span>
                                        </div>

                                        <span v-else :key="key + gameDrawNumbers[key]" :class="[key, group, typeResolver(key)]" v-tooltip="{content: key}">
                                            {{ gameDrawNumbers[key] }}
                                        </span>
                                    </div>
                                </template>
                            </div>

                            <div class="draw-date">
                                <span class="text-muted small">Date: {{ dateFormat(syndicateShare.cut_off_at, 'YYYY-MM-DD') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Player
                </div>

                <div class="card-body">
                    <a :href="`/players/${syndicateShare.player_id}/view`" class="text-primary">
                        <h3>{{ syndicateShare.player_name }} {{ syndicateShare.player_surname }}</h3>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Winnings
                </div>

                <div class="card-body">
                    <h3 class="text-primary">
                        {{ ticketWinStatus }}
                    </h3>
                </div>
            </div>
        </div>

        <div class="col-12 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Syndicate
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <span class="mr-2">Syndicate: <strong>{{ syndicateShare.title }}</strong></span>
                            <span class="mr-2">Total syndicate shares: <strong>{{ syndicateShare.total_shares }}</strong></span>
                            <span>Syndicate lines: <strong>{{ syndicateShare.lines_count }}</strong></span>
                        </div>
                        <div class="col-12 col-lg-6">
                            <span>Your shares: <strong>{{ syndicateShare.shares }}</strong></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TicketMixin from '../../../../mixins/TicketMixin';
import dateFormat from '../../../../filters/DateFormat';
import formatCurrency from '../../../../filters/FormatCurrency';

export default {
    name: 'syndicate-ticket-info',
    mixins: [
        TicketMixin
    ],
    props: ['syndicateShare'],
    computed: {
        gameDrawNumbers() {
            return JSON.parse(this.syndicateShare.numbers);
        },
        ticketWinStatus() {
            if (this.syndicateShare.prize === null) {
                return 'No status';
            }

            if (this.syndicateShare.prize === 0) {
                return 'No winnings';
            }

            return `Won: ${formatCurrency(this.syndicateShare.prize)}`;
        }
    },
    methods: {
        dateFormat,
    },
}
</script>