var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center justify-content-between"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-warning",
              on: { click: _vm.fetchLeadMessages }
            },
            [
              _c("i", {
                staticClass: "fa fa-sync",
                attrs: { "aria-hidden": "false" }
              })
            ]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.messages, function(item, index) {
            return !_vm.isLoading
              ? _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "status-column col-12 col-lg-6 col-xl mb-2 mb-lg-0 text-center"
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "" + (item.is_read ? "Read" : "Unread")
                            },
                            expression:
                              "{\n                       content: `${item.is_read ? 'Read' : 'Unread'}`\n                   }"
                          }
                        ],
                        staticClass: "fa fa-lg",
                        class: {
                          "fa-envelope-open": item.is_read,
                          "fa-envelope": !item.is_read
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Agent")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.agent_name) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-6 col-xl mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Template Name")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.template_name) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "description-column col-12 col-lg-6 col-xl mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge-extended" }, [
                        _vm._v("Template Description")
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(item.template_description.substr(0, 40)))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg-6 col-xl-2" }, [
                    _c("span", { staticClass: "small-mb-badge-extended" }, [
                      _vm._v("Date")
                    ]),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.utcToLocal(item.created_at)) +
                        "\n                "
                    )
                  ])
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { msg: "No messages found.", items: _vm.messages }
              })
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    !_vm.isLoading && _vm.messagesResponse.last_page > 1
      ? _c("div", { staticClass: "card-footer" }, [
          _c(
            "nav",
            { staticClass: "wrap" },
            [
              _c("atbl-pagination", {
                attrs: { pagination: _vm.messagesResponse },
                on: { paginate: _vm.fetchLeadMessages }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-envelope" }),
      _vm._v(" Messages\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-none d-xl-flex" }, [
      _c("div", { staticClass: "col-12 col-lg status-column text-center" }, [
        _vm._v("Status")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0" }, [
        _vm._v("Agent")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Description")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0" }, [
        _vm._v("Date")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }