var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("search-free-game-form", {
        attrs: { "show-search": _vm.showSearch, model: _vm.searchModel }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  staticClass: "btn btn-outline-primary btn-sm",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      _vm.showSearch = true
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-filter" })]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(1),
              _vm._v(" "),
              !_vm.isLoading && !!_vm.freeGames.length
                ? _vm._l(_vm.freeGames, function(item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-lg-1 text-center text-lg-left mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("ID")
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.id) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-lg-2 text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Created By")
                          ]),
                          _vm._v(" "),
                          _vm.isset(item, "user") && item.user
                            ? _c("span", [_vm._v(_vm._s(item.user.name))])
                            : _c(
                                "span",
                                { staticClass: "badge badge-danger" },
                                [
                                  _vm._v(
                                    "\n                                System\n                            "
                                  )
                                ]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-lg-2 text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Player")
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/players/" + item.player.id + "/view",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.player.name) +
                                  " " +
                                  _vm._s(item.player.surname) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Game")
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                item.gameable.name
                                  ? item.gameable.name
                                  : item.gameable.title
                              ) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-lg-2 text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Is Used")
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fa",
                            class: {
                              "fa-check text-success": item.is_used,
                              "fa-times text-danger": !item.is_used
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-2 text-center" },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Operations")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-link btn-delete btn-sm mr-2",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewFreeGame(item)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-eye" })]
                              ),
                              _vm._v(" "),
                              _vm.$can("Free Games - Delete") &&
                              !item.number_of_tickets_used
                                ? _c("confirmable-remove-button", {
                                    attrs: { value: item.id },
                                    on: { confirmed: _vm.removeFreeGame }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: {
                      msg: "No free games found found.",
                      items: _vm.freeGames
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        !_vm.isLoading && _vm.freeGamesData.last_page > 1
          ? _c("div", { staticClass: "card-footer" }, [
              _c(
                "nav",
                { staticClass: "wrap" },
                [
                  _c("atbl-pagination", {
                    staticClass: "m-0",
                    attrs: { pagination: _vm.freeGamesData },
                    on: { paginate: _vm.fetchFreeGames }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("preview-free-game-sidebar", {
        attrs: {
          data: _vm.freeGamePreviewData,
          "is-delete-pressed": _vm.isDeletePressed,
          "is-loading": _vm.isLoading
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-gamepad mr-1" }),
      _vm._v(" Free Games\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-1 text-center text-lg-left" }, [
        _vm._v("ID")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
        _vm._v("Created By")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
        _vm._v("Player")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [_vm._v("Game")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
        _vm._v("Is Used")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-center" }, [
        _vm._v("Operations")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }