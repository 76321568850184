<template>
    <player-layout :id="id" icon="fa fa-ticket-alt" title="Player Bonuses">
        <template #rightSide>
            <button v-if="$can('Bonuses - View List')" type="submit" class="btn btn-sm btn-primary" @click="showAssignBonusModal = true">Assign bonus</button>
        </template>

        <section class="general-list agents">
            <div class="row">
                <div class="col text-muted">ID</div>
                <div class="col text-muted">Bonus</div>
                <div class="col text-muted">Assigned at</div>
                <div class="col text-muted">Claimed at</div>
                <div class="col text-muted">Accomplished at</div>
                <div class="col text-muted">Revoked at</div>
                <div class="col text-muted">Expiry date</div>
            </div>

            <div v-if="!isLoading" v-for="(playerBonus, index) in playerBonuses" :key="index" class="row">
                <div class="col align-self-center">
                    {{ playerBonus.uuid }}
                </div>

                <div class="col align-self-center">
                    {{ playerBonus.bonus.title }}
                </div>

                <div class="col align-self-center" v-html="formatPlayerBonusDate('assigned_at', playerBonus)"></div>

                <div class="col align-self-center" v-html="formatPlayerBonusDate('claimed_at', playerBonus)"></div>

                <div class="col align-self-center" v-html="formatPlayerBonusDate('accomplished_at', playerBonus)"></div>

                <div class="col align-self-center" v-html="formatPlayerBonusDate('revoked_at', playerBonus)"></div>

                <div class="col align-self-center" v-html="formatPlayerBonusDate('expires_at', playerBonus)"></div>
            </div>

            <loader :show="isLoading" type="small" />

            <not-found v-if="!isLoading" msg="No bonuses assigned to this player." :items="playerBonuses" />

            <nav class="wrap py-3" v-if="!isLoading && playerBonusesResponse.last_page > 1">
                <atbl-pagination :pagination="playerBonusesResponse" @paginate="getPlayerBonuses" />
            </nav>
        </section>

        <assign-bonus-modal v-if="showAssignBonusModal" @assign="onAssign" @cancel="showAssignBonusModal = false" />
    </player-layout>
</template>

<script>
import BonusService from '../../../../services/BonusService';
import AssignBonusModal from './AssignBonusModal';

export default {
    name: 'player-bonuses',

    components: { AssignBonusModal },

    props: ['id'],

    data() {
        return {
            playerBonuses: [],
            playerBonusesResponse: {},
            isLoading: false,

            showAssignBonusModal: false,
        };
    },

    mounted() {
        this.getPlayerBonuses();
    },

    computed: {
        player() {
            return this.$store.state.players.playerModel;
        },
    },

    methods: {
        getPlayerBonuses(page = 1) {
            this.isLoading = true;

            BonusService.fetchPlayerBonuses(this.id, { params: { page }})
                .then((response) => {
                    this.playerBonuses = response.data.data;
                    this.playerBonusesResponse = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        formatPlayerBonusDate(dateType, playerBonus) {
            const date = playerBonus[dateType];

            if (!date) {
                return '/';
            }

            const dateString = this.$options.filters.dateFormat(date, 'YYYY-MM-DD', false);
            const timeString = this.$options.filters.dateFormat(date, 'HH:mm', false);

            return `${dateString}<br/>${timeString}`;
        },

        onAssign(bonusId) {
            window.axios.post(`/gamify/players/${this.player.id}/manually-assign/${bonusId}`).then((res) => {
                this.showAssignBonusModal = false;
                
                this.getPlayerBonuses();
            }).catch((err) => {
                window.showMessage('Bonus can not be assigned', false);
            });
        },
    }
}
</script>
