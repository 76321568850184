<template>
    <atbl-modal v-if="$can('Notes - Create/Update')"
                :show="show"
                :close-on-backdrop="false"
                title="Create Note"
                @input="toggleNoteModal"
    >
        <template v-if="!isLoading">
            <atbl-input v-model.trim="model.type"
                        :errors="errors"
                        label="Enter note type"
                        name="type"
            />
        </template>

        <loader :show="isLoading"
                type="small"
        />

        <template v-if="!isLoading"
                  #footer
        >
            <button class="btn btn-sm btn-danger"
                    @click="toggleNoteModal"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                    @click="onCreateUpdateNote"
            >
                Ok
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../components/AtblModal/AtblModal.vue';
import NoteModel from './NoteModel';
import AtblInput from '../../../components/Forms/Inputs/ATBLInput.vue';
import Loader from '../../../components/Widgets/Loader.vue';

export default {
    name: 'CreateUpdateNoteComponent',
    inject: [
        'onCreateUpdateNote',
        'toggleNoteModal',
    ],
    components: {
        AtblModal,
        AtblInput,
        Loader,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        model: {
            type: Object,
            default: () => (NoteModel)
        },
        errors: {
            type: Object,
            default: () => ({})
        },
    },
}
</script>