var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configuration" }, [
    _c(
      "h5",
      { staticClass: "configuration-header", on: { click: _vm.toggleBody } },
      [
        _c("span", [_vm._v(_vm._s(_vm.ucFirst(_vm.index)))]),
        _vm._v(" "),
        _c("i", {
          staticClass: "fa",
          class: {
            "fa-caret-up": _vm.showBody,
            "fa-caret-down": !_vm.showBody
          },
          attrs: { "aria-hidden": "false" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBody,
            expression: "showBody"
          }
        ],
        staticClass: "align-items-center justify-content-between flex-wrap",
        class: { "d-flex": _vm.showBody }
      },
      [
        _vm.isObject(_vm.numberTypes)
          ? _vm._l(_vm.numberTypes, function(numberType, numberTypeIndex) {
              return _c(
                "div",
                { key: numberTypeIndex, staticClass: "w-50 text-center" },
                [
                  _c("h6", { staticClass: "my-2" }, [
                    _vm._v(_vm._s(numberTypeIndex))
                  ]),
                  _vm._v(" "),
                  _vm._l(numberType, function(number, numIndex) {
                    return [
                      _c(
                        "div",
                        { key: numIndex },
                        [
                          number.hasOwnProperty("numbers")
                            ? [
                                _c("input", {
                                  staticClass: "configuration-text-input",
                                  class: {
                                    "input-has-error": !!number.error
                                  },
                                  attrs: {
                                    id: number.id,
                                    disabled: _vm.disabled
                                  },
                                  domProps: { value: number.value },
                                  on: {
                                    input: function($event) {
                                      return _vm.changeNumber(
                                        $event,
                                        _vm.index,
                                        numberTypeIndex,
                                        number
                                      )
                                    }
                                  }
                                })
                              ]
                            : _c("raffle-configuration-form-main-item", {
                                attrs: { "number-type-index": numberTypeIndex },
                                on: {
                                  "remove-configuration":
                                    _vm.onRemoveConfigurationEvent,
                                  "update-configuration":
                                    _vm.onUpdateConfigurationEvent
                                }
                              })
                        ],
                        2
                      )
                    ]
                  })
                ],
                2
              )
            })
          : _c(
              "div",
              { staticClass: "configuration-winning-prizes" },
              _vm._l(_vm.numberTypes, function(numberType, numberTypeIndex) {
                return _c("raffle-editor-prize-winner-item", {
                  key: numberTypeIndex,
                  attrs: {
                    "number-type": numberType,
                    index: _vm.index,
                    disabled: _vm.disabled,
                    "number-type-index": numberTypeIndex
                  },
                  on: {
                    change: _vm.changeNumber,
                    "update-configuration": _vm.onPrizeWinnerUpdateEvent,
                    "remove-field-configuration": _vm.onDeleteFieldEvent
                  }
                })
              }),
              1
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }