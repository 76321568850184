<template>
	<div class="container-fluid commission-eligible-transactions-single-day">
		<template v-if="!isLoading">
			<div class="details-holder">
				<div>
					<div class="card card-list">
						<div class="card-header">
							<i class="fa fa-user"/> User Details
						</div>
						<div class="card-body">
							<div class="general-list">
								<div class="row d-none d-sm-flex py-2">
									<div class="col-12 col-sm">ID</div>
									<div class="col-12 col-sm">Name</div>
								</div>
								<div class="row py-2">
									<div class="col-12 col-sm mb-2 mb-md-0">
										<span class="small-mb-badge">ID</span>
										{{ user.id }}
									</div>
									<div class="col-12 col-sm mb-2 mb-md-0">
										<span class="small-mb-badge">Name</span>
										{{ user.name }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="card card-list">
						<div class="card-header">
							<i class="fa fa-percent"/> Commission Details
						</div>
						<div class="card-body">
							<div class="general-list">
								<div class="row d-none d-sm-flex py-0">
									<div class="col-12 col-sm">Plan</div>
									<div class="col-12 col-sm">Type</div>
									<div class="col-12 col-sm">Deposit</div>
									<div class="col-12 col-sm  bg-primary-20 py-2">Commission</div>
								</div>
								<div class="row py-0">
									<div class="col-12 col-sm mb-2 mb-md-0">
										<span class="small-mb-badge">Plan</span>
										{{ user.commission_plan.name }}
									</div>
									<div class="col-12 col-sm mb-2 mb-md-0">
										<span class="small-mb-badge">Type</span>
										<span
										  :class="['badge', `badge-${getCommissionPlanTypeColor(user.commission_plan.type)}`]"
										>
											{{ user.commission_plan.type }}
										</span>
									</div>
									<div class="col-12 col-sm mb-2 mb-md-0">
										<span class="small-mb-badge">Deposit</span>
										{{ formatCurrency(user.commission.total_sales) }}
									</div>
									<div class="col-12 col-sm bg-primary-20 py-2">
										<span class="small-mb-badge">Commission</span>
										{{ formatCurrency(user.commission.commission_payout) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="card card-list">
				<div class="card-header d-flex align-items-center justify-content-between">
					<div>
						<i class="fa fa-list"/> Commission transactions
					</div>
					<div>
						<strong>Date:</strong> {{ filters.dateFrom }}
					</div>
				</div>
				
				<div class="card-body">
					<div class="general-list">
						<div class="row">
							<div class="col-12 col-md-6 col-lg">Player name</div>
							<div class="col-12 col-md-6 col-lg">Transaction</div>
							<div class="col-12 col-md-6 col-lg">Created By</div>
							<div class="col-12 col-md-6 col-lg">Amount</div>
							<div class="col-12 col-md-6 col-lg">Created</div>
						</div>
						
						
						<div v-for="({transaction_id, transaction}, index) in commissionEligibleTransactions"
						     :key="index"
						     class="row"
						>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Player name</span>
								<a v-if="$can('Player - View Details')"
								   :href="`/players/${transaction.player_id}/view`"
								   target="_blank"
								>
									{{ transaction.player_name }} {{ transaction.player_surname }}
								</a>
								<span v-else>
									{{ transaction.player_name }} {{ transaction.player_surname }}
								</span>
							</div>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Transaction</span>
								<a v-if="$can('Transactions - View Transaction Details')"
								   :href="`/transaction/${transaction_id}`"
								   target="_blank"
								>
									T-{{ transaction_id }}
								</a>
								<span v-else>
									T-{{ transaction_id }}
								</span>
							</div>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Created By</span>
								<created-by-badge :created="transaction" />
							</div>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Amount</span>
								{{ formatCurrency(transaction.amount) }}
							</div>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Created</span>
								{{ utcToLocal(transaction.created_at) }}
							</div>
						</div>
						
						<items-not-found v-if="!isLoading"
						                 :items="commissionEligibleTransactions"
						                 msg="No transactions found"
						/>
					</div>
				</div>
			</div>
		</template>
		
		<loader :show="isLoading"
		        type="small"
		/>
	</div>
</template>

<script>
import Loader from "../../../../components/Widgets/Loader.vue";
import ItemsNotFound from "../../../../components/Lists/ItemsNotFound.vue";
import formatCurrency from "../../../../filters/FormatCurrency";
import utcToLocal from "../../../../filters/UtcToLocal";
import CreatedByBadge from "../../../../components/Transactions/CreatedByBadge.vue";

export default {
	name: "CommissionEligibleTransactionsSingleDayPage",
	components: {
		Loader,
		ItemsNotFound,
		CreatedByBadge,
	},
	props: {
		filters: {
			type: Object,
			default: () => ({})
		},
	},
	data() {
		return {
			isLoading: false,
			user: {
				id: null,
				name: null,
				commission_plan: {
					id: null,
					name: null,
					type: null,
					version: {
						id: null,
						commission_plan_id: null,
						commissions: []
					}
				},
				commission_eligible_transactions: [],
				commission: {
					id: null,
					user_id: null,
					commission_plan_version_id: null,
					total_sales: null,
					commission_payout: null,
					payout_date: null,
					created_at: null,
					updated_at: null,
				},
			},
		};
	},
	computed: {
		commissionEligibleTransactions() {
			return this.user.commission_eligible_transactions;
		},
	},
	async created() {
		await this.fetchCommissionEligibleTransactions();
	},
	methods: {
		formatCurrency,
		utcToLocal,
		async fetchCommissionEligibleTransactions() {
			this.isLoading = true;
			
			try {
				const response = await window.axios.get(`/commissions/eligible-transactions/single-day/transactions`, {
					params: this.filters,
				});
				
				this.user = response.data;
				
				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				
				window.flashError(error);
			}
		},
		getCommissionPlanTypeColor(type) {
			switch (type) {
				case 'fixed':
					return 'danger';
				case 'tiered':
					return 'success';
				case 'scalar':
					return 'warning';
				default:
					return 'dark';
			}
		},
	},
}
</script>