<template>
	<atbl-form-group :label="label"
                     class="atbl-fake-input-group"
    >
		<div class="atbl-fake-input">
			<slot />
            <a v-if="copy"
               href="#"
               class="atbl-fake-input-copy"
               @click.prevent="copyToClipboard(value)"
            >
                <i class="fas fa-copy"></i>
            </a>
		</div>
	</atbl-form-group>
</template>

<script>
import AtblFormGroup from "../Holders/ATBLFormGroup.vue";

export default {
	name: "AtblFakeInput",
	components: {
		AtblFormGroup,
	},
	props: {
		label: {
			type: String,
			default: 'Fake Input'
		},
        value: {
            type: [String, Number],
            default: null
        },
        copy: {
            type: Boolean,
            default: false
        },
	},
}
</script>