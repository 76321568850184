<template>
	<div class="container">
		<PlayerCallComponent v-if="!isPlayerLoading && $can('Player - Call')"
		                     :number="number"
		                     :player="player"
		                     :agent-id="agentid"
		/>
		
		<atbl-tabs variant="tabs" v-if="!isPlayerLoading && (player && isset(player, 'id'))">
			<atbl-tab title="Disposition"
			       active
			>
				<AddDispositionSection v-if="$can('Player - Add Disposition')"
				                       :player_id="player_id"
				                       :dispositions="dispositions"
				                       :saveLoader="dispositionsSaveLoader"
				                       :errors="errors"
				                       :clean="isClean"
				                       @saveNewDisposition="saveDisposition"
				/>
				
				<PlayerDispositionsList v-if="$can('Player - View Dispositions')"
				                        :playerDispositions="playerDispositions"
				                        :loader="dispositionsLoader"
				/>

                <player-last-calls v-if="$can('Player - View Calls')"
                                   :player-id="player_id"
                />
			</atbl-tab>
			<atbl-tab title="Notes">
				<call-notes :callable-id="player_id"/>
			</atbl-tab>
		</atbl-tabs>

        <loader :show="isPlayerLoading"
                type="small"
        />
	</div>
</template>

<script>
	import PlayerCallComponent from "./Sections/PlayerCallComponent";
	import DispositionService from "../../../../services/DispositionService";
	import PlayerDispositionService from "../../../../services/PlayerDispositionService";
	import AddDispositionSection from "./Sections/AddDispositionSection";
	import PlayerDispositionsList from "./Sections/PlayerDispositionsList";
	import CallNotes from "../../../../components/CallNote/CallNotes";
	import PlayerLastCalls from './Sections/PlayerLastCalls';
	import AtblTabs from '../../../../components/AtblTabs/AtblTabs.vue';
	import AtblTab from '../../../../components/AtblTabs/AtblTab.vue';
	
	export default {
		components: {
			CallNotes,
			PlayerCallComponent,
			AddDispositionSection,
			PlayerDispositionsList,
            PlayerLastCalls,
			AtblTabs,
			AtblTab,
		},
		props: [
			"number",
			"player_id",
			"agentid"
		],
		data: function () {
			return {
				dispositions: [],
				dispositionsSaveLoader: false,
				dispositionsLoader: false,
				playerDispositions: [],
				errors: {},
				isClean: false,
				player: {},
                isPlayerLoading: false,
			}
		},
		created() {
            if (this.player_id && Number(this.player_id) > 0) {
                if (this.$can("Dispositions - Get List")) {
                    this.getDispositions();
                }
                if (this.$can("Player - View Dispositions")) {
                    this.getPlayerDispositions();
                }
                if (this.$can("Player - View Details")) {
                    this.fetchPlayer(this.player_id);
                }
            }

			window.addEventListener('beforeunload', this.callWindowClose)
		},
		methods: {
			fetchPlayer(id) {
				this.isPlayerLoading = true;
				window.axios.get(`/player/${id}`)
                    .then(response => {
                        this.player = response.data;
                    })
                    .finally(() => {
                        this.isPlayerLoading = false;
                    });
			},
			getDispositions: function () {
				let vm = this;
				
				DispositionService.getDispositionsDropDown()
					.then(response => {
						vm.dispositions = response.data;
					});
			},
			getPlayerDispositions: function () {
				let vm = this;
				
				vm.dispositionsLoader = true;
				PlayerDispositionService.getPlayerDispositions(this.player_id)
					.then(response => {
						vm.playerDispositions = response.data.dispositions;
					})
					.finally(() => {
						vm.dispositionsLoader = false;
					});
			},
			saveDisposition: function (value) {
				let vm = this;
				vm.errors = {};
				
				vm.dispositionsSaveLoader = true;
				PlayerDispositionService.addPlayerDisposition(value)
					.then(response => {
						let data = response.data;
						
						flash(data.message, (!data.status ? "alert-danger" : "alert-success"));
						
						if (data.status) {
							if (this.$can("Player - View Dispositions")) {
								vm.getPlayerDispositions();
							}
							vm.isClean = true;
						}
					})
					.then(() => {
						vm.isClean = false;
					})
					.catch(error => {
						vm.errors = error.response.data.errors;
					})
					.finally(() => {
						vm.dispositionsSaveLoader = false;
					});
			},
			callWindowClose: function (e) {
				e.preventDefault();
				localStorage.removeItem("WPOpened");
			},
		}
	}
</script>
