var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: {
        label: "Transaction Type",
        "label-for": "paymentType",
        horizontal: _vm.horizontal
      }
    },
    [
      _c("tree-select", {
        attrs: {
          multiple: true,
          searchable: true,
          "clear-on-select": true,
          options: _vm.types,
          normalizer: function(node) {
            return { id: node, label: node }
          },
          disabled: !!_vm.disabled || !!_vm.isLoading,
          placeholder: "" + (!!_vm.isLoading ? "Loading..." : "Select options")
        },
        model: {
          value: _vm.selectedType,
          callback: function($$v) {
            _vm.selectedType = $$v
          },
          expression: "selectedType"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }