<template>
    <form @submit.prevent="onUpdateUsersEvent">
        <div class="card card-list">
            <div class="sticky-element bg-white border-bottom">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <div>
                        <i class="fa fa-user-friends"></i> Users
                    </div>
                    <div class="d-flex">
                        <sort-by v-if="!usersUpdateLoader"
                                 v-model="sortByModel"
                                 :options="sortByOptions"
                        />
                        <button :disabled="!isModelDirty || usersUpdateLoader"
                                class="btn btn-sm btn-primary ml-2"
                        >
                            <span v-if="!usersUpdateLoader">Save</span>
                            <loader :show="usersUpdateLoader"
                                    type="smaller"
                            />
                        </button>
                    </div>
                </div>
                <div v-if="!usersUpdateLoader"
                     class="p-2"
                >
                    <atbl-input v-model="searchData"
                                placeholder="Search user"
                                class="mb-0"
                    />
                </div>
            </div>

            <div v-if="!usersUpdateLoader"
                 class="card-body"
            >
                <div class="general-list">
                    <div class="row d-flex">
                        <div class="col-6 col-sm-3">
                            <atbl-check-box :value="isAllSelected"
                                            :partial-check="isPartialSelected"
                                            name="users"
                                            class="mr-1"
                                            label="Select All"
                                            @update="onSelectAllUsersEvent"
                            />
                        </div>
                        <div class="col">Name</div>
                    </div>

                    <div v-for="(user, index) in users"
                         :key="index"
                         class="row"
                    >
                        <div class="col-6 col-sm-3">
                            <atbl-check-box :value="user.id"
                                            :label="null"
                                            :checked="modelUsersIds.includes(user.id)"
                                            name="users"
                                            class="mr-1"
                                            @update="onSelectUserEvent(user)"
                            />
                        </div>
                        <div class="col-6 col-sm">
                            {{ user.name }} (#{{ user.id }})
                        </div>
                    </div>

                    <items-not-found :items="users"
                                     msg="No users found"
                    />
                </div>
            </div>

            <loader :show="usersUpdateLoader"
                    type="small"
            />
        </div>
    </form>
</template>

<script>
import SortBy from '../../../../components/SortBy/SortBy';
import AtblCheckBox from '../../../../components/Forms/Inputs/AtblCheckbox';
import ItemsNotFound from '../../../../components/Lists/ItemsNotFound';
import AtblInput from '../../../../components/Forms/Inputs/ATBLInput';
import PermissionModel from '../PermissionModel';
import { clone, stringify } from '../../../../utils/Json/Json';
import dynamicSort from '../../../../utils/Array/DynamicSort';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';

const userPermissionModel = {
    users: [],
    users_revoke: [],
};

export default {
    name: 'users-editor-component',
    inject: [
        'permissionId',
    ],
    components: {
        AtblCheckBox,
        ItemsNotFound,
        SortBy,
        AtblInput,
    },
    props: {
        model: {
            type: Object,
            default: () => (PermissionModel)
        },
        usersData: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            userModelOriginal: clone(userPermissionModel),
            userModel: clone(userPermissionModel),

            usersUpdateLoader: false,

            usersSearch: null,

            sortByOptions: [
                {
                    value: 'id',
                    label: 'Id',
                },
                {
                    value: 'name',
                    label: 'Name',
                }
            ],
            sortByModel: {
                direction: 'asc',
                column: 'name',
            },
        };
    },
    computed: {
        searchData: {
            get() {
                return this.usersSearch;
            },
            set(value) {
                this.usersSearch = !value ? null : value;
            }
        },
        users() {
            let users = this.usersData;
            if (!!this.usersSearch) {
                users = this.usersData.filter(user => user.name.toLowerCase().includes(this.usersSearch.toLowerCase()));
            }

            return dynamicSort(users, this.sortByModel.column, this.sortByModel.direction);
        },
        modelUsersIds() {
            return this.userModel.users.map(user => user.id);
        },
        isAllSelected() {
            return this.userModel.users.length === this.users.length;
        },
        isPartialSelected() {
            return !!this.userModel.users.length;
        },
        isModelDirty() {
            return stringify(this.userModelOriginal) !== stringify(this.userModel);
        },
    },
    created() {
        this.userModelOriginal.users = clone(this.model.users);
        this.userModel.users = clone(this.model.users);
    },
    methods: {
        async onUpdateUsersEvent() {
            this.usersUpdateLoader = true;

            try {
                const { users, users_revoke } = this.userModel;

                const modelData = {
                    users: users.map(item => item.id),
                    users_revoke: users_revoke.map(item => item.id),
                };

                const response = await window.axios.post(`/permissions/${this.permissionId}/users`, modelData);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.usersUpdateLoader = false;

                    return;
                }

                this.searchData = null;
                this.userModel.users_revoke = [];
                this.userModelOriginal = clone(this.userModel);

                this.usersUpdateLoader = false;
            } catch (error) {
                this.usersUpdateLoader = false;

                window.flashError(error);
            }
        },
        onSelectUserEvent(user) {
            const modelUsers = clone(this.userModel);

            const userObject = modelUsers.users.find(item => item.id === user.id);

            if (!!userObject) {
                modelUsers.users = modelUsers.users.filter(item => item.id !== user.id);
                modelUsers.users_revoke.push(user);
            } else {
                modelUsers.users.push(user);
                modelUsers.users_revoke = modelUsers.users_revoke.filter(item => item.id !== user.id);
            }

            Object.assign(this.userModel, modelUsers);
        },
        onSelectAllUsersEvent() {
            const modelUsersOriginal = clone(this.model);
            const modelUsers = clone(this.userModel);

            if (modelUsers.users.length === this.users.length) {
                modelUsers.users = [];
                modelUsers.users_revoke = modelUsersOriginal.users;
            } else {
                modelUsers.users_revoke = [];
                modelUsers.users = this.users;
            }

            Object.assign(this.userModel, modelUsers);
        },
    },
}
</script>