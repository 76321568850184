<template>
    <atbl-modal title="Disable Bundle" :close-on-backdrop="true" color="danger" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <p>Are you sure you want to disable this bundle ?</p>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-danger" @click="emitConfirm">
                Yes
            </button>

            <button class="btn btn-sm btn-primary" @click="emitCloseEvent">
                No
            </button>
        </template>
    </atbl-modal>
</template>

<script>

import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'bundle-disable-modal',
	components: {
		AtblModal,
	},
    methods: {
        emitConfirm() {
            this.$emit('confirm', 'yes');
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },
    },
}
</script>