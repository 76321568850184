<template>
    <div>
        <div v-if="!isLoading" class="card player-play mb-3">
            <bundle-game-header v-if="gameConfiguration" :config="gameConfiguration" :bundle="bundle" />

            <div class="card-body">
                <validation-errors :errors="errors" />
                <div class="player-play-picker">
                    <div class="player-play-picker-wrapper">
                        <template v-if="gameConfigurations.length > 0" >
                            <div v-for="gc in gameConfiguration"
                                 :key="`t_${gc.key}`"
                            >
                                <img :src="$imageService.gameImage(gc.image)" class="mx-2" style="width: 40px; height: 40px;" />
                                <lines-picker :lines="lines[gc.key]"
                                              :game="gc"
                                              :no-line-control="true"
                                              @updateLines="updateLines($event, gc.key)"
                                />
                            </div>
                        </template>
                      <div class="row">
                            <div class="col-12">
                              <div class="row">
                                    <div class="col-12 col-md-8 col-lg-6">
                                        <atbl-form-group label="Weeks"
                                                         label-for="game"
                                                         class="font-weight-bold mb-0"
                                                         :horizontal="false"
                                                         :error="null"
                                        >
                                            <select v-model="weeks"
                                                    class="form-control"
                                                    :disabled="hasSelectedFreeGame"
                                            >
                                                <option :value="null">Select week</option>
                                                <option v-for="bundleWeek in bundle.bundle_weeks"
                                                        :key="`bundle_week_${bundleWeek.id}`"
                                                        :value="bundleWeek.week"
                                                        :selected="bundleWeek.week === weeks"
                                                >
                                                    {{ bundleWeek.week }} | {{ bundleWeek.discount ? `${bundleWeek.discount}%` : `${formatCurrencyMethod(bundleWeek.price)} | ${ calculatePercentageOfPriceDiscount(bundleWeek).toFixed(2) }%` }}
                                                </option>
                                            </select>
                                        </atbl-form-group>
                                        <span v-if="hasSelectedFreeGame"
                                              class="text-danger font-weight-bold"
                                        >
                                            {{weeks}} week{{ weeks > 1 ? 's' : '' }} for free!
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="buy">
                                <div class="buy-total">
                                    <table>
                                        <tr class="border-bottom">
                                            <td align="left">
                                                <span>Weeks: </span>
                                            </td>
                                            <td align="right">
											<span class="buy-total-price ml-1">
                                                {{ weeks }}
											</span>
                                            </td>
                                        </tr>

                                        <tr class="border-bottom">
                                            <td align="left">
                                                <span>Lines: </span>
                                            </td>
                                            <td align="right">
											<span class="buy-total-price ml-1">
                                                {{ totalLines }}
											</span>
                                            </td>
                                        </tr>

                                        <tr class="border-bottom">
                                            <td align="left">
                                                <span>Regular price: </span>
                                            </td>
                                            <td align="right">
											<span class="buy-total-price ml-1">
                                                <del>{{ totalBundleGamePrice.toFixed(2) }}</del>
											</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td align="left">
                                                <span>Total:</span>
                                            </td>
                                            <td align="right">
                                                <strong v-if="!hasSelectedFreeGame" class="buy-total-price ml-1">{{ totalPrice | formatCurrency }}</strong>
                                                <strong v-else class="buy-total-price ml-1">{{ 0 | formatCurrency }}</strong>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="buy-operations">
                                    <button v-if="!hasSelectedFreeGame"
                                              name="buyBtn"
                                              type="submit"
                                              class="btn buy-button btn-sm mb-2 btn-success"
                                              @click="addToCart(false)"
                                              :disabled="hasInvalidLines"
                                    >
                                        <span>
                                            <i class="fa fa-shopping-cart" aria-hidden="false"></i> Add to Cart
                                        </span>
                                    </button>
                                    <button v-if="!hasSelectedFreeGame"
                                              class="btn btn-sm btn-danger buy-button text-uppercase"
                                              @click="addToCart(true)"
                                              :disabled="hasInvalidLines"
                                    >
                                        <span v-if="!isPlacingOrder">
                                            <i class="fa fa-credit-card" aria-hidden="false"></i> Add & Place Order
                                        </span>
                                        <loader :show="isPlacingOrder"
                                                type="smaller"
                                        />
                                    </button>
                                    <button v-if="hasSelectedFreeGame"
                                              class="btn btn-sm btn-warning buy-button text-uppercase"
                                              @click="useFreeTicket(ticket())"
                                              :disabled="isUseFreeLoading || hasInvalidLines"
                                    >
                                    <span v-if="!isUseFreeLoading">
                                        <i class="fa fa-ticket-alt" aria-hidden="false"></i> Use Free Ticket
                                    </span>
                                        <loader :show="isUseFreeLoading"
                                                type="smaller"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import TypesConst from '../../../../../../store/TypesConst';
import NamespaceConst from '../../../../../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';

const {
    mapGetters: mapCartGetters,
    mapMutations: mapCartMutations
} = createNamespacedHelpers(NamespaceConst.cart);

const {
    mapGetters: mapPlayerGetters
} = createNamespacedHelpers(NamespaceConst.players);

const {
    mapGetters: mapOrderGetters,
    mapMutations: mapOrderMutations
} = createNamespacedHelpers(NamespaceConst.order);

import BundleGameHeader from './Components/BundleGameHeader';
import BundleMixin from '../../../../../../mixins/Bundles/Bundle';
import ValidationErrors from '../../Componenets/ValidationErrors/ValidationErrors';
import PlaceOrder from '../../Mixins/PlaceOrder';
import CheckoutAction from '../../Mixins/CheckoutAction';
import UseFreeTicket from '../../Mixins/UseFreeTicket';
import LinesPicker from '../LotteryGame/Components/LinesPicker';
import { clone } from '../../../../../../utils/Json/Json';

export default {
    name: 'bundle-game',
    inject: [
        'selectFreeGame',
        'getFreeGames',
    ],
    mixins: [
        BundleMixin,
        PlaceOrder,
        CheckoutAction,
        UseFreeTicket,
    ],
    components: {
        LinesPicker,
        BundleGameHeader,
        ValidationErrors
    },
    props: {
        selectedFreeGame: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            weeks: null,
            errors: {},
            lines: [],
        };
    },
    computed: {
        ...mapPlayerGetters({
            getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
        }),
        ...mapCartGetters({
            isPlacingOrder: TypesConst.cart.getters.GET_PLACE_ORDER,
        }),
        ...mapOrderGetters({
            orderId: TypesConst.order.getters.GET_ORDER_ID
        }),
        bundle() {
            return this.$store.getters['games/getGameConfigurationData'];
        },

        gameConfiguration() {
            const gameKeys = this.bundle.bundle_games.map(g => g.game.key);

            return this.gameConfigurations.filter(g => gameKeys.includes(g.key));
        },

        totalBundleGamesPricePerWeek() {
            return this.bundle.bundle_games.reduce((a, g) => a + this.getBundleGamePrice(g.game.name), 0);
        },

        totalBundleGamePrice() {
            return this.totalBundleGamesPricePerWeek * this.weeks * this.bundle.bundle_games[0].number_of_lines;
        },

        totalPrice() {
            const bundleWeek = this.bundle.bundle_weeks.find(w => w.week === this.weeks) || this.bundle.bundle_weeks[0];

            return bundleWeek.price ? bundleWeek.price : (this.totalBundleGamePrice - (this.totalBundleGamePrice * (bundleWeek.discount / 100)));
        },

        getCartData() {
            return this.$store.state.cart.cartData || [];
        },

        totalLines() {
            const lines = this.bundle.bundle_games.reduce((a, g) => {
                const gameConfiguration = this.getGameConfiguration(g.game.name);
                if(!gameConfiguration) {
                    return a + 0;
                }

                return a + gameConfiguration.weekdays.length * g.number_of_lines;
            }, 0);

            return lines * this.weeks;
        },

        hasSelectedFreeGame() {
            return !this.isObjectEmpty(this.selectedFreeGame);
        },
        
        hasInvalidLines() {
            return Object.values(this.lines).filter(lines => lines.filter(l => l.error.invalid).length).length > 0;
        },
    },
    watch: {
        bundle(newVal, oldVal) {
            this.weeks = null;
        },
    },
    mounted() {
        this.initializeGame();

        this.$el.scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        ...mapCartMutations({
            setIsPlacingOrder: TypesConst.cart.mutations.SET_PLACE_ORDER,
            clearCartData: TypesConst.cart.mutations.SET_CLEAR_CART_DATA,
            setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
            setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
        }),
        ...mapOrderMutations({
            setOrderId: TypesConst.order.mutations.SET_ORDER_ID
        }),
        initializeGame() {
            if (this.hasSelectedFreeGame) {
                this.weeks = 1;
            }
        },
        getNumberConfiguration(gameKey) {
            const gameConfig = this.gameConfigurations.find(c => c.key === gameKey);
            let gameNumbersConfiguration = gameConfig.numbers,
                numbers = {};

            for(let item in gameNumbersConfiguration){
                let currentItem = gameNumbersConfiguration[item],
                    key = null;

                if(Array.isArray(currentItem)){
                    for(let numberItem in currentItem){
                        let currentNumberItem = currentItem[numberItem];

                        Object.assign(numbers, {
                            [currentNumberItem.key]: currentNumberItem
                        });
                    }
                }else {
                    if(this.isset(currentItem, "key")){
                        key = currentItem.key;
                    }else {
                        key = item;
                    }

                    Object.assign(numbers, {
                        [key]: currentItem
                    });
                }
            }

            return numbers;
        },
        getNumberTypeConfiguration(gameKey, type){
            let numbersConfiguration = this.getNumberConfiguration(gameKey);
            return numbersConfiguration && this.isset(numbersConfiguration, type) ? numbersConfiguration[type] : null;
        },
        lineTranslator(gameKey, lines) {
            let translatorLines = clone(lines),
                translatedLines = [];
            
            for (let line in translatorLines) {
                let currentLine = translatorLines[line],
                    lineNumbers = currentLine.numbers;

                if (currentLine.random) {
                    delete currentLine.numbers;
                } else {
                    for (let type in lineNumbers) {
                        let currentLineNumbers = lineNumbers[type],
                            getNumberTypeConfiguration = this.getNumberTypeConfiguration(gameKey, type);

                        if (type !== 'main' && this.isset(getNumberTypeConfiguration, 'key')) {
                            lineNumbers['extra'] = currentLineNumbers;
                            delete lineNumbers[type];
                        }
                    }
                }

                delete currentLine.error;

                translatedLines = [
                    ...translatedLines,
                    currentLine
                ];
            }

            return translatedLines;
        },
        createLines(gameKey) {
            const gameConfig = this.gameConfigurations.find(c => c.key === gameKey);

            let numbersKeys = Object.keys(gameConfig.numbers),
                numbersObj = {};
            
            for(let item in numbersKeys)
            {
                let currentKey = numbersKeys[item];
                let numbers = null;
                const numberConfiguration = Array.isArray(gameConfig.numbers[currentKey]) ?
                        gameConfig.numbers[currentKey].filter(item => item.active && !item.generated) :
                        gameConfig.numbers[currentKey];

                if(Array.isArray(numberConfiguration))
                {
                    for(let numberItem in numberConfiguration)
                    {
                        let currentNumberItem = numberConfiguration[numberItem];

                        Object.assign(numbersObj, {
                            [currentNumberItem.key]: currentNumberItem.numbers > 1 ? [] : ""
                        });
                    }
                } else {
                    numbers = numberConfiguration.numbers > 1 ? [] : ""

                    Object.assign(numbersObj, {
                        [currentKey]: numbers
                    });
                }
            }

            return {
                random: true,
                numbers: numbersObj,
                error: {
                    invalid: false,
                    messages: []
                }
            };
        },
        ticket(){
            const playerId = this.$store.state.players.playerId;

            const lines = {};
            Object.keys(this.lines).forEach(k => {
                lines[k] = this.lineTranslator(k, this.lines[k]);
            });

            return {
                type: 'bundle',
                image: this.bundle.bundle_games[0].image,
                name: this.bundle.title,
                lines,
                playerId,
                weeks: this.weeks,
                bundle: this.bundle.uuid,
            };
        },
        updateLines(lines, key) {
            this.lines[key] = lines;
        },
        addToCart(directCheckout = false) {
            const playerId = this.$store.state.players.playerId;

            if (this.orderId) {
                this.clearCartData();
                this.setOrderId(null);
            }

            this.errors = {};

            this.weeks = parseInt(this.weeks);

            if (!this.bundle.bundle_weeks.map(w => w.week).includes(this.weeks)) {
                window.flashError({ message: 'Invalid weeks number' });
                return;
            }

            return this.$store.dispatch('cart/addToCartAction', {
                price: this.hasSelectedFreeGame ? 0 : this.totalPrice,
                free_game_id: this.hasSelectedFreeGame ? this.selectedFreeGame.id : null,
                item: this.ticket(),
                playerId: playerId,
            })
                .then(() => {
                    this.selectFreeGame({});
                    this.getFreeGames();

                    this.$store.commit('cart/setShowGame', false);
                    this.$scrollToTop();
                    flash('Successfully added bundle to cart.');

                    if (directCheckout) {
                        this.placeOrder(this.totalPrice);
                    }
                })
                .catch(error => {
                    this.errors = this.isset(error.response.data, "errors") ? error.response.data.errors: {};
                    window.showMessage("Error while adding a ticket to the cart.", false);
                });
        },

        getCartTotal() {
            const baseAccumulator = {
                lines: 0,
                price: 0,
            };

            const result = this.getCartData.items.reduce((acc, item) => {
                acc.price += item.price;

                const orderItem = Array.isArray(item.item) ? item.item : JSON.parse(item.item);
                acc.lines += orderItem.lines ? orderItem.lines.length : 0;

                return acc;
            }, baseAccumulator);

            result.price = result.price.toFixed(2);

            return result;
        },
        formatCurrencyMethod(amount) {
            return this.$options.filters.formatCurrency(amount);
        },

        calculatePercentageOfPriceDiscount(bundleWeek) {
            return 100 - (bundleWeek.price / ((this.totalBundleGamesPricePerWeek * bundleWeek.week) / 100));
        },

        onConfigurationLoaded() {
            let lines = {};

            this.gameConfiguration.forEach((t) => {
                let tmpLines = [];

                for (let i = 0; i < this.bundle.bundle_games[0].number_of_lines; i++) {
                    tmpLines = [
                        ...tmpLines,
                        this.createLines(t.key)
                    ];
                }

                lines[t.key] = [...tmpLines];
            });

            this.lines = lines;
        },
    },
}
</script>

<style lang="scss" scoped>
.player-play-picker-lines-line {
    justify-content : flex-start;
}
</style>