<template>
    <div v-if="showSearch" class="card card-search">
        <div class="card-header d-flex align-items-center">
            <i class="fa fa-filter mr-2"></i> Search
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <atbl-form-group label="Player name">
                        <atbl-input name="name"
                                    :value="model.name"
                                    @update="changeSearchModel"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-3">
                    <atbl-form-group label="Player surname">
                        <atbl-input name="surname"
                                    :value="model.surname"
                                    @update="changeSearchModel"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-3">
                    <atbl-form-group label="Status">
                        <atbl-select name="is_used"
                                     :options="usedOptions"
                                     :value="model.is_used"
                                     @update="changeSearchModel"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-3">
                    <atbl-form-group label="Per Page">
                        <atbl-select name="paginate"
                                     :options="[25, 50, 100, 250, 500]"
                                     :first-option-label="null"
                                     :value="model.paginate"
                                     @update="changeSearchModel"
                        />
                    </atbl-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col" v-if="$can('Users - Get List')">
                    <multi-agents-picker v-model="changeAgents"
                    />
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button class="btn btn-primary btn-sm"
                    @click="fetchFreeGames"
            >
                <i class="fa fa-search mr-1"></i> Search
            </button>
            <button class="btn btn-danger btn-sm"
                    @click="clearSearchForm"
            >
                <i class="fa fa-ban mr-1"></i> Clear
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchFreeGameForm',
    inject: [
        'clearSearchForm',
        'fetchFreeGames',
        'changeSearchModel',
        'usedOptions',
    ],
    props: {
        showSearch: {
            type: Boolean,
            default: false
        },
        model: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        changeAgents: {
            get() {
                return this.model.agents;
            },
            set(value) {
                this.changeSearchModel('agents', value);
            }
        }
    }
}
</script>