<template>
    <div class="container-fluid ticket-results">
        <div v-if="!isLoading" class="row">
            <div class="col-12">
                <raffle-ticket-info v-if="ticket" :ticket="ticket" />
            </div>
        </div>
        <div v-if="!isLoading" class="row">
            <div class="col-12">
                <raffle-ticket-lines v-if="ticket" :ticket="ticket" />
            </div>
        </div>
        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import RaffleTicketInfo from './Sections/RaffleTicketInfo';
import RaffleTicketLines from './Sections/RaffleTicketLines';

export default {
    name: 'raffle-ticket',

    components: { RaffleTicketInfo, RaffleTicketLines },

    props: {
        id: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            ticket: null,
            isLoading: false
        };
    },

    created() {
        this.fetchRaffleTicket();
    },

    methods: {
        fetchRaffleTicket() {
            this.isLoading = true;
            window.axios.get(`/raffle-tickets/${this.id}/show`)
                .then((response) => {
                    this.ticket = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (this.hasPermissionError(error)) {
                        return;
                    }
                    const { message } = error;
                    this.isLoading = false;
                    window.showMessage(message,false);
                });
        }
    },
}
</script>
