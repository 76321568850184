var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: _vm.$imageService.gameImage(_vm.editingGame.image),
            width: "60"
          }
        }),
        _vm._v("\n            " + _vm._s(_vm.editingGame.name) + "\n        ")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          class: [{ disabled: !!_vm.isLoading }],
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.clearGameForEdit.apply(null, arguments)
            }
          }
        },
        [_c("i", { staticClass: "fa fa-times" })]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _vm.isLottery
          ? [
              _c("LinesEditorComponent", {
                attrs: { "editing-game": _vm.editingGame, data: _vm.lineData }
              }),
              _vm._v(" "),
              _c("DrawsEditorComponent", {
                attrs: { "editing-game": _vm.editingGame, data: _vm.drawData }
              })
            ]
          : [
              _c("FractionsEditorComponent", {
                attrs: {
                  "editing-game": _vm.editingGame,
                  data: _vm.fractionData
                }
              })
            ]
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _c(
        "button",
        {
          attrs: { disabled: _vm.isLoading },
          on: { click: _vm.clearGameForEdit }
        },
        [_vm._v("\n            Close\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }