var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Player - Create/Update")
    ? _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                content:
                  "" +
                  (_vm.isPlayerActive
                    ? "Deactivate profile"
                    : "Activate profile")
              },
              expression:
                "{\n            content: `${isPlayerActive ? 'Deactivate profile' : 'Activate profile'}`\n        }"
            }
          ],
          staticClass: "btn btn-sm",
          class: {
            "btn-outline-success": !_vm.isLoading && !_vm.isPlayerActive,
            "btn-outline-danger": !_vm.isLoading && _vm.isPlayerActive
          },
          attrs: { size: "sm" },
          on: { click: _vm.changeActiveState }
        },
        [
          !_vm.isLoading
            ? _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.isPlayerActive ? "Deactivate" : "Activate") +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }