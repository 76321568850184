<template>
    <player-layout :id="id" title="KYC attachments">
        <div class="general-list agents">
            <div class="row border-bottom d-none d-lg-flex">
                <div class="col-2 text-muted">Attachment</div>
                <div class="col-2 text-muted">Added By</div>
                <div class="col-3 text-muted">Type</div>
                <div class="col-3 text-muted">Expires at</div>
                <div class="col-2 text-muted">Actions</div>
            </div>

            <div v-for="(playerKycSubmission, index) in attachments" :key="index" class="row py-3 border-top" :class="{'bg-danger-lighter': playerKycSubmission.status === 'declined'}">
                <div class="col-2">
                    <strong v-if="playerKycSubmission.status === 'declined'">[DECLINED]</strong> Attachment {{ index + 1 }}
                </div>
                <div class="col-2">
                    <span class="badge badge-primary">{{ playerKycSubmission.attachment.created_by.name }}</span>
                </div>
                <div class="col-3">
                    <span v-if="! editingAttachment || editingAttachment.id !== playerKycSubmission.id">
                        {{ playerKycSubmission.kyc_attachment_type ? playerKycSubmission.kyc_attachment_type.type : '' }} {{ playerKycSubmission.is_back_side ? '(Backside)' : '' }}
                    </span>

                    <select class="form-control" v-model="editingKycAttachmentType" v-if="editingAttachment && editingAttachment.id === playerKycSubmission.id">
                        <option :value="null">Select attachment type</option>
                        <option v-for="availableKycAttachmentType in availableKycAttachmentTypes" :value="availableKycAttachmentType.id">{{ availableKycAttachmentType.type }}</option>
                    </select>
                </div>
                <div class="col-3">
                    <span v-if="! editingAttachment || editingAttachment.id !== playerKycSubmission.id">
                        {{ playerKycSubmission.expires_at ? playerKycSubmission.expires_at : '' }}
                    </span>

                    <atbl-form-group v-if="editingAttachment && editingAttachment.id === playerKycSubmission.id" class="mb-0" :label-cols="3" :horizontal="false">
                        <date-range-picker v-model="editingExpiresAt"
                                           :singleDatePicker="true"
                                           :autoApply="true"
                                           :ranges="false"
                                           :minDate="minDate"
                                           :showDropdowns="true">
                            <div slot="input" slot-scope="picker">
                                <span v-if="editingExpiresAtFormatted">{{ editingExpiresAtFormatted }} <i title="Clear date" @click="editingExpiresAt = {startDate: null, endDate: null}" style="margin-left: 8px" class="fa fa-times"></i></span>
                                <span v-else>Select date</span>
                            </div>
                        </date-range-picker>
                    </atbl-form-group>
                </div>
                <div class="col-2">
                    <template v-if="! editingAttachment || editingAttachment.id !== playerKycSubmission.id">
                        <a href="#" v-if="$can('KYC - Manage KYC')" @click.prevent="editingAttachment = playerKycSubmission" title="Edit" class="mr-2">
                            <i class="fa fa-pencil-alt"></i>
                        </a>
                        <a href="#" @click.prevent="previewingAttachment = playerKycSubmission" title="Preview" class="mr-2">
                            <i class="fa fa-eye"></i>
                        </a>
                        <a href="#" v-if="$can('KYC - Manage KYC')" @click.prevent="attachmentToBeDeleted = playerKycSubmission" title="Delete" class="mr-4">
                            <i class="fa fa-trash-alt"></i>
                        </a>
                        <a href="#" v-if="canBeApproved(playerKycSubmission) && $can('KYC - Manage KYC')" @click.prevent="approveAttachment(playerKycSubmission)" title="Approve attachment">
                            <i class="fa fa-check-circle"></i>
                        </a>
                        <a href="#" v-if="playerKycSubmission.status === 'pending' && $can('KYC - Manage KYC')" @click.prevent="decliningAttachment = playerKycSubmission" title="Decline attachment">
                            <i class="fa fa-minus-circle text-danger"></i>
                        </a>
                    </template>

                    <template v-if="editingAttachment && editingAttachment.id === playerKycSubmission.id">
                        <a href="#" @click.prevent="updateAttachment" title="Save" class="mr-2">
                            <i class="fa fa-save"></i>
                        </a>
                        <a href="#" class="text-danger" @click.prevent="editingAttachment = null" title="Cancel">
                            <i class="fa fa-times"></i>
                        </a>
                    </template>
                </div>
            </div>

            <not-found :items="attachments" msg="No attachments found." />
        </div>

        <div class="row border-bottom" v-if="$can('KYC - Manage KYC')">
            <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-sm btn-primary my-2" @click="showAddAttachmentModal = true">Add new kyc attachment</button>
            </div>
        </div>

        <div class="row border-bottom justify-content-between py-3" v-if="$can('KYC - Manage KYC')">
            <div class="col d-flex justify-content-end">
                <c-dropdown toggler-text="Change KYC status" color="primary" size="sm">
                    <c-dropdown-item v-for="playerKycStatus in availablePlayerKycStatuses" :key="`kyc_status_${playerKycStatus.key}`" @click="changePlayerKycStatus(playerKycStatus.key)">
                        {{ playerKycStatus.label }}
                    </c-dropdown-item>
                </c-dropdown>
            </div>
        </div>

        <div class="row border-bottom justify-content-between py-3" v-if="$can('KYC - Manage KYC')">
            <div class="col-12 mb-2">
                <h4 class="border-bottom">Communication</h4>
            </div>

            <div class="col-12">
                <div ref="messagesWrapper" style="max-height: 400px; overflow-y: auto" class="row">
                    <div class="col-12 mb-2 d-flex" v-for="message in discussionMessages" :key="`message_${message.id}`" :class="{'flex-row-reverse': message.is_owner}">
                        <div class="rounded-circle bg-primary text-white d-flex justify-content-center align-items-center" style="width: 40px; height: 40px">{{ message.author.initials }}</div>
                        <div class="px-3 pt-3 pb-4 bg-light d-inline-flex rounded-bottom rounded-right w-75 mx-2 position-relative" :class="{'justify-content-end text-right': message.is_owner}">
                            {{ message.body }}
                            <span v-if="canMessageBeEdited(message)" @click="editMessage(message)" class="position-absolute font-xs cursor-pointer" style="opacity: 0.4; top: 15px; right: 15px">
                                <i class="fa fa-pencil-alt" />
                            </span>
                            <span class="position-absolute" style="opacity: 0.4; bottom: 5px; right: 15px">{{ message.commented_at }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 border-top pt-2">
                <textarea class="rounded border-light p-2 w-100 mb-2" :disabled="sendingMessage" v-model="message" placeholder="Your message"></textarea>

                <div class="d-flex justify-content-end">
                    <button class="btn btn-sm btn-danger mr-2" v-if="editingMessage" @click="editingMessage = null; message = null">Cancel</button>
                    <button class="btn btn-sm btn-primary" :disabled="sendingMessage || ! message" @click="sendMessage">{{ this.editingMessage ? 'Edit message' : 'Send message' }}</button>
                </div>
            </div>
        </div>

        <add-new-kyc-attachment-modal v-if="showAddAttachmentModal"
                                      v-on:cancel="showAddAttachmentModal = null"
                                      v-on:confirm="storeAttachment" />

        <remove-kyc-attachment-modal v-if="attachmentToBeDeleted"
                                     v-on:cancel="attachmentToBeDeleted = null"
                                     v-on:confirm="deleteAttachment" />

        <decline-kyc-attachment-modal v-if="decliningAttachment"
                                      v-on:cancel="decliningAttachment = null"
                                      v-on:confirm="declineAttachment" />

        <preview-kyc-attachment-modal v-if="previewingAttachment"
                                      :player-kyc-attachment="previewingAttachment"
                                      v-on:cancel="previewingAttachment = null" />

        <notify-kyc-verified-player-modal v-if="showNotifyKycVerifiedPlayerModal"
                                          label="Do you want to notify player for his kyc verification ?"
                                          v-on:cancel="showNotifyKycVerifiedPlayerModal = false"
                                          v-on:confirm="updatePlayerKycStatus('force-verified', true); showNotifyKycVerifiedPlayerModal = false" />
    </player-layout>
</template>

<script>
import KycService from '../../../../services/KycService';
import NotifyKycVerifiedPlayerModal from './Components/NotifyKycVerifiedPlayerModal';
import RemoveKycAttachmentModal from './Components/RemoveKycAttachmentModal';
import PreviewKycAttachmentModal from './Components/PreviewKycAttachmentModal';
import AddNewKycAttachmentModal from './Components/AddNewKycAttachmentModal';
import HelperDate from '../../../../helpers/Date';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import DeclineKycAttachmentModal from './Components/DeclineKycAttachmentModal';
import { getAllKYCStatues } from '../../Components/Layout/KYCStatus/KYCStatusConstants'

export default {
    components: {
        DeclineKycAttachmentModal,
        AddNewKycAttachmentModal,
        PreviewKycAttachmentModal,
        RemoveKycAttachmentModal,
        NotifyKycVerifiedPlayerModal,
        DateRangePicker,
    },

    props: [
        'id',
        'auth_user',
    ],

    data() {
        return {
            minDate: moment().toDate(),
            showAddAttachmentModal: false,
            showNotifyKycVerifiedPlayerModal: false,
            attachmentToBeDeleted: null,
            previewingAttachment: null,
            editingAttachment: null,
            editingKycAttachmentType: null,
            editingExpiresAt: { startDate: null },
            decliningAttachment: null,
            editingMessage: null,

            availableKycAttachmentTypes: [],
            availablePlayerKycStatuses: [
                {
                    key: 'not-verified',
                    label: 'Not verified',
                },
                {
                    key: 'in-process',
                    label: 'In process',
                },
                {
                    key: 'force-verified',
                    label: 'Verified',
                },
                {
                    key: 'expired',
                    label: 'Expired',
                },
            ],

            attachments: [],
            selectedFile: null,
            notifyApproval: false,
            discussionMessages: [],
            message: null,
            sendingMessage: false,
        };
    },

    computed: {
        editingExpiresAtFormatted() {
            return this.editingExpiresAt.startDate ? HelperDate.format(this.editingExpiresAt.startDate, 'YYYY-MM-DD') : null;
        },
    },

    watch: {
        editingAttachment(val) {
            const kycAttachmentType = val ? val.kyc_attachment_type_id || null : null;
            const expiresAt = val ? val.expires_at || null : null;

            this.editingKycAttachmentType = kycAttachmentType;
            this.editingExpiresAt = { startDate: expiresAt };
        },
    },

    mounted() {
        this.fetchPlayerKycAttachmentTypes().then(() => {
            this.fetchPlayerKycAttachments();
        });

        if (this.$can('KYC - Manage KYC')) {
            this.fetchPlayerKycDiscussion();
        }
    },

    methods: {
        fetchPlayerKycAttachmentTypes() {
            const promise = KycService.fetchKycAttachmentTypes();

            promise.then((response) => {
                this.availableKycAttachmentTypes = response.data;
            });

            return promise;
        },

        fetchPlayerKycAttachments() {
            KycService.getPlayerKycAttachments(this.id).then((response) => {
                this.attachments = response.data;
            });
        },

        fetchPlayerKycDiscussion() {
            KycService.fetchPlayerKycDiscussion(this.id).then((response) => {
                this.discussionMessages = response.data.map(m => {
                    return {
                        id: m.id,
                        body: m.body,
                        author: {
                            ...m.author,
                            initials: m.author.name.substring(0, 2).toUpperCase(),
                        },
                        is_owner: m.author.is_owner,
                        can_be_edited: m.can_be_edited,
                        commented_at: moment(m.created_at).format('YYYY-MM-DD HH:mm'),
                    };
                });

                this.scrollMessagesToBottom();
            });
        },

        changePlayerKycStatus(status) {
            if (status === 'force-verified') {
                this.showNotifyKycVerifiedPlayerModal = true;
                return;
            }

            this.updatePlayerKycStatus(status, false);
        },

        updatePlayerKycStatus(status, shouldNotify) {
            const params = {
                status,
                notify: shouldNotify,
            };

            KycService.changePlayerKycStatus(this.id, params).then(() => {
                window.showMessage(
                    `
                        Player KYC status was updated to: 
                        <strong>${getAllKYCStatues().find(i=>i.value === params.status)?.label}</strong>
                    `);
            });
        },

        updateAttachment() {
            const params = {
                kycAttachmentType: this.editingKycAttachmentType,
                expiresAt: this.editingExpiresAt.startDate ? moment(this.editingExpiresAt.startDate).format('YYYY-MM-DD') : null,
            };

            KycService.updatePlayerKycAttachment(this.id, this.editingAttachment.id, params).then((response) => {
                window.showMessage('KYC attachment updated')
                this.fetchPlayerKycAttachments();
            }).catch(err => {
                window.flashBadResponse(err);
            }).finally(() => {
                this.editingKycAttachmentType = null;
                this.editingExpiresAt = null;
                this.editingAttachment = null;
            });
        },

        deleteAttachment() {
            KycService.removePlayerKycAttachment(this.id, this.attachmentToBeDeleted.id).then(() => {
                const attachmentIndex = this.attachments.findIndex(a => a.id === this.attachmentToBeDeleted.id);
                if (attachmentIndex !== -1) {
                    this.attachments.splice(attachmentIndex, 1);
                }

                window.showMessage('KYC attachment deleted');
                this.attachmentToBeDeleted = null;
            });
        },

        storeAttachment(formData) {
            KycService.storePlayerKycAttachment(this.id, formData).then(() => {
                this.fetchPlayerKycAttachments();
                this.showAddAttachmentModal = false;
                window.showMessage('Player KYC attachment was stored');
            }).catch(function (error) {
                window.flashBadResponse(error);
            });
        },

        isFuture(attachment) {
            return attachment.expires_at ? new Date(attachment.expires_at) > new Date() : false;
        },

        canBeApproved(attachment) {
            if (attachment.status !== 'pending') {
                return false;
            }

            if (!attachment.kyc_attachment_type_id || !attachment.expires_at) {
                return false;
            }

            return this.isFuture(attachment);
        },

        approveAttachment(attachment) {
            if (!this.canBeApproved(attachment)) {
                window.showMessage('KYC attachment can not be approved', false);
                return;
            }

            KycService.approvePlayerKycAttachment(this.id, attachment.id).then(() => {
                window.showMessage('KYC attachment approved');
                this.fetchPlayerKycAttachments();
            });
        },

        declineAttachment(message) {
            if (this.decliningAttachment.status !== 'pending') {
                window.showMessage('KYC attachment can not be declined', false);
                return;
            }

            KycService.declinePlayerKycAttachment(this.id, this.decliningAttachment.id).then(() => {
                window.showMessage('KYC attachment declined');
                this.fetchPlayerKycAttachments();

                if (message) {
                    KycService.sendPlayerMessage(this.id, message).then(() => {
                        this.fetchPlayerKycDiscussion();
                    });
                }

                this.decliningAttachment = null;
            });
        },

        sendMessage() {
            this.sendingMessage = true;

            const promise = !this.editingMessage ?
                KycService.sendPlayerMessage(this.id, this.message) :
                KycService.editPlayerMessage(this.id, this.editingMessage, this.message);

            promise.then(() => {
                this.message = null;
                this.fetchPlayerKycDiscussion();
                this.sendingMessage = false;
                this.editingMessage = null;
            }).catch((err) => {
                showMessage('Message can not be edited.', false);
                this.sendingMessage = false;
                this.editingMessage = null;
                this.message = null;
            })
        },

        scrollMessagesToBottom() {
            setTimeout(() => {
                const wrapper = this.$refs.messagesWrapper;

                if (wrapper) {
                    wrapper.scrollTop = wrapper.scrollHeight;
                }
            }, 150);
        },

        canMessageBeEdited(message) {
            return ! message.is_owner && message.can_be_edited;
        },

        editMessage(message) {
            const authorId = message.author.id;
            const authorType = message.author.type;

            if (authorId === this.userId && authorType === 'App\\User') {
                this.message = message.body;
                this.editingMessage = message.id;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-danger-lighter {
    background-color : rgb(229 83 83 / 25%) !important;
}
</style>