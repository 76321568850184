var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "settings-categories" }, [
    _c("div", { staticClass: "row settings-categories-breadcrumb" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "breadcrumb-holder" }, [
          _c(
            "ol",
            { staticClass: "breadcrumb" },
            [
              _c("li", { staticClass: "breadcrumb-item" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.openSection($event, 0, true)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-home text-dark",
                      attrs: { "aria-hidden": "false" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.breadcrumbs, function(item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "breadcrumb-item" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.openSection($event, item.id, true)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.name) +
                            "\n                        "
                        )
                      ]
                    )
                  ]
                )
              })
            ],
            2
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "general-list" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [_vm._v("Key")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("Type")]),
          _vm._v(" "),
          _vm.$canAny(_vm.permissionsOperations)
            ? _c("div", { staticClass: "col-2 text-center" }, [
                _vm._v("\n                ...\n            ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._l(_vm.sections, function(section, index) {
          return !_vm.isLoading
            ? _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.openSection($event, section.id)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(section.name) +
                          "\n                "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(section.key) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-2" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(section.type) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _vm.$canAny(_vm.permissionsOperations)
                  ? _c(
                      "div",
                      { staticClass: "col-2 text-center" },
                      [
                        _c(
                          "c-dropdown",
                          {
                            attrs: {
                              id: "dropdown-right",
                              placement: "bottom-end",
                              caret: false,
                              color: "link"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "toggler-content",
                                  fn: function() {
                                    return [
                                      _c("i", {
                                        staticClass: "fa fa-ellipsis-v",
                                        attrs: { "aria-hidden": "false" }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _vm.$can("Settings - Create/Update")
                              ? _c(
                                  "c-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editSettingSection(
                                          section.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                        Edit\n                    "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$can("Settings - Remove")
                              ? _c(
                                  "c-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeSettingSection(section)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                        Remove\n                    "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        !_vm.isLoading
          ? _c("not-found", {
              attrs: {
                items: _vm.sections,
                msg: "No categories/sections found"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("loader", {
          staticClass: "my-2",
          attrs: { type: "small", show: _vm.isLoading }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }