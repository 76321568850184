<template>
	<div class="card player-nav">
		<div class="card-body">
			<div class="player-nav-group"
				 v-if="$canAny(otherTop) || $canAny(manualWallet)"
			>
				<a v-if="$can('Player - Place A Bet')"
				   :href="`/players/${id}/play`"
				   class="player-nav-group-button btn btn-primary"
				>
					<i class="fa fa-play" aria-hidden="false"></i> PLAY
				</a>
				<a v-if="$can('Payon - Funds Deposit Agent')"
				   :href="`/players/${id}/funds`"
				   class="player-nav-group-button btn btn-success"
				>
					<i class="fa fa-wallet" aria-hidden="false"></i> ADD FUNDS
				</a>
				<a v-if="$canAny(manualWallet)"
				   :href="`/players/${id}/manage/wallet`"
				   class="player-nav-group-button btn btn-warning"
				>
					<i class="fa fa-wallet" aria-hidden="false"></i> MANAGE WALLET
				</a>
				<send-email-component v-if="$can('Player - Send Email')"
									  :client="player"
				/>
			</div>
			
			<div v-if="$canAny(crm)"
				 class="player-nav-group"
			>
				<span class="navbar-text border-bottom font-weight-bold">CRM</span>
				<ul class="nav flex-column">
					<li v-if="$can('Player - View Details')"
						:class="{'active': isRoute('agent.players.view')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/view`" class="nav-link">
							Profile
						</a>
					</li>
					<li v-if="$can('Player - View Notes')"
						:class="{'active': isRoute('agent.players.notes')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/note`" class="nav-link">
							Notes
						</a>
					</li>
					<li v-if="$can('Player - View Messages')"
						:class="{'active': isRoute('agent.players.messages')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/message`" class="nav-link">
							Messages
						</a>
					</li>
					<li v-if="$can('Player - View Dispositions')"
						:class="{'active': isRoute('agent.players.dispositions')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/dispositions`" class="nav-link">
							Dispositions
						</a>
					</li>
					<li v-if="$can('Player - View Credit Cards')"
						:class="{'active': isRoute('agent.players.cards')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/cards`" class="nav-link">
							Credit Cards
						</a>
					</li>
					<li v-if="$can('Player - View Call History')"
						:class="{'active': isRoute('agent.players.call-history')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/call-history`" class="nav-link">
							Call History
						</a>
					</li>
					<li v-if="$can('KYC - Manage KYC')"
						:class="{'active': isRoute('players.kyc-list')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/kyc/list`" class="nav-link">
							KYC
						</a>
					</li>
					<li v-if="$can('Player - View Login History')"
						:class="{'active': isRoute('agent.players.login-history')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/login-history`" class="nav-link">
							Login History
						</a>
					</li>
					<li v-if="$can('Player - Agents History View')"
						:class="{'active': isRoute('agent.players.agents-history')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/agents-history`" class="nav-link">
							Agents History
						</a>
					</li>
					<li v-if="$can('Player - Free Games View')"
						:class="{'active': isRoute('agent.players.free-games')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/free-games`" class="nav-link">
							Free Games
						</a>
					</li>
					<li v-if="$can('Player - Blocks View')"
						:class="{'active': isRoute('players.blocks.view')}"
						class="nav-item"
					>
						<a :href="`/player/${id}/blocks`" class="nav-link">
							Blocks
						</a>
					</li>
				</ul>
			</div>
			
			<div v-if="$canAny(play)"
				 class="player-nav-group"
			>
				<span class="navbar-text border-bottom font-weight-bold">REPORTS</span>
				<ul class="nav flex-column">
					<li v-if="$can('Player - View Tickets')"
						:class="{'active': isRoute('agent.players.tickets')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/tickets`" class="nav-link">
							Tickets
						</a>
					</li>
					<li v-if="$can('Player - View Tickets')"
						:class="{'active': isRoute('agent.players.raffles')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/raffles`" class="nav-link">
							Raffles
						</a>
					</li>
					<li v-if="$can('Player - View Tickets')"
						:class="{'active': isRoute('agent.players.syndicate-shares')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/syndicate-shares`" class="nav-link">
							Syndicate Shares
						</a>
					</li>
					<li v-if="$can('Player - View Tickets')"
						:class="{'active': isRoute('agent.players.bundle-tickets')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/bundle-tickets`" class="nav-link">
							Bundle Tickets
						</a>
					</li>
					<li v-if="$can('Player - View Orders')"
						:class="{'active': isRoute('agent.players.orders')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/orders`" class="nav-link">
							Orders
						</a>
					</li>
					<li v-if="$can('Player - View Transactions')"
						:class="{'active': isRoute('agent.players.transactions')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/transactions`" class="nav-link">
							Transactions
						</a>
					</li>
					<li v-if="$can('Player - Withdrawals')"
						:class="{'active': isRoute('player.with.requests')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/withdrawals`" class="nav-link">
							Withdrawals
						</a>
					</li>
					<li v-if="$can('Player Player Bonuses - Overview')"
						:class="{'active': isRoute('agent.players.')}"
						class="nav-item"
					>
						<a :href="`/players/${id}/bonuses`" class="nav-link">
							Bonuses
						</a>
					</li>
				</ul>
			</div>
			
			<div v-if="$canAny(marketing)"
				 class="player-nav-group"
			>
				<span class="navbar-text border-bottom font-weight-bold">MARKETING</span>
				<ul class="nav flex-column">
					<li v-if="$can('Player - View Campaigns')"
						class="nav-item"
					>
						<a href="#" class="nav-link">
							Campaign
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import TypesConst from "../../../../store/TypesConst";
	import NamespaceConst from "../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";
	
	const {
		mapGetters: mapPlayerGetters
	} = createNamespacedHelpers(NamespaceConst.players);

    import SendEmailComponent from '../../../../components/SendEmail/SendEmailComponent';
	
	export default {
		name: 'player-navigation',
		components: {
            SendEmailComponent
		},
		props: [
			'id'
		],
		data: function () {
			return {
				otherTop: [
					"Player - Place A Bet",
					"Player - Send Email",
					"Payon - Funds Deposit Agent"
				],
				manualWallet: [
					"Payment - Manual Wallet Withdrawals",
					"Payment - Manual Wallet Refunds"
				],
				crm: [
					"Player - View Details",
					"Player - View Notes",
					"Player - View Messages",
					"Player - View Dispositions",
					"Player - View Credit Cards",
					"Player - View Call History"
				],
				play: [
					"Player - View Tickets",
					"Player - View Orders",
					"Player - View Transactions",
					"Payon - Funds Deposit Agent",
					"Withdrawals - View"
				],
				marketing: [
					"Player - View Campaigns"
				]
			};
		},
		computed: {
			...mapPlayerGetters({
				player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL
			})
		}
	}
</script>

<style scss lang="scss">
	.player-nav {
		.card-body {
			padding: 10px;
		}
		
		.nav {
			li {
				&.nav-item {
					border-bottom: 1px solid #e0e0e0;
					
					&:nth-child(odd) {
						background-color: #f6f6f6;
					}
					
					&:hover {
						background-color: #f6f6f6;
					}
					
					&.active {
						background-color: #20a8d8;
						
						a {
							color: #FFF;
						}
					}
				}
			}
		}
		
		&-group {
			display: flex;
			width: 100%;
			flex-direction: column;
			
			&:not(:first-of-type) {
				margin-top: 1rem;
			}
			
			&-button {
				margin-bottom: 5px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				i {
					width: 20px !important;

					&.fa.fa-wallet {
						text-align: left;
					}
				}
			}
		}
	}
</style>
