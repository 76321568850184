<template>
    <atbl-select label="Language"
                 name="language_id"
                 :options="languages"
                 :disabled="isLoading"
                 :value="getValue"
                 @update="changed"
                 :firstOptionLabel="null"
    >
        <template #formGroup v-if="!!getValue">
            <img :src="`/img/flags/${getValue.toLowerCase()}.svg`" class="flag" alt="flag" />
        </template>
    </atbl-select>
</template>

<script>
import LanguagesService from '../../../services/LanguagesService';

export default {
    name: 'language-picker',
    props: {
        input: {},
        value: {},
        returnOnlyValue: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            languages: [],
            isLoading: false,
        };
    },
    computed: {
        getValue() {
            return !!this.returnOnlyValue
                ? this.value
                : this.value.value;
        },
    },
    methods: {
        async getLanguages() {
            this.isLoading = true;

            try {
                const response = await LanguagesService.getLanguagesDropdownList();

                this.languages = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        changed(name, value) {
            const language = !!this.returnOnlyValue
                ? value
                : this.languages.find(language => language.value === value);

            this.$emit('input', language);
            this.$emit('update', name, language);
        }
    },
    async created() {
        await this.getLanguages();
    },
}
</script>