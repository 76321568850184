var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Remove Conversion Rate",
        show: _vm.modalOpened,
        "close-on-backdrop": false
      },
      on: {
        close: function($event) {
          _vm.modalOpened = false
        }
      },
      scopedSlots: _vm._u(
        [
          _vm.$can("Conversions Rate - Remove")
            ? {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: { click: _vm.cancelModal }
                      },
                      [_vm._v("\n            Close\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.submitModal }
                      },
                      [_vm._v("\n            Ok\n        ")]
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              "Are you sure you want to delete " +
                _vm._s(_vm.typeOfCurrency) +
                " currency rate?"
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }