var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.isPlayerLoading && _vm.$can("Player - Call")
        ? _c("PlayerCallComponent", {
            attrs: {
              number: _vm.number,
              player: _vm.player,
              "agent-id": _vm.agentid
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isPlayerLoading && _vm.player && _vm.isset(_vm.player, "id")
        ? _c(
            "atbl-tabs",
            { attrs: { variant: "tabs" } },
            [
              _c(
                "atbl-tab",
                { attrs: { title: "Disposition", active: "" } },
                [
                  _vm.$can("Player - Add Disposition")
                    ? _c("AddDispositionSection", {
                        attrs: {
                          player_id: _vm.player_id,
                          dispositions: _vm.dispositions,
                          saveLoader: _vm.dispositionsSaveLoader,
                          errors: _vm.errors,
                          clean: _vm.isClean
                        },
                        on: { saveNewDisposition: _vm.saveDisposition }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("Player - View Dispositions")
                    ? _c("PlayerDispositionsList", {
                        attrs: {
                          playerDispositions: _vm.playerDispositions,
                          loader: _vm.dispositionsLoader
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("Player - View Calls")
                    ? _c("player-last-calls", {
                        attrs: { "player-id": _vm.player_id }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "atbl-tab",
                { attrs: { title: "Notes" } },
                [_c("call-notes", { attrs: { "callable-id": _vm.player_id } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isPlayerLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }