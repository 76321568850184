<template>
    <atbl-form-group id="fsCountry"
                     :label="label"
                     label-for="country"
                     class="font-weight-bold"
                     :horizontal="isHorizontal"
                     :error="getError(error, 'country')"
    >
        <tree-select v-model="selectedCountry"
                     :multiple="multiple"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="countries"
                     :normalizer="(node) => ({id: node.value, label: node.text})"
                     :disabled="disabled || isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
    </atbl-form-group>
</template>

<script>
import AreasService from '../../../services/AreaService';

export default {
    name: 'countries-options',
    props: {
        value: {
            default: null
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        error: {
            type: Object,
            default: () => ({})
        },
        label: {
            type: String,
            default: 'Country'
        },
        name: {
            type: String,
            default: 'country'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'input'
    ],
    data() {
        return {
            countries: [],
            isLoading: false
        };
    },
    computed: {
        isHorizontal() {
            return this.horizontal ? this.horizontal : false;
        },
        selectedCountry: {
            get() {
                if (!this.multiple && !this.value) {
                    return null;
                }

                return !this.countries.length ? [] : this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
    async created() {
        await this.getCountries();
    },
    methods: {
        async getCountries() {
            this.isLoading = true;

            try {
                const response = await AreasService.getCountriesOptions();

                this.countries = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>
