var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: { title: "Add new attachment", show: true },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  on: { click: _vm.emitCloseEvent }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { disabled: !_vm.canSubmit },
                  on: { click: _vm.confirm }
                },
                [_vm._v("\n            Confirm\n        ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c(
              "atbl-form-group",
              {
                staticClass: "font-weight-bold",
                attrs: { label: "Kyc attachment type", horizontal: false }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kycAttachmentType,
                        expression: "kycAttachmentType"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.kycAttachmentType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.resetFiles
                      ]
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("Select attachment type")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.availableKycAttachmentTypes, function(
                      availableKycAttachmentType
                    ) {
                      return _c(
                        "option",
                        { domProps: { value: availableKycAttachmentType } },
                        [_vm._v(_vm._s(availableKycAttachmentType.type))]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "form-group-atbl m-0 py-3" }, [
            _c("label", [_vm._v("Attachment (front)")]),
            _vm._v(" "),
            !_vm.selectedFileFront
              ? _c("div", { staticClass: "form-group position-relative m-0" }, [
                  _c("input", {
                    ref: "attachmentFileFrontInput",
                    staticClass: "form-control-file",
                    attrs: {
                      type: "file",
                      accept:
                        "image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    },
                    on: {
                      change: function($event) {
                        return _vm.handleAttachmentUpload("front")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedFileFront
              ? _c("span", { staticClass: "d-block" }, [
                  _c("i", { staticClass: "fa fa-upload" }),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.selectedFileFront.name))])
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm.kycAttachmentType && _vm.kycAttachmentType.is_double_sided
          ? _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group-atbl m-0 py-3" }, [
                _c("label", [_vm._v("Attachment (back)")]),
                _vm._v(" "),
                !_vm.selectedFileBack
                  ? _c(
                      "div",
                      { staticClass: "form-group position-relative m-0" },
                      [
                        _c("input", {
                          ref: "attachmentFileBackInput",
                          staticClass: "form-control-file",
                          attrs: {
                            type: "file",
                            accept:
                              "image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleAttachmentUpload("back")
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedFileBack
                  ? _c("span", { staticClass: "d-block" }, [
                      _c("i", { staticClass: "fa fa-upload" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v(_vm._s(_vm.selectedFileBack.name))])
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c(
              "atbl-form-group",
              {
                staticClass: "font-weight-bold",
                attrs: {
                  label: "Expires at",
                  "label-cols": 3,
                  horizontal: false
                }
              },
              [
                _c("date-range-picker", {
                  attrs: {
                    singleDatePicker: true,
                    autoApply: true,
                    ranges: false,
                    minDate: _vm.minDate,
                    showDropdowns: true
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function(picker) {
                        return _c("div", {}, [
                          _vm.expiresAtFormatted
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.expiresAtFormatted) + " "),
                                _c("i", {
                                  staticClass: "fa fa-times",
                                  staticStyle: { "margin-left": "8px" },
                                  attrs: { title: "Clear date" },
                                  on: { click: _vm.clearExpiresAt }
                                })
                              ])
                            : _c("span", [_vm._v("Select date")])
                        ])
                      }
                    }
                  ]),
                  model: {
                    value: _vm.expiresAt,
                    callback: function($$v) {
                      _vm.expiresAt = $$v
                    },
                    expression: "expiresAt"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }