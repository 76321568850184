export default {
    mutations: {
        SET_HAS_SAVED_CARD: "setHasSavedCard",
        SET_HAS_SAVED_CARD_LOADER: "setHasSavedCardLoader",
        SET_PAYMENT_LOADER: "setPaymentLoader"
    },
    getters: {
        GET_HAS_SAVED_CARD: "getHasSavedCard",
        GET_HAS_SAVED_CARD_LOADER: "getHasSavedCardLoader",
        GET_PAYMENT_LOADER: "getPaymentLoader"
    },
    actions: {
        GET_HAS_SAVED_CARDS_ACTION: "getHasSavedCardsAction",
    }
};