var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "basket-price" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: {
              content: "Price"
            },
            expression: "{\n            content: 'Price'\n          }"
          }
        ],
        staticClass: "mr-2"
      },
      [
        _c("b", [_vm._v("Price:")]),
        _vm._v(" "),
        !!_vm.item.discount
          ? _c("s", { staticClass: "text-danger ml-1" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatCurrency")(_vm.item.price)) +
                  "\n        "
              )
            ])
          : _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatCurrency")(_vm.item.total_price)) +
                  "\n        "
              )
            ])
      ]
    ),
    _vm._v(" "),
    !!_vm.item.discount
      ? _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: "Discount"
                },
                expression: "{\n            content: 'Discount'\n          }"
              }
            ],
            staticClass: "mr-2"
          },
          [
            _c("i", { staticClass: "fa fa-percent" }),
            _vm._v(" " + _vm._s(_vm.formatNumber(_vm.item.discount)) + "\n    ")
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !!_vm.item.discount
      ? _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: "Total"
                },
                expression: "{\n            content: 'Total'\n          }"
              }
            ]
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("formatCurrency")(_vm.item.total_price)) +
                "\n    "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }