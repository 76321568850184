<template>
    <div class="card">
        <div class="card-header card-header-atbl d-flex justify-content-between align-items-center">
            <span><i class="nav-icon fa fa-users mr-2"></i> {{ model.name }} {{ model.surname }}</span>
            <div>
                <button v-if="$can('Leads - Create/Edit')"
                          class="btn btn-sm btn-primary m-0"
                          @click="toggleAddLeadModal"
                >
                    Edit
                </button>
            </div>
        </div>

        <div :class="{
                 'pb-1': !isLoading
             }"
             class="card-body"
        >
            <template v-if="!isLoading">
                <div  v-if="!!model.converted"
                      class="d-flex align-items-center justify-content-between bg-light-gray p-2 mb-3"
                >
                    <div class="d-flex align-items-center">
                        <span class="leads-section-profile-label mr-2">Status</span>
                        <span class="badge badge-success">
                            Converted
                        </span>
                    </div>

                    <a v-if="$can('Player - View Details') && !!model.converted"
                       :href="`/players/${model.player_id}/view`"
                       target="_blank"
                    >
                        View Player
                    </a>
                </div>
                <div class="row leads-section-profile">
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Name</span>
                        <div class="leads-section-profile-text">{{ model.name | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Surname</span>
                        <div class="leads-section-profile-text">{{ model.surname | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Email</span>
                        <div class="leads-section-profile-text">{{ model.email | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Birthdate</span>
                        <div class="leads-section-profile-text">{{ model.birthdate | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Address1</span>
                        <div class="leads-section-profile-text">{{ model.address1 | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Address2</span>
                        <div class="leads-section-profile-text">{{ model.address2 | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Country</span>
                        <div class="leads-section-profile-text">{{ model.country | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">State</span>
                        <div class="leads-section-profile-text">{{ model.state | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">City</span>
                        <div class="leads-section-profile-text">{{ model.city | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Postcode</span>
                        <div class="leads-section-profile-text">{{ model.postcode | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Phone</span>
                        <div class="leads-section-profile-text">
                            {{ model.phone | emptyField }}
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Mobile</span>
                        <div class="leads-section-profile-text">
                            {{ model.mobile | emptyField }}
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 m-0">
                        <span class="leads-section-profile-label">Live Spent</span>
                        <div class="leads-section-profile-text">
                            <span v-if="model.live_spent !== null && model.live_spent === 0">
                                {{ model.live_spent }}
                            </span>
                            <span v-else-if="model.live_spent > 0">
                                {{ model.live_spent | formatCurrency }}
                            </span>
                            <span v-else>
                                /
                            </span>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row leads-section-profile">
                    <div class="col-12">
                        <span class="leads-section-profile-label">Other</span>
                        <div class="leads-section-profile-text">
                            {{ model.other | emptyField }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import LeadModel from '../../../_Components/AddLeadsModal/LeadModel';

export default {
    name: 'leads-preview-profile',
    inject: [
        'toggleAddLeadModal',
    ],
    props: {
        model: {
            type: Object,
            default: () => (LeadModel)
        },
        isLoading: {
            type: Boolean,
            default: false
        },
    }
}
</script>