<template>
    <div class="text-center">
        <h4>
            Do you really want to pay with this card?
        </h4>
        <h5 class="text-danger">
            {{ card.holder }} / {{ card.payment_brand }} * {{ card.last_4_digits }}
        </h5>
    </div>
</template>

<script>
    import TypesConst from "../../../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const {
        mapGetters
    } = createNamespacedHelpers(NamespaceConst.players);

    export default {
        name: "confirm-pay-with-card",
        computed: {
            ...mapGetters({
                card: TypesConst.players.getters.GET_PAY_WITH_MODEL
            }),
        }
    }
</script>