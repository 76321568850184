var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-lg-6 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Draw\n            ")
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-3 text-center" }, [
              _c("img", {
                staticClass: "img-fluid",
                attrs: { src: _vm.logo, width: "80", alt: "" }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-9" })
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-lg-3 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Player\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "a",
            {
              staticClass: "text-primary",
              attrs: {
                href: "/players/" + _vm.bundleTicket.order.player_id + "/view"
              }
            },
            [_c("h3", [_vm._v(_vm._s(_vm.playerFullName))])]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-lg-3 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Winnings\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("h3", { staticClass: "text-primary" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.ticketWinStatus) +
                "\n                "
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Bundle\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v("Bundle: "),
                _c("strong", [_vm._v(_vm._s(_vm.bundle.title))])
              ]),
              _vm._v(" "),
              _c(
                "span",
                [
                  _vm._v("Games: "),
                  _vm._l(_vm.bundle.bundle_games, function(bundleGame) {
                    return _c("img", {
                      staticStyle: { width: "40px", height: "auto" },
                      attrs: {
                        src: _vm.$imageService.gameImage(bundleGame.game.image)
                      }
                    })
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _c("span", [
                _vm._v("Weeks: "),
                _c("strong", [_vm._v(_vm._s(_vm.bundleTicket.weeks))])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }