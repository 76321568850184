import TypesConst from "../../TypesConst";

export default {
    [TypesConst.games.mutations.SET_ALL_GAMES_DATA](state, payload){
        state.games = payload;
    },
    [TypesConst.games.mutations.SET_ALL_GAMES_LOADER](state, payload){
        state.gamesLoader = payload;
    },
    [TypesConst.games.mutations.SET_GAME_CONFIGURATION_DATA](state, payload){
        state.gameConfiguration = payload;
    },
    [TypesConst.games.mutations.SET_GAME_CONFIGURATION_LOADER](state, payload){
        state.gameConfigurationLoader = payload;
    },
};