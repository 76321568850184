<template>
    <div class="card card-list">
        <div class="card-header d-flex align-items-center justify-content-between">
            <div>
                <i class="fa fa-envelope"></i> Messages
            </div>
            <div>
                <button class="btn btn-sm btn-warning"
                          @click="fetchLeadMessages"
                >
                    <i class="fa fa-sync" aria-hidden="false"></i>
                </button>
            </div>
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="row d-none d-xl-flex">
                    <div class="col-12 col-lg status-column text-center">Status</div>
                    <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0">Agent</div>
                    <div class="col">Name</div>
                    <div class="col">Description</div>
                    <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0">Date</div>
                </div>

                <div v-if="!isLoading"
                       v-for="(item, index) in messages"
                       :key="index"
                       class="row"
                >
                    <div class="status-column col-12 col-lg-6 col-xl mb-2 mb-lg-0 text-center">
                        <i class="fa fa-lg"
                           :class="{
                           'fa-envelope-open': item.is_read,
                           'fa-envelope': !item.is_read
                       }"
                           v-tooltip="{
                           content: `${item.is_read ? 'Read' : 'Unread'}`
                       }"
                        ></i>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-0">
                        <span class="small-mb-badge-extended">Agent</span>
                        {{ item.agent_name }}
                    </div>
                    <div class="col-12 col-lg-6 col-xl mb-2 mb-lg-0">
                        <span class="small-mb-badge-extended">Template Name</span>
                        {{ item.template_name }}
                    </div>
                    <div class="description-column col-12 col-lg-6 col-xl mb-2 mb-lg-0">
                        <span class="small-mb-badge-extended">Template Description</span>
                        <span>{{ item.template_description.substr(0, 40) }}</span>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-2">
                        <span class="small-mb-badge-extended">Date</span>
                        {{ utcToLocal(item.created_at) }}
                    </div>
                </div>

                <loader :show="isLoading"
                        type="small"
                />

                <not-found v-if="!isLoading"
                           msg="No messages found."
                           :items="messages"
                />
            </div>
        </div>

        <div v-if="!isLoading && messagesResponse.last_page > 1" class="card-footer">
            <nav class="wrap">
                <atbl-pagination :pagination="messagesResponse"
                                 @paginate="fetchLeadMessages"
                />
            </nav>
        </div>
    </div>
</template>

<script>
import LeadService from '../../../../../services/Marketing/LeadService';

export default {
    name: 'leads-messages',
    props: {
        id: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            messagesResponse: {},
            messages: [],
            isLoading: false,
        };
    },
    async created() {
        await this.fetchLeadMessages();
    },
    methods: {
        async fetchLeadMessages(page = 1) {
            if (!this.id) {
                window.showMessage('Id cannot be undefined.', false);

                return;
            }

            if (!this.$can('Leads - Messages')) {
                window.showMessage('You does not have permissions for this action.', false);

                return;
            }

            this.isLoading = true;

            try {
                const response = await LeadService.leadsMessages(this.id, {
                    page
                });

                this.messagesResponse = response.data;
                this.messages = response.data.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>