<template>
    <player-play-layout :id="id">
        <div v-if="canPlayGame"
             class="player-play-content"
        >
            <div class="player-play-games">
                <div class="card">
                    <div class="card-header">
                        <i class="fa fa-play"></i> Play
                    </div>

                    <div class="card-body">
                        <atbl-tabs :active-tab="0"
                                @change-tab="onTabChange"
                        >
                            <atbl-tab title="Lottery" class="p-2">
                                <Games :isLoading="isLoadingNow" />
                            </atbl-tab>

                            <atbl-tab title="Syndicates" class="p-2">
                                <Syndicates />
                            </atbl-tab>

                            <atbl-tab title="Bundles" class="p-2">
                                <Bundles />
                            </atbl-tab>

                            <atbl-tab title="Free Games" class="free-games-tab px-2 pt-2">
                                <free-games :games="freeGames"
                                            :loading="freeGamesLoading"
                                            :order-id="orderId"
                                />
                            </atbl-tab>
                        </atbl-tabs>
                    </div>
                </div>

                <loader :show="getProcessingOverlay"
                        type="small"
                />

                <lottery-game v-if="!getProcessingOverlay && showGame && isLotteryGame"
                              :selected-free-game="selectedFreeGame"
                />
                <raffle-game v-if="!getProcessingOverlay && showGame && isRaffleGame"
                             :selected-free-game="selectedFreeGame"
                />
                <syndicate-game v-if="!getProcessingOverlay && showGame && isSyndicate"
                                :selected-free-game="selectedFreeGame"
                />
                <bundle-game v-if="!getProcessingOverlay && showGame && isBundle"
                             :selected-free-game="selectedFreeGame"
                />
                <lottery-payments v-if="!showGame && orderId && getCheckout" v-on:gatewayResponse="onGatewayResponse" />
            </div>
            <div class="player-play-checkout">
                <checkout-summary />
            </div>
        </div>
        <CanPlayGame :isLoading="isLoadingNow" />

	    <WalletConfirmAction />
        <PayWithSavedCardModal />
    </player-play-layout>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";

    const {
        mapGetters,
        mapMutations
    } = createNamespacedHelpers(NamespaceConst.players);

    const {
        mapGetters: mapCartGetters,
	    mapMutations: mapCartMutations
    } = createNamespacedHelpers(NamespaceConst.cart);

    const {
        mapGetters: mapGamesGetters
    } = createNamespacedHelpers(NamespaceConst.games);

    const {
        mapGetters: mapOrderGetters
    } = createNamespacedHelpers(NamespaceConst.order);

    import LotteryGame from './Sections/LotteryGame/LotteryGame';
    import LotteryPayment from './Sections/LotteryPayment/LotteryPayment';
    import Payon from '../../../../components/Payon';
    import WalletConfirmAction from './Componenets/WalletConfirmAction/WalletConfirmAction';
    import PayWithSavedCardModal from "./Componenets/PayWithSavedCard/PayWithSavedCardModal";
    import CheckoutSummary from "./Sections/CheckoutSummary/CheckoutSummary";
    import CanPlayGame from "../../Components/Content/CantPlayGame";
    import Games from "./Sections/Games/Games";
    import ProcessingOverlay from "./Componenets/ProcessingOverlay/ProcessingOverlay";

    import LinesPickerMixin from "./Sections/LotteryGame/Mixins/LinesPicker";
    import RaffleGame from "./Sections/RaffleGame/RaffleGame";
    import Syndicates from './Sections/Syndicates/Syndicates';
    import SyndicateGame from './Sections/Syndicates/SyndicateGame';
    import Bundles from './Sections/Bundles/Bundles';
    import BundleGame from './Sections/Bundles/BundleGame';
    import PlayerPlayLayout from './Componenets/PlayerPlayLayout';
    import FreeGamesViewModel from './Sections/FreeGames/FreeGamesViewModel';
    import FreeGames from './Sections/FreeGames/FreeGames';
    import LotteryPayments from './Sections/LotteryPayment/LotteryPayments.vue';
    import AtblTabs from '../../../../components/AtblTabs/AtblTabs.vue';
    import AtblTab from '../../../../components/AtblTabs/AtblTab.vue';

    export default {
        mixins: [
            LinesPickerMixin,
            FreeGamesViewModel,
        ],
        provide () {
            return {
                getFreeGames: this.getFreeGames,
                selectFreeGame: this.selectFreeGame,
                setShowGame: this.setShowGame,
                setProcessingOverlay: this.setProcessingOverlay,
                setCheckout: this.setCheckout,
                clearCart: this.clearCart,
            };
        },
        props: [
            'id'
        ],

        components: {
            SyndicateGame,
            Syndicates,
            Bundles,
            BundleGame,
            RaffleGame,
            LotteryGame,
            LotteryPayments,
            Payon,
	        WalletConfirmAction,
            PayWithSavedCardModal,
            CanPlayGame,
	        CheckoutSummary,
            Games,
	        ProcessingOverlay,
            PlayerPlayLayout,
            FreeGames,
            AtblTabs,
            AtblTab,
        },

        data() {
            return {
                redirectUrl: null,
            };
        },

        computed: {
            ...mapGetters({
                player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL,
	            playerLoader: TypesConst.players.getters.GET_PLAYER_LOADER,
                getPlayerId: TypesConst.players.getters.GET_PLAYER_ID,
                card: TypesConst.players.getters.GET_PAY_WITH_MODEL
            }),
            ...mapCartGetters({
                getCheckout: TypesConst.cart.getters.GET_CHECKOUT,
                getCartTotal: TypesConst.cart.getters.GET_CART_TOTAL,
	            canPlayGame: TypesConst.cart.getters.GET_PLAYER_CAN_PLAY_GAME,
	            showGame: TypesConst.cart.getters.GET_SHOW_GAME,
	            getPaymentLink: TypesConst.cart.getters.GET_PAYMENT_LINK,
	            getAddBalance: TypesConst.cart.getters.GET_ADD_BALANCE,
	            getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
                getProcessingOverlay: TypesConst.cart.getters.GET_PROCESSING_OVERLAY,
            }),
            ...mapGamesGetters({
                getGamesLoader: TypesConst.games.getters.GET_ALL_GAMES_LOADER,
	            getAllGames: TypesConst.games.getters.GET_ALL_GAMES_DATA,
                gameConfiguration: TypesConst.games.getters.GET_GAME_CONFIGURATION_DATA
            }),
            ...mapOrderGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            }),
            isLoadingNow: function(){
	            return this.getGamesLoader || this.playerLoader;
            },
            isLotteryGame() {
                const selectedGameKey = this.gameConfiguration.key || '';

                if (selectedGameKey === '') {
                    return false;
                }

                const findSelectedKey = this.getAllGames.find(g => g.key === selectedGameKey);

                if (!findSelectedKey) {
                    return false;
                }

                return !!findSelectedKey.is_lottery;
            },
            isRaffleGame() {
                const selectedGameKey = this.gameConfiguration.key || '';

                if (selectedGameKey === '') {
                    return false;
                }

                const findSelectedKey = this.getAllGames.find(g => g.key === selectedGameKey);

                if (!findSelectedKey) {
                    return false;
                }

                return !!findSelectedKey.is_raffle;
            },
            isSyndicate() {
                const shares = this.gameConfiguration.total_shares || null;

                return !!shares;
            },
            isBundle() {
                const hasLogoAttachment = this.gameConfiguration.logo_attachment_id || null;

                return !!hasLogoAttachment;
            },
        },
        methods: {
            ...mapMutations({
                setPayWithSavedCardModal: TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL
            }),
            ...mapCartMutations({
	            setPaymentLink: TypesConst.cart.mutations.SET_PAYMENT_LINK,
	            setAddBalance: TypesConst.cart.mutations.SET_ADD_BALANCE,
                setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
                clearCart: TypesConst.cart.mutations.SET_CLEAR_CART_DATA,
                setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
                setProcessingOverlay: TypesConst.cart.mutations.SET_PROCESSING_OVERLAY,
            }),
            onGatewayResponse(response) {
                this.redirectUrl = response.checkout.redirect_url || null;
            },
	        closePayment: function(){
            	this.setPaymentLink(null);
            	this.setAddBalance(null);
		        Atbl.$emit("lotteryPaymentCanceled");
	        },
            onTabChange() {
                this.setShowGame(false);
                this.selectedFreeGame = {};
            },
        },
        created () {
            this.getFreeGames();
        }
    }
</script>

<style lang="scss">
.tab-content {
    padding : 16px 0;
}
</style>
