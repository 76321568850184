export default {
    getDefaultCurrency() {
        return window.axios.get('/exchange-office/default');
    },

    isCached() {
        return !!this.getCached();
    },

    getCached() {
        const defaults = localStorage.getItem('exchange-office');
        if (!defaults) {
            return null;
        }

        return JSON.parse(localStorage.getItem('exchange-office'));
    },

    cache(defaults) {
        return localStorage.setItem('exchange-office', JSON.stringify(defaults));
    },
}