var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card statistic-card" }, [
    _c("div", { staticClass: "card-body", class: _vm.bgColor }, [
      _c("div", { staticClass: "statistic-icon" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "statistic-inner" }, [
        _c("div", { staticClass: "statistic-crm" }, [
          _c("div", { staticClass: "statistic-crm-label" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.crmLabel) +
                "\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "statistic-crm-amount" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.crmTotal) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statistic-web" }, [
          _c("div", { staticClass: "statistic-web-label" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.webLabel) +
                "\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "statistic-web-amount" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.webTotal) +
                "\n                "
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }