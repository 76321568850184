import SendEmailConstants from '../SendEmailConstants';
import EmailSendService from '../../../services/EmailSendService';
import { clone } from '../../../utils/Json/Json';
import EmailTemplateModel from '../EmailTemplateModel';
import SendEmailModel from '../SendEmailModel';
import TicketsService from '../../../services/TicketsService';
import OrdersService from '../../../services/OrdersService';

export default {
    data() {
        return {
            selectedLocale: {
                value: 'gb',
                'label': 'English'
            },

            ticket: {},
            ticketLoader: false,
            ticketTypes: Object.values(SendEmailConstants.TicketTypes),
            ticketError: null,

            selectedEmailTemplate: clone(EmailTemplateModel),
            templates: [],
            templatesOriginal: [],
            emailTemplatesLoader: false,
            errors: {},

            order: {},
            orderLoader: false,
            orderError: null,

            sendEmailModel: clone(SendEmailModel),
        };
    },
    computed: {
        isTicketConfirmation() {
            return this.isset(this.selectedEmailTemplate, 'key')
                ? this.selectedEmailTemplate.key === 'TicketsConfirmation'
                : false;
        },
        isOrderSummary() {
            return this.isset(this.selectedEmailTemplate, 'key')
                ? this.selectedEmailTemplate.key === 'OrderDetailsSummary'
                : false;
        },
    },
    methods: {
        // ticket
        async onTicketSearchEvent() {
            this.ticketLoader = true;
            this.ticketError = null;
            this.ticket = {};

            const ticketId = this.sendEmailModel.ticketId;

            try {
                if (!ticketId) {
                    throw new Error('Ticket ID is undefined.');
                }

                let response = null;

                switch (this.sendEmailModel.ticketType) {
                    case SendEmailConstants.TicketTypes.LottoTicket:
                        response = await TicketsService.getTicketById(ticketId);
                        break;
                    case SendEmailConstants.TicketTypes.RaffleTicket:
                        response = await TicketsService.getRaffleById(ticketId);
                        break;
                }

                if (!response) {
                    throw new Error('Response is undefined.');
                }

                const ticket = response.data;
                const { order } = ticket;
                const { player: { id : ticketPlayerId } } = order;
                const { id : activePlayerId } = this.client;

                if (ticketPlayerId !== activePlayerId) {
                    throw new Error('This ticket does not belongs to selected player.');
                }

                this.ticket = ticket;

                this.ticketLoader = false;
            } catch (error) {
                this.ticketError = error.hasOwnProperty('response') && error.response.data.message ? error.response.data.message : error.message;

                this.ticketLoader = false;
            }
        },
        onUpdateTicketTypeEvent(_, value) {
            this.sendEmailModel.ticketType = value;

            this.onTicketIdChangeEvent(null, null);
        },
        onTicketIdChangeEvent(_, value) {
            this.sendEmailModel.ticketId = value;

            this.ticket = {};
        },

        // order
        async onOrderSearchEvent() {
            this.orderLoader = true;
            this.orderError = null;
            this.order = {};

            const orderId = this.sendEmailModel.orderId;

            if (!orderId) {
                return window.showMessage('Order ID is undefined.', false)
            }

            try {
                
                let response = null;

                try {
                    response = await OrdersService.getorderById(orderId)
                } catch (error) {
                    throw new Error (`Order with ID: ${orderId} not found!`)
                }
                
                const order = response.data; 
                const { id : activePlayerId } = this.client;

                if (order.player_id !== activePlayerId) {
                    throw new Error('This order does not belongs to selected player.');
                }

                if (order.status.toLowerCase() !== 'completed') {
                    throw new Error('This order has not been paid yet.');
                }

                this.order = order;

                this.orderLoader = false;
            } catch (error) {
                this.orderError = error.hasOwnProperty('response') && error.response.data.message ? error.response.data.message : error.message;

                this.orderLoader = false;
            }
        },

        onOrderIdChangeEvent(_, value) {
            this.sendEmailModel.orderId = value;

            this.order = {};
        },

        // email templates
        async getTemplates(sendEmailConfiguration) {
            this.emailTemplatesLoader = true;

            const placeToGet = !!sendEmailConfiguration.leadsEnabled
                ? 'leads'
                : 'players';

            try {
                const response = await EmailSendService.getEmailTemplates(placeToGet);

                this.templates = this.convertToTree(response.data, 'templates');
                this.templatesOriginal = response.data;

                this.emailTemplatesLoader = false;
            } catch (error) {
                this.emailTemplatesLoader = false;

                window.flashError(error);
            }
        },
        onEmailTemplateSelectEvent(value) {
            this.ticket = {}
            this.sendEmailModel.ticketId = null;

            const emailTemplate = this.findEmailTemplateById(this.templatesOriginal, value.id);

            if (!emailTemplate) {
                window.flash('No email template found.', 'alert-danger');

                return;
            }

            this.selectedEmailTemplate = emailTemplate;
            this.sendEmailModel.templateId = emailTemplate.id;
        },
        findEmailTemplateById(options, id) {
            if (!id) {
                throw new Error('Id cannot be undefined.');
            }

            const allNonParentsTemplates = options.filter(item => !item.templates.length);
            const allCategoryContainsTemplates = options.filter(item => !!item.templates.length)
                .map(item => {
                    return item.templates;
                })
                .flat();

            const allTemplates = [
                ...allNonParentsTemplates,
                ...allCategoryContainsTemplates
            ].sort((a, b) => a.id - b.id);

            return allTemplates.find(item => item.id === id);
        },
        clearEmailTemplateModel() {
            this.selectedEmailTemplate = clone(EmailTemplateModel);
        },

        // locale
        onLocaleChangeEvent(_, value) {
            this.selectedLocale = value;
        },

        clearOrderModelData() {
            this.order = {};
            this.orderLoader = false;
            this.orderError = null;
        },

        // send email
        clearSendEmailModel() {
            this.sendEmailModel = clone(SendEmailModel);
            this.clearOrderModelData()
        },

    },
}