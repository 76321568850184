<template>
    <atbl-modal title="Add Bonus"
             :show="showModal"
             @close="handleCancel"
             :close-on-backdrop="false"
             class="hide-close"
    >
        <h5 class="text-center m-0">
            Are you sure you want to add bonus <b>{{ getFundsAmount | formatCurrency }}</b> to this player?
        </h5>

        <template #footer>
            <div class="d-flex w-100 align-items-center">
                <div class="w-50 font-weight-bold">
                    * Required
                </div>
                <div class="w-50 text-right">
                    <button class="btn btn-sm btn-danger"
                              @click="handleCancel"
                    >
                        Cancel
                    </button>
                    <button class="btn btn-sm"
                            :class="{
                                'btn-primary': !getPaymentLoader,
                                'btn-outline-primary': getPaymentLoader
                            }"
                            :disabled="getPaymentLoader"
                            @click="handlePayment"
                    >
                        <span v-if="!getPaymentLoader"
                        >
                            Yes
                        </span>
                        <loader type="small"
                                :show="getPaymentLoader"
                        />
                    </button>
                </div>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
    import TypesConst from "../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    
    const {
        mapGetters: mapPlayerGetters,
        mapMutations: mapPlayerMutations
    } = createNamespacedHelpers(NamespaceConst.players);
    
    const {
        mapGetters: mapPaymentGetters,
        mapMutations: mapPaymentMutations
    } = createNamespacedHelpers(NamespaceConst.payment);
    
    import Payment from "../../../../../modules/payment/Payment";
    import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

    export default {
        name: "confirm-bonus-modal",
        components: {
			AtblModal,
        },
        computed: {
            ...mapPlayerGetters({
	            getPlayerId: TypesConst.players.getters.GET_PLAYER_ID,
                isModalOpened: TypesConst.players.getters.GET_BONUS_CONFIRM_MODAL,
                getFundsAmount: TypesConst.players.getters.GET_FUNDS_AMOUNT,
	            getFundsAgent: TypesConst.players.getters.GET_FUNDS_AGENT,
            }),
	        ...mapPaymentGetters({
		        getPaymentLoader: TypesConst.payment.getters.GET_PAYMENT_LOADER
	        }),
            showModal: {
                get: function(){
                    return this.isModalOpened;
                },
                set: function(value){
                    this.setShowModal(value);
                }
            }
        },
        methods: {
            ...mapPlayerMutations({
                setShowModal: TypesConst.players.mutations.SET_BONUS_CONFIRM_MODAL,
	            setAmount: TypesConst.players.mutations.SET_FUNDS_AMOUNT,
	            setReference: TypesConst.players.mutations.SET_FUNDS_REFERENCE,
	            setAgent: TypesConst.players.mutations.SET_FUNDS_AGENT,
            }),
	        ...mapPaymentMutations({
		        setPaymentLoader: TypesConst.payment.mutations.SET_PAYMENT_LOADER
	        }),
            handleCancel: function(){
                this.setShowModal(false);
            },
            handlePayment() {
                this.setPaymentLoader(true);
                const params = {
                    agent_id: this.getFundsAgent,
                    amount: this.getFundsAmount,
                    bonus: true,
                };
                window.axios.post(`/players/${this.getPlayerId}/add-virtual-funds`, params).then((response) => {
                    this.setAmount(0.00);
                    this.setAgent(null);
                    this.setReference('');

                    this.fetchPlayerWallet();
                }).catch(err => {
                    window.flashError(err);
                }).finally(() => {
                    this.setPaymentLoader(false);
                    this.showModal = false;
                });
            },
        }
    }
</script>