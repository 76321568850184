<template>
    <atbl-modal title="Create Player"
             size="lg"
             :show="true"
             :backdrop="true"
             :close-on-backdrop="false"
             :class="{
                 'hide-close': !!isLoading
             }"
             @close="onCancelEvent"
    >
        <div class="row">
            <div class="col-4">
                <atbl-input label="First name *"
                            name="name"
                            v-model="model.name"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-4">
                <atbl-input label="Surname *"
                            name="surname"
                            v-model="model.surname"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-4">
                <birthday-picker v-model="model.birthdate"
                                 label="DOB *"
                                 format="YYYY-MM-DD"
                                 :errors="modelErrors"
                                 :auto-apply="true"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <atbl-input label="Email *"
                            name="email"
                            v-model="model.email"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-4">
                <atbl-input label="Address 1 *"
                            name="address1"
                            v-model="model.address1"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-4">
                <atbl-input label="Address 2"
                            name="address2"
                            v-model="model.address2"
                            :errors="modelErrors"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <atbl-input label="City *"
                            name="city"
                            v-model="model.city"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-2">
                <atbl-input label="Post Code *"
                            name="postcode"
                            v-model="model.postcode"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-3">
                <atbl-input label="State"
                            name="state"
                            v-model="model.state"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-3">
                <countries-options v-model="model.country"
                                   :error="modelErrors"
                                   label="Country *"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <tel-number id="phonestate"
                            label="Phone *"
                            placeholder="Enter Phone"
                            name="phone"
                            v-model="model.phone"
                            :error="modelErrors"
                />
            </div>
            <div class="col-6">
                <tel-number id="mobilestate"
                            label="Mobile (* if no phone provided)"
                            placeholder="Enter Mobile"
                            name="mobile"
                            v-model="model.mobile"
                            :error="modelErrors"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <atbl-input label="Password *"
                            type="password"
                            name="password"
                            v-model="model.password"
                            :errors="modelErrors"
                />
            </div>
            <div class="col-6">
                <atbl-input label="Password Confirmation *"
                            type="password"
                            name="password_confirmation"
                            v-model="model.password_confirmation"
                            :errors="modelErrors"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <atbl-textarea label="Note"
                               name="note"
                               :errors="modelErrors"
                               v-model="model.note"
                />
            </div>
        </div>

        <template #footer>
            <div class="d-flex w-100 align-items-center">
                <div class="w-50 font-weight-bold">
                    * Required
                </div>
                <div class="w-50 text-right">
                    <button class="btn btn-sm btn-danger"
                              @click="onCancelEvent"
                    >
                        Close
                    </button>
                    <button class="btn"
                            :class="{
                                'btn-primary': !isLoading,
                                'btn-outline-primary': isLoading
                            }"
                            @click="onSavePlayerAction"
                            :disabled="isLoading"
                    >
                        <span v-if="!isLoading"
                        >
                            Save
                        </span>
                        <loader type="small"
                                :show="isLoading"
                        />
                    </button>
                </div>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import CountriesOptions from '../../../../../components/Forms/Select/CountriesOptions';
import BirthdayPicker from '../../../../../components/Forms/Select/BirthdayPicker';
import { clone } from '../../../../../utils/Json/Json';
import { pick } from 'lodash';
import CreatePlayerModel from './CreatePlayerModel';
import PlayerService from '../../../../../services/PlayerService';
import GetValidationErrors from '../../../../../utils/Error/GetValidationErrors';
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'create-player',
    emits: [
        'created-lead',
        'success',
        'close',
    ],
    components: {
        CountriesOptions,
        BirthdayPicker,
        AtblModal,
    },
    props: {
        clientModel: {
            type: Object,
            default: () => ({})
        },
        isLead: {
            type: Boolean,
            default: false
        },
        additionalModelData: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            isLoading: false,
            model: clone(CreatePlayerModel),
            modelErrors: {},
        };
    },
    created() {
        this.initializeModel();
    },
    methods: {
        async onSavePlayerAction() {
            this.isLoading = true;
            this.modelErrors = {};

            const model = {
                ...pick(this.model, Object.keys(CreatePlayerModel)),
                ...this.additionalModelData
            };

            try {
                const response = await PlayerService.createPlayer(model);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    throw new Error(message);
                }

                if(!!this.isLead) {
                    this.$emit('created-lead', {
                        shouldAddStatus: false,
                        setCampaignStatus: false,
                        shouldGoNext: true,
                        model
                    });
                } else {
                    this.$emit('success', model);
                }

                this.onCancelEvent();

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.modelErrors = GetValidationErrors(error);

                window.flashBadResponse(error);
            }
        },
        onCancelEvent() {
            this.model = clone(CreatePlayerModel);
            this.modelErrors = {};
            this.$emit('close');
        },
        initializeModel() {
            if (this.isObjectEmpty(this.clientModel)) {
                return;
            }

            this.model = clone(this.clientModel);
        },
    }
}
</script>