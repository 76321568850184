<template>
    <div class="card m-0 py-3">
        <div v-if="$can('Payon - Virtual Deposit Agent')"
             class="card-body"
        >
            <div class="row">
                <div class="col-12 col-lg-6">
                    <agent-picker v-model="agent" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <atbl-number label="Amount" name="amount" :errors="errors" :min="0.00" :step=".01" :value="amount" @update="updateAmount" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <atbl-select :options="reasonList"
                                    label="Reason"
                                    name="reason"
                                    @update="onReasonChange"
                    />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <atbl-form-group label="Reference" label-for="reference" class="font-weight-bold">
                        <input name="reference" type="text" v-model="reference" placeholder="Enter reference" class="form-control" />
                    </atbl-form-group>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <atbl-form-group label="Last 4" label-for="last4" class="font-weight-bold">
                        <input v-model="last4"
                               name="last4"
                               type="text"
                               placeholder="Enter last 4 digits"
                               class="form-control"
                        />
                    </atbl-form-group>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <atbl-check-box :value="isFailedTransaction"
                                    label="Create failed transaction"
                                    class="atbl-checkbox pb-2"
                                    @input="isFailedTransaction = ! isFailedTransaction"
                    />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <button class="btn"
                            :class="{
                                'btn-primary': !getPaymentLoader,
                                'btn-outline-primary': getPaymentLoader
                            }"
                            @click="setCreditConfirmModal(true)"
                            :disabled="isSaveEnabled"
                    >
                        <span v-if="!getPaymentLoader">Add</span>
                        <loader type="smaller"
                                :show="getPaymentLoader"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../store/NamespaceConst";
    import AtblCheckBox from '../../../../../components/Forms/Inputs/AtblCheckbox.vue';

    import {createNamespacedHelpers} from "vuex";

    const {
        mapGetters: mapPlayerGetters,
        mapMutations: mapPlayerMutations
    } = createNamespacedHelpers(NamespaceConst.players);

    const {
        mapGetters: mapPaymentGetters
    } = createNamespacedHelpers(NamespaceConst.payment);

    import Amount from "../Mixins/Amount";

    export default {
        name: "add-credit-form",
        components: {
            AtblCheckBox
        },
	    mixins: [
	    	Amount
	    ],
        computed: {
            ...mapPlayerGetters({
                getFundsAmount: TypesConst.players.getters.GET_FUNDS_AMOUNT,
                getFundsReference: TypesConst.players.getters.GET_FUNDS_REFERENCE,
                getFundsMeta: TypesConst.players.getters.GET_FUNDS_META,
                getFundsAgent: TypesConst.players.getters.GET_FUNDS_AGENT,
                getFundsIsFailedTransaction: TypesConst.players.getters.GET_FUNDS_IS_FAILED_TRANSACTION,
                getFundsCredit: TypesConst.players.getters.GET_FUNDS_CREDIT_TYPE,
                getPaymentLoader: TypesConst.players.getters.GET_FUNDS_LOADER,
	            getFundsErrors: TypesConst.players.getters.GET_FUNDS_CREDIT_CARD_ERRORS
            }),
	        ...mapPaymentGetters({
		        getPaymentLoader: TypesConst.payment.getters.GET_PAYMENT_LOADER
	        }),
            agent: {
                get: function () {
                    return this.getFundsAgent;
                },
                set: function (value) {
                    this.setFundsAgent(value);
                }
            },
            isFailedTransaction: {
                get: function () {
                    return this.getFundsIsFailedTransaction;
                },
                set: function (value) {
                    this.setFundsIsFailedTransaction(value);
                }
            },
            isSaveEnabled: function(){
                if(this.getPaymentLoader){
                    return true;
                }else {
                    if(this.amount <= 0 || this.isDisabled || !this.getFundsAgent || this.getFundsReference.length === 0){
                        return true;
                    }
                    return false;
                }
            },
            reference: {
                get: function(){
                    return this.getFundsReference;
                },
                set: function(value){
                    this.setFundsReference(value);
                }
            },
            last4: {
                get: function () {
                    return this.getFundsMeta.last4;
                },
                set: function (value) {
                    this.setFundsMeta({key: 'last4', value: value});
                }
            },
        },
        data() {
            return {
                reasonList: [
                    {
                        label: 'interchange reject',
                        value: 'interchange-reject'
                    },
                ],
            };
        },
        methods: {
            ...mapPlayerMutations({
                setFundsAmount: TypesConst.players.mutations.SET_FUNDS_AMOUNT,
                setFundsReference: TypesConst.players.mutations.SET_FUNDS_REFERENCE,
                setFundsReason: TypesConst.players.mutations.SET_FUNDS_REASON,
                setFundsMeta: TypesConst.players.mutations.SET_FUNDS_META,
                setFundsAgent: TypesConst.players.mutations.SET_FUNDS_AGENT,
                setFundsIsFailedTransaction: TypesConst.players.mutations.SET_FUNDS_IS_FAILED_TRANSACTION,
                setFundsCredit: TypesConst.players.mutations.SET_FUNDS_TRANSACTION_TYPE,
                setCreditConfirmModal: TypesConst.players.mutations.SET_FUNDS_CONFIRM_MODAL
            }),

            onReasonChange(e, reason) {
	            this.setFundsReason(reason);
            },
        }
    }
</script>