export default {
    provide() {
        return {
            campaignTitle: this.getCampaignTitle(),
            isLeadCampaign: this.isLeadCampaign,
        };
    },
    computed: {
        campaignTitle() {
            return this.getCampaignTitle();
        },
    },
    methods: {
        getCampaignTitle() {
            return `${this.type}s`;
        },
        isLeadCampaign() {
            return this.type === 'lead';
        },
    },
}