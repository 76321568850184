var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      attrs: {
        label: _vm.label,
        "label-for": _vm.getLabelFor,
        error: _vm.getError(_vm.errors, _vm.name),
        "label-class": _vm.labelClass
      }
    },
    [
      _c("textarea", {
        staticClass: "form-control",
        class: {
          "is-invalid": _vm.hasError(_vm.errors, _vm.name)
        },
        attrs: {
          id: _vm.getLabelFor,
          name: _vm.name,
          placeholder: _vm.getPlaceholder,
          disabled: _vm.disabled,
          rows: _vm.rows
        },
        domProps: { value: _vm.value },
        on: { keyup: _vm.onUpdate }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }