<template>
    <form @submit.prevent="onSearchCampaignsEvent">
        <div class="card card-search mb-3">
            <div class="card-header">
                <i class="fa fa-search" aria-hidden="false"></i>
                Search
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <campaigns-status-picker v-model="searchModel.status"
                                                 :errors="{}"
                        />
                    </div>
                    <div class="col-12 col-lg">
                        <campaign-tags-picker v-model="searchModel.tags"
                                              :search-only="true"
                        />
                    </div>
                </div>
            </div>
            
            <div class="card-footer">
                <button type="submit"
                        class="btn btn-sm btn-primary"
                >
                    <i class="fa fa-search"></i> Search
                </button>
                <button type="reset"
                        class="btn btn-sm btn-danger"
                        @click.prevent="onClearFilterFormEvent"
                >
                    <i class="fa fa-ban"></i> Clear
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { clone } from '../../../../../utils/Json/Json';
import CampaignSearchModel from './CampaignSearchModel';
import CampaignTagsPicker from '../../_Components/CampaignTagsPicker.vue';

export default {
    name: 'campaign-search',
    components: {
        CampaignTagsPicker,
    },
    data() {
        return {
            searchModel: clone(CampaignSearchModel),
        };
    },
    methods: {
        onSearchCampaignsEvent() {
            this.$emit('search', this.searchModel);
        },
        onClearFilterFormEvent() {
            this.searchModel = clone(CampaignSearchModel);

            this.onSearchCampaignsEvent();
        }
    },
}
</script>