import DiscountService from "../../../../services/DiscountService";
import {clone} from '../../../../utils/Json/Json';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';
import errors from '../../../../mixins/Errors';

export default {
	provide() {
		return {
			fetchFractionDiscounts: this.fetchFractionDiscounts,
			addDiscountFraction: this.addDiscountFraction,
			removeDiscountFraction: this.removeDiscountFraction,
			publishFractions: this.publishFractions,
			editDiscountFraction: this.editDiscountFraction,
		};
	},
	data() {
		return {
			editFractionsLoader: false,
			editingGameFractionsDiscountInitial: [],
			editingGameFractionsDiscount: [],
			fractionDiscountActiveVersion: null,
			fractionDiscountErrors: {},
		};
	},
	computed: {
		fractionsData() {
			return {
				discountsInitial: this.editingGameFractionsDiscountInitial,
				discounts: this.editingGameFractionsDiscount,
				activeVersion: this.fractionDiscountActiveVersion,
				loader: this.editFractionsLoader,
				errors: this.fractionDiscountErrors,
			};
		},
	},
	methods: {
		async publishFractions() {
			this.fractionDiscountErrors = {};
			this.editFractionsLoader = true;

			const discountFractions = this.editingGameFractionsDiscount.map(l => {
				return {
					from: l.from,
					to: l.to,
					discount: l.discount,
					discountCrm: l.discount_crm,
				};
			});

			try {
				await DiscountService.publishDiscountFractions(this.editingGame.id, {
					discountFractions,
				});

				await this.setUpFractionDiscounts(this.editingGame);

				window.showMessage('New discount fractions version was published');

				this.editFractionsLoader = false;
			} catch (error) {
				this.editFractionsLoader = false;

				this.fractionDiscountErrors = getValidationErrors(error);
			}
		},
		async fetchFractionDiscounts(game, version = null) {
			this.editFractionsLoader = true;

			try {
				const response = await DiscountService.fetchGameDiscounts(game.id, 'fraction', version);

				this.editingGameFractionsDiscount = response.data;
				this.editingGameFractionsDiscountInitial = clone(response.data);

				this.editFractionsLoader = false;
			} catch (error) {
				this.editFractionsLoader = false;

				window.flashError(error);
			}
		},
		async setUpFractionDiscounts(game) {
			await this.fetchFractionDiscounts(game);

			const hasDiscountFractions = !!this.editingGameFractionsDiscount.length;

			this.fractionDiscountActiveVersion = hasDiscountFractions
				? this.editingGameFractionsDiscount[0].version
				: 1;

			if (!!hasDiscountFractions) {
				return;
			}

			this.addDiscountFraction(1, 0, 0, 0, 0);
		},
		editDiscountFraction(fractionId, name, value) {
			const fractions = clone(this.editingGameFractionsDiscount);

			const currentFraction = fractions.find(fraction => fraction.id === fractionId);

			if (!currentFraction) {
				return window.showMessage(`Fraction with ID:${fractionId} has not been found.`);
			}

			const fractionIndex = fractions.indexOf(currentFraction);

			fractions[fractionIndex][name] = Number(value);

			this.editingGameFractionsDiscount = fractions;
		},
		addDiscountFraction(id, from, to, discount, discountCrm) {
			const ids = this.editingGameFractionsDiscount.map(item => item.id);
			const idTmp = ! id
				? Math.max(...ids)
				: id;

			this.editingGameFractionsDiscount.push({
				id: idTmp + 1,
				from,
				to,
				discount,
				discount_crm: discountCrm
			});
		},
		removeDiscountFraction(index) {
			this.editingGameFractionsDiscount.splice(index, 1);
		},
		clearDiscountFractions() {
			this.editingGameFractionsDiscount = [];
			this.fractionDiscountActiveVersion = null;
			this.fractionDiscountErrors = {};
		},
	},
}