<template>
    <form @submit.prevent="search">
        <div class="card card-search">
            <div class="card-header">
                <i class='fa fa-search' aria-hidden='false'></i> Player Search
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-4 col-lg-3">
                        <atbl-input label="Name"
                                    name="firstname"
                                    v-model="searchModel.name"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <atbl-input label="Surname"
                                    name="surname"
                                    v-model="searchModel.surname"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <atbl-form-group label="Player (ID Range)"
                                         :error="errorModel.idRange.message"
                        >
                            <div class="id-range-holder d-flex">
                                <atbl-number v-model="searchModel.playerIdFrom"
                                             :min="1"
                                             :max="false"
                                             name="playerIdFrom"
                                             placeholder="From"
                                             class="mr-1 mb-0"
                                             add-input-classes="mb-0"
                                />
                                <atbl-number v-model="searchModel.playerIdTo"
                                             :min="1"
                                             :max="false"
                                             name="playerIdTo"
                                             class="mb-0"
                                             placeholder="To"
                                             add-input-classes="mb-0"
                                />
                            </div>
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <atbl-input label="Phone"
                                    name="phone"
                                    v-model="searchModel.phone"
                        />
                    </div>
                    <div class="col-12 col-md-8 col-lg-6">
                        <div class="d-flex border rounded flex-wrap p-3 mb-2">
                            <atbl-form-group label="Date"
                                             class="mr-3 mb-0 flex-grow-1"
                            >
                                <multi-date-picker v-model="selectedDates"
                                                   :show-ranges="true"
                                                   :auto-apply="true"
                                                   :ranges="dateRanges"
                                                   format="YYYY-MM-DD"
                                                   direction="right"
                                />
                            </atbl-form-group>
                            <multi-players-date-types-picker v-model="searchModel.dateTypes" />
                            <span v-if="!!errorModel.date.message"
                                  class="form-group-atbl-error w-100"
                            >
                                {{ errorModel.date.message }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <atbl-input label="Email"
                                    name="email"
                                    v-model="searchModel.email"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <balance-select v-model="balanceSelect"
                                        :error-message="errorModel.balanceSelector"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <countries-options v-model="searchModel.country"
                                           :multiple="true"
                                           :error="{}"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <compare-number-select :value="[searchModel.liveSpent, searchModel.liveSpentSelector]"
                                               key="liveSpent"
                                               label="Live Spent"
                                               name="liveSpent"
                                               @update="onUpdateCompareNumber"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <compare-number-select :value="[searchModel.liveDeposit, searchModel.liveDepositSelector]"
                                               key="liveDeposit"
                                               label="Live Deposit"
                                               name="liveDeposit"
                                               @update="onUpdateCompareNumber"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <compare-number-select :value="[searchModel.liveRefund, searchModel.liveRefundSelector]"
                                               key="liveRefund"
                                               label="Live Refund"
                                               name="liveRefund"
                                               @update="onUpdateCompareNumber"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <compare-number-select :value="[searchModel.totalWin, searchModel.totalWinSelector]"
                                               key="totalWin"
                                               label="Total Win"
                                               name="totalWin"
                                               @update="onUpdateCompareNumber"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <compare-number-select :value="[searchModel.totalWithdrawal, searchModel.totalWithdrawalSelector]"
                                               key="totalWithdrawal"
                                               label="Total Withdrawal"
                                               name="totalWithdrawal"
                                               @update="onUpdateCompareNumber"
                        />
                    </div>
                    <div v-if="more" class="col-12 col-md-4 col-lg-3">
                        <atbl-input label="Postcode"
                                    name="postcode"
                                    v-model="searchModel.postcode"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <atbl-input label="Code"
                                    name="promocode"
                                    v-model="searchModel.promocode"
                                    :disabled="true"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <atbl-form-group label="Age"
                                         :error="errorModel.age.message"
                        >
                            <div class="age-holder d-flex">
                                <atbl-number v-model="searchModel.ageFrom"
                                             :min="1"
                                             :max="false"
                                             name="ageFrom"
                                             placeholder="Enter Age from"
                                             class="mr-1 mb-0"
                                />
                                <atbl-number v-model="searchModel.ageTo"
                                             :min="1"
                                             :max="false"
                                             name="ageTo"
                                             placeholder="Enter Age to"
                                             class="mb-0"
                                />
                            </div>
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="$can('Dispositions - Get List')">
                        <dispositions-select v-model="searchModel.disposition_id" />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <atbl-select v-model="searchModel.created_through"
                                     label="Created through"
                                     :options="$options.CreatedThroughConstants.getCreatedThroughTypes()"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <atbl-select v-model="searchModel.kyc_status"
                                     label="KYC Status"
                                     :options="$options.getKYCStatuses()"
                        />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more && $can('Users - Get List')">
                        <multi-agents-picker v-model="searchModel.agents" />
                    </div>
                    <div class="col-12 col-md-4 col-lg-3" v-if="more">
                        <tags-picker v-model="searchModel.tags" />
                    </div>
                    <div v-if="$can('Player - Search Affiliates') && more"
                           class="col-12 col-md-4 col-lg-3"
                    >
                        <affiliates-picker v-model="searchModel.affiliates"
                                           :multiple="true"
                        />
                    </div>
                </div>
                <div class="row align-items-center">
                    <div v-if="$hasAccessToAllUsersData()" class="col-12 col-lg-3 mb-2 m-lg-0">
                        <atbl-checkbox label="Show blocked players"
                                       v-model="searchModel.blocked"
                        />
                    </div>
                    <div v-if="$hasAccessToAllUsersData()" class="col-12 col-lg-3">
                        <atbl-checkbox label="Show non assigned players"
                                       v-model="searchModel.unAssigned"
                        />
                    </div>
                </div>
            </div>
            
            <div class="card-footer">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-12 col-lg d-flex justify-content-center justify-content-lg-start mb-2 mb-lg-0">
                        <button :disabled="!isSearchFormEnabled"
                                type="submit"
                                class="btn btn-sm btn-primary mr-2"
                        >
                            <i class="fa fa-search"></i> Search
                        </button>
                        <button type="reset"
                                  class="btn btn-sm btn-danger"
                                  @click.prevent="clear"
                        >
                            <i class="fa fa-ban"></i> Clear
                        </button>
                    </div>
                    <div class="col-12 col-lg d-flex justify-content-center justify-content-lg-end">
                        <button size="sm"
                                  class="btn btn-sm btn-outline-primary mr-2"
                                  @click="more = !more"
                        >
                            <span>{{ more ? 'Less' : 'More' }}</span>
                            Options
                        </button>
                        <button v-if="$can('Player - Export')"
                                :disabled="isSearchModelEmpty"
                                type="button"
                                class="btn btn-sm btn-success mr-2"
                                @click.prevent="onExportPlayersCsv(searchModel)"
                        >
                            <i class="fa fa-download"></i> Export
                        </button>
                        <button v-if="$can('Player - Create/Update')"
                                  class="btn btn-sm btn-primary"
                                  @click="toggleCreatePlayerForm"
                        >
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import moment from 'moment';
import CountriesOptions from '../../../../../components/Forms/Select/CountriesOptions';
import CreatedThroughConstants from '../../../Sections/Components/CreatedThrough/CreatedThroughConstants';
import TagsPicker from '../../../../../components/Forms/Select/TagsPicker';
import { clone } from '../../../../../utils/Json/Json';
import SearchPlayersModel from './SearchPlayersModel';
import CreatedSelect from '../../../../../components/Forms/Select/CreatedSelect';
import QueryToObject from '../../../../../utils/Url/QueryToObject';
import { debounce, pick } from 'lodash';
import AtblCheckbox from '../../../../../components/Forms/Inputs/AtblCheckbox';
import { booleanFromString } from '../../../../../utils/Boolean';
import { filterNonEmpty } from '../../../../../utils';
import Date from '../../../../../helpers/Date';
import { getKYCStatuses } from '../../Layout/KYCStatus/KYCStatusConstants.js'
import removeUndefinedProperties from '../../../../../utils/Object/RemoveUndefinedProperties';
import { isObjectEmpty } from '../../../../../utils/Object/Object';
import PlayerExport from '../../../PlayerExport';
import AffiliatesPicker from '../../../../../components/Forms/Select/AffiliatesPicker.vue';
import CompareNumberSelect from '../../../../../components/Forms/Select/CompareNumberSelect.vue';
import SearchPlayersValidation from './SearchPlayersValidation';

export default {
    name: 'search-players',
    CreatedThroughConstants,
    getKYCStatuses,
    inject: [
        'toggleCreatePlayerForm',
    ],
    mixins: [
        PlayerExport,
        SearchPlayersValidation,
    ],
    components: {
        AffiliatesPicker,
        CreatedSelect,
        TagsPicker,
        CountriesOptions,
        AtblCheckbox,
        CompareNumberSelect,
    },
    data() {
        return {
            searchModel: clone(SearchPlayersModel),
            more: false,
        }
    },
    computed: {
        dateRanges() {
            const yesterday = moment().subtract(1, 'days').toDate();
            const today = moment().toDate();
            const sevenDays = moment().subtract(7, 'days').toDate();

            return {
                'Yesterday': [yesterday, yesterday],
                'Today': [today, today],
                'Last 7 Days': [sevenDays, today],
                'This Month': [
                    moment().startOf('month').toDate(),
                    moment().endOf('month').toDate(),
                ]
            };
        },
        balanceSelect: {
            get() {
                const { balance, balanceSelector, balanceType } = this.searchModel;

                return {
                    balance,
                    balanceSelector,
                    balanceType,
                };
            },
            set(value) {
                const {
                    balance,
                    balanceSelector,
                    balanceType,
                    errorMessage,
                } = value;
                
                this.errorModel.balanceSelectorValidatorMessage = {
                    status: !!errorMessage,
                    message: errorMessage,
                };
                
                Object.assign(this.searchModel, {
                    balance,
                    balanceSelector,
                    balanceType
                });
            }
        },
        selectedDates: {
            get() {
                if (!this.searchModel.dateFrom && !this.searchModel.dateTo) {
                    return null;
                }

                return {
                    startDate: this.searchModel.dateFrom,
                    endDate: this.searchModel.dateTo
                };
            },
            set(value) {
                this.searchModel.dateFrom = !!value.startDate
                    ? moment(value.startDate).format(Date.defaultDate)
                    : null;
                this.searchModel.dateTo = !!value.endDate
                    ? moment(value.endDate).format(Date.defaultDate)
                    : null;
            }
        },
        isSearchModelEmpty() {
            return !filterNonEmpty(this.searchModel).length;
        },
        isSearchFormEnabled() {
            return !this.isSearchModelEmpty && !!this.isSearchFormValid;
        },
    },
    created() {
        this.initializeSearchForm();
        this.initializeFormMore();
    },
    methods: {
        initializeSearchForm() {
            const searchParams = QueryToObject();
            const searchModel = pick(searchParams, Object.keys(this.searchModel));
            const otherParams = pick(searchParams, ['sort', 'paginate', 'page']);

            if (isObjectEmpty(searchModel) && isObjectEmpty(otherParams)) {
                return;
            }

            if (searchModel.hasOwnProperty('blocked')) {
                searchModel.blocked = booleanFromString(searchModel.blocked);
            }

            if (searchModel.hasOwnProperty('unAssigned')) {
                searchModel.unAssigned = booleanFromString(searchModel.unAssigned);
            }

            if (searchModel.hasOwnProperty('deposits')) {
                searchModel.deposits = booleanFromString(searchModel.deposits);
            }

            Object.assign(this.searchModel, searchModel);

            const removeParams = this.detectNonAdjustableParams()

            this.search({
                searchModel,
                otherParams,
                ...(removeParams.length) && { removeParams }
            });
        },
        initializeFormMore() {
            const data = pick(this.searchModel, [
                'ageFrom',
                'ageTo',
                'disposition_id',
                'created_through',
                'agents',
                'tags',
                'created_at',
                'liveSpent',
                'liveSpentSelector',
                'liveDeposit',
                'liveDepositSelector',
                'liveRefund',
                'liveRefundSelector',
                'totalWin',
                'totalWinSelector',
                'totalWithdrawal',
                'totalWithdrawalSelector',
            ]);

            this.more = !!filterNonEmpty(data).length;
        },
        async search(data) {
            const tmpModel = data instanceof Event
                ? this.searchModel
                : data;

            const searchModel = tmpModel.hasOwnProperty('searchModel')
                ? tmpModel.searchModel
                : tmpModel;

            const otherParams = tmpModel.hasOwnProperty('otherParams')
                ? tmpModel.otherParams
                : {};

            const removeParams = tmpModel.hasOwnProperty('removeParams')
                ? tmpModel.removeParams
                : null

            if (!!removeParams) {
                removeParams.forEach((param) => {
                    this.searchModel[param] = null
                })
            }

            this.$emit('search', {
                searchModel,
                otherParams: {
                    page: 1,
                    ...otherParams,
                },
                ...(!!removeParams) && { removeParams }
            })
        },
        detectNonAdjustableParams () {
            const nonAdjustableParams = ['created_at', 'deposits'];
            let url = new URL(window.location.href)
            let params = new URLSearchParams(url.search)

            return nonAdjustableParams.filter( param => { 
                return params.has(param) 
            });
        },
        clear() {
            this.searchModel = clone(SearchPlayersModel);
            this.clearSearchErrors();
            
            this.$emit('clear');
        },
        onUpdateCompareNumber(name, value, selector, error) {
            Object.assign(this.searchModel, {
                [name]: value,
                [`${name}Selector`]: selector
            });
            
            this.updateSearchModelError(name, error);
        },
    },
    watch: {
        searchModel: {
            handler(value) {
                this.validateSearchForm(value);
            },
            deep: true,
        },
    },
}
</script>
