var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      attrs: { title: "Notification", loader: _vm.getSidebarLoading },
      model: {
        value: _vm.openSidebar,
        callback: function($$v) {
          _vm.openSidebar = $$v
        },
        expression: "openSidebar"
      }
    },
    [
      !_vm.getSidebarLoading
        ? [
            _c("h5", [_vm._v("Message")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.getNotificationModel.data.message) +
                  "\n\t\t"
              )
            ]),
            _vm._v(" "),
            _c("h5", [_vm._v("Data")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm._l(_vm.getDataInformation, function(value, key) {
              return _c("div", { key: key, staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("b", [_vm._v(_vm._s(key))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(value) + "\n\t\t\t")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c("hr", { staticClass: "my-1" })
                ])
              ])
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", {
        staticClass: "mt-2",
        attrs: { type: "small", show: _vm.getSidebarLoading }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }