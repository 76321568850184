<template>
	<div id="payonModal" ref="myModal" class="modal">
		<div class="modal-dialog ">
			<!-- Modal content -->
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="m-0">Card payment</h5>
					<span class="close" @click="$emit('close')">&times;</span>
				</div>

				<div class="modal-body">
					<div class="spiner">
						<img src="/img/spiner.svg" alt="spiner">
					</div>

					<form ref="cardform" :action="redirect" class="paymentWidgets" data-brands="VISA MASTER"></form>
					<script type="application/javascript">
						var wpwlOptions = {
							style: "plain",
							onReady: function () {
								let createRegistrationHtml = '<div class="customLabel">Save payment details?</div>' +
									'<div class="customInput">' +
									'<input type="checkbox" name="createRegistration" value="true" checked/>' +
									'</div>',
									createDiv = document.createElement("div");

								createDiv.id = "check-box-save";
								createDiv.innerHTML = createRegistrationHtml;

								let payOnModal = document.querySelector("#payonModal");

								if (payOnModal) {
									let modalContent = payOnModal.querySelector(".modal-content"),
										spinner = modalContent.querySelector(".spiner"),
										form = document.querySelector("form.wpwl-form-card"),
										wpwlButton = form.querySelector(".wpwl-group-submit");

									spinner.classList.add("d-none");

									form.insertBefore(createDiv, wpwlButton);
								}
							}
						};
					</script>
					<script type="application/javascript" :src="link"></script>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['link', 'redirect'],
		data() {
			return {
				id: "",
				show: true,
				spiner: true
			};
		}
	};
</script>

<style scoped>
/* The Modal (background) */
.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
	background-color: #fefefe;
	margin: auto;
	border: 1px solid #888;
}

/* Modal Body */
.modal-body {
	padding: 0;
}

/* The Close Button */
.close {
	color: #FFF;
	float: right;
	font-size: 28px;
	font-weight: bold;
	text-align: right;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}
</style>


<style lang="scss">
.spiner {
	text-align: center;

	img {
		width: 100px;
		height: 100px;
		margin: auto;
	}
}

.wpwl-form {
	margin: 0;
	max-width: unset;
}

.wpwl-button-pay {
	background-color: #20a8d8 !important;
	border: 1px solid #20a8d8 !important;
}

.wpwl-button-pay:hover,
.wpwl-button-pay:active {
	background-color: #1b8eb7 !important;
	border: 1px solid #1985ac !important;
}

.wpwl-control {
	height: 50px;
}

.wpwl-control {
	border: 1px solid #e6e9ec !important;
}

.customLabel {
	display: inline-block;
	width: 157px;
	padding-top: 10px;
}

.customInput {
	display: inline-block;
	width: 50px;
}

.wpwl-label-brand {
	margin-top: 10px;
}

.wpwl-group-brand {
	margin-bottom: 30px;
}

.wpwl-brand-card {
	margin-top: 15px;
}

.wpwl-group {
	margin-top: 5px;
	padding: 0 15px;

	&:last-of-type {
		margin-bottom: 0;
		background-color: #f6f6f6;
		border-top: 1px solid #e0e0e0;
	}
}

input[type="checkbox"] {
	height: 15px !important;
}

.wpwl-registration {
	width: 100%
}

.wpwl-group {
	.wpwl-wrapper-submit {
		margin-top: 0;
		display: block;
		height: auto;
		padding: 15px 0;
	}
}

.wpwl-wrapper-registration-details {
	padding-left: 15px;
}

.wpwl-group-registration.wpwl-selected {
	border-bottom: 1px solid #ccc;
	border-radius: 0;
}

.wpwl-wrapper-registration-cvv {
	display: none;
}

@media (min-width: 480px) {
	.wpwl-wrapper-registration-details {
		width: 74.333333%;
		padding-right: 0;
	}
}

.wpwl-label-brand,
.wpwl-control-brand {
	font-weight: bold;
	font-size: 16px;
}

.wpwl-group-registration.wpwl-selected {
	border: none;
	border-bottom: 1px solid #ccc;
}

.wpwl-wrapper-registration.wpwl-wrapper-registration-registrationId {
	input[type="radio"] {
		height: 20px !important;
	}
}

#check-box-save {
	padding: 0 15px;
	margin-bottom: 12px;
}
</style>
