<template>
	<atbl-form-group :label="label"
	                 :label-for="getLabelFor"
	                 :error="getError(getErrors, name)"
	>
		<input :id="getLabelFor"
		       type="number"
		       class="form-control"
		       :class="{
                   'is-invalid': hasError(getErrors, name)
               }"
		       :value="value"
		       :placeholder="getPlaceholder"
		       @input="onNumberUpdate"
		       :disabled="disabled"
		       :min="min"
		       :max="max"
		       :step="step"
		/>
	</atbl-form-group>
</template>

<script>
	import ATBLFormControls from "./ATBLFormControls";
	
	export default {
		name: "ATBLNumber",
		mixins: [
			ATBLFormControls
		],
		props: {
			type: {
				type: String,
				default: "number",
				validator: function (value) {
					return ["number"].indexOf(value) !== -1;
				}
			},
			min: {
				type: Number,
				default: 5
			},
			max: {
				type: [Number, Boolean],
				default: 2000
			},
			step: {
				type: Number,
				default: 1
			},
            showErrors: {
			    type: Boolean,
                default: true
            }
		},
		data: function () {
			return {
				localErrors: {}
			};
		},
		computed: {
			getErrors: function () {
				return !this.isObjectEmpty(this.localErrors) ? this.localErrors : this.errors;
			}
		},
		methods: {
			onNumberUpdate: function (event) {
				this.localErrors = {};
				let value = event.target.value,
					isDisabled = false;
				
				if (this.max) {
					if (Number(value) > this.max) {
						isDisabled = true;

						if(this.showErrors) {
                            this.localErrors = {
                                [this.name]: [
                                    `${this.ucFirst(this.name)} cannot be bigger then ${this.max}.`
                                ]
                            };
                        }
					}
				}
				
				this.$emit("input", value);
				this.$emit("update", this.name, value, isDisabled);
			}
		}
	}
</script>