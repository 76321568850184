<template>
    <div class="container-fluid tabs-atbl">
        <template v-if="!callHistoryLoader">
            <call-history-daily-statistics :is-loading="callHistoryLoader"
                                           :statistics="callHistoryResponse.dailyStatistics"
            />
            <call-history-range-statistics :is-loading="callHistoryLoader"
                                           :statistics="callHistoryResponse.rangeStatistics"
                                           :call-history-search-model="callHistoryFilterModel"
            />

            <div class="tabs-atbl-statistics">
                <div class="tabs-atbl-statistics-header">
                    <div class="d-flex align-items-center">
                        <a v-if="!getIsOnlyAgent && !!agentName"
                           href="/call/history/view"
                           class="btn btn-sm btn-primary mr-3"
                        >
                            <i class="fa fa-chevron-left" aria-hidden="false"></i>
                        </a>
                        <i class="fa fa-phone mr-1" aria-hidden="false"></i>
                        <span v-if="!!isAgentSelected">
                            <div v-if="!userLoader">
                                <span v-if="!!agentName">
                                    {{ agentName }}
                                </span>
                                <span v-else>{{ userModel.name }}</span>
                            </div>
                        <loader :show="userLoader"
                                type="smaller"
                        />
                    </span>
                        <span v-else>
                        Agents Call History
                    </span>
                    </div>
                    <div>
                        <button v-if="$can('Call History - Export')"
                                class="btn btn-sm btn-success m-0"
                                @click="onExportCallHistory"
                        >
                            <span v-if="!isExportLoading">
                                <i class="fa fa fa-download" aria-hidden="false"></i>
                            </span>
                            <loader :show="isExportLoading"
                                    type="smaller"
                            />
                        </button>
                        <button v-if="$can('Call History - View')"
                                class="btn btn-sm btn-primary m-0"
                                @click="isCallHistoryFilterFormOpen = !isCallHistoryFilterFormOpen"
                        >
                            <i class="fa fa-filter" aria-hidden="false"></i>
                        </button>
                        <button v-if="$can('Call History - View')"
                                class="btn btn-sm btn-warning m-0"
                                @click="fetchCallHistoryStatistics(callHistoryFilterModel, agentid)"
                        >
                            <i class="fa fa-undo" aria-hidden="false"></i>
                        </button>
                    </div>
                </div>
                <div class="tabs-atbl-statistics-body">
                    <atbl-tabs v-model="selectedTab"
                            v-if="!isAgentSelected"
                    >
                        <atbl-tab title="Agents"
                               active
                        >
                            <call-history-agents-list :is-loading="callHistoryLoader"
                                                      :agents="callHistoryResponse.agentsStatistics"
                                                      :call-history-search-model="callHistoryFilterModel"
                            />
                        </atbl-tab>
                        <atbl-tab title="Calls">
                            <call-history-list :is-loading="callHistoryGeneralLoader"
                                               :statistics="callHistoryResponse.callHistoryStatistics"
                                               :call-history-search-model="callHistoryFilterModel"
                            />
                        </atbl-tab>
                        <atbl-tab title="Daily Total">
                            <call-history-daily-total :is-loading="callHistoryLoader"
                                                      :calls="callHistoryResponse.dailyTotalStatistics"
                                                      :call-history-search-model="callHistoryFilterModel"
                            />
                        </atbl-tab>
                    </atbl-tabs>
                    <call-history-list v-else
                                       :is-loading="callHistoryGeneralLoader"
                                       :statistics="callHistoryResponse.callHistoryStatistics"
                                       :call-history-search-model="callHistoryFilterModel"
                    />
                </div>
            </div>
        </template>
        <loader :show="callHistoryLoader"
                type="small"
        />

        <call-history-filter-form-component :show="isCallHistoryFilterFormOpen"
                                            :call-history-statuses="callHistoryStatuses"
                                            :call-history-statuses-loader="callHistoryStatusesLoader"
                                            :call-history-filter-model="callHistoryFilterModel"
                                            :call-history-filter-loader="false"
        />
        <call-history-filter-recordings-component :show="callHistoryRecordingsSidebarOpen"
                                                  :is-loading="callHistoryRecordingsLoader"
                                                  :recordings="callHistoryRecordingsList"
        />
    </div>
</template>

<script>
import CallHistoryList from './CallHistoryList/CallHistoryList';
import CallHistoryFilterRecordingsComponent
    from './_Components/CallHistoryFilterRecordings/CallHistoryFilterRecordingsComponent';
import CallHistoryDailyStatistics from './CallHistoryDailyStatistic/CallHistoryDailyStatistics';
import CallHistoryRangeStatistics from './CallHistoryRangeStatistic/CallHistoryRangeStatistics';
import CallHistoryAgentsList from './CallHistoryAgentsList/CallHistoryAgentsList';
import CallHistoryDailyTotal from './CallHistoryDailyTotal/CallHistoryDailyTotal';
import CallHistoryAgent from './CallHistoryAgent';
import ExportCallHistory from './ExportCallHistory';
import CallHistoryFilterFormComponent from './CallHistoryFilterForm/CallHistoryFilterFormComponent';
import CallHistoryFilterForm from './CallHistoryFilterForm/CallHistoryFilterForm';
import CallHistoryFilterRecordings from './_Components/CallHistoryFilterRecordings/CallHistoryFilterRecordings';
import MarketingService from '../../services/MarketingService';
import CallHistoryStatuses from './CallHistoryStatuses';
import CallHistory from './CallHistory';
import AtblTabs from '../../components/AtblTabs/AtblTabs.vue';
import AtblTab from '../../components/AtblTabs/AtblTab.vue';

export default {
    name: 'call-history-page',
    mixins: [
        CallHistory,
        CallHistoryAgent,
        ExportCallHistory,
        CallHistoryFilterForm,
        CallHistoryFilterRecordings,
        CallHistoryStatuses,
    ],
    components: {
        CallHistoryList,
        CallHistoryFilterFormComponent,
        CallHistoryFilterRecordingsComponent,
        CallHistoryDailyStatistics,
        CallHistoryRangeStatistics,
        CallHistoryAgentsList,
        CallHistoryDailyTotal,
        AtblTabs,
        AtblTab,
    },
    props: {
        agentid: {
            type: Number,
            default: null
        }
    },
    provide() {
        return {
            agentId: this.agentid,
        };
    },
    data() {
        return {
            selectedTab: 0,
            agentName: null,
        };
    },
    computed: {
        isAgentSelected() {
            return !!this.getIsOnlyAgent || !!this.agentid;
        },
    },
    async created() {
        await this.fetchCallHistoryStatistics(
            this.callHistoryFilterModel,
            this.agentid
        );
        await this.fetchCallHistoryStatuses();

        if (!this.agentid) {
            return;
        }

        this.agentName = this.getParam('name');

        await this.getCurrentAgent();
    },
}
</script>