<template>
    <div class="keypad"
         @keydown="dialNumber"
         tabindex="0"
    >
        <div class="keypad-number">
            <div class="keypad-input-holder">
                <input v-if="!callStarted"
                       :value="number"
                       type="tel"
                       class="keypad-input"
                       disabled
                />
                <input v-else
                       v-model="numberDigits"
                       type="tel"
                       class="keypad-input-digits"
                       placeholder="Dial digits"
                       disabled
                />
            </div>
            <a href="#"
               class="clear-number"
               @click.prevent="resetNumber"
               v-tooltip="{
                   content: 'Reset phone number.'
               }"
            >
                RESET
            </a>
            <a href="#"
               class="clear-number"
               @click.prevent="clearNumber"
               v-tooltip="{
                   content: 'Press ESC to clear'
               }"
            >
                CLEAR
            </a>
            <button v-if="!callStarted"
                    type="button"
                    class="backspace"
                    @mousedown="backspace"
            >
                <img src="/img/icons/backspace.svg" alt="backspace-icon" />
            </button>
        </div>
        <div class="keypad-wrapper"
             :class="wrapperSize"
        >
            <div v-for="(numbers, index) in dialPadPattern"
                 :key="index"
                 class="keypad-wrapper-numbers"
            >
                <button type="button"
                        v-for="(number, numberIndex) in numbers"
                        :key="numberIndex"
                        class="keypad-button"
                        @click="dialClick(number)"
                >
                    <span>
                        {{ number }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dial-keypad',
    props: {
        input: {},
        value: {},
        phone: {
            type: Number,
            required: true
        },
        callStarted: {
            type: Boolean,
            required: true
        },
        wrapperSize: {
            type: String,
            default: 'normal'
        }
    },
    data() {
        return {
            numberDigits: '',
            dialPadPattern: [
                [
                    1, 2, 3
                ],
                [
                    4, 5, 6
                ],
                [
                    7, 8, 9
                ],
                [
                    '*', 0, '#'
                ]
            ]
        };
    },
    computed: {
        number: {
            get() {
                return this.value.toString();
            },
            set(value) {
                this.$emit('update', value);
            }
        }
    },
    methods: {
        dialClick(num) {
            if (this.callStarted) {
                this.numberDigits = `${this.numberDigits}${num}`;
            } else {
                this.number = `${this.number}${num}`;
            }
        },
        backspace() {
            if (this.callStarted) {
                this.numberDigits = this.numberDigits.slice(0, -1);
            } else {
                this.number = this.number.slice(0, -1);
            }
        },
        dialNumber(event) {
            if (!isNaN(event.key)) {
                this.dialClick(event.key);
            } else {
                if (event.keyCode === 8) {
                    this.backspace();
                }
                if (event.keyCode === 27) {
                    this.clearNumber();
                }
            }
        },
        clearNumber() {
            this.number = '';
        },
        resetNumber() {
            this.number = this.phone;
        },
    },
    watch: {
        numberDigits(value) {
            this.$emit('digit', value);
        },
        callStarted(value) {
            if (!value) {
                this.numberDigits = '';
            }
        }
    },
}
</script>