<template>
	<c-header fixed with-subheader light>
		<c-toggler in-header
		           class="ml-1 d-lg-none"
		           @click="setShowSidebarMobile"
		/>
		<c-toggler in-header
		           class="ml-1 d-md-down-none"
		           @click="setShowSidebarDesktop"
		/>
		<c-header-nav class="mr-auto">
			<c-header-nav-item class="px-3">
				<c-header-nav-link href="/">
                    <span class="d-md-down-none">Dashboard</span>
                    <i class="fa fa-tachometer-alt font-xl d-lg-none"></i>
				</c-header-nav-link>
			</c-header-nav-item>
		</c-header-nav>
		<c-header-nav class="mr-2">
			<notifications v-if="$can('Users - Get Notifications')" />
			<HeaderAccount :name="name" :token="token" />
		</c-header-nav>
	</c-header>
</template>

<script>
	import TypesConst from "../../../store/TypesConst";
	import NamespaceConst from "../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";

	const {
		mapGetters: mapGeneralGetters,
		mapMutations: mapGeneralMutations
	} = createNamespacedHelpers(NamespaceConst.general);

	import HeaderAccount from './HeaderAccount'
	import Notifications from "./Components/Notifications";

	export default {
		name: 'main-header',
		components: {
			Notifications,
			HeaderAccount
		},
		props: {
			name: {
				type: String,
				required: true
			},
			token: {
				type: String,
				required: true
			}
		},
		computed: {
			...mapGeneralGetters({
				getShowSidebar: TypesConst.general.getters.GET_SHOW_SIDEBAR
			})
		},
		methods: {
			...mapGeneralMutations({
				setShowSidebarDesktop: TypesConst.general.mutations.SET_SHOW_SIDEBAR_DESKTOP,
				setShowSidebarMobile: TypesConst.general.mutations.SET_SHOW_SIDEBAR_MOBILE
			})
		}
	}
</script>