import Cart from '../../../../../modules/cart/Cart';

export default {
    methods: {
        placeOrder (price) {
            const { reference_id: cart_id } = this.getCartData,
                player_id = this.getPlayerId;

            this.setIsPlacingOrder(true);
            window.axios.post(`/cart/place-order`, {
                cart_id,
                player_id
            })
                .then(response => {
                    const { message, status, data: { order_id } } = response.data;

                    if (status && order_id) {
                        Cart.clear(player_id);
                        this.setOrderId(order_id);
                        this.checkoutAction(price);
                    }

                    window.showMessage(message, status);
                })
                .catch(error => {
                    const { message, response: { status } } = error;

                    if (status === 422) {
                        this.clearCartData();
                        this.setOrderId(null);
                        this.closeCheckout();
                        Cart.clear(player_id);
                    }

                    window.showMessage(message, false);
                })
                .finally(() => {
                    this.setIsPlacingOrder(false);
                });
        },
    }
};