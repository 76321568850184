var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      !_vm.isLoading && !_vm.canShowConfiguration
        ? _c(
            "div",
            { staticClass: "card-header card-header-atbl" },
            [
              !_vm.canShowConfiguration
                ? _c("GameHeader", { attrs: { game: _vm.game } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !!_vm.nextDrawOpen
        ? _c(
            "div",
            { staticClass: "card-header card-header-atbl" },
            [
              _c("current-draw-header-component", {
                attrs: { draw: _vm.nextDraw }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          !_vm.isLoading && !_vm.isObjectEmpty(_vm.configuration)
            ? _c(
                "div",
                [
                  _vm.game.game.key.indexOf("loteria-nacional") === -1
                    ? _vm._l(_vm.configuration, function(item, index) {
                        return _c("Configuration", {
                          key: index,
                          attrs: {
                            configuration: _vm.configuration,
                            title:
                              index.charAt(0).toUpperCase() + index.slice(1),
                            type: index,
                            configurationTmp: _vm.configurationTmp
                          },
                          model: {
                            value: _vm.configurationTmp[index],
                            callback: function($$v) {
                              _vm.$set(_vm.configurationTmp, index, $$v)
                            },
                            expression: "configurationTmp[index]"
                          }
                        })
                      })
                    : _c("raffle-editor", {
                        attrs: {
                          configuration: _vm.configuration,
                          "old-game-draw": _vm.oldGameDraw
                        },
                        on: {
                          "update-raffle": _vm.updateRaffle,
                          "update-configuration":
                            _vm.onUpdateConfigurationEvent,
                          "remove-configuration":
                            _vm.onRemoveConfigurationEvent,
                          "update-winner-prize-configuration":
                            _vm.onUpdateWinnerPrizeConfigurationEvent,
                          "remove-field-configuration": _vm.onDeleteFieldEvent
                        }
                      })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !!_vm.nextDrawOpen
            ? _c("current-draw-editor-component", {
                attrs: {
                  loading: _vm.nextDrawLoader,
                  draw: _vm.nextDraw,
                  errors: _vm.nextDrawErrors
                },
                on: {
                  input: function($event) {
                    _vm.nextDrawModel = $event
                  }
                },
                model: {
                  value: _vm.nextDrawModel,
                  callback: function($$v) {
                    _vm.nextDrawModel = $$v
                  },
                  expression: "nextDrawModel"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.nextDrawOpen && !_vm.isLoading && _vm.canShowConfiguration
            ? _c("not-found", {
                attrs: { items: [], msg: "Please select game draw" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          _vm.isLoading
            ? _c("not-found", { attrs: { items: [], msg: _vm.loadingMessage } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isLoading &&
      !_vm.isObjectEmpty(_vm.configuration) &&
      !_vm.isRaffleGame
        ? _c(
            "div",
            { staticClass: "card-footer card-footer-atbl text-right" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger mr-2",
                  on: { click: _vm.cancelEdit }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _vm.$can("Game Draws - Save Configuration") &&
              _vm.isConfigurationValid
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: { click: _vm.saveEdit }
                    },
                    [_vm._v("\n            Save\n        ")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading &&
      !_vm.isObjectEmpty(_vm.configuration) &&
      _vm.isRaffleGame
        ? _c(
            "div",
            { staticClass: "card-footer card-footer-atbl text-right" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger mr-2",
                  on: {
                    click: function($event) {
                      return _vm.$options.Atbl.$emit(
                        "editConfigurationOpen",
                        true
                      )
                    }
                  }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _vm.$can("Game Draws - Save Configuration") &&
              !_vm.isObjectEmpty(_vm.raffleConfigurationValue) &&
              _vm.isRaffleConfigurationValid
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: { click: _vm.saveRaffle }
                    },
                    [_vm._v("\n            Save\n        ")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !!_vm.nextDrawOpen
        ? _c(
            "div",
            { staticClass: "card-footer card-footer-atbl text-right" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger mr-2",
                  attrs: { disabled: !!_vm.nextDrawLoader },
                  on: {
                    click: function($event) {
                      _vm.nextDrawOpen = false
                      _vm.nextDraw = null
                    }
                  }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _vm.$can("Game Draws - Save Configuration")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { disabled: !!_vm.nextDrawLoader },
                      on: { click: _vm.saveNextDraw }
                    },
                    [
                      !_vm.nextDrawLoader
                        ? _c("span", [_vm._v("Save")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: { show: _vm.nextDrawLoader, type: "smaller" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("CancelConfigurationEditModal", {
        attrs: { show: _vm.showCancelEdit }
      }),
      _vm._v(" "),
      _c("ConfirmConfigurationEditModal", {
        attrs: { show: _vm.showConfirmEdit }
      }),
      _vm._v(" "),
      _c("ConfirmRaffleEdit", {
        attrs: {
          show: _vm.showConfirmRaffleEdit,
          game: _vm.game,
          "configuration-temp": _vm.raffleConfigurationValue,
          loading: _vm.raffleEditLoader
        },
        on: {
          show: function(value) {
            return (_vm.showConfirmRaffleEdit = value)
          },
          saveRaffle: _vm.saveRaffleConfiguration,
          close: _vm.cancelRaffle
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }