<template>
  <c-dropdown inNav
              class="c-header-nav-items"
              placement="bottom-end"
              add-menu-classes="py-0"
              v-tooltip="{
                 content: 'Notifications',
                 placement: 'bottom-center'
              }"
  >
    <template #toggler>
      <c-header-nav-link v-if="!getNotificationsDataLoader"
      >
        <i class="fa fa-bell font-1x2"></i>
        <span class="badge badge-danger badge-pill"
              v-if="unreadNotifications > 0"
        >
          {{ unreadNotifications }}
        </span>
      </c-header-nav-link>
      <loader :show="getNotificationsDataLoader"
              type="smaller"
      />
    </template>

    <c-dropdown-header class="text-center bg-primary text-white">
      Notifications
    </c-dropdown-header>

    <c-dropdown-item v-if="items.length > 0"
                     v-for="(item, index) in items"
                     :key="index"
                     :href="`/notifications?id=${item.id}`"
                     :class="{
		                   'bg-light': !item.read_at
                     }"
    >
      {{ item.data.message ? item.data.message : "Message not found!" }}
    </c-dropdown-item>
	  
    <c-dropdown-item v-if="!items.length">
      No notifications found
    </c-dropdown-item>

    <c-dropdown-header class="p-0 bg-light">
      <c-dropdown-item href="/notifications"
                       class="justify-content-center"
      >
        View all notifications
      </c-dropdown-item>
    </c-dropdown-header>
  </c-dropdown>
</template>

<script>
  import TypesConst from "../../../../store/TypesConst";
  import NamespaceConst from "../../../../store/NamespaceConst";
  import {createNamespacedHelpers} from "vuex";

  const {
    mapGetters: mapNotificationsGetters,
    mapActions: mapNotificationsActions
  } = createNamespacedHelpers(NamespaceConst.notifications);

  export default {
    name: "notifications",
    computed: {
      ...mapNotificationsGetters({
        getNotificationsData: TypesConst.notifications.getters.GET_NOTIFICATIONS_WIDGET_DATA,
        getNotificationsDataLoader: TypesConst.notifications.getters.GET_NOTIFICATIONS_WIDGET_DATA_LOADER,
      }),
      items: function(){
        return this.getNotificationsData.notifications;
      },
      unreadNotifications: function(){
        return this.getNotificationsData.unread_notifications;
      }
    },
    methods: {
      ...mapNotificationsActions({
        getNotificationsDataAction: TypesConst.notifications.actions.NOTIFICATIONS_WIDGET_DATA_ACTION
      })
    },
    created: function(){
      this.getNotificationsDataAction();
    }
  }
</script>