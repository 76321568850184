export default {
    name: null,
    surname: null,
    bet: null,
    ticket: null,
    transaction: null,
    games: [],
    dateFrom: null,
    dateTo: null,
    createdAtFrom: null,
    createdAtTo: null,
    winnings: null,
    winningsSelector: null,
    agents: [],
}