<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span><i class='fa fa-align-justify'></i> Notes</span>
                <button v-if="$can('Notes - Create/Update')"
                        class="btn btn-sm btn-primary float-right mb-2"
                        @click="toggleNoteModal"
                >
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg">Id</div>
                        <div class="col-12 col-lg">Type</div>
                        <div v-if="$canAny(notesOperationPermissions)"
                             class="col-12 col-lg"
                        >
                            Operations
                        </div>
                    </div>

                    <div v-if="!isLoading"
                         v-for="(item, index) in notes"
                         :key="index"
                         class="row"
                    >
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Id</span>
                            {{ item.id }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Type</span>
                            {{ item.type }}
                        </div>
                        <div v-if="$canAny(notesOperationPermissions)"
                             class="col-12 col-lg"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <div class="d-flex align-items-center">
                                <a v-if="$can('Notes - Create/Update')"
                                   href="#"
                                   class="mr-2"
                                   @click.prevent="onEditNoteEvent(item.id)"
                                >
                                    <i class="fa fa-pen"></i>
                                </a>
                                <remove-list-item-button v-if="$can('Notes - Remove')"
                                                         :value="item.id"
                                                         @confirmed="onDeleteNoteEvent"
                                />
                            </div>
                        </div>
                    </div>

                    <items-not-found v-if="!isLoading"
                                     :items="notes"
                                     msg="No notes found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>
        </div>

        <create-update-note-component :show="isCreateNotModalOpen"
                                      :model="createNoteModel"
                                      :errors="createNoteErrors"
                                      :is-loading="isCreateNotModalLoading"
        />
    </div>
</template>

<script>
import RemoveListItemButton from '../../components/Lists/RemoveListItemButton.vue';
import Loader from '../../components/Widgets/Loader.vue';
import ItemsNotFound from '../../components/Lists/ItemsNotFound.vue';
import CreateUpdateNote from './CreateUpdateNote/CreateUpdateNote';
import CreateUpdateNoteComponent from './CreateUpdateNote/CreateUpdateNoteComponent.vue';

export default {
    name: 'notes-page',
    mixins: [
        CreateUpdateNote,
    ],
    components: {
        RemoveListItemButton,
        Loader,
        ItemsNotFound,
        CreateUpdateNoteComponent,
    },
    data() {
        return {
            isLoading: false,
            notes: [],
            notesOperationPermissions: [
                'Notes - Create/Update',
                'Notes - Remove'
            ],
        }
    },
    async created() {
        await this.fetchNotes();
    },
    methods: {
        async fetchNotes() {
            this.isLoading = true;

            try {
                const response = await window.axios.get('/note');

                this.notes = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        async onDeleteNoteEvent(id) {
            this.isLoading = true;

            try {
                const response = await window.axios.delete('/note/' + id);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.isLoading = false;

                    return;
                }

                await this.fetchNotes();

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>
