<template>
	<div class="general-list">
		<div class="row">
			<div class="col-6 col-sm-3 text-muted">Date</div>
			<div class="col-6 col-sm-3 text-muted">Time</div>
		</div>

		<div v-if="!isLoading" v-for="(item, index) in logins" :key="index" class="row">
			<div class="col-6 col-sm-3 mb-2 mb-lg-0">
				<span class="small-mb-badge">Date</span>
				{{ $options.moment(item.created_at).format("YYYY-MM-DD") }}
			</div>
			<div class="col-6 col-sm-3 mb-2 mb-lg-0">
				<span class="small-mb-badge">Time</span>
				{{ $options.moment(item.created_at).format("HH:mm:ss") }}
			</div>
		</div>

		<not-found v-if="!isLoading"
		           :items="logins"
		           msg="No login history found."
		/>

		<loader :show="isLoading"
		        type="small"
		/>

		<nav class="wrap py-3" v-if="!isLoading && loginsData.last_page > 1">
			<atbl-pagination :pagination="loginsData"
			                 @paginate="getPlayerLoginHistory"
			/>
		</nav>
	</div>
</template>

<script>
	import TypesConst from "../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../store/NamespaceConst";
	import { createNamespacedHelpers } from "vuex";

	const {
		mapGetters: mapPlayersGetters
	} = createNamespacedHelpers(NamespaceConst.players);

	import moment from "moment";

	export default {
		name: "player-login-history-list",
		moment,
		data: function(){
			return {
				isLoading: false,
				logins: [],
				loginsData: {}
			};
		},
		computed: {
			...mapPlayersGetters({
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
			})
		},
		methods: {
			getPlayerLoginHistory: function(page = 1){
				let _vm = this;

				_vm.isLoading = true;
				window.axios.get(
					`/player/${this.getPlayerId}/login-history`,
					{
						params: {
							page
						}
					}
				)
					.then(response => {
						_vm.logins = response.data.data;
						_vm.loginsData = response.data;
					})
					.catch(error => flashError(error))
					.finally(() => {
						_vm.isLoading = false;
					});
			}
		},
		created: function () {
			this.getPlayerLoginHistory();
		}
	};
</script>