<template>
    <div class="free-games">
        <div v-if="!loading"
             class="free-games-content"
        >
            <component v-for="(item, index) in games"
                       :key="index"
                       :is="$options.ResolveFreeGameType(item.gameable_type, item.gameable.key ? item.gameable.key : item.gameable.slug).toLowerCase()"
                       :item="item"
                       :order-id="orderId"
            />
        </div>
        <loader :show="loading"
                type="small"
        />
        <not-found :items="games"
                   msg="There are no free games created."
        />
    </div>
</template>

<script>
import components from './GameItemTypes/index';
import ResolveFreeGameType from '../../../FreeGames/Utils/ResolveFreeGameType';

export default {
    name: 'FreeGames',
    ResolveFreeGameType,
    components,
    props: {
        games: {
            type: Array,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false
        },
        orderId: {
            default: null
        }
    }
}
</script>