<template>
	<div v-if="$can('Player - View Tags')"
	     class="player-tags"
	>
		<div class="atbl-placeholder font-weight-bold mb-1">Tags</div>
		<div class="player-tags-container" v-if="!isLoading">
            <span v-for="(item, index) in tags"
                  :key="index"
                  class="player-tag"
                  :class="{
                  	inactive: ! item.is_manual_assignable
                  }"
            >
                {{ item.name }}
	            
	            <a href="#"
	               v-if="item.is_manual_assignable"
	               @click="removeTag(item)"
	            >
		            <i class="fa fa-times"></i>
	            </a>
            </span>
			<CDropdown v-if="$can('Player - Update Tags') && dropDownTags.length"
			           toggler-text="Add"
			           color="link"
			>
				<CDropdownItem v-for="(tag, tagIndex) in dropDownTags"
				               :key="tagIndex"
				               @click="addTag(tag)"
				               :class="{
				                    disabled: ! tag.is_manual_assignable
				               }"
				>
					{{ tag.name }}
				</CDropdownItem>
			</CDropdown>
		</div>
		<loader :show="isLoading"
		        type="smaller"
		/>
	</div>
</template>

<script>
	import TagService from "../../../../../services/TagService";
	import PlayerTagsService from "../../../../../services/PlayerTagsService";
	
	export default {
		name: "PlayerTags",
		props: {
			player: {
				type: Object,
				required: true
			}
		},
		data: function () {
			return {
				tagList: [],
				tags: this.player.tags,
				isLoading: false
			};
		},
		computed: {
			dropDownTags: function () {
				const playerTags = this.tags.map(item => item.id);
				return this.tagList.filter(item => !playerTags.includes(item.id));
			}
		},
		methods: {
			fetchTags: function () {
				const _vm = this;
				
				_vm.isLoading = true;
				TagService.fetch()
					.then(response => {
						const tags = response.data;
						
						this.tagList = tags.map(t => {
							t.$isDisabled = ! t.is_manual_assignable;
							
							return t;
						}).sort((a, b) => a.is_manual_assignable - b.is_manual_assignable);
					})
					.finally(() => {
						_vm.isLoading = false;
					});
			},
			updateTags: function (playerId, tags) {
				let _vm = this;
				
				return new Promise((resolve, reject) => {
					_vm.isLoading = true;
					PlayerTagsService.updatePlayerTags(
						playerId,
						tags.map(item => item.id)
						)
						.then(response => {
							const { message, status } = response.data;
							resolve(response.data);
							window.showMessage(message, status);
						})
						.catch(error => {
							window.flashError(error);
							reject(error);
						})
						.finally(() => {
							_vm.isLoading = false;
						});
				});
			},
			addTag: function (tag) {
				const tags = [
					...this.tags,
					tag
				];
				
				this.updateTags(this.player.id, tags)
					.then(() => {
						this.tags = tags.sort((a, b) => a.is_manual_assignable - b.is_manual_assignable);
					});
			},
			removeTag: function (tag) {
				const tags = this.tags.filter(item => item.id !== tag.id);
				
				this.updateTags(this.player.id, tags)
					.then(() => {
						this.tags = tags;
					});
			}
		},
		created: function () {
			this.fetchTags();
		}
	}
</script>