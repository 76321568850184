<template>
	<atbl-modal :show="getWalletConfirmModal"
	         :close-on-backdrop="false"
	         :fade="true"
	         :class="{
	         	'hide-footer': redirecting
	         }"
			 @close="getWalletConfirmModal=false"
	>
		<template #header>
			<h5 class="m-0">Wallet Transaction Confirmation</h5>
		</template>
		
		<div v-if="!redirecting">
			<p v-if="!isPaymentProcessing">
				Player account will be charged with {{ getAmountToPay | formatCurrency }}. Do you want to proceed?
			</p>
			<div v-else
			     class="alert text-center m-0"
			     :class="`alert-${status}`"
			>
				{{ message }}
			</div>
		</div>
		
		<div v-if="redirecting"
		     class="alert text-center m-0"
		     :class="`alert-success`"
		>
			Redirecting...
		</div>
		
		<template #footer>
			<button class="btn btn-sm btn-light"
			          @click="closeWalletPayment"
			          v-if="!isPaymentProcessing && !redirecting"
			>
				Close
			</button>
			<button :class="{
						'btn-success': !isPaymentProcessing,
						'btn-outline-success': isPaymentProcessing
			          }"
					  class="btn"
			          @click="proceedPayment"
			          v-if="!redirecting"
			>
				<span v-if="!isPaymentProcessing">Confirm</span>
				<loader :show="isPaymentProcessing"
				        type="smaller"
				/>
			</button>
		</template>
	</atbl-modal>
</template>

<script>
	import TypesConst from "../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";

	import Payment from "../../../../../../modules/payment/Payment";
	import Cart from "../../../../../../modules/cart/Cart";
	import CheckoutAction from "../../Mixins/CheckoutAction";
	import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';

	const {
		mapGetters: mapCartGetters,
		mapMutations: mapCartMutations,
		mapActions: mapCartActions
	} = createNamespacedHelpers(NamespaceConst.cart);

	const {
		mapGetters: mapPlayersGetters
	} = createNamespacedHelpers(NamespaceConst.players);

	const {
		mapActions: mapTicketsActions
	} = createNamespacedHelpers(NamespaceConst.tickets);

	const {
		mapGetters: mapOrderGetters
	} = createNamespacedHelpers(NamespaceConst.order);

	export default {
		name: "wallet-confirm-action",
		mixins: [
			CheckoutAction
		],
		components: {
			AtblModal,
		},
		data: function(){
			return {
				isPaymentProcessing: false,
				message: null,
				status: null,
				redirecting: false
			};
		},
		computed: {
			...mapCartGetters({
				getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
				getWalletConfirmModal: TypesConst.cart.getters.GET_WALLET_CONFIRM_MODAL,
				getCartTotal: TypesConst.cart.getters.GET_CART_TOTAL
			}),
			...mapPlayersGetters({
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
			}),
            ...mapOrderGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            }),
		},
		methods: {
			...mapCartMutations({
				setWalletConfirmModal: TypesConst.cart.mutations.SET_WALLET_CONFIRM_MODAL,
				setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
				clearCart: TypesConst.cart.mutations.SET_CLEAR_CART_DATA
			}),
			...mapCartActions({
				resetCartAction: TypesConst.cart.actions.RESET_CART_ACTION
			}),
            proceedPayment() {
			    if(this.isPaymentProcessing) {
			        return;
                }

                if (!this.orderId) {
                    this.setWalletConfirmModal(false);
                    flash('Cart not found. Create new one.', 'alert-danger');
                    return;
                }

                const params = {
                    order_id: this.orderId,
                    'wallet-purchase': true,
                };

                this.isPaymentProcessing = true;
                this.message = 'Payment processing...';
                this.status = 'warning';

                window.axios.post('/internal-purchase', params).then(response => {
                    const responseData = response.data;
                    const isPaid = !!responseData.paid;

                    if(!isPaid) {
                        this.message = responseData.error || 'Something went wrong. Please try again';
                        this.setWalletConfirmModal(false);
                        flash(this.message, 'alert-danger');
                    }
                }).catch(error => {
                    this.message = 'Payment failed. Please try again.';
                    this.status = 'danger';
                }).finally(() => {
                    this.redirecting = true;
                    this.isPaymentProcessing = false;

                    setTimeout( () => {
                        window.location.href = this.buildUrl(`/players/${this.getPlayerId}/orders`);
                        this.setWalletConfirmModal(false);
                    }, 1000);
                });
            },
			closeWalletPayment: function(){
				this.setWalletConfirmModal(false);
				Atbl.$emit("lotteryPaymentCanceled");
			}
		}
	}
</script>
