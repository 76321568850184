<template>
    <div :class="{
            'py-3': !!isLoading,
            'justify-content-center': !isLoading && !filterList.length
         }"
         class="d-flex align-items-center"
    >
        <p v-if="!this.campaignId"
           class="text-danger font-weight-bold m-0"
        >
            No campaign id provided
        </p>
        <div v-if="!isLoading" class="filter-links-holder">
            <filter-list-link v-for="(item, index) in filterList"
                              :key="index"
                              :filter="item"
                              @click="onFilterClickEvent"
            />
        </div>
        <not-found v-if="!isLoading"
                   :items="filterList"
                   msg="No filters found."
        />
        <loader :show="isLoading"
                type="smaller"
        />
    </div>
</template>

<script>
import FilterListLink from './FilterListLink';
import { clone } from '../../../../../utils/Json/Json';
import ItemsNotFound from '../../../../../components/Lists/ItemsNotFound';

export default {
    name: 'campaigns-lead-filter-list-component',
    inject: [
        'defaultFilterName',
        'fetchCampaignFilters',
    ],
    components: {
        ItemsNotFound,
        FilterListLink
    },
    emits: [
        'change',
    ],
    props: {
        campaignId: {
            type: Number,
            default: null,
        },
        campaignType: {
            type: String,
            default: null,
        },
        filtersToSearch: {
            type: Array,
            default: () => ([])
        },
        filterList: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        },
    },
    async created() {
        if (!this.campaignId) {
            return;
        }

        await this.fetchCampaignFilters(this.campaignId, this.campaignType);
    },
    methods: {
        onFilterClickEvent(filter) {
            const list = clone(this.filterList);

            const tmpFilter = list.find(item => item.title === filter.title);

            tmpFilter.isActive = !tmpFilter.isActive;

            const hasActiveFilters = list.filter(item => !!item.isActive);
            const defaultFilterItem = list.find(item => item.title === this.defaultFilterName);

            if (!!defaultFilterItem && !hasActiveFilters.length) {
                defaultFilterItem.isActive = true;
            }

            this.$emit('change', list, this.extractActiveFilters(list));
        },
        extractActiveFilters(filters) {
            return filters.filter(item => !!item.isActive).map(item => item.title);
        },
    },
}
</script>