<template>
    <div class="atbl-date-range-picker">
        <date-range-picker v-model="selectedDate"
                           :opens="direction"
                           :singleDatePicker="false"
                           :ranges="rangesTemplate"
                           :locale-data="localeDataOptions"
                           :showDropdowns="showDropdowns"
                           :auto-apply="autoApply"
        >
            <div slot="input" slot-scope="picker">
                <span v-if="!!hasValue">{{ selectedValue }}</span>
                <span v-else>Select date</span>
            </div>
        </date-range-picker>
        <a v-if='!!clearable && !!hasValue'
           class="atbl-date-range-picker-close"
           href="#"
           @click.prevent="onClearValueEvent"
        >
            <i class="fa fa-times-circle"></i>
        </a>
    </div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import Date from '../../../helpers/Date';

const localeData = {
    direction: 'center',
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    weekLabel: 'W',
    customRangeLabel: 'Custom Range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1
};

const defaultDateRanges = {
    'Yesterday': Array(2).fill(moment().subtract(1, 'days').toDate()),
    'Today': Array(2).fill(moment().toDate()),
    'Last 7 Days': [
        moment().subtract(7, 'days').toDate(),
        moment().toDate()
    ],
    'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
    ],
    'Previous Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
    ]
};

export default {
    name: 'multi-date-picker',
    components: {
        DateRangePicker
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                startDate: null,
                endDate: null,
            })
        },
        direction: {
            type: String,
            default: 'center'
        },
        format: {
            type: String,
            default: Date.defaultDate
        },
        clearable: {
            type: Boolean,
            default: true
        },
        showRanges: {
            type: Boolean,
            default: false
        },
        ranges: {
            type: [Object, Boolean],
            required: false,
        },
        resetDefaultRanges: {
            type: Boolean,
            default: false
        },
        autoApply: {
            type: Boolean,
            default: false
        },
        showDropdowns: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        localeDataOptions() {
            return {
                ...localeData,
                format: this.format,
            };
        },
        selectedDate: {
            get() {
                if (!this.value) {
                    return {
                        startDate: null,
                        endDate: null,
                    };
                }

                const { startDate, endDate } = this.value;

                return {
                    startDate,
                    endDate
                };
            },
            set(value) {
                this.$emit('input', {
                    startDate: value.startDate,
                    endDate: value.endDate
                });
            },
        },
        rangesTemplate() {
            if (!this.showRanges) {
                return false;
            }

            if (this.resetDefaultRanges) {
                return Object.keys(this.ranges).length ? this.ranges : false;
            }

            return Object.assign({}, defaultDateRanges, this.ranges)
        },
        selectedValue() {
            if (!this.hasValue) {
                return null;
            }

            const { startDate, endDate } = this.value;

            return `${startDate} - ${endDate}`;
        },
        hasValue() {
            if (!this.value) {
                return false;
            }

            const { startDate, endDate } = this.value;

            return !!startDate && !!endDate;
        },
    },
    methods: {
        onClearValueEvent() {
            this.selectedDate = {
                startDate: null,
                endDate: null,
            };
        },
    },
}
</script>