<template>
    <atbl-form-group :label="label"
                     :label-for="getLabelFor"
                     :error="getError(errors, name)"
                     :label-class="labelClass"
    >
        <input :id="getLabelFor"
               :type="type"
               :name="name"
               :value="value"
               :placeholder="getPlaceholder"
               :disabled="disabled"
               :autocomplete="autocomplete"
               :class="{
                   'is-invalid': hasError(errors, name)
               }"
               class="form-control"
               @input="onUpdate"
        />
    </atbl-form-group>
</template>

<script>
    import ATBLFormControls from "./ATBLFormControls";

    export default {
        name: "ATBLInput",
        mixins: [
            ATBLFormControls
        ],
        props: {
            type: {
                type: String,
                default: "text",
                validator: function (value) {
                    return ["text", "password", "email", 'time', 'search'].indexOf(value) !== -1;
                }
            }
        }
    }
</script>