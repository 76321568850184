<template>
	<div class="card card-list reports reports-games atbl m-0">
		<div class="card-body">
			<div class="general-list">
				<div class="row">
					<div></div>
					<div class="col">
						<a href="#"
                           @click.prevent="sortChange('game_name')"
                           class="sort-link"
						   :class="{'active': sort.type === 'game_name'}"
                        >
							Game
                            <span v-if="sort.type === 'game_name'">
                                <i class="fa" :class="{'fa-caret-up': sort.order === 'ASC', 'fa-caret-down': sort.order === 'DESC'}"></i>
                            </span>
						</a>
					</div>
					<div class="col">
						<a href="#"
                           @click.prevent="sortChange('total_tickets')"
                           class="sort-link"
						   :class="{'active': sort.type === 'total_tickets'}"
                        >
							Tickets
                            <span v-if="sort.type === 'total_tickets'">
                                <i class="fa" :class="{'fa-caret-up': sort.order === 'ASC', 'fa-caret-down': sort.order === 'DESC'}"></i>
                            </span>
						</a>
					</div>
					<div class="col">
						<a href="#"
                           @click.prevent="sortChange('total_lines')"
                           class="sort-link"
						   :class="{'active': sort.type === 'total_lines'}"
                        >
							Lines
                            <span v-if="sort.type === 'total_lines'">
                                <i class="fa" :class="{'fa-caret-up': sort.order === 'ASC', 'fa-caret-down': sort.order === 'DESC'}"></i>
                            </span>
						</a>
					</div>
				</div>
				
				<div v-for="(item, index) in gameReports"
				       :key="index"
				       v-if="!isLoading"
				       class="row py-1"
				>
					<div>
						<img class="game-thumb" :src="$imageService.gameImage(item.image)">
					</div>
					<div class="col-12 col-lg">
						<span class="small-mb-badge">Game</span>
						{{ item.game_name }}
					</div>
					<div class="col-12 col-lg">
						<span class="small-mb-badge">Tickets</span>
						{{ item.total_tickets }}
					</div>
					<div class="col-12 col-lg">
						<span class="small-mb-badge">Lines</span>
						{{ item.total_lines }}
					</div>
				</div>
				
				<loader :show="isLoading"
				        type="small"
				/>
				<not-found v-if="!isLoading"
				           msg="No results found"
				           :items="gamesReports"
				           class="w-100"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import ReportsGamesService from "../../../../../services/ReportsGamesService";
    import dynamicSort from '../../../../../utils/Array/DynamicSort';
	
	export default {
		name: "ReportsGames",
		data() {
			return {
				gamesReports: [],
				isLoading: false,
				sort: {
					type: "total_lines",
					order: "DESC"
				}
			};
		},
        computed: {
            gameReports() {
                return dynamicSort(this.gamesReports, this.sort.type, this.sort.order);
            },
        },
        async created() {
            await this.getGamesReports();

            Atbl.$on("gameFilter", async (month) => {
                await this.getGamesReports({
                    month
                });
            });
        },
		methods: {
			async getGamesReports(_model = {}) {
                this.isLoading = true;

                try {
                    const response = await ReportsGamesService.getGamesReports(_model);

                    this.gamesReports = response.data.map(function (item) {
                        return {
                            ...item,
                            total_tickets: (item.lotto_tickets_count + item.raffle_tickets_count)
                        };
                    });

                    this.isLoading = false;
                } catch (error) {
                    this.isLoading = false;

                    window.flashError(error);
                }
			},
			sortChange(type, order = null) {
                let orderType = order ? order : (this.sort.order === "ASC" ? "DESC" : "ASC");

				this.sort = {
					type,
					order: orderType
				};
            },
		},
	}
</script>