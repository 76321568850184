<template>
	<atbl-modal v-if="$can('Player - Reset Password')"
	         title="Reset Player Password"
	         :show="value"
	         size="sm"
	         :close-on-backdrop="false"
			 @close="cancelResetPassword"
	>
		<div class="row">
			<div class="col-12">
				<atbl-input label="Password"
				            name="password"
				            type="password"
				            :disabled="isLoading"
				            v-model="password"
				            :errors="errors"
				/>
			</div>
			<div class="col-12">
				<atbl-input label="Confirm Password"
				            name="password_confirmation"
				            type="password"
				            :disabled="isLoading"
				            v-model="password_confirmation"
				            :errors="errors"
				/>
			</div>
		</div>
		
		<template #footer>
			<button v-if="!isLoading"
					  class="btn btn-sm btn-danger"
			          @click="cancelResetPassword"
			>
				Close
			</button>
			<button class="btn btn-sm btn-primary"
			          :disabled="!canUpdatePassword"
			          @click="resetPassword"
			>
				<span v-if="!isLoading">
					Save
				</span>
				<loader :show="isLoading"
				        type="small"
				/>
			</button>
		</template>
	</atbl-modal>
</template>

<script>
    import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';
	
	export default {
        name: "reset-password",
		components: {
			AtblModal,
		},
	    props: {
        	input: {},
		    value: {},
		    playerId: {
        		type: Number,
			    required: true
		    }
	    },
	    data: function () {
        	return {
		        password: null,
		        password_confirmation: null,
		        errors: {},
		        isLoading: false
	        };
	    },
	    computed: {
        	hasResetPasswordPermision: function () {
        		return this.$can('Player - Reset Password');
	        },
		    passwordIsMatching: function () {
        		if(this.password && this.password_confirmation) {
        			return this.password === this.password_confirmation;
		        }
        		return false;
		    },
        	canUpdatePassword: function () {
        		return this.hasResetPasswordPermision && this.passwordIsMatching;
	        }
	    },
	    methods: {
		    resetPassword: function (event) {
			    event.preventDefault();
			    
			    let _vm = this;
			    
			    _vm.isLoading = true;
			    window.axios.patch(
			    	`/player/${_vm.playerId}/reset-password`,
				    {
					    password: _vm.password,
					    password_confirmation: _vm.password_confirmation
				    })
				    .then(function (response) {
				    	const { message } = response.data;
				    	
					    _vm.cancelResetPassword();
					    
					    window.showMessage(message);
				    })
				    .catch(function (error) {
					    _vm.errors = error.response.data.errors
				    })
			        .finally(() => {
				        _vm.isLoading = false;
			        });
		    },
        	modal: function (value) {
        		this.$emit("input", value);
	        },
            cancelResetPassword: function () {
	            this.password = null;
	            this.password_confirmation = null;
	            this.errors = {};
	            this.modal(false);
            }
	    }
    }
</script>