export default {
    default: 25,
    data: [
        {
            value: 25,
            text: 25
        },
        {
            value: 50,
            text: 50
        },
        {
            value: 100,
            text: 100
        },
        {
            value: 150,
            text: 150
        }
    ]
};