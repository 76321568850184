<template>
    <div class="players-list">
        <div class="players-list-mobile">
            <div class="general-list">
                <div class="row">
                    <div class="col-lg col d-flex align-items-center">
                        &nbsp;
                    </div>
                    <div class="col-lg-1">
                        Id
                    </div>
                    <div class="col-2">
                        Info
                    </div>
                    <div class="col">
                        Country
                    </div>
                    <div class="col">
                        Balance
                    </div>
                    <div class="col">
                        Live spent
                    </div>
                    <div class="col">
                        Total win
                    </div>
                    <div class="col" v-if="$can('Player - View Agent')">
                        Agent
                    </div>
                    <div v-if="$canAny(['Player - View Latest Call', 'Player - View Latest Dispositions'])"
                           class="col-2"
                    >
                        Disposition
                    </div>
                    <div class="col-3"> Operations</div>
                </div>

                <template v-if="!isLoading">
                    <div v-for="(player, index) in playersData.data"
                           :key="index"
                           class="row"
                    >
                        <div class="col-6 col-lg-3 align-self-start mc-2">
                            <span class="small-mc-badge-extended black">Action</span>
                        </div>
                        <div class="col-6 col-lg-3 align-self-start mc-2 players-list-row-id">
                            <span class="small-mc-badge-extended black">Id</span>
                            {{ player.id }}
                        </div>
                        <div class="col-6 col-lg-3 align-self-start mc-2">
                            <span class="small-mc-badge-extended black">Info</span>
                            {{ player.name }} {{ player.surname }}<br />
                            <created-through :created-through="player.created_through" />
                            <player-kyc-status-badge :kyc-status="player.kyc_status" />
                            <br />
                            {{ player.email }}<br />
                            <latest-transaction :player="player" />
                            <span v-if="player.last_seen_at" class="badge badge-warning p-1">
                                Last seen at: {{ player.last_seen_at | utcToLocal }}
                            </span>
                            <div v-if="player.tags.length > 0"
                                 class="player-tags-container mt-3"
                            >
                                <div v-for="(tag, index) in player.tags"
                                     :key="index"
                                     class="player-tag primary"
                                >
                                    {{ tag }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 align-self-start mc-2">
                            <span class="small-mc-badge-extended black">Country</span>
                            {{ player.country }}
                        </div>
                        <div class="col-6 col-lg-3 align-self-start mc-2">
                            <span class="small-mc-badge-extended inline black">Balance</span>
                            {{ ((player.amount ? player.amount : 0) + (player.bonus_amount ? player.bonus_amount : 0)) | formatCurrency }}
                        </div>
                        <div class="col-6 col-lg-3 align-self-start mc-2">
                            <span class="small-mc-badge-extended inline black">Live spent</span>
                            {{ player.live_spent | formatCurrency }}
                        </div>
                        <div class="col-6 col-lg-3 align-self-start mc-2">
                            <span class="small-mc-badge-extended inline black">Total win</span>
                            {{ player.total_win | formatCurrency }}
                        </div>
                        <div v-if="$can('Player - View Agent')"
                               class="col-6 col-lg-3 align-self-start mc-2"
                        >
                            <span class="small-mc-badge-extended inline black">Agent</span>
                            {{ player.agent_name }}
                        </div>
                        <div v-if="$canAny(['Player - View Latest Call', 'Player - View Latest Dispositions'])"
                               class="col-6 col-lg-3 align-self-start mc-2"
                        >
                            <span class="small-mc-badge-extended black">Last contact</span>
                            <latest-call v-if="$can('Player - View Latest Call')"
                                         :player="player"
                            />
                            <latest-disposition v-if="$can('Player - View Latest Dispositions')"
                                                :player="player"
                            />
                        </div>
                        <div class="col-6 col-lg-3 align-self-start mc-2">
                            <span class="small-mc-badge-extended black">Operations</span>
                            <a v-if="$can('Player - View Details')"
                               :href="`/players/${player.id}/view`"
                               target="_blank"
                               v-show="player"
                            >
                                View Player
                            </a><br />
                            <a v-if="$can('Player - Assign Agent')"
                               href="#"
                               v-show="player"
                               @click.prevent="onAssignAgentEvent(player)"
                            >
                                Agent
                            </a><br />
                            <div v-if="$can('Player - Call') && playerHasPhone(player)" class="d-inline-block">
                                <c-dropdown v-if="player.mobile && player.phone"
                                            id="callNowDesktop"
                                            toggler-text="Call"
                                            size="sm"
                                            right
                                            color="link">
                                    <c-dropdown-item @click.prevent="callPlayer(player.mobile, player.id)">
                                        {{ player.mobile }}
                                    </c-dropdown-item>
                                    <c-dropdown-item @click.prevent="callPlayer(player.phone, player.id)">
                                        {{ player.phone }}
                                    </c-dropdown-item>
                                </c-dropdown>
                                <a href="#"
                                   v-else
                                   size="sm"
                                   v-show="player"
                                   color="btn btn-outline-primary"
                                   @click.prevent="callPlayer((player.mobile ? player.mobile : player.phone), player.id)"
                                >
                                    Call
                                </a>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="players-list-desktop">
            <div class="table-responsive">
                <table class="table table-borderless m-0">
                    <thead>
                        <tr class="v-sortable" @sort="onUpdateSortEvent">
                            <th scope="col" class="check-box-input">
                                <atbl-checkbox :partial-check="partialChecked"
                                               :value="currentPageSelected"
                                               @input="onAddAllFromCurrentPageToBulkEvent"
                                />
                            </th>
                            <th v-sort:id="sort" scope="col">Id</th>
                            <th v-sort:transaction_created_at="sort" scope="col">Info</th>
                            <th v-sort:country="sort" scope="col">Country</th>
                            <th v-sort:wallet_total_balance="sort" scope="col">Balance</th>
                            <th v-sort:live_spent="sort" scope="col">Live spent</th>
                            <th v-sort:total_win="sort" scope="col">Total win</th>
                            <th v-if="$can('Player - View Agent')"
                                v-sort:agent_name="sort"
                                scope="col"
                            >
                                Agent
                            </th>
                            <th v-if="$canAny(['Player - View Latest Call', 'Player - View Latest Dispositions'])"
                                v-sort:call_created_at="sort"
                                scope="col"
                            >
                                Disposition
                            </th>
                            <th scope="col">Operations</th>
                        </tr>
                    </thead>
                    <tbody v-if="!isLoading">
                        <tr v-for="(player, index) in playersData.data"
                            :key="index"
                        >
                            <th scope="row" class="check-box-input">
                                <atbl-checkbox :value="playersBulk.includes(player.id)"
                                               @input="onAddBulkEvent(player.id)"
                                />
                            </th>
                            <td>
                                {{ player.id }}
                            </td>
                            <td>
                                {{ player.name }} {{ player.surname }}
                                <created-through :created-through="player.created_through" />
                                <player-kyc-status-badge :kyc-status="player.kyc_status" />
                                <br />
                                {{ player.email }}<br />
                                <latest-transaction :player="player" />
                                <span v-if="player.last_seen_at" class="badge badge-warning p-1">
                                    Last seen at: {{ player.last_seen_at | utcToLocal }}
                                </span>
                                <div v-if="player.tags.length > 0"
                                     class="player-tags-container mt-3"
                                >
                                    <div v-for="(tag, index) in player.tags"
                                         :key="index"
                                         class="player-tag primary"
                                    >
                                        {{ tag }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{ player.country }}
                            </td>
                            <td>
                                <span class="text-big font-weight-bold d-block">
                                    {{ player.wallet_total_balance | formatCurrency }}
                                </span>
                                <small class="text-danger d-block">
                                    Real: {{ player.wallet_amount | formatCurrency }}
                                </small>
                                <small class="text-warning d-block">
                                    Bonus: {{ player.wallet_bonus_amount | formatCurrency }}
                                </small>
                            </td>
                            <td>
                                {{ player.live_spent | formatCurrency }}
                            </td>
                            <td>
                                {{ player.total_win | formatCurrency }}
                            </td>
                            <td v-if="$can('Player - View Agent')">
                                {{ emptyField(player.agent_name) }}
                            </td>
                            <td v-if="$canAny(['Player - View Latest Call', 'Player - View Latest Dispositions'])"
                            >
                                <latest-call v-if="$can('Player - View Latest Call')"
                                             :player="player"
                                />
                                <latest-disposition v-if="$can('Player - View Latest Dispositions')"
                                                    :player="player"
                                />
                            </td>
                            <td>
                                <a v-if="$can('Player - View Details')"
                                   :href="`/players/${player.id}/view`"
                                   target="_blank"
                                   v-show="player"
                                >
                                    View Player
                                </a><br />
                                <a v-if="$can('Player - Assign Agent')"
                                   href="#"
                                   v-show="player"
                                   @click.prevent="onAssignAgentEvent(player)"
                                >
                                    Agent
                                </a><br />
                                <div v-if="$can('Player - Call') && playerHasPhone(player)" class="w-100">
                                    <c-dropdown v-if="player.mobile && player.phone"
                                                id="callNow"
                                                toggler-text="Call"
                                                right
                                                color="link"
                                                class="call-dropdown"
                                    >
                                        <c-dropdown-item @click.prevent="callPlayer(player.mobile, player.id)">
                                            {{ player.mobile }}
                                        </c-dropdown-item>
                                        <c-dropdown-item @click.prevent="callPlayer(player.phone, player.id)">
                                            {{ player.phone }}
                                        </c-dropdown-item>
                                    </c-dropdown>
                                    <a v-else
                                       href="#"
                                       v-show="player"
                                       @click.prevent="callPlayer((player.mobile ? player.mobile : player.phone), player.id)"
                                    >
                                        Call
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <not-found v-if="!isLoading"
                   :items="playersData.data"
                   msg="No players found."
                   class="py-2"
        />

        <div v-if="!isLoading && playersData.last_page > 1" class="card-footer">
            <div class="d-flex align-items-center justify-content-between">
                <nav class="wrap">
                    <atbl-pagination :pagination="playersData"
                                     :scroll="false"
                                     @paginate="onPageChangeEvent"
                    />
                </nav>
                <a href="#"
                   @click.prevent="$scrollToTop()"
                >
                    <i class="fa fa-caret-up mr-2"></i> Back to top
                </a>
            </div>
        </div>

        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import LatestTransaction from './Components/LatestTransaction';
import LatestDisposition from './Components/LatestDisposition';
import LatestCall from './Components/LatestCall';
import CreatedThrough from './Components/CreatedThrough/CreatedThrough';
import SortByComponent from './Components/SortByComponent';
import AtblCheckbox from '../../../components/Forms/Inputs/AtblCheckbox';
import PlayerKycStatusBadge from '../Components/Layout/KYCStatus/player-kyc-status-badge.vue';
import emptyField from '../../../filters/EmptyField';

export default {
    name: 'players-list',
    inject: [
        'onAddAllFromCurrentPageToBulkEvent',
        'onAddBulkEvent',
    ],
    components: {
        LatestCall,
        LatestDisposition,
        LatestTransaction,
        CreatedThrough,
        SortByComponent,
        AtblCheckbox,
        PlayerKycStatusBadge
    },
    props: {
        players: {
            type: Array,
            required: () => ([])
        },
        playersData: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        sort: {
            type: Object,
            default: () => ({
                sort: 'id',
                value: 'ASC'
            })
        },
        playersBulk: {
            type: Array,
            default: () => ([])
        },
        bulkSelectedAll: {
            type: Boolean,
            required: true
        },
        page: {
            type: Number,
            default: 1,
        },
        partialChecked: {
            type: Boolean,
            default: false,
        },
        currentPageSelected: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        emptyField,
        playerHasPhone(player) {
            return player.mobile || player.phone;
        },
        onPageChangeEvent(page = 1) {
            this.$emit('paginateUsersList', page);
        },
        callPlayer(phone, id) {
            this.$emit('callPlayer', {
                phone: phone,
                id: id
            });
        },
        onUpdateSortEvent(value) {
            this.$emit('sorted', value);
        },
        onAssignAgentEvent(player) {
            this.$emit('agent', player);
        },
    },
}
</script>