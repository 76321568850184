<template>
    <section class="player-call-component">
        <div class="card mt-2 mb-4" v-if="player && isset(player, 'id')">
            <div class="card-header card-header-atbl align-items-center justify-content-between d-flex bg-primary text-white"
            >
                <span>
                    <i class="fa fa-phone"></i> Call Player ({{ player.name }} {{ player.surname }})
                </span>
                <a v-if="!!callLogs.length"
                   href="#"
                   class="text-white"
                   @click.prevent="toggleCallLogsModal(true)"
                >
                    Logs
                </a>
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <twilio-phone-numbers v-model="selectedNumber"/>
                        
                        <DialKeypad v-if="isKeypadOpen"
                                    :phone="number"
                                    :value="numberToCall"
                                    :call-started="isCallStarted"
                                    @digit="onPressedDigitEvent"
                                    @update="onDialPadNumberUpdate"
                        />
                        
                        <template v-if="!callLoader">
                            <div class="dial-buttons">
                                <button v-if="isCallStarted"
                                        :class="{'btn-primary': !isMuted, 'btn-warning': isMuted}"
                                        :disabled="!isCallAccepted"
                                        type="button"
                                        class="mute-button"
                                        @click="muteHandler"
                                >
                                    <i class="fa" :class="{'fa-microphone': !isMuted, 'fa-microphone-slash': isMuted}"
                                    ></i>
                                </button>
                                <button v-if="!isCallStarted"
                                        :disabled="!selectedNumber || !numberToCall"
                                        name="dialBtn"
                                        type="submit"
                                        class="btn btn-sm btn-primary dial-button"
                                        :class="{'active': isKeypadOpen}"
                                        @click.prevent="onHandleCallEvent"
                                >
                                    <span v-if="!callLoader">
                                        <i class="fa fa-phone"></i> Dial
                                    </span>
                                    <loader :show="callLoader"
                                            type="smaller"
                                    />
                                </button>
                                <button v-else
                                        name="hangBtn"
                                        type="submit"
                                        class="btn btn-sm btn-danger"
                                        @click.prevent="onHangCallEvent"
                                >
                                    <i class="fa fa-phone"></i> Hang
                                </button>
                                <button type="button"
                                        :class="{'active': isKeypadOpen}"
                                        class="keypad-toggle-button"
                                        @click="isKeypadOpen = !isKeypadOpen"
                                >
                                    <img src="/img/icons/keypad.svg" alt="keypad"/>
                                </button>
                            </div>
                        </template>
                        <loader :show="callLoader"
                                type="small"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else
             class="card mt-3"
        >
            <div class="card-body">
                <not-found msg="Player is not loaded. Please refresh you page."
                           :items="[]"
                />
            </div>
        </div>
        
        <atbl-modal title="Call Logs"
                    :close-on-backdrop="true"
                    :show="callLogsOpen"
                    @close="toggleCallLogsModal"
        >
            <div class="card card-list m-0">
                <div class="card-body"
                     :style="{
                                 'max-height': `${logsBodyHeight}px`,
                                 'overflow-y': 'auto'
                             }"
                >
                    <div class="general-list">
                        <div class="row show-first-row">
                            <div class="col text-muted">Message</div>
                        </div>
                        
                        <div v-for="(log, index) in callLogs"
                             :key="index"
                             class="row"
                        >
                            <div class="col text-muted">{{ log }}</div>
                        </div>
                        
                        <not-found msg="You have not made a call yet"
                                   :items="callLogs"
                        />
                    </div>
                </div>
            </div>
            
            <template #footer>
                <button class="btn btn-outline-danger"
                        @click="toggleCallLogsModal(false)"
                >
                    Cancel
                </button>
            </template>
        </atbl-modal>
    </section>
</template>

<script>
    import DialKeypad from '../../../../../components/DialKeypad/DialKeypad';
    import {Device} from '@twilio/voice-sdk';
    import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';
    
    export default {
        props: {
            number: {
                required: true
            },
            player: {
                type: Object,
                required: true
            },
            agentId: {
                type: Number,
                default: null
            },
        },
        components: {
            DialKeypad,
            AtblModal,
        },
        data() {
            return {
                callObject: null,
                callLoader: false,
                selectedNumber: null,
                isCallStarted: false,
                isCallAccepted: false,
                isKeypadOpen: false,
                numberToCall: null,
                device: null,
                isMuted: false,
                CallSid: null,
                callLogs: [],
                callLogsOpen: false,
                logsBodyHeight: null,
            };
        },
        computed: {
            getToNumber() {
                return `+${!this.numberToCall ? this.number : this.numberToCall}`;
            },
        },
        created() {
            this.logsBodyHeight = window.innerHeight - 180;
            this.numberToCall = this.number;
        },
        methods: {
            async getToken() {
                const response = await window.axios.post('/twilio/token', {forPage: window.location.pathname});
                const {token} = response.data;
                
                return token;
            },
            async onHandleCallEvent() {
                if (!!this.callLoader || !!this.isCallStarted) {
                    return this.log('Call is already in progress.', 'alert-danger');
                }
                
                if (!this.selectedNumber) {
                    return this.log(`Select number.`, 'alert-danger');
                }
                
                this.callLoader = true;
                
                await this.call(
                  this.selectedNumber.value,
                  this.getToNumber
                );
            },
            async call(fromPhoneNumber, toPhoneNumber) {
                const {id} = this.player;
                
                try {
                    this.log('Get token.');
                    
                    // Get token
                    const token = await this.getToken();
                    
                    this.log('Token has been get successfully.', 'alert-warning');
                    
                    // Initialize device
                    this.device = new Device(token, {
                        debug: true,
                        codecPreferences: ['opus', 'pcmu']
                    });
                    
                    this.log('Device has been initialized.');
                    
                    this.log(`Call connection start.`, 'alert-success');
                    this.log(`Caller: ${fromPhoneNumber}; To: ${toPhoneNumber};`, 'alert-warning');
                    
                    // Connect to Twilio
                    this.callObject = await this.device.connect({
                        params: {
                            fromPhoneNumber,
                            toPhoneNumber,
                            caller_id: id,
                            caller_type: 'player',
                            agent_id: this.agentId
                        }
                    });
                    
                    this.isCallStarted = true;
                    
                    // Handle device accept call
                    this.callObject.on('accept', async call => {
                        const {parameters: {CallSid}} = call;
                        
                        this.log(`Call has been accepted with CallSid: ${CallSid}.`, 'alert-success');
                        
                        this.CallSid = CallSid;
                        
                        this.isCallAccepted = true;
                        
                        this.callLoader = false;
                    });
                    
                    // Handle device disconnect call
                    this.callObject.on('disconnect', async () => {
                        this.callLoader = true;
                        
                        this.log(`Call has been disconnected.`, 'alert-danger');
                        
                        this.disconnectCall();
                    });
                } catch (error) {
                    this.callLoader = false;
                    
                    this.log(error, 'alert-danger');
                }
            },
            onHangCallEvent() {
                if (!!this.callLoader) {
                    return this.log('Hang up call in progress...', 'alert-warning');
                }
                
                if (!this.device) {
                    return;
                }
                
                this.log(
                  'Hang up call in progress...',
                  'alert-warning'
                );
                
                this.device.disconnectAll();
            },
            disconnectCall() {
                this.callObject = null;
                this.isMuted = false;
                this.isCallAccepted = false;
                this.isCallStarted = false;
                
                setTimeout(() => {
                    this.callLoader = false;
                }, 1000);
            },
            onPressedDigitEvent(value) {
                if (!this.callObject) {
                    return;
                }
                
                const tempValue = value.toString();
                
                this.callObject.sendDigits(tempValue[tempValue.length - 1]);
            },
            onDialPadNumberUpdate(value) {
                this.numberToCall = value;
            },
            muteHandler() {
                if (!this.callObject) {
                    return;
                }
                
                this.isMuted = !this.isMuted;
                
                this.callObject.mute(this.isMuted);
                
                this.log(
                  `Microphone is ${this.isMuted ? 'muted' : 'unmuted'}`,
                  `${this.isMuted ? 'alert-danger' : 'alert-success'}`
                );
            },
            log(error, status = null) {
                const message = this.isObject(error)
                  ? error.hasOwnProperty('response') && error.response.data.message ? error.response.data.message : error.message
                  : error;
                
                if (!!status) {
                    flash(message, status);
                }
                
                this.callLogs = [
                    ...this.callLogs,
                    message
                ];
            },
            toggleCallLogsModal(value = true) {
                this.callLogsOpen = value;
                
                const body = document.querySelector('body');
                
                if (!body) {
                    return;
                }
                
                !!value
                  ? body.classList.add('overflow-hidden')
                  : body.classList.remove('overflow-hidden');
            }
        },
    };
</script>