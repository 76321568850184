<template>
    <atbl-modal title="Add Section"
             :show="openModal"
             :close-on-backdrop="false"
                @close="openAddSectionModal(false)"
    >
        <div v-if="!isLoading" class="row">
            <div class="col-12">
                <atbl-input label="Name *"
                            :value="model.name"
                            @update="updateForm"
                            :errors="errors"
                />
            </div>
            <div class="col-12">
                <atbl-input label="Key *"
                            name="key"
                            :value="model.key"
                            @update="updateForm"
                            :errors="errors"
                />
            </div>
            <div class="col-12">
                <atbl-select label="Type"
                             name="type"
                             :value="model.type"
                             @update="updateForm"
                             :options="settingsSectionsTypes"
                />
            </div>
        </div>
        <h5 class="text-center text-uppercase" v-else>
            Loading...
        </h5>

        <template #footer>
            <div class="d-flex w-100">
                <div class="w-50 font-weight-bold">
                    * Required
                </div>
                <div class="w-50 text-right">
                    <button class="btn btn-sm btn-danger"
                            @click="handleCancel"
                    >
                        Close
                    </button>
                    <button @click="handleOK"
                            class="btn"
                            :class="{
                               'btn-primary': !isLoading,
                               'btn-outline-primary': isLoading,
                               'disabled': !enableSave || isLoading
                           }"
                            :disabled="!enableSave || isLoading"
                            v-if="$can('Settings - Create/Update')"
                    >
                        <span  v-if="!isLoading"
                        >
                            Save
                        </span>
                        <loader type="small"
                                :show="isLoading"
                        />
                    </button>
                </div>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    import AtblModal from '../../../../components/AtblModal/AtblModal.vue';
    const {
        mapActions,
        mapGetters,
        mapMutations
    } = createNamespacedHelpers(NamespaceConst.settings);

    export default {
        name: "add-settings-section-modal",
        components: {
			AtblModal,
        },
        computed: {
            ...mapGetters({
                showModal: TypesConst.settings.getters.GET_ADD_SETTINGS_SECTION_MODAL,
                settingsSectionsTypes: TypesConst.settings.getters.GET_SECTIONS_TYPES,
                isLoading: TypesConst.settings.getters.GET_SECTION_MODAL_LOADER,
                errors: TypesConst.settings.getters.GET_SECTION_FORM_ERRORS,
                parentId: TypesConst.settings.getters.GET_PARENT_ID,
                sectionId: TypesConst.settings.getters.GET_SECTION_ID,
                model: TypesConst.settings.getters.GET_SECTION_MODEL
            }),
            hasName: function(){
                return this.model.name.length < 3;
            },
            hasKey: function(){
                return this.model.key.length < 3;
            },
            enableSave: function(){
                return !this.hasKey && !this.hasName;
            },
            openModal: {
                get: function(){
                    return this.showModal;
                },
                set: function(body){
                    this.openAddSectionModal(body);
                }
            },
        },
        methods: {
            ...mapActions({
                openAddSectionModal: TypesConst.settings.actions.OPEN_SETTINGS_SECTIONS_MODAL_ACTION,
                getSectionsTypes: TypesConst.settings.actions.FETCH_SETTINGS_SECTIONS_TYPES_ACTION,
                saveNewSettingSection: TypesConst.settings.actions.SAVE_SETTINGS_SECTION_ACTION,
            }),
            ...mapMutations({
                clearSectionModal: TypesConst.settings.mutations.CLEAR_SECTION_MODEL,
                updateModel: TypesConst.settings.mutations.UPDATE_FORM_MODEL
            }),
            updateForm: function(field, value) {
                this.updateModel({
                    [field]: value
                });
            },
            handleOK: function(evt){
                evt.preventDefault();

                if(this.enableSave){
                    let model = {
                        ...this.model
                    };
                    model.parentId = this.parentId;
                    if(this.sectionId){
                        model.section_id = this.sectionId;
                    }
                    this.saveNewSettingSection(model);
                }
            },
            handleCancel: function(){
                this.clearSectionModal();
                this.openModal = false;
            }
        },
        created: function(){
            this.getSectionsTypes();
        }
    }
</script>