<template>
    <div class="card m-0">
        <div v-if="$can('Payon - Bonus Deposit Agent') || $can('Payon - Bonus Deposit User')" class="card-body">
            <div class="row mt-2">
                <div class="col-12 col-md-3" v-if="$can('Payon - Bonus Deposit Agent')">
                    <agent-picker v-model="agent"/>
                </div>
                <div class="col-12 col-md-3">
                    <atbl-number label="Amount"
                                 name="amount"
                                 :errors="errors"
                                 :min="0.00"
                                 :step=".01"
                                 :value="amount"
                                 @update="updateAmount"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <atbl-form-group label="#"
                                  label-for="buyBtn"
                    >
                        <button class="btn btn-block"
                                :class="{
                                    'btn-primary': !getPaymentLoader,
                                    'btn-outline-primary': getPaymentLoader
                                }"
                                :disabled="isSaveEnabled"
                                @click="setBonusConfirmModal(true)"
                        >
                            <span v-if="!getPaymentLoader">Add</span>
                            <loader type="smaller"
                                    :show="getPaymentLoader"
                            />
                        </button>
                    </atbl-form-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    
    const {
        mapGetters: mapPlayerGetters,
        mapMutations: mapPlayerMutations
    } = createNamespacedHelpers(NamespaceConst.players);
    
    const {
        mapGetters: mapPaymentGetters
    } = createNamespacedHelpers(NamespaceConst.payment);

    import Amount from "../Mixins/Amount";

    export default {
        name: "add-bonus-form",
	    mixins: [
	    	Amount
	    ],
        computed: {
            ...mapPlayerGetters({
                getFundsAmount: TypesConst.players.getters.GET_FUNDS_AMOUNT,
                getFundsAgent: TypesConst.players.getters.GET_FUNDS_AGENT,
                getFundsCredit: TypesConst.players.getters.GET_FUNDS_CREDIT_TYPE,
                getPaymentLoader: TypesConst.players.getters.GET_FUNDS_LOADER,
	            getFundsErrors: TypesConst.players.getters.GET_FUNDS_CREDIT_CARD_ERRORS
            }),
	        ...mapPaymentGetters({
		        getPaymentLoader: TypesConst.payment.getters.GET_PAYMENT_LOADER
	        }),
            agent: {
                get: function () {
                    return this.getFundsAgent;
                },
                set: function (value) {
                    this.setFundsAgent(value);
                }
            },
            isSaveEnabled: function(){
                if(this.getPaymentLoader){
                    return true;
                }else {
                    if(this.amount === 0 || this.isDisabled){
                        return true;
                    }else {
                        if(!this.getFundsAgent && this.$can('Payon - Bonus Deposit Agent')){
                            return true;
                        }
                        return false;
                    }
                }
            }
        },
        methods: {
            ...mapPlayerMutations({
                setFundsAmount: TypesConst.players.mutations.SET_FUNDS_AMOUNT,
                setFundsAgent: TypesConst.players.mutations.SET_FUNDS_AGENT,
                setFundsCredit: TypesConst.players.mutations.SET_FUNDS_TRANSACTION_TYPE,
                setBonusConfirmModal: TypesConst.players.mutations.SET_BONUS_CONFIRM_MODAL
            })
        }
    }
</script>