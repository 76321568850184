export default {
    fetchPaymentGateways() {
        return window.axios.get('/payments/list');
    },

    fetchPlayerPaymentGateways(playerId) {
        return window.axios.get(`/players/${playerId}/payments/list`);
    },

    fetchAllPlayerPayments(playerId, types) {
        return window.axios.get(`/players/${playerId}/payments/all`, {
            params: {
                ...(!!types) && {types},
            }
        });
    },

    activate(gatewayKey, stateKey) {
        return window.axios.put(`/payments/state/${gatewayKey}/${stateKey}/activate`);
    },

    deactivate(gatewayKey, stateKey) {
        return window.axios.put(`/payments/state/${gatewayKey}/${stateKey}/deactivate`);
    },

    updateCountries(gatewayKey, availableCountries, excludedCountries) {
        const params = {
            availableCountries,
            excludedCountries,
        };

        return window.axios.put(`/payments/countries/${gatewayKey}`, params);
    },

    getHasSavedCards: function(id){
        return window.axios.get(`/pn/card/has-saved`, {
            params: {
                playerId: id
            }
        });
    }
};