import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.tickets.mutations.SET_TICKETS_TICKET_ID](state, payload){
        state.ticketId = payload;
    },
    [TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA](state, payload){
        state.ticket = payload;
    },
    [TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA_LOADER](state, payload){
        state.ticketLoader = payload;
    },
    [TypesConst.tickets.mutations.SET_TICKETS_TICKET_CONFIGURATION](state, payload){
        state.ticketConfiguration = payload;
    },
};