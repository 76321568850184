<template>
    <form @submit.prevent="fetchPermissions">
        <div class="card card-search">
            <div class="card-header">
                <i class="fa fa-search"></i> Search
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <atbl-form-group label="Name"
                                         class="m-0"
                        >
                            <atbl-input :value="model.name"
                                        @update="updateSearchFormModelField"
                            />
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-lg-3">
                        <roles-picker :value="model.roles"
                                      @update="updateSearchFormModelField"
                        />
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-sm btn-primary mr-2">
                    <i class="fa fa-search"></i> Search
                </button>
                <button class="btn btn-sm btn-danger"
                        @click.prevent="clearSearchFormModel"
                >
                    <i class="fa fa-ban"></i> Clear
                </button>
            </div>
        </div>
    </form>

</template>

<script>
import SearchFormModel from './SearchFormModel';
import AtblFormGroup from '../../../../components/Forms/Holders/ATBLFormGroup';
import AtblInput from '../../../../components/Forms/Inputs/ATBLInput';
import RolesPicker from '../../../../components/Forms/Select/RolesPicker';

export default {
    name: 'SearchFormComponent',
    inject: [
        'fetchPermissions',
        'updateSearchFormModelField',
        'clearSearchFormModel',
    ],
    components: {
        RolesPicker,
        AtblFormGroup,
        AtblInput,
    },
    props: {
        model: {
            type: Object,
            default: () => (SearchFormModel)
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>