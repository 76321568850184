<template>
    <atbl-form-group :error="getError(errors, name)"
                     :label="label"
                     id="fsBirthdate"
                     label-for="birthdate"
                     class="birthdate-picker font-weight-bold"
    >
        <date-range-picker v-model="pickerValue"
                           :opens="opens"
                           :singleDatePicker="singleDatePicker"
                           :ranges="showRanges"
                           :locale-data="locale"
                           :showDropdowns="showDropdowns"
                           :auto-apply="autoApply"
                           :class="{
                               'is-invalid': hasError(errors, name)
                           }"
        >
            <div slot="input">
                <span v-if="formattedValue">{{ formattedValue }}</span>
                <span v-else>Select date</span>
            </div>
        </date-range-picker>
    </atbl-form-group>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import Date from '../../../helpers/Date';

export default {
    components: { DateRangePicker },
    name: 'birthday-picker',
    props: {
        value: {
            type: String,
            default: null,
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        name: {
            type: String,
            default: 'birthdate'
        },
        autoApply: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'DOB'
        },
    },
    data() {
        return {
            opens: 'center',
            locale: {
                direction: 'center',
                format: this.format,
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: moment.weekdaysMin(),
                monthNames: moment.monthsShort(),
                firstDay: 1
            },
            singleDatePicker: true,
            showWeekNumbers: true,
            showRanges: false,
            showDropdowns: true,
        }
    },
    computed: {
        pickerValue: {
            get() {
                let startDate = (
                    !this.value
                        ? moment().subtract(18, "years")
                        : moment(this.value, this.format)
                ).toDate();

                return {
                    startDate,
                    endDate: startDate
                }
            },
            set({ startDate }) {
                const birthdate = Date.format(startDate, this.format);

                this.$emit('input', birthdate);
                this.$emit('update', this.name, birthdate);
            }
        },
        formattedValue() {
            return !!this.value
                ? moment(this.value).format(this.format)
                : null;
        },
    },
}
</script>