var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid manage-account" },
    [
      !_vm.getCurrentUserLoader
        ? _c("div", { staticClass: "manage-account-profile" }, [
            _c("span", [
              _vm._v(
                "\n            " + _vm._s(_vm.getUserShortName) + "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h4", [_vm._v(_vm._s(_vm.getCurrentUser.name))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.getCurrentUser.email))])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("User - Can Update Own Password") && !_vm.getCurrentUserLoader
        ? _c("div", { staticClass: "card card-list manage-account-password" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  class: {
                    disabled: !!_vm.passwordChangeLoader
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onPasswordChangeEvent.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("atbl-input", {
                    attrs: {
                      value: _vm.passwordModel.current_password,
                      errors: _vm.passwordModelErrors,
                      label: "Old password",
                      type: "password",
                      name: "current_password"
                    },
                    on: { update: _vm.onChangePasswordInput }
                  }),
                  _vm._v(" "),
                  _c("atbl-input", {
                    attrs: {
                      value: _vm.passwordModel.password,
                      errors: _vm.passwordModelErrors,
                      label: "New Password",
                      type: "password",
                      name: "password"
                    },
                    on: { update: _vm.onChangePasswordInput }
                  }),
                  _vm._v(" "),
                  _c("atbl-input", {
                    attrs: {
                      value: _vm.passwordModel.password_confirmation,
                      errors: _vm.passwordModelErrors,
                      label: "Confirm Password",
                      type: "password",
                      name: "password_confirmation"
                    },
                    on: { update: _vm.onChangePasswordInput }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    { attrs: { type: "submit" } },
                    [
                      !_vm.passwordChangeLoader
                        ? _c("span", [_vm._v("Save")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: {
                          show: !!_vm.passwordChangeLoader,
                          type: "smaller"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", {
        attrs: { show: !!_vm.getCurrentUserLoader, type: "small" }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-key mr-2" }),
      _vm._v(" Change password\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }