var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "magic-link-redirect-form" },
    [
      _c("atbl-form-group", [
        _c(
          "label",
          { staticClass: "mr-2", attrs: { for: "magic-link-default" } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.redirectOption,
                  expression: "redirectOption"
                }
              ],
              attrs: {
                disabled: !!_vm.disabled,
                type: "radio",
                id: "magic-link-default",
                name: "magic-link-redirect",
                value: "1"
              },
              domProps: { checked: _vm._q(_vm.redirectOption, "1") },
              on: {
                change: function($event) {
                  _vm.redirectOption = "1"
                }
              }
            }),
            _vm._v("\n      Default\n      "),
            _c("span", { staticClass: "badge badge-secondary font-sm ml-2s" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.language) +
                  _vm._s(_vm.defaultRedirect) +
                  "\n      "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("label", { attrs: { for: "magic-link-custom" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.redirectOption,
                expression: "redirectOption"
              }
            ],
            attrs: {
              disabled: !!_vm.disabled,
              type: "radio",
              id: "magic-link-custom",
              name: "magic-link-redirect",
              value: "2"
            },
            domProps: { checked: _vm._q(_vm.redirectOption, "2") },
            on: {
              change: function($event) {
                _vm.redirectOption = "2"
              }
            }
          }),
          _vm._v("\n      Custom\n    ")
        ])
      ]),
      _vm._v(" "),
      _vm.isCustomOptionSelected
        ? _c(
            "div",
            { staticClass: "magic-link-redirect-form__custom-link" },
            [
              !!_vm.language
                ? _c("atbl-input", {
                    attrs: {
                      value: _vm.language,
                      disabled: "",
                      name: "language"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("atbl-input", {
                attrs: { disabled: !!_vm.disabled, name: "redirect" },
                model: {
                  value: _vm.redirect,
                  callback: function($$v) {
                    _vm.redirect = $$v
                  },
                  expression: "redirect"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }