import FreeGamesSettingsModel from './FreeGamesSettingsModel';

export default {
    data() {
        return {
            createUpdateFreeGameSettingsForm: false,
            createUpdateFreeGameSettingsLoader: false,
            createUpdateFreeGameSettingsModel: this.clone(FreeGamesSettingsModel),
            createUpdateFreeGameSettingsModelErrors: {},
        };
    },
    computed: {
        canSave() {
            const { range_type, tickets, fractions } = this.createUpdateFreeGameSettingsModel.free_games_settings;

            if (!range_type) {
                return true;
            }

            if (fractions > 10) {
                return false;
            }

            return !!tickets;
        },
    },
    methods: {
        async saveFreeGameSetting() {
            if (!this.canSave) {
                return window.flash('Setting cannot be saved.');
            }

            let freeGameSettingModel = {
                ...this.createUpdateFreeGameSettingsModel.free_games_settings,
                ...{
                    user_id: this.createUpdateFreeGameSettingsModel.id
                }
            };

            this.createUpdateFreeGameSettingsModelErrors = {};
            this.createUpdateFreeGameSettingsLoader = true;

            try {
                const response = await window.axios.post('/users/free-games-settings', freeGameSettingModel);
                const { status, message } = response.data;

                if (status) {
                    await this.fetchUsers();

                    this.clearCreateUpdateFreeGamesModel();
                    this.toggleCreateUpdateFreeGamesForm(false);
                }

                window.showMessage(message, status);

                this.createUpdateFreeGameSettingsLoader = false;
            } catch (error) {
                this.createUpdateFreeGameSettingsModelErrors = !!error.response.data.errors ? error.response.data.errors : {};
                window.flashError(error);
                this.createUpdateFreeGameSettingsLoader = false;
            }
        },
        updateRangeType(name, value) {
            if (!value) {
                this.updateFreeGameSettingModel('tickets', null);
                this.updateFreeGameSettingModel('lines', null);
                this.updateFreeGameSettingModel('fractions', null);
                this.updateFreeGameSettingModel('shares', null);
            }

            this.updateFreeGameSettingModel(name, value);
        },
        updateFreeGameSettingModel(name, value) {
            Object.assign(this.createUpdateFreeGameSettingsModel.free_games_settings, {
                [name]: !!value ? value : null
            });
        },
        updateFreeGameLines(name, value) {
            Object.assign(this.createUpdateFreeGameSettingsModel.free_games_settings, {
                [name]: !!Number(value) ? Number(value) : null
            });
        },
        onEditFreeGameSetting(item) {
            item = this.clone(item);

            if (!item.free_games_settings) {
                item.free_games_settings = this.clone(FreeGamesSettingsModel.free_games_settings);
            }

            Object.assign(this.createUpdateFreeGameSettingsModel, item);
            this.toggleCreateUpdateFreeGamesForm(true);
        },
        clearCreateUpdateFreeGamesModel() {
            Object.assign(this.createUpdateFreeGameSettingsModel, FreeGamesSettingsModel);
        },
        toggleCreateUpdateFreeGamesForm(payload) {
            this.createUpdateFreeGameSettingsForm = payload;
        },
    },
}