var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _vm._m(1),
          _vm._v(" "),
          !_vm.isLoading && !!_vm.calls.length
            ? _vm._l(_vm.calls, function(item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "badge badge-primary" }, [
                      _vm._v(
                        "\n                            From: " +
                          _vm._s(item.from) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "badge badge-success" }, [
                      _vm._v(
                        "\n                            To: " +
                          _vm._s(item.to) +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "badge badge-primary" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.status) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(!!item.duration ? item.duration : "/") +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.utcToLocal(item.created_at)) +
                        "\n                    "
                    )
                  ])
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.calls, msg: "No calls found." }
              })
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    !!_vm.pagination && !_vm.isLoading && _vm.callsResponse.last_page > 1
      ? _c("div", { staticClass: "card-footer" }, [
          _c(
            "nav",
            { staticClass: "wrap" },
            [
              _c("atbl-pagination", {
                attrs: { pagination: _vm.callsResponse },
                on: { paginate: _vm.fetchCalls }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-phone" }),
      _vm._v(" Last Calls\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row show-first-row" }, [
      _c("div", { staticClass: "col" }, [
        _vm._v("\n                    Number\n                ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _vm._v("\n                    Status\n                ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _vm._v("\n                    Date\n                ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }