var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: {
        label: "Bundle games",
        "label-for": "Bundle games",
        horizontal: false
      }
    },
    [
      _c("tree-select", {
        attrs: {
          multiple: true,
          searchable: true,
          "clear-on-select": true,
          options: _vm.bundles,
          disabled: !!_vm.disabled || !!_vm.isLoading,
          normalizer: function(node) {
            return { id: node.id, label: node.title }
          },
          placeholder: "" + (!!_vm.isLoading ? "Loading..." : "Select options")
        },
        model: {
          value: _vm.selectedBundles,
          callback: function($$v) {
            _vm.selectedBundles = $$v
          },
          expression: "selectedBundles"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }