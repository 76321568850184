export default {
    methods: {
        callPlayer(player) {
            if (this.isObjectEmpty(player)) {
                window.showMessage('Player object cannot be empty', false);

                return;
            }

            const { phone, id } = player;

            this.openCallWindow(phone, id);
        },
        openCallWindow(number, playerId, multipleOpen = false) {
            let numberToCall = this.innerTrim(number.trim()),
                url = `${window.location.protocol}//${window.location.hostname}/call/${numberToCall}/${playerId}`,
                isOpened = localStorage.getItem("WPOpened");

            if(multipleOpen){
                this.openWindow(url);
            }else {
                if(!isOpened && isOpened !== "true"){
                    this.winRef = this.openWindow(url);
                    localStorage.setItem("WPOpened", "true");
                } else {
                    this.winRef.location.href = url;
                    this.winRef.focus();
                }
            }
        },
        callWindowClose: function(e) {
            e.preventDefault();
            localStorage.removeItem("WPOpened");
        },
        openWindow: function(url){
            return window.open(url, '_blank', `location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,height=${window.innerHeight},width=450,top=5`);
        }
    },
    created() {
        window.addEventListener('beforeunload', this.callWindowClose)
    },
};