<template>
    <form class="campaign-add-leads-filter-form"
          @submit.prevent="fetchLeadsOutOfCampaign(campaignId)"
    >
        <div class="campaign-add-leads-filter-form-header">
            <span><i class="fa fa-search" aria-hidden="false"></i> Search</span>
        </div>
        <div class="campaign-add-leads-filter-form-body">
            <div class="campaign-add-leads-filter-form-body-holder">
                <div>
                    <div class="already-in-campaign-checkbox">
                        <input id="leadsFilterAlreadyInCampaign"
                               type="checkbox"
                               v-model="changeInCampaign"
                               class="form-control in-campaign-checkbox"
                        />
                        <label for="leadsFilterAlreadyInCampaign" class="col-form-label ml-2 font-weight-bold">Already in campaign</label>
                    </div>
                </div>
                <div>
                    <atbl-input :value="model.name"
                                label="Name"
                                placeholder="Enter name"
                                class="mb-0"
                                @update="changeLeadsOutOfCampaignSearchModel"
                    />
                </div>
                <div>
                    <atbl-input :value="model.surname"
                                name="surname"
                                label="Surname"
                                placeholder="Enter surname"
                                class="mb-0"
                                @update="changeLeadsOutOfCampaignSearchModel"
                    />
                </div>
                <div>
                    <atbl-input :value="model.email"
                                name="email"
                                label="Email"
                                placeholder="Enter email"
                                class="mb-0"
                                @update="changeLeadsOutOfCampaignSearchModel"
                    />
                </div>
                <div>
                    <countries-options :value="model.country"
                                       class="mb-0"
                                       @update="changeLeadsOutOfCampaignSearchModel"
                    />
                </div>
                <div>
                    <atbl-input :value="model.phone"
                                name="phone"
                                label="Phone"
                                placeholder="Enter phone"
                                class="mb-0"
                                @update="changeLeadsOutOfCampaignSearchModel"
                    />
                </div>
                <div>
                    <balance-select v-model="balanceSelect"
                                    :balance-types="false"
                                    :error-message="errors.balanceSelector"
                                    label="Live Spent"
                    />
                </div>
                <div>
                    <batch-files-picker :value="model.batch"
                                        :multiple="true"
                                        class="mb-0"
                                        @update="changeLeadsOutOfCampaignSearchModel"
                    />
                </div>
                <div>
                    <div>
                        <customer-status-picker :value="model.status"
                                                :include-no-status="true"
                                                :is-lead="!!isLeadCampaign()"
                                                class="mb-0"
                                                @update="changeLeadsOutOfCampaignSearchModel"
                        />
                    </div>
                    <div>
                        <atbl-form-group label="Status Date"
                                         label-for="statusDate"
                                         class="font-weight-bold mb-0"
                        >
                            <date-picker v-model="selectStatusDate"
                                         id="statusDate"
                                         format="YYYY-MM-DD"
                            />
                        </atbl-form-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="campaign-add-leads-filter-form-footer">
            <button :disabled="!isSearchEnabled"
                    type="submit"
                    size="sm"
                    class="btn btn-sm btn-primary"
            >
                <i class="fa fa-search"></i> Search
            </button>
            <button type="reset"
                    size="sm"
                    class="btn btn-sm btn-danger"
                    @click="onClearFilterFormEvent"
            >
                <i class="fa fa-ban"></i> Clear
            </button>
        </div>
    </form>
</template>

<script>
import CountriesOptions from '../../../../../../components/Forms/Select/CountriesOptions';
import BatchFilesPicker from '../../../../_Components/BatchFiles/BatchFilesPicker';
import CustomerStatusPicker from '../../../../_Components/CustomerStatus/CustomerStatusPicker';
import CustomersOutOfCampaignSearchModel from '../CustomersOutOfCampaign/CustomersOutOfCampaignSearchModel';
import { isObjectEmpty } from '../../../../../../utils/Object/Object';
import removeUndefinedProperties from '../../../../../../utils/Object/RemoveUndefinedProperties';

export default {
    name: 'leads-filter-form-component',
    inject: [
        'campaignId',
        'isLeadCampaign',
        'fetchLeadsOutOfCampaign',
        'changeLeadsOutOfCampaignSearchModel',
        'clearLeadsOutOfCampaignModel',
        'changeInCampaignProperty',
    ],
    components: {
        CustomerStatusPicker,
        BatchFilesPicker,
        CountriesOptions,
    },
    props: {
        model: {
            type: Object,
            default: () => (CustomersOutOfCampaignSearchModel)
        },
        inCampaign: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            errors: {
                balanceSelector: null,
                balanceSelectorValidatorMessage: null,
            },
        };
    },
    computed: {
        selectStatusDate: {
            get() {
                return this.model.statusDate;
            },
            set(value) {
                this.changeLeadsOutOfCampaignSearchModel('statusDate', value);
            }
        },
        balanceSelect: {
            get() {
                return {
                    balance: this.model.balance,
                    balanceSelector: this.model.balanceSelector
                };
            },
            set(value) {
                if (isObjectEmpty(value)) {
                    return;
                }

                const {
                    balance,
                    balanceSelector,
                    errorMessage,
                } = value;

                this.changeLeadsOutOfCampaignSearchModel('balance', balance);
                this.changeLeadsOutOfCampaignSearchModel('balanceSelector', balanceSelector);

                this.errors = {
                    balanceSelector: null,
                    balanceSelectorValidatorMessage: errorMessage,
                };
            }
        },
        changeInCampaign: {
            get() {
                return this.inCampaign;
            },
            set(value) {
                this.changeInCampaignProperty(value);
            }
        },
        isSearchEnabled() {
            const invalidInputs = removeUndefinedProperties(this.errors);

            return !this.validateInputs() && !!isObjectEmpty(invalidInputs);
        },
    },
    methods: {
        async onClearFilterFormEvent() {
            this.clearLeadsOutOfCampaignModel();
            await this.fetchLeadsOutOfCampaign(this.campaignId);
        },
        validateInputs() {
            const { status: balanceValidationStatus, message: balanceValidationMessage } = this.validateBalance();

            this.errors = {
                balanceSelector: balanceValidationMessage
            };

            return !balanceValidationStatus;
        },
        validateBalance() {
            const {
                balanceSelector,
                balance,
            } = this.model;

            if (!balance && !balanceSelector) {
                return {
                    status: true,
                    message: null
                };
            }

            const validatorMessage = !!this.errors.balanceSelectorValidatorMessage
                ? this.errors.balanceSelectorValidatorMessage
                : null;

            const isBalanceValid = !!balance && !!balanceSelector;

            return {
                status: isBalanceValid,
                message: !!isBalanceValid
                    ? validatorMessage
                    : 'Balance values are invalid, no filter will be applied.'
            };
        },
    },
}
</script>