<script>
    export default {
        defaultSalesUrl: "/reports/sales/sale",
        getSalesDays: function(){
            return `${this.defaultSalesUrl}/days/show`;
        },
        getSalesReports: function(_params){
            return window.axios.get(this.defaultSalesUrl, {
                params: _params
            });
        },
        getSalesDaysReports: function(_params){
            return window.axios.get(this.getSalesDays(), {
                params: _params
            });
        }
    }
</script>