var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid ticket-results" },
    [
      !_vm.isLoading
        ? _c("syndicate-ticket-info", {
            attrs: { "syndicate-share": _vm.syndicateShare }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("syndicate-ticket-lines", {
            attrs: {
              "syndicate-share": _vm.syndicateShare,
              "game-number-configuration": _vm.gameNumberConfiguration
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }