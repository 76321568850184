<template>
    <ul class="pagination wrap m-0">
        <li>
            <button aria-label="First" v-on:click.prevent="changePage(1)" :disabled="pagination.current_page === 1" class="pagination-button" :class="{'disabled': pagination.current_page === 1, 'small': small}">
                First
            </button>
        </li>
        <li>
            <button aria-label="Prev" v-on:click.prevent="changePage(pagination.current_page - 1)" :disabled="pagination.current_page === 1" class="pagination-button" :class="{'disabled': pagination.current_page === 1, 'small': small}">
                <span v-if="!small">Prev</span>
                <i v-else class="fa fa-chevron-left" aria-hidden="false"></i>
            </button>
        </li>
        <li v-for="pageNumber in pagesNumber" :class="{'active': pageNumber == pagination.current_page}">
            <a href="javascript:void(0)" v-on:click.prevent="changePage(pageNumber)" class="pagination-button" :class="{'small': small}">{{ pageNumber }}</a>
        </li>
        <li>
            <button aria-label="Next" v-on:click.prevent="changePage(pagination.current_page + 1)" :disabled="pagination.current_page === pagination.last_page" class="pagination-button" :class="{'disabled': pagination.current_page === pagination.last_page, 'small': small}">
                <span v-if="!small">Next</span>
                <i v-else class="fa fa-chevron-right" aria-hidden="false"></i>
            </button>
        </li>
        <li>
            <button aria-label="Last" v-on:click.prevent="changePage(pagination.last_page)" :disabled="pagination.current_page === pagination.last_page" class="pagination-button" :class="{'disabled': pagination.current_page === pagination.last_page, 'small': small}">
                Last
            </button>
        </li>
    </ul>
</template>

<script>
    export default{
        name: "atbl-pagination",
        props: {
            pagination: {
                type: Object,
                required: true
            },
            small: {
                type: Boolean,
                default: false
            },
            scroll: {
                type: Boolean,
                default: true
            }
        },
        date(){
            return {
                pagesLimit: 10
            };
        },
        computed: {
            pagesNumber() {
                return this.range(this.getPaginationConfig());
            }
        },
        methods : {
            changePage(page) {
                if(this.scroll){
                    window.scrollTo(0,0);
                }
                this.$emit('paginate', page);
            },
            getPaginationConfig(){
                let pagesLimit = 10,
                    lastPage = this.pagination.last_page,
                    currentPage = this.pagination.current_page,
                    start = 0,
                    end = 0;

                if (lastPage <= pagesLimit) {
                    start = 1;
                    end = lastPage;
                } else {
                    let maxPagesBeforeCurrentPage = Math.floor(pagesLimit / 2);
                    let maxPagesAfterCurrentPage = Math.ceil(pagesLimit / 2) - 1;
                    if (currentPage <= maxPagesBeforeCurrentPage) {
                        start = 1;
                        end = pagesLimit;
                    } else if (currentPage + maxPagesAfterCurrentPage >= lastPage) {
                        start = lastPage - pagesLimit + 1;
                        end = lastPage;
                    } else {
                        start = currentPage - maxPagesBeforeCurrentPage;
                        end = currentPage + maxPagesAfterCurrentPage;
                    }
                }

                return {
                    start: start,
                    end: end
                };
            },
            range(config){
                let array = [],
                    j = 0;
                for(let i = config.start; i <= config.end; i++){
                    array[j] = i;
                    j++;
                }
                return array;
            }
        }
    }
</script>

<style scoped lang="scss">
    .pagination {
        margin: 1rem 0;

        &-button {
            position: relative;
            display: block;
            padding: 0.5rem 0.75rem;
            margin-left: -1px;
            line-height: 1.25;
            color: #20a8d8;
            background-color: #fff;
            border: 1px solid #a4b7c1;
            transition: all .4s;

            &:hover,
            &:focus {
                z-index: 2;
                color: #167495;
                text-decoration: none;
                background-color: #c2cfd6;
                border-color: #a4b7c1;
            }

            &[disabled] {
                opacity: .5;
                cursor: no-drop;
            }

            &.small {
                padding: 5px 10px;
                line-height: 1.5;
            }
        }
    }
</style>