import { isObjectEmpty } from '../../utils/Object/Object';
import Download from '../../modules/download/Download';
import moment from 'moment';

export default {
    provide() {
        return {
            onExportPlayersCsv: this.onExportPlayersCsv,
        };
    },
    data() {
        return {
            isExportLoading: false,
        };
    },
    methods: {
        async onExportPlayersCsv(params) {
            if (isObjectEmpty(params)) {
                return window.showMessage('Object model cannot be empty.', false);
            }

            try {
                const response = await window.axios.get(`/players/export`,
                    {
                        params,
                        responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': 'application/json',
                            'Content-Disposition': 'attachment; filename="players.csv"'
                        }
                    });

                Download.downloadFile(new Blob([response.data]), `players-${moment().format('YYYY-MM-DD HH-mm-ss')}.csv`);

                this.isExportLoading = false;
            } catch (error) {
                this.isExportLoading = false;

                window.flashError(error);
            }
        }
    },
}