var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.playerLoader && _vm.canViewPlayer
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-4 col-lg-3" },
              [_c("player-nav", { attrs: { id: _vm.id } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-8 col-lg-9 pl-lg-0" },
              [
                _vm.isPlayerBlocked
                  ? _c(
                      "div",
                      { staticClass: "card bg-danger text-white mb-3" },
                      [
                        _c("div", { staticClass: "card-header p-2" }, [
                          _vm._v(
                            "\n                        Player is blocked\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-body p-2" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _c("strong", [_vm._v("Blocked by:")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.player.block.blocked_by_name) +
                                "\n                            - " +
                                _vm._s(
                                  _vm.utcToLocal(_vm.player.block.created_at)
                                ) +
                                "\n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Reason:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.player.block.reason) +
                              "\n                    "
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("player-info", { attrs: { id: _vm.id } }),
                _vm._v(" "),
                _vm.$slots.first ? _vm._t("first") : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card card-list", class: _vm.cardClass },
                  [
                    _vm.header
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "card-header d-flex justify-content-between align-items-center"
                          },
                          [
                            !!_vm.$slots.header
                              ? [_vm._t("header")]
                              : _c(
                                  "span",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("i", {
                                      staticClass: "mr-1",
                                      class: _vm.icon
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.title) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                            _vm._v(" "),
                            _vm._t("rightSide")
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body", class: _vm.bodyClass },
                      [_vm._t("default")],
                      2
                    ),
                    _vm._v(" "),
                    _vm.$slots.footer
                      ? _c(
                          "div",
                          { staticClass: "card-footer" },
                          [_vm._t("footer")],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.$slots.last ? _vm._t("last") : _vm._e()
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.playerLoader && !_vm.canViewPlayer
        ? _c("blocked-sign", { attrs: { message: "This player is blocked." } })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.playerLoader, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }