<template>
    <div class="container-fluid">
        <campaign-list-view-search-component v-show="listViewSearchFormOpen"
                                             :list-view-search-model="listViewSearchModel"
                                             @search="onSearchLeadsEvent"
        />

        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                    <a :href="`/marketing/campaigns/${type}`"
                       class="mr-2 btn btn-primary btn-sm m-0"
                    >
                        <i class="nav-icon fa fa-chevron-left" aria-hidden="false"></i>
                    </a>
                    <i class="fa fa-users mr-2" aria-hidden="false"></i> {{ ucFirst(campaignTitle) }} list
                </div>
                <div>
                    <button class="btn btn-sm btn-warning"
                            @click="fetchCampaignLeadsListView(listViewSearchModel)"
                    >
                        <i class="fa fa-sync"></i>
                    </button>
                    <button :class="`${listViewSearchFormOpen ? 'btn-outline-primary': 'btn-primary'}`"
                            class="btn btn-sm"
                            @click="toggleListViewSearchForm"
                    >
                        <i class="fa fa-filter" aria-hidden="false"></i>
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row d-none d-lg-flex">
                        <div class="col-12 col-lg-3 align-self-center mb-2 mb-lg-0">
                            <sort-by-component :name="`${type}s.name`"
                                               :sort="sortBy"
                                               label="Name"
                                               @update="updateSort"
                            />
                        </div>
                        <div class="col">
                            Email
                        </div>
                        <div class="col">Phone</div>
                        <div class="col">
                            <sort-by-component :name="`${type}s.country`"
                                               :sort="sortBy"
                                               label="Country"
                                               @update="updateSort"
                            />
                        </div>
                        <div class="col">
                            <sort-by-component :name="`${isLeadCampaign() ? 'leads' : 'player_statistics'}.live_spent`"
                                               :sort="sortBy"
                                               label="Live Spent"
                                               @update="updateSort"
                            />
                        </div>
                        <div class="col">
                            <sort-by-component :name="`${isLeadCampaign() ? 'leads_statuses.name' : 'dispositions.name'}`"
                                               :sort="sortBy"
                                               label="Last Disposition"
                                               @update="updateSort"
                            />
                        </div>
                        <div class="col-lg-1">Action</div>
                    </div>

                    <template v-if="!leadsListViewLoader">
                        <div v-for="(item, index) in leadsList"
                             :key="index"
                             class="row leads-section-list-row"
                        >
                            <div class="col-12 col-lg-3 align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Name</span>
                                <b v-tooltip="{
                                       'content': item.last_note ? item.last_note : 'No notes yet.'
                                   }"
                                >
                                    {{ item.full_name }}
                                </b>
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Email</span>
                                {{ emptyField(item.customer_email) }}
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Country</span>
                                <div v-if="item.customer_phone">{{ item.customer_phone }}</div>
                                <div v-if="item.customer_mobile">{{ item.customer_mobile }}</div>
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Country</span>
                                {{ emptyField(item.customer_country) }}
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Live Spent</span>
                                <span v-if="item.customer_live_spent !== null && item.live_spent === 0">
                                    {{ item.customer_live_spent }}
                                </span>
                                <span v-else-if="item.customer_live_spent > 0">
                                    {{ formatCurrency(item.customer_live_spent) }}
                                </span>
                                <span v-else>
                                    /
                                </span>
                            </div>
                            <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Last Disposition</span>
                                <div v-if="!!item.customer_status_name && !!item.customer_status_created_at">
                                    <span :class="{
                                             'badge-primary': item.customer_status_name !== $options.Campaigns.SaleStatusName,
                                             'badge-success': item.customer_status_name === $options.Campaigns.SaleStatusName,
                                          }"
                                          class="badge"
                                    >
                                        {{ item.customer_status_name }}
                                    </span>
                                    <br />
                                    <small>
                                        {{ utcToLocal(item.customer_status_created_at) }}
                                    </small>
                                </div>
                                <span v-else>/</span>
                            </div>
                            <div class="col-12 col-lg-1 align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Action</span>
                                <a :href="`/marketing/campaigns/${type}/${id}/call-view/${item.customer_id}`"
                                   class="btn btn-primary btn-sm"
                                   target="_blank"
                                >
                                    Call
                                </a>
                            </div>
                        </div>
                    </template>

                    <loader :show="leadsListViewLoader"
                            type="small"
                    />

                    <not-found v-if="!leadsListViewLoader"
                               :items="leadsList"
                               :msg="`No ${campaignTitle} added to campaign.`"
                    />

                    <nav class="wrap py-3" v-if="!leadsListViewLoader && leadsListResponse.last_page > 1">
                        <atbl-pagination class="m-0"
                                         :pagination="leadsListResponse"
                                         @paginate="fetchCampaignLeadsListView(listViewSearchModel, $event)"
                        />
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CampaignListViewSearchComponent from './CampaignListViewSearch/CampaignListViewSearchComponent';
import SortByComponent from '../../../../Players/Sections/Components/SortByComponent';
import { clone } from '../../../../../utils/Json/Json';
import MarketingService from '../../../../../services/MarketingService';
import { isObjectEmpty } from '../../../../../utils/Object/Object';
import CampaignListViewSearch from './CampaignListViewSearch/CampaignListViewSearch';
import PaginationModel from '../../../../../modules/pagination/PaginationModel';
import Campaign from '../../Campaign';
import ucFirst from '../../../../../utils/String/UcFirst';
import emptyField from '../../../../../filters/EmptyField';
import formatCurrency from '../../../../../filters/FormatCurrency';
import Campaigns from '../../../../../constants/Campaigns';

export default {
    name: 'campaigns-list-view',
    Campaigns,
    mixins: [
        Campaign,
        CampaignListViewSearch,
    ],
    components: {
        CampaignListViewSearchComponent,
        SortByComponent,
    },
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            leadsListViewLoader: false,
            leadsListResponse: clone(PaginationModel),
        };
    },
    computed: {
        leadsList() {
            return this.leadsListResponse.data;
        },
        sortBy() {
            return this.listViewSearchModel.sort;
        },
    },
    async created() {
        await this.fetchCampaignLeadsListView();
    },
    methods: {
        ucFirst,
        emptyField,
        formatCurrency,
        async fetchCampaignLeadsListView(model, page = 1) {
            if (!this.$can('Campaigns - Get By Id')) {
                return;
            }

            this.leadsListViewLoader = true;

            try {
                const response = await MarketingService.getListViewLeads(
                    this.type,
                    this.id,
                    {
                        ...model,
                        page
                    }
                );

                this.leadsListResponse = response.data;

                this.leadsListViewLoader = false;
            } catch (error) {
                this.leadsListViewLoader = false;

                window.flashError(error);
            }

        },
        async onSearchLeadsEvent(model) {
            if (isObjectEmpty(model)) {
                return;
            }

            await this.fetchCampaignLeadsListView(model)
        },
        async updateSort(value) {
            const sort = clone(this.sortBy);

            sort.sort = value;

            if (!sort) {
                sort.value = 'ASC';
            } else {
                sort.value = sort.value === 'ASC' ? 'DESC' : 'ASC';
            }

            this.updateListViewSearchFormInput('sort', sort);

            await this.fetchCampaignLeadsListView(this.listViewSearchModel);
        }
    },
}
</script>