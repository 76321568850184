var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: {
        "header-class": "d-flex justify-content-start align-items-center p-0",
        "body-class": "d-flex justify-content-center align-items-center"
      }
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          {
            staticClass:
              "text-uppercase d-flex align-items-center justify-content-between w-100"
          },
          [
            _c("i", {
              staticClass: "card-icon p-2",
              class: [_vm.icon, "bg-" + _vm.color]
            }),
            _vm._v(" "),
            _c("span", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(" ")])
          ]
        )
      ]),
      _vm._v(" "),
      _c("h5", { staticClass: "m-0", class: "text-" + _vm.color }, [
        _vm._v("\n        " + _vm._s(_vm.number) + "\n    ")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }