<template>
    <div class="alert alert-flash" :class="type" role="alert" v-show="show">
        <strong>Message:</strong> <span v-html="body"></span>
    </div>
</template>

<script>
    export default {
        props: [
            'message',
            'alert'
        ],
        data() {
            return {
                body: '',
                show: false,
                type: ''
            }
        },
        created() {
            if (this.message) {
                this.flash(this.message);
            }
            window.Atbl.$on(
                'flash', (message, alert) => this.flash(message, alert)
            );
        },
        methods: {
            flash(message, alert = 'alert-success') {
                this.body = message;
                this.show = true;
                this.type = alert;

                this.hide();
            },
            hide() {
                setTimeout(() => {
                    this.show = false;
                }, 3500);
            }
        }
    };
</script>

<style>
    .alert-flash {
        position: fixed;
        right: 25px;
        bottom: 45px;
        z-index: 9999;
    }
</style>