<template>
    <div class="container-fluid">
        <search-free-game-form :show-search="showSearch"
                               :model="searchModel"
        />

        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                    <i class="fa fa-gamepad mr-1"></i> Free Games
                </div>
                <div>
                    <a href="#"
                       class="btn btn-outline-primary btn-sm"
                       @click="showSearch = true"
                    >
                        <i class="fa fa-filter"></i>
                    </a>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-1 text-center text-lg-left">ID</div>
                        <div class="col-12 col-lg-2 text-center">Created By</div>
                        <div class="col-12 col-lg-2 text-center">Player</div>
                        <div class="col-12 col-lg text-center">Game</div>
                        <div class="col-12 col-lg-2 text-center">Is Used</div>
                        <div class="col-12 col-lg-2 text-center">Operations</div>
                    </div>

                    <template v-if="!isLoading && !!freeGames.length">
                        <div v-for="(item, index) in freeGames"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-lg-1 text-center text-lg-left mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">ID</span>
                                {{ item.id }}
                            </div>
                            <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Created By</span>
                                <span v-if="isset(item, 'user') && item.user">{{ item.user.name }}</span>
                                <span v-else
                                         class="badge badge-danger"
                                >
                                    System
                                </span>
                            </div>
                            <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Player</span>
                                <a :href="`/players/${item.player.id}/view`" target="_blank">
                                    {{ item.player.name }} {{ item.player.surname }}
                                </a>
                            </div>
                            <div class="col-12 col-lg text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Game</span>
                                {{ item.gameable.name ? item.gameable.name : item.gameable.title }}
                            </div>
                            <div class="col-12 col-lg-2 text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge m-0">Is Used</span>
                                <i class="fa" :class="{
                                    'fa-check text-success': item.is_used,
                                    'fa-times text-danger': !item.is_used
                                }"></i>
                            </div>
                            <div class="col-12 col-lg-2 text-center">
                                <span class="small-mb-badge m-0">Operations</span>
                                <div class="d-flex align-items-center justify-content-center">
                                    <button class="btn btn-link btn-delete btn-sm mr-2"
                                            @click="previewFreeGame(item)"
                                    >
                                        <i class="fa fa-eye"></i>
                                    </button>
                                    <confirmable-remove-button v-if="$can('Free Games - Delete') && !item.number_of_tickets_used"
                                                               :value="item.id"
                                                               @confirmed="removeFreeGame"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />
                    <not-found v-if="!isLoading"
                               msg="No free games found found."
                               :items="freeGames"
                    />
                </div>
            </div>

            <div v-if="!isLoading && freeGamesData.last_page > 1"
                 class="card-footer"
            >
                <nav class="wrap">
                    <atbl-pagination class="m-0"
                                     :pagination="freeGamesData"
                                     @paginate="fetchFreeGames"
                    />
                </nav>
            </div>
        </div>

        <preview-free-game-sidebar :data="freeGamePreviewData"
                                   :is-delete-pressed="isDeletePressed"
                                   :is-loading="isLoading"
        />
    </div>
</template>

<script>
import PreviewFreeGameSidebar from './PreviewFreeGame/PreviewFreeGameSidebar';
import SearchFreeGameForm from './SearchFreeGame/SearchFreeGameForm';
import PreviewFreeGame from './PreviewFreeGame/PreviewFreeGame';
import SearchFreeGame from './SearchFreeGame/SearchFreeGame';

export default {
    name: 'FreeGames',
    mixins: [
        PreviewFreeGame,
        SearchFreeGame,
    ],
    components: {
        PreviewFreeGameSidebar,
        SearchFreeGameForm,
    },
    provide() {
        return {
            // main
            fetchFreeGames: this.fetchFreeGames,
            // PreviewFreeGame
            togglePreviewDelete: this.togglePreviewDelete,
            previewFreeGame: this.previewFreeGame,
            removeFreeGame: this.removeFreeGame,
            // SearchFreeGame
            clearSearchForm: this.clearSearchForm,
            changeSearchModel: this.changeSearchModel,
            usedOptions: this.usedOptions
        };
    },
    data() {
        return {
            freeGames: [],
            freeGamesData: {},
            isLoading: false,
        };
    },
    methods: {
        async fetchFreeGames(page = 1) {
            this.isLoading = true;

            try {
                const params = {
                    ...this.searchModel,
                    page
                };

                const response = await window.axios.get('/games/free/list', {
                    params
                });

                this.freeGamesData = response.data;
                this.freeGames = this.freeGamesData.data;

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);

                this.isLoading = false;
            }
        },
        async removeFreeGame(freeGameId){
            if (!freeGameId) {
                return window.showMessage('Free game id is undefined.', false);
            }

            this.isLoading = true;

            try {
                const response = await window.axios.delete(`/games/free/${freeGameId}`, {
                    params: {
                        freeGameId
                    }
                });

                const { status, message } = response.data;

                if (status) {
                    this.freeGamePreviewData = {};
                    this.isDeletePressed = false;
                    await this.fetchFreeGames();
                }

                window.showMessage(message, status);

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);
                this.isLoading = false;
            }
        }
    },
    created() {
        this.fetchFreeGames();
    }
}
</script>