<template>
    <div class="player-play-picker-lines">
        <div class="line-control" v-if="! noLineControl">
            <div>
                <button class="add-line"
                        @click="addNewLine(1)"
                        :disabled="!shouldAddMoreLines || hasSelectedFreeGame"
                >
                <span class="add-line-text">
                    <i class="fa fa-plus" aria-hidden="false"></i> Add Line
                </span>
                </button>
                <button class="add-line"
                        @click="addNewLine(5)"
                        :disabled="!shouldAddMoreLines || hasSelectedFreeGame"
                >
                <span class="add-line-text">
                    <i class="fa fa-plus" aria-hidden="false"></i> Add 5 Lines
                </span>
                </button>
                <button class="add-line"
                        @click="addNewLine(10)"
                        :disabled="!shouldAddMoreLines || hasSelectedFreeGame"
                >
                <span class="add-line-text">
                    <i class="fa fa-plus" aria-hidden="false"></i> Add 10 Lines
                </span>
                </button>
                <span v-if="hasSelectedFreeGame"
                      class="text-danger"
                >
                    {{ selectedFreeGame.meta.lines }} Free Line{{ selectedFreeGame.meta.lines > 1 ? 's' : '' }}
                </span>
                <span v-else>
                    <span v-if="shouldAddMoreLines"
                          class="text-danger"
                    >
                        ({{ currentLinesNumber }} / {{ maxLinesPerTicket }})
                    </span>
                    <span v-else
                          class="add-line-message"
                    >
                        Max lines reach.
                    </span>
                </span>
            </div>
            <div v-if="!hasSelectedFreeGame && currentLinesNumber > 1">
                <a v-if="!removeAllConfirm"
                   href="#"
                   class="remove-all-lines"
                   @click.prevent="removeAllConfirm = true"
                >
                    <i class="fa fa-trash"></i> Remove All
                </a>
                <a v-if="removeAllConfirm"
                   href="#"
                   class="remove-all-confirm"
                   @click.prevent="onRemoveAllLinesEvent"
                >
                    <i class="fa fa-check"></i>
                </a>
                <a v-if="removeAllConfirm"
                   href="#"
                   class="remove-all-cancel"
                   @click.prevent="removeAllConfirm = false"
                >
                    <i class="fa fa-times"></i>
                </a>
            </div>
        </div>
        <div class="line-message" v-if="errorMessage.length > 0">{{ errorMessage }}</div>
        <div v-for="(item, index) in lines"
             class="player-play-picker-lines-line"
             :class="{
                'invalid': item.error.invalid
             }"
             :key="index"
        >
            <span class="generated-number"># {{ index + 1 }}</span>
            <button class="lucky-dip"
                    @click="luckyDip(index)"
            >
                Lucky Dip
            </button>

            <div v-for="(numberItem, numberIndex) in item.numbers"
                 class="player-play-picker-lines-line-numbers"
                 :class="{
                    'disabled': item.random
                 }"
                 :key="numberIndex"
                 v-tooltip="{
                    content: ucFirst(numberIndex)
                 }"
            >
                <input type="text"
                       v-for="(singleNumItem, singleNumIndex) in getNumberTypeConfiguration(numberIndex).numbers"
                       :key="singleNumIndex"
                       :value="getNumberFromLine(index, numberIndex, singleNumIndex)"
                       @keyup="changeLineNumber(index, numberIndex, singleNumIndex, $event)"
                       class="form-control"
                       :class="{
                           'invalid': validateInput(index, numberIndex, singleNumIndex)
                       }"
                       :disabled="item.random"
                />
            </div>

            <input type="checkbox"
                   :checked="item.random"
                   v-tooltip="{
                       content: 'Random'
                   }"
                   @change="updateRandom(index, $event)"
            />

            <button v-if="lines.length > 1 && !hasSelectedFreeGame && !noLineControl"
                    class="remove-line"
                    @click="removeLine(index, $event)"
            >
                <i class="fa fa-times" aria-hidden="false"></i>
            </button>
        </div>
    </div>
</template>

<script>
import LinesPickerMixin from '../Mixins/LinesPicker';

export default {
    name: 'LinesPicker',
    mixins: [
        LinesPickerMixin
    ],
    props: {
        lines: {
            type: Array,
            required: true
        },
        game: {
            type: Object,
            required: true
        },
        selectedFreeGame: {
            type: Object,
            default: () => ({})
        },
        noLineControl: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            errorMessage: '',
            removeAllConfirm: false,
        };
    },
    computed: {
        minLinesPerTicket() {
            return this.game.linesPerTicket[0];
        },
        maxLinesPerTicket() {
            return this.game.linesPerTicket[this.game.linesPerTicket.length - 1];
        },
        shouldAddMoreLines() {
            return this.lines.length < this.maxLinesPerTicket;
        },
        currentLinesNumber() {
            return this.getLines().length;
        },
        hasSelectedFreeGame() {
            return !this.isObjectEmpty(this.selectedFreeGame);
        }
    },
    methods: {
        getLines: function () {
            return JSON.parse(JSON.stringify(this.lines));
        },
        getLineByIndex: function (lineIndex) {
            return this.getLines().filter((item, index) => index === lineIndex)[0];
        },
        getNumberFromLine: function (lineIndex, type, numberIndex) {
            let lineNumbers = this.getLineByIndex(lineIndex).numbers[type],
                numbers = lineNumbers[numberIndex];

            if (Array.isArray(lineNumbers)) {
                return numbers ? numbers : '';
            }
            return lineNumbers;
        },
        addNewLine: function (requiredLines = 1) {
            let localLines = this.getLines(),
                nextPossibleLines = localLines.length + requiredLines,
                numberOfLines = (nextPossibleLines > this.maxLinesPerTicket
                    ? this.maxLinesPerTicket - nextPossibleLines
                    : nextPossibleLines) - localLines.length;

            if ((localLines.length + numberOfLines) > this.maxLinesPerTicket) {
                return;
            }

            for (let i = 0; i < numberOfLines; i++) {
                localLines = [
                    ...localLines,
                    this.createLine()
                ];
            }

            this.updateErrorMessage();
            this.updateLines(localLines);
            setTimeout(() => {
                this.$scrollToBottom();
            }, 1);
        },
        updateRandom: function (lineIndex, event) {
            let localLines = this.getLines(),
                getLine = this.getLineByIndex(lineIndex),
                isChecked = event.target.checked;

            if (getLine) {
                if (isChecked) {
                    localLines[lineIndex] = this.createLine();
                    this.updateErrorMessage();
                } else {
                    getLine.random = isChecked;
                    getLine.error = {
                        invalid: true,
                        error: [
                            this.createErrorMessage(lineIndex, 'Pick line numbers')
                        ]
                    };
                    localLines[lineIndex] = getLine;
                }

                this.updateLines(localLines);
            }
        },
        luckyDip: function (lineIndex) {
            let localLines = this.getLines(),
                getLine = this.getLineByIndex(lineIndex);

            if (getLine) {
                for (let lineNumber in getLine.numbers) {
                    let numbersConfiguration = this.getNumberTypeConfiguration(lineNumber),
                        generateNumbers = this.generateRandomNumbers(
                            numbersConfiguration.maxRange,
                            numbersConfiguration.numbers
                        );

                    getLine.random = false;
                    getLine.numbers[lineNumber] = generateNumbers;
                }

                getLine.random = false;
                getLine.error = this.validateLine(getLine, lineIndex);
                localLines[lineIndex] = getLine;

                this.updateLines(localLines);

                this.updateErrorMessage();
            }
        },
        changeLineNumber: function (lineIndex, type, numberIndex, $event) {
            let numberValue = $event.target.value.replace(/\D/g, ''),
                getNumberValue = numberValue ? numberValue.substr(0, 2) : '',
                value = getNumberValue ? Number(getNumberValue) : '',
                localLines = this.getLines(),
                getLine = this.getLineByIndex(lineIndex),
                numbersConfiguration = this.getNumberTypeConfiguration(type),
                code = ($event.keyCode ? $event.keyCode : $event.which);

            switch (code) {
                case 38:
                    value++;
                    break;
                case 40:
                    value--;
                    break;
            }

            if (getLine) {
                if (numbersConfiguration.numbers > 1) {
                    getLine.numbers[type][numberIndex] = value;
                } else {
                    getLine.numbers[type] = value;
                }

                getLine.error = this.validateLine(getLine, lineIndex);

                localLines[lineIndex] = getLine;
                this.updateLines(localLines);
            }
        },
        removeLine: function (lineIndex, $event = null) {
            let localLines = this.getLines(),
                removedLine = localLines.filter((item, index) => index !== lineIndex);

            if (removedLine.length >= this.maxLinesPerTicket) {
                this.updateLines(removedLine);
            } else {
                let restLinesToCreate = this.minLinesPerTicket - removedLine.length;
                this.updateLines(
                    [
                        ...removedLine,
                        ...this.createMassLines(restLinesToCreate)
                    ]
                );
            }
        },
        onRemoveAllLinesEvent() {
            this.updateLines([
                this.createLine()
            ]);
            this.removeAllConfirm = false;
        },
        updateLines: function (lines) {
            this.$emit('updateLines', lines);
        },
        validateLine: function (line, lineIndex) {
            let numbers = line.numbers,
                invalidMessages = [],
                invalidTypes = [];

            if (!line.random) {
                for (let type in numbers) {
                    let currentNumbers = numbers[type],
                        numbersConfiguration = this.getNumberTypeConfiguration(type);

                    if (numbersConfiguration.numbers > 1) {
                        let checkInvalidNumbers = currentNumbers.filter(item => item < numbersConfiguration.minRange || item > numbersConfiguration.maxRange),
                            checkDuplicateNumbers = this.hasDuplicates(currentNumbers);

                        if (checkInvalidNumbers.length === 0 &&
                            !checkDuplicateNumbers &&
                            currentNumbers.length === numbersConfiguration.numbers
                        ) {
                            invalidTypes = [
                                ...invalidTypes,
                                true
                            ];
                        } else {
                            if (checkInvalidNumbers.length !== 0) {
                                invalidMessages = [
                                    ...invalidMessages,
                                    this.createErrorMessage(lineIndex, 'Invalid numbers range')
                                ];
                            }
                            if (checkDuplicateNumbers) {
                                invalidMessages = [
                                    ...invalidMessages,
                                    this.createErrorMessage(lineIndex, 'Duplicate numbers found')
                                ];
                            }
                            if (currentNumbers.length !== numbersConfiguration.numbers) {
                                invalidMessages = [
                                    ...invalidMessages,
                                    this.createErrorMessage(lineIndex, 'Numbers length does not match')
                                ];
                            }
                        }
                    } else {
                        if (currentNumbers >= numbersConfiguration.minRange && currentNumbers <= numbersConfiguration.maxRange) {
                            invalidTypes = [
                                ...invalidTypes,
                                true
                            ];
                        } else {
                            invalidMessages = [
                                ...invalidMessages,
                                this.createErrorMessage(lineIndex, 'Number length or range does not match')
                            ];
                        }
                    }
                }
            }

            let hasErrors = invalidMessages.length > 0;

            if (!hasErrors) {
                this.updateErrorMessage();
            }

            return {
                invalid: hasErrors,
                messages: [
                    ...invalidMessages
                ]
            };
        },
        validateInput: function (index, numberType, numberIndex) {

            let getLineByIndex = this.getLineByIndex(index);

            if (getLineByIndex && !getLineByIndex.random) {
                let numbersConfiguration = this.getNumberTypeConfiguration(numberType),
                    hasManyNumbers = numbersConfiguration.numbers > 1,
                    getNumber = hasManyNumbers ? getLineByIndex.numbers[numberType][numberIndex] : getLineByIndex.numbers[numberType];

                if (getNumber >= numbersConfiguration.minRange && getNumber <= numbersConfiguration.maxRange) {
                    if (hasManyNumbers) {
                        let checkDuplicates = getLineByIndex.numbers[numberType].filter(item => item === getNumber)
                        return checkDuplicates.length > 1;
                    }
                    return false;
                }

                return true;
            }

            return false;
        },
        findDuplicates: function (array) {
            return array.filter((item, index) => array.indexOf(item) != index)
        },
        hasDuplicates: function (numbers) {
            return [...new Set(this.findDuplicates(numbers))].length > 0;
        },
        updateErrorMessage: function (message = '') {
            this.errorMessage = message;
        },
        createErrorMessage: function (lineIndex, message) {
            return `Line # ${Number(lineIndex) + 1}: ${message}.`
        },
        showErrorMessage: function (lines) {
            let vm = this;

            return new Promise((resolve, reject) => {
                try {
                    let localLines = JSON.parse(JSON.stringify(lines));

                    for (let line in localLines) {
                        let currentLine = localLines[line];

                        localLines[line].error = vm.validateLine(currentLine, line);
                    }

                    resolve(localLines);
                } catch (e) {
                    reject(e);
                }
            })
                .then(response => {
                    let getInvalidLines = response.filter(item => item.error && item.error.invalid);

                    if (getInvalidLines.length > 0) {
                        vm.errorMessage = getInvalidLines[0].error.messages[0];
                    }
                });
        },
        componentKeyHandler: function (event) {
            switch (event.keyCode) {
                case 107:
                    this.addNewLine();
                    break;
                case 109:
                    this.removeLine(this.getLines().length - 1);
                    break;
            }
        }
    },
    watch: {
        lines: {
            handler: function (value) {
                this.showErrorMessage(value);
            },
            deep: true
        }
    },
    created: function () {
        if (!this.noLineControl && !this.hasSelectedFreeGame) {
            window.addEventListener('keyup', this.componentKeyHandler);
        }
    },
    beforeDestroy() {
        if (!this.noLineControl && !this.hasSelectedFreeGame) {
            window.removeEventListener('keyup', this.componentKeyHandler);
        }
    }
}
</script>