import {clone} from '../../../../utils/Json/Json';

const errorModel = {
	amountSelectorError: {
		status: true,
		message: null,
	},
};

export default {
	data() {
		return {
			errorModel: clone(errorModel),
			isSearchFormValid: true,
		};
	},
	methods: {
		validateSearchForm() {
			this.errorModel = {
				amountSelectorError: this.validateComparableInput('amount'),
			};

			const statuses = Object.values(this.errorModel).map((error) => error.status);

			this.isSearchFormValid = !statuses.includes(false);
		},
		validateComparableInput(name) {
			const { status, message } = this.errorModel[`${name}SelectorError`];

			return {
				status,
				message
			};
		},
		updateSearchModelError(name, error) {
			const { isValid, message, showMessage } = error || {
				isValid: true,
				message: null,
				showMessage: false,
			};

			Object.assign(this.errorModel, {
				[`${name}SelectorError`]: {
					status: isValid,
					message: !!showMessage
						? message
						: null
				}
			});
		},
		clearSearchErrors() {
			this.errorModel = clone(errorModel);
		},
	},
}