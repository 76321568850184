var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid tabs-atbl" },
    [
      !_vm.callHistoryLoader
        ? [
            _c("call-history-daily-statistics", {
              attrs: {
                "is-loading": _vm.callHistoryLoader,
                statistics: _vm.callHistoryResponse.dailyStatistics
              }
            }),
            _vm._v(" "),
            _c("call-history-range-statistics", {
              attrs: {
                "is-loading": _vm.callHistoryLoader,
                statistics: _vm.callHistoryResponse.rangeStatistics,
                "call-history-search-model": _vm.callHistoryFilterModel
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "tabs-atbl-statistics" }, [
              _c("div", { staticClass: "tabs-atbl-statistics-header" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  !_vm.getIsOnlyAgent && !!_vm.agentName
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-sm btn-primary mr-3",
                          attrs: { href: "/call/history/view" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-chevron-left",
                            attrs: { "aria-hidden": "false" }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-phone mr-1",
                    attrs: { "aria-hidden": "false" }
                  }),
                  _vm._v(" "),
                  !!_vm.isAgentSelected
                    ? _c(
                        "span",
                        [
                          !_vm.userLoader
                            ? _c("div", [
                                !!_vm.agentName
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.agentName) +
                                          "\n                            "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.userModel.name))
                                    ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loader", {
                            attrs: { show: _vm.userLoader, type: "smaller" }
                          })
                        ],
                        1
                      )
                    : _c("span", [
                        _vm._v(
                          "\n                    Agents Call History\n                "
                        )
                      ])
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.$can("Call History - Export")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-success m-0",
                          on: { click: _vm.onExportCallHistory }
                        },
                        [
                          !_vm.isExportLoading
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa fa-download",
                                  attrs: { "aria-hidden": "false" }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loader", {
                            attrs: {
                              show: _vm.isExportLoading,
                              type: "smaller"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("Call History - View")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-primary m-0",
                          on: {
                            click: function($event) {
                              _vm.isCallHistoryFilterFormOpen = !_vm.isCallHistoryFilterFormOpen
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter",
                            attrs: { "aria-hidden": "false" }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("Call History - View")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-warning m-0",
                          on: {
                            click: function($event) {
                              return _vm.fetchCallHistoryStatistics(
                                _vm.callHistoryFilterModel,
                                _vm.agentid
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-undo",
                            attrs: { "aria-hidden": "false" }
                          })
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tabs-atbl-statistics-body" },
                [
                  !_vm.isAgentSelected
                    ? _c(
                        "atbl-tabs",
                        {
                          model: {
                            value: _vm.selectedTab,
                            callback: function($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab"
                          }
                        },
                        [
                          _c(
                            "atbl-tab",
                            { attrs: { title: "Agents", active: "" } },
                            [
                              _c("call-history-agents-list", {
                                attrs: {
                                  "is-loading": _vm.callHistoryLoader,
                                  agents:
                                    _vm.callHistoryResponse.agentsStatistics,
                                  "call-history-search-model":
                                    _vm.callHistoryFilterModel
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "atbl-tab",
                            { attrs: { title: "Calls" } },
                            [
                              _c("call-history-list", {
                                attrs: {
                                  "is-loading": _vm.callHistoryGeneralLoader,
                                  statistics:
                                    _vm.callHistoryResponse
                                      .callHistoryStatistics,
                                  "call-history-search-model":
                                    _vm.callHistoryFilterModel
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "atbl-tab",
                            { attrs: { title: "Daily Total" } },
                            [
                              _c("call-history-daily-total", {
                                attrs: {
                                  "is-loading": _vm.callHistoryLoader,
                                  calls:
                                    _vm.callHistoryResponse
                                      .dailyTotalStatistics,
                                  "call-history-search-model":
                                    _vm.callHistoryFilterModel
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("call-history-list", {
                        attrs: {
                          "is-loading": _vm.callHistoryGeneralLoader,
                          statistics:
                            _vm.callHistoryResponse.callHistoryStatistics,
                          "call-history-search-model":
                            _vm.callHistoryFilterModel
                        }
                      })
                ],
                1
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.callHistoryLoader, type: "small" } }),
      _vm._v(" "),
      _c("call-history-filter-form-component", {
        attrs: {
          show: _vm.isCallHistoryFilterFormOpen,
          "call-history-statuses": _vm.callHistoryStatuses,
          "call-history-statuses-loader": _vm.callHistoryStatusesLoader,
          "call-history-filter-model": _vm.callHistoryFilterModel,
          "call-history-filter-loader": false
        }
      }),
      _vm._v(" "),
      _c("call-history-filter-recordings-component", {
        attrs: {
          show: _vm.callHistoryRecordingsSidebarOpen,
          "is-loading": _vm.callHistoryRecordingsLoader,
          recordings: _vm.callHistoryRecordingsList
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }