<template>
    <div class="container-fluid">
        <div class="card card-search">
            <div class="card-header">
                <i class='fa fa-search' aria-hidden='false'></i> Search
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col mb-2 m-lg-0">
                        <p class="d-inline">Filter by tags</p>
                        <span v-for="tag in tags"
                              :key="`filterTag_${tag}`"
                              :class="[selectedTags.includes(tag) ? 'badge-primary' : 'badge-secondary']"
                              class="badge mr-1 cursor-pointer"
                              @click="addToSelectedTags(tag)"
                        >
                            {{ tag }}
                        </span>

                        <atbl-form-group label="Date range" :label-cols="3" class="font-weight-bold" :horizontal="false">
                            <date-range-picker v-model="dateRange"
                                               singleDatePicker="range"
                                               :autoApply="true"
                                               :ranges="false"
                                               opens="right"
                                               :showDropdowns="true">
                                <div slot="input" slot-scope="picker">
                                    <span v-if="dateRangeFormatted">{{ dateRangeFormatted }} <i title="Clear date" @click="clearDayRange" style="margin-left: 8px" class="fa fa-times"></i></span>
                                    <span v-else>Select date</span>
                                </div>
                            </date-range-picker>
                        </atbl-form-group>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button type="submit" class="btn btn-sm btn-primary" @click="page = 1; fetchLogs()">
                    <i class="fa fa-search"></i> Search
                </button>
            </div>
        </div>

        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span>
                    <i class='fa fa-align-justify'></i>
                    Logs
                </span>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg">Error</div>
                        <div class="col-12 col-lg">File</div>
                        <div class="col-12 col-lg">File line</div>
                        <div class="col-12 col-lg">Tags</div>
                        <div class="col-12 col-lg">Date time</div>
                        <div class="col-12 col-lg">Operations</div>
                    </div>

                    <div v-for="(item, index) in logs" :key="`log_row_${index}`" :style="{ background: item.color }" class="row">
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Message</span>
                            <strong>{{ item.message }}</strong>
                            <span class="d-block text-muted">
                                Level: <strong>{{ item.level }}</strong>
                            </span>
                            <span class="d-block text-muted">
                                Code: <strong>{{ item.code }}</strong>
                            </span>
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">File</span>
                            {{ item.file }}
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">File line</span>
                            {{ item.file_line }}
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">File</span>
                            <span v-for="tag in item.tags"
                                  :key="`log_tag_${tag}`"
                                  class="badge badge-primary mr-1"
                            >
                                {{ tag }}
                            </span>
                        </div>
                        <div class="col-12 col-md-6 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">File line</span>
                            {{ item.date_time }}
                        </div>
                        <div class="col-12 col-lg">
                            <span class="small-mb-badge">Operations</span>
                            <button class="btn btn-sm btn-warning" @click="showLog(item.group_id)">
                                View
                            </button>
                        </div>
                    </div>

                    <div style="visibility: hidden; padding: 0;" ref="infinite-scroll-trigger"></div>

                    <not-found v-if="! logs.length" :items="[]" msg="No logs found" />

                    <loader :show="isLoading" type="small" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import Date from '../../helpers/Date';
import moment from 'moment';

export default {
    components: { DateRangePicker },

    data() {
        return {
            isLoading: false,
            logs: [],
            page: 1,
            lastGroupId: null,
            tags: [],
            selectedTags: [],
            dateRange: {},
            groupColors: [
                '#ffe6e6',
                '#f1ae89',
                '#a0c1b8',
                '#adce74',
                '#f9f7cf',
            ],
        }
    },

    computed: {
        dateRangeFormatted() {
            if (!this.dateRange.startDate || !this.dateRange.endDate) {
                return null;
            }

            return Date.format(this.dateRange.startDate, 'YYYY-MM-DD') + ' - ' + Date.format(this.dateRange.endDate, 'YYYY-MM-DD');
        },
    },

    mounted() {
        this.setIntersectionObserver();

        this.fetchTags();
        this.fetchLogs();
    },

    methods: {
        setIntersectionObserver() {
            let observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting !== true || this.isLoading) {
                    return;
                }

                this.fetchLogs();
            }, { threshold: [1] });

            observer.observe(this.$refs['infinite-scroll-trigger']);
        },

        fetchTags() {
            window.axios.get('/logs-tag').then((response) => {
                this.tags = response.data;
            });
        },

        fetchLogs() {
            this.isLoading = true;

            const params = {
                page: this.page,
                ...((this.selectedTags.length) && { tags: this.selectedTags }),
                ...((this.dateRange.startDate) && { dateFrom: moment(this.dateRange.startDate).format('YYYY-MM-DD') }),
                ...((this.dateRange.endDate) && { dateTo: moment(this.dateRange.endDate).format('YYYY-MM-DD') }),
            };
            
            if (this.page === 1) {
                this.logs = [];
            }

            window.axios.get('/log-list', { params }).then((response) => {
                let lastGroupId = null;
                let lastColor = this.groupColors[0];

                const data = response.data.map(l => {
                    if (l.group_id !== lastGroupId) {
                        lastColor = this.shiftColors();
                        lastGroupId = l.group_id;
                    }

                    l.color = lastColor;
                    return l;
                });

                this.logs.push(...data);
                this.page++;
            }).finally(() => {
                this.isLoading = false;
            });
        },

        addToSelectedTags(tag) {
            if (!this.selectedTags.includes(tag)) {
                this.selectedTags.push(tag);
                return;
            }

            const index = this.selectedTags.findIndex(t => t === tag);
            this.selectedTags.splice(index, 1);
        },

        showLog(id) {
            window.location.href = `/logs/${id}`;
        },

        shiftColors() {
            const color = this.groupColors.shift();
            this.groupColors.push(color);

            return color;
        },

        clearDayRange() {
            this.dateRange = {};
        },
    },
}
</script>
