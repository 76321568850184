import TypesConst from "../../TypesConst";
import AddToCart from "../../../services/AddToCart";
import Cart from "../../../modules/cart/Cart";

export default {
    [TypesConst.cart.actions.ADD_TO_CART_ACTION]({commit, state, rootState, dispatch}, ticketData = {}){
        return new Promise((resolve, reject) => {
            commit(TypesConst.cart.mutations.SET_ADD_TO_CART_LOADER, true);

            AddToCart.addToCart(state.cartData.reference_id, ticketData)
                .then(response => {
                    let { status, message, data } = response.data;

                    if(status) {
                        commit(TypesConst.cart.mutations.SET_ADD_TO_CART_DATA, data);
                        Cart.storage(data, rootState.players.playerId);
                        resolve();
                    } else {
                        dispatch(TypesConst.cart.actions.RESET_CART_ACTION, data.isClosed);
                    }

                    flash(message, status ? "alert-success" : "alert-danger");
                })
                .catch(error => {
                    let message = error.response.data.message ? error.response.data.message : error.message;
                    flash(message, "alert-danger");
                    reject(error);
                })
                .finally(() => {
                    commit(TypesConst.cart.mutations.SET_ADD_TO_CART_LOADER, false);
                });
        });
    },
    [TypesConst.cart.actions.CLEAR_CART_ACTION]({commit, state, rootState, dispatch}, cartId = null){
        return new Promise((resolve, reject) => {
            commit(TypesConst.cart.mutations.SET_ADD_TO_CART_LOADER, true);

            AddToCart.clearCart((cartId ? cartId : state.cartData.reference_id), {
                    playerId: rootState.players.playerId
                })
                .then(response => {
                    let { status, message, data } = response.data;

                    if(status) {
                        commit(TypesConst.cart.mutations.SET_CLEAR_CART_DATA);
                        commit(TypesConst.cart.mutations.SET_CHECKOUT, {
                            checkout: false,
                            cartTotal: 0
                        });
                        Cart.clear(rootState.players.playerId);
                        resolve(response);
                    }else {
                        dispatch(TypesConst.cart.actions.RESET_CART_ACTION, data);
                        reject(response);
                    }

                    flash(message, status ? "alert-success" : "alert-danger");
                })
                .catch(error => {
                    let message = error.response.data.message ? error.response.data.message : error.message;
                    flash(message, "alert-danger");
                    reject(error);
                })
                .finally(() => {
                    commit(TypesConst.cart.mutations.SET_ADD_TO_CART_LOADER, false);
                });
        });
    },
    [TypesConst.cart.actions.REMOVE_CART_ITEM_ACTION]({commit, state, rootState}, cartItemId){
        return new Promise((resolve, reject) => {
            commit(TypesConst.cart.mutations.SET_ADD_TO_CART_LOADER, true);

            AddToCart.removeItem(
                cartItemId,
                {
                    playerId: rootState.players.playerId
                }
                )
                .then(response => {
                    let { status, message, data } = response.data;

                    if(status) {
                        commit(TypesConst.cart.mutations.SET_ADD_TO_CART_DATA, data);
                        Cart.storage(data, rootState.players.playerId);
                        resolve(response);
                    }else {
                        reject(response.data);
                    }

                    flash(message, status ? "alert-success" : "alert-danger");
                })
                .catch(error => {
                    let message = error.response.data.message ? error.response.data.message : error.message;
                    flash(message, "alert-danger");
                    reject(error);
                })
                .finally(() => {
                    commit(TypesConst.cart.mutations.SET_ADD_TO_CART_LOADER, false);
                });
        });
    },
    [TypesConst.cart.actions.RESET_CART_ACTION]({commit, rootState}, isClosed){
        if((typeof isClosed === "boolean" && isClosed) || isClosed.resetShoppingCart) {
            Cart.clear(rootState.players.playerId);
            commit(TypesConst.cart.mutations.SET_CLEAR_CART_DATA);
        }
        commit(TypesConst.cart.mutations.SET_CART_FIELD_DATA, {
            items: []
        });
        commit(TypesConst.cart.mutations.SET_CHECKOUT, {
            checkout: false,
            cartTotal: 0
        });
    }
};