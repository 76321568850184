var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    { attrs: { label: "Game", error: _vm.getError(_vm.errors, "game") } },
    [
      _c(
        "select",
        {
          staticClass: "form-control",
          attrs: { disabled: _vm.disabled },
          on: { input: _vm.changeGame }
        },
        [
          _c("option", { domProps: { value: null, selected: !_vm.value } }, [
            _vm._v("Select Game")
          ]),
          _vm._v(" "),
          _vm._l(_vm.options, function(item, index) {
            return _c(
              "option",
              {
                key: index,
                domProps: { value: item.id, selected: _vm.value === item.id }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      item.hasOwnProperty("title") ? item.title : item.name
                    ) +
                    "\n        "
                )
              ]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }