<template>
    <div class="discounts-form-holder">
        
        <div class="discounts-form-holder-header">
            <h3>
                <i class="fa fa-exchange-alt"></i> Draw discounts
            </h3>
            
            <div v-if="!isLoading"
                 class="d-flex"
            >
                <button v-if="$can('Discounts - Create/Update')"
                        :disabled="!isDirty || data.activeVersion !== drawPreviewVersion"
                        @click="publishDraws"
                >
                    Publish
                </button>
                
                <atbl-dropdown :small="true"
                               :placeholder="`v${drawPreviewVersion}`"
                               position="right"
                               input-style="input"
                >
                    <atbl-dropdown-item @click="fetchDrawDiscounts(editingGame)">
                        v{{ data.activeVersion }} - latest
                    </atbl-dropdown-item>
                    <template v-if="!!drawDiscountVersions.length">
                        <atbl-dropdown-divider/>
                        <atbl-dropdown-item v-for="version in drawDiscountVersions"
                                            :key="`draw_version_${version}`"
                                            @click="fetchDrawDiscounts(editingGame, version)"
                        >
                            v{{ version }}
                        </atbl-dropdown-item>
                    </template>
                </atbl-dropdown>
            </div>
        </div>
        
        <div class="discounts-form-holder-body">
            <template v-if="!isLoading">
                <div class="discounts-form-holder-row-header">
                    <div>From</div>
                    <div>To</div>
                    <div>Discount</div>
                    <div>CRM Discount</div>
                    <div>&nbsp;</div>
                </div>
                <div v-for="(line, index) in data.discounts"
                     :key="`discount_draw_${index}`"
                     class="discounts-form-holder-row"
                >
                    <div>
                        <atbl-number :value="line.from"
                                     :label="null"
                                     :min="0"
                                     class="m-0"
                                     name="from"
                                     placeholder="From"
                                     @update="editDiscountDraw(line.id, ...arguments)"
                        />
                    </div>
                    <div>
                        <atbl-number :value="line.to"
                                     :label="null"
                                     :min="0"
                                     class="m-0"
                                     name="to"
                                     placeholder="To"
                                     @update="editDiscountDraw(line.id, ...arguments)"
                        />
                    </div>
                    <div>
                        <atbl-number :value="line.discount"
                                     :label="null"
                                     :min="0"
                                     class="m-0"
                                     name="discount"
                                     placeholder="Discount"
                                     @update="editDiscountDraw(line.id, ...arguments)"
                        />
                    </div>
                    <div>
                        <atbl-number :value="line.discount_crm"
                                     :label="null"
                                     :min="0"
                                     class="m-0"
                                     name="discount_crm"
                                     placeholder="CRM Discount"
                                     @update="editDiscountDraw(line.id, ...arguments)"
                        />
                    </div>
                    <div>
                        <a href="#"
                           class="circle circle__minus"
                           @click.prevent="removeDiscountDraw(index)"
                        ></a>
                        <a v-if="index === data.discounts.length - 1"
                           href="#"
                           class="circle circle__plus"
                           @click.prevent="addDiscountDraw(line.id, (line.to || line.from) + 1, null, null, null)"
                        ></a>
                    </div>
                    <div v-if="hasErrors(data.errors, index, 'discountDraws')"
                         class="error"
                    >
                        <div v-for="error in getErrors(data.errors, index, 'discountDraws')">
                            {{ error }}
                        </div>
                    </div>
                </div>
                <div v-if="hasErrors(data.errors, null, 'discountLines')"
                     class="discounts-form-holder-error"
                >
                    {{ getErrors(data.errors, null, 'discountLines') }}
                </div>
            </template>
            <loader :show="isLoading"
                    type="small"
            />
        </div>
    </div>
</template>

<script>
import GameDiscountModel from '../../GameDiscountModel';
import AtblNumber from '../../../../components/Forms/Inputs/ATBLNumber.vue';
import Loader from '../../../../components/Widgets/Loader.vue';
import AtblDropdown from '../../../../components/AtblDropdown/AtblDropdown.vue';
import AtblDropdownItem from '../../../../components/AtblDropdown/AtblDropdownItem.vue';
import AtblDropdownDivider from '../../../../components/AtblDropdown/AtblDropdownDivider.vue';
import {isObjectEmpty} from '../../../../utils/Object/Object';
import {stringify} from '../../../../utils/Json/Json';

export default {
    name: 'DrawsEditorComponent',
    inject: [
        'fetchDrawDiscounts',
        'addDiscountDraw',
        'removeDiscountDraw',
        'publishDraws',
        'editDiscountDraw',
        'hasErrors',
        'getErrors',
    ],
    components: {
        Loader,
        AtblNumber,
        AtblDropdown,
        AtblDropdownItem,
        AtblDropdownDivider,
    },
    props: {
        editingGame: {
            type: Object,
            default: () => (GameDiscountModel)
        },
        data: {
            type: Object,
            default: () => ({
                discountsInitial: [],
                discounts: [],
                activeVersion: null,
                loader: false,
                errors: {},
            })
        },
    },
    computed: {
        isDirty() {
            return stringify(this.data.discounts) !== stringify(this.data.discountsInitial);
        },
        drawPreviewVersion() {
            if (!this.data.discounts.length) {
                return 1;
            }
            
            const discountDraw = this.data.discounts.find(dd => dd.hasOwnProperty('version'));
            if (!discountDraw) {
                return 1;
            }
            
            return discountDraw.version;
        },
        drawDiscountVersions() {
            return [...Array(this.data.activeVersion || 1).keys()].slice(1).reverse();
        },
        isLoading() {
            return this.data.loader;
        },
    },
};
</script>