var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game", attrs: { title: _vm.item.gameable.title } },
    [
      _c("div", { staticClass: "ribbon-danger" }, [
        _c("span", [
          _vm._v(
            "Bundle " +
              _vm._s(
                _vm.item.number_of_tickets > 1
                  ? "x " + _vm.item.number_of_tickets
                  : ""
              )
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "img-holder" },
        _vm._l(_vm.item.gameable.bundle_games, function(bundleGame) {
          return _c("img", {
            staticClass: "game-thumb",
            attrs: { src: _vm.$imageService.gameImage(bundleGame.game.image) }
          })
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "game-play" }, [
        _c(
          "p",
          {
            staticClass: "d-block mb-1",
            attrs: { title: _vm.item.gameable.title }
          },
          [_vm._v(_vm._s(_vm.item.gameable.title))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "game-play-info" },
          [
            !!_vm.item.meta.length
              ? _vm._l(_vm.item.meta, function(item, key) {
                  return _c("span", [
                    _c("b", [_vm._v(_vm._s(_vm.ucFirst(key)) + ":")]),
                    _vm._v(" " + _vm._s(item) + "\n                ")
                  ])
                })
              : _c("span", [_vm._v(" ")])
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: {
              "btn-primary": !_vm.isLoading,
              "btn-outline-primary": _vm.isLoading
            },
            on: { click: _vm.playGame }
          },
          [
            !_vm.isLoading ? _c("span", [_vm._v("Play")]) : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }