import UsersService from '../../services/UsersService';
import { clone } from '../../utils/Json/Json';

const userModel = {
    name: null,
    email: null
};

export default {
    data() {
        return {
            userLoader: false,
            userModel: clone(userModel),
        };
    },
    methods: {
        async getCurrentAgent() {
            this.userLoader = true;

            try {
                const response = await UsersService.getCurrentUser();

                this.userModel = response.data;

                this.userLoader = false;
            } catch (error) {
                this.userLoader = false;

                window.flashError(error);
            }
        },
    },
}