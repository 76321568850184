export default {
    data() {
        return {
            showSearch: false,
            searchModel: {
                agents: [],
                name: null,
                surname: null,
                is_used: null,
                paginate: null
            },
            usedOptions: [
                {
                    label: 'Used',
                    value: true
                },
                {
                    label: 'Un used',
                    value: false
                }
            ]
        };
    },
    methods: {
        changeSearchModel(name, value) {
            Object.assign(this.searchModel, {
                [name]: value
            });
        },
        clearSearchForm() {
            this.searchModel = {
                agents: [],
                name: null,
                surname: null,
                is_used: null,
            };
            this.fetchFreeGames();
        }
    }
}