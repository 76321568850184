var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: { id: _vm.id },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("div", [
                _c("i", { staticClass: "fa fa-ban" }),
                _vm._v(" Player blocks\n        ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("block-player-profile", {
                    attrs: { player: _vm.player },
                    on: { update: _vm.onPlayerBlockUpdate }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row show-first-row" }, [
            _c("div", { staticClass: "col col-lg-2 text-muted" }, [
              _vm._v("Blocked By")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted" }, [_vm._v("Reason")]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Status")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Blocked at")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted text-center" }, [
              _vm._v("Unblocked at")
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.blocks.data, function(block, index) {
            return !_vm.isLoading
              ? _c("div", { key: index, staticClass: "row py-3" }, [
                  _c("div", { staticClass: "col col-lg-2 text-muted" }, [
                    _vm._v(_vm._s(block.blocked_by_name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-muted" }, [
                    _c("small", [_vm._v(_vm._s(block.reason))])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-muted text-center" }, [
                    !block.deleted_at
                      ? _c("span", { staticClass: "badge badge-danger" }, [
                          _vm._v(
                            "\n                    Blocked\n                "
                          )
                        ])
                      : _c("span", { staticClass: "badge badge-success" }, [
                          _vm._v(
                            "\n                    Unblocked\n                "
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-muted text-center" }, [
                    _c("small", [
                      _vm._v(_vm._s(_vm.utcToLocal(block.created_at)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-muted text-center" }, [
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          block.deleted_at
                            ? _vm.utcToLocal(block.deleted_at)
                            : "/"
                        )
                      )
                    ])
                  ])
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.blocks.data, msg: "No dispositions found." }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading && _vm.blocks.last_page > 1
            ? _c(
                "nav",
                { staticClass: "wrap py-3" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.blocks },
                    on: { paginate: _vm.getPlayerBlocks }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }