<template>
    <atbl-modal :title="modalTitle" :close-on-backdrop="false" size="lg" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <atbl-form-group label="Available countries" label-for="Available countries" :horizontal="false" class="font-weight-bold">
                    <tree-select v-model="gatewayAvailableCountries"
                                 :multiple="true"
                                 :searchable="true"
                                 :clear-on-select="true"
                                 :options="countriesList"
                                 :normalizer="(node) => ({id: node.country_code, label: node.text})"
                    />
                </atbl-form-group>
            </div>

            <div class="col-12">
                <atbl-form-group label="Excluded countries" label-for="Excluded countries" :horizontal="false" class="font-weight-bold">
                    <tree-select v-model="gatewayExcludedCountries"
                                 :multiple="true"
                                 :searchable="true"
                                 :clear-on-select="true"
                                 :options="countriesList"
                                 :normalizer="(node) => ({id: node.country_code, label: node.text})"
                    />
                </atbl-form-group>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-primary" @click="save()">
                Save
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import CountriesService from '../../../services/AreaService';
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'gateway-countries-modal',
	components: {
		AtblModal,
	},
    props: ['gateway'],

    data() {
        return {
            countriesList: [],
            gatewayAvailableCountries: [],
            gatewayExcludedCountries: [],
        };
    },

    computed: {
        modalTitle() {
            return `Update ${this.gateway.gateway.name} gateway countries`;
        },
    },

    mounted() {
        this.fetchCountries()
            .then(() => {
                this.gatewayAvailableCountries = this.gateway.available_countries.map(c => c.toLowerCase()).filter(Boolean);
                this.gatewayExcludedCountries = this.gateway.excluded_countries.map(c => c.toLowerCase()).filter(Boolean);
            });
    },

    methods: {
        fetchCountries() {
            return CountriesService.getCountriesOptions().then((response) => {
                const allCountries = {
                    country_code: '*',
                    text: 'All',
                    value: 'All',
                };

                this.countriesList = [allCountries, ...response.data];

                return response;
            });
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },

        save() {
            const availableCountries = this.gatewayAvailableCountries;
            const excludedCountries = this.gatewayExcludedCountries;

            if (availableCountries.some(c => excludedCountries.includes(c))) {
                window.showMessage('There are countries included in both lists. Please fix them before saving', false);

                return;
            }

            const params = {
                availableCountries,
                excludedCountries,
            };

            this.$emit('confirm', params);
        },
    },
}
</script>