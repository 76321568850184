import { keys, omit } from 'lodash';

export default {
    data() {
        return {
            numbersGroups: {
                "bonoloto": {
                    main: "b-green",
                    complementary: "f-green",
                    refund: "f-green"
                },
                "thunderball": {
                    main: "b-purple",
                    thunderball: "f-purple"
                },
                "euromillions": {
                    main: "border-primary",
                    stars: "bg-primary",
                    raffle: "bg-warning"
                },
                "sat-lotto-au": {
                    main: "-green",
                    supplementary: "f-green",
                },
                "powerball": {
                    main: "b-red",
                    powerball: "f-red",
                    powerplay: "f-red",
                },
                "megamillions": {
                    main: "b-yellow",
                    megaball: "f-yellow",
                    megaplier: "f-yellow",
                },
                "mon-wed-lotto-au": {
                    main: "b-yellow-darker",
                    supplementary: "f-yellow-darker"
                },
                "lotto-fr": {
                    main: "b-blue-darker",
                    chance: "f-blue-darker",
                    raffle: "bg-warning"
                },
                "lotto-6aus49": {
                    main: "b-yellow-darker",
                    super: "f-yellow-darker",
                    spiel77: "spiel77",
                    super6: "super6",
                },
                "lotto-649-ca": {
                    main: "b-yellow-darker",
                    bonus: "f-red",
                    encore: "encore",
                    raffle: "bg-warning"
                },
                "superenalotto": {
                    main: "b-yellow-darker",
                    jolly: "f-red",
                    superstar: "bg-warning"
                },
                "set-for-life": {
                    main: "set-for-life-main",
                    life: "set-for-life-life",
                },
                "lotto-max-ca": {
                    main: "b-yellow-darker",
                    bonus: "f-green",
                    encore: "encore",
                }
            }
        }
    },
    computed: {
        getTicket(){
            return this.ticket;
        }
    },
    methods: {
        typeResolver(key, className = ""){
            switch (key) {
                case "raffle":
                case "spiel77":
                case "super6":
                case "encore":
                case "ticket":
                        return className;
                default:
                    return "circle-num";
            }
        },
        getGameNumTypes(game) {
            if (game === null) return;
            return this.numbersGroups.hasOwnProperty(game) ? this.numbersGroups[game] : null
        },
        isTypeArray(obj, type) {
            return obj && this.doesTypeExist(obj, type) && obj[type].length && Array.isArray(obj[type]);
        },
        doesTypeExist(obj, type) {
            return obj !== null && obj[type]
        },
        getNumbersConfiguration(draw, configurationNumbers){
            let drawNumbers = draw.game_draw && draw.game_draw.numbers ? draw.game_draw.numbers : [],
                extraNumbers = JSON.parse(draw.numbers),
                allLineNumbers = draw.lines.length ? draw.lines[0].line.numbers : null,
                mergedNumbers = allLineNumbers ? {
                    ...allLineNumbers,
                    ...extraNumbers
                } : null,
                bonusNumbers = {},
                allNumbers = {};

            if(drawNumbers){
                for(let item in drawNumbers){
                    let currentDrawNumber = drawNumbers[item];

                    if(!mergedNumbers.hasOwnProperty(item)){
                        if(configurationNumbers.main.hasOwnProperty("bonus") && configurationNumbers.main.bonus[0].key === item){
                            let checkIfBonusNumberExists = configurationNumbers.main.bonus.filter(item => item.key === item);

                            if(checkIfBonusNumberExists){
                                bonusNumbers[item] = currentDrawNumber;
                            }
                        }
                    }else {
                        allNumbers[item] = currentDrawNumber;
                    }
                }
            }

            let allLineNumbersValues = allNumbers.main ? Object.values(allNumbers.main) : [],
                getExtraNumbers = omit(drawNumbers, ["main", ...keys(bonusNumbers)]);
            
            const bonus = Object.values(bonusNumbers).reduce((sum, a) => {
                if (Array.isArray(a)) {
                    return [
                        ...sum,
                        ...a
                    ];
                }

                return [
                    ...sum,
                    a
                ];
            }, [])

            return {
                main: allLineNumbersValues,
                bonus,
                ...getExtraNumbers
            };
        },
        getLineNumbers(line, configuration = null){
            let lineNumbers = line.line.numbers ? line.line.numbers : [],
                extraNumbersConfiguration = configuration.extra,
                extraNumbers = {};

            if(this.getTicket.draw.numbers){
                let getExtraNumbers = JSON.parse(this.getTicket.draw.numbers);

                for(let item in getExtraNumbers){
                    let currentExtraNumber = getExtraNumbers[item],
                        hasKey = extraNumbersConfiguration.filter(extraItem => extraItem.key === item)[0] || null;

                    if(hasKey){
                        extraNumbers = {
                            ...extraNumbers,
                            ...{
                                [item]: currentExtraNumber
                            }
                        };
                    }
                }
            }

            return {
                ...{
                    main: lineNumbers.main,
                    ...omit(lineNumbers, ['main'])
                },
                ...extraNumbers
            };
        }
    }
};
