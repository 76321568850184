var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _c("h4", [
      _vm._v("\n        Do you really want to pay with this card?\n    ")
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "text-danger" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.card.holder) +
          " / " +
          _vm._s(_vm.card.payment_brand) +
          " * " +
          _vm._s(_vm.card.last_4_digits) +
          "\n    "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }