<template>
  <atbl-modal :show="show"
              :close-on-backdrop="false"
              :close-on-esc="false"
              add-body-classes="text-left"
              title="Copy Magic Link"
              @close="toggleCopyMagicLink"
  >
    <magic-link-redirect-form-component @update="redirect = $event" />

    <template #footer>
      <div class="d-flex align-items-center justify-content-end w-100">
        <button class="btn btn-sm btn-light mr-2"
                :disabled="!!isLoading"
                @click="toggleCopyMagicLink"
        >
          Close
        </button>
        <button class="btn btn-sm btn-success"
                :disabled="!!isLoading"
                @click="copyPasswordLessLoginLink"
        >
          <span v-if="!isLoading">Copy</span>
          <loader :show="isLoading"
                  type="smaller"
          />
        </button>
      </div>
    </template>
  </atbl-modal>
</template>

<script>
	import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';
	import AtblFormGroup from '../../../../../../components/Forms/Holders/ATBLFormGroup.vue';
	import AtblInput from '../../../../../../components/Forms/Inputs/ATBLInput.vue';
	import Loader from '../../../../../../components/Widgets/Loader.vue';
    import MagicLinkRedirectFormComponent from './MagicLinkRedirectForm/MagicLinkRedirectFormComponent.vue';

	export default {
		name: 'CopyMagicLinkComponent',
		inject: [
			'toggleCopyMagicLink'
		],
		components: {
			AtblModal,
			AtblFormGroup,
			AtblInput,
			Loader,
            MagicLinkRedirectFormComponent,
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			player: {
				type: Object,
				default: () => ({}),
			},
		},
		data() {
			return {
				isLoading: false,
				redirect: null,
			};
		},
		methods: {
			async copyPasswordLessLoginLink() {
				this.isLoading = true;

				try {
					const response = await window.axios.get(`/player/${this.player.id}/magic-link`, {
                        params: {
                            redirect: this.redirect,
                        }
                    });

					this.copyToClipboard(response.data.url);

					this.toggleCopyMagicLink();
				} catch (error) {
					window.flashError(error);
				} finally {
                    this.isLoading = false;
                }
			}
		}
	};
</script>