<template>
    <atbl-modal title="Send Email"
             :show="modalOpen"
             @close="handleCancel"
             :close-on-backdrop="false"
    >
        <div class="send-email-client">
            <p class="font-weight-bold">Client information's</p>
            <div v-if="!!sendEmailConfiguration.is_lead"
                  class="ribbon-primary"
            >
                <span>Lead</span>
            </div>
            <h3># {{ client.id }} / {{ client.name }} {{ client.surname }}</h3>
            <p>{{ client.email }}</p>
        </div>
        <send-email-form-component v-show="!isConfirmationActive"
                                   :send-email-configuration="sendEmailConfiguration"
                                   :selected-email-template="selectedEmailTemplate"
                                   :is-ticket-confirmation="isTicketConfirmation"
                                   :is-order-summary="isOrderSummary"
                                   :templates="templates"
                                   :ticket="ticket"
                                   :ticket-loader="ticketLoader"
                                   :ticket-error="ticketError"
                                   :order="order"
                                   :order-loader="orderLoader"
                                   :order-error="orderError"
                                   :locale="selectedLocale"
                                   :send-email-model="sendEmailModel"
                                   :ticket-types="ticketTypes"
                                   :errors="errors"
        />
        <send-email-confirmation-component v-show="isConfirmationActive"
                                           :locale="selectedLocale"
                                           :is-ticket-confirmation="isTicketConfirmation"
                                           :selected-email-template="selectedEmailTemplate"
                                           :ticket="ticket"
        />

        <template #footer>
            <div class="d-flex justify-content-end align-items-center">
                <div v-if="!sendEmailLoader">
                    <div v-if="!isConfirmationActive">
                        <button class="btn btn-sm btn-outline-danger"
                                  @click="handleCancel"
                        >
                            Cancel
                        </button>
                        <button class="btn btn-sm btn-primary"
                                  :disabled="!canProceed"
                                  @click="isConfirmationActive = true"
                        >
                            Continue
                        </button>
                    </div>
                    <div v-else>
                        <button class="btn btn-sm btn-danger"
                                  @click="isConfirmationActive = false"
                        >
                            Cancel
                        </button>
                        <button class="btn btn-sm btn-success"
                                  @click="onSendEmailEvent"
                        >
                            Send
                        </button>
                    </div>
                </div>
                <loader :show="sendEmailLoader"
                        type="small"
                />
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import SendEmailFormComponent from '../SendEmailForm/SendEmailFormComponent';
import SendEmailConfirmationComponent from '../SendEmailConfirmation/SendEmailConfirmationComponent';
import SendEmailForm from '../SendEmailForm/SendEmailForm';
import EmailSendService from '../../../services/EmailSendService';
import Language from '../../../modules/languages/Language';
import AtblModal from '../../AtblModal/AtblModal.vue';

export default {
    name: 'SendEmailModalComponent',
    mixins: [
        SendEmailForm,
    ],
    provide() {
        return {
            // SendEmailForm
            onLocaleChangeEvent: this.onLocaleChangeEvent,
            onEmailTemplateSelectEvent: this.onEmailTemplateSelectEvent,
            onTicketSearchEvent: this.onTicketSearchEvent,
            onUpdateTicketTypeEvent: this.onUpdateTicketTypeEvent,
            onTicketIdChangeEvent: this.onTicketIdChangeEvent,
            onOrderIdChangeEvent: this.onOrderIdChangeEvent,
            onOrderSearchEvent: this.onOrderSearchEvent,
            emailTemplateData: this.emailTemplateData,
        };
    },
    props: {
        client: {
            type: Object,
            default: () => ({})
        },
        show: {
            type: Boolean,
            default: false
        },
        sendEmailConfiguration: {
            type: Object,
            default: () => ({})
        },
        emailTemplateData: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        SendEmailConfirmationComponent,
        SendEmailFormComponent,
        AtblModal,
    },
    emits: [
        'toggle',
    ],
    data() {
        return {
            isConfirmationActive: false,
            sendEmailLoader: false,
        };
    },
    computed: {
        modalOpen: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit('toggle', value);
            }
        },
        canProceed: function () {
            if (!this.selectedEmailTemplate.id &&
                !this.selectedEmailTemplate.name &&
                !this.selectedEmailTemplate.description
            ) {
                return false;
            }

            if (!!this.selectedEmailTemplate.templates.length) {
                return false;
            }

            if (this.isOrderSummary) {
                if (!!this.emailTemplateData.order) {
                    return true
                }
                return this.orderError === null && !!this.order?.id
            }

            if (!this.isTicketConfirmation || !this.isOrderSummary) {
                return true;
            }

            if (!this.isset(this.ticket, 'order')) {
                return false;
            }

            return this.ticket.order.player_id === this.client.id;
        },
    },
    methods: {
        async onSendEmailEvent() {
            this.sendEmailLoader = true;

            try {
                const { id: sent_id } = this.client;
                const { id: templateId } = this.selectedEmailTemplate;
                const locale = this.isObject(this.selectedLocale)
                    ? this.selectedLocale.value
                    : this.selectedLocale;
                const ticketId = this.sendEmailModel.hasOwnProperty('ticketId')
                    ? this.sendEmailModel.ticketId
                    : null;
                const orderId = this.sendEmailModel.hasOwnProperty('orderId')
                    ? this.sendEmailModel.orderId
                    : null;
                const { ticketType } = this.sendEmailModel;
                const { is_lead } = this.sendEmailConfiguration;

                const response = await EmailSendService.sendEmail(
                    {
                        templateId,
                        ticketId,
                        sent_id,
                        is_lead,
                        ticketType,
                        orderId
                    },
                    Language.resolve(locale)
                );
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.handleCancel();
                }

                this.sendEmailLoader = false;
            } catch (error) {
                this.sendEmailLoader = false;

                window.flashError(error);
            }
        },
        handleCancel() {
            if(!this.emailTemplateData.hasOwnProperty('templateKey')) {
                this.clearEmailTemplateModel();
                this.clearSendEmailModel();
            }
            this.isConfirmationActive = false;
            this.modalOpen = false;
        },
        async setOrderDetailsEmailTemplate() {

            try {
                const response = await window.axios.get("/email-templates/order-details-template");

                const template = response.data.data

                if (!!template?.id) {
                    
                    this.templates = Array(template).map(({id, name: label}) => ({id, label}))
                    this.templatesOriginal = Array(template)
    
                    this.selectedEmailTemplate = template
                    this.sendEmailModel.templateId = template.id
                    this.sendEmailModel.orderId = this.emailTemplateData.order.id

                }

            } catch (error) {

                window.flashError(error);
            }

        }
    },
    async created() {
        if (!!this.emailTemplateData.templateKey) {
            await this.setOrderDetailsEmailTemplate()
        } else {
            await this.getTemplates(this.sendEmailConfiguration);
        }
    }
}
</script>