var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$canAny(_vm.permissions)
    ? _c("c-sidebar-nav-title", [_vm._v("Administrator")])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }