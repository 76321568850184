import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.players.getters.GET_FUNDS_AMOUNT]: state => Number(state.fundsAmount),
    [TypesConst.players.getters.GET_FUNDS_REFERENCE]: state => state.fundsReference,
    [TypesConst.players.getters.GET_FUNDS_REASON]: state => state.fundsReason,
    [TypesConst.players.getters.GET_FUNDS_META]: state => state.fundsMeta,
    [TypesConst.players.getters.GET_FUNDS_TRANSACTION_ID]: state => Number(state.fundsTransactionId),
    [TypesConst.players.getters.GET_FUNDS_AGENT]: state => state.fundsAgent,
    [TypesConst.players.getters.GET_FUNDS_IS_FAILED_TRANSACTION]: state => state.fundsIsFailedTransaction,
    [TypesConst.players.getters.GET_FUNDS_LOADER]: state => state.fundsLoader,
    [TypesConst.players.getters.GET_FUNDS_CREDIT_TYPE]: state => state.fundsCredit,
    [TypesConst.players.getters.GET_FUNDS_ADD_BALANCE]: state => state.fundsAddBalance,
    [TypesConst.players.getters.GET_FUNDS_PAYMENT_LINK]: state => state.fundsPaymentLink,
    [TypesConst.players.getters.GET_FUNDS_CONFIRM_MODAL]: state => state.fundsCreditConfirmModal,
    [TypesConst.players.getters.GET_BONUS_CONFIRM_MODAL]: state => state.fundsBonusConfirmModal,
    [TypesConst.players.getters.GET_FUNDS_CREDIT_CARD]: state => state.fundsCreditCard,
    [TypesConst.players.getters.GET_FUNDS_CONFIRM_CREDIT_CARD_MODAL]: state => state.fundsCreditCardConfirmModal,
    [TypesConst.players.getters.GET_FUNDS_CREDIT_CARD_ERRORS]: state => state.fundsCreditCardErrors,
};