var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "discounts-form-holder" }, [
    _c("div", { staticClass: "discounts-form-holder-header" }, [
      _vm._m(0),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm.$can("Discounts - Create/Update")
                ? _c(
                    "button",
                    {
                      attrs: {
                        disabled:
                          !_vm.isDirty ||
                          _vm.data.activeVersion !== _vm.linePreviewVersion
                      },
                      on: { click: _vm.publishLines }
                    },
                    [_vm._v("\n                Publish\n            ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "atbl-dropdown",
                {
                  attrs: {
                    small: true,
                    placeholder: "v" + _vm.linePreviewVersion,
                    position: "right",
                    "input-style": "input"
                  }
                },
                [
                  _c(
                    "atbl-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.fetchLineDiscounts(_vm.editingGame)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    v" +
                          _vm._s(_vm.data.activeVersion) +
                          " - latest\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !!_vm.lineDiscountVersions.length
                    ? [
                        _c("atbl-dropdown-divider"),
                        _vm._v(" "),
                        _vm._l(_vm.lineDiscountVersions, function(version) {
                          return _c(
                            "atbl-dropdown-item",
                            {
                              key: "line_version_" + version,
                              on: {
                                click: function($event) {
                                  return _vm.fetchLineDiscounts(
                                    _vm.editingGame,
                                    version
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        v" +
                                  _vm._s(version) +
                                  "\n                    "
                              )
                            ]
                          )
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "discounts-form-holder-body" },
      [
        !_vm.isLoading
          ? [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.data.discounts, function(line, index) {
                return _c(
                  "div",
                  {
                    key: "discount_line_" + index,
                    staticClass: "discounts-form-holder-row"
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("atbl-number", {
                          staticClass: "m-0",
                          attrs: {
                            value: line.from,
                            label: null,
                            min: 0,
                            name: "from",
                            placeholder: "From"
                          },
                          on: {
                            update: function($event) {
                              var i = arguments.length,
                                argsArray = Array(i)
                              while (i--) argsArray[i] = arguments[i]
                              return _vm.editDiscountLine.apply(
                                void 0,
                                [line.id].concat(argsArray)
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("atbl-number", {
                          staticClass: "m-0",
                          attrs: {
                            value: line.to,
                            label: null,
                            min: 0,
                            name: "to",
                            placeholder: "To"
                          },
                          on: {
                            update: function($event) {
                              var i = arguments.length,
                                argsArray = Array(i)
                              while (i--) argsArray[i] = arguments[i]
                              return _vm.editDiscountLine.apply(
                                void 0,
                                [line.id].concat(argsArray)
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("atbl-number", {
                          staticClass: "m-0",
                          attrs: {
                            value: line.discount,
                            label: null,
                            min: 0,
                            name: "discount",
                            placeholder: "Discount"
                          },
                          on: {
                            update: function($event) {
                              var i = arguments.length,
                                argsArray = Array(i)
                              while (i--) argsArray[i] = arguments[i]
                              return _vm.editDiscountLine.apply(
                                void 0,
                                [line.id].concat(argsArray)
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("atbl-number", {
                          staticClass: "m-0",
                          attrs: {
                            value: line.discount_crm,
                            label: null,
                            min: 0,
                            name: "discount_crm",
                            placeholder: "CRM Discount"
                          },
                          on: {
                            update: function($event) {
                              var i = arguments.length,
                                argsArray = Array(i)
                              while (i--) argsArray[i] = arguments[i]
                              return _vm.editDiscountLine.apply(
                                void 0,
                                [line.id].concat(argsArray)
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("a", {
                        staticClass: "circle circle__minus",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeDiscountLine(index)
                          }
                        }
                      }),
                      _vm._v(" "),
                      index === _vm.data.discounts.length - 1
                        ? _c("a", {
                            staticClass: "circle circle__plus",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.addDiscountLine(
                                  line.id,
                                  (line.to || line.from) + 1,
                                  null,
                                  null,
                                  null
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.hasErrors(_vm.data.errors, index, "discountLines")
                      ? _c(
                          "div",
                          { staticClass: "error" },
                          _vm._l(
                            _vm.getErrors(
                              _vm.data.errors,
                              index,
                              "discountLines"
                            ),
                            function(error) {
                              return _c("div", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(error) +
                                    "\n                    "
                                )
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ]
                )
              }),
              _vm._v(" "),
              _vm.hasErrors(_vm.data.errors, null, "discountLines")
                ? _c("div", { staticClass: "discounts-form-holder-error" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.getErrors(_vm.data.errors, null, "discountLines")
                        ) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("i", { staticClass: "fa fa-exchange-alt" }),
      _vm._v(" Line discounts\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "discounts-form-holder-row-header" }, [
      _c("div", [_vm._v("From")]),
      _vm._v(" "),
      _c("div", [_vm._v("To")]),
      _vm._v(" "),
      _c("div", [_vm._v("Discount")]),
      _vm._v(" "),
      _c("div", [_vm._v("CRM Discount")]),
      _vm._v(" "),
      _c("div", [_vm._v(" ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }