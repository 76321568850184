var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card call-history-statistic-card" }, [
    _c(
      "div",
      {
        staticClass: "card-body",
        class: {
          "p-0": !_vm.loader
        }
      },
      [
        !_vm.loader
          ? _c("div", { staticClass: "call-history-statistic-card-holder" }, [
              !_vm.loader
                ? _c("div", [
                    _c("h3", [_vm._v(_vm._s(_vm.title))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.description))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.loader
                ? _c("div", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.value) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("loader", { attrs: { show: _vm.loader, type: "smaller" } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }