<template>
    <atbl-modal :show="show"
             :close-on-backdrop="false"
             class="hide-close"
             title="Add Call Note"
             @close="onCallNoteModalClose(false)"
    >
        <atbl-textarea v-model="model.note"
                       :errors="errors"
                       label="Note *"
                       name="note"
        />

        <template #footer>
            <div class="d-flex w-100 align-items-center">
                <div class="w-50 font-weight-bold">
                    * Required
                </div>
                <div class="w-50 text-right">
                    <button class="btn btn-sm btn-danger"
                            @click="onCallNoteModalClose(false)"
                    >
                        Close
                    </button>
                    <button :class="{
                                'btn-primary': !isLoading,
                                'btn-outline-primary': isLoading,
                                'disabled': isLoading
                            }"
                            :disabled="isLoading || isSaveButtonDisabled"
                            class="btn btn-sm"
                            @click="onCreateNoteEvent"
                    >
                        <span v-if="!isLoading"
                        >
                            Save
                        </span>
                        <loader type="small"
                                :show="isLoading"
                        />
                    </button>
                </div>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import CallNotesService from '../Services/CallNotesService';
import AtblModal from '../../AtblModal/AtblModal.vue';

export default {
    name: 'add-call-note-modal-component',
    inject: [
        'callableId',
        'callableType',
        'onCallNoteModalClose',
    ],
    components: {
		AtblModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isSaveButtonDisabled() {
            return !this.model.note;
        }
    },
    data: function () {
        return {
            model: {
                note: null
            },
            errors: {},
            isLoading: false
        };
    },
    methods: {
        async onCreateNoteEvent() {
            this.isLoading = true;

            try {
                const response = await CallNotesService.createCallNote(
                    {
                        ...this.model,
                        callableId: this.callableId,
                        callableType: this.callableType
                    }
                );
                const { status, message } = response.data;

                window.showMessage(message, status);

                this.isLoading = false;

                if (!status) {
                    return;
                }

                this.clearModel();

                await this.onCallNoteModalClose(false, true);
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        clearModel() {
            this.model = {
                note: null
            };
        }
    }
}
</script>