export default {
    data() {
        return {
            emailTemplatesEnabledTypes: {
                playersEnabled: false,
                leadsEnabled: false,
                is_lead: false,
            },
        };
    },
}