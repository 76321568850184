var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    _vm._l(_vm.birthdayGroups, function(birthdayGroup, index) {
      return _c(
        "div",
        { key: "birthday_group_" + index, staticClass: "card card-list" },
        [
          _c(
            "div",
            {
              staticClass:
                "card-header d-flex align-items-center justify-content-between"
            },
            [
              _c("span", [
                _c("i", { staticClass: "fa fa-align-justify" }),
                _vm._v(" " + _vm._s(birthdayGroup.label))
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "general-list" },
              [
                _vm._m(0, true),
                _vm._v(" "),
                _vm._l(birthdayGroup.players, function(player) {
                  return _c(
                    "div",
                    {
                      key: "player_row_before_" + player.id,
                      staticClass: "row"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-3 mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Name")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(player.name) +
                              " " +
                              _vm._s(player.surname)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                        " +
                              _vm._s(player.email) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Birthday")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(player.birthdate.format("MMMM Do, YYYY")) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Agent")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(player.user ? player.user.name : "") +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Last call")
                        ]),
                        _vm._v(" "),
                        player.call
                          ? _c(
                              "div",
                              [
                                _c("call-status-badge", {
                                  attrs: { title: player.call.status }
                                }),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utcToLocal(player.call.created_at)
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Last disposition")
                        ]),
                        _vm._v(" "),
                        player.disposition
                          ? _c("div", [
                              _c(
                                "a",
                                {
                                  staticClass: "d-block",
                                  attrs: {
                                    href:
                                      "/players/" + player.id + "/dispositions",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        player.disposition.disposition.name
                                      ) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              player.disposition.follow_up
                                ? _c("small", { staticClass: "follow-up" }, [
                                    _vm._v(
                                      "\n                                Follow Up:\n                                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          player.disposition.follow_up || ""
                                        ) +
                                        "\n                            "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _vm.$can("Player - View Details")
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.viewPlayer(player.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            View Player\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-3" }, [_vm._v("Player")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Birthday")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Agent")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Last call")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Last disposition")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }