<template>
    <div class="container-fluid">
        <div v-for="log in logs" :key="`log_id_${log.id}`"
             class="card"
        >
            <div class="card-header d-flex align-items-center justify-content-between">
                <span>
                    <i class='fa fa-align-justify'></i>
                    Logs
                </span>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <atbl-fake-input label="ID">
                            {{ log.id }}
                        </atbl-fake-input>
                    </div>

                    <div class="col-12">
                      <atbl-fake-input label="Message">
                        {{ log.message }}
                      </atbl-fake-input>
                    </div>

                    <div class="col-12">
                      <atbl-fake-input label="Level">
                        {{ log.level }}
                      </atbl-fake-input>
                    </div>

                    <div class="col-12">
                      <atbl-fake-input label="File">
                        {{ log.file }}
                      </atbl-fake-input>
                    </div>

                    <div class="col-12">
                      <atbl-fake-input label="File line">
                        {{ log.file_line }}
                      </atbl-fake-input>
                    </div>

                    <div class="col-12">
                      <atbl-fake-input label="Date time">
                        {{ log.date_time }}
                      </atbl-fake-input>
                    </div>

                    <div class="col-12">
                        <atbl-form-group label-size="sm" label="Trace">
                            <pre v-html="log.trace"></pre>
                        </atbl-form-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AtblFakeInput from '../../components/Forms/Inputs/AtblFakeInput.vue';

export default {
    props: ['logs'],
    components: {
	    AtblFakeInput,
    },
}
</script>
