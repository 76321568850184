<template>
    <div class="card">
        <div class="card-header card-header-atbl"
             v-if="!isLoading && !canShowConfiguration"
        >
            <GameHeader v-if="!canShowConfiguration"
                        :game="game"
            />
        </div>
        <div class="card-header card-header-atbl"
             v-if="!isLoading && !!nextDrawOpen"
        >
            <current-draw-header-component :draw="nextDraw" />
        </div>

        <div class="card-body">
            <div v-if="!isLoading && !isObjectEmpty(configuration)">
                <template v-if="game.game.key.indexOf('loteria-nacional') === -1">
                    <Configuration v-for="(item, index) in configuration"
                                   :key="index"
                                   :configuration="configuration"
                                   :title="index.charAt(0).toUpperCase() + index.slice(1)"
                                   :type="index"
                                   :configurationTmp="configurationTmp"
                                   v-model="configurationTmp[index]"
                    />
                </template>
                <raffle-editor v-else
                               :configuration="configuration"
                               :old-game-draw="oldGameDraw"
                               @update-raffle="updateRaffle"
                               @update-configuration="onUpdateConfigurationEvent"
                               @remove-configuration="onRemoveConfigurationEvent"
                               @update-winner-prize-configuration="onUpdateWinnerPrizeConfigurationEvent"
                               @remove-field-configuration="onDeleteFieldEvent"
                />
            </div>

            <current-draw-editor-component v-if="!!nextDrawOpen"
                                           v-model="nextDrawModel"
                                           :loading="nextDrawLoader"
                                           :draw="nextDraw"
                                           :errors="nextDrawErrors"
                                           @input="nextDrawModel = $event"
            />

            <not-found v-if="!nextDrawOpen && !isLoading && canShowConfiguration"
                       :items="[]"
                       msg="Please select game draw"
            />

            <loader :show="isLoading"
                    type="small"
            />
            <not-found v-if="isLoading"
                       :items="[]"
                       :msg="loadingMessage"
            />
        </div>

        <div v-if="!isLoading && !isObjectEmpty(configuration) && !isRaffleGame" class="card-footer card-footer-atbl text-right">
            <button @click="cancelEdit"
                    class="btn btn-danger mr-2"
            >
                Close
            </button>
            <button v-if="$can('Game Draws - Save Configuration') && isConfigurationValid"
                    @click="saveEdit"
                    class="btn btn-success"
            >
                Save
            </button>
        </div>
        <div v-if="!isLoading && !isObjectEmpty(configuration) && isRaffleGame" class="card-footer card-footer-atbl text-right">
            <button @click="$options.Atbl.$emit('editConfigurationOpen', true)"
                    class="btn btn-danger mr-2"
            >
                Close
            </button>
            <button v-if="$can('Game Draws - Save Configuration') && !isObjectEmpty(raffleConfigurationValue) && isRaffleConfigurationValid"
                    @click="saveRaffle"
                    class="btn btn-success"
            >
                Save
            </button>
        </div>
        <div v-if="!!nextDrawOpen" class="card-footer card-footer-atbl text-right">
            <button :disabled="!!nextDrawLoader"
                    class="btn btn-danger mr-2"
                    @click="nextDrawOpen = false; nextDraw = null;"
            >
                Close
            </button>
            <button v-if="$can('Game Draws - Save Configuration')"
                    :disabled="!!nextDrawLoader"
                    class="btn btn-success"
                    @click="saveNextDraw"
            >
                <span v-if="!nextDrawLoader">Save</span>
                <loader :show="nextDrawLoader"
                        type="smaller"
                />
            </button>
        </div>

        <CancelConfigurationEditModal :show="showCancelEdit"
        />

        <ConfirmConfigurationEditModal :show="showConfirmEdit"
        />

        <ConfirmRaffleEdit :show="showConfirmRaffleEdit"
                           :game="game"
                           :configuration-temp="raffleConfigurationValue"
                           :loading="raffleEditLoader"
                           @show="value => showConfirmRaffleEdit = value"
                           @saveRaffle="saveRaffleConfiguration"
                           @close="cancelRaffle"
        />
    </div>
</template>

<script>
import GameDrawsService from "../../../../../services/GamesDrawsService";
import Configuration from "./Components/Configuration";
import ObjectMixin from "../../../../../mixins/Object";
import DrawEditorMixin from "../../Mixins/DrawsEditor";
import GameHeader from "./Components/GameHeader";
import CancelConfigurationEditModal from "./Components/CancelEdit";
import ConfirmConfigurationEditModal from "./Components/ConfirmEdit";
import RaffleEditor from '../RaffleEditor/RaffleEditor';
import ConfirmRaffleEdit from '../RaffleEditor/Components/ConfirmRaffleEdit';
import { clone } from '../../../../../utils/Json/Json';
import CurrentDrawEditorComponent from '../CurrentDraws/CurrentDrawEditorComponent';
import CurrentDrawHeaderComponent from '../CurrentDraws/CurrentDrawHeaderComponent';
import getValidationErrors from '../../../../../utils/Error/GetValidationErrors';

const NextDrawModel = {
    jackpot: null,
    jackpot_raw: null,
};

export default {
    name: "draws-editor",
    Atbl,
    mixins: [
        ObjectMixin,
        DrawEditorMixin
    ],
    components: {
        CurrentDrawHeaderComponent,
        CurrentDrawEditorComponent,
        Configuration,
        GameHeader,
        CancelConfigurationEditModal,
        ConfirmConfigurationEditModal,
        RaffleEditor,
        ConfirmRaffleEdit,
    },
    data() {
        return {
            showCancelEdit: false,
            showConfirmEdit: false,
            showConfirmRaffleEdit: false,
            raffleEditLoader: false,
            isLoading: false,
            loadingMessage: "",
            configuration: {},
            game: {},
            configurationTmp: {},
            isRaffleGame: false,
            raffleConfigurationValue: {},
            oldGameDraw: {},

            nextDrawOpen: false,
            nextDraw: {},
            nextDrawModel: clone(NextDrawModel),
            nextDrawLoader: false,
            nextDrawErrors: {},
        };
    },
    computed: {
        canShowConfiguration() {
            return this.isObjectEmpty(this.configuration);
        },
        isConfigurationValid() {
            return this.isObjectArraysEmpty(
                this.configurationTmp,
                Object.keys(this.configuration).length
            );
        },
        isRaffleConfigurationValid() {
            const allValues = this.nestedArrayToFlat(this.raffleConfigurationValue);

            return allValues.filter(item => !!item.isValid).length === allValues.length;
        }
    },
    methods: {
        isObjectArraysEmpty: function (o, count) {
            let validated = [];

            for (let item in o) {
                if (Array.isArray(o[item])) {
                    if(o[item].length > 0){
                        validated.push(true);
                    }
                }
            }
            return validated.length === count;
        },
        countArraysInObject: function(obj){
            let count = 0;
            for (let item in obj) {
                if (Array.isArray(obj[item])) {
                    count ++;
                }
            }
            return count;
        },
        getConfigurationResponse: function (draw) {
            let vm = this;

            vm.configuration = {};
            vm.configurationTmp = {};
            vm.game = {};
            vm.isLoading = true;
            vm.loadingMessage = "Draw loading...";

            this.isRaffleGame = draw.game.key.indexOf('loteria-nacional') !== -1

            const configuration = this.isRaffleGame
                ? GameDrawsService.getRaffleGameDrawConfiguration(draw.id)
                : GameDrawsService.getGameDrawConfiguration(draw.id);

            configuration.then(response => {
                vm.game = response.data.game;

                if(this.isRaffleGame) {
                    vm.oldGameDraw = response.data.old_game_draw;
                }

                let configuration = response.data.configuration,
                    configurationTmp = {},
                    hasBonusNumbers = !this.isRaffleGame
                        ? !!configuration.main.bonus
                        : false;

                if(hasBonusNumbers){
                    configurationTmp.main = configuration.main;
                    configurationTmp.bonus = configuration.main.bonus;
                    Object.assign(configurationTmp, _.omit(configuration,'main'));
                }

                const tmp = hasBonusNumbers
                    ? configurationTmp
                    : configuration;

                vm.configuration = this.isRaffleGame
                    ? this.resolveMatchesAndPrizes(tmp)
                    : tmp;

                vm.isLoading = false;
            });
        },
        saveConfiguration: function(id, configuration){
            GameDrawsService.saveGameDrawConfiguration(id, configuration)
                .then(response => {
                    let data = response.data;

                    if(data.status){
                        Atbl.$emit("saveConfigurationFinished");
                    }
                    flash(data.message, data.status ? "alert-success" : "alert-danger");
                })
                .catch(error => {
                    flash(error, "alert-danger");
                })
                .finally(() => {
                    Atbl.$emit("saveConfigurationError");
                });
        },
        saveRaffleConfiguration: function(){
            this.raffleEditLoader = true;

            GameDrawsService.saveRaffleGameDrawConfiguration(this.game.id, this.raffleConfigurationValue)
                .then(response => {
                    let data = response.data;

                    if(data.status){
                        this.raffleConfigurationValue = {};
                        this.game = {};
                        this.showConfirmRaffleEdit = false
                        Atbl.$emit("saveConfigurationFinished");
                    }
                    flash(data.message, data.status ? "alert-success" : "alert-danger");
                })
                .catch(error => {
                    flash(error, "alert-danger");
                })
                .finally(() => {
                    this.raffleEditLoader = false;
                });
        },
        saveEdit: function () {
            Atbl.$emit("saveConfigurationOpen", {
                show: true,
                configuration: this.configuration,
                configurationTmp: this.configurationTmp,
                game: this.game
            });
        },
        cancelEdit: function () {
            Atbl.$emit("saveConfigurationCancel", true);
        },
        updateRaffle(value) {
            this.raffleConfigurationValue = value;
        },
        onRemoveConfigurationEvent(type) {
            const temp = clone(this.configuration);

            const numbersType = temp.main.find(item => item.title === type);

            if (! numbersType) {
                return;
            }

            temp.main = temp.main.filter(item => item.title !== type);

            this.configuration = this.resolveMatchesAndPrizes(temp);
        },
        onUpdateConfigurationEvent(type, value) {
            if (!type) {
                return;
            }

            const temp = clone(this.configuration);

            const numbersType = temp.main.find(item => item.title === type);

            if (! numbersType) {
                return;
            }

            const typeIndex = temp.main.indexOf(numbersType);

            temp.main[typeIndex] = {
                ...temp.main[typeIndex],
                ...value
            };

            this.configuration = this.resolveMatchesAndPrizes(temp);
        },
        saveRaffle: function () {
            this.showConfirmRaffleEdit = true;
        },
        cancelRaffle: function () {
            this.showConfirmRaffleEdit = false;
        },
        resolveMatchesAndPrizes(configuration) {
            if (!['loteria-nacional-special'].includes(this.game.game.key)) {
                return configuration;
            }

            const keys = configuration.main
                .map((item, index) => {
                    const title = item.title.toLowerCase();

                    const isRefund = ['refund-a', 'refund-b'].includes(title);

                    const key = isRefund
                        ? `match-r-${title.split('-')[1]}`
                        : `match-${index + 1}`;

                    const obj = {
                        key,
                        title: item.title,
                        is_refund: isRefund,
                        childrenCount: 0
                    };

                    if (!isRefund) {
                        obj.editable = true;
                    }

                    return obj;
                });

            for (const item in configuration) {
                const value = configuration[item];

                if (item !== 'main' && Array.isArray(value)) {
                    configuration[item] = keys;
                }
            }

            return configuration;
        },
        onUpdateWinnerPrizeConfigurationEvent(value) {
            const config = clone(this.configuration);
            const { parent, key, title } = value;

            const prize = config.prizes.find(item => item.key === parent.key);
            const winner = config.winners.find(item => item.key === parent.key);

            if (!prize && !winner) {
                return;
            }

            const currentPrizeKeyItem = config.prizes.find(item => item.key === key);
            const currentWinnerKeyItem = config.prizes.find(item => item.key === key);

            if (currentPrizeKeyItem || currentWinnerKeyItem) {
                window.showMessage('Current key already exists.', false);

                return;
            }

            const index = config.prizes.indexOf(prize);

            const item1 = {
                key,
                title,
                editable: false,
                child: true,
                parent,
            };

            config.prizes.splice(index + prize.childrenCount + 1, 0, item1);
            config.winners.splice(index + winner.childrenCount + 1, 0, item1);

            prize.childrenCount++;
            winner.childrenCount++;
            parent.childrenCount++;

            this.configuration = config;
        },
        onDeleteFieldEvent(value) {
            const config = clone(this.configuration);
            const { parent, key } = value;

            const prize = config.prizes.find(item => item.key === key);
            const winner = config.winners.find(item => item.key === key);

            if (!prize && !winner) {
                return;
            }

            const index = config.prizes.indexOf(prize);

            config.prizes.splice(index, 1);
            config.winners.splice(index, 1);

            const parentPrizeValue = config.prizes.find(item => item.key === parent.key);
            const parentWinnerValue = config.winners.find(item => item.key === parent.key);

            parentPrizeValue.childrenCount--;
            parentWinnerValue.childrenCount--;

            this.configuration = config;
        },
        async saveNextDraw() {
            this.nextDrawErrors = {};
            this.nextDrawLoader = true;

            try {
                const response = await window.axios.post(`/games/draws/current-draws/${this.nextDraw.id}`, this.nextDrawModel);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.cancelEdit();
                    this.isLoading = false;
                    this.nextDrawOpen = false;
                    this.nextDraw = null;
                    this.nextDrawModel = clone(NextDrawModel);
                    Atbl.$emit('nextDrawSaved');
                }

                this.nextDrawLoader = false;
            } catch (error) {
                this.nextDrawLoader = false;

                this.nextDrawErrors = getValidationErrors(error);

                window.flashError(error);
            }
        },
    },
    mounted() {
        let vm = this;

        Atbl.$on("editDraw", (draw) => {
            window.scrollTo(0,0);
            this.nextDrawErrors = {};
            this.nextDrawOpen = false;
            this.nextDraw = null;
            this.nextDrawLoader = false;
            this.nextDrawModel = clone(NextDrawModel);
            this.getConfigurationResponse(draw);
        });

        Atbl.$on("editNextDraw", (draw) => {
            window.scrollTo(0,0);
            this.cancelEdit();
            this.nextDrawErrors = {};
            this.isLoading = false;
            this.nextDrawOpen = true;
            this.nextDraw = draw;
            this.nextDrawModel = {
                jackpot: draw.jackpot,
                jackpot_raw: !!draw.jackpot_raw ? draw.jackpot_raw.toFixed(2) : 0.00,
            };
        });

        Atbl.$on("editConfigurationOk", () => {
            this.configuration = {};
            this.configurationTmp = {};
            this.game = {};
            this.raffleConfigurationValue = {};
            this.oldGameDraw = {};
            this.loadingMessage = "";
            this.showConfirmRaffleEdit = false;
        });

        Atbl.$on("saveConfigurationCancel", () => {
            this.configuration = {};
            this.game = {};
            this.loadingMessage = "";
        });

        Atbl.$on("saveConfigurationOk", () => {
            const resolvedConfiguration = this.configurationResolver(
                this.configuration,
                this.configurationTmp
            );

            this.saveConfiguration(vm.game.id, resolvedConfiguration);
        });
    }
}
</script>