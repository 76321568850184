<template>
	<div class="container-fluid">
		<template v-if="!isLoading">
			<div class="row">
				<div class="col-12 col-sm-6">
					<div class="card">
						<div class="card-header card-header-atbl d-flex justify-content-between">
							<div>
								<span>Transaction</span>
								<strong>#{{ id }}</strong>
							</div>
							<div>
								<span :class="{
                                             'badge-success': !isFailed,
                                             'badge-danger': isFailed,
                                      }"
                                      class="badge"
                                >
									{{ isFailed ? 'Failed' : 'Success' }}
								</span>
							</div>
						</div>
						
						<div class="card-body">
							<atbl-fake-input label="Type">
								{{ transaction.payment_type +' - '+ transaction.type }}
							</atbl-fake-input>
							<atbl-fake-input label="Date created">
								{{ utcToLocal(transaction.created_at) }}
							</atbl-fake-input>
							<atbl-fake-input label="Date updated">
								{{ utcToLocal(transaction.updated_at) }}
							</atbl-fake-input>
							<atbl-fake-input label="Amount">
								{{ formattedAmount }}
							</atbl-fake-input>
							<atbl-fake-input label="Reference">
								{{ transaction.reference_id }}
							</atbl-fake-input>
							<atbl-fake-input label="Reference Transaction">
								{{ transaction.reference_transaction_id }}
							</atbl-fake-input>
							<atbl-fake-input v-if="transaction.payment_brand"
							                 label="Card Brand"
							>
								{{ transaction.payment_brand }}
							</atbl-fake-input>
							<atbl-fake-input label="Message">
								{{ transaction.response_message }}
							</atbl-fake-input>
							<atbl-fake-input v-if="transaction.created_by"
							                 label="Created By"
							>
								{{ transaction.created_by.created }}
							</atbl-fake-input>
							<atbl-fake-input label="Credit card last 4 digits">
								{{ transaction.last_4_digits ? `**** **** **** ${transaction.last_4_digits}` : null }}
							</atbl-fake-input>
						</div>
					</div>
				</div>
				
				<div class="col-12 col-sm-6">
					<div class="card">
						<div class="card-header card-header-atbl">
							<span>Player</span>
							<a v-if="$can('Player - View Details')"
							   :href="`/players/${transaction.player.id}/view`"
							   target="_blank"
							   class="font-weight-bold"
							>
								#{{ transaction.player.id }}
							</a>
							<strong v-else>
								#{{ transaction.player.id }}
							</strong>
						</div>
						
						<div class="card-body">
							<atbl-fake-input label="Name">
								{{this.transaction.player.name}} {{this.transaction.player.surname}}
							</atbl-fake-input>
							<atbl-fake-input label="Email">
								{{transaction.player.email}}
							</atbl-fake-input>
						</div>
					</div>
					
					<div v-if="transaction.meta"
					     class="card"
					>
						<div class="card-header card-header-atbl">
							Meta
						</div>
						
						<div class="card-body">
							<atbl-fake-input v-for="[key, value] in Object.entries(transaction.meta)"
							                 :label="key"
							                 :key="key"
							>
								{{value}}
							</atbl-fake-input>
						</div>
					</div>
				</div>
			</div>
		</template>
		<loader :show="isLoading"
		        type="small"
		/>
	</div>
</template>

<script>
import Loader from "../../../components/Widgets/Loader.vue";
import AtblFakeInput from "../../../components/Forms/Inputs/AtblFakeInput.vue";

export default {
	name: "transaction-details",
	components: {
		Loader,
		AtblFakeInput,
	},
	props: {
		id: {
			type: Number,
			required: true,
		}
	},
	data() {
		return {
			transaction: {
				id: null,
				updated_at: null,
				player_id: null,
				user_id: null,
				payment_type: null,
				payment_brand: null,
				response_message: null,
				amount: null,
				currency: null,
				reference_id: null,
				reference_transaction_id: null,
				last_4_digits: null,
				meta: null,
				type: null,
				created_by: {
					created: null,
					color: null,
				},
				payment_type_beauty: null,
				transaction_status: null,
				player: {
					id: null,
					name: null,
					surname: null,
					email: null,
				},
				user: {
					id: null,
					name: null,
				}
			},
			isLoading: true
		}
	},
	computed: {
		formattedAmount() {
			return this.$options.filters.formatCurrency(this.transaction.amount);
		},
		isFailed() {
			return this.transaction.transaction_type === 0;
		}
	},
	async created() {
		await this.getTransaction(this.id);
	},
	methods: {
		async getTransaction(id) {
			this.isLoading = true;
			
			try {
				const response = await window.axios.get(`/transaction/${id}/show`);
				this.transaction = response.data;
				
				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				
				if (this.hasPermissionError(error)) {
					return;
				}
				
				const {message} = error;
				
				window.showMessage(message, false);
			}
		},
	},
}
</script>
