<template>
    <atbl-form-group :label="label"
                     :label-for="id"
                     :error="getError(error, name)"
    >
        <div class="d-flex align-items-center">
            <img v-if="region"
                 :src="getFlag"
                 class="tel-input-flag"
            />
            <input :value="value"
                   :class="{
                        'is-invalid': hasError(error, name)
                   }"
                   :id="id"
                   :placeholder="placeholder"
                   class="form-control tel-input"
                   type="tel"
                   @input="onInputEvent($event.target.value)"
            />
        </div>
        <div v-if="!!errorMessage"
             class="alert alert-danger p-2"
        >
          {{ errorMessage }}
        </div>
    </atbl-form-group>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber';

export default {
    name: 'tel-number',
    props: {
        value: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: 'phone',
        },
        label: {
            type: String,
            default: 'Phone',
        },
        placeholder: {
            type: String,
            default: 'Enter Phone',
        },
        name: {
            type: String,
            default: 'phone',
        },
        error: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            region: null,
            errorMessage: null
        };
    },
    computed: {
        number: {
            get() {
                return this.value;
            },
            set(value) {
                this.onInputEvent(value);
            },
        },
        getFlag() {
            return '/img/flags/' + this.region.toLowerCase() + '.svg';
        },
    },
    created() {
        if (!this.value) {
            return;
        }

        this.onInputEvent(this.value);
    },
    methods: {
        onInputEvent(value) {
            this.region = null;
            this.errorMessage = null;

            if (!value) {
                this.onUpdateEvent();

                return;
            }

            const telNumber = this.innerTrim(value.replace(/^(00)/, '+'));

            this.region = PhoneNumber(telNumber).getRegionCode() ?? null;

            if (!telNumber.includes('+')) {
                this.errorMessage = 'Phone should contains + sign in front.';

                return;
            }

            const regex = /[`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~a-zA-z]/;

            if (regex.test(telNumber)) {
                this.errorMessage = 'Phone cannot contains alphabet characters or special characters.';

                return;
            }

            this.onUpdateEvent(telNumber);
        },
        onUpdateEvent(value = null) {
            this.$emit('input', value);
        },
    },
    watch: {
        value: {
            handler(value) {
                if (!value) {
                    return;
                }

                this.onInputEvent(value);
            },
            immediate: true
        }
    }
}
</script>

<style scoped lang="scss">
.tel-input {
    &-flag {
        height       : 20px;
        margin-right : 5px;
    }
}
</style>