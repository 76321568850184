export default {
    async getEmailTemplates(place = 'players'){
        return await window.axios.get(`/email-templates/${place}`);
    },
    async sendEmail(params = {}, locale){
        return await window.axios.post(`/email-templates/send-email`, params, {
            headers: {
                "X-Localization": locale
            }
        });
    }
}