import TypesConst from "../../../TypesConst";
import ConfigurationsService from "../../../../services/ConfigurationsService";
import SettingsService from "../../../../services/SettingsService";
import {clone} from '../../../../utils/Json/Json';

export default {
    // settings
    [TypesConst.settings.actions.OPEN_SETTINGS_MODAL_ACTION]({commit}, isOpen){
        commit(TypesConst.settings.mutations.SET_OPEN_SETTINGS_MODAL, isOpen);
    },
    [TypesConst.settings.actions.REMOVE_SETTINGS_ACTION]({commit}, setting){
        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE, setting);
        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL, true);
    },
    [TypesConst.settings.actions.OPEN_CLOSE_REMOVE_SETTINGS_ACTION]({commit}, isOpen){
        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL, isOpen);
    },
    async [TypesConst.settings.actions.FETCH_SETTINGS_TYPES_ACTION]({commit}){
        let settingsTypes = await ConfigurationsService.getSettingsTypes();
        commit(TypesConst.settings.mutations.SET_SETTINGS_TYPES, settingsTypes.data);
    },
    async [TypesConst.settings.actions.SAVE_SETTINGS_ACTION]({commit, dispatch, state}, model){
        commit(TypesConst.settings.mutations.SET_SETTINGS_FORM_ERRORS, {});
        commit(TypesConst.settings.mutations.SET_SETTINGS_MODAL_LOADER, true);

        SettingsService.saveNewSettings("0", model)
            .then(response => {
                const { status, message } = response.data;
                commit(TypesConst.settings.mutations.SET_SETTINGS_MODAL_LOADER, false);

                if(status){
                    commit(TypesConst.settings.mutations.SET_OPEN_SETTINGS_MODAL, false);
                    commit(TypesConst.settings.mutations.CLEAR_SETTINGS_FORM_MODEL);
                    dispatch(TypesConst.settings.actions.OPEN_SECTION_ACTION, {
                        sectionId: state.parent_id
                    });
                }

                flash(message, status ? "alert-success" : "alert-danger");
            })
            .catch(error => {
                commit(TypesConst.settings.mutations.SET_SETTINGS_FORM_ERRORS, error.response.data.errors);
                commit(TypesConst.settings.mutations.SET_SETTINGS_MODAL_LOADER, false);
            });
    },
    async [TypesConst.settings.actions.EDIT_SETTING_ACTION]({commit}, setting){
        commit(TypesConst.settings.mutations.SET_OPEN_SETTINGS_MODAL, true);
        commit(TypesConst.settings.mutations.SET_SETTINGS_MODAL_LOADER, true);

        if(setting.setting_id){
            commit(TypesConst.settings.mutations.SET_SETTING_ID, setting.setting_id);
        }
        let section = await SettingsService.getSettingById(setting.setting_id);
        commit(TypesConst.settings.mutations.SET_SETTINGS_FORM_MODEL, section.data);

        commit(TypesConst.settings.mutations.SET_SETTINGS_MODAL_LOADER, false);
    },
    async [TypesConst.settings.actions.SAVE_SETTINGS_FAST_ACTION]({commit, state}, setting){
        commit(TypesConst.settings.mutations.SET_SETTINGS_SAVE_LOADER, true);

        let section = await SettingsService.saveImmediateSettings(setting.id, setting),
            message = section.data.message;

        const allSettings = clone(state.settings);
        const settingEntity = allSettings.find(item => item.id === setting.id);
        settingEntity.value = setting.value;

        commit(TypesConst.settings.mutations.SET_SETTINGS, allSettings);

        commit(TypesConst.settings.mutations.SET_SETTINGS_SAVE_LOADER, false);
        commit(TypesConst.settings.mutations.SET_SETTINGS_MODAL_LOADER, false);
        flash(message, section.data.status ? "alert-success" : "alert-danger");
    },
    async [TypesConst.settings.actions.REMOVE_SETTING_CONFIRM_ACTION]({commit, state, dispatch}){
        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL_LOADER, true);

        let section = await SettingsService.removeSettingById(state.removeSetting.id),
            message = section.data.message;

        if(section.data.status){
            dispatch(TypesConst.settings.actions.OPEN_SECTION_ACTION, {
                sectionId: state.parent_id
            });
            commit(TypesConst.settings.mutations.SET_SETTING_REMOVE, {});
        }

        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL, false);
        commit(TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL_LOADER, false);
        flash(message, section.data.status ? "alert-success" : "alert-danger");
    }
};