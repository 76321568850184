<template>
    <atbl-form-group label="Transaction Type"
                     label-for="paymentType"
                     class="font-weight-bold"
                     :horizontal="horizontal"
    >
        <tree-select v-model="selectedType"
                     :multiple="true"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="types"
                     :normalizer="(node) => ({id: node, label: node})"
                     :disabled="!!disabled || !!isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
    </atbl-form-group>
</template>

<script>
import TransactionsService from '../../../services/TransactionsService';

export default {
    name: 'payment-types-options',
    emits: [
        'input',
        'update',
    ],
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        errors: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'paymentType'
        },
        horizontal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            types: [],
            isLoading: false
        };
    },
    computed: {
        selectedType: {
            get() {
                if (!!this.isLoading) {
                    return [];
                }

                return !this.value.length ? [] : this.value;
            },
            set: function (value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        }
    },
    async created() {
        await this.getTypes();
    },
    methods: {
        async getTypes() {
            this.isLoading = true;

            const response = await TransactionsService.getTransactions();

            this.types = response.data;

            this.isLoading = false;
        },
    },
}
</script>
