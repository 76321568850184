var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isset(_vm.gameConfiguration, "key")
    ? _c(
        "div",
        { staticClass: "card player-play mb-3" },
        [
          _c("raffle-game-header", {
            attrs: { config: _vm.gameConfiguration }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "player-play-picker" },
              [
                _c("validation-errors", { attrs: { errors: _vm.errors } }),
                _vm._v(" "),
                _c("div", { staticClass: "player-play-picker-wrapper" }, [
                  _c(
                    "div",
                    [
                      _c("raffle-lines", {
                        attrs: {
                          "game-key": _vm.gameConfiguration.key,
                          "selected-free-game": _vm.selectedFreeGame
                        },
                        on: {
                          error: function($event) {
                            return _vm.onLineErrors($event)
                          }
                        },
                        model: {
                          value: _vm.lines,
                          callback: function($$v) {
                            _vm.lines = $$v
                          },
                          expression: "lines"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.hasSelectedFreeGame && _vm.closeToNextFractionDiscount
                    ? _c(
                        "div",
                        { staticClass: "alert alert-warning mt-1 p-2 w-100" },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n                            Note: You are one fraction away to get a " +
                                _vm._s(
                                  _vm.closeToNextFractionDiscount.discount_crm
                                ) +
                                "% discount !\n                        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "buy" }, [
                      _c("div", { staticClass: "buy-total" }, [
                        _c("table", [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("tr", { staticClass: "border-bottom" }, [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("td", { attrs: { align: "right" } }, [
                              !_vm.hasSelectedFreeGame
                                ? _c(
                                    "span",
                                    { staticClass: "buy-total-price ml-1" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.totalFractions) +
                                          " x " +
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              _vm.gameConfiguration.price
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "buy-total-price ml-1" },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(_vm.totalFractions) +
                                          " "
                                      ),
                                      _c("s", [
                                        _vm._v(
                                          "x " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.gameConfiguration.price
                                              )
                                            )
                                        )
                                      ])
                                    ]
                                  )
                            ])
                          ]),
                          _vm._v(" "),
                          !_vm.hasSelectedFreeGame
                            ? _c("tr", { staticClass: "border-bottom" }, [
                                _vm._m(2),
                                _vm._v(" "),
                                _c("td", { attrs: { align: "right" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "buy-total-price ml-1" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.totalDiscount) +
                                          " %\n\t\t\t\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("tr", { staticClass: "border-bottom" }, [
                            _vm._m(3),
                            _vm._v(" "),
                            !_vm.hasSelectedFreeGame
                              ? _c("td", { attrs: { align: "right" } }, [
                                  _vm.totalDiscount === 0
                                    ? _c(
                                        "strong",
                                        { staticClass: "buy-total-price ml-1" },
                                        [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.totalPrice
                                                )
                                              ) +
                                              "\n                                            "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.totalDiscount !== 0
                                    ? _c(
                                        "strong",
                                        { staticClass: "buy-total-price ml-1" },
                                        [
                                          _c(
                                            "s",
                                            { staticClass: "text-danger mr-2" },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatCurrency(
                                                      _vm.totalPrice
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.totalWithDiscount
                                                )
                                              ) +
                                              "\n                                            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _c("td", { attrs: { align: "right" } }, [
                                  _c(
                                    "strong",
                                    { staticClass: "buy-total-price ml-1" },
                                    [
                                      _c(
                                        "s",
                                        { staticClass: "text-danger mr-2" },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.totalPrice
                                                )
                                              ) +
                                              "\n                                                "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(_vm.formatCurrency(0)) +
                                          "\n                                            "
                                      )
                                    ]
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          !_vm.hasSelectedFreeGame && _vm.totalDiscount !== 0
                            ? _c("tr", [
                                _vm._m(4),
                                _vm._v(" "),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.totalPrice - _vm.totalWithDiscount
                                        )
                                      ) +
                                      "\n                                        "
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buy-operations" }, [
                        !_vm.hasSelectedFreeGame
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "buy-button btn-sm mb-2 btn-success",
                                attrs: {
                                  disabled: _vm.lineHasErrors,
                                  name: "buyBtn",
                                  type: "submit"
                                },
                                on: { click: _vm.addToCart }
                              },
                              [_vm._m(5)]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.hasSelectedFreeGame
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-danger buy-button text-uppercase",
                                attrs: {
                                  disabled:
                                    _vm.lineHasErrors || _vm.isPlacingOrder
                                },
                                on: { click: _vm.addToCartAndCheckout }
                              },
                              [
                                !_vm.isPlacingOrder
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa fa-credit-card",
                                        attrs: { "aria-hidden": "false" }
                                      }),
                                      _vm._v(
                                        " Add & Place Order\n                                    "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("loader", {
                                  attrs: {
                                    show: _vm.isPlacingOrder,
                                    type: "smaller"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasSelectedFreeGame
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-warning buy-button text-uppercase",
                                attrs: {
                                  disabled:
                                    _vm.lineHasErrors || _vm.isUseFreeLoading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.useFreeTicket(_vm.ticket())
                                  }
                                }
                              },
                              [
                                !_vm.isUseFreeLoading
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa fa-ticket-alt",
                                        attrs: { "aria-hidden": "false" }
                                      }),
                                      _vm._v(
                                        " Use Free Ticket\n                                    "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("loader", {
                                  attrs: {
                                    show: _vm.isUseFreeLoading,
                                    type: "smaller"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "border-bottom" }, [
      _c("td", { attrs: { align: "left" } }, [_c("span", [_vm._v("Draws:")])]),
      _vm._v(" "),
      _c("td", { attrs: { align: "right" } }, [
        _c("span", { staticClass: "buy-total-price ml-1" }, [_vm._v("1")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Fractions: ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Discount: ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [_c("b", [_vm._v("Total:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "left" } }, [
      _c("span", [_vm._v("Saved:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", {
        staticClass: "fa fa-shopping-cart",
        attrs: { "aria-hidden": "false" }
      }),
      _vm._v(" Add to Cart\n                                    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }