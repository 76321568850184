import Campaigns from "../constants/Campaigns";

export default {
    data: function(){
        return {
            get sellStatusName(){
                return Campaigns.SaleStatusName;
            }
        };
    }
};
