export default {
	ticketRoute: function () {
		return `/sticket`;
	},
	ticketIdRoute: function (id) {
		return `${this.ticketRoute()}/${id}`;
	},
	getTicketById(id) {
		return window.axios.get(this.ticketIdRoute(id));
	},
	getRaffleById(id) {
		return window.axios.get(`/raffle-tickets/${id}/show`);
	},
	validateTicket: function (type, ticket) {
		return window.axios.post(`/lt/ticket/tickets/validate/${type}`, ticket)
	}
}