var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.player.call_status && !!_vm.player.call_created_at
    ? _c("div", { staticClass: "mb-2" }, [
        _c("span", { staticClass: "font-weight-bold mr-1" }, [_vm._v("Call")]),
        _vm._v(" "),
        _c("span", { staticClass: "badge badge-primary" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.ucFirst(_vm.player.call_status)) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("small", [_vm._v(_vm._s(_vm.player.call_created_at))])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }