<template>
    <span :class="['badge', `badge-${variant}`]">
        <b>{{status}}{{ declineReason ? ` - ${declineReason}` : '' }}</b>
    </span>
</template>

<script>
    export default {
        name: "withdrawals-status-badge",
        props: {
            status: {
                type: String,
                required: true,
            },
            declineReason: {
                type: String,
                required: false,
            },
        },
        computed: {
            variant: function(){
                let statusVariant;

                switch(this.status){
                    case "PENDING":
                            statusVariant = "primary";
                        break;
                    case "APPROVED":
                            statusVariant = "success";
                        break;
                    case "DECLINED":
                            statusVariant = "danger";
                        break;
                    default:
                            statusVariant = "primary";
                        break;
                }

                return statusVariant;
            }
        }
    }
</script>