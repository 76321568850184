<template>
    <div class="player-play-header">
        <div class="mb-1 d-flex align-items-center justify-content-center justify-content-xl-between">
            <div class="d-flex align-items-center">
                <img :src="$imageService.gameImage(game.image)" class="game-thumb mx-2" :alt="game.name">
                <h5 class="text-muted m-0">{{ game.name }}</h5>
            </div>
            <button class="btn btn-sm btn-link"
                      @click="closeGame"
            >
                <i class="fa fa-times" aria-hidden="false"></i>
            </button>
        </div>
        <div class="player-play-header-description">
            <p class="text-muted">
                <strong>Draws Every: </strong>
                <span>{{ game.weekdays | shortDays }} @ {{ this.game.cutOffTime | dateFormat('H:mm', false) }}</span>
                <strong>Next Draw Closes:</strong> <span>{{ this.game.cutOffTime | dateFormat('ddd D MMM @ H:mm', false) }}</span>
                <strong>Est. Jackpot:</strong> <span>{{ game.jackpot }}</span>
            </p>
            <p class="text-muted">
                Play
                <span v-for="(item, index) in configuration" :key="index">
                    {{ !item.key ? game.name : `${getKeyIndexByType(index) === getKeyNumbersLength ? "and " : ", "}
                    ${ucFirst(item.key)}` }}
                    {{ item.numbers }} number{{ item.numbers > 1 ? "s" : ""}} from {{ item.minRange }} to {{ item.maxRange }}
                </span>
                or pick Lucky Dip.
            </p>
        </div>
    </div>
</template>

<script>
	import TypesConst from "../../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../../store/NamespaceConst";
	import { createNamespacedHelpers } from "vuex";

	const {
		mapMutations: mapCartMutations
	} = createNamespacedHelpers(NamespaceConst.cart);

    export default {
        name: "LotteryGameHeader",
        props: {
            game: {
                type: Object,
                required: true
            },
            configuration: {
                type: Object,
                required: true
            }
        },
        filters: {
            shortDays: function (days) {
                if (!days) return '';

                return days.map(function (day) {
                    return day.substr(0, 3)
                }).join(', ')
            }
        },
        computed: {
            getKeyNumbersLength: function(){
                return Object.keys(this.configuration).length - 1;
            },
        },
        methods: {
        	...mapCartMutations({
		        setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
		        setCheckout: TypesConst.cart.mutations.SET_CHECKOUT
	        }),
            getKeyIndexByType: function(type){
                return Object.keys(this.configuration).indexOf(type);
            },
	        closeGame: function(){
				this.setShowGame(false);
				this.setCheckout({
					checkout: false,
					total: 0
				});
	        }
        }
    }
</script>