<template>
    <div class="loader" :class="type" v-if="show">&nbsp;</div>
</template>

<script>
    export default {
        name: "loader",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: "big"
            }
        }
    }
</script>