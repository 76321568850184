<template>
    <atbl-modal title="Add Setting"
                :show="openModal"
                :close-on-backdrop="false"
                @close="openAddSectionModal(false)"
    >
        <div v-if="!isLoading" class="row">
            <div class="col-12">
                <atbl-input label="Name *"
                            :value="model.name"
                            @update="updateForm"
                            :errors="errors"
                />
            </div>
            <div class="col-12">
                <atbl-input label="Key *"
                            name="key"
                            :value="model.key"
                            @update="updateForm"
                            :errors="errors"
                />
            </div>
            <div class="col-12">
                <atbl-input label="Value *"
                            name="value"
                            :value="model.value"
                            @update="updateForm"
                            :errors="errors"
                />
            </div>
            <div class="col-12">
                <atbl-select label="Type *"
                             name="type"
                             :value="model.type"
                             @update="updateForm"
                             :errors="errors"
                             :options="settingsSectionsTypes"
                />
            </div>
            <div class="col-6" v-if="isToggle">
                <atbl-input label="Off Value"
                            name="off_value"
                            :value="model.off_value"
                            @update="updateForm"
                            :errors="errors"
                />
            </div>
            <div class="col-6" v-if="isToggle">
                <atbl-input label="On Value"
                            name="on_value"
                            :value="model.on_value"
                            @update="updateForm"
                            :errors="errors"
                />
            </div>
        </div>
        <h5 class="text-center text-uppercase" v-else>
            Loading...
        </h5>

        <template #footer>
            <div class="d-flex w-100">
                <div class="w-50 font-weight-bold">
                    * Required
                </div>
                <div class="w-50 text-right">
                    <button class="btn btn-sm btn-danger"
                            @click="handleCancel"
                    >
                        Close
                    </button>
                    <button @click="handleOK"
                            class="btn"
                            :class="{
                               'btn-primary': !isLoading,
                               'btn-outline-primary': isLoading,
                            }"
                            v-if="$can('Settings - Create/Update')"
                    >
                        <span v-if="!isLoading"
                        >
                            Save
                        </span>
                        <loader type="small"
                                :show="isLoading"
                        />
                    </button>
                </div>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    import AtblModal from '../../../../components/AtblModal/AtblModal.vue';
    const {
        mapActions,
        mapGetters,
        mapMutations
    } = createNamespacedHelpers(NamespaceConst.settings);

    export default {
        name: "add-settings-modal",
        components: {
			AtblModal,
        },
        computed: {
            ...mapGetters({
                showModal: TypesConst.settings.getters.GET_ADD_SETTINGS_MODAL,
                settingsSectionsTypes: TypesConst.settings.getters.GET_SETTINGS_TYPES,
                parentId: TypesConst.settings.getters.GET_PARENT_ID,
                isLoading: TypesConst.settings.getters.GET_SETTINGS_MODAL_LOADER,
                settingId: TypesConst.settings.getters.GET_SETTING_ID,
                errors: TypesConst.settings.getters.GET_SETTINGS_FORM_ERRORS,
                model: TypesConst.settings.getters.GET_SETTINGS_FORM_MODEL,
            }),
            openModal: {
                get: function(){
                    return this.showModal;
                },
                set: function(body){
                    this.openAddSectionModal(body);
                }
            },
            hasName: function(){
                return this.model.name.length < 3;
            },
            hasKey: function(){
                return this.model.key.length < 3;
            },
            hasValue: function(){
                return this.model.value.length;
            },
            hasType: function(){
                return this.model.type !== null;
            },
            enableSave: function(){
                return !this.hasKey && !this.hasName && this.hasType && this.hasValue;
            },
            isToggle: function(){
                return this.model.type === "toggle";
            }
        },
        methods: {
            ...mapActions({
                openAddSectionModal: TypesConst.settings.actions.OPEN_SETTINGS_MODAL_ACTION,
                getSettingsTypes: TypesConst.settings.actions.FETCH_SETTINGS_TYPES_ACTION,
                saveSettings: TypesConst.settings.actions.SAVE_SETTINGS_ACTION
            }),
            ...mapMutations({
                clearSettingModel: TypesConst.settings.mutations.CLEAR_SETTINGS_FORM_MODEL,
                clearSettingId: TypesConst.settings.mutations.SET_SETTING_ID,
                updateModel: TypesConst.settings.mutations.UPDATE_SETTINGS_FORM_MODEL
            }),
            updateForm: function(field, value){
                this.updateModel({
                    [field]: value
                });
            },
            handleOK: function(evt){
                evt.preventDefault();

                if(this.enableSave){
                     let model = {
                         ...this.model
                     };
                    model.category_id = this.parentId;
                    if(this.settingId){
                        model.setting_id = this.settingId;
                    }
                    this.saveSettings(model);
                }
            },
            handleCancel: function(){
                this.openModal = false;
                this.clearSettingModel();
                this.clearSettingId(0);
            }
        },
        created: function(){
            this.getSettingsTypes();
        }
    }
</script>