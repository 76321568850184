var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          class: ["atbl-dropdown-item", { disabled: !!_vm.disabled }],
          attrs: { role: "menuitem" }
        },
        "component",
        _vm.componentAttributes,
        false
      ),
      _vm.componentListeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }