var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game", attrs: { title: _vm.item.gameable.title } },
    [
      _c("div", { staticClass: "ribbon-primary" }, [
        _c("span", [
          _vm._v(
            "Syndicate " +
              _vm._s(
                _vm.item.number_of_tickets > 1
                  ? "x " + _vm.item.number_of_tickets
                  : ""
              )
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-holder" }, [
        _c("img", {
          staticClass: "game-thumb",
          attrs: {
            src: _vm.$imageService.gameImage(_vm.item.gameable.game.image)
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "game-play" }, [
        _c(
          "p",
          { staticClass: "mb-1", attrs: { title: _vm.item.gameable.title } },
          [_vm._v(_vm._s(_vm.item.gameable.title))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "game-play-info" },
          _vm._l(_vm.item.meta, function(item, key) {
            return _c("span", [
              _c("b", [_vm._v(_vm._s(_vm.ucFirst(key)) + ":")]),
              _vm._v(" " + _vm._s(item) + "\n                    ")
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn",
            class: {
              "btn-primary": !_vm.isLoading,
              "btn-outline-primary": _vm.isLoading
            },
            on: { click: _vm.playGame }
          },
          [
            !_vm.isLoading ? _c("span", [_vm._v("Play")]) : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }