var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        show: _vm.show,
        "close-on-backdrop": false,
        title: "Remove Campaign"
      },
      on: { close: _vm.toggleRemoveCampaignModal },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "w-50 text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-sm",
                    on: { click: _vm.toggleRemoveCampaignModal }
                  },
                  [_vm._v("\n                Close\n            ")]
                ),
                _vm._v(" "),
                _vm.$can("Campaigns - Remove")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm",
                        class: {
                          "btn-primary": !_vm.isLoading,
                          "btn-outline-primary": _vm.isLoading
                        },
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.removeCampaign }
                      },
                      [
                        !_vm.isLoading
                          ? _c("span", [
                              _vm._v(
                                "\n                    Confirm\n                "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { type: "small", show: _vm.isLoading }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h4", { staticClass: "text-center" }, [
        _vm._v("\n        Do you really want to remove this campaign?\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }