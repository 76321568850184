<template>
    <div class="game" :title="item.gameable.title">
        <div class="ribbon-primary"><span>Syndicate {{ item.number_of_tickets > 1 ? `x ${item.number_of_tickets}` : ''}}</span></div>
        <div class="img-holder">
            <img class="game-thumb" :src="$imageService.gameImage(item.gameable.game.image)" />
        </div>

        <div class="game-play">
            <p class="mb-1" :title="item.gameable.title">{{ item.gameable.title }}</p>
            <div class="game-play-info">
                    <span v-for="(item, key) in item.meta">
                        <b>{{ ucFirst(key) }}:</b> {{ item }}
                    </span>
            </div>
            <button :class="{
						'btn-primary': !isLoading,
						'btn-outline-primary': isLoading
			          }"
                      class="btn"
                      @click="playGame"
            >
                <span v-if="!isLoading">Play</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Syndicate',
    inject: [
        'clearCart',
        'selectFreeGame',
    ],
    props: {
        item: {
            type: Object,
            required: true
        },
        orderId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        playGame() {
            this.$store.commit('cart/setCheckout', { checkout: false });
            this.$store.commit('cart/setShowGame', false);

            if (this.orderId) {
                this.clearCart();
            }

            this.selectFreeGame(this.item);

            setTimeout(() => {
                this.$store.commit('cart/setShowGame', true);
                this.$store.commit('games/setGameConfigurationData', this.item.gameable);
            }, 150);
        },
    }
}
</script>