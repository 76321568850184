<template>
    <label class="atbl-checkbox"
           :class="{
               disabled: !!disabled,
               'partial-check': !!partialCheck && !isChecked
           }"
    >
        <input :name="name"
               :checked="isChecked"
               type="checkbox"
               @change="changeValue"
        />
        <span v-if="!!label">{{ label }}</span>
    </label>
</template>

<script>
export default {
    name: 'ATBLCheckBox',
    props: {
        value: {
            type: [String, Number, Boolean],
            default: false
        },
        label: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: 'name'
        },
        errors: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        checked: {
            type: Boolean,
            default: false
        },
        trueValue: {
            default: true,
        },
        falseValue: {
            default: false,
        },
        partialCheck: {
            default: false,
        },
    },
    computed: {
        isChecked() {
            return !!this.checked || (this.value === this.trueValue);
        }
    },
    methods: {
        changeValue(event) {
            const isChecked = event.target.checked;
            const value = isChecked
                ? this.trueValue
                : this.falseValue;

            this.$emit('input', value);
            this.$emit('update', this.name, value);
        }
    }
}
</script>