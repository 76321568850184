import { clone } from '../../../utils/Json/Json';
import moment from 'moment';
import RemapModel from './RemapModel';

export default {
    provide() {
        return {
            remapPlayers: this.remapPlayers,
            toggleRemapPlayers: this.toggleRemapPlayers,
        };
    },
    data() {
        return {
            isRemapOpen: false,
            isRemapLoading: false,
            remapErrors: [],
            remapModel: clone(RemapModel),
        };
    },
    methods: {
        initializeRemapModel() {
            this.remapModel = {
                dateFrom: moment().subtract(1, 'week').format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
            };
        },
        async remapPlayers() {
            this.remapErrors = [];
            this.isRemapLoading = true;

            try {
                const response = await window.axios.post('/my-affiliate/remap-players', this.remapModel);
                const { status, message, data: { errors } } = response.data;

                this.remapErrors = errors;

                window.showMessage(message, status);

                if (!status) {
                    this.isRemapLoading = false;

                    return;
                }

                this.toggleRemapPlayers();

                await this.getMyAffiliateAffiliates();

                this.isRemapLoading = false;
            } catch (error) {
                this.isRemapLoading = false;

                window.flashError(error);
            }
        },
        toggleRemapPlayers() {
            this.isRemapOpen = !this.isRemapOpen;
        },
    },
}