import { parse, stringify, clone } from '../utils/Json/Json';
import { isObject, isObjectEmpty, isset } from '../utils/Object/Object';

export default {
    methods: {
        isObjectEmpty,
        isObject,
        parse,
        stringify,
        clone,
        reverseObject: function(obj) {
            return _.transform(obj, function(res, val, key) {
                if(_.isPlainObject(val)) {
                    res[key] = this.reverseObject(val);
                } else {
                    res[val] = key;
                }
            });
        },
        objectToArray: function(obj){
            return Object.keys(obj).map(i => {
                return {
                    text: i,
                    value: obj[i]
                }
            });
        },
        isset,
    }
};