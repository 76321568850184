import Vue from "vue";
import Vuex from "vuex";
import general from "./modules/general";
import settings from "./modules/settings";
import players from "./modules/players";
import tickets from "./modules/tickets";
import transactions from "./modules/transactions";
import users from "./modules/users";
import cart from "./modules/cart";
import payment from "./modules/payment";
import games from "./modules/games";
import callback from "./modules/callback";
import notifications from "./modules/notifications";
import order from "./modules/order";
import paymentGateways from "./modules/payment-gateways";
import exchangeOffice from "./modules/exchange-office";

// load vuex
Vue.use(Vuex);

// store
export default new Vuex.Store({
    strict: true,
    modules: {
        general,
        settings,
        players,
        tickets,
        transactions,
        users,
        cart,
        payment,
        games,
        callback,
        notifications,
        order,
        paymentGateways,
        exchangeOffice,
    },
});
