<template>
    <div class="card flex-fill draw-results"
    >
        <div class="card-header card-header-atbl">
            Draw
        </div>

        <div class="card-body">
            <div class="row"
                   v-if="!ticketLoader"
            >
                <div class="col-12 col-lg-3 text-center">
                    <img :src="$imageService.gameImage(game.image)" alt="`${game.image}`" class="img-fluid" width="80" />
                </div>
                <div class="col-12 col-lg-8">
                    <not-found v-if="!draw.game_draw || (draw.game_draw && !draw.game_draw.numbers)"
                               msg="Lottery numbers not drawn yet."
                               :items="[]"
                               textAlign="left"
                    />
                    <div class="numbers-group">
                        <template v-if="draw.game_draw && draw.game_draw.numbers">
                            <div v-for="(group, key) in getNumberTypes(this.game.key)"
                                 :key="key"
                            >
                                <div v-if="isTypeArray(draw.game_draw.numbers, key)"
                                     :class="`numbers-group-wrapper ${key}-numbers`"
                                     v-tooltip="{
                                        content: key
                                     }"
                                >
                                    <span v-for="number in draw.game_draw.numbers[key]"
                                          :class="[
                                              key,
                                              typeResolver(key)
                                          ]"
                                    >
                                        {{ number }}
                                    </span>
                                </div>
                                <span v-else
                                      :key="key+draw.game_draw.numbers[key]"
                                      :class="[
                                          key,
                                          group,
                                          typeResolver(key)
                                      ]"
                                      v-tooltip="{
                                         content: key
                                      }"
                                >
                                    {{ draw.game_draw.numbers[key] }}
                                </span>
                            </div>
                        </template>
                    </div>
                    <div class="draw-date" v-if="draw.game_draw && isset(draw.game_draw, 'date')">
                        <span class="text-muted small">Date: {{ draw.game_draw.date }}</span>
                    </div>
                </div>
            </div>
            <div class="draw-results-loader-holder"
                 v-if="ticketLoader"
            >
                <loader type="small"
                        :show="ticketLoader"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TypesConst from '../../../../../../store/TypesConst';
import NamespaceConst from '../../../../../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';

const {
    mapGetters
} = createNamespacedHelpers(NamespaceConst.tickets);

import TicketMixin from '../../../../../../mixins/TicketMixin';
import isString from '../../../../../../utils/String/IsString';

export default {
    name: 'ticket-draw-results',
    mixins: [
        TicketMixin
    ],
    computed: {
        ...mapGetters({
            game: TypesConst.tickets.getters.GET_TICKETS_TICKET_GAME,
            ticketLoader: TypesConst.tickets.getters.GET_TICKETS_TICKET_DATA_LOADER,
            draw: TypesConst.tickets.getters.GET_TICKETS_TICKET_DRAW
        }),
    },
    methods: {
        getNumberTypes(gameKey) {
            if (!gameKey) {
                return {};
            }

            const types = this.getGameNumTypes(gameKey);

            if (!types) {
                return {};
            }

            const tmpTypes = Object.keys(types)
                .filter(type => !!isString(types[type]))
                .map(type => {
                    return [type, types[type]];
                });

            return Object.fromEntries(tmpTypes);
        }
    },
}
</script>