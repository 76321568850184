import TypesConst from "../../TypesConst";
import GamesService from "../../../services/GamesService";

export default {
	[TypesConst.games.actions.GET_ALL_GAMES_ACTION]({commit, state}, ticketData = {}) {
		commit(TypesConst.games.mutations.SET_ALL_GAMES_LOADER, true);

		GamesService.getAllGames()
			.then(response => {
				commit(TypesConst.games.mutations.SET_ALL_GAMES_DATA, response.data.data);
			})
			.catch(error => {
				let message = error.response.data.message ? error.response.data.message : error.message;
				flash(message, "alert-danger");
			})
			.finally(() => {
				commit(TypesConst.games.mutations.SET_ALL_GAMES_LOADER, false);
			});
	},
	[TypesConst.games.actions.GET_GAME_CONFIGURATION_ACTION]({commit, state}, game) {
		return new Promise((resolve, reject) => {
			commit(TypesConst.games.mutations.SET_GAME_CONFIGURATION_LOADER, true);

			GamesService.getGameConfiguration(game.key)
				.then(response => {
					commit(TypesConst.games.mutations.SET_GAME_CONFIGURATION_DATA, {
						...response.data,
						id: game.id
					});
					resolve();
				})
				.catch(error => {
					let message = error.response.data.message ? error.response.data.message : error.message;
					flash(message, "alert-danger");
					reject();
				})
				.finally(() => {
					commit(TypesConst.games.mutations.SET_GAME_CONFIGURATION_LOADER, false);
				});
		});
	},
};