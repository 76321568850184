var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: {
        label: "Game",
        "label-for": "Games",
        "label-cols": _vm.labelCols,
        horizontal: _vm.horizontal
      }
    },
    [
      _c("tree-select", {
        attrs: {
          value: _vm.pickerValue,
          multiple: true,
          searchable: true,
          disabled: _vm.isLoading,
          "clear-on-select": true,
          options: _vm.gameList,
          normalizer: function(node) {
            return { id: node.value, label: node.text }
          }
        },
        on: { input: _vm.updateModel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }