<template>
    <atbl-form-group label="Tags"
                     label-for="Tags"
                     :horizontal="false" class="font-weight-bold"
                     :error="getError(errors, name)"
    >
        <tree-select v-if="!!searchOnly"
                     v-model="selectedValues"
                     :multiple="true"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="tags"
                     :disabled="!!disabled || !!isLoading"
                     :disable-branch-nodes="true"
                     :normalizer="(node) => ({id: node.id, label: node.name})"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
        <atbl-treeselect v-else
                         v-model="selectedValues"
                         :multiple="true"
                         :searchable="true"
                         :clear-on-select="true"
                         :options="tags"
                         :disabled="!!disabled || !!isLoading"
                         :disable-branch-nodes="true"
                         :normalizer="(node) => ({id: node.id, label: node.name})"
                         :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
                         @new-node="appendNewItem"
        />
    </atbl-form-group>
</template>

<script>
    import AtblTreeselect from '../../../../components/Forms/Inputs/AtblTreeselect.vue';
    
    export default {
        emits: [
            'input',
            'update',
        ],
        components: {
            AtblTreeselect,
        },
        props: {
            value: {
                type: Array,
                default: () => ([])
            },
            name: {
                type: String,
                default: 'tags'
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            errors: {
                type: Object,
                default: () => ({}),
            },
            searchOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                tags: [
                    {id: 'my-first-node-id', name: 'Add Tag', isDisabled: true}
                ],
                isLoading: false,
            };
        },
        computed: {
            selectedValues: {
                get() {
                    return !!this.isLoading
                      ? []
                      : this.value;
                },
                set(value) {
                    this.$emit('input', value);
                    this.$emit('update', this.name, value);
                }
            },
        },
        async created() {
            this.setInterface();
            
            await this.getCampaignTags();
        },
        methods: {
            async getCampaignTags() {
                this.isLoading = true;
                
                this.tags = [
                    {id: 1, name: 'Loading...', isDisabled: true}
                ];
                
                const {data} = await window.axios.get('/marketing/campaigns/tags');
                
                this.tags = !data.length
                  ? [
                      {id: 'my-first-node-id', name: 'Add Tag', isDisabled: true}
                  ]
                  : data;
                
                this.isLoading = false;
            },
            appendNewItem({value, id}) {
                this.tags = [...this.tags, {id, name: value}];
            },
            setInterface() {
                this.$emit('interface', {
                    getCampaignTags: this.getCampaignTags,
                });
            },
        },
    };
</script>