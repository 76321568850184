<template>
    <div class="permissions-holder-item">
        <div class="permissions-holder-item-header">
            <a href="#"
               @click.prevent="onChangeOpenStateEvent"
            >
                <i class="fa mr-2"
                   :class="{
                   'fa-caret-down': !!isShown,
                   'fa-caret-right': !isShown,
               }"
                ></i> {{ name }} <small class="font-weight-bold ml-1">({{selectedPermissionsGroup.length}} / {{permissionsList.length}})</small>
            </a>
            <atbl-check-box :value="isAllSelected"
                            :partial-check="isPartialSelected"
                            name="group-permission"
                            label="Select All"
                            @update="onSelectGroupPermissionsEvent"
            />
        </div>
        <div v-show="!!isShown"
             class="permissions-holder-item-list"
        >
            <div v-for="(item, index) in permissionsList"
                 :key="index"
            >
                <atbl-check-box :value="item.id"
                                :label="item.text"
                                :checked="!!selectedPermissions.find(permission => item.value === permission.value)"
                                name="users"
                                @update="onSelectPermissionEvent(item)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AtblCheckBox from '../../../../../components/Forms/Inputs/AtblCheckbox';
import { clone } from '../../../../../utils/Json/Json';
import { groupBy } from 'lodash';

export default {
    name: 'PermissionItemComponent',
    emits: [
        'update',
    ],
    components: {
        AtblCheckBox,
    },
    props: {
        name: {
            type: String,
            required: true,
            default: null,
        },
        permissions: {
            type: Array,
            default: () => ([])
        },
        searchData: {
            type: String,
            default: null,
        },
        selectedPermissions: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        permissionsList(){
            if (!this.searchData) {
                return this.permissions;
            }

            return this.permissions.filter((item) => {
                return this.searchData.toLowerCase().split(' ').every(v => item.text.toLowerCase().includes(v));
            });
        },
        isShown() {
            return !!this.isOpen || (!!this.searchData && !!this.permissionsList.length);
        },
        selectedPermissionsGroup() {
            const group = groupBy(this.selectedPermissions, permission => permission.text.split(' - ')[0]);

            return !!group[this.name]
                ? group[this.name]
                : [];
        },
        isAllSelected() {
            return this.selectedPermissionsGroup.length === this.permissions.length;
        },
        isPartialSelected() {
            return !!this.selectedPermissionsGroup.length && !this.isAllSelected;
        },
    },
    methods: {
        onSelectGroupPermissionsEvent() {
            let permissionsTmp = [];

            const alreadySelectedPermissionsIds = this.permissions.map(item => item.value);

            if (this.selectedPermissionsGroup.length === this.permissions.length) {
                permissionsTmp = this.selectedPermissions.filter(item => !alreadySelectedPermissionsIds.includes(item.value));
            } else {
                permissionsTmp = [
                    ...this.selectedPermissions.filter(item => !alreadySelectedPermissionsIds.includes(item.value)),
                    ...clone(this.permissions),
                ];
            }

            this.$emit('update', permissionsTmp);
        },
        onSelectPermissionEvent(value) {
            let permissions = clone(this.selectedPermissions);

            const currentPermission = permissions.find(permission => permission.value === value.value);

            if (!currentPermission) {
                permissions.push(value);
            } else {
                permissions = permissions.filter(permission => permission.value !== value.value);
            }

            this.$emit('update', permissions);
        },
        onChangeOpenStateEvent() {
            this.isOpen = !this.isOpen;
        },
    },
}
</script>