var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "player-profile-info" }, [
    _c("div", { staticClass: "player-profile-info-header" }, [
      _c(
        "div",
        [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.copyToClipboard(_vm.player.id)
                }
              }
            },
            [
              _vm._v(
                "\n                #" + _vm._s(_vm.player.id) + "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.copyToClipboard(
                    _vm.player.name + " " + _vm.player.surname
                  )
                }
              }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.player.name + " " + _vm.player.surname) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c("created-through", {
            attrs: { "created-through": _vm.player.created_through }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "player-profile-info-header-info" },
            [
              _c("span", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.copyToClipboard(_vm.player.email)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.player.email) + "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "player-kyc-status-badge",
                { attrs: { "kyc-status": _vm.player.kyc_status } },
                [
                  _vm._v(
                    "\n                    KYC: " +
                      _vm._s(_vm.player.kyc_status) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              !!_vm.player.my_affiliate_username
                ? _c("span", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "My affiliate user",
                            expression: "`My affiliate user`"
                          }
                        ],
                        staticClass: "badge badge-info"
                      },
                      [
                        _vm._v(
                          "\n            MyAffiliate: " +
                            _vm._s(_vm.player.my_affiliate_username) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasTimezones
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: { content: "Player Local Time" },
                  expression: "{'content': 'Player Local Time'}"
                }
              ]
            },
            [
              _c("div", { staticClass: "player-profile-info-local-time" }, [
                _c("div", [
                  _c("i", { staticClass: "fa fa-clock" }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.getFirstClock.time) +
                      "\n                    "
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            html: true,
                            content: _vm.getOtherClocks,
                            placement: "top-end"
                          },
                          expression:
                            "{\n                html: true,\n                content: getOtherClocks,\n                placement: 'top-end'\n           }"
                        }
                      ],
                      attrs: { href: "#" }
                    },
                    [_c("i", { staticClass: "fa fa-info-circle" })]
                  )
                ]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.getFirstClock.timezone[0]) +
                      " " +
                      _vm._s(_vm.getFirstClock.timezone.length > 1 ? "..." : "")
                  )
                ]),
                _vm._v(" "),
                _c("br")
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "player-profile-info-body" }, [
      _c("div", { staticClass: "player-profile-info-info" }, [
        _c("div", [
          _c("strong", [_vm._v("Activated at:")]),
          _vm._v(
            " " +
              _vm._s(
                _vm.player.activated_at
                  ? _vm.utcToLocal(_vm.player.activated_at)
                  : "/"
              ) +
              "\n            "
          )
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: _vm.lastSeen
                    ? _vm.lastSeen.format("DD MMM, YYYY HH:mm")
                    : "/"
                },
                expression:
                  "{content: lastSeen ? lastSeen.format('DD MMM, YYYY HH:mm') : '/'}"
              }
            ]
          },
          [
            _c("strong", [_vm._v("Last seen:")]),
            _vm._v(" " + _vm._s(_vm.lastSeenFromNow) + "\n            ")
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "player-profile-info-newsletter" },
          [
            _c("strong", [_vm._v("Newsletter:")]),
            _vm._v(" "),
            _c("atbl-switch", {
              attrs: {
                value: _vm.player.newsletter,
                small: true,
                "is-loading": _vm.isPlayerNewsletterUpdating,
                name: "newsletter"
              },
              on: { update: _vm.onNewsletterUpdate }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "player-profile-info-operations" },
        [
          _c("wallet-widget", { attrs: { player: _vm.player } }),
          _vm._v(" "),
          _c("cart-widget")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }