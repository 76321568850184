<template>
    <div class="container-fluid game-draw-review">
        <div class="card card-list">
            <div class="card-header">
                <div>
                    <i class="fa fa-star"></i> Review Draws
                </div>
                <div class="d-flex align-items-center">
                    <sort-by v-model="sortByModel"
                             :options="sortByOptions"
                             class="mr-2"
                    />
                    <button v-if="$can('Game Draws - Review')"
                            class="btn btn-sm btn-warning"
                            @click="getGameDrawsForReview"
                    >
                        <i class="fa fa-sync"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-1">Image</div>
                        <div class="col-12 col-lg-1">Type</div>
                        <div class="col">Draw</div>
                        <div class="col-12 col-lg-1">Date</div>
                        <div class="col-12 col-lg-1">Lines</div>
                        <div class="col-12 col-lg-2">Winnings</div>
                        <div class="col-12 col-lg-1">Is Parsed</div>
                        <div v-if="$can('Game Draws - Review')"
                              class="col-12 col-lg-2"
                        >
                            Operations
                        </div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(draw, index) in gameDraws"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-lg-1">
                                <span class="small-mb-badge">Image</span>
                                <img :src="$imageService.gameImage(draw.game_image)" class="logo-game" />
                            </div>
                            <div class="col-12 col-lg-1">
                                <span class="small-mb-badge">Type</span>
                                <span :class="['badge', 'mr-2', `badge-${draw.draw_type_color}`]"
                                >
                                    {{draw.draw_type}}
                                </span>
                            </div>
                            <div class="col">
                                <span class="small-mb-badge">Draw</span>
                                <div>{{ draw.game_name }}</div>
                                <small>#{{ draw.game_draw_id }}</small>
                            </div>
                            <div class="col-12 col-lg-1">
                                <span class="small-mb-badge">Date</span>
                                {{ draw.date }}
                            </div>
                            <div class="col-12 col-lg-1">
                                <span class="small-mb-badge">Lines</span>
                                {{ draw.total_lines }}
                            </div>
                            <div class="col-12 col-lg-2">
                                <span class="small-mb-badge">Winnings</span>
                                {{ draw.total_winnings }}
                            </div>
                            <div class="col-12 col-lg-1">
                                <span class="small-mb-badge">Is Parsed</span>
                                <i class="fa"
                                   :class="{
                                        'fa-check text-success': !!draw.ready_for_review,
                                        'fa-times text-danger': !draw.ready_for_review
                                   }"
                                ></i>
                            </div>
                            <div v-if="$can('Game Draws - Review')"
                                 class="col-12 col-lg-2"
                            >
                                <span class="small-mb-badge">Operations</span>
                                <a :href="`/games/draws/review/${draw.id}`">
                                    <i class="fa fa-eye"></i>
                                </a>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />

                    <items-not-found v-if="!isLoading"
                                     :items="draws"
                                     msg="No game draws found."
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemsNotFound from '../../../../components/Lists/ItemsNotFound.vue';
import Loader from '../../../../components/Widgets/Loader.vue';
import resolveDrawType from '../utils/ResolveDrawType';
import dynamicSort from '../../../../utils/Array/DynamicSort';
import SortBy from '../../../../components/SortBy/SortBy.vue';

export default {
    name: 'DrawReview',
    components: {
        ItemsNotFound,
        Loader,
        SortBy,
    },
    data() {
        return {
            isLoading: false,
            draws: [],
            sortByOptions: [
                {
                    value: 'total_winnings',
                    label: 'Winnings',
                },
                {
                    value: 'game_draw_id',
                    label: 'Draw Id',
                },
                {
                    value: 'date',
                    label: 'Draw Date',
                },
                {
                    value: 'is_parsed',
                    label: 'Parsed',
                },
            ],
            sortByModel: {
                direction: 'asc',
                column: 'date',
            },
        };
    },
    computed: {
        gameDraws() {
            return dynamicSort(this.draws, this.sortByModel.column, this.sortByModel.direction);
        },
    },
    async created() {
        await this.getGameDrawsForReview();
    },
    methods: {
        async getGameDrawsForReview() {
            if (!this.$can('Game Draws - Review')) {
                return window.showMessage('No permission for this action', false);
            }

            this.isLoading = true;

            try {
                const response = await window.axios.get('/games/draws/review/list');

                this.draws = response.data.map(item => {
                    const resolvedType = resolveDrawType(item.draw_type);

                    item.draw_type = resolvedType.type;
                    item.draw_type_color = resolvedType.color;

                    return item;
                });

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>