var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atbl-select", {
    attrs: { options: _vm.options, label: _vm.label, name: _vm.name },
    model: {
      value: _vm.selectedValue,
      callback: function($$v) {
        _vm.selectedValue = $$v
      },
      expression: "selectedValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }