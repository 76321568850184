var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onUpdateUsersEvent.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "card card-list" },
        [
          _c("div", { staticClass: "sticky-element bg-white border-bottom" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex align-items-center justify-content-between"
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    !_vm.usersUpdateLoader
                      ? _c("sort-by", {
                          attrs: { options: _vm.sortByOptions },
                          model: {
                            value: _vm.sortByModel,
                            callback: function($$v) {
                              _vm.sortByModel = $$v
                            },
                            expression: "sortByModel"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary ml-2",
                        attrs: {
                          disabled: !_vm.isModelDirty || _vm.usersUpdateLoader
                        }
                      },
                      [
                        !_vm.usersUpdateLoader
                          ? _c("span", [_vm._v("Save")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: {
                            show: _vm.usersUpdateLoader,
                            type: "smaller"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            !_vm.usersUpdateLoader
              ? _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c("atbl-input", {
                      staticClass: "mb-0",
                      attrs: { placeholder: "Search user" },
                      model: {
                        value: _vm.searchData,
                        callback: function($$v) {
                          _vm.searchData = $$v
                        },
                        expression: "searchData"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.usersUpdateLoader
            ? _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "general-list" },
                  [
                    _c("div", { staticClass: "row d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 col-sm-3" },
                        [
                          _c("atbl-check-box", {
                            staticClass: "mr-1",
                            attrs: {
                              value: _vm.isAllSelected,
                              "partial-check": _vm.isPartialSelected,
                              name: "users",
                              label: "Select All"
                            },
                            on: { update: _vm.onSelectAllUsersEvent }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [_vm._v("Name")])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.users, function(user, index) {
                      return _c("div", { key: index, staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-6 col-sm-3" },
                          [
                            _c("atbl-check-box", {
                              staticClass: "mr-1",
                              attrs: {
                                value: user.id,
                                label: null,
                                checked: _vm.modelUsersIds.includes(user.id),
                                name: "users"
                              },
                              on: {
                                update: function($event) {
                                  return _vm.onSelectUserEvent(user)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-sm" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(user.name) +
                              " (#" +
                              _vm._s(user.id) +
                              ")\n                    "
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("items-not-found", {
                      attrs: { items: _vm.users, msg: "No users found" }
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            attrs: { show: _vm.usersUpdateLoader, type: "small" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-user-friends" }),
      _vm._v(" Users\n                ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }