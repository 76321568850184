import TypesConst from "../../../TypesConst";
import TransactionsService from "../../../../services/TransactionsService";

export default {
    [TypesConst.transactions.actions.GET_TRANSACTION_BY_ID]({commit, state}, transactionId = null){
        commit(TypesConst.transactions.mutations.SET_TRANSACTIONS_TRANSACTION_DATA_LOADER, true);

        TransactionsService.getTransactionById(transactionId ? transactionId : state.transactionId)
            .then(response => {
                if(response.data){
                    commit(TypesConst.transactions.mutations.SET_TRANSACTIONS_TRANSACTION_DATA, response.data);
                }
            })
            .catch(error => {
                let message = error.response.data.message ? error.response.data.message : error.message;
                flash(message, "alert-danger");
            })
            .finally(() => {
                commit(TypesConst.transactions.mutations.SET_TRANSACTIONS_TRANSACTION_DATA_LOADER, false);
            })
    },
};