export default {
    mutations: {
        SET_CURRENT_USER: `setCurrentUser`,
        SET_CURRENT_USER_LOADER: `setCurrentUserLoader`,
    },
    getters: {
        GET_CURRENT_USER: `getCurrentUser`,
        GET_CURRENT_USER_LOADER: `getCurrentUserLoader`,
    },
    actions: {
        USERS_GET_CURRENT_USER_ACTION: `getCurrentUserAction`,
    }
};