<template>
    <div class="sort-by">
        <span>Sort By</span>
        <select @change="onUpdateColumnEvent">
            <option v-for="(item, index) in options"
                    :value="item.value"
                    :key="index"
                    :selected="item.value === value.column"
            >
                {{ item.label }}
            </option>
        </select>
        <button @click.prevent="onDirectionChangeEvent">
            <span :class="{rotated: value.direction === 'asc'}">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </span>
        </button>
    </div>
</template>

<script>
import AtblSelect from '../Forms/Inputs/ATBLSelect';

export default {
    name: 'SortBy',
    emits: [
        'input',
    ],
    components: {
        AtblSelect,
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                direction: 'asc',
                column: null
            })
        },
        options: {
            type: Array,
            default: () => ([])
        },
    },
    methods: {
        onUpdateColumnEvent(event) {
            const column = event.target.value;

            this.update({
                column,
                direction: this.value.direction
            })
        },
        onDirectionChangeEvent() {
            const direction = this.value.direction === 'asc'
                ? 'desc'
                : 'asc';

            this.update({
                column: this.value.column,
                direction
            })
        },
        update(model) {
            this.$emit('input', model);
        },
    }
}
</script>