<template>
    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
        <img v-if="hasImage && ! isBundle" :src="$imageService.gameImage(item.image)" :alt="`${item.image}`" class="mr-2" style="width: 60px; height: auto;" />
        <img v-if="isBundle" :src="bundleLogoUrl" :alt="`${item.name}`" class="mr-2" style="width: 60px; height: auto;" />

        <span v-if="! hasImage && ! isBundle" class="no-image-found">
            <i class="fa fa-image" aria-hidden="false"></i>
        </span>

        <div>
            <div class="text-left">
                {{ getName(item) }}
            </div>

            <div v-if="isLoteriaNacional" class="d-flex align-items-center">
                <span class="mr-2">
                    <b>Lines:</b> {{ isset(item, "lines") ? item.lines.length : 1 }}
                </span>
            </div>

            <div v-if="isSyndicate" class="d-flex align-items-center">
                <span class="mr-2">
                    <b>Shares:</b> {{ item.shares }}
                </span>
                <span class="d-block mr-2">
                    <b>Draws:</b> {{ item.draws }}
                </span>
            </div>

            <div v-if="isBundle" class="d-flex align-items-center">
                <span class="mr-2">
                    <b>Weeks:</b> {{ item.weeks }}
                </span>
            </div>

            <div v-if="isLotteryGame" class="d-flex align-items-center">
                <span class="mr-2">
                    <b>Lines:</b> {{ item.lines.length }}
                </span>
                <span v-if="isset(item, 'weeks')">
                    <b>Weeks:</b> {{ item.weeks }}
                </span>
                <span v-else>
                    <b>Draws:</b> {{ item.draws }}
                </span>
            </div>

            <div  v-if="isset(item, 'weeks')" class="d-flex align-items-center">
                <span v-for="(weekday, index) in item.weekdays"
                      :key="index"
                      class="badge badge-primary mr-1"
                >
                    {{ weekday }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import BundleService from '../../../../services/BundleService';

export default {
    name: 'item',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasImage() {
            return this.isset(this.item, "image");
        },

        isLotteryGame() {
            return ! this.isLoteriaNacional && ! this.isSyndicate && ! this.isBundle;
        },

        isLoteriaNacional() {
            return this.item.type.indexOf("loteria-nacional") !== -1;
        },

        isSyndicate() {
            return this.item.type === 'syndicate';
        },

        isBundle() {
            return this.item.type === 'bundle';
        },

        bundleLogoUrl() {
            return this.isBundle ? BundleService.makeLogoPreviewUrl(this.item.bundle) : '';
        },
    },
    methods: {
        getName(item) {
            let name = ! this.isSyndicate ? '' : 'Syndicate: ';

            name += this.isset(item, "name") ? item.name : this.generateName(item.type);

            return name;
        },

        generateName(type) {
            return this.ucWords(type.split("-").join(" "));
        }
    }
}
</script>

<style lang="scss">
    .no-image-found {
        align-items: center;
        border: 1px solid #e0e0e0;
        color: #e0e0e0;
        display: flex;
        font-size: 18px;
        height: 60px;
        justify-content: center;
        margin-right: 10px;
        width: 60px;
    }
</style>