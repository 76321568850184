var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-list" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.dispositions, function(disposition, index) {
        return !_vm.isLoading
          ? _c("div", { key: index, staticClass: "row py-3 border-top" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Name")]),
                _vm._v(
                  "\n            " +
                    _vm._s(disposition.disposition.name) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-3" }, [
                disposition.follow_up
                  ? _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Follow-up")
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n            " +
                    _vm._s(disposition.follow_up) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-3" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Created")
                ]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.utcToLocal(disposition.created_at)) +
                    "\n        "
                )
              ])
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("not-found", {
            attrs: { items: _vm.dispositions, msg: "No dispositions found." }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { type: "small", show: _vm.isLoading } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 text-muted" }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-3 text-muted" }, [_vm._v("Follow-up")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-3 text-muted" }, [_vm._v("Created")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }