var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Dispositions - Get List")
    ? _c("atbl-select", {
        attrs: {
          name: _vm.name,
          options: _vm.dispositionOptions,
          label: _vm.label
        },
        on: { update: _vm.changed },
        model: {
          value: _vm.selectedDisposition,
          callback: function($$v) {
            _vm.selectedDisposition = $$v
          },
          expression: "selectedDisposition"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }