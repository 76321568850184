var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center",
      class: {
        "py-3": !!_vm.isLoading,
        "justify-content-center": !_vm.isLoading && !_vm.filterList.length
      }
    },
    [
      !this.campaignId
        ? _c("p", { staticClass: "text-danger font-weight-bold m-0" }, [
            _vm._v("\n        No campaign id provided\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "filter-links-holder" },
            _vm._l(_vm.filterList, function(item, index) {
              return _c("filter-list-link", {
                key: index,
                attrs: { filter: item },
                on: { click: _vm.onFilterClickEvent }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("not-found", {
            attrs: { items: _vm.filterList, msg: "No filters found." }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }