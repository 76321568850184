var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      attrs: {
        label: _vm.label,
        "label-for": _vm.id,
        error: _vm.getError(_vm.error, _vm.name)
      }
    },
    [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.region
          ? _c("img", {
              staticClass: "tel-input-flag",
              attrs: { src: _vm.getFlag }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control tel-input",
          class: {
            "is-invalid": _vm.hasError(_vm.error, _vm.name)
          },
          attrs: { id: _vm.id, placeholder: _vm.placeholder, type: "tel" },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              return _vm.onInputEvent($event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      !!_vm.errorMessage
        ? _c("div", { staticClass: "alert alert-danger p-2" }, [
            _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }