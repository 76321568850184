var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center justify-content-between"
      },
      [
        _c("span", [
          _c("i", { staticClass: "fa fa-phone" }),
          _vm._v(" Callbacks "),
          _c("span", { staticClass: "badge badge-primary" }, [
            _vm._v(_vm._s(_vm.selectedPeriod))
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-primary",
            on: { click: _vm.showHideFilter }
          },
          [_c("i", { staticClass: "fa fa-filter" })]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-3" }, [
              _vm._v("\n\t\t\t\t\t\tPlayer\n\t\t\t\t\t")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-2" }, [
              _vm._v("\n\t\t\t\t\t\tCountry\n\t\t\t\t\t")
            ]),
            _vm._v(" "),
            !_vm.getIsOnlyAgent
              ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                  _vm._v("\n\t\t\t\t\t\tAgent\n\t\t\t\t\t")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-2" }, [
              _vm._v("\n\t\t\t\t\t\tDisposition\n\t\t\t\t\t")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-3" }, [
              _c(
                "a",
                {
                  staticClass: "sort-link",
                  class: {
                    active: _vm.getSearchModel.sort.sort === "follow_up"
                  },
                  attrs: { href: "#", sort: "follow_up" },
                  on: { click: _vm.sortChange }
                },
                [
                  _vm._v("\n\t\t\t\t\t\t\tFollow Up "),
                  _vm.getSearchModel.sort.sort === "follow_up"
                    ? _c("span", [
                        _c("i", {
                          staticClass: "fa",
                          class: {
                            "fa-caret-up":
                              _vm.getSearchModel.sort.value === "ASC",
                            "fa-caret-down":
                              _vm.getSearchModel.sort.value === "DESC"
                          }
                        })
                      ])
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.getCallbackList, function(item, index) {
            return !_vm.getCallbackListLoader
              ? _c("div", { key: index, staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-lg-3" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Player")
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "text-dark",
                        attrs: {
                          href: "/players/" + item.player_id + "/view",
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(item.player_name) +
                            " " +
                            _vm._s(item.player_surname) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    item.player_phone
                      ? _c(
                          "a",
                          {
                            staticClass: "callbacks-phone",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.openCallWindow(
                                  item.player_phone,
                                  item.player_id
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(item.player_phone) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    item.player_mobile
                      ? _c(
                          "a",
                          {
                            staticClass: "callbacks-phone",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.openCallWindow(
                                  item.player_mobile,
                                  item.player_id
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(item.player_mobile) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg-2" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Country")
                    ]),
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(item.player_country) +
                        "\n\t\t\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.getIsOnlyAgent
                    ? _c("div", { staticClass: "col-12 col-lg-2" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Agent")
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(item.agent_name) +
                            "\n\t\t\t\t\t"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg-2" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Disposition")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "badge badge-success" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(item.disposition_name) +
                          "\n\t\t\t\t\t\t"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg-3" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Follow Up")
                    ]),
                    _vm._v(
                      "\n\t\t\t\t\t\t" + _vm._s(item.follow_up) + "\n\t\t\t\t\t"
                    )
                  ])
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _c("loader", {
            attrs: { show: _vm.getCallbackListLoader, type: "small" }
          }),
          _vm._v(" "),
          !_vm.getCallbackListLoader
            ? _c("not-found", {
                attrs: {
                  msg: "No callbacks found.",
                  items: _vm.getCallbackList
                }
              })
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    !_vm.getCallbackListLoader && _vm.getCallbackListResponse.last_page > 1
      ? _c("div", { staticClass: "card-footer" }, [
          _c(
            "nav",
            { staticClass: "wrap" },
            [
              _c("atbl-pagination", {
                staticClass: "m-0",
                attrs: { pagination: _vm.getCallbackListResponse },
                on: { paginate: _vm.getCallbacksAction }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }