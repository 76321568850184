export default {
	mutations: {
		SET_TICKETS_TICKET_ID: `setTicketsTicketId`,
		SET_TICKETS_TICKET_DATA: `setTicketsTicketData`,
		SET_TICKETS_TICKET_DATA_LOADER: `setTicketsTicketDataLoader`,
		SET_TICKETS_TICKET_CONFIGURATION: `setTicketsTicketConfiguration`,
	},
	getters: {
		GET_TICKETS_TICKET_ID: `getTicketsTicketId`,
		GET_TICKETS_TICKET_DATA: `getTicketsTicketData`,
		GET_TICKETS_TICKET_DATA_LOADER: `getTicketsTicketDataLoader`,
		GET_TICKETS_TICKET_GAME: `getTicketsTicketGame`,
		GET_TICKETS_TICKET_CONFIGURATION_NUMBERS: `getTicketsTicketConfigurationNumbers`,
		GET_TICKETS_TICKET_PLAYER: `getTicketsTicketPlayer`,
		GET_TICKETS_TICKET_CURRENCY: `getTicketsTicketCurrency`,
		GET_TICKETS_TICKET_LINES: `getTicketsTicketLines`,
		GET_TICKETS_TICKET_DRAW: `getTicketsTicketDraw`,
		GET_TICKETS_TICKET_CONFIGURATION: `getTicketsTicketConfiguration`,
	},
	actions: {
		GET_TICKET_BY_ID: `getTicketById`,
		GET_RAFFLE_BY_ID: `getRaffleById`,
	}
};