export default {
    data() {
        return {
            freeGamePreviewData: {},
            isDeletePressed: false
        };
    },
    methods: {
        previewFreeGame(freeGame) {
            this.freeGamePreviewData = freeGame;
        },
        togglePreviewDelete() {
            this.isDeletePressed = !this.isDeletePressed;
        }
    },
};