var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Player - Block/Unblock")
    ? _c(
        "div",
        { staticClass: "block-player text-left" },
        [
          !_vm.isPlayerBlocked
            ? _c(
                "button",
                {
                  staticClass: "btn btn-danger text-white",
                  on: {
                    click: function($event) {
                      _vm.showBlockModal = true
                    }
                  }
                },
                [_vm._v("\n        Block\n    ")]
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  on: {
                    click: function($event) {
                      _vm.showUnblockModal = true
                    }
                  }
                },
                [_vm._v("\n        Unblock\n    ")]
              ),
          _vm._v(" "),
          _c(
            "atbl-modal",
            {
              attrs: {
                title: "Block Player",
                show: _vm.showBlockModal,
                "show-close": !_vm.isLoading
              },
              on: { close: _vm.onBlockModalClose },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        !_vm.isLoading
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-light",
                                on: { click: _vm.onBlockModalClose }
                              },
                              [_vm._v("\n                Cancel\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-success text-white",
                            attrs: {
                              disabled: _vm.isLoading || !_vm.isFormValid
                            },
                            on: { click: _vm.onBlockUnblockPlayer }
                          },
                          [
                            !_vm.isLoading
                              ? _c("span", [_vm._v("Confirm")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("loader", {
                              attrs: { show: _vm.isLoading, type: "smaller" }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3743222384
              )
            },
            [
              _c("atbl-textarea", {
                staticClass: "mb-0",
                attrs: {
                  errors: _vm.validationErrors,
                  rows: 5,
                  label: "Reason",
                  name: "reason"
                },
                model: {
                  value: _vm.reason,
                  callback: function($$v) {
                    _vm.reason = $$v
                  },
                  expression: "reason"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "mt-1 badge font-sm",
                  class: {
                    "badge-danger": !_vm.isFormValid,
                    "badge-secondary": _vm.isFormValid
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(!_vm.reason ? 0 : _vm.reason.length) +
                      " / 255\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "atbl-modal",
            {
              attrs: {
                title: "Unblock Player",
                show: _vm.showUnblockModal,
                "show-close": !_vm.isLoading
              },
              on: {
                close: function($event) {
                  _vm.showUnblockModal = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        !_vm.isLoading
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-light",
                                on: {
                                  click: function($event) {
                                    _vm.showUnblockModal = false
                                  }
                                }
                              },
                              [_vm._v("\n                Cancel\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-success text-white",
                            attrs: { disabled: _vm.isLoading },
                            on: { click: _vm.onBlockUnblockPlayer }
                          },
                          [
                            !_vm.isLoading
                              ? _c("span", [_vm._v("Confirm")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("loader", {
                              attrs: { show: _vm.isLoading, type: "smaller" }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3514614121
              )
            },
            [
              _c("h4", { staticClass: "text-center" }, [
                _vm._v(
                  "\n            Do you really want to unblock the player "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.player.name))]),
                _vm._v("?\n        ")
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }