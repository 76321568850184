<template>
    <Layout title="Campaigns"
            icon="nav-icon fa fa-bullhorn"
            class="campaigns-list-section"
    >
        <template #title>
            <i class="nav-icon fa fa-bullhorn mr-2"></i> Campaigns
            <span :class="{
                'badge-primary': type === 'lead',
                'badge-danger': type === 'player'
            }"
                  class="badge text-uppercase ml-2"
            >
                {{ type }}s
            </span>
        </template>
        <template v-slot:header>
            <button :class="{
                'btn-primary': !showFilter,
                'btn-outline-primary': !!showFilter,
            }"
                    class="btn btn-sm mr-2"
                    @click="showFilter = !showFilter"
            >
                <i class="fa fa-filter" aria-hidden="false"></i>
            </button>
            <a v-if="$can('Campaigns - Create/Update')"
               :href="`/marketing/campaigns/${type}/create`"
               class="btn btn-primary btn-sm m-0 text-white"
            >
                Create
            </a>
        </template>

        <template #beforeCard>
            <campaign-search v-show="showFilter"
                             @search="onSearchCampaignsEvent"
            />
        </template>

        <div class="general-list">
            <div class="row">
                <div class="col col-lg-1">Id</div>
                <div class="col">Title</div>
                <div class="col">Type</div>
                <div class="col">Status</div>
                <div class="col text-center">Customers</div>
                <div class="col" v-if="$canAny($options.campaignPermissions)">Operations</div>
            </div>

            <template v-if="!campaignListLoader">
                <div v-for="(item, index) in campaignsList"
                     :key="index"
                     class="row"
                     :class="{
                         'pb-0': !!item.tags
                     }"
                >
                    <div class="col-12 col-lg-1 align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Id</span>
                        {{ item.id }}
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Title</span>
                        <a :href="`/marketing/campaigns/${type}/${item.id}/${item.view_type === $options.Campaigns.ViewTypes.callView ? 'call-view' : 'list-view'}`"
                           v-if="$can('Campaigns - Call')"
                        >
                            {{ item.title }}
                        </a>
                        <span v-else>
                        {{ item.title }}
                    </span>
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Type</span>
                        {{ item.type }}<br />
                        <span :class="`badge badge-${item.view_type === $options.Campaigns.ViewTypes.callView ? 'primary' : 'success'}`">{{ item.view_type }}</span>
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Status</span>
                        <campaign-status-badge :status="item.status" />
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0 text-left text-lg-center">
                        <span class="small-mb-badge">Customers</span>
                        <span class="badge badge-danger" v-tooltip="{content: 'Can be called'}">{{ item.customers_that_can_be_called }}</span>
                        <span class="badge badge-warning" v-tooltip="{content: 'In campaign'}">{{ item.customers_count }}</span>
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0"
                         v-if="$canAny($options.campaignPermissions)"
                    >
                        <span class="small-mb-badge">Operations</span>
                        <a v-if="$can('Campaigns - View')"
                           :href="`/marketing/campaigns/${type}/${item.id}/view`"
                           class="mr-2"
                        >
                            <i class="fa fa-eye"></i>
                        </a>
                        <a v-if="$can('Campaigns - Create/Update')"
                           :href="`/marketing/campaigns/${type}/${item.id}`"
                           class="mr-2"
                        >
                            <i class="fa fa-pen"></i>
                        </a>
                        <a v-if="$can('Campaigns - Remove')"
                           href="#"
                           class="text-danger"
                           @click.prevent="toggleRemoveCampaignModal(type, item.id)"
                        >
                            <i class="fa fa-trash"></i>
                        </a>
                    </div>
                    <div v-if="item.tags"
                         class="col-12 tags-column"
                    >
                        <span v-for="(tag, index) in item.tags.split(',')"
                              :key="index"
                              class="badge badge-primary"
                        >
                            {{ tag }}
                        </span>
                    </div>
                </div>
            </template>

            <loader :show="campaignListLoader"
                    type="small"
            />

            <not-found v-if="!campaignListLoader"
                       :items="campaignsList"
                       msg="No campaigns found."
            />

            <nav class="wrap py-3" v-if="!campaignListLoader && campaignsListResponse.last_page > 1">
                <div>
                    <atbl-pagination class="m-0"
                                     :pagination="campaignsListResponse"
                                     @paginate="fetchCampaigns"
                    />
                </div>
            </nav>
        </div>

        <remove-campaign-modal v-if="$can('Campaigns - Remove')"
                               :show="confirmRemoveCampaignModalOpen"
                               :is-loading="isRemoveCampaignLoading"
        />
    </Layout>
</template>

<script>
import Layout from '../../_Components/Layout';
import RemoveCampaignModal from './RemoveCampaignsModal/RemoveCampaignModal';
import CampaignSearch from './CampaignsSearch/CampaignSearch';
import Campaigns from '../../../../constants/Campaigns';
import CampaignStatusBadge from '../_Components/CampaignStatusBadge';
import RemoveCampaign from './RemoveCampaignsModal/RemoveCampaign';
import MarketingService from '../../../../services/MarketingService';
import { clone } from '../../../../utils/Json/Json';
import CampaignSearchModel from './CampaignsSearch/CampaignSearchModel';

const campaignPermissions = [
    'Campaigns - View',
    'Campaigns - Create/Update',
    'Campaigns - Remove'
];

export default {
    name: 'campaigns',
    Campaigns,
    campaignPermissions,
    mixins: [
        RemoveCampaign,
    ],
    components: {
        Layout,
        RemoveCampaignModal,
        CampaignSearch,
        CampaignStatusBadge,
    },
    props: {
        type: {
            type: String,
            default: null
        },
    },
    provide() {
        return {
            campaignType: this.type,
        };
    },
    data() {
        return {
            showFilter: false,
            campaignListLoader: false,
            campaignsListResponse: {},
            campaignsList: [],
            campaignsSearchModel: clone(CampaignSearchModel),
        };
    },
    async created() {
        await this.fetchCampaigns();
    },
    methods: {
        async onSearchCampaignsEvent(model) {
            this.campaignsSearchModel = model;

            await this.fetchCampaigns();
        },
        async fetchCampaigns(page = 1) {
            this.campaignListLoader = true;

            try {
                const response = await MarketingService.getCampaigns(this.type, {
                    ...this.campaignsSearchModel,
                    page
                });

                this.campaignsList = response.data.data;
                this.campaignsListResponse = response.data;

                this.campaignListLoader = false;
            } catch (error) {
                this.campaignListLoader = false;

                window.flashError(error);
            }
        },
    },
}
</script>