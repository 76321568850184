<template>
    <div class="card card-list">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div>
                <i class="fa fa-percent"></i> Game discounts
            </div>

            <atbl-dropdown v-if="!isLoading && $can('Discounts - Create/Update')"
                           :disabled="! gamesWithoutDiscount.length"
                           :small="true"
                           placeholder="Add game discount"
                           input-style="primary"
            >
                <atbl-dropdown-item v-for="game in gamesWithoutDiscount"
                                    :key="`game_without_discount_${game.id}`"
                                    :is-anchor-tag="true"
                                    @click="createGameDiscount(game.id)"
                >
                    {{ game.name }}
                </atbl-dropdown-item>
            </atbl-dropdown>
            <loader :show="isLoading"
                    type="smaller"
            />
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="d-none"></div>
                <div v-for="discount in gamesWithDiscount"
                     :key="`game_discount_${discount.id}`"
                >
                    <div>
                        <img :src="$imageService.gameImage(discount.image)" width="40" />
                        {{ discount.name }}
                    </div>

                    <a href="#"
                       @click.prevent="onSelectGameForEdit(discount)"
                    >
                        <i class="fa fa-pen"></i>
                    </a>
                </div>
                <items-not-found :items="gamesWithDiscount"
                                 msg="No game discounts found."
                />
            </div>
        </div>
    </div>
</template>

<script>
import GameDiscountModel from "../GameDiscountModel";
import AtblDropdown from "../../../components/AtblDropdown/AtblDropdown.vue";
import AtblDropdownItem from "../../../components/AtblDropdown/AtblDropdownItem.vue";
import DiscountService from "../../../services/DiscountService";
import Loader from "../../../components/Widgets/Loader.vue";
import ItemsNotFound from '../../../components/Lists/ItemsNotFound.vue';

export default {
	name: 'DiscountsList',
	inject: [
		'fetchGamesWithDiscounts',
		'onSelectGameForEdit',
	],
	components: {
		AtblDropdown,
		AtblDropdownItem,
		Loader,
        ItemsNotFound,
	},
	props: {
		games: {
			type: Array,
			default: () => ([])
		},
		editingGame: {
			type: Object,
			default: () => (GameDiscountModel)
		},
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		gamesWithoutDiscount() {
			return this.games.filter(g => !g.discounts.length);
		},
		gamesWithDiscount() {
			return this.games.filter(g => !!g.discounts.length);
		},
	},
	methods: {
		async createGameDiscount(gameId) {
			this.isLoading = true;

			try {
				await DiscountService.createGameDiscountEntry(gameId);

				window.showMessage('Game discount entry successfully created');

				await this.fetchGamesWithDiscounts();

				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;

				window.flashError(error);
			}
		},
	},
}
</script>