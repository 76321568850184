var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atbl-modal", {
    attrs: {
      title: "Import errors log",
      show: true,
      "close-on-backdrop": false,
      size: "lg"
    },
    on: { close: _vm.toggleErrorsModal },
    scopedSlots: _vm._u([
      {
        key: "body-wrapper",
        fn: function() {
          return [
            _c("div", { staticClass: "modal-body p-0" }, [
              !_vm.isObjectEmpty(_vm.errors)
                ? _c(
                    "div",
                    {
                      staticClass: "general-list import-leads-errors-log-list"
                    },
                    [
                      _c("div", { staticClass: "row no-gutters" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm._v(
                            "\n                        Message\n                    "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.errors, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "row no-gutters",
                            on: { click: _vm.onMarkRowEvent }
                          },
                          _vm._l(item, function(errorItem, errorIndex) {
                            return _c(
                              "div",
                              { key: errorIndex, staticClass: "col-12" },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(errorItem) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      })
                    ],
                    2
                  )
                : _c(
                    "div",
                    [
                      _c("not-found", {
                        attrs: { msg: "No errors found", items: [] }
                      })
                    ],
                    1
                  )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-danger",
                on: { click: _vm.toggleErrorsModal }
              },
              [_vm._v("\n            Close\n        ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }