var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      class: {
        "hide-close": !!_vm.isLoading
      },
      attrs: { title: "Import leads", show: true, "close-on-backdrop": false },
      on: { close: _vm.onModalCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex w-100 align-items-center" }, [
                _c("div", { staticClass: "w-50 font-weight-bold" }, [
                  _vm._v(
                    "\n                Files supported: xls, xlsx, csv\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 text-right" }, [
                  !_vm.isObjectEmpty(_vm.errors)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-warning",
                          on: { click: _vm.toggleErrorsModal }
                        },
                        [
                          _vm._v(
                            "\n                    Errors Log\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      attrs: { disabled: !!_vm.isLoading },
                      on: { click: _vm.onModalCancel }
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                  _vm._v(" "),
                  _vm.$can("Leads - Import")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: {
                            "btn-primary": !_vm.isLoading,
                            "btn-outline-primary": _vm.isLoading
                          },
                          attrs: { disabled: !_vm.isUploadDisabled },
                          on: { click: _vm.onImportLeads }
                        },
                        [
                          !_vm.isLoading
                            ? _c("span", [
                                _vm._v(
                                  "\n                        Import\n                    "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loader", {
                            attrs: { type: "small", show: _vm.isLoading }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("input", {
        ref: "file",
        attrs: { type: "file", id: "file" },
        on: {
          change: function($event) {
            return _vm.setImportFile(_vm.$refs.file.files[0])
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }