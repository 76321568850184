<template>
    <a href="#"
       class="sort-link"
       :class="{'active': sort.sort === name}"
       @click.stop="onSortUpdateEvent"
    >
        {{ label }}
        <span v-if="sort.sort === name">
            <i class="fa"
               :class="{
                'fa-caret-up': sort.value === 'ASC',
                'fa-caret-down': sort.value === 'DESC'
            }"></i>
        </span>
    </a>
</template>

<script>
export default {
    name: 'SortByComponent',
    emits: [
        'update',
    ],
    props: {
        sort: {
            type: Object,
            default: () => ({
                value: 'ASC',
                sort: 'id'
            })
        },
        name: {
            type: String,
            default: 'id'
        },
        label: {
            type: String,
            default: 'Id'
        },
    },
    methods: {
        onSortUpdateEvent() {
            this.$emit('update', this.name);
        }
    },
}
</script>