export default {
    callBackList: [],
    callBackListResponse: {},
    callBackListLoader: false,
    callBackListModel: {
        agents: [],
        dateFrom: null,
        dateTo: null,
        country: null,
        sort: {
            sort: "none",
            value: "DESC"
        }
    },
};