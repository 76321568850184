import { clone } from '../../../utils/Json/Json';
import NoteModel from './NoteModel';
import getValidationErrors from '../../../utils/Error/GetValidationErrors';

export default {
    provide() {
        return {
            onCreateUpdateNote: this.onCreateUpdateNote,
            toggleNoteModal: this.toggleNoteModal,
        };
    },
    data() {
        return {
            isCreateNotModalOpen: false,
            isCreateNotModalLoading: false,
            createNoteModel: clone(NoteModel),
            createNoteErrors: {},
        };
    },
    methods: {
        async onCreateUpdateNote() {
            this.isCreateNotModalLoading = true;
            this.createNoteErrors = {};

            const { id } = this.createNoteModel;

            try {
                const response = !id
                    ? await window.axios.post('/note', {
                        type: this.createNoteModel.type
                    })
                    : await window.axios.patch('/note/' + id, {
                        type: this.createNoteModel.type,
                    });

                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.isCreateNotModalLoading = false;

                    return;
                }

                this.toggleNoteModal();
                await this.fetchNotes();

                this.isCreateNotModalLoading = false;
            } catch (error) {
                this.isCreateNotModalLoading = false;

                this.createNoteErrors = getValidationErrors(error);

                window.flashError(error);
            }
        },
        async onEditNoteEvent(id) {
            this.toggleNoteModal();
            this.isCreateNotModalLoading = true;

            try {
                const response = await window.axios.get('/note/' + id);

                this.createNoteModel = response.data;

                this.isCreateNotModalLoading = false;
            } catch (error) {
                this.isCreateNotModalLoading = false;

                this.createNoteErrors = getValidationErrors(error);

                window.flashError(error);
            }
        },
        toggleNoteModal() {
            this.isCreateNotModalOpen = !this.isCreateNotModalOpen;

            if (!this.isCreateNotModalOpen) {
                this.createNoteModel = clone(NoteModel);
                this.createNoteErrors = {};
            }
        },
    },
}