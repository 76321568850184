var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.showFilterForm
        ? _c("player-bonuses-search", {
            staticClass: "mb-3",
            on: { input: _vm.onSearch }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-outline-primary m-0",
                  on: {
                    click: function($event) {
                      _vm.showFilterForm = !_vm.showFilterForm
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-filter",
                    attrs: { "aria-hidden": "false" }
                  })
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(1),
              _vm._v(" "),
              !_vm.isLoading
                ? _vm._l(_vm.playerBonuses, function(playerBonus) {
                    return _c(
                      "div",
                      { key: _vm.playerBonuses.uuid, staticClass: "row py-3" },
                      [
                        _c("div", { staticClass: "col align-self-center" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(playerBonus.uuid) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col align-self-center" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(playerBonus.player.name) +
                              " " +
                              _vm._s(playerBonus.player.surname) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col align-self-center" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(playerBonus.bonus.title) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "col align-self-center",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPlayerBonusDate(
                                "assigned_at",
                                playerBonus
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "col align-self-center",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPlayerBonusDate(
                                "claimed_at",
                                playerBonus
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "col align-self-center",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPlayerBonusDate(
                                "accomplished_at",
                                playerBonus
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "col align-self-center",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPlayerBonusDate(
                                "revoked_at",
                                playerBonus
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "col align-self-center",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatPlayerBonusDate(
                                "expires_at",
                                playerBonus
                              )
                            )
                          }
                        })
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: {
                      msg: "No bonuses assigned to any player.",
                      items: _vm.playerBonuses
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        !_vm.isLoading &&
        _vm.playerBonusesResponse &&
        _vm.playerBonusesResponse.last_page > 1
          ? _c("div", { staticClass: "card-footer" }, [
              _c(
                "nav",
                { staticClass: "wrap" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.playerBonusesResponse },
                    on: { paginate: _vm.onPageChange }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-ticket-alt" }),
      _vm._v(" Player bonuses")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-muted" }, [_vm._v("ID")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Player")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Bonus")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Assigned at")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Claimed at")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Accomplished at")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Revoked at")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Expiry date")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }