var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Player - Reset Password")
    ? _c(
        "atbl-modal",
        {
          attrs: {
            title: "Reset Player Password",
            show: _vm.value,
            size: "sm",
            "close-on-backdrop": false
          },
          on: { close: _vm.cancelResetPassword },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    !_vm.isLoading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger",
                            on: { click: _vm.cancelResetPassword }
                          },
                          [_vm._v("\n\t\t\tClose\n\t\t")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { disabled: !_vm.canUpdatePassword },
                        on: { click: _vm.resetPassword }
                      },
                      [
                        !_vm.isLoading
                          ? _c("span", [_vm._v("\n\t\t\t\tSave\n\t\t\t")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { show: _vm.isLoading, type: "small" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3171965543
          )
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Password",
                    name: "password",
                    type: "password",
                    disabled: _vm.isLoading,
                    errors: _vm.errors
                  },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Confirm Password",
                    name: "password_confirmation",
                    type: "password",
                    disabled: _vm.isLoading,
                    errors: _vm.errors
                  },
                  model: {
                    value: _vm.password_confirmation,
                    callback: function($$v) {
                      _vm.password_confirmation = $$v
                    },
                    expression: "password_confirmation"
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }