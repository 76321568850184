var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["atbl-tabs", { vertical: !!_vm.vertical }] },
    [
      _c(
        "ul",
        { class: ["atbl-tabs__header", _vm.headerClasses] },
        _vm._l(_vm.tabs, function(tab, index) {
          return _c("li", { key: tab.title, class: _vm.headerItemClasses }, [
            _c("a", {
              class: [
                "atbl-tabs__tab",
                _vm.headerItemButtonClasses,
                {
                  "atbl-tabs__tab__selected": index === _vm.selectedIndex,
                  disabled: !!tab.disabled
                }
              ],
              attrs: { href: "#" },
              domProps: { innerHTML: _vm._s(_vm.getTabTitle(tab)) },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onSelectedTabEvent(index)
                }
              }
            })
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }