var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: {
        id: _vm.id,
        title: "Manage Wallet",
        header: false,
        cardClass: "player-manage-wallet tabs-atbl",
        bodyClass: "p-0"
      }
    },
    [
      _vm.$canAny(_vm.manualWallet)
        ? _c(
            "atbl-tabs",
            {
              attrs: { "active-tab": 0 },
              on: { "update:activeTab": _vm.setActiveTab }
            },
            [
              _vm.$can("Payment - Manual Wallet Withdrawals")
                ? _c(
                    "atbl-tab",
                    { attrs: { title: "Withdrawals" } },
                    [_c("AddManualWithdrawal")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Payment - Manual Wallet Refunds")
                ? _c(
                    "atbl-tab",
                    { attrs: { title: "Refunds" } },
                    [_c("AddManualRefund")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c("not-found", {
            attrs: {
              items: [],
              msg: "You don`t have access to visit this page."
            }
          }),
      _vm._v(" "),
      _c("ConfirmWithdrawalModal"),
      _vm._v(" "),
      _c("ConfirmRefundsModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }