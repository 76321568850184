var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("AgentsDetailsCards", {
        attrs: {
          agent: _vm.agentSales,
          deposits: true,
          bonus: true,
          isLoading: _vm.isLoading,
          "search-model": _vm.model
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-list mt-3" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _c("i", { staticClass: "fa fa-align-justify" }),
          _vm._v(" Details: "),
          _c("b", [_vm._v(_vm._s(_vm.agent.name))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            !_vm.isLoading && !!_vm.transactions.length
              ? _c(
                  "section",
                  { staticClass: "general-list" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._l(_vm.transactions, function(transaction, index) {
                      return [
                        _c(
                          "div",
                          {
                            key: index,
                            staticClass: "row general-list-row p-0"
                          },
                          [
                            _c("div", { staticClass: "col-6 col-lg p-2" }, [
                              _c("span", { staticClass: "small-mb-badge" }, [
                                _vm._v("Player Name")
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/players/" +
                                      transaction.player_id +
                                      "/view",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(transaction.player_name) +
                                      " " +
                                      _vm._s(transaction.player_surname) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 col-lg p-2" }, [
                              _c("span", { staticClass: "small-mb-badge" }, [
                                _vm._v("Transaction")
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/transaction/" + transaction.id,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t#T-" +
                                      _vm._s(transaction.id) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 col-lg p-2" },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Type")
                                ]),
                                _vm._v(" "),
                                _c("payment-type-badge", {
                                  attrs: {
                                    "payment-type": transaction.payment_type,
                                    "payment-type-beauty":
                                      transaction.payment_type_beauty
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 col-lg p-2" },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Order")
                                ]),
                                _vm._v(" "),
                                !!transaction.order_id
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "/orders/" + transaction.order_id,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t#O-" +
                                              _vm._s(transaction.order_id) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    ]
                                  : _c("span", [_vm._v("/")])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 col-lg p-2" },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Sales")
                                ]),
                                _vm._v(" "),
                                _vm.$options.Transactions.isSale(
                                  transaction.payment_type
                                )
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.currencyNumber(
                                              transaction,
                                              "amount"
                                            )
                                          )
                                        }
                                      })
                                    ]
                                  : _c("span", [_vm._v("/")])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-6 col-lg bg-primary-20 p-2 border-right border-color-white"
                              },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Deposits")
                                ]),
                                _vm._v(" "),
                                _vm.$options.Transactions.isDeposit(
                                  transaction.payment_type
                                )
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.currencyNumber(
                                              transaction,
                                              "amount"
                                            )
                                          )
                                        }
                                      })
                                    ]
                                  : _c("span", [_vm._v("/")])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 col-lg p-2 border-right" },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Bonus")
                                ]),
                                _vm._v(" "),
                                _vm.$options.Transactions.isBonus(
                                  transaction.payment_type
                                )
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.currencyNumber(
                                              transaction,
                                              "amount"
                                            )
                                          )
                                        }
                                      })
                                    ]
                                  : _c("span", [_vm._v("/")])
                              ],
                              2
                            )
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("items-not-found", {
                  attrs: {
                    items: _vm.transactions,
                    msg: "No transactions found."
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row sticky-element general-list-row d-none d-lg-flex" },
      [
        _c("div", { staticClass: "col" }, [_vm._v("Player Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [_vm._v("Transaction")]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [_vm._v("Type")]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [_vm._v("Order")]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [_vm._v("Sales")]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [_vm._v("Deposits")]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [_vm._v("Bonus")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }