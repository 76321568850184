import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.transactions.mutations.SET_TRANSACTIONS_TRANSACTION_ID](state, payload){
        state.transactionId = payload;
    },
    [TypesConst.transactions.mutations.SET_TRANSACTIONS_TRANSACTION_DATA](state, payload){
        state.transaction = payload;
    },
    [TypesConst.transactions.mutations.SET_TRANSACTIONS_TRANSACTION_DATA_LOADER](state, payload){
        state.transactionLoader = payload;
    },
};