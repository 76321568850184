var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("AgentsFilterForm", {
        attrs: { users: _vm.users, permissions: _vm.isOnlyAgent },
        on: { modelChange: _vm.modelChange }
      }),
      _vm._v(" "),
      _vm.showTable
        ? _c("div", { staticClass: "card card-list" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("AgentsList", {
                  attrs: {
                    sales: _vm.sales,
                    isSearching: _vm.isSearching,
                    agents: _vm.agents,
                    sort: _vm.model.sort
                  },
                  on: { sorted: _vm.sortChange, "open-agent": _vm.openAgent }
                })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isSearching && _vm.agentsData.last_page > 1
              ? _c("div", { staticClass: "card-footer" }, [
                  _c(
                    "nav",
                    { staticClass: "wrap" },
                    [
                      _c("atbl-pagination", {
                        attrs: { pagination: _vm.agentsData },
                        on: { paginate: _vm.getResults }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-users" }),
      _vm._v(" Agents \n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }