var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasPlayerFilledProfileData
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "player-play-games-holder" },
            _vm._l(_vm.bundles, function(bundle) {
              return _c(
                "div",
                { key: "bundle_" + bundle.id, staticClass: "game bundle" },
                [
                  _c(
                    "div",
                    { staticClass: "img-holder" },
                    _vm._l(bundle.bundle_games, function(bundleGame) {
                      return _c("img", {
                        attrs: {
                          src: _vm.$imageService.gameImage(
                            bundleGame.game.image
                          )
                        }
                      })
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "game-play" }, [
                    _c(
                      "p",
                      {
                        staticClass: "d-block",
                        attrs: { title: bundle.title }
                      },
                      [_vm._v(_vm._s(bundle.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.selectBundle(bundle)
                          }
                        }
                      },
                      [_c("span", [_vm._v("Play")])]
                    )
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.bundles, msg: "No bundles found." }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }