export default {
	provide() {
		return {
			toggleAddToCampaign: this.toggleAddToCampaign,
		};
	},
	data() {
		return {
			isAddToCampaignOpened: false,
		};
	},
	methods: {
		toggleAddToCampaign() {
			this.isAddToCampaignOpened = !this.isAddToCampaignOpened;
		}
	},
}