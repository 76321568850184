var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-search" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-lg-4" },
          [
            _c(
              "atbl-form-group",
              { attrs: { label: "Name" } },
              [
                _c("atbl-input", {
                  attrs: { name: "name", value: _vm.searchModel.name },
                  on: { update: _vm.updateSearchModel }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4" },
          [
            _c(
              "atbl-form-group",
              { attrs: { label: "Enabled for players" } },
              [
                _c("atbl-switch", {
                  attrs: {
                    name: "players_enabled",
                    value: _vm.searchModel.players_enabled
                  },
                  on: { update: _vm.updateSearchModel }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4" },
          [
            _c(
              "atbl-form-group",
              { attrs: { label: "Enabled for leads" } },
              [
                _c("atbl-switch", {
                  attrs: {
                    name: "leads_enabled",
                    value: _vm.searchModel.leads_enabled
                  },
                  on: { update: _vm.updateSearchModel }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-primary",
          attrs: { type: "submit" },
          on: { click: _vm.fetchEmailTemplates }
        },
        [_c("i", { staticClass: "fa fa-search" }), _vm._v(" Search\n        ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-danger",
          attrs: { type: "reset" },
          on: { click: _vm.clearSearchModel }
        },
        [_c("i", { staticClass: "fa fa-ban" }), _vm._v(" Clear\n        ")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-search" }),
      _vm._v(" Search\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }