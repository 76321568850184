import LeadModel from './LeadModel';
import { clone } from '../../../../utils/Json/Json';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';
import LeadService from '../../../../services/Marketing/LeadService';

export default {
    provide() {
        return {
            onCreateUpdateLead: this.onCreateUpdateLead,
            updateModelField: this.updateModelField,
            toggleAddLeadModal: this.toggleAddLeadModal,
        };
    },
    data() {
        return {
            isLeadModalOpen: false,
            leadModel: clone(LeadModel),
            previewCustomerModel: clone(LeadModel),
            leadModelErrors: {},
            addLeadLoader: false,
        };
    },
    methods: {
        async onCreateUpdateLead() {
            if (!this.$can('Leads - Create/Edit')) {
                window.showMessage('You does not have permissions for this action.', false);

                return;
            }

            this.addLeadLoader = true;
            this.leadModelErrors = {};

            try {
                const model = {
                    ...this.leadModel,
                    converted: this.leadModel.converted === null
                        ? false
                        : this.leadModel.converted
                };

                const response = this.leadModel.hasOwnProperty('id') && !!this.leadModel.id
                    ? await LeadService.updateLead(this.leadModel.id, model)
                    : await LeadService.createLead(model);

                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status && this.onSuccessAddLead) {
                    await this.onSuccessAddLead();
                }

                this.addLeadLoader = false;
            } catch (error) {
                this.addLeadLoader = false;

                this.leadModelErrors = getValidationErrors(error);

                window.flashSingleValidationError(error);
            }
        },
        updateModelField(name, value) {
            Object.assign(this.leadModel, {
                [name]: value,
            });
        },
        toggleAddLeadModal() {
            this.isLeadModalOpen = !this.isLeadModalOpen;

            if (!!this.isLeadModalOpen) {
                return;
            }

            this.leadModelErrors = {};

            this.leadModel = !this.leadModel.id
                ? clone(LeadModel)
                : clone(this.previewCustomerModel);
        },
    },
}