<template>
    <player-layout :id="id"
                   title="Notes"
                   icon="fa fa-note"
    >
        <template v-slot:rightSide>
            <button v-if="$can('Player - Create/Update Notes')"
                      class="btn btn-sm btn-primary"
                      @click="myModal = true"
            >
                Create Note
            </button>
        </template>

        <NotesList :notes="pnotes"
                   :isLoading="isLoading"
        />

        <!-- Modal Component -->
        <atbl-modal v-if="$can('Player - Create/Update Notes')"
                 title="Create Note"
                 :show="myModal"
                 :closeOnBackdrop="false"
                 @close="myModal = false"
        >
            <div class="row">
                <div class="col-12">
                    <atbl-select label="Note Type"
                                 name="note_id"
                                 v-model="pnote.id"
                                 :options="notes"
                                 :errors="errors"
                                 firstOptionLabel="Select note type"
                    />
                </div>

                <div class="col-12">
                    <atbl-textarea id="type"
                                v-model="pnote.message"
                                label="Message"
                                :errors="errors"
                                placeholder="Enter message"
                                class="font-weight-bold"
                                :rows="3"
                                :max-rows="6">
                    </atbl-textarea>
                </div>

                <div class="col-12">
	                <DateTimePicker v-model="pnote.followUp"
	                                :defaultValue="pnote.followUp"
	                                label="Follow-Up Date"
	                />
                </div>
            </div>

            <template #footer>
                <button class="btn btn-sm btn-danger"
                          @click="handleCancel"
                >
                    Close
                </button>
                <button class="btn btn-sm btn-primary"
                          @click="handleOK"
                >
                    Save
                </button>
            </template>
        </atbl-modal>
    </player-layout>
</template>

<script>
    import playerNav from '../../Components/Layout/PlayerNav';
    import playerInfo from '../../Components/Layout/PlayerInfo';
    import DateTimePicker from '../../../../components/Forms/Select/DateTimePicker';
    import NotesList from "./Sections/NotesList";
    import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

    export default {
        props: [
            'id',
            'auth_user'
        ],
        components: {
            playerNav,
            playerInfo,
            DateTimePicker,
            NotesList,
            AtblModal,
        },
        data: function () {
            return {
                notes: [],
                snote: null,
                pnotes: [],
                pnote: {
                    id: null,
                    message: '',
                    followUp: ''
                },
                myModal: false,
                isEdit: false,
                errors: {},
                isLoading: false,
                isTypesLoading: false
            }

        },
        methods: {
            updateNoteId: function(value){
                this.pnote.id = value;
            },
            fetchPlayerNotes(id) {
                let vm = this;

                vm.isLoading = true;
                window.axios.get(`/player/${id}/note`)
                    .then(function (response) {
                        vm.pnotes = response.data;
                    })
                    .finally(() => {
                        vm.isLoading = false;
                    });
            },
            fetchNote() {
                let vm = this;

                vm.isTypesLoading = true;
                window.axios.get(`/list-notes`).then(function (response) {
                    vm.notes = [
                        ...vm.notes,
                        ...response.data
                    ];
                })
                .finally(() => {
                    vm.isTypesLoading = false;
                });
            },
            create: function (id) {
                let vm = this;
                vm.errors = {};
                window.axios.post(`/player/${id}/note`, {
                    message: this.pnote.message,
                    follow_up: this.pnote.followUp,
                    player_id: this.id,
                    note_id: this.pnote.id,
                    user_id: this.auth_user
                }).then(function (response) {
                    vm.isEdit = false;
                    vm.myModal = false;
                    vm.fetchPlayerNotes(vm.id);
                    flash('player note was inserted.');
                    vm.clearInputs();
                }).catch(function (error) {
                    vm.errors = error.response.data.errors;
                });
            },
            handleOK: function (evt) {
                evt.preventDefault();
                this.isEdit ? this.update(this.pnote.id) : this.create(this.id)
            },
            handleCancel: function () {
                this.myModal = false;
                this.isEdit = false;
                this.errors = {};
                this.clearInputs();
            },
            clearInputs: function () {
                this.pnote.id = '';
                this.pnote.message = '';
                this.pnote.followUp = null;
            }
        },
        created: function () {
            let vm = this;

            this.fetchPlayerNotes(vm.id);
            this.fetchNote();

            Atbl.$on("reloadPlayerNotes", function () {
                vm.fetchPlayerNotes(vm.id);
            });
        }
    }
</script>
