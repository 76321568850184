<template>
    <c-sidebar fixed
               :minimize="getMinimizeSidebar"
               :show="getShowSidebar"
               @update:show="setShowSidebar"
    >
        <c-sidebar-brand class="d-md-down-none"
                         href="/"
        >
            <img :src="$imageService.path('/logos/logo.png')"
                 height="50px"
                 class="c-sidebar-brand-full"
            />
        </c-sidebar-brand>

        <Navigation :badges="getInitializedBadgesData"
                    :user="user"
        />

        <c-sidebar-minimizer class="d-md-down-none"
                             @click.native="setMinimizeSidebar(!getMinimizeSidebar)"
        />
    </c-sidebar>
</template>

<script>
    import TypesConst from "../../../store/TypesConst";
    import NamespaceConst from "../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";

    const {
        mapGetters: mapGeneralGetters,
        mapMutations: mapGeneralMutations
    } = createNamespacedHelpers(NamespaceConst.general);

    import Navigation from "./Navigation/Navigation";

    export default {
        name: 'sidebar',
        components: {
            Navigation
        },
        props: {
            user: {
                type: Number,
                required: true
            },
            callbacks: {
                type: Number,
                required: false
            }
        },
        computed: {
            ...mapGeneralGetters({
                getShowSidebar: TypesConst.general.getters.GET_SHOW_SIDEBAR,
                getMinimizeSidebar: TypesConst.general.getters.GET_MINIMIZE_SIDEBAR
            }),
            getInitializedBadgesData: function(){
                return {
                    callbacks: this.callbacks
                };
            }
        },
        methods: {
            ...mapGeneralMutations({
                setShowSidebar: TypesConst.general.mutations.SET_SHOW_SIDEBAR,
                setMinimizeSidebar: TypesConst.general.mutations.SET_MINIMIZE_SIDEBAR
            })
        }
    }
</script>