import TypesConst from "../../../TypesConst";
import PlayerCreditCardsService from "../../../../services/PlayerCreditCardsService";

export default {
    [TypesConst.players.actions.GET_PLAYERS_SAVED_CARDS_ACTIONS]({commit, state}, expired = null){
        commit(TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL_LOADER, true);

        PlayerCreditCardsService.getPlayerCreditCards(
            state.playerId,
            {
                expired,
                playerId: state.playerId
            })
            .then(response => {
                commit(TypesConst.players.mutations.SET_PLAYER_CREDIT_CARDS, response.data.data);
            })
            .catch(error => {
                let message = error.response.data.message ? error.response.data.message : error.message;
                flash(message, "alert-danger");
            })
            .finally(() => {
                commit(TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL_LOADER, false);
            });
    },
};