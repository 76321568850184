import buildUrl from './Url/BuildUrl';

export default {
    methods: {
        buildUrl,
        // TODO: There is new BuildQuery function, find all usage for this and change with new one.
        buildQuery(obj, prefix) {
            let str = [];

            for (let p in obj) {
                if (!obj.hasOwnProperty(p)) {
                    continue;
                }

                let objectValue = obj[p];

                if (!objectValue) {
                    continue;
                }

                let k = prefix
                    ? prefix + '[' + p + ']'
                    : p;

                const value = typeof objectValue === 'object'
                    ? this.buildQuery(objectValue, k)
                    : encodeURIComponent(k) + '=' + encodeURIComponent(objectValue);

                str.push(value);
            }

            return str.join('&');
        },
        getParam(param) {
            let url = new URL(window.location.href);
            return url.searchParams.get(param);
        },
    }
};