var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-lg-6 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Draw\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-3 text-center" }, [
              _c("img", {
                attrs: {
                  src: _vm.$imageService.gameImage(
                    _vm.syndicateShare.game_image
                  ),
                  width: "80"
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-9" },
              [
                !_vm.gameDrawNumbers
                  ? _c("not-found", {
                      attrs: {
                        msg: "Lottery numbers not drawn yet.",
                        items: [],
                        textAlign: "left"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "numbers-group" },
                  [
                    !!_vm.gameDrawNumbers
                      ? _vm._l(
                          _vm.getGameNumTypes(_vm.syndicateShare.game_key),
                          function(group, key) {
                            return _c("div", [
                              _vm.isTypeArray(_vm.gameDrawNumbers, key)
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: { content: key },
                                          expression: "{content: key}"
                                        }
                                      ],
                                      class:
                                        "numbers-group-wrapper " +
                                        key +
                                        "-numbers"
                                    },
                                    _vm._l(_vm.gameDrawNumbers[key], function(
                                      number
                                    ) {
                                      return _c(
                                        "span",
                                        { class: [key, _vm.typeResolver(key)] },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(number) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: { content: key },
                                          expression: "{content: key}"
                                        }
                                      ],
                                      key: key + _vm.gameDrawNumbers[key],
                                      class: [key, group, _vm.typeResolver(key)]
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.gameDrawNumbers[key]) +
                                          "\n                                    "
                                      )
                                    ]
                                  )
                            ])
                          }
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "draw-date" }, [
                  _c("span", { staticClass: "text-muted small" }, [
                    _vm._v(
                      "Date: " +
                        _vm._s(
                          _vm.dateFormat(
                            _vm.syndicateShare.cut_off_at,
                            "YYYY-MM-DD"
                          )
                        )
                    )
                  ])
                ])
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-lg-3 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Player\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "a",
            {
              staticClass: "text-primary",
              attrs: {
                href: "/players/" + _vm.syndicateShare.player_id + "/view"
              }
            },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.syndicateShare.player_name) +
                    " " +
                    _vm._s(_vm.syndicateShare.player_surname)
                )
              ])
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-lg-3 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Winnings\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("h3", { staticClass: "text-primary" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.ticketWinStatus) +
                "\n                "
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Syndicate\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v("Syndicate: "),
                _c("strong", [_vm._v(_vm._s(_vm.syndicateShare.title))])
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mr-2" }, [
                _vm._v("Total syndicate shares: "),
                _c("strong", [_vm._v(_vm._s(_vm.syndicateShare.total_shares))])
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("Syndicate lines: "),
                _c("strong", [_vm._v(_vm._s(_vm.syndicateShare.lines_count))])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _c("span", [
                _vm._v("Your shares: "),
                _c("strong", [_vm._v(_vm._s(_vm.syndicateShare.shares))])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }