<template>
    <div>
        <h5 class="text-center text-lg-left mt-2 mb-4 pb-2 border-bottom text-uppercase text-secondary pb-2">Order Info</h5>

        <div class="order-info-wrapper mb-3">
            <div class="order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light">Id</div>
            <div class="order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light">Amount</div>
            <div class="order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light">Created At</div>

            <div class="order-info-wrapper-holder text-muted">
                <span class="small-mb-badge-extended">Id</span>
                {{ order.id }}
            </div>
            <div class="order-info-wrapper-holder text-muted">
                <span class="small-mb-badge-extended">Amount</span>
                <span v-if="isOrderFree">
                    /
                </span>
                <span v-else>
                    {{ order.amount | formatCurrency }}
                </span>
            </div>
            <div class="order-info-wrapper-holder text-muted">
                <span class="small-mb-badge-extended">Created At</span>
                {{ utcToLocal(order.created_at) }}
            </div>
        </div>

        <div class="order-info-wrapper mb-3">
            <div class="order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light">Agent</div>
            <div class="order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light">Player</div>
            <div class="order-info-wrapper-holder hide-mobile h6 m-0 mb-2 p-2 bg-light"
                 :class="{
                    half: canBePaidAgain
                 }"
            >
                Status
            </div>
            <div v-if="canBePaidAgain"
                 class="order-info-wrapper-holder half hide-mobile h6 m-0 mb-2 p-2 bg-light"
            >
                Action
            </div>

            <div class="order-info-wrapper-holder text-muted">
                <span class="small-mb-badge-extended">Agent</span>
                <span v-if="order.user">
                    <b>#{{ order.user.id }}</b> / {{ order.user.name }}
                </span>
                <span v-else>
                    /
                </span>
            </div>
            <div class="order-info-wrapper-holder text-muted">
                <span class="small-mb-badge-extended">Player</span>
                <a :href="`/players/${order.player.id}/view`"
                   target="_blank"
                >
                    {{ order.player.name }} {{ order.player.surname }}
                </a>
            </div>
            <div class="order-info-wrapper-holder text-muted"
                 :class="{
                    half: canBePaidAgain
                 }"
            >
                <span class="small-mb-badge-extended">Status</span>
                <span v-if="order.status"
                      :class="[`badge-${$options.OrdersConstants.statusColor(order.status)}`]"
                      class="badge font-weight-bold"
                         v-tooltip="{
                            content: 'Status'
                         }"
                >
                    {{ order.status.split('_').join(' ') }}
                </span>
                <span v-else>/</span>
            </div>
            <div v-if="canBePaidAgain"
                 class="order-info-wrapper-holder half text-muted"
            >
                <span class="small-mb-badge-extended">Action</span>
                <button class="btn btn-sm btn-primary"
                          @click="checkoutAction"
                          :disabled="isOrderPaymentLocked"
                          :title="isOrderPaymentLocked ? 'Payment for this order is locked during transaction processing' : ''"
                >
                    Buy
                </button>
            </div>
        </div>

        <h5 class="text-center text-lg-left mt-4 mt-lg-2 mb-0 pb-2 text-uppercase text-secondary pb-2 border-bottom border-lg-none">Order Items</h5>

        <div class="general-list">
            <div class="row">
                <div class="col-12 col-lg text-muted">Item</div>
                <div class="col-12 col-lg-2 text-muted text-center">Price</div>
                <div class="col-12 col-lg-2 text-muted text-center">Discount (%)</div>
                <div class="col-12 col-lg-3 text-muted text-center">Created At</div>
            </div>

            <div v-for="(orderItem, orderItemIndex) in orderItems" :key="orderItemIndex" class="row">
                <div class="col-12 col-lg text-center text-lg-left">
                    <span class="small-mb-badge-extended">Item</span>
                    <item :item="orderItem.item" />
                </div>
                <div class="col-12 col-lg-2 text-center align-self-center">
                    <span class="small-mb-badge-extended">Price</span>
                    <div v-if="!isOrderFree">
                        <s v-if="!!orderItem.discount" class="text-danger">
                            {{ orderItem.price | formatCurrency }}
                        </s>
                        <span v-else>
                            {{ orderItem.total_price | formatCurrency }}
                        </span>

                        <span v-if="!!orderItem.discount">
                            {{ orderItem.total_price | formatCurrency }}
                        </span>
                    </div>
                    <span v-else>
                        /
                    </span>
                </div>
                <div class="col-12 col-lg-2 text-center align-self-center">
                    <span class="small-mb-badge-extended">Discount (%)</span>
                    {{ !!orderItem.discount ? `${orderItem.discount} %` : '' }}
                </div>
                <div class="col-12 col-lg-3 text-center align-self-center">
                    <span class="small-mb-badge-extended">Created At</span>
                    {{ utcToLocal(orderItem.created_at) }}
                </div>
            </div>

            <not-found msg="No order items found."
                       :items="orderItems"
            />
        </div>
    </div>
</template>

<script>
import OrdersConstants from '../../../../constants/Orders';
import Item from '../Components/Item';
import Orders from '../../../../constants/Orders';

export default {
    name: 'order-info',
    OrdersConstants,
    components: {
        Item
    },
    props: {
        order: {
            type: Object,
            required: true
        },
        isOrderFree: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'checkout'
    ],
    computed: {
        orderItems() {
            return !this.isObjectEmpty(this.order) ? this.order.order_items : [];
        },
        canBePaidAgain() {
            return [Orders.status.PENDING, Orders.status.PAYMENT_FAILED].includes(this.order.status)
        },
        isOrderPaymentLocked() {
            if(!this.order.locked_by_transaction_id) {
                return false;
            }

            if(!this.order.payment_locked_until) {
                return true;
            }

            return new Date(this.order.payment_locked_until) > new Date();
        },
    },
    methods: {
        checkoutAction() {
            this.$emit('checkout', true);
        }
    }
}
</script>