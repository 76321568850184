var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-range-picker", {
    class: {
      "disabled-state": _vm.disabled
    },
    attrs: {
      opens: _vm.opens,
      singleDatePicker: _vm.singleDatePicker,
      ranges: _vm.showRanges,
      "locale-data": _vm.locale,
      showDropdowns: _vm.showDropdowns,
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate,
      disabled: _vm.disabled,
      "auto-apply": _vm.autoApply
    },
    on: { update: _vm.afterUpdate },
    scopedSlots: _vm._u([
      {
        key: "input",
        fn: function(picker) {
          return _c("div", {}, [
            !!_vm.value
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between"
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.value))]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.afterUpdate(null)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-times" })]
                    )
                  ]
                )
              : _c("span", [_vm._v("Select date")])
          ])
        }
      }
    ]),
    model: {
      value: _vm.dateRange,
      callback: function($$v) {
        _vm.dateRange = $$v
      },
      expression: "dateRange"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }