`<template>
    <div class="dashboard-bonus">
        <div class="dashboard-bonus-heading">
            <p class="dashboard-bonus-title">Bonus</p>
        </div>
        <div class="row">
            <div class="col">
                <statistic-card bgColor="bg-linkedin"
                                crmLabel="DAILY"
                                :crmTotal="getStatisticsValue('crm', 'bonus')"
                                webLabel="MONTHLY"
                                :webTotal="getStatisticsValue('crm', 'bonus', 'monthly')"
                                class="mb-0"
                >
                    <i class="fa fa-money-bill"></i>
                </statistic-card>
            </div>
        </div>
    </div>
</template>

<script>
import StatisticCard from '../Components/StatisticCard';

export default {
    name: 'sales',
    inject: [
        'getStatisticsValue',
    ],
    components: {
        StatisticCard
    },
};
</script>