<template>
  <div class="general-list">
    <div class="row">
      <div class="col-6 col-lg text-muted">Reference</div>
      <div class="col-6 col-lg text-muted text-center">Created By</div>
      <div class="col-6 col-lg text-muted text-center">Date</div>
      <div class="col-6 col-lg text-muted text-center">Amount</div>
      <div class="col-6 col-lg text-muted text-center">Status</div>
      <div class="col-12 col-lg text-muted text-left text-lg-center" v-if="$can('Withdrawals - Review')">Action
      </div>
    </div>

    <div v-if="!isLoading && withdrawals.length" v-for="item in withdrawals" :key="item.id" class="row">
      <div class="col-6 col-lg align-self-center mb-2 mb-lg-0">
        <span class="small-mb-badge">Reference</span>
        <div v-if="item.holder && item.holder !== null">
          <a v-if="!isAdmin"
             class="d-block"
             href="#"
          >
            {{ item.payment_brand }} *{{ item.last_4_digits }}
          </a>
          <a v-else
             class="d-block"
             :href="`/transaction/get/withdrawals/${item.id}`"
          >
            {{ item.payment_brand }} *{{ item.last_4_digits }}
          </a>
          <small class="text-muted"><b>Holder:</b> {{ item.holder }}</small>
        </div>
        <p v-else class="text-danger m-0">
          This card is removed.
        </p>
      </div>
      <div class="col-6 col-lg text-left text-lg-center align-self-start align-self-lg-center mb-2 mb-lg-0">
        <span class="small-mb-badge">Created By</span>
        <created-by-badge :created="item"/>
      </div>
      <div class="col-6 col-lg text-left text-lg-center align-self-start align-self-lg-center mb-2 mb-lg-0">
        <span class="small-mb-badge">Created At</span>
        <small>{{ utcToLocal(item.created_at) }}</small>
      </div>
      <div class="col-6 col-lg text-left text-lg-center align-self-start align-self-lg-center mb-2 mb-lg-0">
        <span class="small-mb-badge">Amount</span>
        <span class="badge badge-primary">
            {{ formatCurrency(item.amount) }}
        </span>
      </div>
      <div class="col-6 col-lg text-left text-lg-center align-self-start align-self-lg-center mb-2 mb-lg-0">
        <span class="small-mb-badge">Status</span>
        <span class="d-block">
            <i class="fa fa-clock"></i>
            <withdrawals-status-badge :status="item.status"/>
        </span>
      </div>
      <div v-if="$can('Withdrawals - Review')"
           class="col-12 col-lg align-self-start align-self-lg-center text-left text-lg-center"
      >
        <span class="small-mb-badge">Action</span>
        <a href="#" @click.prevent="reviewWithdrawal(item.id, item.player_id)" class="d-block"><i class="fa fa-flag"></i> Review</a>
      </div>
    </div>

    <items-not-found v-if="!isLoading"
                     :items="withdrawals"
                     msg="No withdrawals found."
    />

    <loader :show="isLoading"
            type="small"
    />

    <nav class="wrap py-3" v-if="withdrawalsData.last_page > 1">
      <atbl-pagination :pagination="withdrawalsData"
                       @paginate="getPlayerWithdrawals"
      />
    </nav>
  </div>
</template>

<script>
import formatCurrency from '../../../../../filters/FormatCurrency';
import AtblPagination from '../../../../../components/AtblPagination.vue';
import Loader from '../../../../../components/Widgets/Loader.vue';
import ItemsNotFound from '../../../../../components/Lists/ItemsNotFound.vue';
import WithdrawalsStatusBadge from '../../../../../components/Transactions/WithdrawalsStatusBadge.vue';
import CreatedByBadge from '../../../../../components/Transactions/CreatedByBadge.vue';

export default {
    name: 'player-withdrawals-list',
    inject: [
        'reviewWithdrawal',
        'getPlayerWithdrawals',
    ],
    components: {
        AtblPagination,
        Loader,
        ItemsNotFound,
        WithdrawalsStatusBadge,
        CreatedByBadge,
    },
    props: {
        withdrawals: {
            type: Array,
            required: true
        },
        withdrawalsData: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isAdmin: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        formatCurrency,
    },
};
</script>