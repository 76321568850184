var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "card", class: _vm.cardClass }, [
      _c("div", { staticClass: "card-body" }, [
        _c("i", {
          staticClass: "p-3 font-2xl mr-3 float-left",
          class: [_vm.icon, "bg-" + _vm.color],
          staticStyle: { "min-width": "56px", "text-align": "center" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "h5 mb-0 mt-2", class: "text-" + _vm.color }, [
          _vm._v("\n                " + _vm._s(_vm.number) + "\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-muted text-uppercase font-xs" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }