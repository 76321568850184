var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "general-list" },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col" }, [_vm._v("Name")]),
          _vm._v(" "),
          _vm._l(_vm.monthNames, function(month, index) {
            return _c("div", { key: index, staticClass: "col" }, [
              _c(
                "a",
                {
                  staticClass: "sort-link",
                  class: { active: _vm.sortBy === index + 1 },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.sortChange(index + 1)
                    }
                  }
                },
                [
                  _vm._v("\n                " + _vm._s(month) + " "),
                  _vm.sortBy === index + 1
                    ? _c("span", [
                        _c("i", {
                          staticClass: "fa",
                          class: {
                            "fa-caret-up": _vm.sortDirection === "asc",
                            "fa-caret-down": _vm.sortDirection === "desc"
                          }
                        })
                      ])
                    : _vm._e()
                ]
              )
            ])
          }),
          _vm._v(" "),
          _vm.$can("Reports Agents - View")
            ? _c("div", { staticClass: "col" }, [
                _vm._v("\n            Actions\n        ")
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.sortedAgents, function(agent, index) {
        return !_vm.isSearching
          ? _c(
              "div",
              { key: agent.id, staticClass: "row" },
              [
                _c("div", { staticClass: "col-6 col-lg" }, [
                  _c("span", { staticClass: "small-mb-badge" }, [
                    _vm._v("Name")
                  ]),
                  _vm._v(
                    "\n                " + _vm._s(agent.name) + "\n            "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.monthNames, function(month, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "col-6 col-lg" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v(_vm._s(month))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Deposit",
                                placement: "top"
                              },
                              expression:
                                "{\n                        content: 'Deposit',\n                        placement: 'top'\n                    }"
                            }
                          ],
                          staticClass: "badge badge-light",
                          staticStyle: { width: "17px" }
                        },
                        [_vm._v("D")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("withoutFraction")(
                              _vm._f("agentDeposit")(agent, index + 1, _vm.year)
                            )
                          ) +
                          "\n                "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Sales",
                                placement: "top"
                              },
                              expression:
                                "{\n                        content: 'Sales',\n                        placement: 'top'\n                    }"
                            }
                          ],
                          staticClass: "badge badge-dark",
                          staticStyle: { width: "17px" }
                        },
                        [_vm._v("\n                    S\n                ")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("withoutFraction")(
                              _vm._f("agentSales")(agent, index + 1, _vm.year)
                            )
                          ) +
                          "\n            "
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.$can("Reports Agents - View")
                  ? _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Actions")
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.openAgent($event, agent)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-eye" }),
                          _vm._v(" View Agent\n                ")
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      }),
      _vm._v(" "),
      !_vm.isSearching
        ? _c("not-found", {
            attrs: { items: _vm.agents, msg: "No agents found." }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isSearching, type: "small" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }