<template>
    <div class="container-fluid">
        <div v-if="!playerLoader && canViewPlayer" class="row">
            <div class="col-12 col-sm-4 col-lg-3">
                <player-nav :id="id" />
            </div>

            <div ref="content" class="col-12 col-sm-8 col-lg-9 pl-lg-0">
				<div v-if="isPlayerBlocked"
					 class="alert alert-danger"
				>
					This player is blocked.
				</div>
	            
	            <player-info :id="id" />

                <slot></slot>
            </div>
        </div>
	    <blocked-sign v-if="!playerLoader && !canViewPlayer"
	                  message="This player is blocked."
	    />
	    <loader :show="playerLoader"
	            type="small"
	    />
    </div>
</template>

<script>
	import TypesConst from '../../../../../store/TypesConst';
	import NamespaceConst from '../../../../../store/NamespaceConst';
	import { createNamespacedHelpers } from "vuex";
	
	const {
		mapGetters: mapPlayersGetters,
		mapMutations: mapPlayersMutations,
		mapActions: mapPlayersActions
		
	} = createNamespacedHelpers(NamespaceConst.players);
	
    import PlayerNav from '../../../Components/Layout/PlayerNav';
    import PlayerInfo from '../../../Components/Layout/PlayerInfo';

    export default {
        name: "player-layout",
        components: {
            PlayerNav,
            PlayerInfo
        },
        props: {
            id: {
                type: Number
            }
        },
	    computed: {
		    ...mapPlayersGetters({
			    player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL,
			    playerLoader: TypesConst.players.getters.GET_PLAYER_LOADER
		    }),
		    isPlayerBlocked: function () {
			    return this.player.blocked === true;
		    },
        	canViewPlayer: function () {
		    	if (this.isPlayerBlocked) {
		    		return this.$can("Player - View Blocked Profile");
			    }
        		return !this.player.blocked;
	        }
	    },
	    methods: {
		    ...mapPlayersMutations({
			    setPlayerId: TypesConst.players.mutations.SET_PLAYER_ID
		    }),
		    ...mapPlayersActions({
			    fetchPlayer: TypesConst.players.actions.FETCH_PLAYER_ACTION
		    })
	    },
	    created () {
		    let vm = this;
		
		    this.setPlayerId(vm.id);
		    this.fetchPlayer();
		    Atbl.$on("fetchPlayer", () => {
			    vm.fetchPlayer(vm.id);
		    });
        }
    }
</script>