<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header">
                <a href="#"
                   class="mr-2"
                   @click.prevent="goBack"
                >
                    <i class="fa fa-chevron-left"></i>
                </a>
                <i class='fa fa-users'></i> New Players ({{month}})
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col">
                            Player Name
                        </div>
                        <div class="col">
                            Created At
                        </div>
                        <div class="col">
                            Deposit
                        </div>
                        <div class="col">
                            Lead Converted
                        </div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(item, index) in players.data"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0">
                                <span class="small-mb-badge">Player Name</span>
                                <a :href="`/players/${item.id}/view`">
                                    {{ item.name }} {{ item.surname }}
                                </a>
                            </div>
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0">
                                <span class="small-mb-badge">Created At</span>
                                {{ utcToLocal(item.created_at) }}
                            </div>
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Deposit</span>
                                <span :class="`badge badge-${!!item.deposit ? 'success' : 'danger'}`">
                                    {{ !!item.deposit ? 'Yes' : 'No' }}
                                </span>
                            </div>
                            <div class="col-12 col-sm-6 col-lg text-center text-lg-left">
                                <span class="small-mb-badge">Lead Converted</span>
                                <span :class="`badge badge-${!!item.lead_id ? 'success' : 'danger'}`">
                                    {{ !!item.lead_id ? 'Yes' : 'No' }}
                                </span>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />
                    <not-found v-if="!isLoading"
                               msg="No reports data found"
                               :items="players.data"
                    />
                </div>
            </div>

            <div v-if="!isLoading && players.last_page > 1" class="card-footer">
                <nav class="wrap">
                    <atbl-pagination :pagination="players"
                                     @paginate="fetchMonthlyDetails"
                    />
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewPlayersDetails',
    props: {
        month: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            isLoading: false,
            players: {},
        };
    },
    methods: {
        async fetchMonthlyDetails(page = 1) {
            this.isLoading = true;

            try {
                const response = await window.axios.get(`/reports/new-players/${this.month}/details/list`, {
                    params: {
                        page
                    }
                });

                this.players = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        goBack() {
            window.location.href = this.getParam('redirect');
        },
    },
    async created() {
        await this.fetchMonthlyDetails();
    },
}
</script>