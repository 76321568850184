var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ticket-number" }, [
    _c(
      "span",
      {
        staticClass: "d-block font-weight-bold w-100 text-center text-lg-left"
      },
      [_vm._v("Ticket Number:")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ticket-number-group" },
      [
        _vm._l(_vm.getWithoutLastNumber, function(item) {
          return _vm.getWithoutLastNumber.length > 0
            ? _c("span", [
                _vm._v("\n            " + _vm._s(item) + "\n        ")
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ticket-number-picker" },
          [
            _c(
              "c-dropdown",
              {
                attrs: {
                  "toggler-text": _vm.getLastGeneratedNumber,
                  dropup: ""
                }
              },
              _vm._l(_vm.getPickerNumbers, function(item) {
                return _c(
                  "c-dropdown-item",
                  {
                    key: item,
                    on: {
                      click: function($event) {
                        return _vm.changeNumber(item)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item) +
                        "\n                "
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }