<template>
	<div class="card-group">
		<statistics-card icon="fa fa-cart-plus"
		                 :number="this.currencyNumber(this.agent, 'total')"
		                 title="Total Sale"
		                 :value="0"
		                 :isLoading="isLoading"
		/>
		<statistics-card v-if="deposits"
		                 icon="fa fa-cart-plus"
		                 :number="this.currencyNumber(this.agent, 'deposits')"
		                 title="Deposits"
		                 :value="0"
		                 :isLoading="isLoading"
		/>
		<statistics-card v-if="bonus"
		                 icon="fa fa-gift"
		                 :number="this.currencyNumber(this.agent, 'bonus')"
		                 title="Bonus"
		                 :value="0"
		                 :isLoading="isLoading"
		/>
		<statistics-card icon="fa fa-percent"
		                 title="Commission"
		                 :value="0"
		                 :isLoading="isLoading"
		>
			<template #value
			          v-if="!isLoading && this.agent"
			>
				<span v-html="getCommissionAmount" />
				<a :href="getCommissionDetailsLink"
				   target="_blank"
				   class="ml-1 font-1x2"
				>
					Details
				</a>
			</template>
		</statistics-card>
	</div>
</template>

<script>
import StatisticsCard from "../../../Dashboard/Components/StatisticsCard.vue";
import buildQuery from "../../../../utils/Url/BuildQuery";

export default {
	name: "agents-details-cards",
	components: {
		StatisticsCard,
	},
	props: {
		agent: {
			type: Object,
			required: true
		},
		deposits: {
			type: Boolean,
			default: true
		},
		bonus: {
			type: Boolean,
			default: false
		},
		isLoading: {
			type: Boolean,
			required: true
		},
		searchModel: {
			type: Object,
			default: () => ({})
		},
		commissionReportType: {
			type: String,
			default: 'single-day'
		},
	},
	computed: {
		getCommissionAmount() {
			if (!this.agent.commission) {
				return 0;
			}
			
			return this.currencyNumber(this.agent, 'commission')
		},
		getCommissionDetailsLink() {
			const queryParams = buildQuery({
				user_id: this.agent.id,
				dateFrom: this.searchModel.dateFrom,
				dateTo: this.searchModel.dateTo,
			});
			
			return `/commissions/eligible-transactions/${this.commissionReportType}?${queryParams}`;
		},
	},
}
</script>