const Acl = {
    install(Vue, options) {
        Vue.prototype.$can = function (permission) {
            if (window.layer) {
                if (this.getIsAdmin) {
                    return true;
                }
                return window.layer.lip.includes(permission);
            }
            return false;
        };

        Vue.prototype.$canAny = function (permission) {
            if (window.layer) {
                if (this.getIsAdmin) {
                    return true;
                }
                return permission.some(i => window.layer.lip.includes(i));
            }
            return false;
        };

        Vue.prototype.$hasAccessToAllUsersData = function () {
            if (!window.layer) {
                return false;
            }

            if (!this.getIsOnlyAgent) {
                return true;
            }

            return this.$can('Has Access To All Users Data');
        };
    }
};

module.exports = Acl;