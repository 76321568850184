import ExchangeOfficeService from '../../../services/ExchangeOfficeService';

const state = {
    currencyCode: 'GBP',
    currencyHtmlSymbol: '£',
};

const getters = {
    code: state => state.currencyCode,
    htmlSymbol: state => state.currencyHtmlSymbol,
};

const actions = {
    fetchDefaultCurrency({ commit }) {
        const promise = ExchangeOfficeService.getDefaultCurrency();

        promise.then((response) => {
            commit('setDefaultCurrency', {currencyCode: response.data.code, currencySymbol: response.data.symbol});
        }).catch(() => {
            commit('setDefaultCurrency', {currencyCode: 'GBP', currencySymbol: '£'});
        });
        
        return promise;
    },
};

const mutations = {
    setDefaultCurrency(state, { currencyCode, currencySymbol }) {
        state.currencyCode = currencyCode;
        state.currencyHtmlSymbol = currencySymbol;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}