var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configuration-form" }, [
    _c(
      "a",
      {
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.onRemoveConfigurationEvent(_vm.numberTypeIndex)
          }
        }
      },
      [_c("i", { staticClass: "fa fa-times" })]
    ),
    _vm._v(" "),
    _c("p", [_vm._v("How many numbers?")]),
    _vm._v(" "),
    _c("div", [
      _c("input", {
        staticClass: "configuration-text-input",
        attrs: {
          placeholder: "Numbers amount (Ex: 7)",
          name: "numbers",
          type: "number"
        },
        domProps: { value: _vm.model.numbers },
        on: {
          input: function($event) {
            return _vm.onUpdateModelEvent("numbers", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c("input", {
        staticClass: "configuration-text-input",
        attrs: {
          placeholder: "Number length (Ex: 4)",
          name: "length",
          type: "number"
        },
        domProps: { value: _vm.model.length },
        on: {
          input: function($event) {
            return _vm.onUpdateModelEvent("length", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { disabled: !_vm.isValid },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.onSaveEvent.apply(null, arguments)
            }
          }
        },
        [_c("i", { staticClass: "fa fa-check" }), _vm._v(" Save\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }