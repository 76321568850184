<template>
    <section class="settings-categories">
        <div class="row settings-categories-breadcrumb">
            <div class="col">
                <div class="breadcrumb-holder">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="#" @click="openSection($event, 0, true)">
                                <i class="fa fa-home text-dark" aria-hidden="false"></i>
                            </a>
                        </li>

                        <li class="breadcrumb-item" v-for="(item, index) in breadcrumbs" :key="index">
                            <a href="#" @click="openSection($event, item.id, true)">
                                {{ item.name }}
                            </a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="general-list">
            <div class="row">
                <div class="col">Name</div>
                <div class="col">Key</div>
                <div class="col-2">Type</div>
                <div class="col-2 text-center"
                       v-if="$canAny(permissionsOperations)"
                >
                    ...
                </div>
            </div>

            <div v-if="!isLoading" v-for="(section, index) in sections"
                   :key="index"
                   class="row"
            >
                <div class="col">
                    <a href="#" @click="openSection($event, section.id)">
                        {{ section.name }}
                    </a>
                </div>
                <div class="col">
                    {{ section.key }}
                </div>
                <div class="col-2">
                    {{ section.type }}
                </div>
                <div class="col-2 text-center"
                       v-if="$canAny(permissionsOperations)"
                >
                    <c-dropdown id="dropdown-right"
                                placement="bottom-end"
                                :caret="false"
                                color="link"
                    >
                        <template #toggler-content>
                            <i class="fa fa-ellipsis-v" aria-hidden="false"></i>
                        </template>
                        <c-dropdown-item href="#"
                                         @click="editSettingSection(section.id)"
                                         v-if="$can('Settings - Create/Update')"
                        >
                            Edit
                        </c-dropdown-item>
                        <c-dropdown-item href="#"
                                         @click="removeSettingSection(section)"
                                         v-if="$can('Settings - Remove')"
                        >
                            Remove
                        </c-dropdown-item>
                    </c-dropdown>
                </div>
            </div>

            <not-found v-if="!isLoading"
                       :items="sections"
                       msg="No categories/sections found"
            />

            <loader class="my-2"
                    type="small"
                    :show="isLoading"
            />
        </div>
    </section>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const {
        mapActions,
        mapGetters
    } = createNamespacedHelpers(NamespaceConst.settings);

    export default {
        name: "settings-sections-list",
        data: function(){
            return {
                permissionsOperations: [
                    "Settings - Create/Update",
                    "Settings - Remove"
                ]
            };
        },
        computed: {
            ...mapGetters({
                isLoading: TypesConst.settings.getters.SECTIONS_LOADER,
                sections: TypesConst.settings.getters.GET_SETTINGS_SECTIONS,
                breadcrumbs: TypesConst.settings.getters.GET_BREADCRUMBS,
                selectedSection: TypesConst.settings.getters.GET_SELECTED_SECTION,
                parentId: TypesConst.settings.getters.GET_PARENT_ID
            })
        },
        methods: {
            ...mapActions({
                getSettingsSections: TypesConst.settings.actions.FETCH_SETTINGS_SECTIONS_ACTION,
                getSection: TypesConst.settings.actions.OPEN_SECTION_ACTION,
                editSettingSection: TypesConst.settings.actions.EDIT_SETTING_SECTION_ACTION,
                removeSettingSection: TypesConst.settings.actions.REMOVE_SETTINGS_SECTION_ACTION
            }),
            openSection: function(e, sectionId, isBreadcrumb = false){
                e.preventDefault();

                this.getSection({
                    sectionId: sectionId,
                    isBreadcrumb: isBreadcrumb
                });
            }
        },
        created: function(){
            this.getSettingsSections(this.parentId);
        }
    }
</script>

<style scoped lang="scss">
    .settings {
        &-categories {
            &-breadcrumb {
                border-bottom: 1px solid #e0e0e0;
                font-weight: bold;

                ol {
                    margin: 0;
                    padding: 10px 0;
                    border: 0;
                    display: -webkit-box;
                    width: 100%;
                    flex-wrap: unset;
                }
            }

            .breadcrumb-holder {
                width: 100%;
                overflow-x: auto;
            }

            &-first-row {
                padding: 10px 0;
                background-color: #f6f6f6;
                border-bottom: 1px solid #e0e0e0;
                font-weight: bold;
            }

            &-row {
                padding: 10px 0;
                border-bottom: 1px solid #e0e0e0;
                align-items: center;

                &:nth-child(even){
                    background-color: #f6f6f6;
                }
            }
        }
    }
</style>