import TypesConst from "../../TypesConst";
import NotificationsService from "../../../services/NotificationsService";

export default {
    [TypesConst.notifications.actions.NOTIFICATIONS_GET_LIST_ACTION]({commit, state}, page = 1){
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_LOADER, true);

        NotificationsService.getNotificationsList({
                page
            })
            .then(response => {
                commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST, response.data.data);
                commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_RESPONSE, response.data);
            })
            .catch(error => flashError(error))
            .finally(() => {
                commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_LOADER, false);
            });
    },
    [TypesConst.notifications.actions.NOTIFICATIONS_GET_BY_ID_ACTION]({commit, dispatch}, id){
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_READ_BAR_LOADER, true);

        NotificationsService.getNotificationsById(id)
            .then(response => {
                commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_MODEL, response.data);
                dispatch(TypesConst.notifications.actions.NOTIFICATIONS_MARK_AS_READ_ACTION, id);
                dispatch(TypesConst.notifications.actions.NOTIFICATIONS_WIDGET_DATA_ACTION);
            })
            .catch(error => flashError(error))
            .finally(() => {
                commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_READ_BAR_LOADER, false);
            });
    },
    [TypesConst.notifications.actions.NOTIFICATIONS_REMOVE_ACTION]({commit, dispatch}, id){
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_LOADER, true);

        NotificationsService.removeNotificationById(id)
            .then(response => {
                let { status, message } = response.data;

                if(status) {
                    dispatch(TypesConst.notifications.actions.NOTIFICATIONS_GET_LIST_ACTION);
                    dispatch(TypesConst.notifications.actions.NOTIFICATIONS_WIDGET_DATA_ACTION);
                }

                flash(message, status ? "alert-success" : "alert-danger");
            })
            .catch(error => flashError(error));
    },
    [TypesConst.notifications.actions.NOTIFICATIONS_MARK_ALL_AS_READ_ACTION]({commit, dispatch}){
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_LOADER, true);

        NotificationsService.getNotificationsAllMarkAsRead()
            .then(response => {
                let { status, message } = response.data;

                if(status) {
                    dispatch(TypesConst.notifications.actions.NOTIFICATIONS_GET_LIST_ACTION);
                    dispatch(TypesConst.notifications.actions.NOTIFICATIONS_WIDGET_DATA_ACTION);
                }

                flash(message, status ? "alert-success" : "alert-danger");
            })
            .catch(error => flashError(error));
    },
    [TypesConst.notifications.actions.NOTIFICATIONS_CLEAR_ALL_ACTION]({commit, dispatch}){
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST_LOADER, true);

        NotificationsService.clearAllNotifications()
            .then(response => {
                let { status, message } = response.data;

                if(status) {
                    dispatch(TypesConst.notifications.actions.NOTIFICATIONS_GET_LIST_ACTION);
                    dispatch(TypesConst.notifications.actions.NOTIFICATIONS_WIDGET_DATA_ACTION);
                }

                flash(message, status ? "alert-success" : "alert-danger");
            })
            .catch(error => flashError(error));
    },
    [TypesConst.notifications.actions.NOTIFICATIONS_WIDGET_DATA_ACTION]({commit}){
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_WIDGET_DATA_LOADER, true);

        NotificationsService.getNotificationsWidgetData()
            .then(response => {
                commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_WIDGET_DATA, response.data);
            })
            .catch(error => flashError(error))
            .finally(() => {
                commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_WIDGET_DATA_LOADER, false);
            });
    },
    [TypesConst.notifications.actions.NOTIFICATIONS_READ_ACTION]({commit, dispatch}, notification){
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_READ_BAR, true);

        dispatch(TypesConst.notifications.actions.NOTIFICATIONS_GET_BY_ID_ACTION, notification.id);
    },
    [TypesConst.notifications.actions.NOTIFICATIONS_MARK_AS_READ_ACTION]({commit, state}, id){
        let markAsRead = (JSON.parse(JSON.stringify(state.notificationsList))).map(item => {
            if(item.id === id) {
                item.read_at = new Date();
            }

            return {
                ...item
            };
        });
        commit(TypesConst.notifications.mutations.SET_NOTIFICATIONS_LIST, markAsRead);
    },
};