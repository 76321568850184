var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        show: _vm.show,
        "close-on-backdrop": false,
        title: "Confirm add"
      },
      on: { input: _vm.toggleConfirmAddAllLeadsModal },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end align-items-center"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger mr-2",
                      attrs: { disabled: _vm.isLoading },
                      on: { click: _vm.toggleConfirmAddAllLeadsModal }
                    },
                    [_vm._v("\n                No\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success",
                      attrs: { disabled: _vm.isLoading },
                      on: { click: _vm.onAddAllLeadsToCampaign }
                    },
                    [_vm._v("\n                Yes\n            ")]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isLoading
        ? _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              "\n        Do you really want to " +
                _vm._s(_vm.inCampaign ? "remove" : "add") +
                " "
            ),
            _c("b", [_vm._v(_vm._s(_vm.leadsCount))]),
            _vm._v(" leads at once?\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "h4",
            { staticClass: "text-center text-secondary text-uppercase" },
            [_vm._v("\n        PROCESSING\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }