var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: {
        id: _vm.id,
        title: "Agents History",
        icon: "fa fa-user-friends"
      },
      scopedSlots: _vm._u([
        {
          key: "first",
          fn: function() {
            return [
              _c("div", { staticClass: "card card-search" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-header d-flex justify-content-between align-items-center"
                  },
                  [
                    _c("div", [
                      _c("i", {
                        staticClass: "fa fa-search",
                        attrs: { "aria-hidden": "false" }
                      }),
                      _vm._v(" Search\n                ")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4" },
                      [
                        _c("agent-picker", {
                          model: {
                            value: _vm.model.agent,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "agent", $$v)
                            },
                            expression: "model.agent"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4" },
                      [
                        _c(
                          "atbl-form-group",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              id: "date",
                              label: "Date",
                              "label-for": "Date"
                            }
                          },
                          [
                            _c("multi-date-picker", {
                              attrs: { format: "YYYY-MM-DD" },
                              model: {
                                value: _vm.createdAt,
                                callback: function($$v) {
                                  _vm.createdAt = $$v
                                },
                                expression: "createdAt"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      attrs: { type: "reset" },
                      on: { click: _vm.clear }
                    },
                    [
                      _c("i", { staticClass: "fa fa-ban" }),
                      _vm._v(" Clear\n                ")
                    ]
                  )
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6 col-md-3 text-muted" }, [
              _vm._v("Agent")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 col-md-3 text-muted" }, [
              _vm._v("From")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 col-md-3 text-muted" }, [
              _vm._v("To")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 col-md-3 text-muted" }, [
              _vm._v("Assigned By")
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.filteredAgents, function(item, index) {
            return !_vm.isLoading
              ? _c("div", { key: index, staticClass: "row" }, [
                  _c("div", { staticClass: "col-6 col-md-3 mb-2 mb-lg-0" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Agent")
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(item.agent) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 col-md-3 mb-2 mb-lg-0" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("From")
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.utcToLocal(item.from)) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 col-md-3 mb-2 mb-lg-0" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("To")
                    ]),
                    _vm._v(" "),
                    !item.to
                      ? _c("span", { staticClass: "badge badge-success" }, [
                          _vm._v(
                            "\n                    Present\n                "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.utcToLocal(item.to)) +
                              "\n                "
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 col-md-3 mb-2 mb-lg-0" }, [
                    _c("span", { staticClass: "small-mb-badge" }, [
                      _vm._v("Assigned By")
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(item.assigned_by) +
                        "\n            "
                    )
                  ])
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: {
                  items: _vm.filteredAgents,
                  msg: "No agents history found."
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }