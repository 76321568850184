<template>
    <div class="card card-search">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div>
                <i class="fa fa-search" aria-hidden="false"></i> Search
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-4">
                    <atbl-input label="Ticket ID" name="ticket-id" placeholder="Enter ticket id" v-model="ticketId" />
                </div>

                <div class="col-12 col-md-4">
                    <atbl-form-group id="date" label="Draw date" label-for="Date" class="font-weight-bold">
                        <date-picker v-model="drawDate" name="expires-at" format="YYYY-MM-DD" />
                    </atbl-form-group>
                </div>

                <div class="col-12 col-md-4">
                    <multi-games-picker v-model="games" :horizontal="false" filter="raffle" />
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button type="submit" class="btn btn-sm btn-primary" @click="search">
                <i class="fa fa-search"></i> Search
            </button>

            <button type="reset" class="btn btn-sm btn-danger" @click="clear">
                <i class="fa fa-ban"></i> Clear
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'player-raffle-tickets-search-form',

        data() {
            return {
                ticketId: null,
                drawDate: null,
                games: [],
            };
        },

        methods: {
            search () {
                const params = {
                    ticketId: this.ticketId,
                    drawDate: this.drawDate,
                    games: this.games,
                };

                this.$emit('search', params);
            },

            clear () {
                this.ticketId = null;
                this.drawDate = null;
                this.games = [];

                this.search();
            }
        },
    }
</script>