<template>
    <c-dropdown inNav
                class="c-header-nav-items"
                placement="bottom-end"
                add-menu-classes="py-0"
    >
        <template #toggler>
            <c-header-nav-link>
                <span class="avatar badge-info rounded-circle"
                      v-tooltip="{
                        content: name,
                        placement: 'bottom-center'
                      }"
                >
                    <span class="avatar-text">
                        <span>{{ getName }}</span>
                    </span>
                </span>
            </c-header-nav-link>
        </template>
        <c-dropdown-item v-if="$can('User - Manage Account View')"
                         href="/users/manage-account"
        >
            <i class="fa fa-user mr-2" /> Manage Account
        </c-dropdown-item>
        <c-dropdown-item @click="submitLogout">
            <i class="fa fa-sign-out-alt mr-2" /> Logout
        </c-dropdown-item>
        <form ref="logoutForm" action="/logout" method="POST" class="d-none">
            <input type="hidden" :value="token" />
        </form>
    </c-dropdown>
</template>

<script>
    export default {
        name: 'header-dropdown-account',
        props: {
            name: {
                type: String,
                required: true
            },
            token: {
                type: String,
                required: true
            }
        },
        data () {
            return {
                itemsCount: 42
            }
        },
        computed: {
            getName: function(){
                return this.name.substr(0, 2);
            }
        },
        methods: {
            submitLogout: function(){
                this.$refs.logoutForm.submit();
            }
        }
    }
</script>
