var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: {
            content: "Created through"
          },
          expression: "{\n            content: 'Created through'\n         }"
        }
      ],
      class: ["badge", _vm.color]
    },
    [_vm._v("\n    " + _vm._s(_vm.createdThrough) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }