var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-bonus" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "statistic-card",
            {
              staticClass: "mb-0",
              attrs: {
                bgColor: "bg-linkedin",
                crmLabel: "DAILY",
                crmTotal: _vm.getStatisticsValue("crm", "bonus"),
                webLabel: "MONTHLY",
                webTotal: _vm.getStatisticsValue("crm", "bonus", "monthly")
              }
            },
            [_c("i", { staticClass: "fa fa-money-bill" })]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-bonus-heading" }, [
      _c("p", { staticClass: "dashboard-bonus-title" }, [_vm._v("Bonus")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }