import { isObjectEmpty } from './Object';

/**
 * Get all values from nested object and convert to array
 *
 * Info on: https://atbl.atlassian.net/wiki/spaces/BAC/pages/1018069013/Object+to+array
 *
 * @param obj
 * @returns {*[]}
 */
function objectToArray(obj) {
    if (isObjectEmpty(obj)) {
        return [];
    }

    let tmp = [];

    for (const value of Object.values(obj)) {
        if (typeof value === 'object') {
            tmp = [
                ...tmp,
                ...objectToArray(value)
            ];
        } else {
            tmp.push(value);
        }
    }

    return tmp;
}

export default objectToArray;