import MarketingService from '../../../../../services/MarketingService';
import { clone, stringify } from '../../../../../utils/Json/Json';
import CampaignModel from './CampaignModel';
import getValidationErrors from '../../../../../utils/Error/GetValidationErrors';
import ObjectToFormData from '../../../../../utils/Object/ObjectToFormData';

export default {
    provide() {
        return {
            saveCampaign: this.saveCampaign,
            updateCampaignModelField: this.updateCampaignModelField,
            setChildInterface: this.setChildInterface,
        };
    },
    childInterface: {
        getCampaignTags: () => {}
    },
    data() {
        return {
            saveCampaignLoading: false,
            campaignModelErrors: {},
            campaignModel: clone(CampaignModel),
        };
    },
    methods: {
        async saveCampaign() {
            if (!this.$can('Campaigns - Create/Update')) {
                window.showMessage('ou does not have permissions for this action.', false);
                return;
            }

            this.saveCampaignLoading = true;
            this.campaignModelErrors = {};

            try {
                const campaignModel = this.campaignModel;
                campaignModel.agents = campaignModel.agents.map(item => this.isObject(item) ? item.id : item);

                const formData = ObjectToFormData(campaignModel);

                const response = !!this.id
                    ? await MarketingService.updateCampaign(this.id, this.type, formData)
                    : await MarketingService.createCampaign(this.type, formData);
                const { status, message, data } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.saveCampaignLoading = false;

                    return;
                }

                await this.$options.childInterface.getCampaignTags();

                const model = data;
                model.tags = data.tags.map(tag => tag.id);

                Object.assign(this.campaignModel, model);

                if (!!this.id && (!!this.campaignModel.batch || !!this.campaignModel.csv)) {
                    this.saveCampaignLoading = false;

                    this.campaignModel.csv = null;
                    this.campaignModel.batch = null;

                    await this.reFetchData(this.id);

                    return;
                }

                if (!this.id) {
                    window.location.href = `/marketing/campaigns/${this.type}/${data.id}`;

                    return;
                }

                this.saveCampaignLoading = false;
            } catch (error) {
                this.saveCampaignLoading = false;

                this.campaignModelErrors = getValidationErrors(error);

                window.flashError(error);
            }
        },
        updateCampaignModelField(name, value) {
            Object.assign(this.campaignModel, {
                [name]: value
            });
        },
        setChildInterface(childInterface) {
            this.$options.childInterface = childInterface;
        },
    },
}