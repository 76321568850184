import TypesConst from "../../../TypesConst";
import PlayerCallHistoryService from "../../../../services/PlayerCallHistoryService";

export default {
    [TypesConst.players.actions.GET_PLAYER_CALL_HISTORY_ACTION]({commit, state}){
        commit(TypesConst.players.mutations.SET_PLAYER_CALL_HISTORY_LIST_LOADER, true);

        PlayerCallHistoryService.getPlayerCallHistory(state.playerId, state.playersCallHistoryListModel)
            .then(response => {
                commit(`marketing/${TypesConst.marketing.mutations.SET_CALL_HISTORY_LIST}`, response.data.data, {root: true});
                commit(`marketing/${TypesConst.marketing.mutations.SET_CALL_HISTORY_LIST_RESPONSE}`, response.data, {root: true});
            })
            .catch(error => {
                let { message } = error;

                if(message){
                    flash(message, "alert-danger");
                }
            })
            .finally(() => {
                commit(TypesConst.players.mutations.SET_PLAYER_CALL_HISTORY_LIST_LOADER, false);
            });
    }
};