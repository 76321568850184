var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "payment-gateways",
      class: {
        "payment-gateways--disabled": _vm.isLoading || _vm.chargeAmount === 0,
        "mb-3": !!_vm.isLoading
      }
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "payment-gateways--container" },
            _vm._l(_vm.availablePayments, function(payment) {
              return _c(
                "div",
                {
                  key: "gateway_" + payment.providerType,
                  staticClass: "payment-gateway-button",
                  class: [
                    "payment-gateway-button--" + payment.gateway,
                    {
                      "payment-gateway-button--toggled":
                        _vm.selectedGatewayDropDown === payment.gateway
                    },
                    {
                      "payment-gateway-button--disabled":
                        payment.providerType === "wallet" &&
                        !_vm.hasEnoughWalletFunds
                    }
                  ],
                  on: {
                    "!click": function($event) {
                      return _vm.onPaymentClick(payment)
                    }
                  }
                },
                [
                  payment.providerType === "oppwa"
                    ? [
                        _vm._v(
                          "\n                    " +
                            _vm._s(payment.name) +
                            "\n                    "
                        ),
                        _c("span"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "payment-gateway-button__dropdown-content"
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.selectPayment(payment)
                                  }
                                }
                              },
                              [_vm._v("Pay with credit card")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.showListOfSavedCardsForGateway =
                                      payment.gateway
                                  }
                                }
                              },
                              [_vm._v("Pay with saved card")]
                            )
                          ]
                        )
                      ]
                    : [
                        _vm._v(
                          "\n                    " +
                            _vm._s(payment.name) +
                            "\n                    "
                        ),
                        payment.providerType === "wallet"
                          ? [
                              _vm._v(
                                "\n                         - " +
                                  _vm._s(_vm.formattedCurrency) +
                                  "\n                    "
                              )
                            ]
                          : _vm._e()
                      ]
                ],
                2
              )
            }),
            0
          )
        : _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
      _vm._v(" "),
      !_vm.isLoading && !_vm.availablePayments.length
        ? _c("h4", { staticClass: "text-danger mb-0" }, [
            _vm._v("\n        No payments available in the moment.\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showListOfSavedCardsForGateway
        ? _c("payment-gateway-saved-credit-cards-modal", {
            attrs: {
              "player-id": _vm.playerId,
              "gateway-key": _vm.showListOfSavedCardsForGateway
            },
            on: {
              confirm: _vm.confirmSelectedSavedCreditCard,
              cancel: function($event) {
                _vm.showListOfSavedCardsForGateway = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedProvider === "oppwa" && !!_vm.apiPaymentResponse
        ? _c("payment-gateway-oppwa", {
            attrs: { "api-payment-response": _vm.apiPaymentResponse },
            on: {
              cancel: function($event) {
                _vm.selectedProvider = null
                _vm.apiPaymentResponse = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedType === "external" && !!_vm.apiPaymentResponse
        ? _c("cashier", {
            attrs: {
              "player-id": _vm.playerId,
              providerType: _vm.selectedProvider,
              "api-payment-response": _vm.apiPaymentResponse
            },
            on: {
              cancel: function($event) {
                _vm.selectedType = null
                _vm.apiPaymentResponse = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.walletPaymentConfirmModal
        ? _c("confirm-wallet-payment-modal", {
            attrs: { amount: _vm.chargeAmount },
            on: {
              confirm: function($event) {
                _vm.walletPaymentConfirmModal = false
                _vm.selectPayment(_vm.walletPayment)
              },
              cancel: function($event) {
                _vm.walletPaymentConfirmModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }