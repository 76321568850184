var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      class: {
        "hide-footer": _vm.redirecting
      },
      attrs: { "close-on-backdrop": false, show: _vm.openModal },
      on: {
        close: function($event) {
          _vm.openModal = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("h5", { staticClass: "m-0" }, [_vm._v("Card payment")])]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex w-100 align-items-center" }, [
                _c("div", { staticClass: "w-50 font-weight-bold" }, [
                  _vm._v("\n\t\t\t\t\t* Required\n\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 text-right" }, [
                  !_vm.getConfirmPay
                    ? _c("div", [
                        !_vm.redirecting
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-danger",
                                on: {
                                  click: function($event) {
                                    _vm.openModal = false
                                  }
                                }
                              },
                              [_vm._v("\n\t\t\t\t\t\t\tClose\n\t\t\t\t\t\t")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.redirecting
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { disabled: !_vm.cardModel.id },
                                on: {
                                  click: function($event) {
                                    return _vm.onCancelConfirm(true)
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                                Continue\n                            "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _c("div", [
                        (!_vm.isConfirmed &&
                          !_vm.getPayWithSavedCardModalLoader &&
                          !_vm.redirecting) ||
                        _vm.status === "danger"
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-danger",
                                on: { click: _vm.cancelAction }
                              },
                              [_vm._v("\n\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.redirecting
                          ? _c(
                              "button",
                              {
                                staticClass: "btn",
                                class: {
                                  "btn-success": !_vm.getPayWithSavedCardModalLoader,
                                  "btn-outline-success":
                                    _vm.getPayWithSavedCardModalLoader
                                },
                                attrs: { disabled: !_vm.cardModel.id },
                                on: { click: _vm.confirmPay }
                              },
                              [
                                !_vm.getPayWithSavedCardModalLoader
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                Confirm\n                            "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("loader", {
                                  attrs: {
                                    show: _vm.getPayWithSavedCardModalLoader,
                                    type: "smaller"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      !_vm.hideContent &&
      !_vm.getPayWithSavedCardModalLoader &&
      !_vm.getConfirmPay
        ? _c("PayWithSavedCard")
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideContent &&
      !_vm.getPayWithSavedCardModalLoader &&
      _vm.getConfirmPay
        ? _c("ConfirmPayWithCard")
        : _vm._e(),
      _vm._v(" "),
      _vm.hideContent && !_vm.redirecting
        ? _c(
            "div",
            {
              staticClass: "alert text-center m-0",
              class: "alert-" + _vm.status
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.message) + "\n\t\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.redirecting
        ? _c(
            "div",
            {
              staticClass: "alert",
              class: {
                "alert-danger": !_vm.redirecting,
                "alert-success": _vm.redirecting
              }
            },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.message))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.redirecting
        ? _c("h5", { staticClass: "text-center mt-2" }, [
            _vm._v(
              "\n\t\t\tRedirecting in " +
                _vm._s(_vm.countDown) +
                " seconds...\n\t\t"
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }