<template>
    <atbl-modal :title="modalTitle" :close-on-backdrop="false" size="lg" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-4">
                <atbl-input label="Bundle Title" placeholder="Enter bundle title" v-model="title" />
            </div>

            <div class="col-4">
                <atbl-input label="Bundle Description" placeholder="Enter bundle description" v-model="description" />
            </div>

            <div class="col-4">
                <atbl-input label="Bundle Ribbon Text" placeholder="Enter ribbon price" v-model="ribbonText" />
            </div>

            <div class="col-12" v-if="bundle">
                <atbl-input label="Bundle Slug" placeholder="Enter bundle slug" v-model="slug" />
            </div>

            <div class="col-4">
                <div class="form-group-atbl">
                    <label>Logo</label>
                    <div class="form-group position-relative">
                        <input type="file" class="form-control-file" accept="image/*" ref="logoFileInput" @change="handleLogoUpload" />
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group-atbl">
                    <label>Background image</label>
                    <div class="form-group position-relative">
                        <input type="file" class="form-control-file" accept="image/*" ref="backgroundImageFileInput" @change="handleBackgroundImageUpload" />
                    </div>
                </div>
            </div>

            <div class="col-4 mb-4">
                <div class="form-group-atbl">
                    <label>Bundle enabled state</label>
                  <atbl-check-box :value="isActive"
                                  label="Is bundle active"
                                  class="mr-1"
                                  @input="isActive = ! isActive"
                  />
                </div>
            </div>

            <div class="col-4 mb-4">
                <div class="form-group-atbl">
                    <label>Enable for CRM</label>
                  <atbl-check-box :value="isCrmEnabled"
                                  label="Enable for CRM"
                                  class="mr-1"
                                  @input="isCrmEnabled = ! isCrmEnabled"
                  />
                </div>
            </div>

            <div class="col-8 mb-4">
                <div class="form-group-atbl">
                    <label>Enable for API</label>
                  <atbl-check-box :value="isApiEnabled"
                                  label="Enable for API"
                                  class="mr-1"
                                  @input="isApiEnabled = ! isApiEnabled"
                  />
                </div>
            </div>

            <div class="col-4 mb-4">
                <atbl-input label="Number of lines" placeholder="Enter number of lines per game" v-model.number="numberOfLines" />
            </div>

            <div class="col-8">
                <atbl-form-group label="Select jackpot currency" label-for="jackpotCurrency" class="font-weight-bold" :horizontal="false" :error="null">
                    <select class="form-control" v-model="jackpotCurrency">
                        <option :value="null">Select currency</option>
                        <option v-for="currency in currencyList" :key="`currency_item_${currency.code}`" :value="currency.code">
                            {{ currency.code }}
                        </option>
                    </select>
                </atbl-form-group>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <strong>Select bundle games:</strong>
                    </div>
                </div>

                <div v-for="(bundleGame, index) in bundleGames" :key="`bundle_game_${index}`" class="row">
                    <div class="col-6">
                        <atbl-form-group :label="index === 0 ? 'Game' : ''" label-for="game" class="font-weight-bold" :horizontal="false" :error="null">
                            <select class="form-control" v-model="bundleGame.game">
                                <option :value="null">Select Game</option>
                                <option v-for="game in gameList" :key="game.value" :value="game.value">
                                    {{ game.text }}
                                </option>
                            </select>
                        </atbl-form-group>
                    </div>

                    <div class="col-3">
                        <div class="form-group-atbl font-weight-bold">
                            <label v-if="index === 0" class="d-block">Line price</label>
                            <label>{{ getBundleGameLinePrice(bundleGame) }}</label>
                        </div>
                    </div>

                    <div class="col-3 align-items-center">
                        <div class="form-group-atbl">
                            <label v-if="index === 0">&nbsp;</label>
                            <div>
                                <a href="#" v-if="bundleGames.length > 1" class="circle circle__minus" @click="removeBundleGame(index)"></a>
                                <a href="#" v-if="index === bundleGames.length - 1" class="circle circle__plus" @click="addBundleGame"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 align-items-center">
                        <div class="form-group-atbl">
                            <label>Total: <strong>{{ totalBundleGamePrice.toFixed(2) }}</strong></label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <strong>Add weeks:</strong>
                    </div>
                </div>

                <div v-for="(bundleWeek, index) in bundleWeeks" :key="`bundle_game_${index}`" class="row">
                    <div class="col-2">
                        <atbl-input :label="index === 0 ? 'Week' : ''" placeholder="Enter week" v-model="bundleWeek.week" />
                    </div>

                    <div class="col-3">
                        <div v-if="bundleWeek.price" class="form-group-atbl">
                            <label v-if="index === 0" class="d-block">Discount</label>
                            <label><strong>{{ (100 - ((bundleWeek.price || getBundlePricePerWeek(bundleWeek.week)) / (getBundlePricePerWeek(bundleWeek.week) / 100))).toFixed(2) }}</strong></label>
                        </div>

                        <atbl-input v-if="! bundleWeek.price" :label="index === 0 ? 'Discount %' : ''" placeholder="Discount" v-model="bundleWeek.discount" />
                    </div>

                    <div class="col-2">
                        <div v-if="bundleWeek.discount" class="form-group-atbl">
                            <label v-if="index === 0" class="d-block">Price</label>
                            <label><strong>{{ (getBundlePricePerWeek(bundleWeek.week) - (getBundlePricePerWeek(bundleWeek.week) * ((bundleWeek.discount || 0) / 100))).toFixed(2) }}</strong></label>
                        </div>

                        <atbl-input v-if="! bundleWeek.discount" :label="index === 0 ? 'Price' : ''" placeholder="Price" v-model="bundleWeek.price" />
                    </div>

                    <div class="col-2">
                        <div class="form-group-atbl">
                            <label v-if="index === 0" class="d-block">Regular price</label>
                            <label><strong>{{ getBundlePricePerWeek(bundleWeek.week).toFixed(2) }}</strong></label>
                        </div>
                    </div>

                    <div class="col-3 align-items-center">
                        <div class="form-group-atbl">
                            <label v-if="index === 0">&nbsp;</label>
                            <div>
                                <a href="#" v-if="bundleWeeks.length > 1" class="circle circle__minus" @click="removeBundleWeek(index)"></a>
                                <a href="#" v-if="index === bundleWeeks.length - 1" class="circle circle__plus" @click="addBundleWeek(0, null, null)"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-primary" @click="submitBundleData()">
                {{ saveButtonLabel }}
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import BundleMixin from '../../../mixins/Bundles/Bundle';
import CurrenciesService from '../../../services/CurrenciesService';
import AtblCheckBox from '../../../components/Forms/Inputs/AtblCheckbox.vue';
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'bundles-create-edit-modal',
	components: {AtblCheckBox, AtblModal,},

    mixins: [BundleMixin],

    props: ['bundle'],

    data() {
        return {
            gameList: [],
            gameDraws: [],
            currencyList: [],

            title: null,
            slug: null,
            description: null,
            ribbonText: null,
            logo: null,
            backgroundImage: null,
            price: null,
            isActive: false,
            isCrmEnabled: true,
            isApiEnabled: true,
            numberOfLines: 1,
            jackpotCurrency: null,

            bundleGames: [],
            bundleWeeks: [],
        };
    },

    computed: {
        modalTitle() {
            return !this.bundle ? 'Create Bundle' : 'Edit Bundle';
        },

        saveButtonLabel() {
            return !this.bundle ? 'Create' : 'Update';
        },

        totalBundleGamePrice() {
            let total = 0;

            this.bundleGames.filter(g => !!g.game).forEach(b => {
                const gameName = this.gameList.find(g => parseInt(g.value) === parseInt(b.game)).text;
                total = total + (this.getBundleGamePrice(gameName) || 0);
            });

            return total * this.numberOfLines;
        },
    },

    mounted() {
        const fetchGamesPromise = this.fetchGames();
        const fetchCurrenciesPromise = this.fetchCurrencies();

        Promise.all([fetchGamesPromise, fetchCurrenciesPromise]).then(() => {
            if (!this.bundle) {
                this.initializeNewBundle();
            } else {
                this.initializeExistingBundle();
            }
        });
    },

    methods: {
        fetchGames() {
            const params = {
                type: 'lottery',
            };

            return window.axios.get('/games', { params }).then(response => {
                this.gameList = response.data;

                return response;
            });
        },

        fetchCurrencies() {
            return CurrenciesService.index().then(response => {
                this.currencyList = response.data;

                return response;
            });
        },

        initializeNewBundle() {
            this.addBundleGame();
            this.addBundleWeek(1, null, null);
            this.addBundleWeek(2, null, null);
            this.addBundleWeek(4, null, null);
            this.addBundleWeek(8, null, null);
            this.addBundleWeek(16, null, null);
        },

        initializeExistingBundle() {
            this.title = this.bundle.title;
            this.slug = this.bundle.slug;
            this.description = this.bundle.description;
            this.ribbonText = this.bundle.ribbon_text;
            this.numberOfLines = this.bundle.bundle_games[0].number_of_lines;
            this.jackpotCurrency = this.bundle.jackpot_currency;
            this.isActive = !!this.bundle.is_active;
            this.isCrmEnabled = !!this.bundle.is_crm_enabled;
            this.isApiEnabled = !!this.bundle.is_api_enabled;
            this.price = this.bundle.price;

            this.bundleGames = this.bundle.bundle_games.map(bg => {
                return {
                    id: bg.id,
                    game: bg.game_id,
                };
            });

            this.bundleWeeks = this.bundle.bundle_weeks.map(w => {
                return {
                    id: w.id,
                    week: w.week,
                    discount: w.discount,
                    price: w.price,
                };
            });
        },

        handleLogoUpload() {
            this.logo = this.$refs.logoFileInput.files[0];
        },

        handleBackgroundImageUpload() {
            this.backgroundImage = this.$refs.backgroundImageFileInput.files[0];
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },

        addBundleGame() {
            this.bundleGames.push({
                game: null,
            })
        },

        getBundleGameLinePrice(bundleGame) {
            const game = this.gameList.find(g => parseInt(g.value) === parseInt(bundleGame.game));

            return game ? game.price : null;
        },

        getBundlePricePerWeek(week) {
            return this.totalBundleGamePrice * week;
        },

        removeBundleGame(index) {
            this.bundleGames.splice(index, 1);
        },

        addBundleWeek(week, discount, price) {
            this.bundleWeeks.push({
                week,
                discount,
                price,
            })
        },

        removeBundleWeek(index) {
            this.bundleWeeks.splice(index, 1);
        },

        submitBundleData() {
            const bundleWeeks = this.bundleWeeks.filter(w => !!w.week && (w.price !== null || w.discount !== null));
            const bundleGames = this.bundleGames.filter(g => g.game);

            const params = {
                ...(this.title && { title: this.title }),
                ...((this.slug && this.bundle) && { slug: this.slug }),
                ...(this.description && { description: this.description }),
                ...(this.ribbonText && { ribbonText: this.ribbonText }),
                ...(this.jackpotCurrency && { jackpotCurrency: this.jackpotCurrency }),
                ...(this.logo && { logo: this.logo }),
                ...(this.backgroundImage && { backgroundImage: this.backgroundImage }),
                ...(this.price && { price: this.price }),
                ...(this.numberOfLines && { numberOfLines: this.numberOfLines }),
                isActive: this.isActive,
                isCrmEnabled: this.isCrmEnabled,
                isApiEnabled: this.isApiEnabled,
                ...(!!bundleGames.length && { bundleGames: bundleGames }),
                ...(!!bundleWeeks.length && { bundleWeeks: bundleWeeks }),
            };

            this.$emit('confirm', params);
        },
    },
}
</script>

<style lang="scss" scoped>
.circle {
    border         : 1px solid #aaaaaa;
    box-shadow     : inset 1px 1px 3px #ffffff;
    width          : 18px;
    height         : 18px;
    border-radius  : 100%;
    position       : relative;
    margin         : 4px;
    display        : inline-block;
    vertical-align : middle;
    background     : #aaaaaa4f;

    &:before,
    &:after {
        content  : '';
        position : absolute;
        top      : 0;
        left     : 0;
        right    : 0;
        bottom   : 0;
    }

    &__plus:before,
    &__plus:after {
        background : green;
        box-shadow : 1px 1px 1px #ffffff9e;
    }

    &__plus:before {
        width  : 2px;
        margin : 3px auto;
    }

    &__plus:after {
        margin     : auto 3px;
        height     : 2px;
        box-shadow : none;
    }

    &__minus:before {
        background : #cc0000;
        margin     : auto 3px;
        height     : 2px;
        box-shadow : 0 1px 1px #ffffff9e;
    }
}
</style>