var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "card card-list" }, [
        _vm.section.name
          ? _c(
              "div",
              {
                staticClass:
                  "card-header d-flex justify-content-between align-items-center"
              },
              [
                _c("span", [
                  _c("i", { staticClass: "fa fa-cogs" }),
                  _vm._v(" " + _vm._s(_vm.section.name))
                ]),
                _vm._v(" "),
                _vm.$can("Settings - Create/Update")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary m-0",
                        on: {
                          click: function($event) {
                            return _vm.openAddSettingsModal(true)
                          }
                        }
                      },
                      [_vm._v("\n                Add Setting\n            ")]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body p-3" },
          [
            _vm.sectionOpened && _vm.settings.length
              ? _c("SettingSections")
              : _vm._e(),
            _vm._v(" "),
            _vm.sectionOpened && !_vm.settings.length
              ? _c("not-found", {
                  attrs: {
                    items: _vm.settings,
                    msg: "No settings found in this section."
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.sectionOpened
              ? _c("not-found", {
                  attrs: { items: [], msg: "Select section to show settings." }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("AddSettingsModal"),
      _vm._v(" "),
      _c("RemoveSettingsModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }