<template>
    <div>
        <div v-if="isCheckbox" class="form-group-atbl">
          <atbl-check-box :value="content"
                          :label="label"
                          :disabled="disabled"
                          class="mr-1"
                          @input="content = ! content; emitInput()"
          />
        </div>

        <atbl-input v-if="isInput" :label="label" placeholder=" " v-model="content" @input="emitInput" :disabled="disabled" />

        <atbl-form-group v-if="isSelectDropdown" :label="label" class="font-weight-bold" :horizontal="false">
            <select class="form-control" v-model="content" :disabled="disabled" @change="emitInput">
                <option :value="null">{{ label }}</option>
                <option v-for="selectOption in selectDropdownOptions" :value="selectOption.value">{{ selectOption.label }}</option>
            </select>
        </atbl-form-group>
    </div>
</template>

<script>
import AtblCheckBox from '../../../../components/Forms/Inputs/AtblCheckbox.vue';

export default {
    name: 'bonus-action-field',
	components: {AtblCheckBox},

    props: ['value', 'config', 'disabled'],

    data() {
        const content = this.config.type === 'checkbox' ? !!this.value.value : this.value.value;

        return {
            content: content,
        };
    },

    computed: {
        key() {
            return this.config.key;
        },

        label() {
            return this.config.label;
        },

        isCheckbox() {
            return this.config.type === 'checkbox';
        },

        isInput() {
            return this.config.type === 'input';
        },

        isSelectDropdown() {
            return this.config.type === 'select';
        },

        selectDropdownOptions() {
            if (!this.isSelectDropdown) {
                return [];
            }

            return Object.entries(this.config.options).map(i => {
                return {
                    value: i[0],
                    label: i[1]
                };
            });
        },
    },

    watch: {
        value: {
            deep: true,
            handler(v) {
                this.content = this.config.type === 'checkbox' ? !!v.value : v.value;
            }
        },
    },

    methods: {
        emitInput() {
            const props = {
                key: this.key,
                value: this.isInput && (this.content && !this.content.length) ? null : this.content,
            };

            this.$emit('input', props);
        },
    },
}
</script>

<style lang="scss" scoped>
.form-group-atbl {
    margin-top : 1rem;
}
</style>