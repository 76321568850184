<template>
	<player-layout :id="id"
	               title="Add Funds"
	               :header="false"
	               cardClass="player-funds-card tabs-atbl"
	               bodyClass="p-0"
	>
		<div v-if="canPlay">
			<atbl-tabs @update:activeTab="setActiveTab"
			        v-if="$canAny(accesses)"
			        :active-tab="0"
			>
				<atbl-tab title="Deposit"
				       v-if="$can('Payon - Funds Deposit Agent')"
				>
					<AddFundsForm v-on:gatewayResponse="onGatewayResponse" />
				</atbl-tab>
				<atbl-tab title="Add credit"
				       v-if="$can('Payon - Virtual Deposit Agent')"
				>
					<AddCreditForm/>
				</atbl-tab>
				<atbl-tab title="Add bonus"
				       v-if="$can('Payon - Bonus Deposit Agent') || $can('Payon - Bonus Deposit User')"
				>
					<AddBonusForm/>
				</atbl-tab>
			</atbl-tabs>
			<not-found v-else
			           :items="[]"
			           msg="You don`t have access to visit this page."
			/>
		</div>
		<div v-else class="py-5">
			<CanPlayGame :isLoading="playerLoader"
			             :isFullScreen="true"
			/>
		</div>

		<ConfirmCreditModal/>
		<ConfirmBonusModal/>
	</player-layout>
</template>

<script>
	import TypesConst from "../../../../store/TypesConst";
	import NamespaceConst from "../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";

	const {
		mapGetters,
		mapMutations,
		mapActions
	} = createNamespacedHelpers(NamespaceConst.players);

	const {
		mapGetters: mapCartGetters
	} = createNamespacedHelpers(NamespaceConst.cart);

	import Payon from '../../../../components/Payon'
	import AddFundsForm from "./Forms/AddFundsForm";
	import AddCreditForm from "./Forms/AddCreditForm";
	import AddBonusForm from "./Forms/AddBonusForm";
	import ConfirmCreditModal from "./Components/ConfirmCreditModal";
	import ConfirmBonusModal from "./Components/ConfirmBonusModal";
	import CanPlayGame from "../../Components/Content/CantPlayGame";
	import AtblTabs from '../../../../components/AtblTabs/AtblTabs.vue';
	import AtblTab from '../../../../components/AtblTabs/AtblTab.vue';

	export default {
		name: "add-funds",
		components: {
			Payon,
			AddFundsForm,
			AddCreditForm,
			AddBonusForm,
			ConfirmCreditModal,
			CanPlayGame,
			ConfirmBonusModal,
			AtblTabs,
			AtblTab,
		},
		props: {
			id: {
				type: Number,
				required: true
			}
		},
		data: function () {
			return {
                redirectUrl: null,
				selectedTab: 0,
				accesses: [
					"Payon - Funds Deposit Agent",
					"Payon - Virtual Deposit Agent",
					"Payon - Bonus Deposit Agent"
				]
			};
		},
		computed: {
			...mapGetters({
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID,
				getAddBalance: TypesConst.players.getters.GET_FUNDS_ADD_BALANCE,
				paymentLink: TypesConst.players.getters.GET_FUNDS_PAYMENT_LINK,
				getCreditCards: TypesConst.players.getters.GET_PLAYER_CREDIT_CARDS,
				playerLoader: TypesConst.players.getters.GET_PLAYER_LOADER
			}),
			...mapCartGetters({
				canPlay: TypesConst.cart.getters.GET_PLAYER_CAN_PLAY_GAME
			}),
			addBalance: {
				get: function () {
					return this.getAddBalance;
				},
				set: function (value) {
					this.setAddBalance(value);
				}
			}
		},
		methods: {
			...mapMutations({
				setPlayerId: TypesConst.players.mutations.SET_PLAYER_ID,
				setAddBalance: TypesConst.players.mutations.SET_FUNDS_ADD_BALANCE,
				setTransactionType: TypesConst.players.mutations.SET_FUNDS_TRANSACTION_TYPE
			}),
			...mapActions({
				getPlayerCreditCards: TypesConst.players.actions.GET_PLAYERS_SAVED_CARDS_ACTIONS
			}),
            onGatewayResponse(response) {
                this.redirectUrl = response.checkout.redirect_url || null;
            },
			tabsActive: function (index) {
				let tabs = [];

				if (this.$can("Payon - Funds Deposit Agent")) {
					tabs.push("CAFD", "CAFD");
				}

				if (this.$can("Payon - Virtual Deposit Agent")) {
					tabs.push("CAVD");
				}

				if (this.$can("Payon - Bonus Deposit Agent")) {
					tabs.push("CABD");
				}

				return tabs[index];
			},
			setActiveTab: function (value) {
				this.setTransactionType(this.tabsActive(value));
                this.$store.commit('players/setFundsAmount', 0);
			},
            onPayonClose() {
                this.addBalance = false;
                this.$store.commit('payment/setPaymentLoader', false);
            }
		},
		created: function () {
			let vm = this;

			this.setPlayerId(this.id);
			this.setTransactionType(this.tabsActive(0));
			this.getPlayerCreditCards(false);

			Atbl.$on("savedCreditCardPayment", function (id) {
				window.location.href = `${vm.redirectURL()}?id=${id}`;
			});
		},
		watch: {
			selectedTab: {
				handler: function (value) {
					this.setTransactionType(this.tabsActive(value));
				},
				immediate: true
			}
		}
	}

</script>

<style>
.nav-tabs .nav-link.disabled, .nav-tabs .navbar .disabled.dropdown-toggle, .navbar .nav-tabs .disabled.dropdown-toggle {
	color: #536c79;
	background-color: transparent;
	border-color: transparent;
	opacity: .5;
}
</style>