`
<template>
    <Layout title="Leads Statuses"
            icon="nav-icon fa fa-clock"
    >
        <template #header>
            <button v-if="$can('Leads Statuses - Create/Edit')"
                    class="btn btn-sm btn-primary m-0"
                    @click="toggleCreateUpdateForm"
            >
                Create
            </button>
        </template>

        <div class="leads-section-list">
            <div class="row leads-section-list-row d-none d-lg-flex mb-0">
                <div class="col-lg-1">Id</div>
                <div class="col-12 col-lg">Name</div>
                <div class="col-12 col-lg">Lock time (minutes)</div>
                <div v-if="$canAny(operationPermissions)"
                     class="col-12 col-lg"
                >
                    Operations
                </div>
            </div>

            <template v-if="!isLoading">
                <div v-for="(item, index) in statuses"
                     :key="index"
                     class="row leads-section-list-row"
                >
                    <div class="col-12 col-lg-1 align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Id</span>
                        {{ item.value }}
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Name</span>
                        {{ item.label }}
                    </div>
                     <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Lock time (minutes)</span>
                        {{ item.lock_time  || '/'  }}
                    </div>
                    <div v-if="$canAny(operationPermissions)"
                         class="col-12 col-lg align-self-center mb-2 mb-lg-0"
                    >
                        <a v-if="$can('Leads Statuses - Create/Edit')"
                           href="#"
                           @click.prevent="onEditStatusEvent(item.value)"
                        >
                            <i class="fa fa-pen"></i>
                        </a>
                    </div>
                </div>
            </template>

            <loader :show="isLoading"
                    type="small"
                    class="my-3"
            />

            <items-not-found v-if="!isLoading"
                             :items="statuses"
                             msg="No statuses found."
            />
        </div>

        <template #modals>
            <create-or-update-lead-status-form-component v-if="$can('Leads Statuses - Create/Edit')"
                                                         :show="isCreateUpdateOpened"
                                                         :model="leadStatusModel"
                                                         :errors="leadStatusModelErrors"
                                                         :is-loading="isCreateUpdateLoading"
            />
        </template>
    </Layout>
</template>

<script>
import Layout from '../_Components/Layout';
import CreateOrUpdateLeadStatusFormComponent
    from './CreateOrUpdateLeadStatusForm/CreateOrUpdateLeadStatusFormComponent';
import LeadStatusService from '../../../services/Marketing/LeadStatusService';
import Loader from '../../../components/Widgets/Loader';
import ItemsNotFound from '../../../components/Lists/ItemsNotFound';
import CreateUpdateStatus from './CreateOrUpdateLeadStatusForm/CreateUpdateStatus';

export default {
    name: 'campaign-leads-statuses',
    mixins: [
        CreateUpdateStatus,
    ],
    components: {
        Layout,
        CreateOrUpdateLeadStatusFormComponent,
        Loader,
        ItemsNotFound,
    },
    data() {
        return {
            isLoading: false,
            statuses: [],
            operationPermissions: [
                'Leads Statuses - Create/Edit'
            ],
        };
    },
    async created() {
        await this.fetchLeadStatuses();
    },
    methods: {
        async fetchLeadStatuses() {
            if (!this.$can('Leads Statuses - Get List')) {
                return window.showMessage('No permission for this action.', false);
            }

            this.isLoading = true;

            try {
                const response = await LeadStatusService.getLeadsStatuses();

                this.statuses = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    }
}
</script>