export default {
    name: null,
    surname: null,
    email: null,
    phone: null,
    live_spent: {
        balance: null,
        balanceSelector: null
    },
    disposition_id: null,
    sort: {
        value: 'ASC',
        sort: 'id'
    },
}