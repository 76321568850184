export default {
	data: function(){
		return {
			isDisabled: true
		};
	},
	computed: {
		errors: function () {
			return this.isset(this.getFundsErrors, "response") ? this.getFundsErrors.response.data.errors : {};
		},
		amount: {
			get: function(){
				return this.getFundsAmount;
			},
			set: function(value){
				this.setFundsAmount(value);
			}
		}
	},
	methods: {
		updateAmount: function(name, value, isDisabled){
			this.isDisabled = isDisabled;
			this.setFundsAmount(value);
		}
	}
};