<template>
    <div class="container-fluid payment-gateways-settings">
        <template v-if="!isLoading">
            <div class="card card-list">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <i class="fa fa-exchange-alt"></i> Payment gateway settings
                    </div>
                </div>
                
                <div class="card-body">
                    <div class="general-list">
                        <div class="row">
                            <div class="col">Title</div>
                            <div class="col">Is CRM active</div>
                            <div class="col">Is API active</div>
                            <div class="col">Withdrawal Enabled</div>
                            <div class="col">Withdrawal API Enabled</div>
                            <div class="col">Order</div>
                        </div>
                        
                        <payment-gateway-item v-for="paymentGateway in gateways"
                                              :key="paymentGateway.gateway.key"
                                              :payment-gateway="paymentGateway"
                        />
                    </div>
                </div>
            </div>
            
            <div class="card card-list">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <i class="fa fa-exchange-alt"></i> Payment gateway countries
                    </div>
                </div>
                
                <div class="card-body">
                    <div class="general-list">
                        <div class="row">
                            <div class="col">Title</div>
                            <div class="col">Available countries</div>
                            <div class="col">Excluded countries</div>
                            <div class="col-2"></div>
                        </div>
                        
                        <div v-for="paymentGateway in gateways" :key="paymentGateway.gateway.key" class="row">
                            <div class="col">{{ paymentGateway.gateway.name }}</div>
                            <div class="col">{{ paymentGateway.available_countries }}</div>
                            <div class="col">{{ paymentGateway.excluded_countries }}</div>
                            <div class="col-2">
                                <a href="#" @click.prevent="setEditingGatewayCountries(paymentGateway.gateway.key)"
                                >Edit</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        
        <loader :show="isLoading"
                type="small"
        />
        
        <gateway-countries-modal v-if="editingGatewayCountries"
                                 :gateway="editingGatewayCountries"
                                 @cancel="editingGatewayCountries = null"
                                 @confirm="saveGatewayCountries"
        />
    </div>
</template>

<script>
    import PaymentService from '../../services/PaymentService';
    import GatewayCountriesModal from './Modals/GatewayCountriesModal';
    import AtblInput from '../../components/Forms/Inputs/ATBLInput.vue';
    import Loader from '../../components/Widgets/Loader.vue';
    import PaymentGatewayItem from './PaymentGatewayItem/PaymentGatewayItem.vue';
    import {clone} from '../../utils/Json/Json';
    
    export default {
        components: {
            AtblInput,
            Loader,
            GatewayCountriesModal,
            PaymentGatewayItem,
        },
        provide() {
            return {
                onOrderChangeEvent: this.onOrderChangeEvent,
            };
        },
        data() {
            return {
                isLoading: false,
                paymentGatewayList: [],
                editingGatewayCountries: null,
            };
        },
        computed: {
            gateways() {
                return this.paymentGatewayList.map(gateway => {
                    const availableCountries = gateway.available_countries.includes('*')
                      ? 'All'
                      : gateway.available_countries.join(',');
                    
                    return {
                        ...gateway,
                        available_countries: availableCountries,
                        excluded_countries: gateway.excluded_countries.join(','),
                    };
                });
            },
        },
        async created() {
            await this.fetchPaymentGateways();
        },
        methods: {
            async fetchPaymentGateways() {
                this.isLoading = true;
                
                try {
                    const response = await PaymentService.fetchPaymentGateways();
                    
                    this.paymentGatewayList = response.data;
                } catch (error) {
                    window.flashError(error);
                } finally {
                    this.isLoading = false;
                }
            },
            onOrderChangeEvent(paymentGateway, property, value) {
                const gateways = clone(this.paymentGatewayList);
                
                const gateway = gateways.find(g => g.gateway.key === paymentGateway.gateway.key);
                
                if (!gateway) {
                    return;
                }
                
                gateway[property] = value;
                
                this.paymentGatewayList = gateways;
            },
            setEditingGatewayCountries(gatewayKey) {
                const gateway = this.paymentGatewayList.find(g => g.gateway.key === gatewayKey);
                if (!gateway) {
                    return;
                }
                
                this.editingGatewayCountries = gateway;
            },
            saveGatewayCountries(params) {
                const gatewayKey = this.editingGatewayCountries.gateway.key;
                
                PaymentService.updateCountries(gatewayKey, params.availableCountries, params.excludedCountries).then(() => {
                    this.editingGatewayCountries = null;
                    this.fetchPaymentGateways();
                });
            },
        },
    };
</script>