<template>
    <div class="sidebar-inner-component"
         :class="{
            'active': value
        }"
    >
        <loader-line :show="loader" />
        <div class="sidebar-inner-component-header">
            <span>
                {{ title }}
            </span>
            <div>
                <button class="close-sidebar"
                        @click="closeSidebar"
                >
                    <i class="fa fa-times" aria-hidden="false"></i>
                </button>
            </div>
        </div>

        <div class="sidebar-inner-component-body" :class="bodyClass">
            <slot></slot>
        </div>

        <div class="sidebar-inner-component-footer" v-if="$slots.footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sidebar-sub',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        loader: {
            type: Boolean,
            default: false
        },
        bodyClass: {
            type: String,
            default: ''
        }
    },
    methods: {
        closeSidebar() {
            this.$emit('input', false);
        }
    }
}
</script>