var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-header card-header-atbl" }, [
    _c(
      "div",
      { staticClass: "player-play-header bundle" },
      [
        _vm._l(_vm.config, function(gameConfig, index) {
          return _c("div", { staticClass: "d-flex align-items-center mb-1" }, [
            _c("img", {
              staticClass: "game-thumb mx-2",
              attrs: {
                src: _vm.$imageService.gameImage(gameConfig.image),
                alt: gameConfig.name
              }
            }),
            _vm._v(" "),
            _c("div", [
              _c("h5", { staticClass: "text-muted m-0" }, [
                _vm._v(_vm._s(gameConfig.name))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-muted m-0" }, [
                _c("strong", [_vm._v("Weekdays:")]),
                _vm._v(
                  " " +
                    _vm._s(gameConfig.weekdays.join(", ")) +
                    "\n                    "
                ),
                _c("strong", [_vm._v("Est. Jackpot:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(gameConfig.jackpot || "N/A"))])
              ])
            ])
          ])
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-sm btn-link", on: { click: _vm.closeGame } },
          [
            _c("i", {
              staticClass: "fa fa-times",
              attrs: { "aria-hidden": "false" }
            })
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }