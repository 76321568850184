<template>
    <atbl-modal title="Notify player" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                {{ label }}
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                No
            </button>

            <button class="btn btn-sm btn-primary" @click="confirm">
                Yes
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'notify-kyc-verified-player-modal',
	components: {
		AtblModal,
	},
    props: ['label'],

    methods: {
        emitCloseEvent() {
            this.$emit('cancel');
        },

        confirm() {
            this.$emit('confirm');
        },
    }
}
</script>