<template>
    <section class="general-list agents">
        <div class="row">
            <div class="col text-muted">Ticket</div>
            <div class="col text-muted text-center">Created By</div>
            <div class="col text-muted text-center">Lottery</div>
            <div class="col text-muted text-center">Order</div>
            <div class="col text-muted text-center">Draw Date</div>
            <div class="col text-muted text-center">Lines</div>
            <div class="col text-muted text-center">Winnings</div>
        </div>

        <template v-if="!isLoading">
            <div v-for="(ticket, index) in ticketsList"
                   :key="index"
                   class="row"
            >
                <div class="col align-self-center">
                    <a :href="`/ticket/${ticket.id}`">T# {{ ticket.id }}</a>
                    <button class="btn btn-sm btn-link p-0"
                            v-tooltip="{
                            content: 'Copy to clipboard'
                        }"
                        @click="copyToClipboard(ticket.id)"
                >
                    <i class="fa fa-copy" aria-hidden="false"></i>
                </button>
                <div>
                    <small class="text-muted">{{ utcToLocal(ticket.created_at) }}</small>
                </div>
                </div>
                <div class="col align-self-center text-center">
                    <created-by-badge :created="ticket.user_name" />
                </div>
                <div class="col text-center align-self-center">
                    <img :src="$imageService.gameImage(ticket.game_image)" :alt="`${ticket.game_image}`"
                         class="game-thumb"
                    />
                </div>
                <div class="col text-center align-self-center">
                    <a v-if="$can('Orders - View Order Details')"
                       :href="`/orders/${ticket.order_id}?playerId=${ticket.player_id}&from=tickets`"
                    >
                        O# {{ ticket.order_id }}
                    </a>
                    <span v-else>
                        O# {{ ticket.order_id }}
                    </span>
                </div>
                <div class="col text-center align-self-center">
                    {{ ticket.date }}
                </div>
                <div class="col text-center align-self-center">
                    {{ ticket.lines_count }}
                </div>
                <div class="col text-center align-self-center">
                    {{ ticket.win !== null ? formatCurrency(ticket.win) : 'Not drawn yet' }}
                </div>
            </div>
        </template>
        <input type="hidden" id="testing-code" :value="testingCode">
        <not-found v-if="!isLoading"
                   :items="ticketsList"
                   msg="No tickets found."
        />

        <loader :show="isLoading"
                type="small"
        />

        <nav class="wrap py-3" v-if="!isLoading && tickets.last_page > 1">
            <atbl-pagination :pagination="tickets"
                             @paginate="getResults"
            />
        </nav>
    </section>
</template>

<script>
    import Transaction from '../../../../Orders/Mixins/Transaction';
    import formatCurrency from '../../../../../filters/FormatCurrency';

    export default {
        name: "tickets-list",
        mixins: [
            Transaction
        ],
        props: {
            tickets: {
                type: Object,
                require: true,
                default: () => ({})
            },
            ticketsList: {
                type: Array,
                require: true,
	            default: () => ([])
            },
            isLoading: {
                type: Boolean,
                require: true,
            }
        },
        data(){
            return {
                testingCode: ""
            };
        },
        methods: {
            formatCurrency,
            getResults: function(value){
                this.$emit("getResults", value);
            },
        }
    }
</script>