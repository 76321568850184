<template>
    <atbl-modal title="Cancel Edit"
                :show="showModal"
                @close="handleCancel"
    >
        <div class="row">
            <div class="col-12 text-center">
                <h3>Are you sure you want to cancel this configuration edit?</h3>
            </div>
        </div>

        <template #footer>
            <button @click="handleCancel"
                      class="btn btn-sm btn-light"
            >
                Cancel
            </button>
            <button @click="handleOK"
                      class="btn btn-sm btn-primary"
            >
                Save
            </button>
        </template>
    </atbl-modal>
</template>

<script>
    import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';
	
    export default {
        name: "cancel-edit",
        components: {
			AtblModal,
        },
        data: function(){
            return {
                showModal: false
            };
        },
        methods: {
            handleOK: function(){
                Atbl.$emit("editConfigurationOk");
                this.showModal = false;
            },
            handleCancel: function(){
                this.showModal = false;
            }
        },
        mounted: function(){
            let vm = this;

            Atbl.$on("editConfigurationOpen", function(value){
                vm.showModal = value;
            });
        }
    }
</script>