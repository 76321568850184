<template>
    <div class="container-fluid tabs-atbl">
        <GamesHeader />

        <atbl-tabs :active-tab="0">
            <atbl-tab title="Games"
                   v-if="$can('Payon - Funds Deposit Agent')"
            >
                <ReportsGames />
            </atbl-tab>
            <atbl-tab title="Agents"
            >
                <ReportsAgents />
            </atbl-tab>
        </atbl-tabs>
    </div>
</template>

<script>
    import GamesHeader from "./Components/GamesHeader";
    import ReportsGames from "./Sections/Games/ReportsGames";
    import ReportsAgents from "./Sections/Agents/ReportsAgents";
    import AtblTab from '../../../components/AtblTabs/AtblTab.vue';
    import AtblTabs from '../../../components/AtblTabs/AtblTabs.vue';

    export default {
        name: "reports-games",
        components: {
            GamesHeader,
            ReportsGames,
            ReportsAgents,
            AtblTabs,
            AtblTab,
        }
    }
</script>