var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6 col-sm-6 col-md text-muted" }, [
              _vm._v("Note")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 col-sm-6 col-md text-muted" }, [
              _vm._v("Added By")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 col-sm-6 col-md text-muted" }, [
              _vm._v("Created")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 col-sm-6 col-md text-muted" }, [
              _vm._v("Follow-up")
            ]),
            _vm._v(" "),
            _vm.$can("Player - Remove Notes")
              ? _c("div", { staticClass: "col-6 col-sm-6 col-md text-muted" }, [
                  _vm._v("Actions")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._l(_vm.notes, function(note, index) {
            return !_vm.isLoading
              ? _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-6 col-md mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Note")
                      ]),
                      _vm._v(" "),
                      note.note && _vm.isset(note.note, "type")
                        ? _c("h6", [
                            _c("span", { staticClass: "badge badge-primary" }, [
                              _vm._v(_vm._s(note.note.type))
                            ])
                          ])
                        : _c("span", { staticClass: "text-danger" }, [
                            _vm._v("Note not found.")
                          ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(note.message) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-6 col-md mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Added by")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "badge badge-primary" }, [
                        _vm._v(_vm._s(note.user.name))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-6 col-md mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Created At")
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(_vm._s(_vm.utcToLocal(note.created_at)))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-6 col-md mb-2 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Follow Up")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(note.follow_up) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.$can("Player - Remove Notes")
                    ? _c(
                        "div",
                        { staticClass: "col-12 col-sm-12 col-md mb-2 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Action")
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-danger btn-block",
                              on: {
                                click: function($event) {
                                  return _vm.deleteNote(note)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Delete\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.notes, msg: "No notes found." }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        2
      ),
      _vm._v(" "),
      _vm.$can("Player - Remove Notes") ? _c("RemoveNoteModal") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }