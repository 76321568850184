<template>
    <atbl-modal v-if="$can('Player - Assign Agent')"
                v-model="modalVisibility"
                :close-on-backdrop="false"
                :class="{
                    'hide-close': !!isLoading
                }"
                title="Assign Agent"
                add-footer-classes="d-flex align-items-center justify-content-between"
    >
        <template v-if="!isUnAssignOperation">
            <div v-if="!confirm">
                <h3 class="text-center">
                    #{{ player.id }} / {{ player.name }} {{ player.surname }}
                </h3>
                <h5 class="text-center text-secondary mb-4">{{ player.email }}</h5>
                <fieldset class="mb-0 border-bottom-0 border-right-0 border-left-0">
                    <legend class="text-center text-muted mb-0">Current agent</legend>
                    <h5 class="text-center text-secondary">
                        <span v-if="!!agentDetails.agent_name">
                            #{{ agentDetails.agent_id }} / {{ agentDetails.agent_name }}
                        </span>
                        <span v-else>/</span>
                    </h5>
                </fieldset>
                <fieldset class="mb-0 border-bottom-0 border-right-0 border-left-0">
                    <legend class="text-center text-muted mb-0">Select agent</legend>
                    <agent-picker v-if="[
                                      'Player - Assign Agent',
                                      'Users - Get List'
                                  ]"
                                  :label="null"
                                  v-model="selectedUserValue"
                                  class="mb-0"
                    />
                    <span v-else>No permission to view select agent picker.</span>
                </fieldset>
            </div>
            <div v-else>
                <h5 v-if="!isLoading"
                    class="text-center"
                    @click="isUnAssignOperation = true; selectedUserValue = null;"
                >
                    Are you sure you want to continue?
                </h5>
                <h5 v-else class="text-center text-secondary">PROCESSING...</h5>
            </div>
        </template>
        <template v-else>
            <h5 v-if="!isLoading"
                class="text-center"
            >
                Are you sure you want to remove agent from this player?
            </h5>
            <h5 v-else class="text-center text-secondary">PROCESSING...</h5>
        </template>

        <template #footer>
            <template v-if="!!isUnAssignOperation">
                <div class="d-flex align-items-center justify-content-end w-100">
                    <button class="btn btn-sm btn-danger mr-2"
                            :disabled="isLoading"
                            @click="isUnAssignOperation = false; selectedUserValue = null;"
                    >
                        Cancel
                    </button>
                    <button class="btn btn-sm"
                            :class="{
                                'btn-outline-success': isLoading,
                                'btn-success': !isLoading
                            }"
                            :disabled="isLoading"
                            @click="onUpdatePlayerAgentEvent"
                    >
                        <span v-if="!isLoading">Save</span>
                        <loader :show="isLoading"
                                type="smaller"
                        />
                    </button>
                </div>
            </template>
            <template v-else>
                <div v-if="!confirm">
                    <button class="btn btn-sm btn-danger"
                            :disabled="isLoading || !agentDetails.agent_name"
                            @click="isUnAssignOperation = true; selectedUserValue = null;"
                    >
                        Un-Assign player
                    </button>
                </div>
                <div class="d-flex align-items-center justify-content-end"
                     :class="{'w-100': !!confirm}"
                >
                    <div v-if="!confirm">
                        <button class="btn btn-sm btn-danger mr-2"
                                :disabled="isLoading"
                                @click="onCancelEvent"
                        >
                            Close
                        </button>
                        <button class="btn btn-sm btn-primary"
                                :disabled="isLoading || !selectedUserValue"
                                @click="confirm = true"
                        >
                            Continue
                        </button>
                    </div>
                    <div v-else>
                        <button class="btn btn-sm btn-danger mr-2"
                                :disabled="isLoading"
                                @click="confirm = false"
                        >
                            Cancel
                        </button>
                        <button class="btn btn-sm"
                                :class="{
                                    'btn-outline-success': isLoading,
                                    'btn-success': !isLoading
                                }"
                                :disabled="isLoading"
                                @click="onUpdatePlayerAgentEvent"
                        >
                            <span v-if="!isLoading">Save</span>
                            <loader :show="isLoading"
                                    type="smaller"
                            />
                        </button>
                    </div>
                </div>
            </template>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../components/AtblModal/AtblModal';

export default {
    name: 'assign-agent',
    components: {
        AtblModal
    },
    props: {
        player: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            confirm: false,
            selectedUserValue: null,
            isLoading: false,
            errors: {},
            isUnAssignOperation: false,
        };
    },
    computed: {
        modalVisibility: {
            get() {
                return this.show;
            },
            set() {
                this.onCancelEvent();
            },
        },
        agentDetails() {
            if (!this.player) {
                return {
                    agent_name: null,
                    agent_id: null,
                };
            }

            if (!!this.player.agent_id && !!this.player.agent_name) {
                return {
                    agent_name: this.player.agent_name,
                    agent_id: this.player.agent_name,
                };
            }
            
            if (!this.player.hasOwnProperty('user') || !this.player.user) {
                return {
                    agent_name: null,
                    agent_id: null,
                };
            }

            return {
                agent_name: this.player.user.name,
                agent_id: this.player.user.id,
            };
        },
    },
    methods: {
        async onUpdatePlayerAgentEvent() {
            this.isLoading = true;
            this.errors = {};

            const { id } = this.player;

            try {
                const response = await window.axios.patch(
                    `/player/${id}/agent`,
                    {
                        user_id: this.selectedUserValue
                    }
                );

                let { status, message } = response.data;

                if (status) {
                    this.onCancelEvent();
                    this.$emit('success');
                }

                window.showMessage(message, status);

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                this.errors = this.isset(error.response.data, 'errors')
                    ? error.response.data.errors
                    : {};

                this.flashBadResponse(error);
            }
        },
        onCancelEvent() {
            this.selectedUserValue = null;
            this.isUnAssignOperation = false;
            this.confirm = false;
            this.$emit('close');
        },
    },
}
</script>