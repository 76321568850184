var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", [
    _c(
      "div",
      [
        _c(
          "b-nav",
          { attrs: { vertical: "" } },
          [
            _c("b-nav-text", { staticClass: "border-bottom" }, [_vm._v("CRM")]),
            _vm._v(" "),
            _c(
              "b-nav-item",
              { attrs: { to: "/marketing/leads/" + _vm.id + "/view" } },
              [_vm._v("Profile")]
            ),
            _vm._v(" "),
            _c(
              "b-nav-item",
              { attrs: { to: "/marketing/leads/" + _vm.id + "/dispositions" } },
              [_vm._v("Dispositions")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }