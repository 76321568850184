<template>
    <div class="dashboard-sales">
        <div class="dashboard-sales-heading">
            <p class="dashboard-sales-title"> Sales </p>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6">
                <statistic-card crmLabel="CRM"
                                :crmTotal="getStatisticsValue()"
                                webLabel="WEB"
                                :webTotal="getStatisticsValue('web')"
                                class="mb-4 mb-sm-0"
                >
                    <i class="fa fa-money-bill"></i>
                    <p class="statistic-icon-label"> Daily </p>
                </statistic-card>
            </div>
            <div class="col-12 col-sm-6">
                <statistic-card bgColor="bg-twitter"
                                crmLabel="CRM"
                                :crmTotal="getStatisticsValue('crm', 'sales', 'monthly')"
                                webLabel="WEB"
                                :webTotal="getStatisticsValue('web', 'sales', 'monthly')"
                                class="mb-0"
                >
                    <i class="fa fa-chart-pie"></i>
                    <p class="statistic-icon-label"> Monthly </p>
                </statistic-card>
            </div>
        </div>
    </div>

</template>

<script>
import StatisticCard from '../Components/StatisticCard';
import FormatCurrency from '../../../filters/FormatCurrency';

export default {
    name: 'sales',
    inject: [
        'getStatisticsValue',
    ],
    components: {
        StatisticCard
    },
}
</script>
