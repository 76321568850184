export default {
    parent_id: 0,
    section_id: 0,
    setting_id: 0,
    sections: [],
    sectionsModel: {
        type: null,
        name: '',
        key: ''
    },
    sectionsLoader: false,
    sectionsModalLoader: false,
    removeSettingSection: {},
    removeSettingSectionModal: false,
    removeSettingSectionModalLoader: false,
    sectionsTypes: [],
    sectionErrors: {},
    settings: [],
    selectedSection: {},
    addSettingsSectionModal: false,
    addSettingsModal: false,
    settingsTypes: [],
    settingsModalLoader: false,
    settingsErrors: {},
    settingsModel: {
        category_id: 0,
        type: null,
        name: '',
        key: '',
        value: '',
        off_value: '',
        on_value: ''
    },
    removeSetting: {},
    removeSettingModal: false,
    removeSettingModalLoader: false,
    breadcrumbs: [],
    settingSaveLoader: false,
};