<template>
	<section>
		<div class="row pb-3 mb-3 border-bottom justify-content-between">
			<div class="col-6">
				<atbl-input label="Name"
				            name="name"
				            class="m-0"
				            label-class="font-weight-bold transform-none"
				            v-model.trim="model.name"
				            :errors="errors"
				/>
			</div>
			<div class="col-6">
				<atbl-input label="Surname"
				            name="surname"
				            class="m-0"
				            label-class="font-weight-bold transform-none"
				            v-model.trim="model.surname"
				            :errors="errors"
				/>
			</div>
		</div>
		
		<div class="row pb-3 mb-3 border-bottom justify-content-between">
			<div class="col-6">
				<birthday-picker v-model="model.birthdate"
				                 class="font-weight-bold transform-none"
				                 format="YYYY-MM-DD"
				/>
			</div>
			<div class="col-6">
				<atbl-input label="Email"
				            name="email"
				            label-class="font-weight-bold transform-none"
				            v-model.trim="model.email"
				            :errors="errors"
				/>
			</div>
			
			<div class="col-6">
				<atbl-input label="Address 1"
				            name="address1"
				            label-class="font-weight-bold transform-none"
				            v-model.trim="model.address1"
				            :errors="errors"
				/>
			</div>
			<div class="col-6">
				<atbl-input label="Address 2"
				            name="address2"
				            label-class="font-weight-bold transform-none"
				            v-model.trim="model.address2"
				            :errors="errors"
				/>
			</div>
		</div>
		
		<div class="row pb-3 mb-3 border-bottom transform-none justify-content-between">
			<div class="col-6">
				<tel-number label="Mobile"
				            id="Mobile"
				            placeholder="Enter Mobile"
				            class="font-weight-bold transform-none"
				            name="mobile"
				            v-model="model.mobile"
				            :error="errors"
				/>
			</div>
			<div class="col-6">
				<tel-number label="Phone"
				            id="Phone"
				            placeholder="Enter Phone"
				            class="font-weight-bold transform-none"
				            name="phone"
				            v-model="model.phone"
				            :error="errors"
				/>
			</div>
		</div>
		
		<div class="row justify-content-between">
			<div class="col-3">
				<atbl-input label="City"
				            name="city"
				            label-class="font-weight-bold transform-none"
				            class="mb-1"
				            v-model.trim="model.city"
				            :errors="errors"
				/>
			</div>
			<div class="col-3">
				<atbl-input label="Postcode"
				            name="postcode"
				            label-class="font-weight-bold transform-none"
				            class="mb-1"
				            v-model.trim="model.postcode"
				            :errors="errors"
				/>
			</div>
			<div class="col-3">
				<atbl-input label="State"
				            label-class="font-weight-bold transform-none"
				            class="mb-1"
				            name="state"
				            v-model.trim="model.state"
				            :errors="errors"
				/>
			</div>
			<div class="col-3">
				<countries-options v-model.trim="model.country"
				                   class="transform-none mb-1"
				                   :error="errors"
				/>
			</div>
		</div>
	</section>
</template>

<script>
	import CountriesOptions from "../../../../../components/Forms/Select/CountriesOptions";
	import BirthdayPicker from "../../../../../components/Forms/Select/BirthdayPicker";
	
	export default {
		name: "edit-player-form",
		components: {
			BirthdayPicker,
			CountriesOptions
		},
		props: {
			player: {
				type: Object,
				required: true
			},
			errors: {
				type: Object,
				required: false,
				default: () => ({})
			},
		},
		data() {
			return {
				model: {
					name: null,
					surname: null,
					birthdate: null,
					email: null,
					address1: null,
					address2: null,
					city: null,
					postcode: null,
					state: null,
					country: null,
					phone: null,
					mobile: null,
                    newsletter: null,
				}
			};
		},
		watch: {
			player: {
				handler(value) {
					this.model = _.pick(
						{
							...value,
							name: value.name ? this.ucFirst(value.name) : null,
							surname: value.surname ? this.ucFirst(value.surname) : null,
							phone: value.phone ? this.innerTrim(value.phone) : null,
							mobile: value.mobile ? this.innerTrim(value.mobile) : null,
                            newsletter: value.newsletter
						},
						Object.keys(this.model)
					);
				},
				deep: true,
				immediate: true
			},
			model: {
				handler(value) {
					this.$emit("update:player-edit-form", value);
				},
				deep: true,
				immediate: true
			}
		}
	}
</script>