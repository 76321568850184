<template>
    <sidebar-sub title="Preview Affiliate"
                 :value="isOpen"
                 :loader="isLoading"
                 @input="onCloseEvent"
                 class="large"
    >
        <div class="row border-bottom mb-2 pb-2">
            <div class="col">
                <h5 class="text-uppercase m-0">General</h5>
            </div>
        </div>
        <div class="row border-bottom">
            <div class="col font-weight-bold py-1">
                # ID
            </div>
            <div class="col py-1">
                # {{ selectedAffiliate.id }}
            </div>
        </div>
        <div class="row border-bottom">
            <div class="col font-weight-bold py-1">
                Username
            </div>
            <div class="col py-1">
                {{ selectedAffiliate.username }}
            </div>
        </div>
        <div class="row border-bottom">
            <div class="col font-weight-bold py-1">
                Status
            </div>
            <div class="col py-1">
                <span :class="`badge badge-${resolveStatusColor(selectedAffiliate.status)}`">
                    {{ selectedAffiliate.status }}
                </span>
            </div>
        </div>
        <div class="row border-bottom">
            <div class="col font-weight-bold py-1">
                Is Active
            </div>
            <div class="col py-1">
                <i class="fa"
                   :class="{
                        'text-success fa-check': !!selectedAffiliate.is_active,
                        'text-danger fa-times': !selectedAffiliate.is_active,
                   }"
                ></i>
            </div>
        </div>
        <div class="row border-bottom">
            <div class="col font-weight-bold py-1">
                Created At
            </div>
            <div class="col py-1">
                {{ selectedAffiliate.created_at | utcToLocal }}
            </div>
        </div>
        <div class="row border-bottom">
            <div class="col font-weight-bold py-1">
                Updated At
            </div>
            <div class="col py-1">
                {{ selectedAffiliate.updated_at | utcToLocal }}
            </div>
        </div>

        <div class="mt-4">
            <div class="row mb-2">
                <div class="col">
                    <h5 class="text-uppercase m-0">Players</h5>
                </div>
            </div>
            <div class="general-list">
                <div class="row">
                    <div class="col">ID</div>
                    <div class="col">Name</div>
                    <div class="col">Surname</div>
                    <div class="col">Email Confirmed</div>
                    <div v-if="$can('Player - View Details')"
                         class="col"
                    >
                        Operations
                    </div>
                </div>

                <template v-if="!!players.length">
                    <div v-for="(player, index) in players"
                         :key="index"
                         class="row"
                    >
                        <div class="col-12 col-lg">
                            <span class="small-mb-badge">ID</span>
                            # {{ player.user.id }}
                        </div>
                        <div class="col-12 col-lg">
                            <span class="small-mb-badge">Name</span>
                            {{ player.user.name }}
                        </div>
                        <div class="col-12 col-lg">
                            <span class="small-mb-badge">Surname</span>
                            {{ player.user.surname }}
                        </div>
                        <div class="col-12 col-lg">
                            <span class="small-mb-badge">Email Confirmed</span>
                            <i class="fa"
                               :class="{
                                   'fa-check text-success': !!player.user.activated_at,
                                   'fa-times text-danger': !player.user.activated_at,
                               }"
                            ></i>
                        </div>
                        <div v-if="$can('Player - View Details')"
                             class="col-12 col-lg"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <a :href="`/players/${player.user.id}/view`"
                               target="_blank"
                            >
                                View Player
                            </a>
                        </div>
                    </div>
                </template>

                <not-found :items="players"
                           msg="No users found"
                />
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-outline-danger mr-2"
                      @click="onCloseEvent"
            >
                Close
            </button>
        </template>
    </sidebar-sub>
</template>

<script>
export default {
    name: 'PreviewAffiliateComponent',
    inject: [
        'resolveStatusColor',
    ],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        selectedAffiliate: {
            type: Object,
            default: false,
        },
    },
    computed: {
        players() {
            return !this.selectedAffiliate.users
                ? []
                : this.selectedAffiliate.users;
        },
    },
    methods: {
        onCloseEvent() {
            this.$emit('close');
        },
    },
}
</script>