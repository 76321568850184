<template>
    <div :class="['atbl-dropdown', {disabled: !!disabled, sm: !!small}]">
        <div v-if="!!isPositionedTop"
             :class="['atbl-dropdown-content', {show: isComponentOpen}, position]"
        >
            <slot/>
        </div>
        <template v-if="!$slots.header">
            <button :class="['atbl-dropdown-header', inputStyle]"
                    @click.prevent.stop="toggleDropdown"
            >
                <span>{{ headerText }}</span>
                <i class="fa"
                   :class="{
                       'fa-caret-down': !isComponentOpen,
                       'fa-caret-up': !!isComponentOpen,
                   }"
                ></i>
            </button>
        </template>
        <div v-else
             :class="['atbl-dropdown-header', inputStyle]"
             @click.prevent.stop="toggleDropdown"
        >
            <slot name="header"/>
        </div>
        <div v-if="!isPositionedTop"
             :class="['atbl-dropdown-content', {show: isComponentOpen}, position]"
        >
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
	name: "AtblDropdown",
	emits: [
		'input',
		'update',
	],
	props: {
		placeholder: {
			type: String,
			default: 'Select option'
		},
		alwaysOpen: {
			type: Boolean,
			default: false
		},
		inputStyle: {
			type: String,
			default: null,
			validator: function (value) {
				return [
					'input',
					'primary',
					'secondary',
					'success',
					'info',
					'warning',
					'danger',
					'light',
					'dark',
					'outline-input',
					'outline-primary',
					'outline-secondary',
					'outline-success',
					'outline-info',
					'outline-warning',
					'outline-danger',
					'outline-light',
					'outline-dark',
				].indexOf(value) !== -1;
			}
		},
		position: {
			type: String,
			default: 'left',
			validator: function (value) {
				return ['left', 'right', 'center', 'top-left', 'top-right', 'top-center'].indexOf(value) !== -1;
			}
		},
		small: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			isOpen: false,
		};
	},
	computed: {
		isComponentOpen() {
			return this.alwaysOpen
				? this.alwaysOpen
				: this.isOpen;
		},
		componentValue() {
			return this.value.value;
		},
		headerText() {
			return this.placeholder;
		},
		isPositionedTop() {
			const position = this.position.split('-')[0];

			return position === 'top';
		},
	},
	created() {
		document.addEventListener('click', (e) => {
			e.stopPropagation();

			this.isOpen = false;
		});
		this.$on('close', () => this.isOpen = false);
	},
	methods: {
		toggleDropdown() {
			this.isOpen = !this.isOpen;
		},
	},
}
</script>