var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-list reports reports-agents m-0 border-0" },
    [
      _c(
        "div",
        {
          staticClass: "card-body",
          class: {
            "p-0": !!_vm.hasAgents
          }
        },
        [
          !_vm.isLoading && !!_vm.hasAgents
            ? _c(
                "atbl-tabs",
                {
                  attrs: { vertical: true },
                  on: { "change-tab": _vm.updateTab }
                },
                _vm._l(_vm.agents, function(agent, index) {
                  return _c(
                    "atbl-tab",
                    { key: "user_list_" + agent, attrs: { title: agent } },
                    [
                      _c(
                        "div",
                        { staticClass: "general-list" },
                        [
                          _c("div", { staticClass: "row no-gutters" }, [
                            _c("div"),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "sort-link",
                                  class: { active: _vm.sort.column === "name" },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.sortChange("name")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                Game "
                                  ),
                                  _vm.sort.column === "name"
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fa",
                                          class: {
                                            "fa-caret-up":
                                              _vm.sort.direction === "ASC",
                                            "fa-caret-down":
                                              _vm.sort.direction === "DESC"
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "sort-link",
                                  class: {
                                    active: _vm.sort.column === "tickets_count"
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.sortChange("tickets_count")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                Tickets "
                                  ),
                                  _vm.sort.column === "tickets_count"
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fa",
                                          class: {
                                            "fa-caret-up":
                                              _vm.sort.direction === "ASC",
                                            "fa-caret-down":
                                              _vm.sort.direction === "DESC"
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "sort-link",
                                  class: {
                                    active: _vm.sort.column === "lines_count"
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.sortChange("lines_count")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                Lines "
                                  ),
                                  _vm.sort.column === "lines_count"
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fa",
                                          class: {
                                            "fa-caret-up":
                                              _vm.sort.direction === "ASC",
                                            "fa-caret-down":
                                              _vm.sort.direction === "DESC"
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "sort-link",
                                  class: {
                                    active:
                                      _vm.sort.column === "free_games_count"
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.sortChange("free_games_count")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                Free Games "
                                  ),
                                  _vm.sort.column === "free_games_count"
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fa",
                                          class: {
                                            "fa-caret-up":
                                              _vm.sort.direction === "ASC",
                                            "fa-caret-down":
                                              _vm.sort.direction === "DESC"
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.agentsReports[index], function(game) {
                            return _c(
                              "div",
                              {
                                key: "user_game_" + agent + "_" + game.id,
                                staticClass: "row p-1 no-gutters"
                              },
                              [
                                _c("div", [
                                  _c("img", {
                                    staticClass: "game-thumb",
                                    attrs: {
                                      src: _vm.$imageService.gameImage(
                                        game.image
                                      ),
                                      alt: game.image
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-lg" }, [
                                  _c(
                                    "span",
                                    { staticClass: "small-mb-badge" },
                                    [_vm._v("Game")]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(game.name) +
                                      "\n                        "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-lg" }, [
                                  _c(
                                    "span",
                                    { staticClass: "small-mb-badge" },
                                    [_vm._v("Tickets")]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(game.tickets_count) +
                                      "\n                        "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-lg" }, [
                                  _c(
                                    "span",
                                    { staticClass: "small-mb-badge" },
                                    [_vm._v("Lines")]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(game.lines_count) +
                                      "\n                        "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-lg" }, [
                                  _c(
                                    "span",
                                    { staticClass: "small-mb-badge" },
                                    [_vm._v("Free Games")]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(game.free_games_count) +
                                      "\n                        "
                                  )
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading && !_vm.hasAgents
            ? _c("not-found", {
                staticClass: "w-100",
                attrs: { msg: "No results found", items: [] }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }