var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid order-preview" },
    [
      _c("div", { staticClass: "card card-search" }, [
        !_vm.isLoading
          ? _c(
              "div",
              {
                staticClass:
                  "card-header d-flex justify-content-between align-items-center"
              },
              [
                _c(
                  "a",
                  { staticClass: "text-white", attrs: { href: _vm.backLink } },
                  [
                    _c("i", {
                      staticClass: "fa fa-arrow-left",
                      attrs: { "aria-hidden": "false" }
                    }),
                    _vm._v(" " + _vm._s(_vm.backLinkTitle) + "\n            ")
                  ]
                ),
                _vm._v(" "),
                _vm.isCompleted
                  ? _c("div", [
                      _vm.$can("Player - Send Order Details")
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-light mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.onToggleEmailModalEvent(true)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-envelope",
                                attrs: { "aria-hidden": "false" }
                              }),
                              _vm._v(" Send Order Details\n                ")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$can("Player - Export Order to PDF")
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-light",
                              on: { click: _vm.onExportPdfEvent }
                            },
                            [
                              !_vm.isExportLoading
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-file-pdf",
                                      attrs: { "aria-hidden": "false" }
                                    }),
                                    _vm._v(" Export PDF\n                    ")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("loader", {
                                attrs: {
                                  show: _vm.isExportLoading,
                                  type: "smaller"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body p-0" },
          [
            !_vm.isLoading
              ? _c(
                  "atbl-tabs",
                  { staticClass: "order-preview-tabs" },
                  [
                    _c(
                      "atbl-tab",
                      { staticClass: "px-3", attrs: { title: "Order" } },
                      [
                        _c("order-info", {
                          attrs: {
                            order: _vm.order,
                            "is-order-free": _vm.isOrderFree
                          },
                          on: { checkout: _vm.checkoutEvent }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "atbl-tab",
                      { staticClass: "px-3", attrs: { title: "Tickets" } },
                      [
                        _c("tickets", {
                          attrs: { tickets: _vm.tickets, order: _vm.order }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isOrderFree
                      ? _c(
                          "atbl-tab",
                          {
                            staticClass: "px-3",
                            attrs: { title: "Transactions" }
                          },
                          [
                            _c("transactions", {
                              attrs: {
                                transactions: _vm.transactions,
                                order: _vm.order
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isOrderFree
                      ? _c("atbl-tab", {
                          staticClass: "px-3",
                          attrs: { disabled: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "badge badge-danger" },
                                      [
                                        _vm._v(
                                          "\n                            Free\n                        "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2003370562
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("send-email-modal-component", {
            attrs: {
              client: _vm.order.player,
              show: _vm.sendEmailModalOpened,
              "send-email-configuration": _vm.emailTemplatesEnabledTypes,
              "email-template-data": _vm.emailTemplateData
            },
            on: { toggle: _vm.onToggleEmailModalEvent }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canExecutePayment && _vm.checkout
        ? _c("order-payment-modal", {
            attrs: { order: _vm.order },
            on: {
              "payment-successful": _vm.getOrder,
              cancel: function($event) {
                _vm.checkout = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }