<template>
    <div class="container-fluid ticket-results">
        <syndicate-ticket-info v-if="!isLoading"
                               :syndicate-share="syndicateShare"
        />

        <syndicate-ticket-lines v-if="!isLoading"
                                :syndicate-share="syndicateShare"
                                :game-number-configuration="gameNumberConfiguration"
        />

        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import SyndicateTicketInfo from './Sections/SyndicateTicketInfo';
import SyndicateTicketLines from './Sections/SyndicateTicketLines';
import Loader from '../../../components/Widgets/Loader';

export default {
    name: 'syndicate-share-ticket',
    components: {
        SyndicateTicketLines,
        SyndicateTicketInfo,
        Loader,
    },
    props: {
        id: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            isLoading: false,
            syndicateShare: null,
            gameNumberConfiguration: null,
        };
    },
    async created() {
        await this.fetchSyndicateShare();
    },
    methods: {
        async fetchSyndicateShare() {
            this.isLoading = true;

            try {
                const response = await window.axios.get(`/syndicate-shares/${this.id}/show`);

                this.syndicateShare = response.data;

                await this.fetchGameConfiguration(response.data.game_key);

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        async fetchGameConfiguration(gameKey) {
            try {
                const response = await window.axios.get(`/game/configuration/${gameKey}`);

                this.gameNumberConfiguration = response.data.numbers
            } catch (error) {
                window.flashError(error);
            }
        },
    },
}
</script>
