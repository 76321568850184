<template>
    <form @submit.prevent="onSaveRoleEvent">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span><i class='fa fa-user-shield'></i> Role</span>
                <div class="d-flex align-items-center justify-content-between">
                    <a v-if="!roleLoader"
                       href="/roles"
                       class="btn btn-sm btn-danger mr-2"
                    >
                        Cancel
                    </a>
                    <button v-if="$can('Roles - Create/Update')"
                            :disabled="!isModelDirty || !!roleLoader"
                            class="btn btn-sm btn-primary"
                    >
                        Save
                    </button>
                </div>
            </div>

            <div v-if="!roleLoader"
                 class="card-body"
            >
                <atbl-input v-model.trim="changeRoleName"
                            :errors="roleErrors"
                            label="Enter name"
                            name="name"
                            class="mt-2"
                />

                <div class="permissions-header">
                    <h6 class="font-weight-bold m-0">Permissions</h6>
                    <small class="ml-2 text-danger font-weight-bold" v-if="hasError(roleErrors, 'permissions')">{{ getError(roleErrors, "permissions") }}</small>
                </div>
                <div class="permissions-operations">
                    <atbl-check-box :value="isAllSelected"
                                    :partial-check="isPartialSelected"
                                    name="permissions"
                                    class="mr-1"
                                    label="Select All"
                                    @update="onSelectAllPermissionsEvent"
                    />
                    <atbl-input v-model="searchData"
                                :label="null"
                                placeholder="Search"
                                class="m-0"
                                type="search"
                    />
                </div>

                <div class="permissions-holder mt-2">
                    <permission-item-component v-for="(permissions, key) in groupedPermissions"
                                               :key="key"
                                               :name="key"
                                               :permissions="permissions"
                                               :search-data="searchData"
                                               :selected-permissions="role.permissions"
                                               @update="onPermissionsListUpdate"
                    />
                </div>
            </div>
            <loader :show="roleLoader"
                    type="small"
            />
        </div>
    </form>
</template>

<script>
import AtblInput from '../../../../components/Forms/Inputs/ATBLInput';
import { clone, stringify } from '../../../../utils/Json/Json';
import PermissionItemComponent from './PermissionItem/PermissionItemComponent';
import AtblCheckBox from '../../../../components/Forms/Inputs/AtblCheckbox';
import RoleModel from '../RoleModel';
import Loader from '../../../../components/Widgets/Loader';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';

export default {
    name: 'role-manager-component',
    inject: [
        'isEditMode',
        'roleId',
        'setRoleModel',
    ],
    components: {
        AtblInput,
        PermissionItemComponent,
        AtblCheckBox,
        Loader,
    },
    props: {
        model: {
            type: Object,
            default: () => (RoleModel)
        },
        permissions: {
            type: Array,
            default: () => ([])
        },
        groupedPermissions: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            roleErrors: {},
            role: clone(RoleModel),
            roleLoader: false,

            permissionsSearch: null,
        };
    },
    computed: {
        changeRoleName: {
            get() {
                return this.role.name;
            },
            set(value) {
                this.role.name = !value || value === ''
                    ? null
                    : value;
            }
        },
        searchData: {
            get() {
                return this.permissionsSearch;
            },
            set(value) {
                this.permissionsSearch = !value ? null : value;
            }
        },
        isAllSelected() {
            return this.role.permissions.length === this.permissions.length;
        },
        isPartialSelected() {
            return !!this.role.permissions.length && !this.isAllSelected;
        },
        isModelDirty() {
            return stringify(this.model) !== stringify(this.role);
        },
    },
    created() {
        this.role = clone(this.model);
    },
    methods: {
        async onSaveRoleEvent() {
            this.roleLoader = true;
            this.roleErrors = {};

            try {
                const modelData = {
                    name: this.role.name,
                    permissions: this.role.permissions.map(item => item.value),
                };

                const response = !this.isEditMode
                    ? await window.axios.post('/roles/create', modelData)
                    : await window.axios.patch(`/roles/${this.roleId}/update`, modelData);
                const { status, message, data } = response.data;

                window.showMessage(message, status);

                if(!status) {
                    this.roleLoader = false;

                    return;
                }

                this.setRoleModel(this.role);

                if(!this.isEditMode) {
                    window.location.href = `/roles/${data.id}/update`;

                    return;
                }

                this.roleLoader = false;
            } catch (error) {
                this.roleLoader = false;

                this.roleErrors = getValidationErrors(error);

                window.flashError(error);
            }
        },
        onPermissionsListUpdate(value) {
            this.role.permissions = value;
        },
        onSelectAllPermissionsEvent() {
            const modelRole = clone(this.role);

            if (modelRole.permissions.length === this.permissions.length) {
                modelRole.permissions = [];
            } else {
                modelRole.permissions = this.permissions;
            }

            Object.assign(this.role, modelRole);
        },
    },
}
</script>