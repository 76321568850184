export default {
    games: [],
    gamesLoader: false,
    gameConfiguration: {
        cutOffTime: null,
        drawsPerTicket: null,
        image: null,
        jackpot: null,
        key: null,
        linesPerTicket: null,
        name: null,
        numbers: null,
        price: null,
        ticketNumbers: null,
        weekdays: null,
        weeksPerTicket: null
    },
    gameConfigurationLoader: false
};