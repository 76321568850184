<template>
    <div>
        <template v-for="(item, key) in meta">
            <atbl-form-group :key="key"
                             :label="!!item.title ? item.title : getLabel(item, key)"
                             :error="getError(errors['meta'], key)"
                             v-if="item.active"
            >
                <atbl-number :value="item.value"
                             :name="key"
                             :min="item.min"
                             :max="!item.max ? item.maxAllowed : item.max"
                             :step="1"
                             :disabled="disabled"
                             @update="changeMetaInput"
                />
            </atbl-form-group>
        </template>
    </div>
</template>

<script>
export default {
    name: 'MetaInputs',
    inject: [
        'changeMetaInput'
    ],
    props: {
        meta: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        getLabel(item, key) {
            const label = !!item.label
                ? item.label
                : this.ucFirst(key);

            let upToString = null;

            switch(key) {
                case 'lines':
                    upToString = !item.max || item.max === 'unlimited'
                        ? `(Max ${item.maxAllowed})`
                        : `(Up to ${item.max})`;
                break;
                case 'fractions':
                    upToString = !item.max || item.max === 'unlimited'
                        ? '(Max 10)'
                        : `(Up to ${item.max})`;
                break;
                default:
                    upToString = !item.max || item.max === 'unlimited'
                        ? `(Unlimited)`
                        : `(Up to ${item.max})`;
            }

            return `${label} ${upToString}`;
        },
    },
}
</script>