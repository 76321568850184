import TypesConst from "../../TypesConst";

export default {
    [TypesConst.settings.getters.GET_PARENT_ID]: state => state.parent_id,
    [TypesConst.settings.getters.GET_SECTION_ID]: state => state.section_id,
    [TypesConst.settings.getters.GET_SETTING_ID]: state => state.setting_id,
    [TypesConst.settings.getters.GET_SETTINGS_SECTIONS]: state => state.sections,
    [TypesConst.settings.getters.GET_ADD_SETTINGS_SECTION_MODAL]: state => state.addSettingsSectionModal,
    [TypesConst.settings.getters.GET_ADD_SETTINGS_MODAL]: state => state.addSettingsModal,
    [TypesConst.settings.getters.GET_SECTIONS_TYPES]: state => state.sectionsTypes,
    [TypesConst.settings.getters.GET_SETTINGS_TYPES]: state => state.settingsTypes,
    [TypesConst.settings.getters.SECTIONS_LOADER]: state => state.sectionsLoader,
    [TypesConst.settings.getters.GET_BREADCRUMBS]: state => state.breadcrumbs,
    [TypesConst.settings.getters.GET_SELECTED_SECTION]: state => state.selectedSection,
    [TypesConst.settings.getters.GET_SETTINGS]: state => state.settings,
    [TypesConst.settings.getters.GET_SECTION_MODEL]: state => state.sectionsModel,
    [TypesConst.settings.getters.GET_SECTION_MODAL_LOADER]: state => state.sectionsModalLoader,
    [TypesConst.settings.getters.GET_SECTION_FORM_ERRORS]: state => state.sectionErrors,
    [TypesConst.settings.getters.GET_SETTINGS_MODAL_LOADER]: state => state.settingsModalLoader,
    [TypesConst.settings.getters.GET_SETTINGS_FORM_ERRORS]: state => state.settingsErrors,
    [TypesConst.settings.getters.GET_SETTINGS_FORM_MODEL]: state => state.settingsModel,
    [TypesConst.settings.getters.GET_SETTING_REMOVE]: state => state.removeSetting,
    [TypesConst.settings.getters.GET_SETTING_REMOVE_MODAL]: state => state.removeSettingModal,
    [TypesConst.settings.getters.GET_SETTING_REMOVE_MODAL_LOADER]: state => state.removeSettingModalLoader,
    [TypesConst.settings.getters.GET_SETTING_SECTION_REMOVE_MODAL]: state => state.removeSettingSectionModal,
    [TypesConst.settings.getters.GET_SETTING_SECTION_REMOVE_MODAL_LOADER]: state => state.removeSettingSectionModalLoader,
    [TypesConst.settings.getters.GET_SETTINGS_SAVE_LOADER]: state => state.settingSaveLoader,
};