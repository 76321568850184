var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game", attrs: { title: _vm.item.name } }, [
    _c("img", {
      staticClass: "game-thumb",
      attrs: { src: _vm.$imageService.gameImage(_vm.item.image) }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "game-play" }, [
      _c("p", [_vm._v(_vm._s(_vm.item.name))]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn",
          class: {
            "btn-primary": !_vm.isLoading,
            "btn-outline-primary": _vm.isLoading
          },
          on: { click: _vm.playGame }
        },
        [
          !_vm.isLoading ? _c("span", [_vm._v("Play")]) : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }