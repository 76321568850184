<script>
    export default {
        defaultSalesUrl: "/reports/games",
        getGamesReports: function(_params){
            return window.axios.get(`${this.defaultSalesUrl}/get`, {
                params: _params
            });
        },
        getAgentsReports: function(_params){
            return window.axios.get(`${this.defaultSalesUrl}/agents`, {
                params: _params
            });
        },
    }
</script>