<template>
	<atbl-form-group :label="label"
	                 :label-for="getLabelFor"
	                 :error="getError(errors, name)"
	>
		<div class="d-flex align-items-center">
			<slot name="formGroup" v-if="$slots.formGroup"></slot>
			<select :id="getId"
			        class="form-control"
			        :class="{
	                    'is-invalid': hasError(errors, name)
                    }"
			        @change="onUpdate"
			        :disabled="disabled"
			>
				<option :value="null" v-if="firstOptionLabel" :selected="!value">{{ firstOptionLabel }}</option>
				<option v-for="(item, index) in options"
				        :value="getOptionData(item, 'value')"
				        :key="index"
				        :selected="getOptionData(item, 'value') === value"
				>
					{{ getOptionData(item, optionLabel) }}
				</option>
				<slot name="end"></slot>
			</select>
		</div>
	</atbl-form-group>
</template>

<script>
	import ATBLFormControls from "./ATBLFormControls";
	
	export default {
		name: "ATBLSelect",
		mixins: [
			ATBLFormControls
		],
		props: {
			options: {
				type: Array,
				default: []
			},
			firstOptionLabel: {
				type: String,
				default: "Select option"
			},
            optionLabel: {
                type: String,
                default: 'label'
            },
		},
		data: function () {
			return {
				selected: null
			};
		},
		computed: {
			getSelected: {
				get: function () {
					return this.selected ? this.selected : null;
				},
				set: function (value) {
					this.selected = value;
				}
			}
		},
		methods: {
			getOptionData: function (item, field) {
				return this.isObject(item) ? item[field] : item;
			}
		},
		watch: {
			value: {
				handler: function (value) {
					this.selected = value;
				},
				immediate: true
			}
		}
	}
</script>