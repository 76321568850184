var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      staticClass: "desktop",
      attrs: {
        title: "Create/Update Email Template",
        value: _vm.show,
        loader: _vm.isLoading
      },
      on: { input: _vm.toggleCreateUpdateForm },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.isLoading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-outline-danger mr-2",
                      attrs: { disabled: _vm.isLoading },
                      on: { click: _vm.toggleCreateUpdateForm }
                    },
                    [_vm._v("\n            Cancel\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$canAny([
                "Email Templates - Create",
                "Email Templates - Update"
              ])
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { disabled: _vm.isLoading || !_vm.canSaveEmail },
                      on: { click: _vm.onSaveEmailTemplate }
                    },
                    [
                      !_vm.isLoading ? _c("span", [_vm._v("Save")]) : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: { show: _vm.isLoading, type: "smaller" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-4" },
                  [
                    _c(
                      "atbl-form-group",
                      {
                        attrs: {
                          label: "Key",
                          error: _vm.getError(_vm.emailErrors, "key")
                        }
                      },
                      [
                        _c("atbl-input", {
                          attrs: {
                            name: "key",
                            value: _vm.emailModel.key,
                            disabled: _vm.isLoading
                          },
                          on: { update: _vm.updateModel }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-4" },
                  [
                    _c(
                      "atbl-form-group",
                      {
                        attrs: {
                          label: "Name",
                          error: _vm.getError(_vm.emailErrors, "name")
                        }
                      },
                      [
                        _c("atbl-input", {
                          attrs: {
                            name: "name",
                            value: _vm.emailModel.name,
                            disabled: _vm.isLoading
                          },
                          on: { update: _vm.updateModel }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-4" },
                  [
                    _c(
                      "atbl-form-group",
                      {
                        attrs: {
                          label: "Description",
                          error: _vm.getError(_vm.emailErrors, "description")
                        }
                      },
                      [
                        _c("atbl-input", {
                          attrs: {
                            name: "description",
                            value: _vm.emailModel.description,
                            disabled: _vm.isLoading
                          },
                          on: { update: _vm.updateModel }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("fieldset", [
                _c("legend", [
                  _vm._v("\n                Send email\n                "),
                  _c("a", {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content:
                            "These options enable/disable visibility for every email template for players in Send Mail modal in the player details view and Send Email in the campaigns call view page."
                        },
                        expression:
                          "{\n                    content: 'These options enable/disable visibility for every email template for players in Send Mail modal in the player details view and Send Email in the campaigns call view page.'\n                   }"
                      }
                    ],
                    staticClass: "fa fa-info-circle text-dark"
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-4" },
                    [
                      _c(
                        "atbl-form-group",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            label: "Visibile for players",
                            error: _vm.getError(
                              _vm.emailErrors,
                              "players_enabled"
                            )
                          }
                        },
                        [
                          _c("atbl-switch", {
                            attrs: {
                              name: "players_enabled",
                              value: !!_vm.emailModel.players_enabled,
                              disabled: _vm.isLoading,
                              off: false,
                              on: true
                            },
                            on: { update: _vm.updateModel }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-4" },
                    [
                      _c(
                        "atbl-form-group",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            label: "Visibile for leads",
                            error: _vm.getError(
                              _vm.emailErrors,
                              "leads_enabled"
                            )
                          }
                        },
                        [
                          _c("atbl-switch", {
                            attrs: {
                              name: "leads_enabled",
                              value: !!_vm.emailModel.leads_enabled,
                              disabled: _vm.isLoading,
                              off: false,
                              on: true
                            },
                            on: { update: _vm.updateModel }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.userTypes, function(userType) {
                return _c(
                  "user-type",
                  { key: userType, attrs: { type: userType } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg-4",
                          class: {
                            "email-templates-internal-links":
                              _vm.emailModel.meta[userType].type === "Internal"
                          }
                        },
                        [
                          _c(
                            "atbl-form-group",
                            {
                              attrs: {
                                label: "Type",
                                error: _vm.getError(_vm.emailErrors, "type")
                              }
                            },
                            [
                              _c("atbl-select", {
                                attrs: {
                                  options: _vm.emailTypes,
                                  value: _vm.emailModel.meta[userType].type,
                                  disabled: _vm.isLoading,
                                  name: "type"
                                },
                                on: {
                                  update: function($event) {
                                    var i = arguments.length,
                                      argsArray = Array(i)
                                    while (i--) argsArray[i] = arguments[i]
                                    return _vm.updateType.apply(
                                      void 0,
                                      [userType].concat(argsArray)
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-4" },
                        [
                          _c(
                            "atbl-form-group",
                            {
                              attrs: {
                                label: "Source",
                                error: _vm.getError(
                                  _vm.emailErrors,
                                  "type_source"
                                )
                              }
                            },
                            [
                              _c("atbl-select", {
                                attrs: {
                                  options: Object.keys(
                                    _vm.emailModel.meta[userType].externalTypes
                                  ),
                                  value:
                                    _vm.emailModel.meta[userType].type_source,
                                  disabled:
                                    _vm.isLoading ||
                                    !_vm.emailModel.meta[userType].type ||
                                    _vm.emailModel.meta[userType].type ===
                                      "Internal",
                                  name: "type_source"
                                },
                                on: {
                                  update: function($event) {
                                    var i = arguments.length,
                                      argsArray = Array(i)
                                    while (i--) argsArray[i] = arguments[i]
                                    return _vm.updateSource.apply(
                                      void 0,
                                      [userType].concat(argsArray)
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-4" },
                        [
                          _c(
                            "atbl-form-group",
                            {
                              attrs: {
                                label: "Source Id",
                                error: _vm.getError(
                                  _vm.emailErrors,
                                  "source_id"
                                )
                              }
                            },
                            [
                              _c("atbl-input", {
                                attrs: {
                                  name: "source_id",
                                  value:
                                    _vm.emailModel.meta[userType].source_id,
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  update: function($event) {
                                    var i = arguments.length,
                                      argsArray = Array(i)
                                    while (i--) argsArray[i] = arguments[i]
                                    return _vm.updateUserTypeModel.apply(
                                      void 0,
                                      [userType].concat(argsArray)
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.emailModel.meta[userType].type === "Internal"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "email-templates-internal-links col-12 mb-3"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.languages, function(
                                  language,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "col-12 col-lg-4"
                                    },
                                    [
                                      _c(
                                        "atbl-form-group",
                                        {
                                          attrs: {
                                            label:
                                              "Internal " +
                                              language.label.toLowerCase() +
                                              " link",
                                            error: _vm.getError(
                                              _vm.emailErrors,
                                              "link"
                                            )
                                          }
                                        },
                                        [
                                          _c("atbl-input", {
                                            attrs: {
                                              name: "link_" + language.value,
                                              value:
                                                _vm.emailModel.meta[userType]
                                                  .links[language.value],
                                              disabled: _vm.isLoading,
                                              placeholder:
                                                "Enter " +
                                                language.label +
                                                " link"
                                            },
                                            on: {
                                              update: function($event) {
                                                var i = arguments.length,
                                                  argsArray = Array(i)
                                                while (i--)
                                                  argsArray[i] = arguments[i]
                                                return _vm.updateUserTypeInternalLinkModel.apply(
                                                  void 0,
                                                  [userType].concat(argsArray)
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "atbl-tabs",
                      {
                        attrs: {
                          variant: "tabs",
                          "active-tab": _vm.activeSourceType,
                          "header-classes":
                            "p-0 border-top-0 border-right-0 border-left-0 bg-transparent"
                        },
                        on: { "change-tab": _vm.updateActiveSourceType }
                      },
                      _vm._l(
                        _vm.emailModel.meta[userType].externalTypes,
                        function(item, index) {
                          return _c(
                            "atbl-tab",
                            {
                              key: index,
                              staticClass: "bg-lighter",
                              attrs: { title: index }
                            },
                            [
                              _c("meta-fields", {
                                attrs: {
                                  metas: item.meta,
                                  "source-type": index,
                                  "user-type": userType,
                                  "is-loading": _vm.isLoading
                                }
                              })
                            ],
                            1
                          )
                        }
                      ),
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }