<template>
    <c-sidebar-nav-dropdown name="Create New"
                            href="#"
                            fontIcon="fa fa-calendar-alt"
                            v-if="$canAny(permissions)"
                            :class="isRouteActive(routes, 'c-show')"
    >
        <c-sidebar-nav-item name="Shifts"
                            fontIcon="fa fa-calendar"
                            href="/shifts"
                            v-if="$can('Shifts - View Shifts List')"
                            :addLinkClasses="isRouteActive('create.new.shifts')"
        />
        <c-sidebar-nav-item name="Notes"
                            fontIcon="fa fa-sticky-note"
                            href="/notes"
                            v-if="$can('Notes - View Notes List')"
                            :addLinkClasses="isRouteActive('create.new.notes')"
        />
        <c-sidebar-nav-item name="Dispositions"
                            fontIcon="fa fa-clock"
                            href="/dispositions"
                            v-if="$can('Dispositions - View Dispositions List')"
                            :addLinkClasses="isRouteActive('create.new.dispositions')"
        />
    </c-sidebar-nav-dropdown>
</template>

<script>
    export default {
        name: "CreateNew",
        data: function(){
            return {
                permissions: [
                    "Shifts - View Shifts List",
                    "Notes - View Notes List",
                    "Dispositions - View Dispositions List"
                ],
                routes: [
                    "create.new.shifts",
                    "create.new.notes",
                    "create.new.dispositions"
                ]
            };
        }
    }
</script>