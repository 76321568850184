<template>
    <div class="ticket-number">
        <span class="d-block font-weight-bold w-100 text-center text-lg-left">Ticket Number:</span>
        <div class="ticket-number-group">
            <span v-if="getWithoutLastNumber.length > 0" v-for="item of getWithoutLastNumber">
                {{ item }}
            </span>
            <div class="ticket-number-picker">
                <c-dropdown :toggler-text="getLastGeneratedNumber" dropup>
                    <c-dropdown-item v-for="item of getPickerNumbers"
                                     :key="item"
                                     @click="changeNumber(item)"
                    >
                        {{ item }}
                    </c-dropdown-item>
                </c-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    import LinesPickerMixin from "../Mixins/LinesPicker";

    export default {
        name: "TicketNumber",
        mixins: [
            LinesPickerMixin
        ],
        props: {
            input: {},
            value: {},
            minRange: {
                type: Number,
                required: true
            },
            maxRange: {
                type: Number,
                required: true
            },
            maxNumbers: {
                type: Number,
                required: true
            }
        },
        data: function(){
            return {
                generatedNumbers: [],
                lastGeneratedNumber: 0
            };
        },
        computed: {
            getLastGeneratedNumber: function(){
                return this.lastGeneratedNumber.toString();
            },
            getPickerNumbers: function(){
                return [...Array(this.maxRange).keys()].map(i => i + this.minRange);
            },
            getWithoutLastNumber: function(){
                return this.generatedNumbers.slice(0, this.maxNumbers - 1);
            }
        },
        methods: {
            generateNumbers: function(){
                let generatedNumbers = this.generateRandomNumbers(this.maxRange, this.maxNumbers, true);
                this.generatedNumbers = generatedNumbers;
                this.lastGeneratedNumber = generatedNumbers[generatedNumbers.length - 1];
            },
            changeNumber: function(number){
                this.generatedNumbers = [
                    ...this.generatedNumbers.slice(0, this.maxNumbers - 1),
                    number
                ];
                this.lastGeneratedNumber = number;
            }
        },
        watch: {
            generatedNumbers: {
                handler: function(value){
                    this.$emit("input", value);
                },
                deep: true,
                immediate: true
            }
        },
        created: function(){
            this.generateNumbers();
        }
    }
</script>