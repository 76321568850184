var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "h1 text-muted text-right mb-4" }, [
        _c("i", { class: _vm.icon })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "h4 mb-0" },
        [
          !_vm.isLoading && !_vm.$slots.value
            ? _c("span", { domProps: { innerHTML: _vm._s(_vm.number) } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && !!_vm.$slots.value ? _vm._t("value") : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            staticClass: "left",
            attrs: { show: _vm.isLoading, type: "smaller" }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "small",
        { staticClass: "text-muted text-uppercase font-weight-bold" },
        [_vm._v(_vm._s(_vm.title))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }