import isString from '../utils/String/IsString';
import ucFirst from '../utils/String/UcFirst';

export default {
    methods: {
        isString,
        ucFirst,
        innerTrim: function (value) {
            return value.replace(/\s/g, '');
        },
        getChar: function (value, index = 0) {
            return value.charAt(index);
        },
        firstChar: function (value) {
            return this.getChar(value)
        },
        ucWords: function ucwords(str) {
            return (str + '')
                .replace(/^(.)|\s+(.)/g, function ($1) {
                    return $1.toUpperCase()
                })
        },
        uuidv4: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
    }
};