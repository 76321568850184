var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-players" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.data, function(item) {
        return item.condition
          ? _c("div", { key: item.index, staticClass: "col-sm-4 col-xl" }, [
              _c(
                "div",
                { staticClass: "callout", class: ["callout-" + item.color] },
                [
                  item.link &&
                  !_vm.isset(item, "with_deposits") &&
                  !_vm.isset(item, "without_deposits")
                    ? _c(
                        "a",
                        { attrs: { href: item.link, target: "_blank" } },
                        [
                          _c("Small", { staticClass: "text-muted" }, [
                            _vm._v(" " + _vm._s(item.title) + " ")
                          ])
                        ],
                        1
                      )
                    : _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  !_vm.isset(item, "with_deposits") &&
                  !_vm.isset(item, "without_deposits")
                    ? _c("strong", { staticClass: "h4" }, [
                        _vm._v(
                          "\n\t\t                " +
                            _vm._s(item.number) +
                            "\n\t                "
                        )
                      ])
                    : _c("div", [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: "With Deposits"
                                },
                                expression:
                                  "{\n\t                          content: 'With Deposits'\n\t                       }"
                              }
                            ],
                            staticClass: "mr-1 text-dark h4",
                            attrs: {
                              href: item.link + "&deposits=true",
                              target: "_blank"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-wallet text-success"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(item.with_deposits) +
                                "\n\t\t                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: "Without Deposits"
                                },
                                expression:
                                  "{\n\t                          content: 'Without Deposits'\n\t                       }"
                              }
                            ],
                            staticClass: "text-dark h4",
                            attrs: {
                              href: item.link + "&deposits=false",
                              target: "_blank"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-wallet text-danger"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(item.without_deposits) +
                                "\n\t\t                "
                            )
                          ]
                        )
                      ])
                ]
              )
            ])
          : _vm._e()
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-players-heading" }, [
      _c("p", { staticClass: "dashboard-players-title" }, [_vm._v(" Players ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }