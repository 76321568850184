var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-dropdown",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: {
            content: "Notifications",
            placement: "bottom-center"
          },
          expression:
            "{\n                 content: 'Notifications',\n                 placement: 'bottom-center'\n              }"
        }
      ],
      staticClass: "c-header-nav-items",
      attrs: { inNav: "", placement: "bottom-end", "add-menu-classes": "py-0" },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function() {
            return [
              !_vm.getNotificationsDataLoader
                ? _c("c-header-nav-link", [
                    _c("i", { staticClass: "fa fa-bell font-1x2" }),
                    _vm._v(" "),
                    _vm.unreadNotifications > 0
                      ? _c(
                          "span",
                          { staticClass: "badge badge-danger badge-pill" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.unreadNotifications) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("loader", {
                attrs: { show: _vm.getNotificationsDataLoader, type: "smaller" }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "c-dropdown-header",
        { staticClass: "text-center bg-primary text-white" },
        [_vm._v("\n      Notifications\n    ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.items, function(item, index) {
        return _vm.items.length > 0
          ? _c(
              "c-dropdown-item",
              {
                key: index,
                class: {
                  "bg-light": !item.read_at
                },
                attrs: { href: "/notifications?id=" + item.id }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      item.data.message
                        ? item.data.message
                        : "Message not found!"
                    ) +
                    "\n    "
                )
              ]
            )
          : _vm._e()
      }),
      _vm._v(" "),
      !_vm.items.length
        ? _c("c-dropdown-item", [
            _vm._v("\n      No notifications found\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "c-dropdown-header",
        { staticClass: "p-0 bg-light" },
        [
          _c(
            "c-dropdown-item",
            {
              staticClass: "justify-content-center",
              attrs: { href: "/notifications" }
            },
            [_vm._v("\n        View all notifications\n      ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }