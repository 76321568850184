<template>
    <div class="container-fluid">
        <div class="card card-search">
            <div class="card-header">
                <i class='fa fa-search' aria-hidden='false'></i> Syndicate filter
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-4 mb-2 m-lg-0">
                        <atbl-switch :off="false"
                                      :on="true"
                                      name="disabledOnly"
                                      v-model="disabledOnly"
                                      label="Show disabled only syndicates"
                        />
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button type="submit" class="btn btn-sm btn-primary" @click="fetchSyndicates">
                    <i class="fa fa-search"></i> Search
                </button>
            </div>
        </div>

        <div class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i class="fa fa-exchange-alt"></i> Syndicates
                </div>

                <button class="btn btn-sm btn-primary" @click="openCreateModal" v-if="$can('Syndicates - Create/Update')">
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col text-muted">Reference</div>
                        <div class="col text-muted">Title</div>
                        <div class="col text-muted">Game</div>
                        <div class="col text-muted">Lines</div>
                        <div class="col text-muted">Shares</div>
                        <div class="col text-muted">Share price</div>
                        <div class="col text-muted">Cut off at</div>
                        <div class="col text-muted">Recurring</div>
                        <div class="col text-muted text-center">Action</div>
                    </div>

                    <template v-for="syndicate in syndicates">
                        <div :key="syndicate.id" class="row">
                            <div class="col">
                                <a class="d-block" :href="`/syndicates/items/${syndicate.active_syndicate_item_id}/edit`" v-if="$can('Syndicates - View List')">
                                    <i class="fa fa-link"></i> {{ syndicate.active_syndicate_item_id }}
                                </a>
                                <span v-else>
                                    Id: {{ syndicate.active_syndicate_item_id }}
                                </span>
                            </div>
                            <div class="col">
                                {{ syndicate.title }}
                            </div>
                            <div class="col">
                                <img :src="$imageService.gameImage(syndicate.game.image)" style="width: 60px; height: auto;">
                            </div>
                            <div class="col">
                                {{ syndicate.lines_count }}
                            </div>
                            <div class="col">
                                {{ syndicate.active_syndicate_item.active_syndicate_item_draw.available_shares }} / {{ syndicate.active_syndicate_item.total_shares }}
                            </div>
                            <div class="col">
                                {{ syndicate.share_price | formatCurrency }}
                            </div>
                            <div class="col">
                                {{ syndicate.active_syndicate_item.active_syndicate_item_draw.cut_off_at }}
                            </div>
                            <div class="col">
                                {{ syndicate.is_recurring ? 'Yes' : 'No' }}
                            </div>
                            <div class="col text-center">
                                <a v-if="$can('Syndicates - Create/Update')" class="d-block" :href="`/syndicates/items/${syndicate.active_syndicate_item_id}/edit`">
                                    <small><i class="fa fa-user"></i> Edit syndicate</small>
                                </a>
                            </div>
                        </div>
                    </template>

                    <not-found v-if="! isLoading && ! syndicates.length" msg="No syndicates found" :items="syndicates" />

                    <loader :show="isLoading" type="small" />
                </div>
            </div>
        </div>

        <syndicates-create-modal v-if="isCreateModalVisible && $can('Syndicates - Create/Update')"
                                 v-on:cancel="isCreateModalVisible = false"
                                 v-on:confirm="storeSyndicate" />
    </div>
</template>

<script>
import SyndicatesCreateModal from './Modals/CreateModal';
import SyndicateService from '../../services/SyndicateService';

export default {
    components: { SyndicatesCreateModal },

    data() {
        return {
            isLoading: false,
            isCreateModalVisible: false,
            syndicates: [],
            disabledOnly: false,
        };
    },

    watch: {
        disabledOnly() {
            this.fetchSyndicates();
        },
    },

    mounted() {
        this.fetchSyndicates();
    },

    methods: {
        fetchSyndicates() {
            this.isLoading = true;
            this.syndicates = [];

            const params = {
                ...(this.disabledOnly && { disabledOnly: this.disabledOnly }),
            };

            SyndicateService
                .index(params)
                .then(response => {
                    this.syndicates = response.data;
                })
                .catch(err => {
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        storeSyndicate(data) {
            const params = {
                title: data.title,
                ...(! data.slug || data.slug.length && { slug: data.slug }),
                game: data.game,
                lines: data.linesCount,
                shares: data.shares,
                recurring: data.recurring,
                sharePrice: data.sharePrice,
                maxFutureDraws: data.maxFutureDraws,
            };

            SyndicateService
                .store(params)
                .then(response => {
                    this.isCreateModalVisible = false;
                    this.fetchSyndicates();
                })
                .catch(err => {
                    const errResponse = err.response;

                    if (errResponse.status === 422) {
                        const errors = [errResponse.data.message];

                        for (const [key, error] of Object.entries(errResponse.data.errors)) {
                            errors.push(error[0]);
                        }

                        window.flash(errors.join('<br />'), 'alert-danger');
                    }
                });
        },

        openCreateModal() {
            this.isCreateModalVisible = true;
        },
    },
}
</script>