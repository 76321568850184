import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.players.mutations.SET_FUNDS_AGENT](state, payload){
        state.fundsAgent = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_AMOUNT](state, payload){
        state.fundsAmount = Number(payload);
    },
    [TypesConst.players.mutations.SET_FUNDS_REFERENCE](state, payload){
        state.fundsReference = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_REASON](state, payload){
        state.fundsReason = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_META](state, { key, value }) {
        Object.assign(state.fundsMeta, {
            [key]: value,
        });
    },
    [TypesConst.players.mutations.SET_FUNDS_TRANSACTION_ID](state, payload){
        state.fundsTransactionId = Number(payload);
    },
    [TypesConst.players.mutations.SET_FUNDS_IS_FAILED_TRANSACTION](state, payload){
        state.fundsIsFailedTransaction = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_LOADER](state, payload){
        state.fundsLoader = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_TRANSACTION_TYPE](state, payload){
        state.fundsCredit = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_ADD_BALANCE](state, payload){
        state.fundsAddBalance = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_PAYMENT_LINK](state, payload){
        state.fundsPaymentLink = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_CONFIRM_MODAL](state, payload){
        state.fundsCreditConfirmModal = payload;
    },
    [TypesConst.players.mutations.SET_BONUS_CONFIRM_MODAL](state, payload){
        state.fundsBonusConfirmModal = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_CREDIT_CARD](state, payload){
        state.fundsCreditCard = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_CONFIRM_CREDIT_CARD_MODAL](state, payload){
        state.fundsCreditCardConfirmModal = payload;
    },
    [TypesConst.players.mutations.SET_FUNDS_CREDIT_CARD_ERRORS](state, payload){
        state.fundsCreditCardErrors = payload;
    },
};