import Campaigns from '../../../../../constants/Campaigns';

export default {
    title: null,
    description: null,
    status: 'Waiting',
    type: 'Phone',
    view_type: Campaigns.ViewTypes.callView,
    agents: [],
    tags: [],
    batch: null,
    csv: null,
    id: null,
}