<template>
  <div class="dashboard-calls">
    <div class="dashboard-calls-heading">
      <p class="dashboard-calls-title"> Calls </p>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-3">
        <div class="callout callout-primary">
          <small class="text-muted">Players Talk Time</small>
          <br /><strong class="h4">{{ data.durationPlayersToday }}</strong>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <div class="callout callout-primary">
          <small class="text-muted">Leads Talk Time</small>
          <br /><strong class="h4">{{ data.durationLeadsToday }}</strong>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <div class="callout callout-danger">
          <small class="text-muted">Players Calls Per Day</small>
          <br /><strong class="h4">{{ data.callsPlayersToday }}</strong>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <div class="callout callout-danger">
          <small class="text-muted">Leads Calls Per Day</small>
          <br /><strong class="h4">{{ data.callsLeadsToday }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sales",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
