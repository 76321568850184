<template>
    <atbl-modal :show="show"
             :close-on-backdrop="false"
             title="Remove Campaign"
             @close="toggleRemoveCampaignModal"
    >
        <h4 class="text-center">
            Do you really want to remove this campaign?
        </h4>

        <template #footer>
            <div class="w-50 text-right">
                <button class="btn btn-danger btn-sm"
                        @click="toggleRemoveCampaignModal"
                >
                    Close
                </button>
                <button v-if="$can('Campaigns - Remove')"
                        :class="{
                                'btn-primary': !isLoading,
                                'btn-outline-primary': isLoading
                            }"
                        :disabled="isLoading"
                        class="btn btn-sm"
                        @click="removeCampaign"
                >
                    <span v-if="!isLoading">
                        Confirm
                    </span>
                    <loader type="small"
                            :show="isLoading"
                    />
                </button>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'remove-campaign-modal',
    inject: [
        'removeCampaign',
        'toggleRemoveCampaignModal',
    ],
	components: {
		AtblModal,
	},
    props: {
        show: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
    },
}
</script>