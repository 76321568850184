<template>
    <atbl-modal title="Remove Attachment" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                Are you sure you want to delete this attachment?
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-danger" @click="confirm()">
                Delete
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'remove-kyc-attachment-modal',
	components: {
		AtblModal,
	},
    methods: {
        emitCloseEvent() {
            this.$emit('cancel');
        },

        confirm() {
            this.$emit('confirm');
        },
    }
}
</script>