<template>
    <atbl-modal size="lg"
                title="Add/Edit leads"
                :show="true"
                :close-on-backdrop="false"
                @input="toggleAddLeadModal"
    >
        <div v-if="!isLoading"
             class="row"
        >
            <div class="col-12 col-lg-4">
                <atbl-input label="Name *"
                            name="name"
                            :errors="errors"
                            :value="model.name"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-4">
                <atbl-input label="Surname"
                            name="surname"
                            :errors="errors"
                            :value="model.surname"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-4">
                <atbl-input label="Email"
                            name="email"
                            :errors="errors"
                            :value="model.email"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-4">
                <birthday-picker :value="model.birthdate"
                                 :error="getError(errors, 'birthdate')"
                                 :auto-apply="true"
                                 format="YYYY-MM-DD"
                                 @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-4">
                <atbl-input label="Address 1"
                            name="address1"
                            :errors="errors"
                            :value="model.address1"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-4">
                <atbl-input label="Address 2"
                            name="address2"
                            :errors="errors"
                            :value="model.address2"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-3">
                <countries-options :value="model.country"
                                   :error="errors"
                                   @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-3">
                <atbl-input label="State"
                            name="state"
                            :errors="errors"
                            :value="model.state"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-3">
                <atbl-input label="City"
                            name="city"
                            :errors="errors"
                            :value="model.city"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-3">
                <atbl-input label="Postcode"
                            name="postcode"
                            :errors="errors"
                            :value="model.postcode"
                            @update="updateModelField"
                />
            </div>
            <div class="col-12 col-lg-4">
                <tel-number label="Phone *"
                            id="Phone"
                            placeholder="Enter phone"
                            name="phone"
                            v-model="phone"
                            :error="errors"
                />
            </div>
            <div class="col-12 col-lg-4">
                <tel-number label="Mobile *(required if no phone)"
                            id="Mobile"
                            placeholder="Enter mobile"
                            name="mobile"
                            v-model="mobile"
                            :error="errors"
                />
            </div>
            <div class="col-12 col-lg-4">
                <atbl-number label="Live Spent"
                             name="live_spent"
                             :errors="errors"
                             :value="model.live_spent"
                             @update="updateModelField"
                />
            </div>
            <div class="col-12">
                <atbl-textarea label="Other"
                               name="other"
                               :errors="errors"
                               :value="model.other"
                               @update="updateModelField"
                />
            </div>
        </div>

        <loader :show="isLoading"
                type="small"
        />

        <template #footer>
            <div class="d-flex w-100 align-items-center">
                <div class="w-50 font-weight-bold">
                    * Required
                </div>
                <div class="w-50 text-right">
                    <button class="btn btn-sm btn-danger"
                            @click="toggleAddLeadModal"
                    >
                        Close
                    </button>
                    <button v-if="$can('Leads - Create/Edit')"
                            :class="{
                                'btn-primary': !isLoading,
                                'btn-outline-primary': isLoading,
                                'disabled': isLoading
                            }"
                            :disabled="isLoading"
                            class="btn btn-sm"
                            @click="onCreateUpdateLead"
                    >
                        <span v-if="!isLoading"
                        >
                            Save
                        </span>
                        <loader type="small"
                                :show="isLoading"
                        />
                    </button>
                </div>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import BirthdayPicker from '../../../../components/Forms/Select/BirthdayPicker';
import CountriesOptions from '../../../../components/Forms/Select/CountriesOptions';
import LeadModel from './LeadModel';
import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'add-leads-modal-component',
    inject: [
        'onCreateUpdateLead',
        'updateModelField',
        'toggleAddLeadModal',
    ],
    components: {
        CountriesOptions,
        BirthdayPicker,
        AtblModal,
    },
    props: {
        model: {
            type: Object,
            default: () => (LeadModel)
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        isLoading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        phone: {
            get() {
                return this.model.phone;
            },
            set(value) {
                this.updateModelField('phone', value);
            }
        },
        mobile: {
            get() {
                return this.model.mobile;
            },
            set(value) {
                this.updateModelField('mobile', value);
            }
        },
    },
}
</script>