var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.campaignLoader
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-5" },
              [
                _c("campaign-form-edit-component", {
                  attrs: {
                    "campaign-id": _vm.id,
                    "is-loading": _vm.saveCampaignLoading,
                    "campaign-model": _vm.campaignModel,
                    "campaign-model-errors": _vm.campaignModelErrors
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-md-7" },
              [
                !!_vm.id
                  ? _c("campaigns-leads-list-edit-component", {
                      attrs: {
                        "filter-list-model": {
                          filterListLoader: _vm.filterListLoader,
                          filtersList: _vm.filtersList
                        },
                        "in-campaign-leads-response":
                          _vm.campaignLeadsListResponse,
                        "in-campaign-leads-loader":
                          _vm.campaignLeadsListLoader ||
                          _vm.addLeadsSidebarLoading
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: !!_vm.campaignLoader, type: "small" } }),
      _vm._v(" "),
      _vm.$can("Campaigns - Add/Remove Leads")
        ? _c("add-customers-sidebar-component", {
            attrs: {
              show: _vm.addLeadsSidebarShow,
              "leads-list-response": _vm.leadsOutOfCampaignResponse,
              "leads-list-loader":
                _vm.leadsOutOfCampaignLoader || _vm.addLeadsSidebarLoading,
              "leads-list-model": _vm.leadsOutOfCampaignSearchModel
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("confirm-add-all-customers-in-campaign-component", {
        attrs: {
          show: _vm.addAllLeadsInCampaignModalOpen,
          inCampaign: _vm.leadsOutOfCampaignSearchModel.inCampaign,
          "is-loading":
            _vm.addAllLeadsInCampaignModalLoading ||
            _vm.leadsOutOfCampaignLoader ||
            _vm.addLeadsSidebarLoading,
          "leads-count": _vm.allLeadsCount
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }