var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold mb-0",
      attrs: {
        horizontal: _vm.horizontal,
        label: "Date Type",
        "label-for": "Date Type"
      }
    },
    [
      _c("tree-select", {
        attrs: {
          multiple: true,
          searchable: true,
          "clear-on-select": true,
          options: _vm.dateTypes,
          disabled: !!_vm.disabled,
          normalizer: function(node) {
            return { id: node.key, label: node.name }
          },
          placeholder: "Select Date Type"
        },
        model: {
          value: _vm.selectedDateTypes,
          callback: function($$v) {
            _vm.selectedDateTypes = $$v
          },
          expression: "selectedDateTypes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }