import MarketingService from '../../../../../services/MarketingService';

export default {
    provide() {
        return {
            removeCampaign: this.removeCampaign,
            toggleRemoveCampaignModal: this.toggleRemoveCampaignModal,
        };
    },
    data() {
        return {
            confirmRemoveCampaignModalOpen: false,
            isRemoveCampaignLoading: false,
            activeRemoveCampaignId: null,
            activeRemoveCampaignType: null,
        };
    },
    methods: {
        async removeCampaign() {
            if (!this.$can('Campaigns - Remove')) {
                window.showMessage('ou does not have permissions for this action.', false);
                return;
            }

            this.isRemoveCampaignLoading = true;

            try {
                const response = await MarketingService.removeCampaign(this.activeRemoveCampaignType, this.activeRemoveCampaignId);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.toggleRemoveCampaignModal();

                    await this.fetchCampaigns();
                }

                this.isRemoveCampaignLoading = false;
            } catch (error) {
                this.isRemoveCampaignLoading = false;

                window.flashError(error);
            }
        },
        toggleRemoveCampaignModal(campaignType = null, campaignId = null) {
            this.confirmRemoveCampaignModalOpen = !this.confirmRemoveCampaignModalOpen;
            this.activeRemoveCampaignType = campaignType;
            this.activeRemoveCampaignId = campaignId;
        },
    },
}