<template>
    <div class="configuration-winning-prizes-item"
         :class="{
             editable: !disabled && !!numberType.hasOwnProperty('editable')
         }"
    >
        <a v-if="!disabled && isEditable"
           href="#"
           class="add-field"
           @click.prevent="showForm = true"
        >
            <i class="fa fa-plus"></i>
        </a>
        <a v-if="!disabled && !isEditable && isChild && !numberType.is_refund"
           href="#"
           class="remove-field"
           @click.prevent="onDeleteFieldEvent"
        >
            <i class="fa fa-times"></i>
        </a>
        <div class="configuration-winning-prizes-title"
             :class="{
                 editable: !disabled && !!numberType.hasOwnProperty('editable')
             }"
        >
            <h6 class="my-2">{{ numberType.title }}</h6>
            <span v-if="isEditable && !disabled">{{ numberType.key }}</span>
        </div>
        <input :id="numberType.id"
               class="configuration-text-input"
               :value="numberType.value"
               :disabled="disabled"
               :class="{
                                'input-has-error': !!numberType.error
                           }"
               @input="changeNumber($event, index, numberTypeIndex, numberType)"
        />
        <raffle-configuration-form-prize-winner-item v-if="!!numberType.editable && showForm"
                                                     :number-type="numberType"
                                                     :number-index="numberTypeIndex"
                                                     @update="onUpdateConfigurationEvent"
                                                     @close="showForm = false"
        />
    </div>
</template>

<script>
import DrawsEditorMixins from '../../../Mixins/DrawsEditor';
import RaffleConfigurationFormPrizeWinnerItem from './RaffleConfigurationFormPrizeWinnerItem';

export default {
    name: 'RaffleEditorPrizeWinnerItem',
    emits: [
        'update-configuration',
        'remove-field-configuration',
        'change',
    ],
    components: {
        RaffleConfigurationFormPrizeWinnerItem,
    },
    mixins: [
        DrawsEditorMixins
    ],
    props: {
        numberType: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        index: {
            default: false
        },
        numberTypeIndex: {
            default: false
        }
    },
    data() {
        return {
            showForm: false,
        };
    },
    computed: {
        isEditable() {
            return !!this.numberType.hasOwnProperty('editable') && !!this.numberType.editable;
        },
        isChild() {
            return !!this.numberType.hasOwnProperty('child') && !!this.numberType.child;
        },
    },
    methods: {
        changeNumber(event, workingKey, numberTypeIndex, item) {
            this.$emit('change', event, workingKey, numberTypeIndex, item);
        },
        onUpdateConfigurationEvent(value) {
            this.$emit('update-configuration', value);

            this.showForm = false;
        },
        onDeleteFieldEvent() {
            this.$emit('remove-field-configuration', this.numberType);
        },
    }
}
</script>