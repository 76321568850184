<template>
    <div class="container-fluid">
        <search-form-component v-if="showSearchForm"
                               :model="searchFormModel"
                               :loading="searchFormLoader"
        />

        <div class="card card-list">
            <div class="card-header d-flex flex-wrap align-items-center justify-content-between">
                <span><i class='fa fa-key'></i> Permissions</span>
                <div class="d-flex align-items-center flex-wrap mt-2 mt-sm-0">
                    <sort-by v-model="changeSortBy"
                             :options="sortByOptions"
                             class="mr-2"
                    />
                    <a href="#"
                       class="btn btn-sm mr-2"
                       :class="{
                           'btn-primary': !showSearchForm,
                           'btn-outline-primary': !!showSearchForm,
                       }"
                       @click.prevent="showSearchForm = !showSearchForm"
                    >
                        <i class="fa fa-filter m-0"></i>
                    </a>
                    <a v-if="$can('Permissions - Create/Update')"
                       href="/permissions/create"
                       class="btn btn-sm btn-primary"
                    >
                        Create
                    </a>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-1">Id</div>
                        <div class="col-12 col-lg">Name</div>
                        <div class="col-12 col-lg-2">Roles</div>
                        <div class="col-12 col-lg-2">Users</div>
                        <div class="col-12 col-lg-2"
                             v-if="$canAny(permissionsOperationPermissions)"
                        >
                            Operations
                        </div>
                    </div>

                    <div v-if="!isLoading"
                         v-for="(item, index) in permissions"
                         :key="index"
                         class="row"
                    >
                        <div class="col-12 col-lg-1 mb-1 mb-lg-0">
                            <span class="small-mb-badge">Id</span>
                            {{ item.id }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Name</span>
                            {{ item.name }}
                        </div>
                        <div class="col-12 col-lg-2 mb-1 mb-lg-0">
                            <span class="small-mb-badge">Roles</span>
                            {{ item.roles_count }}
                        </div>
                        <div class="col-12 col-lg-2 mb-1 mb-lg-0">
                            <span class="small-mb-badge">Users</span>
                            {{ item.users_count }}
                        </div>
                        <div class="col-12 col-lg-2"
                             v-if="$canAny(permissionsOperationPermissions)"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <div class="d-flex align-items-center">
                                <a v-if="$can('Permissions - Create/Update')"
                                   :href="`/permissions/${item.id}/update`"
                                   class="btn btn-link btn-delete btn-sm mr-2"
                                >
                                    <i class="fa fa-pencil-alt"></i>
                                </a>
                                <remove-list-item-button v-if="$can('Permissions - Remove')"
                                                         :value="item.id"
                                                         @confirmed="onDeletePermission"
                                />
                            </div>
                        </div>
                    </div>

                    <not-found v-if="!isLoading"
                               :items="permissions"
                               msg="No permissions found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>

            <div v-if="!isLoading && permissionsData.last_page > 1"
                 class="card-footer overflow-x-auto"
            >
                <nav class="wrap">
                    <atbl-pagination :pagination="permissionsData"
                                     @paginate="fetchPermissions"
                    />
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import ItemsNotFound from '../../../components/Lists/ItemsNotFound';
import Loader from '../../../components/Widgets/Loader';
import RemoveListItemButton from '../../../components/Lists/RemoveListItemButton';
import { clone } from '../../../utils/Json/Json';
import PaginationModel from '../../../modules/pagination/PaginationModel';
import SearchFormComponent from './SearchForm/SearchFormComponent';
import SearchForm from './SearchForm/SearchForm';
import PermissionsService from '../../../services/PermissionsService';
import DeletePermission from './DeletePermission';
import SortBy from '../../../components/SortBy/SortBy';

export default {
    name: 'permissions-page-component',
    mixins: [
        SearchForm,
        DeletePermission,
    ],
    components: {
        SortBy,
        SearchFormComponent,
        RemoveListItemButton,
        ItemsNotFound,
        Loader,
    },
    provide() {
        return {
            fetchPermissions: this.fetchPermissions,
        };
    },
    data() {
        return {
            showSearchForm: true,

            permissions: [],
            permissionsData: clone(PaginationModel),
            page: 1,
            permissionLoader: false,
            permissionsOperationPermissions: [
                'Permissions - Create/Update',
                'Permissions - Remove'
            ],

            sortByOptions: [
                {
                    value: 'id',
                    label: 'Id',
                },
                {
                    value: 'name',
                    label: 'Name',
                },
                {
                    value: 'roles_count',
                    label: 'Roles',
                },
                {
                    value: 'users_count',
                    label: 'Users',
                }
            ],
        }
    },
    computed: {
        isLoading() {
            return this.permissionLoader || this.deletePermissionLoader;
        },
        changeSortBy: {
            get() {
                return this.searchFormModel.sortBy;
            },
            set(value) {
                this.searchFormModel.sortBy = value;

                this.fetchPermissions();
            }
        },
    },
    async created() {
        await this.fetchPermissions();
    },
    methods: {
        async fetchPermissions(page = 1) {
            if (!this.$can('Permissions - View')) {
                return window.showMessage('No permissions for this action', false);
            }

            this.page = page;

            this.permissionLoader = true;

            try {
                const response = await PermissionsService.getAllPermissions({
                    params: {
                        ...this.searchFormModel,
                        page,
                    },
                });

                this.permissions = response.data.data;
                this.permissionsData = response.data;

                this.permissionLoader = false;
            } catch (error) {
                this.permissionLoader = false;

                window.flashError(error);
            }
        },
    }
}
</script>
