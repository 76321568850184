var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$canAny(_vm.permissions)
    ? _c(
        "c-sidebar-nav-dropdown",
        {
          class: _vm.isRouteActive(_vm.routes, "c-show"),
          attrs: {
            name: "Create New",
            href: "#",
            fontIcon: "fa fa-calendar-alt"
          }
        },
        [
          _vm.$can("Shifts - View Shifts List")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Shifts",
                  fontIcon: "fa fa-calendar",
                  href: "/shifts",
                  addLinkClasses: _vm.isRouteActive("create.new.shifts")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Notes - View Notes List")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Notes",
                  fontIcon: "fa fa-sticky-note",
                  href: "/notes",
                  addLinkClasses: _vm.isRouteActive("create.new.notes")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("Dispositions - View Dispositions List")
            ? _c("c-sidebar-nav-item", {
                attrs: {
                  name: "Dispositions",
                  fontIcon: "fa fa-clock",
                  href: "/dispositions",
                  addLinkClasses: _vm.isRouteActive("create.new.dispositions")
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }