<template>
    <atbl-form-group label="Gateway"
                     label-for="gateway"
                     class="font-weight-bold"
                     :horizontal="false"
    >
        <select v-model="selectedGateway"
                class="form-control"
                id="gateway"
                :disabled="isLoading"
        >
            <option v-if="!!defaultItem"
                    :value="null"
            >
                Select option
            </option>
            <option v-for="(gateway, index) in availableGateways"
                    :key="index"
                    :value="gateway.value"
            >
                {{ gateway.label }}
            </option>
        </select>
    </atbl-form-group>
</template>

<script>
import PaymentService from '../../../services/PaymentService';

export default {
    name: 'GatewaySelect',
    emits: [
        'input',
        'update',
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: 'gateway'
        },
        defaultItem: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            availableGateways: [],
            isLoading: false,
        };
    },
    computed: {
        selectedGateway: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            },
        },
    },
    async created() {
        await this.geGatewaysList();
    },
    methods: {
        async geGatewaysList() {
            this.isLoading = true;

            try {
                const response = await PaymentService.fetchPaymentGateways();

                this.availableGateways = response.data.map(item => {
                    return {
                        value: item.gateway.key,
                        label: item.gateway.name
                    };
                });

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashBadResponse(error);
            }
        },
    },
}
</script>