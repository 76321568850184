<template>
    <atbl-modal :title="`${!isEdit ? 'Update' : 'Create'} User`"
             :show="show"
             v-if="$can('Users - Create/Update')"
             :close-on-backdrop="false"
             @close="handleCancel"
             :class="{
                 'hide-footer': isGetPlayerLoading
             }"
    >
        <template v-if="!isGetPlayerLoading">
            <div v-if="$can('Users - Create/Update')"
                 class="mb-4"
            >
                <h5 class="base-heading">General</h5>
                <div class="row">
                    <div class="col-6">
                        <atbl-input label="Name"
                                    name="name"
                                    :errors="errors"
                                    v-model.trim="user.name"
                        />
                    </div>

                    <div class="col-6">
                        <atbl-input label="Email"
                                    name="email"
                                    :errors="errors"
                                    v-model.trim="user.email"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <atbl-input label="Password"
                                    type="password"
                                    name="password"
                                    :errors="errors"
                                    v-model.trim="user.password"
                        />
                    </div>
                    <div class="col-6">
                        <atbl-input label="Repeat Password"
                                    type="password"
                                    name="password_confirmation"
                                    :errors="errors"
                                    v-model.trim="user.password_confirmation"
                        />
                    </div>
                </div>
            </div>
            <div v-if="$can('Users - Assign Shift')"
                 class="mb-4"
            >
                <h5 class="base-heading">Shift</h5>
                <div class="row">
                    <div class="col">
                        <shifts-picker v-model="user.shift_id"/>
                    </div>
                </div>
            </div>
          <div v-if="$can('User - Can Assign Commission Plan')"
               class="mb-4"
          >
            <h5 class="base-heading">Commission plans</h5>
            <div>
              <label v-for="(plan, index) in commissionPlans"
                     :key="index"
                     :for="plan.label"
                     class="mr-1 font-weight-bold"
              >
                <input :id="plan.label"
                       :checked="selectedCommissionPlan === plan.value"
                       type="radio"
                       :value="plan.value"
                       @change="selectPlan(plan.value)"
                />
                {{ plan.label }}
              </label>
              <loader :show="isLoadingCommissionsPlanList"
                      type="smaller"
              />
            </div>
            </div>
            <div v-if="$canAny(['User - Can Assign Roles', 'User - Can Assign Permissions'])">
                <h5 class="base-heading">
                    Roles and Permissions
                </h5>
                <div v-if="$can('User - Can Assign Roles')" class="row">
                    <div class="col-12">
                        <atbl-form-group label="Roles">
                          <div v-if="!isLoadingRolesList"
                               class="d-flex align-items-center flex-wrap"
                          >
                            <atbl-check-box v-for="(item, index) in roles"
                                            :key="index"
                                            :checked="selected.includes(item.value)"
                                            :label="item.text"
                                            class="mr-1"
                                            @input="selectRole(item)"
                            />
                          </div>
                            <loader :show="isLoadingRolesList"
                                    type="smaller"
                            />
                        </atbl-form-group>
                    </div>
                </div>
                <div v-if="$can('User - Can Assign Permissions')" class="row">
                    <div class="col">
                        <multi-permissions-picker :role-permissions="rolePermissions"
                                                  :disabled="isPermissionsLoading"
                                                  v-model="user.permissions"
                                                  label="Direct permissions"
                        />
                    </div>
                </div>
            </div>
        </template>
        <loader :show="isGetPlayerLoading"
                type="small"
        />

        <template #footer>
            <button class="btn btn-sm btn-light"
                      @click="handleCancel"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                      @click="createUpdateUser"
            >
                <span v-if="!isLoading">{{ isEdit ? 'Save' : 'Create' }}</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>

import PermissionsService from '../../../services/PermissionsService';
import MultiPermissionsPicker from '../../../components/Forms/Select/MultiPermissionsPicker';
import ShiftsPicker from '../../../components/Forms/Select/ShiftsPicker';
import AtblCheckBox from '../../../components/Forms/Inputs/AtblCheckbox.vue';
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'CreateUpdateUserForm',
    components: {
	    AtblCheckBox,
        ShiftsPicker,
        MultiPermissionsPicker,
        AtblModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        currentUserId: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            roles: [],
            commissionPlans: [],
            selected: [],
            selectedCommissionPlan: null,
            user: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                roles: [],
                commission_plan_id: null,
                permissions: [],
                shift_id: null,
            },
            errors: {},
            rolePermissions: [],
            isLoading: false,
            isLoadingCommissionsPlanList: false,
            isLoadingRolesList: false,
            isPermissionsLoading: false,
            isGetPlayerLoading: false,
        };
    },
    computed: {
        isEdit () {
            return !!this.currentUserId;
        }
    },
    methods: {
        getUserById (id) {
            this.isGetPlayerLoading = true;
            window.axios.get('/user/' + id)
                .then(response => {
                    this.user = response.data;
                    this.selected = !!this.user.roles.length
                        ? this.user.roles.map(item => item.id)
                        : [];
                    this.user.permissions = !!response.data.permissions.length
                        ? response.data.permissions.map(({ id, name }) => {
                            return name;
                        })
                        : [];
                    this.selectPlan(this.user.commission_plan_id);
                })
                .finally(() => {
                    this.isGetPlayerLoading = false;
                });
        },
        createUpdateUser (event) {
            if (!this.$can('Users - Create/Update')) {
                window.showMessage('Unauthorised action.', false);

                return;
            }

            event.preventDefault();

            const model = {
                name: this.user.name,
                email: this.user.email,
                password: this.user.password,
                password_confirmation: this.user.password_confirmation,
                roles: this.roles.filter(item => this.selected.includes(item.value)).map(item => item.text),
                permissions: this.user.permissions,
                commissionPlan: this.selectedCommissionPlan || this.commissionPlans[0].value.toString(),
                shift_id: this.user.shift_id,
            };

            this.isLoading = true;

            const operation = this.isEdit
                ? window.axios.patch(`/user/${this.currentUserId}`, model)
                : window.axios.post('/user', model);

            operation.then(() => {
                    this.handleCancel();
                    this.$emit('reload');
                })
                .catch(error => {
                    this.errors = error.response.data.errors
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        fetchRoles () {
            if (!this.$can('Roles - Get List')) {
                return;
            }

            this.isLoadingRolesList = true;
            this.isPermissionsLoading = true;
            window.axios.get('/list-roles/')
                .then(response => {
                    this.roles = response.data;
                    this.fetchRolePermissions();
                })
                .finally(() => {
                    this.isLoadingRolesList = false;
                    this.isPermissionsLoading = false;
                });
        },
        fetchRolePermissions () {
            if (!this.$can('Permissions - Get List')) {
                return;
            }

            const selectedRoles = this.roles
                .filter(item => this.selected.includes(item.value))
                .map(item => item.text);

            this.isPermissionsLoading = true;
            PermissionsService.getPermissionsByRole(selectedRoles)
                .then(response => {
                    this.rolePermissions = response.data;
                })
                .finally(() => {
                    this.isPermissionsLoading = false;
                });
        },
        fetchCommissionPlans () {
            if (!this.$can('Commission Plans - Get List')) {
                return;
            }

            if(this.commissionPlans.length) {
                return;
            }

            this.isLoadingCommissionsPlanList = true;

            window.axios.get('/list-commission-plans')
                .then((response) => {
                    this.commissionPlans = response.data;
                })
                .finally(() => {
                    this.isLoadingCommissionsPlanList = false;
                });
        },
        selectRole (value) {
            if (this.selected.includes(value.value)) {
                this.selected = this.selected.filter(item => item !== value.value);
            } else {
                this.selected = [
                    ...this.selected,
                    value.value
                ];
            }

            if (!!this.selected.length) {
                this.fetchRolePermissions(this.selected);
            } else {
                this.rolePermissions = [];
            }
        },
        selectPlan (value) {
	        this.selectedCommissionPlan = value.toString();
        },
        handleCancel () {
            this.$emit('cancel');
            this.errors = {};
            this.clearInputs();
        },
        clearInputs () {
            this.user = {
                name: null,
                email: null,
                password: null,
                password_confirmation: null,
                roles: [],
                permissions: [],
            };
            this.selectedCommissionPlan = null;
            this.selected = [];
        },
    },
    watch: {
        show (value) {
            if (value) {
                if (this.currentUserId) {
                    this.getUserById(this.currentUserId);
                }
                this.fetchRoles();
                this.fetchCommissionPlans();
            }
        }
    }
}
</script>