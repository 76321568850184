var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        "header-bg-color": "light",
        "footer-class": "justify-content-between",
        show: _vm.showModal,
        "close-on-backdrop": false,
        "no-close-on-esc": true,
        size: "lg"
      },
      on: {
        close: function($event) {
          _vm.showModal = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _vm.game.game
                ? _c("GameHeader", {
                    attrs: { game: _vm.game, "is-from-confirm": true }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("h4", { staticClass: "m-0" }, [
                _vm._v("Are you sure you want to save this game draw?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  !_vm.loading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-danger",
                          on: {
                            click: function($event) {
                              return _vm.handleCancel()
                            }
                          }
                        },
                        [_vm._v("\n                Cancel\n            ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("Game Draws Edit") && !_vm.loading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-success",
                          on: { click: _vm.handleOK }
                        },
                        [_vm._v("\n                Confirm\n            ")]
                      )
                    : _c("loader", {
                        attrs: { show: _vm.loading, type: "small" }
                      })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._l(_vm.configurationTemp, function(numberTypes, index) {
        return _c(
          "div",
          { key: index, staticClass: "configuration" },
          [
            _c("raffle-editor-item", {
              attrs: {
                index: index,
                "number-types": numberTypes,
                "configuration-temp": _vm.configurationTemp,
                disabled: true
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }