<template>
    <div>
        <loader type="smaller"
                :show="isLoading"
        />
        <button v-if="!isLoading"
                class="btn btn-sm btn-link"
                @click="onDownloadEvent"
        >
            <i class="fa fa-download" aria-hidden="false"></i>
        </button>
        <button v-if="!isLoading"
                class="btn btn-sm btn-link"
                @click="onPlayEvent"
        >
            <i class="fa fa-play" aria-hidden="false"></i>
        </button>
    </div>
</template>

<script>
import Download from '../../../modules/download/Download';
import MarketingService from '../../../services/MarketingService';
import getValidationErrors from '../../../utils/Error/GetValidationErrors';
import parseXmlToJson from '../../../utils/Xml/XmlToJson';

export default {
    name: 'recording-audio',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            url: null,
            isLoading: false,
            message: null,
        };
    },
    methods: {
        async getRecording() {
            this.isLoading = true;

            try {
                const response = await MarketingService.downloadRecording({
                    sid: this.item.sid
                });
                const data = response.data;

                if (!!this.isset(data, 'status') && !data.status) {
                    const message = !!this.isset(data, 'errors') && data.errors.type === 'not_found'
                        ? 'Recorded audio cannot be found.'
                        : data.message

                    this.$emit('error-message', message);

                    this.isLoading = false;

                    return;
                }

                const url = response.data.url;

                const { status, message } = await this.checkIfFileExists(url);

                if (!status) {
                    this.isLoading = false;

                    window.showMessage(message, status);

                    return;
                }

                this.url = url;

                this.isLoading = false;

                return true;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);

                return false;
            }
        },
        async checkIfFileExists(url = null) {
            if (!url) {
                return;
            }

            try {
                const response = await fetch(url);

                if (!response.ok) {
                    const data = parseXmlToJson(await response.text());
                    const message = !response.ok
                        ? data.TwilioResponse.RestException.Message
                        : null;

                    return {
                        status: response.ok,
                        message,
                    };
                }

                return {
                    status: true,
                    message: null,
                };
            } catch ({ message }) {
                return {
                    status: false,
                    message,
                };
            }
        },
        async onPlayEvent() {
            if (!!this.url) {
                await this.play();

                return;
            }

            const status = await this.getRecording();

            if (!status) {
                return;
            }

            await this.play();
        },
        async onDownloadEvent() {
            if (!!this.url) {
                await Download.fileFromUrl(this.url);

                return;
            }

            const status = await this.getRecording();

            if (!status) {
                return;
            }

            await Download.fileFromUrl(this.url);
        },
        async play() {
            await this.$emit('play', this.url);
        },
    },
}
</script>