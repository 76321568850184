var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configuration-form mt-2" }, [
    _c("span", { staticClass: "font-weight-bold text-secondary" }, [
      _vm._v(_vm._s(_vm.getResolvedKey))
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("atbl-select", {
          staticClass: "mb-1",
          attrs: { options: _vm.matchTypes, name: "key" },
          model: {
            value: _vm.modelKey,
            callback: function($$v) {
              _vm.modelKey = $$v
            },
            expression: "modelKey"
          }
        }),
        _vm._v(" "),
        !!_vm.getKeyObject &&
        _vm.getKeyObject.hasOwnProperty("min") &&
        _vm.getKeyObject.hasOwnProperty("max")
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.modelValue,
                  expression: "modelValue"
                }
              ],
              staticClass: "configuration-text-input",
              attrs: {
                placeholder:
                  "Number between " +
                  _vm.getKeyObject.min +
                  " and " +
                  _vm.getKeyObject.max,
                min: _vm.getKeyObject.min,
                max: _vm.getKeyObject.max,
                type: "number"
              },
              domProps: { value: _vm.modelValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.modelValue = $event.target.value
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: { disabled: !_vm.isValid },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onSaveEvent.apply(null, arguments)
              }
            }
          },
          [_c("i", { staticClass: "fa fa-check" }), _vm._v(" Save\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onCloseEvent.apply(null, arguments)
              }
            }
          },
          [_c("i", { staticClass: "fa fa-times" }), _vm._v(" Close\n        ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }