var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Preview Attachment",
        show: true,
        size: "lg",
        centered: ""
      },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary float-right",
                  on: {
                    click: function($event) {
                      return _vm.emitCloseEvent()
                    }
                  }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary float-right mr-2",
                  attrs: { href: _vm.downloadUrl, target: "_blank" }
                },
                [_vm._v("\n            Download\n        ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("a", { attrs: { href: _vm.previewUrl, target: "_blank" } }, [
        _vm.isImage
          ? _c("img", {
              staticClass: "img-fluid w-100",
              attrs: { src: _vm.previewUrl, alt: "image" }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.isImage
        ? _c("p", [
            _vm._v(
              "File can not be previewed, click on the link bellow to open in new tab"
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isImage
        ? _c("a", { attrs: { href: _vm.previewUrl, target: "_blank" } }, [
            _vm._v(_vm._s(_vm.previewUrl))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }