var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid game-draws" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-5" },
        [
          _c(
            "atbl-tabs",
            [
              _vm.$can("Game Draws - Drawn Games")
                ? _c(
                    "atbl-tab",
                    { attrs: { title: "Drawn Games" } },
                    [_c("drawn-games-component")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Game Draws - Current Draws")
                ? _c(
                    "atbl-tab",
                    { attrs: { title: "Current Draws" } },
                    [_c("current-draws-component")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-lg-7" },
        [
          _vm.$canAny([
            "Game Draws - Drawn Games",
            "Game Draws - Current Draws"
          ])
            ? _c("draws-editor")
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }