var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-header",
    { attrs: { fixed: "", "with-subheader": "", light: "" } },
    [
      _c("c-toggler", {
        staticClass: "ml-1 d-lg-none",
        attrs: { "in-header": "" },
        on: { click: _vm.setShowSidebarMobile }
      }),
      _vm._v(" "),
      _c("c-toggler", {
        staticClass: "ml-1 d-md-down-none",
        attrs: { "in-header": "" },
        on: { click: _vm.setShowSidebarDesktop }
      }),
      _vm._v(" "),
      _c(
        "c-header-nav",
        { staticClass: "mr-auto" },
        [
          _c(
            "c-header-nav-item",
            { staticClass: "px-3" },
            [
              _c("c-header-nav-link", { attrs: { href: "/" } }, [
                _c("span", { staticClass: "d-md-down-none" }, [
                  _vm._v("Dashboard")
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-tachometer-alt font-xl d-lg-none"
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-header-nav",
        { staticClass: "mr-2" },
        [
          _vm.$can("Users - Get Notifications")
            ? _c("notifications")
            : _vm._e(),
          _vm._v(" "),
          _c("HeaderAccount", { attrs: { name: _vm.name, token: _vm.token } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }