var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid tabs-atbl" },
    [
      _c("GamesHeader"),
      _vm._v(" "),
      _c(
        "atbl-tabs",
        { attrs: { "active-tab": 0 } },
        [
          _vm.$can("Payon - Funds Deposit Agent")
            ? _c(
                "atbl-tab",
                { attrs: { title: "Games" } },
                [_c("ReportsGames")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "atbl-tab",
            { attrs: { title: "Agents" } },
            [_c("ReportsAgents")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }