<template>
    <div class="card card-list">
        <div class="card-header">
            <i class="fa fa-chart-line"></i> Statistics
        </div>

        <div class="card-body px-3 pb-0 d-flex align-items-center"
             :class="{
					    'pt-3': !isLoading
		             }"
        >
            <div v-if="!isLoading"
                 class="w-100"
            >
                <div class="row mb-3">
                    <div class="col-12 col-lg-6 col-xl-4 mb-2 mb-md-0">
                        <h5 class="text-secondary text-uppercase">Live Spent</h5>
                        <div class="callout callout-success mb-0">
                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                <small class="text-muted">Amount</small>
                                <span class="h5">
									{{ formatCurrency(player.live_spent) }}
								</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 mb-2 mb-md-0">
                        <h5 class="text-secondary text-uppercase">Live Deposit</h5>
                        <div class="callout callout-success mb-0">
                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                <small class="text-muted">Amount</small>
                                <span class="h5">
									{{ formatCurrency(player.live_deposit) }}
								</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 mb-2 mb-md-0">
                        <h5 class="text-secondary text-uppercase">Live Refund</h5>
                        <div class="callout callout-success mb-0">
                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                <small class="text-muted">Amount</small>
                                <span class="h5">
									{{ formatCurrency(player.live_refund) }}
								</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 col-lg-6 col-xl-4 mb-2 mb-md-0">
                        <h5 class="text-secondary text-uppercase">Total Win</h5>
                        <div class="callout callout-success mb-0">
                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                <small class="text-muted">Amount</small>
                                <span class="h5">
									{{ formatCurrency(player.total_win) }}
								</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 mb-2 mb-md-0">
                        <h5 class="text-secondary text-uppercase">Total Withdrawal</h5>
                        <div class="callout callout-success mb-0">
                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                <small class="text-muted">Amount</small>
                                <span class="h5">
									{{ formatCurrency(player.total_withdrawal) }}
								</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6 col-xl-4 mb-2 mb-md-0">
                        <h5 class="text-secondary text-uppercase">Last payment</h5>
                        <div class="callout callout-danger">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                                <small class="text-muted">Amount</small>
                                <strong>
                                    {{ lastTransactionAmount }}
                                </strong>
                            </div>
                            <div class="d-flex justify-content-between mb-1">
                                <small class="text-muted">Type</small>
                                <span class="badge badge-primary">
                                    {{ displayValue(lastTransaction, 'payment_type_beauty') }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <small class="text-muted">Created At</small>
                                <small class="text-right">
                                    {{ displayDateTime(lastTransaction, 'created_at') }}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div v-if="$can('Player - View Latest Dispositions')"
                         class="col-12 col-lg-6 col-xl-4 mb-2 mb-md-0"
                    >
                        <h5 class="text-secondary text-uppercase">Last disposition</h5>
                        <div class="callout callout-primary">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                                <small class="text-muted">Name</small>
                                <span class="badge badge-primary">
                                    {{ displayValue(lastDisposition, 'disposition.name') }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-between mb-1">
                                <small class="text-muted">Follow Up</small>
                                <small class="text-right">
                                    {{ displayValue(lastDisposition, 'follow_up') }}
                                </small>
                            </div>
                            <div class="d-flex justify-content-between">
                                <small class="text-muted">Created At</small>
                                <small class="text-right">
                                    {{ displayDateTime(lastDisposition, 'created_at') }}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div v-if="$can('Player - View Latest Call')"
                         class="col-12 col-lg-6 col-xl-4"
                    >
                        <h5 class="text-secondary text-uppercase">Last call</h5>
                        <div class="callout callout-warning">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                                <small class="text-muted">Status</small>
                                <span class="badge badge-primary">
                                    {{ displayValue(lastCall, 'status') }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-between mb-1">
                                <small class="text-muted">Duration</small>
                                <small v-html="lastCallDuration" />
                            </div>
                            <div class="d-flex justify-content-between">
                                <small class="text-muted">Created At</small>
                                <small class="text-right">
                                    {{ displayDateTime(lastCall, 'created_at') }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <loader :show="isLoading"
                    type="small"
            />
        </div>
    </div>
</template>

<script>
import formatCurrency from '../../../../../filters/FormatCurrency';
import Loader from '../../../../../components/Widgets/Loader.vue';

export default {
    name: 'PlayerStatistics',
    components: {
        Loader,
    },
    props: {
        player: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        lastTransaction() {
            return !!this.player && !!this.isset(this.player, 'last_transaction')
                ? this.player.last_transaction
                : null;
        },
        lastDisposition() {
            return !!this.player && !!this.isset(this.player, 'last_disposition')
                ? this.player.last_disposition
                : null;
        },
        lastCall() {
            return !!this.player && !!this.isset(this.player, 'last_call')
                ? this.player.last_call
                : null;
        },
        lastTransactionAmount() {
            const transactionAmount = this.getObjectPropertyValue(this.lastTransaction, 'amount');

            return !transactionAmount
                ? '/'
                : formatCurrency(transactionAmount);
        },
        lastCallDuration() {
            const duration = this.getObjectPropertyValue(this.lastCall, 'duration');

            return !duration
                ? '/'
                : duration;
        }
    },
    methods: {
        formatCurrency,
        displayDateTime(object, prop) {
            const propertyValue = this.getObjectPropertyValue(object, prop);

            return !propertyValue
                ? '/'
                : this.utcToLocal(propertyValue);
        },
        displayValue(object, prop) {
            const propertyValue = this.getObjectPropertyValue(object, prop);

            return !propertyValue ? '/' : propertyValue;
        },
        getObjectPropertyValue(object, prop, splitter = '.') {
            if (!object || this.isObjectEmpty(object)) {
                return null;
            }

            return prop.split(splitter)
                .reduce(function (prev, curr) {
                    return prev[curr];
                }, object || this);
        },
    },
}
</script>