export default {
    gateways: {
        oppwa: "oppwa",
        jeton: "jeton"
    },
    gatewayColor: function (status) {
        switch (status) {
            case this.gateways.oppwa:
                return "primary";
            case this.gateways.jeton:
                return "warning";
            default:
                return "info";
        }
    }
};