export default {
    getLeads(params = {}){
        return window.axios.get(
            `/marketing/leads/get`,
            {
                params
            }
        );
    },
    getLeadById(id){
        return window.axios.get(
            `/marketing/leads/${id}`
        );
    },
    getLeadByIdWithConversionCheck(id){
        return window.axios.get(
            `/marketing/leads/${id}/converted`
        );
    },
    createLead(model = {}){
        return window.axios.post(
            `/marketing/leads/create`,
            model
        );
    },
    updateLead(id, model = {}){
        return window.axios.post(
            `/marketing/leads/${id}`,
            model
        );
    },
    removeLead(id){
        return window.axios.post(
            `/marketing/leads/${id}/remove`
        );
    },
    importLeads(file){
        let formData = new FormData();
        formData.append("file", file);

        return window.axios.post(
            `/marketing/leads/import`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },
    leadsMessages(id, params = {}){
        return window.axios.get(
            `/marketing/leads/${id}/messages`,
            {
                params
            }
        );
    },
    batchFiles(){
        return window.axios.get(
            `/marketing/leads/batch`
        );
    },
    getAllLeadsCount(params = {}){
        return window.axios.get(
            `/marketing/leads/count`,
            {
                params
            }
        );
    },
}