var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Player - Assign Agent")
    ? _c(
        "atbl-modal",
        {
          class: {
            "hide-close": !!_vm.isLoading
          },
          attrs: {
            "close-on-backdrop": false,
            title: "Assign Agent",
            "add-footer-classes":
              "d-flex align-items-center justify-content-between"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    !!_vm.isUnAssignOperation
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-end w-100"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-danger mr-2",
                                  attrs: { disabled: _vm.isLoading },
                                  on: {
                                    click: function($event) {
                                      _vm.isUnAssignOperation = false
                                      _vm.selectedUserValue = null
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    Cancel\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm",
                                  class: {
                                    "btn-outline-success": _vm.isLoading,
                                    "btn-success": !_vm.isLoading
                                  },
                                  attrs: { disabled: _vm.isLoading },
                                  on: { click: _vm.onUpdatePlayerAgentEvent }
                                },
                                [
                                  !_vm.isLoading
                                    ? _c("span", [_vm._v("Save")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("loader", {
                                    attrs: {
                                      show: _vm.isLoading,
                                      type: "smaller"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      : [
                          !_vm.confirm
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger",
                                    attrs: {
                                      disabled:
                                        _vm.isLoading ||
                                        !_vm.agentDetails.agent_name
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.isUnAssignOperation = true
                                        _vm.selectedUserValue = null
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Un-Assign player\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-end",
                              class: { "w-100": !!_vm.confirm }
                            },
                            [
                              !_vm.confirm
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-danger mr-2",
                                        attrs: { disabled: _vm.isLoading },
                                        on: { click: _vm.onCancelEvent }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Close\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-primary",
                                        attrs: {
                                          disabled:
                                            _vm.isLoading ||
                                            !_vm.selectedUserValue
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.confirm = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Continue\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                : _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-danger mr-2",
                                        attrs: { disabled: _vm.isLoading },
                                        on: {
                                          click: function($event) {
                                            _vm.confirm = false
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Cancel\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm",
                                        class: {
                                          "btn-outline-success": _vm.isLoading,
                                          "btn-success": !_vm.isLoading
                                        },
                                        attrs: { disabled: _vm.isLoading },
                                        on: {
                                          click: _vm.onUpdatePlayerAgentEvent
                                        }
                                      },
                                      [
                                        !_vm.isLoading
                                          ? _c("span", [_vm._v("Save")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("loader", {
                                          attrs: {
                                            show: _vm.isLoading,
                                            type: "smaller"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                            ]
                          )
                        ]
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2775395897
          ),
          model: {
            value: _vm.modalVisibility,
            callback: function($$v) {
              _vm.modalVisibility = $$v
            },
            expression: "modalVisibility"
          }
        },
        [
          !_vm.isUnAssignOperation
            ? [
                !_vm.confirm
                  ? _c("div", [
                      _c("h3", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n                #" +
                            _vm._s(_vm.player.id) +
                            " / " +
                            _vm._s(_vm.player.name) +
                            " " +
                            _vm._s(_vm.player.surname) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "h5",
                        { staticClass: "text-center text-secondary mb-4" },
                        [_vm._v(_vm._s(_vm.player.email))]
                      ),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        {
                          staticClass:
                            "mb-0 border-bottom-0 border-right-0 border-left-0"
                        },
                        [
                          _c(
                            "legend",
                            { staticClass: "text-center text-muted mb-0" },
                            [_vm._v("Current agent")]
                          ),
                          _vm._v(" "),
                          _c(
                            "h5",
                            { staticClass: "text-center text-secondary" },
                            [
                              !!_vm.agentDetails.agent_name
                                ? _c("span", [
                                    _vm._v(
                                      "\n                        #" +
                                        _vm._s(_vm.agentDetails.agent_id) +
                                        " / " +
                                        _vm._s(_vm.agentDetails.agent_name) +
                                        "\n                    "
                                    )
                                  ])
                                : _c("span", [_vm._v("/")])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        {
                          staticClass:
                            "mb-0 border-bottom-0 border-right-0 border-left-0"
                        },
                        [
                          _c(
                            "legend",
                            { staticClass: "text-center text-muted mb-0" },
                            [_vm._v("Select agent")]
                          ),
                          _vm._v(" "),
                          ["Player - Assign Agent", "Users - Get List"]
                            ? _c("agent-picker", {
                                staticClass: "mb-0",
                                attrs: { label: null },
                                model: {
                                  value: _vm.selectedUserValue,
                                  callback: function($$v) {
                                    _vm.selectedUserValue = $$v
                                  },
                                  expression: "selectedUserValue"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  "No permission to view select agent picker."
                                )
                              ])
                        ],
                        1
                      )
                    ])
                  : _c("div", [
                      !_vm.isLoading
                        ? _c(
                            "h5",
                            {
                              staticClass: "text-center",
                              on: {
                                click: function($event) {
                                  _vm.isUnAssignOperation = true
                                  _vm.selectedUserValue = null
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                Are you sure you want to continue?\n            "
                              )
                            ]
                          )
                        : _c(
                            "h5",
                            { staticClass: "text-center text-secondary" },
                            [_vm._v("PROCESSING...")]
                          )
                    ])
              ]
            : [
                !_vm.isLoading
                  ? _c("h5", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n            Are you sure you want to remove agent from this player?\n        "
                      )
                    ])
                  : _c("h5", { staticClass: "text-center text-secondary" }, [
                      _vm._v("PROCESSING...")
                    ])
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }