import DiscountService from "../../../../services/DiscountService";
import {clone} from '../../../../utils/Json/Json';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';

export default {
	provide() {
		return {
			fetchDrawDiscounts: this.fetchDrawDiscounts,
			addDiscountDraw: this.addDiscountDraw,
			removeDiscountDraw: this.removeDiscountDraw,
			publishDraws: this.publishDraws,
			editDiscountDraw: this.editDiscountDraw,
		};
	},
	data() {
		return {
			editDrawLoader: false,
			editingGameDrawsDiscountInitial: [],
			editingGameDrawsDiscount: [],
			drawDiscountActiveVersion: null,
			drawDiscountErrors: {},
		};
	},
	computed: {
		drawData() {
			return {
				discountsInitial: this.editingGameDrawsDiscountInitial,
				discounts: this.editingGameDrawsDiscount,
				activeVersion: this.drawDiscountActiveVersion,
				loader: this.editDrawLoader,
				errors: this.drawDiscountErrors,
			};
		},
	},
	methods: {
		async publishDraws() {
			this.drawDiscountErrors = {};
			this.editDrawLoader = true;

			const discountDraws = this.editingGameDrawsDiscount.map(l => {
				return {
					from: l.from,
					to: l.to,
					discount: l.discount,
					discountCrm: l.discount_crm,
				};
			});

			try {
				await DiscountService.publishDiscountDraws(this.editingGame.id, {
					discountDraws,
				});

				await this.setUpDrawDiscounts(this.editingGame);

				window.showMessage('New discount draws version was published');

				this.editDrawLoader = false;
			} catch (error) {
				this.editDrawLoader = false;

				this.drawDiscountErrors = getValidationErrors(error);
			}
		},
		async fetchDrawDiscounts(game, version = null) {
			this.editDrawLoader = true;

			try {
				const response = await DiscountService.fetchGameDiscounts(game.id, 'draw', version);

				this.editingGameDrawsDiscount = response.data;
				this.editingGameDrawsDiscountInitial = clone(response.data);

				this.editDrawLoader = false;
			} catch (error) {
				this.editDrawLoader = false;

				window.flashError(error);
			}
		},
		async setUpDrawDiscounts(game) {
			await this.fetchDrawDiscounts(game);

			const hasDiscountDraws = !!this.editingGameDrawsDiscount.length;

			this.drawDiscountActiveVersion = hasDiscountDraws
				? this.editingGameDrawsDiscount[0].version
				: 1;

			if (!!hasDiscountDraws) {
				return;
			}

			this.addDiscountDraw(1, 0, 0, 0, 0);
		},
		editDiscountDraw(drawId, name, value) {
			const draws = clone(this.editingGameDrawsDiscount);

			const currentDraw = draws.find(draw => draw.id === drawId);

			if (!currentDraw) {
				return currentDraw.showMessage(`Line with ID:${drawId} has not been found.`);
			}

			const drawIndex = draws.indexOf(currentDraw);

			draws[drawIndex][name] = Number(value);

			this.editingGameDrawsDiscount = draws;
		},
		addDiscountDraw(id, from, to, discount, discountCrm) {
			const ids = this.editingGameFractionsDiscount.map(item => item.id);
			const idTmp = ! id
				? Math.max(...ids)
				: id;

			this.editingGameDrawsDiscount.push({
				id: idTmp + 1,
				from,
				to,
				discount,
				discount_crm: discountCrm
			});
		},
		removeDiscountDraw(index) {
			this.editingGameDrawsDiscount.splice(index, 1);
		},
		clearDiscountDraw() {
			this.editingGameLinesDiscount = [];
			this.lineDiscountActiveVersion = null;
			this.drawDiscountErrors = {};
		},
	},
}