<template>
    <div v-if="!!player.transaction_id && !!player.transaction_payment_type && !!player.transaction_created_at">
        <span class="badge badge-success p-1">
            {{ isPurchase ? 'Purchase' : 'Deposit' }}
            <span v-if="isPurchase">
                {{ isCardPayment ? 'CC' : 'W' }}
            </span>
            {{ utcToLocal(player.transaction_created_at) }}
        </span>
    </div>
</template>

<script>
import Transactions from '../../../../constants/Transactions';

export default {
    name: 'LatestTransaction',
    props: {
        player: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        isPurchase() {
            return Transactions.getTicketPaymentTypes().includes(this.player.transaction_payment_type);
        },
        isCardPayment() {
            return Transactions.creditCardPaymentTypes().includes(this.player.transaction_payment_type);
        },
    },
}
</script>