<template>
    <c-sidebar-nav-dropdown v-if="$canAny(permissions)"
                            name="Games"
                            href="#"
                            fontIcon="fa fa-play"
                            :class="isRouteActive(routes, 'c-show')"
    >
        <c-sidebar-nav-item name="Games Draws"
                            fontIcon="fa fa-play"
                            href="/games/draws"
                            v-if="$can('Game Draws - View')"
                            :addLinkClasses="isRouteActive('games.draws.view')"
        />
        <c-sidebar-nav-item name="Draws Reviews"
                            fontIcon="fa fa-star"
                            href="/games/draws/review"
                            v-if="$can('Game Draws - Review')"
                            :addLinkClasses="isRouteActive(['games.draws.review', 'games.draws.review.details'])"
        />
        <c-sidebar-nav-item name="Games Prices"
                            fontIcon="fa fa-gamepad"
                            href="/games/view"
                            v-if="$can('Games - Price View')"
                            :addLinkClasses="isRouteActive('games.view')"
        />
        <c-sidebar-nav-item name="Free Games"
                            fontIcon="fa fa-gamepad"
                            href="/games/free"
                            v-if="$can('Free Games - View')"
                            :addLinkClasses="isRouteActive('games.free.view')"
        />
        <c-sidebar-nav-item name="User Free Games Settings"
                            fontIcon="fa fa-gamepad"
                            href="/users/free-games-settings/view"
                            v-if="$can('Users - Free Games Settings View')"
                            :addLinkClasses="isRouteActive('users.free-games-settings.view')"
        />
    </c-sidebar-nav-dropdown>
</template>

<script>
export default {
    name: 'CreateNew',
    props: {
        user: {
            type: Number
        }
    },
    data() {
        return {
            routes: [
                'games.draws.view',
                'games.view',
                'games.free.view',
                'games.draws.review',
                'games.draws.review.details',
            ],
            permissions: [
                'Game Draws - View',
                'Games - Price View',
                'Free Games - View',
                'Users - Free Games Settings View',
                'Game Draws - Review',
            ]
        };
    }
}
</script>