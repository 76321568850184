var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Shifts - Create/Update")
    ? _c(
        "atbl-modal",
        {
          attrs: {
            show: _vm.show,
            "close-on-backdrop": false,
            "no-close-on-esc": false,
            title: "Delete shift"
          },
          on: { close: _vm.toggleDeleteShiftModal },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    !_vm.isLoading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-danger mr-2",
                            attrs: { disabled: _vm.isLoading },
                            on: { click: _vm.toggleDeleteShiftModal }
                          },
                          [_vm._v("\n            Cancel\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("Shifts - Details")
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            attrs: {
                              disabled:
                                !!_vm.isLoading ||
                                !_vm.selectedShift ||
                                _vm.hasUsers
                            },
                            on: { click: _vm.deleteShift }
                          },
                          [
                            !_vm.isLoading
                              ? _c("span", [_vm._v("Save")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("loader", {
                              attrs: { show: _vm.isLoading, type: "smaller" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2591365841
          )
        },
        [
          !_vm.isLoading
            ? [
                !_vm.hasUsers
                  ? _c("h4", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n            Are you really want to delete this shift?\n        "
                      )
                    ])
                  : _c("div", [
                      _c("h4", { staticClass: "text-center mb-3" }, [
                        _vm._v(
                          "You cannot remove this shift because there is still users assigned?"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(_vm.selectedShift.users, function(ref, index) {
                          var id = ref.id
                          var name = ref.name
                          return _c("li", { key: index }, [
                            _vm._v(
                              "\n                    # " + _vm._s(id) + " / "
                            ),
                            _c("b", [_vm._v(_vm._s(name))])
                          ])
                        }),
                        0
                      )
                    ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }