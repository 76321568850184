var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      attrs: {
        title: "Create/Update Free Game",
        value: _vm.show,
        loader: _vm.isLoading
      },
      on: { input: _vm.toggleCreateUpdateForm },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.isLoading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-outline-danger mr-2",
                      on: { click: _vm.toggleCreateUpdateForm }
                    },
                    [_vm._v("\n            Cancel\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { disabled: !_vm.isSaveAllowed || _vm.isLoading },
                  on: { click: _vm.createFreeGame }
                },
                [
                  !_vm.isLoading ? _c("span", [_vm._v("Save")]) : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: _vm.isLoading, type: "smaller" }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "atbl-form-group",
        { attrs: { label: "Type", error: _vm.getError(_vm.errors, "type") } },
        [
          _c("atbl-select", {
            attrs: {
              options: Object.keys(_vm.configuration),
              value: _vm.freeGameModel.type,
              disabled: _vm.isLoading,
              name: "type"
            },
            on: { update: _vm.updateGameType }
          })
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.freeGameModel.type && !!_vm.games.length
        ? _c("game-select", {
            attrs: {
              options: _vm.games,
              value: _vm.freeGameModel.game,
              disabled: _vm.isLoading,
              errors: _vm.errors
            },
            on: { update: _vm.updateGame }
          })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.freeGameModel.type && !_vm.games.length
        ? _c("not-found", {
            attrs: {
              items: _vm.games,
              msg: "No " + _vm.freeGameModel.type + " games are found."
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isObjectEmpty(_vm.freeGameModel.fields) && !!_vm.freeGameModel.game
        ? _c("fields-inputs", {
            attrs: {
              fields: _vm.freeGameModel.fields,
              "free-game-model": _vm.freeGameModel,
              disabled: _vm.isLoading,
              errors: _vm.errors,
              "user-free-game-settings": _vm.userFreeGameSettings
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.freeGameModel.type && !!_vm.freeGameModel.game
        ? _c("meta-inputs", {
            attrs: {
              meta: _vm.freeGameModel.meta,
              disabled: _vm.isLoading,
              errors: _vm.errors
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.freeGameModel.type && !!_vm.freeGameModel.game
        ? _c(
            "atbl-form-group",
            { attrs: { label: "Expires at" } },
            [
              _c("date-picker", {
                attrs: {
                  value: _vm.freeGameModel.expires_at,
                  name: "expires_at",
                  format: "YYYY-MM-DD",
                  disabled: _vm.isLoading,
                  "min-date": _vm.minDate
                },
                on: { update: _vm.updateFreeGameModel }
              }),
              _vm._v(" "),
              _vm.hasExpireData
                ? _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.updateFreeGameModel("expires_at", null)
                        }
                      }
                    },
                    [_c("small", [_vm._v("clear")])]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.freeGameModel.type === "bundle" && !!_vm.getFlattenErrors.length
        ? _c(
            "div",
            _vm._l(_vm.getFlattenErrors, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "alert alert-danger" },
                [_vm._v("\n            " + _vm._s(item) + "\n        ")]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isGameLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }