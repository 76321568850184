import moment from 'moment';

export default function (value, format, utc = true) {
    if (!format) {
        format = 'YYYY-MM-DD HH:mm';
    }

    if (!value) {
        return moment().format(format);
    }

    value = value.toString();
    const dateTime = moment(value);

    if(utc) {
        dateTime.utc();
    }

    return dateTime.format(format);
};