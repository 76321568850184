<template>
	<div class="text-center col justify-content-center align-items-center d-flex flex-column m-auto mb-4"
	       :class="withScreen"
           v-if="!canPlayGame"
	>
		<div v-if="!isLoading">
			<h4 class="text-muted">Please fill in all required fields.</h4>
			<p class="m-0">
				To complete player profile please go to
				<a :href="`/players/${player.id}/view`">
					Player Profile
				</a>
				(update: {{ canPlayData }}).
			</p>
		</div>
		<loader :show="isLoading"
		        type="small"
		/>
	</div>
</template>

<script>
	import TypesConst from "../../../../store/TypesConst";
	import NamespaceConst from "../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";

	const {
		mapGetters,
		mapMutations,
		mapActions
	} = createNamespacedHelpers(NamespaceConst.players);

	const {
		mapGetters: mapCartGetters,
		mapMutations: mapCartMutations
	} = createNamespacedHelpers(NamespaceConst.cart);

	export default {
		name: "cant-play-game",
		props: {
			isLoading: {
				type: Boolean,
				default: false
			},
			isFullScreen: {
				type: Boolean,
				default: false
			}
		},
		data: function () {
			return {
				canPlayData: '',
			};
		},
		computed: {
			...mapGetters({
				player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL
			}),
			...mapCartGetters({
				canPlayGame: TypesConst.cart.getters.GET_PLAYER_CAN_PLAY_GAME
			}),
			withScreen: function () {
				return this.isFullScreen ? "col-12" : "col-12 col-sm-8 col-lg-9";
			}
		},
		methods: {
			...mapCartMutations({
				setPlayerCanPlay: TypesConst.cart.mutations.SET_PLAYER_CAN_PLAY_GAME
			}),
			validateCanPlayFields: function (player) {
				let errors = [];

				if (player.name === null) {
					errors.push("name");
				}
				if (player.surname === null) {
					errors.push("surname");
				}
				if (player.birthdate === null) {
					errors.push("birthdate");
				}
				if (player.country === null) {
					errors.push("country");
				}
				if (player.city === null) {
					errors.push("city");
				}
				if (player.address1 === null) {
					errors.push("address1");
				}
				if (player.postcode === null) {
					errors.push("postcode");
				}

				return errors;
			}
		},
		watch: {
			player: {
				handler: function (value) {
					let canPlayErrors = this.validateCanPlayFields(value);

					this.canPlayData = canPlayErrors.join();

					this.setPlayerCanPlay(!canPlayErrors.length);
				},
				deep: true,
				immediate: true
			}
		}
	}
</script>