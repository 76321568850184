<template>
    <atbl-modal title="Add to campaign"
                 :value="show"
                 :loader="isLoading"
                 @input="toggleAddToCampaign"
    >
        <atbl-tabs v-show="!isModalLoading"
                   @change-tab="onTabChange"
        >
            <atbl-tab title="Add to campaign"
                      :active="addToCampaignMode === 0"
            >
                <campaigns-picker v-model="selectedCampaignId"
                                  :multiple="false"
                                  :disabled="isModalLoading"
                                  class="mt-2 mb-0"
                />
            </atbl-tab>
            <atbl-tab title="Create campaign"
                      :active="addToCampaignMode === 1"
            >
                <atbl-input :value="campaignModel.title"
                            :disabled="isModalLoading"
                            :errors="campaignModelErrors"
                            label="Title *"
                            name="title"
                            class="mt-2"
                            @update="updateCampaignModelField"
                />
                <strong>* Finish this form after save</strong>
            </atbl-tab>
        </atbl-tabs>
        
        <loader :show="isModalLoading"
                type="small"
        />
        
        <template #footer>
            <button v-if="!isModalLoading"
                    class="btn btn-sm btn-outline-danger mr-2"
                    @click="toggleAddToCampaign"
            >
                Cancel
            </button>
            <button class="btn btn-sm btn-primary"
                    :disabled="isModalLoading || !isSaveEnabled"
                    @click="onAddToCampaign"
            >
                <span v-if="!isModalLoading">Save</span>
                <loader :show="isModalLoading"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
    import AtblSwitch from '../../../../../components/Forms/Inputs/ATBLSwitch.vue';
    import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';
    import CampaignFormEdit from '../../../Campaigns/Editor/CampaignFormEdit/CampaignFormEdit';
    import AtblTabs from '../../../../../components/AtblTabs/AtblTabs.vue';
    import AtblTab from '../../../../../components/AtblTabs/AtblTab.vue';
    import CampaignsPicker from '../../../_Components/CampaignsPicker/CampaignsPicker.vue';

    export default {
        name: 'AddToCampaignComponent',
        inject: [
          'toggleAddToCampaign',
        ],
        mixins: [
            CampaignFormEdit,
        ],
        components: {
            CampaignsPicker,
            AtblTabs,
            AtblSwitch,
            AtblModal,
            AtblTab,
        },
        props: {
            isLoading: {
                type: Boolean,
                default: false
            },
            show: {
                type: Boolean,
                default: false
            },
            searchModel: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                addToCampaignMode: 0,
                selectedCampaignId: null,
                addToCampaignLoader: false,
            };
        },
        computed: {
            isSaveEnabled() {
                if(!this.addToCampaignMode) {
                    return !!this.selectedCampaignId;
                }
                
                return !!this.campaignModel.title;
            },
            isModalLoading() {
                return this.isLoading || this.addToCampaignLoader;
            },
        },
        methods: {
            async onAddToCampaign() {
                this.addToCampaignLoader = true;
                
                try {
                    const response = await window.axios.post(
                      `/marketing/leads/add-to-campaign`,
                      {
                          campaign_id: this.selectedCampaignId,
                          searchModel: this.searchModel,
                          type: this.addToCampaignMode
                            ? 'new'
                            : 'existing',
                          campaignModel: this.campaignModel,
                      }
                    );
                    const { status, message, data } = response.data;
                    
                    window.showMessage(message, status);
                    
                    if (!status) {
                        return;
                    }
                    
                    window.location.href = `/marketing/campaigns/lead/${data.id}`;
                } catch (error) {
                    this.addToCampaignLoader = false;
                    
                    window.flashError(error);
                }
            },
            onTabChange(value) {
                if (!!value) {
                    this.selectedCampaignId = null;
                } else {
                    this.updateCampaignModelField('title', null);
                }
                
                this.addToCampaignMode = value;
            },
        },
    }
</script>
