var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Create Refund",
        show: _vm.showModal,
        "close-on-backdrop": false
      },
      on: { close: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex w-100 align-items-center" }, [
                _c("div", { staticClass: "w-50 font-weight-bold" }, [
                  _vm._v("\n                * Required\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm",
                      class: {
                        "btn-primary": !_vm.getPaymentLoader,
                        "btn-outline-primary": _vm.getPaymentLoader
                      },
                      attrs: { disabled: _vm.getPaymentLoader },
                      on: { click: _vm.handlePayment }
                    },
                    [
                      !_vm.getPaymentLoader
                        ? _c("span", [
                            _vm._v(
                              "\n                        Yes\n                    "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: { type: "small", show: _vm.getPaymentLoader }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h5", { staticClass: "text-center m-0" }, [
        _vm._v("\n        Are you sure you want to make refund of "),
        _c("b", [_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.getFundsAmount)))]),
        _vm._v(" to this player?\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }