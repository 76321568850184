var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "card card-list" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _vm._m(1),
            _vm._v(" "),
            !_vm.isLoading
              ? _vm._l(_vm.reports.data, function(item, index) {
                  return _c("div", { key: index, staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Month")
                        ]),
                        _vm._v(" "),
                        _vm.$can("Reports New Players - Details View")
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.showDetails(item.month)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.month) +
                                    "\n                            "
                                )
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(item.month))])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Total Players")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.total_players) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Players")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.players) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 col-lg text-center text-lg-left"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Leads Conversion")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.leads) +
                            "\n                        "
                        )
                      ]
                    )
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("not-found", {
                  attrs: {
                    msg: "No reports data found",
                    items: _vm.reports.data
                  }
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      !_vm.isLoading && _vm.reports.last_page > 1
        ? _c("div", { staticClass: "card-footer" }, [
            _c(
              "nav",
              { staticClass: "wrap" },
              [
                _c("atbl-pagination", {
                  attrs: { pagination: _vm.reports },
                  on: { paginate: _vm.getPlayersReports }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-users" }),
      _vm._v(" New Players\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg" }, [
        _vm._v("\n                        Month\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg" }, [
        _vm._v("\n                        Total Players\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg" }, [
        _vm._v("\n                        Players\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg" }, [
        _vm._v(
          "\n                        Leads Conversion\n                    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }