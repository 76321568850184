<template>
	<div class="card mb-3">
		<div class="card-header card-header-atbl bg-primary text-white">
			<i class='fa fa-plus'></i> Add Disposition
		</div>
		
		<div class="card-body">
			<form v-on:submit.prevent="saveDisposition">
				<div class="add-dispositions-holder-dropdown">
					<dispositions-select v-model="model.disposition_id"/>
					
					<DateTimePicker v-model="model.follow_up"
					                :defaultValue="model.follow_up"
					                :passDays="true"
					                label="Follow-Up Date"
					/>
				</div>
				<div class="add-dispositions-holder-button">
					<button v-if="!saveLoader"
					          type="submit"
							  class="btn btn-sm btn-primary btn-block">
						Save
					</button>
					<loader :show="saveLoader"
					        type="smaller"
					/>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import DateTimePicker from '../../../../../components/Forms/Select/DateTimePicker';
	
	export default {
		name: "add-disposition-section",
		components: {
			DateTimePicker
		},
		props: {
			dispositions: {
				type: Array,
				required: true
			},
			player_id: {
				required: true
			},
			errors: {
				type: Object,
				required: true
			},
			clean: {
				type: Boolean,
				required: true
			},
			saveLoader: {
				type: Boolean,
				required: true
			}
		},
		data() {
			return {
				model: {
					player_id: this.player_id,
					disposition_id: null,
					follow_up: null
				}
			};
		},
		methods: {
			saveDisposition: function () {
				this.$emit("saveNewDisposition", this.model);
			}
		},
		watch: {
			clean: {
				handler: function (value) {
					if (value) {
						this.model.disposition_id = null;
						this.model.follow_up = null;
					}
				},
				deep: true
			}
		}
	}
</script>