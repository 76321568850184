export default {
    data() {
        return {
            emailTemplatesUserTypesLoading: false,
            emailTemplateUserTypes: [],
        };
    },
    methods: {
        async fetchEmailTemplateUserTypes() {
            this.emailTemplatesUserTypesLoading = true;

            try {
                const response = await window.axios.get('/email-templates/user-types');

                this.emailTemplateUserTypes = response.data;

                this.emailTemplatesUserTypesLoading = false;
            } catch (error) {
                this.emailTemplatesUserTypesLoading = false;

                window.flashError(error);
            }
        },
        resolveMetaUserTypes(userTypes) {
            let meta = {};

            const links = this.languages.map(language => {
                return [language.value, null];
            });

            for (const userType of userTypes) {
                meta[userType] = {
                    type: null,
                    type_source: null,
                    source_id: null,
                    links: Object.fromEntries(links),
                };
            }

            return meta;
        },
    },
}