var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configuration" }, [
    _c(
      "h5",
      { staticClass: "configuration-header", on: { click: _vm.toggleBody } },
      [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("i", {
          staticClass: "fa",
          class: {
            "fa-caret-up": _vm.showBody,
            "fa-caret-down": !_vm.showBody
          },
          attrs: { "aria-hidden": "false" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBody,
            expression: "showBody"
          }
        ]
      },
      [
        _c(
          "div",
          {
            class: {
              "configuration-holder": _vm.getConfiguration.numbers,
              "configuration-holder-multiple": !_vm.getConfiguration.numbers
            }
          },
          _vm._l(_vm.getMainNumbers, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                class: {
                  "configuration-input-holder": _vm.getConfiguration.numbers,
                  "configuration-holder-single": !_vm.getConfiguration.numbers
                }
              },
              [
                _vm.getConfiguration.numbers
                  ? _c("input", {
                      attrs: {
                        id:
                          "preview_" + _vm.title + "_" + _vm.genId(item, index),
                        disabled: ""
                      },
                      domProps: { value: _vm.getValue(_vm.genId(item, index)) }
                    })
                  : _c("div", [
                      _c(
                        "label",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            for:
                              "preview_" +
                              _vm.title +
                              "_" +
                              _vm.genId(item, index)
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                item.title ? item.title : _vm.ucFirst(index)
                              ) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      item.numbers
                        ? _c(
                            "div",
                            _vm._l(item.numbers, function(i, index) {
                              return _c("input", {
                                key: index,
                                class: {
                                  "configuration-text-input":
                                    item.type === "text"
                                },
                                attrs: {
                                  id:
                                    "preview_" +
                                    _vm.title +
                                    "_" +
                                    _vm.genId(item, index),
                                  disabled: ""
                                },
                                domProps: {
                                  value: _vm.getValue(_vm.genId(item, index))
                                }
                              })
                            }),
                            0
                          )
                        : _c("input", {
                            staticClass: "configuration-text",
                            attrs: {
                              id:
                                "preview_" +
                                _vm.title +
                                "_" +
                                _vm.genId(item, index),
                              disabled: ""
                            },
                            domProps: {
                              value: _vm.getValue(_vm.genId(item, index))
                            }
                          })
                    ])
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }