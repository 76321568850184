var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: { title: _vm.modalTitle, "close-on-backdrop": false, show: true },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  on: { click: _vm.emitCloseEvent }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { disabled: !_vm.action },
                  on: { click: _vm.submitBonusActionData }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.saveButtonLabel) +
                      "\n        "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row border-bottom" }, [
        _c(
          "div",
          { staticClass: "col col-4" },
          [
            _c(
              "atbl-form-group",
              {
                staticClass: "font-weight-bold",
                attrs: {
                  label: "Select action",
                  horizontal: false,
                  error: null
                }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.action,
                        expression: "action"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.action = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onActionChange
                      ]
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("Select action")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.actionList, function(action) {
                      return _c(
                        "option",
                        {
                          key: "action_" + action.key,
                          domProps: { value: action }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(action.name) +
                              "\n                    "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.action
        ? _c("bonus-action-field-wrapper", {
            attrs: {
              "action-fields-config": _vm.action.config.fields,
              "disable-fields": _vm.allowDataOverride
            },
            model: {
              value: _vm.fields,
              callback: function($$v) {
                _vm.fields = $$v
              },
              expression: "fields"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.action
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col col-8" }, [
              _c(
                "div",
                { staticClass: "form-group-atbl" },
                [
                  _c("atbl-check-box", {
                    staticClass: "mr-1",
                    attrs: {
                      value: _vm.allowDataOverride,
                      label: "Allow data override"
                    },
                    on: { input: _vm.onAllowDataOverrideChange }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }