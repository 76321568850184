var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      attrs: {
        label: _vm.label,
        "label-for": _vm.getLabelFor,
        error: _vm.getError(_vm.getErrors, _vm.name)
      }
    },
    [
      _c("input", {
        staticClass: "form-control",
        class: {
          "is-invalid": _vm.hasError(_vm.getErrors, _vm.name)
        },
        attrs: {
          id: _vm.getLabelFor,
          type: "number",
          placeholder: _vm.getPlaceholder,
          disabled: _vm.disabled,
          min: _vm.min,
          max: _vm.max,
          step: _vm.step
        },
        domProps: { value: _vm.value },
        on: { input: _vm.onNumberUpdate }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }