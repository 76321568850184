<template>
    <div>
        <div class="card card-list">
            <div v-if="section.name" class="card-header d-flex justify-content-between align-items-center">
                <span><i class='fa fa-cogs'></i> {{ section.name }}</span>
                <button class="btn btn-sm btn-primary m-0"
                          @click="openAddSettingsModal(true)"
                          v-if="$can('Settings - Create/Update')"
                >
                    Add Setting
                </button>
            </div>

            <div class="card-body p-3">
                <SettingSections v-if="sectionOpened && settings.length"
                />
                <not-found v-if="sectionOpened && !settings.length"
                           :items="settings"
                           msg="No settings found in this section."
                />

                <not-found v-if="!sectionOpened"
                           :items="[]"
                           msg="Select section to show settings."
                />
            </div>
        </div>

        <AddSettingsModal />
        <RemoveSettingsModal />
    </div>
</template>

<script>
    import SettingSections from "./Sections/SettingsSection";
    import AddSettingsModal from "./Components/AddSettingsModal";
    import RemoveSettingsModal from "./Components/RemoveSettingsModal";
    import TypesConst from "../../../store/TypesConst";
    import NamespaceConst from "../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const { mapActions, mapGetters } = createNamespacedHelpers(NamespaceConst.settings);

    export default {
        name: "settings",
        components: {
            SettingSections,
            AddSettingsModal,
            RemoveSettingsModal
        },
        computed: {
            ...mapGetters({
                section: TypesConst.settings.getters.GET_SELECTED_SECTION,
                settings: TypesConst.settings.getters.GET_SETTINGS
            }),
            sectionOpened: function(){
                return !!this.section.name;
            }
        },
        methods: {
            ...mapActions({
                openAddSettingsModal: TypesConst.settings.actions.OPEN_SETTINGS_MODAL_ACTION
            })
        }
    }
</script>