import general from "./modules/general/types";
import settings from "./modules/settings/types";
import players from "./modules/players/types";
import tickets from "./modules/tickets/types";
import transactions from "./modules/transactions/types";
import users from "./modules/users/types";
import cart from "./modules/cart/types"
import payment from "./modules/payment/types"
import games from "./modules/games/types"
import callback from "./modules/callback/types";
import notifications from "./modules/notifications/types";
import order from "./modules/order/types";

export default {
    general,
    settings,
    players,
    tickets,
    transactions,
    users,
    cart,
    payment,
    games,
    callback,
    notifications,
    order
};
