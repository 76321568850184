<template>
  <card-section class="player-withdrawals-create mb-4"
                title="Create Withdrawals"
  >
    <form @submit.prevent="createWithdrawRequest">
      <div v-if="!!isKycApproved"
           class="row"
      >
        <div class="col-12 col-lg-5 mb-2 mb-lg-0">
          <atbl-number v-model="model.amount"
                       :disabled="!isKycApproved"
                       :errors="amountValidationErrors"
                       label="Amount"
                       name="amount"
                       class="m-0"
          />
        </div>
        <div class="col-12 col-lg-5 mb-2 mb-lg-0">
          <atbl-form-group label="Card"
                           label-for="Card"
                           class="font-weight-bold m-0"
          >
            <select v-model="model.card_id"
                    class="form-control"
                    :disabled="cardsLoader"
            >
              <option :value="null" disabled>
                -- Please select an option --
              </option>
              <template v-for="(card, index) in cards">
                <option :key="index" :value="card.id">
                  {{ card.holder }} / {{ card.payment_brand }} * {{ card.last_4_digits }}
                </option>
              </template>
            </select>
          </atbl-form-group>
        </div>
        <div class="col-12 col-lg-2 d-flex align-items-end mt-2 mt-lg-0">
          <button :disabled="!!isLoading || !!isDisabled"
                  type="submit"
                  class="btn btn-primary btn-block"
          >
            <span v-if="!isLoading">
              Withdrawal
            </span>
            <loader :show="isLoading"
                    type="smaller"
            />
          </button>
        </div>
      </div>
      <items-not-found v-else
                       :items="[]"
                       msg="Cannot make withdrawal because KYC status is not verified."
                       text-color="danger"
      />
    </form>
  </card-section>
</template>

<script>
	import Loader from '../../../../../components/Widgets/Loader.vue';
	import AtblFormGroup from '../../../../../components/Forms/Holders/ATBLFormGroup.vue';
	import AtblNumber from '../../../../../components/Forms/Inputs/ATBLNumber.vue';
	import formatCurrency from '../../../../../filters/FormatCurrency';
	import PlayerCreditCardsService from '../../../../../services/PlayerCreditCardsService.vue';
	import CardSection from '../../../Components/Layout/CardSection.vue';
	import KycStatus from '../../../../../constants/KycStatus';
	import ItemsNotFound from '../../../../../components/Lists/ItemsNotFound.vue';
	import {isObjectEmpty} from '../../../../../utils/Object/Object';
	import PlayerWithdrawalService from '../../../../../services/PlayerWithdrawalsService';
    import {clone} from '../../../../../utils/Json/Json';

    const withdrawalModel = {
        card_id: null,
        amount: null,
        playerId: null,
    };
    
	export default {
		name: 'player-create-withdrawal-component',
        inject: [
            'getPlayerWithdrawals',
            'onFetchPlayerWallet',
        ],
		components: {
			ItemsNotFound,
			Loader,
			AtblFormGroup,
			AtblNumber,
			CardSection,
		},
		props: {
			playerId: {
				type: Number,
				required: true
			},
		},
		data() {
			return {
				model: clone(withdrawalModel),
				isLoading: false,
				cardsLoader: false,
				cards: [],
			};
		},
		computed: {
			isKycApproved() {
				const player = this.$store.state.players.playerModel;

				return [
					KycStatus.Verified,
					KycStatus.ForceVerified
				].includes(player.kyc_status);
			},
			modelId: {
				get() {
					return this.model.card_id;
				},
				set(value) {
					this.model.card_id = value;
				}
			},
			amountValidationErrors() {
				const amount = this.model.amount;

				if (amount === null) {
					return null;
				}

				if (amount < 5) {
					return {amount: ['Amount is less then 5']};
				}

				return {};
			},
			hasCardSelected() {
				return !!this.model.card_id;
			},
			isDisabled() {
				const hasAmountError = this.amountValidationErrors === null
					? false
					: isObjectEmpty(this.amountValidationErrors);

				return !hasAmountError || !this.hasCardSelected;
			},
		},
		async created() {
            this.initializeModel();
            
			await this.getPayerCards();
		},
		methods: {
			formatCurrency,
			async getPayerCards() {
				this.cardsLoader = true;

				try {
					const response = await PlayerCreditCardsService.getPlayerCreditCards(this.playerId, {
						playerId: this.playerId,
					});

					this.cards = response.data.data;

					this.cardsLoader = false;
				} catch (error) {
					this.cardsLoader = false;

					window.flashError(error);
				}
			},
			async createWithdrawRequest() {
				this.isLoading = true;

				try {
					const response = await PlayerWithdrawalService.createWithdrawalRequest(this.model);
					const {status, message} = response.data;

					window.showMessage(message, status);

					if (!!status) {
                        this.initializeModel();
                      
                        this.onFetchPlayerWallet();
                        await this.getPlayerWithdrawals();
					}

					this.isLoading = false;
				} catch (error) {
					this.isLoading = false;

					window.flashError(error);
				}
			},
			initializeModel() {
				this.model = clone(withdrawalModel);
                this.model.playerId = this.playerId;
			},
		},
	};
</script>