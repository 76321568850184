import Transactions from "../../constants/Transactions";

export default {
	_paymentModel: {
		paymentType: null,
		playerId: null
	},
	credit: function (params = null) {
		return this.preparePayment(Transactions.CAVD, params);
	},
	bonus: function (params = null) {
		return this.preparePayment(Transactions.CABD, params);
	},
	withdrawal: function (params = null) {
		return this.preparePayment(Transactions.CMWD, params);
	},
	refund: function (params = null) {
		return this.preparePayment(Transactions.CMRF, params);
	},
	wallet: function (params = null) {
		return this.preparePayment(Transactions.CAWP, params);
	},
	card: function (params = null) {
		return this.preparePayment(Transactions.CACP, params);
	},
	deposit: function (params = null) {
		return this.preparePayment(Transactions.CAFD, params);
	},
	preparePayment: function (paymentType = null, params = null) {
		let _parent = this;

		if(!params && !paymentType) {
			throw new Error("Parameters must be defined.");
		}

		let paymentModel = {
			...this._paymentModel,
			paymentType,
			...params
		};

		return new Promise((resolve, reject) => {
			_parent.createPayment(paymentModel)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	createPayment: function (model = {}) {
		return window.axios.post('/purchase', model);
	}
};