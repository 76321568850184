var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "send-email" },
    [
      _vm.$can("Player - Send Email")
        ? _c(
            "button",
            {
              staticClass: "btn btn-light player-nav-group-button btn-block",
              attrs: { disabled: !_vm.hasEmail },
              on: {
                click: function($event) {
                  return _vm.onToggleEmailModalEvent(true)
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-envelope",
                attrs: { "aria-hidden": "false" }
              }),
              _vm._v(" SEND EMAIL\n\t\t")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasEmail
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "small",
              { staticClass: "text-danger text-uppercase font-weight-bold" },
              [_vm._v("\n\t\t\t\tEmail is missing\n\t\t\t")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("send-email-modal-component", {
        attrs: {
          client: _vm.client,
          show: _vm.sendEmailModalOpened,
          "send-email-configuration": _vm.emailTemplatesEnabledTypes
        },
        on: { toggle: _vm.onToggleEmailModalEvent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }