import { clone } from '../../../utils/Json/Json';
import CallHistorySearchModel from './CallHistorySearchModel';

export default {
    provide() {
        return {
            onCallHistorySearchForm: this.onCallHistorySearchForm,
            updateCallHistorySearchField: this.updateCallHistorySearchField,
            clearCallHistoryFilterForm: this.clearCallHistoryFilterForm,
            toggleCallHistoryFilterForm: this.toggleCallHistoryFilterForm,
        };
    },
    data() {
        return {
            isCallHistoryFilterFormOpen: false,
            callHistoryFilterModel: clone(CallHistorySearchModel),
        };
    },
    methods: {
        async onCallHistorySearchForm() {
            await this.fetchCallHistoryStatistics(this.callHistoryFilterModel, this.agentid);
        },
        updateCallHistorySearchField(name, value) {
            Object.assign(this.callHistoryFilterModel, {
                [name]: value
            });
        },
        async clearCallHistoryFilterForm() {
            this.callHistoryFilterModel = clone(CallHistorySearchModel);

            await this.onCallHistorySearchForm();
        },
        toggleCallHistoryFilterForm() {
            this.isCallHistoryFilterFormOpen = !this.isCallHistoryFilterFormOpen;
        },
    },
}