<template>
    <b-card header-class="d-flex justify-content-start align-items-center p-0"
            body-class="d-flex justify-content-center align-items-center"
    >
        <template slot="header">
            <div class="text-uppercase d-flex align-items-center justify-content-between w-100">
                <i :class="[icon, `bg-${color}`]" class="card-icon p-2"></i>
                <span class="card-title">{{title}}</span>
                <span>&nbsp;</span>
            </div>
        </template>
        <h5 class="m-0" :class="`text-${color}`">
            {{number}}
        </h5>
    </b-card>
</template>

<script>
    export default {
        name: "small-statistics-card",
        props: {
            icon: {
                required: true
            },
            number: {
                type: String,
                required: true
            },
            title: {
                required: true
            },
            color: {
                default: 'primary'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        &-icon {
            font-size: 20px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 36px;
            height: 36px;
        }
        &-title {
            font-size: 12px !important;
            margin: 0;
        }
    }
</style>