export default {
    mutations: {
        SET_NOTIFICATIONS_LIST: `setNotificationsList`,
        SET_NOTIFICATIONS_LIST_RESPONSE: `setNotificationsResponse`,
        SET_NOTIFICATIONS_LIST_LOADER: `setNotificationsLoader`,
        SET_NOTIFICATIONS_READ_BAR: `setNotificationsReadBar`,
        SET_NOTIFICATIONS_READ_BAR_LOADER: `setNotificationsReadBarLoader`,
        SET_NOTIFICATIONS_MODEL: `setNotificationModel`,
        SET_NOTIFICATIONS_MODEL_FIELD: `setNotificationModelField`,
        SET_NOTIFICATIONS_WIDGET_DATA: `setNotificationsWidgetData`,
        SET_NOTIFICATIONS_WIDGET_DATA_LOADER: `setNotificationsWidgetDataLoader`,
    },
    getters: {
        GET_NOTIFICATIONS_LIST: `getNotificationsList`,
        GET_NOTIFICATIONS_LIST_RESPONSE: `getNotificationsResponse`,
        GET_NOTIFICATIONS_LIST_LOADER: `getNotificationsLoader`,
        GET_NOTIFICATIONS_READ_BAR: `getNotificationsReadBar`,
        GET_NOTIFICATIONS_READ_BAR_LOADER: `getNotificationsReadBarLoader`,
        GET_NOTIFICATIONS_MODEL: `getNotificationModel`,
        GET_NOTIFICATIONS_WIDGET_DATA: `getNotificationsWidgetData`,
        GET_NOTIFICATIONS_WIDGET_DATA_LOADER: `getNotificationsWidgetDataLoader`,
    },
    actions: {
        NOTIFICATIONS_GET_LIST_ACTION: 'getAllNotificationAction',
        NOTIFICATIONS_READ_ACTION: 'readNotificationAction',
        NOTIFICATIONS_GET_BY_ID_ACTION: 'getNotificationByIdAction',
        NOTIFICATIONS_MARK_AS_READ_ACTION: 'markNotificationAsReadAction',
        NOTIFICATIONS_MARK_ALL_AS_READ_ACTION: 'markAllNotificationsAsReadAction',
        NOTIFICATIONS_CLEAR_ALL_ACTION: 'clearAllNotificationsAction',
        NOTIFICATIONS_REMOVE_ACTION: 'removeNotificationsAction',
        NOTIFICATIONS_WIDGET_DATA_ACTION: 'getNotificationsWidgetData'
    }
};