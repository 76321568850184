var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Enable Syndicate",
        "close-on-backdrop": true,
        show: true
      },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.isRecurring ||
              (_vm.syndicateItem.is_active && !_vm.syndicate.is_active)
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-danger",
                        on: { click: _vm.emitEnableSyndicate }
                      },
                      [_vm._v("\n                Yes\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.emitCloseEvent }
                      },
                      [_vm._v("\n                No\n            ")]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isRecurring &&
              _vm.syndicate.is_active &&
              !_vm.syndicateItem.is_active
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-danger",
                        on: { click: _vm.emitEnableSyndicateItem }
                      },
                      [_vm._v("\n                Yes\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.emitCloseEvent }
                      },
                      [_vm._v("\n                No\n            ")]
                    )
                  ]
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isRecurring ||
      (_vm.syndicateItem.is_active && !_vm.syndicate.is_active)
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _vm._v("Are you sure you want to enable this syndicate ?")
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isRecurring && _vm.syndicate.is_active && !_vm.syndicateItem.is_active
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _vm._v("Are you sure you want to enable this syndicate draw ?")
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }