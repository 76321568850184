var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: { show: _vm.isModalOpened, title: "Create/Update Disposition" },
      on: { close: _vm.toggleCreateUpdateForm },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger font-weight-bold mr-1",
                  on: { click: _vm.toggleCreateUpdateForm }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: {
                    disabled:
                      !_vm.model.name || !_vm.isLockTimeValid || !!_vm.isLoading
                  },
                  on: { click: _vm.saveDisposition }
                },
                [
                  !_vm.isLoading ? _c("span", [_vm._v("Save")]) : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: _vm.isLoading, type: "smaller" }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isLoading
        ? [
            _c("atbl-input", {
              attrs: {
                value: _vm.model.name,
                errors: _vm.errors,
                label: "Enter name",
                name: "name"
              },
              on: { update: _vm.updateModelField }
            }),
            _vm._v(" "),
            _c(
              "h6",
              { staticClass: "border-bottom pb-1 mt-4 text-uppercase" },
              [_vm._v("Settings")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-items-center" },
              [
                _c("atbl-number", {
                  staticClass: "m-0",
                  attrs: {
                    value: _vm.model.campaign_lock_time,
                    min: 1,
                    max: _vm.maxDaysInMinutes,
                    errors: _vm.errors,
                    "show-errors": false,
                    label: "Campaign lock time (minutes)",
                    name: "campaign_lock_time"
                  },
                  on: { update: _vm.updateModelField }
                }),
                _vm._v(" "),
                !_vm.isLockTimeValid
                  ? _c("span", { staticClass: "invalid-feedback d-block" }, [
                      _vm._v(
                        "\n                Number cannot be less than 0 or bigger than " +
                          _vm._s(_vm.maxDaysInMinutes) +
                          " minutes and should be only full number without decimals.\n            "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }