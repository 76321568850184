var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid game-draw-review-details" },
    [
      !_vm.isLoading
        ? [
            _c(
              "div",
              { staticClass: "card card-list game-draw-review-details-info" },
              [
                _c("div", { staticClass: "card-header" }, [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("i", { staticClass: "fa fa-star mr-2" }),
                    _vm._v(" Draw "),
                    _c("strong", { staticClass: "mx-2" }, [
                      _vm._v(_vm._s(_vm.draw.game_draw_id))
                    ]),
                    _vm._v(" review\n                ")
                  ]),
                  _vm._v(" "),
                  _vm.$can("Game Draws - Approve")
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-success",
                            attrs: {
                              disabled: _vm.isToggleApproveButtonActive
                            },
                            on: { click: _vm.toggleApproveModal }
                          },
                          [
                            _vm._v(
                              "\n                        Continue\n                    "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: _vm.$imageService.gameImage(_vm.draw.game_image)
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [_vm._v("Name")]),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.draw.game_name) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [_vm._v("Date")]),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.draw.date) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [_vm._v("Type")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { class: ["badge", "badge-" + _vm.drawType.color] },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.drawType.type) +
                            "\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [_vm._v("Is Parsed")]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fa",
                      class: {
                        "fa-check text-success": !!_vm.draw.ready_for_review,
                        "fa-times text-danger": !_vm.draw.ready_for_review
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [_vm._v("Created At")]),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.utcToLocal(_vm.draw.created_at)) +
                        "\n                "
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("draw-review-group-item", {
              attrs: {
                items: _vm.allLines,
                "selected-items": _vm.selectedLines
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
      _vm._v(" "),
      _c("approve-modal-component", {
        attrs: {
          show: _vm.isApproveModalOpen,
          "is-loading": _vm.isApproveModalLoading,
          "is-all-selected": _vm.isAllSelected,
          "all-lines": _vm.allLines,
          "selected-lines": _vm.selectedLines
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mr-2", attrs: { href: "/games/draws/review" } },
      [_c("i", { staticClass: "fa fa-chevron-left" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }