<template>
    <div class="container-fluid order-preview">
        <div class="card card-search">
            <div v-if="!isLoading" class="card-header d-flex justify-content-between align-items-center">
                <a :href="backLink" class="text-white">
                    <i class="fa fa-arrow-left" aria-hidden="false"></i> {{ backLinkTitle }}
                </a>
                <div v-if="isCompleted">
                    <button v-if="$can('Player - Send Order Details')"
                            class="btn btn-sm btn-light mr-2"
                            @click="onToggleEmailModalEvent(true)"
                    >
                        <i class="fa fa-envelope" aria-hidden="false"></i> Send Order Details
                    </button>
                    <button v-if="$can('Player - Export Order to PDF')"
                            class="btn btn-sm btn-light"
                            @click="onExportPdfEvent"
                    >
                        <span v-if="!isExportLoading">
                            <i class="fa fa-file-pdf" aria-hidden="false"></i> Export PDF
                        </span>
                        <loader :show="isExportLoading"
                                type="smaller"
                        />
                    </button>
                </div>
            </div>

            <div class="card-body p-0">
                <atbl-tabs v-if="!isLoading" class="order-preview-tabs">
                    <atbl-tab title="Order" class="px-3">
                        <order-info :order="order"
                                    :is-order-free="isOrderFree"
                                    @checkout="checkoutEvent"
                        />
                    </atbl-tab>
                    <atbl-tab title="Tickets" class="px-3">
                        <tickets :tickets="tickets" :order="order" />
                    </atbl-tab>
                    <atbl-tab v-if="!isOrderFree" title="Transactions" class="px-3">
                        <transactions :transactions="transactions" :order="order" />
                    </atbl-tab>
                    <atbl-tab v-if="isOrderFree" disabled class="px-3">
                        <template #title>
                            <span class="badge badge-danger">
                                Free
                            </span>
                        </template>
                    </atbl-tab>
                </atbl-tabs>

                <loader :show="isLoading" type="small" />
            </div>
        </div>

        <send-email-modal-component v-if="!isLoading"
                                    :client="order.player"
                                    :show="sendEmailModalOpened"
                                    :send-email-configuration="emailTemplatesEnabledTypes"
                                    :email-template-data="emailTemplateData"
                                    @toggle="onToggleEmailModalEvent"
        />

        <order-payment-modal v-if="canExecutePayment && checkout" :order="order" v-on:payment-successful="getOrder" v-on:cancel="checkout = false" />
    </div>
</template>

<script>
    import Tickets from "./Sections/Tickets";
    import OrderInfo from "./Sections/OrderInfo";
    import Transactions from './Sections/Transactions';
    import Orders from '../../../constants/Orders';
    import OrderPaymentModal from './Components/OrderPaymentModal';

    import SendEmailModalComponent from "../../../components/SendEmail/SendEmailModal/SendEmailModalComponent"
    import SendEmailModal from "../../../components/SendEmail/SendEmailModal/SendEmailModal"
    import SendEmail from "../../../components/SendEmail/SendEmail"
    import SendEmailConstants from "../../../components/SendEmail/SendEmailConstants"
    import AtblTab from '../../../components/AtblTabs/AtblTab.vue';
    import AtblTabs from '../../../components/AtblTabs/AtblTabs.vue';
    import Download from '../../../modules/download/Download';
    import moment from 'moment/moment';
    import utcToLocal from '../../../filters/UtcToLocal';
    import Loader from '../../../components/Widgets/Loader.vue';


    export default {
        props: ['id'],
        mixins: [
            SendEmail,
            SendEmailModal,
        ],
        components: {
            OrderPaymentModal,
            OrderInfo,
            Tickets,
            Transactions,
            SendEmailModalComponent,
            AtblTabs,
            AtblTab,
            Loader,
        },
        data () {
            return {
                isLoading: false,
                isExportLoading: false,
                playerId: null,
                from: null,
                order: {
                    order_items: [],
                    player: {
                        id: null,
                        name: null,
                        surname: null
                    },
                    total: 0,
                    discount: 0,
                    tickets: [],
                    raffle_tickets: [],
                    syndicate_shares: [],
                    user: {
                        id: null,
                        name: ''
                    },
                    status: null,
                    reference_id: null,
                    transactions: []
                },
                checkout: false,
                emailTemplateData: null
            }
        },
        computed: {
            backLink: function () {
                const from = this.from ? this.from : "orders"
                return this.playerId ? `/players/${this.playerId}/${from}` : "/orders";
            },
            backLinkTitle: function () {
                return this.playerId && this.order ? `${this.order.player.name} ${this.order.player.surname}` : "Orders";
            },
            tickets() {
                if (this.isObjectEmpty(this.order)) {
                    return [];
                }

                const tickets = this.order.tickets.map((t) => {
                    return {
                        id: t.id,
                        game: t.game,
                        order_id: t.order_id,
                        date: t.date,
                        lines_count: t.draws[0].lines_count,
                        winnings: t.draws[0].win !== null ? `${this.formatCurrency(t.draws[0].win)}` : 'Not drawn yet',
                        url: `/ticket/${t.id}`
                    };
                });

                const raffleTickets = this.order.raffle_tickets.map((t) => {
                    return {
                        id: t.id,
                        game: t.game,
                        order_id: t.order_id,
                        date: t.draw_date,
                        lines_count: 1,
                        winnings: t.is_parsed ? `${this.formatCurrency(t.prize)}` : 'Not drawn yet',
                        url: `/raffle-tickets/${t.id}`
                    };
                });

                const syndicates = this.order.syndicate_shares.map((t) => {
                    const prizePerShare = t.syndicate_item_draw.total_prize / t.syndicate_item_draw.syndicate_item.total_shares;

                    return {
                        id: t.id,
                        game: t.syndicate_item_draw.syndicate_item.syndicate.game,
                        order_id: t.order_id,
                        date: this.$options.filters.dateFormat(t.syndicate_item_draw.cut_off_at, 'YYYY-MM-DD'),
                        lines_count: t.shares,
                        winnings: t.syndicate_item_draw.total_prize !== null ? `${this.formatCurrency(prizePerShare * t.shares)}` : 'Not drawn yet',
                        url: `/syndicate-shares/${t.id}`,
                        is_lottery: false,
                        is_raffle: false,
                        is_syndicate: true,
                    };
                });

                return tickets.concat(raffleTickets.concat(syndicates));
            },
            transactions: function () {
                return this.order.transactions;
            },
            canExecutePayment () {
                return this.order.player.id &&
                    [
                        Orders.status.PENDING,
                        Orders.status.PAYMENT_FAILED
                    ].includes(this.order.status);
            },
            isOrderFree () {
                if(!this.order){
                    return false;
                }

                return !!this.order.is_free;
            },
            isCompleted () {
                return this.order.status.toLowerCase() === "completed"
            }
        },
        methods: {
            async getOrder () {
                this.checkout = false;
                this.isLoading = true;
                window.axios.get(`/orders/${this.id}/view`)
                    .then((response) => {
                        this.order = response.data;
                        this.emailTemplateData.order = response.data
                        this.isLoading = false;
                    })
                    .catch(error => {
                        if (this.hasPermissionError(error)) {
                            return;
                        }
                        const { message } = error;
                        this.isLoading = false;
                        window.showMessage(message,false);
                    });
            },
            async onExportPdfEvent() {
                if (! this.$can('Player - Export Order to PDF')) {
                    return window.showMessage("You don't have permission to export PDF", false);
                }
                
                this.isExportLoading = true;
                
                try {
                    const response = await window.axios.get(`/orders/${this.id}/export-to-pdf`, {
                        responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': 'application/json',
                            'Content-Disposition': 'attachment; filename="orders.pdf"'
                        }
                    });
                    
                    Download.downloadFile(new Blob([response.data]), `order_${this.order.player.name}_${this.order.player.surname}_${utcToLocal(this.order.created_at)}.pdf`);
                } catch (error) {
                    const { message } = error;
                    window.showMessage(message,false);
                } finally {
                    this.isExportLoading = false;
                }
            },
            formatCurrency (val) {
                return this.$options.filters.formatCurrency(val);
            },
            checkoutEvent (value) {
                this.checkout = value;
            }
        },
        async created() {
            await this.getOrder();

            const playerId = this.getParam("playerId"),
                  from = this.getParam("from");

            if (playerId) {
                this.playerId = Number(playerId);
                this.from = from;
            }

            this.emailTemplatesEnabledTypes = {
                playersEnabled: true,
                leadsEnabled: false,
                is_lead: false
            };

            this.emailTemplateData = {
                templateKey: 'OrderDetailsSummary',
                order: this.order
            }

            window.Atbl.$on(SendEmailConstants.key, this.onToggleEmailModalEvent);
        }
    }
</script>