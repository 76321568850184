var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Remap Preview",
        value: _vm.isOpen,
        loader: !!_vm.isLoading
      },
      on: { close: _vm.toggleRemapPlayers },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger",
                  attrs: { disabled: !!_vm.isLoading },
                  on: { click: _vm.toggleRemapPlayers }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success",
                  on: { click: _vm.remapPlayers }
                },
                [
                  !_vm.isLoading
                    ? _c("span", [
                        _vm._v("\n                Remap\n            ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: !!_vm.isLoading, type: "small" }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !!_vm.errors.length
        ? _vm._l(_vm.errors, function(error, index) {
            return _c(
              "div",
              { key: index, staticClass: "alert alert-danger" },
              [_vm._v("\n            " + _vm._s(error) + "\n        ")]
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "text-center" }, [
        _vm._v("\n        Remap players from "),
        _c("u", [_vm._v(_vm._s(_vm.model.dateFrom))]),
        _vm._v(" to "),
        _c("u", [_vm._v(_vm._s(_vm.model.dateTo))]),
        _vm._v(".\n    ")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }