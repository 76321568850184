<template>
    <player-layout :id="id" icon="fa fa-ticket-alt" title="Bundle Tickets">
        <template slot="first">
            <player-bundle-tickets-search-form v-on:search="search" />
        </template>

        <section class="general-list agents">
            <div class="row">
                <div class="col text-muted">Ticket</div>
                <div class="col text-muted text-center">Created By</div>
                <div class="col text-muted text-center">Bundle</div>
                <div class="col text-muted text-center">Logo</div>
                <div class="col text-muted text-center">Order</div>
                <div class="col text-muted text-center">Weeks</div>
                <div class="col text-muted text-center">Winnings</div>
            </div>

            <div v-if="!isLoading" v-for="(bundleTicket, index) in bundleTickets" :key="index" class="row">
                <div class="col align-self-center">
                    <a :href="`/bundle-tickets/${bundleTicket.id}`">BT# {{ bundleTicket.id }}</a>
                    <button class="btn btn-sm btn-link p-0" v-tooltip="{content: 'Copy to clipboard'}" @click="copyToClipboard(bundleTicket.id)">
                        <i class="fa fa-copy" aria-hidden="false"></i>
                    </button>
                    <div>
                        <small class="text-muted">{{ utcToLocal(bundleTicket.created_at) }}</small>
                    </div>
                </div>

                <div class="col align-self-center text-center">
                    <created-by-badge :created="bundleTicket.user_name" />
                </div>

                <div class="col text-center align-self-center">
                    <a :href="`/bundles/${bundleTicket.bundle_id}/edit`" v-if="$can('Bundles - Create/Update')">
                        S#{{ bundleTicket.bundle_id }} <br/> {{ bundleTicket.title }}
                    </a>
                    <span v-else>
                        S#{{ bundleTicket.bundle_id }} <br/> {{ bundleTicket.title }}
                    </span>
                </div>

                <div class="col text-center align-self-center">
                    <img :src="getBundleLogoUrl(bundleTicket.bundle_id)" :alt="`${bundleTicket.title}`" class="game-thumb" />
                </div>

                <div class="col text-center align-self-center">
                    <a v-if="$can('Orders - View Order Details')"
                       :href="`/orders/${bundleTicket.order_id}?playerId=${bundleTicket.player_id}&from=bundle-tickets`"
                    >
                        O# {{ bundleTicket.order_id }}
                    </a>
                    <span v-else>
                        O# {{ bundleTicket.order_id }}
                    </span>
                </div>

                <div class="col text-center align-self-center">
                    {{ bundleTicket.weeks }}
                </div>

                <div class="col text-center align-self-center">
                    {{ formatCurrency(bundleTicket.total_prize || 0) }}
                </div>
            </div>

            <loader :show="isLoading" type="small" />

            <not-found v-if="!isLoading" msg="No tickets found." :items="bundleTickets" />

            <nav class="wrap py-3" v-if="!isLoading && bundleTicketsResponse.last_page > 1">
                <atbl-pagination :pagination="bundleTicketsResponse" @paginate="getBundleTickets" />
            </nav>
        </section>
    </player-layout>
</template>

<script>
import Transaction from '../../../Orders/Mixins/Transaction';
import BundleTicketsService from '../../../../services/BundleTicketsService';
import BundleService from '../../../../services/BundleService';
import PlayerBundleTicketsSearchForm from './Sections/PlayerBundleTicketsSearchForm';
import formatCurrency from '../../../../filters/FormatCurrency';

export default {
    name: 'player-bundle-tickets',
    components: { PlayerBundleTicketsSearchForm },
    mixins: [
        Transaction
    ],
    props: ['id'],
    data() {
        return {
            bundleTickets: [],
            bundleTicketsResponse: {},
            isLoading: false
        };
    },
    mounted() {
        this.getBundleTickets();
    },
    methods: {
        formatCurrency,
        getBundleTickets(page = 1) {
            this.isLoading = true;
            const params = {
                page: page,
                ...(this.searchData && this.searchData.ticketId && { ticketId: this.searchData.ticketId }),
                ...(this.searchData && this.searchData.drawDate && { drawDate: this.searchData.drawDate }),
                ...(this.searchData && this.searchData.games && { games: this.searchData.games }),
            };

            BundleTicketsService.playerBundleTickets(this.id, params)
                .then((response) => {
                    this.bundleTickets = response.data.data;
                    this.bundleTicketsResponse = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        search(data) {
            this.searchData = data;
            this.getBundleTickets(1);
        },
        getBundleLogoUrl(bundleId) {
            return BundleService.makeLogoPreviewUrl(bundleId);
        },
    }
}
</script>
