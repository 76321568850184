var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atbl-modal" }, [
    _c(
      "div",
      {
        class: _vm.modalClasses,
        attrs: { tabindex: "-1", role: "dialog" },
        on: { click: _vm.onGeneralModalCloseEvent }
      },
      [
        _c("div", { class: _vm.dialogClasses, attrs: { role: "document" } }, [
          _c(
            "div",
            { class: _vm.contentClasses },
            [
              _vm._t("header-wrapper", function() {
                return [
                  _c(
                    "header",
                    { class: _vm.headerClasses },
                    [
                      _vm._t("header", function() {
                        return [
                          _vm._t("header-title", function() {
                            return [
                              _c("h5", { staticClass: "modal-title" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.title) +
                                    "\n                "
                                )
                              ])
                            ]
                          }),
                          _vm._v(" "),
                          _vm.showClose
                            ? _c(
                                "button",
                                {
                                  staticClass: "close",
                                  attrs: {
                                    type: "button",
                                    "aria-label": "Close"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHideEvent($event)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-times" })]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]
              }),
              _vm._v(" "),
              _vm._t("body-wrapper", function() {
                return [
                  _c("div", { class: _vm.bodyClasses }, [_vm._t("default")], 2)
                ]
              }),
              _vm._v(" "),
              _vm._t("footer-wrapper", function() {
                return [
                  !!_vm.$slots.footer
                    ? _c(
                        "footer",
                        { class: _vm.footerClasses },
                        [_vm._t("footer")],
                        2
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ])
      ]
    ),
    _vm._v(" "),
    _vm.backdrop && (_vm.visible || _vm.isTransitioning)
      ? _c("div", { class: _vm.backdropClasses })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }