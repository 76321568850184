<script>
    export default {
        getPlayerWithDispositions: function(id){
            return `/player/${id}/dispositions`;
        },
        getPlayerDispositions: function(id, _params = {}){
            return window.axios.get(`${this.getPlayerWithDispositions(id)}`, _params);
        },
        getPlayerLatestDispositions: function(id, _params = {}){
            return window.axios.get(`/players/${id}/last-dispositions`, _params);
        },
        addPlayerDisposition: function(model){
            return window.axios.post(`${this.getPlayerWithDispositions(model.player_id)}`, model);
        }
    }
</script>