var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: {
        id: "campaign-status",
        label: "Status",
        "label-for": "campaignStatus",
        horizontal: _vm.horizontal,
        error: _vm.getError(_vm.errors, "status")
      }
    },
    [
      _c(
        "select",
        {
          staticClass: "form-control",
          attrs: {
            id: "campaignStatus",
            disabled: _vm.isLoading || _vm.disabled
          },
          on: { change: _vm.changed }
        },
        [
          _c(
            "option",
            {
              attrs: { disabled: _vm.disableFirstOption },
              domProps: { value: null }
            },
            [_vm._v("Select status")]
          ),
          _vm._v(" "),
          _vm._l(_vm.statuses, function(item, index) {
            return _c(
              "option",
              {
                key: index,
                domProps: { value: item, selected: item === _vm.value }
              },
              [_vm._v("\n            " + _vm._s(item) + "\n        ")]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }