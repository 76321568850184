<template>
    <form @submit.prevent="search">
        <div class="card card-search mb-3">
            <div class="card-header">
                <i class="fa fa-search" aria-hidden="false"></i> Search
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <atbl-input id="transaction"
                                    label="Transaction Number"
                                    class="font-weight-bold"
                                    type="text"
                                    placeholder="Transaction Number"
                                    v-model="filters.transaction"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <payment-types-select v-model="filters.paymentType"
                                              :errors="{}"
                        />
                    </div>
                    <div v-if="!getIsOnlyAgent && $can('Users - Get List')" class="col-12 col-lg-3">
                        <gateway-select v-model="filters.gateway"/>
                    </div>
                    <div class="col-12 col-lg-3">
                        <atbl-select :options="transactionStatuses"
                                     v-model="filters.transactionStatus"
                                     name="transactionStatus"
                                     label="Status"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <atbl-input id="transaction"
                                    label="Reference transaction id"
                                    class="font-weight-bold"
                                    type="text"
                                    placeholder="Reference transaction id"
                                    v-model="filters.referenceTransactionId"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <atbl-form-group label="For Date"
                                         label-for="For Date"
                                         class="font-weight-bold"
                                         :horizontal="false"
                        >
                            <multi-date-picker v-model="selectedDates"
                                               :auto-apply="true"
                                               :show-ranges="true"
                                               format="YYYY-MM-DD"
                            />
                        </atbl-form-group>
                    </div>
                    <div class="col-12 col-lg-3">
                        <atbl-form-group label="Created by"
                                         label-for="createdBy"
                                         class="font-weight-bold"
                        >
                            <tree-select v-model="selectedCreatedBy"
                                         :multiple="true"
                                         :searchable="true"
                                         :clear-on-select="true"
                                         :options="createdByOptions"
                                         id="createdBy"
                            />
                        </atbl-form-group>
                    </div>
                    <div v-if="!getIsOnlyAgent && $can('Users - Get List')" class="col-12 col-lg-3">
                        <multi-agents-picker v-model="selectedAgent"/>
                    </div>
                    <div v-if="more" class="col-12 col-lg-3">
                        <atbl-input id="firstName"
                                    label="Name"
                                    class="font-weight-bold"
                                    type="text"
                                    placeholder="First Name"
                                    v-model="filters.name"
                        />
                    </div>
                    <div v-if="more" class="col-12 col-lg-3">
                        <atbl-input id="surname"
                                    label="Surname"
                                    class="font-weight-bold"
                                    type="text"
                                    placeholder="Surname"
                                    v-model="filters.surname"
                        />
                    </div>
                    <div v-if="more" class="col-12 col-lg-3">
                        <atbl-input id="order"
                                    label="Order Number"
                                    class="font-weight-bold"
                                    type="text"
                                    placeholder="Order Number"
                                    v-model="filters.order"
                        />
                    </div>
                    <div v-if="more" class="col-12 col-lg-3">
                        <compare-number-select :value="[filters.amount, filters.amountSelector]"
                                               label="Amount"
                                               name="amount"
                                               @update="onUpdateCompareNumber"
                        />
                    </div>
                </div>
            </div>
            
            <div class="card-footer d-flex justify-content-between align-items-center">
                <div>
                    <button :disabled="!isSearchFormEnabled"
                            type="submit"
                            class="btn btn-sm btn-primary"
                    >
                        <i class="fa fa-search"></i> Search
                    </button>
                    <button type="reset"
                            class="btn btn-sm btn-danger"
                            @click="clearFilters"
                    >
                        <i class="fa fa-ban"></i> Clear
                    </button>
                </div>
                <div class="d-flex">
                    <div class="export-controls mr-1"
                         v-if="$can('Transactions - Export Excel')"
                    >
                        <b title="Export As"
                           class="mr-1"
                        >
                            <i class="fa fa-download"></i> Export:
                        </b>
                        <button type="button"
                                class="btn btn-sm btn-success"
                                :disabled="isExporting"
                                @click.prevent="exportFile"
                                title="EXCEL Document"
                                v-if="$can('Transactions - Export Excel')"
                        >
                        <span v-if="!isExporting">
                            <i class="fa fa-file-excel"></i> EXCEL
                        </span>
                            <loader :show="isExporting"
                                    type="smaller"
                            />
                        </button>
                    </div>
                    <button type="reset"
                            class="btn btn-sm btn-outline-primary"
                            @click.prevent="more = !more"
                    >
                        {{ more ? 'Less' : 'More' }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import moment from 'moment';
    import {clone} from '../../../../utils/Json/Json';
    import TransactionSearchModel from './TransactionSearchModel';
    import Date from '../../../../helpers/Date';
    import GatewaySelect from '../../../../components/Forms/Select/GatewaySelect';
    import QueryToObject from '../../../../utils/Url/QueryToObject';
    import {pick} from 'lodash';
    import {filterNonEmpty} from '../../../../utils';
    import RemoveUndefinedProperties from '../../../../utils/Object/RemoveUndefinedProperties';
    import CompareNumberSelect from '../../../../components/Forms/Select/CompareNumberSelect.vue';
    import TransactionsSearchValidation from './TransactionsSearchValidation';
    
    export default {
        name: 'transactions-search',
        emits: [
            'search',
            'clear',
        ],
        mixins: [
            TransactionsSearchValidation,
        ],
        components: {
            CompareNumberSelect,
            GatewaySelect,
        },
        data() {
            return {
                filters: clone(TransactionSearchModel),
                transactionStatuses: [
                    {
                        label: 'Success',
                        value: 'completed',
                    },
                    {
                        label: 'Failed',
                        value: 'failed',
                    },
                    {
                        label: 'Pending',
                        value: 'pending',
                    }
                ],
                createdByOptions: [
                    {
                        id: 'agents',
                        label: 'Agents',
                    },
                    {
                        id: 'web',
                        label: 'Web',
                    },
                    {
                        id: 'system',
                        label: 'System',
                    }
                ],
                availableGateways: [],
                more: false,
                isExporting: false,
            };
        },
        computed: {
            isAgentsSelected() {
                return this.filters.searchType.includes('agents');
            },
            selectedAgent: {
                get() {
                    return this.filters.agents;
                },
                set(value) {
                    this.filters.agents = value;
                    
                    const searchType = !this.isAgentsSelected
                      ? [...this.filters.searchType, 'agents']
                      : this.filters.searchType;
                    
                    this.filters.searchType = !!value.length
                      ? searchType
                      : this.filters.searchType.filter(item => item !== 'agents');
                }
            },
            selectedCreatedBy: {
                get() {
                    return this.filters.searchType;
                },
                set(value) {
                    this.filters.searchType = value;
                    
                    if (!this.isAgentsSelected) {
                        this.filters.agents = [];
                    }
                }
            },
            selectedDates: {
                get() {
                    if (!this.filters.dateFrom && !this.filters.dateTo) {
                        return null;
                    }
                    return {
                        startDate: this.filters.dateFrom,
                        endDate: this.filters.dateTo
                    };
                },
                set(value) {
                    Object.assign(this.filters, {
                        dateFrom: !!value.startDate
                          ? moment(value.startDate).format(Date.defaultDate)
                          : null,
                        dateTo: !!value.endDate
                          ? moment(value.endDate).format(Date.defaultDate)
                          : null
                    });
                }
            },
            isSearchModelEmpty() {
                return !filterNonEmpty(this.filters).length;
            },
            isSearchFormEnabled() {
                return !this.isSearchModelEmpty && !!this.isSearchFormValid;
            },
        },
        created() {
            this.initializeSearchForm();
        },
        methods: {
            initializeSearchForm() {
                const searchParams = QueryToObject();
                const searchModel = pick(searchParams, Object.keys(this.filters));
                const otherParams = pick(searchParams, ['sort', 'paginate', 'page']);
                
                if (this.isObjectEmpty(searchModel) && this.isObjectEmpty(otherParams)) {
                    return;
                }
                
                Object.assign(this.filters, searchModel);
                
                this.search({
                    searchModel,
                    otherParams
                });
            },
            search(data) {
                const filters = data instanceof Event
                  ? this.filters
                  : data;
                
                const agents = !!filters.hasOwnProperty('searchModel')
                  ? filters.searchModel.agents
                  : filters.agents;
                
                if (!!this.isAgentsSelected && !agents.length) {
                    this.filters.searchType
                      = filters.searchType
                      = filters.searchType.filter(item => item !== 'agents');
                }
                
                this.$emit('search', RemoveUndefinedProperties(filters));
            },
            clearFilters() {
                this.filters = clone(TransactionSearchModel);
                this.$emit('clear');
            },
            searchType(event) {
                let value = event.target.value;
                
                this.filters.searchType = value ? value : null;
                if (this.filters.searchType !== 'agents') {
                    this.selectedAgent = null;
                }
            },
            exportFile() {
                const keys = Object.keys(this.filters).filter(item => {
                    if (!!this.filters[item]) {
                        if (Array.isArray(this.filters[item])) {
                            return !!this.filters[item].length;
                        }
                        return this.filters[item];
                    }
                    return false;
                });
                
                let params = {};
                for (const key in keys) {
                    const keyName = keys[key];
                    params = {
                        ...params,
                        ...{
                            [keyName]: this.filters[keyName]
                        }
                    };
                }
                
                if (!filterNonEmpty(this.filters).length) {
                    window.showMessage('Search form cannot be empty.', false);
                    
                    return;
                }
                
                this.isExporting = true;
                window.axios.get(`/transactions/export-excel`,
                    {
                        params,
                        responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': 'application/json',
                            'Content-Disposition': 'attachment; filename="transactions.xlsx"'
                        }
                    })
                  .then(response => {
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', `transactions-${moment().format('YYYY-MM-DD HH-mm-ss')}.xlsx`); //or any other extension
                      document.body.appendChild(link);
                      link.click();
                      
                      document.body.removeChild(link);
                  })
                  .catch((error) => {
                      let message = error.message;
                      
                      if (error.response.status === 400) {
                          message = 'Too many transactions to be processed.';
                      }
                      
                      window.showMessage(message, false);
                  })
                  .finally(() => {
                      this.isExporting = false;
                  });
            },
            onUpdateCompareNumber(name, value, selector, error) {
                Object.assign(this.filters, {
                    [name]: value,
                    [`${name}Selector`]: selector
                });
                
                this.updateSearchModelError(name, error);
            },
        },
        watch: {
            filters: {
                handler() {
                    this.validateSearchForm();
                },
                deep: true,
            },
        },
    };
</script>
