var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mb-4" }, [
    _c(
      "div",
      { staticClass: "col-12 col-lg-6 col-xl-3" },
      [
        _c("call-history-statistic-card", {
          staticClass: "border-primary",
          attrs: {
            value: _vm.statistics.dailyTalkTime,
            loader: _vm.isLoading,
            title: "Daily Talk Time"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-lg-6 col-xl-3" },
      [
        _c("call-history-statistic-multiple-card", {
          staticClass: "border-danger",
          attrs: {
            rightValue: _vm.statistics.leads,
            leftValue: _vm.statistics.players,
            loader: _vm.isLoading,
            title: "Daily Dials",
            rightTitle: "Leads",
            leftTitle: "Players"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-lg-6 col-xl-3" },
      [
        _c("call-history-statistic-card", {
          staticClass: "border-success",
          attrs: {
            value: _vm.statistics.completedCalls,
            loader: _vm.isLoading,
            title: "Completed Calls"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-lg-6 col-xl-3" },
      [
        _c("call-history-statistic-card", {
          staticClass: "border-warning",
          attrs: {
            description: _vm.statistics.description,
            value: _vm.statistics.averageTalkTime,
            loader: _vm.isLoading,
            title: "Average Daily Talk Time"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }