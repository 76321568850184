<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span><i class='fa fa-user-shield'></i> Roles</span>
                <div class="d-flex align-items-center">
                    <sort-by v-model="sortBy"
                             :options="sortByOptions"
                             class="mr-2"
                    />
                    <a v-if="$can('Roles - Create/Update')"
                       href="/roles/create"
                       class="btn btn-sm btn-primary"
                    >
                        Create
                    </a>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-1">Name</div>
                        <div class="col-12 col-lg">Permissions</div>
                        <div class="col-12 col-lg-1">Users</div>
                        <div class="col-12 col-lg-2"
                             v-if="$canAny(rolesOperationPermissions)"
                        >
                            Operations
                        </div>
                    </div>

                    <div v-if="!isLoading"
                         v-for="(item, index) in rolesList"
                         :key="index"
                         class="row"
                    >
                        <div class="col-12 col-lg-1 mb-1 mb-lg-0">
                            <span class="small-mb-badge">Name</span>
                            {{ item.name }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Permissions</span>
                            <div>
                                <span v-for="(item, index) in item.permissions"
                                      :key="index"
                                      class="badge badge-primary mr-1"
                                >
                                    {{ item.name }}
                                    <span class="badge badge-light ml-1">
                                        {{ item.count }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-lg-1 mb-1 mb-lg-0">
                            <span class="small-mb-badge">Permissions</span>
                            {{ item.users_count }}
                        </div>
                        <div class="col-12 col-lg-2"
                             v-if="$canAny(rolesOperationPermissions)"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <div class="d-flex align-items-center">
                                <a v-if="$can('Roles - Create/Update')"
                                   :href="`/roles/${item.id}/update`"
                                   class="btn btn-link btn-delete btn-sm mr-2"
                                >
                                    <i class="fa fa-pencil-alt"></i>
                                </a>
                                <remove-list-item-button v-if="$can('Roles - Remove')"
                                                         :value="item.id"
                                                         @confirmed="onDeleteRoleEvent"
                                />
                            </div>
                        </div>
                    </div>

                    <items-not-found v-if="!isLoading"
                                     :items="roles"
                                     msg="No roles found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RemoveListItemButton from '../../../components/Lists/RemoveListItemButton';
import Loader from '../../../components/Widgets/Loader';
import ItemsNotFound from '../../../components/Lists/ItemsNotFound';
import dynamicSort from '../../../utils/Array/DynamicSort';
import SortBy from '../../../components/SortBy/SortBy';

export default {
    components: {
        ItemsNotFound,
        Loader,
        RemoveListItemButton,
        SortBy,
    },
    data() {
        return {
            roles: [],
            isLoading: false,
            rolesOperationPermissions: [
                'Roles - Create/Update',
                'Roles - Remove'
            ],

            sortBy: {
                direction: 'asc',
                column: 'id',
            },
            sortByOptions: [
                {
                    value: 'id',
                    label: 'Id',
                },
                {
                    value: 'name',
                    label: 'Name',
                },
                {
                    value: 'users_count',
                    label: 'Users',
                }
            ],
        };
    },
    computed: {
        rolesList() {
            return dynamicSort(this.roles, this.sortBy.column, this.sortBy.direction);
        },
    },
    async created() {
        await this.fetchRoles();
    },
    methods: {
        async fetchRoles() {
            this.isLoading = true;

            try {
                const response = await window.axios.get('/roles/list');

                this.roles = response.data.map(role => {
                    const permissions = this.extractPermissions(role.permissions);

                    return {
                        ...role,
                        permissions
                    };
                });

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        async onDeleteRoleEvent(id) {
            this.isLoading = true;

            try {
                const response = await window.axios.delete(`/roles/${id}`);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    this.isLoading = false;

                    return;
                }

                await this.fetchRoles();

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
        extractPermissions(permissions) {
            const permissionNames = permissions.map(item => item.name.split(' - ')[0]);
            const unique = permissionNames.filter((a, b) => permissionNames.indexOf(a) === b);

            return unique.map(item => {
                return {
                    name: item,
                    count: permissionNames.filter(x => x === item).length
                };
            });
        },
    }
}
</script>
