<template>
    <div class="container-fluid">
        <player-bonuses-search class="mb-3" v-if="showFilterForm" @input="onSearch" />

        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <div><i class="fa fa-ticket-alt"></i> Player bonuses</div>
                <div>
                    <button class="btn btn-sm btn-outline-primary m-0" @click="showFilterForm = ! showFilterForm">
                        <i class="fa fa-filter" aria-hidden="false"></i>
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col text-muted">ID</div>
                        <div class="col text-muted">Player</div>
                        <div class="col text-muted">Bonus</div>
                        <div class="col text-muted">Assigned at</div>
                        <div class="col text-muted">Claimed at</div>
                        <div class="col text-muted">Accomplished at</div>
                        <div class="col text-muted">Revoked at</div>
                        <div class="col text-muted">Expiry date</div>
                    </div>

                    <template v-if="!isLoading">
                        <div class="row py-3" v-for="playerBonus in playerBonuses" :key="playerBonuses.uuid">
                            <div class="col align-self-center">
                                {{ playerBonus.uuid }}
                            </div>

                            <div class="col align-self-center">
                                {{ playerBonus.player.name }} {{ playerBonus.player.surname }}
                            </div>

                            <div class="col align-self-center">
                                {{ playerBonus.bonus.title }}
                            </div>

                            <div class="col align-self-center" v-html="formatPlayerBonusDate('assigned_at', playerBonus)"></div>

                            <div class="col align-self-center" v-html="formatPlayerBonusDate('claimed_at', playerBonus)"></div>

                            <div class="col align-self-center" v-html="formatPlayerBonusDate('accomplished_at', playerBonus)"></div>

                            <div class="col align-self-center" v-html="formatPlayerBonusDate('revoked_at', playerBonus)"></div>

                            <div class="col align-self-center" v-html="formatPlayerBonusDate('expires_at', playerBonus)"></div>
                        </div>
                    </template>

                    <loader :show="isLoading" type="small" />

                    <not-found v-if="!isLoading" msg="No bonuses assigned to any player." :items="playerBonuses" />
                </div>
            </div>

            <div v-if="!isLoading && playerBonusesResponse && playerBonusesResponse.last_page > 1" class="card-footer">
                <nav class="wrap">
                    <atbl-pagination :pagination="playerBonusesResponse" @paginate="onPageChange" />
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import PlayerBonusService from '../../../services/PlayerBonusService';
import PlayerBonusesSearch from './Components/PlayerBonusesSearch';

export default {
    name: 'player-bonuses-index',

    components: { PlayerBonusesSearch },

    data() {
        return {
            isLoading: false,
            showFilterForm: true,
            playerBonuses: [],
            playerBonusesResponse: null,
            page: 1,
            filters: {},
        };
    },

    mounted() {
        this.getPlayerBonuses();
    },

    methods: {
        getPlayerBonuses() {
            const params = {
                page: this.page,
                limit: this.filters.perPage || 25,
                ...(this.filters.name) && { name: this.filters.name },
                ...(this.filters.surname) && { surname: this.filters.surname },
                ...(this.filters.bonuses && this.filters.bonuses.length) && { bonuses: this.filters.bonuses },
            };

            this.isLoading = true;
            PlayerBonusService.index(params)
                .then((response) => {
                    this.playerBonusesResponse = response.data;
                    this.playerBonuses = response.data.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        formatPlayerBonusDate(dateType, playerBonus) {
            const date = playerBonus[dateType];

            if (!date) {
                return '/';
            }

            const dateString = this.$options.filters.dateFormat(date, 'YYYY-MM-DD');
            const timeString = this.$options.filters.dateFormat(date, 'HH:mm');

            return `${dateString}<br/>${timeString}`;
        },

        onSearch(filters) {
            this.filters = filters;
            this.getPlayerBonuses();
        },

        onPageChange(page) {
            this.page = page;
            this.getPlayerBonuses();
        },
    },
}
</script>