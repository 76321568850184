<template>
    <div class="player-play-picker-lines">
        <div class="line-control">
            <div>
                <button class="add-line"
                        :disabled="hasSelectedFreeGame"
                        @click="addLine(1)"
                >
                <span class="add-line-text">
                    <i class="fa fa-plus" aria-hidden="false"></i> Add Line
                </span>
                </button>
                <button class="add-line"
                        :disabled="hasSelectedFreeGame"
                        @click="addLine(5)"
                >
                <span class="add-line-text">
                    <i class="fa fa-plus" aria-hidden="false"></i> Add 5 Lines
                </span>
                </button>
                <button class="add-line"
                        :disabled="hasSelectedFreeGame"
                        @click="addLine(10)"
                >
                <span class="add-line-text">
                    <i class="fa fa-plus" aria-hidden="false"></i> Add 10 Lines
                </span>
                </button>
                <span v-if="hasSelectedFreeGame"
                      class="text-danger"
                >
                    {{ selectedFreeGame.meta.lines }} Free Line{{ selectedFreeGame.meta.lines > 1 ? 's' : '' }}
                </span>
                <span v-if="lineErrors.length || gameErrors.length" class="line-message">
                    <template v-if="lineErrors.length">
                        {{ `Line #${lineErrors[0].line}: ${lineErrors[0].errMessage}` }} <br />
                    </template>
                    <template v-for="gameError in gameErrors">
                        {{ gameError }} <br />
                    </template>
                </span>
            </div>
            <div v-if="!hasSelectedFreeGame && lines.length > 1">
                <a v-if="!removeAllConfirm"
                   href="#"
                   class="remove-all-lines"
                   @click.prevent="removeAllConfirm = true"
                >
                    <i class="fa fa-trash"></i> Remove All
                </a>
                <a v-if="removeAllConfirm"
                   href="#"
                   class="remove-all-confirm"
                   @click.prevent="onRemoveAllLinesEvent"
                >
                    <i class="fa fa-check"></i>
                </a>
                <a v-if="removeAllConfirm"
                   href="#"
                   class="remove-all-cancel"
                   @click.prevent="removeAllConfirm = false"
                >
                    <i class="fa fa-times"></i>
                </a>
            </div>
        </div>

        <div v-for="(line, index) in lines" :key="index" class="player-play-picker-lines-line" :class="{'error': !!getLineError(line)}">
            <span class="generated-number"># {{ index + 1 }}</span>
            <button class="lucky-dip" @click="luckyDip(line)">Lucky Dip</button>

            <div class="player-play-picker-lines-line-numbers" :class="{'disabled': line.random}" v-tooltip="{content: 'Main numbers'}">
                <input type="text" class="form-control" v-on:keypress="onMainNumberKeyPress($event)" v-model.number="line.numbers[0]" @keypress="isNumber($event)" />
                <input type="text" class="form-control" v-on:keypress="onMainNumberKeyPress($event)" v-model.number="line.numbers[1]" @keypress="isNumber($event)" />
                <input type="text" class="form-control" v-on:keypress="onMainNumberKeyPress($event)" v-model.number="line.numbers[2]" @keypress="isNumber($event)" />
                <input type="text" class="form-control" v-on:keypress="onMainNumberKeyPress($event)" v-model.number="line.numbers[3]" @keypress="isNumber($event)" />
                <input type="text" class="form-control" v-on:keypress="onMainNumberKeyPress($event)" v-model.number="line.numbers[4]" @keypress="isNumber($event)" />
            </div>

            <div class="player-play-picker-lines-line-numbers" v-c-tooltip="{content: 'Fractions (max 10)'}">
                <input :value="line.fractions"
                       :disabled="hasSelectedFreeGame"
                       :min="1"
                       :max="10"
                       type="number"
                       class="form-control fractions-input"
                       @input="onUpdateLineFraction($event, line, index)"
                       @blur="onBlurLineFraction($event, line, index)"
                />
            </div>

            <input type="checkbox" :checked="line.random" v-tooltip="{content: 'Random'}" @change="onRandomCheckboxChange($event, line)" />

            <button v-if="lines.length > 1 && !hasSelectedFreeGame"
                    class="remove-line"
                    @click="removeLine(index)"
            >
                <i class="fa fa-times" aria-hidden="false"></i>
            </button>
        </div>
    </div>
</template>

<script>
import {clone} from '../../../../../../../utils/Json/Json';

export default {
    name: 'raffle-lines',
    props: {
        value: {
            default: [],
        },
        gameKey: {
            type: String,
            required: true,
        },
        selectedFreeGame: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            lines: this.value,
            removeAllConfirm: false,
        };
    },
    computed: {
        gameErrors() {
            const cartNumbers = [].concat.apply([], this.cartRaffleTickets.map(i => {
                const item = JSON.parse(i.item);

                return item.lines.map(l => {
                    return l.numbers.join('');
                }).filter(l => l);
            }));

            let errors = [];
            const numbers = this.lines.map(l => l.numbers.join('')).filter(n => n);

            // Check for same number combination
            const hasLocalDuplicates = numbers.filter((item, i) => numbers.indexOf(item) !== i);
            const hasCartDuplicates = numbers.filter((item, i) => cartNumbers.indexOf(item) !== -1);

            if (hasLocalDuplicates.length) {
                errors.push('Merge duplicated lines into one');
            }

            if (hasCartDuplicates.length) {
                errors.push('Ticket number already exists in cart');
            }

            return errors;
        },
        cartRaffleTickets() {
            return this.$store.state.cart.cartData.items.filter(i => {
                const item = JSON.parse(i.item);

                return item.type === this.gameKey;
            });
        },
        lineErrors() {
            return this.lines.map((line, index) => {
                return {
                    line: index + 1,
                    errMessage: this.getLineError(line)
                }
            }).filter(l => l.errMessage);
        },
        hasSelectedFreeGame() {
            return !this.isObjectEmpty(this.selectedFreeGame);
        },
    },
    watch: {
        value: {
            deep: true,
            handler: function (val) {
                this.lines = val;
            },
        },
        lines: {
            deep: true,
            handler: function (val) {
                this.$emit('input', val);
            }
        },
        lineErrors() {
            return this.$emit('error', [
                ...this.lineErrors,
                ...this.gameErrors,
            ])
        },
        gameErrors() {
            return this.$emit('error', [
                ...this.lineErrors,
                ...this.gameErrors,
            ])
        },
    },
    methods: {
        addLine(lines = 1) {
            for(let i = 0; i < lines; i++) {
                this.lines.push(
                    {
                        type: this.gameKey,
                        random: true,
                        numbers: [],
                        fractions: 1,
                    },
                );
            }
            this.$scrollToBottom();
        },
        removeLine(index) {
            this.lines.splice(index, 1);
        },
        onRemoveAllLinesEvent() {
            this.lines = [];
            this.addLine();
            this.removeAllConfirm = false;
        },
        getLineError(line) {
            const lineNumbers = line.numbers.filter(n => n === 0 || n);

            if (line.random) {
                return null;
            }

            if (lineNumbers.length < 5) {
                return 'Fill all the numbers';
            }

            const numbersWithinRange = lineNumbers.filter(n => n >= 0 && n <= 9);
            if (numbersWithinRange.length < 5) {
                return 'All numbers should be within range 0 to 9';
            }

            return null;
        },
        isNumber(e) {
            const charCode = e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        luckyDip(line) {
            line.numbers = this.randomizer();
            line.random = false
        },
        clearLine(line) {
            line.numbers = [];
            line.random = true;
        },
        onRandomCheckboxChange(e, line) {
            const isChecked = e.target.checked;

            if (isChecked) {
                this.clearLine(line);
                return;
            }

            line.random = false;
        },
        onUpdateLineFraction(event, line, index) {
            const value = event.target.value.includes('.')
                ? 1
                : event.target.value;
            
            const fractions = !!event.target.value
                ? parseInt(value)
                : null;
    
            const lines = clone(this.lines);
    
            lines[index].fractions = fractions > 10 ? 10 : fractions;
            
            this.lines = lines;
        },
        onBlurLineFraction(event, line, index) {
            const value = parseInt(event.target.value);
            
            const lines = clone(this.lines);
    
            if (!value || value < 0) {
                lines[index].fractions = 1;
            } else {
                lines[index].fractions = value > 10 ? 10 : value;
            }
            
            this.lines = lines;
        },
        onMainNumberKeyPress(e) {
            const nextSibling = e.target.nextElementSibling;

            setTimeout(() => {
                if (e.target.value.length > 1) {
                    e.target.value = e.target.value.slice(-1);
                    e.target.dispatchEvent(new Event('input'));
                }

                if (!nextSibling) {
                    return;
                }

                nextSibling.focus();
            }, 50);
        },
        randomizer() {
            return Array(5).fill().map(() => Math.floor(Math.random() * 10));
        },
        componentKeyHandler: function(event){
            switch(event.keyCode){
                case 107:
                        this.addLine();
                    break;
                case 109:
                        if(this.lines.length > 1) {
                            this.removeLine(this.lines.length - 1);
                        }
                    break;
            }
        }
    },
    created: function(){
        if (!this.hasSelectedFreeGame) {
            window.addEventListener("keyup", this.componentKeyHandler);
        }
    },
    beforeDestroy() {
        if (!this.hasSelectedFreeGame) {
            window.removeEventListener("keyup", this.componentKeyHandler);
        }
    }
}
</script>

<style lang="scss" scoped>
.player-play-picker-lines-line {
    &.error {
        border        : 1px solid #db3333;
        border-radius : 3px;
    }
}
</style>