var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-header card-header-atbl" }, [
    _c("div", { staticClass: "player-play-header" }, [
      _c(
        "div",
        {
          staticClass:
            "mb-1 d-flex align-items-center justify-content-center justify-content-xl-between"
        },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("img", {
              staticClass: "game-thumb mx-2",
              attrs: {
                src: _vm.$imageService.gameImage(_vm.config.image),
                alt: _vm.config.name
              }
            }),
            _vm._v(" "),
            _c("h5", { staticClass: "text-muted m-0" }, [
              _vm._v(
                _vm._s(_vm.config.name) + " | " + _vm._s(_vm.syndicate.title)
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-link",
              on: { click: _vm.closeGame }
            },
            [
              _c("i", {
                staticClass: "fa fa-times",
                attrs: { "aria-hidden": "false" }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "player-play-header-description" }, [
        _c("p", { staticClass: "text-muted" }, [
          _c("strong", [
            _vm._v(
              "Next Draw Closes: " +
                _vm._s(
                  _vm._f("dateFormat")(
                    _vm.syndicate.active_syndicate_item.cut_off_at,
                    "ddd DD MMM [@] H:mm",
                    false
                  )
                )
            )
          ]),
          _vm._v(" "),
          _c("strong", [_vm._v("Est. Jackpot:")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.config.jackpot || "N/A"))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }