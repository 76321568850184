<template>
    <div class="card card-search">
        <div class="card-header d-flex align-items-center justify-content-between">
            <span>
                <i class="fa fa-search"></i> Search
            </span>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col" v-if="!getIsOnlyAgent">
                    <multi-agents-picker v-model="agents" />
                </div>
	            <div class="col-3">
		            <countries-options :error="{}"
		                               v-model="country"
		            />
	            </div>
                <div class="col-3">
                    <atbl-form-group label="For Date">
                        <multi-date-picker v-model="selectedDates"
                                           :direction="`${getIsOnlyAgent ? 'right' : 'left'}`"
                        />
                    </atbl-form-group>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button type="submit"
                      class="btn btn-sm btn-primary"
                      @click="search"
            >
                <i class="fa fa-search"></i> Search
            </button>
            <button type="reset"
                      class="btn btn-sm btn-danger"
                      @click="clear"
            >
                <i class="fa fa-ban"></i> Clear
            </button>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../store/TypesConst";
    import NamespaceConst from "../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    import moment from "moment";
    import Date from "../../../helpers/Date";
    import CountriesOptions from "../../../components/Forms/Select/CountriesOptions";

    const {
        mapGetters: mapCallbackGetters,
        mapMutations: mapCallbackMutations,
        mapActions: mapCallbackActions,
    } = createNamespacedHelpers(NamespaceConst.callback);

    export default {
        name: "CallbackSearchForm",
	    components: {CountriesOptions},
	    computed: {
            ...mapCallbackGetters({
                getSearchModel: TypesConst.callback.getters.GET_CALLBACK_LIST_MODEL
            }),
            agents: {
                get: function(){
                    return this.getSearchModel.agents;
                },
                set: function(value){
                    this.updateModel("agents", value);
                }
            },
            selectedDates: {
                get: function(){
                    if(!this.getSearchModel.dateFrom && !this.getSearchModel.dateTo){
                        return null;
                    }
                    return {
                        startDate: this.getSearchModel.dateFrom,
                        endDate: this.getSearchModel.dateTo
                    };
                },
                set: function(value){
                    this.setModelField({
                        dateFrom: !!value.startDate
                            ? moment(value.startDate).format(Date.defaultDate)
                            : null,
                        dateTo: !!value.endDate
                            ? moment(value.endDate).format(Date.defaultDate)
                            : null
                    });
                }
            },
		    country: {
            	get: function () {
            		return this.getSearchModel.country;
	            },
			    set: function(value){
				    this.updateModel("country", value);
			    }
		    }
        },
        methods: {
            ...mapCallbackMutations({
                setModelField: TypesConst.callback.mutations.SET_CALLBACK_LIST_MODEL,
                clearModel: TypesConst.callback.mutations.SET_CALLBACK_LIST_MODEL_CLEAR
            }),
            ...mapCallbackActions({
                getCallbacksAction: TypesConst.callback.actions.CALLBACK_GET_LIST_ACTION
            }),
            updateModel: function(type, value){
                this.setModelField({
                    [type]: value
                });
            },
            search () {
                this.getCallbacksAction();
            },
            clear () {
                this.clearModel();
                this.getCallbacksAction();
            }
        },
	    created: function(){
		    this.selectedDates = {
			    startDate: moment().subtract(7, "days").format(Date.defaultDate),
			    endDate: moment().format(Date.defaultDate)
		    };
            this.getCallbacksAction();
	    }
    }
</script>