<template>
    <div class="row">
        <div class="col-12 col-lg-6 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Draw
                </div>

                <div>
                    <div class="row">
                        <div class="col-12 col-lg-3 text-center">
                            <img :src="logo" class="img-fluid" width="80" alt="" />
                        </div>

                        <div class="col-12 col-lg-9">

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Player
                </div>

                <div class="card-body">
                    <a :href="`/players/${bundleTicket.order.player_id}/view`" class="text-primary">
                        <h3>{{ playerFullName }}</h3>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Winnings
                </div>
    
                <div class="card-body">
                    <h3 class="text-primary">
                        {{ ticketWinStatus }}
                    </h3>
                </div>
            </div>
        </div>

        <div class="col-12 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Bundle
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <span class="mr-2">Bundle: <strong>{{ bundle.title }}</strong></span>
                            <span>Games: <img v-for="bundleGame in bundle.bundle_games" :src="$imageService.gameImage(bundleGame.game.image)" style="width: 40px; height: auto;"></span>
                        </div>

                        <div class="col-12 col-lg-6">
                            <span>Weeks: <strong>{{ bundleTicket.weeks }}</strong></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TicketMixin from '../../../../mixins/TicketMixin';
import BundleService from '../../../../services/BundleService';

export default {
    name: 'bundle-ticket-info',

    mixins: [
        TicketMixin
    ],

    props: ['bundleTicket'],

    computed: {
        bundle() {
            return this.bundleTicket.bundle;
        },

        logo() {
            return BundleService.makeLogoPreviewUrl(this.bundle.id);
        },

        tickets() {
            return this.bundleTicket.tickets;
        },

        playerFullName() {
            return `${this.bundleTicket.order.player.name} ${this.bundleTicket.order.player.surname}`;
        },

        ticketWinStatus() {
            const total = this.tickets.reduce((sum, item) => {
                return sum + item.draw.lines.reduce((sum, item) => sum + item.prize, 0);
            }, 0);

            return this.$options.filters.formatCurrency(total);
        },
    }
}
</script>