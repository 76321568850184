export default {
    data() {
        return {
            callLogs: [],
            callLogsOpen: false,
            logsBodyHeight: null,
        };
    },
    methods: {
        log(error, status = null) {
            const message = this.isObject(error)
                ? error.hasOwnProperty('response') && error.response.data.message ? error.response.data.message : error.message
                : error;

            if (!!status) {
                flash(message, status);
            }

            this.callLogs = [
                ...this.callLogs,
                message
            ];
        },
        toggleCallLogsModal() {
            this.callLogsOpen = !this.callLogsOpen;

            const body = document.querySelector('body');

            if (!body) {
                return;
            }

            !!this.callLogsOpen
                ? body.classList.add('overflow-hidden')
                : body.classList.remove('overflow-hidden');
        },
    },
}