<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span><i class='fa fa-align-justify'></i> My Affiliate </span>
                <div class="d-flex align-items-center justify-content-between">
                    <button v-if="$can('My Affiliate - Sync Affiliates')"
                            :disabled="!!isSyncLoading"
                            class="btn btn-sm btn-success mr-2"
                            @click="syncPlayers"
                    >
                        <span v-if="!isSyncLoading">Sync Affiliates</span>
                        <loader :show="!!isSyncLoading"
                                type="smaller"
                        />
                    </button>
                    <button v-if="$can('My Affiliate - Remap Players')"
                              class="btn btn-sm btn-primary mr-2"
                                :class="{
								  disabled: !!isRemapLoading
                                }"
                              @click="toggleRemapPlayers"
                    >
                        Remap Players
                    </button>
                    <button v-if="$can('My Affiliate - Get Affiliate List')"
                              v-tooltip="{
                                content: 'Reload affiliates list',
                                placement: 'top-end'
                              }"
                              class="btn btn-sm btn-primary"
                                :class="{
								  disabled: !!isRemapLoading,
                                }"
                              @click="getMyAffiliateAffiliates"
                    >
                        <i class="fa fa-undo m-0"></i>
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg">Id</div>
                        <div class="col-12 col-lg">Username</div>
                        <div class="col-12 col-lg">Status</div>
                        <div class="col-12 col-lg">Is Active</div>
                        <div class="col-12 col-lg">Users</div>
                        <div v-if="$can('My Affiliate - Preview Affiliate')"
                               class="col-12 col-lg"
                        >
                            Operations
                        </div>
                    </div>

                    <div v-if="!isLoading"
                           v-for="(item, index) in affiliates"
                           :key="index"
                           class="row"
                    >
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Id</span>
                            {{ item.id }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Username</span>
                            {{ item.username }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Status</span>
                            <span :color="`badge badge-${resolveStatusColor(item.status)}`">
                                {{ item.status }}
                            </span>
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Is Active</span>
                            <i class="fa"
                               :class="{
                                    'text-success fa-check': !!item.is_active,
                                    'text-danger fa-times': !item.is_active,
                               }"
                            ></i>
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Users</span>
                            {{ !item.users.length ? '/' : item.users.length }}
                        </div>
                        <div class="col-12 col-lg"
                               v-if="$can('My Affiliate - Preview Affiliate')"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <button class="btn btn-sm btn-link"
                                      size="sm"
                                      @click="onPreviewAffiliateEvent(item.id)"
                            >
                                <i class="fa fa-eye"></i>
                            </button>
                        </div>
                    </div>

                    <not-found v-if="!isLoading"
                               :items="affiliates"
                               msg="No notes found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>
        </div>

        <preview-affiliate-component v-if="$can('My Affiliate - Preview Affiliate')"
                                     :is-open="isPreviewOpen"
                                     :is-loading="isPreviewLoading"
                                     :selected-affiliate="selectedAffiliate"
                                     @close="selectedAffiliate = {}; isPreviewOpen = false"
        />

        <confirm-remap-component :is-open="isRemapOpen"
                                 :is-loading="isRemapLoading"
                                 :model="remapModel"
                                 :errors="remapErrors"
        />
    </div>
</template>

<script>
import PreviewAffiliateComponent from './PreviewAffiliate/PreviewAffiliateComponent';
import ConfirmRemapComponent from './RemapPlayers/ConirmRemapComponent.vue';
import RemapPlayers from './RemapPlayers/RemapPlayers';

export default {
    name: 'MyAffiliate',
    mixins: [
        RemapPlayers,
    ],
    components: {
        ConfirmRemapComponent,
        PreviewAffiliateComponent
    },
    provide() {
        return {
            resolveStatusColor: this.resolveStatusColor,
        };
    },
    data() {
        return {
            isLoading: false,
            affiliates: [],

            isPreviewOpen: false,
            isPreviewLoading: false,
            selectedAffiliate: {},

            isSyncLoading: false,
        };
    },
    async created() {
        this.initializeRemapModel();

        await this.getMyAffiliateAffiliates();
    },
    methods: {
        async getMyAffiliateAffiliates() {
            this.isLoading = true;

            try {
                const response = await window.axios.get('/my-affiliate/list');

                this.affiliates = response.data.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        async syncPlayers() {
            this.isSyncLoading = true;

            try {
                const response = await window.axios.post('/my-affiliate/sync-players');
                const { status, message, data: { errors } } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    await this.getMyAffiliateAffiliates();
                }

                this.isSyncLoading = false;
            } catch (error) {
                this.isSyncLoading = false;

                window.flashError(error);
            }
        },
        onPreviewAffiliateEvent(id) {
            this.isPreviewOpen = true;
            this.isPreviewLoading = true;

            const affiliate = this.affiliates.find(item => item.id === id);

            if (!affiliate) {
                window.showMessage('Affiliate cannot be found.', false);

                this.isPreviewOpen = false;
                this.isPreviewLoading = false;

                return;
            }

            this.selectedAffiliate = affiliate;
        },
        resolveStatusColor(status) {
            switch (status) {
                case 'denied':
                    return 'danger';
                case 'verified':
                    return 'primary';
                case 'suspended':
                    return 'warning';
                case 'accepted':
                    return 'success';
                default:
                    return 'dark';
            }
        },
    },
}
</script>