<template>
    <sidebar-sub title="Call Recordings"
                 :value="show"
                 :loader="isLoading"
                 bodyClass="px-3"
                 @input="onCloseSidebar"
    >
        <audio-player :url="audioUrl"
                      ref="audioPlayer"
                      @close="audioUrl = null"
        />

        <div class="general-list"
             :class="{
                 'mt-3': !!audioUrl
             }"
        >
            <div class="row leads-section-list-row align-items-center">
                <div class="col text-center">Duration</div>
                <div class="col text-center">Status</div>
                <div class="col text-center">Operations</div>
            </div>

            <template v-if="!isLoading">
                <div class="row leads-section-list-row align-items-center"
                     v-for="(item, index) in recordings"
                     :key="index"
                >
                    <div class="col text-center">
                        {{ item.duration }}<br />
                    </div>
                    <div class="col text-center">
                        <call-status-badge :title="item.status" />
                    </div>
                    <div class="col text-center">
                        <recording-audio :item="item"
                                         @play="onPlayerEvent"
                                         @error-message="onErrorMessageEvent"
                        />
                    </div>
                </div>
            </template>
            <loader :show="isLoading"
                    type="small"
            />

            <not-found v-if="!isLoading"
                       :items="recordings"
                       msg="No recordings found."
            />
        </div>

        <div v-if="!!message"
             class="alert alert-danger mt-4"
        >
            {{ message }}
        </div>
    </sidebar-sub>
</template>

<script>
import RecordingAudio from '../RecordingAudio';
import CallStatusBadge from '../CallStatusBadge';

export default {
    name: 'call-history-filter-recordings-component',
    inject: [
        'toggleCallRecordingsSidebar',
    ],
    components: {
        RecordingAudio,
        CallStatusBadge,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        recordings: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            audioUrl: null,
            message: null,
        };
    },
    methods: {
        onPlayerEvent(link) {
            this.audioUrl = link;
        },
        onErrorMessageEvent(message) {
            this.message = message;
        },
        onCloseSidebar() {
            this.audioUrl = null;
            this.message = null;

            this.toggleCallRecordingsSidebar();
        },
    },
}
</script>