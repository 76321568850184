var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { class: ["badge", "badge-" + _vm.color] },
    [
      _vm.$slots.default
        ? _vm._t("default")
        : [_vm._v("\n    " + _vm._s(_vm.status) + "\n  ")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }