<template>
    <player-layout :id="id"
                   title="Manage Wallet"
                   :header="false"
                   cardClass="player-manage-wallet tabs-atbl"
                   bodyClass="p-0"
    >
        <atbl-tabs @update:activeTab="setActiveTab"
                v-if="$canAny(manualWallet)"
                :active-tab="0"
        >
            <atbl-tab title="Withdrawals"
                   v-if="$can('Payment - Manual Wallet Withdrawals')"
            >
                <AddManualWithdrawal />
            </atbl-tab>
            <atbl-tab title="Refunds"
                   v-if="$can('Payment - Manual Wallet Refunds')"
            >
                <AddManualRefund />
            </atbl-tab>
        </atbl-tabs>
        <not-found v-else
                   :items="[]"
                   msg="You don`t have access to visit this page."
        />

        <ConfirmWithdrawalModal />
        <ConfirmRefundsModal />
    </player-layout>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const {
        mapGetters,
        mapMutations,
        mapActions
    } = createNamespacedHelpers(NamespaceConst.players);

    import AddManualWithdrawal from "./Forms/AddManualWithdrawal";
    import AddManualRefund from "./Forms/AddManualRefund";
    import ConfirmWithdrawalModal from "./Components/ConfirmWithdrawalModal";
    import ConfirmRefundsModal from "./Components/ConfirmRefundsModal";

    import TransactionsConstants from "../../../../constants/Transactions";
    import AtblTabs from '../../../../components/AtblTabs/AtblTabs.vue';
    import AtblTab from '../../../../components/AtblTabs/AtblTab.vue';

    export default {
        name: "ManageWallet",
        components: {
            AddManualWithdrawal,
            AddManualRefund,
            ConfirmWithdrawalModal,
            ConfirmRefundsModal,
            AtblTabs,
            AtblTab,
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data: function(){
            return {
                manualWallet: [
                    "Payment - Manual Wallet Withdrawals",
                    "Payment - Manual Wallet Refunds"
                ]
            };
        },
        methods:{
            ...mapMutations({
                setAddBalance: TypesConst.players.mutations.SET_FUNDS_ADD_BALANCE,
                setTransactionType: TypesConst.players.mutations.SET_FUNDS_TRANSACTION_TYPE
            }),
            ...mapActions({
                getPlayerCreditCards: TypesConst.players.actions.GET_PLAYERS_SAVED_CARDS_ACTIONS
            }),
            tabsActive: function(index){
                let tabs = [];

                if(this.$can("Payment - Manual Wallet Withdrawals")){
                    tabs.push(TransactionsConstants.CMWD);
                }

                if(this.$can("Payment - Manual Wallet Refunds")){
                    tabs.push(TransactionsConstants.CMRF);
                }

                return tabs[index];
            },
            setActiveTab: function(value){
                this.setTransactionType(this.tabsActive(value));
            },
        },
        created: function(){
            this.setTransactionType(this.tabsActive(0));
        }
    }
</script>