import "core-js/stable";

window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    switch(error.response.status){
        case 403:
               window.location.href = "/status/403";
            break;
        case 401:
        case 302:
               window.location.href = "/login";
            break;
    }

    return Promise.reject(error);
});

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found!');
}

if (window.Laravel) {
    window.layer = window.Laravel;
    window.Laravel = null;
	window.axios.defaults.headers.common['Authorization'] = window.layer.api_token;

	let scMain = document.getElementById("scMain");
	if(scMain){
	    document.head.removeChild(scMain);
    }
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo"

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsHost: process.env.MIX_PUSHER_HOST,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    scheme: 'http'
});

window.Vue = require('vue');
window.Atbl = new Vue;

window.flash = function (message, alert) {
    window.Atbl.$emit('flash', message, alert);
};

window.showMessage = function (message, status = true) {
	window.flash(message, status ? "alert-success" : "alert-danger");
};

window.flashError = function(error){
	const message = error.hasOwnProperty("response") && error.response.data.message ? error.response.data.message : error.message;
	flash(message, "alert-danger");
};

window.flashBadResponse = (err) => {
    const errResponse = err.response;

    if (errResponse.status !== 422) {
        flash(err.response.data.message, "alert-danger");

        return;
    }

    const errors = [errResponse.data.message];
    for (const [key, error] of Object.entries(errResponse.data.errors)) {
        errors.push(error[0]);
    }

    window.flash(errors.join('<br />'), 'alert-danger');
};

window.flashSingleValidationError = (err) => {
    const errResponse = err.response;

    if (errResponse.status !== 422) {
        flash(err.response.data.message, "alert-danger");

        return;
    }

    const error = Object.values(errResponse.data.errors)[0][0];

    window.flash(error || 'Validation error.', 'alert-danger');
}

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);
Vue.prototype.$scrollToBottom = () => window.scrollTo(0,document.querySelector("body").scrollHeight);