<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header">
                <i class='fa fa-pound-sign'></i> Sales
            </div>

            <div class="card-body">
                <section class="sales general-list">
                    <div class="row sticky-element">
                        <div class="col">Month</div>
                        <div class="col">Items</div>
                        <div class="col">Deposits</div>
                        <div class="col">Dep. CRM</div>
                        <div class="col">Dep. Web</div>
                        <div class="col">Bonus</div>
                        <div class="col">Sales</div>
                        <div class="col">Winners</div>
                        <div class="col">Winnings</div>
                        <div class="col">Withdrawals</div>
                        <div class="col">Refunds</div>
                    </div>

                    <template v-if="!isLoading && !!sales.length">
                        <div v-for="(sale, index) in sales"
                               :key="index"
                               class="row"
                        >
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Month</span>
                                <a href="#" @click="openMonth($event, sale.r_date)">
                                    {{ formatReportDate(sale.r_date) }}
                                </a>
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Items</span>
                                <div>Tickets: {{ sale.tickets }}</div>
                                <div>Lines: {{ sale.lines }}</div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Deposits</span>
                                {{ sale.deposits | formatCurrency }}
                            </div>
                             <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Dep. CRM</span>
                                {{ sale.deposits_crm | formatCurrency }}
                            </div>
                             <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Dep. Web</span>
                                {{ sale.deposits_web | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Bonus</span>
                                {{ sale.bonus | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Total</span>
                                {{ sale.sales | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Winners</span>
                                {{ sale.winners }}
                                <div>Unique: {{ sale.unique_winners }}</div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Winnings</span>
                                {{ sale.winnings | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Withdrawals</span>
                                {{ sale.withdrawals | formatCurrency }}
                            </div>
                            <div class="col-6 col-sm-4 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Refunds</span>
                                {{ sale.refunds | formatCurrency }}
                            </div>
                        </div>
                    </template>

                    <not-found v-if="!isLoading"
                               :items="sales"
                               msg="No sales found."
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </section>
            </div>

            <div v-if="!isLoading && salesData.last_page > 1" class="card-footer">
                <nav class="wrap">
                    <atbl-pagination :pagination="salesData"
                                     @paginate="getMonthlyData"
                    />
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import ReportsSalesService from '../../../services/ReportsSalesService';

export default {
    name: 'sales',
    data() {
        return {
            salesData: {},
            sales: [],
            isLoading: true
        };
    },
    methods: {
        getMonthlyData(page = 1) {
            this.isLoading = true;

            ReportsSalesService.getSalesReports(
                {
                    page
                }
            ).then(response => {
                this.salesData = response.data;
                this.sales = response.data.data;
                this.isLoading = false;
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        getMonthByNumber(i) {
            return 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',')[i - 1];
        },
        openMonth(e, month) {
            e.preventDefault();
            const [year, m] = month.split('-');
            const monthName = this.getMonthByNumber(m);

            window.location.href = `/reports/sales/sale/days?month=${monthName} ${year}`;
        },
        formatReportDate(date) {
            const [year, month] = date.split('-');
            const monthName = this.getMonthByNumber(month);

            return `${monthName} ${year}`;
        },
    },
    created() {
        this.getMonthlyData();
    }
}
</script>