<template>
    <player-layout :id="id"
                   title="Login History"
                   icon="fa fa-list"
    >
        <PlayerLoginHistoryList />
    </player-layout>
</template>

<script>
	import TypesConst from "../../../../store/TypesConst";
	import NamespaceConst from "../../../../store/NamespaceConst";
	import { createNamespacedHelpers } from "vuex";

	const {
		mapMutations: mapPlayersMutations
	} = createNamespacedHelpers(NamespaceConst.players);

	import PlayerLoginHistoryList from "./Sections/PlayerLoginHistoryList";

    export default {
    	name: "player-login-history",
	    components: {
    		PlayerLoginHistoryList
	    },
        props: [
            'id'
        ],
	    methods: {
    		...mapPlayersMutations({
			    setPlayerId: TypesConst.players.mutations.SET_PLAYER_ID
		    })
	    },
	    created: function(){
    		this.setPlayerId(this.id);
	    }
    }
</script>
