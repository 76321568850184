<template>
    <atbl-modal v-model="modalVisibility"
                :close-on-backdrop="false"
                :class="{
                    'hide-close': !!isLoading
                }"
                class="bulk-players-modal hide-footer"
                title="Bulk Action"
    >
        <component :is="bulkAction"
                   :players="players"
                   :selected-players="selectedPlayers"
                   @loader="isLoading = $event"
        />
    </atbl-modal>
</template>

<script>
import components from './Types/index';
import AtblModal from '../../../../../components/AtblModal/AtblModal';

export default {
    name: 'bulk-players',
    inject: [
        'closeBulkForm',
    ],
    components: {
        ...components,
        AtblModal,
    },
    props: {
        bulkAction: {
            type: String,
            default: 'AssignAgent'
        },
        players: {
            type: Array,
            default: () => ([])
        },
        selectedPlayers: {
            type: Array,
            default: () => ([])
        },
        show: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        modalVisibility: {
            get() {
                return this.show;
            },
            set() {
                this.closeBulkForm();
            },
        },
    },
}
</script>