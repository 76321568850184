<template>
    <div class="card">
        <div class="card-body d-flex align-items-center justify-content-between">
            <div>
                <atbl-form-group :horizontal="true"
                                 label="Month"
                                 label-for="month"
                                 class="m-0 font-weight-bold"
                                 label-cols="3"
                >
                    <select :disabled="isLoading"
                            id="month"
                            class="form-control"
                            @change="selectedMonthHandler"
                    >
                        <option :value="null">Select month</option>
                        <option v-for="(item, index) in dates"
                                :key="index"
                                :value="item"
                                :selected="item === selectedMonth"
                        >
                            {{ item }}
                        </option>
                    </select>
                </atbl-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import TransactionsService from '../../../../services/TransactionsService';
    import moment from 'moment';
    
    export default {
        name: 'GamesHeader',
        data() {
            return {
                isLoading: false,
                dates: [],
                selectedMonth: null,
            };
        },
        async created() {
            await this.getTransactionsExistingDates();
        },
        methods: {
            async getTransactionsExistingDates() {
                this.isLoading = true;
                
                try {
                    const response = await TransactionsService.getTransactionsExistingDates();
                    this.dates = response.data;
                    
                    const activeMonth = moment().format('MMMM YYYY');
                    
                    this.selectedMonth = this.dates.find(item => item === activeMonth);
                } catch (error) {
                    window.flashError(error);
                } finally {
                    this.isLoading = false;
                }
            },
            selectedMonthHandler(event) {
                let value = event.target.value;
                this.selectedMonth = value;
                
                Atbl.$emit('gameFilter', value ? value.trim() : value);
            }
        },
    }
</script>