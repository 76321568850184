import MarketingService from '../../../../../../services/MarketingService';
import LeadService from '../../../../../../services/Marketing/LeadService';
import { clone } from '../../../../../../utils/Json/Json';
import CustomerToCallModel from './CustomerToCallModel';
import { isObjectEmpty } from '../../../../../../utils/Object/Object';
import removeUndefinedProperties from '../../../../../../utils/Object/RemoveUndefinedProperties';
import CustomerModel from './CustomerModel';
import PlayerService from '../../../../../../services/PlayerService';
import ResponseCodes from '../../../../../../constants/ResponseCodes';

export default {
    provide() {
        return {
            fetchCustomerToCall: this.fetchCustomerToCall,
        };
    },
    data() {
        return {
            customerToCallLoader: false,
            customerToCallModel: clone(CustomerToCallModel),
        };
    },
    computed: {
        customer() {
            return this.customerToCallModel.customer;
        },
        callId() {
            return !!this.customer ? this.customer.id : null;
        },
        isCustomerToCallModelEmpty() {
            return isObjectEmpty(removeUndefinedProperties(this.customer));
        },
    },
    methods: {
        async fetchCustomerToCall(campaignType, campaignId, customerId, shouldGoNext = false) {
            this.isAddStatusAllowed = false;
            this.shouldGoNext = shouldGoNext;

            !!customerId
                ? await this.fetchCustomerById(campaignType, campaignId, customerId)
                : await this.fetchCampaignNextLead(campaignType, campaignId);

            await this.fetchCustomerStatuses(this.callId);

            await this.fetchLeadLastCalls(this.callId);
        },
        async fetchCampaignNextLead(campaignType, campaignId){
            if (!campaignType) {
                window.showMessage('Campaign type is not provided.', false);

                return;
            }

            if (!campaignId) {
                window.showMessage('Campaign id is not provided.', false);

                return;
            }

            this.customerToCallLoader = true;

            try {
                const response = await MarketingService.getSingleCallLead(campaignType, campaignId);
                const { data } = response.data;

                if (!data) {
                    this.customerToCallLoader = false;

                    this.closeCampaign();

                    return;
                }

                this.previewCustomerModel = clone(data.customer);

                this.customerToCallModel = {
                    customer_call_id: data.id,
                    customer: data.customer
                };

                this.leadModel = data.customer;

                this.customerToCallLoader = false;
            } catch (error) {
                this.customerToCallLoader = false;

                if (error.response.status === ResponseCodes.NotFound) {
                    this.closeCampaign();
                }

                window.flashError(error);
            }
        },
        async fetchCustomerById(campaignType, campaignId, customerId) {
            if (!customerId) {
                window.showMessage('Customer id is not provided.', false);

                return;
            }

            this.customerToCallLoader = true;

            try {
                const response = await MarketingService.getCustomerById(campaignType, campaignId, customerId);
                const { data } = response.data;

                this.previewCustomerModel = !!data
                    ? clone(data)
                    : null;

                this.customerToCallModel = {
                    customer_call_id: null,
                    customer: data
                };

                this.leadModel = data;

                this.customerToCallLoader = false;
            } catch (error) {
                this.customerToCallLoader = false;

                window.flashError(error);
            }
        },
        closeCampaign() {
            this.customerToCallModel = {
                customer_call_id: null,
                customer: null
            };

            this.leadModel = null;
            this.previewCustomerModel = clone(CustomerModel);
        },
    },
}