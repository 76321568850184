<template>
    <player-layout :id="id"
                   icon="fa fa-ticket-alt"
                   title="Tickets"
    >
        <template slot="first">
            <player-tickets-search-form v-on:search="search" />
        </template>

        <TicketsList :tickets="tickets"
                     :ticketsList="ticketsList"
                     :isLoading="isLoading"
                     @getResults="getResults"
        />
    </player-layout>
</template>

<script>
    import TicketsList from "./Sections/TicketsList";
    import PlayerTicketsSearchForm from './Sections/PlayerTicketsSearchForm';

    export default {
        components: {
            PlayerTicketsSearchForm,
            TicketsList
        },

        props: [
            'id',
            'auth_user'
        ],

        data() {
            return {
                tickets: {},
                ticketsList: [],

                page: 1,
                isLoading: false,
                searchData: null,
            }
        },

        mounted() {
            this.getTickets();
        },

        methods: {
            getTickets() {
                this.isLoading = true;

                const params = {
                    page: this.page,
                    ...(this.searchData && this.searchData.ticketId && { ticketId: this.searchData.ticketId }),
                    ...(this.searchData && this.searchData.drawDate && { drawDate: this.searchData.drawDate }),
                    ...(this.searchData && this.searchData.games && { games: this.searchData.games }),
                };

                window.axios.get(`/player/${this.id}/tickets`, { params })
                    .then((response) => {
                        this.tickets = response.data;
                        this.ticketsList = response.data.data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            getResults(page = 1) {
                this.page = page;
                this.getTickets();
            },

            search(data) {
                this.searchData = data;
                this.getResults(1);
            },
        },
    }
</script>
