export default {
    index(params) {
        return window.axios.get(`/syndicates`, { params });
    },

    availableSyndicateList() {
        return window.axios.get(`/games/syndicates`);
    },

    showSyndicateItem(id) {
        return window.axios.get(`/syndicates/items/${id}/show`);
    },

    fetchSyndicateItems(id) {
        return window.axios.get(`/syndicates/${id}/items`);
    },

    store(params) {
        return window.axios.post('/syndicates', params);
    },

    disableSyndicate(id) {
        return window.axios.post(`/syndicates/${id}/disable`);
    },

    enableSyndicate(id) {
        return window.axios.post(`/syndicates/${id}/enable`);
    },

    disableSyndicateItem(syndicateItemId) {
        return window.axios.post(`/syndicates/items/${syndicateItemId}/disable`);
    },

    enableSyndicateItem(syndicateItemId) {
        return window.axios.post(`/syndicates/items/${syndicateItemId}/enable`);
    },

    syndicateLines(syndicateItemId, page) {
        const params = {
            page
        };

        return window.axios.get(`/syndicates/items/${syndicateItemId}/lines`, { params });
    },
}