<template>
    <atbl-modal title="Choose payment type" :close-on-backdrop="true" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <list-payments :player-id="order.player_id"
                               :charge-amount="parseFloat(order.amount)"
                               :params="{order_id: order.reference_id}"
                               v-on:payment-successful="paySuccess"
                               v-on:payment-failed="payFailed"
                               v-on:payment-error="payError" />
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-danger" @click="emitCloseEvent">
                Cancel
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import ListPayments from '../../../../components/PaymentGateways/ListPayments.vue';
import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'order-payment-modal',

    components: { ListPayments, AtblModal, },

    props: ['order'],

    data() {
        return {
            creditCardList: [],
            selectedCreditCard: null,
        };
    },

    methods: {
        paySuccess(data) {
            window.showMessage('Order has been charged successfully.')
            this.$emit('payment-successful', data);
        },

        payFailed(data) {
            window.showMessage(`Payment error: ${data.error}`, false);
        },

        payError(err) {
            window.showMessage('Payment failed. Please try again.', false);
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },
    },
}
</script>