export default {
	orderRoute: function () {
		return `/orders`;
	},
	orderIdRoute: function (id) {
		return `${this.orderRoute()}/${id}/view`;
	},
	getorderById(id) {
		return window.axios.get(this.orderIdRoute(id));
	}
}