import { clone } from '../../../../../../utils/Json/Json';
import CustomersOutOfCampaignSearchModel from './CustomersOutOfCampaignSearchModel';
import MarketingService from '../../../../../../services/MarketingService';
import PaginationModel from '../../../../../../modules/pagination/PaginationModel';

export default {
    provide() {
        return {
            fetchLeadsOutOfCampaign: this.fetchLeadsOutOfCampaign,
            changeLeadsOutOfCampaignSearchModel: this.changeLeadsOutOfCampaignSearchModel,
            clearLeadsOutOfCampaignModel: this.clearLeadsOutOfCampaignModel,
        };
    },
    data() {
        return {
            leadsOutOfCampaignLoader: false,
            leadsOutOfCampaignResponse: clone(PaginationModel),
            leadsOutOfCampaignSearchModel: clone(CustomersOutOfCampaignSearchModel),
        };
    },
    methods: {
        async fetchLeadsOutOfCampaign(campaignId = null, page = 1) {
            if (!campaignId) {
                return;
            }

            this.leadsOutOfCampaignLoader = true;

            this.leadsOutOfCampaignSearchModel = {
                ...this.leadsOutOfCampaignSearchModel,
                inCampaign: this.inCampaign,
            };

            try {
                const response = await MarketingService.getLeadsListOutOfCampaign(
                    this.type,
                    campaignId,
                    {
                        ...this.leadsOutOfCampaignSearchModel,
                        page
                    });

                this.leadsOutOfCampaignResponse = response.data;

                this.leadsOutOfCampaignLoader = false;
            } catch (error) {
                this.leadsOutOfCampaignLoader = false;

                window.flashError(error);
            }
        },
        changeLeadsOutOfCampaignSearchModel(name, value) {
            Object.assign(this.leadsOutOfCampaignSearchModel, {
                [name]: value
            });
        },
        clearLeadsOutOfCampaignModel() {
            this.leadsOutOfCampaignSearchModel = clone(CustomersOutOfCampaignSearchModel);
        },
    },
}