var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-list" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.tickets, function(ticket, index) {
        return _c("div", { key: index, staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-lg-2 text-muted text-center text-lg-left"
            },
            [
              _c("span", [
                _c("b", [_vm._v("No.")]),
                _vm._v(" " + _vm._s(index + 1) + "\n            ")
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Ticket")]),
              _vm._v(" "),
              _vm.$can("Tickets - View Ticket Details")
                ? _c("a", { attrs: { href: ticket.url } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(ticket.is_syndicate ? "Syndicate " : "") +
                        " Ticket# " +
                        _vm._s(ticket.id) +
                        "\n            "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "{ ticket.is_syndicate ? 'Syndicate ' : '' }} Ticket# " +
                        _vm._s(ticket.id)
                    )
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("img", {
                staticStyle: { width: "60px", height: "auto" },
                attrs: {
                  src: _vm.$imageService.gameImage(ticket.game.image),
                  alt: "" + ticket.game.image
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("a", { attrs: { href: "/orders/" + _vm.order.id } }, [
                _vm._v("Order# " + _vm._s(_vm.order.id))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-ceNter" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Date")]),
              _vm._v("\n            " + _vm._s(ticket.date) + "\n        ")
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Lines")]),
              _vm._v(
                "\n            " + _vm._s(ticket.lines_count) + "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [
                _vm._v("Winnings")
              ]),
              _vm._v("\n            " + _vm._s(ticket.winnings) + "\n        ")
            ]
          )
        ])
      }),
      _vm._v(" "),
      _c("not-found", {
        attrs: { msg: "No tickets created.", items: _vm.tickets }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-none d-lg-flex" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-2 text-muted text-center text-lg-left" },
        [_vm._v("Ticket")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Game")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Order")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Draw Date")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Lines / Shares")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Winnings")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }