var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "atbl-switch",
      class: {
        disabled: !!_vm.disabled || !!_vm.isLoading,
        small: !!_vm.small
      }
    },
    [
      _c("input", {
        staticClass: "atbl-switch-toggle",
        attrs: { id: _vm.name, name: _vm.name, type: "checkbox" }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          class: [_vm.color, { checked: _vm.checked }],
          attrs: { for: _vm.name },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.change.apply(null, arguments)
            }
          }
        },
        [_c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })],
        1
      ),
      _vm._v(" "),
      _vm.label
        ? _c("small", [
            _c("b", [_vm._v(_vm._s(_vm.label))]),
            _vm._v(" "),
            _vm.tooltip
              ? _c("i", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: { content: _vm.tooltip },
                      expression: "{content: tooltip}"
                    }
                  ],
                  staticClass: "fa fa-info-circle"
                })
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }