var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-between"
              },
              [
                _vm.$can("My Affiliate - Sync Affiliates")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-success mr-2",
                        attrs: { disabled: !!_vm.isSyncLoading },
                        on: { click: _vm.syncPlayers }
                      },
                      [
                        !_vm.isSyncLoading
                          ? _c("span", [_vm._v("Sync Affiliates")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { show: !!_vm.isSyncLoading, type: "smaller" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("My Affiliate - Remap Players")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary mr-2",
                        class: {
                          disabled: !!_vm.isRemapLoading
                        },
                        on: { click: _vm.toggleRemapPlayers }
                      },
                      [
                        _vm._v(
                          "\n                        Remap Players\n                    "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("My Affiliate - Get Affiliate List")
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Reload affiliates list",
                              placement: "top-end"
                            },
                            expression:
                              "{\n                                content: 'Reload affiliates list',\n                                placement: 'top-end'\n                              }"
                          }
                        ],
                        staticClass: "btn btn-sm btn-primary",
                        class: {
                          disabled: !!_vm.isRemapLoading
                        },
                        on: { click: _vm.getMyAffiliateAffiliates }
                      },
                      [_c("i", { staticClass: "fa fa-undo m-0" })]
                    )
                  : _vm._e()
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Id")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [
                  _vm._v("Username")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Status")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [
                  _vm._v("Is Active")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Users")]),
                _vm._v(" "),
                _vm.$can("My Affiliate - Preview Affiliate")
                  ? _c("div", { staticClass: "col-12 col-lg" }, [
                      _vm._v(
                        "\n                            Operations\n                        "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._l(_vm.affiliates, function(item, index) {
                return !_vm.isLoading
                  ? _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Id")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.id) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Username")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.username) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Status")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            attrs: {
                              color:
                                "badge badge-" +
                                _vm.resolveStatusColor(item.status)
                            }
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.status) +
                                "\n                            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Is Active")
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "fa",
                          class: {
                            "text-success fa-check": !!item.is_active,
                            "text-danger fa-times": !item.is_active
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Users")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              !item.users.length ? "/" : item.users.length
                            ) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$can("My Affiliate - Preview Affiliate")
                        ? _c("div", { staticClass: "col-12 col-lg" }, [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-link",
                                attrs: { size: "sm" },
                                on: {
                                  click: function($event) {
                                    return _vm.onPreviewAffiliateEvent(item.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-eye" })]
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: { items: _vm.affiliates, msg: "No notes found" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.$can("My Affiliate - Preview Affiliate")
        ? _c("preview-affiliate-component", {
            attrs: {
              "is-open": _vm.isPreviewOpen,
              "is-loading": _vm.isPreviewLoading,
              "selected-affiliate": _vm.selectedAffiliate
            },
            on: {
              close: function($event) {
                _vm.selectedAffiliate = {}
                _vm.isPreviewOpen = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("confirm-remap-component", {
        attrs: {
          "is-open": _vm.isRemapOpen,
          "is-loading": _vm.isRemapLoading,
          model: _vm.remapModel,
          errors: _vm.remapErrors
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-align-justify" }),
      _vm._v(" My Affiliate ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }