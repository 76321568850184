<template>
    <div class="card m-0">
        <div class="card-header card-header-atbl d-flex justify-content-between align-items-center">
            <span>
                <i class='fa fa-align-justify'></i> {{ title }}
            </span>
            <slot name="rightSide"></slot>
        </div>

        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "card-section",
        props: {
            title: {
                type: String,
                required: true
            }
        }
    }
</script>