<template>
    <c-sidebar-nav>
        <!--- Players --->
        <c-sidebar-nav-item name="Players"
                            fontIcon="fa fa-user-friends"
                            href="/players"
                            v-if="$can('Player - View List')"
                            :addLinkClasses="isRouteActive('players.page')"
        />
        <c-sidebar-nav-item name="Callback"
                            fontIcon="fa fa-phone"
                            href="/callback"
                            v-if="$can('Callback - View')"
                            :addLinkClasses="isRouteActive('callback.players')"
                            :badge="getCallbacks"
        />
        <c-sidebar-nav-item name="Orders"
                            fontIcon="fa fa-file"
                            href="/orders"
                            v-if="$can('Orders - View Orders List')"
                            :addLinkClasses="isRouteActive('orders.list.view')"
        />
        <c-sidebar-nav-item name="Tickets"
                            fontIcon="fa fa-ticket"
                            href="/tickets"
                            v-if="$can('Tickets - View Tickets List')"
                            :addLinkClasses="isRouteActive('tickets.page')"
        />
        <c-sidebar-nav-item name="Raffles"
                            fontIcon="fa fa-ticket"
                            href="/raffle-tickets"
                            v-if="$can('Tickets - View Tickets List')"
                            :addLinkClasses="isRouteActive('raffle-tickets.page')"
        />
        <c-sidebar-nav-item name="Syndicate shares"
                            fontIcon="fa fa-ticket"
                            href="/syndicate-shares"
                            v-if="$can('Tickets - View Tickets List')"
                            :addLinkClasses="isRouteActive('syndicate-shares.index-view')"
        />
        <c-sidebar-nav-item name="Bundle tickets"
                            fontIcon="fa fa-ticket"
                            href="/bundle-tickets"
                            v-if="$can('Tickets - View Tickets List')"
                            :addLinkClasses="isRouteActive('bundle-tickets.index-view')"
        />
        <c-sidebar-nav-item name="Transactions"
                            fontIcon="fa fa-exchange-alt"
                            href="/transactions"
                            v-if="$can('Transactions - View Transactions List')"
                            :addLinkClasses="isRouteActive('transactions.page')"
        />
        <CreateNew />
        <Reports :user="user" />
        <Marketing />
        <c-sidebar-nav-item name="Call History"
                            fontIcon="fa fa-phone"
                            :href="`/call/history/view${getIsOnlyAgent ? `/${user}` : ''}`"
                            v-if="$can('Call History - View')"
                            :addLinkClasses="isRouteActive([
                                'calls.history'
                            ])"
        />
        <c-sidebar-nav-item name="KYC Submissions"
                            fontIcon="fa fa-exchange-alt"
                            href="/kyc-submissions/list"
                            v-if="$can('KYC - Manage KYC') || $can('KYC - View KYC')"
                            :addLinkClasses="isRouteActive('kyc-submissions.page')"
        />
        <c-sidebar-nav-item name="Syndicates"
                            fontIcon="fa fa-ticket"
                            href="/syndicates/list"
                            v-if="$can('Syndicates - View List')"
                            :addLinkClasses="isRouteActive('syndicates.page')"
        />
        <c-sidebar-nav-item name="Bundles"
                            fontIcon="fa fa-ticket"
                            href="/bundles/list"
                            v-if="$can('Bundles - View List')"
                            :addLinkClasses="isRouteActive('bundles.page')"
        />
        <c-sidebar-nav-item name="Discounts"
                            fontIcon="fa fa-percent"
                            href="/discounts/list"
                            v-if="$can('Discounts - View List')"
                            :addLinkClasses="isRouteActive('discounts.page')"
        />
        <c-sidebar-nav-item name="Bonuses"
                            fontIcon="fa fa-tag"
                            href="/bonuses/list"
                            v-if="$can('Bonuses - View List')"
                            :addLinkClasses="isRouteActive('bonus.index-page')"
        />
        <c-sidebar-nav-item name="Player bonuses"
                            fontIcon="fa fa-tag"
                            href="/player-bonuses"
                            v-if="$can('Player Bonuses - Overview')"
                            :addLinkClasses="isRouteActive('player-bonuses.index-view')"
        />
        <AdministratorNavTitle />
        <Games />
        <c-sidebar-nav-item name="Withdrawals"
                                fontIcon="fa fa-credit-card"
                            href="/transaction/get/withdrawals"
                            v-if="$can('Withdrawals - View')"
                            :addLinkClasses="isRouteActive('withdrawals-view')"
        />
        <c-sidebar-nav-item name="Conversions Rates"
                            fontIcon="fa fa-pound-sign"
                            href="/rates/conversions"
                            v-if="$can('Conversions Rate - View')"
                            :addLinkClasses="isRouteActive('rates-conversions')"
        />
        <c-sidebar-nav-item name="Payments settings"
                            fontIcon="fa fa-pound-sign"
                            href="/settings/payments"
                            v-if="$can('Payment - Manage settings')"
                            :addLinkClasses="isRouteActive('payments-settings')"
        />
        <c-sidebar-nav-item name="Email Templates"
                            fontIcon="fa fa-envelope"
                            href="/email-templates/view"
                            v-if="$can('Email Templates - View')"
                            :addLinkClasses="isRouteActive('email.templates.view')"
        />
        <c-sidebar-nav-item name="Phone Numbers"
                            fontIcon="fa fa-phone"
                            href="/phone-numbers/view"
                            v-if="$can('Phone Numbers - View')"
                            :addLinkClasses="isRouteActive('phone-numbers.view')"
        />
        <c-sidebar-nav-item name="My Affiliate"
                            fontIcon="fa fa-user-friends"
                            href="/my-affiliate"
                            v-if="$can('My Affiliate - View')"
                            :addLinkClasses="isRouteActive('my-affiliate.view')"
        />
        <c-sidebar-nav-item name="Users"
                            fontIcon="fa fa-user"
                            href="/users"
                            v-if="$can('Users - View')"
                            :addLinkClasses="isRouteActive('users.page')"
        />
        <c-sidebar-nav-item name="Roles"
                            fontIcon="fa fa-user-shield"
                            href="/roles"
                            v-if="$can('Roles - View')"
                            :addLinkClasses="isRouteActive('roles.page')"
        />
        <c-sidebar-nav-item name="Permissions"
                            fontIcon="fa fa-key"
                            href="/permissions"
                            v-if="$can('Permissions - View')"
                            :addLinkClasses="isRouteActive(['permissions.list.view', 'permissions.create.view', 'permissions.update.view'])"
        />
        <c-sidebar-nav-item name="Logs"
                            fontIcon="fa fa-print"
                            href="/logs"
                            v-if="$can('Logs - View')"
                            :addLinkClasses="isRouteActive('logs.index')"
        />
        <c-sidebar-nav-item name="Settings"
                            fontIcon="fa fa-cog"
                            href="/settings"
                            v-if="$can('Settings - View')"
                            :addLinkClasses="isRouteActive('settings.page')"
        />
    </c-sidebar-nav>
</template>

<script>
    import CreateNew from "./Items/CreateNew";
    import Reports from "./Items/Reports";
    import Marketing from "./Items/Marketing";
    import AdministratorNavTitle from "./Items/AdministratorNavTitle";
    import Games from './Items/Games';

    export default {
        name: "Navigation",
        components: {
            CreateNew,
            Reports,
            Marketing,
            AdministratorNavTitle,
            Games
        },
        props: {
            user: {
                type: Number
            },
            badges: {
                type: Object
            }
        },
        data: function(){
            return {
                callbacks: null
            };
        },
        computed: {
            getCallbacks: function(){
                return {
                    color: "success",
                    text: this.callbacks ? this.callbacks : this.badges.callbacks
                };
            }
        },
        created: function () {
            let vm = this;

            window.Echo.channel("Callbacks")
                .listen("CallbacksEvent", event => {
                    vm.callbacks = event.callbacks;
                })
        }
    }
</script>