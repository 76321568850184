var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onCreateUpdateEvent.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "card card-list" },
        [
          _c(
            "div",
            {
              staticClass:
                "card-header d-flex align-items-center justify-content-between"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              !_vm.createUpdateLoader &&
              _vm.isEditMode &&
              _vm.$can("Permissions - Remove")
                ? _c(
                    "div",
                    [
                      _c("remove-list-item-button", {
                        attrs: { value: _vm.permissionModel.id },
                        on: {
                          confirmed: function($event) {
                            return _vm.onDeletePermission(
                              $event,
                              "/permissions"
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          !_vm.createUpdateLoader
            ? _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("atbl-input", {
                        staticClass: "mt-2",
                        attrs: {
                          errors: _vm.permissionErrors,
                          label: "Enter name"
                        },
                        model: {
                          value: _vm.changePermissionName,
                          callback: function($$v) {
                            _vm.changePermissionName =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "changePermissionName"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "atbl-form-group",
                        { staticClass: "mt-2 mb-0", attrs: { label: "Roles" } },
                        [
                          _c(
                            "div",
                            { staticClass: "roles-holder" },
                            _vm._l(_vm.roles, function(item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c("atbl-check-box", {
                                    attrs: {
                                      value: item.value,
                                      label: item.text,
                                      checked: _vm.permissionModel.roles.includes(
                                        item.value
                                      ),
                                      name: "roles"
                                    },
                                    on: {
                                      update: function($event) {
                                        return _vm.onSelectRoleEvent(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.createUpdateLoader
            ? _c("div", { staticClass: "card-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary mr-2",
                    attrs: { disabled: !_vm.isModelDirty }
                  },
                  [_vm._v("\n                Save\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-sm btn-danger",
                    attrs: { href: "/permissions" }
                  },
                  [_vm._v("\n                Cancel\n            ")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            attrs: { show: _vm.createUpdateLoader, type: "small" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-key" }),
      _vm._v(" Permission Manager\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }