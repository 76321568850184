export default {
    index() {
        return window.axios.get(`/discounts`);
    },

    createGameDiscountEntry(gameId) {
        const params = {
            game: gameId
        };

        return window.axios.post(`/discounts`, params);
    },

    fetchGameDiscounts(gameId, type, version) {
        const params = {
            type,
            ...(version && { version: version }),
        };

        return window.axios.get(`/discounts/game/${gameId}`, { params })
    },

    publishDiscountLines(gameId, params) {
        return window.axios.post(`/discounts/game/${gameId}/line-discounts`, params);
    },

    publishDiscountDraws(gameId, params) {
        return window.axios.post(`/discounts/game/${gameId}/draw-discounts`, params);
    },

    publishDiscountFractions(gameId, params) {
        return window.axios.post(`/discounts/game/${gameId}/fraction-discounts`, params);
    },

    findLineDiscount(lineDiscounts, lines) {
        if(!lineDiscounts.length) {
            return 0;
        }

        const sortedLineDiscounts = [...lineDiscounts].sort((a, b) => a.from - b.from);

        const lastLineDiscount = sortedLineDiscounts.pop();
        if(lines >= lastLineDiscount.from) {
            return lastLineDiscount;
        }

        const foundRange = sortedLineDiscounts.find((d) => {
            return lines >= d.from && lines <= d.to;
        })

        return ! foundRange ? null : foundRange;
    },

    findDrawDiscount(drawDiscounts, draws) {
        if(!drawDiscounts.length) {
            return 0;
        }

        const sortedDrawDiscounts = [...drawDiscounts].sort((a, b) => a.from - b.from);

        const lastDrawDiscount = sortedDrawDiscounts.pop();
        if(draws >= lastDrawDiscount.from) {
            return lastDrawDiscount;
        }

        const foundRange = sortedDrawDiscounts.find((d) => {
            return draws >= d.from && draws <= d.to;
        })

        return ! foundRange ? null : foundRange;
    },
    findFractionDiscount(fractionsDiscounts, fractions) {
        if(!fractionsDiscounts.length) {
            return 0;
        }

        const sortedDrawDiscounts = [...fractionsDiscounts].sort((a, b) => a.from - b.from);

        const lastDrawDiscount = sortedDrawDiscounts.pop();
        if(fractions >= lastDrawDiscount.from) {
            return lastDrawDiscount;
        }

        const foundRange = sortedDrawDiscounts.find((d) => {
            return fractions >= d.from && fractions <= d.to;
        })

        return ! foundRange ? null : foundRange;
    },
}