<template>
    <atbl-modal title="Disable Syndicate" :close-on-backdrop="true" color="danger" :show="true" @close="emitCloseEvent">
        <div v-if="! isRecurring" class="row">
            <div class="col-12">
                <p>Are you sure you want to disable this syndicate ?</p>
            </div>
        </div>

        <div v-if="isRecurring" class="row">
            <div class="col-12">
                <p><strong>!!! Recurring syndicate detected !!!</strong></p>
                <p>Do you want to disable only this draw, or all linked future syndicates ?</p>
            </div>
        </div>

        <template #footer>
            <template v-if="! isRecurring">
                <button class="btn btn-sm btn-danger" @click="emitDisableSyndicate">
                    Yes
                </button>

                <button class="btn btn-sm btn-primary" @click="emitCloseEvent">
                    No
                </button>
            </template>

            <template v-if="isRecurring">
                <button class="btn btn-sm btn-danger" @click="emitDisableSyndicate">
                    All
                </button>

                <button class="btn btn-sm btn-danger" @click="emitDisableSyndicateItem">
                    Only this
                </button>

                <button class="btn btn-sm btn-primary" @click="emitCloseEvent">
                    No
                </button>
            </template>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'syndicates-disable-modal',
	components: {
		AtblModal,
	},
    props: ['syndicateItem'],

    computed: {
        syndicate() {
            return this.syndicateItem.syndicate;
        },

        isRecurring() {
            return !! this.syndicate.is_recurring;
        },
    },

    methods: {
        emitDisableSyndicate() {
            this.$emit('disableSyndicate', 'syndicate');
        },

        emitDisableSyndicateItem() {
            this.$emit('disableSyndicate', 'syndicateItem');
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },
    },
}
</script>