<template>
    <atbl-modal class="modal__payment-oppwa" :title="title" :close-on-backdrop="true" :show="true" @close="emitCloseEvent">
        <div class="oppwa-spinner">
            <img src="/img/spiner.svg" alt="spiner">
        </div>

        <form :action="apiPaymentResponse.checkout.redirect_url" class="paymentWidgets" data-brands="VISA MASTER"></form>

        <script type="application/javascript" :src="apiPaymentResponse.checkout.url"></script>

        <template #footer-wrapper>
            <div></div>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../AtblModal/AtblModal.vue';

export default {
    name: 'payment-gateway-oppwa',
	components: {
		AtblModal,
	},
    props: {
        title: {
            type: String,
            default: 'Credit card payment',
        },

        apiPaymentResponse: {
            type: Object,
            required: true,
        },

        allowSavingCreditCard: {
            type: Boolean,
            default: true,
        },
    },
    
    mounted() {
        const self = this;

        window.wpwlOptions = {
            style: 'plain',
            onReady() {
                let createRegistrationHtml = '<div class="customLabel">Save payment details?</div>' +
                        '<div class="customInput">' +
                        '<input type="checkbox" name="createRegistration" value="true" checked/>' +
                        '</div>',
                    createDiv = document.createElement('div');

                createDiv.id = 'check-box-save';
                createDiv.innerHTML = createRegistrationHtml;

                let form = document.querySelector('form.wpwl-form-card'),
                    wpwlButton = form.querySelector('.wpwl-group-submit'),
                    oppwaSpinner = document.querySelector(".oppwa-spinner");

                oppwaSpinner.classList.add('d-none');

                if(self.allowSavingCreditCard) {
                    form.insertBefore(createDiv, wpwlButton);
                }
            }
        };
    },

    methods: {
        emitCloseEvent() {
            this.$emit('cancel');
        },
    },
};
</script>

<style lang="scss">
.modal__payment-oppwa {
    .oppwa-spinner {
        text-align: center;

        img {
            width: 100px;
            height: 100px;
            margin: auto;
        }
    }

    .modal-body {
        padding: 0;
    }

    .wpwl-form {
        margin: 0;
        max-width: unset;
    }

    .wpwl-button-pay {
        background-color: #20a8d8 !important;
        border: 1px solid #20a8d8 !important;
    }

    .wpwl-button-pay:hover,
    .wpwl-button-pay:active {
        background-color: #1b8eb7 !important;
        border: 1px solid #1985ac !important;
    }

    .wpwl-control {
        height: 50px;
    }

    .wpwl-control {
        border: 1px solid #e6e9ec !important;
    }

    .customLabel {
        display: inline-block;
        width: 157px;
        padding-top: 10px;
    }

    .customInput {
        display: inline-block;
        width: 50px;
    }

    .wpwl-label-brand {
        margin-top: 10px;
    }

    .wpwl-group-brand {
        margin-bottom: 30px;
    }

    .wpwl-brand-card {
        margin-top: 15px;
    }

    .wpwl-group {
        margin-top: 5px;
        padding: 0 15px;

        &:last-of-type {
            margin-bottom: 0;
            background-color: #f6f6f6;
            border-top: 1px solid #e0e0e0;
        }
    }

    input[type="checkbox"] {
        height: 15px !important;
    }

    .wpwl-registration {
        width: 100%
    }

    .wpwl-group {
        .wpwl-wrapper-submit {
            margin-top: 0;
            display: block;
            height: auto;
            padding: 15px 0;
        }
    }

    .wpwl-wrapper-registration-details {
        padding-left: 15px;
    }

    .wpwl-group-registration.wpwl-selected {
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }

    .wpwl-wrapper-registration-cvv {
        display: none;
    }

    @media (min-width: 480px) {
        .wpwl-wrapper-registration-details {
            width: 74.333333%;
            padding-right: 0;
        }
    }

    .wpwl-label-brand,
    .wpwl-control-brand {
        font-weight: bold;
        font-size: 16px;
    }

    .wpwl-group-registration.wpwl-selected {
        border: none;
        border-bottom: 1px solid #ccc;
    }

    .wpwl-wrapper-registration.wpwl-wrapper-registration-registrationId {
        input[type="radio"] {
            height: 20px !important;
        }
    }

    #check-box-save {
        padding: 0 15px;
        margin-bottom: 12px;
    }
}
</style>