import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL](state, payload){
        state.payWithSavedCardModal = payload;
    },
    [TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL_LOADER](state, payload){
        state.payWithSavedCardModalLoader = payload;
    },
    [TypesConst.players.mutations.SET_PLAYER_CREDIT_CARDS](state, payload){
        state.playerCreditCards = payload;
    },
    [TypesConst.players.mutations.SET_PAY_WITH_MODEL_FIELD](state, payload){
        state.card = payload;
    },
    [TypesConst.players.mutations.SET_CONFIRM_PAY_WITH_CARD](state, payload){
        state.confirmPayWithCard = payload;
    },
};