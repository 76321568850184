<template>
    <sidebar-sub title="Create/Update Free Game"
                 :value="show"
                 :loader="isLoading"
                 @input="toggleCreateUpdateForm"
    >
        <atbl-form-group label="Type"
                         :error="getError(errors, 'type')"
        >
            <atbl-select :options="Object.keys(configuration)"
                         :value="freeGameModel.type"
                         :disabled="isLoading"
                         name="type"
                         @update="updateGameType"
            />
        </atbl-form-group>

        <game-select v-if="!!freeGameModel.type && !!games.length"
                     :options="games"
                     :value="freeGameModel.game"
                     :disabled="isLoading"
                     :errors="errors"
                     @update="updateGame"
        />
        <not-found v-if="!!freeGameModel.type && !games.length"
                   :items="games"
                   :msg="`No ${freeGameModel.type} games are found.`"
        />

        <fields-inputs v-if="!isObjectEmpty(freeGameModel.fields) && !!freeGameModel.game"
                       :fields="freeGameModel.fields"
                       :free-game-model="freeGameModel"
                       :disabled="isLoading"
                       :errors="errors"
                       :user-free-game-settings="userFreeGameSettings"
        />

        <meta-inputs v-if="!!freeGameModel.type && !!freeGameModel.game"
                     :meta="freeGameModel.meta"
                     :disabled="isLoading"
                     :errors="errors"
        />

        <atbl-form-group v-if="!!freeGameModel.type && !!freeGameModel.game"
                         label="Expires at"
        >
            <date-picker :value="freeGameModel.expires_at"
                         name="expires_at"
                         format="YYYY-MM-DD"
                         :disabled="isLoading"
                         :min-date="minDate"
                         @update="updateFreeGameModel"
            />
            <a v-if="hasExpireData"
               href="#"
               @click="updateFreeGameModel('expires_at', null)"
            >
                <small>clear</small>
            </a>
        </atbl-form-group>

        <div v-if="freeGameModel.type === 'bundle' && !!getFlattenErrors.length">
            <div v-for="(item, index) in getFlattenErrors"
                 :key="index"
                 class="alert alert-danger"
            >
                {{ item }}
            </div>
        </div>

        <loader :show="isGameLoading"
                type="small"
        />

        <template #footer>
            <button v-if="!isLoading"
                      class="btn btn-sm btn-outline-danger mr-2"
                      @click="toggleCreateUpdateForm"
            >
                Cancel
            </button>
            <button class="btn btn-sm btn-primary"
                      :disabled="!isSaveAllowed || isLoading"
                      @click="createFreeGame"
            >
                <span v-if="!isLoading">Save</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </sidebar-sub>
</template>

<script>
import moment from 'moment';
import GameSelect from './Components/GameSelect';
import MetaInputs from './Components/MetaInputs';
import FieldsInputs from './Components/FieldsInputs';
import ObjectToArray from '../../../../../utils/Object/ObjectToArray';

export default {
    name: 'CreateUpdateFreeGameForm',
    inject: [
        'toggleCreateUpdateForm',
        'updateGameType',
        'updateFreeGameModel',
        'createFreeGame',
        'freeGameModel',
        'updateGame',
    ],
    components: {
        GameSelect,
        MetaInputs,
        FieldsInputs,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isGameLoading: {
            type: Boolean,
            default: false
        },
        configuration: {
            type: Object,
            default: () => ({})
        },
        isSaveAllowed: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        userFreeGameSettings: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        games () {
            if (!!this.freeGameModel.type) {
                const games = this.configuration[this.freeGameModel.type].games;

                return !!games.length ? games : [];
            }

            return [];
        },
        hasExpireData () {
            return !!this.freeGameModel.expires_at;
        },
        minDate() {
            return moment().format('YYYY-MM-DD');
        },
        getFlattenErrors() {
            return ObjectToArray(this.errors);
        },
    },
}
</script>