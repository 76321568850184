<template>
    <div v-if="!!collectedErrors.length">
        <div v-for="(item, index) in collectedErrors"
             :key="index"
             class="alert alert-danger w-100 rounded-0 px-3 py-2"
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ValidationErrors',
    props: {
        errors: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        collectedErrors () {
            let errors = [];

            if (! this.isObjectEmpty(this.errors)) {
                const errorsValues = Object.values(this.errors);

                for (const errorValue in errorsValues) {
                    const errorsArray = errorsValues[errorValue];

                    for (const error in errorsArray) {
                        errors.push(errorsArray[error]);
                    }
                }
            }

            return errors;
        }
    }
}
</script>