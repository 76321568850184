import { clone } from '../../../../../../utils/Json/Json';
import ListViewSearchModel from './ListViewSearchModel';

export default {
    provide() {
        return {
            updateListViewSearchFormInput: this.updateListViewSearchFormInput,
            clearListViewSearchModel: this.clearListViewSearchModel,
        };
    },
    data() {
        return {
            listViewSearchFormOpen: false,
            listViewSearchModel: clone(ListViewSearchModel),
        };
    },
    methods: {
        updateListViewSearchFormInput(name, value) {
            Object.assign(this.listViewSearchModel, {
                [name]: value
            });
        },
        toggleListViewSearchForm() {
            this.listViewSearchFormOpen = !this.listViewSearchFormOpen;
        },
        async clearListViewSearchModel() {
            this.listViewSearchModel = clone(ListViewSearchModel);

            await this.fetchCampaignLeadsListView(this.listViewSearchModel);
        },
    }
}