var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      attrs: {
        title: "Call Recordings",
        value: _vm.show,
        loader: _vm.isLoading,
        bodyClass: "px-3"
      },
      on: { input: _vm.onCloseSidebar }
    },
    [
      _c("audio-player", {
        ref: "audioPlayer",
        attrs: { url: _vm.audioUrl },
        on: {
          close: function($event) {
            _vm.audioUrl = null
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "general-list",
          class: {
            "mt-3": !!_vm.audioUrl
          }
        },
        [
          _c(
            "div",
            { staticClass: "row leads-section-list-row align-items-center" },
            [
              _c("div", { staticClass: "col text-center" }, [
                _vm._v("Duration")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-center" }, [_vm._v("Status")]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-center" }, [
                _vm._v("Operations")
              ])
            ]
          ),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.recordings, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "row leads-section-list-row align-items-center"
                  },
                  [
                    _c("div", { staticClass: "col text-center" }, [
                      _vm._v("\n                    " + _vm._s(item.duration)),
                      _c("br")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col text-center" },
                      [
                        _c("call-status-badge", {
                          attrs: { title: item.status }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col text-center" },
                      [
                        _c("recording-audio", {
                          attrs: { item: item },
                          on: {
                            play: _vm.onPlayerEvent,
                            "error-message": _vm.onErrorMessageEvent
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.recordings, msg: "No recordings found." }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      !!_vm.message
        ? _c("div", { staticClass: "alert alert-danger mt-4" }, [
            _vm._v("\n        " + _vm._s(_vm.message) + "\n    ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }