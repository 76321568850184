<template>
    <div class="card m-0">
        <div class="card-body">
            <div class="row mt-2">
                <div class="col-12 col-md-3">
                    <atbl-number label="Amount"
                                 name="amount"
                                 :errors="errors"
                                 :min="0.00"
                                 :step=".01"
                                 :max="100000"
                                 :value="amount"
                                 @update="updateAmount"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <list-payments :player-id="getPlayerId"
                                   :charge-amount="parseFloat(getFundsAmount)"
                                   :request-url="`/players/${getPlayerId}/add-funds`"
                                   :payment-types="allowedPaymentTypes"
                                   :params="{amount: getFundsAmount}"
                                   v-on:payment-successful="paySuccess"
                                   v-on:payment-failed="payFailed"
                                   v-on:payment-error="payError" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../store/NamespaceConst";
    import {createNamespacedHelpers} from "vuex";
    import ListPayments from '../../../../../components/PaymentGateways/ListPayments.vue';
    import PaymentTypes from '../../../../../constants/PaymentTypes.js'

    const {
        mapGetters: mapPlayerGetters,
        mapMutations: mapPlayerMutations,
        mapActions: mapPlayerActions
    } = createNamespacedHelpers(NamespaceConst.players);

    const {
        mapGetters: mapPaymentGetters,
        mapMutations: mapPaymentMutations
    } = createNamespacedHelpers(NamespaceConst.payment);

    import Amount from "../Mixins/Amount";

    export default {
        name: "add-funds-form",
        components: { ListPayments },
	    mixins: [
	    	Amount
	    ],
        computed: {
            ...mapPlayerGetters({
	            getPlayerId: TypesConst.players.getters.GET_PLAYER_ID,
                getFundsAmount: TypesConst.players.getters.GET_FUNDS_AMOUNT,
                getFundsCredit: TypesConst.players.getters.GET_FUNDS_CREDIT_TYPE,
	            getFundsErrors: TypesConst.players.getters.GET_FUNDS_CREDIT_CARD_ERRORS
            }),
	        ...mapPaymentGetters({
		        getPaymentLoader: TypesConst.payment.getters.GET_PAYMENT_LOADER
	        }),
            allowedPaymentTypes() {
                return [PaymentTypes.Fixed, PaymentTypes.External];
            },
        },
        methods: {
            ...mapPlayerMutations({
                setFundsAmount: TypesConst.players.mutations.SET_FUNDS_AMOUNT,
                setCreditConfirmModal: TypesConst.players.mutations.SET_FUNDS_CONFIRM_MODAL,
	            setPaymentLink: TypesConst.players.mutations.SET_FUNDS_PAYMENT_LINK,
	            setAddBalance: TypesConst.players.mutations.SET_FUNDS_ADD_BALANCE
            }),
	        ...mapPaymentMutations({
		        setPaymentLoader: TypesConst.payment.mutations.SET_PAYMENT_LOADER
	        }),

            paySuccess(data) {
                window.showMessage(data.message);
                this.setFundsAmount(0);
                this.fetchPlayerWallet();
            },

            payFailed(data) {
                window.flash(`Payment error: ${data.error}`, 'alert-danger');
            },

            payError(err) {
                window.showMessage('Payment failed. Please try again.', false);
            },
        }
    }
</script>
