<template>
    <div class="row">
        <div class="col-12 col-lg-6 d-flex">
            <TicketDrawResults />
        </div>
        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Player
                </div>

                <div class="card-body">
                    <a :href="`/players/${player.id}/view`"
                       v-if="!ticketLoader"
                       class="text-primary"
                    >
                        <h3>{{ player.name }} {{ player.surname }}</h3>
                    </a>
                    <div class="draw-results-loader-holder"
                         v-if="ticketLoader"
                    >
                        <loader type="small"
                                :show="ticketLoader"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Winnings
                </div>

                <div class="card-body">
                    <h3 class="text-primary"
                        v-if="!ticketLoader"
                    >
                        {{ win | formatCurrency }}
                    </h3>
                    <div class="draw-results-loader-holder"
                         v-if="ticketLoader"
                    >
                        <loader type="small"
                                :show="ticketLoader"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const {
        mapGetters
    } = createNamespacedHelpers(NamespaceConst.tickets);

    import TicketDrawResults from "./Components/TicketDrawResults";

    export default {
        name: "lotto-ticket-info",
        components: {
            TicketDrawResults
        },
        computed: {
            ...mapGetters({
                game: TypesConst.tickets.getters.GET_TICKETS_TICKET_GAME,
                ticketLoader: TypesConst.tickets.getters.GET_TICKETS_TICKET_DATA_LOADER,
                player: TypesConst.tickets.getters.GET_TICKETS_TICKET_PLAYER,
                lines: TypesConst.tickets.getters.GET_TICKETS_TICKET_LINES
            }),
            win: function(){
                return this.lines ? this.lines.reduce((total, item) => {
                    return  total + Number(item.prize);
                }, 0): 0;
            }
        }
    }
</script>
