var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "payment-type-badge", class: _vm.getBadge },
    [_vm._v("\n\t" + _vm._s(_vm.paymentTypeBeauty) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }