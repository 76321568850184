var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-play-layout",
    { attrs: { id: _vm.id } },
    [
      _vm.canPlayGame
        ? _c("div", { staticClass: "player-play-content" }, [
            _c(
              "div",
              { staticClass: "player-play-games" },
              [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _c("i", { staticClass: "fa fa-play" }),
                    _vm._v(" Play\n                    ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c(
                        "atbl-tabs",
                        {
                          attrs: { "active-tab": 0 },
                          on: { "change-tab": _vm.onTabChange }
                        },
                        [
                          _c(
                            "atbl-tab",
                            { staticClass: "p-2", attrs: { title: "Lottery" } },
                            [
                              _c("Games", {
                                attrs: { isLoading: _vm.isLoadingNow }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "atbl-tab",
                            {
                              staticClass: "p-2",
                              attrs: { title: "Syndicates" }
                            },
                            [_c("Syndicates")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "atbl-tab",
                            { staticClass: "p-2", attrs: { title: "Bundles" } },
                            [_c("Bundles")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "atbl-tab",
                            {
                              staticClass: "free-games-tab px-2 pt-2",
                              attrs: { title: "Free Games" }
                            },
                            [
                              _c("free-games", {
                                attrs: {
                                  games: _vm.freeGames,
                                  loading: _vm.freeGamesLoading,
                                  "order-id": _vm.orderId
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("loader", {
                  attrs: { show: _vm.getProcessingOverlay, type: "small" }
                }),
                _vm._v(" "),
                !_vm.getProcessingOverlay && _vm.showGame && _vm.isLotteryGame
                  ? _c("lottery-game", {
                      attrs: { "selected-free-game": _vm.selectedFreeGame }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.getProcessingOverlay && _vm.showGame && _vm.isRaffleGame
                  ? _c("raffle-game", {
                      attrs: { "selected-free-game": _vm.selectedFreeGame }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.getProcessingOverlay && _vm.showGame && _vm.isSyndicate
                  ? _c("syndicate-game", {
                      attrs: { "selected-free-game": _vm.selectedFreeGame }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.getProcessingOverlay && _vm.showGame && _vm.isBundle
                  ? _c("bundle-game", {
                      attrs: { "selected-free-game": _vm.selectedFreeGame }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showGame && _vm.orderId && _vm.getCheckout
                  ? _c("lottery-payments", {
                      on: { gatewayResponse: _vm.onGatewayResponse }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "player-play-checkout" },
              [_c("checkout-summary")],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("CanPlayGame", { attrs: { isLoading: _vm.isLoadingNow } }),
      _vm._v(" "),
      _c("WalletConfirmAction"),
      _vm._v(" "),
      _c("PayWithSavedCardModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }