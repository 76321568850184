var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      staticClass: "modal__payment-cashier",
      attrs: { show: true, title: _vm.title, "close-on-backdrop": true },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ])
    },
    [_c("div", { attrs: { id: "cashierWrapper" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }