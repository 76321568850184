<template>
    <div class="container-fluid">
        <AgentsFilterForm :users="users"
                          :filterModel="modelStore"
                          @modelChange="modelChange"
                          :show="false"
        />
        <AgentsDetailsCards :agent="agent"
                            :bonus="true"
                            :isLoading="isLoading"
                            :search-model="modelStore"
                            commission-report-type="day-groups"
        />
        <div class="card card-list mt-3"
        >
            <div class="card-header">
                <i class='fa fa-align-justify'></i> Details: <b>{{ agentName }}</b>
            </div>

            <div class="card-body">
                <AgentsGroupsDetailsList :user="user"
                                         :days="days"
                                         :id="id"
                                         @openAgent="openAgent"
                                         :isLoading="isLoading"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import AgentsFilterForm from "./Forms/AgentsFilterForm";
    import AgentsDetailsCards from "./Sections/AgentsDetailsCards";
    import AgentsGroupsDetailsList from "./Sections/AgentsGroupsDetailsList";

    export default {
        name: "agents-groups",
        components: {
            AgentsDetailsCards,
            AgentsGroupsDetailsList,
            AgentsFilterForm
        },
        props: {
	        id: {
		        type: Number
	        },
	        model: {
		        type: Object
	        },
        },
        data(){
            const modelStore = this.model;
            if (!modelStore.dateFrom || !modelStore.dateTo) {
                [modelStore.dateFrom, modelStore.dateTo] = this.getRangeFor(modelStore.filterDate, 'YYYY-MM-DD');
            }

            return {
                users: [],
                user: {},
                agent: {},
                days: [],
                agentName: "",
                modelStore: modelStore,
                isLoading: false
            };
        },
        methods: {
            getAgentDetails: function(){
                let vm = this;

                vm.isLoading = true;
                window.axios.get(`/reports/agents/agent/${this.id}/groups`, { params: this.modelStore })
                    .then(function (response) {
                        vm.user = response.data.user;
                        vm.days = response.data.days;
                        vm.agentName = response.data.user.name;

                        vm.agent = {
							id: response.data.user.id,
                            total: vm.days.reduce((sum, next) => sum + Number(next.sales), 0).toFixed(2),
                            commission: vm.days.reduce((sum, next) => sum + Number(next.commission), 0).toFixed(2),
                            deposits: vm.days.reduce((sum, next) => sum + Number(next.deposit), 0).toFixed(2),
                            bonus: vm.days.reduce((sum, next) => sum + Number(next.bonus), 0).toFixed(2)
                        };
                    })
                    .finally(() => {
                        vm.isLoading = false;
                    });
            },
            modelChange: function(value){
                this.modelStore = value;
                this.filteredRange = this.ucFirst(this.modelStore.filterDate);
                this.getAgentDetails();
            },
            openAgent(date){
                this.modelStore.filterDate = "daterange";
                this.modelStore.dateFrom = date;
                this.modelStore.dateTo = date;
                window.open(`/reports/agents/agent/${this.id}?${this.buildQuery(this.modelStore)}`);
            }
        },
        created: function(){
            this.getAgentDetails();
        }
    }
</script>