var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 col-lg-6 d-flex" },
      [_c("TicketDrawResults")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-lg-3 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Player\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          !_vm.ticketLoader
            ? _c(
                "a",
                {
                  staticClass: "text-primary",
                  attrs: { href: "/players/" + _vm.player.id + "/view" }
                },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.player.name) + " " + _vm._s(_vm.player.surname)
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ticketLoader
            ? _c(
                "div",
                { staticClass: "draw-results-loader-holder" },
                [
                  _c("loader", {
                    attrs: { type: "small", show: _vm.ticketLoader }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-lg-3 d-flex" }, [
      _c("div", { staticClass: "card flex-fill draw-results" }, [
        _c("div", { staticClass: "card-header card-header-atbl" }, [
          _vm._v("\n                Winnings\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          !_vm.ticketLoader
            ? _c("h3", { staticClass: "text-primary" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm._f("formatCurrency")(_vm.win)) +
                    "\n                "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.ticketLoader
            ? _c(
                "div",
                { staticClass: "draw-results-loader-holder" },
                [
                  _c("loader", {
                    attrs: { type: "small", show: _vm.ticketLoader }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }