<template>
    <span :class="['badge', color]"
             v-tooltip="{
                content: 'Created through'
             }"
    >
        {{ createdThrough}}
    </span>
</template>

<script>
import CreatedThroughConstants from './CreatedThroughConstants';

export default {
    name: 'CreatedThrough',
    props: {
        createdThrough: {
            type: String,
            default: CreatedThroughConstants.CRM
        },
    },
    computed: {
        color() {
            return this.createdThrough === CreatedThroughConstants.CRM
                ? 'bg-primary'
                : 'bg-purple';
        },
    },
}
</script>