export default {
    cartRoute: "/cart",
    cartWithIdRoute: function(id){
        return `${this.cartRoute}${id ? `/${id}` : ''}`;
    },
    addToCart: function(id = null, params = {}){
        return window.axios.post(this.cartWithIdRoute(id), params);
    },
    clearCart: function(id = null, params = {}){
        return window.axios.post(`${this.cartWithIdRoute(id)}/clear`, params);
    },
    removeItem: function(id = null, params = {}){
        return window.axios.delete(this.cartWithIdRoute(id), {
            params
        });
    }
};