import {clone} from '../../../../../utils/Json/Json';

const errorModel = {
	idRange: {
		status: true,
		message: null,
	},
	age: {
		status: true,
		message: null,
	},
	date: {
		status: true,
		message: null,
	},
	balanceSelectorValidatorMessage: {
		status: true,
		message: null,
	},
	liveSpentSelectorError: {
		status: true,
		message: null,
	},
	liveDepositSelectorError: {
		status: true,
		message: null,
	},
	liveRefundSelectorError: {
		status: true,
		message: null,
	},
	totalWinSelectorError: {
		status: true,
		message: null,
	},
	totalWithdrawalSelectorError: {
		status: true,
		message: null,
	},
};

export default {
	data() {
		return {
			errorModel: clone(errorModel),
			isSearchFormValid: true,
		};
	},
	methods: {
		validateSearchForm(searchModel) {
			this.errorModel = {
				idRange: this.validateIdRange(searchModel),
				age: this.validateAge(searchModel),
				date: this.validateDate(searchModel),
				balanceSelectorValidatorMessage: this.validateBalance(searchModel),
				liveSpentSelectorError: this.validateComparableInput('liveSpent'),
				liveDepositSelectorError: this.validateComparableInput('liveDeposit'),
				liveRefundSelectorError: this.validateComparableInput('liveRefund'),
				totalWinSelectorError: this.validateComparableInput('totalWin'),
				totalWithdrawalSelectorError: this.validateComparableInput('totalWithdrawal'),
			};

			const statuses = Object.values(this.errorModel).map((error) => error.status);

			this.isSearchFormValid = !statuses.includes(false);
		},
		validateIdRange(searchModel) {
			const isIdRangeValid = (!!searchModel.playerIdFrom && !!searchModel.playerIdTo)
				? parseInt(searchModel.playerIdFrom) <= parseInt(searchModel.playerIdTo)
				: true;

			return {
				status: isIdRangeValid,
				message: !!isIdRangeValid
					? null
					: 'Please enter valid ID Range'
			};
		},
		validateDate(searchModel) {
			const isDatePopulated = !!searchModel.dateFrom && !!searchModel.dateTo
			const isDateTypesPopulated = searchModel.dateTypes.length > 0

			const isDateValid = (isDatePopulated && isDateTypesPopulated) ||
				(!isDatePopulated && !isDateTypesPopulated);

			return {
				status: isDateValid,
				message: isDateValid
					? null
					:  isDatePopulated && !isDateTypesPopulated
						? "Please select date type"
						: "Please select valid date"
			};
		},
		validateAge(searchModel) {
			const isAgeValid = (!!searchModel.ageFrom && !!searchModel.ageTo)
				? parseInt(searchModel.ageFrom) <= parseInt(searchModel.ageTo)
				: true;

			return {
				status: isAgeValid,
				message: isAgeValid
					? null
					: 'Please enter valid Age Range'
			};
		},
		validateBalance(searchModel) {
			const {
				balanceType,
				balanceSelector,
				balance,
			} = searchModel;

			if (!balance && !balanceSelector && !balanceType) {
				return {
					status: true,
					message: null
				};
			}

			const validatorMessage = !!this.errorModel.balanceSelectorValidatorMessage
				? this.errorModel.balanceSelectorValidatorMessage
				: null;

			const isBalanceValid = !!balance && !!balanceSelector && !!balanceType;

			return {
				status: isBalanceValid,
				message: !!isBalanceValid
					? validatorMessage
					: 'Balance values are invalid, no filter will be applied.'
			};
		},
		validateComparableInput(name) {
			const { status, message } = this.errorModel[`${name}SelectorError`];

			return {
				status,
				message
			};
		},
		updateSearchModelError(name, error) {
			const { isValid, message, showMessage } = error || {
				isValid: true,
				message: null,
				showMessage: false,
			};

			Object.assign(this.errorModel, {
				[`${name}SelectorError`]: {
					status: isValid,
					message: !!showMessage
						? message
						: null
				}
			});
		},
		clearSearchErrors() {
			this.errorModel = clone(errorModel);
		},
	},
}