<template>
    <div>
        <div v-if="!isConfirmed" class="bulk-assign-agent">
            <h3 class="mb-3">Unassign multiple players to agent.</h3>
            <div class="mb-3">
                <label class="font-weight-bold">Players</label><br />
                <span v-for="(item, index) in getPlayersForAssign"
                      :key="index"
                      class="badge badge-primary mr-1"
                >
                    #{{ item.id }} / {{ item.name }} {{ item.surname }}
                </span>
            </div>
        </div>
        <div v-else class="p-3">
            <h5 v-if="!isLoading"
                class="text-center mb-0"
            >
                Are you sure you want to continue?
            </h5>
            <h5 v-else class="text-center text-secondary mb-0">PROCESSING...</h5>
        </div>
        <div class="modal-footer d-block d-flex justify-content-end">
            <div v-if="!isConfirmed">
                <button class="btn btn-sm btn-danger mr-2"
                        @click.prevent="closeBulkForm"
                >
                    Close
                </button>
                <button class="btn btn-sm btn-primary"
                        :disabled="!getPlayersForAssign.length"
                        @click.prevent="isConfirmed = true"
                >
                    Continue
                </button>
            </div>
            <div v-else>
                <button class="btn btn-sm btn-danger mr-2"
                        @click.prevent="isConfirmed = false"
                >
                    Cancel
                </button>
                <button class="btn btn-sm btn-success"
                        :disabled="!getPlayersForAssign.length"
                        @click.prevent="onAssignAgentsEvent"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import PlayerService from '../../../../../../services/PlayerService';

export default {
    name: 'un-assign-agent',
    inject: [
        'closeBulkForm',
        'clearBulk',
        'fetchPlayers',
    ],
    props: {
        players: {
            type: Array,
            default: () => ([])
        },
        selectedPlayers: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            selectedAgent: null,
            isLoading: null,
            isConfirmed: false,
        };
    },
    computed: {
        getPlayersForAssign() {
            return !this.players.length || !this.selectedPlayers.length
                ? []
                : this.players.filter(item => this.selectedPlayers.includes(item.id));
        },
    },
    methods: {
        async onAssignAgentsEvent() {
            this.setLoader(true);

            try {
                const response = await PlayerService.bulkAssignAgent({
                    agent: this.selectedAgent,
                    players: this.selectedPlayers,
                });
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.clearBulk();
                    this.closeBulkForm();
                    await this.fetchPlayers();
                }

                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);

                window.flashBadResponse(error);
            }
        },
        setLoader(value = false) {
            this.isLoading = value;

            this.$emit('loader', value);
        }
    },
}
</script>