export default {
	provide () {
		return {
			toggleCopyMagicLink: this.toggleCopyMagicLink,
		}
	},
	data () {
		return {
			copyMagicLinkOpened: false,
		}
	},
	methods: {
		toggleCopyMagicLink() {
			this.copyMagicLinkOpened = !this.copyMagicLinkOpened;
		}
	}
}