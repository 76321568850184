var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-list" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.transactions, function(transaction, index) {
        return _c("div", { key: index, staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-lg-2 text-muted text-center text-lg-left"
            },
            [
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Id")]),
              _vm._v(" "),
              _vm.$can("Transactions - View Transaction Details")
                ? _c(
                    "a",
                    { attrs: { href: "/transaction/" + transaction.id } },
                    [
                      _vm._v(
                        "\n                #T-" +
                          _vm._s(transaction.id) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Agent")]),
              _vm._v(" "),
              transaction.user
                ? _c("span", [
                    _c("b", [_vm._v("#" + _vm._s(transaction.user.id))]),
                    _vm._v(
                      " / " + _vm._s(transaction.user.name) + "\n            "
                    )
                  ])
                : _c("span", [_vm._v("\n                /\n            ")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Player")]),
              _vm._v(" "),
              transaction.player
                ? _c("span", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/players/" + transaction.player.id + "/view",
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(transaction.player.name) +
                            " " +
                            _vm._s(transaction.player.surname) +
                            "\n                "
                        )
                      ]
                    )
                  ])
                : _c("span", [_vm._v("\n                /\n            ")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Amount")]),
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatCurrency")(transaction.amount)) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-1 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [
                _vm._v("Gateway")
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  attrs: {
                    color: [
                      "badge",
                      "badge-" +
                        _vm.$options.Gateways.gatewayColor(transaction.gateway)
                    ]
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        transaction.gateway
                          ? _vm.ucFirst(transaction.gateway)
                          : "Atbl"
                      ) +
                      "\n            "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [
                _vm._v("Payment Type")
              ]),
              _vm._v(" "),
              _c("transaction-badge", { attrs: { transaction: transaction } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-1 text-center align-self-center" },
            [
              _c("span", { staticClass: "small-mb-badge" }, [_vm._v("Status")]),
              _vm._v(" "),
              _c(
                "span",
                {
                  class:
                    "badge " +
                    (transaction.transaction_type
                      ? "badge-success"
                      : "badge-danger")
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        transaction.transaction_type ? "Success" : "Failed"
                      ) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        ])
      }),
      _vm._v(" "),
      _c("not-found", {
        attrs: { msg: "No transactions created.", items: _vm.transactions }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-none d-lg-flex" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-2 text-muted text-center text-lg-left" },
        [_vm._v("Id")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Agent")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Player")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Amount")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-1 text-muted text-center" }, [
        _vm._v("Gateway")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 text-muted text-center" }, [
        _vm._v("Payment Type")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-1 text-muted text-center" }, [
        _vm._v("Status")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }