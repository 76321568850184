var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "player-play-header" }, [
    _c(
      "div",
      {
        staticClass:
          "mb-1 d-flex align-items-center justify-content-center justify-content-xl-between"
      },
      [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("img", {
            staticClass: "game-thumb mx-2",
            attrs: {
              src: _vm.$imageService.gameImage(_vm.game.image),
              alt: _vm.game.name
            }
          }),
          _vm._v(" "),
          _c("h5", { staticClass: "text-muted m-0" }, [
            _vm._v(_vm._s(_vm.game.name))
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-sm btn-link", on: { click: _vm.closeGame } },
          [
            _c("i", {
              staticClass: "fa fa-times",
              attrs: { "aria-hidden": "false" }
            })
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "player-play-header-description" }, [
      _c("p", { staticClass: "text-muted" }, [
        _c("strong", [_vm._v("Draws Every: ")]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(_vm._f("shortDays")(_vm.game.weekdays)) +
              " @ " +
              _vm._s(_vm._f("dateFormat")(this.game.cutOffTime, "H:mm", false))
          )
        ]),
        _vm._v(" "),
        _c("strong", [_vm._v("Next Draw Closes:")]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm._f("dateFormat")(
                this.game.cutOffTime,
                "ddd D MMM @ H:mm",
                false
              )
            )
          )
        ]),
        _vm._v(" "),
        _c("strong", [_vm._v("Est. Jackpot:")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.game.jackpot))])
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "text-muted" },
        [
          _vm._v("\n            Play\n            "),
          _vm._l(_vm.configuration, function(item, index) {
            return _c("span", { key: index }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    !item.key
                      ? _vm.game.name
                      : (_vm.getKeyIndexByType(index) ===
                        _vm.getKeyNumbersLength
                          ? "and "
                          : ", ") +
                          "\n                " +
                          _vm.ucFirst(item.key)
                  ) +
                  "\n                " +
                  _vm._s(item.numbers) +
                  " number" +
                  _vm._s(item.numbers > 1 ? "s" : "") +
                  " from " +
                  _vm._s(item.minRange) +
                  " to " +
                  _vm._s(item.maxRange) +
                  "\n            "
              )
            ])
          }),
          _vm._v("\n            or pick Lucky Dip.\n        ")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }