<template>
	<atbl-modal :close-on-backdrop="false"
	         :show="openModal"
	         :class="{
	         	'hide-footer': redirecting
	         }"
			 @close="openModal = false"
	>
		<template #header>
			<h5 class="m-0">Card payment</h5>
		</template>

		<PayWithSavedCard v-if="!hideContent && !getPayWithSavedCardModalLoader && !getConfirmPay"/>
		<ConfirmPayWithCard v-if="!hideContent && !getPayWithSavedCardModalLoader && getConfirmPay"/>

		<div v-if="hideContent && !redirecting"
		     class="alert text-center m-0"
		     :class="`alert-${status}`"
		>
			{{ message }}
		</div>

		<div v-if="redirecting"
		     class="alert"
		     :class="{
                 'alert-danger': !redirecting,
                 'alert-success': redirecting
             }"
		>
			<span class="font-weight-bold">{{ message }}</span>
		</div>
		<h5 v-if="redirecting"
		    class="text-center mt-2"
		>
			Redirecting in {{ countDown }} seconds...
		</h5>

		<template #footer>
			<div class="d-flex w-100 align-items-center">
				<div class="w-50 font-weight-bold">
					* Required
				</div>
				<div class="w-50 text-right">
					<div v-if="!getConfirmPay">
						<button class="btn btn-sm btn-danger"
						          @click="openModal = false"
						          v-if="!redirecting"
						>
							Close
						</button>
						<button class="btn btn-primary"
						        :disabled="!cardModel.id"
						        @click="onCancelConfirm(true)"
						        v-if="!redirecting"
						>
                            <span>
                                Continue
                            </span>
						</button>
					</div>
					<div v-else>
						<button class="btn btn-sm btn-danger"
						          @click="cancelAction"
						          v-if="(!isConfirmed && !getPayWithSavedCardModalLoader && !redirecting) || status === 'danger'"
						>
							Cancel
						</button>
						<button class="btn"
						        :class="{
                                    'btn-success': !getPayWithSavedCardModalLoader,
                                    'btn-outline-success': getPayWithSavedCardModalLoader
                                }"
						        :disabled="!cardModel.id"
						        v-if="!redirecting"
						        @click="confirmPay"
						>
                            <span v-if="!getPayWithSavedCardModalLoader">
                                Confirm
                            </span>
							<loader :show="getPayWithSavedCardModalLoader"
							        type="smaller"
							/>
						</button>
					</div>
				</div>
			</div>
		</template>
	</atbl-modal>
</template>

<script>
	import TypesConst from "../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";

	const {
		mapGetters,
		mapMutations,
		mapActions
	} = createNamespacedHelpers(NamespaceConst.players);

	const {
		mapGetters: mapCartsGetters,
		mapMutations: mapCartMutations,
		mapActions: mapCartActions
	} = createNamespacedHelpers(NamespaceConst.cart);

    const {
        mapGetters: mapOrderGetters
    } = createNamespacedHelpers(NamespaceConst.order);

	import PayWithSavedCard from "./Sections/PayWithSavedCard";
	import ConfirmPayWithCard from "./Sections/ConfirmPayWithCard";
	import Payment from "../../../../../../modules/payment/Payment";
	import Cart from "../../../../../../modules/cart/Cart";
	import CheckoutAction from "../../Mixins/CheckoutAction";
	import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';

	export default {
		name: "pay-with-saved-card-modal",
		components: {
			PayWithSavedCard,
			ConfirmPayWithCard,
			AtblModal,
		},
		mixins: [
			CheckoutAction
		],
		data: function () {
			return {
				isConfirmed: false,
				countDown: 3,
				redirecting: false,
				message: null,
				status: null,
				hideContent: false
			};
		},
		computed: {
			...mapGetters({
				getPayWithSavedCardModal: TypesConst.players.getters.GET_PAY_WITH_SAVED_CARD_MODAL,
				getPayWithSavedCardModalLoader: TypesConst.players.getters.GET_PAY_WITH_SAVED_CARD_MODAL_LOADER,
				getConfirmPay: TypesConst.players.getters.GET_CONFIRM_PAY_WITH_CARD,
				cardModel: TypesConst.players.getters.GET_PAY_WITH_MODEL,
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
			}),
			...mapCartsGetters({
				getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
				getCartTotal: TypesConst.cart.getters.GET_CART_TOTAL
			}),
            ...mapOrderGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            }),
			openModal: {
				get: function () {
					return this.getPayWithSavedCardModal;
				},
				set: function (value) {
					let vm = this;

					vm.setPayWithSavedCardModal(value);

					if (!value) {
						Atbl.$emit("lotteryPaymentCanceled");
						setTimeout(function(){
							vm.hideContent = false;
						}, 500);
						vm.setCard({
							id: null
						});
					}
				}
			}
		},
		methods: {
			...mapMutations({
				setPayWithSavedCardModal: TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL,
				setPayWithSavedCardModalLoader: TypesConst.players.mutations.SET_PAY_WITH_SAVED_CARD_MODAL_LOADER,
				onCancelConfirm: TypesConst.players.mutations.SET_CONFIRM_PAY_WITH_CARD,
				setCard: TypesConst.players.mutations.SET_PAY_WITH_MODEL_FIELD
			}),
			...mapActions({
				getPlayerCreditCards: TypesConst.players.actions.GET_PLAYERS_SAVED_CARDS_ACTIONS
			}),
			...mapCartMutations({
				clearCart: TypesConst.cart.mutations.SET_CLEAR_CART_DATA,
				setCheckout: TypesConst.cart.mutations.SET_CHECKOUT
			}),
			...mapCartActions({
				resetCartAction: TypesConst.cart.actions.RESET_CART_ACTION
			}),
			confirmPay: function () {
				this.isConfirmed = true;
				this.cardPayment();
			},
			counter: function () {
				if (this.countDown > 0) {
					setTimeout(() => {
						this.countDown -= 1;
						this.counter();
					}, 1000)
				} else {
					this.openModal = false;
					this.paymentProcessingRedirect();
				}
			},
            cardPayment() {
                if (!this.orderId) {
                    this.setPayWithSavedCardModalLoader(false);
                    this.openModal = false;
                    flash("Cart not found. Create new one.", "alert-danger");
                    return;
                }

                const params = {
                    order_id: this.orderId,
                    card_id: this.cardModel.id,
                };

                this.setPayWithSavedCardModalLoader(true);
                this.message = 'Payment processing...';
                this.status = 'warning';
                this.hideContent = true;

                window.axios.post('/gateway-purchase', params).then(response => {
                    const responseData = response.data;
                    const isPaid = !!responseData.paid;

                    if(!isPaid) {
                        this.status = 'danger';
                        this.message = responseData.error || 'Something went wrong. Please try again';
                        flash(this.message, 'alert-danger');
                        return;
                    }

                    this.redirecting = true;
                    this.status = 'success';
                    this.message = responseData.message;
                }).catch(error => {
                    if(error.response.status === 422) {
                        this.$store.commit(`cart/setClearCartData`);
                        this.$store.commit(`cart/setCheckout`, { checkout: false, cartTotal: 0});
                    }

                    this.message = 'Payment failed. Please try again.';
                    this.status = 'danger';
                }).finally(() => {
                    this.setPayWithSavedCardModalLoader(false);
                    this.counter();
                });
            },
			paymentProcessingRedirect: function () {
                window.location.href = this.buildUrl(`/players/${this.getPlayerId}/orders`);
			},
			cancelAction: function () {
				this.onCancelConfirm(false);
				this.hideContent = false;
			}
		},
		created: function () {
			this.getPlayerCreditCards(false);
		}
	}
</script>