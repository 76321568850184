var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: {
        label: "Tags",
        "label-for": "Tags",
        horizontal: false,
        error: _vm.getError(_vm.errors, _vm.name)
      }
    },
    [
      !!_vm.searchOnly
        ? _c("tree-select", {
            attrs: {
              multiple: true,
              searchable: true,
              "clear-on-select": true,
              options: _vm.tags,
              disabled: !!_vm.disabled || !!_vm.isLoading,
              "disable-branch-nodes": true,
              normalizer: function(node) {
                return { id: node.id, label: node.name }
              },
              placeholder:
                "" + (!!_vm.isLoading ? "Loading..." : "Select options")
            },
            model: {
              value: _vm.selectedValues,
              callback: function($$v) {
                _vm.selectedValues = $$v
              },
              expression: "selectedValues"
            }
          })
        : _c("atbl-treeselect", {
            attrs: {
              multiple: true,
              searchable: true,
              "clear-on-select": true,
              options: _vm.tags,
              disabled: !!_vm.disabled || !!_vm.isLoading,
              "disable-branch-nodes": true,
              normalizer: function(node) {
                return { id: node.id, label: node.name }
              },
              placeholder:
                "" + (!!_vm.isLoading ? "Loading..." : "Select options")
            },
            on: { "new-node": _vm.appendNewItem },
            model: {
              value: _vm.selectedValues,
              callback: function($$v) {
                _vm.selectedValues = $$v
              },
              expression: "selectedValues"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }