var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card border-0 m-0" }, [
    _c("div", { staticClass: "card-body p-3" }, [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("ID")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("Client")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("Agent")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("Call Status & Date")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("Talk Time")]
            ),
            _vm._v(" "),
            _vm.$can("Call History - Recordings")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                  },
                  [
                    _vm._v(
                      "\n                        Audio\n                    "
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.callHistoryList, function(item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("ID")
                      ]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.id) +
                          "\n                        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Client")
                      ]),
                      _vm._v(" "),
                      item.callable &&
                      _vm.isset(item.callable, "name") &&
                      _vm.isset(item.callable, "surname")
                        ? _c("span", [
                            _vm._v(
                              "\n\t\t                        " +
                                _vm._s(item.callable.name) +
                                " " +
                                _vm._s(item.callable.surname) +
                                "\n\t\t                    "
                            )
                          ])
                        : _c("span", [_vm._v("/")]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      item.callable && _vm.isset(item.callable, "id")
                        ? _c("small", [
                            _c("strong", [_vm._v("ID:")]),
                            _vm._v(
                              " " +
                                _vm._s(item.callable.id) +
                                "\n                                "
                            ),
                            _c("strong", [_vm._v("TYPE:")]),
                            _vm._v(
                              " " +
                                _vm._s(item.callable_type.split("\\")[1]) +
                                "\n                            "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Agent")
                      ]),
                      _vm._v(" "),
                      item.user && _vm.isset(item.user, "name")
                        ? _c("span", [
                            _vm._v(
                              "\n\t\t                        " +
                                _vm._s(item.user.name) +
                                "\n\t\t                    "
                            )
                          ])
                        : _c("span", [_vm._v("/")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Call Status & Date")
                      ]),
                      _vm._v(" "),
                      _c("call-status-badge", {
                        attrs: { title: item.status }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(_vm._s(_vm.utcToLocal(item.created_at)))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Talk Time")
                      ]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.emptyField(item.duration)) +
                          "\n                        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.$can("Call History - Recordings")
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Audio")
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: "Recordings"
                                  },
                                  expression:
                                    "{\n                                        content: 'Recordings'\n                                    }"
                                }
                              ],
                              staticClass: "audio-link",
                              on: {
                                click: function($event) {
                                  return _vm.showCallRecordings(item.id)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-file-audio",
                                attrs: { "aria-hidden": "false" }
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: {
                  items: _vm.callHistoryList,
                  msg: "No call history found."
                }
              })
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    !_vm.isLoading && _vm.statistics.last_page > 1
      ? _c("div", { staticClass: "card-footer" }, [
          _c(
            "nav",
            { staticClass: "wrap" },
            [
              _c("atbl-pagination", {
                staticClass: "m-0",
                attrs: { pagination: _vm.statistics },
                on: {
                  paginate: function($event) {
                    return _vm.fetchGeneralCallStatistics(
                      _vm.callHistorySearchModel,
                      _vm.agentId,
                      $event
                    )
                  }
                }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }