var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.canPlayGame
    ? _c(
        "div",
        {
          staticClass:
            "text-center col justify-content-center align-items-center d-flex flex-column m-auto mb-4",
          class: _vm.withScreen
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _c("h4", { staticClass: "text-muted" }, [
                  _vm._v("Please fill in all required fields.")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(
                    "\n\t\t\t\tTo complete player profile please go to\n\t\t\t\t"
                  ),
                  _c(
                    "a",
                    { attrs: { href: "/players/" + _vm.player.id + "/view" } },
                    [_vm._v("\n\t\t\t\t\tPlayer Profile\n\t\t\t\t")]
                  ),
                  _vm._v(
                    "\n\t\t\t\t(update: " +
                      _vm._s(_vm.canPlayData) +
                      ").\n\t\t\t"
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }