var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atbl-date-range-picker" },
    [
      _c("date-range-picker", {
        attrs: {
          opens: _vm.direction,
          singleDatePicker: false,
          ranges: _vm.rangesTemplate,
          "locale-data": _vm.localeDataOptions,
          showDropdowns: _vm.showDropdowns,
          "auto-apply": _vm.autoApply
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function(picker) {
              return _c("div", {}, [
                !!_vm.hasValue
                  ? _c("span", [_vm._v(_vm._s(_vm.selectedValue))])
                  : _c("span", [_vm._v("Select date")])
              ])
            }
          }
        ]),
        model: {
          value: _vm.selectedDate,
          callback: function($$v) {
            _vm.selectedDate = $$v
          },
          expression: "selectedDate"
        }
      }),
      _vm._v(" "),
      !!_vm.clearable && !!_vm.hasValue
        ? _c(
            "a",
            {
              staticClass: "atbl-date-range-picker-close",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onClearValueEvent.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "fa fa-times-circle" })]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }