<template>
	<sidebar-sub title="Notification"
	             v-model="openSidebar"
	             :loader="getSidebarLoading"
	>
		<template v-if="!getSidebarLoading">
			<h5>Message</h5>
			<p>
				{{ getNotificationModel.data.message }}
			</p>

			<h5>Data</h5>
			<hr/>
			<div class="row"
			     v-for="(value, key) in getDataInformation"
			     :key="key"
			>
				<div class="col-6">
					<b>{{ key }}</b>
				</div>
				<div class="col-6">
					{{ value }}
				</div>
				<div class="col-12">
					<hr class="my-1"/>
				</div>
			</div>
		</template>

		<loader class="mt-2"
		        type="small"
		        :show="getSidebarLoading"
		/>
	</sidebar-sub>
</template>

<script>
	import TypesConst from "../../../store/TypesConst";
	import NamespaceConst from "../../../store/NamespaceConst";
	import { createNamespacedHelpers } from "vuex";

	const {
		mapGetters: mapNotificationsGetters,
		mapMutations: mapNotificationsMutations,
		mapActions: mapNotificationsActions
	} = createNamespacedHelpers(NamespaceConst.notifications);

    export default {
        name: "read-notification-sidebar",
	    computed: {
        	...mapNotificationsGetters({
		        getSidebarShow: TypesConst.notifications.getters.GET_NOTIFICATIONS_READ_BAR,
		        getSidebarLoading: TypesConst.notifications.getters.GET_NOTIFICATIONS_READ_BAR_LOADER,
		        getNotificationModel: TypesConst.notifications.getters.GET_NOTIFICATIONS_MODEL
	        }),
		    openSidebar: {
        		get: function(){
        			return this.getSidebarShow;
		        },
			    set: function(value){
					this.setSidebarShow(value);

				    let id = this.getParam("id");
					if(id) {
						window.history.pushState({}, "", '/notifications');
					}
			    }
		    },
		    getDataInformation: function() {
        		return _.omit(this.getNotificationModel.data, "message");
		    }
	    },
	    methods: {
        	...mapNotificationsMutations({
		        setSidebarShow: TypesConst.notifications.mutations.SET_NOTIFICATIONS_READ_BAR
	        })
	    }
    }
</script>