export default {
    general: "general/",
    settings: "settings/",
    players: "players/",
    tickets: "tickets/",
    transactions: "transactions/",
    users: "users/",
    cart: "cart/",
    payment: "payment/",
    games: "games/",
    callback: "callback/",
    notifications: "notifications/",
    order: "order/",

    // create namespace constant
    create: function(namespace, path){
        let getNamespace = namespace.replace("/", "");
        return `${this[getNamespace]}${path}`;
    }
};
