import TypesConst from "../../../TypesConst";
import TicketsService from "../../../../services/TicketsService";

export default {
	[TypesConst.tickets.actions.GET_TICKET_BY_ID]({commit, state}) {
		commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA_LOADER, true);
		commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA, {});

		TicketsService.getTicketById(state.ticketId)
			.then(response => {
				if (response.data) {
					commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA, response.data);
				}
				commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA_LOADER, false);
			})
			.catch(error => {
				if (error.response.status === 403) {
					return;
				}
				flashError(error);
			})
			.finally(() => {
				commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA_LOADER, false);
			});
	},
	[TypesConst.tickets.actions.GET_RAFFLE_BY_ID]({commit, state}) {
		commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA_LOADER, true);
		commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA, {});

		TicketsService.getRaffleById(state.ticketId)
			.then(response => {
				if (response.data) {
					commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA, response.data);
				}
			})
			.catch(error => {
				if (error.response.status === 403) {
					return;
				}
				flashError(error);
			})
			.finally(() => {
				commit(TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA_LOADER, false);
			})
	},
};