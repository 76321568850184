<template>
    <atbl-modal title="Create Syndicate" :close-on-backdrop="false" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <atbl-input label="Syndicate Title" v-model="title" :errors="errors" />
            </div>

            <div class="col-12">
                <atbl-input label="Syndicate Slug" v-model="slug" :errors="errors" />
            </div>

            <div class="col-6">
                <atbl-form-group label="Game" label-for="game" class="font-weight-bold" :horizontal="false" :error="null">
                    <select class="form-control" id="game" v-model="game">
                        <option :value="null">Select Game</option>
                        <option v-for="game in gamesWithDraws" :key="game.value" :value="game.value">
                            {{ game.text }}
                        </option>
                    </select>
                </atbl-form-group>
            </div>

            <div class="col-6">
                <atbl-input label="Share price" placeholder="Enter share price" v-model="sharePrice" :errors="errors" />
            </div>

            <div class="col-6">
                <atbl-input label="Lines" placeholder="Enter number of ticket lines" v-model="linesCount" :errors="errors" />
            </div>

            <div class="col-6">
                <atbl-input label="Shares" placeholder="Enter syndicate shares" v-model="shares" :errors="errors" />
            </div>

            <div class="col-12">
                <atbl-input label="Max. future draws" placeholder="Enter max future draws" v-model.number="maxFutureDraws" :errors="errors" />
            </div>

            <div class="col-6">
              <atbl-check-box :value="recurring"
                              label="Recurring"
                              class="mr-1"
                              @input="recurring = ! recurring"
              />
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-light" @click="emitCloseEvent">
                Close
            </button>

            <button class="btn btn-sm btn-primary" @click="submitSyndicate()">
                Create
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblCheckBox from '../../../components/Forms/Inputs/AtblCheckbox.vue';
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'syndicates-create-modal',
	components: {AtblCheckBox, AtblModal,},

    props: [],

    data() {
        return {
            gameList: [],
            gameDraws: [],

            title: null,
            slug: null,
            game: null,
            sharePrice: null,
            linesCount: null,
            cutOffAt: null,
            shares: null,
            recurring: false,
            maxFutureDraws: null,

            errors: null,
        };
    },

    computed: {
        gamesWithDraws() {
            return this.gameList.filter(g => {
                const gameDraw = this.gameDraws.find(d => d.name === g.text);

                return gameDraw && gameDraw.support_syndicate;
            });
        },
    },

    mounted() {
        this.fetchGameDraws();
        this.fetchGames();
    },

    methods: {
        emitCloseEvent() {
            this.$emit('cancel');
        },

        fetchGames() {
            const params = {
                type: 'lottery',
            };

            window.axios.get('/games', { params }).then(response => {
                this.gameList = response.data;
            });
        },

        fetchGameDraws() {
            window.axios.get('/games/all').then(response => {
                this.gameDraws = response.data.data;
            });
        },

        submitSyndicate() {
            this.$emit('confirm', {
                title: this.title,
                slug: this.slug,
                game: this.game,
                sharePrice: this.sharePrice,
                linesCount: this.linesCount,
                shares: this.shares,
                cutOffAt: this.cutOffAt,
                recurring: this.recurring,
                maxFutureDraws: this.maxFutureDraws,
            });
        },
    },
}
</script>