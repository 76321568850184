import { clone } from '../../../../utils/Json/Json';
import SearchFormModel from './SearchFormModel';

export default {
    provide() {
        return {
            updateSearchFormModelField: this.updateSearchFormModelField,
            clearSearchFormModel: this.clearSearchFormModel,
        };
    },
    data() {
        return {
            searchFormModel: clone(SearchFormModel),
            searchFormLoader: false,
        };
    },
    methods: {
        updateSearchFormModelField(name, value) {
            Object.assign(this.searchFormModel, {
                [name]: value,
            });
        },
        async clearSearchFormModel() {
            this.searchFormModel = clone(SearchFormModel);

            await this.fetchPermissions();
        },
    },
}