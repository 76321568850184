import { clone } from '../../../../../utils/Json/Json';
import LeadsFilterFormModel from './LeadsFilterFormModel';

export default {
    provide() {
        return {
            changeFilterModelField: this.changeFilterModelField,
            onClearFilterFormEvent: this.onClearFilterFormEvent,
        };
    },
    data() {
        return {
            modelSearch: clone(LeadsFilterFormModel),
        };
    },
    methods: {
        changeFilterModelField(name, value) {
            Object.assign(this.modelSearch, {
                [name]: value,
            });
        },
        async onClearFilterFormEvent() {
            this.modelSearch = clone(LeadsFilterFormModel);

            await this.fetchLeads();
        },
    },
}