var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    {
      attrs: { title: "Leads Statuses", icon: "nav-icon fa fa-clock" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _vm.$can("Leads Statuses - Create/Edit")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary m-0",
                      on: { click: _vm.toggleCreateUpdateForm }
                    },
                    [_vm._v("\n            Create\n        ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "modals",
          fn: function() {
            return [
              _vm.$can("Leads Statuses - Create/Edit")
                ? _c("create-or-update-lead-status-form-component", {
                    attrs: {
                      show: _vm.isCreateUpdateOpened,
                      model: _vm.leadStatusModel,
                      errors: _vm.leadStatusModelErrors,
                      "is-loading": _vm.isCreateUpdateLoading
                    }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "leads-section-list" },
        [
          _c(
            "div",
            { staticClass: "row leads-section-list-row d-none d-lg-flex mb-0" },
            [
              _c("div", { staticClass: "col-lg-1" }, [_vm._v("Id")]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg" }, [
                _vm._v("Lock time (minutes)")
              ]),
              _vm._v(" "),
              _vm.$canAny(_vm.operationPermissions)
                ? _c("div", { staticClass: "col-12 col-lg" }, [
                    _vm._v("\n                Operations\n            ")
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.statuses, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "row leads-section-list-row" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg-1 align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Id")
                        ]),
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.value) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Name")
                        ]),
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.label) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg align-self-center mb-2 mb-lg-0"
                      },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Lock time (minutes)")
                        ]),
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.lock_time || "/") +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.$canAny(_vm.operationPermissions)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg align-self-center mb-2 mb-lg-0"
                          },
                          [
                            _vm.$can("Leads Statuses - Create/Edit")
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.onEditStatusEvent(item.value)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-pen" })]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            staticClass: "my-3",
            attrs: { show: _vm.isLoading, type: "small" }
          }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("items-not-found", {
                attrs: { items: _vm.statuses, msg: "No statuses found." }
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }