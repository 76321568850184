var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.fields, function(item, key) {
      return _c(
        "atbl-form-group",
        {
          key: key,
          attrs: {
            label: _vm.getLabel(item, key),
            error: _vm.getError(_vm.errors, key)
          }
        },
        [
          _c("atbl-number", {
            attrs: {
              value: _vm.freeGameModel[key],
              name: key,
              min: item.min,
              max: !item.max ? item.maxAllowed : _vm.getUpTo(key, item.max),
              step: 1,
              disabled: _vm.disabled
            },
            on: { update: _vm.changeFieldsInput }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }