var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-list" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card-body px-3 pb-0 d-flex align-items-center",
        class: {
          "pt-3": !_vm.isLoading
        }
      },
      [
        !_vm.isLoading
          ? _c("div", { staticClass: "w-100" }, [
              _c("div", { staticClass: "row mb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6 col-xl-4 mb-2 mb-md-0" },
                  [
                    _c("h5", { staticClass: "text-secondary text-uppercase" }, [
                      _vm._v("Live Spent")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "callout callout-success mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center flex-wrap"
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Amount")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "h5" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(_vm.player.live_spent)
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6 col-xl-4 mb-2 mb-md-0" },
                  [
                    _c("h5", { staticClass: "text-secondary text-uppercase" }, [
                      _vm._v("Live Deposit")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "callout callout-success mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center flex-wrap"
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Amount")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "h5" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(_vm.player.live_deposit)
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6 col-xl-4 mb-2 mb-md-0" },
                  [
                    _c("h5", { staticClass: "text-secondary text-uppercase" }, [
                      _vm._v("Live Refund")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "callout callout-success mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center flex-wrap"
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Amount")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "h5" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(_vm.player.live_refund)
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6 col-xl-4 mb-2 mb-md-0" },
                  [
                    _c("h5", { staticClass: "text-secondary text-uppercase" }, [
                      _vm._v("Total Win")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "callout callout-success mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center flex-wrap"
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Amount")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "h5" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(_vm.player.total_win)
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6 col-xl-4 mb-2 mb-md-0" },
                  [
                    _c("h5", { staticClass: "text-secondary text-uppercase" }, [
                      _vm._v("Total Withdrawal")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "callout callout-success mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center flex-wrap"
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Amount")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "h5" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(
                                    _vm.player.total_withdrawal
                                  )
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6 col-xl-4 mb-2 mb-md-0" },
                  [
                    _c("h5", { staticClass: "text-secondary text-uppercase" }, [
                      _vm._v("Last payment")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "callout callout-danger" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center mb-1"
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Amount")
                          ]),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.lastTransactionAmount) +
                                "\n                                "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between mb-1" },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Type")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "badge badge-primary" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.displayValue(
                                    _vm.lastTransaction,
                                    "payment_type_beauty"
                                  )
                                ) +
                                "\n                                "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Created At")
                          ]),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-right" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.displayDateTime(
                                    _vm.lastTransaction,
                                    "created_at"
                                  )
                                ) +
                                "\n                                "
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.$can("Player - View Latest Dispositions")
                  ? _c(
                      "div",
                      { staticClass: "col-12 col-lg-6 col-xl-4 mb-2 mb-md-0" },
                      [
                        _c(
                          "h5",
                          { staticClass: "text-secondary text-uppercase" },
                          [_vm._v("Last disposition")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "callout callout-primary" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center mb-1"
                            },
                            [
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Name")
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "badge badge-primary" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.displayValue(
                                          _vm.lastDisposition,
                                          "disposition.name"
                                        )
                                      ) +
                                      "\n                                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-between mb-1"
                            },
                            [
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Follow Up")
                              ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-right" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.displayValue(
                                        _vm.lastDisposition,
                                        "follow_up"
                                      )
                                    ) +
                                    "\n                                "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Created At")
                              ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-right" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.displayDateTime(
                                        _vm.lastDisposition,
                                        "created_at"
                                      )
                                    ) +
                                    "\n                                "
                                )
                              ])
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("Player - View Latest Call")
                  ? _c("div", { staticClass: "col-12 col-lg-6 col-xl-4" }, [
                      _c(
                        "h5",
                        { staticClass: "text-secondary text-uppercase" },
                        [_vm._v("Last call")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "callout callout-warning" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center mb-1"
                          },
                          [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v("Status")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "badge badge-primary" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.displayValue(_vm.lastCall, "status")
                                  ) +
                                  "\n                                "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-between mb-1"
                          },
                          [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v("Duration")
                            ]),
                            _vm._v(" "),
                            _c("small", {
                              domProps: {
                                innerHTML: _vm._s(_vm.lastCallDuration)
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v("Created At")
                            ]),
                            _vm._v(" "),
                            _c("small", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.displayDateTime(
                                      _vm.lastCall,
                                      "created_at"
                                    )
                                  ) +
                                  "\n                                "
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-chart-line" }),
      _vm._v(" Statistics\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }