import MarketingService from '../../../../../../services/MarketingService';
import getValidationErrors from '../../../../../../utils/Error/GetValidationErrors';
import Campaigns from '../../../../../../constants/Campaigns';
import { clone } from '../../../../../../utils/Json/Json';

const sellStatusesList = [
    Campaigns.SaleStatusName,
    Campaigns.ConvertToPlayerStatusName,
];

const statusModel = {
    status_id: null,
};

export default {
    provide() {
        return {
            checkAndSaveStatus: this.checkAndSaveStatus,
            updateStatusModelField: this.updateStatusModelField,
        };
    },
    data() {
        return {
            isAddStatusAllowed: false,
            shouldGoNext: false,
            addStatusLoader: false,
            statusModel: clone(statusModel),
            statusModelErrors: {},
        };
    },
    methods: {
        async checkAndSaveStatus() {
            let status = this.customerDispositionList.find(item => item.value === Number(this.statusModel.status_id));

            if (!status) {
                return window.showMessage(`Lead disposition does not have status with id: ${this.statusModel.status_id}!`)
            }

            if (!!this.isLeadCampaign() && sellStatusesList.includes(status.label)) {
                return this.toggleCreatePlayerModal();
            }

            await this.saveCustomerStatus();
        },
        async saveCustomerStatus() {
            this.addStatusLoader = true;

            try {
                const response = await MarketingService.saveCustomerStatus(this.type, this.callId, {
                    ...this.statusModel,
                    campaign_id: this.id,
                });
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!status) {
                    return;
                }

                this.clearStatusModel();

                if (this.campaignModel.view_type === Campaigns.ViewTypes.callView) {
                    this.addStatusLoader = false;
                    this.shouldGoNext = false;
                    this.isAddStatusAllowed = false;
                    this.statusModel.status_id = null;

                    await this.fetchCustomerToCall(this.type, this.id, this.customerid);

                    return;
                }

                this.shouldGoNext = true;
                this.isAddStatusAllowed = false;

                await this.fetchCustomerStatuses(this.callId);
                await this.fetchLeadLastCalls(this.callId);

                this.addStatusLoader = false;
            } catch (error) {
                this.addStatusLoader = false;

                this.statusModelErrors = getValidationErrors(error);

                window.flashError(error);
            }
        },
        updateStatusModelField(name, value) {
            Object.assign(this.statusModel, {
                [name]: value
            });
        },
        onAddStatusStateChanged(value) {
            this.isAddStatusAllowed = value;
        },
        clearStatusModel() {
            this.statusModel = clone(statusModel);
        },
    },
}