var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid payment-gateways-settings" },
    [
      !_vm.isLoading
        ? [
            _c("div", { staticClass: "card card-list" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "general-list" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._l(_vm.gateways, function(paymentGateway) {
                      return _c("payment-gateway-item", {
                        key: paymentGateway.gateway.key,
                        attrs: { "payment-gateway": paymentGateway }
                      })
                    })
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card card-list" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "general-list" },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._l(_vm.gateways, function(paymentGateway) {
                      return _c(
                        "div",
                        { key: paymentGateway.gateway.key, staticClass: "row" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _vm._v(_vm._s(paymentGateway.gateway.name))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(_vm._s(paymentGateway.available_countries))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(_vm._s(paymentGateway.excluded_countries))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-2" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setEditingGatewayCountries(
                                      paymentGateway.gateway.key
                                    )
                                  }
                                }
                              },
                              [_vm._v("Edit")]
                            )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
      _vm._v(" "),
      _vm.editingGatewayCountries
        ? _c("gateway-countries-modal", {
            attrs: { gateway: _vm.editingGatewayCountries },
            on: {
              cancel: function($event) {
                _vm.editingGatewayCountries = null
              },
              confirm: _vm.saveGatewayCountries
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Payment gateway settings\n                ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_vm._v("Title")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Is CRM active")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Is API active")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Withdrawal Enabled")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Withdrawal API Enabled")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Order")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Payment gateway countries\n                ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_vm._v("Title")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Available countries")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Excluded countries")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }