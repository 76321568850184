export default {
    index() {
        return window.axios.get('/currencies');
    },

    getCurrencySymbol(currency) {
        const defaultCurrency = '&pound';

        const currencies = {
            'EUR': '&euro;',
            'USD': '&dollar;',
            'GBP': '&pound;',
            'AUD': 'A&dollar;',
            'USN': '&dollar;',
            'CAD': 'CA&dollar;',
            'MKD': 'ден.',
        };

        return currencies[currency] || defaultCurrency;
    },
}