var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.showFilter
        ? _c("TransactionsSearch", {
            on: { search: _vm.onSearchEvent, clear: _vm.clearSearchModel }
          })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.showTable
        ? _c("div", { staticClass: "card card-list" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex justify-content-between align-items-center"
              },
              [
                _vm._m(0),
                _vm._v(" "),
                !!_vm.hasMore
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                Search result contains more than 1000 rows, please narrow your search.\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    !!_vm.transactions.length
                      ? _c("atbl-select", {
                          staticClass: "mr-2 mb-0",
                          attrs: {
                            options: _vm.paginationModel.data,
                            value: _vm.paginationModel.default,
                            "first-option-label": null,
                            "option-label": "text"
                          },
                          on: { input: _vm.onPaginationLimitChangeEvent }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-outline-primary m-0",
                        on: {
                          click: function($event) {
                            _vm.showFilter = !_vm.showFilter
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-filter",
                          attrs: { "aria-hidden": "false" }
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                {
                  staticClass: "general-list v-sortable",
                  on: { sort: _vm.onUpdateSortEvent }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row sticky-element d-none d-xl-flex" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:id",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "id"
                            }
                          ],
                          staticClass: "col text-muted"
                        },
                        [_vm._v("Reference")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:created_by_string",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "created_by_string"
                            }
                          ],
                          staticClass: "col text-muted text-center"
                        },
                        [_vm._v("Created By")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:updated_at",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "updated_at"
                            }
                          ],
                          staticClass: "col text-muted text-center"
                        },
                        [_vm._v("Date")]
                      ),
                      _vm._v(" "),
                      _vm.$can("Transactions - View Gateway")
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "sort",
                                  rawName: "v-sort:gateway",
                                  value: _vm.sort,
                                  expression: "sort",
                                  arg: "gateway"
                                }
                              ],
                              staticClass: "col text-muted"
                            },
                            [_vm._v("Gateway")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:payment_type",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "payment_type"
                            }
                          ],
                          staticClass: "col text-muted"
                        },
                        [_vm._v("Type")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:transaction_type",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "transaction_type"
                            }
                          ],
                          staticClass: "col text-muted"
                        },
                        [_vm._v("Status")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:last_4_digits",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "last_4_digits"
                            }
                          ],
                          staticClass: "col-1 text-muted"
                        },
                        [_vm._v("Last 4")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sort",
                              rawName: "v-sort:amount",
                              value: _vm.sort,
                              expression: "sort",
                              arg: "amount"
                            }
                          ],
                          staticClass: "col text-muted"
                        },
                        [_vm._v("Amount")]
                      ),
                      _vm._v(" "),
                      _vm.$can("Player - View Details")
                        ? _c(
                            "div",
                            { staticClass: "col text-muted text-center" },
                            [_vm._v("Action")]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isLoading
                    ? _vm._l(_vm.transactionsData.data, function(transaction) {
                        return _c(
                          "div",
                          { key: transaction.id, staticClass: "row" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0"
                              },
                              [
                                _vm.$can(
                                  "Transactions - View Transaction Details"
                                )
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "d-block",
                                        attrs: {
                                          href: "/transaction/" + transaction.id
                                        }
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-link" }),
                                        _vm._v(
                                          " " +
                                            _vm._s(transaction.id) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                Id: " +
                                          _vm._s(transaction.id) +
                                          "\n                            "
                                      )
                                    ]),
                                _vm._v(" "),
                                !!transaction.order
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "/orders/" + transaction.order.id
                                        }
                                      },
                                      [
                                        _c("small", [
                                          _c("b", [_vm._v("Order")]),
                                          _vm._v(
                                            " #" +
                                              _vm._s(transaction.order.id) +
                                              "\n                                "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "d-block text-muted" },
                                  [
                                    _c("b", [_vm._v("Player:")]),
                                    _vm._v(
                                      " #" +
                                        _vm._s(transaction.player_id) +
                                        " - " +
                                        _vm._s(
                                          transaction.player.name +
                                            " " +
                                            transaction.player.surname
                                        ) +
                                        "\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "small-mb-badge mt-2" },
                                  [_vm._v("Reference id")]
                                ),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _c("b", [_vm._v("Ref:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(transaction.reference_id) +
                                      "\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _c("b", [_vm._v("Ref tid:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        transaction.reference_transaction_id
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                !!transaction.game_winner
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.getGameWinnerTicket(
                                            transaction
                                          ).url
                                        }
                                      },
                                      [
                                        _c("small", [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getGameWinnerTicket(
                                                  transaction
                                                ).label
                                              )
                                            )
                                          ]),
                                          _vm._v(
                                            " #" +
                                              _vm._s(
                                                _vm.getGameWinnerTicket(
                                                  transaction
                                                ).id
                                              ) +
                                              "\n                                "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-xl align-self-center text-center mb-2 mb-lg-0"
                              },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Created by")
                                ]),
                                _vm._v(" "),
                                _c("created-by-badge", {
                                  attrs: { created: transaction }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-xl text-center align-self-center mb-2 mb-lg-0"
                              },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Updated at")
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utcToLocal(transaction.updated_at)
                                    )
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.$can("Transactions - View Gateway")
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "small-mb-badge" },
                                      [_vm._v("Gateway")]
                                    ),
                                    _vm._v(" "),
                                    !!transaction.gateway &&
                                    transaction.gateway !== "atbl"
                                      ? _c("small", [
                                          _c("strong", [
                                            _vm._v(_vm._s(transaction.gateway))
                                          ])
                                        ])
                                      : _c("span", [_vm._v("/")])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0"
                              },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Status")
                                ]),
                                _vm._v(" "),
                                _c("transaction-badge", {
                                  attrs: { transaction: transaction }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "transaction-type font-weight-bold",
                                    class: {
                                      "text-success":
                                        transaction.transaction_type === 1,
                                      "text-danger":
                                        transaction.transaction_type !== 1
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.getReadableTransactionType(
                                            transaction.transaction_type
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                    transaction.transaction_type === 0 &&
                                    transaction.response_message
                                      ? _c("span", [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    html: true,
                                                    content:
                                                      transaction.response_message,
                                                    placement: "top"
                                                  },
                                                  expression:
                                                    "{\n                                            html: true,\n                                            content: transaction.response_message,\n                                            placement: 'top'\n                                       }"
                                                }
                                              ],
                                              attrs: { href: "#" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-info-circle text-danger ml-1"
                                              })
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-xl-1 align-self-center text-center text-xl-left mb-2 mb-lg-0"
                              },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Last 4")
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(transaction.last_4_digits))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0"
                              },
                              [
                                _c("span", { staticClass: "small-mb-badge" }, [
                                  _vm._v("Amount")
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "d-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        transaction.amount
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "d-block text-muted" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.logABSum("before", transaction)
                                        ) +
                                        "\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "d-block text-muted" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.logABSum("after", transaction)
                                        ) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.$can("Player - View Details")
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-xl align-self-center text-center text-xl-left"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "small-mb-badge" },
                                      [_vm._v("Action")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "d-block",
                                        attrs: {
                                          href:
                                            "/players/" +
                                            transaction.player.id +
                                            "/view"
                                        }
                                      },
                                      [_vm._m(1, true)]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isLoading
                    ? _c("not-found", {
                        attrs: {
                          msg: "No transactions found",
                          items: _vm.transactions
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: _vm.isLoading, type: "small" }
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            !_vm.isLoading && _vm.transactionsData.last_page > 1
              ? _c("div", { staticClass: "card-footer overflow-x-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-block d-lg-flex align-items-center justify-content-between text-center"
                    },
                    [
                      _c(
                        "nav",
                        { staticClass: "wrap" },
                        [
                          _c("atbl-pagination", {
                            staticClass:
                              "justify-content-center justify-content-lg-start",
                            attrs: {
                              pagination: _vm.transactionsData,
                              scroll: false
                            },
                            on: { paginate: _vm.onPageChangeEvent }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$scrollToTop()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-caret-up mr-2" }),
                          _vm._v(" Back to top\n                ")
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-exchange-alt" }),
      _vm._v(" Transactions\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" View Player")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }