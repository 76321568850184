<template>
    <div class="payment-gateways" :class="{'payment-gateways--disabled': isLoading || chargeAmount === 0}">
        <div v-if="enableWalletPayment && $can('Payment - Gateway wallet')" class="payment-gateway-button payment-gateway-button--wallet" :class="{'payment-gateway-button--disabled': ! hasEnoughWalletFunds}" @click="walletPaymentConfirmModal = true">
            <loader :show="isLoading" type="small" class="lottery-payment-balance" />
            Wallet - {{ formattedCurrency }}
        </div>

        <template v-for="gateway in gateways">
            <div v-if="$can(`Payment - Gateway ${gateway.key}`)" :key="`gateway_${gateway.key}`" class="payment-gateway-button" :class="[`payment-gateway-button--${gateway.key}`, {'payment-gateway-button--toggled': selectedGatewayDropDown === gateway.key}]" @click.capture="onButtonClick(gateway)">
                {{ gateway.name }}
                <loader :show="isLoading" type="small" />

                <template v-if="gateway.is_saving_credit_cards_enabled">
                    <span></span>

                    <div class="payment-gateway-button__dropdown-content">
                        <a href="#" @click.prevent="selectGateway(gateway.key)">Pay with credit card</a>
                        <a href="#" @click.prevent="showListOfSavedCardsForGateway = gateway.key">Pay with saved card</a>
                    </div>
                </template>
            </div>
        </template>

        <payment-gateway-saved-credit-cards-modal v-if="showListOfSavedCardsForGateway"
                                                  :player-id="playerId"
                                                  :gateway-key="showListOfSavedCardsForGateway"
                                                  v-on:confirm="confirmSelectedSavedCreditCard"
                                                  v-on:cancel="showListOfSavedCardsForGateway = null" />

        <payment-gateway-oppwa v-if="selectedGateway === 'oppwa'" :api-payment-response="apiPaymentResponse" v-on:cancel="selectedGateway = null; apiPaymentResponse = null" />
        <payment-gateway-bambora v-if="selectedGateway === 'bambora'" :player-id="playerId" :api-payment-response="apiPaymentResponse" v-on:cancel="selectedGateway = null; apiPaymentResponse = null" />

        <confirm-wallet-payment-modal v-if="walletPaymentConfirmModal"
                                      :amount="chargeAmount"
                                      @confirm="walletPaymentConfirmModal = false; selectGateway('wallet')"
                                      @cancel="walletPaymentConfirmModal = false"
        />
    </div>
</template>

<script>
import PaymentGatewaySavedCreditCardsModal from './SavedCardModal';
import PlayerService from '../../services/PlayerService';
import PaymentGatewayOppwa from './Oppwa';
import PaymentGatewayBambora from './Bambora';
import ConfirmWalletPaymentModal from './ConfirmWalletPaymentModal';

export default {
    name: 'payment-gateways',

    props: ['playerId', 'enableWalletPayment', 'chargeAmount', 'url', 'walletUrl', 'params'],

    components: { PaymentGatewayBambora, PaymentGatewayOppwa, PaymentGatewaySavedCreditCardsModal, ConfirmWalletPaymentModal },

    data() {
        return {
            selectedGateway: null,
            selectedGatewayDropDown: null,
            showListOfSavedCardsForGateway: null,
            walletBalance: null,
            isWalletLoading: false,
            apiPaymentResponse: null,
            isLoading: false,
            walletPaymentConfirmModal: false,
        };
    },

    computed: {
        gateways() {
            const paymentSettings = this.$store.getters['paymentGateways/paymentGateways'];

            return paymentSettings.map(g => {
                return {
                    ...g.gateway,
                    is_active: g.is_active,
                }
            }).filter(g => g.is_active);
        },

        hasEnoughWalletFunds() {
            const toCharge = this.chargeAmount || 0;

            return this.walletBalance >= toCharge && !!toCharge;
        },

        formattedCurrency() {
            if (!this.walletBalance) {
                return '-';
            }

            return this.$options.filters.formatCurrency(this.walletBalance);
        },
    },

    mounted() {
        this.$store.dispatch('paymentGateways/fetchPlayerPaymentGateways', this.playerId);

        if (this.enableWalletPayment) {
            this.fetchPlayerWallet();
        }

        window.addEventListener('click', (e) => {
            let targetElement = e.target;

            do {
                if (!targetElement.classList) {
                    this.selectedGatewayDropDown = null;
                    return;
                }

                if (targetElement.classList.contains('payment-gateway-button')) {
                    return;
                }

                targetElement = targetElement.parentNode;
            } while (targetElement);

            this.selectedGatewayDropDown = null;
        });
    },

    methods: {
        fetchPlayerWallet() {
            this.isWalletLoading = true;

            PlayerService.fetchPlayerWallet(this.playerId).then((response) => {
                this.walletBalance = response.data.data.amount;
                this.isWalletLoading = false;
            });
        },

        onButtonClick(gateway) {
            if (this.selectedGatewayDropDown === gateway.key) {
                this.selectedGatewayDropDown = null;
                return;
            }

            if (gateway.is_saving_credit_cards_enabled) {
                this.selectedGatewayDropDown = gateway.key;
            } else {
                this.selectGateway(gateway.key);
            }
        },

        selectGateway(gatewayKey) {
            if (gatewayKey === 'wallet' && !this.hasEnoughWalletFunds) {
                return;
            }

            this.isLoading = true;
            this.handlePayment(gatewayKey, null).finally(() => {
                this.isLoading = false;
            });

            this.selectedGatewayDropDown = null;
        },

        confirmSelectedSavedCreditCard(creditCard) {
            this.isLoading = true;
            this.handlePayment(this.showListOfSavedCardsForGateway, creditCard.id).finally(() => {
                this.isLoading = false;
            });

            this.showListOfSavedCardsForGateway = null;
        },

        handlePayment(gatewayKey, creditCardId) {
            const params = {
                ...this.params,
            };

            if (gatewayKey !== 'wallet') {
                params.gateway = gatewayKey;

                if (creditCardId) {
                    params.card_id = creditCardId;
                }
            }

            const url = gatewayKey === 'wallet' ? this.walletUrl : this.url;

            const paymentPromise = window.axios.post(url, params);

            paymentPromise.then(response => {
                const responseData = response.data;
                const paymentStatus = responseData.status;
                this.apiPaymentResponse = responseData;

                const emitData = {
                    action: responseData.action,
                    message: responseData.message,
                };

                if (paymentStatus === 'success' && responseData.paid) {
                    emitData.order = responseData.order;
                    emitData.transaction = responseData.transaction;

                    this.$emit('payment-successful', emitData)

                    return;
                }

                if (paymentStatus === 'failed') {
                    emitData.error = responseData.error;

                    this.$emit('payment-failed', emitData)

                    return;
                }

                if (paymentStatus === 'pending' && responseData.is_checkout) {
                    this.selectedGateway = gatewayKey;
                }

                if (paymentStatus === 'pending' && responseData.is_redirect) {
                    window.location.href = responseData.redirect.url;
                }
            });

            paymentPromise.catch(error => {
                this.$emit('payment-error', error);
            })

            return paymentPromise;
        },
    },
}
</script>