<template>
    <div v-if="!!player.call_status && !!player.call_created_at" class="mb-2">
        <span class="font-weight-bold mr-1">Call</span>
        <span class="badge badge-primary">
            {{ ucFirst(player.call_status) }}
        </span>
        <br />
        <small>{{ player.call_created_at }}</small>
    </div>
</template>

<script>
export default {
    name: 'LatestCall',
    props: {
        player: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>