export default {
    fundsAmount: 0,
    fundsReference: "",
    fundsReason: null,
    fundsMeta: {
        note: null,
        last4: null,
    },
    fundsTransactionId: 0,
    fundsAgent: null,
    fundsCreditCard: null,
    fundsAddBalance: false,
    fundsCredit: false,
    fundsIsFailedTransaction: false,
    fundsCreditConfirmModal: false,
    fundsBonusConfirmModal: false,
    fundsCreditCardConfirmModal: false,
    fundsCreditCardErrors: {},
    fundsLoader: false,
    fundsPaymentLink: "",
};