export default {
    cartData: {
        id: null,
        reference_id: null,
        items: []
    },
    cartLoader: false,
    cartTotal: 0,
    checkout: false,
    showProcessingOverlay: false,
    showGame: false,
    playerCanPlayGame: false,
    walletConfirmModal: false,
    addBalance: false,
    paymentLink: null,
    isPlacingOrder: false,
};