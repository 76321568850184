export default {
    index(params) {
        return window.axios.get('/bundle-tickets/index', { params });
    },

    show(id) {
        return window.axios.get(`/bundle-tickets/${id}/show`);
    },

    playerBundleTickets(playerId, params) {
        return window.axios.get(`/players/${playerId}/bundle-tickets/index`, { params });
    },
}