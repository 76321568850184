var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loader", { attrs: { type: "smaller", show: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-link",
              on: { click: _vm.onDownloadEvent }
            },
            [
              _c("i", {
                staticClass: "fa fa-download",
                attrs: { "aria-hidden": "false" }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-link",
              on: { click: _vm.onPlayEvent }
            },
            [
              _c("i", {
                staticClass: "fa fa-play",
                attrs: { "aria-hidden": "false" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }