export default {
    name: null,
    surname: null,
    orderId: null,
    ticket: null,
    raffle: null,
    transaction: null,
    agents: [],
    webCreated: true,
    status: null,
    dateFrom: null,
    dateTo: null,
}