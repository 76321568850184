var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "general-list" },
    [
      _vm._m(0),
      _vm._v(" "),
      !_vm.isLoading && _vm.days.length
        ? _vm._l(_vm.days, function(day, index) {
            return _c("div", { key: index, staticClass: "row p-0" }, [
              _c("div", { staticClass: "col-6 col-lg p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Date.")
                ]),
                _vm._v(" "),
                _vm.$can("Reports Agents - View")
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.openAgent($event, day.created_at)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(day.created_at) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    )
                  : _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(day.day) +
                          "\n                    "
                      )
                    ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Orders")
                ]),
                _vm._v(" "),
                _c("a", { attrs: { target: "_blank" } }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t" + _vm._s(day.ordersCount) + "\n\t\t\t\t\t"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Num. Tickets")
                ]),
                _vm._v("\n\t\t\t\t\t" + _vm._s(day.ticketsCount) + "\n\t\t\t\t")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Sales")
                ]),
                _vm._v(" "),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.currencyNumber(day, "sales"))
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg bg-primary-20 p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Deposits")
                ]),
                _vm._v(" "),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.currencyNumber(day, "deposit"))
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Bonus")
                ]),
                _vm._v(" "),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.currencyNumber(day, "bonus"))
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 col-lg bg-primary-20 p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Commission")
                ]),
                _vm._v(" "),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.currencyNumber(day, "commission"))
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg p-2" }, [
                _c("span", { staticClass: "small-mb-badge" }, [
                  _vm._v("Transactions")
                ]),
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(day.transactionsCount) + "\n\t\t\t\t"
                )
              ])
            ])
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("not-found", {
            attrs: { items: _vm.days, msg: "No transactions found." }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row sticky-element p-0" }, [
      _c("div", { staticClass: "col-6 col-lg p-2" }, [_vm._v("Date")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg p-2" }, [_vm._v("Orders")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg p-2" }, [_vm._v("Num. Tickets")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg p-2" }, [_vm._v("Sales")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg bg-primary-20 p-2" }, [
        _vm._v("Deposits")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg p-2" }, [_vm._v("Bonus")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg bg-primary-20 p-2" }, [
        _vm._v("Commission")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg p-2" }, [_vm._v("Transactions")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }