const CopyToClipBoard = {
    install(Vue, options) {
        Vue.prototype.copyToClipboard = function (text) {
            navigator.clipboard.writeText(text).then(function() {
                window.flash('Successfully copy to clipboard', 'alert-success');
            }, function() {
                window.flash('Error while copy to clipboard', 'alert-danger');
            });
        }
    }
};

module.exports = CopyToClipBoard;