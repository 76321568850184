var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-list" },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            {
              staticClass: "col text-muted",
              class: {
                "col-8": _vm.numberGroupsCount === 2,
                "col-4": _vm.numberGroupsCount > 2
              }
            },
            [_vm._v("Main numbers")]
          ),
          _vm._v(" "),
          _vm._l(_vm.numberGroupsTitles, function(groupTitle) {
            return _c(
              "div",
              {
                key: "group_title_" + groupTitle,
                staticClass: "col col-4 text-muted"
              },
              [_vm._v(_vm._s(groupTitle))]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.lines, function(line, index) {
        return _vm.totalPages
          ? _c(
              "div",
              { key: "syndicate_line_" + index, staticClass: "row" },
              [
                _vm._l(line.numbers, function(numberGroup, key, index) {
                  return [
                    Array.isArray(numberGroup)
                      ? _c(
                          "div",
                          {
                            staticClass: "col",
                            class: {
                              "col-8":
                                index === 0 && _vm.numberGroupsCount === 2,
                              "col-4": index !== 0
                            }
                          },
                          _vm._l(numberGroup, function(number) {
                            return _c(
                              "span",
                              {
                                key:
                                  "number_" +
                                  _vm.syndicateItem.raffle +
                                  "_" +
                                  number
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(number) +
                                    "\n                "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !Array.isArray(numberGroup)
                      ? _c(
                          "div",
                          {
                            staticClass: "col",
                            class: {
                              "col-8":
                                index === 0 && _vm.numberGroupsCount === 2,
                              "col-4": index !== 0
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(numberGroup) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          : _vm._e()
      }),
      _vm._v(" "),
      !_vm.totalPages || _vm.totalPages >= _vm.linesPage
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col d-flex justify-content-center align-items-center my-2"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    attrs: { type: "submit", disabled: _vm.isLoadingMoreLines },
                    on: { click: _vm.fetchLines }
                  },
                  [
                    _c("i", { staticClass: "fa fa-search" }),
                    _vm._v(
                      " " +
                        _vm._s(!_vm.totalPages ? "Show lines" : "Show more") +
                        "\n            "
                    )
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }