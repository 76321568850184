var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atbl-file-upload", {
    attrs: {
      value: _vm.value,
      errors: _vm.errors,
      accept: ["csv"],
      label: "Upload Csv",
      name: "csv"
    },
    on: { update: _vm.onUpdateFileEvent }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }