<script>
    export default {
        playerNotesRoute: function(id){
            return `/player/${id}/note`;
        },
        getPlayerNotes: function(id){
            return window.axios.get(this.playerNotesRoute(id));
        },
        removePlayerNote: function(id){
            return window.axios.delete(
                this.playerNotesRoute(id)
            );
        }
    }
</script>