<template>
  <form @submit.prevent="fetchWithdrawals">
    <div class="card card-search">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span><i class="fa fa-search"></i> Search</span>
      </div>
      
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-4">
            <atbl-input :value="model.name"
                        label="Name"
                        name="name"
                        @update="updateModel"
            />
          </div>
          <div class="col-12 col-md-4">
            <atbl-input :value="model.surname"
                        label="Surname"
                        name="surname"
                        @update="updateModel"
            />
          </div>
          <div class="col-12 col-md-4">
            <atbl-select :options="withdrawalStatuses"
                         :value="model.status"
                         :first-option-label="null"
                         label="Status"
                         class="font-weight-bold"
                         name="status"
                         @update="updateModel"
            />
          </div>
          <div v-if="$can('Users - Get List')"
               class="col-12 col-md-8"
          >
            <multi-agents-picker :value="model.created_by"
                                 name="created_by"
                                 class="font-weight-bold"
                                 @update="updateModel"
            />
          </div>
          <div class="col-12 col-md-4">
            <atbl-form-group id="lbCreateAt"
                             label="Created At"
                             label-for="created_at"
                             class="font-weight-bold"
            >
              <date-picker :value="model.created_at"
                           format="YYYY-MM-DD"
                           name="created_at"
                           @update="updateModel"
              />
            </atbl-form-group>
          </div>
        </div>
      </div>
      
      <div class="card-footer">
        <button type="submit"
                class="btn btn-sm btn-primary mr-2"
        >
          <i class="fa fa-search"></i> Search
        </button>
        <button type="reset"
                class="btn btn-sm btn-danger"
                @click="clearModel"
        >
          <i class="fa fa-ban"></i> Clear
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from '../../../components/Forms/Select/DatePicker';
import WithdrawalsService from '../../../services/WithdrawalsService.vue';
import AtblSelect from '../../../components/Forms/Inputs/ATBLSelect.vue';
import WithdrawalSearchModel from './WithdrawalSearchModel';
import AtblInput from '../../../components/Forms/Inputs/ATBLInput.vue';

export default {
    name: 'withdrawal-search-component',
    inject: [
        'fetchWithdrawals',
        'updateModel',
        'clearModel',
    ],
    components: {
        AtblSelect,
        AtblInput,
        DatePicker,
    },
    props: {
        model: {
            type: Object,
            default: () => (WithdrawalSearchModel)
        },
    },
    data() {
        return {
            withdrawalStatusesLoader: false,
            withdrawalStatuses: [],
        };
    },
    async created() {
        await this.fetchWithdrawalsStatuses();
    },
    methods: {
        async fetchWithdrawalsStatuses() {
            this.withdrawalStatusesLoader = true;

            try {
                const response = await WithdrawalsService.getWithdrawalsStatuses();

                this.withdrawalStatuses = response.data;

                this.withdrawalStatusesLoader = false;
            } catch (error) {
                this.withdrawalStatusesLoader = false;

                window.flashError(error);
            }
        },
    }
};
</script>
