<template>
    <div class="card card-search">
        <div class="card-header">
            <i class="fa fa-search"></i> Search
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <atbl-form-group label="Name">
                        <atbl-input name="name"
                                    :value="searchModel.name"
                                    @update="updateSearchModel"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-4">
                    <atbl-form-group label="Enabled for players">
                        <atbl-switch name="players_enabled"
                                     :value="searchModel.players_enabled"
                                     @update="updateSearchModel"
                        />
                    </atbl-form-group>
                </div>
                <div class="col-12 col-lg-4">
                    <atbl-form-group label="Enabled for leads">
                        <atbl-switch name="leads_enabled"
                                     :value="searchModel.leads_enabled"
                                     @update="updateSearchModel"
                        />
                    </atbl-form-group>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button type="submit"
                      class="btn btn-sm btn-primary"
                      @click="fetchEmailTemplates"
            >
                <i class="fa fa-search"></i> Search
            </button>
            <button type="reset"
                      class="btn btn-sm btn-danger"
                      @click="clearSearchModel"
            >
                <i class="fa fa-ban"></i> Clear
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchEmailTemplatesComponent',
    inject: [
        'fetchEmailTemplates',
        'updateSearchModel',
        'clearSearchModel',
    ],
    props: {
        searchModel: {
            type: Object,
            default: () => ({})
        },
        emailTypes: {
            type: Array,
            default: () => ([])
        },
        externalTypes: {
            type: Object,
            default: () => ({})
        },
    }
}
</script>