<template>
    <div class="user-type mb-3">
        <div class="user-type-header d-flex align-items-center justify-content-between bg-primary text-color p-3 cursor-pointer"
             @click.prevent="isShown = !isShown"
        >
            <span class="font-weight-bold">{{ type }}</span>
            <a href="#"
               class="text-white"
               @click.prevent
            >
                <i class="fa"
                   :class="{
                        'fa-caret-down': !isShown,
                        'fa-caret-up': isShown
                   }"
                ></i>
            </a>
        </div>
        <div v-if="!!isShown"
             class="user-type-body bg-lighter p-3"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserType',
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isShown: false,
        };
    },
}
</script>