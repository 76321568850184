var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-footer" }, [
    _c("div", [
      _c(
        "a",
        { attrs: { href: "https://allthebestlottos.com/", target: "_blank" } },
        [_vm._v("ATBL")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "ml-1" }, [
        _vm._v("© " + _vm._s(new Date().getFullYear()))
      ])
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto" }, [
      _c("span", { staticClass: "mr-1" }, [_vm._v("Powered by")]),
      _vm._v(" "),
      _c(
        "a",
        { attrs: { href: "https://allthebestlottos.com/", target: "_blank" } },
        [_vm._v("ATBL")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }