<template>
    <div class="player-profile-info">
        <div class="player-profile-info-header">
            <div>
                <a href="#"
                   @click.prevent="copyToClipboard(player.id)"
                >
                    #{{ player.id }}
                </a>
                <a href="#"
                   @click.prevent="copyToClipboard(`${player.name} ${player.surname}`)"
                >
                    {{ `${player.name} ${player.surname}` }}
                </a>
                <created-through :created-through="player.created_through"/>
                <div class="player-profile-info-header-info">
          <span>
            <a href="#"
               @click.prevent="copyToClipboard(player.email)"
            >
              {{ player.email }}
            </a>
          </span>
                    <player-kyc-status-badge :kyc-status="player.kyc_status">
                        KYC: {{ player.kyc_status }}
                    </player-kyc-status-badge>
                    <span v-if="!!player.my_affiliate_username">
            <span v-tooltip="`My affiliate user`"
                  class="badge badge-info"
            >
                MyAffiliate: {{ player.my_affiliate_username }}
            </span>
          </span>
                </div>
            </div>
            <div v-if="hasTimezones"
                 v-tooltip="{'content': 'Player Local Time'}"
            >
                <div class="player-profile-info-local-time">
                    <div>
                        <i class="fa fa-clock"></i>
                        {{ getFirstClock.time }}
                        <a v-tooltip="{
                    html: true,
                    content: getOtherClocks,
                    placement: 'top-end'
               }"
                           href="#"
                        >
                            <i class="fa fa-info-circle"></i>
                        </a>
                    </div>
                    <small>{{ getFirstClock.timezone[0] }} {{ getFirstClock.timezone.length > 1 ? '...' : '' }}</small>
                    <br/>
                </div>
            </div>
        </div>
        <div class="player-profile-info-body">
            <div class="player-profile-info-info">
                <div>
                    <strong>Activated at:</strong> {{ player.activated_at ? utcToLocal(player.activated_at) : '/' }}
                </div>
                <span v-tooltip="{content: lastSeen ? lastSeen.format('DD MMM, YYYY HH:mm') : '/'}">
                  <strong>Last seen:</strong> {{ lastSeenFromNow }}
                </span>
                <div class="player-profile-info-newsletter">
                    <strong>Newsletter:</strong>
                    <atbl-switch :value="player.newsletter"
                                 :small="true"
                                 :is-loading="isPlayerNewsletterUpdating"
                                 name="newsletter"
                                 @update="onNewsletterUpdate"
                    />
                </div>
            </div>
            <div class="player-profile-info-operations">
                <wallet-widget :player="player"/>
                <cart-widget/>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import TypesConst from '../../../../store/TypesConst';
    import NamespaceConst from '../../../../store/NamespaceConst';
    import {createNamespacedHelpers} from 'vuex';
    
    const {
        mapGetters,
        mapMutations
    } = createNamespacedHelpers(NamespaceConst.players);
    
    import WalletWidget from '../Widgets/WalletWidget';
    import CartWidget from '../../Player/Play/Componenets/CartWidget/CartWidget';
    import PlayerKycStatusBadge from './KYCStatus/player-kyc-status-badge.vue';
    import CreatedThrough from '../../Sections/Components/CreatedThrough/CreatedThrough';
    import {clone} from '../../../../utils/Json/Json';
    import utcToLocal from '../../../../filters/UtcToLocal';
    import AtblSwitch from '../../../../components/Forms/Inputs/ATBLSwitch.vue';
    import {omit} from 'lodash';
    
    export default {
        name: 'PlayerInfo',
        components: {
            PlayerKycStatusBadge,
            CreatedThrough,
            WalletWidget,
            CartWidget,
            AtblSwitch,
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isPlayerNewsletterUpdating: false,
            };
        },
        computed: {
            ...mapGetters({
                player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL
            }),
            lastSeen() {
                if (!this.player.last_seen_at) {
                    return null;
                }
                
                return moment.utc(this.player.last_seen_at).local();
            },
            lastSeenFromNow() {
                if (!this.lastSeen) {
                    return '/';
                }
                
                return this.lastSeen.fromNow();
            },
            currentTimezones() {
                return this.getTimezones(this.player);
            },
            hasTimezones() {
                return !!this.currentTimezones && !!Object.keys(this.currentTimezones).length;
            },
            getFirstClock() {
                const time = Object.keys(this.currentTimezones)[0];
                const timezone = this.currentTimezones[time];
                
                return {
                    time,
                    timezone
                };
            },
            getOtherClocks() {
                const clocks = clone(this.currentTimezones);
                
                let str = '';
                
                for (const clock in clocks) {
                    const value = clocks[clock].join('<br/>');
                    
                    str += '-----<br/>' + clock + '<br/><span class="text-secondary font-10">' + value + '</span><br/>';
                }
                
                return str;
            },
        },
        methods: {
            ...mapMutations({
                setPlayerModel: TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL_FIELD
            }),
            utcToLocal,
            getTimezones(player) {
                const {timezones} = player;
                
                if (!timezones || !timezones.length) {
                    return null;
                }
                
                const timezonesTemp = timezones.map(timezone => {
                    return this.getCurrentTimeByTimezone(timezone);
                });
                
                let timezonesGroup = {};
                
                for (const {time, timezone} of timezonesTemp) {
                    if (!!timezonesGroup.hasOwnProperty(time)) {
                        timezonesGroup[time].push(timezone);
                        
                        continue;
                    }
                    
                    timezonesGroup[time] = [
                        timezone
                    ];
                }
                
                return timezonesGroup;
            },
            getCurrentTimeByTimezone(timezone) {
                if (!timezone) {
                    return null;
                }
                
                const formatter = new Intl.DateTimeFormat([], {
                    timeZone: timezone,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                });
                
                const date = formatter.format(new Date()).toLocaleString().split(',');
                
                return {
                    time: date[1],
                    timezone
                };
            },
            async onNewsletterUpdate(_, value) {
                this.isPlayerNewsletterUpdating = true;
                
                const data = {
                    ...omit(this.player, ["tags"]),
                    newsletter: value
                };
                
                try {
                    const response = await window.axios.patch(
                      `/player/${this.player.id}`,
                      data
                    );
                    const { message } = response.data;
                    
                    this.setPlayerModel(data);
                    
                    window.showMessage(message);
                } catch (error) {
                    window.flashError(error);
                } finally {
                    this.isPlayerNewsletterUpdating = false;
                }
            },
        },
    };
</script>
