export default {
    data () {
        return {
            isUseFreeLoading: false
        };
    },
    methods: {
        async useFreeTicket (item) {
            const obj = {
                free_game_id: this.hasSelectedFreeGame ? this.selectedFreeGame.id : null,
                item,
                playerId: this.getPlayerId
            };

            this.isUseFreeLoading = true;

            try {
                const response = await window.axios.post(`/player/${this.getPlayerId}/free-games/play`, obj);
                const { message, status } = response.data;

                if (status) {
                    this.setShowGame(false);
                    this.selectFreeGame({});
                    await this.getFreeGames();
                }

                window.showMessage(message, status);

                this.isUseFreeLoading = false;
            } catch (error) {
                window.flashError(error);

                this.isUseFreeLoading = false;
            }
        }
    }
}