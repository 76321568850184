<template>
    <sidebar-sub :value="show"
                 :body-class="`${show ? 'pt-0': ''}`"
                 title="Phone numbers list"
                 class="large"
                 @input="togglePullPhoneNumberWindow"
    >
        <div v-if="!isLoading">
            <div class="general-list">
                <div class="row">
                    <div class="col">
                        Include
                        <a v-tooltip="{
                           content: 'If you dont check this check box means that you dont want to include this number in the database.'
                       }"
                           href="#"
                           @click.prevent
                        >
                            <i class="fa fa-info-circle"></i>
                        </a>
                    </div>
                    <div class="col">
                        Phone number
                    </div>
                    <div class="col">
                        Status
                    </div>
                    <div class="col">
                        Allow For
                    </div>
                    <div class="col text-center">
                        Active
                    </div>
                </div>

                <template v-if="!!phoneNumbers.length">
                    <div v-for="(phoneNumber, index) in phoneNumbers"
                         :key="index"
                         class="row"
                    >
                        <div class="col">
                            <atbl-checkbox :value="phoneNumber.include"
                                           name="selected"
                                           @update="onUpdatePhoneNumberSelectedStatus(phoneNumber.phone_number, ...arguments)"
                            />
                        </div>
                        <div class="col">
                            {{ phoneNumber.phone_number }}
                        </div>
                        <div class="col">
                            <span :class="`badge badge-${resolveStatusColor(phoneNumber.status)}`">
                                {{ phoneNumber.status }}
                            </span>
                        </div>
                        <div class="col">
                            <div v-for="type in ['players', 'leads']"
                                 class="d-flex align-items-center"
                            >
                                <atbl-checkbox :value="phoneNumber.allowed_for.includes(type)"
                                               :label="ucFirst(type)"
                                               name="is_active"
                                               class="mb-2"
                                               @update="onUpdateAllowedTypes(phoneNumber, ...arguments, type)"
                                />
                            </div>
                        </div>
                        <div class="col text-center">
                            <atbl-checkbox :value="phoneNumber.is_active"
                                           class="d-flex align-items-center justify-content-center"
                                           name="is_active"
                                           @update="onUpdatePhoneNumberActiveStatus(phoneNumber.phone_number, ...arguments)"
                            />
                        </div>
                    </div>
                </template>

                <not-found :items="phoneNumbers"
                           msg="No phone numbers found."
                />
            </div>
        </div>
        <loader :show="isLoading"
                type="small"
        />

        <template v-if="!isLoading" #footer>
            <div class="d-flex align-items-center justify-content-between w-100">
                <div>
                    <button v-if="$can('Phone Numbers - Pull')"
                              class="btn btn-sm btn-success mr-2"
                              @click.prevent="onPullPhoneNumbers"
                    >
                        <i class="fa fa-download"></i> Pull
                    </button>
                </div>
                <div>
                    <button class="btn btn-sm btn-outline-danger mr-2"
                              :disabled="isLoading"
                              @click="togglePullPhoneNumberWindow"
                    >
                        Cancel
                    </button>

                    <button class="btn btn-sm btn-primary"
                              :disabled="isLoading || !hasSelectedPhoneNumbers"
                              @click="onSavePhoneNumbers"
                    >
                <span v-if="!isLoading">
                    Save
                </span>
                        <loader :show="isLoading"
                                type="smaller"
                        />
                    </button>
                </div>
            </div>
        </template>
    </sidebar-sub>
</template>

<script>
import AtblCheckbox from '../../../components/Forms/Inputs/AtblCheckbox';

export default {
    name: 'PullPhoneNumbersComponent',
    inject: [
        'resolveStatusColor',
        'onPullPhoneNumbers',
        'onSavePhoneNumbers',
        'onUpdateAllowedTypes',
        'onUpdatePhoneNumberActiveStatus',
        'onUpdatePhoneNumberSelectedStatus',
        'togglePullPhoneNumberWindow',
    ],
    components: {
        AtblCheckbox,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        phoneNumbers: {
            type: Array,
            default: () => ([])
        },
    },
    computed: {
        hasSelectedPhoneNumbers() {
            return !!this.phoneNumbers.filter(item => !!item.include).length;
        },
    },
}
</script>