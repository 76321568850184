var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "campaign-call" },
    [
      _c(
        "div",
        { staticClass: "campaign-leads-call" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("h4", { staticClass: "text-center mb-3" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.customer.name) +
                " " +
                _vm._s(_vm.customer.surname) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center mb-3"
            },
            [
              _c(
                "span",
                {
                  staticClass: "badge text-uppercase",
                  class: {
                    "badge-primary": _vm.campaignType === "lead",
                    "badge-danger": _vm.campaignType === "player"
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.campaignType) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              !!_vm.callLogs.length
                ? _c("span", { staticClass: "text-secondary mx-3" }, [
                    _vm._v("|")
                  ])
                : _vm._e(),
              _vm._v(" "),
              !!_vm.callLogs.length
                ? _c("div", { staticClass: "text-center" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.toggleCallLogsModal(true)
                          }
                        }
                      },
                      [_vm._v("\n                    Logs\n                ")]
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onHandleCallEvent.apply(null, arguments)
                }
              }
            },
            [
              _c("twilio-phone-numbers", {
                staticClass: "mb-3",
                attrs: { modelType: "leads", placeholder: "Call Number" },
                model: {
                  value: _vm.selectedNumber,
                  callback: function($$v) {
                    _vm.selectedNumber = $$v
                  },
                  expression: "selectedNumber"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "span",
                    { staticClass: "atbl-placeholder font-weight-bold" },
                    [_vm._v("Customer Number")]
                  ),
                  _vm._v(" "),
                  !!_vm.customerNumberToCall
                    ? _c("input", {
                        staticClass: "form-control",
                        attrs: { disabled: true, type: "text", disabled: "" },
                        domProps: { value: _vm.customerNumberToCall }
                      })
                    : [
                        _vm.customerPhone || _vm.customerMobile
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.changeNumberToCall,
                                    expression: "changeNumberToCall"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "input-3", required: "" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.changeNumberToCall = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                !!_vm.customerPhone
                                  ? _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: _vm.customerPhone,
                                          selected:
                                            _vm.numberToCall ===
                                              _vm.customerPhone &&
                                            !this.customerNumberToCall
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.customerPhone) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.customerMobile
                                  ? _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: _vm.customerMobile,
                                          selected:
                                            _vm.numberToCall ===
                                              _vm.customerMobile &&
                                            !this.customerNumberToCall
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.customerMobile) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Please add phone to this customer.")
                            ])
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _vm.isKeypadOpen
                ? _c("dial-keypad", {
                    attrs: {
                      phone: _vm.originalPhoneNumber,
                      value: _vm.callingNumber,
                      callStarted: _vm.isCallStarted,
                      "wrapper-size": "smaller"
                    },
                    on: {
                      digit: _vm.onPressedDigitEvent,
                      update: _vm.onDialPadNumberUpdate
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.callLoader && _vm.originalPhoneNumber
                ? _c("div", { staticClass: "dial-buttons" }, [
                    _vm.isCallStarted
                      ? _c(
                          "button",
                          {
                            staticClass: "mute-button",
                            class: {
                              "btn-primary": !_vm.isMuted,
                              "btn-warning": _vm.isMuted
                            },
                            attrs: { type: "button" },
                            on: { click: _vm.muteHandler }
                          },
                          [
                            _c("i", {
                              staticClass: "fa",
                              class: {
                                "fa-microphone": !_vm.isMuted,
                                "fa-microphone-slash": _vm.isMuted
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isCallStarted
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary dial-button",
                            class: { active: _vm.isKeypadOpen },
                            attrs: {
                              name: "dialBtn",
                              type: "submit",
                              disabled: !_vm.selectedNumber
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-phone" }),
                            _vm._v(" Dial\n                ")
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger",
                            attrs: { name: "hangBtn", type: "submit" }
                          },
                          [
                            _c("i", { staticClass: "fa fa-phone" }),
                            _vm._v(" Hang\n                ")
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "keypad-toggle-button",
                        class: { active: _vm.isKeypadOpen },
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.isKeypadOpen = !_vm.isKeypadOpen
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/img/icons/keypad.svg",
                            alt: "keypad-icon"
                          }
                        })
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { type: "small", show: _vm.callLoader } })
            ],
            1
          ),
          _vm._v(" "),
          _c("horizontal-line", { attrs: { size: "small" } }),
          _vm._v(" "),
          _vm.$can("Leads - Send Email")
            ? _c("send-email-component", {
                attrs: {
                  "players-enabled": !_vm.isLeadCampaign(),
                  "leads-enabled": !!_vm.isLeadCampaign(),
                  "is-lead": _vm.isLeadCampaign(),
                  client: _vm.customer
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "atbl-modal",
        {
          attrs: {
            title: "Call Logs",
            "close-on-backdrop": true,
            "close-on-esc": true,
            show: _vm.callLogsOpen
          },
          on: { close: _vm.toggleCallLogsModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger",
                      on: {
                        click: function($event) {
                          return _vm.toggleCallLogsModal(false)
                        }
                      }
                    },
                    [_vm._v("\n                Cancel\n            ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "card card-list m-0" }, [
            _c(
              "div",
              {
                staticClass: "card-body",
                style: {
                  "max-height": _vm.logsBodyHeight + "px",
                  "overflow-y": "auto"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "general-list" },
                  [
                    _c("div", { staticClass: "row show-first-row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Message")
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.callLogs, function(log, index) {
                      return _c("div", { key: index, staticClass: "row" }, [
                        _c("div", { staticClass: "col text-muted" }, [
                          _vm._v(_vm._s(log))
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("items-not-found", {
                      attrs: {
                        msg: "You have not made a call yet",
                        items: _vm.callLogs
                      }
                    })
                  ],
                  2
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "profile-holder" }, [
      _c("span", { staticClass: "profile-holder-icon" }, [
        _c("i", { staticClass: "fa fa-user icons font-4xl" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }