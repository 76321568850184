var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atbl-number", {
    attrs: {
      label: "Amount",
      name: "amount",
      value: _vm.getSelected,
      errors: _vm.errors,
      min: 0.0,
      step: 0.01
    },
    on: { update: _vm.setAmount }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }