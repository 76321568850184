<template>
    <atbl-modal :show="show"
                title="Create/Update Status"
                @close="toggleCreateUpdateForm"
    >
        <template v-if="!isLoading">
            <atbl-input :value="model.name"
                        :errors="errors"
                        label="Enter name"
                        name="name"
                        @update="updateModelField"
            />

            <h6 class="border-bottom pb-1 mt-4 text-uppercase">Settings</h6>
            <div class="d-flex flex-wrap align-items-center">
                <atbl-number :value="model.campaign_lock_time"
                             :min="1"
                             :max="maxDaysInMinutes"
                             :errors="errors"
                             :show-errors="false"
                             class="m-0"
                             label="Campaign lock time (minutes)"
                             name="campaign_lock_time"
                             @update="updateModelField"
                />
                <span v-if="!isLockTimeValid"
                      class="invalid-feedback d-block"
                >
                    Number cannot be less than 0 or bigger than {{maxDaysInMinutes}} minutes and should be only full number without decimals.
                </span>
            </div>
        </template>
        <loader :show="isLoading"
                type="small"
        />

        <template #footer>
            <button class="btn btn-sm btn-danger font-weight-bold mr-1"
                    @click="toggleCreateUpdateForm"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                    :disabled="!model.name || !isLockTimeValid || !!isLoading"
                    @click="onSaveLeadStatusEvent"
            >
                <span v-if="!isLoading">Save</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../components/AtblModal/AtblModal';
import Loader from '../../../../components/Widgets/Loader';
import LeadStatusModel from './LeadStatusModel';
import AtblNumber from '../../../../components/Forms/Inputs/ATBLNumber';
import AtblInput from '../../../../components/Forms/Inputs/ATBLInput';

export default {
    name: 'create-or-update-lead-status-form-component',
    inject: [
        'onSaveLeadStatusEvent',
        'updateModelField',
        'toggleCreateUpdateForm',
        'maxDaysInMinutes',
    ],
    components: {
        AtblModal,
        Loader,
        AtblNumber,
        AtblInput,
    },
    props: {
        show: {
            type: Boolean,
            default: null,
        },
        model: {
            type: Object,
            default: () => (LeadStatusModel),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        isLockTimeValid() {
            if(this.model.campaign_lock_time === null) {
                return true;
            }

            const lockTime = Number(this.model.campaign_lock_time);

            if (!Number.isSafeInteger(lockTime)) {
                return false;
            }

            return lockTime > 0 && lockTime <= this.maxDaysInMinutes;
        },
    },
}
</script>