<template>
    <atbl-form-group :label="label"
                     :label-for="getLabelFor"
                     :error="getError(errors, name)"
                     :label-class="labelClass"
    >
        <textarea :id="getLabelFor"
                  class="form-control"
                  :class="{
                      'is-invalid': hasError(errors, name)
                  }"
                  :name="name"
                  :value="value"
                  :placeholder="getPlaceholder"
                  @keyup="onUpdate"
                  :disabled="disabled"
                  :rows="rows"
        ></textarea>
    </atbl-form-group>
</template>

<script>
    import ATBLFormControls from "./ATBLFormControls";

    export default {
        name: "ATBLTextarea",
        mixins: [
            ATBLFormControls
        ],
        props: {
            rows: {
                type: Number,
                default: 2
            }
        }
    }
</script>