export default {
    mutations: {
        SET_TRANSACTIONS_TRANSACTION_ID: `setTransactionsTransactionId`,
        SET_TRANSACTIONS_TRANSACTION_DATA: `setTransactionsTransactionData`,
        SET_TRANSACTIONS_TRANSACTION_DATA_LOADER: `setTransactionsTransactionDataLoader`,
        SET_TRANSACTIONS_TRANSACTION_CONFIGURATION: `setTransactionsTransactionConfiguration`,
    },
    getters: {
        GET_TRANSACTIONS_TRANSACTION_ID: `getTransactionsTransactionId`,
        GET_TRANSACTIONS_TRANSACTION_DATA: `getTransactionsTransactionData`,
        GET_TRANSACTIONS_TRANSACTION_DATA_LOADER: `getTransactionsTransactionDataLoader`,
        GET_TRANSACTIONS_TRANSACTION_GAME: `getTransactionsTransactionGame`,
        GET_TRANSACTIONS_TRANSACTION_CONFIGURATION_NUMBERS: `getTransactionsTransactionConfigurationNumbers`,
        GET_TRANSACTIONS_TRANSACTION_PLAYER: `getTransactionsTransactionPlayer`,
        GET_TRANSACTIONS_TRANSACTION_CURRENCY: `getTransactionsTransactionCurrency`,
        GET_TRANSACTIONS_TRANSACTION_LINES: `getTransactionsTransactionLines`,
        GET_TRANSACTIONS_TRANSACTION_DRAW: `getTransactionsTransactionDraw`,
        GET_TRANSACTIONS_TRANSACTION_CONFIGURATION: `getTransactionsTransactionConfiguration`,
    },
    actions: {
        GET_TRANSACTION_BY_ID: `getTransactionById`,
    }
};