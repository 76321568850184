var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card m-0" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row mt-2" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-3" },
          [
            _c("atbl-number", {
              attrs: {
                label: "Amount",
                name: "amount",
                errors: _vm.errors,
                min: 0.0,
                step: 0.01,
                max: 100000,
                value: _vm.amount
              },
              on: { update: _vm.updateAmount }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("list-payments", {
              attrs: {
                "player-id": _vm.getPlayerId,
                "charge-amount": parseFloat(_vm.getFundsAmount),
                "request-url": "/players/" + _vm.getPlayerId + "/add-funds",
                "payment-types": _vm.allowedPaymentTypes,
                params: { amount: _vm.getFundsAmount }
              },
              on: {
                "payment-successful": _vm.paySuccess,
                "payment-failed": _vm.payFailed,
                "payment-error": _vm.payError
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }