import { isObject } from '../Object/Object';
import errors from '../../mixins/Errors';

/**
 * Get validation errors
 *
 * @param err
 * @return {{}|*}
 */
export default function (err) {
    if (!isObject(err)) {
        return {};
    }

    const { response } = err;

    if (response.status !== 422) {
        return {};
    }

    const { data } = response;

    if (!data.hasOwnProperty('errors')) {
        return {};
    }

    return data.errors;
}