var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    { staticClass: "font-weight-bold", attrs: { label: "Campaign" } },
    [
      _c("tree-select", {
        attrs: {
          multiple: _vm.multiple,
          value: _vm.value,
          options: _vm.campaigns,
          disabled: _vm.disabled || _vm.isLoading,
          placeholder: "" + (!!_vm.isLoading ? "Loading..." : "Select options")
        },
        model: {
          value: _vm.selectedCampaign,
          callback: function($$v) {
            _vm.selectedCampaign = $$v
          },
          expression: "selectedCampaign"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }