import TypesConst from "../../TypesConst";
import CallbackService from "../../../services/CallbackService";

export default {
	[TypesConst.callback.actions.CALLBACK_GET_LIST_ACTION]({commit, state}, page = 1) {
		commit(TypesConst.callback.mutations.SET_CALLBACK_LIST_LOADER, true);

		let model = {
			...state.callBackListModel,
			page
		};

		CallbackService.getCallbackList(model)
			.then(response => {
				commit(TypesConst.callback.mutations.SET_CALLBACK_LIST, response.data.data);
				commit(TypesConst.callback.mutations.SET_CALLBACK_LIST_RESPONSE, response.data);
			})
			.catch(error => {
				let message = error.response.data.message ? error.response.data.message : error.message;
				flash(message, "alert-danger");
			})
			.finally(() => {
				commit(TypesConst.callback.mutations.SET_CALLBACK_LIST_LOADER, false);
			});
	}
};