<template>
    <div class="container-fluid">
        <syndicate-shares-search class="mb-3"
                                 v-if="showFilterForm"
                                 @search="onSearchEvent"
                                 @clear="clearSearchModel"
        />

        <div v-if="!!showTable"
             class="card card-list"
        >
            <div class="card-header d-flex align-items-center justify-content-between">
                <div><i class="fa fa-ticket-alt"></i> Syndicate shares</div>
                <span v-if="!!hasMore"
                      class="text-danger"
                >
                    Search result contains more than 1000 rows, please narrow your search.
                </span>
                <div class="d-flex align-items-center">
                    <atbl-select v-if="!!tickets.length"
                                 :options="paginationModel.data"
                                 :value="paginationModel.default"
                                 :first-option-label="null"
                                 option-label="text"
                                 class="mr-2 mb-0"
                                 @input="onPaginationLimitChangeEvent"
                    />
                    <button class="btn btn-sm btn-outline-primary m-0"
                            @click="showFilterForm = ! showFilterForm"
                    >
                        <i class="fa fa-filter" aria-hidden="false"></i>
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list v-sortable" @sort="onUpdateSortEvent">
                    <div class="row sticky-element">
                        <div v-sort:id="sort" class="col text-muted">Ticket</div>
                        <div v-sort:created_by="sort" class="col text-muted text-center">Created By</div>
                        <div v-sort:game_id="sort" class="col text-muted text-center">Game</div>
                        <div v-sort:syndicate_item_id="sort" class="col text-muted text-center">Syndicate</div>
                        <div v-sort:order_id="sort" class="col text-muted text-center">Order</div>
                        <div v-sort:cut_off_at="sort" class="col text-muted text-center">Draw Date</div>
                        <div v-sort:lines_count="sort" class="col text-muted text-center">Lines</div>
                        <div v-sort:shares="sort" class="col text-muted text-center">Shares</div>
                        <div v-sort:prize="sort" class="col text-muted text-center">Winnings</div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="syndicateShare in ticketsData.data"
                             :key="syndicateShare.id"
                             class="row py-3"
                        >
                            <div class="col align-self-center">
                                <a :href="`/syndicate-shares/${syndicateShare.id}`" v-if="$can('Player - View Tickets')">
                                    ST# {{ syndicateShare.id }}
                                </a>
                                <div>
                                    <small class="text-muted">{{ utcToLocal(syndicateShare.created_at) }}</small>
                                </div>
                                <div>
                                    <span>Player: </span>
                                    <a :href="`/players/${syndicateShare.player_id}/view`" v-if="$can('Player - View Details')">
                                        {{ syndicateShare.player_name }} {{ syndicateShare.player_surname }}
                                    </a>
                                    <span v-else>
                                        {{ syndicateShare.player_name }} {{ syndicateShare.player_surname }}
                                    </span>
                                </div>
                            </div>
                            <div class="col align-self-center text-center">
                                <created-by-badge :created="syndicateShare.created_by" />
                            </div>
                            <div class="col text-center align-self-center">
                                <img :src="$imageService.gameImage(syndicateShare.game_image)" style="width: 60px; height: auto;">
                            </div>
                            <div class="col text-center align-self-center">
                                <a :href="`/syndicates/items/${syndicateShare.syndicate_item_id}/edit`" v-if="$can('Syndicates - Create/Update')">
                                    S#{{ syndicateShare.syndicate_item_id }} <br /> {{ syndicateShare.title }}
                                </a>
                                <span v-else>
                                    S#{{ syndicateShare.syndicate_item_id }} <br /> {{ syndicateShare.title }}
                                </span>
                            </div>
                            <div class="col text-center align-self-center">
                                <a :href="`/orders/${syndicateShare.order_id}`" v-if="$can('Bets - View Bet Details')">
                                    O# {{ syndicateShare.order_id }}
                                </a>
                                <span v-else>
                                    O# {{ syndicateShare.order_id }}
                                </span>
                            </div>
                            <div class="col text-center align-self-center">
                                {{ syndicateShare.cut_off_at }}
                            </div>
                            <div class="col text-center align-self-center">
                                {{ syndicateShare.lines_count }}
                            </div>
                            <div class="col text-center align-self-center">
                                {{ syndicateShare.shares }}
                            </div>
                            <div class="col text-center align-self-center">
                                <span v-if="syndicateShare.prize === null">
                                    Not drawn yet
                                </span>
                                <span v-else>
                                    {{ formatCurrency(syndicateShare.prize) }}
                                </span>
                            </div>
                        </div>
                    </template>

                    <loader :show="isLoading"
                            type="small"
                    />

                    <items-not-found v-if="!isLoading"
                                     msg="No syndicates shares found"
                                     :items="tickets"
                    />
                </div>
            </div>

            <div v-if="!isLoading && ticketsData.last_page > 1"
                 class="card-footer overflow-x-auto"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <nav class="wrap">
                        <atbl-pagination :pagination="ticketsData"
                                         :scroll="false"
                                         @paginate="onPageChangeEvent"
                        />
                    </nav>
                    <a href="#"
                       @click.prevent="$scrollToTop()"
                    >
                        <i class="fa fa-caret-up mr-2"></i> Back to top
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SyndicateSharesSearch from './SyndicateSharesSearch/SyndicateSharesSearch';
import SyndicateShareService from '../../../services/SyndicateShareService';
import { clone } from '../../../utils/Json/Json';
import PaginationModel from '../../../modules/pagination/PaginationModel';
import PaginateOptions from '../../../data/PaginateOptions';
import { filterNonEmpty } from '../../../utils';
import SaveObjectToUrl from '../../../utils/Url/SaveObjectToUrl';
import DynamicSort from '../../../utils/Array/DynamicSort';
import Paginate from '../../../modules/pagination/Paginate';
import formatCurrency from '../../../filters/FormatCurrency';
import ItemsNotFound from '../../../components/Lists/ItemsNotFound';
import Loader from '../../../components/Widgets/Loader';
import AtblPagination from '../../../components/AtblPagination';
import utcToLocal from '../../../filters/UtcToLocal';

const sortObject = {
    sortBy: 'id',
    direction: 'DESC',
    sortOnInit: false,
};

export default {
    name: 'syndicate-shares-index',
    components: {
        SyndicateSharesSearch,
        ItemsNotFound,
        Loader,
        AtblPagination,
    },
    data() {
        return {
            showFilterForm: true,
            hasMore: false,
            tickets: [],
            ticketsData: clone(PaginationModel),
            isLoading: false,
            model: {},
            paginationModel: clone(PaginateOptions),
            sort: clone(sortObject),
            showTable: false,
            page: 1,
        };
    },
    computed: {
        hasModelValues() {
            return !!filterNonEmpty(this.model).length;
        },
    },
    methods: {
        utcToLocal,
        formatCurrency,
        onSearchEvent(data) {
            if (data.hasOwnProperty('otherParams')) {
                const { page, sort, paginate } = data.otherParams;

                this.page = page;
                this.sort = sort;
                this.paginationModel.default = paginate;
            }

            this.model = data.hasOwnProperty('searchModel')
                ? data.searchModel
                : data;

            this.getSyndicateShares();
        },

        async getSyndicateShares() {
            if (!this.hasModelValues) {
                window.showMessage('Search form cannot be empty.', false);

                return;
            }

            this.showTable = true;
            this.isLoading = true;

            const response = await SyndicateShareService.index(this.model);

            const { tickets, hasMore } = response.data;

            this.hasMore = hasMore;
            this.tickets = tickets;

            this.setOrdersData(this.page);

            this.isLoading = false;
        },
        onPageChangeEvent(page = 1) {
            this.setOrdersData(page);
        },
        onUpdateSortEvent(e) {
            this.sort = {
                sortBy: e.detail.key,
                direction: e.detail.direction,
                sortOnInit: sortObject.sortOnInit,
            };

            this.setOrdersData();
        },
        setOrdersData(page = 1) {
            SaveObjectToUrl({
                ...this.model,
                sort: this.sort,
                paginate: this.paginationModel.default,
                page: page,
            });

            this.ticketsData = this.sortAndPaginateData(page);
        },
        sortAndPaginateData(page = 1) {
            const { sortBy, direction } = this.sort;

            const sortOrders = DynamicSort(
                clone(this.tickets),
                sortBy,
                direction
            );

            return Paginate(
                sortOrders,
                page,
                this.paginationModel.default
            );
        },
        onPaginationLimitChangeEvent(value) {
            this.paginationModel.default = value;

            this.setOrdersData();
        },
        clearSearchModel() {
            this.model = {}

            const cleanUrl = window.location.href.split('?')[0];
            if (!!cleanUrl) {
                window.history.pushState({}, '', cleanUrl);
            }
        }
    },
}
</script>