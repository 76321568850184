var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        show: _vm.show,
        "close-on-backdrop": false,
        "close-on-esc": false,
        title: "Approve Draw"
      },
      on: { close: _vm.toggleApproveModal },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger font-weight-bold mr-1",
                  on: { click: _vm.toggleApproveModal }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { disabled: !!_vm.isLoading },
                  on: {
                    click: function($event) {
                      return _vm.approveDrawAction(
                        _vm.drawId,
                        _vm.selectedLines
                      )
                    }
                  }
                },
                [
                  !_vm.isLoading ? _c("span", [_vm._v("Approve")]) : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: _vm.isLoading, type: "smaller" }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isAllSelected
        ? [
            _c("h4", { staticClass: "text-center" }, [
              _vm._v("Are your sure you want to approve the whole draw?")
            ])
          ]
        : [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col text-center font-weight-bold text-uppercase"
                },
                [_vm._v("Total lines")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col text-center font-weight-bold text-uppercase"
                },
                [_vm._v("Selected lines")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col text-center" }, [
                _vm._v(_vm._s(_vm.allLines.length))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-center" }, [
                _vm._v(_vm._s(_vm.selectedLines.length))
              ])
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("h5", { staticClass: "text-center" }, [
              _vm._v(
                "Are your sure you want to approve only the selected lines in this draw?"
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }