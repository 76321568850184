var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.collectedErrors.length
    ? _c(
        "div",
        _vm._l(_vm.collectedErrors, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "alert alert-danger w-100 rounded-0 px-3 py-2"
            },
            [_vm._v("\n        " + _vm._s(item) + "\n    ")]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }