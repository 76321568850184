<template>
    <div class="container-fluid">
        <div v-if="!campaignLoader"
             class="row"
        >
            <div class="col-12 col-md-5">
                <campaign-form-edit-component :campaign-id="id"
                                              :is-loading="saveCampaignLoading"
                                              :campaign-model="campaignModel"
                                              :campaign-model-errors="campaignModelErrors"
                />
            </div>
            <div class="col-12 col-md-7">
                <campaigns-leads-list-edit-component v-if="!!id"
                                                     :filter-list-model="{
                                                        filterListLoader,
                                                        filtersList
                                                     }"
                                                     :in-campaign-leads-response="campaignLeadsListResponse"
                                                     :in-campaign-leads-loader="campaignLeadsListLoader || addLeadsSidebarLoading"
                />
            </div>
        </div>

        <loader :show="!!campaignLoader"
                type="small"
        />

        <add-customers-sidebar-component v-if="$can('Campaigns - Add/Remove Leads')"
                                         :show="addLeadsSidebarShow"
                                         :leads-list-response="leadsOutOfCampaignResponse"
                                         :leads-list-loader="leadsOutOfCampaignLoader || addLeadsSidebarLoading"
                                         :leads-list-model="leadsOutOfCampaignSearchModel"
        />

        <confirm-add-all-customers-in-campaign-component :show="addAllLeadsInCampaignModalOpen"
                                                         :inCampaign="leadsOutOfCampaignSearchModel.inCampaign"
                                                         :is-loading="addAllLeadsInCampaignModalLoading || leadsOutOfCampaignLoader || addLeadsSidebarLoading"
                                                         :leads-count="allLeadsCount"
        />
    </div>
</template>

<script>
import AddCustomersSidebarComponent from './AddCustomersSidebar/AddCustomersSidebarComponent';
import CampaignsLeadsListEditComponent from './CampaignCustomersListEdit/CampaignsLeadsListEditComponent';
import CampaignFormEditComponent from './CampaignFormEdit/CampaignFormEditComponent';
import CampaignFormEdit from './CampaignFormEdit/CampaignFormEdit';
import CampaignCustomersFilterList from '../_Components/CampaignCustomersFilterList/CampaignCustomersFilterList';
import MarketingService from '../../../../services/MarketingService';
import AddCustomersSidebar from './AddCustomersSidebar/AddCustomersSidebar';
import CustomersOutOfCampaign from './AddCustomersSidebar/CustomersOutOfCampaign/CustomersOutOfCampaign';
import ConfirmAddAllCustomersInCampaignComponent
    from './AddCustomersSidebar/ConfirmAddAllCustomersInCampaign/ConfirmAddAllCustomersInCampaignComponent';
import { clone } from '../../../../utils/Json/Json';
import PaginationModel from '../../../../modules/pagination/PaginationModel';
import ConfirmAddAllCustomersInCampaign
    from './AddCustomersSidebar/ConfirmAddAllCustomersInCampaign/ConfirmAddAllCustomersInCampaign';
import Campaign from '../Campaign';

export default {
    name: 'campaigns-edit-component',
    mixins: [
        Campaign,
        CampaignFormEdit,
        CampaignCustomersFilterList,
        AddCustomersSidebar,
        CustomersOutOfCampaign,
        ConfirmAddAllCustomersInCampaign,
    ],
    components: {
        CampaignFormEditComponent,
        CampaignsLeadsListEditComponent,
        AddCustomersSidebarComponent,
        CampaignFormEdit,
        ConfirmAddAllCustomersInCampaignComponent,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: null,
        }
    },
    provide() {
        return {
            campaignId: this.id,
            campaignType: this.type,
            fetchCampaignLeads: this.fetchCampaignLeads,
            changeModelFilters: this.changeModelFilters,
        };
    },
    data() {
        return {
            campaignSearchModel: {
                filter: [],
                page: 1,
            },
            campaignSearchFilters: [],
            campaignLoader: false,
            campaignLeadsListLoader: false,
            campaignLeadsListResponse: clone(PaginationModel),
        };
    },
    async created() {
        if (!this.id) {
            return;
        }

        await this.fetchCampaignById();
        await this.fetchCampaignLeads();
        await this.fetchLeadsOutOfCampaign(this.id);
    },
    methods: {
        async reFetchData(campaignId, resetFilters = false) {
            if (!!resetFilters) {
                this.campaignSearchModel.filter = [];
            }

            this.fetchCampaignFilters(campaignId, this.type);
            await this.fetchCampaignLeads();
            await this.fetchLeadsOutOfCampaign(campaignId);
        },
        async fetchCampaignLeads(page = 1) {
            this.campaignLeadsListLoader = true;

            this.campaignSearchModel.page = page;

            try {
                const response = await MarketingService.getCampaignLeads(this.type, this.id, this.campaignSearchModel);

                this.campaignLeadsListResponse = response.data;

                this.campaignLeadsListLoader = false;
            } catch (error) {
                this.campaignLeadsListLoader = false;

                window.flashError(error);
            }
        },
        async fetchCampaignById() {
            if (!this.$can('Campaigns - Get By Id')) {
                window.showMessage('ou does not have permissions for this action.', false);
                return;
            }

            this.campaignLoader = true;

            try {
                const response = await MarketingService.getCampaignById(this.type, this.id);
                const data = response.data;

                if (!!data.hasOwnProperty('status') && !data.status) {
                    window.location.href = `/marketing/campaigns/${this.type}`;

                    return;
                }
                
                const model = data;
                model.tags = data.tags.map(item => item.id);
                
                Object.assign(this.campaignModel, model);

                this.campaignLoader = false;
            } catch (error) {
                this.campaignLoader = false;

                window.flashError(error);
            }
        },
        async changeModelFilters(filters, resolvedFilters) {
            this.filtersList = filters;
            this.campaignSearchModel.filter = resolvedFilters;

            await this.fetchCampaignLeads();
        },
    },
}
</script>