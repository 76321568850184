<template>
    <div class="card card-list">
        <div class="card-header">
            <i class="fa fa-phone"></i> Last Calls
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="row show-first-row">
                    <div class="col">
                        Number
                    </div>
                    <div class="col">
                        Status
                    </div>
                    <div class="col">
                        Date
                    </div>
                </div>

                <template v-if="!isLoading && !!calls.length">
                    <div v-for="(item, index) in calls"
                         :key="index"
                         class="row"
                    >
                        <div class="col">
                            <span class="badge badge-primary">
                                From: {{ item.from }}
                            </span>
                            <br />
                            <span class="badge badge-success">
                                To: {{ item.to }}
                            </span>
                        </div>
                        <div class="col">
                            <span class="badge badge-primary">
                                {{ item.status }}
                            </span>
                            <br />
                            <small>
                                {{ !!item.duration ? item.duration : '/' }}
                            </small>
                        </div>
                        <div class="col">
                            {{ utcToLocal(item.created_at) }}
                        </div>
                    </div>
                </template>

                <loader :show="isLoading"
                        type="small"
                />

                <not-found v-if="!isLoading"
                           :items="calls"
                           msg="No calls found."
                />
            </div>
        </div>

        <div v-if="!!pagination && !isLoading && callsResponse.last_page > 1" class="card-footer">
            <nav class="wrap">
                <atbl-pagination :pagination="callsResponse"
                                 @paginate="fetchCalls"
                />
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeadLastCalls',
    props: {
        leadId: {},
        pagination: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isLoading: false,
            calls: [],
            callsResponse: {},
        };
    },
    async created() {
        await this.fetchCalls();
    },
    methods: {
        async fetchCalls(page = 1) {
            if (!this.$can('Leads - View Calls')) {
                window.showMessage('You does not have permissions for this action.', false);

                return;
            }

            this.isLoading = true;

            try {
                const response = await window.axios.get(`/marketing/leads/${this.leadId}/last-calls`, {
                    params: {
                        page
                    }
                });

                this.callsResponse = response.data;
                this.calls = response.data.data;

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);

                this.isLoading = false;
            }
        },
    },
}
</script>