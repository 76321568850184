var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Send Email",
        show: _vm.modalOpen,
        "close-on-backdrop": false
      },
      on: { close: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end align-items-center"
                },
                [
                  !_vm.sendEmailLoader
                    ? _c("div", [
                        !_vm.isConfirmationActive
                          ? _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-outline-danger",
                                  on: { click: _vm.handleCancel }
                                },
                                [
                                  _vm._v(
                                    "\n                        Cancel\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-primary",
                                  attrs: { disabled: !_vm.canProceed },
                                  on: {
                                    click: function($event) {
                                      _vm.isConfirmationActive = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                        Continue\n                    "
                                  )
                                ]
                              )
                            ])
                          : _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-danger",
                                  on: {
                                    click: function($event) {
                                      _vm.isConfirmationActive = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                        Cancel\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-success",
                                  on: { click: _vm.onSendEmailEvent }
                                },
                                [
                                  _vm._v(
                                    "\n                        Send\n                    "
                                  )
                                ]
                              )
                            ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loader", {
                    attrs: { show: _vm.sendEmailLoader, type: "small" }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "send-email-client" }, [
        _c("p", { staticClass: "font-weight-bold" }, [
          _vm._v("Client information's")
        ]),
        _vm._v(" "),
        !!_vm.sendEmailConfiguration.is_lead
          ? _c("div", { staticClass: "ribbon-primary" }, [
              _c("span", [_vm._v("Lead")])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("h3", [
          _vm._v(
            "# " +
              _vm._s(_vm.client.id) +
              " / " +
              _vm._s(_vm.client.name) +
              " " +
              _vm._s(_vm.client.surname)
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.client.email))])
      ]),
      _vm._v(" "),
      _c("send-email-form-component", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isConfirmationActive,
            expression: "!isConfirmationActive"
          }
        ],
        attrs: {
          "send-email-configuration": _vm.sendEmailConfiguration,
          "selected-email-template": _vm.selectedEmailTemplate,
          "is-ticket-confirmation": _vm.isTicketConfirmation,
          "is-order-summary": _vm.isOrderSummary,
          templates: _vm.templates,
          ticket: _vm.ticket,
          "ticket-loader": _vm.ticketLoader,
          "ticket-error": _vm.ticketError,
          order: _vm.order,
          "order-loader": _vm.orderLoader,
          "order-error": _vm.orderError,
          locale: _vm.selectedLocale,
          "send-email-model": _vm.sendEmailModel,
          "ticket-types": _vm.ticketTypes,
          errors: _vm.errors
        }
      }),
      _vm._v(" "),
      _c("send-email-confirmation-component", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isConfirmationActive,
            expression: "isConfirmationActive"
          }
        ],
        attrs: {
          locale: _vm.selectedLocale,
          "is-ticket-confirmation": _vm.isTicketConfirmation,
          "selected-email-template": _vm.selectedEmailTemplate,
          ticket: _vm.ticket
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }