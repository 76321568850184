<template>
    <div class="game-header d-flex align-items-center w-100">
        <img :src="$imageService.gameImage(game.game.image)" class="logo-game"/>
        <div class="d-flex justify-content-between w-100 align-items-center">
            <div>
                <h4 class="m-0">
                    <div>{{ game.game.name }}</div>
                </h4>
                <span>
                    #{{ game.id }}
                </span>
            </div>
            <h4 class="m-0">
                <p class="text-center m-0 draw-date-title" :class="{ 'text-white': isFromConfirm }">Draw Date</p>
                <span>{{ game.date }}</span>
            </h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: "game-header",
        props: {
            game: {
                type: Object,
                required: true
            },
            isFromConfirm: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped lang="scss">
    .game-header {
        .draw-date-title {
            font-size: 14px;
            color: #9a9a9a;
        }

        img {
            width: 70px;
            margin-right: 20px
        }
    }
</style>