import TypesConst from "../../TypesConst";

export default {
    [TypesConst.general.mutations.SET_SHOW_SIDEBAR](state, payload) {
        state.sidebarShow = payload;
    },
    [TypesConst.general.mutations.SET_SHOW_SIDEBAR_DESKTOP](state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
        state.sidebarShow = sidebarOpened ? false : 'responsive';
    },
    [TypesConst.general.mutations.SET_SHOW_SIDEBAR_MOBILE](state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
        state.sidebarShow = sidebarClosed ? true : 'responsive';
    },
    [TypesConst.general.mutations.SET_MINIMIZE_SIDEBAR](state, payload) {
        state.sidebarMinimize = payload;
    },
    [TypesConst.general.mutations.SET_LOCALE](state, payload) {
        Object.assign(state.locale, payload);
    },
    [TypesConst.general.mutations.SET_LOCALE_CLEAR](state) {
        Object.assign(state.locale, {
            label: "English",
            value: "gb"
        });
    },
};