<template>
    <atbl-form-group :horizontal="horizontal"
                     label="Date Type"
                     label-for="Date Type"
                     class="font-weight-bold mb-0"
    >
        <tree-select v-model="selectedDateTypes"
                     :multiple="true"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="dateTypes"
                     :disabled="!!disabled"
                     :normalizer="(node) => ({id: node.key, label: node.name})"
                     :placeholder="'Select Date Type'"
        />
    </atbl-form-group>
</template>

<script>
export default {
    name: 'multi-players-date-types-picker',
    emits: [
        'input',
        'update',
    ],
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'dateTypes'
        },
        reset: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            dateTypes: [
                {
                    key: 'created_at',
                    name: 'Date Created'
                },
                {
                    key: 'last_seen_at',
                    name: 'Last seen'
                },
                {
                    key: 'transaction_created_at',
                    name: 'Last transaction'
                },
                {
                    key: 'latest_sale_transaction_at',
                    name: 'Last sale'
                },
                {
                    key: 'latest_deposit_transaction_at',
                    name: 'Last deposit'
                },
                {
                    key: 'call_created_at',
                    name: 'Last call'
                },
                {
                    key: 'disposition_created_at',
                    name: 'Last disposition'
                },
            ]
        };
    },
    computed: {
        selectedDateTypes: {
            get() {
                return this.reset ? [] : this.value
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
}
</script>