<template>
    <player-layout :id="id"
                   icon="fa fa-play"
                   title="Orders"
    >
        <div class="general-list">
            <div class="row d-none d-xl-flex">
                <div class="col-12 col-lg-4 col-xl text-muted text-center text-xl-left">Order#</div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">Created By</div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">Items</div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">Amount</div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">Transaction</div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">Status</div>
            </div>

            <div v-if="!isLoading && orders.length"
                   v-for="(order, index) in orders"
                   :key="index"
                   class="row"
            >
                <div class="col-12 col-lg text-muted text-center text-xl-left">
                    <span class="small-mb-badge-extended">Order</span>
                    <a :href="`/orders/${order.id}?playerId=${order.player_id}`">#O-{{ order.id }}</a>
                    <br />
                    <span class="badge badge-warning font-weight-bold"
                          v-tooltip="{
                              content: 'Created at'
                          }"
                    >
                        {{ utcToLocal(order.created_at) }}
                    </span>
                </div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">
                    <span class="small-mb-badge-extended">Created By</span>
                    <created-by-badge :created="order" />
                </div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">
                    <span class="small-mb-badge-extended">Items</span>
                    {{ order.order_items_count }}
                </div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">
                    <span class="small-mb-badge-extended">Amount</span>
                    <span v-if="!!order.is_free"
                          class="badge badge-danger"
                    >
                        Free
                    </span>
                    <span v-else>
                        {{ formatCurrency(order.amount) }}
                    </span>
                </div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">
                    <span class="small-mb-badge-extended">Transaction</span>
                    <a v-if="!!order.latest_completed_transaction_id"
                       class="d-block"
                       :href="`/transaction/${order.latest_completed_transaction_id}`"
                    >
                        #T-{{ order.latest_completed_transaction_id }}
                    </a>
                    <span v-else>/</span>
                </div>
                <div class="col-12 col-lg-4 col-xl text-muted text-center">
                    <span class="small-mb-badge-extended">Status</span>
                    <span :class="['badge', `badge-${$options.OrdersConstants.statusColor(order.status)}`]"
                             class="font-weight-bold"
                             v-tooltip="{
                                content: 'Status'
                             }"
                    >
                        {{ order.status.split('_').join(' ') }}
                    </span>
                </div>
            </div>

            <not-found v-if="!isLoading"
                       msg="No orders found"
                       :items="orders"
            />

            <loader :show="isLoading"
                    type="small"
            />
        </div>

        <template #footer>
            <nav class="wrap" v-if="!isLoading && ordersData.last_page > 1">
                <atbl-pagination :pagination="ordersData"
                                 @paginate="fetchOrders"
                />
            </nav>
        </template>
    </player-layout>
</template>

<script>
    import playerNav from '../../Components/Layout/PlayerNav'
    import playerInfo from '../../Components/Layout/PlayerInfo'

    import OrdersConstants from '../../../../constants/Orders';
    import Transaction from '../../../Orders/Mixins/Transaction';
    import formatCurrency from '../../../../filters/FormatCurrency';

    export default {
        name: "orders",
        OrdersConstants,
        mixins: [
            Transaction
        ],
        props: [
            'id'
        ],
        components: {
            playerNav,
            playerInfo
        },
        data: function(){
            return {
                orders: [],
                ordersData: {},
                isLoading: false
            }
        },
        methods: {
            formatCurrency,
            fetchOrders: function (page = 1) {
                let _vm = this;

                _vm.isLoading = true;
                window.axios.get(`/player/${this.id}/orders`, {
                    params: {
                        page
                    }
                })
                    .then(function (response) {
                        _vm.orders = response.data.data;
                        _vm.ordersData = response.data;
                    })
                    .finally(() => {
                        _vm.isLoading = false;
                    });
            }
        },
        created: function () {
            this.fetchOrders();
        }
    }
</script>
