import BuildQuery from './BuildQuery';

/**
 * Convert object to query params and set to the URL
 * @param object
 */
export default (object) => {
    const query = BuildQuery(object);

    const urlObject = new URL(window.location.href);

    const url = `${urlObject.origin}${urlObject.pathname}${!query ? '' : `?${query}`}`;

    window.history.pushState({}, '', url);
}