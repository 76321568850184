<template>
    <div class="row mb-4">
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-card :value="statistics.dailyTalkTime"
                                         :loader="isLoading"
                                         title="Daily Talk Time"
                                         class="border-primary"
            />
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-multiple-card :rightValue="statistics.leads"
                                                  :leftValue="statistics.players"
                                                  :loader="isLoading"
                                                  title="Daily Dials"
                                                  rightTitle="Leads"
                                                  class="border-danger"
                                                  leftTitle="Players"
            />
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-card :value="statistics.completedCalls"
                                         :loader="isLoading"
                                         class="border-success"
                                         title="Completed Calls"
            />
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-card :description="statistics.description"
                                         :value="statistics.averageTalkTime"
                                         :loader="isLoading"
                                         class="border-warning"
                                         title="Average Daily Talk Time"
            />
        </div>
    </div>
</template>

<script>
import CallHistoryStatisticCard from '../_Components/Statistics/CallHistoryStatisticCard';
import CallHistoryStatisticMultipleCard from '../_Components/Statistics/CallHistoryStatisticMultipleCard';

export default {
    name: 'CallHistoryStatistics',
    components: {
        CallHistoryStatisticCard,
        CallHistoryStatisticMultipleCard
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        statistics: {
            type: Object,
            default: () => ({
                dailyTalkTime: null,
                leads: null,
                players: null,
                completedCalls: null,
                description: null,
                averageTalkTime: null,
            })
        },
    },
}
</script>