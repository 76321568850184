export default {
    dispositionsRoute: "/dispositions/disposition",
    getSingleDispositionRoute(id){
        return `${this.dispositionsRoute}/${id}`;
    },
    getDispositionsDropDown(_params = {}){
        return window.axios.get(`${this.dispositionsRoute}/drop/down/data`, { params: _params });
    },
    getDispositionsData(_params = {}){
        return window.axios.get(this.dispositionsRoute, { params: _params });
    },
    createDisposition(_params = {}){
        return window.axios.post(this.dispositionsRoute, _params);
    },
    updateDisposition(id, _params = {}){
        return window.axios.patch(`${this.dispositionsRoute}/${id}`, _params);
    },
    getSingleDisposition(id){
        return window.axios.get(this.getSingleDispositionRoute(id));
    },
}