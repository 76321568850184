var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.$can("Notes - Create/Update")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary float-right mb-2",
                    on: { click: _vm.toggleNoteModal }
                  },
                  [_vm._v("\n                Create\n            ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Id")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Type")]),
                _vm._v(" "),
                _vm.$canAny(_vm.notesOperationPermissions)
                  ? _c("div", { staticClass: "col-12 col-lg" }, [
                      _vm._v(
                        "\n                        Operations\n                    "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._l(_vm.notes, function(item, index) {
                return !_vm.isLoading
                  ? _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Id")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.id) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Type")
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.type) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$canAny(_vm.notesOperationPermissions)
                        ? _c("div", { staticClass: "col-12 col-lg" }, [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _vm.$can("Notes - Create/Update")
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onEditNoteEvent(item.id)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-pen" })]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$can("Notes - Remove")
                                  ? _c("remove-list-item-button", {
                                      attrs: { value: item.id },
                                      on: { confirmed: _vm.onDeleteNoteEvent }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("items-not-found", {
                    attrs: { items: _vm.notes, msg: "No notes found" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("create-update-note-component", {
        attrs: {
          show: _vm.isCreateNotModalOpen,
          model: _vm.createNoteModel,
          errors: _vm.createNoteErrors,
          "is-loading": _vm.isCreateNotModalLoading
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-align-justify" }),
      _vm._v(" Notes")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }