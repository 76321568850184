export default {
    address1: null,
    address2: null,
    birthdate: null,
    city: null,
    converted: null,
    country: null,
    email: null,
    id: null,
    latest_disposition_id: null,
    live_spent: null,
    mobile: null,
    name: null,
    other: null,
    phone: null,
    postcode: null,
    state: null,
    status_name: null,
    surname: null,
    unlock_at: null,
}