import LanguagesService from '../../services/LanguagesService';
import { clone } from '../../utils/Json/Json';

export default {
    data() {
        return {
            languages: [],
        };
    },
    methods: {
        async fetchLanguages() {
            this.isLoading = true;

            try {
                const response = await LanguagesService.getLanguagesDropdownList();

                this.languages = response.data.map(language => {
                    const value = language.value === 'gb' ? 'en' : language.value;

                    return {
                        ...language,
                        value
                    };
                });
            } catch (error) {
                window.flashError(error);
            }
        },
        resolveExternalTypesLanguages(languages, externalTypes) {
            if (!languages.length) {
                throw new Error('Languages cannot be empty.');
            }

            const languagesValues = languages.map(item => {
                return [
                    item.value,
                    {
                        value: null,
                        inputType: 'number',
                        title: item.label + ' template ID.',
                        attributes: [
                            {
                                title: 'Link',
                                name: 'link',
                                value: null,
                                inputType: 'text',
                            }
                        ],
                    }
                ];
            });

            const resolvedTypes = Object.fromEntries(languagesValues);

            const tmpExternalTypes = clone(externalTypes);

            for (const externalType in tmpExternalTypes) {
                tmpExternalTypes[externalType].meta.languages = resolvedTypes;
            }

            return tmpExternalTypes;
        },
        resolveExternalTypeByUserTypeMeta(model, externalTypes) {
            const tmpModel = clone(model);

            for (const metaKey in tmpModel.meta) {
                const currentMetaValue = tmpModel.meta[metaKey];

                currentMetaValue.externalTypes = clone(externalTypes);
            }

            return tmpModel;
        },
    },
}