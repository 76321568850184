var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-dropdown",
    {
      staticClass: "c-header-nav-items",
      attrs: { inNav: "", placement: "bottom-end", "add-menu-classes": "py-0" },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function() {
            return [
              _c("c-header-nav-link", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.name,
                          placement: "bottom-center"
                        },
                        expression:
                          "{\n                    content: name,\n                    placement: 'bottom-center'\n                  }"
                      }
                    ],
                    staticClass: "avatar badge-info rounded-circle"
                  },
                  [
                    _c("span", { staticClass: "avatar-text" }, [
                      _c("span", [_vm._v(_vm._s(_vm.getName))])
                    ])
                  ]
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.$can("User - Manage Account View")
        ? _c("c-dropdown-item", { attrs: { href: "/users/manage-account" } }, [
            _c("i", { staticClass: "fa fa-user mr-2" }),
            _vm._v(" Manage Account\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("c-dropdown-item", { on: { click: _vm.submitLogout } }, [
        _c("i", { staticClass: "fa fa-sign-out-alt mr-2" }),
        _vm._v(" Logout\n    ")
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "logoutForm",
          staticClass: "d-none",
          attrs: { action: "/logout", method: "POST" }
        },
        [
          _c("input", {
            attrs: { type: "hidden" },
            domProps: { value: _vm.token }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }