<template>
	<div class="send-email">
		<button class="btn btn-light player-nav-group-button btn-block"
		          v-if="$can('Player - Send Email')"
		          @click="onToggleEmailModalEvent(true)"
		          :disabled="!hasEmail"
		>
			<i class="fa fa-envelope" aria-hidden="false"></i> SEND EMAIL
		</button>
		<div class="text-center"
		     v-if="!hasEmail"
		>
			<small class="text-danger text-uppercase font-weight-bold">
				Email is missing
			</small>
		</div>
		
		<send-email-modal-component :client="client"
                                    :show="sendEmailModalOpened"
                                    :send-email-configuration="emailTemplatesEnabledTypes"
                                    @toggle="onToggleEmailModalEvent"
        />
	</div>
</template>

<script>
	import SendEmailModalComponent from "./SendEmailModal/SendEmailModalComponent";
    import SendEmail from './SendEmail';
    import SendEmailModal from './SendEmailModal/SendEmailModal';
    import SendEmailConstants from './SendEmailConstants';
	
	export default {
		name: "SendEmailComponent",
        mixins: [
            SendEmail,
            SendEmailModal,
        ],
		props: {
			playersEnabled: {
				type: Boolean,
				default: true
			},
			leadsEnabled: {
				type: Boolean,
				default: false
			},
			isLead: {
				type: Boolean,
				default: false
			},
            client: {
                type: Object,
                default: () => ({})
            },
		},
		components: {
            SendEmailModalComponent
		},
        computed: {
            hasEmail() {
                return !!this.client &&
                       !this.isObjectEmpty(this.client) &&
                       !!this.client.email;
            },
        },
		created() {
            this.emailTemplatesEnabledTypes = {
                playersEnabled: this.playersEnabled,
                leadsEnabled: this.leadsEnabled,
                is_lead: this.isLead
            };

            window.Atbl.$on(SendEmailConstants.key, this.onToggleEmailModalEvent);
		}
	}
</script>