<template>
    <div>
        <hr />
        <h5 class="text-center mb-3">Do you really want to send this type of message.</h5>
        <div class="text-center">
            <p class="mb-1">
                Language:
            </p>
            <div class="d-flex align-items-center justify-content-center mb-3">
                <img :src="`/img/flags/${getLocaleValue.toLowerCase()}.svg`" class="flag mr-1" alt="flag" />
                <b>{{ getLocaleName }}</b>
            </div>
            <p>
                Email Name:<br /><b>{{ selectedEmailTemplate.name }}</b>
            </p>
            <p>
                Email Description:<br /><b>{{ selectedEmailTemplate.description }}</b>
            </p>
        </div>
        <div v-if="isTicketConfirmation && !isObjectEmpty(ticket)"
             class="ticket-preview vertical"
        >
            <div v-if="!isObjectEmpty(ticket)"
                 class="ticket-preview-holder"
            >
                <div class="ticket-preview-holder-row">
                    <div>
                        <img :src="$imageService.gameImage(ticket.game.image)"
                             :alt="`${ticket.game.image}`" style="width: 60px; height: auto;">
                    </div>
                    <div>
                        <h4>{{ ticket.game.name }}</h4>
                        <div class="draw-date"
                             v-if="isset(ticket, 'draw') && ticket.draw.game_draw && isset(ticket.draw.game_draw, 'date')">
                            <span class="text-muted small">Date: {{ ticket.draw.game_draw.date }}</span>
                        </div>
                        <div class="draw-date"
                             v-if="isset(ticket, 'game_draw') && ticket.game_draw && isset(ticket.game_draw, 'date')">
                            <span class="text-muted small">Date: {{ ticket.game_draw.date }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Language from '../../../modules/languages/Language';

export default {
    name: 'SendEmailConfirmationComponent',
    Language,
    props: {
        isTicketConfirmation: {
            type: Boolean,
            default: false,
        },
        locale: {
            type: [String, Object],
            default: null
        },
        selectedEmailTemplate: {
            type: Object,
            default: () => ({})
        },
        ticket: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        getLocaleValue() {
            return this.isObject(this.locale)
                ? this.locale.value
                : this.locale;
        },
        getLocaleName() {
            return this.isObject(this.locale)
                ? this.locale.label
                : this.locale;
        },
    },
}
</script>