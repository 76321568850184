<template>
    <player-layout :id="id"
                   title="Call History"
                   class="mb-2"
    >
        <div class="general-list">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0">ID</div>
                <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">Agent</div>
                <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">Call Status & Date</div>
                <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">Talk Time</div>
                <div v-if="$can('Call History - Recordings')"
                     class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                    Audio
                </div>
            </div>

            <template v-if="!callHistoryLoader">
                <div v-for="(item, index) in callHistoryList"
                     :key="index"
                     class="row"
                >
                    <div class="col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">ID</span>
                        {{ item.id }}
                    </div>
                    <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Agent</span>
                        {{ item.user.name }}
                    </div>
                    <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Call Status & Date</span>
                        <call-status-badge :title="item.status" />
                        <br>
                        <small>{{ utcToLocal(item.created_at) }}</small>
                    </div>
                    <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Talk Time</span>
                        {{ item.duration | emptyField }}
                    </div>
                    <div v-if="$can('Call History - Recordings')"
                         class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    >
                        <span class="small-mb-badge">Audio</span>
                        <button class="audio-link"
                                v-tooltip="{
                                    content: 'Recordings'
                                }"
                                @click="showCallRecordings(item.id)"
                        >
                            <i class="fa fa-file-audio" aria-hidden="false"></i>
                        </button>
                    </div>
                </div>
            </template>

            <loader :show="callHistoryLoader"
                    type="small"
            />

            <not-found v-if="!callHistoryLoader"
                       :items="callHistoryList"
                       msg="No call history found."
            />

            <nav v-if="!callHistoryLoader && callHistoryResponse.last_page > 1"
                 class="wrap py-3"
            >
                <atbl-pagination :pagination="callHistoryResponse"
                                 @paginate="fetchPlayerCallHistory"
                />
            </nav>
        </div>

        <call-history-filter-recordings-component :show="callHistoryRecordingsSidebarOpen"
                                                  :is-loading="callHistoryRecordingsLoader"
                                                  :recordings="callHistoryRecordingsList"
        />
    </player-layout>
</template>

<script>
import CallHistoryFilterRecordingsComponent
    from '../../../CallHistory/_Components/CallHistoryFilterRecordings/CallHistoryFilterRecordingsComponent';
import PlayerCallHistoryService from '../../../../services/PlayerCallHistoryService';
import PaginationModel from '../../../../modules/pagination/PaginationModel';
import { clone } from '../../../../utils/Json/Json';
import CallStatusBadge from '../../../CallHistory/_Components/CallStatusBadge';
import CallHistoryFilterRecordings
    from '../../../CallHistory/_Components/CallHistoryFilterRecordings/CallHistoryFilterRecordings';

export default {
    name: 'player-call-history',
    mixins: [
        CallHistoryFilterRecordings,
    ],
    components: {
        CallStatusBadge,
        CallHistoryFilterRecordingsComponent
    },
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            callHistoryLoader: false,
            callHistoryResponse: clone(PaginationModel),
        };
    },
    computed: {
        callHistoryList() {
            return this.callHistoryResponse.data;
        },
    },
    async created() {
        await this.fetchPlayerCallHistory();
    },
    methods: {
        async fetchPlayerCallHistory(page = 1) {
            this.callHistoryLoader = true;

            try {
                const response = await PlayerCallHistoryService.getPlayerCallHistory(this.id, {
                    page
                });

                this.callHistoryResponse = response.data;

                this.callHistoryLoader = false;
            } catch (error) {
                this.callHistoryLoader = false;

                window.flashError(error);
            }
        },
    },
}
</script>