<template>
    <sidebar-sub title="Search Calls"
                 :value="show"
                 :loader="callHistoryFilterLoader"
                 class="medium"
                 @input="toggleCallHistoryFilterForm"
    >
        <div class="row leads-section-list-row d-lg-flex align-items-center w-100 m-0">
            <div class="col">
                <atbl-form-group id="date"
                                 label="Date"
                                 label-for="Date"
                                 class="font-weight-bold"
                >
                    <multi-date-picker v-model="selectedDates"
                                       :auto-apply="true"
                    />
                </atbl-form-group>
            </div>
        </div>

        <div v-if="!getIsOnlyAgent && $can('Users - Get List')"
             class="row leads-section-list-row d-lg-flex align-items-center w-100 m-0"
        >
            <div class="col">
                <multi-agents-picker v-model="agents" />
            </div>
        </div>

        <div class="row leads-section-list-row d-lg-flex align-items-center w-100 m-0">
            <div class="col">
                <atbl-form-group id="clientName"
                                 label="Name"
                                 label-for="ClientName"
                                 class="font-weight-bold"
                >
                    <input id="ClientNameInput"
                           :value="callHistoryFilterModel.client_name"
                           class="form-control"
                           required
                           placeholder="Enter client name"
                           @keyup="updateCallHistorySearchField('client_name', $event.target.value)"
                    />
                </atbl-form-group>
            </div>
        </div>

        <div class="row leads-section-list-row d-lg-flex align-items-center w-100 m-0">
            <div class="col">
                <atbl-form-group id="ClientSurname"
                                 label="Surname"
                                 label-for="ClientSurname"
                                 class="font-weight-bold"
                >
                    <input id="ClientSurnameInput"
                           :value="callHistoryFilterModel.client_surname"
                           class="form-control"
                           required
                           placeholder="Enter client surname"
                           @keyup="updateCallHistorySearchField('client_surname', $event.target.value)"
                    />
                </atbl-form-group>
            </div>
        </div>

        <div class="row leads-section-list-row d-lg-flex align-items-center w-100 m-0">
            <div class="col">
                <atbl-form-group id="status"
                                 label="Status"
                                 label-for="Status"
                                 class="font-weight-bold"
                >
                    <select v-model="status"
                            :disabled="!!callHistoryStatusesLoader"
                            id="inline-form-custom-select-pref"
                            class="form-control"
                    >
                        <option :value="null">Select status</option>
                        <option v-for="(item, index) in callHistoryStatuses"
                                :key="index"
                                :value="item.status"
                        >
                            {{ item.status }}
                        </option>
                    </select>
                </atbl-form-group>
            </div>
        </div>

        <div class="row leads-section-list-row d-lg-flex align-items-center justify-content-between w-100 m-0 bg-white border-light">
            <div class="col d-lg-flex align-items-center">
                <button class="btn btn-sm btn-outline-danger"
                        @click="clearCallHistoryFilterForm"
                >
                    <i class="fa fa-ban" aria-hidden="false"></i> Clear
                </button>
            </div>
            <div class="col d-lg-flex align-items-center justify-content-end">
                <button class="btn btn-sm btn-danger mr-2"
                        @click="toggleCallHistoryFilterForm"
                >
                    <i class="fa fa-times" aria-hidden="false"></i> Cancel
                </button>
                <button class="btn btn-sm btn-primary"
                        @click="onCallHistorySearchForm"
                >
                    <i class="fa fa-search" aria-hidden="false"></i> Search
                </button>
            </div>
        </div>
    </sidebar-sub>
</template>

<script>
import moment from 'moment';
import Date from '../../../helpers/Date';
import CallHistorySearchModel from './CallHistorySearchModel';

export default {
    name: 'call-history-filter-form-component',
    inject: [
        'onCallHistorySearchForm',
        'updateCallHistorySearchField',
        'clearCallHistoryFilterForm',
        'toggleCallHistoryFilterForm',
    ],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        callHistoryStatuses: {
            type: Array,
            default: () => ([])
        },
        callHistoryStatusesLoader: {
            type: Boolean,
            default: false
        },
        callHistoryFilterModel: {
            type: Object,
            default: () => (CallHistorySearchModel)
        },
        callHistoryFilterLoader: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        agents: {
            get() {
                return this.callHistoryFilterModel.agents;
            },
            set(value) {
                this.updateCallHistorySearchField('agents', value);
            }
        },
        status: {
            get() {
                return this.callHistoryFilterModel.status;
            },
            set(value) {
                this.updateCallHistorySearchField('status', value);
            }
        },
        selectedDates: {
            get() {
                if (!this.callHistoryFilterModel.dateFrom && !this.callHistoryFilterModel.dateTo) {
                    return null;
                }
                return {
                    startDate: this.callHistoryFilterModel.dateFrom,
                    endDate: this.callHistoryFilterModel.dateTo
                };
            },
            set(value) {
                this.updateCallHistorySearchField(
                    'dateFrom',
                    !!value.startDate
                        ? moment(value.startDate).format(Date.defaultDate)
                        : null
                );
                this.updateCallHistorySearchField(
                    'dateTo',
                    !!value.endDate
                        ? moment(value.endDate).format(Date.defaultDate)
                        : null
                );
            }
        },
    },
}
</script>