export default {
    name: null,
    surname: null,
    email: null,
    country: null,
    phone: null,
    status: [],
    batch: null,
    balanceSelector: null,
    balance: null,
    converted: null,
    campaigns: [],
}