var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "row justify-content-between pb-3 mb-3 border-bottom" },
      [
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Name",
                  value: _vm.player.name + " " + _vm.player.surname,
                  copy: true
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.name) +
                    " " +
                    _vm._s(_vm.player.surname) +
                    "\n            "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Birthdate",
                  value: _vm.player.birthdate,
                  copy: !!_vm.player.birthdate
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.birthdate) +
                    "\n            "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Email",
                  value: _vm.player.email,
                  copy: !!_vm.player.email
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.email) +
                    "\n            "
                )
              ]
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row justify-content-between pb-3 mb-3 border-bottom" },
      [
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Mobile",
                  value: _vm.player.mobile,
                  copy: !!_vm.player.mobile
                }
              },
              [
                _vm.$can("Player - Call") && _vm.player.mobile
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openCallWindow(
                              _vm.player.mobile,
                              _vm.player.id
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.player.mobile) +
                            "\n                "
                        )
                      ]
                    )
                  : _c("span", [
                      _vm._v(_vm._s(_vm.emptyField(_vm.player.mobile)))
                    ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Phone",
                  value: _vm.player.phone,
                  copy: !!_vm.player.phone
                }
              },
              [
                _vm.$can("Player - Call") && _vm.player.phone
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openCallWindow(
                              _vm.player.phone,
                              _vm.player.id
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.player.phone) +
                            "\n                "
                        )
                      ]
                    )
                  : _c("span", [
                      _vm._v(_vm._s(_vm.emptyField(_vm.player.phone)))
                    ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Country",
                  value: _vm.player.country,
                  copy: !!_vm.player.country
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.country) +
                    "\n            "
                )
              ]
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row justify-content-between pb-3 mb-3 border-bottom" },
      [
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "City",
                  value: _vm.player.city,
                  copy: !!_vm.player.city
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.city) +
                    "\n            "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "State",
                  value: _vm.player.state,
                  copy: !!_vm.player.state
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.state) +
                    "\n            "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Postcode",
                  value: _vm.player.postcode,
                  copy: !!_vm.player.postcode
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.postcode) +
                    "\n            "
                )
              ]
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row justify-content-between pb-3 mb-3 border-bottom" },
      [
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Address",
                  value: _vm.player.address1,
                  copy: !!_vm.player.address1
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.player.address1) +
                    "\n            "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4 mb-1 mb-lg-0" },
          [
            _c(
              "atbl-fake-input",
              {
                attrs: {
                  label: "Address",
                  value: _vm.player.address2,
                  copy: !!_vm.player.address2
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.emptyField(_vm.player.address2)) +
                    "\n            "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }