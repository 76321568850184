<template>
    <div class="general-list">
        <div class="row d-none d-lg-flex">
            <div class="col-12 col-lg-2 text-muted text-center text-lg-left">Ticket</div>
            <div class="col-12 col-lg-2 text-muted text-center">Game</div>
            <div class="col-12 col-lg-2 text-muted text-center">Order</div>
            <div class="col-12 col-lg-2 text-muted text-center">Draw Date</div>
            <div class="col-12 col-lg-2 text-muted text-center">Lines / Shares</div>
            <div class="col-12 col-lg-2 text-muted text-center">Winnings</div>
        </div>

        <div v-for="(ticket, index) in tickets"
               :key="index"
               class="row"
        >
            <div class="col-12 col-lg-2 text-muted text-center text-lg-left">
                <span>
                    <b>No.</b> {{ index + 1 }}
                </span>
                <br />
                <span class="small-mb-badge">Ticket</span>
                <a :href="ticket.url"
                   v-if="$can('Tickets - View Ticket Details')"
                >
                    {{ ticket.is_syndicate ? 'Syndicate ' : '' }} Ticket# {{ ticket.id }}
                </a>
                <span v-else>{ ticket.is_syndicate ? 'Syndicate ' : '' }} Ticket# {{ ticket.id }}</span>
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <img :src="$imageService.gameImage(ticket.game.image)" :alt="`${ticket.game.image}`" style="width: 60px; height: auto;">
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <a :href="`/orders/${order.id}`">Order# {{ order.id }}</a>
            </div>
            <div class="col-12 col-lg-2 text-center align-self-ceNter">
                <span class="small-mb-badge">Date</span>
                {{ ticket.date }}
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <span class="small-mb-badge">Lines</span>
                {{ ticket.lines_count }}
            </div>
            <div class="col-12 col-lg-2 text-center align-self-center">
                <span class="small-mb-badge">Winnings</span>
                {{ ticket.winnings }}
            </div>
        </div>

        <not-found msg="No tickets created." :items="tickets" />
    </div>
</template>

<script>
    export default {
        name: 'tickets',
        props: {
            tickets: {
                type: Array,
                required: true
            },
            order: {
                type: Object,
                required: true
            }
        }
    }
</script>