<template>
    <atbl-form-group :label="label"
                     label-for="Status"
                     class="font-weight-bold"
    >
        <tree-select v-model="selectedStatus"
                     :multiple="true"
                     :value="value"
                     :options="statuses"
                     :disabled="disabled || isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
    </atbl-form-group>
</template>

<script>
import LeadStatusService from '../../../../services/Marketing/LeadStatusService';
import DispositionService from '../../../../services/DispositionService';

export default {
    name: 'LeadStatusPicker',
    props: {
        value: {
            type: Array,
            default: null,
        },
        name: {
            type: String,
            default: 'status',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'Status',
        },
        includeNoStatus: {
            type: Boolean,
            default: false,
        },
        isLead: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isLoading: false,
            statuses: [],
        };
    },
    computed: {
        selectedStatus: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
    async created() {
        await this.fetchCustomerStatuses();
    },
    methods: {
        async fetchCustomerStatuses() {
            this.isLoading = true;
            try {
                const response = !!this.isLead
                    ? await LeadStatusService.getLeadsStatuses()
                    : await DispositionService.getDispositionsDropDown();

                this.statuses = [
                    ...(
                        !!this.includeNoStatus
                            ? [
                                {
                                    label: 'No status',
                                    id: 'no-status',
                                    value: 'no-status',
                                }
                            ]
                            : []
                    ),
                    ...response.data.map(item => {
                        return {
                            ...item,
                            id: item.value,
                        };
                    })
                ];

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>