<template>
    <atbl-form-group :horizontal="horizontal"
                     label="Tags"
                     label-for="tags"
                     class="font-weight-bold"
    >
        <tree-select v-model="selectedTags"
                     :multiple="true"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="tags"
                     :normalizer="(node) => ({id: node.id, label: node.name})"
                     :disabled="!!disabled || !!isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
                     id="tags"
        />
    </atbl-form-group>
</template>

<script>
import TagService from '../../../services/TagService';

export default {
    name: 'tags-picker',
    emits: [
        'input',
        'update',
    ],
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'tags'
        }
    },
    data() {
        return {
            tags: [],
            isLoading: false,
        };
    },
    computed: {
        selectedTags: {
            get() {
                return !this.tags.length ? [] : this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
    async created() {
        await this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            this.isLoading = true;

            const response = await TagService.fetchAll();

            this.tags = response.data

            this.isLoading = false;
        },
    },
}
</script>