/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import ExchangeOfficeService from './services/ExchangeOfficeService';

require('./bootstrap');

import CoreuiVue from '@coreui/vue';

import Acl from "./modules/acl/Acl";
import CopyToClipBoard from "./modules/clipboard/CopyToClipBoard";
import store from "./store";
import Object from "./mixins/Object";
import Constants from "./mixins/Constants";
import Global from "./mixins/Global";
import StringMixin from "./mixins/String";
import ErrorsMixin from "./mixins/Errors";
import NumbersMixin from "./mixins/Numbers";
import DateMixin from "./mixins/Date";
import ArrayMixin from "./mixins/Array";
import BooleanMixin from "./mixins/Boolean";
import UrlMixin from "./mixins/Url";
import WalletMixin from "./mixins/Player/Wallet";
import EmptyField from "./filters/EmptyField";
import Capitalize from "./filters/Capitalize";
import FormatCurrency from "./filters/FormatCurrency";
import WithoutFraction from "./filters/WithoutFraction";
import DateFormat from "./filters/DateFormat";
import UtcToLocal from "./filters/UtcToLocal";
import TreeSelect from '@riophae/vue-treeselect';
import Elipsis from './utils/Text/Elipsis';
import VueAudio from 'vue-audio-better';
import ImageService from './services/ImageService';

import sortDirective from './directives/sort';
import ClickOutside from './directives/click-outside';
import tooltip from './directives/tooltip';
import popover from './directives/popover';

Vue.directive('sort', sortDirective);
Vue.directive('click-outside', ClickOutside);
Vue.directive('tooltip', tooltip);
Vue.directive('popover', popover);

Vue.mixin(Constants);
Vue.mixin(Object);
Vue.mixin(Global);
Vue.mixin(ArrayMixin);
Vue.mixin(StringMixin);
Vue.mixin(ErrorsMixin);
Vue.mixin(NumbersMixin);
Vue.mixin(BooleanMixin);
Vue.mixin(DateMixin);
Vue.mixin(UrlMixin);
Vue.mixin(WalletMixin);

Vue.filter("emptyField", EmptyField);
Vue.filter("capitalize", Capitalize);
Vue.filter("formatCurrency", FormatCurrency);
Vue.filter("withoutFraction", WithoutFraction);
Vue.filter("dateFormat", DateFormat);
Vue.filter("UtcToLocal", UtcToLocal);
Vue.filter("elipsis", Elipsis);

Vue.use(CoreuiVue);
Vue.use(Acl);
Vue.use(CopyToClipBoard);
Vue.use(VueAudio);

Vue.prototype.$imageService = new ImageService;

// components
Vue.component('tree-select', TreeSelect);

//layout
Vue.component('main-header', require('./components/Layout/Header/Header.vue').default);
Vue.component('main-footer', require('./components/Layout/Footer/Footer.vue').default);
Vue.component('sidebar', require('./components/Layout/Sidebar/Sidebar.vue').default);
Vue.component("player-layout", require("./pages/Players/Components/Layout/PlayerLayout").default);

// forms components
Vue.component('atbl-form-group', require('./components/Forms/Holders/ATBLFormGroup').default);
Vue.component('balance-select', require('./components/Forms/Select/BalanceSelect').default);
Vue.component('atbl-switch', require('./components/Forms/Inputs/ATBLSwitch').default);
Vue.component('atbl-input', require('./components/Forms/Inputs/ATBLInput').default);
Vue.component('atbl-select', require('./components/Forms/Inputs/ATBLSelect').default);
Vue.component('atbl-number', require('./components/Forms/Inputs/ATBLNumber').default);
Vue.component('atbl-textarea', require('./components/Forms/Inputs/ATBLTextarea').default);
Vue.component("dispositions-select", require("./components/Forms/Select/DispositionsSelect").default);
Vue.component("twilio-phone-numbers", require("./components/Forms/Select/TwilioPhoneNumbers").default);

// other
Vue.component('nav-item-text', require('./components/Nav/NavItemText').default);
Vue.component('atbl-pagination', require('./components/AtblPagination.vue').default);
Vue.component("show-per-page", require("./components/Forms/Select/ShowPerPage").default);
Vue.component("multi-agents-picker", require("./components/Forms/Select/MultiAgentsPicker").default);
Vue.component("multi-players-date-types-picker", require("./components/Forms/Select/MultiPlayersDateTypesPicker").default);
Vue.component("multi-games-picker", require("./components/Forms/Select/MultGamesPicker").default);
Vue.component("transaction-badge", require("./components/Transactions/TransactionBadge").default);
Vue.component("withdrawals-status-badge", require("./components/Transactions/WithdrawalsStatusBadge").default);
Vue.component("created-by-badge", require("./components/Transactions/CreatedByBadge").default);
Vue.component("multi-date-picker", require("./components/Forms/Select/MultiDatePicker").default);
Vue.component("date-picker", require("./components/Forms/Select/DatePicker").default);
Vue.component("loader", require("./components/Widgets/Loader").default);
Vue.component("loader-line", require("./components/Widgets/LoaderLine").default);
Vue.component("horizontal-line", require("./components/Widgets/HorizontalLine").default);
Vue.component("tel-number", require("./components/Forms/Inputs/TelNumber").default);
Vue.component("sidebar-sub", require("./components/SidebarSub/SidebarSub").default);
Vue.component("audio-player", () => import("./components/Widgets/AudioPlayer"));
Vue.component("agent-picker", require("./components/Forms/Select/AgentsSinglePicker").default);
Vue.component("payment-types-select", require("./components/Forms/Select/PaymentTypesOptions").default);
Vue.component("campaigns-status-picker", require("./pages/Marketing/_Components/CampaignStatusPicker/CampaignStatusPicker").default);
Vue.component("blocked-sign", require("./components/Layout/BlockedSign/BlockedSign").default);

Vue.component('dashboard', require('./pages/Dashboard/Dashboard.vue').default);
Vue.component('notifications', require('./pages/Notifications/Notifications.vue').default);
Vue.component('shifts', require('./pages/Shifts/Shift.vue').default);
Vue.component('notes', require('./pages/Notes/Note.vue').default);
Vue.component('roles', require('./pages/Roles/List/Roles').default);
Vue.component('roles-manager', require('./pages/Roles/Manager/RolesManager').default);
Vue.component('permissions', require('./pages/Permissions/List/Permission.vue').default);
Vue.component('permissions-manager', require('./pages/Permissions/Manager/PermissionsManager').default);
Vue.component('users', require('./pages/Users/User.vue').default);
Vue.component('players', require('./pages/Players/Player.vue').default);
Vue.component('playerView', require('./pages/Players/Player/View/PlayerProfile.vue').default);
Vue.component('playerNote', require('./pages/Players/Player/Notes/PlayerNote').default);
Vue.component('playerTickets', require('./pages/Players/Player/Tickets/PlayerTickets').default);
Vue.component('playerRaffleTickets', require('./pages/Players/Player/RaffleTickets/PlayerRaffleTickets').default);
Vue.component('playerSyndicateShares', require('./pages/Players/Player/SyndicateShares/PlayerSyndicateShares').default);
Vue.component('playerBundleTickets', require('./pages/Players/Player/BundleTickets/PlayerBundleTickets').default);
Vue.component('playerBonuses', require('./pages/Players/Player/Bonuses/PlayerBonuses').default);
Vue.component('playerDispositions', require('./pages/Players/Player/Dispositions/PlayerDispositions').default);
Vue.component('player-blocks', require('./pages/Players/Player/Blocks/PlayerBlocks.vue').default);
Vue.component('playerKyc', require('./pages/Players/Player/Kyc/PlayerKyc').default);
Vue.component("player-card-section", require("./pages/Players/Components/Layout/CardSection").default);
Vue.component('playerMessage', require('./pages/Players/Player/Messages/PlayerMessage.vue').default);
Vue.component('player-orders', require('./pages/Players/Player/Orders/PlayerOrders').default);
Vue.component('order', require('./pages/Orders/Preview/Order').default);
Vue.component('orders', require('./pages/Orders/List/Orders').default);
Vue.component('playerTransactions', require('./pages/Players/Player/Transactions/PlayerTransactions.vue').default);
Vue.component('playerFunds', require('./pages/Players/Player/Funds/PlayerFunds.vue').default);
Vue.component('playerWithdrawals', require('./pages/Players/Player/Withdrawals/PlayerWithdrawals.vue').default);
Vue.component('playerPlay', require('./pages/Players/Player/Play/PlayerPlay.vue').default);
Vue.component('playerCall', require('./pages/Players/Player/Call/PlayerCall.vue').default);
Vue.component('playerLoginHistory', require('./pages/Players/Player/LoginHistory/PlayerLoginHistory.vue').default);
Vue.component('playerAgentsHistory', require('./pages/Players/Player/AgentsHistory/PlayerAgentsHistory.vue').default);
Vue.component('tickets', require('./pages/Tickets/List/Tickets.vue').default);
Vue.component('ticket', require('./pages/Tickets/Preview/Ticket.vue').default);
Vue.component('raffle-index', require('./pages/RaffleTickets/List/RaffleTickets').default);
Vue.component('raffle-ticket', require('./pages/RaffleTickets/Preview/RaffleTicket').default);
Vue.component('syndicate-shares-index', require('./pages/SyndicateShares/List/SyndicateShares').default);
Vue.component('syndicate-share-ticket', require('./pages/SyndicateShares/Preview/SyndicateShare').default);
Vue.component('bundle-tickets-index', require('./pages/BundleTickets/List/BundleTickets').default);
Vue.component('bundle-ticket', require('./pages/BundleTickets/Preview/BundleTicket').default);
Vue.component('bonus-player-index', require('./pages/PlayerBonuses/List/PlayerBonuses').default);
Vue.component('dispositions', require('./pages/Dispositions/Dispositions').default);
Vue.component('transactions', require('./pages/Transactions/List/Transactions.vue').default);
Vue.component('transaction', require('./pages/Transactions/Preview/Transaction.vue').default);
Vue.component('reports-agents', require('./pages/Reports/Agents/Agents').default);
Vue.component('reports-agent-deposits', require('./pages/Reports/Agents/Deposits').default);
Vue.component('reports-games', require('./pages/Reports/Games/Games').default);
Vue.component('reports-sales', require('./pages/Reports/Sales/Sales').default);
Vue.component('reports-sales-days', require('./pages/Reports/Sales/SalesDays').default);
Vue.component('reports-agents-details', require('./pages/Reports/Agents/AgentsDetails').default);
Vue.component('reports-agents-groups', require('./pages/Reports/Agents/AgentsGroups').default);
Vue.component('reports-players', require('./pages/Reports/Players/ReportPlayers').default);
Vue.component('reports-new-players', require('./pages/Reports/NewPlayers/NewPlayersSummary/ReportNewPlayersSummary').default);
Vue.component('reports-new-players-details', require('./pages/Reports/NewPlayers/NewPlayersDetails/NewPlayersDetails').default);
Vue.component('rates-conversions', require('./pages/Rates/Conversions/RatesConversions').default);
Vue.component('not-found', require('./components/Lists/ItemsNotFound').default);
Vue.component('confirmable-remove-button', require('./components/Lists/RemoveListItemButton').default);
Vue.component('confirmable-button', require('./components/Buttons/ConfirmableButton').default);
Vue.component('stat-card', require('./pages/Dashboard/Components/StatisticsCard').default);
Vue.component('multi-stat-card', require('./pages/Dashboard/Components/MultiStatisticsCard').default);
Vue.component('small-stat-card', require('./pages/Dashboard/Components/SmallStatisticsCard').default);
Vue.component('multi-value-stat-card', require('./pages/Dashboard/Components/MultiValueStatisticsCard').default);
Vue.component('extra-small-stat-card', require('./pages/Dashboard/Components/ExtraSmallStatisticsCard').default);
Vue.component('player-credit-cards', require('./pages/Players/Player/CreditCards/CreditCards').default);
Vue.component('player-birthdays', require('./pages/Players/Player/Birthdays/PlayerBirthdays').default);
Vue.component('payments-settings', require('./pages/PaymentSettings/PaymentSettingsView').default);
Vue.component('settings', require('./pages/Settings/Settings').default);
Vue.component('withdrawals', require('./pages/Withdrawals/Withdrawals').default);
Vue.component('games-draws', require('./pages/Games/Draws/GamesDraws').default);
Vue.component('games-draws-review', require('./pages/Games/DrawReview/List/DrawReview.vue').default);
Vue.component('games-draws-review-details', require('./pages/Games/DrawReview/Details/DrawReviewDetails.vue').default);
Vue.component('leads', require('./pages/Marketing/Leads/List/Leads').default);
Vue.component('leads-details', require('./pages/Marketing/Leads/Details/LeadsDetails').default);
Vue.component('campaign-leads-statuses', require('./pages/Marketing/Statuses/LeadsStatuses').default);
Vue.component('campaigns', require('./pages/Marketing/Campaigns/List/Campaigns').default);
Vue.component('campaigns-edit-component', require('./pages/Marketing/Campaigns/Editor/CampaignsEditComponent').default);
Vue.component('campaigns-call-view-page', require('./pages/Marketing/Campaigns/Call/CallView/CampaignsCallViewPage').default);
Vue.component('campaigns-list', require('./pages/Marketing/Campaigns/Call/ListView/CampaignsListView').default);
Vue.component('campaigns-view', require('./pages/Marketing/Campaigns/View/CampaignsView').default);
Vue.component('call-history', require('./pages/CallHistory/CallHistoryPage').default);
Vue.component('player-call-history', require('./pages/Players/Player/CallHistory/CallHistory').default);
Vue.component('player-manage-wallet', require('./pages/Players/Player/ManageWallet/ManageWallet').default);
Vue.component('callback', require('./pages/Callback/Callback').default);
Vue.component('game-price', require('./pages/Games/GamePrice/GamePrice').default);
Vue.component('player-free-games', require('./pages/Players/Player/FreeGames/FreeGames').default);
Vue.component('free-games', require('./pages/Games/FreeGames/FreeGames').default);
Vue.component('user-free-games-settings', require('./pages/Games/FreeGamesSettings/UserFreeGamesSettings').default);
Vue.component('user-manage-account', require('./pages/ManageAccount/ManageAccount').default);

Vue.component('kyc-submissions', require('./pages/KycSubmissions/KycSubmissions').default);

Vue.component('syndicate-list', require('./pages/Syndicates/List.vue').default);
Vue.component('syndicate-edit', require('./pages/Syndicates/Edit.vue').default);

Vue.component('bundle-list', require('./pages/Bundles/List.vue').default);
Vue.component('bundle-edit', require('./pages/Bundles/Edit.vue').default);

Vue.component('discounts', require('./pages/Discounts/Discounts.vue').default);

Vue.component('bonus-list', require('./pages/Bonuses/List.vue').default);
Vue.component('bonus-create', require('./pages/Bonuses/CreateEdit.vue').default);

Vue.component('logs', require('./pages/Logs/Logs.vue').default);
Vue.component('log-view', require('./pages/Logs/LogView.vue').default);

Vue.component('presentation-agents-sales', require('./pages/Presentations/AgentsSales/AgentSales').default);

Vue.component('email-templates', require('./pages/EmailTemplates/EmailTemplates').default);
Vue.component('phone-numbers', require('./pages/PhoneNumbers/PhoneNumbers').default);

Vue.component('my-affiliate', require('./pages/MyAffiliate/MyAffiliate').default);

Vue.component('capture-payment', require('./pages/Payments/CapturePayment').default);
Vue.component('status-payment', require('./pages/Payments/StatusPayment').default);
Vue.component('capture-authorize', require('./pages/Payments/CaptureAuthorize').default);
Vue.component('t-processing', require('./pages/Transactions/Processing/TransactionProcessing.vue').default);
Vue.component('ft-processing', require('./pages/Transactions/Processing/FundsTransactionProcessing.vue').default);

Vue.component('flash', require('./components/Flash.vue').default);

Vue.component('commission-eligible-transactions-single-day', require('./pages/Commissions/EligibleTransactions/SingleDay/CommissionEligibleTransactionsSingleDayPage.vue').default);
Vue.component('commission-eligible-transactions-day-groups', require('./pages/Commissions/EligibleTransactions/DayGroups/CommissionEligibleTransactionsDayGroupsPage.vue').default);

Vue.config.productionTip = false;

Vue.config.devtools = true;

const path = window.location.hostname;

if (path === 'app.allthebestlottos.com') {
	Vue.prototype.$pgPath = 'https://oppwa.com/'
} else {
	Vue.prototype.$pgPath = 'https://test.oppwa.com/'
}

const app = new Vue({
	el: '#app',
	store,
	created: function () {
		if(!this.isUserAuthenticated()) {
			return;
		}

		this.fetchDefaultCurrency();
	},
	
	methods: {
		isUserAuthenticated() {
			return !!window.layer;
		},
		
		fetchDefaultCurrency() {
			if(!ExchangeOfficeService.isCached()) {
				this.$store.dispatch('exchangeOffice/fetchDefaultCurrency').then((response) => {
					ExchangeOfficeService.cache({ currencyCode: response.data.code, currencySymbol: response.data.symbol });
				});
			}else{
				this.$store.commit('exchangeOffice/setDefaultCurrency', ExchangeOfficeService.getCached());
			}
		},
	},
});