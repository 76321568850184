<template>
    <atbl-modal title="Import leads"
             :show="true"
             :close-on-backdrop="false"
             :class="{
                 'hide-close': !!isLoading,
             }"
             @close="onModalCancel"
    >

        <input type="file" id="file" ref="file" v-on:change="setImportFile($refs.file.files[0])" />

        <template #footer>
            <div class="d-flex w-100 align-items-center">
                <div class="w-50 font-weight-bold">
                    Files supported: xls, xlsx, csv
                </div>
                <div class="w-50 text-right">
                    <button class="btn btn-sm btn-warning"
                              v-if="!isObjectEmpty(errors)"
                              @click="toggleErrorsModal"
                    >
                        Errors Log
                    </button>
                    <button class="btn btn-sm btn-danger"
                              :disabled="!!isLoading"
                              @click="onModalCancel"
                    >
                        Close
                    </button>
                    <button v-if="$can('Leads - Import')"
                            :class="{
                                'btn-primary': !isLoading,
                                'btn-outline-primary': isLoading
                            }"
                            :disabled="!isUploadDisabled"
                            class="btn"
                            @click="onImportLeads"
                    >
                        <span v-if="!isLoading"
                        >
                            Import
                        </span>
                        <loader type="small"
                                :show="isLoading"
                        />
                    </button>
                </div>
            </div>
        </template>
    </atbl-modal>
</template>

<script>
import NamespaceConst from '../../../../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

const {
    mapGetters,
    mapMutations,
    mapActions
} = createNamespacedHelpers(NamespaceConst.marketing);

export default {
    name: 'import-leads-modal',
    inject: [
        'setImportFile',
        'toggleImportModal',
        'toggleErrorsModal',
        'onImportLeads',
    ],
	components: {
		AtblModal,
	},
    props: {
        errors: {
            type: Object,
            default: () => ({})
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        file: {
            type: File,
            default: null
        },
    },
    computed: {
        isUploadDisabled() {
            if (this.isLoading) {
                return false;
            }

            return !!this.file;
        },
    },
    methods: {
        onModalCancel() {
            this.toggleImportModal();
            this.clearFileValue();
        },
        clearFileValue() {
            this.$refs.file.value = '';
        },
    },
    watch: {
        file(value) {
            if(!!value) {
                return;
            }

            this.clearFileValue();
        },
    },
}
</script>