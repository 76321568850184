<template>
    <div>
        <span class="transaction-badge" :class="getBadge">{{ transaction.payment_type_beauty }}</span>
        <br v-if="reason.length" />
        <small v-if="reason.length" class="d-block text-muted my-1">{{ reason }}</small>
        <br />
        <span :class="['badge', `badge-${statusBadgeColor}`]">
            {{ transaction.status | capitalize }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'badge',
    props: {
        transaction: {
            type: Object,
            required: true
        }
    },
    computed: {
        getBadge() {
            let classText = '';

            switch (this.transaction.payment_type) {
                case 'CPCP':
                case 'CACP':
                    classText = 'b-red';
                    break;
                case 'CPFD':
                case 'CAFD':
                    classText = 'b-blue';
                    break;
                case 'CPWP':
                case 'CAWP':
                    classText = 'wallet';
                    break;
                case 'CAHP':
                case 'CPHP':
                    classText = 'mixed';
                    break;
                case 'CSWN':
                    classText = 'f-green';
                    break;
                case 'CSVD':
                case 'CAVD':
                    classText = 'f-purple';
                    break;
                case 'CABD':
                case 'CSBD':
                    classText = 'bg-dark text-white';
                    break;
                case 'CMWD':
                    classText = 'f-pink';
                    break;
                case 'CSVC':
                    classText = 'f-orange';
                    break;
                case 'CMRF':
                    classText = 'f-brown';
                    break;
                default:
                    classText = 'b-blue';
                    break;
            }

            return classText;
        },
        reason() {
            switch (this.transaction.reason) {
                case 'interchange-reject':
                    return 'Interchange reject';
            }

            return '';
        },
        statusBadgeColor() {
            switch (this.transaction.status) {
                case 'completed':
                    return 'success';
                case 'failed':
                    return 'danger';
                case 'pending':
                    return 'dark';
                default:
                    return 'light';
            }
        }
    }
}
</script>

<style>
.transaction-badge {
    display            : inline-block;
    padding            : 0.25em 0.4em;
    font-size          : 75%;
    font-weight        : 700;
    line-height        : 1;
    text-align         : center;
    white-space        : nowrap;
    vertical-align     : baseline;
    -webkit-transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition         : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition         : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition         : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.f-purple {
    background-color : #c61197;
    color            : #ffffff;
}

.f-pink {
    background-color : #dd57b8;
    color            : #ffffff;
}

.f-green {
    background-color : #31a846;
    color            : #ffffff;
}

.b-red {
    background-color : #ce2033;
    color            : #ffffff;
}

.b-blue {
    background-color : #29c6f4;
    color            : #ffffff;
}

.mixed {
    color            : #ffffff;
    background-color : #6c757d;
}

.wallet {
    color            : #212529;
    background-color : #ffc107;
}

.f-orange {
    color            : #ffffff;
    background-color : #ff8707;
}

.f-brown {
    color            : #ffffff;
    background-color : #814610;
}
</style>
