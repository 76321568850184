/**
 * Returns the rounded value of val to specified precision (number of digits after the decimal point).
 * precision can also be negative or zero (default).
 * @param int|float number
 * The value to round
 *
 * @param int precision
 * The optional number of decimal digits to round to.
 *
 * @return float {number}
 */
export default function (number, precision){
    const numberSign = number >= 0 ? 1 : -1;

    const value = Math.round((number * Math.pow(10, precision)) + (numberSign * 0.0001)) / Math.pow(10, precision);

    return parseFloat(value.toFixed(precision));
}