<template>
    <sidebar-sub :value="show"
                 :loader="isLoading"
                 title="Add Lead"
                 class="left leadsAddRemoveModal medium"
                 @input="toggleAddLeadsSidebar"
    >
        <div class="campaign-add-leads-section-header">
            <div v-if="!!leadsList.length">
                <input v-model="allSelected"
                       v-tooltip="{
                           content: 'Select all',
                           placement: 'bottom'
                       }"
                       type="checkbox"
                       class="checkbox-leads"
                       @click="onSelectedAllLeadsEvent"
                />
            </div>
            <div>
                <button v-if="!!isLeadCampaign()"
                        :class="{
                            'btn-outline-primary': showFilter,
                            'btn-primary': !showFilter
                        }"
                        class="btn btn-sm m-0 mr-2"
                        @click="showFilter = !showFilter"
                >
                    <i class="fa fa-filter" aria-hidden="false"></i>
                </button>
                <button v-text="leadsListModel.inCampaign ? 'Remove Selected' : 'Add Selected'"
                        :class="{
                            'btn-primary': !leadsListModel.inCampaign,
                            'btn-danger': leadsListModel.inCampaign
                        }"
                        :disabled="!leadsIds.length || isLoading"
                        class="btn btn-sm"
                        @click="onAddSelectedLeads(leadsIds, campaignId)"
                >
                </button>
                <button v-if="!!isLeadCampaign()"
                        :disabled="!leadsList.length || !!isLoading"
                        class="btn btn-sm btn-danger ml-2"
                        @click="toggleConfirmAddAllLeadsModal"
                >
                    {{ leadsListModel.inCampaign ? 'Remove All' : 'Add All' }}
                </button>
            </div>
        </div>

        <leads-filter-form-component v-if="!!isLeadCampaign()"
                                     v-show="showFilter"
                                     :model="leadsListModel"
        />

        <div v-if="!isLoading && !!leadsList.length"
             class="campaign-add-leads-section-list"
        >
            <div v-for="(item, index) in leadsList"
                 :key="index"
                 class="campaign-add-leads-section-list-item"
            >
                <div class="campaign-add-leads-section-list-item-header">
                    <label :for="`checkBox${item.id}`">
                        <input v-model="leadsIds"
                               :value="item.id"
                               :id="`checkBox${item.id}`"
                               type="checkbox"
                               class="checkbox-leads"
                               @click="allSelected = false"
                        />
                        <a v-tooltip="`Copy to clipboard`"
                           href="#"
                           @click.prevent="copyToClipboard(item.id)"
                        >
                            #{{ item.id }}
                        </a>
                        <b>/ {{ item.name }} {{ item.surname }}</b>
                    </label>
                    <button v-if="!leadsListModel.inCampaign"
                            v-tooltip="`Add customer`"
                            class="button-add"
                            @click="addLeadToCampaign(item, campaignId, campaignType)"
                    >
                        <i class="fa fa-plus"></i>
                    </button>
                    <button v-if="leadsListModel.inCampaign"
                            v-tooltip="`Remove customer`"
                            class="button-remove"
                            @click="removeLeadOutOfCampaign(item, campaignId, campaignType)"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <div class="campaign-add-leads-section-list-item-body">
                    <div>
                        <span class="small-mb-badge show m-0">Last Disposition</span>
                        <div v-if="item.status_name && item.status_date">
                            <span v-if="item.status_name"
                                  class="badge badge-success"
                            >
                                {{ item.status_name }}
                            </span>
                            <br />
                            <small>{{ item.status_date }}</small>
                        </div>
                        <span v-else>/</span>
                    </div>
                    <div>
                        <span class="small-mb-badge show m-0">Country</span>
                        {{ emptyField(item.country) }}
                    </div>
                    <div>
                        <span class="small-mb-badge show m-0">Live Spent</span>
                        {{
                            !!item.live_spent
                                ? (
                                    item.live_spent === 0
                                        ? 0
                                        : formatCurrency(item.live_spent)
                                )
                                : '/'
                        }}
                    </div>
                </div>
                <div class="campaign-add-leads-section-list-item-footer">
                    <div v-if="!!item.campaigns.length"
                         class="campaigns"
                    >
                        <span>Campaigns</span>
                        <div>
                            <span v-for="(campaign, index) in item.campaigns"
                                  :key="index"
                                  class="badge badge-primary ml-1"
                            >
                                {{ campaign.title }}
                            </span>
                        </div>
                    </div>
                    <div v-if="item.batch"
                         class="batch"
                    >
                        <span>Batch</span>
                        {{ item.batch }}
                    </div>
                </div>
            </div>
        </div>

        <loader :show="isLoading"
                class="mt-2"
                type="small"
        />

        <not-found v-if="!isLoading"
                   :items="leadsList"
                   class="w-100 m-0"
                   msg="No leads found."
        />

        <nav v-if="!isLoading && leadsListResponse.last_page > 1"
             class="wrap mt-3 mx-0"
        >
            <atbl-pagination :small="true"
                             :pagination="leadsListResponse"
                             class="m-0"
                             @paginate="fetchLeadsOutOfCampaign(campaignId, $event)"
            />
        </nav>
    </sidebar-sub>
</template>

<script>
import LeadsFilterFormComponent from './LeadsFilterForm/LeadsFilterFormComponent';
import PaginationModel from '../../../../../modules/pagination/PaginationModel';
import CustomersOutOfCampaignSearchModel from './CustomersOutOfCampaign/CustomersOutOfCampaignSearchModel';
import formatCurrency from '../../../../../filters/FormatCurrency';
import emptyField from '../../../../../filters/EmptyField';

export default {
    name: 'add-customers-sidebar-component',
    inject: [
        'campaignId',
        'campaignType',
        'isLeadCampaign',
        'addLeadToCampaign',
        'removeLeadOutOfCampaign',
        'fetchLeadsOutOfCampaign',
        'toggleAddLeadsSidebar',
        'toggleConfirmAddAllLeadsModal',
    ],
    components: {
        LeadsFilterFormComponent,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        leadsListResponse: {
            type: Object,
            default: () => (PaginationModel)
        },
        leadsListLoader: {
            type: Boolean,
            default: false
        },
        leadsListModel: {
            type: Object,
            default: () => (CustomersOutOfCampaignSearchModel)
        },
    },
    data() {
        return {
            allSelected: false,
            leadsIds: [],
            showFilter: false,
        };
    },
    computed: {
        isLoading() {
            return this.leadsListLoader;
        },
        leadsList() {
            return this.leadsListResponse.data;
        },
    },
    methods: {
        emptyField,
        formatCurrency,
        onSelectedAllLeadsEvent() {
            let selectedLeadIds = [];

            if (!this.allSelected) {
                this.allSelected = true;

                selectedLeadIds = this.leadsList.map(lead => lead.id);
            }

            this.leadsIds = selectedLeadIds;
        },
        async onAddSelectedLeads() {
            const status = await this.addLeadToCampaign(this.leadsIds, this.campaignId, this.campaignType);

            if (!status) {
                return;
            }

            this.leadsIds = [];
        },
    }
}
</script>