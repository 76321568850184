<template>
    <div v-if="hasPlayerFilledProfileData">
        <div class="player-play-games-holder">
            <div v-for="bundle in bundles" :key="`bundle_${bundle.id}`" class="game bundle">
                <div class="img-holder">
                    <img v-for="bundleGame in bundle.bundle_games" :src="$imageService.gameImage(bundleGame.game.image)">
                </div>

                <div class="game-play">
                    <p :title="bundle.title" class="d-block">{{ bundle.title }}</p>

                    <button @click="selectBundle(bundle)" class="btn btn-sm btn-primary">
                        <span>Play</span>
                    </button>
                </div>
            </div>
        </div>

        <not-found v-if="!isLoading"
                   :items="bundles"
                   msg="No bundles found."
        />

        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import BundleService from '../../../../../../services/BundleService';

export default {
    name: 'bundles',
    data() {
        return {
            bundles: [],
            isLoading: false
        };
    },
    computed: {
        hasPlayerFilledProfileData() {
            return this.$store.getters['cart/getPlayerCanPlayGame'];
        },
        orderId() {
            return this.$store.getters['order/getOrderId'];
        },
    },
    mounted() {
        this.fetchBundles();
    },
    methods: {
        fetchBundles() {
            this.isLoading = true;
            BundleService
                .availableBundleList()
                .then(response => {
                    this.bundles = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        selectBundle(bundle) {
            if (this.orderId) {
                this.$store.commit('cart/setClearCartData');
            }

            this.$store.commit('cart/setShowGame', false);
            this.$store.commit('cart/setCheckout', { checkout: false });

            setTimeout(() => {
                this.$store.commit('cart/setShowGame', true);
                this.$store.commit('games/setGameConfigurationData', bundle);
            }, 150);
        },
    },
}
</script>