<template>
    <div class="card border-0 m-0">
        <div class="card-body p-3">
            <div class="general-list">
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0">ID</div>
                    <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">Client</div>
                    <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">Agent</div>
                    <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">Call Status & Date</div>
                    <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">Talk Time</div>
                    <div v-if="$can('Call History - Recordings')"
                         class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    >
                        Audio
                    </div>
                </div>

                <template v-if="!isLoading">
                    <div v-for="(item, index) in callHistoryList"
                         :key="index"
                         class="row"
                    >
                        <div class="col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">ID</span>
                            {{ item.id }}
                        </div>
                        <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Client</span>
                            <span v-if="item.callable && isset(item.callable, 'name') && isset(item.callable, 'surname')">
		                        {{ item.callable.name }} {{ item.callable.surname }}
		                    </span>
                            <span v-else>/</span>
                            <br />
                            <small v-if="item.callable && isset(item.callable, 'id')">
                                <strong>ID:</strong> {{ item.callable.id }}
                                <strong>TYPE:</strong> {{ item.callable_type.split('\\')[1] }}
                            </small>
                        </div>
                        <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Agent</span>
                            <span v-if="item.user && isset(item.user, 'name')">
		                        {{ item.user.name }}
		                    </span>
                            <span v-else>/</span>
                        </div>
                        <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Call Status & Date</span>
                            <call-status-badge :title="item.status" />
                            <br>
                            <small>{{ utcToLocal(item.created_at) }}</small>
                        </div>
                        <div class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Talk Time</span>
                            {{ emptyField(item.duration) }}
                        </div>
                        <div v-if="$can('Call History - Recordings')"
                             class="col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                        >
                            <span class="small-mb-badge">Audio</span>
                            <button class="audio-link"
                                    v-tooltip="{
                                        content: 'Recordings'
                                    }"
                                    @click="showCallRecordings(item.id)"
                            >
                                <i class="fa fa-file-audio" aria-hidden="false"></i>
                            </button>
                        </div>
                    </div>
                </template>

                <loader :show="isLoading"
                        type="small"
                />

                <not-found v-if="!isLoading"
                           :items="callHistoryList"
                           msg="No call history found."
                />
            </div>
        </div>

        <div v-if="!isLoading && statistics.last_page > 1" class="card-footer">
            <nav class="wrap">
                <atbl-pagination class="m-0"
                                 :pagination="statistics"
                                 @paginate="fetchGeneralCallStatistics(callHistorySearchModel, agentId, $event)"
                />
            </nav>
        </div>
    </div>
</template>

<script>
import CallStatusBadge from '../_Components/CallStatusBadge';
import PaginationModel from '../../../modules/pagination/PaginationModel';
import CallHistorySearchModel from '../CallHistoryFilterForm/CallHistorySearchModel';
import emptyField from '../../../filters/EmptyField';
import utcToLocal from '../../../filters/UtcToLocal';
import { isset } from '../../../utils/Object/Object';

export default {
    name: 'call-history-list',
    inject: [
        'agentId',
        'fetchGeneralCallStatistics',
        'showCallRecordings',
    ],
    components: {
        CallStatusBadge,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        statistics: {
            type: Object,
            default: () => (PaginationModel)
        },
        callHistorySearchModel: {
            type: Object,
            default: () => (CallHistorySearchModel)
        },
    },
    computed: {
        callHistoryList() {
            return this.statistics.data;
        },
    },
    methods: {
        emptyField,
        utcToLocal,
        isset,
    },
}
</script>