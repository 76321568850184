<template>
  <section class="general-list">
    <div class="row sticky-element p-0">
      <div class="col-6 col-lg">No.</div>
      <div class="col-6 col-lg">
        <a href="#" @click="sortChange('name')" class="sort-link" :class="{'active': sort.sort === 'name'}">
          Name <span v-if="sort.sort === 'name'"><i class="fa"
                                                    :class="{'fa-caret-up': sort.value === 'ASC', 'fa-caret-down': sort.value === 'DESC'}"
        ></i> </span>
        </a>
      </div>
      <div class="col-6 col-lg">Number of Sales</div>
      <div class="col-6 col-lg">
        <a href="#" @click="sortChange('total')" class="sort-link" :class="{'active': sort.sort === 'total'}">
          Sales <span v-if="sort.sort === 'total'"><i class="fa"
                                                      :class="{'fa-caret-up': sort.value === 'ASC', 'fa-caret-down': sort.value === 'DESC'}"
        ></i> </span>
        </a>
      </div>
      <div class="col-6 col-lg">
        <a href="#" @click="sortChange('deposits')" class="sort-link" :class="{'active': sort.sort === 'deposits'}">
          Deposits <span v-if="sort.sort === 'deposits'"><i class="fa"
                                                            :class="{'fa-caret-up': sort.value === 'ASC', 'fa-caret-down': sort.value === 'DESC'}"
        ></i> </span>
        </a>
      </div>
      <div class="col-6 col-lg">
        <a href="#" @click="sortChange('bonus')" class="sort-link" :class="{'active': sort.sort === 'bonus'}">
          Bonus <span v-if="sort.sort === 'bonus'"><i class="fa"
                                                      :class="{'fa-caret-up': sort.value === 'ASC', 'fa-caret-down': sort.value === 'DESC'}"
        ></i> </span>
        </a>
      </div>
      <div class="col-6 col-lg">Percentage</div>
      <div v-if="$can('Reports Agents - View')"
           class="col-6 col-lg"
      >
        Actions
      </div>
    </div>

    <template>
      <div v-if="!isSearching"
           v-for="(agent, index) in agents"
           :key="agent.id"
           class="row"
      >
        <div class="col-6 col-lg">
          <span class="small-mb-badge">No.</span>
          {{ index + 1 }}
        </div>
        <div class="col-6 col-lg">
          <span class="small-mb-badge">Name</span>
          {{ agent.name }}
        </div>
        <div class="col-6 col-lg">
          <span class="small-mb-badge">Num. of Sales</span>
          {{ agent.count }}
        </div>
        <div class="col-6 col-lg">
          <span class="small-mb-badge">Sales</span>
          {{ total(agent.total) }}
        </div>
        <div class="col-6 col-lg">
          <span class="small-mb-badge">Deposits</span>
          {{ total(agent.deposits) }}
        </div>
        <div class="col-6 col-lg">
          <span class="small-mb-badge">Bonus</span>
          {{ total(agent.bonus) }}
        </div>
        <div class="col-6 col-lg">
          <span class="small-mb-badge">Percents</span>
          {{ percents(agent.total) }} %
        </div>
        <div class="col-12 col-lg"
             v-if="$can('Reports Agents - View')"
        >
          <span class="small-mb-badge">Actions</span>
          <a href="#" @click="openAgent($event, agent)">
            <i class="fa fa-eye"></i> View Agent
          </a>
        </div>
      </div>
    </template>

    <div v-if="!isSearching && agents.length"
         class="row general-list-row last font-weight-bold"
    >
      <div class="col-12 col-lg">
        <span class="small-mb-badge">Total</span>
        Total: {{ agents.length }}
      </div>
      <div class="d-none d-lg-flex col-lg">&nbsp;</div>
      <div class="col-12 col-lg">
        <span class="small-mb-badge">Total Sales Count</span>
        {{ sales.countAll }}
      </div>
      <div class="col-12 col-lg">
        <span class="small-mb-badge">Total Sales</span>
        {{ total(sales.totalAll) }}
      </div>
      <div class="col-12 col-lg">
        <span class="small-mb-badge">Total Deposits</span>
        {{ total(sales.totalDeposits) }}
      </div>
      <div class="col-12 col-lg">
        <span class="small-mb-badge">Total Bonus</span>
        {{ total(sales.totalBonus) }}
      </div>
      <div class="col-12 col-lg">
        <span class="small-mb-badge">Total Percents</span>
        {{ sales.totalSalesPercent }} %
      </div>
      <div class="d-none d-lg-flex col-lg">&nbsp;</div>
    </div>

    <not-found v-if="!isSearching"
               :items="agents"
               msg="No agents found."
    />

    <loader :show="isSearching"
            type="small"
    />
  </section>
</template>

<script>
	import FormatCurrency from '../../../../filters/FormatCurrency';

	export default {
		name: 'agents-list',
		props: {
			agents: {
				type: Array,
				require: true
			},
			sales: {
				type: Object,
				require: true
			},
			sort: {
				require: true
			},
			isSearching: {
				type: Boolean,
				require: true
			}
		},
		data() {
			return {
				grandTotal: 0
			};
		},
		methods: {
			total: function (total) {
				return `${FormatCurrency(total ? total : 0)}`;
			},
			percents: function (total) {
				let t = total ? total : 0;
				return t > 0 ? this.percent(t / this.sales.totalSales * 100) : 0;
			},
			sortChange: function (sort) {
				let sortBy = {
					sort: sort,
					value: this.sort.value === 'ASC' ? 'DESC' : 'ASC'
				};

				this.$emit('sorted', sortBy);
			},
			openAgent(e, agent) {
				e.preventDefault();
				this.$emit('open-agent', agent);
			}
		}
	};
</script>