import TypesConst from "../../TypesConst";

export default {
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_LIST]: state => state.notificationsList,
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_WIDGET_DATA]: state => state.notificationsWidgetData,
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_LIST_RESPONSE]: state => state.notificationsListResponse,
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_LIST_LOADER]: state => state.notificationsListLoader,
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_WIDGET_DATA_LOADER]: state => state.notificationsWidgetDataLoader,
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_READ_BAR]: state => state.notificationReadBar,
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_READ_BAR_LOADER]: state => state.notificationReadBarLoader,
    [TypesConst.notifications.getters.GET_NOTIFICATIONS_MODEL]: state => state.notificationModel,
};