var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.$can("Users - Create/Update")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary m-0",
                    on: { click: _vm.showModal }
                  },
                  [_vm._v("\n                Create\n            ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("ID")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Info")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Email")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Added")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Roles")]),
                _vm._v(" "),
                _vm.$canAny(_vm.usersOperationPermissions)
                  ? _c("div", { staticClass: "col-12 col-lg" }, [
                      _vm._v(
                        "\n                        Operations\n                    "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$can("Users - Create/Update")
                  ? _c("div", { staticClass: "col-12 col-lg" }, [
                      _vm._v(
                        "\n                        Is active\n                    "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._l(_vm.users, function(item, index) {
                return !_vm.isLoading
                  ? _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("ID")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.id) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Info")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: { content: "Shift" },
                                  expression: "{ content: 'Shift' }"
                                }
                              ],
                              staticClass: "badge",
                              class: {
                                "badge-info": !!item.shift_name,
                                "badge-danger": !item.shift_name
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.shift_name || "/") +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Email")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.email) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Added")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.utcToLocal(item.added)) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6 col-lg mb-1 mb-lg-0" },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Roles")
                          ]),
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.roles) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$canAny(_vm.usersOperationPermissions)
                        ? _c("div", { staticClass: "col-12 col-lg" }, [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _vm.$can("Users - Create/Update")
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-warning",
                                    on: {
                                      click: function($event) {
                                        return _vm.onEditEvent(item.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Edit\n                        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$can("Users - Remove")
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger",
                                    on: {
                                      click: function($event) {
                                        return _vm.onVisibilityChange(
                                          item.id,
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Delete\n                        "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$can("Users - Create/Update")
                        ? _c(
                            "div",
                            { staticClass: "col-12 col-lg" },
                            [
                              item.id !== _vm.authUserId
                                ? _c(
                                    "span",
                                    { staticClass: "small-mb-badge" },
                                    [_vm._v("Is active")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$can("Users - Create/Update") &&
                              item.id !== _vm.authUserId
                                ? _c("atbl-switch", {
                                    attrs: {
                                      color: "success",
                                      value: !!item.is_active,
                                      name: item.name,
                                      off: false,
                                      on: true
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.onUserActiveChange(
                                          $event,
                                          item
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: { items: _vm.users, msg: "No users found" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.$can("Users - Create/Update")
        ? _c("create-update-user-form", {
            attrs: {
              show: _vm.isCreateModalVisible,
              "current-user-id": _vm.currentUserId
            },
            on: { cancel: _vm.onCancelEvent, reload: _vm.getUsers }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Users - Remove")
        ? _c("delete-user-form", {
            attrs: {
              show: _vm.isConfirmDeleteOpen,
              "current-user-id": _vm.currentUserId
            },
            on: {
              "on-visibility-change": _vm.onVisibilityChange,
              reload: _vm.getUsers
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-align-justify" }),
      _vm._v(" Users")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }