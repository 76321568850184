var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid commission-eligible-transactions-single-day"
    },
    [
      !_vm.isLoading
        ? [
            _c("div", { staticClass: "card card-list" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex align-items-center justify-content-between"
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [_vm._v("Date:")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.filters.dateFrom) +
                        " - " +
                        _vm._s(_vm.filters.dateTo) +
                        "\n\t\t\t\t"
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "general-list" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6 col-lg mb-2 mb-md-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("ID")
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.user.id) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6 col-lg mb-2 mb-md-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Name")
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.user.name) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6 col-lg mb-2 mb-md-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Total Deposits")
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.formatCurrency(
                                _vm.user.commission.total_sales
                              )
                            ) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Commission")
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.formatCurrency(
                              _vm.user.commission.total_commission
                            )
                          ) +
                          "\n\t\t\t\t\t\t"
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card card-list" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "general-list" },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._l(_vm.commissionEligibleTransactions, function(
                      group,
                      index
                    ) {
                      return _c("div", { key: index, staticClass: "row p-0" }, [
                        _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Date")
                          ]),
                          _vm._v(" "),
                          _vm.$can("Player - View Details")
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.getSingleDayUrl(group.date),
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(group.date) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(group.date) +
                                    "\n\t\t\t\t\t\t\t"
                                )
                              ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Transactions")
                          ]),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(group.transactions) +
                              "\n\t\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Deposits")
                          ]),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.formatCurrency(group.commission.total_sales)
                              ) +
                              "\n\t\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-md-6 col-lg bg-primary-20 py-2"
                          },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Commission")
                            ]),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(
                                    group.commission.commission_payout
                                  )
                                ) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ])
                    }),
                    _vm._v(" "),
                    !_vm.isLoading
                      ? _c("items-not-found", {
                          attrs: {
                            items: _vm.commissionEligibleTransactions,
                            msg: "No transactions found"
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" Details\n\t\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [_vm._v("ID")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
        _vm._v("Total Deposits")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
        _vm._v("Total Commission")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-list" }),
      _vm._v(" Commission transactions\n\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row p-0" }, [
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [_vm._v("Date")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
        _vm._v("Transactions")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
        _vm._v("Deposits")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg bg-primary-20 py-2" }, [
        _vm._v("Commission")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }