<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-8">
                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Syndicate details
                        </div>
                    </div>

                    <div v-if="syndicateItem && syndicate" class="card-body">
                        <div class="general-list">
                            <div class="row">
                                <div class="col col-2 text-muted">Title</div>
                                <div class="col col-4">{{ syndicate.title }}</div>
                                <div class="col col-2 text-muted">Slug</div>
                                <div class="col col-4">{{ syndicate.slug }}</div>
                            </div>

                            <div class="row">
                                <div class="col col-2 text-muted">Reference</div>
                                <div class="col col-4">{{ syndicate.uuid }}</div>
                                <div class="col col-2 text-muted">Game</div>
                                <div class="col col-4">
                                    <img :src="$imageService.gameImage(syndicate.game.image)" style="width: 60px; height: auto;">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-2 text-muted">Game draw</div>
                                <div class="col col-4">
                                    <strong>{{ syndicateItem.active_syndicate_item_draw.cut_off_at }}</strong>
                                </div>
                                <div class="col col-2 text-muted">Total shares</div>
                                <div class="col col-4">
                                    <strong>{{ syndicateItem.total_shares }}</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-2 text-muted">Shares left</div>
                                <div class="col col-4">
                                    <strong>{{ syndicateItem.active_syndicate_item_draw.available_shares }}</strong>
                                </div>
                                <div class="col col-2 text-muted">Lines</div>
                                <div class="col col-4">
                                    <strong>{{ syndicateItem.lines_count }}</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-2 text-muted">Share price</div>
                                <div class="col col-4">
                                    <strong>{{ syndicateItem.share_price | formatCurrency }}</strong>
                                </div>
                                <div class="col col-2 text-muted">Is recurring</div>
                                <div class="col col-4">
                                    <strong>{{ syndicate.is_recurring ? 'Yes' : 'No' }}</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-2 text-muted">Is active</div>
                                <div class="col col-4">
                                    <strong>{{ syndicateItem.is_active ? 'Yes' : 'No' }}</strong>
                                </div>
                                <div class="col col-2 text-muted">Max. future draws</div>
                                <div class="col col-4">
                                    <strong>{{ syndicate.max_future_draws }}</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <button v-if="syndicate.is_active && syndicateItem.is_active" type="reset" class="btn btn-sm btn-danger" @click="showDisableSyndicateModal = true">
                                            <i class="fa fa-ban"></i> Disable syndicate
                                        </button>

                                        <button v-if="! syndicate.is_active || ! syndicateItem.is_active" type="reset" class="btn btn-sm btn-primary" @click="showEnableSyndicateModal = true">
                                            <i class="fa fa-ban"></i> Enable syndicate
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-list" v-if="syndicateItems.length">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Syndicate draws
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="general-list">
                            <div class="row">
                                <div class="col text-muted">Reference</div>
                                <div class="col text-muted">Title</div>
                                <div class="col text-muted">Cut off at</div>
                                <div class="col text-muted">Is active</div>
                                <div class="col text-muted">Available shares</div>
                                <div class="col text-muted text-center">Action</div>
                            </div>

                            <template v-for="syndicateItem in showingSyndicateItems">
                                <div :key="syndicateItem.id" class="row">
                                    <div class="col">
                                        <span> ID: {{ syndicateItem.id }}</span>
                                        <small v-if="activeSyndicateItem.id === syndicateItem.id">
                                            <br />Current
                                        </small>
                                    </div>
                                    <div class="col">
                                        {{ syndicate.title }}
                                    </div>
                                    <div class="col">
                                        {{ syndicateItem.active_syndicate_item_draw.cut_off_at }}
                                    </div>
                                    <div class="col">
                                        {{ syndicateItem.is_active ? 'Yes' : 'No' }}
                                    </div>
                                    <div class="col">
                                        {{ syndicateItem.active_syndicate_item_draw.available_shares }}
                                    </div>
                                    <div class="col text-center">
                                        <template v-if="$can('Syndicates - Create/Update')">
                                            <a href="#" class="d-block" @click.prevent="gotoSyndicateItem(syndicateItem.id)">
                                                <small><i class="fa fa-user"></i> View item</small>
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <div v-if="syndicateItemsPage * syndicateItemsPerPage < syndicateItems.length" class="row">
                                <div class="d-flex justify-content-center align-items-center my-2">
                                    <button type="submit" class="btn btn-sm btn-primary" @click="syndicateItemsPage++">
                                        <i class="fa fa-search"></i> Show more
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Syndicate lines
                        </div>
                    </div>

                    <div v-if="syndicateItem" class="card-body">
                        <syndicate-lines :syndicate-item="syndicateItem" />
                    </div>
                </div>
            </div>
        </div>

        <syndicate-disable-modal v-if="showDisableSyndicateModal"
                                 :syndicate-item="syndicateItem"
                                 v-on:cancel="showDisableSyndicateModal = false"
                                 v-on:disableSyndicate="disableSyndicate" />

        <syndicate-enable-modal v-if="showEnableSyndicateModal"
                                :syndicate-item="syndicateItem"
                                 v-on:cancel="showEnableSyndicateModal = false"
                                 v-on:enableSyndicate="enableSyndicate" />
    </div>
</template>

<script>
import SyndicateService from '../../services/SyndicateService';
import SyndicateLines from './Components/SyndicateLines';
import SyndicateDisableModal from './Modals/DisableModal';
import SyndicateEnableModal from './Modals/EnableModal';

export default {
    components: { SyndicateLines, SyndicateDisableModal, SyndicateEnableModal },

    props: ['id'],

    data() {
        return {
            showDisableSyndicateModal: false,
            showEnableSyndicateModal: false,

            syndicateItem: null,
            syndicateItems: [],
            syndicateItemsPage: 1,
            syndicateItemsPerPage: 7,
        };
    },

    computed: {
        syndicate() {
            return this.syndicateItem.syndicate;
        },

        showingSyndicateItems() {
            return this.syndicateItems.slice(0, this.syndicateItemsPage * this.syndicateItemsPerPage);
        },
        
        activeSyndicateItem() {
            if(!this.syndicate) {
                return null;
            }

            return this.syndicateItems.find(s => s.id === this.syndicate.active_syndicate_item_id);
        },
    },

    beforeMount() {
        this.fetchSyndicateItem().then((response) => {
            this.fetchSyndicateItems();
        });
    },

    methods: {
        fetchSyndicateItem() {
            const promise = SyndicateService.showSyndicateItem(this.id);
            
            promise.then(response => {
                this.syndicateItem = response.data;
            });
            
            return promise;
        },

        fetchSyndicateItems() {
            SyndicateService.fetchSyndicateItems(this.syndicate.id).then((response) => {
                this.syndicateItems = response.data;
            });
        },

        disableSyndicate(action) {
            const apiPromise = action === 'syndicate' ?
                SyndicateService.disableSyndicate(this.syndicate.id)
                : SyndicateService.disableSyndicateItem(this.id);

            apiPromise.then(response => {
                this.showDisableSyndicateModal = false;
                this.syndicate.is_active = false;
                window.showMessage('Syndicate successfully disabled');
                location.reload();
            }).catch(err => {
                this.showDisableSyndicateModal = false;
                window.flashError('There was a problem disabling this syndicate');
            });
        },

        enableSyndicate(action) {
            const apiPromise = action === 'syndicate' ?
                SyndicateService.enableSyndicate(this.syndicate.id)
                : SyndicateService.enableSyndicateItem(this.id);

            apiPromise.then(response => {
                this.showEnableSyndicateModal = false;
                this.syndicate.is_active = true;
                window.showMessage('Syndicate successfully enabled');
                location.reload();
            }).catch(err => {
                this.showEnableSyndicateModal = false;

                const errResponse = err.response;

                if (errResponse.status === 422) {
                    const errors = [errResponse.data.message];

                    for (const [key, error] of Object.entries(errResponse.data.errors)) {
                        errors.push(error[0]);
                    }

                    window.flash(errors.join('<br />'), 'alert-danger');
                    return;
                }

                window.flashError('There was a problem enabling this syndicate');
            });
        },

        gotoSyndicateItem(syndicateItemId) {
            window.location.href = `/syndicates/items/${syndicateItemId}/edit`;
        },
    },
}
</script>

<style lang="scss" scoped>
.number-circle {
    display       : inline-block;
    width         : 30px;
    height        : 30px;
    padding       : 5px;
    margin        : 5px 5px 5px 0;

    color         : #000000;
    font-size     : 14px;
    line-height   : 20px;
    text-align    : center;

    box-shadow    : inset -3px -3px 8px rgba(0, 0, 0, 0.2), inset 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius : 50%;

    &--yellow {
        box-shadow : inset -3px -3px 8px rgba(198, 185, 2, 0.33), inset 0 0 16px rgba(206, 213, 11, 0.68);
    }
}
</style>