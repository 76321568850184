<template>
    <form @submit.prevent="onSearchEvent">
        <div class="card card-search">
            <div class="card-header">
                <i class="fa fa-filter" aria-hidden="false"></i> Search
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <atbl-input :value="listViewSearchModel.name"
                                    label="Name"
                                    name="name"
                                    @update="updateListViewSearchFormInput"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <atbl-input :value="listViewSearchModel.surname"
                                    label="Surname"
                                    name="surname"
                                    @update="updateListViewSearchFormInput"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <atbl-input :value="listViewSearchModel.email"
                                    label="Email"
                                    name="email"
                                    @update="updateListViewSearchFormInput"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <atbl-input :value="listViewSearchModel.phone"
                                    label="Phone"
                                    name="phone"
                                    @update="updateListViewSearchFormInput"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <balance-select v-model="liveSpent"
                                        :balance-types="false"
                                        :error-message="errors.balanceSelector"
                                        label="Live Spent"
                        />
                    </div>
                    <div class="col-12 col-lg-3">
                        <customer-status-picker :is-lead="!!isLeadCampaign()"
                                                :value="listViewSearchModel.disposition_id"
                                                label="Disposition"
                                                name="disposition_id"
                                                @update="updateListViewSearchFormInput"
                        />
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button :disabled="!isSearchEnabled"
                        class="btn btn-sm btn-primary mr-2"
                        type="submit"
                >
                    <i class="fa fa-search"></i> Search
                </button>
                <button class="btn btn-sm btn-danger"
                        type="reset"
                        @click="clearListViewSearchModel"
                >
                    <i class="fa fa-ban"></i> Clear
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import CustomerStatusPicker from '../../../../_Components/CustomerStatus/CustomerStatusPicker';
import ListViewSearchModel from './ListViewSearchModel';
import CountriesOptions from '../../../../../../components/Forms/Select/CountriesOptions';
import removeUndefinedProperties from '../../../../../../utils/Object/RemoveUndefinedProperties';
import { isObjectEmpty } from '../../../../../../utils/Object/Object';
import DispositionsSelect from '../../../../../../components/Forms/Select/DispositionsSelect';

export default {
    name: 'campaign-list-view-search-component',
    inject: [
        'isLeadCampaign',
        'updateListViewSearchFormInput',
        'clearListViewSearchModel',
    ],
    components: {
        CountriesOptions,
        CustomerStatusPicker,
        DispositionsSelect,
    },
    props: {
        listViewSearchModel: {
            type: Object,
            default: () => (ListViewSearchModel)
        },
    },
    data() {
        return {
            errors: {
                balanceSelector: null,
                balanceSelectorValidatorMessage: null,
            },
        };
    },
    computed: {
        liveSpent: {
            get() {
                const { balance, balanceSelector } = this.listViewSearchModel.live_spent;

                return {
                    balance,
                    balanceSelector,
                };
            },
            set(value) {
                const {
                    balance,
                    balanceSelector,
                    errorMessage,
                } = value;

                this.updateListViewSearchFormInput('live_spent', {
                    balance,
                    balanceSelector
                });

                this.errors = {
                    balanceSelector: null,
                    balanceSelectorValidatorMessage: errorMessage,
                };
            }
        },
        isSearchEnabled() {
            const invalidInputs = removeUndefinedProperties(this.errors);

            return !this.validateInputs() && !!isObjectEmpty(invalidInputs);
        },
    },
    methods: {
        validateInputs() {
            const { status: balanceValidationStatus, message: balanceValidationMessage } = this.validateBalance();

            this.errors = {
                balanceSelector: balanceValidationMessage
            };

            return !balanceValidationStatus;
        },
        validateBalance() {
            const {
                balanceSelector,
                balance,
            } = this.listViewSearchModel.live_spent;

            if (!balance && !balanceSelector) {
                return {
                    status: true,
                    message: null
                };
            }

            const validatorMessage = !!this.errors.balanceSelectorValidatorMessage
                ? this.errors.balanceSelectorValidatorMessage
                : null;

            const isBalanceValid = !!balance && !!balanceSelector;

            return {
                status: isBalanceValid,
                message: !!isBalanceValid
                    ? validatorMessage
                    : 'Balance values are invalid, no filter will be applied.'
            };
        },
        onSearchEvent() {
            this.errors = {
                balanceSelector: null,
                balanceSelectorValidatorMessage: null,
            };
            this.$emit('search', this.listViewSearchModel);
        },
    },
}
</script>