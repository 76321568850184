export default {
    mutations: {
        SET_ADD_TO_CART_DATA: "setAddToCartData",
        SET_CLEAR_CART_DATA: "setClearCartData",
        SET_ADD_TO_CART_LOADER: "setAddToCartLoader",
        SET_CHECKOUT: "setCheckout",
        SET_CART_TOTAL: "setCartTotal",
        SET_PROCESSING_OVERLAY: "setShowProcessingOverlay",
        SET_SHOW_GAME: "setShowGame",
        SET_PLAYER_CAN_PLAY_GAME: "setPlayerCanPlayGame",
        SET_WALLET_CONFIRM_MODAL: "setWalletConfirmModal",
        SET_ADD_BALANCE: "setAddBalance",
        SET_PAYMENT_LINK: "setPaymentLink",
        SET_CART_FIELD_DATA: "setCartFieldData",
        SET_PLACE_ORDER: "setPlaceOrder",
    },
    getters: {
        GET_ADD_TO_CART_DATA: "getAddToCartData",
        GET_ADD_TO_CART_LOADER: "getAddToCartLoader",
        GET_CHECKOUT: "getCheckout",
        GET_CART_TOTAL: "getCartTotal",
        GET_PROCESSING_OVERLAY: "getShowProcessingOverlay",
        GET_SHOW_GAME: "getShowGame",
        GET_PLAYER_CAN_PLAY_GAME: "getPlayerCanPlayGame",
        GET_WALLET_CONFIRM_MODAL: "getWalletConfirmModal",
        GET_ADD_BALANCE: "getAddBalance",
        GET_PAYMENT_LINK: "getPaymentLink",
        GET_PLACE_ORDER: "getPlaceOrder",
    },
    actions: {
        ADD_TO_CART_ACTION: "addToCartAction",
        CLEAR_CART_ACTION: "clearCartAction",
        REMOVE_CART_ITEM_ACTION: "removeCartItemAction",
        RESET_CART_ACTION: "resetCartAction",
    }
};