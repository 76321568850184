import { render, staticRenderFns } from "./DeleteShiftComponent.vue?vue&type=template&id=445ea8fa&"
import script from "./DeleteShiftComponent.vue?vue&type=script&lang=js&"
export * from "./DeleteShiftComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/app-stage.thelottoexperts.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('445ea8fa')) {
      api.createRecord('445ea8fa', component.options)
    } else {
      api.reload('445ea8fa', component.options)
    }
    module.hot.accept("./DeleteShiftComponent.vue?vue&type=template&id=445ea8fa&", function () {
      api.rerender('445ea8fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/pages/Shifts/DeleteShift/DeleteShiftComponent.vue"
export default component.exports