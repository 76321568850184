var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "birthdate-picker font-weight-bold",
      attrs: {
        error: _vm.getError(_vm.errors, _vm.name),
        label: _vm.label,
        id: "fsBirthdate",
        "label-for": "birthdate"
      }
    },
    [
      _c(
        "date-range-picker",
        {
          class: {
            "is-invalid": _vm.hasError(_vm.errors, _vm.name)
          },
          attrs: {
            opens: _vm.opens,
            singleDatePicker: _vm.singleDatePicker,
            ranges: _vm.showRanges,
            "locale-data": _vm.locale,
            showDropdowns: _vm.showDropdowns,
            "auto-apply": _vm.autoApply
          },
          model: {
            value: _vm.pickerValue,
            callback: function($$v) {
              _vm.pickerValue = $$v
            },
            expression: "pickerValue"
          }
        },
        [
          _c("div", { attrs: { slot: "input" }, slot: "input" }, [
            _vm.formattedValue
              ? _c("span", [_vm._v(_vm._s(_vm.formattedValue))])
              : _c("span", [_vm._v("Select date")])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }