<template>
    <div class="container-fluid ticket-results">
        <div class="row">
            <div class="col-12">
                <lottoTicketInfo />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <lottoTicketLines />
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../store/TypesConst";
    import NamespaceConst from "../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const {
        mapMutations,
        mapActions
    } = createNamespacedHelpers(NamespaceConst.tickets);

    import lottoTicketInfo from './Sections/LottoTicketInfo/LottoTicketInfo';
    import lottoTicketLines from './Sections/LottoTicketLines';

    export default {
        props: {
            id: {
                required: true,
                type: Number
            }
        },
        components: {
            lottoTicketInfo,
            lottoTicketLines
        },
        methods: {
            ...mapMutations({
                setTicketId: TypesConst.tickets.mutations.SET_TICKETS_TICKET_ID
            }),
            ...mapActions({
                fetchTicket: TypesConst.tickets.actions.GET_TICKET_BY_ID
            })
        },
        created: function(){
            this.setTicketId(this.id);
            this.fetchTicket();
        }
    }
</script>
