import TypesConst from "../../TypesConst";

export default {
	[TypesConst.cart.getters.GET_ADD_TO_CART_DATA]: state => state.cartData,
	[TypesConst.cart.getters.GET_ADD_TO_CART_LOADER]: state => state.cartLoader,
	[TypesConst.cart.getters.GET_CHECKOUT]: state => state.checkout,
	[TypesConst.cart.getters.GET_CART_TOTAL]: state => Number(state.cartTotal),
	[TypesConst.cart.getters.GET_PROCESSING_OVERLAY]: state => state.showProcessingOverlay,
	[TypesConst.cart.getters.GET_SHOW_GAME]: state => state.showGame,
	[TypesConst.cart.getters.GET_PLAYER_CAN_PLAY_GAME]: state => state.playerCanPlayGame,
	[TypesConst.cart.getters.GET_WALLET_CONFIRM_MODAL]: state => state.walletConfirmModal,
	[TypesConst.cart.getters.GET_ADD_BALANCE]: state => state.addBalance,
	[TypesConst.cart.getters.GET_PAYMENT_LINK]: state => state.paymentLink,
	[TypesConst.cart.getters.GET_PLACE_ORDER]: state => state.isPlacingOrder,
};