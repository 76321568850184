export default {
    async fileFromUrl(url, closeAfterMilliseconds = 3000) {
        const filename = url.substring(
            url.lastIndexOf('/') + 1
        )
            .split('?')[0];

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';

        xhr.onload = () => this.downloadFile(xhr.response, filename, closeAfterMilliseconds);

        xhr.open('GET', url);
        xhr.send();
    },
    downloadFile(data, filename = null, closeAfterMilliseconds = 3000) {
        if (!data) {
            return window.showMessage('Data is undefined.', false);
        }

        if (!filename) {
            return window.showMessage('Undefined filename.', false);
        }

        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.style.display = 'none';

        document.body.appendChild(a);

        a.click();

        setTimeout(function () {
            document.body.removeChild(a);
        }, closeAfterMilliseconds);
    },
}