import TypesConst from "../../TypesConst";

export default {
    [TypesConst.settings.mutations.SET_PARENT_ID](state, id) {
        state.parent_id = id;
    },
    [TypesConst.settings.mutations.SET_SECTION_ID](state, id) {
        state.section_id = id;
    },
    [TypesConst.settings.mutations.SET_SETTING_ID](state, id) {
        state.setting_id = id;
    },
    [TypesConst.settings.mutations.SET_OPEN_SETTINGS_SECTIONS_MODAL](state, isOpen) {
        state.addSettingsSectionModal = isOpen;
    },
    [TypesConst.settings.mutations.SET_OPEN_SETTINGS_MODAL](state, isOpen) {
        state.addSettingsModal = isOpen;
    },
    [TypesConst.settings.mutations.SET_SECTIONS_TYPES](state, types) {
        state.sectionsTypes = types;
    },
    [TypesConst.settings.mutations.SET_SETTINGS_TYPES](state, types) {
        state.settingsTypes = types;
    },
    [TypesConst.settings.mutations.SET_SETTINGS_SECTIONS](state, sections) {
        state.sections = sections;
    },
    [TypesConst.settings.mutations.SET_SECTIONS_LOADER](state, isLoading) {
        state.sectionsLoader = isLoading;
    },
    [TypesConst.settings.mutations.SET_SECTION_MODAL_LOADER](state, isLoading) {
        state.sectionsModalLoader = isLoading;
    },
    [TypesConst.settings.mutations.SET_SETTINGS_MODAL_LOADER](state, isLoading) {
        state.settingsModalLoader = isLoading;
    },
    [TypesConst.settings.mutations.SET_BREADCRUMBS](state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs;
    },
    [TypesConst.settings.mutations.SET_SELECTED_SECTION](state, data) {
        if (data !== null) {
            state.selectedSection = data;
            state.sections = data.sections;
            state.settings = data.settings;
            state.breadcrumbs = [
                ...state.breadcrumbs,
                {
                    name: data.name,
                    id: data.id
                }
            ];
        }else {
            state.selectedSection = {};
            state.sections = [];
            state.settings = [];
            state.breadcrumbs = [];
        }
    },
    [TypesConst.settings.mutations.SET_SETTINGS](state, data) {
        state.settings = data;
    },
    [TypesConst.settings.mutations.SET_SETTINGS_SAVE_LOADER](state, data) {
        state.settingSaveLoader = data;
    },
    [TypesConst.settings.mutations.UPDATE_FORM_MODEL](state, model) {
        Object.assign(state.sectionsModel, model);
    },
    [TypesConst.settings.mutations.SET_SECTION_FORM_MODEL](state, model) {
        state.sectionsModel = {
            key: model.key,
            name: model.name,
            type: model.type,
            parentId: model.id
        };
    },
    [TypesConst.settings.mutations.CLEAR_SECTION_MODEL](state) {
        state.sectionsModel = {
            parentId: 0,
            type: null,
            name: '',
            key: ''
        };
        state.section_id = 0;
    },
    [TypesConst.settings.mutations.UPDATE_SETTINGS_FORM_MODEL](state, model) {
        Object.assign(state.settingsModel, model);
    },
    [TypesConst.settings.mutations.SET_SETTINGS_FORM_MODEL](state, model) {
        state.settingsModel = {
            id: model.id,
            category_id: model.category_id,
            name: model.name,
            key: model.key,
            value: model.value,
            type: model.type,
            off_value: model.off_value,
            on_value: model.on_value
        };
    },
    [TypesConst.settings.mutations.CLEAR_SETTINGS_FORM_MODEL](state) {
        state.settingsModel = {
            id: 0,
            category_id: 0,
            name: '',
            key: '',
            value: '',
            type: null,
            off_value: '',
            on_value: ''
        };
        state.setting_id = 0;
    },
    [TypesConst.settings.mutations.SET_SECTION_FORM_ERRORS](state, errors) {
        state.sectionErrors = errors;
    },
    [TypesConst.settings.mutations.SET_SETTINGS_FORM_ERRORS](state, errors) {
        state.settingsErrors = errors;
    },
    [TypesConst.settings.mutations.SET_SETTING_REMOVE](state, model) {
        state.removeSetting = model;
    },
    [TypesConst.settings.mutations.SET_SETTING_SECTION_REMOVE](state, model) {
        state.removeSettingSection = model;
    },
    [TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL](state, isOpen) {
        state.removeSettingModal = isOpen;
    },
    [TypesConst.settings.mutations.SET_SETTING_REMOVE_MODAL_LOADER](state, isOpen) {
        state.removeSettingModalLoader = isOpen;
    },
    [TypesConst.settings.mutations.SET_SETTING_SECTION_REMOVE_MODAL](state, isOpen) {
        state.removeSettingSectionModal = isOpen;
    },
};