<template>
    <div class="card card-list">
        <div class="card-header bg-primary text-white">
            <i class="fa fa-phone"></i> Last Calls
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="row show-first-row">
                    <div class="col">
                        Number
                    </div>
                    <div class="col">
                        Status
                    </div>
                </div>

                <template v-if="!isLoading && !!calls.length">
                    <div v-for="(item, index) in calls"
                         :key="index"
                         class="row"
                    >
                        <div class="col">
                            <span class="badge badge-primary">
                                From: {{ item.from }}
                            </span>
                            <br />
                            <span class="badge badge-success">
                                To: {{ item.to }}
                            </span>
                            <br />
                            <span class="badge badge-warning">
                                {{ item.created_at | utcToLocal }}
                            </span>
                        </div>
                        <div class="col">
                            <span class="badge badge-primary">
                                {{ item.status }}
                            </span>
                            <br />
                            <small>
                                Duration: {{ !!item.duration ? item.duration : '/' }}
                            </small>
                        </div>
                    </div>
                </template>

                <loader :show="isLoading"
                        type="small"
                />

                <not-found v-if="!isLoading"
                           :items="calls"
                           msg="No calls found."
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlayerLastCalls',
    props: {
        playerId: {}
    },
    data() {
        return {
            isLoading: false,
            calls: [],
        };
    },
    methods: {
        async fetchCalls(){
            this.isLoading = true;

            try {
                const response = await window.axios.get(`/player/${this.playerId}/last-calls`);

                this.calls = response.data.data;

                this.isLoading = false;
            } catch (error) {
                window.flashError(error);

                this.isLoading = false;
            }
        },
    },
    created() {
        this.fetchCalls();
    },
}
</script>