var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.isLoading && !!_vm.callId && !_vm.isCustomerToCallModelEmpty
        ? [
            !_vm.isCustomerToCallModelEmpty && !!_vm.canCallThisLead
              ? _c("no-leads-have-been-found-component", {
                  attrs: {
                    message:
                      "This lead has status Not Interested and cannot be called again until " +
                      _vm.customer.unlock_at +
                      "."
                  }
                })
              : [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-3" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-sm btn-block btn-primary font-weight-bold",
                            attrs: { href: "/marketing/campaigns/" + _vm.type }
                          },
                          [
                            _c("i", { staticClass: "fa fa-chevron-left" }),
                            _vm._v(" Back to campaigns\n                    ")
                          ]
                        ),
                        _vm._v(" "),
                        _c("campaign-customer-call-component", {
                          attrs: {
                            customer: _vm.customer,
                            campaign: _vm.campaignModel,
                            "customer-campaign-id":
                              _vm.customerToCallModel.customer_call_id
                          },
                          on: {
                            "add-status-state": _vm.onAddStatusStateChanged
                          }
                        }),
                        _vm._v(" "),
                        _vm.$can("Leads - Add Status To Leads")
                          ? _c("customer-dispositions-component", {
                              attrs: {
                                "is-loading":
                                  _vm.customerDispositionStatusesLoader,
                                statuses: _vm.customerDispositionStatusesList
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$can("Leads - View Calls")
                          ? _c("last-calls-component", {
                              attrs: {
                                "is-loading": _vm.leadLastCallsLoading,
                                "latest-calls-response":
                                  _vm.leadLastCallsResponse
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-6" },
                      [
                        _vm.$can("Leads - Add Status To Leads") &&
                        !_vm.isLastStatusSale &&
                        !!_vm.isAddStatusAllowed
                          ? _c("add-status-component", {
                              attrs: {
                                campaignId: _vm.id,
                                statuses: _vm.customerDispositionList,
                                "is-loading":
                                  _vm.customerDispositionLoader ||
                                  _vm.addStatusLoader,
                                model: _vm.statusModel,
                                "model-errors": _vm.statusModelErrors
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("customer-details-component", {
                          attrs: {
                            customer: _vm.previewCustomerModel,
                            "show-create-player":
                              !!_vm.isLeadCampaign() &&
                              !_vm.isLastStatusSale &&
                              !_vm.shouldGoNext,
                            "customer-instant-access-loader":
                              _vm.customerInstantAccessLoader
                          }
                        }),
                        _vm._v(" "),
                        _vm.shouldGoNext
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-end"
                              },
                              [
                                _vm.isCampaignCallViewType
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function($event) {
                                            return _vm.fetchCustomerToCall(
                                              _vm.type,
                                              _vm.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Next Lead\n                        "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: { click: _vm.closeWindow }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Next Lead\n                        "
                                        )
                                      ]
                                    )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-3" },
                      [
                        _c("call-notes", {
                          attrs: {
                            "callable-id": _vm.callId,
                            "callable-type": _vm.type
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.callId && !!_vm.isCustomerToCallModelEmpty
        ? _c("no-leads-have-been-found-component", [
            _c("div", { staticClass: "text-center mt-3" }, [
              _c("a", { attrs: { href: "/marketing/campaigns/" + _vm.type } }, [
                _c("i", { staticClass: "fa fa-chevron-left" }),
                _vm._v(" Back to campaigns\n            ")
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: !!_vm.isLoading, type: "small" } }),
      _vm._v(" "),
      !!_vm.isLeadCampaign() &&
      _vm.$can("Leads - Create/Edit") &&
      _vm.isLeadModalOpen
        ? _c("add-leads-modal-component", {
            attrs: {
              model: _vm.leadModel,
              errors: _vm.leadModelErrors,
              "is-loading": _vm.addLeadLoader,
              "is-from-call": true
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.isLeadCampaign() &&
      _vm.$can("Player - Create/Update") &&
      !!_vm.createPlayerModalOpen
        ? _c("create-player-component", {
            attrs: {
              "client-model": _vm.customer,
              "additional-model-data": {
                lead_id: _vm.customer.id,
                campaign_id: _vm.id
              },
              isLead: true
            },
            on: {
              "created-lead": _vm.onCreatedLead,
              close: _vm.toggleCreatePlayerModal
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }