var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Users - Get List")
    ? _c(
        "atbl-form-group",
        {
          staticClass: "font-weight-bold",
          attrs: {
            horizontal: _vm.horizontal,
            label: "Agents",
            "label-for": "Agents"
          }
        },
        [
          _c("tree-select", {
            attrs: {
              multiple: true,
              searchable: true,
              "clear-on-select": true,
              options: _vm.users,
              disabled: !!_vm.disabled || !!_vm.isLoading,
              placeholder:
                "" + (!!_vm.isLoading ? "Loading..." : "Select options")
            },
            model: {
              value: _vm.selectedAgent,
              callback: function($$v) {
                _vm.selectedAgent = $$v
              },
              expression: "selectedAgent"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }