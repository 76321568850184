<template>
    <div class="container-fluid">
        <div v-if="!addLeadLoader"
             class="row"
        >
            <div class="col">
                <leads-preview-profile :model="previewCustomerModel"
                                       :is-loading="addLeadLoader"
                />

                <leads-messages :id="id" />
            </div>
            <div class="col-12 col-md-6">
                <leads-dispositions-list :lead-id="id" />

                <lead-last-calls v-if="$can('Leads - View Calls')"
                                 :lead-id="id"
                                 :pagination="true"
                />
            </div>
        </div>

        <loader :show="addLeadLoader"
                type="small"
        />

        <add-leads-modal-component v-if="$can('Leads - Create/Edit') && this.isLeadModalOpen"
                                   :model="leadModel"
                                   :errors="leadModelErrors"
                                   :is-loading="addLeadLoader"
        />
    </div>
</template>

<script>
import AddLeads from '../../_Components/AddLeadsModal/AddLeads';
import AddLeadsModalComponent from '../../_Components/AddLeadsModal/AddLeadsModalComponent';
import CampaignsLeadsPreviewNavigation from './_Components/LeadsPreviewNavigation';
import LeadLastCalls from './LeadLastCalls/LeadLastCalls';
import LeadModel from '../../_Components/AddLeadsModal/LeadModel';
import LeadService from '../../../../services/Marketing/LeadService';
import LeadsDetails from './LeadsDetails/LeadsDetails';
import LeadsMessages from './LeadsMessages/LeadsMessages';
import LeadsPreviewProfile from './LeadsDetails/LeadsDetails';
import { clone } from '../../../../utils/Json/Json';
import LeadsDispositionsList from './LeadsDispositions/LeadsDispositionsList';

export default {
    name: 'campaign-leads-preview',
    mixins: [
        AddLeads,
    ],
    components: {
        LeadsPreviewProfile,
        LeadsMessages,
        AddLeadsModalComponent,
        CampaignsLeadsPreviewNavigation,
        LeadsDetails,
        LeadLastCalls,
        LeadsDispositionsList,
    },
    props: {
        id: {
            type: Number
        }
    },
    data() {
        return {
            previewCustomerModel: clone(LeadModel),
        };
    },
    async created() {
        await this.getLeadById(this.id);
    },
    methods: {
        async getLeadById(id) {
            if (!id) {
                window.showMessage('Id cannot be undefined.', false);

                return;
            }

            if (!this.$can('Leads - Details')) {
                window.showMessage('You does not have permissions for this action.', false);

                return;
            }

            this.addLeadLoader = true;

            try {
                const response = await LeadService.getLeadById(id);
                const { data } = response.data;

                this.previewCustomerModel = clone(data);
                this.leadModel = clone(data);

                this.addLeadLoader = false;
            } catch (error) {
                this.addLeadLoader = false;

                window.flashError(error);
            }
        },
        async onSuccessAddLead() {
            this.previewCustomerModel = clone(this.leadModel);

            await this.toggleAddLeadModal();
        },
    },
}
</script>