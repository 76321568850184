<template>
    <atbl-modal header-bg-color="light"
                footer-class="justify-content-between"
                :show="showModal"
                :close-on-backdrop="false"
                :no-close-on-esc="true"
                size="lg"
                @close="showModal = false"
    >
        <template #header>
            <GameHeader v-if="game.game"
                        :game="game"
                        :is-from-confirm="true"
            />
        </template>

        <div class="row">
            <div class="col-12 text-center">
                <ConfirmConfiguration v-for="(item, index) in configuration"
                                      :key="index"
                                      :configuration="configuration"
                                      :configurationTmp="configurationTmp"
                                      :title="index.charAt(0).toUpperCase() + index.slice(1)"
                                      :type="index"
                                      v-if="item.length || item.numbers"
                />
                <div v-if="canShowMessage">
                    Nothing to show. Please fill all fields.
                </div>
            </div>
        </div>

        <template #footer>
            <h4 class="m-0">Are you sure you want to save this game draw?</h4>
            <div class="text-right">
                <button v-if="!isSaving"
                          class="btn btn-sm btn-danger"
                          @click="handleCancel()"
                >
                    Cancel
                </button>
                <button v-if="$can('Game Draws Edit') && !isSaving"
                          class="btn btn-sm btn-success"
                          @click="handleOK"
                >
                    Confirm
                </button>
                <loader v-else
                        :show="isSaving"
                        type="small"
                />
            </div>
        </template>
    </atbl-modal>
</template>

<script>
    import ConfirmConfiguration from "./ConfirmConfiguration";
    import ObjectMixin from "../../../../../../mixins/Object";
    import GameHeader from "./GameHeader";
    import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';

    export default {
        name: "confirm-edit",
        mixins: [
            ObjectMixin
        ],
        components: {
            ConfirmConfiguration,
            GameHeader,
            AtblModal,
        },
        data: function(){
            return {
                showModal: false,
                configuration: {},
                configurationTmp: {},
                game: {},
                isSaving: false
            };
        },
        computed: {
            canShowMessage: function(){
                if(!this.isObjectEmpty(this.configuration)){
                    let count = 0;
                    for(let item in this.configuration){
                        count += this.configuration[item].length;
                    }
                    return count === 0;
                }
                return false;
            }
        },
        methods: {
            handleOK: function(){
                this.isSaving = true;
                Atbl.$emit("saveConfigurationOk");
            },
            handleCancel: function(){
                this.showModal = false;
            }
        },
        mounted: function(){
            let vm = this;

            Atbl.$on("saveConfigurationOpen", function(value){
                vm.configuration = value.configuration;
                vm.configurationTmp = value.configurationTmp;
                vm.game = value.game;
                vm.showModal = value.show;
            });

            Atbl.$on("saveConfigurationFinished", function(){
                vm.isSaving = false;
                vm.showModal = false;
                Atbl.$emit("editConfigurationOk");
            });

            Atbl.$on("saveConfigurationError", function(){
                vm.isSaving = false;
            });
        }
    }
</script>