<template>
    <atbl-modal title="Select credit card" :close-on-backdrop="true" :show="true" @close="emitCloseEvent">
        <div class="row">
            <div class="col-12">
                <atbl-form-group label="Select credit card" class="font-weight-bold" :horizontal="false">
                    <select class="form-control" v-model="selectedCreditCard">
                        <option :value="null">Select credit card</option>
                        <option v-for="creditCardItem in creditCardList" :value="creditCardItem">
                            {{ creditCardItem.readable }}
                        </option>
                    </select>
                </atbl-form-group>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-danger" @click="emitCloseEvent">
                Cancel
            </button>

            <button class="btn btn-sm btn-primary" @click="confirm" :disabled="! selectedCreditCard">
                Continue
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import PlayerCreditCardsService from '../../services/PlayerCreditCardsService';
import AtblModal from '../AtblModal/AtblModal.vue';

export default {
    name: 'payment-gateway-saved-credit-cards-modal',
	components: {
		AtblModal,
	},
    props: ['gatewayKey', 'playerId'],

    data() {
        return {
            creditCardList: [],
            selectedCreditCard: null,
        };
    },

    mounted() {
        this.fetchCreditCards();
    },

    methods: {
        fetchCreditCards() {
            const params = {
                playerId: this.playerId,
                gateway: this.gatewayKey,
            };

            PlayerCreditCardsService.getPlayerCreditCards(this.playerId, params).then(response => {
                this.creditCardList = response.data.data.map(c => {
                    const temp = `${c.holder} * ${c.last_4_digits} | exp. ${c.expiry_month}/${c.expiry_year} | succ: ${c.success} fail: ${c.failed}`;

                    return {
                        ...c,
                        readable: temp,
                    }
                });
            });
        },

        confirm() {
            this.$emit('confirm', this.selectedCreditCard);
        },

        emitCloseEvent() {
            this.$emit('cancel');
        },
    },
}
</script>