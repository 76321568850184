<template>
    <div class="card-header card-header-atbl">
        <div class="player-play-header">
            <div class="mb-1 d-flex align-items-center justify-content-center justify-content-xl-between">
                <div class="d-flex align-items-center">
                    <img :src="$imageService.gameImage(config.image)" class="game-thumb mx-2" :alt="config.name">
                    <h5 class="text-muted m-0">{{ config.name }} | {{ syndicate.title }}</h5>
                </div>
                <button class="btn btn-sm btn-link" @click="closeGame">
                    <i class="fa fa-times" aria-hidden="false"></i>
                </button>
            </div>

            <div class="player-play-header-description">
                <p class="text-muted">
                    <strong>Next Draw Closes: {{ syndicate.active_syndicate_item.cut_off_at | dateFormat('ddd DD MMM [@] H:mm', false) }}</strong>
                    <strong>Est. Jackpot:</strong> <span>{{ config.jackpot || 'N/A' }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'syndicate-game-header',

    props: ['config', 'syndicate'],

    methods: {
        closeGame() {
            this.$store.commit('cart/setShowGame', false);
            this.$store.commit('cart/setCheckout', {
                checkout: false,
                total: 0,
            });
        },

        getKeyIndexByType(type){
            return Object.keys(this.configuration).indexOf(type);
        },
    }
}
</script>