<template>
    <atbl-form-group label="Campaign"
                     class="font-weight-bold"
    >
        <tree-select v-model="selectedCampaign"
                     :multiple="multiple"
                     :value="value"
                     :options="campaigns"
                     :disabled="disabled || isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
    </atbl-form-group>
</template>

<script>
import MarketingService from '../../../../services/MarketingService';

export default {
    name: 'CampaignsPicker',
    props: {
        value: {
            type: [Array, String, Number],
            default: null,
        },
        name: {
            type: String,
            default: 'campaigns',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        modelType: {
            type: String,
            default: 'lead'
        },
        multiple: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            isLoading: false,
            campaigns: [],
        };
    },
    computed: {
        selectedCampaign: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
    async created() {
        await this.fetchCampaignsList();
    },
    methods: {
        async fetchCampaignsList() {
            this.isLoading = true;

            try {
                const response = await MarketingService.getCampaignsDropdownList(this.modelType);

                this.campaigns = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>