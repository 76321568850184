<template>
    <Layout title="Leads"
            icon="nav-icon fa fa-users"
    >
        <template v-if="showFilter" #beforeCard>
            <leads-filter-form-component :model="modelSearch"
                                         @search="fetchLeads"
            />
        </template>
        
        <template v-slot:header>
            <button v-if="$can('Leads - View')"
                    v-tooltip="{
                        content: 'Search'
                    }"
                    :class="{'btn-outline-primary': showFilter, 'btn-primary': !showFilter}"
                    class="btn btn-sm"
                    @click="showFilter = !showFilter"
            >
                <i class="fa fa-filter" aria-hidden="false"></i>
            </button>
            <button v-if="$can('Leads - Import')"
                    v-tooltip="{
                        content: 'Import'
                    }"
                    class="btn btn-sm btn-warning"
                    @click="showImportLeadsModal = true"
            >
                <i class="fa fa-upload" aria-hidden="false"></i>
            </button>
            <button v-if="$can('Leads - Export')"
                    v-tooltip="{
                        content: `Export`
                    }"
                    :disabled="!!exportLoader"
                    class="btn btn-sm btn-success"
                    @click="onExportEvent"
            >
                <loader :show="exportLoader"
                        type="smaller"
                />
                <i v-if="!exportLoader"
                   class="fa fa-download" aria-hidden="false"
                ></i>
            </button>
            <button v-if="$can('Leads - Create/Edit')"
                    v-tooltip="{
                        content: 'Create Lead'
                    }"
                    class="btn btn-sm btn-primary"
                    @click="toggleAddLeadModal"
            >
                Create
            </button>
            <span v-tooltip="{
                     content: !hasSearchModelValues
                     ? 'Search form is empty'
                     : 'Create campaign from leads list'
                  }"
            >
                <button v-if="$can('Campaigns - Create/Update')"
                        :disabled="!hasSearchModelValues"
                        class="btn btn-sm btn-primary"
                        @click="toggleAddToCampaign"
                >
                    Add to campaign
                </button>
            </span>
        </template>
        
        <div class="general-list">
            <div class="row">
                <div class="col-12 col-lg">Name</div>
                <div class="col-12 col-lg">Email</div>
                <div class="col-12 col-lg">Phone</div>
                <div class="col-12 col-lg">Country</div>
                <div class="col-12 col-lg">Live Spent</div>
                <div class="col-12 col-lg">Last Status</div>
                <div class="col-12 col-lg">Operations</div>
            </div>
            
            <template v-if="!isLoading">
                <div v-for="(item, index) in leads"
                     :key="index"
                     class="row"
                >
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Name</span>
                        <p class="mb-2 font-weight-bold">
                            {{ item.name }} {{ item.surname }}
                        </p>
                        <a v-if="$can('Leads - Details')"
                           href="#"
                           target="_blank"
                           @click.prevent="copyToClipboard(item.id)"
                        >
                            <small>#{{ item.id }} <i class="fa fa-copy"></i></small>
                        </a>
                        <span v-if="!!item.converted"
                              class="badge badge-success"
                        >
                            Converted
                        </span>
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Email</span>
                        {{ emptyField(item.email) }}
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Phone</span>
                        <small>{{ item.phone }}</small>
                        <br/>
                        <small>{{ item.mobile }}</small>
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Country</span>
                        {{ emptyField(item.country) }}
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Live Spent</span>
                        <span v-if="item.live_spent !== null && item.live_spent === 0">
                            {{ item.live_spent }}
                        </span>
                        <span v-else-if="item.live_spent > 0">
                            {{ formatCurrency(item.live_spent) }}
                        </span>
                        <span v-else>
                            /
					    </span>
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <span class="small-mb-badge">Last Status</span>
                        <div v-if="!!item.status_name && !!item.status_date">
                            <span :class="{
                                     'badge-primary': item.status_name !== $options.Campaigns.SaleStatusName,
                                     'badge-success': item.status_name === $options.Campaigns.SaleStatusName,
                                  }"
                                  class="badge"
                            >
                                {{ item.status_name }}
                            </span>
                            <br/>
                            <small>
                                {{ utcToLocal(item.status_date) }}
                            </small>
                        </div>
                        <span v-else>/</span>
                    </div>
                    <div class="col-12 col-lg align-self-center mb-2 mb-lg-0">
                        <div class="d-flex align-items-center">
                            <a v-if="$can('Leads - Details')"
                               :href="`/marketing/leads/${item.id}/view`"
                               target="_blank"
                               class="mr-2"
                            >
                                <i class="fa fa-eye"></i>
                            </a>
                            <confirmable-remove-button v-if="$can('Leads - Remove')"
                                                       :value="item.id"
                                                       @confirmed="onDeleteLead"
                            />
                        </div>
                    </div>
                    <div v-if="!!item.campaigns && item.campaigns.length"
                         class="col-12 align-self-center mb-2 mt-2 mb-lg-0 bg-light p-1"
                    >
                        <span v-for="(campaign, index) in item.campaigns"
                              :key="index"
                              class="badge badge-primary ml-1"
                        >
                            {{ campaign.title }}
                        </span>
                    </div>
                </div>
            </template>
            
            <loader :show="isLoading"
                    type="small"
            />
            
            <not-found v-if="!isLoading"
                       :items="leads"
                       msg="No leads found."
            />
            
            <nav class="wrap py-3 overflow-x-auto" v-if="!isLoading && leadsResponse.last_page > 1">
                <atbl-pagination :pagination="leadsResponse"
                                 @paginate="fetchLeads"
                />
            </nav>
        </div>
        
        <template #modals>
            <add-leads-modal-component v-if="isLeadModalOpen"
                                       :model="leadModel"
                                       :errors="leadModelErrors"
                                       :is-loading="addLeadLoader"
            />
            
            <import-leads-modal v-if="showImportLeadsModal"
                                :file="fileToImport"
                                :is-loading="importLoader"
                                :errors="importErrors"
                                @close="showImportLeadsModal = false"
            />
            
            <import-errors-log-modal v-if="showImportLeadsErrorsModal"
                                     :errors="importErrors"
            />
            
            <add-to-campaign-component :show="isAddToCampaignOpened"
                                       :search-model="modelSearch"
            />
        </template>
    </Layout>
</template>

<script>
import AddLeadsModalComponent from '../../_Components/AddLeadsModal/AddLeadsModalComponent';
import ImportErrorsLogModal from './ImportLeads/ImportErrorsLogModal/ImportErrorsLogModal';
import ImportLeads from './ImportLeads/ImportLeads';
import ImportLeadsModal from './ImportLeads/ImportLeadsModal';
import Layout from '../../_Components/Layout';
import LeadsFilterForm from './LeadsFilterForm/LeadsFilterForm.js';
import LeadsFilterFormComponent from './LeadsFilterForm/LeadsFilterFormComponent';
import AddLeads from '../../_Components/AddLeadsModal/AddLeads';
import LeadService from '../../../../services/Marketing/LeadService';
import formatCurrency from '../../../../filters/FormatCurrency';
import utcToLocal from '../../../../filters/UtcToLocal';
import emptyField from '../../../../filters/EmptyField';
import Campaigns from '../../../../constants/Campaigns';
import {filterNonEmpty} from '../../../../utils';
import AddToCampaignComponent from './AddToCampaign/AddToCampaignComponent.vue';
import AddToCampaign from './AddToCampaign/AddToCampaign';

export default {
    name: 'campaign-leads',
    Campaigns,
    mixins: [
        AddLeads,
        LeadsFilterForm,
        ImportLeads,
        AddToCampaign,
    ],
    components: {
        AddToCampaignComponent,
        AddLeadsModalComponent,
        ImportErrorsLogModal,
        ImportLeadsModal,
        Layout,
        LeadsFilterFormComponent,
    },
    data() {
        return {
            isLoading: false,
            leadsResponse: {},
            leads: [],
            
            showFilter: false,
            exportLoader: false,
        };
    },
    computed: {
        hasSearchModelValues() {
            return !!filterNonEmpty(this.modelSearch).length;
        },
    },
    async created() {
        await this.fetchLeads();
    },
    methods: {
        formatCurrency,
        utcToLocal,
        emptyField,
        async fetchLeads(page = 1) {
            if (!this.$can('Leads - View')) {
                window.showMessage('You does not have permissions for this action.', false);
                
                return;
            }
            
            this.isLoading = true;
            
            try {
                const response = await LeadService.getLeads({
                    ...this.modelSearch,
                    page
                });
                
                this.leadsResponse = response.data;
                this.leads = response.data.data;
                
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                
                window.flashError(error);
            }
        },
        async onDeleteLead(id) {
            if (!id) {
                window.showMessage('Undefined id.', false);
                
                return;
            }
            
            if (!this.$can('Leads - Remove')) {
                window.showMessage('You does not have permissions for this action.', false);
                
                return;
            }
            
            this.isLoading = true;
            
            try {
                const response = await LeadService.removeLead(id);
                const {status, message} = response.data;
                
                window.showMessage(message, status);
                
                if (!!status) {
                    await this.fetchLeads();
                }
                
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                
                window.flashError(error);
            }
        },
        async onSuccessAddLead() {
            this.toggleAddLeadModal();
            
            await this.fetchLeads();
        },
        async onExportEvent() {
            if (!this.modelSearch.batch) {
                window.showMessage('To search choose batch from the search from.', false);
                
                return;
            }
            
            if (!this.$can('Leads - Export')) {
                window.showMessage('You does not have permissions for this action.', false);
                
                return;
            }
            
            this.exportLoader = true;
            
            try {
                const response = await window.axios.get(`/marketing/leads/export`,
                  {
                      params: this.modelSearch,
                      responseType: 'text',
                  });
                
                const contentDisposition = response.headers['content-disposition'];
                const filename = contentDisposition.split('=')[1];
                
                this.downloadFile(response.data, filename);
                
                this.exportLoader = false;
            } catch (error) {
                this.exportLoader = false;
                
                window.flashError(error);
            }
        },
        downloadFile(data, filename) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            
            link.click();
            
            document.body.removeChild(link);
        },
    }
};
</script>