<template>
	<div class="horizontal-line" :class="size"></div>
</template>

<script>
    export default {
        name: "horizontal-line",
	    props: {
        	size: {
        		type: String,
		        default: null,
		        validator: function(value){
		        	return ["small", "large"].indexOf(value) !== -1;
		        }
	        }
	    }
    }
</script>

<style lang="scss">
	.horizontal-line {
		margin: 1rem auto;
		border: 0;
		border-top: 1px solid rgba(0, 0, 21, 0.2);

		&.small {
			width: 100px;
		}
	}
</style>