var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr"),
    _vm._v(" "),
    _c("h5", { staticClass: "text-center mb-3" }, [
      _vm._v("Do you really want to send this type of message.")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _c("p", { staticClass: "mb-1" }, [
        _vm._v("\n            Language:\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-center mb-3"
        },
        [
          _c("img", {
            staticClass: "flag mr-1",
            attrs: {
              src: "/img/flags/" + _vm.getLocaleValue.toLowerCase() + ".svg",
              alt: "flag"
            }
          }),
          _vm._v(" "),
          _c("b", [_vm._v(_vm._s(_vm.getLocaleName))])
        ]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n            Email Name:"),
        _c("br"),
        _c("b", [_vm._v(_vm._s(_vm.selectedEmailTemplate.name))])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n            Email Description:"),
        _c("br"),
        _c("b", [_vm._v(_vm._s(_vm.selectedEmailTemplate.description))])
      ])
    ]),
    _vm._v(" "),
    _vm.isTicketConfirmation && !_vm.isObjectEmpty(_vm.ticket)
      ? _c("div", { staticClass: "ticket-preview vertical" }, [
          !_vm.isObjectEmpty(_vm.ticket)
            ? _c("div", { staticClass: "ticket-preview-holder" }, [
                _c("div", { staticClass: "ticket-preview-holder-row" }, [
                  _c("div", [
                    _c("img", {
                      staticStyle: { width: "60px", height: "auto" },
                      attrs: {
                        src: _vm.$imageService.gameImage(_vm.ticket.game.image),
                        alt: "" + _vm.ticket.game.image
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("h4", [_vm._v(_vm._s(_vm.ticket.game.name))]),
                    _vm._v(" "),
                    _vm.isset(_vm.ticket, "draw") &&
                    _vm.ticket.draw.game_draw &&
                    _vm.isset(_vm.ticket.draw.game_draw, "date")
                      ? _c("div", { staticClass: "draw-date" }, [
                          _c("span", { staticClass: "text-muted small" }, [
                            _vm._v(
                              "Date: " + _vm._s(_vm.ticket.draw.game_draw.date)
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isset(_vm.ticket, "game_draw") &&
                    _vm.ticket.game_draw &&
                    _vm.isset(_vm.ticket.game_draw, "date")
                      ? _c("div", { staticClass: "draw-date" }, [
                          _c("span", { staticClass: "text-muted small" }, [
                            _vm._v("Date: " + _vm._s(_vm.ticket.game_draw.date))
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }