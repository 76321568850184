<template>
    <div :class="['atbl-tabs', {vertical: !!vertical}]">
        <ul :class="['atbl-tabs__header', headerClasses]">
            <li v-for="(tab, index) in tabs"
                :key="tab.title"
                :class="headerItemClasses"
            >
                <a href="#"
                   :class="[
                       'atbl-tabs__tab',
                       headerItemButtonClasses,
                       {
                           'atbl-tabs__tab__selected': (index === selectedIndex),
                           disabled: !!tab.disabled,
                       },
                   ]"
                   @click.prevent="onSelectedTabEvent(index)"
                   v-html="getTabTitle(tab)"
                />
            </li>
        </ul>
        <slot></slot>
    </div>
</template>

<script>
import Slot from '../../utils/Vue/Slot';

export default {
    name: 'atbl-tabs',
    emits: [
        'change-tab',
    ],
    props: {
        activeTab: {
            type: Number,
            default: 0
        },
        headerClasses: {
            type: [Array, Object, String],
            default: null,
        },
        headerItemClasses: {
            type: [Array, Object, String],
            default: null,
        },
        headerItemButtonClasses: {
            type: [Array, Object, String],
            default: null,
        },
        vertical: {
			type: Boolean,
            default: false
        },
    },
    data () {
        return {
            selectedIndex: 0,
            tabs: [],
        }
    },
    created () {
        this.tabs = this.$children;
    },
    mounted () {
        this.onSelectedTabEvent(this.activeTab);
    },
    methods: {
        onSelectedTabEvent(i) {
            this.selectedIndex = i;

            // loop over all the tabs
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);
            });

            this.$emit('change-tab', i);
        },
        getTabTitle(tab) {
			if(!tab.$slots.title) {
				return tab.title;
            }
			
	        return Slot.make(tab.$slots.title).toHtml();
        },
    }
}
</script>