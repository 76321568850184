<template>
    <player-layout :id="id"
                   title="Dispositions"
                   icon="fa fa-clock"
    >
        <PlayerDispositionsList :dispositions="dispositions"
                                :isLoading="isLoading"
        />
    </player-layout>
</template>

<script>
    import PlayerDispositionsList from "./Sections/PlayerDispositionsList";
    import PlayerDispositionService from "../../../../services/PlayerDispositionService";

    export default {
        name: "player-dispositions",
        components: {
            PlayerDispositionsList
        },
        props: ['id', 'auth_user'],
        data(){
            return {
                dispositions: [],
                isLoading: false
            };

        },
        methods: {
            getPlayerDispositions: function(){
                let vm = this;

                vm.isLoading = true;
                PlayerDispositionService.getPlayerDispositions(this.id, { params: { limit: "all"} })
                    .then(response => {
                        vm.dispositions = response.data.dispositions;
                    })
                    .finally(() => {
                        vm.isLoading = false;
                    });
            }
        },
        mounted: function(){
            if(this.$can("Player - View Dispositions")){
                this.getPlayerDispositions();
            }
        }
    }
</script>