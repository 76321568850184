var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "pagination wrap m-0" },
    [
      _c("li", [
        _c(
          "button",
          {
            staticClass: "pagination-button",
            class: {
              disabled: _vm.pagination.current_page === 1,
              small: _vm.small
            },
            attrs: {
              "aria-label": "First",
              disabled: _vm.pagination.current_page === 1
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.changePage(1)
              }
            }
          },
          [_vm._v("\n            First\n        ")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "button",
          {
            staticClass: "pagination-button",
            class: {
              disabled: _vm.pagination.current_page === 1,
              small: _vm.small
            },
            attrs: {
              "aria-label": "Prev",
              disabled: _vm.pagination.current_page === 1
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.changePage(_vm.pagination.current_page - 1)
              }
            }
          },
          [
            !_vm.small
              ? _c("span", [_vm._v("Prev")])
              : _c("i", {
                  staticClass: "fa fa-chevron-left",
                  attrs: { "aria-hidden": "false" }
                })
          ]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.pagesNumber, function(pageNumber) {
        return _c(
          "li",
          { class: { active: pageNumber == _vm.pagination.current_page } },
          [
            _c(
              "a",
              {
                staticClass: "pagination-button",
                class: { small: _vm.small },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.changePage(pageNumber)
                  }
                }
              },
              [_vm._v(_vm._s(pageNumber))]
            )
          ]
        )
      }),
      _vm._v(" "),
      _c("li", [
        _c(
          "button",
          {
            staticClass: "pagination-button",
            class: {
              disabled:
                _vm.pagination.current_page === _vm.pagination.last_page,
              small: _vm.small
            },
            attrs: {
              "aria-label": "Next",
              disabled: _vm.pagination.current_page === _vm.pagination.last_page
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.changePage(_vm.pagination.current_page + 1)
              }
            }
          },
          [
            !_vm.small
              ? _c("span", [_vm._v("Next")])
              : _c("i", {
                  staticClass: "fa fa-chevron-right",
                  attrs: { "aria-hidden": "false" }
                })
          ]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "button",
          {
            staticClass: "pagination-button",
            class: {
              disabled:
                _vm.pagination.current_page === _vm.pagination.last_page,
              small: _vm.small
            },
            attrs: {
              "aria-label": "Last",
              disabled: _vm.pagination.current_page === _vm.pagination.last_page
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.changePage(_vm.pagination.last_page)
              }
            }
          },
          [_vm._v("\n            Last\n        ")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }