var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        show: _vm.show,
        "close-on-backdrop": false,
        "close-on-esc": false,
        "add-body-classes": "text-left",
        title: "Copy Magic Link"
      },
      on: { close: _vm.toggleCopyMagicLink },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-end w-100"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light mr-2",
                      attrs: { disabled: !!_vm.isLoading },
                      on: { click: _vm.toggleCopyMagicLink }
                    },
                    [_vm._v("\n        Close\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success",
                      attrs: { disabled: !!_vm.isLoading },
                      on: { click: _vm.copyPasswordLessLoginLink }
                    },
                    [
                      !_vm.isLoading ? _c("span", [_vm._v("Copy")]) : _vm._e(),
                      _vm._v(" "),
                      _c("loader", {
                        attrs: { show: _vm.isLoading, type: "smaller" }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("magic-link-redirect-form-component", {
        on: {
          update: function($event) {
            _vm.redirect = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }