<template>
    <atbl-form-group label="View Type"
                     label-for="ViewType"
                     class="font-weight-bold"
    >
        <select v-model="changeViewType"
                id="ViewType"
                class="form-control"
                :disabled="isDisabled"
        >
            <option v-for="(item, index) in viewTypes"
                    :key="index"
                    :value="item"
                    :selected="item === value"
            >
                {{ item }}
            </option>
        </select>
    </atbl-form-group>
</template>

<script>
import MarketingService from '../../../../services/MarketingService';

export default {
    name: 'campaign-view-types-picker',
    props: {
        value: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: 'view_type'
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            viewTypes: [],
            isLoading: false,
        };
    },
    computed: {
        isDisabled() {
            return !!this.disabled || this.isLoading;
        },
        changeViewType: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            },
        },
    },
    async created() {
        await this.getViewTypes();
    },
    methods: {
        async getViewTypes() {
            this.isLoading = true;

            try {
                const response = await MarketingService.getCampaignsConfigsViewTypes();

                this.viewTypes = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    },
}
</script>
