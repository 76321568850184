var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-sidebar",
    {
      attrs: {
        fixed: "",
        minimize: _vm.getMinimizeSidebar,
        show: _vm.getShowSidebar
      },
      on: { "update:show": _vm.setShowSidebar }
    },
    [
      _c(
        "c-sidebar-brand",
        { staticClass: "d-md-down-none", attrs: { href: "/" } },
        [
          _c("img", {
            staticClass: "c-sidebar-brand-full",
            attrs: {
              src: _vm.$imageService.path("/logos/logo.png"),
              height: "50px"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("Navigation", {
        attrs: { badges: _vm.getInitializedBadgesData, user: _vm.user }
      }),
      _vm._v(" "),
      _c("c-sidebar-minimizer", {
        staticClass: "d-md-down-none",
        nativeOn: {
          click: function($event) {
            return _vm.setMinimizeSidebar(!_vm.getMinimizeSidebar)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }