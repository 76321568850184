var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    { attrs: { id: _vm.id, icon: "fa fa-ticket-alt", title: "Tickets" } },
    [
      _c(
        "template",
        { slot: "first" },
        [_c("player-tickets-search-form", { on: { search: _vm.search } })],
        1
      ),
      _vm._v(" "),
      _c("TicketsList", {
        attrs: {
          tickets: _vm.tickets,
          ticketsList: _vm.ticketsList,
          isLoading: _vm.isLoading
        },
        on: { getResults: _vm.getResults }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }