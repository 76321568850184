import getValidationErrors from '../../../utils/Error/GetValidationErrors';
import { clone } from '../../../utils/Json/Json';

export default {
    provide() {
        return {
            onPullPhoneNumbers: this.onPullPhoneNumbers,
            onSavePhoneNumbers: this.onSavePhoneNumbers,
            onUpdateAllowedTypes: this.onUpdateAllowedTypes,
            onUpdatePhoneNumberActiveStatus: this.onUpdatePhoneNumberActiveStatus,
            onUpdatePhoneNumberSelectedStatus: this.onUpdatePhoneNumberSelectedStatus,
            togglePullPhoneNumberWindow: this.togglePullPhoneNumberWindow,
        };
    },
    data() {
        return {
            showPullWindow: false,
            isPullLoading: false,
            pulledPhoneNumbers: [],
            pulledPhoneNumbersErrors: {},
        };
    },
    computed: {
        selectedPhoneNumbers() {
            return this.pulledPhoneNumbers.filter(item => !!item.include)
                .map(({ allowed_for, is_active, phone_number, provider, status }) => {
                    return {
                        allowed_for,
                        is_active,
                        phone_number,
                        provider,
                        status,
                    };
                });
        },
    },
    methods: {
        async onPullPhoneNumbers() {
            if (!this.$can('Phone Numbers - Pull')) {
                window.showMessage('You does not have permissions to pull phone numbers list.', false);

                return;
            }

            this.isPullLoading = true;
            this.showPullWindow = true;

            try {
                const response = await window.axios.get('/phone-numbers/pull');
                const { status, message, data } = response.data;

                window.showMessage(message, status);

                this.pulledPhoneNumbers = !!data && data.length
                    ? data.map(item => {
                        return {
                            ...item,
                            include: false,
                        };
                    })
                    : [];

                this.isPullLoading = false;
            } catch (error) {
                this.isPullLoading = false;

                window.flashBadResponse(error);
            }
        },
        async onSavePhoneNumbers() {
            if (!this.$can('Phone Numbers - Insert')) {
                window.showMessage('No permissions to save phone numbers list.', false);

                return;
            }

            if (!this.selectedPhoneNumbers.length) {
                window.showMessage('No phone numbers are selected.', false);

                return;
            }

            this.pulledPhoneNumbersErrors = {};
            this.isPullLoading = true;

            try {
                const response = await window.axios.post('/phone-numbers/insert', {
                    phoneNumbers: this.selectedPhoneNumbers
                });
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.showPullWindow = false;
                    this.pulledPhoneNumbers = [];
                    await this.fetchPhoneNumbersList();
                }

                this.isPullLoading = false;
            } catch (error) {
                this.isPullLoading = false;

                this.pulledPhoneNumbersErrors = getValidationErrors(error);

                window.flashBadResponse(error);
            }
        },
        onUpdateAllowedTypes(phoneNumber, name, value, type) {
            const allowed_for = !!value
                ? [...phoneNumber.allowed_for, type]
                : phoneNumber.allowed_for.filter(item => !item.includes(type));

            this.pulledPhoneNumbers = this.updatePhoneNumber(
                phoneNumber.phone_number,
                'allowed_for',
                allowed_for
            );
        },
        onUpdatePhoneNumberActiveStatus(phoneNumber, name, value) {
            this.pulledPhoneNumbers = this.updatePhoneNumber(
                phoneNumber,
                'is_active',
                value
            );
        },
        onUpdatePhoneNumberSelectedStatus(phoneNumber, name, value) {
            this.pulledPhoneNumbers = this.updatePhoneNumber(
                phoneNumber,
                'include',
                value
            );
        },
        updatePhoneNumber(phoneNumber, propertyName, value) {
            const phoneNumbers = clone(this.pulledPhoneNumbers);

            const phone = phoneNumbers.find(item => item.phone_number === phoneNumber);

            if (!phone) {
                window.showMessage('Phone number is not found.');

                return;
            }

            phone[propertyName] = value;

            return phoneNumbers;
        },
        togglePullPhoneNumberWindow() {
            this.showPullWindow = !this.showPullWindow;

            if (!this.showPullWindow) {
                this.pulledPhoneNumbers = [];
                this.pulledPhoneNumbersErrors = {};
            }
        },
    },
}