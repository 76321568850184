<template>
	<div class="d-flex flex-column justify-content-center align-items-center flex-grow-1" style="height: 100%">
		<span v-if="isLoading" class="spiner"><img src="/img/spiner.svg" alt="spiner"></span>
		<div v-if="status">
			<p v-if="!isLoading" class="success"><i class="fa fa-check"></i></p>
			<div :class="['alert', `alert-${alertState}`]">{{ message }}</div>
		</div>
		<div v-else>
			<p class="error"><i class="fa fa-times"></i></p>
			<div class="alert alert-danger">{{ message }}</div>
		</div>
		<h6 v-if="countDown >= 0">Redirect in {{ countDown }} seconds</h6>
	</div>
</template>

<script>
	import Cart from "../../../modules/cart/Cart";
	import Payment from "../../../modules/payment/Payment";
	
	export default {
		props: ['id'],
		data() {
			return {
				isLoading: false,
				success: '',
				error: '',
				countDown: -1,
				message: null,
				alertState: null,
				status: true
			}
		},
		methods: {
			redirect: function (id, ms) {
				const _vm = this;
				
				setTimeout(function () {
					window.location.href = _vm.buildUrl(`/players/${id}/bets`);
				}, ms);
				
				_vm.countDown = ms / 1000;
				_vm.counter();
			},
			counter: function () {
				if (this.countDown > 0) {
					setTimeout(() => {
						this.countDown -= 1;
						this.counter();
					}, 1000)
				}
			},
			handlePayment: function(){
				let _vm = this,
					playerId = Number(this.getParam("uID")),
					getCart = Cart.storage(null, playerId);
				
				if (!getCart) {
					throw new Error("Cart not found.");
				}
				
				_vm.isLoading = true;
				_vm.alertState = "warning";
				_vm.message = 'Transaction processing...';
				
				Payment.card({
						cart_id: getCart.reference_id,
						playerId: playerId,
						referenceTransactionId: _vm.id
					})
					.then(response => {
						let {status, message} = response.data;
						
						if (status) {
							Cart.clear(playerId);
						}
						
						_vm.message = message;
						_vm.status = status;
						_vm.alertState = status ? "success" : "danger";
						_vm.redirect(playerId, 3000);
					})
					.catch(this.flashError)
					.finally(() => {
						_vm.isLoading = false;
					});
			}
		},
		created() {
			this.handlePayment();
		}
	};
</script>

<style scoped>
.spiner {
	z-index: 5;
	width: 100px;
	height: 100px;
}

.spiner img {
	width: 100%;
}

.success {
	text-align: center;
}

.success i {
	font-size: 84px;
	line-height: 84px;
	color: #20a8d8;
}

.error {
	text-align: center;
}

.error i {
	font-size: 84px;
	line-height: 84px;
	color: #f86c6b;
}
</style>
