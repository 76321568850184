var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-sales" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-6" },
        [
          _c(
            "statistic-card",
            {
              staticClass: "mb-4 mb-sm-0",
              attrs: {
                crmLabel: "CRM",
                crmTotal: _vm.getStatisticsValue(),
                webLabel: "WEB",
                webTotal: _vm.getStatisticsValue("web")
              }
            },
            [
              _c("i", { staticClass: "fa fa-money-bill" }),
              _vm._v(" "),
              _c("p", { staticClass: "statistic-icon-label" }, [
                _vm._v(" Daily ")
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-sm-6" },
        [
          _c(
            "statistic-card",
            {
              staticClass: "mb-0",
              attrs: {
                bgColor: "bg-twitter",
                crmLabel: "CRM",
                crmTotal: _vm.getStatisticsValue("crm", "sales", "monthly"),
                webLabel: "WEB",
                webTotal: _vm.getStatisticsValue("web", "sales", "monthly")
              }
            },
            [
              _c("i", { staticClass: "fa fa-chart-pie" }),
              _vm._v(" "),
              _c("p", { staticClass: "statistic-icon-label" }, [
                _vm._v(" Monthly ")
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-sales-heading" }, [
      _c("p", { staticClass: "dashboard-sales-title" }, [_vm._v(" Sales ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }