import TypesConst from "../../../TypesConst";
import PlayerService from "../../../../services/PlayerService";

export default {
    [TypesConst.players.actions.FETCH_PLAYER_ACTION]({commit, state}, silent = false){
        return new Promise((resolve, reject) => {
            if (!silent) {
                commit(TypesConst.players.mutations.SET_PLAYER_LOADER, true);
            }

            PlayerService.fetchPlayer(state.playerId)
                .then(function (response) {
                    commit(TypesConst.players.mutations.SET_PLAYER_DATA, response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    window.flashError(error);
                    reject(error);
                })
                .finally(() => {
                    if (!silent) {
                        commit(TypesConst.players.mutations.SET_PLAYER_LOADER, false);
                    }
                });
        });
    },
};