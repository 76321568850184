<template>
    <atbl-form-group label="Bundle games"
                     label-for="Bundle games"
                     :horizontal="false" class="font-weight-bold"
    >
        <tree-select v-model="selectedBundles"
                     :multiple="true"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="bundles"
                     :disabled="!!disabled || !!isLoading"
                     :normalizer="(node) => ({id: node.id, label: node.title})"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
    </atbl-form-group>
</template>

<script>
import BundleService from '../../services/BundleService';

export default {
    name: 'BundleGamePicker',
    emits: [
        'input',
        'update',
    ],
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        name: {
            type: String,
            default: 'bundles'
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            bundles: [],
            isLoading: false
        };
    },
    computed: {
        selectedBundles: {
            get() {
                return !this.bundles.length ? [] : this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
    async created() {
        await this.fetchBundleGames();
    },
    methods: {
        async fetchBundleGames() {
            this.isLoading = true;

            const response = await BundleService.availableBundleList();

            this.bundles = response.data.map(b => {
                return {
                    id: b.id,
                    title: b.title,
                };
            })

            this.isLoading = false;
        },
    },
}
</script>