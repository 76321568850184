var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "player-play-picker-lines" },
    [
      !_vm.noLineControl
        ? _c("div", { staticClass: "line-control" }, [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "add-line",
                  attrs: {
                    disabled: !_vm.shouldAddMoreLines || _vm.hasSelectedFreeGame
                  },
                  on: {
                    click: function($event) {
                      return _vm.addNewLine(1)
                    }
                  }
                },
                [_vm._m(0)]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "add-line",
                  attrs: {
                    disabled: !_vm.shouldAddMoreLines || _vm.hasSelectedFreeGame
                  },
                  on: {
                    click: function($event) {
                      return _vm.addNewLine(5)
                    }
                  }
                },
                [_vm._m(1)]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "add-line",
                  attrs: {
                    disabled: !_vm.shouldAddMoreLines || _vm.hasSelectedFreeGame
                  },
                  on: {
                    click: function($event) {
                      return _vm.addNewLine(10)
                    }
                  }
                },
                [_vm._m(2)]
              ),
              _vm._v(" "),
              _vm.hasSelectedFreeGame
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.selectedFreeGame.meta.lines) +
                        " Free Line" +
                        _vm._s(_vm.selectedFreeGame.meta.lines > 1 ? "s" : "") +
                        "\n            "
                    )
                  ])
                : _c("span", [
                    _vm.shouldAddMoreLines
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                    (" +
                              _vm._s(_vm.currentLinesNumber) +
                              " / " +
                              _vm._s(_vm.maxLinesPerTicket) +
                              ")\n                "
                          )
                        ])
                      : _c("span", { staticClass: "add-line-message" }, [
                          _vm._v(
                            "\n                    Max lines reach.\n                "
                          )
                        ])
                  ])
            ]),
            _vm._v(" "),
            !_vm.hasSelectedFreeGame && _vm.currentLinesNumber > 1
              ? _c("div", [
                  !_vm.removeAllConfirm
                    ? _c(
                        "a",
                        {
                          staticClass: "remove-all-lines",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.removeAllConfirm = true
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash" }),
                          _vm._v(" Remove All\n            ")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.removeAllConfirm
                    ? _c(
                        "a",
                        {
                          staticClass: "remove-all-confirm",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onRemoveAllLinesEvent.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-check" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.removeAllConfirm
                    ? _c(
                        "a",
                        {
                          staticClass: "remove-all-cancel",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.removeAllConfirm = false
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-times" })]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorMessage.length > 0
        ? _c("div", { staticClass: "line-message" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.lines, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "player-play-picker-lines-line",
            class: {
              invalid: item.error.invalid
            }
          },
          [
            _c("span", { staticClass: "generated-number" }, [
              _vm._v("# " + _vm._s(index + 1))
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "lucky-dip",
                on: {
                  click: function($event) {
                    return _vm.luckyDip(index)
                  }
                }
              },
              [_vm._v("\n            Lucky Dip\n        ")]
            ),
            _vm._v(" "),
            _vm._l(item.numbers, function(numberItem, numberIndex) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.ucFirst(numberIndex)
                      },
                      expression:
                        "{\n                content: ucFirst(numberIndex)\n             }"
                    }
                  ],
                  key: numberIndex,
                  staticClass: "player-play-picker-lines-line-numbers",
                  class: {
                    disabled: item.random
                  }
                },
                _vm._l(
                  _vm.getNumberTypeConfiguration(numberIndex).numbers,
                  function(singleNumItem, singleNumIndex) {
                    return _c("input", {
                      key: singleNumIndex,
                      staticClass: "form-control",
                      class: {
                        invalid: _vm.validateInput(
                          index,
                          numberIndex,
                          singleNumIndex
                        )
                      },
                      attrs: { type: "text", disabled: item.random },
                      domProps: {
                        value: _vm.getNumberFromLine(
                          index,
                          numberIndex,
                          singleNumIndex
                        )
                      },
                      on: {
                        keyup: function($event) {
                          return _vm.changeLineNumber(
                            index,
                            numberIndex,
                            singleNumIndex,
                            $event
                          )
                        }
                      }
                    })
                  }
                ),
                0
              )
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    content: "Random"
                  },
                  expression:
                    "{\n                   content: 'Random'\n               }"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: { checked: item.random },
              on: {
                change: function($event) {
                  return _vm.updateRandom(index, $event)
                }
              }
            }),
            _vm._v(" "),
            _vm.lines.length > 1 &&
            !_vm.hasSelectedFreeGame &&
            !_vm.noLineControl
              ? _c(
                  "button",
                  {
                    staticClass: "remove-line",
                    on: {
                      click: function($event) {
                        return _vm.removeLine(index, $event)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-times",
                      attrs: { "aria-hidden": "false" }
                    })
                  ]
                )
              : _vm._e()
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "add-line-text" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "false" } }),
      _vm._v(" Add Line\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "add-line-text" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "false" } }),
      _vm._v(" Add 5 Lines\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "add-line-text" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "false" } }),
      _vm._v(" Add 10 Lines\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }