var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid commission-eligible-transactions-single-day"
    },
    [
      !_vm.isLoading
        ? [
            _c("div", { staticClass: "details-holder" }, [
              _c("div", [
                _c("div", { staticClass: "card card-list" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "general-list" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "row py-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm mb-2 mb-md-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("ID")
                            ]),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.user.id) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm mb-2 mb-md-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Name")
                            ]),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.user.name) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "card card-list" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "general-list" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "row py-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm mb-2 mb-md-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Plan")
                            ]),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.user.commission_plan.name) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm mb-2 mb-md-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Type")
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: [
                                  "badge",
                                  "badge-" +
                                    _vm.getCommissionPlanTypeColor(
                                      _vm.user.commission_plan.type
                                    )
                                ]
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.user.commission_plan.type) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm mb-2 mb-md-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Deposit")
                            ]),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(
                                    _vm.user.commission.total_sales
                                  )
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm bg-primary-20 py-2" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Commission")
                            ]),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.formatCurrency(
                                    _vm.user.commission.commission_payout
                                  )
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card card-list" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex align-items-center justify-content-between"
                },
                [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [_vm._v("Date:")]),
                    _vm._v(" " + _vm._s(_vm.filters.dateFrom) + "\n\t\t\t\t")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "general-list" },
                  [
                    _vm._m(5),
                    _vm._v(" "),
                    _vm._l(_vm.commissionEligibleTransactions, function(
                      ref,
                      index
                    ) {
                      var transaction_id = ref.transaction_id
                      var transaction = ref.transaction
                      return _c("div", { key: index, staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Player name")
                          ]),
                          _vm._v(" "),
                          _vm.$can("Player - View Details")
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/players/" +
                                      transaction.player_id +
                                      "/view",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(transaction.player_name) +
                                      " " +
                                      _vm._s(transaction.player_surname) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(transaction.player_name) +
                                    " " +
                                    _vm._s(transaction.player_surname) +
                                    "\n\t\t\t\t\t\t\t"
                                )
                              ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Transaction")
                          ]),
                          _vm._v(" "),
                          _vm.$can("Transactions - View Transaction Details")
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/transaction/" + transaction_id,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tT-" +
                                      _vm._s(transaction_id) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tT-" +
                                    _vm._s(transaction_id) +
                                    "\n\t\t\t\t\t\t\t"
                                )
                              ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6 col-lg" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Created By")
                            ]),
                            _vm._v(" "),
                            _c("created-by-badge", {
                              attrs: { created: transaction }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Amount")
                          ]),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.formatCurrency(transaction.amount)) +
                              "\n\t\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Created")
                          ]),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.utcToLocal(transaction.created_at)) +
                              "\n\t\t\t\t\t\t"
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    !_vm.isLoading
                      ? _c("items-not-found", {
                          attrs: {
                            items: _vm.commissionEligibleTransactions,
                            msg: "No transactions found"
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" User Details\n\t\t\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-none d-sm-flex py-2" }, [
      _c("div", { staticClass: "col-12 col-sm" }, [_vm._v("ID")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm" }, [_vm._v("Name")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-percent" }),
      _vm._v(" Commission Details\n\t\t\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-none d-sm-flex py-0" }, [
      _c("div", { staticClass: "col-12 col-sm" }, [_vm._v("Plan")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm" }, [_vm._v("Type")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm" }, [_vm._v("Deposit")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm  bg-primary-20 py-2" }, [
        _vm._v("Commission")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-list" }),
      _vm._v(" Commission transactions\n\t\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
        _vm._v("Player name")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
        _vm._v("Transaction")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [
        _vm._v("Created By")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [_vm._v("Amount")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg" }, [_vm._v("Created")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }