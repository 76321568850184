<template>
    <div class="card card-search">
        <div class="card-header">
            <i class='fa fa-search' aria-hidden='false'></i> Search
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col">
                    <atbl-input id="firstName" label="Name" type="text" class="font-weight-bold" placeholder="First Name" v-model="filters.name" />
                </div>
                <div class="col">
                    <atbl-input id="surname" label="Surname" class="font-weight-bold" type="text" placeholder="Surname" v-model="filters.surname" />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <atbl-form-group label="Bonuses" label-for="Bonuses" :horizontal="false" class="font-weight-bold">
                        <tree-select v-model="filters.bonuses"
                                     :multiple="true"
                                     :searchable="true"
                                     :clear-on-select="true"
                                     :options="bonusList"
                                     :normalizer="(node) => ({id: node.id, label: node.title})"
                        />
                    </atbl-form-group>
                </div>
            </div>
        </div>

        <div class="card-footer d-flex justify-content-between align-items-center">
            <div>
                <button type="submit" class="btn btn-sm btn-primary" @click="onSearch">
                    <i class="fa fa-search"></i> Search
                </button>
                <button type="reset" class="btn btn-sm btn-danger" @click="clearFilters">
                    <i class="fa fa-ban"></i> Clear
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import BonusService from '../../../../services/BonusService';

export default {
    name: 'player-bonuses-search',

    data() {
        const initFilters = {
            perPage: 25,
            name: null,
            surname: null,
            bonuses: [],
        };

        return {
            bonusList: [],
            filters: _.cloneDeep(initFilters),
            initFilters: _.cloneDeep(initFilters),
        };
    },

    watch: {
        filters: {
            handler: function () {
                this.onSearch();
            },
            deep: true
        }
    },

    mounted() {
        this.fetchBonuses();
    },

    methods: {
        fetchBonuses() {
            BonusService
                .index()
                .then((response) => {
                    this.bonusList = response.data.map(b => {
                        return {
                            id: b.id,
                            title: b.title,
                        };
                    });
                });
        },

        clearFilters() {
            this.filters = _.cloneDeep(this.initFilters);
        },

        onSearch: debounce(function () {
            this.$emit('input', this.filters);
        }, 500),
    },
}
</script>
