<template>
    <button v-if="$can('Player - Create/Update')"
            :class="{
                'btn-outline-success': !isLoading && !isPlayerActive,
                'btn-outline-danger': !isLoading && isPlayerActive
            }"
            v-tooltip="{
                content: `${isPlayerActive ? 'Deactivate profile' : 'Activate profile'}`
            }"
            size="sm"
            class="btn btn-sm"
            @click="changeActiveState"
    >
        <span v-if="!isLoading">
            {{ isPlayerActive ? "Deactivate" : "Activate" }}
        </span>
        <loader :show="isLoading"
                type="smaller"
        />
    </button>
</template>

<script>
import PlayerService from '../../../../../services/PlayerService.vue';
import Loader from '../../../../../components/Widgets/Loader.vue';

export default {
    name: "activate-player-profile",
    inject: [
        'setPlayerModel',
    ],
    components: {
        Loader,
    },
    props: {
        player: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        isPlayerActive() {
            return !!this.player.activated_at;
        },
    },
    methods: {
        async changeActiveState() {
            this.isLoading = true;

            try {
                const response = await PlayerService.activatePlayer(this.player.id);
                let { status, message, data } = response.data;
				
	            window.showMessage(message, status);

                if (!status) {
                    this.isLoading = false;

                    return;
                }

                this.setPlayerModel({
                    ...this.player,
                    activated_at: data.date
                });

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
    }
}
</script>