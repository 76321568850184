<template>
    <div v-if="$can('Payment - Manual Wallet Refunds')"
         class="card m-0"
    >
        <div class="card-body py-3">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <agent-picker v-model="agent" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <AmountInput v-model="amount"
                                 :check-limit="true"
                    />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <atbl-form-group label="Note" label-for="note" class="font-weight-bold">
                        <input v-model="note"
                               name="note"
                               type="text"
                               placeholder="Leave a note"
                               class="form-control"
                        />
                    </atbl-form-group>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <atbl-number label="Transaction Id"
                                 name="transactionId"
                                 v-model="transactionId"
                                 placeholder="Enter transaction ID"
                                 :max="false"
                    />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <button class="btn"
                            :class="{
                                'btn-primary': !getPaymentLoader,
                                'btn-outline-primary': getPaymentLoader
                            }"
                            @click="setRefundsConfirmModal(true)"
                            :disabled="isSaveEnabled"
                    >
                        <span v-if="!getPaymentLoader">Add</span>
                        <loader type="smaller"
                                :show="getPaymentLoader"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../store/NamespaceConst";
    import {createNamespacedHelpers} from "vuex";

    const {
        mapGetters: mapPlayersGetters,
        mapMutations: mapPlayersMutations,
    } = createNamespacedHelpers(NamespaceConst.players);

    const {
        mapMutations: mapTransactionsMutations,
        mapActions: mapTransactionsActions
    } = createNamespacedHelpers(NamespaceConst.transactions);

    const {
	    mapGetters: mapPaymentGetters
    } = createNamespacedHelpers(NamespaceConst.payment);

    import AmountInput from "../../Funds/Components/AmountInput";

    export default {
        name: "AddManualRefunds",
        components: {
            AmountInput
        },
        computed: {
            ...mapPlayersGetters({
                getFundsAmount: TypesConst.players.getters.GET_FUNDS_AMOUNT,
                getFundsTransactionId: TypesConst.players.getters.GET_FUNDS_TRANSACTION_ID,
                getFundsCredit: TypesConst.players.getters.GET_FUNDS_CREDIT_TYPE,
                getPlayerId: TypesConst.players.getters.GET_PLAYER_ID,
                getFundsAgent: TypesConst.players.getters.GET_FUNDS_AGENT,
                getFundsMeta: TypesConst.players.getters.GET_FUNDS_META,
            }),
	        ...mapPaymentGetters({
		        getPaymentLoader: TypesConst.payment.getters.GET_PAYMENT_LOADER
	        }),
            amount: {
                get: function () {
                    return this.getFundsAmount;
                },
                set: function (value) {
                    this.setFundsAmount(value);
                }
            },
            agent: {
                get: function () {
                    return this.getFundsAgent;
                },
                set: function (value) {
                    this.setFundsAgent(value);
                }
            },
            note: {
                get: function () {
                    return this.getFundsMeta.note;
                },
                set: function (value) {
                    this.setFundsMeta({key: 'note', value: value});
                }
            },
            transactionId: {
                get: function () {
                    return this.getFundsTransactionId;
                },
                set: function (value) {
                    this.setFundsTransactionId(value)
                }
            },
            isSaveEnabled: function () {
                if (this.amount === 0 || this.amount > this.amountLimit) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        methods: {
            ...mapPlayersMutations({
                setFundsAmount: TypesConst.players.mutations.SET_FUNDS_AMOUNT,
                setFundsMeta: TypesConst.players.mutations.SET_FUNDS_META,
                setFundsAgent: TypesConst.players.mutations.SET_FUNDS_AGENT,
                setFundsTransactionId: TypesConst.players.mutations.SET_FUNDS_TRANSACTION_ID,
                setRefundsConfirmModal: TypesConst.players.mutations.SET_REFUNDS_CONFIRM_MODAL
            }),
            ...mapTransactionsMutations({
                setTransactionId: TypesConst.transactions.mutations.SET_TRANSACTIONS_TRANSACTION_ID
            }),
            ...mapTransactionsActions({
                getTransactionById: TypesConst.transactions.actions.GET_TRANSACTION_BY_ID
            }),
        }
    }
</script>