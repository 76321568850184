<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i class="fa fa-pound-sign"></i> Default payment currency
                </div>

                <button class="btn btn-sm btn-primary" @click="saveDefaultCurrency" v-if="$can('Conversions Rate - Create/Update')">
                    Save
                </button>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-8">
                        <atbl-form-group label="Select default system currency" class="font-weight-bold" :horizontal="false">
                            <select class="form-control" v-model="defaultCurrency">
                                <option :value="null">Select default currency</option>
                                <option v-for="currencyRate in conversions" :value="currencyRate.currency_code" :key="currencyRate.id">{{ currencyRate.currency_code }}</option>
                            </select>
                        </atbl-form-group>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span><i class='fa fa-pound-sign'></i> Conversions Rates</span>
                <button class="btn btn-sm btn-primary float-right py-1 px-2 m-0"
                          @click="editModal = true"
                          v-if="$can('Conversions Rate - Create/Update')"
                >
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg">Id</div>
                        <div class="col-12 col-lg">Currency Code</div>
                        <div class="col-12 col-lg">Currency Name</div>
                        <div class="col-12 col-lg">Rate</div>
                        <div class="col-12 col-lg">Updated</div>
                        <div class="col-12 col-lg"
                               v-if="$canAny(ratesOperationPermissions)"
                        >
                            Operations
                        </div>
                    </div>

                    <div v-if="!isLoading"
                           v-for="(item, index) in conversions"
                           :key="index"
                           class="row"
                    >
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Id</span>
                            {{ item.id }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Currency Code</span>
                            {{ item.currency_code }}
                        </div>
                         <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Currency Name</span>
                            {{ item.currency_name }}
                        </div>
                        <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Rate</span>
                            {{ item.rate }}
                        </div>
                         <div class="col-12 col-lg mb-1 mb-lg-0">
                            <span class="small-mb-badge">Updated</span>
                            {{  utcToLocal(item.updated_at) }}
                        </div>
                        <div class="col-12 col-lg"
                               v-if="$canAny(ratesOperationPermissions)"
                        >
                            <span class="small-mb-badge">Operations</span>
                            <button class="btn btn-sm btn-warning"
                                      @click="edit(item.id)"
                                      v-if="$can('Conversions Rate - Create/Update')"
                            >
                                Edit
                            </button>
                            <button class="btn btn-sm btn-danger"
                                      @click="del(item.id)"
                                      v-if="$can('Conversions Rate - Remove')"
                            >
                                Delete
                            </button>
                        </div>
                    </div>

                    <not-found v-if="!isLoading"
                               :items="conversions"
                               msg="No conversion rates found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>
        </div>

        <CreateCurrencyRateModal :editModal="editModal"
                                 :model="model"
                                 :errors="errors"
                                 @cancel="cancelModal"
                                 @on-edit="submitModal"
        />

        <RemoveCurrencyRateModal :removeModal="removeModal"
                                 :currencyType="model.currency_code"
                                 @cancel="cancelRemoveModal"
                                 @on-edit="submitRemoveModal"
        />
    </div>
</template>

<script>
    import CreateCurrencyRateModal from "./Modals/CreateCurrencyRateModal";
    import RemoveCurrencyRateModal from "./Modals/RemoveCurrencyRateModal";

    export default {
        name: "rates-conversions",
        components: {
            CreateCurrencyRateModal,
            RemoveCurrencyRateModal
        },
        data(){
            return {
                defaultCurrency: null,
                conversions: [],
                model: {
                    id: 0,
                    currency_code: null,
                    currency_name: null,
                    rate: null
                },
                editModal: false,
                removeModal: false,
                errors: {},
                isLoading: false,
                ratesOperationPermissions: [
                    "Conversions Rate - Create/Update",
                    "Conversions Rate - Remove"
                ]
            };
        },
        methods: {
            getDefaultCurrency() {
                window.axios.get('/exchange-office/default').then((response) => {
                    this.defaultCurrency = response.data.code;
                });
            },

            getConversionsRates: function(){
                let vm = this;

                vm.isLoading = true;
                return window.axios.get("/rates/conversions/conversion").then(function (response) {
                    vm.conversions = response.data;
                })
                    .finally(() => {
                        vm.isLoading = false;
                    });
            },
            edit: function(id) {
                let vm = this;

                this.getCurrency(id, function(){
                    vm.editModal = true;
                });
            },
            del: function(id){
                let vm = this;

                this.getCurrency(id, function(){
                    vm.removeModal = true;
                });
            },
            cancelModal: function(value){
                if(typeof value !== "undefined"){
                    this.model = value;
                    this.clearModel();
                }

                this.editModal = false;
            },
            cancelRemoveModal: function(){
                this.removeModal = false;
                this.clearModel();
            },
            submitModal: function(value){
                let vm = this,
                    message = "";

                vm.model = value;

                if(vm.model.id === 0){
                    message = "Error while adding new conversions rate.";

                    window.axios.post(`/rates/conversions/conversion`, vm.model).then(function(response){
                        let data = response.data;

                        if(data.status){
                            message = "Successfully create new conversion rate.";
                            vm.getConversionsRates();
                            flash(message);
                        }else {
                            if(data.exists){
                                flash(`${vm.model.currency_code} already exists.`, "alert-danger");
                            }
                        }

                        vm.editModal = false;
                        vm.clearModel();
                    }).catch(function(error){
                        vm.errors = error.response.data.errors;
                    });
                }else {
                    message = "Error while edit current conversions rate.";

                    window.axios.patch(`/rates/conversions/conversion/${this.model.id}`, this.model).then(function(response){
                        let data = response.data;

                        if(data.status){
                            message = "Successfully edited.";
                            vm.editModal = false;

                            vm.clearModel();
                            vm.getConversionsRates();
                        }

                        flash(message)
                    }).catch(function(error){
                        vm.errors = error.response.data.errors;
                    });
                }
            },
            submitRemoveModal: function(){
                let vm = this,
                    message = "Error while removing conversion.";

                window.axios.delete(`/rates/conversions/conversion/${this.model.id}`).then(function(response){
                    let data = response.data;

                    if(data.status){
                        message = "Successfully removed conversion.";
                        vm.removeModal = false;
                        vm.clearModel();
                        vm.getConversionsRates();
                    }

                    flash(message);
                });
            },
            getCurrency: function(id, callback){
                let vm = this;

                window.axios.get(`/rates/conversions/conversion/${id}`).then(function(response) {
                    let data = response.data;
                    vm.model.id = data.id;
                    vm.model.currency_code = data.currency_code;
                    vm.model.currency_name = data.currency_name;
                    vm.model.rate = data.rate;
                    callback(response);
                });
            },
            clearModel: function(){
                this.model = {
                    id: 0,
                    currency_code: null,
                    currency_name: null,
                    rate: null
                };
                this.errors = {};
            },

            saveDefaultCurrency() {
                window.axios.post('/exchange-office/default', {code: this.defaultCurrency}).then(() => {
                    window.showMessage('The default currency was updated');
                }).catch(() => {
                    window.showMessage('Default currency can not be changed', false);
                });
            },
        },
        mounted(){
            this.getConversionsRates().then(() => {
                this.getDefaultCurrency();
            })
        }
    }
</script>