<template>
    <atbl-form-group id="campaign-status"
                     label="Status"
                     label-for="campaignStatus"
                     class="font-weight-bold"
                     :horizontal="horizontal"
                     :error="getError(errors, 'status')"
    >
        <select id="campaignStatus"
                class="form-control"
                :disabled="isLoading || disabled"
                @change="changed"
        >
            <option :value="null" :disabled="disableFirstOption">Select status</option>
            <option v-for="(item, index) in statuses"
                    :key="index"
                    :value="item"
                    :selected="item === value"
            >
                {{ item }}
            </option>
        </select>
    </atbl-form-group>
</template>

<script>
import MarketingService from '../../../../services/MarketingService';

export default {
    name: 'campaign-status-picker',
    props: {
        value: {
            type: String,
            default: null
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disableFirstOption: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            statuses: [],
        };
    },
    async created() {
        await this.fetchCampaignConfigs();
    },
    methods: {
        async fetchCampaignConfigs() {
            this.isLoading = true;

            try {
                const response = await MarketingService.getCampaignsConfigsStatuses();

                this.statuses = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        changed($event) {
            this.$emit('input', $event.target.value);
        }
    },
}
</script>