<template>
    <div class="card card-list" v-if="gameNumberConfigurations">
        <div class="card-header d-flex align-items-center justify-content-between">
            <span><i class='fa fa-align-justify'></i> Ticket Lines</span>
        </div>
        <div class="card-body ticket-lines-card-body">
            <div class="row d-none d-lg-flex leads-section-list-row">
                <div class="col-12 col-lg-1 text-muted">#Line</div>
                <div class="col-12 col-lg-2 text-muted">Reference</div>
                <div class="col-12 col-lg-1 text-muted">Game</div>
                <div class="col-12 col-lg-1 text-muted">Draw Date</div>
                <div class="col-12 col-lg-5 text-muted">Numbers</div>
                <div class="col-12 col-lg-2 text-muted">Status</div>
            </div>

            <div class="row leads-section-list-row" v-for="(ticketLine, index) in lines" :key="`ticket_line_${index}`">
                <div class="col-12 col-lg-1 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">#Line</span>
                    #L-{{ index + 1 }}
                </div>

                <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">Reference</span>
                    <a :href="`/bundle-tickets/${bundleTicket.id}`" class="d-block">
                        <strong>Bundle Ticket #{{ bundleTicket.id }}</strong>
                    </a>
                    <a :href="`/orders/${bundleTicket.order_id}`">Order #{{ bundleTicket.order_id }}</a>
                </div>

                <div class="col-12 col-lg-1 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">Game</span>
                    <img :src="$imageService.gameImage(ticketLine.ticket.game.image)" class="img-fluid" width="40" alt="" />
                </div>

                <div class="col-12 col-lg-1 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">Draw Date</span>
                    {{ ticketLine.ticket.date }}
                </div>

                <div class="col-12 col-lg-5 text-center align-self-center text-center text-lg-left mb-2 mb-lg-0">
                    <span class="small-mb-badge">Numbers</span>

                    <div class="numbers-group">
                        <template v-for="(group, key) in getLineNumbers(ticketLine, getGameConfiguration(ticketLine.ticket.game.key))">
                            <div v-if="Array.isArray(group)" :class="`numbers-group-wrapper ${key}-numbers`" v-tooltip="{content: key}">
                                <span v-for="number in group" :key="key + number" :class="[key, isMatch(ticketLine.ticket, number, key) ? 'match' : '', typeResolver(key)]">
                                    {{ number }}
                                </span>
                            </div>

                            <div v-else class="d-flex" v-tooltip="{content: key}">
                                <span class="mr-1" :class="[key, isMatch(ticketLine.ticket, group, key) ? 'match' : '', typeResolver(key), getGameNumbersColor(ticketLine.ticket.game.key, key)]">
                                    {{ group }}
                                </span>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="col-12 col-lg-2 align-self-center text-center text-lg-left">
                    <span class="small-mb-badge">Status</span>
                    {{ lineWinStatus(ticketLine) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TicketMixin from '../../../../mixins/TicketMixin';
import GameConfigurationService from '../../../../services/GameConfigurationService';

export default {
    name: 'bundle-ticket-lines',

    mixins: [
        TicketMixin
    ],

    props: ['bundleTicket'],

    data() {
        return {
            gameNumberConfigurations: null,
        };
    },

    computed: {
        bundle() {
            return this.bundleTicket.bundle;
        },

        tickets() {
            return this.bundleTicket.tickets;
        },

        lines() {
            const lines = [];

            this.bundleTicket.tickets.forEach(t => {
                const ticketLines = t.draw.lines.map(l => {
                    return {
                        ticket: t,
                        line: l,
                    };
                });

                lines.push(...ticketLines);
            });

            return lines;
        },
    },

    mounted() {
        this.fetchGameConfiguration();
    },

    methods: {
        fetchGameConfiguration() {
            GameConfigurationService.index()
                .then((response) => {
                    this.gameNumberConfigurations = response.data.map(c => {
                        return {
                            key: c.key,
                            numbers: c.numbers,
                        }
                    });
                });
        },

        getGameConfiguration(gameKey) {
            return this.gameNumberConfigurations.find(c => c.key === gameKey).numbers;
        },

        getGameNumbersColor(gameKey, type) {
            return this.numbersGroups[gameKey][type];
        },

        getLineNumbers(ticketLine) {
            const ticket = ticketLine.ticket;
            const ticketDraw = ticket.draw;
            const configuration = ticket.numbers;
            const lineNumbers = ticketLine.line.line.numbers;
            const extraNumbersConfiguration = configuration.extra
            let extraNumbers = {};

            if (!ticket.draw.numbers) {
                return {
                    ...lineNumbers,
                    ...extraNumbers
                };
            }

            const extraDrawNumbers = JSON.parse(ticketDraw.numbers);

            for (let item in extraDrawNumbers) {
                let currentExtraNumber = extraDrawNumbers[item],
                    hasKey = extraNumbersConfiguration.filter(extraItem => extraItem.key === item)[0] || null;

                if (hasKey) {
                    extraNumbers = {
                        ...extraNumbers,
                        ...{
                            [item]: currentExtraNumber
                        }
                    };
                }
            }

            return {
                ...lineNumbers,
                ...extraNumbers
            };
        },

        isMatch(ticket, num, key) {
            const gameDraw = ticket.draw.game_draw;

            if (!gameDraw || !gameDraw.numbers) {
                return false;
            }

            const gameDrawNumbers = JSON.parse(gameDraw.numbers);

            if (!gameDrawNumbers.hasOwnProperty(key)) {
                return false;
            }

            let numbers = gameDrawNumbers[key];

            if (Array.isArray(numbers)) {
                return numbers.includes(num.toString()) || numbers.includes(num);
            }

            return numbers === num || numbers.toString() === num.toString();
        },

        lineWinStatus(ticketLine) {
            const prize = ticketLine.line.prize;

            if (prize === null) {
                return 'No status';
            }

            const prizeFormatted = this.$options.filters.formatCurrency(prize);

            return prize === 0 ? 'Not a winning line' : `Won: ${prizeFormatted}`;
        },
    },
}
</script>

<style lang="scss" scoped>
.ticket-results {
    .raffle {
        font-family    : monospace;
        font-size      : 16px;
        font-weight    : bold;
        letter-spacing : 4px;
    }
}
</style>