import store from '../store';
import utils from '../utils'

export default function (value, decimalLength, wholeLength, wholeDelimiter, decimalDelimiter, withSymbol = true) {
    decimalLength = decimalLength === undefined ? 2 : decimalLength;

    const formatted = utils.formatNumber(value, decimalLength, decimalDelimiter, ',');

    if(withSymbol) {
        const currencySymbol = store.getters['exchangeOffice/htmlSymbol'];

        return `${currencySymbol} ${formatted}`;
    }

    return formatted;
};