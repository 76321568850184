var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        size: "lg",
        title: "Add/Edit leads",
        show: true,
        "close-on-backdrop": false
      },
      on: { input: _vm.toggleAddLeadModal },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex w-100 align-items-center" }, [
                _c("div", { staticClass: "w-50 font-weight-bold" }, [
                  _vm._v("\n                * Required\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      on: { click: _vm.toggleAddLeadModal }
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                  _vm._v(" "),
                  _vm.$can("Leads - Create/Edit")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm",
                          class: {
                            "btn-primary": !_vm.isLoading,
                            "btn-outline-primary": _vm.isLoading,
                            disabled: _vm.isLoading
                          },
                          attrs: { disabled: _vm.isLoading },
                          on: { click: _vm.onCreateUpdateLead }
                        },
                        [
                          !_vm.isLoading
                            ? _c("span", [
                                _vm._v(
                                  "\n                        Save\n                    "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loader", {
                            attrs: { type: "small", show: _vm.isLoading }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Name *",
                    name: "name",
                    errors: _vm.errors,
                    value: _vm.model.name
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Surname",
                    name: "surname",
                    errors: _vm.errors,
                    value: _vm.model.surname
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Email",
                    name: "email",
                    errors: _vm.errors,
                    value: _vm.model.email
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("birthday-picker", {
                  attrs: {
                    value: _vm.model.birthdate,
                    error: _vm.getError(_vm.errors, "birthdate"),
                    "auto-apply": true,
                    format: "YYYY-MM-DD"
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Address 1",
                    name: "address1",
                    errors: _vm.errors,
                    value: _vm.model.address1
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Address 2",
                    name: "address2",
                    errors: _vm.errors,
                    value: _vm.model.address2
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-3" },
              [
                _c("countries-options", {
                  attrs: { value: _vm.model.country, error: _vm.errors },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-3" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "State",
                    name: "state",
                    errors: _vm.errors,
                    value: _vm.model.state
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-3" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "City",
                    name: "city",
                    errors: _vm.errors,
                    value: _vm.model.city
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-3" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Postcode",
                    name: "postcode",
                    errors: _vm.errors,
                    value: _vm.model.postcode
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("tel-number", {
                  attrs: {
                    label: "Phone *",
                    id: "Phone",
                    placeholder: "Enter phone",
                    name: "phone",
                    error: _vm.errors
                  },
                  model: {
                    value: _vm.phone,
                    callback: function($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("tel-number", {
                  attrs: {
                    label: "Mobile *(required if no phone)",
                    id: "Mobile",
                    placeholder: "Enter mobile",
                    name: "mobile",
                    error: _vm.errors
                  },
                  model: {
                    value: _vm.mobile,
                    callback: function($$v) {
                      _vm.mobile = $$v
                    },
                    expression: "mobile"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("atbl-number", {
                  attrs: {
                    label: "Live Spent",
                    name: "live_spent",
                    errors: _vm.errors,
                    value: _vm.model.live_spent
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-textarea", {
                  attrs: {
                    label: "Other",
                    name: "other",
                    errors: _vm.errors,
                    value: _vm.model.other
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }