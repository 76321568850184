export default {
    provide() {
        return {
            deleteShift: this.deleteShift,
            toggleDeleteShiftModal: this.toggleDeleteShiftModal,
        };
    },
    data() {
        return {
            deleteShiftLoader: false,
            deleteShiftModalOpen: false,
            selectedShift: null,
        };
    },
    methods: {
        async getShiftDetailsForDelete(id) {
            if (!this.$can('Shifts - Details')) {
                window.showMessage('No permissions for this action.', false);

                return;
            }

            this.deleteShiftLoader = true;
            this.toggleDeleteShiftModal();

            try {
                const response = await window.axios.get(`/shifts/${id}/details`);
                const { status, message, data } = response.data;

                window.showMessage(message, status);

                this.selectedShift = data;

                this.deleteShiftLoader = false;
            } catch (error) {
                this.deleteShiftLoader = false;

                window.flashError(error);
            }
        },
        async deleteShift() {
            if (!this.$can('Shifts - Remove')) {
                window.showMessage('No permissions for this action.', false);

                return;
            }

            if (!this.selectedShift) {
                window.showMessage('No selected shift.', false);

                return;
            }

            const { id } = this.selectedShift;

            this.deleteShiftLoader = true;

            try {
                const response = await window.axios.delete(`/shift/${id}`);
                const { status, message } = response.data;

                window.showMessage(message, status);

                if (!!status) {
                    this.toggleDeleteShiftModal();

                    await this.fetchShifts();
                }

                this.deleteShiftLoader = false;
            } catch (error) {
                this.deleteShiftLoader = false;

                window.flashError(error);
            }
        },
        toggleDeleteShiftModal() {
            this.deleteShiftModalOpen = !this.deleteShiftModalOpen;

            if (!this.deleteShiftModalOpen) {
                this.selectedShift = null;
            }
        },
    },
}