<script>
    export default {
        gameDrawsRoute: function(){
            return "/games/draws";
        },
        getGameDrawConfigurationRoute(id){
            return `${this.gameDrawsRoute()}/configuration/${id}`;
        },
        getGamesDraws: function(){
            return window.axios.get(`${this.gameDrawsRoute()}/get`);
        },
        getCurrentGamesDraws: function(){
            return window.axios.get(`${this.gameDrawsRoute()}/current-draws`);
        },
        getGameDrawConfiguration: function(id){
            return window.axios.get(this.getGameDrawConfigurationRoute(id));
        },
        getRaffleGameDrawConfiguration: function(id){
            return window.axios.get(`${this.getGameDrawConfigurationRoute(id)}/raffle`);
        },
        saveGameDrawConfiguration: function(id, _params = {}){
            return window.axios.post(this.getGameDrawConfigurationRoute(id), _params);
        },
        saveRaffleGameDrawConfiguration: function(id, _params = {}){
            return window.axios.post(`${this.getGameDrawConfigurationRoute(id)}/raffle`, _params);
        }
    }
</script>