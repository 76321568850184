export default {
    getPlayerKycAttachments(id) {
        return window.axios.get(`/players/${id}/kyc/attachments`);
    },

    storePlayerKycAttachment(id, formData) {
        const requestOptions = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        return window.axios.post(
            `/players/${id}/kyc/attachments`,
            formData,
            requestOptions
        );
    },

    updatePlayerKycAttachment(playerId, attachmentId, params) {
        return window.axios.put(`/players/${playerId}/kyc/attachments/${attachmentId}`, params);
    },

    approvePlayerKycAttachment(playerId, attachmentId) {
        return window.axios.post(`/players/${playerId}/kyc/attachments/${attachmentId}/approve`);
    },

    declinePlayerKycAttachment(playerId, attachmentId) {
        return window.axios.post(`/players/${playerId}/kyc/attachments/${attachmentId}/decline`);
    },

    removePlayerKycAttachment(playerId, attachmentId) {
        return window.axios.delete(`/players/${playerId}/kyc/attachments/${attachmentId}`);
    },

    changePlayerKycStatus(playerId, params) {
        return window.axios.post(`/players/${playerId}/kyc/status`, params);
    },

    fetchKycAttachmentTypes() {
        return window.axios.get('/kyc-attachment-types');
    },

    sendPlayerMessage(playerId, message) {
        return window.axios.post(`/players/${playerId}/kyc/send-message`, { message });
    },

    editPlayerMessage(playerId, messagesId, message) {
        return window.axios.post(`/players/${playerId}/kyc/edit-message/${messagesId}`, { message });
    },
    
    fetchPlayerKycDiscussion(playerId) {
        return window.axios.get(`/players/${playerId}/kyc/messages`);
    },
}