var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid report-sales-days" }, [
    _c("div", { staticClass: "card card-search" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-8 col-lg-4" },
            [
              _c(
                "atbl-form-group",
                {
                  staticClass: "font-weight-bold mb-0",
                  attrs: {
                    label: "Date range",
                    "label-cols": 3,
                    horizontal: false
                  }
                },
                [
                  _c("multi-date-picker", {
                    attrs: {
                      "show-ranges": true,
                      "auto-apply": true,
                      clearable: _vm.isChanged,
                      format: "YYYY-MM-DD"
                    },
                    model: {
                      value: _vm.selectedCreatedDates,
                      callback: function($$v) {
                        _vm.selectedCreatedDates = $$v
                      },
                      expression: "selectedCreatedDates"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card card-list" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-pound-sign mr-1" }),
        _vm._v(
          " Sales ( " +
            _vm._s(_vm.dateRange.startDate + " - " + _vm.dateRange.endDate) +
            " )\n        "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "section",
          { staticClass: "sales general-list" },
          [
            _vm._m(2),
            _vm._v(" "),
            !_vm.isLoading && !!_vm.salesData.length
              ? _vm._l(_vm.salesData, function(sale, index) {
                  return _c("div", { key: index, staticClass: "row py-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Day")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(sale.report_date) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Tickets")
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v("Tickets: " + _vm._s(sale.tickets))]),
                        _vm._v(" "),
                        _c("div", [_vm._v("Lines: " + _vm._s(sale.lines))])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Deposits")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm._f("formatCurrency")(sale.deposits)) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Dep. CRM")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("formatCurrency")(sale.deposits_crm)
                            ) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Dep. Web")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("formatCurrency")(sale.deposits_web)
                            ) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Bonus")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm._f("formatCurrency")(sale.bonus)) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Total")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm._f("formatCurrency")(sale.sales)) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Winners")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(sale.winners) +
                            "\n                            "
                        ),
                        _c("div", [
                          _vm._v("Unique: " + _vm._s(sale.unique_winners))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Winnings")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm._f("formatCurrency")(sale.winnings)) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Withdrawals")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm._f("formatCurrency")(sale.withdrawals)) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Refunds")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm._f("formatCurrency")(sale.refunds)) +
                            "\n                        "
                        )
                      ]
                    )
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isLoading && _vm.salesData.length
              ? _c("div", { staticClass: "row last" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-sm-4 col-lg mb-1 mb-lg-0 d-none d-lg-flex justify-content-end"
                    },
                    [
                      _vm._v(
                        "\n                        Total\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Items")
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("Tickets: " + _vm._s(_vm.total.tickets))
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v("Lines: " + _vm._s(_vm.total.lines))])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Deposits")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm._f("formatCurrency")(_vm.total.deposits)) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Dep. CRM")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("formatCurrency")(_vm.total.deposits_crm)
                          ) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Dep. Web")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("formatCurrency")(_vm.total.deposits_web)
                          ) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Bonus")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm._f("formatCurrency")(_vm.total.bonus)) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Sales")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm._f("formatCurrency")(_vm.total.sales)) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Winners")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.total.winners) +
                          "\n                        "
                      ),
                      _c("div", [
                        _vm._v("Unique: " + _vm._s(_vm.total.unique_winners))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Winnings")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm._f("formatCurrency")(_vm.total.winnings)) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Withdrawals")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("formatCurrency")(_vm.total.withdrawals)
                          ) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-sm-4 col-lg mb-1 mb-lg-0" },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Total Refunds")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm._f("formatCurrency")(_vm.total.refunds)) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("not-found", {
                  attrs: { items: _vm.salesData, msg: "No sales found." }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-search mr-1" }),
      _vm._v(" Search\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "mr-1", attrs: { href: "/reports/sales" } }, [
      _c("i", { staticClass: "fa fa-chevron-left" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row sticky-element" }, [
      _c("div", { staticClass: "col" }, [_vm._v("Day")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Items")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Deposits")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Dep. CRM")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Dep. Web")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Bonus")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Sales")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Winners")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Winnings")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Withdrawals")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("Refunds")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }