<template>
    <atbl-form-group :label="label"
                     :label-for="getLabelFor"
                     :error="getError(allErrors, name)"
                     :label-class="labelClass"
                     class="atbl-file-upload"
    >
        <input :id="getLabelFor"
               :accept="accept.map(extension => `.${extension}`).join(',')"
               type="file"
               ref="file"
               @change="onFileUpdateEvent($refs.file.files[0])"
        />
        <a v-if="!!value"
           href="#"
           @click.prevent="update(null)"
        >
            Clear
        </a>
    </atbl-form-group>
</template>

<script>
import { isObjectEmpty } from '../../../utils/Object/Object';
import ATBLFormControls from './ATBLFormControls';

export default {
    name: 'ATBLFileUpload',
    mixins: [
        ATBLFormControls
    ],
    props: {
        accept: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            localErrors: {
                [this.name]: []
            },
        };
    },
    computed: {
        allErrors() {
            if (!isObjectEmpty(this.localErrors)) {
                return this.localErrors;
            }

            return this.errors;
        },
    },
    methods: {
        onFileUpdateEvent(file) {
            this.localErrors[this.name] = [];

            if (!this.accept.length) {
                return this.update(file);
            }

            if(!file) {
                return this.update(null);
            }

            const extensionTemp = file.name.split('.');
            const extension = extensionTemp[extensionTemp.length - 1];

            if(!this.accept.includes(extension)) {
                this.$refs.file.value = null;

                this.localErrors[this.name].push(`Only ${this.accept.join('.')} are allowed for upload.`);;

                return;
            }

            return this.update(file);
        },
    },
    watch: {
        value: {
            handler(value) {
                if(!!value) {
                    return;
                }

                this.$refs.file.value = null;
            },
        },
    },
}
</script>