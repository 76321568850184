<template>
    <b-card>
        <div>
            <b-nav vertical>
                <b-nav-text class="border-bottom">CRM</b-nav-text>
                <b-nav-item :to="`/marketing/leads/${id}/view`">Profile</b-nav-item>
                <b-nav-item :to="`/marketing/leads/${id}/dispositions`">Dispositions</b-nav-item>
            </b-nav>
        </div>
    </b-card>
</template>

<script>
    export default {
        name: "leads-preview-navigation",
        props: {
            id: {
                type: Number,
                required: true
            }
        }
    }
</script>