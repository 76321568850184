export const statuses = [
  {
      key: 'not-verified',
      label: 'Not verified',
      color: 'danger',
  },
  {
      key: 'in-process',
      label: 'In process',
      color: 'warning',
  },
  {
      key: 'verified',
      label: 'Verified',
      color: 'success',
  },
  {
      key: 'expired',
      label: 'Expired',
      color: 'secondary',
  },
  {
      key: 'force-verified',
      label: 'Force Verified',
      color: 'success',
  },
];

export function getKYCStatuses() {
  return statuses
    .map(({key, label}) => ({label, value: key}))
    .filter(item => item.value !== 'force-verified')
}

export function getAllKYCStatues() {
    return statuses
        .map(({key, label}) => ({label, value: key}))
}
