export default {
    index(params) {
        return window.axios.get('/bonuses', { params });
    },

    show(id) {
        return window.axios.get(`/bonuses/${id}`);
    },

    store(params) {
        return window.axios.post('/bonuses', params);
    },

    update(id, params) {
        return window.axios.post(`/bonuses/${id}`, params);
    },

    enable(id) {
        return window.axios.post(`/bonuses/${id}/enable`);
    },

    disable(id) {
        return window.axios.post(`/bonuses/${id}/disable`);
    },

    fetchTriggers() {
        return window.axios.get('/gamify/list/triggers');
    },

    fetchActions() {
        return window.axios.get('/gamify/list/actions');
    },

    fetchPlayerBonuses(playerId, params) {
        return window.axios.get(`/players/${playerId}/bonuses/index`, params);
    },
}