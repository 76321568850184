var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", { staticClass: "transaction-badge", class: _vm.getBadge }, [
      _vm._v(_vm._s(_vm.transaction.payment_type_beauty))
    ]),
    _vm._v(" "),
    _vm.reason.length ? _c("br") : _vm._e(),
    _vm._v(" "),
    _vm.reason.length
      ? _c("small", { staticClass: "d-block text-muted my-1" }, [
          _vm._v(_vm._s(_vm.reason))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("span", { class: ["badge", "badge-" + _vm.statusBadgeColor] }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm._f("capitalize")(_vm.transaction.status)) +
          "\n    "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }