var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    { staticClass: "mb-2", attrs: { id: _vm.id, title: "Call History" } },
    [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("ID")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("Agent")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("Call Status & Date")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
              },
              [_vm._v("Talk Time")]
            ),
            _vm._v(" "),
            _vm.$can("Call History - Recordings")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                  },
                  [_vm._v("\n                Audio\n            ")]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.callHistoryLoader
            ? _vm._l(_vm.callHistoryList, function(item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg-1 align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("ID")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.id) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Agent")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.user.name) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Call Status & Date")
                      ]),
                      _vm._v(" "),
                      _c("call-status-badge", {
                        attrs: { title: item.status }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(_vm._s(_vm.utcToLocal(item.created_at)))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Talk Time")
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("emptyField")(item.duration)) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.$can("Call History - Recordings")
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-md-4 col-lg align-self-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge" }, [
                            _vm._v("Audio")
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: "Recordings"
                                  },
                                  expression:
                                    "{\n                                content: 'Recordings'\n                            }"
                                }
                              ],
                              staticClass: "audio-link",
                              on: {
                                click: function($event) {
                                  return _vm.showCallRecordings(item.id)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-file-audio",
                                attrs: { "aria-hidden": "false" }
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            attrs: { show: _vm.callHistoryLoader, type: "small" }
          }),
          _vm._v(" "),
          !_vm.callHistoryLoader
            ? _c("not-found", {
                attrs: {
                  items: _vm.callHistoryList,
                  msg: "No call history found."
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.callHistoryLoader && _vm.callHistoryResponse.last_page > 1
            ? _c(
                "nav",
                { staticClass: "wrap py-3" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.callHistoryResponse },
                    on: { paginate: _vm.fetchPlayerCallHistory }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("call-history-filter-recordings-component", {
        attrs: {
          show: _vm.callHistoryRecordingsSidebarOpen,
          "is-loading": _vm.callHistoryRecordingsLoader,
          recordings: _vm.callHistoryRecordingsList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }