<script>
    import Treeselect from "@riophae/vue-treeselect";
    
    export default {
        extends: Treeselect,
        data() {
            return {
                overridesLastNodeId: 0,
            };
        },
        methods: {
            overridesFindValue() {
                if (this.$refs.control) {
                    const childRefs = this.$refs.control.$refs;
                    
                    if (childRefs["value-container"]) {
                        const valueContainer = childRefs["value-container"];
                        
                        if (valueContainer.$refs.input) {
                            return valueContainer.$refs.input.value;
                        }
                    }
                }
                
                return null;
            },
            overridesCheckValueInNodes(value) {
                let childHasValue = false;
                
                this.traverseAllNodesDFS((node) => {
                    if (node.label === value) {
                        childHasValue = true;
                    }
                });
                
                return childHasValue;
            },
            select(node) {
                /**
                 * Here we override the select(node) method from
                 * the library, we will inject a new node if a node
                 * doesn't exist and then proxy this method to the original!
                 */
                const value = this.overridesFindValue();
                if (typeof value === "string" && value.length === 0) {
                    // This function gets called internally a lot, so we need
                    // to make sure it's proxied when there is no value
                    this.resetSearchQuery();
                    return Treeselect.mixins[0].methods.select.call(this, node);
                }
                
                if (value && value !== "") {
                    if (this.overridesCheckValueInNodes(value)) {
                        // If there is a value, we just fallback to the default function
                        this.resetSearchQuery();
                        return Treeselect.mixins[0].methods.select.call(this, node);
                    }
                }
                
                /**
                 * Finally, we can emit a "fallback" event and
                 * in your parrent you can call your appendNode function.
                 */
                const id = `item-${value}`;
                this.$emit("new-node", { value, id });
                
                /**
                 * Additionally, to make the select select our value
                 * we need to "emit" it to v-model as well
                 */
                this.$emit("input", [...this.value, id]);
                
                /**
                 * Finally, let's reset the input
                 */
                this.resetSearchQuery();
            },
        },
    };
</script>