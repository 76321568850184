export default {
    provide() {
        return {
            onAddAllFromCurrentPageToBulkEvent: this.onAddAllFromCurrentPageToBulkEvent,
            onAddBulkEvent: this.onAddBulkEvent,
            closeBulkForm: this.closeBulkForm,
            clearBulk: this.clearBulk,
        };
    },
    data() {
        return {
            showBulkModal: false,
            activeBulkAction: null,
            bulkSelectedOnCurrentPage: false,
            bulkSelectedAll: false,
            playersBulk: [],
            partialChecked: false,
        };
    },
    computed: {
        hasPlayerInBulk() {
            return !!this.playersBulk.length;
        },
    },
    methods: {
        toggleBulkAction(type = 'AssignAgent') {
            if (!type) {
                window.showMessage('Bulk type must be provided.', false);

                return;
            }

            this.showBulkModal = true;
            this.activeBulkAction = type;
        },
        onAddAllFromCurrentPageToBulkEvent() {
            this.partialChecked = false;
            this.bulkSelectedAll = false;
            this.bulkSelectedOnCurrentPage = !this.bulkSelectedOnCurrentPage;

            let players = this.playersData.data.map(item => Number(item.id));
            
            const fromBulk = !!this.bulkSelectedOnCurrentPage
                ? this.playersBulk
                : this.playersBulk.filter(item => !players.includes(item));

            this.playersBulk = [
                ...fromBulk,
                ...(!!this.bulkSelectedOnCurrentPage ? players : [])
            ];
        },
        onAddAllToBulkEvent() {
            this.partialChecked = false;

            let players = [];

            if (!this.bulkSelectedAll) {
                this.bulkSelectedAll = true;
                this.bulkSelectedOnCurrentPage = true;

                players = this.players.map(item => Number(item.id));
            } else {
                this.bulkSelectedAll = false;
                this.bulkSelectedOnCurrentPage = false;
            }

            this.playersBulk = players;
        },
        onAddBulkEvent(id) {
            this.bulkSelectedOnCurrentPage = false;
            this.bulkSelectedAll = false;

            const value = Number(id);

            if (this.playersBulk.includes(value)) {
                this.playersBulk = this.playersBulk.filter(item => item !== value);

                this.partialChecked = !!this.playersBulk.length;

                return;
            }

            this.playersBulk.push(value);

            const areAllPlayersInBulk = this.playersBulk.length === this.players.length;

            this.partialChecked = !areAllPlayersInBulk;
            this.bulkSelectedAll = !!areAllPlayersInBulk;
            this.bulkSelectedOnCurrentPage = !!areAllPlayersInBulk;
        },
        closeBulkForm() {
            this.showBulkModal = false;
            this.activeBulkAction = null;
        },
        clearBulk() {
            this.playersBulk = [];
            this.bulkSelectedAll = false;
        },

    },
}