<template>
    <atbl-form-group label="Batch"
                     label-for="Batch"
                     class="font-weight-bold"
    >
        <tree-select v-model="batch"
                     :multiple="multiple"
                     :value="value"
                     :options="batchFiles"
                     :disabled="disabled || isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"
        />
    </atbl-form-group>
</template>

<script>
import LeadService from '../../../../services/Marketing/LeadService';

export default {
    name: 'batch-files-picker',
    props: {
        value: {
            type: [String, Array],
            default: null
        },
        name: {
            type: String,
            default: 'batch'
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            batchFiles: [],
            isLoading: false,
        };
    },
    computed: {
        isDisabled() {
            return !!this.disabled || this.isLoading;
        },
        batch: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            },
        },
    },
    async created() {
        await this.getBatchFiles();
    },
    methods: {
        async getBatchFiles() {
            if (!this.$can('Leads - View')) {
                window.showMessage('You does not have permissions for this action.', false);

                return;
            }

            this.isLoading = true;

            try {
                const response = await LeadService.batchFiles();

                this.batchFiles = response.data.map(item => ({
                    label: item.source,
                    id: item.source,
                }));

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        batchChanged($event) {
            let value = $event.target.value;

            this.$emit('input', value.length ? value : null);
        }
    },
}
</script>
