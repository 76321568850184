var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-group" },
    [
      _c("statistics-card", {
        attrs: {
          icon: "fa fa-cart-plus",
          number: this.currencyNumber(this.agent, "total"),
          title: "Total Sale",
          value: 0,
          isLoading: _vm.isLoading
        }
      }),
      _vm._v(" "),
      _vm.deposits
        ? _c("statistics-card", {
            attrs: {
              icon: "fa fa-cart-plus",
              number: this.currencyNumber(this.agent, "deposits"),
              title: "Deposits",
              value: 0,
              isLoading: _vm.isLoading
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.bonus
        ? _c("statistics-card", {
            attrs: {
              icon: "fa fa-gift",
              number: this.currencyNumber(this.agent, "bonus"),
              title: "Bonus",
              value: 0,
              isLoading: _vm.isLoading
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("statistics-card", {
        attrs: {
          icon: "fa fa-percent",
          title: "Commission",
          value: 0,
          isLoading: _vm.isLoading
        },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading && this.agent
              ? {
                  key: "value",
                  fn: function() {
                    return [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.getCommissionAmount) }
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "ml-1 font-1x2",
                          attrs: {
                            href: _vm.getCommissionDetailsLink,
                            target: "_blank"
                          }
                        },
                        [_vm._v("\n\t\t\t\tDetails\n\t\t\t")]
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }