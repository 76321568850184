<template>
    <atbl-modal header-bg-color="light"
             footer-class="justify-content-between"
             :show="showModal"
             :close-on-backdrop="false"
             :no-close-on-esc="true"
             size="lg"
             @close="showModal = false"
    >
        <template #header>
            <GameHeader v-if="game.game"
                        :game="game"
                        :is-from-confirm="true"
            />
        </template>

        <div v-for="(numberTypes, index) in configurationTemp"
             :key="index"
             class="configuration"
        >
            <raffle-editor-item :index="index"
                                :number-types="numberTypes"
                                :configuration-temp="configurationTemp"
                                :disabled="true"
            />
        </div>

        <template #footer>
            <h4 class="m-0">Are you sure you want to save this game draw?</h4>
            <div class="text-right">
                <button v-if="!loading"
                          class="btn btn-sm btn-danger"
                          @click="handleCancel()"
                >
                    Cancel
                </button>
                <button v-if="$can('Game Draws Edit') && !loading"
                          class="btn btn-sm btn-success"
                          @click="handleOK"
                >
                    Confirm
                </button>
                <loader v-else
                        :show="loading"
                        type="small"
                />
            </div>
        </template>
    </atbl-modal>
</template>

<script>
    import ObjectMixin from "../../../../../../mixins/Object";
    import RaffleEditorItem from './RaffleEditorItem';
    import GameHeader from '../../DrawsEditor/Components/GameHeader';
    import AtblModal from '../../../../../../components/AtblModal/AtblModal.vue';

    export default {
        name: "confirm-raffle-edit",
        mixins: [
            ObjectMixin
        ],
        components: {
            RaffleEditorItem,
            GameHeader,
            AtblModal,
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            configurationTemp: {
                required: true
            },
            game: {
                required: true
            }
        },
        data: function(){
            return {
                configuration: {},
                configurationTmp: {},
            };
        },
        computed: {
            showModal: {
                get(){
                    return this.show;
                },
                set(value){
                    this.$emit('show', value);
                }
            },
            canShowMessage: function(){
                if(!this.isObjectEmpty(this.configuration)){
                    let count = 0;
                    for(let item in this.configuration){
                        count += this.configuration[item].length;
                    }
                    return count === 0;
                }
                return false;
            }
        },
        methods: {
            handleOK: function(){
                this.$emit("saveRaffle");
            },
            handleCancel: function(){
                this.$emit('close');
            }
        }
    }
</script>