<template>
    <div class="container-fluid players">
        <search-players v-show="isSearchOpen"
                        @search="onSearchEvent"
                        @clear="clearSearchModel"
        />
        <div v-if="!!showTable"
             class="card card-list atbl-table m-0"
        >
            <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                    <i class='fa fa-users'></i> Players
                    <span v-if="players.length">
                        ({{ players.length }})
                    </span>
                </div>
                <span v-if="!!hasMore"
                      class="text-danger"
                >
                    Search result contains more than {{limit}} rows, please narrow your search.
                </span>
                <div class="card-header-operations">
                    <a v-if="!isLoading && !!playersBulk.length"
                       class="mr-2 font-weight-bold"
                       href="#"
                       @click.prevent="onAddAllToBulkEvent"
                    >
                        <span v-if="playersBulk.length !== players.length">
                            Select all {{ players.length }} players
                        </span>
                        <span v-else>
                            Clear selection
                        </span>
                    </a>
                    <atbl-select v-if='!!players.length'
                                 :options="paginationModel.data"
                                 :value="paginationModel.default"
                                 :first-option-label="null"
                                 option-label="text"
                                 class="mr-2 mb-0"
                                 @input="onPaginationLimitChangeEvent"
                    />
                    <button class="btn btn-sm mr-1"
                            :class="{
                                'btn-primary': !isSearchOpen,
                                'btn-outline-primary': isSearchOpen
                            }"
                            @click="isSearchOpen = !isSearchOpen"
                    >
                        <i class="fa fa-filter m-0"></i>
                    </button>
                    <c-dropdown v-if="$can('Player - Assign Agent')"
                                id="bulkAction"
                                toggler-text="Bulk"
                                color="danger"
                                size="sm"
                                right
                                class="mr-2"
                                :disabled="!hasPlayerInBulk"
                    >
                        <c-dropdown-item @click.prevent="toggleBulkAction()">
                            Assign Agent
                        </c-dropdown-item>
                        <c-dropdown-item @click.prevent="toggleBulkAction('UnAssignAgent')">
                            Unassign Agent
                        </c-dropdown-item>
                    </c-dropdown>
                </div>
            </div>
            <div class="card-body">
                <PlayersList :isLoading="isLoading"
                             :players="players"
                             :playersData="playersData"
                             :players-bulk="playersBulk"
                             :bulk-selected-all="bulkSelectedAll"
                             :sort="sort"
                             :page="page"
                             :partial-checked="partialChecked"
                             :current-page-selected="bulkSelectedOnCurrentPage"
                             @paginateUsersList="onPageChangeEvent"
                             @agent="onAssignAgentEvent"
                             @callPlayer="callPlayer"
                             @sorted="onUpdateSortEvent"
                />
            </div>
        </div>

        <create-player v-if="!!showCreatePlayerForm"
                       @success="fetchPlayers"
                       @close="showCreatePlayerForm = false"
        />
        <bulk-players :show="showBulkModal"
                      :bulk-action="activeBulkAction"
                      :players="players"
                      :selected-players="playersBulk"
        />
        <assign-agent :player="selectedPlayerToAssign"
                      :show="showAssignAgentForm"
                      @success="fetchPlayers"
                      @close="showAssignAgentForm = false"
        />
    </div>
</template>

<script>
import SearchPlayers from './Components/Forms/SearchPlayers/SearchPlayers';
import PaginateOptions from '../../data/PaginateOptions';
import BirthdayPicker from '../../components/Forms/Select/BirthdayPicker';
import CountriesOptions from '../../components/Forms/Select/CountriesOptions';
import PlayerCallMixin from './Mixins/PlayerCallMixin';
import PlayersList from './Sections/PlayersList';
import BulkPlayers from './Components/Forms/BulkPlayers/BulkPlayers';
import CreatePlayer from './Components/Forms/CreatePlayer/CreatePlayer';
import AssignAgent from './Components/Forms/AssignAgent/AssignAgent';
import BulkPlayersMixin from './Components/Forms/BulkPlayers/BulkPlayersMixin';
import AssignAgentMixin from './Components/Forms/AssignAgent/AssignAgentMixin';
import PlayerService from '../../services/PlayerService';
import { clone } from '../../utils/Json/Json';
import PaginationModel from '../../modules/pagination/PaginationModel';
import { filterNonEmpty } from '../../utils';
import SaveObjectToUrl from '../../utils/Url/SaveObjectToUrl';
import DynamicSort from '../../utils/Array/DynamicSort';
import Paginate from '../../modules/pagination/Paginate';

const sortObject = {
    sortBy: 'id',
    direction: 'ASC',
    sortOnInit: false,
};

export default {
    name: 'players-page',
    mixins: [
        PlayerCallMixin,
        AssignAgentMixin,
        BulkPlayersMixin,
    ],
    components: {
        SearchPlayers,
        BirthdayPicker,
        CountriesOptions,
        PlayersList,
        BulkPlayers,
        CreatePlayer,
        AssignAgent
    },
    provide() {
        return {
            fetchPlayers: this.fetchPlayers,
            toggleCreatePlayerForm: this.toggleCreatePlayerForm,
        };
    },
    data() {
        return {
            showCreatePlayerForm: false,

            hasMore: false,
            players: [],
            playersData: clone(PaginationModel),
            isLoading: false,

            paginationModel: clone(PaginateOptions),
            isSearchOpen: true,
            model: {},
            sort: clone(sortObject),
            showTable: false,
            page: 1,
            limit: null,
        }
    },
    computed: {
        hasModelValues() {
            return !!filterNonEmpty(this.model).length;
        },
    },
    methods: {
        async onSearchEvent(data) {
            if (data.hasOwnProperty('otherParams')) {
                const { page, sort, paginate } = data.otherParams;

                this.page = page ?? 1;
                this.sort = sort ?? sortObject;
                this.paginationModel.default = paginate ?? this.paginationModel.default;
            }

            this.model = data.hasOwnProperty('searchModel')
                ? data.searchModel
                : data;

            await this.fetchPlayers().then(() => {
                if (data.hasOwnProperty('removeParams')) {
                    data.removeParams.forEach(param => {
                        this.model[param] = null
                    })
                    
                    this.sortAndPaginateData();
                }
            });
        },
        async fetchPlayers() {
            if (!this.hasModelValues) {
                return;
            }

            this.isLoading = true;
            this.showTable = true;

            const response = await PlayerService.allPlayers(this.model);
            const { players, hasMore, limit } = response.data;

            this.hasMore = hasMore;
            this.limit = limit;

            this.players = players.map(item => {
                return {
                    ...item,
                    tags: item["tags"]?.split(',') || []
                };
            });

            this.setListData(this.page);

            this.isLoading = false;
        },
        onPaginationLimitChangeEvent(value) {
            this.paginationModel.default = value;

            this.setListData();
        },
        onPageChangeEvent(page = 1) {
            this.page = page;

            this.partialChecked = false;
            this.bulkSelectedOnCurrentPage = false;
            this.bulkSelectedAll = false;

            this.setListData(page);
        },
        onUpdateSortEvent(e) {
            this.sort = {
                sortBy: e.detail.key,
                direction: e.detail.direction,
                sortOnInit: sortObject.sortOnInit,
            };

            this.setListData();
        },
        setListData(page = 1) {
            this.playersData = this.sortAndPaginateData(page);

            const bulkFilter = this.playersData.data.filter(item => this.playersBulk.includes(item.id));
            this.partialChecked = bulkFilter.length !== 0 && bulkFilter.length !== this.playersData.data.length;
            this.bulkSelectedOnCurrentPage = bulkFilter.length !== 0 && bulkFilter.length === this.playersData.data.length;
        },
        sortAndPaginateData(page = 1) {
            const { sortBy, direction } = this.sort;

            const sortTickets = DynamicSort(
                clone(this.players),
                sortBy,
                direction
            );

            const pagination = Paginate(
                sortTickets,
                page,
                this.paginationModel.default
            );

            SaveObjectToUrl({
                ...this.model,
                sort: this.sort,
                paginate: this.paginationModel.default,
                page: pagination.current_page,
            });

            return pagination;
        },
        toggleCreatePlayerForm() {
            this.showCreatePlayerForm = !this.showCreatePlayerForm;
        },
        clearSearchModel() {
            this.model = {}

            const cleanUrl = window.location.href.split('?')[0];
            if (!!cleanUrl) {
                window.history.pushState({}, '', cleanUrl);
            }
        }
    },
}
</script>