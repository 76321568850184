<template>
    <div class="card card-list">
        <div class="card-header">
            <i class="fa fa-phone"></i> Last Calls
        </div>

        <div class="card-body">
            <div class="general-list">
                <div class="row show-first-row">
                    <div class="col">
                        Number
                    </div>
                    <div class="col">
                        Status
                    </div>
                    <div class="col">
                        Date
                    </div>
                </div>

                <template v-if="!isLoading && !!latestCallsList.length">
                    <div v-for="(item, index) in latestCallsList"
                         :key="index"
                         class="row"
                    >
                        <div class="col">
                            <span class="badge badge-primary">
                                From: {{ item.from }}
                            </span>
                            <br />
                            <span class="badge badge-success">
                                To: {{ item.to }}
                            </span>
                        </div>
                        <div class="col">
                            <span class="badge badge-primary">
                                {{ item.status }}
                            </span>
                            <br />
                            <small>
                                {{ !!item.duration ? item.duration : '/' }}
                            </small>
                        </div>
                        <div class="col">
                            {{ utcToLocal(item.created_at) }}
                        </div>
                    </div>
                </template>

                <loader :show="isLoading"
                        type="small"
                />

                <not-found v-if="!isLoading"
                           :items="latestCallsList"
                           msg="No calls found."
                />
            </div>
        </div>
    </div>
</template>

<script>
import PaginationModel from '../../../../../../modules/pagination/PaginationModel';

export default {
    name: 'LastCallsComponent',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        latestCallsResponse: {
            type: Object,
            default: () => (PaginationModel)
        },
    },
    computed: {
        latestCallsList() {
            return this.latestCallsResponse.data;
        },
    },
}
</script>