var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6 col-lg-8" }, [
          _c("div", { staticClass: "card card-list" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.syndicateItem && _vm.syndicate
              ? _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "general-list" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Title")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _vm._v(_vm._s(_vm.syndicate.title))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Slug")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _vm._v(_vm._s(_vm.syndicate.slug))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Reference")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _vm._v(_vm._s(_vm.syndicate.uuid))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Game")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("img", {
                          staticStyle: { width: "60px", height: "auto" },
                          attrs: {
                            src: _vm.$imageService.gameImage(
                              _vm.syndicate.game.image
                            )
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Game draw")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.syndicateItem.active_syndicate_item_draw
                                .cut_off_at
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Total shares")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.syndicateItem.total_shares))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Shares left")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.syndicateItem.active_syndicate_item_draw
                                .available_shares
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Lines")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.syndicateItem.lines_count))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Share price")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.syndicateItem.share_price
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Is recurring")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.syndicate.is_recurring ? "Yes" : "No")
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Is active")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.syndicateItem.is_active ? "Yes" : "No")
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-2 text-muted" }, [
                        _vm._v("Max. future draws")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-4" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.syndicate.max_future_draws))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-end align-items-center"
                          },
                          [
                            _vm.syndicate.is_active &&
                            _vm.syndicateItem.is_active
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger",
                                    attrs: { type: "reset" },
                                    on: {
                                      click: function($event) {
                                        _vm.showDisableSyndicateModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-ban" }),
                                    _vm._v(
                                      " Disable syndicate\n                                    "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.syndicate.is_active ||
                            !_vm.syndicateItem.is_active
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-primary",
                                    attrs: { type: "reset" },
                                    on: {
                                      click: function($event) {
                                        _vm.showEnableSyndicateModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-ban" }),
                                    _vm._v(
                                      " Enable syndicate\n                                    "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.syndicateItems.length
            ? _c("div", { staticClass: "card card-list" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "general-list" },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _vm._l(_vm.showingSyndicateItems, function(
                        syndicateItem
                      ) {
                        return [
                          _c(
                            "div",
                            { key: syndicateItem.id, staticClass: "row" },
                            [
                              _c("div", { staticClass: "col" }, [
                                _c("span", [
                                  _vm._v(" ID: " + _vm._s(syndicateItem.id))
                                ]),
                                _vm._v(" "),
                                _vm.activeSyndicateItem.id === syndicateItem.id
                                  ? _c("small", [
                                      _c("br"),
                                      _vm._v(
                                        "Current\n                                    "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.syndicate.title) +
                                    "\n                                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      syndicateItem.active_syndicate_item_draw
                                        .cut_off_at
                                    ) +
                                    "\n                                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      syndicateItem.is_active ? "Yes" : "No"
                                    ) +
                                    "\n                                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      syndicateItem.active_syndicate_item_draw
                                        .available_shares
                                    ) +
                                    "\n                                "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col text-center" },
                                [
                                  _vm.$can("Syndicates - Create/Update")
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "d-block",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.gotoSyndicateItem(
                                                  syndicateItem.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._m(3, true)]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        ]
                      }),
                      _vm._v(" "),
                      _vm.syndicateItemsPage * _vm.syndicateItemsPerPage <
                      _vm.syndicateItems.length
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center align-items-center my-2"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-primary",
                                    attrs: { type: "submit" },
                                    on: {
                                      click: function($event) {
                                        _vm.syndicateItemsPage++
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-search" }),
                                    _vm._v(
                                      " Show more\n                                "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6 col-lg-4" }, [
          _c("div", { staticClass: "card card-list" }, [
            _vm._m(4),
            _vm._v(" "),
            _vm.syndicateItem
              ? _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("syndicate-lines", {
                      attrs: { "syndicate-item": _vm.syndicateItem }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.showDisableSyndicateModal
        ? _c("syndicate-disable-modal", {
            attrs: { "syndicate-item": _vm.syndicateItem },
            on: {
              cancel: function($event) {
                _vm.showDisableSyndicateModal = false
              },
              disableSyndicate: _vm.disableSyndicate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEnableSyndicateModal
        ? _c("syndicate-enable-modal", {
            attrs: { "syndicate-item": _vm.syndicateItem },
            on: {
              cancel: function($event) {
                _vm.showEnableSyndicateModal = false
              },
              enableSyndicate: _vm.enableSyndicate
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Syndicate details\n                    ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Syndicate draws\n                    ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Reference")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Title")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Cut off at")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [_vm._v("Is active")]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted" }, [
        _vm._v("Available shares")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col text-muted text-center" }, [
        _vm._v("Action")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" View item")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-exchange-alt" }),
          _vm._v(" Syndicate lines\n                    ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }