var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header card-header d-flex justify-content-between align-items-center"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.$can("Dispositions - Create/Update")
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-primary float-right py-1 px-2 m-0",
                    attrs: { size: "sm" },
                    on: { click: _vm.toggleCreateUpdateForm }
                  },
                  [_vm._v("\n                Create\n            ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Id")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Name")]),
                _vm._v(" "),
                _vm.$canAny(_vm.dispositionsOperationPermissions)
                  ? _c("div", { staticClass: "col-12 col-lg" }, [
                      _vm._v(
                        "\n                        Operations\n                    "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              !_vm.isLoading
                ? _vm._l(_vm.dispositions, function(item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Id")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.id) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg mb-1 mb-lg-0" }, [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Name")
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.name) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$canAny(_vm.dispositionsOperationPermissions)
                        ? _c("div", { staticClass: "col-12 col-lg" }, [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _vm.$can("Dispositions - Create/Update")
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.onEditDispositionEvent(
                                          item.id
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-pen" })]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("items-not-found", {
                    attrs: {
                      items: _vm.dispositions,
                      msg: "No dispositions found."
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("create-or-update-disposition-form-component", {
        attrs: {
          isModalOpened: _vm.isCreateUpdateOpened,
          model: _vm.dispositionModel,
          errors: _vm.dispositionModelErrors,
          "is-loading": _vm.isCreateUpdateLoading
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" Dispositions")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }