var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Notes - Create/Update")
    ? _c(
        "atbl-modal",
        {
          attrs: {
            show: _vm.show,
            "close-on-backdrop": false,
            title: "Create Note"
          },
          on: { input: _vm.toggleNoteModal },
          scopedSlots: _vm._u(
            [
              !_vm.isLoading
                ? {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger",
                            on: { click: _vm.toggleNoteModal }
                          },
                          [_vm._v("\n            Close\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            on: { click: _vm.onCreateUpdateNote }
                          },
                          [_vm._v("\n            Ok\n        ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          !_vm.isLoading
            ? [
                _c("atbl-input", {
                  attrs: {
                    errors: _vm.errors,
                    label: "Enter note type",
                    name: "type"
                  },
                  model: {
                    value: _vm.model.type,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.model,
                        "type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "model.type"
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }