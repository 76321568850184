<template>
    <section>
        <div class="row justify-content-between pb-3 mb-3 border-bottom">
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Name"
                                 :value="`${player.name} ${player.surname}`"
                                 :copy="true"
                >
                    {{ player.name }} {{ player.surname }}
                </atbl-fake-input>
            </div>
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Birthdate"
                                 :value="player.birthdate"
                                 :copy="!!player.birthdate"
                >
                    {{ player.birthdate }}
                </atbl-fake-input>
            </div>
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Email"
                                 :value="player.email"
                                 :copy="!!player.email"
                >
                    {{ player.email }}
                </atbl-fake-input>
            </div>
        </div>
        
        <div class="row justify-content-between pb-3 mb-3 border-bottom">
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Mobile"
                                 :value="player.mobile"
                                 :copy="!!player.mobile"
                >
                    <a v-if="$can('Player - Call') && player.mobile"
                       href="#"
                       @click.prevent="openCallWindow(player.mobile, player.id)"
                    >
                        {{ player.mobile }}
                    </a>
                    <span v-else>{{ emptyField(player.mobile) }}</span>
                </atbl-fake-input>
            </div>
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Phone"
                                 :value="player.phone"
                                 :copy="!!player.phone"
                >
                    <a v-if="$can('Player - Call') && player.phone"
                       href="#"
                       @click.prevent="openCallWindow(player.phone, player.id)"
                    >
                        {{ player.phone }}
                    </a>
                    <span v-else>{{ emptyField(player.phone) }}</span>
                </atbl-fake-input>
            </div>
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Country"
                                 :value="player.country"
                                 :copy="!!player.country"
                >
                    {{ player.country }}
                </atbl-fake-input>
            </div>
        </div>
        
        <div class="row justify-content-between pb-3 mb-3 border-bottom">
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="City"
                                 :value="player.city"
                                 :copy="!!player.city"
                >
                    {{ player.city }}
                </atbl-fake-input>
            </div>
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="State"
                                 :value="player.state"
                                 :copy="!!player.state"
                >
                    {{ player.state }}
                </atbl-fake-input>
            </div>
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Postcode"
                                 :value="player.postcode"
                                 :copy="!!player.postcode"
                >
                    {{ player.postcode }}
                </atbl-fake-input>
            </div>
        </div>
        
        <div class="row justify-content-between pb-3 mb-3 border-bottom">
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Address"
                                 :value="player.address1"
                                 :copy="!!player.address1"
                >
                    {{ player.address1 }}
                </atbl-fake-input>
            </div>
            <div class="col-12 col-lg-4 mb-1 mb-lg-0">
                <atbl-fake-input label="Address"
                                 :value="player.address2"
                                 :copy="!!player.address2"
                >
                    {{ emptyField(player.address2) }}
                </atbl-fake-input>
            </div>
        </div>
    </section>
</template>

<script>
    import PlayerCallMixin from '../../../Mixins/PlayerCallMixin';
    import AtblFakeInput from '../../../../../components/Forms/Inputs/AtblFakeInput.vue';
    import emptyField from '../../../../../filters/EmptyField';
    
    export default {
        name: 'preview-player',
        components: {AtblFakeInput},
        props: {
            player: {
                type: Object,
                required: true
            }
        },
        mixins: [
            PlayerCallMixin,
        ],
        methods: {
            emptyField,
        },
    };
</script>