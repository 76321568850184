export default {
    mutations: {
        SET_CALLBACK_LIST: `setCallbackList`,
        SET_CALLBACK_LIST_RESPONSE: `setCallbackListResponse`,
        SET_CALLBACK_LIST_LOADER: `setCallbackListLoader`,
        SET_CALLBACK_LIST_MODEL: `setCallbackListModel`,
        SET_CALLBACK_LIST_MODEL_CLEAR: `setCallbackListModelClear`,
    },
    getters: {
        GET_CALLBACK_LIST: `getCallbackList`,
        GET_CALLBACK_LIST_RESPONSE: `getCallbackListResponse`,
        GET_CALLBACK_LIST_LOADER: `getCallbackListLoader`,
        GET_CALLBACK_LIST_MODEL: `getCallbackListModel`,
    },
    actions: {
        CALLBACK_GET_LIST_ACTION: 'getCallbackList'
    }
};