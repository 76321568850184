import TypesConst from "../../TypesConst";
import UsersService from "../../../services/UsersService";

export default {
    [TypesConst.users.actions.USERS_GET_CURRENT_USER_ACTION]({commit}){
        commit(TypesConst.users.mutations.SET_CURRENT_USER_LOADER, true);

        UsersService.getCurrentUser()
            .then(response => {
                commit(TypesConst.users.mutations.SET_CURRENT_USER, response.data);
            })
            .catch(error => {
                let message = error.response.data.message ? error.response.data.message : error.message;
                flash(message, "alert-danger");
            })
            .finally(() => {
                commit(TypesConst.users.mutations.SET_CURRENT_USER_LOADER, false);
            });
    },
};