var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "player-play-picker-lines" },
    [
      _c("div", { staticClass: "line-control" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "add-line",
              attrs: { disabled: _vm.hasSelectedFreeGame },
              on: {
                click: function($event) {
                  return _vm.addLine(1)
                }
              }
            },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "add-line",
              attrs: { disabled: _vm.hasSelectedFreeGame },
              on: {
                click: function($event) {
                  return _vm.addLine(5)
                }
              }
            },
            [_vm._m(1)]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "add-line",
              attrs: { disabled: _vm.hasSelectedFreeGame },
              on: {
                click: function($event) {
                  return _vm.addLine(10)
                }
              }
            },
            [_vm._m(2)]
          ),
          _vm._v(" "),
          _vm.hasSelectedFreeGame
            ? _c("span", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.selectedFreeGame.meta.lines) +
                    " Free Line" +
                    _vm._s(_vm.selectedFreeGame.meta.lines > 1 ? "s" : "") +
                    "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.lineErrors.length || _vm.gameErrors.length
            ? _c(
                "span",
                { staticClass: "line-message" },
                [
                  _vm.lineErrors.length
                    ? [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              "Line #" +
                                _vm.lineErrors[0].line +
                                ": " +
                                _vm.lineErrors[0].errMessage
                            ) +
                            " "
                        ),
                        _c("br")
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.gameErrors, function(gameError) {
                    return [
                      _vm._v(
                        "\n                    " + _vm._s(gameError) + " "
                      ),
                      _c("br")
                    ]
                  })
                ],
                2
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.hasSelectedFreeGame && _vm.lines.length > 1
          ? _c("div", [
              !_vm.removeAllConfirm
                ? _c(
                    "a",
                    {
                      staticClass: "remove-all-lines",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.removeAllConfirm = true
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-trash" }),
                      _vm._v(" Remove All\n            ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.removeAllConfirm
                ? _c(
                    "a",
                    {
                      staticClass: "remove-all-confirm",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onRemoveAllLinesEvent.apply(
                            null,
                            arguments
                          )
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-check" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.removeAllConfirm
                ? _c(
                    "a",
                    {
                      staticClass: "remove-all-cancel",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.removeAllConfirm = false
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._l(_vm.lines, function(line, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "player-play-picker-lines-line",
            class: { error: !!_vm.getLineError(line) }
          },
          [
            _c("span", { staticClass: "generated-number" }, [
              _vm._v("# " + _vm._s(index + 1))
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "lucky-dip",
                on: {
                  click: function($event) {
                    return _vm.luckyDip(line)
                  }
                }
              },
              [_vm._v("Lucky Dip")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: { content: "Main numbers" },
                    expression: "{content: 'Main numbers'}"
                  }
                ],
                staticClass: "player-play-picker-lines-line-numbers",
                class: { disabled: line.random }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: line.numbers[0],
                      expression: "line.numbers[0]",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: line.numbers[0] },
                  on: {
                    keypress: [
                      function($event) {
                        return _vm.onMainNumberKeyPress($event)
                      },
                      function($event) {
                        return _vm.isNumber($event)
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(line.numbers, 0, _vm._n($event.target.value))
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: line.numbers[1],
                      expression: "line.numbers[1]",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: line.numbers[1] },
                  on: {
                    keypress: [
                      function($event) {
                        return _vm.onMainNumberKeyPress($event)
                      },
                      function($event) {
                        return _vm.isNumber($event)
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(line.numbers, 1, _vm._n($event.target.value))
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: line.numbers[2],
                      expression: "line.numbers[2]",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: line.numbers[2] },
                  on: {
                    keypress: [
                      function($event) {
                        return _vm.onMainNumberKeyPress($event)
                      },
                      function($event) {
                        return _vm.isNumber($event)
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(line.numbers, 2, _vm._n($event.target.value))
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: line.numbers[3],
                      expression: "line.numbers[3]",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: line.numbers[3] },
                  on: {
                    keypress: [
                      function($event) {
                        return _vm.onMainNumberKeyPress($event)
                      },
                      function($event) {
                        return _vm.isNumber($event)
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(line.numbers, 3, _vm._n($event.target.value))
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: line.numbers[4],
                      expression: "line.numbers[4]",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: line.numbers[4] },
                  on: {
                    keypress: [
                      function($event) {
                        return _vm.onMainNumberKeyPress($event)
                      },
                      function($event) {
                        return _vm.isNumber($event)
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(line.numbers, 4, _vm._n($event.target.value))
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "c-tooltip",
                    rawName: "v-c-tooltip",
                    value: { content: "Fractions (max 10)" },
                    expression: "{content: 'Fractions (max 10)'}"
                  }
                ],
                staticClass: "player-play-picker-lines-line-numbers"
              },
              [
                _c("input", {
                  staticClass: "form-control fractions-input",
                  attrs: {
                    disabled: _vm.hasSelectedFreeGame,
                    min: 1,
                    max: 10,
                    type: "number"
                  },
                  domProps: { value: line.fractions },
                  on: {
                    input: function($event) {
                      return _vm.onUpdateLineFraction($event, line, index)
                    },
                    blur: function($event) {
                      return _vm.onBlurLineFraction($event, line, index)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: { content: "Random" },
                  expression: "{content: 'Random'}"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: { checked: line.random },
              on: {
                change: function($event) {
                  return _vm.onRandomCheckboxChange($event, line)
                }
              }
            }),
            _vm._v(" "),
            _vm.lines.length > 1 && !_vm.hasSelectedFreeGame
              ? _c(
                  "button",
                  {
                    staticClass: "remove-line",
                    on: {
                      click: function($event) {
                        return _vm.removeLine(index)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-times",
                      attrs: { "aria-hidden": "false" }
                    })
                  ]
                )
              : _vm._e()
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "add-line-text" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "false" } }),
      _vm._v(" Add Line\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "add-line-text" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "false" } }),
      _vm._v(" Add 5 Lines\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "add-line-text" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "false" } }),
      _vm._v(" Add 10 Lines\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }