<template>
    <div class="general-list">
        <div class="row">
            <div class="col text-muted" :class="{'col-8': numberGroupsCount === 2, 'col-4': numberGroupsCount > 2}">Main numbers</div>
            <div class="col col-4 text-muted" v-for="groupTitle in numberGroupsTitles" :key="`group_title_${groupTitle}`">{{ groupTitle }}</div>
        </div>

        <div v-if="totalPages" v-for="(line, index) in lines" :key="`syndicate_line_${index}`" class="row">
            <template v-for="(numberGroup, key, index) in line.numbers">
                <div v-if="Array.isArray(numberGroup)" class="col" :class="{'col-8': index === 0 && numberGroupsCount === 2, 'col-4': index !== 0}">
                    <span v-for="number in numberGroup" :key="`number_${syndicateItem.raffle}_${number}`">
                        {{ number }}
                    </span>
                </div>

                <div v-if="! Array.isArray(numberGroup)" class="col" :class="{'col-8': index === 0 && numberGroupsCount === 2, 'col-4': index !== 0}">
                    {{ numberGroup }}
                </div>
            </template>
        </div>

        <div v-if="! totalPages || totalPages >= linesPage" class="row">
            <div class="col d-flex justify-content-center align-items-center my-2">
                <button type="submit" class="btn btn-sm btn-primary" @click="fetchLines" :disabled="isLoadingMoreLines">
                    <i class="fa fa-search"></i> {{ !totalPages ? 'Show lines' : 'Show more' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SyndicateService from '../../../services/SyndicateService';

export default {
    name: 'syndicate-lines',

    props: ['syndicateItem'],

    data() {
        return {
            lines: [],
            linesPage: 1,
            totalPages: null,
            isLoadingMoreLines: false,
        };
    },

    computed: {
        numberGroupsCount() {
            if (this.lines.length) {
                return this.lines[0].numbers;
            }

            return null;
        },

        numberGroupsTitles() {
            if (!this.lines.length) {
                return [];
            }

            const titles = Object.keys(this.lines[0].numbers);

            titles.shift();

            const capitalize = (s) => {
                if (typeof s !== 'string') {
                    return '';
                }

                return s.charAt(0).toUpperCase() + s.slice(1);
            }

            return titles.map(t => capitalize(t));
        },
    },

    methods: {
        fetchLines() {
            this.isLoadingMoreLines = true;

            SyndicateService.syndicateLines(this.syndicateItem.active_syndicate_item_draw.id, this.linesPage).then((response) => {
                this.totalPages = response.data.last_page;

                if (this.linesPage === 1) {
                    this.lines = [];
                }

                this.lines.push(...response.data.data);
                this.linesPage++;
            }).finally(() => {
                this.isLoadingMoreLines = false;
            });
        },
    },
}
</script>