<template>
    <div class="container-fluid game-draw-review-details">
        <template v-if="!isLoading">
            <div class="card card-list game-draw-review-details-info">
                <div class="card-header">
                    <div class="d-flex align-items-center">
                        <a href="/games/draws/review" class="mr-2">
                            <i class="fa fa-chevron-left"></i>
                        </a>
                        <i class="fa fa-star mr-2"></i> Draw <strong class="mx-2">{{ draw.game_draw_id }}</strong> review
                    </div>
                    <div v-if="$can('Game Draws - Approve')">
                        <button :disabled="isToggleApproveButtonActive"
                                class="btn btn-sm btn-success"
                                @click="toggleApproveModal"
                        >
                            Continue
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <img :src="$imageService.gameImage(draw.game_image)" />
                    </div>
                    <div>
                        <span>Name</span>
                        {{ draw.game_name }}
                    </div>
                    <div>
                        <span>Date</span>
                        {{ draw.date }}
                    </div>
                    <div>
                        <span>Type</span>
                        <span :class="['badge', `badge-${drawType.color}`]"
                        >
                            {{ drawType.type }}
                        </span>
                    </div>
                    <div>
                        <span>Is Parsed</span>
                        <i class="fa"
                           :class="{
                                'fa-check text-success': !!draw.ready_for_review,
                                'fa-times text-danger': !draw.ready_for_review
                           }"
                        ></i>
                    </div>
                    <div>
                        <span>Created At</span>
                        {{ utcToLocal(draw.created_at) }}
                    </div>
                </div>
            </div>

            <draw-review-group-item :items="allLines"
                                    :selected-items="selectedLines"
            />
        </template>

        <loader :show="isLoading"
                type="small"
        />

        <approve-modal-component :show="isApproveModalOpen"
                                 :is-loading="isApproveModalLoading"
                                 :is-all-selected="isAllSelected"
                                 :all-lines="allLines"
                                 :selected-lines="selectedLines"
        />
    </div>
</template>

<script>
import Loader from '../../../../components/Widgets/Loader.vue';
import DrawReviewGroupItem from './DrawReviewGroup/DrawReviewGroupItem.vue';
import { clone } from '../../../../utils/Json/Json';
import AtblCheckBox from '../../../../components/Forms/Inputs/AtblCheckbox.vue';
import ApproveModal from './ApproveModal/ApproveModal';
import ApproveModalComponent from './ApproveModal/ApproveModalComponent.vue';
import GameableModels from '../../../../constants/GameableModels';
import resolveDrawType from '../utils/ResolveDrawType';
import utcToLocal from '../../../../filters/UtcToLocal';

export default {
    name: 'DrawReview',
    mixins: [
        ApproveModal,
    ],
    components: {
        Loader,
        DrawReviewGroupItem,
        AtblCheckBox,
        ApproveModalComponent,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
    },
    provide() {
        return {
            drawId: this.id,
            selectLines: this.selectLines,
        };
    },
    data() {
        return {
            draw: {
                created_at: null,
                date: null,
                draw_type: null,
                game_draw_id: null,
                game_image: null,
                game_name: null,
                id: null,
                ready_for_review: false,
                items: [],
            },
            isLoading: false,
            selectedLines: [],
        };
    },
    async created() {
        await this.getGameDrawReviewDetails(this.id);
    },
    computed: {
        allLines() {
            return this.draw.items;
        },
        hasSelectedLines() {
            return !!this.selectedLines.length;
        },
        isAllSelected() {
            return this.selectedLines.length === this.allLines.length;
        },
        isPartialSelected() {
            return !!this.selectedLines.length && !this.isAllSelected;
        },
        drawType() {
            return resolveDrawType(this.draw.draw_type);
        },
        isToggleApproveButtonActive() {
            if (!this.draw.ready_for_review) {
                return true;
            }

            return !this.hasSelectedLines || !!this.isApproveModalOpen;
        },
    },
    methods: {
        utcToLocal,
        async getGameDrawReviewDetails(id) {
            if (!id) {
                return window.showMessage('Undefined id.', false);
            }

            if (!this.$can('Game Draws - Review')) {
                return window.showMessage('No permission for this action', false);
            }

            this.isLoading = true;

            try {
                const response = await window.axios.get(`/games/draws/review/${id}/details`);

                this.draw = this.setDrawData(response.data);

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        setDrawData(drawData) {
            const drawTemp = clone(drawData);

            drawTemp.items = drawTemp.items.map(item => {
                item.ticket_id = this.getTicketId(item);

                return item;
            });

            return drawTemp;
        },
        getTicketId(line) {
            const gameableType = line.gameable_type;
            const gameable = line.gameable;

            switch (gameableType) {
                case GameableModels.SyndicateShare:
                case GameableModels.RaffleTicket:
                    return gameable.id;
                case GameableModels.TicketLine:
                    return gameable.ticket_draw_id;
            }
        },
        selectLines(lines, selected) {
            const linesTmp = Array.isArray(lines)
                ? lines
                : [lines];

            let selectedLines = clone(this.selectedLines);

            if (!!selected) {
                for (const id of linesTmp) {
                    if (selectedLines.includes(id)) {
                        continue;
                    }

                    selectedLines.push(id);
                }
            } else {
                selectedLines = selectedLines.filter(item => !linesTmp.includes(item));
            }

            this.selectedLines = selectedLines;
        },
    },
}
</script>