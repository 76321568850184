<template>
    <atbl-modal title="Remove Note"
             :show="showModal"
             :closeOnBackdrop="false"
             @close="showModal=false"
    >
        <div class="row">
            <div class="col-12">
                Are you sure you want to delete this note?
            </div>
        </div>

        <template #footer>
            <button class="btn btn-sm btn-danger"
                      @click="handleCancel"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                      @click="handleOK"
            >
                Save
            </button>
        </template>
    </atbl-modal>
</template>

<script>
    import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

    export default {
        name: "remove-note-modal",
	    components: {
		    AtblModal,
	    },
        data: function(){
            return {
                showModal: false,
                noteToDelete: {}
            };
        },
        methods: {
            handleOK: function(){
                Atbl.$emit("deleteNoteOk", this.noteToDelete);
            },
            handleCancel: function(){
                this.noteToDelete = {};
                this.showModal = false;
            }
        },
        mounted: function(){
            let vm = this;

            Atbl.$on("openDeleteNoteModal", function(note){
                vm.showModal = true;
                vm.noteToDelete = note;
            });

            Atbl.$on("closeDeleteNoteModal", function(){
                vm.showModal = false;
            });
        }
    }
</script>