import MarketingService from '../../services/MarketingService';

export default {
    data() {
        return {
            callHistoryStatusesLoader: false,
            callHistoryStatuses: [],
        };
    },
    methods: {
        async fetchCallHistoryStatuses() {
            this.callHistoryStatusesLoader = false;

            try {
                const response = await MarketingService.getCallsStatuses();

                this.callHistoryStatuses = response.data;

                this.callHistoryStatusesLoader = false;
            } catch(error) {
                this.callHistoryStatusesLoader = false;

                window.flashError(error);
            }
        },
    },
}