<template>
    <div class="c-footer">
        <div>
            <a href="https://allthebestlottos.com/" target="_blank">ATBL</a>
            <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
        </div>
        <div class="ml-auto">
            <span class="mr-1">Powered by</span>
            <a href="https://allthebestlottos.com/" target="_blank">ATBL</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'main-footer'
    }
</script>
