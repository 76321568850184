var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "remove-list-item-button" }, [
    !_vm.isChecked
      ? _c(
          "a",
          {
            staticClass: "remove-list-item-button-trash",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onShowOperation.apply(null, arguments)
              }
            }
          },
          [_c("i", { staticClass: "fa fa-trash-alt" })]
        )
      : _vm._e(),
    _vm._v(" "),
    !!_vm.isChecked
      ? _c(
          "a",
          {
            staticClass: "remove-list-item-button-cancel",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onShowOperation.apply(null, arguments)
              }
            }
          },
          [_c("i", { staticClass: "fa fa-times" })]
        )
      : _vm._e(),
    _vm._v(" "),
    !!_vm.isChecked
      ? _c(
          "a",
          {
            staticClass: "remove-list-item-button-confirm",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onConfirmEvent.apply(null, arguments)
              }
            }
          },
          [_c("i", { staticClass: "fa fa-check" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }