<script>
    export default {
        getUsersList: function(){
            return window.axios.get("/list-users");
        },
        getCurrentUser: function(){
            return window.axios.get("/current/user");
        },
        updateUserActiveState: function (id, isActive) {
            return window.axios.put(`users/${id}/active-state`, { is_active: isActive });
        }
    }
</script>