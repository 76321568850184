<template>
	<div class="atbl-date-time-picker">
		<atbl-form-group :label="label"
		                 :error="getError(errors, name)"
		>
			<div class="atbl-date-time-picker-input-holder">
				<date-range-picker v-model="componentValue"
				                   :opens="direction"
				                   :singleDatePicker="singleDatePicker"
				                   :timePicker="timePicker"
				                   :showWeekNumbers="showWeekNumbers"
				                   :ranges="showRanges"
				                   :timePicker24Hour="timePicker24Hour"
				                   :locale-data="locale"
				                   :date-format="dateFormat"
				                   :auto-apply="autoApply"
				                   class="w-100"
				>
					<div v-if="!!componentValue.startDate"
					     slot="input"
					     slot-scope="scope"
					>
						{{ componentValue.startDate }}
					</div>
					<span v-else>{{ placeholder }}</span>
				</date-range-picker>
				<a v-if='!!clearable && !!shouldClearBeShown'
				   class="atbl-date-time-picker-close"
				   href="#"
				   @click.prevent="onClearValueEvent"
				>
					<i class="fa fa-times-circle"></i>
				</a>
			</div>
		</atbl-form-group>
	</div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import Date from "../../../helpers/Date";
import ATBLFormGroup from "../Holders/ATBLFormGroup.vue";

export default {
	name: 'date-time-picker',
	emits: [
		'input',
		'update',
	],
	components: {
		DateRangePicker,
		ATBLFormGroup,
	},
	props: {
		value: {
			type: String,
			default: null
		},
		name: {
			type: String,
			default: 'date'
		},
		label: {
			type: String,
			default: 'Select date'
		},
		placeholder: {
			type: String,
			default: 'Please select date'
		},
		applyButtonLabel: {
			type: String,
			default: 'Apply'
		},
		cancelButtonLabel: {
			type: String,
			default: 'Cancel'
		},
		direction: {
			type: String,
			default: 'center'
		},
		defaultValue: {
			type: String,
			default: null,
		},
		passDays: {
			type: Boolean,
			default: false
		},
		autoApply: {
			type: Boolean,
			default: false
		},
		errors: {
			type: Object,
			default: () => ({})
		},
		format: {
			type: String,
			default: Date.defaultFormat,
		},
		clearable: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			locale: {
				direction: this.direction,
				applyLabel: this.applyButtonLabel,
				cancelLabel: this.cancelButtonLabel,
				weekLabel: 'W',
				customRangeLabel: 'Custom Range',
				daysOfWeek: moment.weekdaysMin(),
				monthNames: moment.monthsShort(),
				firstDay: 1
			},
			singleDatePicker: true,
			timePicker: true,
			timePicker24Hour: true,
			showWeekNumbers: true,
			showRanges: false
		}
	},
	computed: {
		componentValue: {
			get() {
				if (!this.defaultValue && !this.value) {
					return {
						startDate: null
					};
				}
				
				if (!!this.value) {
					return {
						startDate: this.value
					};
				}
				
				return {
					startDate: this.defaultValue
				};
			},
			set({startDate}) {
				let value = startDate !== null
				  ? moment(startDate).format(this.format)
				  : null;
				
				this.$emit('input', value);
				this.$emit('update', this.name, value);
			},
		},
		shouldClearBeShown() {
			if (!!this.defaultValue && !this.value) {
				return false;
			}
			
			if (!!this.defaultValue && (this.defaultValue === this.value)) {
				return false;
			}
			
			return !!this.componentValue.startDate;
		},
	},
	methods: {
		dateFormat(classes, date) {
			if (!this.passDays) {
				return classes;
			}
			
			if (!classes.disabled) {
				classes.disabled = moment(date.toString()) <= moment().subtract(1, "days");
			}
			
			return classes;
		},
		onClearValueEvent() {
			this.componentValue = {
				startDate: null,
			};
		},
	},
}
</script>