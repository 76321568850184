export default {
    index(params) {
        return window.axios.get(`/bundles`, { params });
    },

    availableBundleList() {
        return window.axios.get(`/games/bundles`);
    },

    show(id) {
        return window.axios.get(`/bundles/${id}`);
    },

    makeLogoPreviewUrl(id) {
        return `/bundles/${id}/logo/preview`;
    },

    makeBackgroundImagePreviewUrl(id) {
        return `/bundles/${id}/background-image/preview`;
    },

    store(params) {
        const requestOptions = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        return window.axios.post('/bundles', this.makeBundleFormData(params), requestOptions);
    },

    update(id, params) {
        const requestOptions = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        return window.axios.post(`/bundles/${id}`, this.makeBundleFormData(params), requestOptions);
    },

    enable(id) {
        return window.axios.post(`/bundles/${id}/enable`);
    },

    disable(id) {
        return window.axios.post(`/bundles/${id}/disable`);
    },

    enableForApi(id) {
        return window.axios.post(`/bundles/${id}/enable-for-api`);
    },

    disableForApi(id) {
        return window.axios.post(`/bundles/${id}/disable-for-api`);
    },

    makeBundleFormData(params) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value)) {
                continue;
            }

            formData.append(key, value);
        }

        if (params.bundleGames && Array.isArray(params.bundleGames)) {
            for (let i = 0; i < params.bundleGames.length; i++) {
                formData.append(`bundleGames[${i}][game]`, params.bundleGames[i].game);

                if (params.bundleGames[i].id) {
                    formData.append(`bundleGames[${i}][id]`, params.bundleGames[i].id);
                }
            }
        }

        if (params.bundleWeeks && Array.isArray(params.bundleWeeks)) {
            for (let i = 0; i < params.bundleWeeks.length; i++) {
                formData.append(`bundleWeeks[${i}][week]`, params.bundleWeeks[i].week);

                if (params.bundleWeeks[i].price && params.bundleWeeks[i].price > 0) {
                    formData.append(`bundleWeeks[${i}][price]`, params.bundleWeeks[i].price);
                }

                if (params.bundleWeeks[i].discount && params.bundleWeeks[i].discount > 0) {
                    formData.append(`bundleWeeks[${i}][discount]`, params.bundleWeeks[i].discount);
                }

                if (params.bundleWeeks[i].id) {
                    formData.append(`bundleWeeks[${i}][id]`, params.bundleWeeks[i].id);
                }
            }
        }

        return formData;
    },
}