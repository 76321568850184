<template>
    <section class="campaign-call">
        <div class="campaign-leads-call">
            <div class="profile-holder">
                        <span class="profile-holder-icon">
                            <i class="fa fa-user icons font-4xl"></i>
                        </span>
            </div>
            <h4 class="text-center mb-3">
                {{ customer.name }} {{ customer.surname }}
            </h4>
            <div class="d-flex align-items-center justify-content-center mb-3">
                <span :class="{
                          'badge-primary': campaignType === 'lead',
                          'badge-danger': campaignType === 'player'
                      }"
                      class="badge text-uppercase"
                >
                    {{ campaignType }}
                </span>
                <span v-if="!!callLogs.length" class="text-secondary mx-3">|</span>
                <div v-if="!!callLogs.length"
                     class="text-center"
                >
                    <a href="#"
                       @click.prevent="toggleCallLogsModal(true)"
                    >
                        Logs
                    </a>
                </div>
            </div>
            <form @submit.prevent="onHandleCallEvent">
                <twilio-phone-numbers v-model="selectedNumber"
                                      modelType="leads"
                                      placeholder="Call Number"
                                      class="mb-3"
                />

                <div class="mb-3">
                    <span class="atbl-placeholder font-weight-bold">Customer Number</span>
                    <input v-if="!!customerNumberToCall"
                           :disabled="true"
                           :value="customerNumberToCall"
                           type="text"
                           class="form-control"
                           disabled
                    />
                    <template v-else>
                        <select v-if="customerPhone || customerMobile"
                                v-model="changeNumberToCall"
                                id="input-3"
                                class="form-control"
                                required
                        >
                            <option v-if="!!customerPhone"
                                    :value="customerPhone"
                                    :selected="numberToCall === customerPhone && !this.customerNumberToCall"
                            >
                                {{ customerPhone }}
                            </option>
                            <option v-if="customerMobile"
                                    :value="customerMobile"
                                    :selected="numberToCall === customerMobile && !this.customerNumberToCall"
                            >
                                {{ customerMobile }}
                            </option>
                        </select>
                        <span v-else class="font-weight-bold">Please add phone to this customer.</span>
                    </template>
                </div>

                <dial-keypad v-if="isKeypadOpen"
                             :phone="originalPhoneNumber"
                             :value="callingNumber"
                             :callStarted="isCallStarted"
                             wrapper-size="smaller"
                             @digit="onPressedDigitEvent"
                             @update="onDialPadNumberUpdate"
                />

                <div class="dial-buttons" v-if="!callLoader && originalPhoneNumber">
                    <button v-if="isCallStarted"
                            type="button"
                            class="mute-button"
                            :class="{'btn-primary': !isMuted, 'btn-warning': isMuted}"
                            @click="muteHandler"
                    >
                        <i class='fa' :class="{'fa-microphone': !isMuted, 'fa-microphone-slash': isMuted}"></i>
                    </button>
                    <button v-if="!isCallStarted"
                            name="dialBtn"
                            type="submit"
                            :disabled="!selectedNumber"
                            class="btn btn-sm btn-primary dial-button"
                            :class="{'active': isKeypadOpen}"
                    >
                        <i class='fa fa-phone'></i> Dial
                    </button>
                    <button v-else
                            name="hangBtn"
                            type="submit"
                            class="btn btn-sm btn-danger">
                        <i class='fa fa-phone'></i> Hang
                    </button>
                    <button type="button"
                            class="keypad-toggle-button"
                            :class="{'active': isKeypadOpen}"
                            @click="isKeypadOpen = !isKeypadOpen"
                    >
                        <img src="/img/icons/keypad.svg" alt="keypad-icon" />
                    </button>
                </div>
                <loader type="small"
                        :show="callLoader"
                />
            </form>
            <horizontal-line size="small" />
            <send-email-component v-if="$can('Leads - Send Email')"
                                  :players-enabled="!isLeadCampaign()"
                                  :leads-enabled="!!isLeadCampaign()"
                                  :is-lead="isLeadCampaign()"
                                  :client="customer"
            />
        </div>

        <atbl-modal title="Call Logs"
                    :close-on-backdrop="true"
                    :close-on-esc="true"
                    :show="callLogsOpen"
                    @close="toggleCallLogsModal"
        >
            <div class="card card-list m-0">
                <div :style="{
                         'max-height': `${logsBodyHeight}px`,
                         'overflow-y': 'auto'
                     }"
                     class="card-body"
                >
                    <div class="general-list">
                        <div class="row show-first-row">
                            <div class="col text-muted">Message</div>
                        </div>

                        <div v-for="(log, index) in callLogs"
                             :key="index"
                             class="row"
                        >
                            <div class="col text-muted">{{ log }}</div>
                        </div>

                        <items-not-found msg="You have not made a call yet"
                                         :items="callLogs"
                        />
                    </div>
                </div>
            </div>

            <template #footer>
                <button class="btn btn-outline-danger"
                        @click="toggleCallLogsModal(false)"
                >
                    Cancel
                </button>
            </template>
        </atbl-modal>
    </section>
</template>

<script>
import DialKeypad from '../../../../../../components/DialKeypad/DialKeypad';
import SendEmailComponent from '../../../../../../components/SendEmail/SendEmailComponent';
import CampaignCustomerCall from './CampaignCustomerCall';
import CampaignCustomerCallLogs from './CampaignCustomerCallLogs';
import CampaignModel from '../../../Editor/CampaignFormEdit/CampaignModel';
import ItemsNotFound from '../../../../../../components/Lists/ItemsNotFound';
import AtblModal from '../../../../../../components/AtblModal/AtblModal';
import HorizontalLine from '../../../../../../components/Widgets/HorizontalLine';
import CustomerModel from '../CustomerToCall/CustomerModel';

export default {
    name: 'campaign-lead-call-component',
    inject: [
        'agentId',
        'isLeadCampaign',
        'campaignType',
        'fetchCustomerToCall',
    ],
    mixins: [
        CampaignCustomerCall,
        CampaignCustomerCallLogs,
    ],
    components: {
        DialKeypad,
        SendEmailComponent,
        ItemsNotFound,
        AtblModal,
        HorizontalLine,
    },
    props: {
        customer: {
            type: Object,
            default: () => (CustomerModel)
        },
        campaign: {
            type: Object,
            default: () => (CampaignModel)
        },
        customerCampaignId: {
            type: Number,
            default: null
        },
    },
    computed: {
        changeNumberToCall: {
            get() {
                return this.numberToCall;
            },
            set(value) {
                this.onNumberUpdate(value);
                this.customerNumberToCall = null;
            }
        },
        customerMobile() {
            return Number(this.customer.mobile);
        },
        customerPhone() {
            return Number(this.customer.phone);
        },
        callingNumber() {
            return !!this.customerNumberToCall
                ? this.customerNumberToCall
                : this.numberToCall;
        },
    },
    methods: {
        onNumberUpdate(number) {
            this.numberToCall = number;
            this.originalPhoneNumber = number;
        },
    },
    created() {
        const numberToCall = !!this.customer.phone
            ? this.customer.phone
            : this.customer.mobile;

        this.onNumberUpdate(Number(numberToCall));
    }
}
</script>