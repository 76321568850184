<template>
    <div v-if="isset(gameConfiguration, 'key')" class="card player-play mb-3">
        <raffle-game-header :config="gameConfiguration" />

        <div class="card-body">
            <div class="player-play-picker">
                <validation-errors :errors="errors" />
                <div class="player-play-picker-wrapper">
                    <div>
                        <raffle-lines v-model="lines"
                                      :game-key="gameConfiguration.key"
                                      :selected-free-game="selectedFreeGame"
                                      @error="onLineErrors($event)"
                        />
                    </div>
                    
                    <div v-if="!hasSelectedFreeGame && closeToNextFractionDiscount"
                         class="alert alert-warning mt-1 p-2 w-100"
                    >
                        <strong>
                            Note: You are one fraction away to get a {{ closeToNextFractionDiscount.discount_crm }}% discount !
                        </strong>
                    </div>

                    <div>
                        <div class="buy">
                            <div class="buy-total">
                                <table>
                                    <tr class="border-bottom">
                                        <td align="left">
                                            <span>Draws:</span>
                                        </td>
                                        <td align="right">
                                            <span class="buy-total-price ml-1">1</span>
                                        </td>
                                    </tr>

                                    <tr class="border-bottom">
                                        <td align="left">
                                            <span>Fractions: </span>
                                        </td>
                                        <td align="right">
											<span v-if="!hasSelectedFreeGame"
                                                  class="buy-total-price ml-1"
                                            >
												{{ totalFractions }} x {{ gameConfiguration.price | formatCurrency }}
											</span>
                                            <span v-else
                                                  class="buy-total-price ml-1"
                                            >
                                                {{ totalFractions }} <s>x {{ gameConfiguration.price | formatCurrency }}</s>
											</span>
                                        </td>
                                    </tr>
                                    <tr v-if="!hasSelectedFreeGame"
                                        class="border-bottom"
                                    >
                                        <td align="left">
                                            <span>Discount: </span>
                                        </td>
                                        <td align="right">
											<span class="buy-total-price ml-1">
												{{ totalDiscount }} %
											</span>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td align="left">
                                            <b>Total:</b>
                                        </td>
                                        <td v-if="!hasSelectedFreeGame"
                                            align="right"
                                        >
                                            <strong v-if="totalDiscount === 0"
                                                    class="buy-total-price ml-1"
                                            >
                                                {{ formatCurrency(totalPrice) }}
                                            </strong>
                                            <strong v-if="totalDiscount !== 0"
                                                    class="buy-total-price ml-1"
                                            >
                                                <s class="text-danger mr-2">
                                                    {{ formatCurrency(totalPrice) }}
                                                </s>
                                                {{ formatCurrency(totalWithDiscount) }}
                                            </strong>
                                        </td>
                                        <td v-else
                                            align="right"
                                        >
                                            <strong class="buy-total-price ml-1">
                                                <s class="text-danger mr-2">
                                                    {{ formatCurrency(totalPrice) }}
                                                </s>
                                                {{ formatCurrency(0) }}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-if="!hasSelectedFreeGame && totalDiscount !== 0">
                                        <td align="left">
                                            <span>Saved:</span>
                                        </td>
                                        <td align="right">
                                            {{ formatCurrency(totalPrice - totalWithDiscount) }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="buy-operations">
                                <button v-if="!hasSelectedFreeGame"
                                          :disabled="lineHasErrors"
                                          name="buyBtn"
                                          type="submit"
                                          class="buy-button btn-sm mb-2 btn-success"
                                          @click="addToCart"
                                >
                                    <span>
                                        <i class="fa fa-shopping-cart" aria-hidden="false"></i> Add to Cart
                                    </span>
                                </button>
                                <button v-if="!hasSelectedFreeGame"
                                          :disabled="lineHasErrors || isPlacingOrder"
                                          class="btn btn-sm btn-danger buy-button text-uppercase"
                                          @click="addToCartAndCheckout"
                                >
                                    <span v-if="!isPlacingOrder">
                                        <i class="fa fa-credit-card" aria-hidden="false"></i> Add & Place Order
                                    </span>
                                    <loader :show="isPlacingOrder"
                                            type="smaller"
                                    />
                                </button>
                                <button v-if="hasSelectedFreeGame"
                                          class="btn btn-sm btn-warning buy-button text-uppercase"
                                          @click="useFreeTicket(ticket())"
                                          :disabled="lineHasErrors || isUseFreeLoading"
                                >
                                    <span v-if="!isUseFreeLoading">
                                        <i class="fa fa-ticket-alt" aria-hidden="false"></i> Use Free Ticket
                                    </span>
                                    <loader :show="isUseFreeLoading"
                                            type="smaller"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TypesConst from '../../../../../../store/TypesConst';
import NamespaceConst from '../../../../../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';

const {
    mapGetters: mapCartGetters,
    mapMutations: mapCartMutations
} = createNamespacedHelpers(NamespaceConst.cart);

const {
    mapGetters: mapPlayerGetters
} = createNamespacedHelpers(NamespaceConst.players);

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers(NamespaceConst.order);

import RaffleGameHeader from './Components/RaffleGameHeader';
import RaffleLines from './Components/RaffleLines';
import ValidationErrors from '../../Componenets/ValidationErrors/ValidationErrors';
import PlaceOrder from '../../Mixins/PlaceOrder';
import CheckoutAction from '../../Mixins/CheckoutAction';
import UseFreeTicket from '../../Mixins/UseFreeTicket';
import DiscountService from '../../../../../../services/DiscountService';
import round from '../../../../../../utils/Number/Round';
import formatCurrency from '../../../../../../filters/FormatCurrency';

export default {
    name: 'raffle-game',
    inject: [
        'selectFreeGame',
        'getFreeGames',
    ],
    mixins: [
        PlaceOrder,
        CheckoutAction,
        UseFreeTicket
    ],
    components: {
        RaffleLines,
        RaffleGameHeader,
        ValidationErrors
    },
    props: {
        selectedFreeGame: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            lines: [],
            lineHasErrors: false,
            errors: {},
            fractionDiscounts: [],
        };
    },
    computed: {
        ...mapPlayerGetters({
            getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
        }),
        ...mapCartGetters({
            isPlacingOrder: TypesConst.cart.getters.GET_PLACE_ORDER,
        }),
        gameConfiguration() {
            return this.$store.state.games.gameConfiguration;
        },
        totalFractions() {
            return this.lines.reduce((aggregator, item) => aggregator + Number(item.fractions), 0);
        },
        totalPrice() {
            return this.totalFractions * this.gameConfiguration.price;
        },
        getCartData() {
            return this.$store.state.cart.cartData || [];
        },
        hasSelectedFreeGame() {
            return !this.isObjectEmpty(this.selectedFreeGame);
        },
        totalDiscount() {
            const fractionDiscount = DiscountService.findFractionDiscount(this.fractionDiscounts, this.totalFractions);
            
            return fractionDiscount.discount_crm || 0;
        },
        closeToNextFractionDiscount() {
            const currentFractionDiscount = DiscountService.findFractionDiscount(this.fractionDiscounts, this.totalFractions);
            const fractionPlusDiscount = DiscountService.findFractionDiscount(this.fractionDiscounts, this.totalFractions + 1);
        
            if(currentFractionDiscount.id !== fractionPlusDiscount.id && fractionPlusDiscount.discount_crm > currentFractionDiscount.discount_crm) {
                return fractionPlusDiscount;
            }
        
            return null;
        },
        totalWithDiscount() {
            return round(this.totalPrice - (this.totalPrice * this.totalDiscount / 100), 2);
        },
    },
    async created() {
        await this.fetchGameDiscounts(this.gameConfiguration.id);
    },
    mounted() {
        this.initializeGame();
        this.$el.scrollIntoView({behavior: "smooth"});
    },
    methods: {
        formatCurrency,
        ...mapCartMutations({
            setIsPlacingOrder: TypesConst.cart.mutations.SET_PLACE_ORDER,
            clearCartData: TypesConst.cart.mutations.SET_CLEAR_CART_DATA,
            setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
            setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
        }),
        ...mapOrderMutations({
            setOrderId: TypesConst.order.mutations.SET_ORDER_ID
        }),
        onLineErrors(errors) {
            this.lineHasErrors = !!errors.length;
        },
        initializeGame () {
            if (this.hasSelectedFreeGame) {
                this.lines = [...new Array(Number(this.selectedFreeGame.meta.lines)).keys()]
                    .map(() => {
                        return {
                            type: this.gameConfiguration.key,
                            random: true,
                            numbers: [],
                            fractions: this.selectedFreeGame.meta.fractions,
                        }
                    });
            } else {
                this.lines.push({
                    type: this.gameConfiguration.key,
                    random: true,
                    numbers: [],
                    fractions: 1,
                });
            }
        },
        async fetchGameDiscounts(game, version = null) {
            const response = await DiscountService.fetchGameDiscounts(game, 'fraction', version);
            
            this.fractionDiscounts = response.data;
        },
        ticket() {
            const playerId = this.$store.state.players.playerId;

            return {
                type: this.gameConfiguration.key,
                image: this.gameConfiguration.image,
                name: this.gameConfiguration.name,
                playerId,
                lines: this.lines,
            };
        },
        addToCart() {
            const playerId = this.$store.state.players.playerId;

            this.errors = {};

            return this.$store.dispatch('cart/addToCartAction', {
                price: this.hasSelectedFreeGame ? 0 : this.totalWithDiscount,
                free_game_id: this.hasSelectedFreeGame ? this.selectedFreeGame.id : null,
                item: this.ticket(),
                playerId: playerId,
            })
                .then(() => {
                    this.selectFreeGame({});
                    this.getFreeGames();

                    this.$store.commit('cart/setShowGame', false);
                    this.$scrollToTop();
                    flash('Successfully added ticket to cart.');
                })
                .catch(error => {
                    this.errors = this.isset(error.response.data, "errors") ? error.response.data.errors: {};
                    window.showMessage("Error while adding a ticket to the cart.", false);
                });
        },
        addToCartAndCheckout() {
            this.addToCart()
                .then(() => {
                    this.placeOrder(this.totalPrice);
                });
        },
        getCartTotal() {
            const baseAccumulator = {
                lines: 0,
                price: 0,
            };

            const result = this.getCartData.items.reduce((acc, item) => {
                acc.price += item.price;

                const orderItem = Array.isArray(item.item) ? item.item : JSON.parse(item.item);
                acc.lines += orderItem.lines ? orderItem.lines.length : 0;

                return acc;
            }, baseAccumulator);

            result.price = result.price.toFixed(2);

            return result;
        },
    },
}
</script>