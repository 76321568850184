export default {
    mutations: {
        SET_PLAYER_ID: "setPlayerId",
        SET_PLAYER_DATA: "setPlayerData",
        SET_PLAYER_LOADER: `setPlayerLoader`,
        SET_CREATE_PLAYER_MODAL: `setCreatePlayerModal`,
        SET_CREATE_PLAYER_MODEL_ERRORS: `setCreatePlayerModelErrors`,
        SET_CREATE_PLAYER_MODEL: `setCreatePlayerModel`,
        SET_CREATE_PLAYER_MODEL_FIELD: `setCreatePlayerModelField`,
        SET_CREATE_PLAYER_MODEL_LOADER: `setCreatePlayerModelLoader`,
        SET_CREATE_PLAYER_MODEL_CLEAR: `setCreatePlayerModelClear`,
        SET_SHOULD_ADD_STATUS: `setShouldAddStatus`,
        SET_PLAYER_ACTIVATION_LOADER: `setPlayerActivationLoader`,
        SET_PLAYERS_ACTIVE: `setPlayerActive`,
        SET_PLAYERS_SEARCH_MODEL: `setPlayersSearchModel`,
    },
    getters: {
        GET_PLAYER_ID: "getPlayerId",
        GET_PLAYER_LOADER: `getPlayerLoader`,
        GET_CREATE_PLAYER_MODAL: `getCreatePlayerModal`,
        GET_CREATE_PLAYER_MODEL_ERRORS: `getCreatePlayerModelErrors`,
        GET_CREATE_PLAYER_MODEL: `getCreatePlayerModel`,
        GET_CREATE_PLAYER_MODEL_LOADER: `getCreatePlayerModelLoader`,
        GET_SHOULD_ADD_STATUS: `getShouldAddStatus`,
        GET_PLAYER_ACTIVATION_LOADER: `getPlayerActivationLoader`,
        GET_PLAYERS_ACTIVE: `getPlayerActive`,
        GET_PLAYERS_SEARCH_MODEL: `getPlayersSearchModel`,
    },
    actions: {
        CREATE_PLAYER_ACTION: `createPlayerAction`,
        FETCH_PLAYER_ACTION: `fetchPlayerAction`,
        ACTIVATE_PLAYER_ACTION: `activatePlayerAction`,
    }
};