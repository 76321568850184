export default {
    ticketNumber: null,
    games: [],
    name: null,
    surname: null,
    agents: null,
    drawDateFrom: null,
    drawDateTo: null,
    createdAtFrom: null,
    createdAtTo: null,
}