<template>
    <span :class="['badge', `badge-${color}`]">
      <slot v-if="$slots.default"></slot>
      <template v-else>
        {{ status }}
      </template>
    </span>
</template>

<script>
import { statuses } from './KYCStatusConstants.js';
export default {
    name: 'player-kyc-status-badge',
    props: {
	    kycStatus: {
			type: String,
            default: null
        },
    },
    data() {
        return {
            statuses: [...statuses],
        };
    },
    computed: {
        status() {
            const status = this.statuses.find(s => s.key === this.kycStatus);
            if (!status) {
                return 'Unknown status';
            }

            return status.label;
        },
        color() {
            const status = this.statuses.find(s => s.key === this.kycStatus);
            if (!status) {
                return null;
            }

            return status.color;
        },
    },
}
</script>