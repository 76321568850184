var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: _vm.modalTitle,
        "close-on-backdrop": false,
        size: "lg",
        show: true
      },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  on: { click: _vm.emitCloseEvent }
                },
                [_vm._v("\n            Close\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.submitBundleData()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.saveButtonLabel) +
                      "\n        "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Bundle Title",
                placeholder: "Enter bundle title"
              },
              model: {
                value: _vm.title,
                callback: function($$v) {
                  _vm.title = $$v
                },
                expression: "title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Bundle Description",
                placeholder: "Enter bundle description"
              },
              model: {
                value: _vm.description,
                callback: function($$v) {
                  _vm.description = $$v
                },
                expression: "description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Bundle Ribbon Text",
                placeholder: "Enter ribbon price"
              },
              model: {
                value: _vm.ribbonText,
                callback: function($$v) {
                  _vm.ribbonText = $$v
                },
                expression: "ribbonText"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.bundle
          ? _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-input", {
                  attrs: {
                    label: "Bundle Slug",
                    placeholder: "Enter bundle slug"
                  },
                  model: {
                    value: _vm.slug,
                    callback: function($$v) {
                      _vm.slug = $$v
                    },
                    expression: "slug"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c("div", { staticClass: "form-group-atbl" }, [
            _c("label", [_vm._v("Logo")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group position-relative" }, [
              _c("input", {
                ref: "logoFileInput",
                staticClass: "form-control-file",
                attrs: { type: "file", accept: "image/*" },
                on: { change: _vm.handleLogoUpload }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c("div", { staticClass: "form-group-atbl" }, [
            _c("label", [_vm._v("Background image")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group position-relative" }, [
              _c("input", {
                ref: "backgroundImageFileInput",
                staticClass: "form-control-file",
                attrs: { type: "file", accept: "image/*" },
                on: { change: _vm.handleBackgroundImageUpload }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4 mb-4" }, [
          _c(
            "div",
            { staticClass: "form-group-atbl" },
            [
              _c("label", [_vm._v("Bundle enabled state")]),
              _vm._v(" "),
              _c("atbl-check-box", {
                staticClass: "mr-1",
                attrs: { value: _vm.isActive, label: "Is bundle active" },
                on: {
                  input: function($event) {
                    _vm.isActive = !_vm.isActive
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4 mb-4" }, [
          _c(
            "div",
            { staticClass: "form-group-atbl" },
            [
              _c("label", [_vm._v("Enable for CRM")]),
              _vm._v(" "),
              _c("atbl-check-box", {
                staticClass: "mr-1",
                attrs: { value: _vm.isCrmEnabled, label: "Enable for CRM" },
                on: {
                  input: function($event) {
                    _vm.isCrmEnabled = !_vm.isCrmEnabled
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8 mb-4" }, [
          _c(
            "div",
            { staticClass: "form-group-atbl" },
            [
              _c("label", [_vm._v("Enable for API")]),
              _vm._v(" "),
              _c("atbl-check-box", {
                staticClass: "mr-1",
                attrs: { value: _vm.isApiEnabled, label: "Enable for API" },
                on: {
                  input: function($event) {
                    _vm.isApiEnabled = !_vm.isApiEnabled
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4 mb-4" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Number of lines",
                placeholder: "Enter number of lines per game"
              },
              model: {
                value: _vm.numberOfLines,
                callback: function($$v) {
                  _vm.numberOfLines = _vm._n($$v)
                },
                expression: "numberOfLines"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-8" },
          [
            _c(
              "atbl-form-group",
              {
                staticClass: "font-weight-bold",
                attrs: {
                  label: "Select jackpot currency",
                  "label-for": "jackpotCurrency",
                  horizontal: false,
                  error: null
                }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.jackpotCurrency,
                        expression: "jackpotCurrency"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.jackpotCurrency = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("Select currency")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.currencyList, function(currency) {
                      return _c(
                        "option",
                        {
                          key: "currency_item_" + currency.code,
                          domProps: { value: currency.code }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(currency.code) +
                              "\n                    "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("strong", [_vm._v("Select bundle games:")])
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.bundleGames, function(bundleGame, index) {
              return _c(
                "div",
                { key: "bundle_game_" + index, staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "atbl-form-group",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            label: index === 0 ? "Game" : "",
                            "label-for": "game",
                            horizontal: false,
                            error: null
                          }
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: bundleGame.game,
                                  expression: "bundleGame.game"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    bundleGame,
                                    "game",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("Select Game")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.gameList, function(game) {
                                return _c(
                                  "option",
                                  {
                                    key: game.value,
                                    domProps: { value: game.value }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(game.text) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group-atbl font-weight-bold" },
                      [
                        index === 0
                          ? _c("label", { staticClass: "d-block" }, [
                              _vm._v("Line price")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(_vm._s(_vm.getBundleGameLinePrice(bundleGame)))
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3 align-items-center" }, [
                    _c("div", { staticClass: "form-group-atbl" }, [
                      index === 0 ? _c("label", [_vm._v(" ")]) : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _vm.bundleGames.length > 1
                          ? _c("a", {
                              staticClass: "circle circle__minus",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.removeBundleGame(index)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        index === _vm.bundleGames.length - 1
                          ? _c("a", {
                              staticClass: "circle circle__plus",
                              attrs: { href: "#" },
                              on: { click: _vm.addBundleGame }
                            })
                          : _vm._e()
                      ])
                    ])
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-3 align-items-center" }, [
                _c("div", { staticClass: "form-group-atbl" }, [
                  _c("label", [
                    _vm._v("Total: "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.totalBundleGamePrice.toFixed(2)))
                    ])
                  ])
                ])
              ])
            ])
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("strong", [_vm._v("Add weeks:")])
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.bundleWeeks, function(bundleWeek, index) {
              return _c(
                "div",
                { key: "bundle_game_" + index, staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _c("atbl-input", {
                        attrs: {
                          label: index === 0 ? "Week" : "",
                          placeholder: "Enter week"
                        },
                        model: {
                          value: bundleWeek.week,
                          callback: function($$v) {
                            _vm.$set(bundleWeek, "week", $$v)
                          },
                          expression: "bundleWeek.week"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      bundleWeek.price
                        ? _c("div", { staticClass: "form-group-atbl" }, [
                            index === 0
                              ? _c("label", { staticClass: "d-block" }, [
                                  _vm._v("Discount")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("label", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    (
                                      100 -
                                      (bundleWeek.price ||
                                        _vm.getBundlePricePerWeek(
                                          bundleWeek.week
                                        )) /
                                        (_vm.getBundlePricePerWeek(
                                          bundleWeek.week
                                        ) /
                                          100)
                                    ).toFixed(2)
                                  )
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !bundleWeek.price
                        ? _c("atbl-input", {
                            attrs: {
                              label: index === 0 ? "Discount %" : "",
                              placeholder: "Discount"
                            },
                            model: {
                              value: bundleWeek.discount,
                              callback: function($$v) {
                                _vm.$set(bundleWeek, "discount", $$v)
                              },
                              expression: "bundleWeek.discount"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      bundleWeek.discount
                        ? _c("div", { staticClass: "form-group-atbl" }, [
                            index === 0
                              ? _c("label", { staticClass: "d-block" }, [
                                  _vm._v("Price")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("label", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    (
                                      _vm.getBundlePricePerWeek(
                                        bundleWeek.week
                                      ) -
                                      _vm.getBundlePricePerWeek(
                                        bundleWeek.week
                                      ) *
                                        ((bundleWeek.discount || 0) / 100)
                                    ).toFixed(2)
                                  )
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !bundleWeek.discount
                        ? _c("atbl-input", {
                            attrs: {
                              label: index === 0 ? "Price" : "",
                              placeholder: "Price"
                            },
                            model: {
                              value: bundleWeek.price,
                              callback: function($$v) {
                                _vm.$set(bundleWeek, "price", $$v)
                              },
                              expression: "bundleWeek.price"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _c("div", { staticClass: "form-group-atbl" }, [
                      index === 0
                        ? _c("label", { staticClass: "d-block" }, [
                            _vm._v("Regular price")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("label", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .getBundlePricePerWeek(bundleWeek.week)
                                .toFixed(2)
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3 align-items-center" }, [
                    _c("div", { staticClass: "form-group-atbl" }, [
                      index === 0 ? _c("label", [_vm._v(" ")]) : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _vm.bundleWeeks.length > 1
                          ? _c("a", {
                              staticClass: "circle circle__minus",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.removeBundleWeek(index)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        index === _vm.bundleWeeks.length - 1
                          ? _c("a", {
                              staticClass: "circle circle__plus",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.addBundleWeek(0, null, null)
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ])
                  ])
                ]
              )
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }