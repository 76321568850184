import { isObject } from '../Object/Object';
import { stringify } from '../Json/Json';

/**
 * Convert object to query string
 * Skips all nullable values
 *
 * Example: {
 *     "name": "Name",
 *     "surname": "Surname",
 *     "ticket": "123",
 *     "transaction": null,
 *     "games": [
 *         "3",
 *         "4"
 *     ],
 *     "dateFrom": "2022-01-11",
 *     "asd": {
 *         "extra": 123,
 *         "extra2": 1234
 *     }
 * }
 *
 * Result:
 * name=Name&surname=Surname&ticket=123&games%5B0%5D=3&games%5B1%5D=4&dateFrom=2022-01-11&asd={"extra":123,"extra2":1234}
 *
 * @param obj
 * @param prefix
 * @return {string}
 */
function buildQuery(obj, prefix = null) {
    let str = [];

    for (let p in obj) {
        if (!obj.hasOwnProperty(p)) {
            continue;
        }

        let objectValue = obj[p];

        if (objectValue === null || objectValue === '') {
            continue;
        }

        let k = prefix
            ? `${prefix}[${p}]`
            : p;

        let value = isObject(objectValue)
            ? encodeURIComponent(k) + '=' + stringify(objectValue)
            : encodeURIComponent(k) + '=' + encodeURIComponent(objectValue);

        if (Array.isArray(objectValue)) {
            value = buildQuery(objectValue, k);
        }

        str.push(value);
    }

    return !!str.length
        ? str.filter(item => !!item).join('&')
        : null;
}

export default buildQuery;