<template>
    <div class="form-group-atbl"
         :class="{
             'd-flex align-items-center': horizontal
         }"
    >
        <label v-if="label"
               :for="labelFor"
               :class="[
                    labelClass,
                    {
                        'm-0 mr-3': horizontal
                    }
               ]"
               v-html="label"
        />
        <slot></slot>
        <span v-if="error"
              class="form-group-atbl-error"
        >
            {{ error }}
        </span>
    </div>
</template>

<script>
    export default {
        name: "CFormGroup",
        props: {
            label: {
                type: String,
                default: ""
            },
            labelFor: {
                type: String,
                default: ""
            },
            labelClass: {
                type: String,
                default: ""
            },
            error: {
                default: null
            },
            horizontal: {
                type: Boolean,
                default: false
            }
        }
    }
</script>