import WithdrawalsService from '../../../services/WithdrawalsService.vue';
import isFunction from '../../../utils/Function/IsFuntion';
import {clone} from '../../../utils/Json/Json';
import WithdrawalDetailsModel from './WithdrawalDetailsModel';

export default {
	provide() {
		return {
			reviewWithdrawal: this.reviewWithdrawal,
			changeRequest: this.changeRequest,
			closeWithdrawalDetails: this.closeWithdrawalDetails,
		};
	},
	data(){
		return {
			playerId: null,
			withdrawalDetails: clone(WithdrawalDetailsModel),
			isWithdrawalDetailsLoading: false,
			isPending: false,
			withdrawalDetailsModal: false,
			errorMessage: null
		};
	},
	methods: {
		async reviewWithdrawal(withdrawalId, playerId){
			if (!withdrawalId && playerId) {
				return window.showMessage('Withdrawal or player id is undefined.', false);
			}

			this.playerId = playerId;
			this.withdrawalDetailsModal = true;
			this.isWithdrawalDetailsLoading = true;
			this.errorMessage = null;

			try {
				const response = await WithdrawalsService.getWithdrawalById(withdrawalId, playerId);

				this.withdrawalDetails = response.data.data;

				this.isWithdrawalDetailsLoading = false;
			} catch (error) {
				console.log(error);
				this.isWithdrawalDetailsLoading = false;

				this.errorMessage = error.response.data.message;
			}
		},
		async changeRequest(model) {
			this.isWithdrawalDetailsLoading = true;

			const url = `/players/${this.playerId}/withdrawal/${model.status === 'APPROVED' ? 'approve' : 'decline'}`

			const params = {
				...model,
				player_id: this.playerId,
			};
			delete params.status;

			try {
				const response = await window.axios.post(url, params);
				const responseMessage = response.data.message || null;

				const isEmptyResponse = !response.data;
				const isApproved = isEmptyResponse ? model.status === 'APPROVED' : false;

				const message = isEmptyResponse
					? (isApproved ? 'The withdrawal was approved' : 'The withdrawal was declined')
					: response.data.message;

				this.withdrawalDetails.status = isApproved ? 'APPROVED' : 'DECLINED';

				this.playerId = null;

				window.showMessage(message, isApproved);

				if (!!responseMessage) {
					this.errorMessage = responseMessage;
				}

				this.isWithdrawalDetailsLoading = false;
			} catch (error) {
				this.isWithdrawalDetailsLoading = false;

				this.errorMessage = error.response.data.message;

				window.flashError(error);
			}
		},
		async closeWithdrawalDetails() {
			this.errorMessage = null;
			this.withdrawalDetailsModal = false;
			this.withdrawalDetails = clone(WithdrawalDetailsModel);

			if (! this.afterWithdrawalClose || ! isFunction(this.afterWithdrawalClose)) {
				return;
			}

			await this.afterWithdrawalClose();
		},
	},
}