<template>
    <div v-if="$can('Player - Block/Unblock')"
         class="block-player text-left"
    >
        <button v-if="!isPlayerBlocked"
                class="btn btn-danger text-white"
                @click="showBlockModal = true"
        >
            Block
        </button>
        <button v-else
                class="btn btn-outline-danger"
                @click="showUnblockModal = true"
        >
            Unblock
        </button>
        
        <atbl-modal title="Block Player"
                    :show="showBlockModal"
                    :show-close="!isLoading"
                    @close="onBlockModalClose"
        >
            <atbl-textarea v-model="reason"
                           :errors="validationErrors"
                           :rows="5"
                           label="Reason"
                           name="reason"
                           class="mb-0"
            />
            <span :class="{
                    'badge-danger': !isFormValid,
                    'badge-secondary': isFormValid
                  }"
                  class="mt-1 badge font-sm"
            >
                {{ !reason ? 0 : reason.length }} / 255
            </span>
            
            <template #footer>
                <button v-if="!isLoading"
                        class="btn btn-sm btn-light"
                        @click="onBlockModalClose"
                >
                    Cancel
                </button>
                <button :disabled="isLoading || !isFormValid"
                        class="btn btn-sm btn-success text-white"
                        @click="onBlockUnblockPlayer"
                >
                    <span v-if="!isLoading">Confirm</span>
                    <loader :show="isLoading"
                            type="smaller"
                    />
                </button>
            </template>
        </atbl-modal>
        
        <atbl-modal title="Unblock Player"
                    :show="showUnblockModal"
                    :show-close="!isLoading"
                    @close="showUnblockModal = false"
        >
            <h4 class="text-center">
                Do you really want to unblock the player <strong>{{ player.name }}</strong>?
            </h4>
            
            <template #footer>
                <button v-if="!isLoading"
                        class="btn btn-sm btn-light"
                        @click="showUnblockModal = false"
                >
                    Cancel
                </button>
                <button :disabled="isLoading"
                        class="btn btn-sm btn-success text-white"
                        @click="onBlockUnblockPlayer"
                >
                    <span v-if="!isLoading">Confirm</span>
                    <loader :show="isLoading"
                            type="smaller"
                    />
                </button>
            </template>
        </atbl-modal>
    </div>
</template>

<script>
    import PlayerService from '../../../../../services/PlayerService';
    import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';
    import AtblTextarea from '../../../../../components/Forms/Inputs/ATBLTextarea.vue';
    import getValidationErrors from '../../../../../utils/Error/GetValidationErrors';
    
    export default {
        name: 'BlockPlayerProfile',
        components: {
            AtblModal,
            AtblTextarea,
        },
        props: {
            player: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
                showBlockModal: false,
                showUnblockModal: false,
                reason: null,
                validationErrors: {},
            };
        },
        computed: {
            isPlayerBlocked() {
                return !!this.player.block;
            },
            isFormValid() {
                if (!this.reason) {
                    return false;
                }
                
                if (this.reason.length < 5) {
                    return false;
                }
                
                return this.reason.length <= 255;
            },
        },
        methods: {
            async onBlockUnblockPlayer() {
                if(!this.$can('Player - Block/Unblock')) {
                    return window.showMessage('You are not authorized to perform this action.', false);
                }
                
                this.isLoading = true;
                
                const playerId = this.player.id;
                
                try {
                    const response = await PlayerService.blockUnblockPlayer(playerId, {
                        reason: this.reason
                    });
                    const { data, status, message } = response.data;
                    
                    window.showMessage(message, status);
                    
                    if (!status) {
                        return;
                    }
                    
                    this.$emit('update', data);
                    
                    this.showBlockModal = false;
                    this.showUnblockModal = false;
                    this.reason = null;
                } catch (error) {
                    this.validationErrors = getValidationErrors(error);
                    
                    window.flashError(error);
                } finally {
                    this.isLoading = false;
                }
            },
            onBlockModalClose() {
                this.showBlockModal = false;
                this.reason = null;
                this.validationErrors = {};
            },
        }
    };
</script>