<template>
    <form @submit.prevent="fetchPhoneNumbersList">
        <div class="card card-search">
            <div class="card-header">
                <i class="fa fa-search"></i> Search
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-3">
                        <atbl-input :value="model.phone_number"
                                    label="Phone number"
                                    name="phone_number"
                                    class="mb-0"
                                    @update="setFilterModelProperty"

                        />
                    </div>
                    <div class="col-3">
                        <atbl-form-group label="Include inactive"
                                         label-for="is_active"
                        >
                            <atbl-checkbox v-model="isActive"
                                           id="is_active"
                            />
                        </atbl-form-group>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-sm btn-primary mr-2">
                    <i class="fa fa-search"></i> Search
                </button>
                <button type="button"
                        class="btn btn-sm btn-danger"
                        @click="clearFilterModel"
                >
                    <i class="fa fa-ban"></i> Clear
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import AtblInput from '../../../components/Forms/Inputs/ATBLInput.vue';
import PhoneNumberModel from '../UpdatePhoneNumber/PhoneNumberModel';
import AtblFormGroup from '../../../components/Forms/Holders/ATBLFormGroup.vue';
import AtblCheckbox from '../../../components/Forms/Inputs/AtblCheckbox.vue';

export default {
    name: 'PhoneNumbersFilterFormComponent',
    inject: [
        'fetchPhoneNumbersList',
        'setFilterModelProperty',
        'clearFilterModel',
    ],
    components: {
        AtblInput,
        AtblCheckbox,
        AtblFormGroup,
    },
    props: {
        model: {
            type: Object,
            default: () => (PhoneNumberModel)
        },
    },
    computed: {
        isActive: {
            get() {
                return this.model.is_active;
            },
            set(value) {
                this.setFilterModelProperty('is_active', value);
            },
        },
    },
}
</script>