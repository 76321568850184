import { isObject } from './Object/Object';

export default {
    formatNumber(num, decimals, decimalSeparator, thousandsSeparator) {
        decimals = decimals || 2;
        decimalSeparator = decimalSeparator || '.';
        thousandsSeparator = thousandsSeparator || ',';
        const thousandsLength = 3;

        const regexpPattern = '\\d(?=(\\d{' + thousandsLength + '})+' + (decimals > 0 ? '\\D' : '$') + ')';
        const numParsed = parseFloat(num)
            .toFixed(Math.max(0, ~~decimals))
            .replace('.', decimalSeparator);

        return numParsed.replace(new RegExp(regexpPattern, 'g'), '$&' + thousandsSeparator);
    }
}

export function filterNonEmpty(v) {
    const isObj = isObject(v);

    if (!Array.isArray(v) && !isObj) {
        return [];
    }

    return (isObj ? Object.values(v) : v).filter(String).filter(item => item !== null);
}