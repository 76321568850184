<template>
  <player-layout :id="id"
                 title="Withdrawals"
                 class="mb-3"
                 icon="fa fa-credit-card"
  >
    <template #first>
      <div class="card">
        <div class="card-body d-flex align-items-center justify-content-between flex-wrap">
          <template v-if="!isWalletLoading">
            <div class="font-weight-bold">
              Wallet
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <span class="mr-2">
                <i class="fa fa-wallet mr-1"></i>
                Real funds: <strong>{{ formatCurrency(wallet.real_funds) }}</strong>
              </span>
              <span>
                <i class="fa fa-wallet mr-1 mr-1"></i>
                Bonus funds: <strong>{{ formatCurrency(wallet.bonus_funds) }}</strong>
              </span>
            </div>
          </template>
          <loader :show="isWalletLoading"
                  type="small"
          />
        </div>
      </div>
      <player-create-withdrawal-component v-if="$can('Withdrawals - Create')"
                                          :player-id="id"
      />
    </template>

    <player-withdrawals-list :withdrawals="withdrawals"
                             :withdrawalsData="withdrawalsData"
                             :isLoading="isLoading"
                             :isAdmin="isAdmin"
    />

    <review-withdrawal-component v-if="withdrawalDetailsModal"
                                 :is-loading="isWithdrawalDetailsLoading"
                                 :withdrawal-details="withdrawalDetails"
                                 :error-message="errorMessage"
    />
  </player-layout>
</template>

<script>
	import PlayerWithdrawalsList from './Sections/PlayerWithdrawalsList';
	import PlayerCreateWithdrawalComponent from './CreateWithdrawal/PlayerCreateWithdrawalComponent.vue';
	import PlayerWithdrawalService from '../../../../services/PlayerWithdrawalsService';
	import ReviewWithdrawalComponent
		from '../../../Withdrawals/ReviewWithdrawal/ReviewWithdrawalComponent.vue';
	import ReviewWithdrawal from '../../../Withdrawals/ReviewWithdrawal/ReviewWithdrawal';
	import PlayerService from '../../../../services/PlayerService.vue';
	import formatCurrency from '../../../../filters/FormatCurrency';

	export default {
		name: 'player-withdrawals-page',
		mixins: [
			ReviewWithdrawal,
		],
		props: {
			id: {
				type: Number,
				required: true
			},
			auth_user: {
				type: Number,
				required: true
			},
		},
		components: {
			PlayerWithdrawalsList,
			PlayerCreateWithdrawalComponent,
			ReviewWithdrawalComponent,
		},
		provide() {
			return {
				getPlayerWithdrawals: this.getPlayerWithdrawals,
				onFetchPlayerWallet: this.onFetchPlayerWallet,
			};
		},
		data() {
			return {
				withdrawals: [],
				withdrawalsData: {},
				isLoading: false,
				isWalletLoading: false,
				wallet: {},
			};
		},
		computed: {
			isAdmin() {
				return this.isadmin === 'true';
			}
		},
		async created() {
			this.onFetchPlayerWallet();
			await this.getPlayerWithdrawals();
		},
		methods: {
			formatCurrency,
			// this method is called after withdrawal close button is pressed
			// call is made in ReviewWithdrawal.js -> closeWithdrawalDetails()
			async afterWithdrawalClose() {
				this.onFetchPlayerWallet();
				await this.getPlayerWithdrawals();
			},
			// get player withdrawals list
			async getPlayerWithdrawals(page = 1) {
				this.isLoading = true;

				try {
					const response = await PlayerWithdrawalService.getPlayerWithdrawals(this.id, page);

					this.withdrawalsData = response.data;
					this.withdrawals = response.data.data;

					this.isLoading = false;
				} catch (error) {
					this.isLoading = false;
				}
			},
			async onFetchPlayerWallet() {
				try {
					this.isWalletLoading = true;
					const response = await PlayerService.fetchPlayerWallet(this.id);

					this.wallet = response.data.data;

					this.isWalletLoading = false;
				} catch (error) {
					this.isWalletLoading = false;
					window.flashError(error);
				}
			}
		},
	};
</script>