<template>
    <div class="container-fluid ticket-results">
        <div class="row">
            <div class="col-12">
                <bundle-ticket-info v-if="bundleTicket" :bundle-ticket="bundleTicket" />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <bundle-ticket-lines v-if="bundleTicket" :bundle-ticket="bundleTicket" />
            </div>
        </div>
    </div>
</template>

<script>
import BundleTicketInfo from './Sections/BundleTicketInfo';
import BundleTicketLines from './Sections/BundleTicketLines';
import BundleTicketsService from '../../../services/BundleTicketsService';

export default {
    name: 'bundle-ticket',

    components: { BundleTicketInfo, BundleTicketLines },

    props: {
        id: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            bundleTicket: null,
        };
    },

    created() {
        this.fetchBundleTicket();
    },

    methods: {
        fetchBundleTicket() {
            BundleTicketsService.show(this.id).then((response) => {
                this.bundleTicket = response.data;
            });
        }
    },
}
</script>
