<template>
    <div class="card card-search">
        <div class="card-header d-flex align-items-center justify-content-between">
            <span><i class="fa fa-search" aria-hidden="false"></i> Search</span>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <atbl-input :value="model.name"
                                label="Name"
                                name="name"
                                @update="changeFilterModelField"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <atbl-input :value="model.surname"
                                label="Surname"
                                name="surname"
                                @update="changeFilterModelField"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <atbl-input :value="model.email"
                                label="Email"
                                name="email"
                                @update="changeFilterModelField"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <countries-options v-model.trim="model.country"
                                       :error="{}"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <customer-status-picker :value="model.status"
                                            :include-no-status="true"
                                            @update="changeFilterModelField"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <atbl-input v-model="model.phone"
                                label="Phone"
                                name="phone"
                                @update="changeFilterModelField"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <balance-select label="Live Spent"
                                    v-model="balanceSelect"
                                    :balance-types="false"
                                    :error-message="errors.balanceSelector"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <batch-files-picker :value="model.batch"
                                        @update="changeFilterModelField" />
                </div>
                <div class="col-12 col-lg-3">
                    <atbl-select v-model="changeConverted"
                                 :options="convertedOptions"
                                 :first-option-label="null"
                                 label="Converted"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <campaigns-picker v-model="changeCampaignId" />
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button type="submit"
                      class="btn btn-sm btn-primary"
                      :disabled="!isSearchEnabled"
                      @click="$emit('search')"
            >
                <i class="fa fa-search"></i> Search
            </button>
            <button type="reset"
                      class="btn btn-sm btn-danger"
                      @click="onClearFilterForm"
            >
                <i class="fa fa-ban"></i> Clear
            </button>
        </div>
    </div>
</template>

<script>
import CountriesOptions from '../../../../../components/Forms/Select/CountriesOptions';
import CustomerStatusPicker from '../../../_Components/CustomerStatus/CustomerStatusPicker';
import LeadsFilterFormModel from './LeadsFilterFormModel';
import BatchFilesPicker from '../../../_Components/BatchFiles/BatchFilesPicker';
import { booleanFromString } from '../../../../../utils/Boolean';
import CampaignsPicker from '../../../_Components/CampaignsPicker/CampaignsPicker';
import removeUndefinedProperties from '../../../../../utils/Object/RemoveUndefinedProperties';
import { isObject, isObjectEmpty } from '../../../../../utils/Object/Object';

export default {
    name: 'leads-filter-form-component',
    inject: [
        'changeFilterModelField',
        'onClearFilterFormEvent',
    ],
    emits: [
        'search',
    ],
    components: {
        CampaignsPicker,
        BatchFilesPicker,
        CustomerStatusPicker,
        CountriesOptions,
    },
    props: {
        model: {
            type: Object,
            default: () => (LeadsFilterFormModel)
        },
    },
    data() {
        return {
            convertedOptions: [
                {
                    label: 'All',
                    value: null,
                },
                {
                    label: 'Converted',
                    value: true,
                },
                {
                    label: 'Un-Converted',
                    value: false,
                },
            ],
            errors: {
                balanceSelector: null,
                balanceSelectorValidatorMessage: null
            },
        };
    },
    computed: {
        balanceSelect: {
            get() {
                return {
                    balance: this.model.balance,
                    balanceSelector: this.model.balanceSelector
                };
            },
            set(value) {
                if (isObject(value) && isObjectEmpty(value)) {
                    return;
                }

                this.changeFilterModelField('balance', value.balance);
                this.changeFilterModelField('balanceSelector', value.balanceSelector);

                this.errors.balanceSelector = null;
                this.errors.balanceSelectorValidatorMessage = value.errorMessage;
            }
        },
        changeConverted: {
            get() {
                return this.model.converted;
            },
            set(value) {
                const converted = value === null || value === ''
                    ? null
                    : booleanFromString(value);

                this.changeFilterModelField('converted', converted);
            }
        },
        changeCampaignId: {
            get() {
                return this.model.campaigns;
            },
            set(value) {
                this.changeFilterModelField('campaigns', value);
            }
        },
        isSearchEnabled() {
            const invalidInputs = removeUndefinedProperties(this.errors);

            return !!this.validateInputs() && !!isObjectEmpty(invalidInputs);
        },
    },
    methods: {
        onClearFilterForm() {
            this.errors.balanceSelector = null;
            this.errors.balanceSelectorValidatorMessage = null;
            this.onClearFilterFormEvent();
        },
        validateInputs() {
            const {
                balanceSelector,
                balance,
            } = this.model;

            if (!balance && !balanceSelector) {
                return true;
            }

            const validatorMessage = !!this.errors.balanceSelectorValidatorMessage
                ? this.errors.balanceSelectorValidatorMessage
                : null;

            const isBalanceValid = !!balance && !!balanceSelector;

            this.errors.balanceSelector = !!isBalanceValid
                ? validatorMessage
                : 'Live spent values are invalid, no filter will be applied.';

            return isBalanceValid;
        },
    },
}
</script>