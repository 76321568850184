<template>
    <div class="container-fluid game-draws">
        <div class="row">
            <div class="col-12 col-lg-5">
                <atbl-tabs>
                    <atbl-tab v-if="$can('Game Draws - Drawn Games')"
                              title="Drawn Games"
                    >
                        <drawn-games-component />
                    </atbl-tab>
                    <atbl-tab v-if="$can('Game Draws - Current Draws')"
                              title="Current Draws"
                    >
                        <current-draws-component />
                    </atbl-tab>
                </atbl-tabs>
            </div>
            <div class="col-12 col-lg-7">
                <draws-editor v-if="$canAny(['Game Draws - Drawn Games', 'Game Draws - Current Draws'])" />
            </div>
        </div>
    </div>
</template>

<script>
import DrawnGamesComponent from './Sections/DrawnGames/DrawnGamesComponent';
import DrawsEditor from './Sections/DrawsEditor/DrawsEditor';
import AtblTabs from '../../../components/AtblTabs/AtblTabs';
import AtblTab from '../../../components/AtblTabs/AtblTab';
import CurrentDrawsComponent from './Sections/CurrentDraws/CurrentDrawsComponent';

export default {
    name: 'games-draws',
    components: {
        DrawnGamesComponent,
        DrawsEditor,
        AtblTabs,
        AtblTab,
        CurrentDrawsComponent,
    },
}
</script>