var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Create Currency Rate",
        show: _vm.modalOpened,
        "close-on-backdrop": false
      },
      on: {
        close: function($event) {
          _vm.modalOpened = false
        }
      },
      scopedSlots: _vm._u(
        [
          _vm.$can("Conversions Rate - Create/Update")
            ? {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: { click: _vm.cancelModal }
                      },
                      [_vm._v("\n            Close\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.submitModal }
                      },
                      [_vm._v("\n            Save\n        ")]
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("atbl-input", {
              attrs: {
                label: "Enter currency code",
                name: "currency_code",
                errors: _vm.errors
              },
              model: {
                value: _vm.editModel.currency_code,
                callback: function($$v) {
                  _vm.$set(
                    _vm.editModel,
                    "currency_code",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "editModel.currency_code"
              }
            }),
            _vm._v(" "),
            _c("atbl-input", {
              attrs: {
                label: "Enter currency name (ISO 4217 Currency Name)",
                name: "currency_name",
                errors: _vm.errors
              },
              model: {
                value: _vm.editModel.currency_name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.editModel,
                    "currency_name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "editModel.currency_name"
              }
            }),
            _vm._v(" "),
            _c("atbl-input", {
              attrs: { label: "Enter rate", name: "rate", errors: _vm.errors },
              model: {
                value: _vm.editModel.rate,
                callback: function($$v) {
                  _vm.$set(
                    _vm.editModel,
                    "rate",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "editModel.rate"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }