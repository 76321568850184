<template>
  <div class="magic-link-redirect-form">
    <atbl-form-group>
      <label for="magic-link-default" class="mr-2">
        <input v-model="redirectOption"
               :disabled="!!disabled"
               type="radio"
               id="magic-link-default"
               name="magic-link-redirect"
               value="1"
        />
        Default
        <span class="badge badge-secondary font-sm ml-2s">
          {{ language }}{{ defaultRedirect }}
        </span>
      </label>
      <br/>
      <label for="magic-link-custom">
        <input v-model="redirectOption"
               :disabled="!!disabled"
               type="radio"
               id="magic-link-custom"
               name="magic-link-redirect"
               value="2"
        />
        Custom
      </label>
    </atbl-form-group>

    <div v-if="isCustomOptionSelected"
         class="magic-link-redirect-form__custom-link"
    >
      <atbl-input v-if="!!language"
                  :value="language"
                  disabled
                  name="language"
      />
      <atbl-input v-model="redirect"
                  :disabled="!!disabled"
                  name="redirect"
      />
    </div>
  </div>
</template>

<script>
    import Language from '../../../../../../../modules/languages/Language';

	export default {
		name: 'MagicLinkRedirectFormComponent',
		emits: ['update'],
		props: {
			disabled: {
				type: Boolean,
				default: false,
			},
			type: {
				type: String,
				default: null
			},
			locale: {
				type: Object,
				default: () => ({})
			},
		},
		data() {
			return {
				option: 1,
				customRedirect: null,
                magicLinkSettings: {
                  magic_link_default_redirect_url: null,
                  magic_link_default_email_redirect_url: null,
                },
			};
		},
		computed: {
            language() {
              if (!this.locale || !this.locale.value) {
                return '';
              }
              
              const locale = Language.resolve(this.locale.value);
              
              return locale === Language.en
                ? ''
                : `/${locale}`;
            },
			defaultRedirect() {
				return this.type === 'email'
					? this.magicLinkSettings.magic_link_default_email_redirect_url
					: this.magicLinkSettings.magic_link_default_redirect_url;

			},
			redirectOption: {
				get() {
					return this.option;
				},
				set(value) {
					this.option = Number(value);

					this.onUpdateForm();
				}
			},
			redirect: {
				get() {
					return this.customRedirect;
				},
				set(value) {
					this.customRedirect = value;

					this.onUpdateForm();
				}
			},
			isCustomOptionSelected() {
				return this.redirectOption === 2;
			},
		},
        async created() {
          await this.getMagicLinkSettings();
        },
		methods: {
			onUpdateForm() {
				const value = this.isCustomOptionSelected
                  ? `${this.language}${this.redirect}`
                  : `${this.language}${this.defaultRedirect}`;

				this.$emit('update', value);
			},
            async getMagicLinkSettings() {
              const response = await window.axios.get('/short-url/magic-link-settings');
              
              this.magicLinkSettings = response.data;
            },
		},
	};
</script>