var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("kyc-submission-search", {
        staticClass: "mb-3",
        on: { search: _vm.onSearchEvent, clear: _vm.clearSearchModel }
      }),
      _vm._v(" "),
      !!_vm.showTable
        ? _c("div", { staticClass: "card card-list" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex justify-content-between align-items-center"
              },
              [
                _c("div", [
                  _c("i", { staticClass: "fa fa-archive" }),
                  _vm._v(" KYC Submissions\n                "),
                  _vm.players.length
                    ? _c("span", [
                        _vm._v(
                          "\n                    (" +
                            _vm._s(_vm.players.length) +
                            ")\n                "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                !!_vm.hasMore
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                Search result contains more than " +
                          _vm._s(_vm.limitResults) +
                          " rows, please narrow your search.\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    !!_vm.players.length
                      ? _c("atbl-select", {
                          staticClass: "mr-2 mb-0",
                          attrs: {
                            options: _vm.paginationModel.data,
                            value: _vm.paginationModel.default,
                            "first-option-label": null,
                            "option-label": "text"
                          },
                          on: { input: _vm.onPaginationLimitChangeEvent }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                !_vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "general-list v-sortable",
                        on: { sort: _vm.onUpdateSortEvent }
                      },
                      [
                        _c("div", { staticClass: "row d-none d-md-flex" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "sort",
                                  rawName: "v-sort:full_name",
                                  value: _vm.sort,
                                  expression: "sort",
                                  arg: "full_name"
                                }
                              ],
                              staticClass: "col text-muted"
                            },
                            [_vm._v("Player")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "sort",
                                  rawName: "v-sort:agent_name",
                                  value: _vm.sort,
                                  expression: "sort",
                                  arg: "agent_name"
                                }
                              ],
                              staticClass: "col text-muted"
                            },
                            [_vm._v("Agent")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "sort",
                                  rawName: "v-sort:last_activity",
                                  value: _vm.sort,
                                  expression: "sort",
                                  arg: "last_activity"
                                }
                              ],
                              staticClass: "col text-muted"
                            },
                            [_vm._v("Last Activity at")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "sort",
                                  rawName: "v-sort:kyc_status",
                                  value: _vm.sort,
                                  expression: "sort",
                                  arg: "kyc_status"
                                }
                              ],
                              staticClass: "col text-muted"
                            },
                            [_vm._v("Status")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col text-muted" }, [
                            _vm._v("Action")
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.playersData.data, function(player) {
                          return [
                            _c("div", { key: player.id, staticClass: "row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "d-block",
                                    attrs: {
                                      href: "/players/" + player.id + "/view"
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-link" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(player.name) +
                                        " " +
                                        _vm._s(player.surname) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      player.user ? player.user.name : ""
                                    ) +
                                    "\n                        "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.lastActivityAt(player)) +
                                    "\n                        "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c("player-kyc-status-badge", {
                                    attrs: { "kyc-status": player.kyc_status }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "d-block",
                                    attrs: {
                                      href:
                                        "/players/" + player.id + "/kyc/list"
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-flag" }),
                                    _vm._v(
                                      " Review submission\n                            "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        }),
                        _vm._v(" "),
                        !_vm.isLoading
                          ? _c("not-found", {
                              attrs: {
                                msg: "No players found.",
                                items: _vm.players
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isLoading && _vm.playersData.last_page > 1
              ? _c("div", { staticClass: "card-footer overflow-x-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c(
                        "nav",
                        { staticClass: "wrap" },
                        [
                          _c("atbl-pagination", {
                            attrs: {
                              pagination: _vm.playersData,
                              scroll: false
                            },
                            on: { paginate: _vm.onPageChangeEvent }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$scrollToTop()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-caret-up mr-2" }),
                          _vm._v(" Back to top\n                ")
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }