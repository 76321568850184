var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "discounts" }, [
            _c(
              "div",
              { staticClass: "discounts-list" },
              [
                _c("DiscountsList", {
                  attrs: { games: _vm.games, "editing-game": _vm.editingGame }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.hasGameForEditing
              ? _c(
                  "div",
                  { staticClass: "discounts-editor" },
                  [
                    _c("DiscountEditorComponent", {
                      attrs: {
                        "editing-game": _vm.editingGame,
                        "line-data": _vm.linesData,
                        "draw-data": _vm.drawData,
                        "fraction-data": _vm.fractionsData
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }