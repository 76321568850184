<template>
    <player-layout :id="id"
                   icon="fa fa-user"
                   title="Player"
                   header
                   body-class="py-3"
    >
        <template #header>
	        <div>
		        <i class='fa fa-align-justify'></i> Player
	        </div>
        </template>
	    
	    <template #rightSide>
            <div v-if="$canAny(['Player - View Agent', 'Player - Assign Agent'])"
                 class="d-flex align-items-center"
            >
		        <span class="mr-1 text-uppercase">
			        <small class="font-weight-bold">Agent</small>
		        </span>
                <span v-if="$can('Player - View Agent') && !$can('Player - Assign Agent')"
                         class="badge badge-danger atbl-badge-padding rounded-0"
                >
                    {{ player && player.user ? player.user.name : "/" }}
                </span>
                <a v-if="$can('Player - Assign Agent') && !agentLoader"
                   href="#"
                   @click.prevent="showAssignAgentForm = true"
                >
                    <span class="badge badge-danger atbl-badge-padding rounded-0">
                        {{ player && player.user ? player.user.name : "/" }} <i class="fa fa-pencil-alt ml-1"></i>
                    </span>
                </a>
                <loader :show="agentLoader"
                        type="smaller"
                />
            </div>
	    </template>

        <div v-show="!isLoadingNow">
            <PreviewPlayer v-if="!isEdit"
                           :player="player"
            />
	        <EditPlayerForm v-else
	                        :player="modelTemp"
	                        :errors="errors"
	                        @update:player-edit-form="updateModel"
	        />
	        
            <div v-if="!isEdit" class="row justify-content-between">
                <div class="col-12">
	               <player-tags :player="player" />
                </div>
            </div>
        </div>

        <loader type="small"
                :show="isLoadingNow"
        />

        <template slot="footer">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="player-profile-actions-holder text-center text-lg-left">
                    <small class="text-muted">
                        Created: <b>{{ utcToLocal(player.created_at) }}</b>
                    </small><br/>
                    <small class="text-muted">
                        Updated: <b>{{ utcToLocal(player.updated_at) }}</b>
                    </small>
                </div>
                <div v-if="!isLoadingNow"
                     class="player-profile-actions-holder text-center text-lg-right d-flex"
                >
                    <extra-actions-component v-if="!isEdit"
                                             :player="player"
                                             class="mr-2"
                    />
                    <button v-if="$can('Player - Create/Update') && !isEdit"
                              class="btn btn-sm btn-outline-primary mr-2"
                              @click="onEditPlayerToggle">Edit Profile
                    </button>
                    <button v-if="$can('Player - Reset Password') && !isEdit"
                              class="btn btn-sm btn-primary"
                              @click="showResetModal = true">Reset Password
                    </button>
                    <activate-player-profile v-if="!isEdit"
                                             :player="player"
                                             class="ml-2"
                    />
                    <BlockPlayerProfile v-if="!isEdit"
                                        :player="player"
                                        class="ml-2"
                                        @update="setPlayerModel({block: $event})"
                    />
                    <button v-if="$can('Player - Create/Update') && isEdit"
                              class="btn btn-sm btn-primary mr-2"
                              @click="update(player.id)">Save
                    </button>
                    <button v-if="isEdit"
                              class="btn btn-sm btn-secondary"
                              @click="editPlayerToggleExit">Exit
                    </button>
                </div>
            </div>
        </template>
	    
	    <template #last>
		    <PlayerStatistics :player="player"
		                      :is-loading="isLoadingNow"
		    />
	    </template>
	    
	    <ResetPassword v-model="showResetModal"
	                   :player-id="player.id"
	    />
        <AssignAgent :show="showAssignAgentForm"
                     :player="player"
                     @success="fetchPlayer(true)"
                     @close="showAssignAgentForm = false"
        />
    </player-layout>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import {createNamespacedHelpers} from "vuex";

    const {
        mapGetters,
        mapMutations,
        mapActions
    } = createNamespacedHelpers(NamespaceConst.players);

    import ActivatePlayerProfile from "./Components/ActivatePlayerProfile";
    import playerNav from '../../Components/Layout/PlayerNav';
    import playerInfo from '../../Components/Layout/PlayerInfo';
    import BlockPlayerProfile from "./Components/BlockPlayerProfile";
    import PlayerTags from "./Components/PlayerTags";
    import PlayerStatistics from "./Components/PlayerStatistics";
    import EditPlayerForm from "./Forms/EditPlayerForm";
    import PreviewPlayer from "./Sections/PreviewPlayer";
    import ResetPassword from "./Forms/ResetPassword";
    import ExtraActionsComponent from "./ExtraActions/ExtraActionsComponent.vue";
    import AssignAgent from "../../Components/Forms/AssignAgent/AssignAgent";

    export default {
        name: "player-profile",
	    props: [
		    'id'
	    ],
        components: {
            playerNav,
            playerInfo,
            ActivatePlayerProfile,
	        BlockPlayerProfile,
	        PlayerTags,
	        PlayerStatistics,
	        EditPlayerForm,
	        PreviewPlayer,
	        ResetPassword,
	        ExtraActionsComponent,
	        AssignAgent
        },
        provide() {
            return {
                setPlayerModel: this.setPlayerModel,
            };
        },
        data() {
            return {
                modelTemp: {},
                isEdit: false,
                showResetModal: false,
                isLoading: false,
                errors: {},
                model: {},
                showAssignAgentForm: false,
	            agentLoader: false
            }
        },
        computed: {
            ...mapGetters({
                player: TypesConst.players.getters.GET_CREATE_PLAYER_MODEL,
                playerLoader: TypesConst.players.getters.GET_PLAYER_LOADER
            }),
            isLoadingNow() {
                return this.isLoading || this.playerLoader;
            }
        },
        methods: {
        	...mapMutations({
		        setPlayerModel: TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL_FIELD,
	        }),
        	...mapActions({
		        fetchPlayerAction: TypesConst.players.actions.FETCH_PLAYER_ACTION
	        }),
	        updateModel(value) {
	        	this.model = value;
	        },
            onEditPlayerToggle() {
                this.isEdit = true;
                this.modelTemp = Object.assign({}, this.player);
            },
	        editPlayerToggleExit() {
		        this.isEdit = false;
		        this.model = this.modelTemp;
		        this.modelTemp = {};
		        this.errors = {};
	        },
	        fetchPlayer(agentLoader = false) {
                this.agentLoader = agentLoader;
		        this.fetchPlayerAction(true)
		            .then(() => {
                        this.isLoading = false;
                        this.agentLoader = false;
		            });
	        },
            update(id) {
                this.isLoading = true;

                this.modelTemp = this.model;

                window.axios.patch(
                	'/player/' + id,
	                _.omit(this.model, ["tags"])
                )
	                .then(response => {
						const { message } = response.data;
                        this.setPlayerModel(this.model);
                        this.editPlayerToggleExit();
                        this.fetchPlayer();
	                    window.showMessage(message)
	                })
	                .catch(error => {
                        this.errors = error.response.data.errors;

                        window.flashError(error);
	                })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
        watch: {
            player: {
                handler(value) {
                    this.modelTemp = value;
                },
                deep: true
            },
        },
    }
</script>