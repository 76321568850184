var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      attrs: {
        value: _vm.show,
        "body-class": "" + (_vm.show ? "pt-0" : ""),
        title: "Update phone number"
      },
      on: { input: _vm.toggleUpdateWindow },
      scopedSlots: _vm._u(
        [
          !_vm.isLoading
            ? {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-outline-danger mr-2",
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.toggleUpdateWindow }
                      },
                      [_vm._v("\n            Cancel\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.onUpdatePhoneNumber }
                      },
                      [
                        !_vm.isLoading
                          ? _c("span", [
                              _vm._v("\n                Save\n            ")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { show: _vm.isLoading, type: "smaller" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      !!_vm.show && !_vm.isLoading
        ? _c("div", { staticClass: "general-list" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
                _vm._v("\n                Phone Number\n            ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg-6 font-weight-normal" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.phoneNumber.phone_number) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
                _vm._v("\n                Status\n            ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg-6" }, [
                _c(
                  "span",
                  {
                    class:
                      "badge badge-" +
                      _vm.resolveStatusColor(_vm.phoneNumber.status)
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.phoneNumber.status) +
                        "\n                "
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
                _vm._v("\n                Provider\n            ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-lg-6" }, [
                _c(
                  "span",
                  {
                    class:
                      "badge badge-" +
                      _vm.resolveProviderColor(_vm.phoneNumber.provider)
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.phoneNumber.provider) +
                        "\n                "
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
                _vm._v("\n                Allowed for\n            ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-6" },
                _vm._l(["players", "leads"], function(type) {
                  return _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("atbl-checkbox", {
                        staticClass: "mb-2",
                        attrs: {
                          value: _vm.phoneNumber.allowed_for.includes(type),
                          label: _vm.ucFirst(type),
                          name: "is_active"
                        },
                        on: {
                          update: function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.updateAllowedTypesModel.apply(
                              void 0,
                              argsArray.concat([type])
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-6 font-weight-bold" }, [
                _vm._v("\n                Is Active\n            ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-lg-6 d-flex justify-content-start align-items-center"
                },
                [
                  _c("atbl-checkbox", {
                    attrs: {
                      value: _vm.phoneNumber.is_active,
                      name: "is_active"
                    },
                    on: { update: _vm.updateIsActiveStatusModel }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }