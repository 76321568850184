<template>
    <div class="container-fluid">
        <TransactionsSearch v-if="showFilter"
                            @search="onSearchEvent"
                            @clear="clearSearchModel"
        />

        <div v-if="!!showTable"
                class="card card-list"
        >
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i class="fa fa-exchange-alt"></i> Transactions
                </div>
                <span v-if="!!hasMore"
                      class="text-danger"
                >
                    Search result contains more than 1000 rows, please narrow your search.
                </span>
                <div class="d-flex align-items-center">
                    <atbl-select v-if="!!transactions.length"
                                 :options="paginationModel.data"
                                 :value="paginationModel.default"
                                 :first-option-label="null"
                                 option-label="text"
                                 class="mr-2 mb-0"
                                 @input="onPaginationLimitChangeEvent"
                    />
                    <button class="btn btn-sm btn-outline-primary m-0"
                              @click="showFilter = !showFilter"
                    >
                        <i class="fa fa-filter" aria-hidden="false"></i>
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list v-sortable" @sort="onUpdateSortEvent">
                    <div class="row sticky-element d-none d-xl-flex">
                        <div v-sort:id="sort" class="col text-muted">Reference</div>
                        <div v-sort:created_by_string="sort" class="col text-muted text-center">Created By</div>
                        <div v-sort:updated_at="sort" class="col text-muted text-center">Date</div>
                        <div v-sort:gateway="sort" class="col text-muted" v-if="$can('Transactions - View Gateway')">Gateway</div>
                        <div v-sort:payment_type="sort" class="col text-muted">Type</div>
                        <div v-sort:transaction_type="sort" class="col text-muted">Status</div>
                        <div v-sort:last_4_digits="sort" class="col-1 text-muted">Last 4</div>
                        <div v-sort:amount="sort" class="col text-muted">Amount</div>
                        <div class="col text-muted text-center" v-if="$can('Player - View Details')">Action</div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="transaction in transactionsData.data"
                               :key="transaction.id"
                               class="row"
                        >
                            <div class="col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0">
                                <a class="d-block"
                                   :href="`/transaction/${transaction.id}`"
                                   v-if="$can('Transactions - View Transaction Details')"
                                >
                                    <i class="fa fa-link"></i> {{ transaction.id }}
                                </a>
                                <span v-else>
                                    Id: {{ transaction.id }}
                                </span>
                                <a v-if="!!transaction.order"
                                   :href="`/orders/${transaction.order.id}`"
                                >
                                    <small>
                                        <b>Order</b> #{{ transaction.order.id }}
                                    </small>
                                </a>
                                <small class="d-block text-muted">
                                    <b>Player:</b> #{{ transaction.player_id }} - {{ transaction.player.name + ' ' + transaction.player.surname }}
                                </small>
                                <div class="small-mb-badge mt-2">Reference id</div>
                                <small class="text-muted">
                                    <b>Ref:</b> {{ transaction.reference_id }}
                                </small>
                                <br />
                                <small class="text-muted"><b>Ref tid:</b> {{ transaction.reference_transaction_id }}</small>
                                <br />
                                <a v-if="!!transaction.game_winner" :href="getGameWinnerTicket(transaction).url">
                                    <small>
                                        <b>{{ getGameWinnerTicket(transaction).label }}</b> #{{ getGameWinnerTicket(transaction).id }}
                                    </small>
                                </a>
                            </div>
                            <div class="col-12 col-xl align-self-center text-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Created by</span>
                                <created-by-badge :created="transaction" />
                            </div>
                            <div class="col-12 col-xl text-center align-self-center mb-2 mb-lg-0">
                                <span class="small-mb-badge">Updated at</span>
                                <small>{{ utcToLocal(transaction.updated_at) }}</small>
                            </div>
                            <div class="col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0" v-if="$can('Transactions - View Gateway')">
                                <span class="small-mb-badge">Gateway</span>
                                <small v-if="!!transaction.gateway && transaction.gateway !== 'atbl'"><strong>{{ transaction.gateway }}</strong></small>
                                <span v-else>/</span>
                            </div>
                            <div class="col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0">
                                <span class="small-mb-badge">Status</span>
                                <transaction-badge :transaction="transaction" />
                            </div>
                            <div class="col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0">
                                <div class="transaction-type font-weight-bold"
                                      :class="{
                                        'text-success': transaction.transaction_type === 1,
                                        'text-danger': transaction.transaction_type !== 1
                                      }"
                                >
                                    {{ getReadableTransactionType(transaction.transaction_type) }}
                                    <span v-if="transaction.transaction_type === 0 && transaction.response_message">
                                        <a v-tooltip="{
                                                html: true,
                                                content: transaction.response_message,
                                                placement: 'top'
                                           }"
                                           href="#"
                                        >
                                            <i class="fa fa-info-circle text-danger ml-1"></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-xl-1 align-self-center text-center text-xl-left mb-2 mb-lg-0">
                                <span class="small-mb-badge">Last 4</span>
                                <span>{{ transaction.last_4_digits }}</span>
                            </div>
                            <div class="col-12 col-xl align-self-center text-center text-xl-left mb-2 mb-lg-0">
                                <span class="small-mb-badge">Amount</span>
                                <span class="d-block">{{ transaction.amount | formatCurrency }}</span>
                                <small class="d-block text-muted">
                                    {{ logABSum('before', transaction) }}
                                </small>
                                <small class="d-block text-muted">
                                    {{ logABSum('after', transaction) }}
                                </small>
                            </div>
                            <div class="col-12 col-xl align-self-center text-center text-xl-left"
                                   v-if="$can('Player - View Details')"
                            >
                                <span class="small-mb-badge">Action</span>
                                <a class="d-block"
                                   :href="`/players/${transaction.player.id}/view`"
                                >
                                    <small><i class="fa fa-user"></i> View Player</small>
                                </a>
                            </div>
                        </div>
                    </template>

                    <not-found v-if="!isLoading"
                               msg="No transactions found"
                               :items="transactions"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>

            <div v-if="!isLoading && transactionsData.last_page > 1" class="card-footer overflow-x-auto">
                <div class="d-block d-lg-flex align-items-center justify-content-between text-center">
                    <nav class="wrap">
                        <atbl-pagination :pagination="transactionsData"
                                         :scroll="false"
                                         class="justify-content-center justify-content-lg-start"
                                         @paginate="onPageChangeEvent"
                        />
                    </nav>
                    <a href="#"
                       @click.prevent="$scrollToTop()"
                    >
                        <i class="fa fa-caret-up mr-2"></i> Back to top
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TransactionsSearch from './TransactionsSearch/TransactionsSearch';
import PaginateOptions from '../../../data/PaginateOptions';
import { clone } from '../../../utils/Json/Json';
import PaginationModel from '../../../modules/pagination/PaginationModel';
import SaveObjectToUrl from '../../../utils/Url/SaveObjectToUrl';
import { filterNonEmpty } from '../../../utils';
import DynamicSort from '../../../utils/Array/DynamicSort';
import Paginate from '../../../modules/pagination/Paginate';
import Wallet from '../../../mixins/Wallet';

const sortObject = {
    sortBy: 'id',
    direction: 'DESC',
    sortOnInit: false,
};

export default {
    mixins: [
        Wallet,
    ],
    components: {
        TransactionsSearch,
    },
    data() {
        return {
            hasMore: false,
            showFilter: true,
            transactions: [],
            transactionsData: clone(PaginationModel),
            model: {},
            isLoading: false,
            paginationModel: clone(PaginateOptions),
            sort: clone(sortObject),
            showTable: false,
            page: 1,
        }
    },
    computed: {
        hasModelValues() {
            return !!filterNonEmpty(this.model).length;
        },
    },
    methods: {
        async getTransactions() {
            if (!this.hasModelValues) {
                window.showMessage('Search form cannot be empty.', false);

                return;
            }

            this.showTable = true;
            this.isLoading = true;

            this.isLoading = true;
            const response = await window.axios.get('/transaction', {
                params: this.model
            })
            const { transactions, hasMore } = response.data;

            this.hasMore = hasMore;
            this.transactions = transactions.map(item => {
                return {
                    ...item,
                    amount: Number(item.amount),
                    created_by_string: item.created_by.created,
                    gateway: !!item.gateway ? item.gateway : 'atbl'
                };
            });

            this.setTransactionsData(this.page);

            this.isLoading = false;
        },
        onSearchEvent(data) {
            if (data.hasOwnProperty('otherParams')) {
                const { page, sort, paginate } = data.otherParams;

                this.page = page;
                this.sort = sort;
                this.paginationModel.default = paginate;
            }

            this.model = data.hasOwnProperty('searchModel')
                ? data.searchModel
                : data;

            this.getTransactions();
        },
        onPageChangeEvent(page = 1) {
            this.setTransactionsData(page);
        },
        onUpdateSortEvent(e) {
            this.sort = {
                sortBy: e.detail.key,
                direction: e.detail.direction,
                sortOnInit: sortObject.sortOnInit,
            };

            this.setTransactionsData();
        },
        setTransactionsData(page = 1) {
            SaveObjectToUrl({
                ...this.model,
                sort: this.sort,
                paginate: this.paginationModel.default,
                page: page,
            });

            this.transactionsData = this.sortAndPaginateData(page);
        },
        sortAndPaginateData(page = 1) {
            const { sortBy, direction } = this.sort;

            const sortTransactions = DynamicSort(
                clone(this.transactions),
                sortBy,
                direction
            );

            return Paginate(
                sortTransactions,
                page,
                this.paginationModel.default
            );
        },
        onPaginationLimitChangeEvent(value) {
            this.paginationModel.default = value;

            this.setTransactionsData();
        },
        getReadableTransactionType(transactionType) {
            switch (transactionType) {
                case 0:
                    return 'Failed';
                case 1:
                    return 'Success';
                case 2:
                    return 'Rejected';
            }
        },
        getGameWinnerTicket(transaction) {
            switch (transaction.game_winner.gameable_type) {
                case "App\\Models\\Syndicates\\SyndicateShare":
                    return {
                        url: `/syndicate-shares/${transaction.game_winner.gameable_id}`,
                        label: 'Syndicate share',
                        id: transaction.game_winner.gameable_id,
                    };

                case "App\\RaffleTicket":
                    return {
                        url: `/raffle-tickets/${transaction.game_winner.gameable_id}`,
                        label: 'Raffle',
                        id: transaction.game_winner.gameable_id,
                    };

                case "App\\TicketLine":
                    return {
                        url: `/ticket/${transaction.game_winner.gameable.draw.ticket_id}`,
                        label: 'Ticket',
                        id: transaction.game_winner.gameable.draw.ticket_id,
                    };
            }

            return {
                url: '#',
                label: 'Ticket #',
                id: 1,
            };
        },
        clearSearchModel() {
            this.model = {}

            const cleanUrl = window.location.href.split('?')[0];
            if (!!cleanUrl) {
                window.history.pushState({}, '', cleanUrl);
            }
        }
    },
}
</script>
