var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn btn-sm mr-2 filter-link",
      class: {
        "btn-primary": !_vm.filter.isActive,
        "btn-success": !!_vm.filter.isActive
      },
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.$emit("click", _vm.filter)
        }
      }
    },
    [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm._v("\n        " + _vm._s(_vm.filter.title) + "\n        "),
        !!_vm.filter.count
          ? _c(
              "span",
              { staticClass: "badge badge-light position-static ml-2" },
              [
                _vm.filter.count
                  ? _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.filter.count) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }