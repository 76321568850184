<template>
    <div class="container-fluid">
        <kyc-submission-search class="mb-3"
                               @search="onSearchEvent"
                               @clear="clearSearchModel" 
        />
        <div v-if="!!showTable" class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i class="fa fa-archive"></i> KYC Submissions
                    <span v-if="players.length">
                        ({{ players.length }})
                    </span>
                    
                </div>
                <span v-if="!!hasMore"
                      class="text-danger"
                >
                    Search result contains more than {{ limitResults }} rows, please narrow your search.
                </span>
                <div class="d-flex align-items-center">
                    <atbl-select v-if="!!players.length"
                                 :options="paginationModel.data"
                                 :value="paginationModel.default"
                                 :first-option-label="null"
                                 option-label="text"
                                 class="mr-2 mb-0"
                                 @input="onPaginationLimitChangeEvent"
                    />
                </div>
            </div>

            <div class="card-body">
                <div v-if="!isLoading"
                     class="general-list v-sortable"
                     @sort="onUpdateSortEvent"
                >
                    <div class="row d-none d-md-flex">
                        <div v-sort:full_name="sort" class="col text-muted">Player</div>
                        <div v-sort:agent_name="sort" class="col text-muted">Agent</div>
                        <div v-sort:last_activity="sort" class="col text-muted">Last Activity at</div>
                        <div v-sort:kyc_status="sort" class="col text-muted">Status</div>
                        <div class="col text-muted">Action</div>
                    </div>

                    <template v-for="player in playersData.data">
                        <div :key="player.id" class="row">
                            <div class="col">
                                <a class="d-block" :href="`/players/${player.id}/view`">
                                    <i class="fa fa-link"></i> {{ player.name }} {{ player.surname }}
                                </a>
                            </div>
                            <div class="col">
                                {{ player.user ? player.user.name : '' }}
                            </div>
                            <div class="col">
                                {{ lastActivityAt(player) }}
                            </div>
                            <div class="col">
                                <player-kyc-status-badge :kyc-status="player.kyc_status" />
                            </div>
                            <div class="col">
                                <a :href="`/players/${player.id}/kyc/list`" class="d-block">
                                    <i class="fa fa-flag"></i> Review submission
                                </a>
                            </div>
                        </div>
                    </template>

                     <not-found v-if="!isLoading"
                               msg="No players found."
                               :items="players"
                    />

                </div>
                <loader :show="isLoading"
                        type="small"
                />
            </div>

            <div v-if="!isLoading && playersData.last_page > 1"
                 class="card-footer overflow-x-auto"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <nav class="wrap">
                        <atbl-pagination :pagination="playersData"
                                         :scroll="false"
                                         @paginate="onPageChangeEvent"
                        />
                    </nav>
                    <a href="#"
                       @click.prevent="$scrollToTop()"
                    >
                        <i class="fa fa-caret-up mr-2"></i> Back to top
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UtcToLocal from '../../filters/UtcToLocal';
import PlayerKycStatusBadge from '../Players/Components/Layout/KYCStatus/player-kyc-status-badge.vue';
import KycSubmissionSearch from './KycSubmissionsSearch/KycSubmissionSearch.vue'
import { clone } from '../../utils/Json/Json';
import { filterNonEmpty } from '../../utils'
import SaveObjectToUrl from '../../utils/Url/SaveObjectToUrl';
import Paginate from '../../modules/pagination/Paginate';
import PaginateOptions from '../../data/PaginateOptions.js';
import PaginationModel from '../../modules/pagination/PaginationModel';
import DynamicSort from '../../utils/Array/DynamicSort';

const sortObject = {
    sortBy: 'id',
    direction: 'DESC',
    sortOnInit: false,
};

export default {
    name: 'kyc-submissions',

    components: {
        PlayerKycStatusBadge,
        KycSubmissionSearch
    },

    data() {
        return {
            hasMore: false,
            limitResults: 1000,
            isLoading: false,
            showTable: false,
            players: [],
            playersData: clone(PaginationModel),
            model: {},
            paginationModel: clone(PaginateOptions),
            sort: clone(sortObject),
            page: 1,
        };
    },

    computed: {
        hasModelValues() {
            return !!filterNonEmpty(this.model).length;
        },
    },

    methods: {
        lastActivityAt(player) {
            return !!player.latest_kyc_history
                ? UtcToLocal(player.latest_kyc_history.created_at)
                : ''
        },
        onSearchEvent(data) {
            if (data.hasOwnProperty('otherParams')) {
                const { page, sort, paginate } = data.otherParams;

                this.page = page;
                this.sort = sort;
                this.paginationModel.default = paginate;
            }

            this.model = data.hasOwnProperty('searchModel')
                ? data.searchModel
                : data;

            this.fetchSubmissions();
        },
        async fetchSubmissions() {

            this.showTable = true;
            this.isLoading = true;

            const response = await window.axios.get('/kyc-submissions', {
                params: this.model
            });

            const { players, hasMore, limitResults } = response.data;

            this.hasMore = hasMore;
            this.limitResults = limitResults

            this.players = players.map(player => {
                return {
                    ...player,
                    agent_name: player.user?.name || '',
                    full_name: `${player.name} ${player.surname}`,
                    last_activity: this.lastActivityAt(player)
                }
            });

            this.setPlayersData(this.page);

            this.isLoading = false;
        },
        onPageChangeEvent(page = 1) {
            this.setPlayersData(page);
        },

        onUpdateSortEvent(e) {
            this.sort = {
                sortBy: e.detail.key,
                direction: e.detail.direction,
                sortOnInit: sortObject.sortOnInit,
            };

            this.setPlayersData();
        },
        setPlayersData(page = 1) {
            SaveObjectToUrl({
                ...this.model,
                sort: this.sort,
                paginate: this.paginationModel.default,
                page: page
            });

            this.playersData = this.sortAndPaginateDate(page)
        },
        sortAndPaginateDate(page = 1) {
            const { sortBy, direction } = this.sort;

            const sortPlayers = DynamicSort(
                clone(this.players),
                sortBy,
                direction
            );

            return Paginate(
                sortPlayers,
                page,
                this.paginationModel.default
            );
        },
        onPaginationLimitChangeEvent(value) {
            this.paginationModel.default = value;

            this.setPlayersData();
        },
        clearSearchModel() {
            this.model = {}
            this.sort = clone(sortObject)

            // clean url
            const cleanUrl = window.location.href.split('?')[0]
            if (!!cleanUrl) {
                window.history.pushState({}, '', cleanUrl)
            }
        }
    },
}
</script>