<template>
	<div class="blocked">
		<span class="blocked-sign">
			<i class="fa fa-hand-paper"></i>
		</span>
		<h2 class="message">
			{{ message }}
		</h2>
	</div>
</template>

<script>
    export default {
        name: "BlockedSign",
	    props: {
        	message: {
        		type: String,
		        required: true
	        }
	    }
    }
</script>

<style scoped lang="scss">
	.blocked {
		margin: 50px;
		text-align: center;
		
		&-sign {
			background-color: #ea9384;
			border-radius: 0 0 140px 140px;
			display: inline-block;
			height: 180px;
			position: relative;
			width: 160px;
			
			
			&:before, &:after {
				-moz-transform-origin: 0 100%;
				-moz-transform: rotate(-60deg);
				-ms-transform-origin: 0 100%;
				-ms-transform: rotate(-60deg);
				-o-transform-origin: 0 100%;
				-o-transform: rotate(-60deg);
				-webkit-transform-origin: 0 100%;
				-webkit-transform: rotate(-60deg);
				background: #ea9384;
				border-radius: 100px 100px 0 0;
				content:"";
				height: 85px;
				left: 80px;
				margin-top: 107px;
				position: absolute;
				top: 0;
				transform-origin: 0 100%;
				transform: rotate(-60deg);
				width: 70px;
			}
			&:after {
				-moz-transform-origin: 100% 100%;
				-moz-transform: rotate(60deg);
				-ms-transform-origin: 100% 100%;
				-ms-transform: rotate(60deg);
				-o-transform-origin: 100% 100%;
				-o-transform: rotate(60deg);
				-webkit-transform-origin: 100% 100%;
				-webkit-transform: rotate(60deg);
				background-color: #ea9384;
				left: 10px;
				transform-origin :100% 100%;
				transform: rotate(60deg);
			}
			
			i {
				color: white;
				font-size: 80px;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 999;
			}
		}
		
		.message {
			color: #ea9384;
			margin-top: 50px;
		}
	}
</style>