var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "atbl-form-group",
        { attrs: { label: "Jackpot" } },
        [
          _c("atbl-input", {
            attrs: {
              value: _vm.value.jackpot,
              disabled: !!_vm.loader,
              errors: _vm.errors,
              name: "jackpot"
            },
            on: { update: _vm.updateNextDraw }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "atbl-form-group",
        { attrs: { label: "Jackpot Raw" } },
        [
          _c("atbl-number", {
            attrs: {
              value: _vm.value.jackpot_raw,
              disabled: !!_vm.loader,
              errors: _vm.errors,
              max: null,
              name: "jackpot_raw"
            },
            on: { update: _vm.updateNextDraw }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }