var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-sub",
    {
      staticClass: "left leadsAddRemoveModal medium",
      attrs: { value: _vm.show, loader: _vm.isLoading, title: "Add Lead" },
      on: { input: _vm.toggleAddLeadsSidebar }
    },
    [
      _c("div", { staticClass: "campaign-add-leads-section-header" }, [
        !!_vm.leadsList.length
          ? _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.allSelected,
                    expression: "allSelected"
                  },
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: "Select all",
                      placement: "bottom"
                    },
                    expression:
                      "{\n                       content: 'Select all',\n                       placement: 'bottom'\n                   }"
                  }
                ],
                staticClass: "checkbox-leads",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.allSelected)
                    ? _vm._i(_vm.allSelected, null) > -1
                    : _vm.allSelected
                },
                on: {
                  click: _vm.onSelectedAllLeadsEvent,
                  change: function($event) {
                    var $$a = _vm.allSelected,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.allSelected = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.allSelected = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.allSelected = $$c
                    }
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          !!_vm.isLeadCampaign()
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm m-0 mr-2",
                  class: {
                    "btn-outline-primary": _vm.showFilter,
                    "btn-primary": !_vm.showFilter
                  },
                  on: {
                    click: function($event) {
                      _vm.showFilter = !_vm.showFilter
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-filter",
                    attrs: { "aria-hidden": "false" }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn btn-sm",
            class: {
              "btn-primary": !_vm.leadsListModel.inCampaign,
              "btn-danger": _vm.leadsListModel.inCampaign
            },
            attrs: { disabled: !_vm.leadsIds.length || _vm.isLoading },
            domProps: {
              textContent: _vm._s(
                _vm.leadsListModel.inCampaign
                  ? "Remove Selected"
                  : "Add Selected"
              )
            },
            on: {
              click: function($event) {
                return _vm.onAddSelectedLeads(_vm.leadsIds, _vm.campaignId)
              }
            }
          }),
          _vm._v(" "),
          !!_vm.isLeadCampaign()
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger ml-2",
                  attrs: { disabled: !_vm.leadsList.length || !!_vm.isLoading },
                  on: { click: _vm.toggleConfirmAddAllLeadsModal }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.leadsListModel.inCampaign ? "Remove All" : "Add All"
                      ) +
                      "\n            "
                  )
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      !!_vm.isLeadCampaign()
        ? _c("leads-filter-form-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilter,
                expression: "showFilter"
              }
            ],
            attrs: { model: _vm.leadsListModel }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !!_vm.leadsList.length
        ? _c(
            "div",
            { staticClass: "campaign-add-leads-section-list" },
            _vm._l(_vm.leadsList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "campaign-add-leads-section-list-item"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "campaign-add-leads-section-list-item-header"
                    },
                    [
                      _c("label", { attrs: { for: "checkBox" + item.id } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.leadsIds,
                              expression: "leadsIds"
                            }
                          ],
                          staticClass: "checkbox-leads",
                          attrs: { id: "checkBox" + item.id, type: "checkbox" },
                          domProps: {
                            value: item.id,
                            checked: Array.isArray(_vm.leadsIds)
                              ? _vm._i(_vm.leadsIds, item.id) > -1
                              : _vm.leadsIds
                          },
                          on: {
                            click: function($event) {
                              _vm.allSelected = false
                            },
                            change: function($event) {
                              var $$a = _vm.leadsIds,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = item.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.leadsIds = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.leadsIds = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.leadsIds = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Copy to clipboard",
                                expression: "`Copy to clipboard`"
                              }
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.copyToClipboard(item.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                        #" +
                                _vm._s(item.id) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("b", [
                          _vm._v(
                            "/ " +
                              _vm._s(item.name) +
                              " " +
                              _vm._s(item.surname)
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      !_vm.leadsListModel.inCampaign
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Add customer",
                                  expression: "`Add customer`"
                                }
                              ],
                              staticClass: "button-add",
                              on: {
                                click: function($event) {
                                  return _vm.addLeadToCampaign(
                                    item,
                                    _vm.campaignId,
                                    _vm.campaignType
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-plus" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.leadsListModel.inCampaign
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Remove customer",
                                  expression: "`Remove customer`"
                                }
                              ],
                              staticClass: "button-remove",
                              on: {
                                click: function($event) {
                                  return _vm.removeLeadOutOfCampaign(
                                    item,
                                    _vm.campaignId,
                                    _vm.campaignType
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-times" })]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "campaign-add-leads-section-list-item-body"
                    },
                    [
                      _c("div", [
                        _c("span", { staticClass: "small-mb-badge show m-0" }, [
                          _vm._v("Last Disposition")
                        ]),
                        _vm._v(" "),
                        item.status_name && item.status_date
                          ? _c("div", [
                              item.status_name
                                ? _c(
                                    "span",
                                    { staticClass: "badge badge-success" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item.status_name) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("small", [_vm._v(_vm._s(item.status_date))])
                            ])
                          : _c("span", [_vm._v("/")])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "small-mb-badge show m-0" }, [
                          _vm._v("Country")
                        ]),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.emptyField(item.country)) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "small-mb-badge show m-0" }, [
                          _vm._v("Live Spent")
                        ]),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              !!item.live_spent
                                ? item.live_spent === 0
                                  ? 0
                                  : _vm.formatCurrency(item.live_spent)
                                : "/"
                            ) +
                            "\n                "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "campaign-add-leads-section-list-item-footer"
                    },
                    [
                      !!item.campaigns.length
                        ? _c("div", { staticClass: "campaigns" }, [
                            _c("span", [_vm._v("Campaigns")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              _vm._l(item.campaigns, function(campaign, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "badge badge-primary ml-1"
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(campaign.title) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.batch
                        ? _c("div", { staticClass: "batch" }, [
                            _c("span", [_vm._v("Batch")]),
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.batch) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader", {
        staticClass: "mt-2",
        attrs: { show: _vm.isLoading, type: "small" }
      }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("not-found", {
            staticClass: "w-100 m-0",
            attrs: { items: _vm.leadsList, msg: "No leads found." }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.leadsListResponse.last_page > 1
        ? _c(
            "nav",
            { staticClass: "wrap mt-3 mx-0" },
            [
              _c("atbl-pagination", {
                staticClass: "m-0",
                attrs: { small: true, pagination: _vm.leadsListResponse },
                on: {
                  paginate: function($event) {
                    return _vm.fetchLeadsOutOfCampaign(_vm.campaignId, $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }