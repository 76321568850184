<template>
    <atbl-form-group v-if="$can('Users - Get List')"
                     :horizontal="horizontal"
                     label="Agents"
                     label-for="Agents"
                     class="font-weight-bold"
    >
        <tree-select v-model="selectedAgent"
                     :multiple="true"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="users"
                     :disabled="!!disabled || !!isLoading"
                     :placeholder="`${!!isLoading ? 'Loading...' : 'Select options'}`"

        />
    </atbl-form-group>
</template>

<script>
export default {
    name: 'multi-agents-picker',
    emits: [
        'input',
        'update',
    ],
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        sortDirection: {
            type: String,
            default: 'ASC'
        },
        name: {
            type: String,
            default: 'agents'
        }
    },
    data() {
        return {
            users: [],
            isLoading: false,
        };
    },
    computed: {
        selectedAgent: {
            get() {
                return !this.users.length ? [] : this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update', this.name, value);
            }
        },
    },
    async created() {
        await this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            if (!this.$can('Users - Get List')) {
                return;
            }

            this.isLoading = true;

            const response = await window.axios.get('/list-users', {
                params: { sort: this.sortDirection }
            });

            this.users = response.data.map(user => {
                const { id, name: label } = user;

                return {
                    id,
                    label
                };
            });

            this.isLoading = false;
        },
    },
}
</script>