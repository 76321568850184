var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "basket-item" }, [
    _c("div", { staticClass: "basket-image" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: { src: _vm.$imageService.gameImage(_vm.item.item.image) }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "basket-data" }, [
      _c("div", { staticClass: "basket-title" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.item.item.name))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "basket-items-data" },
        [
          _c("span", { staticClass: "mr-2" }, [
            _c("b", [_vm._v("Lines")]),
            _vm._v(": " + _vm._s(_vm.item.item.lines.length) + "\n            ")
          ]),
          _vm._v(" "),
          _c("price", { attrs: { item: _vm.item } })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.canDeleteItem
      ? _c(
          "div",
          { staticClass: "basket-operations" },
          [
            _c("confirmable-remove-button", {
              attrs: { value: _vm.item.id },
              on: { confirmed: _vm.onConfirmedEvent }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }