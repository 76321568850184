<script>
    export default {
        playerTransactionsRoute: function(id){
            return `/player/${id}/transactions`;
        },
        getPlayerTransactions: function(id, params = {}){
            return window.axios.get(this.playerTransactionsRoute(id), {
                params
            });
        }
    }
</script>