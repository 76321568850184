var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card lottery-payment m-0 mb-4" }, [
    _c(
      "header",
      {
        staticClass:
          "card-header card-header-atbl d-flex align-items-center justify-content-between"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: { type: "button" },
              on: { click: _vm.closeCheckout }
            },
            [_c("i", { staticClass: "fa fa-times" })]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _vm.$canAny(_vm.paymentOperations)
        ? _c("div", { staticClass: "row p-2" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("list-payments", {
                  attrs: {
                    "player-id": _vm.playerId,
                    "charge-amount": parseFloat(
                      _vm.getCartItemsStatistics.price
                    ),
                    params: { order_id: _vm.orderId }
                  },
                  on: {
                    "payment-successful": _vm.paySuccess,
                    "payment-failed": _vm.payFailed,
                    "payment-error": _vm.payError
                  }
                })
              ],
              1
            )
          ])
        : _c("div", { staticClass: "row" }, [_vm._m(1)])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-credit-card" }),
      _vm._v(" How do you want to pay ?\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h5", [_vm._v("No payment access allowed.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }