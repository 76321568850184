<template>
    <span class="remove-list-item-button">
        <a v-if="!isChecked"
           href="#"
           class="remove-list-item-button-trash"
           @click.stop.prevent="onShowOperation"
        >
            <i class="fa fa-trash-alt"></i>
        </a>
        <a v-if="!!isChecked"
           href="#"
           class="remove-list-item-button-cancel"
           @click.stop.prevent="onShowOperation"
        >
            <i class="fa fa-times"></i>
        </a>
        <a v-if="!!isChecked"
           href="#"
           class="remove-list-item-button-confirm"
           @click.stop.prevent="onConfirmEvent"
        >
            <i class="fa fa-check"></i>
        </a>
    </span>
</template>

<script>
export default {
    name: 'remove-list-item-button',
    props: {
        value: {
            required: false
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'confirmed'
    ],
    data() {
        return {
            isChecked: this.show
        };
    },
    methods: {
        onShowOperation() {
            this.isChecked = !this.isChecked;
        },
        onConfirmEvent() {
            this.$emit('confirmed', this.value);
        }
    },
}
</script>