var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atbl-select", {
    staticClass: "font-weight-bold",
    attrs: {
      id: "Show",
      label: "Show",
      value: _vm.paginate,
      plain: true,
      options: _vm.paginateOptions,
      "option-label": "text"
    },
    on: { input: _vm.change }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }