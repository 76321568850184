<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-8">
                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Bundle details
                        </div>
                    </div>

                    <div v-if="bundle"
                         class="card-body"
                    >
                        <div class="general-list">
                            <div class="row">
                                <div class="col text-muted">Title</div>
                                <div class="col">{{ bundle.title }}</div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Slug</div>
                                <div class="col">{{ bundle.slug }}</div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Reference</div>
                                <div class="col">{{ bundle.uuid }}</div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Description</div>
                                <div class="col">{{ bundle.description }}</div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Logo</div>
                                <div class="col">
                                    <img :src="logo" style="width: 60px;" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Ribbon text</div>
                                <div class="col">{{ bundle.ribbon_text }}</div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Price</div>
                                <div class="col">{{ bundlePrice.toFixed(2) | formatCurrency }} - <small><strong>Price taken from first week</strong></small></div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Jackpot</div>
                                <div class="col"><span v-html="getBundleCurrencySymbol()" /> {{ bundle.jackpot }}</div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Is CRM enabled</div>
                                <div class="col">{{ bundle.is_crm_enabled ? 'Yes' : 'No'}}</div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">Is API enabled</div>
                                <div class="col">{{ bundle.is_api_enabled ? 'Yes' : 'No'}}</div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <button type="reset" class="btn btn-sm btn-primary mr-2" @click="showBundleEditModal = true">
                                            <i class="fa fa-pencil-alt"></i> Edit bundle
                                        </button>

                                        <button v-if="bundle.is_active" type="reset" class="btn btn-sm btn-danger mr-2" @click="showDisableBundleModal = true">
                                            <i class="fa fa-ban"></i> Disable bundle
                                        </button>

                                        <button v-if="! bundle.is_active" type="reset" class="btn btn-sm btn-primary mr-2" @click="enableBundle">
                                            <i class="fa fa-check"></i> Enable bundle
                                        </button>

                                        <button v-if="bundle.is_active && ! bundle.is_api_enabled" type="reset" class="btn btn-sm btn-primary" @click="enableBundleForApi">
                                            <i class="fa fa-check"></i> Enable bundle for API
                                        </button>

                                        <button v-if="bundle.is_active && bundle.is_api_enabled" type="reset" class="btn btn-sm btn-danger" @click="disableBundleForApi">
                                            <i class="fa fa-ban"></i> Disable bundle for API
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Games
                        </div>
                    </div>

                    <div v-if="bundle"
                         class="card-body"
                    >
                        <div class="general-list">
                            <div class="row">
                                <div class="col text-muted">Game</div>
                                <div class="col">Price</div>
                                <div class="col">Number of lines</div>
                            </div>

                            <div v-for="bundleGame in bundle.bundle_games" :key="`bundle_game_${bundleGame.id}`" class="row">
                                <div class="col text-muted">
                                    <img :src="$imageService.gameImage(bundleGame.game.image)" style="width: 60px; height: auto;">
                                    {{ bundleGame.game.name }}
                                </div>
                                <div class="col">
                                    {{ bundleGame.game.current_price.line_price }}
                                </div>
                                <div class="col">
                                    {{ bundleGame.number_of_lines }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col text-muted">
                                    <strong>Total</strong>
                                </div>
                                <div class="col">
                                    <strong>{{ totalBundleGamePrice.toFixed(2) }}</strong>
                                </div>
                                <div class="col">
                                    <strong>{{ linesPerGame }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-exchange-alt"></i> Weeks
                        </div>
                    </div>

                    <div v-if="bundle"
                         class="card-body"
                    >
                        <div class="general-list">
                            <div class="row">
                                <div class="col text-muted">Week</div>
                                <div class="col">Discount %</div>
                                <div class="col">Price</div>
                            </div>

                            <div v-for="bundleWeek in bundle.bundle_weeks" :key="`bundle_week_${bundleWeek.id}`" class="row">
                                <div class="col">{{ bundleWeek.week }}</div>
                                <div class="col"><strong>{{ bundleWeek.discount || '/' }}</strong></div>
                                <div class="col"><strong>{{ getBundleWeekPrice(bundleWeek).toFixed(2) }}</strong></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-list">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <i class="fa fa-image"></i> Background image
                        </div>
                    </div>

                    <div v-if="bundle && bundle.background_image_attachment_id"
                         class="card-body"
                    >
                        <div class="general-list">
                            <div class="row">
                                <div class="col">
                                    <img :src="backgroundImage" style="width: 100%; height: auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <bundle-disable-modal v-if="showDisableBundleModal"
                              v-on:cancel="showDisableBundleModal = false"
                              v-on:confirm="disableBundle" />

        <bundle-edit-modal v-if="showBundleEditModal && $can('Bundles - Create/Update')"
                           :bundle="bundle"
                           v-on:cancel="showBundleEditModal = false"
                           v-on:confirm="updateBundle" />
    </div>
</template>

<script>
import BundleService from '../../services/BundleService';
import BundleDisableModal from './Modals/DisableModal';
import BundleEditModal from './Modals/CreateEditModal';
import BundleMixin from '../../mixins/Bundles/Bundle';
import CurrenciesService from '../../services/CurrenciesService';

export default {
    components: { BundleEditModal, BundleDisableModal },

    mixins: [BundleMixin],

    props: ['id'],

    data() {
        return {
            bundle: null,
            showDisableBundleModal: null,
            showBundleEditModal: null,
        };
    },

    computed: {
        logo() {
            return BundleService.makeLogoPreviewUrl(this.id);
        },

        backgroundImage() {
            return BundleService.makeBackgroundImagePreviewUrl(this.id);
        },

        linesPerGame() {
            return this.bundle.bundle_games[0].number_of_lines;
        },

        totalBundleGamePrice() {
            return this.bundle.bundle_games.reduce((a, g) => a + this.getBundleGamePrice(g.game.name), 0) * this.linesPerGame;
        },

        bundlePrice() {
            const firstWeek = this.bundle.bundle_weeks[0];

            return firstWeek.price ? firstWeek.price : (this.totalBundleGamePrice - (this.totalBundleGamePrice * (firstWeek.discount / 100)));
        },
    },

    beforeMount() {
        this.fetchBundle();
    },

    methods: {
        fetchBundle() {
            BundleService.show(this.id).then(response => {
                this.bundle = response.data;
            })
        },

        updateBundle(params) {
            BundleService.update(this.id, params).then(() => {
                this.showBundleEditModal = false;
                this.fetchBundle();
                window.showMessage('Bundle successfully updated');
            }).catch(err => {
                const errResponse = err.response;

                if (errResponse.status === 422) {
                    const errors = [errResponse.data.message];

                    for (const [key, error] of Object.entries(errResponse.data.errors)) {
                        errors.push(error[0]);
                    }

                    window.flash(errors.join('<br />'), 'alert-danger');
                }
            });
        },

        getBundleCurrencySymbol() {
            return CurrenciesService.getCurrencySymbol(this.bundle.jackpot_currency);
        },

        getBundleWeekPrice(bundleWeek) {
            const totalBundleGamePricePerChosenWeek = this.totalBundleGamePrice * bundleWeek.week;

            return bundleWeek.price ? bundleWeek.price : (totalBundleGamePricePerChosenWeek - (totalBundleGamePricePerChosenWeek * (bundleWeek.discount / 100)));
        },

        enableBundle() {
            BundleService.enable(this.id).then(() => {
                this.bundle.is_active = true;
                window.showMessage('Bundle successfully enabled');
            }).catch(err => {
                window.showMessage('There was a problem enabling this bundle', false);
            });
        },

        disableBundle() {
            BundleService.disable(this.id).then(() => {
                this.showDisableBundleModal = false;
                this.bundle.is_active = false;
                window.showMessage('Bundle successfully disabled');
            }).catch(err => {
                this.showDisableBundleModal = false;
                window.showMessage('There was a problem disabling this bundle', false);
            });
        },

        enableBundleForApi() {
            BundleService.enableForApi(this.id).then(() => {
                this.bundle.is_api_enabled = true;
                window.showMessage('Bundle successfully enabled for api');
            }).catch(err => {
                window.showMessage('There was a problem enabling this bundle', false);
            });
        },

        disableBundleForApi() {
            BundleService.disableForApi(this.id).then(() => {
                this.bundle.is_api_enabled = false;
                window.showMessage('Bundle successfully disabled for api');
            }).catch(err => {
                window.showMessage('There was a problem disabling this bundle', false);
            });
        },
    },
}
</script>