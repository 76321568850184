<template>
    <div class="checkout-summary-item">
        <div class="checkout-summary-image">
            <img :src="bundleLogoUrl" class="avatar" />
        </div>
        <div class="checkout-summary-data">
            <div class="checkout-summary-title">
                <span class="title">{{ item.item.name }}</span>
            </div>

            <div class="checkout-summary-data">
                <span class="mr-2">
                    <b>Weeks</b>: {{ item.item.weeks }}
                </span>
                <price :item="item" />
            </div>
        </div>
        <div v-if="canDeleteItem"
             class="checkout-summary-operations"
        >
            <confirmable-remove-button :value="item.id" @confirmed="onConfirmedEvent" />
        </div>
    </div>
</template>

<script>
import BundleService from '../../../../../../../services/BundleService';
import Price from './Components/Price';

export default {
    name: 'cart-bundle-row',
    components: {
        Price
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        canDeleteItem: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        bundleLogoUrl() {
            return BundleService.makeLogoPreviewUrl(this.item.item.bundle);
        },
    },
    methods: {
        onConfirmedEvent (value) {
            this.$emit('removeCartItem', value);
        }
    },
}
</script>