<template>
    <div class="container-fluid">
        <div class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i class="fa fa-exchange-alt"></i> Bonuses
                </div>

                <button class="btn btn-sm btn-primary" @click="navigateToCreateBonus" v-if="$can('Bonuses - Create/Update')">
                    Create
                </button>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col text-muted">Reference</div>
                        <div class="col text-muted">Title</div>
                        <div class="col text-muted">Trigger</div>
                        <div class="col text-muted">Number of actions</div>
                        <div class="col text-muted">Expires at</div>
                        <div class="col text-muted">Duration <i class="fa fa-info-circle" v-tooltip="{content: 'Duration after bonus is assigned to player'}"></i></div>
                        <div class="col text-muted text-center">Action</div>
                    </div>

                    <template v-for="bonus in bonuses">
                        <div :key="`bonus_${bonus.id}`" class="row">
                            <div class="col">
                                {{ bonus.uuid }}
                            </div>
                            <div class="col">
                                {{ bonus.title }}
                            </div>
                            <div class="col">
                                {{ bonus.trigger }}
                            </div>
                            <div class="col">
                                {{ bonus.actions.length }}
                            </div>
                            <div class="col">
                                {{ bonus.expires_at || '-' }}
                            </div>
                            <div class="col">
                                {{ bonus.duration || '-' }}
                            </div>
                            <div class="col text-center">
                                <a class="d-block" :href="`/bonuses/${bonus.id}/edit`" v-if="$can('Bonuses - Create/Update')">
                                    <small><i class="fa fa-user"></i> Edit bonus</small>
                                </a>
                            </div>
                        </div>
                    </template>

                    <not-found v-if="! isLoading && ! bonuses.length" msg="No bonuses found" :items="bonuses" />

                    <loader :show="isLoading" type="small" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BonusService from '../../services/BonusService';

export default {
    data() {
        return {
            isLoading: false,
            isCreateModalVisible: false,
            bonuses: [],
        };
    },

    mounted() {
        this.fetchBonuses();
    },

    methods: {
        navigateToCreateBonus() {
            window.location.href = '/bonuses/create';
        },

        fetchBonuses() {
            this.isLoading = true;
            this.bonuses = [];

            BonusService
                .index()
                .then(response => {
                    this.bonuses = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
}
</script>