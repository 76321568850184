var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: { label: "Gateway", "label-for": "gateway", horizontal: false }
    },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedGateway,
              expression: "selectedGateway"
            }
          ],
          staticClass: "form-control",
          attrs: { id: "gateway", disabled: _vm.isLoading },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedGateway = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          !!_vm.defaultItem
            ? _c("option", { domProps: { value: null } }, [
                _vm._v("\n            Select option\n        ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.availableGateways, function(gateway, index) {
            return _c(
              "option",
              { key: index, domProps: { value: gateway.value } },
              [_vm._v("\n            " + _vm._s(gateway.label) + "\n        ")]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }