<template>
    <div class="card card-list">
        <div class="card-header d-flex align-items-center justify-content-between">
            <span><i class='fa fa-align-justify'></i> Ticket Lines</span>
        </div>

        <div class="card-body ticket-lines-card-body">
            <div class="row d-none d-lg-flex leads-section-list-row">
                <div class="col-sm-2 text-muted">#Line</div>
                <div class="col-sm-2 text-muted">Reference</div>
                <div class="col-sm-2 text-muted">Draw Date</div>
                <div class="col-sm-4 text-muted">Numbers</div>
                <div class="col-sm-2 text-muted">Status</div>
            </div>

            <template v-if="!ticketLoader && !!ticketLines.length">
                <div v-for="(line, index) in ticketLines"
                       :key="index"
                       class="row leads-section-list-row"
                >
                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">#Line</span>
                        #L-{{ line.id }}
                    </div>
                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">Reference</span>
                        <a :href="`/ticket/${ticket.id}`" class="d-block">Ticket #{{ ticket.id }}</a>
                        <a :href="`/orders/${ticket.order_id}`">Order #{{ ticket.order_id }}</a>
                    </div>
                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">Draw Date</span>
                        {{ draw.game_draw && isset(draw.game_draw, 'date') ? draw.game_draw.date : null }}
                    </div>
                    <div class="col-12 col-lg-4 text-center align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">Numbers</span>
                        <div class="numbers-group">
                            <template v-for="(numbers, key) in line.winningNumbers">
                                <span v-tooltip="{
                                          content: key
                                      }"
                                      class="mr-2"
                                >
                                    <span v-for="numberObj in numbers"
                                          :key="key+numberObj.number"
                                          :class="numberObj.styles"
                                    >
                                        {{ numberObj.number }}
                                    </span>
                                </span>
                            </template>
                        </div>
                    </div>
                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left">
                        <span class="small-mb-badge">Status</span>
                        {{ getStatus(line.prize) }}
                    </div>
                </div>
            </template>

            <div class="p-3"
                 v-if="ticketLoader"
            >
                <loader type="small"
                        :show="ticketLoader"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TypesConst from '../../../../store/TypesConst';
import NamespaceConst from '../../../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';

const {
    mapGetters,
    mapMutations
} = createNamespacedHelpers(NamespaceConst.tickets);

import TicketMixin from '../../../../mixins/TicketMixin';
import { cloneDeep } from 'lodash';
import { parse } from '../../../../utils/Json/Json';
import flatObject from '../../../../utils/Object/FlatObject';

export default {
    name: 'lotto-ticket-lines',
    mixins: [
        TicketMixin
    ],
    data() {
        return {
            ticketLines: [],
        };
    },
    computed: {
        ...mapGetters({
            ticket: TypesConst.tickets.getters.GET_TICKETS_TICKET_DATA,
            game: TypesConst.tickets.getters.GET_TICKETS_TICKET_GAME,
            configurationNumbers: TypesConst.tickets.getters.GET_TICKETS_TICKET_CONFIGURATION_NUMBERS,
            ticketLoader: TypesConst.tickets.getters.GET_TICKETS_TICKET_DATA_LOADER,
            player: TypesConst.tickets.getters.GET_TICKETS_TICKET_PLAYER,
            currency: TypesConst.tickets.getters.GET_TICKETS_TICKET_CURRENCY,
            lines: TypesConst.tickets.getters.GET_TICKETS_TICKET_LINES,
            draw: TypesConst.tickets.getters.GET_TICKETS_TICKET_DRAW,
            configuration: TypesConst.tickets.getters.GET_TICKETS_TICKET_CONFIGURATION,
        })
    },
    methods: {
        ...mapMutations({
            setConfiguration: TypesConst.tickets.mutations.SET_TICKETS_TICKET_CONFIGURATION,
            setTicket: TypesConst.tickets.mutations.SET_TICKETS_TICKET_DATA,
        }),
        getStatus(prize) {
            if (prize === null) {
                return 'No status';
            }

            if (prize === 0) {
                return 'Not a winning line';
            }

            return `Won ${this.$options.filters.formatCurrency(prize)}`;
        },
        isMatch(num, type) {
            if (this.draw.game_draw &&
                this.draw.game_draw.numbers !== null &&
                this.draw.game_draw.numbers.hasOwnProperty(type)
            ) {
                let getNumbersForMatching = this.configuration[type];
                if (Array.isArray(getNumbersForMatching)) {
                    return getNumbersForMatching.includes(num.toString()) ||
                        getNumbersForMatching.includes(num);
                }
                return getNumbersForMatching === num ||
                    getNumbersForMatching.toString() === num.toString();
            } else {
                return false;
            }
        },
        getProcessedLines() {
            const ticket = cloneDeep(this.ticket);

            const {
                draw: { lines, numbers },
                game: { key },
            } = ticket;

            const ticketNumbers = flatObject(parse(numbers));
            const colors = this.getGameNumTypes(key);

            return lines.map(item => {
                const {
                    line: { numbers }
                } = item;

                const allNumbers = {
                    ...numbers,
                    ...ticketNumbers
                };

                let winningNumbers = {};

                for (const type in allNumbers) {
                    let results = {};

                    if (!colors[type]) {
                        continue;
                    }

                    const color = type === 'main' ? null : (colors[type] || null);

                    if (Array.isArray(allNumbers[type])) {
                        results[type] = allNumbers[type]
                            .map(number => {
                                const hasBonusNumber = this.configuration && this.configuration.hasOwnProperty('bonus') && this.configuration.bonus.includes(number);

                                return {
                                    number,
                                    styles: [
                                        !!hasBonusNumber ? 'bonus' : type,
                                        this.isMatch(number, type) ? 'match' : null,
                                        !!hasBonusNumber ? 'f-red' : null,
                                        this.typeResolver(type),
                                        color,
                                    ].filter(item => !!item),
                                };
                            });
                    } else {
                        results[type] = [
                            {
                                number: allNumbers[type],
                                styles: [
                                    'mr-1',
                                    type,
                                    this.isMatch(allNumbers[type], type) ? 'match' : null,
                                    this.typeResolver(type),
                                    color,
                                ].filter(item => !!item),
                            }
                        ];
                    }

                    winningNumbers = {
                        ...winningNumbers,
                        ...results
                    };
                }

                return {
                    ...item,
                    winningNumbers,
                };
            });
        },
    },
    watch: {
        draw: {
            handler(value) {
                if (!value) {
                    return;
                }

                this.setConfiguration(this.getNumbersConfiguration(value, this.configurationNumbers));

                this.ticketLines = this.getProcessedLines();
            },
            deep: true,
            immediate: true
        }
    }
}
</script>