<template>
    <atbl-form-group :label="label"
                     label-for="Permissions"
                     class="font-weight-bold"
                     :horizontal="isHorizontal"
    >
        <tree-select v-model="selectedPermissions"
                     :multiple="true"
                     :searchable="true"
                     :clear-on-select="true"
                     :options="getPermissionsList"
                     :normalizer="(node) => ({id: node.name, label: node.name})"
                     @input="updateModel"
                     :disabled="disabled || isLoading"
        />
    </atbl-form-group>
</template>

<script>
    import PermissionsService from '../../../services/PermissionsService';

    export default {
        name: "multi-permissions-picker",
        props: {
            label: {
                type: String,
                default: "Permissions"
            },
            value: {
                type: Array,
                default: () => ([])
            },
            input: {},
            disabled: {
                type: Boolean,
                default: false
            },
            horizontal: {
                type: Boolean,
                default: false
            },
            rolePermissions: {
                type: Array,
                default: () => ([])
            }
        },
        data(){
            return {
                permissions: [],
                selectedPermissions: this.value,
                isLoading: false
            };
        },
        computed: {
            getPermissionsList () {
                const rolePermissions = !!this.rolePermissions.length
                    ? this.rolePermissions.map(item => item.id)
                    : [];

                return !rolePermissions.length
                    ? this.permissions
                    : this.permissions.filter(item => !rolePermissions.includes(item.id));
            },
            isHorizontal () {
                return this.horizontal ? this.horizontal : false;
            }
        },
        methods: {
            getPermissions() {
                this.isLoading = true;
                PermissionsService.getPermissionsList()
                    .then(response => {
                        this.permissions = response.data.map(({ text, value }) => {
                            return {
                                id: value,
                                name: text
                            };
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            updateModel(){
                this.$emit("input", this.selectedPermissions);
            },
        },
        mounted: function(){
            if(this.$can("Permissions - Assign Direct Permission")){
                this.getPermissions();
            }
        }
    }
</script>