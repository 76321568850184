/**
 * Resolve game type
 * @param type
 * @param key
 */
export default function (type, key) {
    const gameTypeTemp = type.split('\\');
    const gameType = gameTypeTemp[gameTypeTemp.length - 1];

   if (gameType === 'Game') {
        return key.indexOf('loteria-nacional') !== -1 ? 'Raffle' : 'Lottery';
   }

   return gameType;
}