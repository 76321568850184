<template>
    <player-layout :id="id"
                   title="Credit Cards"
                   icon="fa fa-credit-card"
    >
        <div class="py-2 text-right">
            <button class="btn btn-sm btn-primary" @click="addNewCreditCard">
                Add new credit card
            </button>
        </div>
        <div class="general-list">
            <div class="row">
                <div class="col text-muted">Holder</div>
                <div class="col text-muted">Terminal</div>
                <div class="col text-muted">Payment Brand</div>
                <div class="col text-muted">Last 4 Digits</div>
                <div class="col text-muted">Expire</div>
                <div class="col text-muted">Succ. attempts</div>
                <div class="col text-muted">Fail attempts</div>
                <div class="col text-muted" v-if="$can('Player - Remove Credit Cards')">Actions</div>
            </div>

            <template v-if="!isLoading">
                <div v-for="(card, index) in cards"
                       :key="index"
                       class="row"
                >
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                        <span class="small-mb-badge">Holder</span>
                        <span class="d-block">{{ card.holder }}</span>
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                        <span class="small-mb-badge">Terminal</span>
                        <span class="d-block">{{ card.payment_terminal }}</span>
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                        <span class="small-mb-badge">Payment Brand</span>
                        <span class="d-block">{{ card.payment_brand }}</span>
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                        <span class="small-mb-badge">Last 4 Digits</span>
                        <span class="d-block">{{ card.last_4_digits }}</span>
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                        <span class="small-mb-badge">Expire</span>
                        <span class="d-block"
                              :class="{
                    	    'text-danger': card.is_expired
                          }"
                        >
	                    {{ card.expiry_month }}/{{ card.expiry_year }}
                    </span>
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                        <span class="small-mb-badge">Succ. attempts</span>
                        <span class="d-block">{{ card.success }}</span>
                        <small v-if="card.last_success_attempt_at">{{ card.last_success_attempt_at | dateFormat('YYYY-MM-DD HH:mm', false) }}</small>
                    </div>
                    <div class="col-6 col-sm-6 col-md mb-2 mb-lg-0">
                        <span class="small-mb-badge">Fail attempts</span>
                        <span class="d-block">{{ card.failed }}</span>
                        <small v-if="card.last_failed_attempt_at">{{ card.last_failed_attempt_at | dateFormat('YYYY-MM-DD HH:mm', false) }}</small>
                    </div>
                    <div class="col-12 col-sm-12 col-md mb-2 mb-lg-0" v-if="$can('Player - Remove Credit Cards')">
                        <span class="small-mb-badge">Actions</span>
                        <button class="btn btn-sm btn-danger m-0 btn-block"
                                  @click="removeCard(card.id)"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </template>

            <not-found :items="cards"
                       v-if="!isLoading"
                       msg="No credit cards found."
            />

            <loader :show="isLoading"
                    class="mt-4"
                    type="small"
            />
        </div>

        <RemoveCreditCardModal v-if="$can('Player - Remove Credit Cards')"
                               :show="showRemoveCardModal"
                               :card-id="currentCard"
                               @close="removeCard"
                               @removed="loadCreditCards"
        />

        <payment-gateway-oppwa v-if="showAddNewCreditCardForm" title="Add new credit card" :allow-saving-credit-card="false" :api-payment-response="newCreditCardResponse" v-on:cancel="newCreditCardResponse = null; showAddNewCreditCardForm = false" />
    </player-layout>
</template>

<script>
    import PlayerCreditCardsService from "../../../../services/PlayerCreditCardsService";
    import RemoveCreditCardModal from "./Components/RemoveCreditCardModal";
    import PaymentGatewayOppwa from '../../../../components/PaymentGateways/Oppwa';

    export default {
        name: "player-credit-cards",
        components: {
            PaymentGatewayOppwa,
            RemoveCreditCardModal
        },
        props: {
            id: {
                type: Number
            }
        },
        data () {
            return {
                cards: [],
                isLoading: false,
                currentCard: null,
                showRemoveCardModal: false,
                
                showAddNewCreditCardForm: false,
                newCreditCardResponse: null,
            };
        },
        methods: {
            getPlayerCreditCards (id) {
                this.isLoading = true;

                PlayerCreditCardsService.getPlayerCreditCards(id, { playerId: id, list_all_cards: true })
                    .then(response => {
                        this.cards = response.data.data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            removeCard (card, show = true) {
                this.currentCard = card;
                this.showRemoveCardModal = show;
            },
            loadCreditCards () {
                if(this.$can("Player - Get Credit Cards List")){
                    this.getPlayerCreditCards(this.id);
                }
            },
            addNewCreditCard() {
                PlayerCreditCardsService.createNewCreditCard(this.id).then((response) => {
                    this.showAddNewCreditCardForm = true;
                    this.newCreditCardResponse = response.data;
                });
            },
        },
        created () {
            this.loadCreditCards();
        }
    }
</script>