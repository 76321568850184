const parentClass = 'v-sortable'
const directiveClass = 'v-sort';
const toggleElementType = 'span';
const toggleElementClass = 'v-sort-btn';

const getChild = (directiveEl) => {
    return directiveEl.querySelectorAll('.v-sort-btn')[0];
};

const getParent = (directiveEl) => {
    return directiveEl.closest('.v-sortable');
};

const setState = (directiveEl, direction) => {
    directiveEl.dataset.sort = direction;
    directiveEl.classList.add('active');
};

const toggleState = (directiveEl) => {
    const currentState = directiveEl.dataset.sort;
    directiveEl.classList.add('active');

    if (!currentState || currentState === 'desc') {
        setState(directiveEl, 'asc');
        return;
    }

    setState(directiveEl, 'desc');
};

const resetState = (directiveEl) => {
    directiveEl.classList.remove('active');
};

const doSort = function (parent, sortKey) {
    parent.querySelectorAll('.v-sort').forEach(vSortEl => {
        if (vSortEl !== this) {
            resetState(vSortEl);
        }
    });

    toggleState(this);
    dispatchEvent(this, sortKey, this.dataset.sort);
};

const dispatchEvent = (directiveEl, sortKey, direction) => {
    const event = new CustomEvent('sort', { detail: { key: sortKey, direction } });
    getParent(directiveEl).dispatchEvent(event);
};

const createSortButton = () => {
    const el = document.createElement('span');
    el.classList.add('v-sort-btn');

    return el;
};

export default {
    bind(el, binding, vnode) {
        const sortKey = binding.arg;

        let isDefault = !!binding.modifiers.default;
        let defaultDirection = binding.modifiers.desc ? 'desc' : 'asc';
        let sortOnInit = binding.value.sortOnInit;

        if (binding.value && binding.value.sortBy && binding.value.direction) {
            isDefault = binding.value.sortBy === sortKey;
            defaultDirection = binding.value.direction ? binding.value.direction.toLowerCase() : 'asc';
        }
        
        el.classList.add('v-sort');

        vnode.context.$nextTick(() => {
            const parent = getParent(el);

            if (!parent) {
                throw new Error('v-sort directive requires a .v-sortable class');
            }

            el.append(createSortButton());

            if (isDefault) {
                setState(el, defaultDirection);

                if (sortOnInit) {
                    dispatchEvent(el, sortKey, defaultDirection);
                }
            }

            el.addEventListener('click', doSort.bind(el, parent, sortKey));
        });
    },

    unbind(el) {
        getChild(el).removeEventListener('click', doSort);
    },
};