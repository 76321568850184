export default {
	player_id: null,
	user_id: null,
	amount: null,
	currency: null,
	alternative_amount: null,
	alternative_currency: null,
	status: null,
	decline_reason: null,
	created_at: null,
	id: null,
	name: null,
	player_name: null,
	player_surname: null,
	holder: null,
	payment_brand: null,
	last_4_digits: null,
	created_by: {
		created: null,
		color: null,
	},
	currency_symbol: null,
}