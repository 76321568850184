var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.playerLoader && _vm.canViewPlayer
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-4 col-lg-3" },
              [_c("player-nav", { attrs: { id: _vm.id } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "content",
                staticClass: "col-12 col-sm-8 col-lg-9 pl-lg-0"
              },
              [
                _vm.isPlayerBlocked
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v("\n\t\t\t\t\tThis player is blocked.\n\t\t\t\t")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("player-info", { attrs: { id: _vm.id } }),
                _vm._v(" "),
                _vm._t("default")
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.playerLoader && !_vm.canViewPlayer
        ? _c("blocked-sign", { attrs: { message: "This player is blocked." } })
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.playerLoader, type: "small" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }