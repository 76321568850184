<template>
    <atbl-select id="Show"
              label="Show"
              class="font-weight-bold"
              :value="paginate"
              :plain="true"
              :options="paginateOptions"
              option-label="text"
              @input="change"
    />
</template>

<script>
    import PaginateOptions from "../../../data/PaginateOptions";
	import AtblSelect from '../Inputs/ATBLSelect.vue';

    export default {
		components: {
			AtblSelect,
        },
        props: {
            value: {
                required: false
            },
            horizontal: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                paginateOptions: PaginateOptions.data,
                paginate: PaginateOptions.default
            };
        },
        methods: {
            change(value) {
                this.$emit("input", Number(value));
            }
        }
    }
</script>