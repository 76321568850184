var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-type mb-3" }, [
    _c(
      "div",
      {
        staticClass:
          "user-type-header d-flex align-items-center justify-content-between bg-primary text-color p-3 cursor-pointer",
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.isShown = !_vm.isShown
          }
        }
      },
      [
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(_vm.type))
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-white",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa",
              class: {
                "fa-caret-down": !_vm.isShown,
                "fa-caret-up": _vm.isShown
              }
            })
          ]
        )
      ]
    ),
    _vm._v(" "),
    !!_vm.isShown
      ? _c(
          "div",
          { staticClass: "user-type-body bg-lighter p-3" },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }