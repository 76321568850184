import MarketingService from '../../../../../../services/MarketingService';
import LeadStatusService from '../../../../../../services/Marketing/LeadStatusService';
import DispositionService from '../../../../../../services/DispositionService';
import PlayerDispositionService from '../../../../../../services/PlayerDispositionService';

export default {
    data() {
        return {
            // fetchCustomerStatuses
            customerDispositionStatusesLoader: false,
            customerDispositionStatusesList: [],

            // fetchCustomerDispositions
            customerDispositionList: [],
            customerDispositionLoader: false,
        };
    },
    methods: {
        async fetchCustomerStatuses(customerId) {
            if (!customerId) {
                return;
            }

            this.customerDispositionStatusesLoader = true;

            const isLeadCampaign = this.isLeadCampaign();

            try {
                const response = !!isLeadCampaign
                    ? await MarketingService.getLeadStatusList(customerId)
                    : await PlayerDispositionService.getPlayerLatestDispositions(customerId);

                this.customerDispositionStatusesList = await this.resolveCustomerStatuses(response.data, isLeadCampaign);

                this.customerDispositionStatusesLoader = false;
            } catch (error) {
                this.customerDispositionStatusesLoader = false;

                window.flashError(error);
            }
        },
        async fetchCustomerDispositions() {
            this.customerDispositionLoader = true;

            const isLeadCampaign = this.isLeadCampaign();

            try {
                const response = !!isLeadCampaign
                    ? await LeadStatusService.getLeadsStatuses()
                    : await DispositionService.getDispositionsDropDown();

                this.customerDispositionList = response.data;

                this.customerDispositionLoader = false;
            } catch (error) {
                this.customerDispositionLoader = false;

                window.flashError(error);
            }
        },
        async resolveCustomerStatuses(data, isLeadCampaign) {
            return await data.map(item => {
                const status_name = !!isLeadCampaign
                    ? item.status ? item.status.name : '/'
                    : item.disposition ? item.disposition.name : '/';

                return {
                    status_name,
                    created_at: item.created_at,
                    user_name: !!item.user ? item.user.name : '/',
                    campaign_name: !!item.campaign ? item.campaign.title : '/',
                    campaign_id: !!item.campaign ? item.campaign.id : null,
                };
            });
        },
    },
}