<script>
    export default {
        // all players
        allPlayers: function(params = {}){
            return window.axios.get("/all-players", { params });
        },
        // single player
        singlePlayerApi: function(id){
            return `/player/${id}`;
        },
        fetchPlayer: function(id){
            return window.axios.get(this.singlePlayerApi(id));
        },
        fetchPlayerWallet: function(id){
            return window.axios.get(`${this.singlePlayerApi(id)}/wallet`);
        },
        createPlayer: function(model = {}){
           return window.axios.post('/player', model);
        },
        bulkAssignAgent: function(model = {}){
            return window.axios.post("/player/agent", model);
        },
        activatePlayer: function(id){
            return window.axios.post(`/player/${id}/activate`);
        },
        blockUnblockPlayer(id, params = {}){
            return window.axios.post(`/player/${id}/block-unblock`, params);
        },
    }
</script>