<template>
    <div class="container-fluid">
        <phone-numbers-filter-form-component v-if="$can('Phone Numbers - View')"
                                             v-show="!!phoneNumbersFilterFormVisibility"
                                             :model="phoneNumbersFilterModel"
        />

        <div class="card card-list">
            <div class="card-header d-flex align-items-center justify-content-between">
                <span><i class='fa fa-phone'></i> Phone Numbers</span>
                <div class="d-flex align-items-center justify-content-between">
                    <button :class="{
                                'btn-primary': !phoneNumbersFilterFormVisibility,
                                'btn-outline-primary': !!phoneNumbersFilterFormVisibility
                            }"
                            class="btn btn-sm mr-2"
                            @click.prevent="phoneNumbersFilterFormVisibility = !phoneNumbersFilterFormVisibility"
                    >
                        <i class="fa fa-filter"></i>
                    </button>
                    <button v-if="$can('Phone Numbers - Pull')"
                            class="btn btn-sm btn-success mr-2"
                            @click.prevent="onPullPhoneNumbers"
                    >
                        <i class="fa fa-download"></i> Pull
                    </button>
                    <a v-tooltip="{
                           content: 'Pull all phone numbers and store to database.',
                           placement: 'top-end'
                       }"
                       href="#"
                       @click.prevent
                    >
                        <i class="fa fa-info-circle"></i>
                    </a>
                </div>
            </div>

            <div class="card-body">
                <div class="general-list">
                    <div class="row">
                        <div class="col-12 col-lg-1">Id</div>
                        <div class="col-12 col-lg">Phone number</div>
                        <div class="col-12 col-lg-1">Status</div>
                        <div class="col-12 col-lg">Allowed for</div>
                        <div class="col-12 col-lg-1">Is Active</div>
                        <div class="col-12 col-lg-1">Provider</div>
                        <div v-if="$canAny(operationColumnPermissions)"
                             class="col-12 col-lg-2"
                        >
                            Operations
                        </div>
                    </div>

                    <template v-if="!isLoading">
                        <div v-for="(item, index) in phoneNumbers.data"
                             :key="index"
                             class="row"
                        >
                            <div class="col-12 col-lg-1 mb-1 mb-lg-0">
                                <span class="small-mb-badge">Id</span>
                                {{ item.id }}
                            </div>
                            <div class="col-12 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Phone number</span>
                                {{ item.phone_number }}
                            </div>
                            <div class="col-12 col-lg-1 mb-1 mb-lg-0">
                                <span class="small-mb-badge">Status</span>
                                <span :class="['badge', `badge-${resolveStatusColor(item.status)}`]">
                                {{ item.status }}
                            </span>
                            </div>
                            <div class="col-12 col-lg mb-1 mb-lg-0">
                                <span class="small-mb-badge">Allowed for</span>
                                <template v-if="!!item.allowed_for && !!item.allowed_for.length">
                                <span v-for="(type, index) in item.allowed_for"
                                      :key="index"
                                      :class="['badge', 'mr-2', `badge-${resolveTypeColor(type)}`]"
                                >
                                    {{ type }}
                                </span>
                                </template>
                                <span v-else>/</span>
                            </div>
                            <div class="col-12 col-lg-1 mb-1 mb-lg-0">
                                <span class="small-mb-badge">Is Active</span>
                                <i class="fa"
                                   :class="{
                                        'fa-check text-success': !!item.is_active,
                                        'fa-times text-danger': !item.is_active,
                                   }"
                                ></i>
                            </div>
                            <div class="col-12 col-lg-1 mb-1 mb-lg-0">
                                <span class="small-mb-badge">Provider</span>
                                <span :class="['badge', `badge-${resolveProviderColor(item.provider)}`]">
                                {{ item.provider }}
                            </span>
                            </div>
                            <div v-if="$canAny(operationColumnPermissions)"
                                 class="col-12 col-lg-2"
                            >
                                <span class="small-mb-badge">Operations</span>
                                <div class="d-flex align-items-center justify-content-start">
                                    <a v-if="$can('Phone Numbers - Update')"
                                       href="#"
                                       class="mr-2"
                                       @click.prevent="onSelectPhoneNumber(item)"
                                    >
                                        <i class="fa fa-pen"></i>
                                    </a>
                                    <remove-list-item-button v-if="$can('Phone Numbers - Delete')"
                                                             :value="item.id"
                                                             @confirmed="onDeletePhoneNumberEvent"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>

                    <items-not-found v-if="!isLoading"
                                     :items="phoneNumbers.data"
                                     msg="No phone numbers found"
                    />

                    <loader :show="isLoading"
                            type="small"
                    />
                </div>
            </div>
            <div v-if="!isLoading && phoneNumbers.last_page > 1"
                 class="card-footer"
            >
                <nav class="wrap py-3">
                    <atbl-pagination :pagination="phoneNumbers"
                                     @paginate="fetchPhoneNumbersList"
                    />
                </nav>
            </div>
        </div>

        <pull-phone-numbers-component :show="showPullWindow"
                                      :is-loading="isPullLoading"
                                      :phone-numbers="pulledPhoneNumbers"
        />

        <update-phone-number-component :show="showUpdateWindow"
                                       :is-loading="isUpdateLoading"
                                       :phone-number="selectedPhoneNumber"
        />
    </div>
</template>

<script>
import PullPhoneNumbers from './PullPhoneNumbers/PullPhoneNumbers';
import PullPhoneNumbersComponent from './PullPhoneNumbers/PullPhoneNumbersComponent';
import UpdatePhoneNumberComponent from './UpdatePhoneNumber/UpdatePhoneNumberComponent';
import UpdatePhoneNumber from './UpdatePhoneNumber/UpdatePhoneNumber';
import { clone } from '../../utils/Json/Json';
import PaginationModel from '../../modules/pagination/PaginationModel';
import AtblPagination from '../../components/AtblPagination.vue';
import Loader from '../../components/Widgets/Loader.vue';
import ItemsNotFound from '../../components/Lists/ItemsNotFound.vue';
import RemoveListItemButton from '../../components/Lists/RemoveListItemButton.vue';
import PhoneNumbersFilterForm from './PhoneNumberFilterForm/PhoneNumbersFilterForm';
import PhoneNumbersFilterFormComponent from './PhoneNumberFilterForm/PhoneNumbersFilterFormComponent.vue';
import { omit } from 'lodash/object';

export default {
    name: 'PhoneNumbers',
    mixins: [
        PullPhoneNumbers,
        UpdatePhoneNumber,
        PhoneNumbersFilterForm,
    ],
    components: {
        PhoneNumbersFilterFormComponent,
        UpdatePhoneNumberComponent,
        PullPhoneNumbersComponent,
        Loader,
        AtblPagination,
        ItemsNotFound,
        RemoveListItemButton,
    },
    provide() {
        return {
            fetchPhoneNumbersList: this.fetchPhoneNumbersList,
            resolveProviderColor: this.resolveProviderColor,
            resolveStatusColor: this.resolveStatusColor,
        };
    },
    data() {
        return {
            operationColumnPermissions: [
                'Phone Numbers - Update',
                'Phone Numbers - Delete'
            ],

            isLoading: false,
            phoneNumbers: clone(PaginationModel),
        };
    },
    async created() {
        await this.fetchPhoneNumbersList();
    },
    methods: {
        async fetchPhoneNumbersList(page = 1) {
            if (!this.$can('Phone Numbers - View')) {
                window.showMessage('No permissions to fetch phone numbers list.', false);

                return;
            }

            this.isLoading = true;

            try {
                const response = await window.axios.get('/phone-numbers/list', {
                    params: {
                        page,
                        ...this.phoneNumbersFilterModel,
                    },
                });

                this.phoneNumbers = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        async onDeletePhoneNumberEvent(id) {
            if (!this.$can('Phone Numbers - Delete')) {
                window.showMessage('No permissions to remove phone numbers.', false);

                return;
            }

            if (!id) {
                window.showMessage('No phone number id is provided.', false);

                return;
            }

            this.isLoading = true;

            try {
                const response = await window.axios.delete(`/phone-numbers/${id}`);
                const { status, message, data } = response.data;

                window.showMessage(message, status);

                this.phoneNumbers = data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        resolveStatusColor(status) {
            switch (status) {
                case 'in-use':
                    return 'success';
                default:
                    return 'primary';
            }
        },
        resolveTypeColor(type) {
            switch (type) {
                case 'leads':
                    return 'primary';
                case 'players':
                default:
                    return 'success';
            }
        },
        resolveProviderColor(provider) {
            switch (provider) {
                case 'twilio':
                    return 'danger';
                default:
                    return 'primary';
            }
        },
    },
}
</script>