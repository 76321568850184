var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.meta, function(item, key) {
        return [
          item.active
            ? _c(
                "atbl-form-group",
                {
                  key: key,
                  attrs: {
                    label: !!item.title ? item.title : _vm.getLabel(item, key),
                    error: _vm.getError(_vm.errors["meta"], key)
                  }
                },
                [
                  _c("atbl-number", {
                    attrs: {
                      value: item.value,
                      name: key,
                      min: item.min,
                      max: !item.max ? item.maxAllowed : item.max,
                      step: 1,
                      disabled: _vm.disabled
                    },
                    on: { update: _vm.changeMetaInput }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }