<template>
    <player-layout :id="id" icon="fa fa-ticket-alt" title="Syndicate shares">
        <template #first>
            <player-syndicate-shares-search-form v-on:search="search" />
        </template>

        <section class="general-list agents">
            <div class="row">
                <div class="col text-muted">Ticket</div>
                <div class="col text-muted text-center">Created By</div>
                <div class="col text-muted text-center">Lottery</div>
                <div class="col text-muted text-center">Syndicate</div>
                <div class="col text-muted text-center">Order</div>
                <div class="col text-muted text-center">Draw Date</div>
                <div class="col text-muted text-center">Lines</div>
                <div class="col text-muted text-center">Shares</div>
                <div class="col text-muted text-center">Winnings</div>
            </div>

            <template v-if="!isLoading">
                <div v-for="(syndicateShare, index) in syndicateShares"
                     :key="index"
                     class="row"
                >
                    <div class="col align-self-center">
                        <a :href="`/syndicate-shares/${syndicateShare.id}`">ST# {{ syndicateShare.id }}</a>
                        <button class="btn btn-sm btn-link p-0" v-tooltip="{content: 'Copy to clipboard'}" @click="copyToClipboard(syndicateShare.id)">
                            <i class="fa fa-copy" aria-hidden="false"></i>
                        </button>
                        <div>
                            <small class="text-muted">{{ utcToLocal(syndicateShare.created_at) }}</small>
                        </div>
                    </div>
                    <div class="col align-self-center text-center">
                        <created-by-badge :created="syndicateShare.created_by" />
                    </div>
                    <div class="col text-center align-self-center">
                        <img :src="$imageService.gameImage(syndicateShare.game_image)" style="width: 60px; height: auto;">
                    </div>
                    <div class="col text-center align-self-center">
                        <a :href="`/syndicates/items/${syndicateShare.syndicate_item_id}/edit`" v-if="$can('Syndicates - Create/Update')">
                            S#{{ syndicateShare.syndicate_item_id }} <br /> {{ syndicateShare.title }}
                        </a>
                        <span v-else>
                            S#{{ syndicateShare.syndicate_item_id }} <br /> {{ syndicateShare.title }}
                        </span>
                    </div>
                    <div class="col text-center align-self-center">
                        <a v-if="$can('Orders - View Order Details')"
                           :href="`/orders/${syndicateShare.order_id}?playerId=${syndicateShare.player_id}&from=syndicate-shares`"
                        >
                            O# {{ syndicateShare.order_id }}
                        </a>
                        <span v-else>
                            O# {{ syndicateShare.order_id }}
                        </span>
                    </div>
                    <div class="col text-center align-self-center">
                        {{ syndicateShare.cut_off_at }}
                    </div>
                    <div class="col text-center align-self-center">
                        {{ syndicateShare.lines_count }}
                    </div>
                    <div class="col text-center align-self-center">
                        {{ syndicateShare.shares }}
                    </div>
                    <div class="col text-center align-self-center">
                        <span v-if="syndicateShare.prize === null">
                            Not drawn yet
                        </span>
                        <span v-else>
                            {{ formatCurrency(syndicateShare.prize) }}
                        </span>
                    </div>
                </div>
            </template>

            <loader :show="isLoading"
                    type="small"
            />

            <items-not-found v-if="!isLoading"
                             :items="syndicateShares"
                             msg="No tickets found."
            />

            <nav v-if="!isLoading && syndicateSharesResponse.last_page > 1"
                 class="wrap py-3"
            >
                <atbl-pagination :pagination="syndicateSharesResponse"
                                 @paginate="getSyndicateShares"
                />
            </nav>
        </section>
    </player-layout>
</template>

<script>
import Transaction from '../../../Orders/Mixins/Transaction';
import SyndicateShareService from '../../../../services/SyndicateShareService';
import PlayerSyndicateSharesSearchForm from './Sections/PlayerSyndicateSharesSearchForm';
import formatCurrency from '../../../../filters/FormatCurrency';
import ItemsNotFound from '../../../../components/Lists/ItemsNotFound';
import AtblPagination from '../../../../components/AtblPagination';
import Loader from '../../../../components/Widgets/Loader';
import utcToLocal from '../../../../filters/UtcToLocal';

export default {
    name: 'player-syndicate-shares',
    components: {
        PlayerSyndicateSharesSearchForm,
        Loader,
        AtblPagination,
        ItemsNotFound,
    },
    mixins: [
        Transaction
    ],
    props: ['id'],
    data() {
        return {
            syndicateShares: [],
            syndicateSharesResponse: {},
            isLoading: false,
            searchData: null,
        };
    },
    async created() {
        await this.getSyndicateShares();
    },
    methods: {
        formatCurrency,
        utcToLocal,
        async getSyndicateShares(page = 1) {
            this.isLoading = true;

            const params = {
                page: page,
                ...this.searchData,
            };

            try {
                const response = await SyndicateShareService.playerSyndicateShares(this.id, params);

                this.syndicateShares = response.data.data;
                this.syndicateSharesResponse = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        search(data) {
            this.searchData = data;
            this.getSyndicateShares(1);
        },
    }
}
</script>
