import LeadService from '../../../../../../services/Marketing/LeadService';
import removeUndefinedProperties from '../../../../../../utils/Object/RemoveUndefinedProperties';

export default {
    provide() {
        return {
            onAddAllLeadsToCampaign: this.onAddAllLeadsToCampaign,
            toggleConfirmAddAllLeadsModal: this.toggleConfirmAddAllLeadsModal,
        };
    },
    data() {
        return {
            allLeadsCount: null,
            addAllLeadsInCampaignModalOpen: false,
            addAllLeadsInCampaignModalLoading: false,
        };
    },
    methods: {
        async getAllLeadsCount() {
            this.addAllLeadsInCampaignModalLoading = true;

            try {
                const response = await LeadService.getAllLeadsCount(removeUndefinedProperties(this.leadsOutOfCampaignSearchModel));

                this.allLeadsCount = response.data;

                this.addAllLeadsInCampaignModalLoading = false;
            } catch (error) {
                this.addAllLeadsInCampaignModalLoading = false;

                window.flashError(error);
            }
        },
        async toggleConfirmAddAllLeadsModal() {
            this.allLeadsCount = null;
            this.addAllLeadsInCampaignModalOpen = !this.addAllLeadsInCampaignModalOpen;

            if (!this.addAllLeadsInCampaignModalOpen) {
                return;
            }

            await this.getAllLeadsCount();
        },
    },
}