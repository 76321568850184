<template>
    <player-layout :id="id"
                   title="Agents History"
                   icon="fa fa-user-friends"
    >
        <template #first>
            <div class="card card-search">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <i class="fa fa-search" aria-hidden="false"></i> Search
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <agent-picker v-model="model.agent" />
                        </div>
                        <div class="col-12 col-md-4">
                            <atbl-form-group id="date"
                                             label="Date"
                                             label-for="Date"
                                             class="font-weight-bold"
                            >
                                <multi-date-picker v-model="createdAt"
                                                   format="YYYY-MM-DD"
                                />
                            </atbl-form-group>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <button type="reset"
                              class="btn btn-sm btn-danger"
                              @click="clear"
                    >
                        <i class="fa fa-ban"></i> Clear
                    </button>
                </div>
            </div>
        </template>

        <div class="general-list">
            <div class="row">
                <div class="col-6 col-md-3 text-muted">Agent</div>
                <div class="col-6 col-md-3 text-muted">From</div>
                <div class="col-6 col-md-3 text-muted">To</div>
                <div class="col-6 col-md-3 text-muted">Assigned By</div>
            </div>

            <div v-if="!isLoading" v-for="(item, index) in filteredAgents" :key="index" class="row">
                <div class="col-6 col-md-3 mb-2 mb-lg-0">
                    <span class="small-mb-badge">Agent</span>
                    {{ item.agent }}
                </div>
                <div class="col-6 col-md-3 mb-2 mb-lg-0">
                    <span class="small-mb-badge">From</span>
                    {{ utcToLocal(item.from) }}
                </div>
                <div class="col-6 col-md-3 mb-2 mb-lg-0">
                    <span class="small-mb-badge">To</span>
                    <span v-if="!item.to"
                          class="badge badge-success"
                    >
                        Present
                    </span>
                    <span v-else>
                        {{ utcToLocal(item.to) }}
                    </span>
                </div>
                <div class="col-6 col-md-3 mb-2 mb-lg-0">
                    <span class="small-mb-badge">Assigned By</span>
                    {{ item.assigned_by }}
                </div>
            </div>

            <not-found v-if="!isLoading"
                       :items="filteredAgents"
                       msg="No agents history found."
            />

            <loader :show="isLoading"
                    type="small"
            />
        </div>
    </player-layout>
</template>

<script>
    import moment from 'moment';

    export default {
    	name: "player-agents-history",
        props: [
            'id'
        ],
        data () {
    	    return {
    	        agents: [],
    	        filteredAgents: [],
                model: {
    	            agent: null,
    	            dateFrom: null,
                    dateTo: null,
                },
                isLoading: false,
            };
        },
        computed: {
            createdAt: {
                get(){
                    if (!this.model.dateFrom && !this.model.dateTo) {
                        return null;
                    }

                    return {
                        startDate: this.model.dateFrom,
                        endDate: this.model.dateTo
                    };
                },
                set(value){
                    if(this.model.dateFrom === value.startDate || this.model.dateTo === value.endDate) {
                        return;
                    }

                    this.model.dateFrom = !!value.startDate
                        ? moment(value.startDate).format(Date.defaultDate)
                        : null;
                    this.model.dateTo = !!value.endDate
                        ? moment(value.endDate).format(Date.defaultDate)
                        : null;
                }
            },
        },
	    methods: {
            async getPlayerAgentsHistory () {
                this.isLoading = true;

                try {
                    const response = await window.axios.get(`/player/${this.id}/agents-history`);

                    this.agents = this.transformPlayerAgentsData(response.data);
                    this.filteredAgents = this.agents;

                    this.isLoading = false;
                } catch (error) {
                    flashError(error);
                    this.isLoading = false;
                }
            },
            transformPlayerAgentsData (agents) {
                return agents.map((agent, index, all) => {
                    const nextAgent = all[index + 1];

                    return {
                        user_id: agent.user_id,
                        agent: agent.name,
                        from: agent.created_at,
                        to: nextAgent ? nextAgent.created_at : null,
                        assigned_by: agent.assigned_by
                    };
                });
            },
            clear () {
                this.model = {
                    agent: null,
                    dateFrom: null,
                    dateTo: null
                };
            },
	    },
        watch: {
    	    model: {
    	        handler (value) {
                    let agents = this.agents;

                    if (!!value.agent) {
                        agents = agents.filter(item => item.user_id === Number(value.agent));
                    }

                    if (!!value.dateFrom && !!value.dateTo) {
                        agents = agents.filter(item => this.validateDateBetweenTwoDates(value.dateFrom, value.dateTo, item.from));
                    }

                    this.filteredAgents = agents;

                },
                deep: true
            },
        },
	    created () {
    	    this.getPlayerAgentsHistory();
	    }
    }
</script>
