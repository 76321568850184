<template>
    <form @submit.prevent="search">
        <div class="card card-search">
            <div class="card-header d-flex align-items-center">
                <i class="fa fa-search mr-1"></i> Search
            </div>
            <div class="card-body pb-1">
                <div class="row">
                    <div class="col-4">
                        <atbl-input id="order"
                                 label="Order Num."
                                 class="font-weight-bold"
                                 type="text"
                                 placeholder="Order Number"
                                 v-model="searchModel.orderId"
                        />
                    </div>
                    <div class="col-4">
                        <atbl-form-group label="Status"
                                         label-for="status"
                                         class=""
                        >
                            <select id="status"
                                    class="form-control"
                                    v-model="searchModel.status"
                            >
                                <option :value="null">Select status</option>
                                <option v-for="(item, index) in $options.Orders.status"
                                        :key="index"
                                        :value="item"
                                >
                                    {{ item }}
                                </option>
                            </select>
                        </atbl-form-group>
                    </div>
                    <div class="col-4">
                        <atbl-input id="transaction"
                                 type="text"
                                 label="Transaction Num."
                                 placeholder="Transaction Number"
                                 v-model="searchModel.transaction"
                                 class="font-weight-bold"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <atbl-form-group label="For Date">
                            <multi-date-picker direction="right"
                                               v-model="selectedDates"
                                               format="YYYY-MM-DD"
                                               :show-ranges="true"
                                               :auto-apply="true"
                            />
                        </atbl-form-group>
                    </div>
                    <div v-if="$can('Users - Get List')"
                           class="col-12 col-lg-7"
                    >
                        <multi-agents-picker v-model="searchModel.agents" />
                    </div>
                    <div class="col-12 col-lg-2">
                        <atbl-form-group label="Website created"
                                         labelClass="mb-2"
                        >
                            <atbl-switch color="success"
                                         :on="true"
                                         :off="false"
                                         name="webCreated"
                                         v-model="searchModel.webCreated"
                            />
                        </atbl-form-group>
                    </div>
                </div>
                <div v-if="more" class="row">
                    <div class="col-3">
                        <atbl-input id="firstName"
                                 label="Name"
                                 type="text"
                                 class="font-weight-bold"
                                 placeholder="First Name"
                                 v-model="searchModel.name"
                        />
                    </div>
                    <div class="col-3">
                        <atbl-input id="surname"
                                 label="Surname"
                                 class="font-weight-bold"
                                 type="text"
                                 placeholder="Surname"
                                 v-model="searchModel.surname"
                        />
                    </div>
                    <div class="col-3">
                        <atbl-input id="ticket"
                                 label="Ticket Num."
                                 class="font-weight-bold"
                                 type="text"
                                 placeholder="Ticket Number"
                                 v-model="searchModel.ticket"
                        />
                    </div>
                    <div class="col-3">
                        <atbl-input id="ticket"
                                 label="Raffle Num."
                                 class="font-weight-bold"
                                 type="text"
                                 placeholder="Raffle Number"
                                 v-model="searchModel.raffle"
                        />
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-between align-items-center">
                <div>
                    <button type="submit"
                              class="btn btn-sm btn-primary"
                    >
                        <i class="fa fa-search"></i> Search
                    </button>
                    <button type="reset"
                              class="btn btn-sm btn-danger"
                              @click.prevent="clear">
                        <i class="fa fa-ban"></i> Clear
                    </button>
                </div>
                <div>
                    <button type="button"
                              class="btn btn-sm btn-outline-primary"
                              @click.prevent="more = !more"
                    >
                        {{ more ? 'Less' : 'More' }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import Orders from '../../../../constants/Orders';
import Date from '../../../../helpers/Date';
import moment from 'moment';
import { clone } from '../../../../utils/Json/Json';
import OrderSearchModel from './OrderSearchModel';
import QueryToObject from '../../../../utils/Url/QueryToObject';
import { pick } from 'lodash';

export default {
    name: 'orders-search',
    Orders,
    data() {
        return {
            searchModel: clone(OrderSearchModel),
            more: false,
        };
    },
    computed: {
        selectedDates: {
            get() {
                if (!this.searchModel.dateFrom && !this.searchModel.dateTo) {
                    return null;
                }

                return {
                    startDate: this.searchModel.dateFrom,
                    endDate: this.searchModel.dateTo
                };
            },
            set(value) {
                this.searchModel.dateFrom = !!value.startDate
                    ? moment(value.startDate).format(Date.defaultDate)
                    : null;
                this.searchModel.dateTo = !!value.endDate
                    ? moment(value.endDate).format(Date.defaultDate)
                    : null;
            }
        }
    },
    created() {
        this.initializeSearchForm();
    },
    methods: {
        initializeSearchForm() {
            const searchParams = QueryToObject();
            const searchModel = pick(searchParams, Object.keys(this.searchModel));
            const otherParams = pick(searchParams, ['sort', 'paginate', 'page']);

            if (this.isObjectEmpty(searchModel) && this.isObjectEmpty(otherParams)) {
                return;
            }

            searchModel.webCreated = Boolean(searchModel.webCreated);

            Object.assign(this.searchModel, searchModel);

            this.search({
                searchModel,
                otherParams
            });
        },
        search(data) {
            const model = data instanceof Event
                ? this.searchModel
                : data;

            this.$emit('search', model)
        },
        clear() {
            this.searchModel = clone(OrderSearchModel);
            this.$emit('clear');
        }
    },
}
</script>
