<script>
    export default {
        playerCreditCardsRoute: function(id){
            return `/player/${id}/cards`;
        },
        removePlayerCardRoute: function(id){
            return this.playerCreditCardsRoute(id) + "/delete";
        },
        getPlayerCreditCards: function(id, params = {}){
            return window.axios.get(
                this.playerCreditCardsRoute(id),
                {
	                params
                }
            );
        },
        deletePlayerCard: function(id, model){
            return window.axios.delete(
                this.removePlayerCardRoute(id),
                {
                    params: model
                }
            );
        },
        createNewCreditCard(playerId) {
            return window.axios.post(this.playerCreditCardsRoute(playerId));
        },
    }
</script>