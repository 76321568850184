import { clone } from '../../../../utils/Json/Json';

export default {
    computed: {
        isNotNumbers: function(){
            return this.type !== "main" && this.type !== "other";
        },
        getMainNumbers: function(){
            if(this.getConfiguration){
                if(this.getConfiguration.numbers){
                    return this.getConfiguration.numbers;
                }
                return this.getConfiguration;
            }
            return 0;
        },
        getConfiguration: function(){
            if(this.configuration[this.type]){
                return this.configuration[this.type];
            }
            return null;
        },
        getConfigurationTemp: function(){
            if(this.configurationTmp[this.type]){
                return this.configurationTmp[this.type];
            }
            return null;
        },
        isConfigArray: function(){
            return Array.isArray(this.configuration[this.type]);
        }
    },
    methods: {
        toggleBody: function(){
            this.showBody = !this.showBody;
        },
        generateId: function(item, index, type = null){
            if(!item.key){
                return `${this.type}Number_${index}`;
            }
            return `${type ? `${type}|` : ''}${this.innerTrim(item.key.toLowerCase())}_${index}`;
        },
        configurationResolver: function(configuration, configurationTmp) {
            let configResolved = {};

            for (let item in configuration) {
                let config = configuration[item],
                    tmpConfig = configurationTmp[item];

                if (config.numbers) {
                    configResolved[item] = tmpConfig.map(item => item.value);
                } else {
                    let resolve = [];

                    for (let conf in config) {
                        let currentItem = config[conf];

                        if (currentItem.numbers) {
                            let findAllResultsByKey = tmpConfig.filter(tmpItem => tmpItem.key.split("_")[0].toLowerCase() === currentItem.key.toLowerCase()),
                                getValuesFromResults = findAllResultsByKey.map(findAllItem => findAllItem.value),
                                objectResolved = {
                                    key: currentItem.key,
                                    value: getValuesFromResults
                                };
                            resolve.push(objectResolved);
                        } else {
                            resolve = tmpConfig.map(tmpItem => {
                                return {
                                    key: tmpItem.key.split("_")[0],
                                    value: tmpItem.value
                                };
                            });
                        }
                    }

                    configResolved[item] = resolve;
                }
            }

            return configResolved;
        },
        idResolver: function(id){
            let chunk = id.split("|");
            return id.includes("|") ? chunk[1] : id;
        }
    }
};