import transactionsPreviewTypes from "./types/transactionsPreviewTypes";

export default {
    mutations: {
        ...transactionsPreviewTypes.mutations,
    },
    getters: {
        ...transactionsPreviewTypes.getters,
    },
    actions: {
        ...transactionsPreviewTypes.actions,
    }
};
