<template>
    <div>
        <atbl-form-group label="Jackpot">
            <atbl-input :value="value.jackpot"
                        :disabled="!!loader"
                        :errors="errors"
                        name="jackpot"
                        @update="updateNextDraw"
            />
        </atbl-form-group>
        <atbl-form-group label="Jackpot Raw">
            <atbl-number :value="value.jackpot_raw"
                         :disabled="!!loader"
                         :errors="errors"
                         :max="null"
                         name="jackpot_raw"
                         @update="updateNextDraw"
            />
        </atbl-form-group>
    </div>
</template>

<script>
import AtblFormGroup from '../../../../../components/Forms/Holders/ATBLFormGroup';
import AtblNumber from '../../../../../components/Forms/Inputs/ATBLNumber';
import { clone } from '../../../../../utils/Json/Json';

export default {
    name: 'CurrentDrawEditorComponent',
    emits: [
        'input',
    ],
    components: {
        AtblFormGroup,
        AtblNumber,
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        loader: {
            type: Boolean,
            default: false
        },
        draw: {
            type: Object,
            default: () => ({})
        },
        errors: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        updateNextDraw(name, value) {
            const model = clone(this.value);

            model[name] = value;

            this.$emit('input', model);
        }
    },
}
</script>