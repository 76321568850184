import { clone } from '../../../utils/Json/Json';
import PhoneNumberModel from './PhoneNumberModel';
import getValidationErrors from '../../../utils/Error/GetValidationErrors';

export default {
    provide() {
        return {
            onUpdatePhoneNumber: this.onUpdatePhoneNumber,
            updateAllowedTypesModel: this.updateAllowedTypesModel,
            updateIsActiveStatusModel: this.updateIsActiveStatusModel,
            toggleUpdateWindow: this.toggleUpdateWindow,
        };
    },
    data() {
        return {
            showUpdateWindow: false,
            isUpdateLoading: false,
            selectedPhoneNumber: clone(PhoneNumberModel),
            phoneNumberErrors: {},
        };
    },
    methods: {
        async onUpdatePhoneNumber() {
            if (!this.$can('Phone Numbers - Update')) {
                window.showMessage('No permissions to save update phone numbers.', false);

                return;
            }

            this.phoneNumberErrors = {};
            this.isUpdateLoading = true;

            const { id, allowed_for, is_active } = this.selectedPhoneNumber;

            try {
                const response = await window.axios.patch(`/phone-numbers/${id}`, {
                    allowed_for,
                    is_active,
                });
                const { status, message } = response.data;

                window.showMessage(message, status);

                if(!!status) {
                    this.toggleUpdateWindow();
                    await this.fetchPhoneNumbersList();
                }

                this.isUpdateLoading = false;
            } catch (error) {
                this.isUpdateLoading = false;

                this.phoneNumberErrors = getValidationErrors(error);

                window.flashBadResponse(error);
            }
        },
        updateAllowedTypesModel(name, value, type) {
            this.selectedPhoneNumber.allowed_for = !!value
                ? [...this.selectedPhoneNumber.allowed_for, type]
                : this.selectedPhoneNumber.allowed_for.filter(item => !item.includes(type));
        },
        updateIsActiveStatusModel(name, value) {
            this.selectedPhoneNumber.is_active = value;
        },
        onSelectPhoneNumber(phoneNumber) {
            if (!phoneNumber) {
                window.showMessage('Phone number is undefined.', false);

                return;
            }

            this.selectedPhoneNumber = clone(phoneNumber);
            this.toggleUpdateWindow();
        },
        toggleUpdateWindow() {
            this.showUpdateWindow = !this.showUpdateWindow;

            if (!this.showUpdateWindow) {
                this.selectedPhoneNumber = clone(PhoneNumberModel);
            }
        },
    },
}