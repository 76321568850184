var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group-atbl",
      class: {
        "d-flex align-items-center": _vm.horizontal
      }
    },
    [
      _vm.label
        ? _c("label", {
            class: [
              _vm.labelClass,
              {
                "m-0 mr-3": _vm.horizontal
              }
            ],
            attrs: { for: _vm.labelFor },
            domProps: { innerHTML: _vm._s(_vm.label) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.error
        ? _c("span", { staticClass: "form-group-atbl-error" }, [
            _vm._v("\n        " + _vm._s(_vm.error) + "\n    ")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }