<template>
    <atbl-modal :show="show"
             title="Remove User"
             v-if="$can('Users - Remove')"
             :close-on-backdrop="false"
             class="hide-close"
    >
        <h5>Are you sure you want to delete this user.</h5>

        <template #footer>
            <button class="btn btn-sm btn-light"
                      @click="$emit('on-visibility-change', null, false)"
            >
                Close
            </button>
            <button class="btn btn-sm btn-danger"
                      @click="deleteUserEvent"
            >
                <span v-if="!isDeleting">Delete</span>
                <loader :show="isDeleting"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'DeleteUser',
    components: {
		AtblModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        currentUserId: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            isDeleting: false
        };
    },
    methods: {
        async deleteUserEvent () {
            if (!this.$can('Users - Create/Update')) {
                window.showMessage('Unauthorized action.');

                return;
            }

            this.isDeleting = true;

            try {
                const response = await window.axios.delete(`/user/${this.currentUserId}`);
                const { message, status } = response.data;

                window.showMessage(message, status);

                if (status) {
                    this.$emit('on-visibility-change', null, false);
                    this.$emit('reload');
                }

                this.isDeleting = false;
            } catch (error) {
                window.flashError(error);

                this.isDeleting = false;
            }
        }
    }
}
</script>