var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-form-group",
    {
      staticClass: "font-weight-bold",
      attrs: {
        label: _vm.label,
        "label-for": "Permissions",
        horizontal: _vm.isHorizontal
      }
    },
    [
      _c("tree-select", {
        attrs: {
          multiple: true,
          searchable: true,
          "clear-on-select": true,
          options: _vm.getPermissionsList,
          normalizer: function(node) {
            return { id: node.name, label: node.name }
          },
          disabled: _vm.disabled || _vm.isLoading
        },
        on: { input: _vm.updateModel },
        model: {
          value: _vm.selectedPermissions,
          callback: function($$v) {
            _vm.selectedPermissions = $$v
          },
          expression: "selectedPermissions"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }