<template>
    <form @submit.prevent="search">
        <div class="card card-search">
            <div class="card-header d-flex align-items-center">
                <i class="fa fa-search mr-1"></i> Search
            </div>
            <div class="card-body pb-1">
                <div class="row">
                    <div class="col-12 col-lg-4">
                      <atbl-input id="firstName"
                                  label="Name"
                                  type="text"
                                  class="font-weight-bold"
                                  placeholder="First Name"
                                  v-model="searchModel.name"
                      />
                    </div>
                    <div class="col-12 col-lg-4">
                      <atbl-input id="surname"
                                  label="Surname"
                                  class="font-weight-bold"
                                  type="text"
                                  placeholder="Surname"
                                  v-model="searchModel.surname"
                      />
                    </div>
                    <div class="col-4">
                        <atbl-select v-model="searchModel.status"
                                     label="KYC Status"
                                     :options="$options.getAllKYCStatues()"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <atbl-form-group label="Last Activity">
                            <multi-date-picker direction="right"
                                               v-model="selectedDates"
                                               format="YYYY-MM-DD"
                                               :show-ranges="true"
                                               :auto-apply="true"
                            />
                        </atbl-form-group>
                    </div>
                    <div v-if="$can('Users - Get List')"
                           class="col-12 col-lg-8"
                    >
                        <multi-agents-picker v-model="searchModel.agents" />
                    </div>
                </div>
                <div class="row">
              
                </div>
            </div>
            <div class="card-footer d-flex align-items-center">
                <div>
                    <button type="submit"
                              size="sm"
                              class="btn btn-sm btn-primary"
                    >
                        <i class="fa fa-search"></i> Search
                    </button>
                    <button type="reset"
                              class="btn btn-sm btn-danger"
                              @click.prevent="clear">
                        <i class="fa fa-ban"></i> Clear
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import Date from '../../../helpers/Date.js';
import moment from 'moment';
import { clone } from '../../../utils/Json/Json';
import { pick } from 'lodash';
import QueryToObject from '../../../utils/Url/QueryToObject';
import KycSubmissionSearchModel from './KycSubmissionSearchModel';
import { getAllKYCStatues } from '../../Players/Components/Layout/KYCStatus/KYCStatusConstants.js'

export default {
    name: 'kyc-submission-search',
    getAllKYCStatues,
    data() {
        return {
            searchModel: clone(KycSubmissionSearchModel),
        };
    },
    computed: {
        selectedDates: {
            get() {
                if (!this.searchModel.dateFrom && !this.searchModel.dateTo) {
                    return null;
                }

                return {
                    startDate: this.searchModel.dateFrom,
                    endDate: this.searchModel.dateTo
                };
            },
            set(value) {
                this.searchModel.dateFrom = !!value.startDate
                    ? moment(value.startDate).format(Date.defaultDate)
                    : null;
                this.searchModel.dateTo = !!value.endDate
                    ? moment(value.endDate).format(Date.defaultDate)
                    : null;
            }
        }
    },
    created() {
        this.initializeSearchForm();
    },
    methods: {
        initializeSearchForm() {
            const searchParams = QueryToObject();
            const searchModel = pick(searchParams, Object.keys(this.searchModel));
            const otherParams = pick(searchParams, ['sort', 'paginate', 'page']);

            if (this.isObjectEmpty(searchModel) && this.isObjectEmpty(otherParams)) {
                return;
            }

            Object.assign(this.searchModel, searchModel);

            this.search({
                searchModel,
                otherParams
            });
        },
        search(data) {
            const model = data instanceof Event
                ? this.searchModel
                : data;

            this.$emit('search', model)
        },
        clear() {
            this.searchModel = clone(KycSubmissionSearchModel);
            this.$emit('clear');
        }
    },
}
</script>
