import {clone} from "../../../utils/Json/Json";
import GameDiscountModel from "../GameDiscountModel";
import GameType from "../../../constants/GameType";
import {isObjectEmpty} from '../../../utils/Object/Object';

export default {
	provide() {
		return {
			onSelectGameForEdit: this.onSelectGameForEdit,
			clearGameForEdit: this.clearGameForEdit,
			hasErrors: this.hasErrors,
			getErrors: this.getErrors,
		};
	},
	data() {
		return {
			editingGame: clone(GameDiscountModel),
		};
	},
	computed: {
		hasGameForEditing() {
			return !! this.editingGame.id;
		},
	},
	methods: {
		async onSelectGameForEdit(gameToEdit) {
			this.editingGame = gameToEdit;

			this.clearDiscountLines();
			this.clearDiscountDraw();
			this.clearDiscountFractions();

			if (!gameToEdit) {
				return;
			}

			switch (gameToEdit.type) {
				case GameType.Lottery:
					this.setUpLineDiscounts(gameToEdit);
					this.setUpDrawDiscounts(gameToEdit);
					return;
				case GameType.Raffle:
					this.setUpFractionDiscounts(gameToEdit);
					return;
			}
		},
		async clearGameForEdit() {
			await this.onSelectGameForEdit(clone(GameDiscountModel));
		},
		hasErrors(errors, index, keyToSearch = 'discountLines') {
			if (isObjectEmpty(errors)) {
				return false;
			}

			if (index === null) {
				return errors.hasOwnProperty(keyToSearch);
			}

			return !! Object.keys(errors)
				.filter(k => k.includes(`${keyToSearch}.${index}`))
				.length;
		},
		getErrors(errors, index, keyToSearch = 'discountLines') {
			const key = `${keyToSearch}.${index}`;

			if (index === null) {
				return errors[keyToSearch][0];
			}

			return Object.keys(errors)
				.filter(k => k.includes(key))
				.map(k => {
					return errors[k][0].replace(key + '.', '');
				});
		},
	},
}