var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", { staticClass: "card card-list m-0 border-0 bg-transparent" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex bg-primary text-white align-items-center justify-content-between border"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  on: {
                    click: function($event) {
                      _vm.callNoteModal = true
                    }
                  }
                },
                [_vm._v("\n                    Add Note\n                ")]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card-body p-0",
            class: {
              "bg-white": _vm.callNotesList.length === 0,
              "bg-transparent": _vm.callNotesList.length > 0
            }
          },
          [
            _vm._l(_vm.callNotesList, function(item, index) {
              return _c("div", { key: index, staticClass: "card" }, [
                _c("div", { staticClass: "card-header p-2" }, [
                  _c("div", [
                    _vm._v(
                      "\n                        Agent:\n                        "
                    ),
                    _c("span", { staticClass: "badge badge-primary" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.user.name) +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                        Created:\n                        "
                    ),
                    _c("small", { staticClass: "text-dark" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.utcToLocal(item.created_at)) +
                          "\n                        "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body p-2" }, [
                  _c("p", { staticClass: "m-0" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(item.note) +
                        "\n                    "
                    )
                  ])
                ])
              ])
            }),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("not-found", {
                  attrs: {
                    msg: "No call notes found.",
                    items: _vm.callNotesList
                  }
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("add-call-note-modal-component", {
        attrs: { show: _vm.callNoteModal }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-sticky-note" }),
      _vm._v(" Notes\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }