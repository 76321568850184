var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-content-center align-items-center flex-grow-1",
      staticStyle: { height: "100%" }
    },
    [
      _vm.isLoading
        ? _c("span", { staticClass: "spiner" }, [
            _c("img", { attrs: { src: "/img/spiner.svg", alt: "spiner" } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.status
        ? _c("div", [
            !_vm.isLoading
              ? _c("p", { staticClass: "success" }, [
                  _c("i", { staticClass: "fa fa-check" })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { class: ["alert", "alert-" + _vm.alertState] }, [
              _vm._v(_vm._s(_vm.message))
            ])
          ])
        : _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(_vm._s(_vm.message))
            ])
          ]),
      _vm._v(" "),
      _vm.countDown >= 0
        ? _c("h6", [
            _vm._v("Redirect in " + _vm._s(_vm.countDown) + " seconds")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "error" }, [
      _c("i", { staticClass: "fa fa-times" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }