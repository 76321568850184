<template>
	<section class="general-list">
		<div class="row sticky-element p-0">
			<div class="col-6 col-lg p-2">Date</div>
			<div class="col-6 col-lg p-2">Orders</div>
			<div class="col-6 col-lg p-2">Num. Tickets</div>
			<div class="col-6 col-lg p-2">Sales</div>
			<div class="col-6 col-lg bg-primary-20 p-2">Deposits</div>
			<div class="col-6 col-lg p-2">Bonus</div>
			<div class="col-6 col-lg bg-primary-20 p-2">Commission</div>
			<div class="col-6 col-lg p-2">Transactions</div>
		</div>
		
		<template v-if="!isLoading && days.length">
			<div v-for="(day, index) in days"
                   :key="index"
                   class="row p-0"
            >
				<div class="col-6 col-lg p-2">
					<span class="small-mb-badge">Date.</span>
					<a href="#"
					   @click="openAgent($event, day.created_at)"
					   v-if="$can('Reports Agents - View')"
					>
						{{ day.created_at }}
					</a>
					<span v-else>
                        {{ day.day }}
                    </span>
				</div>
				<div class="col-6 col-lg p-2">
					<span class="small-mb-badge">Orders</span>
					<a target="_blank">
						{{ day.ordersCount }}
					</a>
				</div>
				<div class="col-6 col-lg p-2">
					<span class="small-mb-badge">Num. Tickets</span>
					{{ day.ticketsCount }}
				</div>
				<div class="col-6 col-lg p-2">
					<span class="small-mb-badge">Sales</span>
					<span v-html="currencyNumber(day, 'sales')"/>
				</div>
				<div class="col-6 col-lg bg-primary-20 p-2">
					<span class="small-mb-badge">Deposits</span>
					<span v-html="currencyNumber(day, 'deposit')"/>
				</div>
				<div class="col-6 col-lg p-2">
					<span class="small-mb-badge">Bonus</span>
					<span v-html="currencyNumber(day, 'bonus')"/>
				</div>
				<div class="col-6 col-lg bg-primary-20 p-2">
					<span class="small-mb-badge">Commission</span>
					<span v-html="currencyNumber(day, 'commission')"/>
				</div>
				<div class="col-12 col-lg p-2">
					<span class="small-mb-badge">Transactions</span>
					{{ day.transactionsCount }}
				</div>
			</div>
		</template>
		
		<not-found v-if="!isLoading"
		           :items="days"
		           msg="No transactions found."
		/>
		
		<loader :show="isLoading"
		        type="small"
		/>
	</section>
</template>

<script>
	export default {
		name: "agents-details-list",
		props: {
			user: {
				require: true
			},
			days: {
				require: true
			},
			id: {
				require: true
			},
			isLoading: {
				type: Boolean,
				require: true
			}
		},
		data() {
			return {
				transactions: [],
				commission: 0,
				total: 0
			};
		},
		methods: {
			openAgent(e, date) {
				e.preventDefault();
				this.$emit("openAgent", date);
			}
		}
	}
</script>