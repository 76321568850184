var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card m-0" }, [
    _c("div", { staticClass: "card-body p-3" }, [
      _c("div", { staticClass: "call-history-agents-badge" }, [
        _c("span", { staticClass: "badge badge-primary" }, [
          _vm._v(
            "\n                Period: " + _vm._s(_vm.range) + "\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.agents, function(item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Agent")
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/call/history/view/" +
                              item.agent_id +
                              "?name=" +
                              item.name
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.name) +
                              "\n                        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Leads Time Talk")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.isset(item, "talk_time_leads")
                              ? item.talk_time_leads
                              : 0
                          ) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Players Time Talk")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.isset(item, "talk_time_players")
                              ? item.talk_time_players
                              : 0
                          ) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Calls")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.total_calls) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Completed Calls")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.completed_calls) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0"
                    },
                    [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Average Talk Time")
                      ]),
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.average_talk_time) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: { items: _vm.agents, msg: "No call history found." }
              })
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0" },
        [_vm._v("Agent")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0" },
        [_vm._v("Leads Time Talk")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0" },
        [_vm._v("Players Time Talk")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0" },
        [_vm._v("Calls")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0" },
        [_vm._v("Completed Calls")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0" },
        [_vm._v("Average Talk Time")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }