var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      staticClass: "withdrawals-details-modal",
      attrs: {
        title: "Withdrawal Details",
        show: true,
        "close-on-backdrop": false
      },
      on: { close: _vm.closeWithdrawalDetails }
    },
    [
      !_vm.isLoading
        ? [
            _c("div", { staticClass: "withdrawals-details-modal-details" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col col-3" }, [
                  _c("span", [_vm._v("Player")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.withdrawalDetails.player_name) +
                      " " +
                      _vm._s(_vm.withdrawalDetails.player_surname) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.withdrawalDetails.name
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col col-3" }, [
                      _c("span", [_vm._v("Agent")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.withdrawalDetails.name) +
                          "\n        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col col-3" }, [
                  _c("span", [_vm._v("Reference")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm.withdrawalDetails.holder &&
                  _vm.withdrawalDetails.holder !== null
                    ? _c("div", [
                        _vm._v(
                          "\n            Holder: " +
                            _vm._s(_vm.withdrawalDetails.holder) +
                            "\n            "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.withdrawalDetails.payment_brand) +
                            " / *" +
                            _vm._s(_vm.withdrawalDetails.last_4_digits) +
                            "\n          "
                        )
                      ])
                    : _c("p", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            This card is removed.\n          "
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col col-3" }, [
                  _c("span", [_vm._v("Amount")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("formatCurrency")(_vm.withdrawalDetails.amount)
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col col-3" }, [
                  _c("span", [_vm._v("Status")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("withdrawals-status-badge", {
                      attrs: {
                        status: _vm.withdrawalDetails.status,
                        "decline-reason": _vm.withdrawalDetails.decline_reason
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col col-3" }, [
                  _c("span", [_vm._v("Created At")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.utcToLocal(_vm.withdrawalDetails.created_at)) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.$can("Withdrawals - Update") &&
              _vm.isStatusPending &&
              !_vm.isCheckedStatus
                ? _c("div", { staticClass: "row" }, [
                    _vm.withdrawalDetails.holder &&
                    _vm.withdrawalDetails.holder !== null
                      ? _c("div", { staticClass: "col" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success btn-block",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.setStatusType("APPROVED")
                                }
                              }
                            },
                            [_vm._v("\n            APPROVE\n          ")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "atbl-dropdown",
                          { attrs: { "input-style": "danger" } },
                          [
                            _c(
                              "atbl-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.setStatusType(
                                      "DECLINED",
                                      "Invalid email"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Invalid email\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "atbl-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.setStatusType(
                                      "DECLINED",
                                      "Not enough funds"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Not enough funds\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "atbl-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.setStatusType("DECLINED", null)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Without reason\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("Withdrawals - Update") &&
              !_vm.isLoading &&
              _vm.isStatusPending &&
              _vm.isCheckedStatus
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h5", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n            Are your sure you want to set status "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.status))]),
                        _vm._v(" to this withdrawal request?\n          ")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-success btn-block",
                          attrs: { type: "button" },
                          on: { click: _vm.onStatusChangeEvent }
                        },
                        [_vm._v("\n            YES\n          ")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-danger btn-block",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.cancelProcess()
                            }
                          }
                        },
                        [_vm._v("\n            NO\n          ")]
                      )
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            !_vm.withdrawalDetails.id
              ? _c("items-not-found", {
                  attrs: { items: [], msg: "No withdrawal request found." }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("div", { staticClass: "alert alert-danger mt-2 mb-0" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  ")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }