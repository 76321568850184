<template>
    <div class="flag-select">
		<span v-if="placeholder"
              class="atbl-placeholder font-weight-bold"
        >
			{{ placeholder }}
		</span>
        <div v-if="isLoading"
             class="p-2 border"
        >
            <loader type="smaller"
                    :show="true"
            />
        </div>
        <tree-select v-else
                     v-model="selectedNumber"
                     :options="phoneNumbers"
                     :searchable="true"
        >
            <label slot="option-label"
                   slot-scope="{
                                  node,
                                  shouldShowCount,
                                  count,
                                  labelClassName,
                                  countClassName
                              }"
                   :class="labelClassName"
            >
                <img :src="node.raw.flag" class="flag-smaller" alt="flag-icon" />
                <strong>{{ node.label }}</strong>
            </label>
            <div slot="value-label" slot-scope="{ node }">
                <img :src="node.raw.flag" class="flag-smaller" alt="flag-icon" />
                <strong>{{ node.label }}</strong>
            </div>
        </tree-select>
    </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber';

export default {
    name: 'TwilioPhoneNumbers',
    props: {
        input: {},
        name: {
            type: String,
            value: 'phone-number'
        },
        value: {
            type: Object,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        modelType: {
            type: String,
            default: 'players'
        },
    },
    data() {
        return {
            phoneNumbers: [],
            isLoading: false
        };
    },
    computed: {
        selectedNumber: {
            get() {
                return !!this.value ? this.value.value : null;
            },
            set(value) {
                const number = this.phoneNumbers.find(number => number.id === value);

                this.$emit('input', number);
                this.$emit('update', this.name, number);
            }
        }
    },
    methods: {
        async getNumbers() {
            this.isLoading = true;

            try {
                const response = await window.axios.get(`/phone-numbers/list/${this.modelType}`);
                const { status, message, data } = response.data;

                window.showMessage(message, status);

                const phoneNumbers = !!data && !!data.length
                    ? data.map(({phone_number: number}) => {
                        let numberInstance = PhoneNumber(number),
                            regionCode = numberInstance.getRegionCode().toLowerCase(),
                            flag = `/img/flags/${regionCode}.svg`;

                        return {
                            id: number,
                            flag: flag,
                            value: number,
                            label: number,
                            regionCode: regionCode.toUpperCase()
                        };
                    })
                    : [];

                this.phoneNumbers = this.alphabeticSort(phoneNumbers, 'regionCode');

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        findNumberByValue(value) {
            return this.phoneNumbers.find(number => number.value === value);
        }
    },
    async created() {
        await this.getNumbers();
    }
}
</script>