<template>
    <div class="container-fluid permissions-manager">
        <div v-if="!isLoading"
             class="row"
        >
            <div class="col-12 col-lg-6">
                <permission-editor-component :model="permissionModel"
                                             :roles="roles"
                />
            </div>
            <div v-if="isEditMode"
                 class="col-12 col-lg-6"
            >
                <users-editor-component :model="permissionModel"
                                        :users-data="usersData"
                />
            </div>
        </div>
        <loader :show="isLoading"
                type="small"
        />
    </div>
</template>

<script>
import Loader from '../../../components/Widgets/Loader';
import PermissionsService from '../../../services/PermissionsService';
import RemoveListItemButton from '../../../components/Lists/RemoveListItemButton';
import SortBy from '../../../components/SortBy/SortBy';
import PermissionEditorComponent from './PermissionEditor/PermissionEditorComponent';
import UsersEditorComponent from './UsersEditor/UsersEditorComponent';
import { clone } from '../../../utils/Json/Json';
import PermissionModel from './PermissionModel';
import DeletePermission from '../List/DeletePermission';

export default {
    name: 'PermissionsManager',
    mixins: [
        DeletePermission,
    ],
    components: {
        UsersEditorComponent,
        PermissionEditorComponent,
        Loader,
        RemoveListItemButton,
        SortBy,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
    },
    provide() {
        return {
            isEditMode: this.isEditMode,
            permissionId: this.id,
            setPermissionModel: this.setPermissionModel,
        };
    },
    data() {
        return {
            dataLoader: false,

            permissionModel: clone(PermissionModel),
            permissionLoader: false,

            roles: [],
            rolesLoader: false,

            usersData: [],
            usersLoader: false,
        };
    },
    computed: {
        isEditMode() {
            return !!this.id;
        },
        isLoading() {
            return this.dataLoader || this.deletePermissionLoader;
        },
    },
    async created() {
        await this.initializeData();
    },
    methods: {
        async initializeData() {
            this.dataLoader = true;

            try {
                const actions = [
                    window.axios.get('/list-roles'),
                    window.axios.get('/list-users-all', {
                        params: {
                            sortBy: {
                                direction: 'asc',
                                column: 'name',
                            },
                        },
                    }),
                ];

                if (!!this.isEditMode) {
                    actions.push(PermissionsService.getPermissionsById(this.id));
                }

                const [
                    rolesResponse,
                    usersResponse,
                    permissionResponse
                ] = await Promise.all(actions);

                this.setRoles(rolesResponse);
                this.setUsers(usersResponse);
                this.setPermissionModel(!!permissionResponse ? permissionResponse.data : null);

                this.dataLoader = false;
            } catch (error) {
                this.dataLoader = false;

                window.flashError(error);
            }

        },
        setRoles(response) {
            if (!response) {
                return;
            }

            this.roles = response.data;
        },
        setUsers(response) {
            if (!response) {
                return;
            }

            this.usersData = response.data;
        },
        setPermissionModel(response) {
            if (!response) {
                return;
            }
            Object.assign(this.permissionModel, response);
        },
    }
}
</script>