export default {
    getMarketingRoutes(){
        return "/marketing";
    },

    // campaigns
    getCampaignsConfigsStatuses(){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/configs/statuses`
        );
    },
    getCampaignsConfigsTypes(){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/configs/types`
        );
    },
    getCampaignsConfigsViewTypes(){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/configs/view-types`
        );
    },
    getCampaigns(type, params){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/${type}/get`,
            {
                params
            }
        );
    },
    getCampaignById(type, id){
        return window.axios.get(`${this.getMarketingRoutes()}/campaigns/${type}/${id}/details`);
    },
    getCampaignsDropdownList(type){
        return window.axios.get(`${this.getMarketingRoutes()}/campaigns/${type}/dropdown-list`);
    },
    getCampaignLeads(type, id, params){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/customers`,
            {
                params
            }
        );
    },
    getCustomerById(type, id, customerId,){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/customers/get/${customerId}`,
        );
    },
    getCampaignsLeadsFilter(type, id){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/filters`
        );
    },
    getLeadsListOutOfCampaign(type, id, params){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/list`,
            {
                params
            }
        );
    },
    getListViewLeads(type, id, params = {}){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/list/view`,
            {
                params
            }
        );
    },
    getSingleCallLead(type, id){
        return window.axios.get(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/customers/call`
        );
    },
    async downloadRecording(params = {}) {
        return await window.axios.post(
            `/twilio/recordings/download`,
            params
        );
    },
    createCampaign(type, model = {}){
        return window.axios.post(
            `${this.getMarketingRoutes()}/campaigns/${type}/create`,
            model,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },
    updateCampaign(id, type, model = {}){
        return window.axios.post(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}`,
            model,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },
    addLeadById(type, id, model = {}){
        return window.axios.post(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/add`,
            model
        );
    },
    removeCampaignLeads(type, id, model = {}){
        return window.axios.post(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/remove`,
            model
        );
    },
    removeCampaign(type, id){
        return window.axios.delete(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}`
        );
    },

    // leads statuses
    saveCustomerStatus(type, id, model = {}){
        return window.axios.post(
            `${this.getMarketingRoutes()}/campaigns/${type}/${id}/customers/add-status`,
            model
        );
    },
    getLeadStatusList(id){
        return window.axios.get(`${this.getMarketingRoutes()}/leads/${id}/call-view-dispositions`);
    },

    // call history
    getCallsHistory(params = {}, agent = null){
        return window.axios.get(
            `/call/history/get${agent ? `/${agent}` : ''}`,
            {
                params
            }
        );
    },
    getCallsStatuses(){
        return window.axios.get(
            `/call/history/statuses`
        );
    },
}