var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header card-header-atbl d-flex justify-content-between align-items-center"
      },
      [
        _c("span", [
          _c("i", { staticClass: "nav-icon fa fa-users mr-2" }),
          _vm._v(" " + _vm._s(_vm.model.name) + " " + _vm._s(_vm.model.surname))
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.$can("Leads - Create/Edit")
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary m-0",
                  on: { click: _vm.toggleAddLeadModal }
                },
                [_vm._v("\n                Edit\n            ")]
              )
            : _vm._e()
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card-body",
        class: {
          "pb-1": !_vm.isLoading
        }
      },
      [
        !_vm.isLoading
          ? [
              !!_vm.model.converted
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between bg-light-gray p-2 mb-3"
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm.$can("Player - View Details") && !!_vm.model.converted
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/players/" + _vm.model.player_id + "/view",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n                    View Player\n                "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row leads-section-profile" }, [
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Name")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.name)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Surname")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.surname)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Email")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.email)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Birthdate")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.birthdate)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Address1")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.address1)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Address2")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.address2)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Country")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.country)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("State")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.state)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("City")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.city)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Postcode")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(_vm._s(_vm._f("emptyField")(_vm.model.postcode)))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Phone")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("emptyField")(_vm.model.phone)) +
                        "\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Mobile")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("emptyField")(_vm.model.mobile)) +
                        "\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6 m-0" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Live Spent")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm.model.live_spent !== null && _vm.model.live_spent === 0
                      ? _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.model.live_spent) +
                              "\n                        "
                          )
                        ])
                      : _vm.model.live_spent > 0
                      ? _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("formatCurrency")(_vm.model.live_spent)
                              ) +
                              "\n                        "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                            /\n                        "
                          )
                        ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "row leads-section-profile" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("span", { staticClass: "leads-section-profile-label" }, [
                    _vm._v("Other")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "leads-section-profile-text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("emptyField")(_vm.model.other)) +
                        "\n                    "
                    )
                  ])
                ])
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("span", { staticClass: "leads-section-profile-label mr-2" }, [
        _vm._v("Status")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-success" }, [
        _vm._v("\n                        Converted\n                    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }