<template>
    <player-layout :id="id" icon="fa fa-ticket-alt" title="Raffle tickets">
        <template slot="first">
            <player-raffle-tickets-search-form v-on:search="search" />
        </template>

        <section class="general-list agents">
            <div class="row">
                <div class="col text-muted">Ticket</div>
                <div class="col text-muted text-center">Created By</div>
                <div class="col text-muted text-center">Lottery</div>
                <div class="col text-muted text-center">Order</div>
                <div class="col text-muted text-center">Draw Date</div>
                <div class="col text-muted text-center">Lines</div>
                <div class="col text-muted text-center">Winnings</div>
            </div>

            <div v-if="!isLoading"
                   v-for="(ticket, index) in tickets"
                   :key="index"
                   class="row"
            >
                <div class="col align-self-center">
                    <a :href="`/raffle-tickets/${ticket.id}`">T# {{ ticket.id }}</a>
                    <button class="btn btn-sm btn-link p-0" v-tooltip="{content: 'Copy to clipboard'}" @click="copyToClipboard(ticket.id)">
                        <i class="fa fa-copy" aria-hidden="false"></i>
                    </button>
                    <div>
                        <small class="text-muted">{{ utcToLocal(ticket.created_at) }}</small>
                    </div>
                </div>

                <div class="col align-self-center text-center">
                    <created-by-badge :created="ticket.user_name" />
                </div>

                <div class="col text-center align-self-center">
                    <img :src="$imageService.gameImage(ticket.game_image)" :alt="`${ticket.game_image}`" class="game-thumb" />
                </div>

                <div class="col text-center align-self-center">
                    <a v-if="$can('Orders - View Order Details')"
                       :href="`/orders/${ticket.order_id}?playerId=${ticket.player_id}&from=raffles`"
                    >
                        O# {{ ticket.order_id }}
                    </a>
                    <span v-else>
                        O# {{ ticket.order_id }}
                    </span>
                </div>

                <div class="col text-center align-self-center">
                    {{ ticket.draw_date }}
                </div>

                <div class="col text-center align-self-center">
                    {{ ticket.fractions }}
                </div>

                <div class="col text-center align-self-center" v-html="ticket.is_parsed ? formatCurrency(ticket.prize) : 'Not drawn yet'"></div>
            </div>

            <loader :show="isLoading"
                    type="small"
            />

            <not-found v-if="!isLoading"
                       msg="No tickets found."
                       :items="tickets"
            />

            <nav class="wrap py-3" v-if="!isLoading && ticketResponse.last_page > 1">
                <atbl-pagination :pagination="ticketResponse"
                                 @paginate="getRaffleTickets"
                />
            </nav>
        </section>
    </player-layout>
</template>

<script>
import Transaction from '../../../Orders/Mixins/Transaction';
import PlayerRaffleTicketsSearchForm from './Sections/PlayerRaffleTicketsSearchForm';
import formatCurrency from '../../../../filters/FormatCurrency';

export default {
    name: 'player-raffle-tickets',
    components: { PlayerRaffleTicketsSearchForm },
    mixins: [
        Transaction
    ],
    props: ['id'],
    data() {
        return {
            tickets: [],
            ticketResponse: {},
            isLoading: false,
            searchData: null,
        };
    },
    created() {
        this.getRaffleTickets();
    },
    methods: {
        formatCurrency,
        getRaffleTickets(page = 1) {
            this.isLoading = true;

            const params = {
                page: page,
                ...(this.searchData && this.searchData.ticketId && { ticketId: this.searchData.ticketId }),
                ...(this.searchData && this.searchData.drawDate && { drawDate: this.searchData.drawDate }),
                ...(this.searchData && this.searchData.games && { games: this.searchData.games }),
            };

            window.axios(`/players/${this.id}/raffles/index`, { params })
                .then((response) => {
                    this.tickets = response.data.data;
                    this.ticketResponse = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        search(data) {
            this.searchData = data;
            this.getRaffleTickets(1);
        },
    }
}
</script>
