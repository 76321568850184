export default {
    name: null,
    surname: null,
    order: null,
    transaction: null,
    referenceTransactionId: null,
    paymentType: [],
    agents: [],
    dateFrom: null,
    dateTo: null,
    searchType: [],
    transactionStatus: null,
    gateway: null,
    amount: null,
    amountSelector: null,
};