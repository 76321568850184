<template>
    <div>
        <atbl-form-group label="Card *"
                      label-for="Card"
                      class="m-0 font-weight-bold"
        >
            <select class="form-control"
                    v-model="selectCard"
            >
                <option :value="null">Select credit card</option>
                <template v-for="(card, index) in getCreditCards"
                >
                    <option :key="index" :value="card.id">
                        {{ card.holder }} * {{ card.last_4_digits }} |
                        exp. {{ card.expiry_month }}/{{ card.expiry_year }} |
                        succ: {{ card.success ? card.success : 0 }} |
                        fail: {{ card.failed ? card.failed : 0 }}
                    </option>
                </template>
            </select>
        </atbl-form-group>
    </div>
</template>

<script>
    import TypesConst from "../../../../../../../store/TypesConst";
    import NamespaceConst from "../../../../../../../store/NamespaceConst";
    import { createNamespacedHelpers } from "vuex";
    const {
        mapGetters,
        mapMutations
    } = createNamespacedHelpers(NamespaceConst.players);

    export default {
        name: "pay-saved-with-card",
        computed: {
            ...mapGetters({
                getCreditCards: TypesConst.players.getters.GET_PLAYER_CREDIT_CARDS,
                card: TypesConst.players.getters.GET_PAY_WITH_MODEL
            }),
            selectCard: {
                get: function(){
                    return this.card.id;
                },
                set: function(value){
                    let creditCard = this.getCreditCards.filter(item => item.id === value)[0];
                    this.setModelField(creditCard ? creditCard : {});
                }
            }
        },
        methods: {
            ...mapMutations({
                setModelField: TypesConst.players.mutations.SET_PAY_WITH_MODEL_FIELD
            })
        }
    }
</script>