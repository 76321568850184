var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "player-call-component" },
    [
      _vm.player && _vm.isset(_vm.player, "id")
        ? _c("div", { staticClass: "card mt-2 mb-4" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header card-header-atbl align-items-center justify-content-between d-flex bg-primary text-white"
              },
              [
                _c("span", [
                  _c("i", { staticClass: "fa fa-phone" }),
                  _vm._v(
                    " Call Player (" +
                      _vm._s(_vm.player.name) +
                      " " +
                      _vm._s(_vm.player.surname) +
                      ")\n            "
                  )
                ]),
                _vm._v(" "),
                !!_vm.callLogs.length
                  ? _c(
                      "a",
                      {
                        staticClass: "text-white",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.toggleCallLogsModal(true)
                          }
                        }
                      },
                      [_vm._v("\n                Logs\n            ")]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("twilio-phone-numbers", {
                      model: {
                        value: _vm.selectedNumber,
                        callback: function($$v) {
                          _vm.selectedNumber = $$v
                        },
                        expression: "selectedNumber"
                      }
                    }),
                    _vm._v(" "),
                    _vm.isKeypadOpen
                      ? _c("DialKeypad", {
                          attrs: {
                            phone: _vm.number,
                            value: _vm.numberToCall,
                            "call-started": _vm.isCallStarted
                          },
                          on: {
                            digit: _vm.onPressedDigitEvent,
                            update: _vm.onDialPadNumberUpdate
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.callLoader
                      ? [
                          _c("div", { staticClass: "dial-buttons" }, [
                            _vm.isCallStarted
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "mute-button",
                                    class: {
                                      "btn-primary": !_vm.isMuted,
                                      "btn-warning": _vm.isMuted
                                    },
                                    attrs: {
                                      disabled: !_vm.isCallAccepted,
                                      type: "button"
                                    },
                                    on: { click: _vm.muteHandler }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa",
                                      class: {
                                        "fa-microphone": !_vm.isMuted,
                                        "fa-microphone-slash": _vm.isMuted
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isCallStarted
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-primary dial-button",
                                    class: { active: _vm.isKeypadOpen },
                                    attrs: {
                                      disabled:
                                        !_vm.selectedNumber ||
                                        !_vm.numberToCall,
                                      name: "dialBtn",
                                      type: "submit"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.onHandleCallEvent.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    !_vm.callLoader
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-phone"
                                          }),
                                          _vm._v(
                                            " Dial\n                                "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("loader", {
                                      attrs: {
                                        show: _vm.callLoader,
                                        type: "smaller"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger",
                                    attrs: { name: "hangBtn", type: "submit" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.onHangCallEvent.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-phone" }),
                                    _vm._v(
                                      " Hang\n                            "
                                    )
                                  ]
                                ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "keypad-toggle-button",
                                class: { active: _vm.isKeypadOpen },
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.isKeypadOpen = !_vm.isKeypadOpen
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/img/icons/keypad.svg",
                                    alt: "keypad"
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("loader", {
                      attrs: { show: _vm.callLoader, type: "small" }
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        : _c("div", { staticClass: "card mt-3" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("not-found", {
                  attrs: {
                    msg: "Player is not loaded. Please refresh you page.",
                    items: []
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c(
        "atbl-modal",
        {
          attrs: {
            title: "Call Logs",
            "close-on-backdrop": true,
            show: _vm.callLogsOpen
          },
          on: { close: _vm.toggleCallLogsModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger",
                      on: {
                        click: function($event) {
                          return _vm.toggleCallLogsModal(false)
                        }
                      }
                    },
                    [_vm._v("\n                Cancel\n            ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "card card-list m-0" }, [
            _c(
              "div",
              {
                staticClass: "card-body",
                style: {
                  "max-height": _vm.logsBodyHeight + "px",
                  "overflow-y": "auto"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "general-list" },
                  [
                    _c("div", { staticClass: "row show-first-row" }, [
                      _c("div", { staticClass: "col text-muted" }, [
                        _vm._v("Message")
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.callLogs, function(log, index) {
                      return _c("div", { key: index, staticClass: "row" }, [
                        _c("div", { staticClass: "col text-muted" }, [
                          _vm._v(_vm._s(log))
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("not-found", {
                      attrs: {
                        msg: "You have not made a call yet",
                        items: _vm.callLogs
                      }
                    })
                  ],
                  2
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }