<template>
    <div class="no-items-found"
         :class="{
            'row': rowable
         }"
           no-gutters
           v-if="!items.length"
    >
        <div class="no-items-found-message"
               :class="[
                   `text-${textAlign}`,
                   `text-${textColor}`,
                   {
                        'col': rowable
                     }
               ]"
        >
            {{ msg }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "items-not-found",
        props: {
            items: {
                type: Array,
                required: true
            },
            msg: {
                type: String,
                required: true
            },
            textAlign: {
                type: String,
                default: "center"
            },
            textColor: {
                type: String,
                default: 'black'
            },
            rowable: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .no-items-found {
        &-message {
            text-align: center;
            font-size: 18px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.62);
            letter-spacing: 2px;
        }
    }
</style>