export default {
    data() {
        return {
            freeGamesLoading: false,
            freeGames: [],
            freeGamesTab: true,
            selectedFreeGame: {}
        };
    },
    methods: {
        async getFreeGames() {
            this.freeGamesLoading = true;

            try {
                const response = await window.axios.get(`/player/${this.id}/free-games/play`);

                this.freeGames = response.data;

                this.freeGamesLoading = false;
            } catch (error) {
                window.flashError(error);

                this.freeGamesLoading = false;
            }
        },
        selectFreeGame (game) {
            this.selectedFreeGame = game;
        }
    }
}