<template>
    <div class="card-header card-header-atbl">
        <div class="player-play-header">
            <div class="mb-1 d-flex align-items-center justify-content-center justify-content-xl-between">
                <div class="d-flex align-items-center">
                    <img :src="$imageService.gameImage(config.image)" class="game-thumb mx-2" :alt="config.name">
                    <h5 class="text-muted m-0">{{ config.name }}</h5>
                </div>
                <button class="btn btn-sm btn-link" @click="closeGame">
                    <i class="fa fa-times" aria-hidden="false"></i>
                </button>
            </div>

            <div class="player-play-header-description">
                <p class="text-muted">
                    <strong>Next Draw Closes: {{ this.config.config.cutOffTime | dateFormat('ddd DD MMM [@] H:mm', false) }}</strong>
                    <strong>Est. Jackpot:</strong> <span>{{ config.config.jackpot || 'N/A'}}</span>
                </p>

                <p class="text-muted">
                    Play {{ config.name }} 5 numbers from 0 to 9 and fractions from 1-10
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'raffle-game-header',
    props: ['config'],
    data() {
        return {};
    },
    methods: {
        closeGame() {
            this.$store.commit('cart/setShowGame', false);
            this.$store.commit('cart/setCheckout', {
                checkout: false,
                total: 0,
            });
        }
    }
}
</script>