var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid free-games-settings" },
    [
      _c("div", { staticClass: "card card-list" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(1),
              _vm._v(" "),
              !_vm.isLoading && !!_vm.users.length
                ? _vm._l(_vm.users, function(item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-lg text-center text-lg-left mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("ID")
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.id) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("User")
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.name) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Range Type")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class: ["badge", "badge-" + _vm.rangeType(item)]
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    !!item.free_games_settings &&
                                      item.free_games_settings.range_type
                                      ? _vm.ucFirst(
                                          item.free_games_settings.range_type
                                        )
                                      : "/"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Used Tickets")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class:
                                "badge badge-" +
                                (_vm.hasUsedAllTickets(item)
                                  ? "warning"
                                  : _vm.rangeType(item))
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.used_tickets) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Tickets")
                          ]),
                          _vm._v(" "),
                          !!item.free_games_settings
                            ? _c("span", [
                                !!_vm.has(item, "tickets")
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          class:
                                            "badge badge-" +
                                            (_vm.hasUsedAllTickets(item)
                                              ? "warning"
                                              : _vm.rangeType(item))
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                item.free_games_settings.tickets
                                              ) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                    ∞\n                                "
                                      )
                                    ])
                              ])
                            : _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content:
                                          "Cannot create free games for any player."
                                      },
                                      expression:
                                        "{\n                                    content: 'Cannot create free games for any player.'\n                                  }"
                                    }
                                  ],
                                  staticClass: "text-danger"
                                },
                                [_c("i", { staticClass: "fa fa-ban" })]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Lines")
                          ]),
                          _vm._v(" "),
                          !!item.free_games_settings
                            ? _c("span", [
                                !!_vm.has(item, "lines")
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            item.free_games_settings.lines
                                          ) +
                                          "\n                                "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                    ∞\n                                "
                                      )
                                    ])
                              ])
                            : _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content:
                                          "Cannot create free games for any player."
                                      },
                                      expression:
                                        "{\n                                    content: 'Cannot create free games for any player.'\n                                  }"
                                    }
                                  ],
                                  staticClass: "text-danger"
                                },
                                [_c("i", { staticClass: "fa fa-ban" })]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Fractions")
                          ]),
                          _vm._v(" "),
                          !!item.free_games_settings
                            ? _c("span", [
                                !!_vm.has(item, "fractions")
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            item.free_games_settings.fractions
                                          ) +
                                          "\n                                "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                    ∞\n                                "
                                      )
                                    ])
                              ])
                            : _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content:
                                          "Cannot create free games for any player."
                                      },
                                      expression:
                                        "{\n                                    content: 'Cannot create free games for any player.'\n                                  }"
                                    }
                                  ],
                                  staticClass: "text-danger"
                                },
                                [_c("i", { staticClass: "fa fa-ban" })]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-lg text-center mb-2 mb-lg-0"
                        },
                        [
                          _c("span", { staticClass: "small-mb-badge m-0" }, [
                            _vm._v("Shares")
                          ]),
                          _vm._v(" "),
                          !!item.free_games_settings
                            ? _c("span", [
                                !!_vm.has(item, "shares")
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            item.free_games_settings.shares
                                          ) +
                                          "\n                                "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                    ∞\n                                "
                                      )
                                    ])
                              ])
                            : _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content:
                                          "Cannot create free games for any player."
                                      },
                                      expression:
                                        "{\n                                    content: 'Cannot create free games for any player.'\n                                  }"
                                    }
                                  ],
                                  staticClass: "text-danger"
                                },
                                [_c("i", { staticClass: "fa fa-ban" })]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg text-center" }, [
                        _c("span", { staticClass: "small-mb-badge m-0" }, [
                          _vm._v("Operations")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center"
                          },
                          [
                            _vm.$can("Users - Free Games Settings Create/Edit")
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-link btn-delete btn-sm mr-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.onEditFreeGameSetting(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa",
                                      class: {
                                        "fa-pencil-alt":
                                          item.free_games_settings,
                                        "fa-plus": !item.free_games_settings
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$can("Users - Free Games Settings Delete") &&
                            !!item.free_games_settings
                              ? _c("confirmable-remove-button", {
                                  attrs: { value: item.id },
                                  on: {
                                    confirmed: function($event) {
                                      return _vm.removeFreeGameSetting(
                                        item.free_games_settings.id
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ])
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("not-found", {
                    attrs: { msg: "No users found.", items: _vm.users }
                  })
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("create-update-free-game-setting-form", {
        attrs: {
          show: _vm.createUpdateFreeGameSettingsForm,
          model: _vm.createUpdateFreeGameSettingsModel,
          errors: _vm.createUpdateFreeGameSettingsModelErrors,
          loader: _vm.createUpdateFreeGameSettingsLoader,
          "can-save": _vm.canSave
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center justify-content-between"
      },
      [
        _c("div", [
          _c("i", { staticClass: "fa fa-gamepad mr-1" }),
          _vm._v(" Free Games Settings\n            ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg text-center text-lg-left" }, [
        _vm._v("ID")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [
        _vm._v("Range Type")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [
        _vm._v("Used Tickets")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [
        _vm._v("Tickets")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [
        _vm._v("Lines")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [
        _vm._v("Fractions")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [
        _vm._v("Shares")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg text-center" }, [
        _vm._v("Operations")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }