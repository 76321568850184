<template>
    <div class="card presenter-agents">
        <div class="card-header bg-light box-shadow d-flex align-items-center justify-content-between section-header">
            <b>Agents Sales</b>
            <div class="header-button-holder">
                <slot></slot>
            </div>
        </div>

        <div class="card-body">
            <div class="presenter-agents-content">
                <div class="row">
                    <template v-if="!isLoading" v-for="(agent, index) in agents">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="card">
                                <div class="card-header d-flex align-items-center justify-content-between winner" :class="winnerClass(agent, index)">
                                    <span>{{ index + 1 }}</span>
                                    <b class="name">{{ agent.name }}</b>
                                    <div class="medal" v-if="index < 3 && agent.total > 0">
                                        <i class="fa fa-trophy" aria-hidden="false"></i>
                                    </div>
                                    <span v-else>&nbsp;</span>
                                </div>

                                <div class="card-body p-2">
                                    <div class="body-content d-flex justify-content-center align-items-center">
                                        <div class="currency">
                                            <span class="currency-symbol"><i class="fa fa-pound-sign" aria-hidden="false"></i></span> {{ total(agent.total) }}
                                        </div>
                                        <div class="percents">
                                            {{ percents(agent.total) }}
                                            <span class="percent-symbol"><i class="fa fa-percent" aria-hidden="false"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <loader class="mt-4"
                        :show="isLoading"
                        type="normal"
                />
            </div>
        </div>

        <div class="card-footer bg-light box-shadow">
            <div class="presenter-agents-content-row last-row" v-if="agents.length">
                <div class="total-title">
                    <span class="small-mb-badge show">Agents</span>
                    {{ agents.length }}
                </div>
                <div>
                    <span class="small-mb-badge show">Number of Sales</span>
                    {{ sales.countAll }}
                </div>
                <div>
                    <span class="small-mb-badge show">Total Sales</span>
                    {{ total(sales.totalSales) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'agents-list',
    props: {
        agents: {
            type: Array,
            require: true
        },
        sales: {
            type: Object,
            require: true
        },
        sort: {
            require: true
        },
        isLoading: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {
            sortBy: {
                sort: 'total',
                value: 'DESC'
            },
            grandTotal: 0
        };
    },
    methods: {
        winnerClass: function (agent, index) {
            let classes = [
                'd-flex',
                'align-items-center',
                'justify-content-between'
            ];

            if (this.total(agent.total) > 0) {
                classes = [
                    ...classes,
                    `winner-${index + 1}`
                ];
            }

            return classes;
        },
        total: function (total) {
            return total ? total : 0;
        },
        percents: function (total) {
            let t = total ? total : 0;
            return t > 0 ? this.percent(t / this.sales.totalSales * 100) : 0;
        },
        sortChange: function (e) {
            e.preventDefault();
            this.sortBy.sort = e.target.getAttribute('sort');
            if (!this.sortBy) {
                this.sortBy.value = 'ASC';
            } else {
                this.sortBy.value = this.sortBy.value === 'ASC' ? 'DESC' : 'ASC';
            }
        },
        clear: function (e) {
            e.preventDefault();
            this.sortBy.sort = 'total';
            this.sortBy.value = 'DESC';
        },
        doSort: function () {
            this.$emit('sorted', this.sortBy);
        },
        openAgent(e, agent) {
            e.preventDefault();
            this.$emit('open-agent', agent);
        }
    },
    watch: {
        sort: function (value) {
            this.sortBy = value;
        },
        sortBy: {
            handler() {
                this.doSort();
            },
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../../../sass/mixins/sales-list";

.presenter-agents {
    margin : 0;
    height : 100vh;

    .box-shadow {
        box-shadow : 0 0 6px rgba(0, 0, 0, .3);
    }

    .header-button-holder {
        .logout-button {
            color      : #000000;
            opacity    : 0;
            transition : all .6s;
        }

        &:hover {
            .logout-button {
                opacity : 1;
            }
        }
    }

    .card-header {
        font-size       : 28px;
        display         : flex;
        justify-content : space-between;
        width           : 100%;

        &.section-header {
            font-size : 22px;
        }

        .medal {
            font-size : 30px;
        }

        &.winner-1 {
            color           : rgba(0, 0, 0, 0.72);
            text-decoration : none;
            font-weight     : bold;
            text-shadow     : 1px 1px 0 #ffffff;
            box-shadow      : 2px 2px 0.5em rgba(122, 98, 0, 0.55),
            inset 1px 1px 0 rgba(255, 255, 255, 0.9),
            inset -1px -1px 0 rgba(0, 0, 0, 0.34);
            border          : 1px solid #deca73;
            background      : -moz-linear-gradient(
                    -72deg,
                    #ffde45,
                    #ffffff 16%,
                    #ffde45 21%,
                    #ffffff 24%,
                    #452100 27%,
                    #ffde45 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #ffde45 72%,
                    #ffffff 80%,
                    #ffde45 84%,
                    #452100
            );
            background      : -webkit-linear-gradient(
                    -72deg,
                    #ffde45,
                    #ffffff 16%,
                    #ffde45 21%,
                    #ffffff 24%,
                    #452100 27%,
                    #ffde45 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #ffde45 72%,
                    #ffffff 80%,
                    #ffde45 84%,
                    #452100
            );
            background      : -o-linear-gradient(
                    -72deg,
                    #ffde45,
                    #ffffff 16%,
                    #ffde45 21%,
                    #ffffff 24%,
                    #452100 27%,
                    #ffde45 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #ffde45 72%,
                    #ffffff 80%,
                    #ffde45 84%,
                    #452100
            );
            background      : linear-gradient(
                    -72deg,
                    #ffde45,
                    #ffffff 16%,
                    #ffde45 21%,
                    #ffffff 24%,
                    #452100 27%,
                    #ffde45 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #ffde45 72%,
                    #ffffff 80%,
                    #ffde45 84%,
                    #452100
            );
        }

        &.winner-2 {
            color           : rgba(0, 0, 0, 0.5);
            text-decoration : none;
            font-weight     : bold;
            text-shadow     : 1px 1px 0 #ffffff;
            box-shadow      : 2px 2px 0.5em rgba(122, 122, 122, 0.55),
            inset 1px 1px 0 rgba(255, 255, 255, 0.9),
            inset -1px -1px 0 rgba(0, 0, 0, 0.34);
            border          : 1px solid #dedede;
            background      : -moz-linear-gradient(
                    -72deg,
                    #dedede,
                    #ffffff 16%,
                    #dedede 21%,
                    #ffffff 24%,
                    #454545 27%,
                    #dedede 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #dedede 72%,
                    #ffffff 80%,
                    #dedede 84%,
                    #a1a1a1
            );
            background      : -webkit-linear-gradient(
                    -72deg,
                    #dedede,
                    #ffffff 16%,
                    #dedede 21%,
                    #ffffff 24%,
                    #454545 27%,
                    #dedede 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #dedede 72%,
                    #ffffff 80%,
                    #dedede 84%,
                    #a1a1a1
            );
            background      : -o-linear-gradient(
                    -72deg,
                    #dedede,
                    #ffffff 16%,
                    #dedede 21%,
                    #ffffff 24%,
                    #454545 27%,
                    #dedede 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #dedede 72%,
                    #ffffff 80%,
                    #dedede 84%,
                    #a1a1a1
            );
            background      : linear-gradient(
                    -72deg,
                    #dedede,
                    #ffffff 16%,
                    #dedede 21%,
                    #ffffff 24%,
                    #454545 27%,
                    #dedede 36%,
                    #ffffff 45%,
                    #ffffff 60%,
                    #dedede 72%,
                    #ffffff 80%,
                    #dedede 84%,
                    #a1a1a1
            );
        }

        &.winner-3 {
            color           : rgba(45, 00, 0, 0.55);
            text-decoration : none;
            font-weight     : bold;
            text-shadow     : 1px 1px 0 #dea173;
            box-shadow      : 2px 2px 0.5em rgba(122, 55, 34, 0.55),
            inset 1px 1px 0 rgba(255, 255, 255, 0.9),
            inset -1px -1px 0 rgba(0, 0, 0, 0.5);
            border          : 1px solid #dea173;
            background      : -moz-linear-gradient(
                    -72deg,
                    #ca7345,
                    #ffdeca 16%,
                    #ca7345 21%,
                    #ffdeca 24%,
                    #a14521 27%,
                    #ca7345 36%,
                    #ffdeca 45%,
                    #ffdeca 60%,
                    #ca7345 72%,
                    #ffdeca 80%,
                    #ca7345 84%,
                    #732100
            );
            background      : -webkit-linear-gradient(
                    -72deg,
                    #ca7345,
                    #ffdeca 16%,
                    #ca7345 21%,
                    #ffdeca 24%,
                    #a14521 27%,
                    #ca7345 36%,
                    #ffdeca 45%,
                    #ffdeca 60%,
                    #ca7345 72%,
                    #ffdeca 80%,
                    #ca7345 84%,
                    #732100
            );
            background      : -o-linear-gradient(
                    -72deg,
                    #ca7345,
                    #ffdeca 16%,
                    #ca7345 21%,
                    #ffdeca 24%,
                    #a14521 27%,
                    #ca7345 36%,
                    #ffdeca 45%,
                    #ffdeca 60%,
                    #ca7345 72%,
                    #ffdeca 80%,
                    #ca7345 84%,
                    #732100
            );
            background      : linear-gradient(
                    -72deg,
                    #ca7345,
                    #ffdeca 16%,
                    #ca7345 21%,
                    #ffdeca 24%,
                    #a14521 27%,
                    #ca7345 36%,
                    #ffdeca 45%,
                    #ffdeca 60%,
                    #ca7345 72%,
                    #ffdeca 80%,
                    #ca7345 84%,
                    #732100
            );
        }
    }

    .card-body {
        height  : calc(100% - 198px);
        padding : 0.25rem;
        width   : 100%;

        @media(min-width : 992px) {
            height : calc(100% - 116px);
        }
    }

    .card-footer {
        padding   : 0;
        font-size : 22px;
    }

    &-content {
        height     : 100%;
        overflow-y : auto;
        padding    : 15px;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow : inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius      : 10px;
            background-color   : #f5f5f5;
        }

        &::-webkit-scrollbar {
            width            : 2px;
            background-color : #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius      : 10px;
            -webkit-box-shadow : inset 0 0 6px rgba(0, 0, 0, .3);
            background-color   : #2d2d2d;
        }

        .body-content {
            font-size : 26px;

            .percents,
            .currency {
                text-align      : center;
                position        : relative;
                display         : flex;
                width           : 50%;
                justify-content : center;
                align-items     : center;
            }

            .currency {
                &:after {
                    content          : '';
                    width            : 2px;
                    height           : 100%;
                    background-color : #f0f3f5;
                    position         : absolute;
                    right            : 0;
                }
            }

            .currency-symbol,
            .percent-symbol {
                font-size : 32px;
                color     : #c1c1c1;
            }

            .currency-symbol {
                margin-right : 10px;
            }

            .percent-symbol {
                margin-left : 10px;
            }
        }

        &-row {
            display         : flex;
            padding         : 10px 1.25rem;
            flex-wrap       : wrap;
            justify-content : flex-end;
            align-items     : center;

            @media(min-width : 992px) {
                flex-wrap : unset;
            }

            &.last-row {
                font-weight : bold;

                div {
                    width         : 100%;
                    padding       : 0 15px;
                    text-align    : center;
                    border-bottom : 1px solid #e0e0e0;
                    border-right  : none;

                    @media(min-width : 992px) {
                        width         : unset;
                        border-right  : 1px solid #e0e0e0;
                        border-bottom : none;
                    }

                    &:last-of-type {
                        margin        : 0;
                        border-right  : none;
                        padding       : 0 0 0 15px;
                        border-right  : none;
                        border-bottom : none;
                    }
                }

                span {
                    color : #949494;
                }
            }
        }
    }
}
</style>