var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card leads-section card-list" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "general-list" },
        [
          _vm._m(1),
          _vm._v(" "),
          !_vm.isLoading
            ? _vm._l(_vm.statuses, function(item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c("div", { staticClass: "col-6 text-muted" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(item.status_name) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 text-muted text-center" }, [
                    _c("small", [
                      _vm._v(_vm._s(_vm.utcToLocal(item.created_at)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 c-flex align-items-center c-light" },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Agent"
                              },
                              expression:
                                "{\n                                 content: 'Agent'\n                              }"
                            }
                          ],
                          staticClass: "badge badge-success"
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.user_name) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "mx-2" }, [
                        _vm._v(
                          "\n                             /\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: "Campaign"
                              },
                              expression:
                                "{\n                                  content: 'Campaign'\n                              }"
                            }
                          ],
                          staticClass: "badge badge-primary"
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.campaign_name) +
                              "\n                        "
                          )
                        ]
                      )
                    ]
                  )
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("items-not-found", {
                attrs: { items: _vm.statuses, msg: "No status found." }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("loader", {
            staticClass: "mt-3",
            attrs: { show: _vm.isLoading, type: "small" }
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" Last Dispositions\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 text-muted" }, [_vm._v("Status")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 text-muted text-center" }, [
        _vm._v("Date")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }