var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configuration-wrapper" },
    _vm._l(_vm.configurationTemp, function(numberTypes, index) {
      return _c(
        "div",
        { key: index, staticClass: "configuration" },
        [
          _c("raffle-editor-item", {
            attrs: {
              index: index,
              "number-types": numberTypes,
              "configuration-temp": _vm.configurationTemp
            },
            on: {
              update: _vm.update,
              "remove-configuration": _vm.onRemoveConfigurationEvent,
              "update-configuration": _vm.onUpdateConfigurationEvent,
              "update-winner-prize-configuration": _vm.onPrizeWinnerUpdateEvent,
              "remove-field-configuration": _vm.onDeleteFieldEvent
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }