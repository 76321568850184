var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "card card-list" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _vm._m(1),
            _vm._v(" "),
            !_vm.isLoading
              ? _vm._l(_vm.reports, function(item, index) {
                  return _c("div", { key: index, staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-lg-2 text-center text-lg-left"
                      },
                      [
                        _c("span", { staticClass: "text-primary" }, [
                          _vm._v("#" + _vm._s(item.id))
                        ]),
                        _vm._v(
                          " " + _vm._s(item.name) + "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg text-center text-lg-left" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Today")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center justify-content-lg-left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-success"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.today_deposits) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-danger"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.today - item.today_deposits) +
                                    "\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg text-center text-lg-left" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Current Week")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center justify-content-lg-left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-success"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.current_week_deposits) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-danger"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.current_week -
                                        item.current_week_deposits
                                    ) +
                                    "\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg text-center text-lg-left" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Current Month")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center justify-content-lg-left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-success"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.current_month_deposits) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-danger"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.current_month -
                                        item.current_month_deposits
                                    ) +
                                    "\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg text-center text-lg-left" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Last 3 Months")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center justify-content-lg-left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-success"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.last_three_months_deposits) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-danger"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.last_three_months -
                                        item.last_three_months_deposits
                                    ) +
                                    "\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg text-center text-lg-left" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Last 6 Months")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center justify-content-lg-left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-success"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.last_six_months_deposits) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-danger"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.last_six_months -
                                        item.last_six_months_deposits
                                    ) +
                                    "\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg text-center text-lg-left" },
                      [
                        _c("span", { staticClass: "small-mb-badge" }, [
                          _vm._v("Last Year")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center justify-content-lg-left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-success"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.last_year_deposits) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-grow-0 flex-lg-grow-1 text-center text-lg-left mr-3 mr-lg-0"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-wallet text-danger"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.last_year - item.last_year_deposits
                                    ) +
                                    "\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("not-found", {
                  attrs: { msg: "No reports data found", items: _vm.reports }
                })
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("i", { staticClass: "fa fa-users" }),
      _vm._v(" Players\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-2" }, [
        _vm._v("\n                        Agent\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg col-6 col-lg" }, [
        _vm._v("\n                        Today\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg" }, [
        _vm._v("\n                        Current Week\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg" }, [
        _vm._v("\n                        Current Month\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg" }, [
        _vm._v("\n                        Last 3 Months\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg" }, [
        _vm._v("\n                        Last 6 Months\n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg" }, [
        _vm._v("\n                        Last Year\n                    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }