<template>
    <div class="container-fluid manage-account">
        <div v-if="!getCurrentUserLoader"
             class="manage-account-profile"
        >
            <span>
                {{ getUserShortName }}
            </span>
            <div>
                <h4>{{ getCurrentUser.name }}</h4>
                <p>{{ getCurrentUser.email }}</p>
            </div>
        </div>
        <div v-if="$can('User - Can Update Own Password') && !getCurrentUserLoader"
              class="card card-list manage-account-password"
        >
            <div class="card-header">
                <i class="fa fa-key mr-2"></i> Change password
            </div>
            <div class="card-body">
                <form @submit.prevent="onPasswordChangeEvent"
                      :class="{
                        disabled: !!passwordChangeLoader
                      }"
                >
                    <atbl-input :value="passwordModel.current_password"
                                :errors="passwordModelErrors"
                                label="Old password"
                                type="password"
                                name="current_password"
                                @update="onChangePasswordInput"
                    />
                    <atbl-input :value="passwordModel.password"
                                :errors="passwordModelErrors"
                                label="New Password"
                                type="password"
                                name="password"
                                @update="onChangePasswordInput"
                    />
                    <atbl-input :value="passwordModel.password_confirmation"
                                :errors="passwordModelErrors"
                                label="Confirm Password"
                                type="password"
                                name="password_confirmation"
                                @update="onChangePasswordInput"
                    />
                    <button type="submit">
                        <span v-if="!passwordChangeLoader">Save</span>
                        <loader :show="!!passwordChangeLoader"
                                type="smaller"
                        />
                    </button>
                </form>
            </div>
        </div>
        <loader :show="!!getCurrentUserLoader"
                type="small"
        />
    </div>
</template>

<script>
import TypesConst from '../../store/TypesConst';
import NamespaceConst from '../../store/NamespaceConst';
import { createNamespacedHelpers } from 'vuex';
import { clone } from '../../utils/Json/Json';

const {
    mapGetters: mapUsersGetters,
    mapActions: mapUsersActions
} = createNamespacedHelpers(NamespaceConst.users);

const passwordModel = {
    current_password: null,
    password: null,
    password_confirmation: null,
};

export default {
    name: 'ManageAccount',
    data() {
        return {
            passwordModel: clone(passwordModel),
            passwordModelErrors: {},
            passwordChangeLoader: false,
        };
    },
    computed: {
        ...mapUsersGetters({
            getCurrentUser: TypesConst.users.getters.GET_CURRENT_USER,
            getCurrentUserLoader: TypesConst.users.getters.GET_CURRENT_USER_LOADER
        }),
        getUserShortName() {
            if (!this.getCurrentUser.name) {
                return 'na';
            }

            const name = this.getCurrentUser.name.split(' ');

            return name.length > 1
                ? `${name[0].substr(0, 1)}${name[1].substr(0, 1)}`
                : name[0].substr(0, 1);
        },
    },
    methods: {
        ...mapUsersActions({
            getCurrentUserAction: TypesConst.users.actions.USERS_GET_CURRENT_USER_ACTION
        }),
        async onPasswordChangeEvent() {
            this.passwordChangeLoader = true;
            this.passwordModelErrors = {};

            if (!this.$can('User - Can Update Own Password')) {
                window.showMessage('Unauthorised action.');

                return;
            }

            try {
                const response = await window.axios.post('/users/manage-account/change-password', this.passwordModel);
                const { message, status } = response.data;

                window.showMessage(message, status);

                if (status) {
                    this.passwordModel = clone(passwordModel);
                }

                this.passwordChangeLoader = false;
            } catch (error) {
                this.passwordChangeLoader = false;

                this.passwordModelErrors = this.isset(error.response.data, 'errors') ? error.response.data.errors : {};

                window.flashError(error);
            }
        },
        onChangePasswordInput(name, value) {
            Object.assign(this.passwordModel, {
                [name]: value
            });
        },
    },
    created() {
        this.getCurrentUserAction();
    }
}
</script>