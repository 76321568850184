var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Shifts - Create/Update")
    ? _c(
        "atbl-modal",
        {
          attrs: {
            show: _vm.show,
            "close-on-backdrop": false,
            "no-close-on-esc": false,
            title: "Create/Update Shift"
          },
          on: { close: _vm.toggleCreateUpdateModal },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    !_vm.isLoading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger",
                            on: { click: _vm.toggleCreateUpdateModal }
                          },
                          [_vm._v("\n            Cancel\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-success",
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.createUpdateShift }
                      },
                      [
                        !_vm.isLoading
                          ? _c("span", [_vm._v("Save")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("loader", {
                          attrs: { show: _vm.isLoading, type: "smaller" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3978572143
          )
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-input", {
                  attrs: {
                    value: _vm.model.name,
                    errors: _vm.modelErrors,
                    disabled: _vm.isLoading,
                    label: "Enter your name",
                    name: "name"
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("atbl-textarea", {
                  attrs: {
                    value: _vm.model.description,
                    disabled: _vm.isLoading,
                    label: "Enter your description",
                    name: "description"
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("atbl-input", {
                  attrs: {
                    value: _vm.model.start,
                    errors: _vm.modelErrors,
                    disabled: _vm.isLoading,
                    label: "Enter start",
                    name: "start",
                    type: "time"
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("atbl-input", {
                  attrs: {
                    value: _vm.model.finish,
                    errors: _vm.modelErrors,
                    disabled: _vm.isLoading,
                    label: "Enter finish",
                    name: "finish",
                    type: "time"
                  },
                  on: { update: _vm.updateModelField }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }