import isString from '../String/IsString';

/**
 * Check if value is boolean
 *
 * @param value
 * @return {boolean}
 */
export function isBoolean(value) {
    return typeof value === 'boolean';
}

/**
 * Make boolean from string
 *
 * @param value
 * @return {boolean}
 */
export function booleanFromString(value) {
    if (!isString(value)) {
        return false;
    }

    return value.toLowerCase() === 'true';
}