var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-sidebar-nav",
    [
      _vm.$can("Player - View List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Players",
              fontIcon: "fa fa-user-friends",
              href: "/players",
              addLinkClasses: _vm.isRouteActive("players.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Callback - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Callback",
              fontIcon: "fa fa-phone",
              href: "/callback",
              addLinkClasses: _vm.isRouteActive("callback.players"),
              badge: _vm.getCallbacks
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Orders - View Orders List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Orders",
              fontIcon: "fa fa-file",
              href: "/orders",
              addLinkClasses: _vm.isRouteActive("orders.list.view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Tickets - View Tickets List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Tickets",
              fontIcon: "fa fa-ticket",
              href: "/tickets",
              addLinkClasses: _vm.isRouteActive("tickets.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Tickets - View Tickets List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Raffles",
              fontIcon: "fa fa-ticket",
              href: "/raffle-tickets",
              addLinkClasses: _vm.isRouteActive("raffle-tickets.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Tickets - View Tickets List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Syndicate shares",
              fontIcon: "fa fa-ticket",
              href: "/syndicate-shares",
              addLinkClasses: _vm.isRouteActive("syndicate-shares.index-view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Tickets - View Tickets List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Bundle tickets",
              fontIcon: "fa fa-ticket",
              href: "/bundle-tickets",
              addLinkClasses: _vm.isRouteActive("bundle-tickets.index-view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Transactions - View Transactions List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Transactions",
              fontIcon: "fa fa-exchange-alt",
              href: "/transactions",
              addLinkClasses: _vm.isRouteActive("transactions.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("CreateNew"),
      _vm._v(" "),
      _c("Reports", { attrs: { user: _vm.user } }),
      _vm._v(" "),
      _c("Marketing"),
      _vm._v(" "),
      _vm.$can("Call History - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Call History",
              fontIcon: "fa fa-phone",
              href:
                "/call/history/view" +
                (_vm.getIsOnlyAgent ? "/" + _vm.user : ""),
              addLinkClasses: _vm.isRouteActive(["calls.history"])
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("KYC - Manage KYC") || _vm.$can("KYC - View KYC")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "KYC Submissions",
              fontIcon: "fa fa-exchange-alt",
              href: "/kyc-submissions/list",
              addLinkClasses: _vm.isRouteActive("kyc-submissions.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Syndicates - View List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Syndicates",
              fontIcon: "fa fa-ticket",
              href: "/syndicates/list",
              addLinkClasses: _vm.isRouteActive("syndicates.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Bundles - View List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Bundles",
              fontIcon: "fa fa-ticket",
              href: "/bundles/list",
              addLinkClasses: _vm.isRouteActive("bundles.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Discounts - View List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Discounts",
              fontIcon: "fa fa-percent",
              href: "/discounts/list",
              addLinkClasses: _vm.isRouteActive("discounts.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Bonuses - View List")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Bonuses",
              fontIcon: "fa fa-tag",
              href: "/bonuses/list",
              addLinkClasses: _vm.isRouteActive("bonus.index-page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Player Bonuses - Overview")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Player bonuses",
              fontIcon: "fa fa-tag",
              href: "/player-bonuses",
              addLinkClasses: _vm.isRouteActive("player-bonuses.index-view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("AdministratorNavTitle"),
      _vm._v(" "),
      _c("Games"),
      _vm._v(" "),
      _vm.$can("Withdrawals - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Withdrawals",
              fontIcon: "fa fa-credit-card",
              href: "/transaction/get/withdrawals",
              addLinkClasses: _vm.isRouteActive("withdrawals-view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Conversions Rate - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Conversions Rates",
              fontIcon: "fa fa-pound-sign",
              href: "/rates/conversions",
              addLinkClasses: _vm.isRouteActive("rates-conversions")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Payment - Manage settings")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Payments settings",
              fontIcon: "fa fa-pound-sign",
              href: "/settings/payments",
              addLinkClasses: _vm.isRouteActive("payments-settings")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Email Templates - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Email Templates",
              fontIcon: "fa fa-envelope",
              href: "/email-templates/view",
              addLinkClasses: _vm.isRouteActive("email.templates.view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Phone Numbers - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Phone Numbers",
              fontIcon: "fa fa-phone",
              href: "/phone-numbers/view",
              addLinkClasses: _vm.isRouteActive("phone-numbers.view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("My Affiliate - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "My Affiliate",
              fontIcon: "fa fa-user-friends",
              href: "/my-affiliate",
              addLinkClasses: _vm.isRouteActive("my-affiliate.view")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Users - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Users",
              fontIcon: "fa fa-user",
              href: "/users",
              addLinkClasses: _vm.isRouteActive("users.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Roles - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Roles",
              fontIcon: "fa fa-user-shield",
              href: "/roles",
              addLinkClasses: _vm.isRouteActive("roles.page")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Permissions - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Permissions",
              fontIcon: "fa fa-key",
              href: "/permissions",
              addLinkClasses: _vm.isRouteActive([
                "permissions.list.view",
                "permissions.create.view",
                "permissions.update.view"
              ])
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Logs - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Logs",
              fontIcon: "fa fa-print",
              href: "/logs",
              addLinkClasses: _vm.isRouteActive("logs.index")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("Settings - View")
        ? _c("c-sidebar-nav-item", {
            attrs: {
              name: "Settings",
              fontIcon: "fa fa-cog",
              href: "/settings",
              addLinkClasses: _vm.isRouteActive("settings.page")
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }