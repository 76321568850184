<template>
    <section class="general-list">
        <div class="row">
            <div class="col">Name</div>
            <div class="col" v-for="(month, index) in monthNames" :key="index">
                <a href="#" @click="sortChange(index + 1)" class="sort-link" :class="{'active': sortBy === index + 1}">
                    {{ month }} <span v-if="sortBy === index + 1"><i class="fa" :class="{'fa-caret-up': sortDirection === 'asc', 'fa-caret-down': sortDirection === 'desc'}"></i></span>
                </a>
            </div>
            <div class="col" v-if="$can('Reports Agents - View')">
                Actions
            </div>
        </div>

        <template>
            <div v-if="!isSearching"
                   v-for="(agent, index) in sortedAgents"
                   :key="agent.id"
                   class="row"
            >
                <div class="col-6 col-lg">
                    <span class="small-mb-badge">Name</span>
                    {{ agent.name }}
                </div>
                <div v-for="(month, index) in monthNames" :key="index" class="col-6 col-lg">
                    <span class="small-mb-badge">{{ month }}</span>
                    <span class="badge badge-light" style="width:17px;"
                        v-tooltip="{
                            content: 'Deposit',
                            placement: 'top'
                        }"
                        >D</span> {{ agent | agentDeposit(index + 1, year) | withoutFraction }}
                    <br />
                    <span class="badge badge-dark"  style="width:17px;"
                        v-tooltip="{
                            content: 'Sales',
                            placement: 'top'
                        }">
                        S
                    </span> {{ agent | agentSales(index + 1, year) | withoutFraction }}
                </div>
                <div class="col-12 col-lg" v-if="$can('Reports Agents - View')">
                    <span class="small-mb-badge">Actions</span>
                    <a href="#" @click="openAgent($event, agent)">
                        <i class="fa fa-eye"></i> View Agent
                    </a>
                </div>
            </div>
        </template>

        <not-found v-if="!isSearching"
                   :items="agents"
                   msg="No agents found."
        />

        <loader :show="isSearching"
                type="small"
        />
    </section>
</template>

<script>
import moment from 'moment';

export default {
    name: "agent-deposit-list",
    props: {
        agents: {
            type: Array,
            require: true
        },
        year: {
            type: Number,
            require: true,
        },
        isSearching: {
            type: Boolean,
            require: true
        },
    },
    data() {
        return {
            grandTotal: 0,
            monthNames: moment.monthsShort(),
            sortBy: '',
            sortDirection: 'asc',
        };
    },

    computed: {
        sortedAgents() {
            return this.agents.sort((a, b) => {
                let a1 = a.deposits.find(d => d.r_date === `${this.year}-${this.sortBy}`) || 0;
                let a2 = b.deposits.find(d => d.r_date === `${this.year}-${this.sortBy}`) || 0;
                a1 = a1 ? a1.total_deposits : 0;
                a2 = a2 ? a2.total_deposits : 0;

                if(this.sortDirection === 'asc') {
                    return a1 - a2;
                }

                return a2 - a1;
            });
        }
    },

    methods: {
        sortChange: function (sortBy) {
            if(sortBy === this.sortBy) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                return;
            }

            this.sortBy = sortBy;
            this.sortDirection = 'asc';
        },

        openAgent(e, agent) {
            e.preventDefault();
            this.$emit("open-agent", agent);
        }
    },

    filters: {
        agentSales(agent, month, year) {
            const item = agent.transactions.find(d => d.r_date === `${year}-${month}`);
            if (!item) {
                return 0;
            }

            return item.total_sales;
        },

        agentDeposit(agent, month, year) {
            const item = agent.deposits.find(d => d.r_date === `${year}-${month}`);
            if (!item) {
                return 0;
            }

            return item.total_deposits;
        }
    }
}
</script>