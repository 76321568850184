<template>
    <div class="general-list">
        <div class="row">
            <div class="col-sm-6 text-muted">Name</div>
            <div class="col-sm-3 text-muted">Follow-up</div>
            <div class="col-sm-3 text-muted">Created</div>
        </div>

        <div v-if="!isLoading" v-for="(disposition, index) in dispositions" :key="index" class="row py-3 border-top">
            <div class="col-12 col-sm-6">
                <span class="small-mb-badge">Name</span>
                {{ disposition.disposition.name }}
            </div>
            <div class="col-12 col-sm-3">
                <span class="small-mb-badge" v-if="disposition.follow_up">Follow-up</span>
                {{ disposition.follow_up }}
            </div>
            <div class="col-12 col-sm-3">
                <span class="small-mb-badge">Created</span>
                {{ utcToLocal(disposition.created_at) }}
            </div>
        </div>

        <not-found v-if="!isLoading"
                   :items="dispositions"
                   msg="No dispositions found."
        />

        <loader type="small"
                :show="isLoading"
        />
    </div>
</template>

<script>
    export default {
        name: "player-dispositions-list",
        props: {
            dispositions: {
                type: Array,
                require: true
            },
            isLoading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>