<template>
    <div class="game" :title="item.gameable.title">
        <div class="ribbon-danger"><span>Bundle {{ item.number_of_tickets > 1 ? `x ${item.number_of_tickets}` : ''}}</span></div>
        <div class="img-holder">
            <img v-for="bundleGame in item.gameable.bundle_games" :src="$imageService.gameImage(bundleGame.game.image)" class="game-thumb">
        </div>

        <div class="game-play">
            <p :title="item.gameable.title" class="d-block mb-1">{{ item.gameable.title }}</p>

            <div class="game-play-info">
                <template v-if="!!item.meta.length">
                    <span v-for="(item, key) in item.meta">
                        <b>{{ ucFirst(key) }}:</b> {{ item }}
                    </span>
                </template>
                <span v-else>&nbsp;</span>
            </div>
            <button :class="{
                      'btn-primary': !isLoading,
                      'btn-outline-primary': isLoading
                    }"
                    @click="playGame"
            >
                <span v-if="!isLoading">Play</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bundle',
    inject: [
        'clearCart',
        'selectFreeGame',
    ],
    props: {
        item: {
            type: Object,
            required: true
        },
        orderId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        playGame() {
            this.$store.commit('cart/setCheckout', { checkout: false });
            this.$store.commit('cart/setShowGame', false);

            if (this.orderId) {
                this.clearCart();
            }

            this.selectFreeGame(this.item);

            setTimeout(() => {
                this.$store.commit('cart/setShowGame', true);
                this.$store.commit('games/setGameConfigurationData', this.item.gameable);
            }, 150);
        },
    }
}
</script>