import DiscountService from "../../../../services/DiscountService";
import {clone} from '../../../../utils/Json/Json';
import getValidationErrors from '../../../../utils/Error/GetValidationErrors';
import errors from '../../../../mixins/Errors';

export default {
	provide() {
		return {
			fetchLineDiscounts: this.fetchLineDiscounts,
			addDiscountLine: this.addDiscountLine,
			removeDiscountLine: this.removeDiscountLine,
			publishLines: this.publishLines,
			editDiscountLine: this.editDiscountLine,
		};
	},
	data() {
		return {
			editLinesLoader: false,
			editingGameLinesDiscountInitial: [],
			editingGameLinesDiscount: [],
			lineDiscountActiveVersion: null,
			lineDiscountErrors: {},
		};
	},
	computed: {
		linesData() {
			return {
				discountsInitial: this.editingGameLinesDiscountInitial,
				discounts: this.editingGameLinesDiscount,
				activeVersion: this.lineDiscountActiveVersion,
				loader: this.editLinesLoader,
				errors: this.lineDiscountErrors,
			};
		},
	},
	methods: {
		async publishLines() {
			this.lineDiscountErrors = {};
			this.editLinesLoader = true;

			const discountLines = this.editingGameLinesDiscount.map(l => {
				return {
					from: l.from,
					to: l.to,
					discount: l.discount,
					discountCrm: l.discount_crm,
				};
			});

			try {
				await DiscountService.publishDiscountLines(this.editingGame.id, {
					discountLines,
				});

				await this.setUpLineDiscounts(this.editingGame);

				window.showMessage('New discount lines version was published');

				this.editLinesLoader = false;
			} catch (error) {
				this.editLinesLoader = false;

				this.lineDiscountErrors = getValidationErrors(error);
			}
		},
		async fetchLineDiscounts(game, version = null) {
			this.editLinesLoader = true;

			try {
				const response = await DiscountService.fetchGameDiscounts(game.id, 'line', version);

				this.editingGameLinesDiscount = response.data;
				this.editingGameLinesDiscountInitial = clone(response.data);

				this.editLinesLoader = false;
			} catch (error) {
				this.editLinesLoader = false;

				window.flashError(error);
			}
		},
		async setUpLineDiscounts(game) {
			await this.fetchLineDiscounts(game);

			const hasDiscountDraws = !!this.editingGameLinesDiscount.length;

			this.lineDiscountActiveVersion = hasDiscountDraws
				? this.editingGameLinesDiscount[0].version
				: 1;

			if (!!hasDiscountDraws) {
				return;
			}

			this.addDiscountLine(1, 0, 0, 0, 0);
		},
		editDiscountLine(lineId, name, value) {
			const lines = clone(this.editingGameLinesDiscount);

			const currentLine = lines.find(line => line.id === lineId);

			if (!currentLine) {
				return window.showMessage(`Line with ID:${lineId} has not been found.`);
			}

			const lineIndex = lines.indexOf(currentLine);

			lines[lineIndex][name] = Number(value);

			this.editingGameLinesDiscount = lines;
		},
		addDiscountLine(id, from, to, discount, discountCrm) {
			const ids = this.editingGameFractionsDiscount.map(item => item.id);
			const idTmp = ! id
				? Math.max(...ids)
				: id;

			this.editingGameLinesDiscount.push({
				id: idTmp + 1,
				from,
				to,
				discount,
				discount_crm: discountCrm
			});
		},
		removeDiscountLine(index) {
			this.editingGameLinesDiscount.splice(index, 1);
		},
		clearDiscountLines() {
			this.editingGameLinesDiscount = [];
			this.lineDiscountActiveVersion = null;
			this.lineDiscountErrors = {};
		},
	},
}