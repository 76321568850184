import {clone} from '../../../utils/Json/Json';
import WithdrawalSearchModel from './WithdrawalSearchModel';

export default {
	provide() {
		return {
			updateModel: this.updateModel,
			clearModel: this.clearModel,
		};
	},
	data() {
		return {
			searchModel: clone(WithdrawalSearchModel),
		};
	},
	methods: {
		updateModel(name, value) {
			Object.assign(this.searchModel, {
				[name]: value
			});
		},
		async clearModel() {
			this.searchModel = clone(WithdrawalSearchModel);

			await this.fetchWithdrawals();
		},
	},
}