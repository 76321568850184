var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-list reports reports-games atbl m-0" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "general-list" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div"),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c(
                  "a",
                  {
                    staticClass: "sort-link",
                    class: { active: _vm.sort.type === "game_name" },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.sortChange("game_name")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tGame\n                            "
                    ),
                    _vm.sort.type === "game_name"
                      ? _c("span", [
                          _c("i", {
                            staticClass: "fa",
                            class: {
                              "fa-caret-up": _vm.sort.order === "ASC",
                              "fa-caret-down": _vm.sort.order === "DESC"
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c(
                  "a",
                  {
                    staticClass: "sort-link",
                    class: { active: _vm.sort.type === "total_tickets" },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.sortChange("total_tickets")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tTickets\n                            "
                    ),
                    _vm.sort.type === "total_tickets"
                      ? _c("span", [
                          _c("i", {
                            staticClass: "fa",
                            class: {
                              "fa-caret-up": _vm.sort.order === "ASC",
                              "fa-caret-down": _vm.sort.order === "DESC"
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c(
                  "a",
                  {
                    staticClass: "sort-link",
                    class: { active: _vm.sort.type === "total_lines" },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.sortChange("total_lines")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tLines\n                            "
                    ),
                    _vm.sort.type === "total_lines"
                      ? _c("span", [
                          _c("i", {
                            staticClass: "fa",
                            class: {
                              "fa-caret-up": _vm.sort.order === "ASC",
                              "fa-caret-down": _vm.sort.order === "DESC"
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.gameReports, function(item, index) {
              return !_vm.isLoading
                ? _c("div", { key: index, staticClass: "row py-1" }, [
                    _c("div", [
                      _c("img", {
                        staticClass: "game-thumb",
                        attrs: { src: _vm.$imageService.gameImage(item.image) }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Game")
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(item.game_name) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Tickets")
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(item.total_tickets) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-lg" }, [
                      _c("span", { staticClass: "small-mb-badge" }, [
                        _vm._v("Lines")
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(item.total_lines) +
                          "\n\t\t\t\t\t"
                      )
                    ])
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("not-found", {
                  staticClass: "w-100",
                  attrs: { msg: "No results found", items: _vm.gamesReports }
                })
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }