var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "myModal", staticClass: "modal", attrs: { id: "payonModal" } },
    [
      _c("div", { staticClass: "modal-dialog " }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "m-0" }, [_vm._v("Card payment")]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "close",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("form", {
              ref: "cardform",
              staticClass: "paymentWidgets",
              attrs: { action: _vm.redirect, "data-brands": "VISA MASTER" }
            }),
            _vm._v(" "),
            _c("script", { attrs: { type: "application/javascript" } }, [
              _vm._v(
                '\n\t\t\t\t\tvar wpwlOptions = {\n\t\t\t\t\t\tstyle: "plain",\n\t\t\t\t\t\tonReady: function () {\n\t\t\t\t\t\t\tlet createRegistrationHtml = \'<div class="customLabel">Save payment details?</div>\' +\n\t\t\t\t\t\t\t\t\'<div class="customInput">\' +\n\t\t\t\t\t\t\t\t\'<input type="checkbox" name="createRegistration" value="true" checked/>\' +\n\t\t\t\t\t\t\t\t\'</div>\',\n\t\t\t\t\t\t\t\tcreateDiv = document.createElement("div");\n\n\t\t\t\t\t\t\tcreateDiv.id = "check-box-save";\n\t\t\t\t\t\t\tcreateDiv.innerHTML = createRegistrationHtml;\n\n\t\t\t\t\t\t\tlet payOnModal = document.querySelector("#payonModal");\n\n\t\t\t\t\t\t\tif (payOnModal) {\n\t\t\t\t\t\t\t\tlet modalContent = payOnModal.querySelector(".modal-content"),\n\t\t\t\t\t\t\t\t\tspinner = modalContent.querySelector(".spiner"),\n\t\t\t\t\t\t\t\t\tform = document.querySelector("form.wpwl-form-card"),\n\t\t\t\t\t\t\t\t\twpwlButton = form.querySelector(".wpwl-group-submit");\n\n\t\t\t\t\t\t\t\tspinner.classList.add("d-none");\n\n\t\t\t\t\t\t\t\tform.insertBefore(createDiv, wpwlButton);\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t};\n\t\t\t\t'
              )
            ]),
            _vm._v(" "),
            _c("script", {
              attrs: { type: "application/javascript", src: _vm.link }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spiner" }, [
      _c("img", { attrs: { src: "/img/spiner.svg", alt: "spiner" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }