<template>
    <div class="card card-list">
        <div class="card-header card-header-atbl d-flex align-items-center">
            <a :href="`/marketing/campaigns/${campaignType}`"
               class="mr-2 btn btn-primary btn-sm m-0"
            >
                <i class="nav-icon fa fa-chevron-left" aria-hidden="false"></i>
            </a>
            <span>
                <i class="nav-icon fa fa-bullhorn" aria-hidden="false"></i> <span v-text="!!campaignId ? 'Edit' : 'Create'"></span> Campaign
            </span>
        </div>
        
        <div class="card-body pt-2">
            <div class="row">
                <div class="col-12">
                    <multi-agents-picker v-model="selectedAgent"
                                         :disabled="isLoading"
                    />
                </div>
                <div class="col-12">
                    <atbl-input :value="campaignModel.title"
                                :disabled="isLoading"
                                :errors="campaignModelErrors"
                                label="Title *"
                                name="title"
                                @update="updateCampaignModelField"
                    />
                </div>
                <div class="col-12">
                    <atbl-form-group :error="getError(campaignModelErrors, 'description')"
                                     :state="getState(campaignModelErrors, 'description')"
                                     id="campaign-description"
                                     label="Description"
                                     label-for="campaignDescription"
                                     class="font-weight-bold"
                    >
                        <textarea :value="campaignModel.description"
                                  :disabled="isLoading"
                                  id="campaignDescription"
                                  class="form-control"
                                  rows="5"
                                  required
                                  placeholder="Enter description"
                                  @input="updateCampaignModelField('description', $event.target.value)"
                        ></textarea>
                    </atbl-form-group>
                </div>
                <div v-if="$can('Leads - View')"
                     class="col-12"
                >
                    <batch-files-picker v-if="isLeadCampaign()"
                                        :value="campaignModel.batch"
                                        :disabled="isLoading"
                                        @update="updateCampaignModelField"
                    />
                    <upload-csv :value="campaignModel.csv"
                                :disabled="isLoading"
                                @update="updateCampaignModelField"
                    />
                </div>
                <div class="col-12">
                    <campaign-types-picker :value="campaignModel.type"
                                           :disabled="isLoading"
                                           @update="updateCampaignModelField"
                    />
                </div>
                <div class="col-12">
                    <campaign-view-types-picker :value="campaignModel.view_type"
                                                :disabled="isLoading"
                                                @update="updateCampaignModelField"
                    />
                </div>
                <div class="col-12">
                    <campaigns-status-picker :value="campaignModel.status"
                                             :errors="campaignModelErrors"
                                             :disabled="isLoading"
                                             @input="updateCampaignModelField('status', $event)"
                    />
                </div>
                <div class="col-12">
                    <campaign-tags-picker :value="campaignModel.tags"
                                          :errors="campaignModelErrors"
                                          :disabled="isLoading"
                                          @input="updateCampaignModelField('tags', $event)"
                                          @interface="setChildInterface"
                    />
                </div>
            </div>
        </div>
        
        <div class="card-footer d-flex align-items-center justify-content-end">
            <button :disabled="!isSaveEnabled"
                    :class="{
                        'btn-primary': !isLoading,
                        'btn-outline-primary': isLoading
                    }"
                    class="btn btn-sm"
                    @click="saveCampaign"
            >
                <span v-if="!isLoading"
                >
                    Save
                </span>
                
                <loader :show="isLoading"
                        type="small"
                />
            </button>
        </div>
    </div>
</template>

<script>
    import BatchFilesPicker from '../../../_Components/BatchFiles/BatchFilesPicker';
    import CampaignModel from './CampaignModel';
    import CampaignViewTypesPicker from '../../../_Components/CampaignViewTypesPicker/CampaignViewTypesPicker';
    import CampaignTypesPicker from '../../../_Components/CampaignTypesPicker/CampaignTypesPicker';
    import UploadCsv from '../_Components/UploadCsv';
    import CampaignTagsPicker from '../../_Components/CampaignTagsPicker.vue';
    
    export default {
        name: 'campaign-form-edit-component',
        inject: [
            'campaignType',
            'saveCampaign',
            'updateCampaignModelField',
            'isLeadCampaign',
            'setChildInterface',
        ],
        components: {
            CampaignTypesPicker,
            BatchFilesPicker,
            CampaignViewTypesPicker,
            UploadCsv,
            CampaignTagsPicker,
        },
        props: {
            campaignId: {
                type: Number,
                default: null,
            },
            isLoading: {
                type: Boolean,
                default: false
            },
            campaignModel: {
                type: Object,
                default: () => (CampaignModel)
            },
            campaignModelErrors: {
                type: Object,
                default: () => ({})
            },
        },
        computed: {
            selectedAgent: {
                get() {
                    const agents = this.campaignModel.agents;
                    
                    return !!agents && !!agents.length
                      ? this.campaignModel.agents.map(item => this.isObject(item) ? item.id : item)
                      : [];
                },
                set(value) {
                    this.updateCampaignModelField('agents', value);
                }
            },
            isSaveEnabled() {
                if (!this.campaignModel.title) {
                    return false;
                }
                
                if (!this.campaignModel.type) {
                    return false;
                }
                
                if (!this.campaignModel.view_type) {
                    return false;
                }
                
                if (!this.campaignModel.status) {
                    return false;
                }
                
                return true;
            },
        },
    };
</script>