export default {
    methods: {
        getTransactionId: function (item) {
            const transactionId = this.getTransactionValue(item, "id");
            return transactionId ? `#T-${transactionId}` : null;
        },
        getTransactionValue: function (item, key) {
            const transaction = this.getTransaction(item);

            if (!transaction) {
                return null;
            }

            return transaction[key];
        },
        getTransaction: function (item) {
            return this.isset(item, "transactions") && !!item.transactions.length ? item.transactions[0] : null
        }
    }
};