import PaginationModel from '../../../../../../modules/pagination/PaginationModel';
import { clone } from '../../../../../../utils/Json/Json';

export default {
    data() {
        return {
            leadLastCallsLoading: false,
            leadLastCallsResponse: clone(PaginationModel),
        };
    },
    methods: {
        async fetchLeadLastCalls(leadId, page = 1) {
            if (!leadId) {
                return;
            }

            this.leadLastCallsLoading = true;

            try {
                const url = this.isLeadCampaign()
                    ? `/marketing/leads/${leadId}/last-calls`
                    : `/player/${leadId}/last-calls`;

                const response = await window.axios.get(url, {
                    params: {
                        page
                    }
                });

                this.leadLastCallsResponse = response.data;

                this.leadLastCallsLoading = false;
            } catch (error) {
                window.flashError(error);

                this.leadLastCallsLoading = false;
            }
        },
    },
}