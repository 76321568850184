<template>
    <div>
        <div class="card">
            <div class="card-header card-header-atbl d-flex align-items-center justify-content-between">
                <span><i class="fa fa-user" aria-hidden="false"></i> Details</span>
                <div class="d-flex">
                    <template v-if="!!isLeadCampaign()">
                        <button v-if="$can('Leads - Create/Edit')"
                                class="btn btn-sm btn-primary m-0 mr-2"
                                @click="toggleAddLeadModal"
                        >
                            Edit
                        </button>
                    </template>
                    <a v-else
                       href="#"
                       class="btn btn-sm btn-primary mr-2"
                       @click.prevent="onViewPlayerEvent(customer.id)"
                    >
                        <span v-if="!customerInstantAccessLoader">View Player</span>
                        <loader :show="customerInstantAccessLoader"
                                type="smaller"
                        />
                    </a>
                    <button v-if="$can('Player - Create/Update') && !!showCreatePlayer"
                            class="btn btn-sm btn-success d-flex align-items-center m-0"
                            @click="toggleCreatePlayerModal"
                    >
                        <img src="/img/icons/transfer.svg"
                             width="20"
                             class="mr-1"
                        />
                        Create Player
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="row leads-section-profile" v-if="customer">
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Name</span>
                        <div class="leads-section-profile-text">{{ customer.name | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Surname</span>
                        <div class="leads-section-profile-text">{{ customer.surname | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Email</span>
                        <div class="leads-section-profile-text">{{ customer.email | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Birthdate</span>
                        <div class="leads-section-profile-text">{{ customer.birthdate | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Address1</span>
                        <div class="leads-section-profile-text">{{ customer.address1 | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Address2</span>
                        <div class="leads-section-profile-text">{{ customer.address2 | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Country</span>
                        <div class="leads-section-profile-text">{{ customer.country | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">State</span>
                        <div class="leads-section-profile-text">{{ customer.state | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">City</span>
                        <div class="leads-section-profile-text">{{ customer.city | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Postcode</span>
                        <div class="leads-section-profile-text">{{ customer.postcode | emptyField }}</div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Phone</span>
                        <div class="leads-section-profile-text">
                            {{ customer.phone | emptyField }}
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <span class="leads-section-profile-label">Mobile</span>
                        <div class="leads-section-profile-text">
                            {{ customer.mobile | emptyField }}
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 m-0">
                        <span class="leads-section-profile-label">Live Spent</span>
                        <div class="leads-section-profile-text">
	                        <span v-if="customer.live_spent && customer.live_spent > 0">
								{{ customer.live_spent | formatCurrency }}
							</span>
                            <span v-else>
								/
							</span>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row leads-section-profile">
                    <div class="col-12 m-0">
                        <span class="leads-section-profile-label">Other</span>
                        <div class="leads-section-profile-text">
                            {{ customer.other | emptyField }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h3 v-if="isWindowClosing" class="text-center font-weight-bold">Window will be closed automatically.</h3>
    </div>
</template>

<script>
import CreatePlayer from '../../../../../Players/Components/Forms/CreatePlayer/CreatePlayer';
import CustomerModel from '../CustomerToCall/CustomerModel';
import Loader from '../../../../../../components/Widgets/Loader';

export default {
    name: 'customer-details-component',
    inject: [
        'campaignId',
        'toggleAddLeadModal',
        'toggleCreatePlayerModal',
        'isLeadCampaign',
        'onViewPlayerEvent',
    ],
    components: {
        CreatePlayer,
        Loader,
    },
    props: {
        customer: {
            type: Object,
            default: () => (CustomerModel)
        },
        showCreatePlayer: {
            type: Boolean,
            default: true
        },
        customerInstantAccessLoader: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isWindowClosing: false,
        };
    },
}
</script>