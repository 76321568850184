<template>
    <div v-show='isActive'
         class='atbl-tab'
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'atbl-tab',
    props: {
        title: {
            type: String,
            default: 'Tab'
        },
        disabled: {
			type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
}
</script>