<template>
    <atbl-modal class="modal__payment-bambora" :title="title" :close-on-backdrop="true" :show="true" @close="emitCloseEvent">
        <div id="bamboraWrapper"></div>

        <template #footer-wrapper>
            <div></div>
        </template>
    </atbl-modal>
</template>

<script>
import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper';
import AtblModal from '../AtblModal/AtblModal.vue';

export default {
    name: 'payment-gateway-bambora',
    components: {
		AtblModal,
    },
    props: {
        title: {
            type: String,
            default: 'Credit card payment',
        },

        apiPaymentResponse: {
            type: Object,
            required: true,
        },

        allowSavingCreditCard: {
            type: Boolean,
            default: true,
        },

        playerId: {
            type: Number,
            required: true,
        },
    },

    mounted() {
        new _PaymentIQCashier('#bamboraWrapper', this.apiPaymentResponse.meta,
            (api) => {
                api.on({
                    success: (data) => {
                        window.location.href = `${this.apiPaymentResponse.checkout.redirect_url}&txId=${data.data.payload.txRefId}`;
                    },
                    failure: (data) => {
                        window.location.href = `${this.apiPaymentResponse.checkout.redirect_url}&txId=${data.data.txRefId}`;
                    },
                    cancelled: (data) => {
                        if (this.apiPaymentResponse.action === 'purchase') {
                            window.location.href = this.buildUrl(`/players/${this.playerId}/orders`);
                        }

                        if (this.apiPaymentResponse.action === 'add-funds') {
                            window.location.href = this.buildUrl(`/players/${this.playerId}/funds`);
                        }
                    },
                })
            },
        )
    },

    methods: {
        emitCloseEvent() {
            window._PaymentIQCashierReset();
            this.$emit('cancel');
        },
    },
};
</script>

<style lang="scss">
.modal__payment-bambora {
    .modal-body {
        padding : 0;
    }
}
</style>