<template>
    <div class="card border-0 m-0">
        <div class="card-body p-3">
            <div class="call-history-agents-badge d-flex align-items-center">
				<span v-if="!hasSelectedAgent"
                      class="text-danger font-weight-bold mr-1"
                >
					Select an agent to show daily results.
				</span>
                <span class="badge badge-primary">
                    Period: {{ range }}
                </span>
            </div>
            <div class="general-list">
                <div class="row">
                    <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">Day</div>
                    <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">Leads Time Talk</div>
                    <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">Players Time Talk</div>
                    <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">Calls</div>
                    <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">Completed Calls</div>
                    <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">Average Talk Time</div>
                </div>

                <template v-if="!isLoading">
                    <div v-for="(item, index) in calls"
                         :key="index"
                         class="row"
                    >
                        <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Day</span>
                            {{ item.day }}
                        </div>
                        <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Leads Time Talk</span>
                            {{ isset(item, 'talk_time_leads') ? item.talk_time_leads : 0 }}
                        </div>
                        <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Players Time Talk</span>
                            {{ isset(item, 'talk_time_players') ? item.talk_time_players : 0 }}
                        </div>
                        <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Calls</span>
                            {{ item.total_calls }}
                        </div>
                        <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Completed Calls</span>
                            {{ item.completed_calls }}
                        </div>
                        <div class="col-6 col-md-6 col-lg align-self-center mb-2 mb-lg-0">
                            <span class="small-mb-badge">Average Talk Time</span>
                            {{ item.average_talk_time }}
                        </div>
                    </div>
                </template>

                <loader :show="isLoading"
                        type="small"
                />

                <not-found v-if="!isLoading"
                           :items="calls"
                           msg="No call history found."
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import CallHistorySearchModel from '../CallHistoryFilterForm/CallHistorySearchModel';
import { isset } from '../../../utils/Object/Object';

export default {
    name: 'call-history-daily-total',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        calls: {
            type: Array,
            default: () => ([])
        },
        callHistorySearchModel: {
            type: Object,
            default: () => (CallHistorySearchModel)
        },
    },
    computed: {
        range() {
            const { dateFrom, dateTo } = this.callHistorySearchModel;

            if (!!dateFrom && !!dateTo) {
                return `${dateFrom} - ${dateTo}`;
            }

            return `${moment().startOf('month').format('YYYY-MM-DD')} - ${moment().endOf('month').format('YYYY-MM-DD')}`;
        },
        hasSelectedAgent() {
            return !!this.callHistorySearchModel.agents.length;
        },
    },
    methods: {
        isset,
    },
}
</script>