var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flag-select" },
    [
      _vm.placeholder
        ? _c("span", { staticClass: "atbl-placeholder font-weight-bold" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.placeholder) + "\n\t\t")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "p-2 border" },
            [_c("loader", { attrs: { type: "smaller", show: true } })],
            1
          )
        : _c("tree-select", {
            attrs: { options: _vm.phoneNumbers, searchable: true },
            scopedSlots: _vm._u([
              {
                key: "option-label",
                fn: function(ref) {
                  var node = ref.node
                  var shouldShowCount = ref.shouldShowCount
                  var count = ref.count
                  var labelClassName = ref.labelClassName
                  var countClassName = ref.countClassName
                  return _c("label", { class: labelClassName }, [
                    _c("img", {
                      staticClass: "flag-smaller",
                      attrs: { src: node.raw.flag, alt: "flag-icon" }
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(node.label))])
                  ])
                }
              },
              {
                key: "value-label",
                fn: function(ref) {
                  var node = ref.node
                  return _c("div", {}, [
                    _c("img", {
                      staticClass: "flag-smaller",
                      attrs: { src: node.raw.flag, alt: "flag-icon" }
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(node.label))])
                  ])
                }
              }
            ]),
            model: {
              value: _vm.selectedNumber,
              callback: function($$v) {
                _vm.selectedNumber = $$v
              },
              expression: "selectedNumber"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }