export default {
    createRoute(path) {
        return `/marketing${!!path ? path : null}`;
    },
    getLeadsStatuses() {
        return window.axios.get(this.createRoute('/statuses/get'));
    },
    getLeadsStatusById(id) {
        return window.axios.get(this.createRoute(`/statuses/${id}`));
    },
    createStatus(model = {}) {
        return window.axios.post(
            this.createRoute(`/statuses/create`),
            model
        );
    },
    updateStatus(id, model = {}) {
        return window.axios.patch(
            this.createRoute(`/statuses/${id}/update`),
            model
        );
    },
}