var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSaveRoleEvent.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "card card-list" },
        [
          _c(
            "div",
            {
              staticClass:
                "card-header d-flex align-items-center justify-content-between"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between"
                },
                [
                  !_vm.roleLoader
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-sm btn-danger mr-2",
                          attrs: { href: "/roles" }
                        },
                        [
                          _vm._v(
                            "\n                    Cancel\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("Roles - Create/Update")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-primary",
                          attrs: {
                            disabled: !_vm.isModelDirty || !!_vm.roleLoader
                          }
                        },
                        [_vm._v("\n                    Save\n                ")]
                      )
                    : _vm._e()
                ]
              )
            ]
          ),
          _vm._v(" "),
          !_vm.roleLoader
            ? _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("atbl-input", {
                    staticClass: "mt-2",
                    attrs: {
                      errors: _vm.roleErrors,
                      label: "Enter name",
                      name: "name"
                    },
                    model: {
                      value: _vm.changeRoleName,
                      callback: function($$v) {
                        _vm.changeRoleName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "changeRoleName"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "permissions-header" }, [
                    _c("h6", { staticClass: "font-weight-bold m-0" }, [
                      _vm._v("Permissions")
                    ]),
                    _vm._v(" "),
                    _vm.hasError(_vm.roleErrors, "permissions")
                      ? _c(
                          "small",
                          { staticClass: "ml-2 text-danger font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getError(_vm.roleErrors, "permissions")
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "permissions-operations" },
                    [
                      _c("atbl-check-box", {
                        staticClass: "mr-1",
                        attrs: {
                          value: _vm.isAllSelected,
                          "partial-check": _vm.isPartialSelected,
                          name: "permissions",
                          label: "Select All"
                        },
                        on: { update: _vm.onSelectAllPermissionsEvent }
                      }),
                      _vm._v(" "),
                      _c("atbl-input", {
                        staticClass: "m-0",
                        attrs: {
                          label: null,
                          placeholder: "Search",
                          type: "search"
                        },
                        model: {
                          value: _vm.searchData,
                          callback: function($$v) {
                            _vm.searchData = $$v
                          },
                          expression: "searchData"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "permissions-holder mt-2" },
                    _vm._l(_vm.groupedPermissions, function(permissions, key) {
                      return _c("permission-item-component", {
                        key: key,
                        attrs: {
                          name: key,
                          permissions: permissions,
                          "search-data": _vm.searchData,
                          "selected-permissions": _vm.role.permissions
                        },
                        on: { update: _vm.onPermissionsListUpdate }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.roleLoader, type: "small" } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-user-shield" }),
      _vm._v(" Role")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }