<template>
    <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1" style="height: 100%">
		<span v-if="isLoading" class="spiner">
            <img src="/img/spiner.svg" alt="spiner" />
        </span>

        <div v-if="status">
            <p v-if="!isLoading" class="success"><i class="fa fa-check"></i></p>
          <div :class="['alert', `alert-${alertState}`]">{{ message }}</div>
        </div>

        <div v-else>
            <p class="error"><i class="fa fa-times"></i></p>
            <div class="alert alert-danger">{{ message }}</div>
        </div>

        <h6 v-if="countDown >= 0">Redirect in {{ countDown }} seconds</h6>
    </div>
</template>

<script>
export default {
    props: ['id'],

    data() {
        return {
            isLoading: true,
            countDown: -1,
            message: 'Transaction processing...',
            alertState: 'warning',
            status: true,
            playerId: null
        }
    },

    created() {
        const playerId = this.getParam('player_id');

        if (playerId) {
            this.playerId = playerId;
        }

        this.checkPaymentStatus();
    },

    methods: {
        checkPaymentStatus() {
            this.getPaymentStatus(30);
        },

        getPaymentStatus(tries, initTries) {
            const url = '/payments/status' + window.location.search;
            const captureUrl = '/payments/capture' + window.location.search;
            initTries = initTries || tries;

            window.axios.post(url).then(response => {
                const responseData = response.data;

                if (responseData.status === 'failed') {
                    this.status = false;
                    this.alertState = 'danger';
                    this.message = responseData.error || 'There was a problem processing your payment';

                    this.redirect(this.makeRedirectUrl(responseData));
                    return;
                }

                if (tries === Math.ceil(initTries / 2)) {
                    this.message = 'Please be patient. Your transaction is still processing.';
                }

                if (responseData.status === 'processing') {
                    this.status = true;
                    this.alertState = 'warning';

                    if (tries === 1) {
                        this.redirect(captureUrl);

                        return;
                    }

                    setTimeout(() => {
                        this.getPaymentStatus(tries - 1, initTries);
                    }, 1000);

                    return;
                }

                if (!!responseData.paid) {
                    this.status = !!responseData.paid;
                    this.alertState = !!responseData.paid ? 'success' : 'danger';
                    this.message = !!responseData.paid ? responseData.message : (responseData.error || 'There was a problem processing your payment');
                }

                this.redirect(this.makeRedirectUrl(responseData));
                this.isLoading = false;
            }).catch(err => {
                if (tries !== 1) {
                    setTimeout(() => {
                        this.getPaymentStatus(tries - 1);
                    }, 1000);

                    return;
                }

                this.isLoading = false;
                window.flashError(err);
            });
        },

        makeRedirectUrl(responseData) {
            if (responseData.status === 'failed' && responseData.action === 'purchase') {
                return this.buildUrl(`/players/${responseData.meta.player_id}/orders`);
            }

            if (responseData.status === 'failed' && responseData.action === 'add-funds') {
                return this.buildUrl(`/players/${responseData.meta.player_id}/funds`);
            }

            if (responseData.status === 'success' && responseData.action === 'purchase') {
                return this.buildUrl(`/players/${responseData.order.player_id}/orders`);
            }

            if (responseData.status === 'success' && responseData.action === 'add-funds') {
                return this.buildUrl(`/players/${responseData.transaction.player_id}/view`);
            }

            return this.buildUrl('');
        },

        redirect(url) {
            this.countDown = 3;

            setTimeout(() => {
                window.location.href = url;
            }, this.countDown * 1000);

            this.countdown();
        },

        countdown() {
            if (this.countDown <= 0) {
                return;
            }

            setTimeout(() => {
                this.countDown -= 1;
                this.countdown();
            }, 1000);
        },
    },
};
</script>

<style scoped>
.spiner {
    z-index : 5;
    width   : 100px;
    height  : 100px;
}

.spiner img {
    width : 100%;
}

.success {
    text-align : center;
}

.success i {
    font-size   : 84px;
    line-height : 84px;
    color       : #20a8d8;
}

.error {
    text-align : center;
}

.error i {
    font-size   : 84px;
    line-height : 84px;
    color       : #f86c6b;
}
</style>
