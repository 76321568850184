<template>
    <div class="checkout-summary-item">
        <div class="checkout-summary-image">
            <img :src="$imageService.gameImage(item.item.image)" class="avatar" />
        </div>
        <div class="checkout-summary-data">
            <div class="checkout-summary-title">
                <span class="title">{{ item.item.name }}</span>
            </div>

            <div class="checkout-summary-items-data">
                <span class="mr-2">
                    <b>Lines</b>: {{ item.item.lines.length }}
                </span>
                <price :item="item" />
            </div>
        </div>
        <div v-if="canDeleteItem"
             class="checkout-summary-operations"
        >
            <confirmable-remove-button :value="item.id" @confirmed="onConfirmedEvent" />
        </div>
    </div>
</template>

<script>
import Price from './Components/Price';

export default {
    name: 'cart-lottery-row',
    components: {
        Price
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        canDeleteItem: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        onConfirmedEvent (value) {
            this.$emit('removeCartItem', value);
        }
    },
}
</script>