var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Player - View Tags")
    ? _c(
        "div",
        { staticClass: "player-tags" },
        [
          _c("div", { staticClass: "atbl-placeholder font-weight-bold mb-1" }, [
            _vm._v("Tags")
          ]),
          _vm._v(" "),
          !_vm.isLoading
            ? _c(
                "div",
                { staticClass: "player-tags-container" },
                [
                  _vm._l(_vm.tags, function(item, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "player-tag",
                        class: {
                          inactive: !item.is_manual_assignable
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.name) +
                            "\n\t            \n\t            "
                        ),
                        item.is_manual_assignable
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeTag(item)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-times" })]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.$can("Player - Update Tags") && _vm.dropDownTags.length
                    ? _c(
                        "CDropdown",
                        { attrs: { "toggler-text": "Add", color: "link" } },
                        _vm._l(_vm.dropDownTags, function(tag, tagIndex) {
                          return _c(
                            "CDropdownItem",
                            {
                              key: tagIndex,
                              class: {
                                disabled: !tag.is_manual_assignable
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addTag(tag)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" + _vm._s(tag.name) + "\n\t\t\t\t"
                              )
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "smaller" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }