import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.players.getters.GET_PLAYER_ID]: state => state.playerId,
    [TypesConst.players.getters.GET_PLAYER_LOADER]: state => state.playerLoader,
    [TypesConst.players.getters.GET_CREATE_PLAYER_MODAL]: state => state.createPlayerModal,
    [TypesConst.players.getters.GET_CREATE_PLAYER_MODEL_ERRORS]: state => state.playerModelErrors,
    [TypesConst.players.getters.GET_CREATE_PLAYER_MODEL]: state => state.playerModel,
    [TypesConst.players.getters.GET_CREATE_PLAYER_MODEL_LOADER]: state => state.createPlayerModalLoader,
    [TypesConst.players.getters.GET_SHOULD_ADD_STATUS]: state => state.shouldAddStatus,
};