import TypesConst from "../../../TypesConst";

export default {
    [TypesConst.players.mutations.SET_PLAYER_ID](state, payload){
        state.playerId = payload;
    },
    [TypesConst.players.mutations.SET_PLAYER_DATA](state, payload){
        state.playerModel = payload;
    },
    [TypesConst.players.mutations.SET_PLAYER_LOADER](state, payload){
        state.playerLoader = payload;
    },
    [TypesConst.players.mutations.SET_CREATE_PLAYER_MODAL](state, payload){
        state.createPlayerModal = _.isObject(payload) ? payload.show : payload;

        if(payload.model){
            state.playerModel = payload.model;
        }
    },
    [TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL_ERRORS](state, payload){
        state.playerModelErrors = payload;
    },
    [TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL](state, payload){
        state.playerModal = payload;
    },
    [TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL_CLEAR](state){
        state.playerModal = {
            id: '',
            name: '',
            surname: '',
            password: '',
            password_confirmation: '',
            birthdate: null,
            email: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
            state: '',
            country: '',
            phone: '',
            mobile: '',
        };
    },
    [TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL_FIELD](state, payload){
        Object.assign(state.playerModel, payload);
    },
    [TypesConst.players.mutations.SET_CREATE_PLAYER_MODEL_LOADER](state, payload){
        state.createPlayerModalLoader = payload;
    },
    [TypesConst.players.mutations.SET_SHOULD_ADD_STATUS](state, payload){
        state.shouldAddStatus = payload;
    },
};