<template>
  <div class="atbl-modal">
    <div :class="modalClasses"
         tabindex="-1"
         role="dialog"
         @click="onGeneralModalCloseEvent"
    >
      <div :class="dialogClasses"
           role="document"
      >
        <div :class="contentClasses">
          <slot name="header-wrapper">
            <header :class="headerClasses">
              <slot name="header">
                <slot name="header-title">
                  <h5 class="modal-title">
                    {{ title }}
                  </h5>
                </slot>
                <button v-if="showClose"
                        type="button"
                        aria-label="Close"
                        class="close"
                        @click="onHideEvent($event)"
                >
                  <i class="fa fa-times"></i>
                </button>
              </slot>
            </header>
          </slot>
          <slot name="body-wrapper">
            <div :class="bodyClasses">
              <slot></slot>
            </div>
          </slot>
          <slot name="footer-wrapper">
            <footer v-if="!!$slots.footer"
                    :class="footerClasses"
            >
              <slot name="footer"></slot>
            </footer>
          </slot>
        </div>
      </div>
    </div>
    <div v-if="backdrop && (visible || isTransitioning)"
         :class="backdropClasses"
    />
  </div>
</template>

<script>
	const modalColors = [
		'primary',
		'warning',
		'danger',
		'success',
		'info',
		'light',
		'dark',
		'secondary'
	];

	const modalSizes = ['', 'sm', 'lg', 'xl'];

	export default {
		name: 'atbl-modal',
		emits: [
			'input',
			'close',
		],
		props: {
			value: {
				type: Boolean,
				default: false,
			},
			show: {
				type: Boolean,
				default: false,
			},
			title: {
				type: String,
				default: null,
			},
			centered: {
				type: Boolean,
				default: false,
			},
			scrollable: {
				type: Boolean,
				default: false,
			},
			color: {
				type: String,
				default: null,
				validator: value => modalColors.includes(value),
			},
			borderColor: {
				type: String,
				default: null,
			},
			backdrop: {
				type: Boolean,
				default: true,
			},
			fade: {
				type: Boolean,
				default: true,
			},
			size: {
				type: String,
				validator: value => modalSizes.includes(value),
			},
			addContentClasses: {
				type: [String, Array, Object],
				default: null,
			},
			addHeaderClasses: {
				type: [String, Array, Object],
				default: null,
			},
			addBodyClasses: {
				type: [String, Array, Object],
				default: null,
			},
			addFooterClasses: {
				type: [String, Array, Object],
				default: null,
			},
			closeOnBackdrop: {
				type: Boolean,
				default: false,
			},
			closeOnEsc: {
				type: Boolean,
				default: false,
			},
            showClose: {
                type: Boolean,
                default: true,
            },
		},
		data() {
			return {
				visible: false,
				isTransitioning: false,
				timeout: null
			}
		},
		computed: {
			isVisible() {
				return !!this.show || !!this.value;
			},
			backdropClasses() {
				return {
					'modal-backdrop': true,
					'fade': this.fade,
					'show': this.visible || !this.fade
				}
			},
			modalClasses() {
				return [
					'modal overflow-auto',
					{
						'fade': this.fade,
						'show': this.visible,
						'd-block': this.visible || this.isTransitioning,
						[`modal-${this.color}`]: this.color
					}
				]
			},
			dialogClasses() {
				return [
					'modal-dialog',
					{
						'modal-dialog-centered': this.centered,
						'modal-dialog-scrollable': this.scrollable,
						[`modal-${this.size}`]: this.size
					}
				]
			},
			contentClasses() {
				return [
					this.addContentClasses,
					'modal-content',
					{
						[`border-${this.borderColor}`]: this.borderColor
					}
				]
			},
			headerClasses() {
				return [
					this.addHeaderClasses,
					'modal-header',
				]
			},
			bodyClasses() {
				return [
					this.addBodyClasses,
					'modal-body',
				]
			},
			footerClasses() {
				return [
					this.addFooterClasses,
					'modal-footer',
				]
			},
		},
		created() {
			this.setModalVisibility(this.isVisible);
		},
		mounted() {
			if (!this.closeOnEsc) {
				return;
			}

			window.addEventListener('keydown', this.onEscPressEvent);
		},
		beforeDestroy() {
			window.removeEventListener('keydown', this.onEscPressEvent);
		},
		methods: {
			onGeneralModalCloseEvent(event) {
				if (!this.closeOnBackdrop) {
					return;
				}

				if (event.target !== this.$el.firstElementChild) {
					return;
				}

				this.onHideEvent();
			},
			onHideEvent() {
				this.$emit('input', false);
				this.$emit('close');
			},
			onEscPressEvent(event) {
				if (event.keyCode !== 27) {
					return;
				}

				this.onHideEvent(event);
			},
			setModalVisibility(value) {
				setTimeout(() => {
					this.visible = value;
				}, 0);

				if (!this.fade) {
					return;
				}

				this.isTransitioning = true;

				setTimeout(() => {
					this.isTransitioning = false;
				}, 150);
			},
		},
		watch: {
			isVisible: {
				handler(value) {
					this.setModalVisibility(value);
				},
			},
		},
	}
</script>