<template>
    <div class="dashboard-disposition">
        <div class="dashboard-disposition-heading">
            <p class="dashboard-disposition-title">Disposition breakdown</p>
        </div>
        <div class="card m-0 border-0">
            <div class="card-body p-0">
                <a
                  v-for="(item, index) in data"
                  :key="index"
                  :href="`${baseUrl}/players?disposition_id=${item.id}`"
                  class="badge badge-primary button-badge mt-2 mr-2 mb-2 cursor-pointer p-1"
                  target="_blank"
                >
                    {{ item.name }}
                    
                    <span class="badge badge-light button-badge-number ml-1 p-1">
            {{ item.players_count }}
          </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'disposition-breakdown',
        props: {
            data: {
                type: Array,
                required: true,
            },
        },
        computed: {
            baseUrl() {
                return `${window.location.protocol}//${window.location.hostname}`;
            },
        },
    };
</script>