export default {
    mutations: {
        SET_ALL_GAMES_DATA: "setAllGamesData",
        SET_ALL_GAMES_LOADER: "setAllGamesLoader",
        SET_GAME_CONFIGURATION_DATA: "setGameConfigurationData",
        SET_GAME_CONFIGURATION_LOADER: "setGameConfigurationLoader",
    },
    getters: {
        GET_ALL_GAMES_DATA: "getAllGamesData",
        GET_ALL_GAMES_LOADER: "getAllGamesLoader",
        GET_GAME_CONFIGURATION_DATA: "getGameConfigurationData",
        GET_GAME_CONFIGURATION_LOADER: "getGameConfigurationLoader",
    },
    actions: {
        GET_ALL_GAMES_ACTION: "getAllGamesAction",
        GET_GAME_CONFIGURATION_ACTION: "getGameConfigurationAction",
    }
};