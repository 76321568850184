var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "atbl-modal",
    {
      attrs: {
        title: "Disable Syndicate",
        "close-on-backdrop": true,
        color: "danger",
        show: true
      },
      on: { close: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.isRecurring
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-danger",
                        on: { click: _vm.emitDisableSyndicate }
                      },
                      [_vm._v("\n                Yes\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.emitCloseEvent }
                      },
                      [_vm._v("\n                No\n            ")]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isRecurring
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-danger",
                        on: { click: _vm.emitDisableSyndicate }
                      },
                      [_vm._v("\n                All\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-danger",
                        on: { click: _vm.emitDisableSyndicateItem }
                      },
                      [_vm._v("\n                Only this\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.emitCloseEvent }
                      },
                      [_vm._v("\n                No\n            ")]
                    )
                  ]
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isRecurring
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _vm._v("Are you sure you want to disable this syndicate ?")
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isRecurring
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _c("strong", [_vm._v("!!! Recurring syndicate detected !!!")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Do you want to disable only this draw, or all linked future syndicates ?"
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }