<template>
    <sidebar-sub :value="show"
                 :body-class="`${show ? 'pt-0': ''}`"
                 title="Update phone number"
                 @input="toggleUpdateWindow"
    >
        <div v-if="!!show && !isLoading"
             class="general-list"
        >
            <div class="row">
                <div class="col-12 col-lg-6 font-weight-bold">
                    Phone Number
                </div>
                <div class="col-12 col-lg-6 font-weight-normal">
                    {{ phoneNumber.phone_number }}
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6 font-weight-bold">
                    Status
                </div>
                <div class="col-12 col-lg-6">
                    <span :class="`badge badge-${resolveStatusColor(phoneNumber.status)}`">
                        {{ phoneNumber.status }}
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6 font-weight-bold">
                    Provider
                </div>
                <div class="col-12 col-lg-6">
                    <span :class="`badge badge-${resolveProviderColor(phoneNumber.provider)}`">
                        {{ phoneNumber.provider }}
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6 font-weight-bold">
                    Allowed for
                </div>
                <div class="col-12 col-lg-6">
                    <div v-for="type in ['players', 'leads']"
                         class="d-flex align-items-center"
                    >
                        <atbl-checkbox :value="phoneNumber.allowed_for.includes(type)"
                                       :label="ucFirst(type)"
                                       name="is_active"
                                       class="mb-2"
                                       @update="updateAllowedTypesModel(...arguments, type)"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6 font-weight-bold">
                    Is Active
                </div>
                <div class="col-12 col-lg-6 d-flex justify-content-start align-items-center">
                    <atbl-checkbox :value="phoneNumber.is_active"
                                   name="is_active"
                                   @update="updateIsActiveStatusModel"
                    />
                </div>
            </div>
        </div>

        <template v-if="!isLoading" #footer>
            <button class="btn btn-sm btn-outline-danger mr-2"
                      :disabled="isLoading"
                      @click="toggleUpdateWindow"
            >
                Cancel
            </button>

            <button class="btn btn-sm btn-primary"
                      :disabled="isLoading"
                      @click="onUpdatePhoneNumber"
            >
                <span v-if="!isLoading">
                    Save
                </span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </sidebar-sub>
</template>

<script>
import PhoneNumberModel from './PhoneNumberModel';
import AtblCheckbox from '../../../components/Forms/Inputs/AtblCheckbox';

export default {
    name: 'UpdatePhoneNumberComponent',
    inject: [
        'onUpdatePhoneNumber',
        'updateAllowedTypesModel',
        'updateIsActiveStatusModel',
        'toggleUpdateWindow',
        'resolveStatusColor',
        'resolveProviderColor',
    ],
    components: {
        AtblCheckbox,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        phoneNumber: {
            type: Object,
            default: () => (PhoneNumberModel)
        },
    },
}
</script>