<template>
	<atbl-dropdown input-style="success"
	            :small="true"
	            add-menu-classes="p-0"
	            add-toggler-classes="d-flex align-items-center"
				   position="right"
	            class="basket"
	            id="cartWidget"
	>
		<template #header>
			<loader :show="getCartLoader"
			        type="smaller"
			        class="white mr-2"
			/>
			<div v-if="!getCartLoader"
				  class="d-flex align-items-center"
			>
				<span class="d-flex align-items-center items-indicator">
					<i class="fa fa-shopping-cart mr-2" aria-hidden="false"></i> ({{ getCartData.items.length }})
				</span>
				<i class="fa fa-caret-down"></i>
			</div>
		</template>

		<div class="card card-list m-0 p-0 bcart-0">
			<div class="card-header bg-light text-black d-flex align-items-center justify-content-between">
				<div class="card-header-title">
					<i class="fa fa-shopping-cart" aria-hidden="false"></i> Cart
				</div>
				<div v-if="! orderId && getCartData.reference_id && (!getCartLoader && cartItems.length > 0)"
                     class="card-header-operations"
                >
					<a href="#"
					   @click="clearCart"
					   v-if="!isClearConfirm"
					   :disabled="getCartLoader"
					   class="text-link"
					>
						Clear cart
					</a>
					<button class="btn btn-sm btn-success"
					          @click="clearCartConfirm"
					          v-if="isClearConfirm"
					>
						<i class="fa fa-check" aria-hidden="false"></i>
					</button>
					<button class="btn btn-sm btn-danger"
					          @click="clearCart"
					          v-if="isClearConfirm"
					>
						<i class="fa fa-times" aria-hidden="false"></i>
					</button>
				</div>
			</div>

			<div :class="{
                     'no-gutters': !cartItems.length
                 }"
                 class="card-body"
            >
                <div class="basket-body">
                    <template v-if="!getCartLoader && !!cartItems.length">
                        <component v-for="(cartItem, index) in cartItems"
                                   :key="index"
                                   :is="getGameType(cartItem)"
                                   :item="cartItem"
                                   :can-delete-item="! orderId"
                                   @removeCartItem="removeCartItem"
                        />
                    </template>

                    <not-found v-if="!getCartLoader"
                               msg="No items found"
                               class="bg-white"
                               :rowable="false"
                               :items="cartItems"
                    />

                    <loader :show="getCartLoader"
                            type="small"
                    />
                </div>
			</div>

			<div v-if="!getCartLoader && cartItems.length > 0"
			     class="card-footer bg-light"
			>
				<div class="row d-flex align-items-center">
					<div class="col-12 col-lg text-center">
						<span class="small-mb-badge show">Tickets</span>
						{{ cartItems.length }}
					</div>
					<div class="col-12 col-lg text-center">
						<span class="small-mb-badge show">Subtotal</span>
						{{ getCartItemsStatistics.price | formatCurrency }}
					</div>
					<div v-if="!this.isRoute('player.play.view')" class="col-12 col-lg text-center mt-2 mt-lg-none">
						<button class="btn btn-sm btn-success text-uppercase"
						          size="sm"
						          @click="checkoutAction"
						>
							<i class="fa fa-credit-card mr-2" aria-hidden="false"></i> Checkout
						</button>
					</div>
				</div>
			</div>
		</div>
	</atbl-dropdown>
</template>

<script>
	import TypesConst from "../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";
	import Cart from "../../../../../../modules/cart/Cart";

	const {
		mapGetters: mapCartGetters,
		mapMutations: mapCartMutations,
		mapActions: mapCartActions
	} = createNamespacedHelpers(NamespaceConst.cart);

	const {
		mapGetters: mapPlayersGetters,
		mapActions: mapPlayerActions
	} = createNamespacedHelpers(NamespaceConst.players);

	const {
		mapGetters: mapOrdersGetters
	} = createNamespacedHelpers(NamespaceConst.order);

	import CheckoutAction from "../../Mixins/CheckoutAction";
	import components from './Types/index';
	import AtblDropdown from '../../../../../../components/AtblDropdown/AtblDropdown.vue';

	export default {
		name: "cart-widget",
        components: {
			...components,
			AtblDropdown,
		},
        mixins: [
			CheckoutAction
		],
		data () {
			return {
				isClearConfirm: false
			};
		},
		computed: {
			...mapCartGetters({
				getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
				getCartLoader: TypesConst.cart.getters.GET_ADD_TO_CART_LOADER,
				getCheckout: TypesConst.cart.getters.GET_CHECKOUT
			}),
			...mapPlayersGetters({
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
			}),
            ...mapOrdersGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            }),
		},
		methods: {
			...mapCartMutations({
				setAddToCartData: TypesConst.cart.mutations.SET_ADD_TO_CART_DATA,
				setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
				setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME
			}),
			...mapCartActions({
				clearCartAction: TypesConst.cart.actions.CLEAR_CART_ACTION,
				removeCartItemAction: TypesConst.cart.actions.REMOVE_CART_ITEM_ACTION,
				resetCartAction: TypesConst.cart.actions.RESET_CART_ACTION
			}),
			...mapPlayerActions({
				fetchPlayer: TypesConst.players.actions.FETCH_PLAYER_ACTION
			}),
			clearCart (event) {
				event.preventDefault();
				event.stopPropagation();

				this.isClearConfirm = !this.isClearConfirm;
			},
			clearCartConfirm (event) {
				let vm = this;

				this.clearCartAction(null)
					.then(() => {
						vm.isClearConfirm = false;
					});
			},
			getCartIfExistAndSet () {
				const cart = Cart.storage(null, this.getPlayerId);
				if (cart) {
					this.setAddToCartData(cart);
					
					if (!cart.items.length) {
						this.resetCartAction(false);
					}
				} else {
					this.resetCartAction(true);
				}
			}
		},
		created () {
			let _vm = this;
			
			_vm.getCartIfExistAndSet();
			window.addEventListener("storage", function (event) {
				if (event.key === Cart._cartStorageKey) {
					_vm.getCartIfExistAndSet();
					_vm.fetchPlayer();
				}
			});
		},
		mounted () {
			const checkout = this.getParam("checkout");

			if (checkout && checkout === "true") {
				this.checkoutAction();
				window.history.pushState({}, "", `/players/${this.getPlayerId}/play`)
			}
		}
	}
</script>