export default {
    mutations: {
        SET_FUNDS_AMOUNT: `setFundsAmount`,
        SET_FUNDS_REFERENCE: `setFundsReference`,
        SET_FUNDS_REASON: `setFundsReason`,
        SET_FUNDS_META: `setFundsMeta`,
        SET_FUNDS_TRANSACTION_ID: `setFundsTransactionId`,
        SET_FUNDS_AGENT: `setFundsAgent`,
        SET_FUNDS_IS_FAILED_TRANSACTION: `setFundsIsFailedTransaction`,
        SET_FUNDS_LOADER: `setFundsLoader`,
        SET_FUNDS_TRANSACTION_TYPE: `setFundsTransactionType`,
        SET_FUNDS_ADD_BALANCE: `setFundsAddBalance`,
        SET_FUNDS_PAYMENT_LINK: `setFundsPaymentLink`,
        SET_FUNDS_CONFIRM_MODAL: `setFundsConfirmModal`,
        SET_FUNDS_CONFIRM_CREDIT_CARD_MODAL: `setFundsCreditCardConfirmModal`,
        SET_FUNDS_CREDIT_CARD: `setFundsCreditCard`,
        SET_FUNDS_CREDIT_CARD_ERRORS: `setFundsCreditCardErrors`,
        SET_BONUS_CONFIRM_MODAL: `setBonusConfirmModal`,
    },
    getters: {
        GET_FUNDS_AMOUNT: `getFundsAmount`,
        GET_FUNDS_REFERENCE: `getFundsReference`,
        GET_FUNDS_REASON: `getFundsReason`,
        GET_FUNDS_META: `getFundsMeta`,
        GET_FUNDS_TRANSACTION_ID: `getFundsTransactionId`,
        GET_FUNDS_AGENT: `getFundsAgent`,
        GET_FUNDS_IS_FAILED_TRANSACTION: `getFundsIsFailedTransaction`,
        GET_FUNDS_LOADER: `getFundsLoader`,
        GET_FUNDS_CREDIT_TYPE: `getFundsCreditType`,
        GET_FUNDS_ADD_BALANCE: `getFundsAddBalance`,
        GET_FUNDS_PAYMENT_LINK: `getFundsPaymentLink`,
        GET_FUNDS_CONFIRM_MODAL: `getFundsConfirmModal`,
        GET_FUNDS_CONFIRM_CREDIT_CARD_MODAL: `getFundsCreditCardConfirmModal`,
        GET_FUNDS_CREDIT_CARD: `getFundsCreditCard`,
        GET_FUNDS_CREDIT_CARD_ERRORS: `getFundsCreditCardErrors`,
        GET_BONUS_CONFIRM_MODAL: `getBonusConfirmModal`,
    },
    actions: {
        ADD_FUNDS_ACTION: `addFundsAction`,
    }
};