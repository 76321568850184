<template>
    <atbl-modal :show="show"
                :close-on-backdrop="false"
                :close-on-esc="false"
                title="Approve Draw"
                @close="toggleApproveModal"
    >
        <template v-if="isAllSelected">
            <h4 class="text-center">Are your sure you want to approve the whole draw?</h4>
        </template>
        <template v-else>
            <div class="row">
                <div class="col text-center font-weight-bold text-uppercase">Total lines</div>
                <div class="col text-center font-weight-bold text-uppercase">Selected lines</div>
            </div>
            <div class="row">
                <div class="col text-center">{{ allLines.length }}</div>
                <div class="col text-center">{{ selectedLines.length }}</div>
            </div>
            <hr />
            <h5 class="text-center">Are your sure you want to approve only the selected lines in this draw?</h5>
        </template>

        <template #footer>
            <button class="btn btn-sm btn-danger font-weight-bold mr-1"
                    @click="toggleApproveModal"
            >
                Close
            </button>
            <button class="btn btn-sm btn-primary"
                    :disabled="!!isLoading"
                    @click="approveDrawAction(drawId, selectedLines)"
            >
                <span v-if="!isLoading">Approve</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'ApproveModalComponent',
    inject: [
        'drawId',
        'approveDrawAction',
        'toggleApproveModal',
    ],
    components: {
        AtblModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isAllSelected: {
            type: Boolean,
            default: false,
        },
        allLines: {
            type: Array,
            default: () => ([]),
        },
        selectedLines: {
            type: Array,
            default: () => ([]),
        },
    },
}
</script>