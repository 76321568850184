<template>
    <component v-bind="componentAttributes"
               v-on="componentListeners"
               :is="tag"
               :class="['atbl-dropdown-item', {disabled: !!disabled}]"
               role="menuitem"
    >
        <slot/>
    </component>
</template>

<script>
import {cloneDeep} from "lodash/lang";

export default {
	name: "AtbDropdownItem",
	emits: [
		'click',
		'close',
	],
	props: {
		tag: {
			type: String,
			default: 'a'
		},
		preventClose: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		componentAttributes() {
			const attributes = cloneDeep(this.$attrs);
			
			if (this.tag === 'a') {
				attributes.href = '#';
            }
			
			return attributes;
        },
		componentListeners() {
			return {
				...this.$listeners,
				click: (event) => {
					event.preventDefault();
					event.stopPropagation();

					if (!this.preventClose) {
						this.$parent.$emit('close');
					}

					this.$emit('click', event);
				}
			};
		},
	},
}
</script>