export default {
	callNotesRoute: "/call-notes",
	getCallNotes(params = {}) {
		return window.axios.get(`${this.callNotesRoute}/`, {
			params
		});
	},
	createCallNote(_params = {}) {
		return window.axios.post(`${this.callNotesRoute}/save`, _params);
	}
};