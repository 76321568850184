<template>
	<div class="card card-list">
		<div class="card-header bg-primary text-white">
			<i class="fa fa-clock"></i> Player Dispositions
		</div>
		
		<div class="card-body">
			<div class="general-list">
				<div class="row show-first-row">
					<div class="col text-muted">Disposition</div>
					<div class="col text-muted">Follow Up</div>
					<div class="col text-muted text-center">Date</div>
				</div>
				
				<div v-if="!loader"
				       class="row py-3"
				       v-for="disposition in playerDispositions"
				       :key="disposition.id"
				>
					<div class="col text-muted">{{ disposition.disposition.name }}</div>
					<div class="col text-muted text-center">
						<small>{{ disposition.follow_up }}</small>
					</div>
					<div class="col text-muted text-center">
						<small>{{ disposition.created_at | utcToLocal }}</small>
					</div>
				</div>
				
				<not-found v-if="!loader"
				           :items="playerDispositions"
				           msg="No dispositions found."
				/>
				
				<loader :show="loader"
				        type="small"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "player-dispositions-list",
		props: {
			playerDispositions: {
				type: Array,
				required: true
			},
			loader: {
				type: Boolean,
				required: true
			}
		}
	}
</script>