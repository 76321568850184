var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.getNotificationsList.length > 0
              ? _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _vm.$can("Users - Update Notifications")
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-link mr-2",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.markAllAsRead.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("\n            Mark all as read\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("Users - Remove Notifications")
                      ? _c("confirmable-button", {
                          attrs: { label: "Clear all" },
                          on: { confirmed: _vm.clearAll }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "general-list" },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.getNotificationsList, function(item, index) {
                return !_vm.getNotificationsListLoader
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass: "row",
                        class: {
                          "bg-light": !item.read_at
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Message")
                            ]),
                            _vm._v(
                              "\n              " +
                                _vm._s(item.data.message) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg-2 mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Created At")
                            ]),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._f("utcToLocal")(item.created_at)) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg-2 mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Operations")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _vm.$can("Users - Get Notifications")
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-link",
                                        on: {
                                          click: function($event) {
                                            return _vm.readNotification(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-eye",
                                          attrs: { "aria-hidden": "false" }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$can("Users - Remove Notifications")
                                  ? _c("confirmable-remove-button", {
                                      attrs: { value: item.id },
                                      on: { confirmed: _vm.removeNotification }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.getNotificationsListLoader
                ? _c("not-found", {
                    attrs: {
                      items: _vm.getNotificationsList,
                      msg: "No notifications found"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("loader", {
                attrs: { show: _vm.getNotificationsListLoader, type: "small" }
              }),
              _vm._v(" "),
              !_vm.getNotificationsListLoader &&
              _vm.getNotificationsListResponse.last_page > 1
                ? _c(
                    "nav",
                    { staticClass: "wrap p-3" },
                    [
                      _c("atbl-pagination", {
                        attrs: { pagination: _vm.getNotificationsListResponse },
                        on: { paginate: _vm.getNotificationsListAction }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.$can("Users - Get Notifications")
        ? _c("ReadNotificationsSidebar")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-bell mr-1" }),
      _vm._v(" Notifications\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Message")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2" }, [_vm._v("Created At")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2" }, [_vm._v("Operations")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }