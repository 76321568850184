var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.addLeadLoader
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("leads-preview-profile", {
                  attrs: {
                    model: _vm.previewCustomerModel,
                    "is-loading": _vm.addLeadLoader
                  }
                }),
                _vm._v(" "),
                _c("leads-messages", { attrs: { id: _vm.id } })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-md-6" },
              [
                _c("leads-dispositions-list", { attrs: { "lead-id": _vm.id } }),
                _vm._v(" "),
                _vm.$can("Leads - View Calls")
                  ? _c("lead-last-calls", {
                      attrs: { "lead-id": _vm.id, pagination: true }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loader", { attrs: { show: _vm.addLeadLoader, type: "small" } }),
      _vm._v(" "),
      _vm.$can("Leads - Create/Edit") && this.isLeadModalOpen
        ? _c("add-leads-modal-component", {
            attrs: {
              model: _vm.leadModel,
              errors: _vm.leadModelErrors,
              "is-loading": _vm.addLeadLoader
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }