<template>
    <div class="row">
        <div class="col-12 col-lg-6 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Draw
                </div>

                <div class="card-body">
                    <div class="row">
                        <div cols="12" lg="3" class="text-center">
                            <img :src="$imageService.gameImage(ticket.game.image)" class="img-fluid" alt="`${this.game.image}`" width="80" />
                        </div>
                        <div cols="12" lg="9">
                            <div v-if="firstPrize" class="numbers-group">
                                <div class="numbers-group-wrapper" v-tooltip="{content: 'Main prize'}">
                                    <span class="mr-1 raffle bg-warning">{{ firstPrize }}</span>
                                    <br />
                                    <a href="#" @click.prevent="drawResultsModalVisible = true">Show more</a>
                                </div>
                            </div>
                            <not-found v-if="! firstPrize" msg="Lottery numbers not drawn yet." :items="[]" textAlign="left" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Player
                </div>

                <div class="card-body">
                    <a :href="`/players/${ticket.player_id}/view`" class="text-primary">
                        <h3>{{ playerFullName }}</h3>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3 d-flex">
            <div class="card flex-fill draw-results">
                <div class="card-header card-header-atbl">
                    Winnings
                </div>

                <div class="card-body">
                    <h3 class="text-primary">
                        {{ ticketWinStatus }}
                    </h3>
                </div>
            </div>
        </div>

        <atbl-modal :show="drawResultsModalVisible"
                 title="Draw results"
                 size="lg"
                 :close-on-backdrop="true"
                 @close="drawResultsModalVisible = false"
        >
            <div class="row">
                <div class="col">
                    <template v-for="(numberItems, key) in prizes">
                        <strong>{{ key }}</strong>
                        <pre>{{ numberItems }}</pre>
                    </template>
                </div>
            </div>

            <template #footer>
                <button class="btn btn-sm btn-danger" @click="drawResultsModalVisible = false">
                    Close
                </button>
            </template>
        </atbl-modal>
    </div>
</template>

<script>
import AtblModal from '../../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'raffle-ticket-info',
	components: {
		AtblModal,
	},
    props: ['ticket'],

    data() {
        return {
            drawResultsModalVisible: false,
        };
    },

    computed: {
        playerFullName() {
            return `${this.ticket.order.player.name} ${this.ticket.order.player.surname}`;
        },

        prizes() {
            const gameDraw = this.ticket.game_draw;

            if (!gameDraw) {
                return null;
            }

            return JSON.parse(gameDraw.numbers);
        },

        firstPrize() {
            const prizes = this.prizes;

            if (!prizes) {
                return null;
            }

            return prizes.first || [];
        },

        ticketWinStatus() {
            if (!this.ticket.is_parsed || this.ticket.prize === null) {
                return 'No status';
            }

            if (this.ticket.prize === 0) {
                return 'Not a winning line';
            }

            return `Won: ${this.$options.filters.formatCurrency(this.ticket.prize)}`;
        }
    }
}
</script>

<style lang="scss" scoped>
.numbers-group-wrapper {
    flex-direction : column;

    .raffle {
        font-family    : monospace;
        font-size      : 16px;
        font-weight    : bold;
        letter-spacing : 4px;
    }
}
</style>