<template>
    <div class="container-fluid">
        <AgentsFilterForm :users="users"
                          @modelChange="modelChange"
                          :permissions="isOnlyAgent"
        />

        <div v-if="showTable"
             class="card card-list"
        >
            <div class="card-header">
                <i class='fa fa-users'></i> Agents 
            </div>

            <div class="card-body">
                <AgentsList :sales="sales"
                            :isSearching="isSearching"
                            :agents="agents"
                            :sort="model.sort"
                            @sorted="sortChange"
                            @open-agent="openAgent"
                />
            </div>

            <div v-if="!isSearching && agentsData.last_page > 1" class="card-footer">
                <nav class="wrap">
                    <atbl-pagination :pagination="agentsData"
                                     @paginate="getResults"
                    />
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    import AgentsFilterForm from "./Forms/AgentsFilterForm";
    import AgentsList from "./Sections/AgentsList";
    import PaginateOptions from "../../../data/PaginateOptions";
    import moment from "moment";
    import Date from "../../../helpers/Date";

    export default {
        name: "agents",
        components: {
            AgentsFilterForm,
            AgentsList
        },
        props: [
            "isonlyagent"
        ],
        data(){
            return {
                getAgentsUrl: "/reports/agents/agent",
                topPlayersNum: 10,
                filterChanged: false,
                model: {
                    filterDate: "today",
                    dateFrom: moment().format(Date.defaultDate),
                    dateTo: moment().format(Date.defaultDate),
                    paginate: PaginateOptions.default,
                    sort: {
                        sort: "deposits",
                        value: "DESC"
                    }
                },
                agentsData: {},
                agents: [],
                users: [],
                sales: {},
                totalAll: 0,
                countAll: 0,
                totalSales: 0,
                totalDeposits: 0,
                totalSalesPercent: 0,
                isSearching: false,
                showTable: false,
            };
        },
        methods: {
            getResults(page = 1) {
                this.model.page = page;
                this.getAgents(this.model);
            },
            getAgents: function(){
                let vm = this;
	
	            if(!!this.isSearching){
					return;
	            }
				
	            this.isSearching = true;
				
	            window.axios.get(this.getAgentsUrl, {params: this.model}).then(function (response) {
		            vm.agents = response.data.agents.data;
		            vm.agentsData = response.data.agents;
		            vm.sales = {
			            totalAll: response.data.totalAll,
			            countAll: response.data.countAll,
			            totalSales: response.data.totalSales,
			            totalDeposits: response.data.totalDeposits,
			            totalBonus: response.data.totalBonus,
			            totalSalesPercent: response.data.totalSalesPercent
		            };
	            })
	              .finally(() => {
		              vm.isSearching = false;
	              });
				
            },
            modelChange: function(value){
                this.showTable = true
                this.model = value;
                this.filterChanged = true;
                this.getAgents();
            },
            sortChange: function(sort){
                this.model.sort = sort;
                this.getAgents(this.model);
            },
            openAgent: function(agent){
                if(!this.filterChanged){
                    this.model.filterDate = "month";
                }
                window.open(`/reports/agents/agent/${agent.id}/edit?${this.buildQuery(this.model)}`);
            }
        },
        computed: {
            isOnlyAgent: function(){
                return this.isonlyagent === "true";
            }
        }
    }
</script>