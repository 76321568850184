var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: { id: _vm.id, icon: "fa fa-ticket-alt", title: "Player Bonuses" },
      scopedSlots: _vm._u([
        {
          key: "rightSide",
          fn: function() {
            return [
              _vm.$can("Bonuses - View List")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          _vm.showAssignBonusModal = true
                        }
                      }
                    },
                    [_vm._v("Assign bonus")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "general-list agents" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col text-muted" }, [_vm._v("ID")]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted" }, [_vm._v("Bonus")]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted" }, [
              _vm._v("Assigned at")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted" }, [
              _vm._v("Claimed at")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted" }, [
              _vm._v("Accomplished at")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted" }, [
              _vm._v("Revoked at")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-muted" }, [
              _vm._v("Expiry date")
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.playerBonuses, function(playerBonus, index) {
            return !_vm.isLoading
              ? _c("div", { key: index, staticClass: "row" }, [
                  _c("div", { staticClass: "col align-self-center" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(playerBonus.uuid) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col align-self-center" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(playerBonus.bonus.title) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "col align-self-center",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatPlayerBonusDate("assigned_at", playerBonus)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "col align-self-center",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatPlayerBonusDate("claimed_at", playerBonus)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "col align-self-center",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatPlayerBonusDate(
                          "accomplished_at",
                          playerBonus
                        )
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "col align-self-center",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatPlayerBonusDate("revoked_at", playerBonus)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "col align-self-center",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatPlayerBonusDate("expires_at", playerBonus)
                      )
                    }
                  })
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _c("loader", { attrs: { show: _vm.isLoading, type: "small" } }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("not-found", {
                attrs: {
                  msg: "No bonuses assigned to this player.",
                  items: _vm.playerBonuses
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && _vm.playerBonusesResponse.last_page > 1
            ? _c(
                "nav",
                { staticClass: "wrap py-3" },
                [
                  _c("atbl-pagination", {
                    attrs: { pagination: _vm.playerBonusesResponse },
                    on: { paginate: _vm.getPlayerBonuses }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.showAssignBonusModal
        ? _c("assign-bonus-modal", {
            on: {
              assign: _vm.onAssign,
              cancel: function($event) {
                _vm.showAssignBonusModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }