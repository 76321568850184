<template>
	<div class="container-fluid commission-eligible-transactions-single-day">
		<template v-if="!isLoading">
			<div class="card card-list">
				<div class="card-header d-flex align-items-center justify-content-between">
					<div>
						<i class="fa fa-user"/> Details
					</div>
					<div>
						<strong>Date:</strong> {{ filters.dateFrom }} - {{ filters.dateTo }}
					</div>
				</div>
				<div class="card-body">
					<div class="general-list">
						<div class="row">
							<div class="col-12 col-md-6 col-lg">ID</div>
							<div class="col-12 col-md-6 col-lg">Name</div>
							<div class="col-12 col-md-6 col-lg">Total Deposits</div>
							<div class="col-12 col-md-6 col-lg">Total Commission</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-6 col-lg mb-2 mb-md-0">
								<span class="small-mb-badge">ID</span>
								{{ user.id }}
							</div>
							<div class="col-12 col-md-6 col-lg mb-2 mb-md-0">
								<span class="small-mb-badge">Name</span>
								{{ user.name }}
							</div>
							<div class="col-12 col-md-6 col-lg mb-2 mb-md-0">
								<span class="small-mb-badge">Total Deposits</span>
								{{ formatCurrency(user.commission.total_sales) }}
							</div>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Total Commission</span>
								{{ formatCurrency(user.commission.total_commission) }}
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="card card-list">
				<div class="card-header">
					<i class="fa fa-list"/> Commission transactions
				</div>
				
				<div class="card-body">
					<div class="general-list">
						<div class="row p-0">
							<div class="col-12 col-md-6 col-lg">Date</div>
							<div class="col-12 col-md-6 col-lg">Transactions</div>
							<div class="col-12 col-md-6 col-lg">Deposits</div>
							<div class="col-12 col-md-6 col-lg bg-primary-20 py-2">Commission</div>
						</div>
						
						
						<div v-for="(group, index) in commissionEligibleTransactions"
						     :key="index"
						     class="row p-0"
						>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Date</span>
								<a v-if="$can('Player - View Details')"
								   :href="getSingleDayUrl(group.date)"
								   target="_blank"
								>
									{{ group.date }}
								</a>
								<span v-else>
									{{ group.date }}
								</span>
							</div>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Transactions</span>
								{{ group.transactions }}
							</div>
							<div class="col-12 col-md-6 col-lg">
								<span class="small-mb-badge">Deposits</span>
								{{ formatCurrency(group.commission.total_sales) }}
							</div>
							<div class="col-12 col-md-6 col-lg bg-primary-20 py-2">
								<span class="small-mb-badge">Commission</span>
								{{ formatCurrency(group.commission.commission_payout) }}
							</div>
						</div>
						
						<items-not-found v-if="!isLoading"
						                 :items="commissionEligibleTransactions"
						                 msg="No transactions found"
						/>
					</div>
				</div>
			</div>
		</template>
		
		<loader :show="isLoading"
		        type="small"
		/>
	</div>
</template>

<script>
import Loader from "../../../../components/Widgets/Loader.vue";
import ItemsNotFound from "../../../../components/Lists/ItemsNotFound.vue";
import formatCurrency from "../../../../filters/FormatCurrency";
import utcToLocal from "../../../../filters/UtcToLocal";
import BuildQuery from "../../../../utils/Url/BuildQuery";

export default {
	name: "CommissionEligibleTransactionsDayGroupsPage",
	components: {
		Loader,
		ItemsNotFound,
	},
	props: {
		filters: {
			type: Object,
			default: () => ({})
		},
	},
	data() {
		return {
			isLoading: false,
			user: {
				id:null,
				name: null,
				transactions: [],
				commission: {
					total_sales: null,
					total_commission: null
				}
			},
		};
	},
	computed: {
		commissionEligibleTransactions() {
			return this.user.transactions;
		},
	},
	async created() {
		await this.fetchCommissionEligibleTransactions();
	},
	methods: {
		formatCurrency,
		utcToLocal,
		async fetchCommissionEligibleTransactions() {
			this.isLoading = true;
			
			try {
				const response = await window.axios.get(`/commissions/eligible-transactions/day-groups/transactions`, {
					params: this.filters,
				});
				
				this.user = response.data;
				
				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				
				window.flashError(error);
			}
		},
		getCommissionPlanTypeColor(type) {
			switch(type) {
				case 'fixed':
					return 'danger';
				case 'tiered':
					return 'success';
				case 'scalar':
					return 'warning';
				default:
					return 'dark';
			}
		},
		getSingleDayUrl(dateFrom) {
			const params = BuildQuery({
				user_id: this.user.id,
				dateFrom,
			});
			
			return `/commissions/eligible-transactions/single-day?${params}`;
		},
	},
}
</script>