var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "card card-list" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.$can("Shifts - Create/Update")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    on: {
                      click: function($event) {
                        _vm.createUpdateShiftModalOpen = true
                      }
                    }
                  },
                  [_vm._v("\n                Create\n            ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c(
              "div",
              { staticClass: "general-list" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("ID")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg" }, [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg" }, [
                    _vm._v("Description")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg" }, [
                    _vm._v("Start")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg" }, [
                    _vm._v("Finish")
                  ]),
                  _vm._v(" "),
                  _vm.$canAny(_vm.shiftOperationPermissions)
                    ? _c("div", { staticClass: "col-12 col-lg" }, [
                        _vm._v(
                          "\n                        Operations\n                    "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm._l(_vm.shifts, function(item, index) {
                  return !_vm.isLoading
                    ? _c("div", { key: index, staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("ID")
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.id) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Name")
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.name) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Description")
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._f("elipsis")(item.description)) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Start")
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.start) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg mb-1 mb-lg-0" },
                          [
                            _c("span", { staticClass: "small-mb-badge" }, [
                              _vm._v("Finish")
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.finish) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$canAny(_vm.shiftOperationPermissions)
                          ? _c("div", { staticClass: "col-12 col-lg" }, [
                              _c("span", { staticClass: "small-mb-badge" }, [
                                _vm._v("Operations")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start align-items-center"
                                },
                                [
                                  _vm.$can("Shifts - Create/Update")
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.onUpdateShiftEvent(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_c("i", { staticClass: "fa fa-pen" })]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$can("Shifts - Remove")
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.getShiftDetailsForDelete(
                                                item.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                })
              ],
              2
            ),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("not-found", {
                  attrs: { items: _vm.shifts, msg: "No shifts found" }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("loader", { attrs: { show: _vm.isLoading, type: "small" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("create-update-shift-component", {
        attrs: {
          show: _vm.createUpdateShiftModalOpen,
          model: _vm.shiftModel,
          "model-errors": _vm.shiftModelErrors,
          "is-loading": _vm.isCreateShiftLoading
        }
      }),
      _vm._v(" "),
      _c("delete-shift-component", {
        attrs: {
          show: _vm.deleteShiftModalOpen,
          "is-loading": _vm.deleteShiftLoader,
          "selected-shift": _vm.selectedShift
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-calendar-alt" }),
      _vm._v(" Shifts\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }