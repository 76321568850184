<template>
    <div class="checkout-summary" :class="{ sticky : isSticky }" :style="{ top: `${top}px` }">
        <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
                <div class="card-header-title">
                    <i class="fa fa-shopping-cart" aria-hidden="false"></i> Cart
                </div>
                <div v-if="! orderId && getCartData.reference_id && (!getCartLoader && cartItems.length > 0)"
                     class="card-header-operations"
                >
                    <a href="#"
                       @click="clearCart"
                       v-if="!isClearConfirm"
                       :disabled="getCartLoader"
                       class="text-link"
                    >
                        Clear cart
                    </a>
                    <button class="btn btn-sm btn-success"
                              @click="clearCartConfirm"
                              v-if="isClearConfirm"
                    >
                        <i class="fa fa-check" aria-hidden="false"></i>
                    </button>
                    <button class="btn btn-sm btn-danger"
                              @click="clearCart"
                              v-if="isClearConfirm"
                    >
                        <i class="fa fa-times" aria-hidden="false"></i>
                    </button>
                </div>
            </div>

            <div :class="{
                     'no-gutters': !cartItems.length,
                     'p-0': !!cartItems.length
                 }"
                 class="card-body"
            >
                <div v-if="!!cartItems.length"
                     class="checkout-summary-place-order"
                >
                    <div class="checkout-summary-total">
                        <span>TOTAL:</span> {{ getCartItemsStatistics.price | formatCurrency }}
                    </div>
                    <div class="checkout-summary-action">
                        <button v-if="!orderId"
                                  :disabled="isPlacingOrder || getCartLoader"
                                  class="btn btn-sm btn-danger"
                                  @click="placeOrder"
                        >
                            <span v-if="!isPlacingOrder">Place Order</span>
                            <loader :show="isPlacingOrder" type="smaller" />
                        </button>
                        <button v-if="!!orderId"
                                  :disabled="isPlacingOrder || getCartLoader"
                                  class="btn btn-sm btn-danger"
                                  @click="checkoutAction(getCartItemsStatistics.price)"
                        >
                            <span>Payment</span>
                        </button>
                    </div>
                </div>
                <div class="checkout-summary-body">
                    <template v-if="!getCartLoader && !!cartItems.length">
                        <component v-for="(cartItem, index) in cartItems"
                                   :key="index"
                                   :is="getGameType(cartItem)"
                                   :item="cartItem"
                                   :can-delete-item="!orderId"
                                   @removeCartItem="removeCartItem"
                        />
                    </template>

                    <not-found v-if="!getCartLoader"
                               msg="No items found"
                               class="bg-white"
                               :items="cartItems"
                    />

                    <loader :show="getCartLoader"
                            type="small"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import TypesConst from "../../../../../../store/TypesConst";
	import NamespaceConst from "../../../../../../store/NamespaceConst";
	import {createNamespacedHelpers} from "vuex";
	import Cart from "../../../../../../modules/cart/Cart";

	const {
		mapGetters: mapCartGetters,
		mapMutations: mapCartMutations,
		mapActions: mapCartActions
	} = createNamespacedHelpers(NamespaceConst.cart);

	const {
		mapGetters: mapPlayersGetters,
		mapActions: mapPlayerActions
	} = createNamespacedHelpers(NamespaceConst.players);

	const {
		mapGetters: mapOrdersGetters,
        mapMutations: mapOrderMutations
	} = createNamespacedHelpers(NamespaceConst.order);

	import CheckoutAction from "../../Mixins/CheckoutAction";
	import PlaceOrder from '../../Mixins/PlaceOrder';
	import components from './Types/index';

	export default {
		name: "cart-widget",
        components,
        mixins: [
			CheckoutAction,
            PlaceOrder
		],
		data () {
			return {
				isClearConfirm: false,
                isSticky: false,
                top: null
			};
		},
		computed: {
			...mapCartGetters({
				getCartData: TypesConst.cart.getters.GET_ADD_TO_CART_DATA,
				getCartLoader: TypesConst.cart.getters.GET_ADD_TO_CART_LOADER,
				getCheckout: TypesConst.cart.getters.GET_CHECKOUT,
                isPlacingOrder: TypesConst.cart.getters.GET_PLACE_ORDER,
			}),
			...mapPlayersGetters({
				getPlayerId: TypesConst.players.getters.GET_PLAYER_ID
			}),
            ...mapOrdersGetters({
                orderId: TypesConst.order.getters.GET_ORDER_ID
            }),
		},
		methods: {
			...mapCartMutations({
				setAddToCartData: TypesConst.cart.mutations.SET_ADD_TO_CART_DATA,
				setCheckout: TypesConst.cart.mutations.SET_CHECKOUT,
				setShowGame: TypesConst.cart.mutations.SET_SHOW_GAME,
                clearCartData: TypesConst.cart.mutations.SET_CLEAR_CART_DATA,
                setIsPlacingOrder: TypesConst.cart.mutations.SET_PLACE_ORDER,
			}),
            ...mapOrderMutations({
                setOrderId: TypesConst.order.mutations.SET_ORDER_ID
            }),
			...mapCartActions({
				clearCartAction: TypesConst.cart.actions.CLEAR_CART_ACTION,
				removeCartItemAction: TypesConst.cart.actions.REMOVE_CART_ITEM_ACTION,
				resetCartAction: TypesConst.cart.actions.RESET_CART_ACTION
			}),
			...mapPlayerActions({
				fetchPlayer: TypesConst.players.actions.FETCH_PLAYER_ACTION
			}),
			clearCart (event) {
				event.preventDefault();
				event.stopPropagation();

				this.isClearConfirm = !this.isClearConfirm;
			},
			clearCartConfirm (event) {
				let vm = this;

				this.clearCartAction(null)
					.then(() => {
						vm.isClearConfirm = false;
					});
			},
			getCartIfExistAndSet () {
				const cart = Cart.storage(null, this.getPlayerId);
				if (cart) {
					this.setAddToCartData(cart);
					
					if (!cart.items.length) {
						this.resetCartAction(false);
					}
				} else {
					this.resetCartAction(true);
				}
			},
		},
		created () {
			let _vm = this;
			
			_vm.getCartIfExistAndSet();
			window.addEventListener("storage", function (event) {
				if (event.key === Cart._cartStorageKey) {
					_vm.getCartIfExistAndSet();
					_vm.fetchPlayer();
				}
			});
		},
		mounted () {
			const checkout = this.getParam("checkout");

			if (checkout && checkout === "true") {
				this.checkoutAction();
				window.history.pushState({}, "", `/players/${this.getPlayerId}/play`)
			}

            const header = document.querySelector('.c-header');
            const playGames = document.querySelector('.player-play-games');
            const playGamesRectangle = playGames.getBoundingClientRect();

            window.addEventListener('scroll', () => {
                this.isSticky = (window.pageYOffset + header.offsetHeight) >= playGamesRectangle.top && window.innerWidth >= 1200;
                this.top = this.isSticky ? header.offsetHeight : null;
            });
		}
	}
</script>