<template>
    <div class="container-fluid leads-section">
        <slot name="beforeCard"></slot>
        <div class="card card-list">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span v-if="!$slots.title">
                    <i :class="icon"></i> {{ title }}
                </span>
                <div v-else>
                    <slot name="title"></slot>
                </div>
                <div>
                    <slot name="header"></slot>
                </div>
            </div>

            <div class="card-body">
                <slot></slot>
            </div>
        </div>

        <slot name="modals"></slot>
    </div>
</template>

<script>
    export default {
        name: "layout",
        props: {
            title: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                required: true
            }
        }
    }
</script>