export default {
    getNotificationsRoute: function(){
        return "/notifications";
    },
    getNotificationsList: function(params = {}){
        return window.axios.get(`${this.getNotificationsRoute()}/list`, {
            params
        });
    },
    getNotificationsAllMarkAsRead: function(){
        return window.axios.post(`${this.getNotificationsRoute()}/mark-as-read`);
    },
    clearAllNotifications: function(){
        return window.axios.post(`${this.getNotificationsRoute()}/clear-all`);
    },
    getNotificationsById: function(id){
        return window.axios.get(`${this.getNotificationsRoute()}/${id}`);
    },
    removeNotificationById: function(id){
        return window.axios.post(`${this.getNotificationsRoute()}/${id}/remove`);
    },
    getNotificationsWidgetData: function(id){
        return window.axios.get(`${this.getNotificationsRoute()}/widget`);
    }
}