<template>
    <atbl-form-group label="Game"
                     label-for="Games"
                     :label-cols="labelCols"
                     :horizontal="horizontal"
                     class="font-weight-bold"
    >
        <tree-select :value="pickerValue"
                     :multiple="true"
                     :searchable="true"
                     :disabled="isLoading"
                     :clear-on-select="true"
                     :options="gameList"
                     :normalizer="(node) => ({id: node.value, label: node.text})"
                     @input="updateModel"
        />
    </atbl-form-group>
</template>

<script>
export default {
    name: 'multi-games-picker',
    props: {
        value: {},
        input: {},
        labelCols: {
            type: Number,
            default: 3,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: String,
            default: 'lottery'
        },
    },
    data() {
        return {
            isLoading: false,
            gameList: [],
        };
    },
    computed: {
        pickerValue() {
            return !!this.isLoading
                ? []
                : this.value;
        },
    },
    methods: {
        async getGames() {
            this.isLoading = true;

            try {
                const response = await window.axios.get('/games', {
                    params: { type: this.filter }
                });

                this.gameList = response.data;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        updateModel(value) {
            this.$emit('input', value);
        },
    },
    async created() {
        await this.getGames();
    }
}
</script>