<template>
    <div>
        <div class="row">
            <div class="col-12">
                <languages-picker :value="locale"
                                  :return-only-value="false"
                                  @update="onLocaleChangeEvent"
                />
            </div>
            <div class="col-12">
                <atbl-form-group id="fsEmailTemplate"
                                 label="EmailTemplate"
                                 label-for="template"
                                 class="font-weight-bold"
                                 :invalid-feedback="getError(errors, 'email-template')"
                                 :state="getState(errors, 'email-template')"
                >
                    <tree-select name="email_template"
                                 :multiple="false"
                                 :clearable="true"
                                 :searchable="true"
                                 :disabled="predefinedTemplate"
                                 :open-on-click="true"
                                 :open-on-focus="true"
                                 :clear-on-select="true"
                                 :close-on-select="true"
                                 :options="templates"
                                 :max-height="300"
                                 :value="sendEmailModel.templateId"
                                 @select="onEmailTemplateSelectEvent"
                                 class="font-weight-normal"
                    />
                </atbl-form-group>
            </div>
        </div>
        <div v-if="isOrderSummary">
            <form @submit.prevent="onOrderSearchEvent">
                <div class="d-flex align-items-end justify-content-between">
                    <div class="flex-grow-1 mr-2">
                        <atbl-number label="Order ID"
                                     name="orderId"
                                     :value="`${!orderId ? '' : orderId}`"
                                     @update="onOrderIdChangeEvent"
                                     placeholder="Enter order id"
                                     :min=1
                                     :max="false"
                                     class="m-0"
                                     :disabled="predefinedTemplate"
                        />
                    </div>
                    <div v-if="!predefinedTemplate" class="p-1">
                        <button class="btn btn-sm btn-primary"
                                type="submit"
                                :disabled="!orderId"
                        >
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
            <loader :show="orderLoader"
                    type="small"
            />
            <div v-if="!orderLoader && !isObjectEmpty(order)"
                 class="mt-3"
            >
                <div class="alert alert-info mb-0">
                    <span>ID: <b>{{ order.id }}</b>, </span>
                    <span>Date: <b>{{  this.$options.filters.dateFormat(order.created_at, 'DD-MM-YYYY') }}</b>, </span>
                    <span>Number of order items: <b>{{ order.order_items.length }}</b></span>
                </div>
            </div>
            <div v-if="!!orderError"
                 class="alert alert-danger mt-3"
            >
                {{ orderError }}
            </div>
        </div>
        <div v-if="isTicketConfirmation">
            <form @submit.prevent="onTicketSearchEvent">
                <div class="d-flex align-items-end justify-content-between">
                    <div class="mr-2">
                        <atbl-select label="Type"
                                     name="ticketType"
                                     :options="ticketTypes"
                                     :first-option-label="null"
                                     :value="sendEmailModel.ticketType"
                                     class="m-0"
                                     @update="onUpdateTicketTypeEvent"
                        />
                    </div>
                    <div class="flex-grow-1 mr-2">
                        <atbl-number label="ID"
                                     name="ticketId"
                                     :value="`${!ticketId ? '' : ticketId}`"
                                     @update="onTicketIdChangeEvent"
                                     placeholder="Enter ticket id"
                                     :max="false"
                                     :min="1"
                                     class="m-0"
                        />
                    </div>
                    <div class="p-1">
                        <button class="btn btn-sm btn-primary"
                                type="submit"
                                :disabled="!ticketId"
                        >
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
            <loader :show="ticketLoader"
                    type="small"
            />
            <div v-if="!ticketLoader && !isObjectEmpty(ticket)"
                 class="ticket-preview mt-3"
            >
                <div class="ticket-preview-holder-row">
                    <div>
                        <img :src="$imageService.gameImage(ticket.game.image)"
                             :alt="`${ticket.game.image}`" style="width: 60px; height: auto;">
                    </div>
                    <div>
                        <h4>{{ ticket.game.name }}</h4>
                        <div class="draw-date"
                             v-if="isset(ticket, 'draw') && ticket.draw.game_draw && isset(ticket.draw.game_draw, 'date')">
                            <span class="text-muted small">Date: {{ ticket.draw.game_draw.date }}</span>
                        </div>
                        <div class="draw-date"
                             v-if="isset(ticket, 'game_draw') && ticket.game_draw && isset(ticket.game_draw, 'date')">
                            <span class="text-muted small">Date: {{ ticket.game_draw.date }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!!ticketError"
                 class="alert alert-danger mt-3"
            >
                {{ ticketError }}
            </div>
        </div>
    </div>
</template>

<script>
import LanguagesPicker from '../../Forms/Select/LanguagesPicker';
import AtblModal from '../../AtblModal/AtblModal.vue';

export default {
    name: 'SendEmailFormComponent',
    inject: [
        'onLocaleChangeEvent',
        'onEmailTemplateSelectEvent',
        'onTicketSearchEvent',
        'onUpdateTicketTypeEvent',
        'onTicketIdChangeEvent',
        'onOrderIdChangeEvent',
        'onOrderSearchEvent',
        'emailTemplateData'
    ],
    components: {
	    AtblModal,
        LanguagesPicker,
    },
    props: {
        sendEmailConfiguration: {
            type: Object,
            default: () => ({})
        },
        locale: {
            type: [String, Object],
            default: null
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        templates: {
            type: Array,
            default: () => ([])
        },
        ticketTypes: {
            type: Array,
            default: () => ([])
        },
        selectedEmailTemplate: {
            type: Object,
            default: () => ({})
        },
        ticket: {
            type: Object,
            default: () => ({})
        },
        ticketLoader: {
            type: Boolean,
            default: false
        },
        ticketError: {
            type: String,
            default: null
        },
        isTicketConfirmation: {
            type: Boolean,
            default: false,
        },
        isOrderSummary: {
            type: Boolean,
            default: false,
        },
        order: {
            type: Object,
            default: () => ({})
        },
        orderLoader: {
            type: Boolean,
            default: false
        },
        orderError: {
            type: String,
            default: null
        },
        sendEmailModel: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        ticketId() {
            return this.sendEmailModel.ticketId;
        },
        orderId() {
            return this.sendEmailModel.orderId;
        },
        predefinedTemplate() {
            return !!this.emailTemplateData.templateKey
        }
    },
}
</script>