var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "d-flex align-items-center justify-content-center" },
    [
      !_vm.isChecked
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-link btn-confirm",
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.showOperations }
            },
            [
              _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
              _vm._v(" "),
              _vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isChecked
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success btn-confirm-action",
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.confirmed }
            },
            [_c("i", { staticClass: "fa fa-check" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isChecked
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-danger btn-confirm-action",
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.showOperations }
            },
            [_c("i", { staticClass: "fa fa-times" })]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }