var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "alert alert-flash",
      class: _vm.type,
      attrs: { role: "alert" }
    },
    [
      _c("strong", [_vm._v("Message:")]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.body) } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }