var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "player-layout",
    {
      attrs: {
        id: _vm.id,
        title: "Add Funds",
        header: false,
        cardClass: "player-funds-card tabs-atbl",
        bodyClass: "p-0"
      }
    },
    [
      _vm.canPlay
        ? _c(
            "div",
            [
              _vm.$canAny(_vm.accesses)
                ? _c(
                    "atbl-tabs",
                    {
                      attrs: { "active-tab": 0 },
                      on: { "update:activeTab": _vm.setActiveTab }
                    },
                    [
                      _vm.$can("Payon - Funds Deposit Agent")
                        ? _c(
                            "atbl-tab",
                            { attrs: { title: "Deposit" } },
                            [
                              _c("AddFundsForm", {
                                on: { gatewayResponse: _vm.onGatewayResponse }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$can("Payon - Virtual Deposit Agent")
                        ? _c(
                            "atbl-tab",
                            { attrs: { title: "Add credit" } },
                            [_c("AddCreditForm")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$can("Payon - Bonus Deposit Agent") ||
                      _vm.$can("Payon - Bonus Deposit User")
                        ? _c(
                            "atbl-tab",
                            { attrs: { title: "Add bonus" } },
                            [_c("AddBonusForm")],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c("not-found", {
                    attrs: {
                      items: [],
                      msg: "You don`t have access to visit this page."
                    }
                  })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "py-5" },
            [
              _c("CanPlayGame", {
                attrs: { isLoading: _vm.playerLoader, isFullScreen: true }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c("ConfirmCreditModal"),
      _vm._v(" "),
      _c("ConfirmBonusModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }