<template>
    <div class="game" :title="item.gameable.name">
        <div class="ribbon-warning"><span>Raffle {{ item.number_of_tickets > 1 ? `x ${item.number_of_tickets}` : ''}}</span></div>
        <div class="img-holder">
            <img class="game-thumb" :src="$imageService.gameImage(item.gameable.image)" />
        </div>
        <div class="game-play">
            <p class="mb-1">{{ item.gameable.name }}</p>
            <div class="game-play-info">
                    <span v-for="(item, key) in item.meta">
                        <b>{{ ucFirst(key) }}:</b> {{ item }}
                    </span>
            </div>
            <button :class="{
						'btn-primary': !isLoading,
						'btn-outline-primary': isLoading
			          }"
                      class="btn"
                      @click="playGame"
            >
                <span v-if="!isLoading">Play</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </div>
    </div>
</template>

<script>
import TypesConst from '../../../../../../../store/TypesConst';
import NamespaceConst from "../../../../../../../store/NamespaceConst";
import { createNamespacedHelpers } from "vuex";

const {
    mapActions: mapGamesActions
} = createNamespacedHelpers(NamespaceConst.games);

export default {
    name: 'Raffle',
    inject: [
        'setShowGame',
        'setProcessingOverlay',
        'setCheckout',
        'clearCart',
        'selectFreeGame',
    ],
    props: {
        item: {
            type: Object,
            required: true
        },
        orderId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        ...mapGamesActions({
            getGameConfiguration: TypesConst.games.actions.GET_GAME_CONFIGURATION_ACTION
        }),
        playGame () {
            this.setShowGame(true);
            this.setProcessingOverlay(true);
            this.setCheckout({
                checkout: false
            });

            if (this.orderId) {
                this.clearCart();
            }

            this.selectFreeGame(this.item);

            this.isLoading = true;
            this.getGameConfiguration(this.item.gameable)
                .finally(() => {
                    this.isLoading = false
                    this.setProcessingOverlay(false);
                });
        }
    }
}
</script>