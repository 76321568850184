export default {
    customerio: {
        color: 'danger',
        meta: {
            languages: {},
            options: {
                send_to_unsubscribed: {
                    value: true,
                    inputType: 'toggle',
                    title: 'Send to unsubscribed people?',
                },
                tracked: {
                    value: true,
                    inputType: 'toggle',
                    title: 'Enable open and link tracking?',
                    description: 'Enable link tracking to generate click metrics for any links in your message.'
                },
                disable_message_retention: {
                    value: false,
                    inputType: 'toggle',
                    title: 'Disabling message retention.',
                    description: 'This will prevent the message body from displaying in Customer.io when viewing sent deliveries. Hiding the message body is helpful for obfuscating sensitive content, like password reset tokens.'
                },
                queue_draft: {
                    value: false,
                    inputType: 'toggle',
                    title: 'Queue messages as drafts?',
                    description: 'This will generate a draft for every triggered message. You can review these messages under the "Drafts" tab and decide whether to send them or delete them.'
                },
                disable_css_preprocessing: {
                    value: true,
                    inputType: 'toggle',
                    title: 'Disable CSS processing?',
                },
            }
        }
    },
}