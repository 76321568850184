import { filterNonEmpty } from '../../utils';
import moment from 'moment/moment';
import Download from '../../modules/download/Download';

export default {
    data() {
        return {
            isExportLoading: false,
        };
    },
    methods: {
        async onExportCallHistory() {
            if (!filterNonEmpty(this.callHistoryFilterModel).length) {
                window.showMessage('Search form cannot be empty.', false);

                return;
            }

            this.isExportLoading = true;

            try {
                const response = await window.axios.get('/call/history/export',
                    {
                        params: this.callHistoryFilterModel,
                        responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': 'text/csv',
                            'Content-Disposition': 'attachment; filename="call-history.csv"'
                        }
                    });

                Download.downloadFile(new Blob([response.data]), `call-history-${moment().format('YYYY-MM-DD HH-mm-ss')}.csv`);

                this.isExportLoading = false;
            } catch (error) {
                this.isExportLoading = false;

                window.flashError(error);
            }
        },
    },
}
