<template>
    <div class="settings-list">
        <div v-for="(setting, index) in settings" :key="index" class="settings-list-item">
            <div class="settings-list-item-left">
                <span class="name" title="Name">{{ setting.name }}</span><br/>
                <span class="key" title="Key">{{ setting.key }}</span>
            </div>
            <div class="settings-list-item-middle">
                <input v-if="setting.type === 'input'"
                       type="text"
                       class="form-control"
                       @keyup="changeValue($event, setting)"
                       :value="setting.value"
                       :disabled="!$can('Settings - Create/Update')"
                />
                <atbl-switch v-else
                             :value="setting.value"
                             :on="setting.on_value"
                             :off="setting.off_value"
                             :name="setting.key"
                             :is-loading="settingSaveLoading"
                             @input="changeValue($event, setting)"
                />
            </div>
            <div class="settings-list-item-right"
                 v-if="$canAny(permissionsOperations)"
            >
                <c-dropdown id="dropdown-right" right color="link" :caret="false">
                    <template #toggler-content>
                        <i class="fa fa-ellipsis-v" aria-hidden="false"></i>
                    </template>
                    <c-dropdown-item href="#"
                                     @click="editSetting($event, setting)"
                                     v-if="$can('Settings - Create/Update')"
                    >
                        Edit
                    </c-dropdown-item>
                    <c-dropdown-item href="#"
                                     @click="removeSetting(setting)"
                                     v-if="$can('Settings - Remove')"
                    >
                        Remove
                    </c-dropdown-item>
                </c-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    import TypesConst from "../../../../store/TypesConst";
    import NamespaceConst from "../../../../store/NamespaceConst";
    import {createNamespacedHelpers} from 'vuex';
    const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(NamespaceConst.settings);

    export default {
        name: "settings-section",
        data: function(){
            return {
                permissionsOperations: [
                    "Settings - Create/Update",
                    "Settings - Remove"
                ]
            };
        },
        computed: {
            ...mapGetters({
                settings: TypesConst.settings.getters.GET_SETTINGS,
                settingSaveLoading: TypesConst.settings.getters.GET_SETTINGS_SAVE_LOADER
            })
        },
        methods: {
			...mapMutations({
                setSettingSaveLoading: TypesConst.settings.mutations.SET_SETTINGS_SAVE_LOADER
			}),
            ...mapActions({
                editSettingForm: TypesConst.settings.actions.EDIT_SETTING_ACTION,
                immediateSaveSetting: TypesConst.settings.actions.SAVE_SETTINGS_FAST_ACTION,
                removeSetting: TypesConst.settings.actions.REMOVE_SETTINGS_ACTION
            }),
            changeValue: _.debounce(function($event, setting){
				this.setSettingSaveLoading(true);
				
                const value = setting.type === 'input'
                    ? $event.target.value
                    : $event;
				
                this.immediateSaveSetting({
                    id: setting.id,
                    key: setting.key,
                    value: value
                });
            }, 500),
            editSetting: function($event, setting){
                this.editSettingForm({
                    setting_id: setting.id
                });
            },
        }
    }
</script>

<style lang="scss">
    .settings-list {
        &-item {
            display: flex;
            border-bottom: 1px solid #e0e0e0;
            align-items: center;

            &:nth-child(odd){
                background-color: #f6f6f6;
            }

            &-left {
                width: 50%;
                padding: 0 10px;

                .name {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 5px;
                }

                .key {
                    color: #9c9c9c;
                }
            }

            &-middle {
                width: 45%;
            }

            &-right {
                width: 5%;

                .dropdown {
                    margin: 0;
                    width: 100%;

                    button {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }
</style>