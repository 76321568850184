<template>
    <div class="row mb-4">
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-card :description="range"
                                         :value="statistics.talkTime"
                                         :loader="isLoading"
                                         title="Talk Time"
                                         class="border-primary"
            />
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-multiple-card :description="range"
                                                  :rightValue="statistics.leads"
                                                  :leftValue="statistics.players"
                                                  :loader="isLoading"
                                                  title="Total Dials"
                                                  rightTitle="Leads"
                                                  leftTitle="Players"
                                                  class="border-danger"
            />
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-card :description="range"
                                         :value="statistics.completedCalls"
                                         :loader="isLoading"
                                         title="Completed Calls"
                                         class="border-success"
            />
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <call-history-statistic-card :description="range"
                                         :value="statistics.averageTalkTime"
                                         :loader="isLoading"
                                         title="Average Daily Talk Time"
                                         class="border-warning"
            />
        </div>
    </div>
</template>

<script>
import CallHistoryStatisticCard from '../_Components/Statistics/CallHistoryStatisticCard';
import CallHistoryStatisticMultipleCard from '../_Components/Statistics/CallHistoryStatisticMultipleCard';
import moment from 'moment';
import CallHistorySearchModel from '../CallHistoryFilterForm/CallHistorySearchModel';

export default {
    name: 'CallHistoryRangeStatistics',
    components: {
        CallHistoryStatisticCard,
        CallHistoryStatisticMultipleCard
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        statistics: {
            type: Object,
            default: () => ({
                talkTime: null,
                leads: null,
                players: null,
                completedCalls: null,
                averageTalkTime: null,
            })
        },
        callHistorySearchModel: {
            type: Object,
            default: () => (CallHistorySearchModel)
        },
    },
    computed: {
        range() {
            const { dateFrom, dateTo } = this.callHistorySearchModel;

            if (!!dateFrom && !!dateTo) {
                return `${dateFrom} - ${dateTo}`;
            }

            return `${moment().startOf('month').format('YYYY-MM-DD')} - ${moment().endOf('month').format('YYYY-MM-DD')}`;
        }
    },
}
</script>