<template>
    <c-sidebar-nav-title v-if="$canAny(permissions)">Administrator</c-sidebar-nav-title>
</template>

<script>
    export default {
        name: "AdministratorNavTitle",
        data: function(){
            return {
                permissions: [
                    "Withdrawals - View",
                    "Conversions Rate - View",
                    "Users - View",
                    "Roles - View",
                    "Permissions - View",
                    "Settings - View",
                    'Game Draws - View',
                    'Games - Price View',
                    'Free Games - View',
                    'Users - Free Games Settings View',
                ]
            };
        }
    }
</script>