<template>
    <CContainer v-if="$canAny(permissions)" fluid>
        <div class="row">
            <div v-if="$can('Dashboard - View Player Section')"
                 class="col-12 mb-3"
            >
                <PlayersSection :data="playersCreated"
                                class="pb-0"
                />
            </div>
            <div v-if="$can('Dashboard - View Sales Section')"
                 class="col-12 col-xl-8 mb-3"
            >
                <SalesSection />
            </div>
            <div v-if="$canAny([
                    'Dashboard - View Deposits Section',
                    'Dashboard - View Bonus Section'
                ])"
                 class="col-12 mb-3"
            >
                <div class="row">
                    <div v-if="$can('Dashboard - View Deposits Section')"
                         class="col-12 col-md-6 col-xl-8 mb-3 mb-md-0"
                    >
                        <DepositsSection />
                    </div>
                    <div v-if="$can('Dashboard - View Bonus Section')"
                         class="col-12 col-md-6 col-xl-4"
                    >
                        <BonusSection />
                    </div>
                </div>
            </div>
            <div v-if="$can('Dashboard - View Calls Section')"
                 class="col-12 mb-3"
            >
                <CallStatistics :data="calls"
                                class="pb-0"
                />
            </div>
            <div v-if="$can('Dashboard - View Disposition Breakdown Section')"
                 class="col-12 mb-3"
            >
                <DispositionBreakdownSection :data="dispositionBreakdown" />
            </div>
        </div>
    </CContainer>
</template>

<script>
import PlayersSection from './Sections/Players';
import DispositionBreakdownSection from './Sections/DispositionBreakdown';
import SalesSection from './Sections/Sales';
import DepositsSection from './Sections/Deposits';
import BonusSection from './Sections/Bonus';
import CallStatistics from './Sections/CallStatistics';

export default {
    name: 'dashboard',
    components: {
        PlayersSection,
        DispositionBreakdownSection,
        SalesSection,
        DepositsSection,
        BonusSection,
        CallStatistics
    },
    props: [
        'playersCreated',
        'earnings',
        'calls',
        'dispositionBreakdown'
    ],
    provide() {
        return {
            getStatisticsValue: this.getStatisticsValue,
        };
    },
    data() {
        return {
            permissions: [
                'Dashboard - View Player Section',
                'Dashboard - View Sales Section',
                'Dashboard - View Deposits Section',
                'Dashboard - View Bonus Section',
                'Dashboard - View Calls Section',
                'Dashboard - View Disposition Breakdown Section',
            ],
        };
    },
    methods: {
        getStatisticsValue(type = 'crm', paymentType = 'sales', period = 'daily') {
            if (!this.earnings) {
                return this.getAmount(0);
            }

            if (!this.earnings[type]) {
                return this.getAmount(0);
            }

            const value = !!this.isset(this.earnings[type], paymentType) &&
            !!this.isset(this.earnings[type][paymentType], period)
                ? this.earnings[type][paymentType][period]
                : 0;

            return this.$options.filters.formatCurrency(value);
        },
        getAmount(value) {
            return this.$options.filters.formatCurrency(value);
        },
    },
}
</script>
