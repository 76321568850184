import NamespaceConst from "../../../NamespaceConst";

export default {
    mutations: {
        SET_PLAYER_CALL_HISTORY_LIST_LOADER: `${NamespaceConst.players}setPlayerCallHistoryListLoader`,
        SET_PLAYER_CALL_HISTORY_LIST_MODEL_FIELD: `${NamespaceConst.players}setPlayerCallHistoryModelField`,
    },
    getters: {
        GET_PLAYER_CALL_HISTORY_LIST_LOADER: `${NamespaceConst.players}getPlayerCallHistoryListLoader`,
        GET_PLAYER_CALL_HISTORY_LIST_MODEL: `${NamespaceConst.players}getPlayerCallHistoryModel`,
    },
    actions: {
        GET_PLAYER_CALL_HISTORY_ACTION: `${NamespaceConst.players}getPlayerCallHistoryAction`,
    }
};