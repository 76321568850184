export default {
    provide() {
        return {
            toggleCreatePlayerModal: this.toggleCreatePlayerModal,
        };
    },
    data() {
        return {
            createPlayerModalOpen: false,
        };
    },
    methods: {
        async onCreatedLead(object) {
            const {
                shouldGoNext,
                shouldAddStatus,
            } = object;

            this.isAddStatusAllowed = shouldAddStatus;
            this.shouldGoNext = shouldGoNext;

            await this.fetchCustomerStatuses(this.callId);

            await this.fetchLeadLastCalls(this.callId);
        },
        toggleCreatePlayerModal() {
            this.createPlayerModalOpen = !this.createPlayerModalOpen;
        },
    },
}