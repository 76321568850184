<template>
    <div class="card card-list">
        <div class="card-header d-flex align-items-center justify-content-between">
            <span><i class='fa fa-align-justify'></i> Ticket Lines</span>
        </div>

        <div class="card-body ticket-lines-card-body">
            <div class="general-list">
                <div class="row d-none d-lg-flex">
                    <div class="col-sm-2 text-muted">#Line</div>
                    <div class="col-sm-2 text-muted">Reference</div>
                    <div class="col-sm-2 text-muted">Draw Date</div>
                    <div class="col-sm-4 text-muted">Numbers</div>
                    <div class="col-sm-2 text-muted">Status</div>
                </div>

                <div v-for="(line, index) in lines"
                     :key="`line_${index}`"
                     class="row"
                >
                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">#Line</span>
                        #L-{{ index + 1 }}
                    </div>

                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">Reference</span>
                        <a :href="`/syndicate-shares/${syndicateShare.id}`" class="d-block">
                            <strong>Syndicates Ticket #{{ syndicateShare.id }}</strong>
                        </a>
                        <a :href="`/orders/${syndicateShare.order_id}`">Order #{{ syndicateShare.order_id }}</a>
                    </div>

                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">Draw Date</span>
                        {{ syndicateShare.cut_off_at || '' }}
                    </div>

                    <div class="col-12 col-lg-4 text-center align-self-center text-center text-lg-left mb-2 mb-lg-0">
                        <span class="small-mb-badge">Numbers</span>

                        <div class="numbers-group">
                            <template v-for="(group, key) in lineNumbers(line)">
                                <div v-if="Array.isArray(group)" :class="`numbers-group-wrapper ${key}-numbers`" v-tooltip="{content: key}">
                                <span v-for="number in group" :class="[key, typeResolver(key), isMatch(number, key) && line.prize ? 'match' : '']">
                                    {{ number }}
                                </span>
                                </div>

                                <span v-else :class="[key, group, typeResolver(key), isMatch(group, key) ? 'match' : '']" v-tooltip="{content: key}">
                                {{ group }}
                            </span>
                            </template>
                        </div>
                    </div>

                    <div class="col-12 col-lg-2 align-self-center text-center text-lg-left">
                        <span class="small-mb-badge">Status</span>
                        {{ lineWinStatus(line) }}
                    </div>
                </div>
            </div>

            <div v-if="totalPages && totalPages >= linePage"
                 class="row"
            >
                <div class="col-12 d-flex justify-content-center align-items-center my-2">
                    <button :disabled="isLoading"
                            type="submit"
                            class="btn btn-sm btn-primary"
                            @click="fetchLines"
                    >
                        <span v-if="!isLoading">
                            <i class="fa fa-search"></i> Show more
                        </span>
                        <loader :show="isLoading"
                                type="smaller"
                        />
                    </button>
                </div>
            </div>
            <template v-else>
                <loader :show="isLoading"
                        type="small"
                />
            </template>
        </div>
    </div>
</template>

<script>
import TicketMixin from '../../../../mixins/TicketMixin';
import SyndicateService from '../../../../services/SyndicateService';
import { parse } from '../../../../utils/Json/Json';
import formatCurrency from '../../../../filters/FormatCurrency';
import Loader from '../../../../components/Widgets/Loader';

export default {
    name: 'syndicate-ticket-lines',
    mixins: [
        TicketMixin
    ],
    components: {
        Loader,
    },
    props: {
        syndicateShare: {
            type: Object,
            default: () => ({})
        },
        gameNumberConfiguration: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            linePage: 1,
            totalPages: null,
            lines: [],
            isLoading: false,
        };
    },
    computed: {
        gameDrawNumbers() {
            return parse(this.syndicateShare.numbers);
        },
    },
    async created() {
        await this.fetchLines();
    },
    methods: {
        async fetchLines() {
            this.isLoading = true;

            try {
                const response = await SyndicateService.syndicateLines(this.syndicateShare.syndicate_item_draw_id, this.linePage);

                this.totalPages = response.data.last_page;

                if(this.linePage === 1) {
                    this.lines = [];
                }

                this.lines.push(...response.data.data);
                this.linePage++;

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;

                window.flashError(error);
            }
        },
        lineNumbers(line) {
            let lineNumbers = line.numbers,
                extraNumbersConfiguration = this.gameNumberConfiguration.extra,
                extraNumbers = {},
                drawExtraNumbers = !!this.gameDrawNumbers ? this.gameDrawNumbers : null;

            if (!extraNumbersConfiguration) {
                return {
                    ...lineNumbers,
                    ...extraNumbers
                };
            }

            for (let extraNumberKey in drawExtraNumbers) {
                let currentExtraNumber = drawExtraNumbers[extraNumberKey],
                    hasKey = this.isset(extraNumbersConfiguration, extraNumberKey);

                if (!hasKey) {
                    continue;
                }

                extraNumbers = {
                    ...extraNumbers,
                    item: currentExtraNumber
                };
            }

            return {
                ...lineNumbers,
                ...extraNumbers
            };
        },
        isMatch(num, type) {
            if (!this.gameDrawNumbers) {
                return false;
            }

            const gameDrawNumbers = this.gameDrawNumbers[type];

            if (Array.isArray(gameDrawNumbers)) {
                return gameDrawNumbers.includes(num.toString()) || gameDrawNumbers.includes(num);
            }

            return gameDrawNumbers === num || gameDrawNumbers.toString() === num.toString();
        },
        lineWinStatus(line) {
            if (line.prize === null) {
                return 'No status';
            }

            if (!line.prize) {
                return 'Not a winning line';
            }

            return `Won: ${formatCurrency(line.prize)}`;
        },
    },
}
</script>

<style lang="scss" scoped>
.ticket-results {
    .raffle {
        font-family    : monospace;
        font-size      : 16px;
        font-weight    : bold;
        letter-spacing : 4px;
    }

    .fraction {
        color       : #000000;
        font-weight : bold;
        font-size   : 16px;
        font-family : monospace;
    }
}
</style>