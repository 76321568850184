<template>
    <atbl-modal v-if="$can('Shifts - Create/Update')"
             :show="show"
             :close-on-backdrop="false"
             :no-close-on-esc="false"
             title="Delete shift"
             @close="toggleDeleteShiftModal"
    >
        <template v-if="!isLoading">
            <h4 v-if="!hasUsers"
                class="text-center"
            >
                Are you really want to delete this shift?
            </h4>
            <div v-else>
                <h4 class="text-center mb-3">You cannot remove this shift because there is still users assigned?</h4>

                <ul>
                    <li v-for="({id, name}, index) in selectedShift.users"
                        :key="index"
                    >
                        # {{ id }} / <b>{{ name }}</b>
                    </li>
                </ul>
            </div>
        </template>
        <loader :show="isLoading"
                type="small"
        />

        <template #footer>
            <button v-if="!isLoading"
                      class="btn btn-sm btn-outline-danger mr-2"
                      :disabled="isLoading"
                      @click="toggleDeleteShiftModal"
            >
                Cancel
            </button>
            <button v-if="$can('Shifts - Details')"
                      :disabled="!!isLoading || !selectedShift || hasUsers"
                      class="btn btn-sm btn-primary"
                      @click="deleteShift"
            >
                <span v-if="!isLoading">Save</span>
                <loader :show="isLoading"
                        type="smaller"
                />
            </button>
        </template>
    </atbl-modal>
</template>

<script>
import AtblModal from '../../../components/AtblModal/AtblModal.vue';

export default {
    name: 'DeleteShiftComponent',
    inject: [
        'deleteShift',
        'toggleDeleteShiftModal',
    ],
    components: {
		AtblModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        selectedShift: {
            type: Object,
            default: null,
        },
    },
    computed: {
        hasUsers() {
            if (!this.selectedShift) {
                return false;
            }

            const { users } = this.selectedShift;

            return users && !!users.length;
        }
    },
}
</script>